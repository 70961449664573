import React from 'react';
import {
  Grid,
  withStyles,
  Paper,
  Avatar,
  Chip,
  Typography,
} from '@material-ui/core';
import { MemberDetailField } from '..';

import ProviderSVG from '../../../../client/svgs/provider.svg';
import OrganizationSVG from '../../../../client/svgs/organization.svg';
import { RESULT_TYPE_PROVIDER } from './mockResults';
import MemberAddressField from '../MemberAddressField';

const styles = {
  root: {
    borderRadius: 4,
    boxShadow: '0 0 0 rgba(0,0,0,0)',
    border: '1px solid #CECECE',
    marginBottom: 8,
    padding: '20px 32px',
    cursor: 'pointer',
  },
  avatar: {
    marginRight: 10,
    width: 65,
    height: 65,
    backgroundColor: '#F0F0F0',
    '& img': {
      width: 32,
      height: 32,
    },
  },
  header: {
    fontWeight: 500,
    fontSize: 18,
    margin: '0px 0 4px',
  },
  chip: {
    borderRadius: 4,
    height: 25,
    fontWeight: 700,
    color: '#fff',
    textTransform: 'capitalize',
    marginRight: 12,
    marginBottom: 12,
  },
};

const DEFAULT_FIELD_VALUE = 'Not specified';

const defaultValue = (value, replacement) => {
  return value === undefined ? replacement : value;
};

const SearchResult = ({ data, classes, onClick }) => {
  const renderAddressLine1 = () => {
    const { street = '', street2 = '' } = data;
    return `${street} ${street2 === 'NULL' ? '' : street2}`.trim();
  };

  const renderAddressLine2 = () => {
    const { city = '', state = '', postalCode = '' } = data;
    if (
      city.trim().length + state.trim().length + postalCode.trim().length >
      0
    ) {
      return `${city}, ${state} ${postalCode}`;
    }
    return null;
  };

  const onPlatform = () => {
    if (data.onPlatform) {
      return (
        <Chip
          className={classes.chip}
          label="On Platform"
          style={{ backgroundColor: '#A4D859', color: '#FFF' }}
        />
      );
    }
    return (
      <Chip
        className={classes.chip}
        label="Not on Platform"
        style={{ backgroundColor: '#FBC67A', color: '#FFF' }}
      />
    );
  };

  const setResultType = () => {
    return ProviderSVG;
    if (data.type === RESULT_TYPE_PROVIDER) {
      return ProviderSVG;
    }
    return OrganizationSVG;
  };

  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  let fullName = '';
  let secondaryName = null;
  if (data.entityTypeCode === '2') {
    fullName = data.organizationName;
  } else {
    const prefix = data.prefix && data.prefix !== 'NULL' ? data.prefix : '';
    const suffix = data.suffix && data.suffix !== 'NULL' ? data.suffix : '';

    fullName = `${prefix} ${data.firstName} ${data.lastName} ${suffix}`.trim();
  }

  return (
    <Paper className={classes.root} onClick={handleClick}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item container xs={4} direction="row" alignItems="flex-start">
          <Avatar className={classes.avatar} src={setResultType()} />
          <Grid item xs={8}>
            {secondaryName && (
              <Typography variant="body2">{secondaryName}</Typography>
            )}
            <h2 className={classes.header}>{fullName}</h2>
            {onPlatform()}
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <MemberAddressField
            onSubmit={() => {}}
            icon="place"
            member={data}
            isEditable={false}
          />
          {/* <MemberDetailField
            icon="place"
            line1={renderAddressLine1()}
            line2={renderAddressLine2()}
          /> */}
        </Grid>
        <Grid item xs={3}>
          <MemberDetailField
            icon="local_phone"
            dataLine={defaultValue(data.phoneNumber, DEFAULT_FIELD_VALUE)}
            isEditable={false}
          />
          <MemberDetailField
            icon="email"
            dataLine={defaultValue(data.email, DEFAULT_FIELD_VALUE)}
            isEditable={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(SearchResult);
