import { APPLICATION_INVITATION_LOAD_INFO } from "./actions";

const initialState = {
  firstName: '',
  lastName: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_INVITATION_LOAD_INFO:
      return {
        ...state,
        ...action.payload.data
      };

    default:
      return state;
  }
};