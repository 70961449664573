import OrdinalFrame from '../../../OrdinalFrame/OrdinalFrame';
import React from 'react';
import styles from '../styles.css';
import { theme } from './';

const WaterStackedBarChart = props => {
  const { data } = props;
  const frameProps = {
    data,
    size: [320, 320],
    margin: 5,
    type: 'bar',
    projection: 'horizontal',
    dynamicColumnWidth: 'percentage',
    rAccessor: d => {
      return 100;
    },
    oAccessor: 'type',
    style: d => {
      return {
        fill: theme[d.data.type].background,
      };
    },
    oLabel: (d, i) => {
      const label = data.find(item => item.type === d);
      if (label.percentage > 8) return `${label.percentage}%`;
      return '';
    },
    labelSettingsProp: {
      orient: 'center',
      padding: -3,
    },
    labelStyle: (d, i) => {
      return {
        fill: theme[d].color,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: '16px',
      };
    },
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}> </div>
      <OrdinalFrame {...frameProps} />
    </div>
  );
};

export default WaterStackedBarChart;
