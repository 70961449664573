import React from 'react';
import PageHeader from '../../components/PageHeader';
import BasePage from '../BasePage';
import ImmunizationTable from './ImmunizationTable';
import { Grid, Button, Typography, Icon, Divider } from '@material-ui/core';
import Drawer from './Drawer';
import { useImmunizationState, useImmunizationActions } from './hooks';
import Empty from '../../components/Empty/Empty';
import { SUCCESS } from '../../../store/state';

const ImmunizationPage = props => {
  const {
    userImmunizations: { listIds, list, uiState, deleting },
  } = props;
  const { status, setStatus } = useImmunizationState(props);
  const actions = useImmunizationActions(props, setStatus);

  React.useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from === 'healthStatus'
    ) {
      actions.addImmunization();
    }
  }, [location.from]);

  return (
    <BasePage
      state={uiState}
      emptyStateComponentProps={{
        name: 'Immunization',
        type: 'Immunization',
        onClick: actions.addImmunization,
      }}
      DrawerComponent={
        <Drawer
          setStatus={setStatus}
          status={status}
          onClose={actions.handleCloseDrawer}
          onSubmit={actions.handleSubmit}
          onUpdateMediaObject={actions.onUpdateMediaObject}
          {...props}
        />
      }
    >
      <Grid item xs={12}>
        <PageHeader
          headerText="Immunizations / Vaccinations"
          subHeaderText="View Records"
          backButtonText="Go Back"
          backLink={props.navigation.dashboard.healthRecord.self}
          rightSlot={
            listIds.length ? (
              <Button
                color="primary"
                variant="contained"
                aria-label="Add"
                onClick={actions.addImmunization}
                style={{ float: 'right' }}
              >
                <Icon>add</Icon>
                New Record
              </Button>
            ) : null
          }
        />
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      </Grid>
      <Grid item xs={12}>
        <Drawer
          setStatus={setStatus}
          status={status}
          onClose={actions.handleCloseDrawer}
          onSubmit={actions.handleSubmit}
          onUpdateMediaObject={actions.onUpdateMediaObject}
          {...props}
        />
        {listIds.length ? (
          <ImmunizationTable
            listIds={listIds}
            list={list}
            onEdit={actions.updateImmunization}
            onDelete={actions.removeImmunization}
            viewMediaObjects={actions.viewMediaObjects}
            deleting={deleting}
          />
        ) : (
          <Empty
            name="Vaccination"
            onClick={actions.addImmunization}
            disableGoBack
            content={
              <div>
                <Typography style={{ fontSize: '16px', paddingBottom: '16px' }}>
                  Capturing your Immunizations helps your Care Team determine
                  where you might be at risk for disease or infection.
                </Typography>
                <Typography style={{ fontSize: '16px' }}>
                  Additionally, this is a great way to reduce the paperwork
                  headaches associated with the demands for vaccination history
                  or COVID health status from schools, employers and others.
                </Typography>
              </div>
            }
          />
        )}
      </Grid>
    </BasePage>
  );
};

export default ImmunizationPage;
