import React, { Component, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import PhotosIcon from '@material-ui/icons/PhotoLibrary';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './MediaObjectDrawer.css';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ListIcon from '@material-ui/icons/List';
import BackIcon from '@material-ui/icons/ArrowBack';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MediaEditMode from './MediaEditMode';
import MediaAddMode from './MediaAddMode';
import MediaCustomDialog from './MediaCustomDialog';
import Pagination from 'material-ui-flat-pagination';
import FileDownload from '@material-ui/icons/GetApp';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import { downloadBlob } from '../DocumentsDrawer/helpers';
import moment from 'moment';
import {
  getMedia,
  setMedia,
  updateMedia,
  setCurrentItem,
  setItemByIndex,
  setHeaderState,
  setDrawerState,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import { allergySelectId } from '../../../store/reducers/allergies/actions';
import { mvsSetIdToUpdate } from '../../../store/reducers/mvs/actions';
import {
  SURGICALHISTORY,
  ALLERGY,
  JOURNAL,
  CONDITIONS,
  IMMUNIZATION,
  MVS,
  clearFiles,
} from '../../../store/reducers/mediaObject';

import Image from '../../components/Image';
import Video from '../../components/Video';

import { getBestTargetImageFormat } from '../../components/MediaObject/helpers';
import {
  isImageMimeType,
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
  MIME_TYPE_IMAGE_WEBP,
  MIME_TYPE_VIDEO_MP4,
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_APPLICATION_WORD,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_EXCEL,
  MIME_TYPE_APPLICATION_XLSX,
  MIME_TYPE_TEXT_CSV,
  MIME_TYPE_TEXT_PSV,
  MIME_TYPE_TEXT_TSV,
  MIME_TYPE_TEXT_PLAIN,
} from '../../../library/helpers/mimeTypes';

import { WordIcon, PdfIcon, OtherIcon } from './MediaObjectSvgIcons';

const noop = () => {};

export const MediaObjectDrawerPreview = ({
  personId,
  data: { formats, links, mimeType, base64DataUriString },
}) => {
  const iconStyle = {
    background: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  };

  switch (mimeType) {
    case MIME_TYPE_IMAGE_JPG:
    case MIME_TYPE_IMAGE_JPEG:
    case MIME_TYPE_IMAGE_PNG:
    case MIME_TYPE_IMAGE_WEBP:
      return (
        <Image
          personId={personId}
          src={links[getBestTargetImageFormat(formats, 'SM')]}
          placeholder={base64DataUriString}
          className={styles.imgCover}
          height="100%"
          alt="Media Image"
        />
      );

    case MIME_TYPE_VIDEO_MP4:
      return (
        <Video
          personId={personId}
          src={links.self}
          controls={false}
          className={styles.videoThumb}
          height="100%"
          cover={true}
        />
      );

    case MIME_TYPE_APPLICATION_PDF:
      return (
        <div style={iconStyle}>
          <PdfIcon width={48} />
        </div>
      );

    case MIME_TYPE_APPLICATION_WORD:
    case MIME_TYPE_APPLICATION_DOCX:
      return (
        <div style={iconStyle}>
          <WordIcon width={48} />
        </div>
      );

    case MIME_TYPE_APPLICATION_EXCEL:
    case MIME_TYPE_APPLICATION_XLSX:
      return (
        <div style={iconStyle}>
          <OtherIcon width={48} />
        </div>
      );

    case MIME_TYPE_TEXT_PLAIN:
    case MIME_TYPE_TEXT_CSV:
    case MIME_TYPE_TEXT_PSV:
    case MIME_TYPE_TEXT_TSV:
      return (
        <div style={iconStyle}>
          <OtherIcon width={48} />
        </div>
      );

    default:
      throw new Error(`Unsupported mime type ${mimeType}`);
  }
};

export const MediaObjectDrawerItems = ({
  personId,
  data,
  index,
  drawerDisplayMode,
  onClick,
  fullWidth = false,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  // separate media file extension file wise and based on extension component will be render.
  let mediaType = data.mimeType;

  const requestControllerRef = useRef();

  useEffect(() => {
    requestControllerRef.current = new AbortController();

    () => requestControllerRef.current.abort();
  }, []);

  const onClickDownload = async evt => {
    evt.stopPropagation();
    if (isDownloading) return noop();

    setIsDownloading(true);

    const response = await apiFetchAuthorized(
      data.links.download,
      {
        signal: requestControllerRef.current.signal,
      },
      false
    );

    const blob = await response.blob();
    downloadBlob(blob, data.fileName);

    setIsDownloading(false);
  };
  return (
    <Tooltip
      placement="top-start"
      title=""
      // title="View attached media"
      arrow
      style={{
        height: '44px',
        width: '44px',
      }}
    >
      <div
        key={data.mediaObjectId}
        className={styles.mediaBlocks}
        style={fullWidth ? { width: '100%', margin: 0 } : {}}
        onClick={() => {
          onClick();
          drawerDisplayMode('view', mediaType, data, index);
        }}
      >
        <div className={styles.mediaBox}>
          <div className={styles.responsive}>
            <MediaObjectDrawerPreview personId={personId} data={data} />
          </div>
        </div>
        <div className={styles.mediaDetails}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                maxWidth: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className={styles.mediaName}
            >
              {data.fileName}
            </Typography>
            <div
              onClick={onClickDownload}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                zIndex: 999,
              }}
            >
              <Tooltip title="Download file">
                <IconButton size="small">
                  <FileDownload color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={styles.mediaDate}>
            {moment(data.enteredOn).format('L')}
          </div>
          <div className={styles.mediaDescription}>
            {data.description && data.description.length > 125
              ? data.description.substring(0, 125) + ' ...'
              : data.description}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export const MediaObjectDrawerHeader = ({
  mode,
  onClickBackButton,
  onClickListDisplayType,
  changeMediaDisplayType,
  viewType,
  disableActions,
}) => {
  const buttonSwitch = viewType === 'LIST';

  mode = mode.toUpperCase();

  return mode === 'VIEW' || mode === 'EDIT' || mode === 'ADD' ? (
    <React.Fragment>
      <div
        className={styles.overviewBackBtn}
        onClick={() => onClickBackButton('LIST')}
      >
        <BackIcon /> Overview
      </div>
    </React.Fragment>
  ) : (
    <div className={styles.rightDrawerPanel}>
      <React.Fragment>
        <ListIcon
          onClick={buttonSwitch ? noop() : () => onClickListDisplayType('LIST')}
          className={`${styles.listIcon} ${buttonSwitch ? styles.active : ''}`}
        />
        <DashboardIcon
          onClick={
            !buttonSwitch ? noop() : () => onClickListDisplayType('GRID')
          }
          className={`${styles.gridIcon} ${!buttonSwitch ? styles.active : ''}`}
        />
      </React.Fragment>
    </div>
  );
};

const MediaObjectDrawerSearch = () => (
  <div className={styles.searchHolder}>
    <InputBase
      value={this.state.searchTxt}
      placeholder="Search"
      inputProps={{ 'aria-label': 'search' }}
      className={styles.searchInput}
      onChange={e => this.searchMedia(e)}
    />
    {this.state.isSearchMode ? (
      <IconButton aria-label="search">
        <SearchIcon />
      </IconButton>
    ) : (
      <IconButton aria-label="clear" onClick={this.clearSearch}>
        <ClearIcon />
      </IconButton>
    )}
  </div>
);

class MediaObjectDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTxt: '',
      right: false,
      setOpen: false,
      fileURL: '',
      isSearchMode: true,
      primaryId: 0,
      areaType: this.props.areaType,
      mediaCollection: this.props.mediaCollection,
      isSubmitting: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.mediaCollection !== prevProps.mediaCollection) {
      this.setState({
        mediaCollection: this.props.mediaCollection,
      });
    }

    if (this.props.currentMediaFile !== prevProps.currentMediaFile) {
      this.setState({
        currentMediaFile: this.props.currentMediaFile,
      });
    }

    // This ill conceived code is the reason for the sudden changes with the "view mode"
    // if (this.props.isMediaUpdate !== prevProps.isMediaUpdate) {
    //   this.setState({ mode: 'view' });
    // }

    if (this.props.areaType !== prevProps.areaType) {
      this.setState({ areaType: this.props.areaType });
    }
  }

  // function to change pagination or current page
  onChangePage = (offset, index) => {
    console.log('onChangePage', offset, index);
    this.setState({
      offset,
      currentMediaIndex: offset,
    });
  };

  // function to change the view of media object collection, there are two types of display option
  // 1) GRID View
  // 2) LIST View
  changeMediaDisplayType = status => {
    this.setState({
      isListView: status,
    });
  };

  // function to open drawer with view mode and edit mode
  // 1) VIEW Mode : to display media files list with grid view and list view structure
  // 2) EDIT Mode : for initialize or open edit mode which allow to update information of selected media file
  drawerDisplayMode = (modeType, filetype, fileObject, index) => {
    this.setState({
      mode: modeType,
      filetype: filetype,
      // currentMediaFile: fileObject,
      currentMediaIndex: index,
      offset: index,
    });
  };

  // There are two dialog box type
  // 1) DELETE : To delete any media object file
  // 2) PREVIEW : Preview any media object file in dialog box
  // Function to change the state or selection of dialog box which is currently needs to be display
  dialogMode = (status, type, fileURL, mediaObj) => {
    if (type) {
      this.setState({
        setOpen: status,
        dialogType: type,
        fileURL: fileURL,
        // currentMediaFile: mediaObj,
      });
    } else {
      this.setState({
        setOpen: status,
        // currentMediaFile: mediaObj,
      });
    }
  };

  // function to submit form data
  onSubmitMediaObjectUpdate = async data => {
    this.setState({ isSubmitting: true });
    await this.props.updateMedia(
      {
        ...data,
        objectId: this.props.primaryId ? this.props.primaryId : data.objectId,
      },
      this.props.areaType
    );
    this.setState({ isSubmitting: false });
  };

  // function to open/close drawer and reinitialize required states of drawer
  toggleDrawer(event, status) {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.setState({
      right: status,
    });

    if (!status) {
      const oldCollection = this.props.mediaCollection;
      this.setState({
        searchTxt: '',
        isListView: false,
        mode: 'list',
        isSearchMode: true,
        collectionMediaFile: oldCollection,
      });
      this.props.clearFiles();
    } else {
      this.props.setMedia(this.props.recordMediaObjects);

      this.setState({ primaryId: this.props.primaryId });
    }
  }

  // function to clear search text box and get old collection or previous collection of media object list
  clearSearch = () => {
    this.setState({
      searchTxt: '',
      isSearchMode: true,
      mediaCollection: this.props.mediaCollection,
    });
  };

  // function to search specific media file from search box
  searchMedia = e => {
    const oldCollection = this.props.mediaCollection;
    if (e.target.value.length === 0) {
      this.setState({
        mediaCollection: oldCollection,
        isSearchMode: true,
        searchTxt: '',
      });
    } else {
      const newCollection = oldCollection.filter(m =>
        m.fileName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      this.setState({
        mediaCollection: newCollection,
        isSearchMode: false,
        searchTxt: e.target.value,
      });
    }
  };

  render() {
    // change view mode of display in container or media object list
    const mediaDisplayStyle =
      this.props.headerStatus === 'LIST'
        ? `${styles.mediaGrid} ${styles.mediaList}`
        : styles.mediaGrid;

    const sideList = () => (
      <div className={styles.rightDrawerPanel}>
        <MediaObjectDrawerHeader
          mode={this.props.drawerStatus}
          onClickBackButton={() => this.props.setDrawerState('LIST')}
          onClickListDisplayType={type => this.props.setHeaderState(type)}
          // changeMediaDisplayType={this.changeMediaDisplayType}
          viewType={this.props.headerStatus}
          // isListView={this.state.isListView}
        />
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={0}
          variant="fullWidth"
        >
          <Tab label="MEDIA VIEWER" />
        </Tabs>

        {this.props.drawerStatus === 'LIST' && (
          <React.Fragment>
            <AddCircleIcon
              onClick={() => this.props.setDrawerState('ADD')}
              className={styles.addCircleIcon}
            />
          </React.Fragment>
        )}

        {this.props.drawerStatus === 'LIST' && (
          <div className={styles.mediaHolder}>
            <div className={styles.searchHolder}>
              <InputBase
                value={this.state.searchTxt}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                className={styles.searchInput}
                onChange={e => this.searchMedia(e)}
              />
              {this.state.isSearchMode ? (
                <IconButton aria-label="search">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="clear" onClick={this.clearSearch}>
                  <ClearIcon />
                </IconButton>
              )}
            </div>
            <div className={mediaDisplayStyle}>
              {this.state.mediaCollection.length > 0 ? (
                this.state.mediaCollection.map((item, index) => (
                  <MediaObjectDrawerItems
                    key={`modi-${index}`}
                    index={index}
                    personId={this.props.activeUser.personId}
                    data={item}
                    drawerDisplayMode={this.drawerDisplayMode}
                    onClick={() => this.props.setCurrentItem(item)}
                  />
                ))
              ) : (
                <h4 style={{ color: '#ff0000', marginLeft: 10 }}>
                  No Media Found..
                </h4>
              )}
            </div>
          </div>
        )}

        {this.props.isFetchingMedia ? (
          <div className={styles.mediaHolder}>
            {/* Mode == EDIT */}

            {this.props.drawerStatus === 'ADD' && (
              <MediaAddMode
                areaType={this.state.areaType}
                objectId={this.state.primaryId}
                onClickCancel={() => this.props.setDrawerState('LIST')}
              />
            )}

            {/* Mode == VIEW Then need to display pagination */}
            {(this.props.drawerStatus === 'VIEW' ||
              this.props.drawerStatus === 'EDIT') && (
              <React.Fragment>
                {this.props.mediaCollection.length > 1 && (
                  <Pagination
                    className={styles.customPagination}
                    limit={1}
                    reduced={true}
                    offset={this.props.currentMediaFileIndex}
                    total={this.props.mediaCollection.length}
                    onClick={(_, offset) => {
                      this.props.setItemByIndex(offset);
                    }}
                  />
                )}
                <MediaEditMode
                  primaryId={this.state.primaryId}
                  personId={this.props.activeUser.personId}
                  currentMediaFile={this.props.currentMediaFile}
                  dialogMode={this.dialogMode}
                  viewMode={this.props.drawerStatus}
                  onSubmitUpdate={this.onSubmitMediaObjectUpdate}
                  onClickCancel={() => this.props.setDrawerState('VIEW')}
                  onClickEdit={() => this.props.setDrawerState('EDIT')}
                  isSubmitting={this.state.isSubmitting}
                />
              </React.Fragment>
            )}

            {/* Media custom dialog component will be appear when state of modal updated to open*/}
            {this.state.setOpen && (
              <MediaCustomDialog
                primaryId={this.state.primaryId}
                areaType={this.state.areaType}
                drawerDisplayMode={this.drawerDisplayMode}
                currentMediaFile={this.props.currentMediaFile}
                fileURL={this.state.fileURL}
                dialogType={this.state.dialogType}
                dialogMode={this.dialogMode}
              />
            )}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );

    return (
      <div onClick={e => e.stopPropagation()}>
        <Tooltip title="View Attachments">
          <div>
            <PhotosIcon
              className={
                this.props.class ? this.props.class : styles.iconsStyle
              }
              style={this.props.style ? this.props.style : {}}
              onClick={e => {
                e.stopPropagation();
                this.toggleDrawer(e, true);
              }}
            />
          </div>
        </Tooltip>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={e => this.toggleDrawer(e, false)}
        >
          {sideList('right')}
        </Drawer>
      </div>
    );
  }
}

const getMediaObjectsForRecordByAppArea = (state, objectId, appArea) => {
  switch (appArea.toUpperCase()) {
    case ALLERGY:
      return state.allergies.list[objectId]
        ? state.allergies.list[objectId].mediaObjects
        : null;
    case MVS:
      return state.member.medication.list[objectId]
        ? state.member.medication.list[objectId].mediaObjects
        : null;
    case IMMUNIZATION:
      return state.member.userImmunizations.list[objectId]
        ? state.member.userImmunizations.list[objectId].mediaObjects
        : null;
    case JOURNAL:
      return state.journalEntries.list[objectId]
        ? state.journalEntries.list[objectId].mediaObjects
        : null;
    case CONDITIONS:
      return state.conditions.list[objectId]
        ? state.conditions.list[objectId].mediaObjects
        : null;
    case SURGICALHISTORY:
      return state.surgicalHistory.list[objectId]
        ? state.surgicalHistory.list[objectId].mediaObjects
        : null;
    default:
      throw Error(`Unsupported app area ${appArea}`);
  }
};

const mapStateToProps = (state, props) => ({
  mediaCollection: state.mediaObjectDrawer.mediaCollection,
  currentMediaFile: state.mediaObjectDrawer.currentMediaFile,
  currentMediaFileIndex: state.mediaObjectDrawer.currentMediaFileIndex,
  drawerStatus: state.mediaObjectDrawer.drawerStatus,
  headerStatus: state.mediaObjectDrawer.headerStatus,
  isFetchingMedia: state.mediaObjectDrawer.isFetchingMedia,
  isMediaUpdate: state.mediaObjectDrawer.isMediaUpdate,
  activeUser: state.active.user,
  allergies: state.allergies,
  recordMediaObjects: getMediaObjectsForRecordByAppArea(
    state,
    props.primaryId,
    props.areaType
  ),
});

const mapDispatchToProps = dispatch => ({
  getMedia: (pid, aid, area) => dispatch(getMedia(pid, aid, area)),
  setMedia: bundle => dispatch(setMedia(bundle)),
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  setItemByIndex: index => dispatch(setItemByIndex(index)),
  setHeaderState: state => dispatch(setHeaderState(state)),
  setDrawerState: state => dispatch(setDrawerState(state)),
  setCurrentItem: obj => dispatch(setCurrentItem(obj)),
  allergySelectId: aid => dispatch(allergySelectId(aid)),
  mvsSetIdToUpdate: id => dispatch(mvsSetIdToUpdate(id)),
  clearFiles: () => dispatch(clearFiles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaObjectDrawer);
