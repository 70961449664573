import React, { Component } from 'react';
import { shorten } from '../../../library/helpers/format';
import styles from './File.css';
import wordicon from '../../../client/images/word-icon.png';
import pdficon from '../../../client/images/pdf-icon.png';
import excelicon from '../../../client/images/excel-icon.png';
import AddCircleoutlineIcon from '@material-ui/icons/AddCircleOutline';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';
import AddInfo from './FileAddInfo';
import Image from '../../components/Image';
import Video from '../../components/Video';
import { getBestTargetImageFormat } from '../../components/MediaObject/helpers';

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: this.props.index,
    };
  }

  componentDidMount() {
    const { bundle } = this.props;
    this.setState({
      description: bundle.description,
      time: bundle.time,
      date: bundle.date,
    });
  }

  // function to open media file information form to fill up
  addInfoButtonClick = currentIndex => {
    this.props.changeCurrentIndex(currentIndex);
  };

  // handle the change event of form control
  handleDescriptionChange = event => {
    //event.stopPropagation();
    this.props.descriptionChanged(this.props.index, event.target.value);
    this.setState({ description: event.target.value });
  };

  // handle the change event of form control
  handleDateChange = event => {
    //event.stopPropagation();
    this.props.dateChanged(this.props.index, event.target.value);
    this.setState({ date: event.target.value });
  };

  // handle the change event of form control
  handleTimeChange = event => {
    //event.stopPropagation();
    this.props.timeChanged(this.props.index, event.target.value);
    this.setState({ time: event.target.value });
  };

  // calculation uploading progress of media which is in upload process
  renderProgressBar = (current, max) => {
    const calculation = Math.round((current / max) * 100);
    return (
      <div>
        <div className={styles.progressBar}>
          <div
            style={{ width: `${calculation}%` }}
            className={styles.progressData}
          ></div>
        </div>
        <div className={styles.info}>
          {calculation === 100 ? 'Upload Complete!' : `${calculation}%`}
        </div>
      </div>
    );
  };

  render() {
    const {
      bundle,
      uploadingProcess,
      removeObject,
      descriptionChanged,
      dateChanged,
      timeChanged,
      isSubmitting,
      personId,
      mode,
      isDetailCollapseDisabled = false,
      ...rest
    } = this.props;

    const uploadProgressOption = false;

    // separate media file extension file wise and based on extension component will be render.
    let icon;
    const extensionType = bundle.fileName.split('.');
    if (extensionType[1] === 'pdf') {
      icon = <img src={pdficon} alt="PDF File" />;
    } else if (extensionType[1] === 'docx' || extensionType[1] === 'doc') {
      icon = <img src={wordicon} alt="Word File" />;
    } else if (extensionType[1] === 'xls' || extensionType[1] === 'xlsx') {
      icon = <img src={excelicon} alt="Excel File" />;
    } else if (
      extensionType[1] === 'jpeg' ||
      extensionType[1] === 'jpg' ||
      extensionType[1] === 'png'
    ) {
      icon = (
        <div className={styles.fileImageBlock}>
          <div className={styles.responsive}>
            <Image
              personId={this.props.personId}
              src={bundle.links[getBestTargetImageFormat(bundle.formats, 'SM')]}
              placeholder={bundle.base64DataUriString}
              height="100%"
              alt="Media Image"
              className={styles.imgCover}
            />
          </div>
        </div>
      );
    } else {
      icon = (
        <div className={styles.fileImageBlock}>
          <div className={styles.responsive}>
            <Video
              personId={personId}
              src={bundle.self}
              controls={false}
              height="100%"
              cover={true}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className={styles.block}
        onClick={event => {
          event.stopPropagation();
        }}
        {...rest}
      >
        <div>
          <div className={styles.preview}>
            {bundle.isUploaded && (
              <div className={styles.overlay}>
                <i className="fa fa-check-square fa-3x"></i>
              </div>
            )}
            {icon}
          </div>
          <div className={styles.description}>
            <div className={styles.name}>
              {shorten(bundle.fileName, 20)}{' '}
              {!uploadProgressOption && !isSubmitting && (
                <div
                  className={`${styles.incBtn} ${styles.incBtnClose}`}
                  onClick={removeObject}
                >
                  <DeleteIcon className={styles.deleteIcon} />
                </div>
              )}
            </div>

            {!isDetailCollapseDisabled && !isSubmitting && (
              <div>
                {this.props.currentIndex !== this.state.currentIndex && (
                  <div
                    className={styles.addInfoBtn}
                    onClick={() => this.addInfoButtonClick(this.props.index)}
                  >
                    <AddCircleoutlineIcon /> Details
                  </div>
                )}

                {this.props.currentIndex === this.state.currentIndex && (
                  <div
                    className={styles.addInfoBtn}
                    onClick={() => this.addInfoButtonClick('')}
                  >
                    <CollapseIcon /> Details
                  </div>
                )}

                {this.props.currentIndex === this.state.currentIndex && (
                  <AddInfo
                    index={this.props.index}
                    description={this.state.description}
                    date={this.state.date}
                    time={this.state.time}
                    name={bundle.fileName
                      .substring(0, 4)
                      .split(' ')
                      .join('')}
                    handleDescriptionChange={this.handleDescriptionChange}
                    handleDateChange={this.handleDateChange}
                    handleTimeChange={this.handleTimeChange}
                    addInfoButtonClick={() => this.addInfoButtonClick('')}
                  />
                )}
              </div>
            )}
          </div>
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

export default File;
