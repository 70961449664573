import {
  MEDIA_OBJECT_UPLOAD_REQUEST,
  // MEDIA_OBJECT_UPLOAD_SUCCESS,
  // MEDIA_OBJECT_UPLOAD_FAILURE,
  MEDIA_OBJECT_ALL_DONE,
  MEDIA_OBJECT_SET_FILES,
  MEDIA_OBJECT_REMOVE_FILE,
  // MEDIA_OBJECT_CLEAR_FILES,
  MEDIA_OBJECT_SET_DESCRIPTION,
  MEDIA_OBJECT_UPDATE_PROGRESS,
  MEDIA_OBJECT_UPLOAD_COMPLETE,
  MEDIA_OBJECT_UPLOADING_FILE,
  MEDIA_OBJECT_CLEAR_FILES,
  MEDIA_OBJECT_SET_META,
  MEDIA_OBJECT_SET_DATE,
  MEDIA_OBJECT_SET_TIME,
} from './actions';

import { IMMUNIZATION_REMOVE_MEDIA } from '../immunizations/actions';

import { LABS_REMOVE_MEDIA } from '../labs/actions';
import { ALLERGY_REMOVE_MEDIA } from '../allergies/actions';
import { CONDITIONS_REMOVE_MEDIA } from '../conditions/actions';
import { SURGERIES_REMOVE_MEDIA } from '../surgicalHistory/actions';
import { DOCUMENTS_DRAWER_TOGGLE } from '../documents/actions';
import { MVS_REMOVE_MEDIA } from '../mvs/actions';
import { DRAWER_STATUS_CLOSED } from '../mediaObjectDrawer/actions';

const initialState = {
  bundles: [],
  bundleToAttach: [],
  isUploadingFiles: false,
};

// 1. Get every item of the bundles array up to the index
// 2. Insert the new item into the "middle" of the array.
// 3. Get every item of the bundles array, except for the replacement index
const replaceIndex = (array, index, item) => [
  ...array.slice(0, index), // 1
  item, // 2
  ...array.slice(index + 1), // 3
];

const findIndexByName = (bundles, name) =>
  bundles.findIndex(bundle => bundle.file.name === name);

const reducer = (state = initialState, action) => {
  let newBundles, newBundle, index;

  switch (action.type) {
    case DOCUMENTS_DRAWER_TOGGLE:
      return !action.payload
        ? {
            ...initialState,
          }
        : state;
    case MEDIA_OBJECT_SET_FILES:
      return {
        isUploadingFiles: false,
        bundles: action.payload,
      };

    case CONDITIONS_REMOVE_MEDIA:
    case SURGERIES_REMOVE_MEDIA:
    case LABS_REMOVE_MEDIA:
    case ALLERGY_REMOVE_MEDIA:
    case IMMUNIZATION_REMOVE_MEDIA:
    case MEDIA_OBJECT_REMOVE_FILE:
    case MVS_REMOVE_MEDIA:
      newBundles = state.bundles.filter((x, i) => i !== action.payload);
      return {
        ...state,
        bundles: newBundles,
      };

    case MEDIA_OBJECT_SET_META:
      newBundle = {
        ...state.bundles[action.payload.index],
        ...action.payload.meta,
      };

      return {
        ...state,
        bundles: replaceIndex(state.bundles, action.payload.index, newBundle),
      };

    case MEDIA_OBJECT_SET_DESCRIPTION:
      newBundle = {
        ...state.bundles[action.payload.index],
        description: action.payload.description,
      };

      return {
        ...state,
        bundles: replaceIndex(state.bundles, action.payload.index, newBundle),
      };

    case MEDIA_OBJECT_SET_DATE:
      newBundle = {
        ...state.bundles[action.payload.index],
        date: action.payload.date,
      };

      return {
        ...state,
        bundles: replaceIndex(state.bundles, action.payload.index, newBundle),
      };

    case MEDIA_OBJECT_SET_TIME:
      newBundle = {
        ...state.bundles[action.payload.index],
        time: action.payload.time,
      };

      return {
        ...state,
        bundles: replaceIndex(state.bundles, action.payload.index, newBundle),
      };

    case MEDIA_OBJECT_UPLOADING_FILE:
      newBundle = {
        ...state.bundles[action.payload],
        isUploading: true,
        isUploaded: false,
      };

      return {
        ...state,
        bundles: replaceIndex(state.bundles, action.payload, newBundle),
      };

    case MEDIA_OBJECT_UPDATE_PROGRESS:
      // This action will be called asynchronously which will result in a high probability
      // that the same bundle will be modified at the same time. To avoid this we'll make
      // sure we're only modifying the state when bundle isn't locked (missing from the array).
      // Get the index of a bundle located by the file name. If the index is > -1 the item isn't locked.
      index = findIndexByName(state.bundles, action.payload.name);

      // If the index is -1 the file cannot be found ( the bundle is logically "locked").
      // Return an unmodified state if this is the case
      if (index === -1) {
        return state;
      }

      newBundle = {
        ...state.bundles[index],
        loaded: action.payload.loaded,
        total: action.payload.total,
      };

      // Replace bundle with new bundle that has the updated upload progress details.
      newBundles = replaceIndex(state.bundles, index, newBundle);

      return {
        ...state,
        isUploadingFiles: true,
        bundles: newBundles,
      };

    case MEDIA_OBJECT_UPLOAD_COMPLETE: {
      newBundle = {
        ...state.bundles[action.payload.index],
        loaded: state.bundles[action.payload.index].total,
        isUploading: false,
        isUploaded: true,
      };

      return {
        ...state,
        isUploadingFiles: false,
        bundles: replaceIndex(state.bundles, action.payload.index, newBundle),
        bundleToAttach: [...(state.bundleToAttach || []), action.payload.data],
      };
    }

    case MEDIA_OBJECT_UPLOAD_REQUEST:
      return {
        ...state,
        bundles: action.payload,
        isUploadingFiles: true,
      };

    case MEDIA_OBJECT_ALL_DONE:
      return {
        ...state,
        isUploadingFiles: false,
      };

    case MEDIA_OBJECT_CLEAR_FILES: {
      if (!action.payload) {
        return {
          ...state,
          bundles: [],
          bundleToAttach: [],
        };
      }
      const bundles = state.bundles.filter(x => x.appArea !== action.payload);
      return {
        ...state,
        bundles: [...bundles],
        bundleToAttach: [],
      };
    }

    case DRAWER_STATUS_CLOSED: {
      return {
        ...state,
        bundles: [],
        bundleToAttach: [],
      };
    }

    default:
      return state;
  }
};

export default reducer;
