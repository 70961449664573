import MedicationButton from '../../buttons/MedicationButton';
import { defaultDataNormalizer } from '../utils';

const dataFields = {
  name: '',
  dosage: '',
  unit: '',
  deliveryMethod: '',
  deliveryForm: '',
  rxUserAction: 'Not Prescribed',
  relatedConditions: '',
  sideEffects: '',
};

const normalizeData = defaultDataNormalizer;

const properties = {
  label: 'Medications',
  color: '#ED5158',
  icon: MedicationButton,
  dataFields,
  normalizeData,
};

export { dataFields, properties };
