import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography } from '@material-ui/core';
import { destroy } from 'redux-form';
import MediaObject from '../MediaObject/MediaObject';
import '../MediaObject/File.css';
import { PreviewMedia, PreviewBundle } from '../../components/Preview';
import {
  attachmentTypes,
  getId,
  getFormType,
  isDocument,
} from './attachmentService';
import {
  deleteMedia,
  setToDelete,
  cancelDelete,
} from '../../../store/reducers/mediaObject/actions';

const AttachmentsCompose = props => {
  const {
    classes,
    isSubmitting,
    appArea,
    mediaObjects,
    style,
    mediaObjectIds = [],
    modalState,
    isDetailCollapseDisabled = false,
    removeFile,
    bundles,
    destroy,
    deleteExistingMedia,
    bundlesToDelete = [],
    cancelDelete,
    disableDropDown = true,
    ...rest
  } = props;
  const [currentIndex, setCurrentIndex] = React.useState('');
  const handleRemoveMedia = index => {
    destroy(index);
    removeFile(index);
  };

  return (
    <div className={classes.root} style={{ ...style }}>
      <MediaObject
        mode="modal"
        appArea={appArea}
        isSubmitting={isSubmitting}
        disabledMap={true}
      />
      {mediaObjects &&
        mediaObjects.map((object, index) => {
          return (
            <PreviewMedia
              disableDropDown={disableDropDown}
              type={getFormType[appArea]}
              bundle={object}
              personId={props.personId}
              id={getId(object, appArea)}
              index={index}
              isSubmitting={isSubmitting}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              removeObject={deleteExistingMedia}
              removeAllCopies={deleteExistingMedia}
              id={object.id}
              cancelDelete={cancelDelete}
              isDeleting={bundlesToDelete.find(
                bundle => bundle.mediaObjectId === object.mediaObjectId
              )}
              isDocument={isDocument(appArea)}
            />
          );
        })}
      {bundles &&
        bundles.map((object, index) => {
          return (
            <PreviewBundle
              bundle={object}
              type={getFormType[appArea]}
              personId={props.personId}
              index={index}
              isSubmitting={isSubmitting}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              removeObject={handleRemoveMedia}
              isDocument={isDocument(appArea)}
            />
          );
        })}
    </div>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
  },
});

const mapStateToProps = state => ({
  bundles: state.mediaObject.bundles,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const actionTypes = attachmentTypes[ownProps.appArea];
  return {
    removeFile: index => dispatch(deleteMedia(index, actionTypes.deleteMedia)),
    deleteExistingMedia: (mediaObjectId, id) =>
      dispatch(setToDelete(mediaObjectId, id, actionTypes.setToDelete)),
    destroy: index =>
      dispatch(
        destroy(
          isDocument(ownProps.appArea)
            ? `addInfoForm.bundle${index}`
            : `addInfo.bundle${index}`
        )
      ),
    cancelDelete: mediaObjectId =>
      dispatch(cancelDelete(mediaObjectId, actionTypes.cancelDelete)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AttachmentsCompose));
