import React, { Component } from 'react';
import { Grid, Button, Icon } from '@material-ui/core';
import { Table, Column, AutoSizer } from 'react-virtualized';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  clearEmergencyContactToUpdate,
  fetchEmergencyContacts,
  getEmergencyContactToUpdate,
  deleteEmergencyContact,
} from './actions';

// eslint-disable-next-line

import {
  cancelFormReset,
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';

import { EMPTY, SUCCESS } from '../../../store/state';

import BasePage, { HEALTH_SERVICES } from '../BasePage';
import PageHeader from '../../components/PageHeader';
import 'react-virtualized/styles.css';
import formStyles from '../FormStyles.css';
import styles from '../Table.css';

const sort = sortBy => {
  return (a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] === b[sortBy]) {
      return 0;
    }
  };
};

class EmergencyContactPage extends Component {
  state = {
    personId: 0,
    personHasChanged: false,
  };

  componentDidMount() {
    let {
      didInvalidate,
      fetchEmergencyContacts,
      listIds,
      active,
      isFormCanceled,
      cancelFormReset,
    } = this.props;

    if (didInvalidate || (listIds.length === 0 && !isFormCanceled)) {
      fetchEmergencyContacts(active.user.personId);
    }

    if (isFormCanceled) {
      cancelFormReset();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.personId !== prevState.personId) {
      return {
        personId: nextProps.personId,
        personHasChanged: true,
      };
    }

    return null;
  }

  // Dependent on getDerivedStateFromProps
  componentDidUpdate(prevProps) {
    if (
      prevProps.personId !== this.state.personId ||
      this.state.personHasChanged
    ) {
      this.props.fetchEmergencyContacts(this.state.personId);
      this.setState({ personHasChanged: false });
    }
  }

  createRecord = () => {
    const { history, uiStateEntityCreate, clearEdit } = this.props;
    const route = this.props.navigation.dashboard.account;

    uiStateEntityCreate('EMERGENCY_CONTACT');
    clearEdit();
    history.push(route.emergencyContact.create);
  };

  updateContact = (e, contact) => {
    const {
      history,
      uiStateEntityUpdate,
      getEmergencyContactToUpdate,
    } = this.props;
    const route = this.props.navigation.dashboard.account;

    uiStateEntityUpdate('EMERGENCY_CONTACT');
    getEmergencyContactToUpdate(contact);
    history.push(route.emergencyContact.update);
  };

  deleteContact = (e, contact) => {
    const { deleteEmergencyContact } = this.props;
    deleteEmergencyContact(contact);
  };

  render() {
    let { listIds, emergencyContactsList, uiState } = this.props;
    const { dashboard } = this.props.navigation;

    return (
      <BasePage
        type={HEALTH_SERVICES}
        state={uiState}
        emptyStateComponentProps={{
          name: 'Emergency Contact',
          type: 'Emergency Contacts',
          onClick: this.createRecord,
        }}
      >
        <Grid item xs={12}>
          <PageHeader
            headerText="Emergency Contacts"
            subHeaderText="View Records"
            backButtonText="Go Back"
            backLink={dashboard.account.self}
            rightSlot={
              uiState === SUCCESS ? (
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Add"
                  onClick={this.createRecord}
                  style={{ float: 'right' }}
                >
                  <Icon>add</Icon>
                  New Record
                </Button>
              ) : (
                ''
              )
            }
          />
          <EmergencyContactTable
            listIds={listIds}
            emergencyContactsList={emergencyContactsList}
            updateContact={this.updateContact}
            deleteContact={this.deleteContact}
          />
        </Grid>
      </BasePage>
    );
  }
}

class EmergencyContactTable extends Component {
  _sortTableData = ({ sortBy, sortDirection }) => {
    this.setState({ sortBy, sortDirection });
  };

  _renderColumn({ label, name, width }) {
    return (
      <Column key={name} label={label} dataKey={name} width={width || 100} />
    );
  }

  _rowClassName({ index }) {
    if (index < 0) {
      return styles.headerRow;
    }

    return index % 2 === 0 ? styles.evenRow : styles.oddRow;
  }

  _getDatum(list, index) {
    return list[index];
  }

  render() {
    const AutoSizerWrapper = {
      flex: '1 1 auto',
    };

    let { listIds = [], emergencyContactsList = {}, headerHeight } = this.props;
    const rowGetter = ({ index }) =>
      this._getDatum(emergencyContactsList, listIds[index]);

    // const { sortBy, sortDirection } = this.state;

    // //list = list.sort(sort(sortBy));
    // if (sortDirection === SortDirection.DESC) {
    //     list = list.reverse();
    // }

    /* padding-top 1px is used to ensure that resizing works both
     * by expansion and contraction. This issue is believed to exists
     * because a flex-box layout is used with React Virtualized
     */
    return (
      <div style={{ flex: '1 1 auto', height: '100%', paddingTop: '1px' }}>
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <Table
                headerClassName={styles.headerColumn}
                width={width}
                height={500}
                headerHeight={50}
                rowClassName={this._rowClassName}
                rowHeight={60}
                rowCount={listIds.length}
                rowGetter={rowGetter}
                // sort={this._sortTableData}
                // sortBy={sortBy}
                // sortDirection={sortDirection}
                // onRowClick={this.handleHover}
                // onRowMouseOver={this.handleHover}
              >
                <Column
                  width={150}
                  dataKey="contactRelationship"
                  label="Relationship"
                />
                <Column width={150} dataKey="firstName" label="First Name" />
                <Column width={150} dataKey="lastName" label="Last Name" />
                <Column
                  width={300}
                  dataKey="hasMedicalPoa"
                  label="Medical Power of Attorney"
                  cellRenderer={row => {
                    return (
                      <span>{row.rowData.hasMedicalPOA ? 'Yes' : 'No'}</span>
                    );
                  }}
                />
                <Column
                  width={150}
                  flexGrow={1}
                  label=""
                  dataKey="emergencyContactId"
                  cellRenderer={row => {
                    return (
                      <div className="pull-right">
                        <button
                          type="button"
                          className="btn btn-default btn-sm"
                          onClick={e =>
                            this.props.updateContact(e, row.rowData)
                          }
                        >
                          <i className="fa fa-edit" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-default btn-sm"
                          onClick={e =>
                            this.props.deleteContact(e, row.rowData)
                          }
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                    );
                  }}
                />
              </Table>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  active: state.active,
  didInvalidate: state.member.emergencyInfo.didInvalidate,
  listIds: state.member.emergencyInfo.listIds,
  emergencyContactsList: state.member.emergencyInfo.emergencyContactsList,
  personId: state.active.user.personId,
  isFormCanceled: state.ui.isFormCanceled,
  uiState: state.member.emergencyInfo.uiState,
});

const mapDispatchToProps = dispatch => ({
  fetchEmergencyContacts: id => dispatch(fetchEmergencyContacts(id)),
  clearEdit: () => dispatch(clearEmergencyContactToUpdate()),
  getEmergencyContactToUpdate: contact =>
    dispatch(getEmergencyContactToUpdate(contact)),
  deleteEmergencyContact: contact => dispatch(deleteEmergencyContact(contact)),
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(EmergencyContactPage)),
};
