import OrdinalFrame from '../../../OrdinalFrame/OrdinalFrame';
import React from 'react';
import { pieDataDefault } from './useNetWaterIntake';
import { theme } from './';

const WaterChartLegend = () => {
  const frameProps = {
    data: pieDataDefault,
    size: [100, 320],
    margin: 5,
    type: 'bar',
    projection: 'horizontal',
    rAccessor: d => {
      return 40;
    },
    oAccessor: 'type',
    oPadding: 7,
    style: d => {
      return {
        fill: theme[d.data.type].background,
        rx: '2',
        ry: '2',
        height: '43px',
      };
    },
    oLabel: {
      label: true,
      orient: 'center',
    },
    labelStyle: (d, i) => {
      return {
        fill: theme[d].color,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: '16px',
      };
    },
    customClickBehavior: () => {},
    customDoubleClickBehavior: () => {},
  };
  return <OrdinalFrame {...frameProps} />;
};

export default WaterChartLegend;
