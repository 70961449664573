import React, { Component } from 'react';
import { Field, reduxForm, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  states,
  phoneTypes,
  relationshipTypes,
} from '../../../library/static/data';
import { mask } from '../../../library/helpers/format';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import validate from './ValidateForm';
import InputField from '../../../library/forms/InputField';
import CheckboxField from '../../../library/forms/CheckboxField';
import SelectField from '../../../library/forms/SelectField';
import PhoneAndTypeField from '../FormFields/PhoneAndTypeField';

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

class EmergencyContactForm extends Component {
  render() {
    const {
      handleSubmit,
      onCancel,
      action,
      phone1NumberDirty,
      phone2NumberDirty,
    } = this.props;
    const entityAction = action === 'CREATE' ? 'Create' : 'Update';

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Field
              name="hasMedicalPOA"
              label="This person is my Medical Power of Attorney"
              component={CheckboxField}
              type="checkbox"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="contactRelationship"
              label="Relationship"
              labelKey="label"
              valueKey="value"
              placeholder="Select Relationship"
              component={SelectField}
              options={relationshipTypes}
              isSearchable
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="firstName"
              label="First Name *"
              hint="Required"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="lastName"
              label="Last Name *"
              hint="Required"
              component={InputField}
            />
          </Grid>

          <PhoneAndTypeField
            phoneFieldName="phone1Number"
            phoneLabel="Primary Phone Number"
            typeFieldName="phone1Type"
            typeLabel="Phone Type"
            phoneTypes={phoneTypes}
            typeRequired={phone1NumberDirty}
          />

          <PhoneAndTypeField
            phoneFieldName="phone2Number"
            phoneLabel="Secondary Phone Number"
            typeFieldName="phone2Type"
            typeLabel="Phone Type"
            phoneTypes={phoneTypes}
            typeRequired={phone2NumberDirty}
          />

          <Grid item xs={12} lg={6}>
            <Field
              type="text"
              name="street"
              label="Street Address Line 1"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              type="text"
              name="street2"
              label="Street Address Line 2"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="city"
              label="City"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="state"
              label="State"
              labelKey="longName"
              valueKey="shortName"
              placeholder="Select State"
              component={SelectField}
              options={this.props.stateTypes}
              isLoading={this.props.isFetchingStateType}
              isSearchable
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="postalCode"
              label="Postal Code"
              placeholder="xxxxx or xxxxx-xxxx"
              inputProps={{ maxLength: 10 }}
              component={InputField}
              format={value => mask(value, 'XXXXX-XXXX')}
              normalize={postalCodeNormalizer}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="email"
              label="Email Address"
              placeholder="example@website.com"
              component={InputField}
            />
          </Grid>
        </Grid>

        <Grid container justify="flex-end" spacing={3}>
          <Grid item xs={6} sm={2}>
            <Button
              type="button"
              fullWidth={true}
              onClick={onCancel}
              color="default"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              color="primary"
              size="large"
            >
              {entityAction} Record
            </Button>
          </Grid>
        </Grid>

        {/* <div className="col-md-12 bottom-space" >
          <button type="submit" className="btn btn-primary pull-right">{entityAction} Record</button>
          <button type="button" onClick={ onCancel } className="btn btn-cancel pull-right" >Cancel</button>
        </div> */}
      </form>
    );
  }
}

const formName = 'emergency-contact-form';
EmergencyContactForm = reduxForm({
  form: formName,
  validate,
})(withRouter(EmergencyContactForm));

export default connect(state => {
  const { contactToEdit = {} } = state.member.emergencyInfo;

  return {
    action: state.ui.entity.action,
    phone1NumberDirty: isDirty(formName)(state, ['phone1Number']),
    phone2NumberDirty: isDirty(formName)(state, ['phone2Number']),
    stateTypes: state.codes.stateTypes,
    isFetchingStateType: state.codes.isFetchingStateTypes,
    initialValues: {
      emergencyContactId: contactToEdit.emergencyContactId || null,
      personId: contactToEdit.personId || null,
      contactRelationship: contactToEdit.contactRelationship || '',
      firstName: contactToEdit.firstName || '',
      lastName: contactToEdit.lastName || '',
      phone1Number: contactToEdit.phone1Number || '',
      phone1Type: contactToEdit.phone1Type || '',
      phone2Number: contactToEdit.phone2Number || '',
      phone2Type: contactToEdit.phone2Type || '',
      street: contactToEdit.street || '',
      street2: contactToEdit.street2 || '',
      city: contactToEdit.city || '',
      state: contactToEdit.state || '',
      postalCode: contactToEdit.postalCode || '',
      email: contactToEdit.email || '',
      hasMedicalPOA: contactToEdit.hasMedicalPOA ? true : false,
    },
  };
})(EmergencyContactForm);
