import React from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SelectField from '../../../../../library/forms/SelectField';
import InputField from '../../../../../library/forms/InputField';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import { maxLength } from '../../../../components/JournalCompose/fragments/Validations';

const styles = theme => ({
  field: {
    padding: '0px 32px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
  },
});

const arrayToOptions = arr => {
  const obj = [];
  arr.forEach(item =>
    obj.push({
      shortName: item,
      longName: item,
    })
  );
  return obj;
};
export const required = value => (value ? undefined : 'Required');
const maxLength1000 = maxLength(1000);

const NominationForm = props => {
  const { classes } = props;
  return (
    <Grid container style={{ margin: '0px', width: '100%' }}>
      <Grid className={classes.title} item xs={12}>
        <HowToVoteIcon />
        <Typography>&nbsp;&nbsp;Nominate your favorite resource</Typography>
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <Field
          name="type"
          label="Resource Type*"
          placeholder="Pick a resource type"
          component={SelectField}
          labelKey="longName"
          valueKey="shortName"
          validate={required}
          hint="required"
          options={arrayToOptions(props.categoryList)}
        />
      </Grid>

      <Grid className={classes.field} item xs={12}>
        <Field
          name="name"
          label="Resource Name*"
          placeholder="What resource are you nominating?"
          validate={[required, maxLength1000]}
          hint="required"
          component={InputField}
        />
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <Field
          name="url"
          label="Resource Website"
          placeholder="How do we find it?"
          component={InputField}
          validate={maxLength1000}
        />
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <Field
          name="contactDetail"
          label="Resource Phone or email"
          placeholder="How can they be reached?"
          component={InputField}
          validate={maxLength1000}
        />
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <Field
          name="price"
          label="Resource Price"
          placeholder="How much does it cost?"
          // type="number"
          component={InputField}
          validate={maxLength1000}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">$</InputAdornment>,
          // }}
          shrink={true}
        />
      </Grid>
      <Grid className={classes.field} item xs={12}>
        <Field
          name="description"
          label="Resource Description"
          validate={[required, maxLength1000]}
          placeholder="Why do you recommend this resource? How has it helped you?"
          hint="required"
          component={InputField}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(NominationForm);
