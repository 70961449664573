import React, { Component, useEffect, useState, useRef } from 'react';
import { WordIcon, PdfIcon, OtherIcon } from './MediaObjectSvgIcons';
import { getBestTargetImageFormat } from '../../MediaObject/helpers';
import { Grid, Typography } from '@material-ui/core';
import Image from '../../Image';
import Video from '../../Video';
import styles from '../styles/MediaObjectDrawer.css';

const noop = () => {};

// React.memo prevents rerender
const ImagePreview = React.memo(({ personId, mediaFile, mediaLink }) => {
  return (
    <div className={styles.mediaImageBlock}>
      <div className={styles.responsive}>
        <Image
          personId={personId}
          placeholder={mediaFile.base64DataUriString}
          src={mediaLink}
          className={styles.imgCover}
          height="100%"
          alt="Media Image"
        />
      </div>
    </div>
  );
});

const VideoPreview = React.memo(({ personId, currentMediaFile }) => {
  return (
    <Video
      controls={true}
      personId={personId}
      src={currentMediaFile.links.self}
      preventReload={true}
      height="330px"
    />
  );
});

const PreviewObject = ({
  personId,
  currentMediaFile,
  mediaType,
  mode,
  dialogMode,
  fileName,
}) => {
  mode = mode.toUpperCase();

  const [mediaFile, setMediaFile] = useState(currentMediaFile);
  const [mediaLink, setMediaLink] = useState(null);
  const [isEditting, setIsEditting] = useState(mode === 'EDIT');

  useEffect(() => {
    if (mediaType === 'IMAGE') {
      const format = getBestTargetImageFormat(currentMediaFile.formats, 'MD', [
        'XS',
      ]);
      setMediaLink(currentMediaFile.links[format]);
    }

    setMediaFile(currentMediaFile);
  }, [currentMediaFile]);

  useEffect(() => {
    setIsEditting(mode === 'EDIT');
  }, [mode]);

  const renderPreview = () => {
    switch (mediaType) {
      case 'IMAGE': {
        return (
          <ImagePreview
            personId={personId}
            mediaFile={mediaFile}
            currentMediaFile={currentMediaFile}
            mediaLink={mediaLink}
          />
        );
      }
      case 'VIDEO': {
        return (
          <VideoPreview
            personId={personId}
            currentMediaFile={currentMediaFile}
          />
        );
      }
      case 'PDF': {
        return (
          <div
            onClick={
              isEditting
                ? noop()
                : () =>
                    dialogMode(
                      true,
                      'iframe',
                      currentMediaFile.fileName,
                      currentMediaFile
                    )
            }
          >
            <div className={styles.iconViewer}>
              <PdfIcon />
            </div>
          </div>
        );
      }
      case 'WORD': {
        return (
          <div className={styles.iconViewer}>
            <WordIcon />
          </div>
        );
      }
      case 'EXCEL': {
        return (
          <div className={styles.iconViewer}>
            <OtherIcon />
          </div>
        );
      }
      default: {
        return (
          <div className={styles.iconViewer}>
            <OtherIcon />
          </div>
        );
      }
    }
  };

  return (
    <Grid
      item
      xs={isEditting || mediaType === 'IMAGE' || mediaType === 'VIDEO' ? 12 : 5}
      className={styles.mBottom}
    >
      {renderPreview()}
      {!isEditting && (
        <Typography
          style={{ fontSize: '14px', marginTop: '11px', color: '#737373' }}
        >
          {currentMediaFile.fileName}
        </Typography>
      )}
    </Grid>
  );
};

export default PreviewObject;
