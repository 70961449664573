import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    padding: 24,
    marginBottom: 32,
    position: 'relative',
    paddingTop: 36,
    zIndex: 2,
  },
  BgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: -1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  Bg: {
    height: '100%',
    objectFit: 'cover',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px',
  },
  checkBoxFields: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: '200px',
    display: 'flex',
    textAlign: 'center',
    fontSize: '14px',
    color: '#535461',
    alignItems: 'flex-start',
  },
  checkBoxText: {
    fontSize: '14px',
    lineHeight: '1',
    height: '24px',
    margin: '8px',
    textAlign: 'start',
    marginTop: '0px',
  },

  //accordion
  root: {
    width: '100%',
  },
  heading: {
    padding: '0px 8px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
  accordion: {
    boxShadow: 'none',
  },
});
