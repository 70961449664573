import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  Info as InfoIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';

const styles = theme => ({
  timeLineIcon: {
    color: '#757575',
  },
  alertIcon: {
    color: '#ED5158',
  },
  generalIcon: {
    color: '#757575',
  },
  infoIcon: {
    color: '#4D8AF0',
  },
  msgIcon: {
    color: '#FFCA28',
  },
  reportIcon: {
    color: '#757575',
  },
});

const NotificationIcon = props => {
  const { type, classes } = props;

  const iconTypes = {
    activity: classes.timeLineIcon,
    alert: classes.alertIcon,
    general: classes.generalIcon,
    message: classes.msgIcon,
    report: classes.reportIcon,
    info: classes.infoIcon,
  };

  const className = iconTypes[type];

  switch (type) {
    case 'alert':
      return <WarningIcon className={className} fontSize="inherit" />;
    case 'activity':
      return <TimelineIcon className={className} fontSize="inherit" />;
    case 'info':
      return <InfoIcon className={className} fontSize="inherit" />;
    case 'report':
      return <InsertDriveFileIcon className={className} fontSize="inherit" />;
    case 'message':
      return <EmailIcon className={className} fontSize="inherit" />;
    case 'general':
      return <NotificationsIcon className={className} fontSize="inherit" />;
    default:
      return null;
  }
};

export default withStyles(styles)(NotificationIcon);
