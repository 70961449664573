import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import NotificationBase from './NotificationBase';
import NotificationIcon from './NotificationIcon';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(4),
  },
}));

const NotificationGeneral = ({
  data,
  children,
  onNotificationPressed,
  onNotificationOption,
}) => {
  const classes = useStyles();

  return (
    <NotificationBase
      data={data}
      Icon={
        <Avatar className={classes.iconAvatar}>
          <NotificationIcon className={classes.icon} type="general" />
        </Avatar>
      }
      onNotificationPressed={onNotificationPressed}
      onNotificationOption={onNotificationOption}
      on
    >
      {children}
    </NotificationBase>
  );
};

NotificationGeneral.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default NotificationGeneral;
