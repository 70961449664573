import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';

import InputField from '../../../library/forms/InputField';
import SelectField from '../../../library/forms/SelectField';

import { mask } from '../../../library/helpers/format';

const phoneMask = value => mask(value, 'XXX-XXX-XXXX');

const PhoneFormFields = props => {
  const {
    phoneFieldName,
    phoneTypeName,
    phoneLabel,
    typeLabel,
    required,
    contactTypes,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Field
          name={phoneFieldName}
          label={`${phoneLabel} ${required ? '*' : ''}`}
          type="text"
          hint={required ? 'Required' : ''}
          placeholder="XXX-XXX-XXXX"
          component={InputField}
          format={phoneMask}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={phoneTypeName}
          label={`${typeLabel} ${required ? '*' : ''}`}
          hint={required ? 'Required' : ''}
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={contactTypes}
          isSearchable
          isClearable
        />
      </Grid>
    </Grid>
  );
};

PhoneFormFields.propTypes = {
  phoneLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  phoneFieldName: PropTypes.string.isRequired,
  phoneTypeName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

PhoneFormFields.defaultProps = {
  phoneLabel: 'Phone Number',
  typeLabel: 'Phone Type',
  phoneFieldName: 'phoneNumber',
  phoneTypeName: 'phoneType',
  required: false,
};

export default PhoneFormFields;
