import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ErrorTooltip from '../../Forms/ErrorTooltip';

//returns excess hr value when minute > 60
const getHrsFromMinutes = minute => {
  if (parseInt(minute) > 60) {
    const hrs = Math.floor(parseInt(minute) / 60);
    const min = parseInt(minute) % 60;
    return [hrs, min];
  }
  return [0, parseInt(minute)];
};

function DurationField(props) {
  const [fieldHourValue, setHourValue] = useState(0);
  const [fieldMinuteValue, setFieldMinuteValue] = useState(0);
  const [isLoadingProps, setIsLoadingProps] = useState(true);

  useEffect(() => {
    if (isLoadingProps) {
      const val = getHrsFromMinutes(props.initialValue);
      //prevent initialValues to affect state when component receives props
      setHourValue(val[0]);
      setFieldMinuteValue(val[1]);
      setIsLoadingProps(false);
    }
  }, [props.initialValue]);

  const getTotalValue = (hrs, min) => hrs * 60 + min;
  const handleChange = props.onChange ? props.onChange : props.input.onChange;
  const maxHrs = 23;
  const maxMins = 59;
  const handleChangeField = field => event => {
    const _value = event.target.value ? parseInt(event.target.value) : 0;
    switch (field) {
      case 'hours':
        if (_value > maxHrs) return;
        setHourValue(_value);
        handleChange(getTotalValue(_value, fieldMinuteValue));
        break;

      case 'minutes':
        if (_value > maxMins) return;
        setFieldMinuteValue(_value);
        handleChange(getTotalValue(fieldHourValue, _value));
        break;
    }
  };

  useEffect(() => {
    if (props.controlledInput) return;
    const _value = getTotalValue(fieldHourValue, fieldMinuteValue);
    if (props.input.value !== _value && !isLoadingProps) {
      const hrsMins = getHrsFromMinutes(props.input.value);
      setFieldMinuteValue(hrsMins[1]);
      setHourValue(hrsMins[0]);
    }
  }, [props.input.value]);

  return (
    <OutlinedContainer
      error={props.error}
      label={props.label.length ? props.label : ''}
      onClick={() =>
        props.isDurationCalculated ? props.onClick('totalMinutes') : {}
      }
      InputProps={{
        style: {
          borderRadius: 4,
          border: '1px solid #0068A8',
          backgroundColor: '#EFF1F3',
        },
      }}
    >
      <Grid
        spacing={0}
        style={{ display: 'flex', flexDirection: 'row', marginTop: '2px' }}
        key="3"
      >
        <Grid item xs={6}>
          <Input
            type="number"
            key="2"
            style={{ margin: '0', padding: '0px 9px', height: '18px' }}
            value={fieldHourValue ? fieldHourValue : ''}
            placeholder="0"
            maxLength="2"
            margin="dense"
            InputProps={{
              inputProps: {
                min: 0,
                max: 24,
              },
              ...props.InputProps,
            }}
            inputProps={{
              ...props.inputProps,
            }}
            onFocus={props.input.onFocus}
            onChange={handleChangeField('hours')}
            disabled={props.disabled ? props.disabled : false}
            fullWidth
            endAdornment={
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '10px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                  marginRight: '5px',
                  marginLeft: '-3px',
                }}
              >
                hrs
              </InputAdornment>
            }
            disableUnderline
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="number"
            key="1"
            style={{ margin: '0', padding: '0 9px', height: '18px' }}
            value={fieldMinuteValue ? fieldMinuteValue : ''}
            placeholder="0"
            maxLength="2"
            margin="dense"
            InputProps={{
              inputProps: {
                min: 0,
                max: 59,
              },
              ...props.InputProps,
            }}
            inputProps={{
              ...props.inputProps,
            }}
            onFocus={props.input.onFocus}
            onChange={handleChangeField('minutes')}
            disabled={props.disabled ? props.disabled : false}
            fullWidth
            endAdornment={
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '10px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                  marginRight: '5px',
                  marginLeft: '-3px',
                }}
              >
                min
              </InputAdornment>
            }
            disableUnderline
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  );
}

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedContainer = props => {
  const { children, label, error, InputProps, hint } = props;
  return (
    <TextField
      label={label}
      margin="dense"
      onFocus={props.onClick}
      helperText={!!error ? error : !!hint ? hint : ''}
      error={!!error}
      disabled={props.disabled ? props.disabled : false}
      fullWidth
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...InputProps,
        inputComponent: InputComponent,
        disableUnderline: true,
      }}
      inputProps={{
        children: children,
        style: {
          padding: '3px',
          paddingTop: '5px',
          paddingBottom: '1px',
        },
      }}
      style={{
        marginTop: '8px',
      }}
    />
  );
};

const DurationForm = props => (
  <div style={{ minHeight: '80px', maxWidth: '300px' }}>
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <div>
        <DurationField
          {...props}
          initialValue={props.meta.initial}
          error={props.meta.error && props.meta.touched}
        />
      </div>
    </ErrorTooltip>
  </div>
);

DurationField.defaultProps = {
  input: {
    onFocus: () => {},
  },
  label: '',
};

DurationField.propTypes = {
  initialHourValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  initialMinValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default DurationForm;
