import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import 'react-dates/initialize';

import { getUserInitialState } from '../../../store/reducers/app/actions';
import LoadingAppPage from '../LoadingAppPage';

import { setTimeZone } from '../../../store/reducers/app/actions';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import { sessionExpired } from '../../../store/reducers/app/actions';
import { unblockNavigation } from '../../../store/reducers/ui/actions';

class App extends Component {
  state = {
    appLoaded: false,
  };

  async componentDidMount() {
    const {
      history,
      createMessage,
      sessionExpired,
      unblockNavigation,
    } = this.props;

    if (this.props.login.isAuthenticated === false) {
      this.setState({ appLoaded: true });
    } else {
      await this.props.getUserInitialState();
      if (this.props.app.user !== null) {
        // Handle JWT session expirations
        this.sessionWatcher = setInterval(() => {
          const seconds =
            this.props.login.details.exp - Math.floor(Date.now() / 1000);

          if (seconds <= 0) {
            unblockNavigation();
            createMessage(
              "We apologize for the inconvenience, but your session has expired, and for your protection, we signed you out.  Sign back in when you're ready.",
              'warning'
            );
            sessionExpired();
            localStorage.clear();
            history.push('/');
          }
        }, 1000);

        this.setState({ appLoaded: true });
      }
    }

    // Update timezone
    const timeZoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.props.setTimeZone({ timeZoneIANA });
    console.log('Timezone!', timeZoneIANA);
  }

  componentWillUnmount() {
    clearInterval(this.sessionWatcher);
  }

  render() {
    const { appLoaded } = this.state;
    return appLoaded ? (
      <div className="container-fluid App">
        <Prompt
          when={this.props.ui.isNavigationLocked}
          message={this.props.ui.lockedMessage || ''}
        />

        {this.props.children}
        {renderRoutes(this.props.route.routes)}
      </div>
    ) : (
      <LoadingAppPage />
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  login: state.member.login,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  getUserInitialState: () => dispatch(getUserInitialState()),
  createMessage: (msg, type) => dispatch(createMessage(msg, type)),
  sessionExpired: () => dispatch(sessionExpired()),
  unblockNavigation: () => dispatch(unblockNavigation()),
  setTimeZone: payload => dispatch(setTimeZone(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
