import React from 'react';
import { UpgradeAccount } from '../../components/Reports/ReportModal';
import { Modal } from '@material-ui/core';

const FeatureLockedModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          background: '#fff',
          borderRadius: 6,
          width: '95%',
          maxWidth: 1160,
          position: 'relative',
          outline: 'none',
          maxHeight: '90vh',
          flexWrap: 'nowrap',
          overflow: 'auto',
          margin: '64px auto',
        }}
      >
        <UpgradeAccount
          upgradeMessage="Shareable account Users can automatically sync data with our Device, App and EMR partners.  
Click “Upgrade Account” to upgrade to a Shareable account from your Account Management page."
        />
      </div>
    </Modal>
  );
};

export default FeatureLockedModal;
