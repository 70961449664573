import React from 'react';
import { CircularProgress, Container, Typography } from '@material-ui/core';

const BaseLoader = ({ loadingText = 'Loading' }) => (
  <Container
    maxWidth="md"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '600px',
    }}
  >
    <CircularProgress size={40} thickness={4} />
    <Typography
      style={{
        marginTop: '40px',
        color: 'rgba(0, 0, 0, 0.8)',
        fontWeight: 400,
      }}
    >
      Preparing an easier way to manage your health data...
    </Typography>
  </Container>
);

export default BaseLoader;
