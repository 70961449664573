import React from 'react';
import Button from '@material-ui/core/Button';
import HowToVoteIcon from '@material-ui/icons/HowToVote';

const NominateButton = ({ onClickNominate }) => {
  return (
    <Button
      startIcon={<HowToVoteIcon />}
      onClick={onClickNominate}
      color="primary"
      disableRipple
      disableFocusRipple
      style={{ margin: '8px' }}
    >
      Nominate
    </Button>
  );
};

export default NominateButton;
