import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Question.css';

class Question extends Component {
  render() {
    const { text, value, answerDefault, selected, onChange } = this.props;

    let isSelected = !selected ^ (answerDefault === 'yes');

    const CheckMark = ({ isSelected }) =>
      isSelected ? (
        <React.Fragment>
          <i className="fa fa-check-circle"></i>
        </React.Fragment>
      ) : null;

    return (
      <div className={styles.question}>
        <p className={styles.questionText}>{text}</p>
        <div className={styles.questionButtons}>
          <button
            className={classnames({
              [styles.questionButton]: true,
              [styles.questionButtonSelected]: isSelected,
            })}
            role="button"
            onClick={e => onChange(value)}
          >
            <CheckMark isSelected={isSelected} /> Yes
          </button>
          <button
            className={classnames({
              [styles.questionButton]: true,
              [styles.questionButtonSelected]: !isSelected,
            })}
            role="button"
            onClick={e => onChange(value)}
          >
            <CheckMark isSelected={!isSelected} /> No
          </button>
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  answerDefault: PropTypes.oneOf(['yes', 'no']),
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Question.defaultProps = {
  answerDefault: 'yes',
  selected: false,
};

export default Question;
