import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';

const meta = {
  color: '#2AA5A2',
  icon: 'accessibility',
};

const BodyMassViewer = ({ data }) => {
  const calculateBMI = (weight, height) => {
    if (!weight || !height) {
      return '--';
    }
    const bmi = weight / Math.pow(height, 2);
    return bmi.toFixed(2);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="h6">Weight ({data.weightUnit})</Typography>
        <Typography variant="body1">{data.weight}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Body Fat (%)</Typography>
        <Typography variant="body1">{data.bodyFatPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Water (%)</Typography>
        <Typography variant="body1">{data.waterPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Muscle Mass (%)</Typography>
        <Typography variant="body1">{data.bodyMassPercentage}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">BMI</Typography>
        <Typography variant="body1">
          {calculateBMI(data.bmiWeight, data.bmiHeight)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BodyMassViewer;
