import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const NotificationBaseOptions = ({
  isRead = false,
  onSelectOption,
  onClose,
  ...props
}) => {
  const handleSelectOption = action => _ => {
    onSelectOption(action);
    onClose();
  };

  return (
    <Menu {...props} onClose={onClose}>
      <MenuItem onClick={handleSelectOption('clear')}>
        <ListItemIcon>
          <ClearIcon />
        </ListItemIcon>
        <ListItemText>Clear this notification </ListItemText>
      </MenuItem>
      {isRead ? (
        <MenuItem onClick={handleSelectOption('unread')}>
          <ListItemIcon>
            <VisibilityOffIcon />
          </ListItemIcon>
          <ListItemText>Mark as unread</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleSelectOption('read')}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText>Mark as read</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
};

NotificationBaseOptions.propTypes = {
  onSelectOption: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationBaseOptions;
