import React from 'react';
import { SleepVector01, SleepVector02, SleepVector03 } from './SVG';
import useStyles from '../useStyles';
import { Typography, Box } from '@material-ui/core';
import classNames from 'classnames';

const VectorFields = ({ onSleepClick, totalTimeSlept }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <div
        className={classNames(
          classes.vectorContainer,
          totalTimeSlept === 2 && classes.activeStyle
        )}
        onClick={e => onSleepClick(2)}
      >
        <div className={classes.vectorContainerDiv}>
          <SleepVector01 />
        </div>
        <Typography className={classes.vectorHeader}>2 hrs</Typography>
      </div>
      <div
        className={classNames(
          classes.vectorContainer,
          totalTimeSlept === 6 && classes.activeStyle
        )}
        onClick={e => onSleepClick(6)}
      >
        <div className={classes.vectorContainerDiv}>
          <SleepVector02 />
        </div>
        <Typography className={classes.vectorHeader}>6 hrs</Typography>
      </div>
      <div
        className={classNames(
          classes.vectorContainer,
          totalTimeSlept === 8 && classes.activeStyle
        )}
        onClick={e => onSleepClick(8)}
      >
        <div className={classes.vectorContainerDiv}>
          <SleepVector03 />
        </div>
        <Typography className={classes.vectorHeader}>8 hrs</Typography>
      </div>
    </Box>
  );
};

export default VectorFields;
