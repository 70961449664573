import React from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SelectField from '../../../library/forms/SelectField';

const styles = theme => ({
  field: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
});

const DemographicFormFields = props => {
  const { classes } = props;

  return (
    <Grid container spacing={3}>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="gender"
          label="Gender"
          type="text"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.genderTypes}
          isLoading={props.isFetchingGenderType}
          isSearchable
          isClearable
        />
      </Grid>

      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="bloodType"
          label="Blood Type"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.bloodTypes}
          isLoading={props.isFetchingBloodType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="primaryLanguage"
          label="Primary Language"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.languageTypes}
          isLoading={props.isFetchingLanguageType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="secondaryLanguage"
          label="Secondary Language"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.languageTypes}
          isLoading={props.isFetchingLanguageType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="ethnicity"
          label="Race/Ethnicity"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.ethnicityTypes}
          isLoading={props.isFetchingEthnicityType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="religion"
          label="Religion"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.religionTypes}
          isLoading={props.isFetchingReligionType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="maritalStatus"
          label="Marital Status"
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={props.maritalTypes}
          isLoading={props.isFetchingMaritalType}
          isSearchable
          isClearable
        />
      </Grid>
      <Grid className={classes.field} item xs={12} sm={6}>
        <Field
          name="veteranStatus"
          label="Veteran Status"
          labelKey="shortName"
          valueKey="shortName"
          component={SelectField}
          options={props.veteranTypes}
          isLoading={props.isFetchingVeteranType}
          isSearchable
          isClearable
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DemographicFormFields);
