import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Grid,
  IconButton,
  withStyles,
  Icon,
  Typography,
  Button,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import ReportModalTable from './ReportModalTable';
import ReportSVG from '../../../client/images/report-progress.jpg';
import {
  createJournalReport,
  setActiveDownload,
  downloadReport,
  cleanUpReportsState,
} from '../../../store/reducers/reportsModal/actions';
import {
  GENERAL,
  HEALTH_STATUS,
} from '../../../store/reducers/reportsModal/reducers';
import ReportModalHeader from './ReportModalHeader';
import { getShareableLink } from '../../../store/reducers/healthStatus/actions';
import { withRouter } from 'react-router';
import QRCode from 'qrcode.react';
import AgrinHealthBanner from '../../../client/images/agrinhealth.logo.png';
import { format, addDays } from 'date-fns';
import EasterEgg from '../../../library/components/EasterEggComponents';
const DATE_FORMAT = 'MMM d, yyyy';

const styles = theme => ({
  overlay: {},
  root: {
    background: '#fff',
    borderRadius: 6,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '95%',
    maxWidth: 1160,
    position: 'relative',
    outline: 'none',
    maxHeight: '90vh',
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
    '@media print': {
      display: 'none !important',
    },
  },
  header: {
    margin: 0,
    marginBottom: 4,
    fontWeight: 500,
  },
  subHeader: {
    fontSize: 16,
    color: '#666',
    maxWidth: 850,
  },
  progressContainer: {
    padding: '60px 0',
  },
  progressImg: {
    maxWidth: 250,
    marginBottom: 25,
  },
  progressBar: {
    width: '100%',
    maxWidth: 400,
    marginTop: 20,
    height: 12,
    borderRadius: 12,
  },
  completeContainer: {
    padding: '60px 0',
  },
  completeImg: {
    maxWidth: 200,
    marginBottom: 25,
  },
  link: {
    borderRadius: 4,
    background: '#EFF1F3',
    padding: '12px',
    color: '#444447',
    width: '100%',
    maxWidth: 410,
    marginRight: 8,
    textOverflow: 'ellipsis',
    outline: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    border: 'none',
  },
  warn: {
    color: '#F7A224',
  },
  warnText: {
    fontSize: 16,
    marginLeft: 8,
    color: '#F7A224',
  },
  noPrint: {
    '@media print': {
      display: 'none !important',
    },
  },
  exactImage: {
    '-webkit-print-color-adjust': 'exact !important',
  },
});

export const UpgradeAccount = withRouter(
  withStyles(styles)(props => {
    const {
      classes,
      history,
      onClose,
      upgradeMessage = `Create detailed reports and share them with people that need them.
      Upgrade to the shareable account by contacting support to access this
      feature.`,
    } = props;
    const onClick = () => {
      onClose();
      if (history.location.pathname.includes('account/account-management')) {
        return;
      }
      history.push('account/account-management');
    };
    return (
      <Grid
        className={classes.completeContainer}
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          <img className={classes.completeImg} src={ReportSVG} />
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            Feature Locked
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="body1" style={{ maxWidth: 560 }}>
            {upgradeMessage}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 26 }}
            onClick={onClick}
          >
            Upgrade Account
          </Button>
        </Grid>
      </Grid>
    );
  })
);

const DownloadComplete = withStyles(styles)(({ classes, ...rest }) => {
  const { isSubmittingReport, pid, report, downloadReport } = rest;

  const handleClick = async () => {
    const response = await downloadReport(pid, report);

    const fileURL = URL.createObjectURL(response.payload);

    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('target', '_bank');
    // tempLink.setAttribute('download', report.fileName);

    // Required for Firefox, element must be apart of the document tree
    document.body.appendChild(tempLink);

    // Simulate click on newly created element.
    tempLink.click();

    // Link is no longer needed remove from tree.
    document.body.removeChild(tempLink);
  };

  return (
    <Grid
      className={classes.completeContainer}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <img className={classes.completeImg} src={ReportSVG} />
      <Typography align="center" variant="h5">
        File Completed
      </Typography>
      <Typography
        align="center"
        variant="body1"
        style={{ maxWidth: 640, marginBottom: 16, marginTop: 8, opacity: 0.6 }}
      >
        In order to print your report in color, your browser's print settings
        will need to have the "Background graphics" or "Print Background (color
        & images)" box checked. Please refer to our Help page for more details
      </Typography>

      <Button
        disabled={isSubmittingReport}
        variant="contained"
        color="primary"
        style={{ marginTop: 25 }}
        onClick={handleClick}
      >
        View
      </Button>
    </Grid>
  );
});
const LinkComplete = withStyles(styles)(({ classes, report }) => {
  const [copied, setCopied] = React.useState(false);

  const linkRef = React.createRef();

  const handleClick = e => {
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Grid
      className={classes.completeContainer}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <img className={classes.completeImg} src={ReportSVG} />
      <Typography align="center" variant="h5">
        Link Generated
      </Typography>
      <Typography
        align="center"
        variant="body1"
        style={{ maxWidth: 600, marginBottom: 16 }}
      >
        For security reasons the link is set to expire in 7 days. In order to
        share it after this, you will need to have a new link generated
      </Typography>
      <Grid container justify="center" wrap="nowrap">
        <Grid container item xs={6} alignItems="center" justify="flex-end">
          <Icon className={classes.warn}>info</Icon>
          <div className={classes.warnText}>
            Anyone with this link can view it
          </div>
        </Grid>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          style={{ marginLeft: 24 }}
        >
          <Icon className={classes.warn}>info</Icon>
          <div className={classes.warnText}>
            This Link will expire in 7 days
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ marginTop: 25 }}
      >
        <input
          className={classes.link}
          ref={linkRef}
          value={report.reportLink}
          readOnly
        />

        <Tooltip
          open={copied}
          title="Copied!"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleClick}>
            Copy Link
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
});

const ReportProgress = withStyles(styles)(({ header, classes }) => {
  return (
    <Grid
      className={classes.progressContainer}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <img className={classes.progressImg} src={ReportSVG} />
      <Typography align="center" variant="h5">
        {header}
      </Typography>
      <Typography align="center" variant="body1">
        Gathering selected entries...
      </Typography>
      <LinearProgress className={classes.progressBar} color="secondary" />
    </Grid>
  );
});

const ReportError = withStyles(styles)(({ tryAgain, classes }) => {
  return (
    <Grid
      className={classes.progressContainer}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <img className={classes.progressImg} src={ReportSVG} />
      <Typography align="center" variant="h5">
        Unable to generate report
      </Typography>
      <Typography align="center" variant="body1">
        It looks like we weren't able to generate your report. You can try again
      </Typography>
      <Button onClick={tryAgain} style={{ marginTop: 24 }} variant="contained">
        Try Again
      </Button>
    </Grid>
  );
});

const SelectionFooter = ({ numSelected, onDownload, onShareableLink }) => (
  <Grid container justify="space-between" alignItems="center">
    <Typography variant="h6">{numSelected} Item(s) selected</Typography>
    <Grid container item justify="flex-end" xs={8}>
      {/* <Button variant="contained" disabled>
        Print
      </Button> */}
      <Button
        style={{ marginLeft: 16 }}
        variant="contained"
        color="primary"
        onClick={onDownload}
        disabled={numSelected === 0}
      >
        <Icon style={{ display: 'inline-block', marginRight: 8 }}>
          cloud_download
        </Icon>
        Print / Download
      </Button>
      <Button
        style={{ marginLeft: 16 }}
        variant="contained"
        color="primary"
        onClick={onShareableLink}
        disabled={numSelected === 0}
      >
        <Icon style={{ display: 'inline-block', marginRight: 8 }}>link</Icon>
        Shareable Link
      </Button>
    </Grid>
  </Grid>
);

const selectStyles = {
  root: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#EFF1F3',
    height: 388,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 24,
  },
  imgContainer: {
    position: 'absolute',
    bottom: -125,
    left: `calc(50% - 125px)`,
    width: 250,
    height: 250,
    borderRadius: 125,
  },
  img: {
    width: 290,
    marginTop: -130,
    objectFit: 'contain',
  },
};

const SelectTemplate = withStyles(selectStyles)(({ classes }) => (
  <Grid
    justifyContent="center"
    alignItems="center"
    container
    className={classes.root}
  >
    <Typography variant="h6">Select Template</Typography>
    <Grid
      justify="center"
      alignItems="center"
      container
      className={classes.imgContainer}
    >
      <img
        className={classes.img}
        src={require('../../../client/images/report-select.png')}
      />
    </Grid>
  </Grid>
));

const GenerateReportQrCode = withStyles(styles)(
  ({ classes, report, fullName }) => {
    const downloadQR = () => {
      window.print();

      // function downloads QR, disabled as whole page is needed instead of the QR image
      // const canvas = document.getElementById('reportLink');
      // const pngUrl = canvas
      //   .toDataURL('image/png')
      //   .replace('image/png', 'image/octet-stream');
      // let downloadLink = document.createElement('a');
      // downloadLink.href = pngUrl;
      // downloadLink.download = 'Health_Status_QR_Code.png';
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
    };
    return (
      <Grid
        className={classes.progressContainer}
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{
            padding: '8px',
            background: '#0068A8',
            marginBottom: '24px',
          }}
          className={classes.exactImage}
        >
          <img
            src={AgrinHealthBanner}
            style={{ width: '100%', maxWidth: '600px', height: '45px' }}
          />
        </Grid>

        <Typography align="center" variant="h5">
          COVID Health Status Report for:
        </Typography>
        <Typography align="center" variant="h5">
          {fullName}
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          style={{ marginBottom: '16px' }}
        >
          Created {format(new Date(), DATE_FORMAT)}
        </Typography>

        <QRCode
          id="reportLink"
          value={report.reportLink}
          size={150}
          level={'H'}
          includeMargin={true}
        />

        {/* <Typography align="center" variant="h5" style={{ margin: '16px' }}>
        QR Code Generated
      </Typography> */}

        <Grid
          container
          item
          xs={6}
          alignItems="center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <div>
            <Icon className={classes.warn}>info</Icon>
          </div>
          <div className={classes.warnText}>
            Valid until: {format(addDays(new Date(), 7), DATE_FORMAT)}.
          </div>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 24 }}
          onClick={downloadQR}
          className={classes.noPrint}
        >
          Print QR Code
        </Button>

        <Typography
          align="center"
          variant="body1"
          style={{ maxWidth: 437, margin: 16 }}
        >
          NOTICE: This information was shared for the sole purpose of
          demonstrating current COVID health status. Any attempts to use this
          information for any other purpose without the express permission of
          its owner may be subject to legal action under US law.
        </Typography>
      </Grid>
    );
  }
);

export const REPORT_DOWNLOAD_PROGRESS = 'report-download-progress';
export const REPORT_DOWNLOAD_COMPLETE = 'report-download-complete';
export const REPORT_LINK_PROGRESS = 'report-link-progress';
export const REPORT_LINK_COMPLETE = 'report-link-complete';
export const REPORT_ERROR = 'report-error';
export const PLAN_BASIC = 'Basic';
export const REPORT_LOCKED = 'report-locked';
export const REPORT_SELECTION = 'report-selection';
export const REPORT_QR_COMPLETE = 'report-qr-complete';

const ReportModal = ({
  open,
  personId,
  accountRole,
  classes,
  onClose,
  headers,
  rows,
  template,
  activeModalState,
  setActiveModalState,
  fullName,
  cleanUpReportsState,
  ...rest
}) => {
  const [selected, setSelected] = React.useState([]);

  const [filters, setFilters] = React.useState({
    category: null,
    query: null,
    attachments: true,
  });

  const {
    setActiveReport,
    createJournalReport,
    downloadReport,
    report,
    isSubmittingReport,
  } = rest;

  React.useEffect(() => {
    // if user account isn't shareable set to locked
    if (accountRole === PLAN_BASIC) {
      setActiveModalState(REPORT_LOCKED);
    } else if (activeModalState === REPORT_LOCKED) {
      setActiveModalState(REPORT_SELECTION);
    }
  }, [accountRole, open]);

  const handleSelectAllClick = indeterminate => event => {
    if (event.currentTarget.checked && !indeterminate) {
      const itemsToAdd = rows.items.filter(applyFilters).map(n => ({
        recordId: n.id,
        recordType: n.data.recordType,
        text: n.data.recordType === 'MEDICAL_HX' ? n.data.condition : '',
        isTemp: n.data.recordType === 'MEDICAL_HX' ? n.isTemp : false,
        mediaObjectIds: [],
      }));

      return setSelected([...selected, ...itemsToAdd]);
    }

    const filtered = selected.filter(item => item.recordType !== template);

    return setSelected(filtered);
  };

  const handleTableRowClick = (_, data) => {
    console.log(data);
    const selectedIndex = selected.map(s => s.recordId).indexOf(data.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        recordId: data.id,
        recordType: data.recordType,
        text: data.text,
        isTemp: data.isTemp,
        mediaObjectIds: [],
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const normalizeRecords = (acc, curr) => {
    switch (curr.recordType) {
      case 'ALLERGIES':
        return {
          ...acc,
          allergyRecords: [...acc.allergyRecords, curr],
        };
      case 'JOURNAL_ENTRIES':
        return {
          ...acc,
          journalRecords: [...acc.journalRecords, curr],
        };
      case 'MEDICAL_HX':
        const hasTempId = curr && curr.isTemp;
        return {
          ...acc,
          familyMedicalHistoryRecords: [
            ...acc.familyMedicalHistoryRecords,
            {
              ...curr,
              recordId: !hasTempId ? curr.recordId : null,
            },
          ],
        };
      case 'IMMUNIZATIONS':
        return {
          ...acc,
          immunizationRecords: [...acc.immunizationRecords, curr],
        };
      case 'SURGICAL_HX':
        return {
          ...acc,
          surgicalHistoryRecords: [...acc.surgicalHistoryRecords, curr],
        };
      case 'MVS':
        return {
          ...acc,
          mvsRecords: [...acc.mvsRecords, curr],
        };
      case 'CONDITIONS':
        return {
          ...acc,
          diagnosisRecords: [...acc.diagnosisRecords, curr],
        };
      case 'LABRESULTS':
        return {
          ...acc,
          labResultRecords: [...acc.labResultRecords, curr],
        };
      default:
        return acc;
    }
  };

  const onDownloadClick = async () => {
    if (selected.length !== 0) {
      setActiveModalState(REPORT_DOWNLOAD_PROGRESS);

      const records = selected.reduce(normalizeRecords, {
        allergyRecords: [],
        journalRecords: [],
        immunizationRecords: [],
        familyMedicalHistoryRecords: [],
        mvsRecords: [],
        surgicalHistoryRecords: [],
        diagnosisRecords: [],
        labResultRecords: [],
      });

      const payload = {
        personId,
        generateLink: false,
        ...records,
      };

      console.log('[DOWNLOAD STARTED]', payload);
      const result = await createJournalReport(personId, payload);
      console.log('[DOWNLOAD RESULT]', result);

      if (result.error) {
        setActiveModalState(REPORT_ERROR);
      } else {
        setActiveReport(result.payload);
        setActiveModalState(REPORT_DOWNLOAD_COMPLETE);
      }
    }
  };

  const onShareableLinkClick = async () => {
    if (selected.length !== 0) {
      setActiveModalState(REPORT_LINK_PROGRESS);

      const records = selected.reduce(normalizeRecords, {
        allergyRecords: [],
        journalRecords: [],
        immunizationRecords: [],
        familyMedicalHistoryRecords: [],
        mvsRecords: [],
        surgicalHistoryRecords: [],
        diagnosisRecords: [],
        labResultRecords: [],
      });

      const payload = {
        personId,
        generateLink: true,
        ...records,
      };

      console.log('[SHAREABLE LINK STARTED]', payload);
      const result = await createJournalReport(personId, payload);
      console.log('[SHAREABLE LINK RESULT]', result);

      if (result.error) {
        setActiveModalState(REPORT_ERROR);
      } else {
        setActiveReport(result.payload);
        setActiveModalState(REPORT_LINK_COMPLETE);
      }
    }
  };

  /**
   * Reset modal
   */
  const onCloseReport = () => {
    setSelected([]);
    setFilters({ category: null, query: null, attachments: true });
    setActiveModalState(REPORT_SELECTION);
    cleanUpReportsState();
    if (onClose) {
      onClose();
    }
  };

  /**
   * Just hide the modal do not reset.
   */
  const onHideReportModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const hasMediaObjects = data =>
    data.mediaObjects && data.mediaObjects.length > 0;

  const addFilter = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };

  const applyFilters = row => {
    const { query, attachments, category } = filters;
    const isAll = template === 'ALL';
    let showRow = true;
    if (query) {
      if (!row.columns[isAll ? 1 : 0].toLowerCase().includes(query)) {
        showRow = false;
      }
      if (!!row.queryTags) {
        if (
          row.queryTags.filter(
            tag => !!tag && tag.toLowerCase().includes(query)
          ).length > 0
        ) {
          showRow = true;
        }
      }
    }

    if (!attachments && hasMediaObjects(row.data)) {
      showRow = false;
    }

    if (category && category !== row.data.recordType) {
      showRow = false;
    }

    return showRow;
  };
  const renderModalState = () => {
    switch (activeModalState) {
      case REPORT_LOCKED:
        return <UpgradeAccount onClose={onCloseReport} />;
      case REPORT_SELECTION:
        return (
          <React.Fragment>
            <div style={{ width: '100%' }}>
              <Typography variant="h5" className={classes.header}>
                Generate a Report
              </Typography>
              <Typography variant="body1" className={classes.subHeader}>
                Here you can customize your Report and choose what you want to
                do with it. You can print or download it for personal use, or
                share it with someone via Shareable Link. All created reports
                will be available in your Reports History.
              </Typography>
              {/*
                                <EasterEgg
                                    egg="3"
                                    message="You found a super egg! CODE: EGGCEPTIONAL"
                                />
                        */}
              <ReportModalHeader
                selected={selected}
                filters={filters}
                applyFilter={addFilter}
                onTemplateChange={rest.setTemplate}
              />
              {template ? (
                <ReportModalTable
                  resourceType={rows.resourceType}
                  handleSelectAllClick={handleSelectAllClick}
                  handleClick={handleTableRowClick}
                  selected={selected}
                  rows={rows.items.filter(applyFilters)}
                  headers={headers}
                  template={template}
                  loading={rows.loading}
                />
              ) : (
                <SelectTemplate />
              )}
            </div>
            <SelectionFooter
              numSelected={selected.length}
              onDownload={onDownloadClick}
              onShareableLink={onShareableLinkClick}
            />
          </React.Fragment>
        );
      case REPORT_DOWNLOAD_PROGRESS:
        return <ReportProgress header="Generating File" />;
      case REPORT_DOWNLOAD_COMPLETE:
        return (
          <DownloadComplete
            report={report}
            isSubmittingReport={isSubmittingReport}
            pid={personId}
            downloadReport={downloadReport}
          />
        );
      case REPORT_LINK_PROGRESS:
        return <ReportProgress header="Generating Shareable Link" />;
      case REPORT_LINK_COMPLETE:
        return <LinkComplete report={report} />;
      case REPORT_ERROR:
        return (
          <ReportError tryAgain={() => setActiveModalState(REPORT_SELECTION)} />
        );

      case REPORT_QR_COMPLETE:
        return <GenerateReportQrCode report={report} fullName={fullName} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onHideReportModal}
      disableAutoFocus={true}
      className={classes.overlay}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.root}
        style={{ margin: '20px auto' }}
      >
        <IconButton className={classes.close} onClick={onCloseReport}>
          <Icon>close</Icon>
        </IconButton>
        {renderModalState()}
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state, props) => ({
  personId: state.active.user.personId,
  report:
    props.type === HEALTH_STATUS
      ? state.healthStatus.report
      : state.reportCompose.report,
  isSubmittingReport: state.reportCompose.isSubmitting,
  fullName: `${state.active.user.firstName} ${state.active.user.lastName}`,
});

const mapDispatchToProps = dispatch => ({
  createJournalReport: (pid, payload) =>
    dispatch(createJournalReport(pid, payload)),
  downloadReport: (pid, payload) => dispatch(downloadReport(pid, payload)),
  setActiveReport: payload => dispatch(setActiveDownload(payload)),
  getShareableLink: payload => dispatch(getShareableLink()),
  cleanUpReportsState: () => dispatch(cleanUpReportsState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReportModal));
