import {
  syncPermissions_REQUEST,
  syncPermissions_SUCCESS,
  syncPermissions_FAILURE,
  syncPermissions_remove_REQUEST,
  syncPermissions_remove_SUCCESS,
  syncPermissions_remove_FAILURE,
  syncPermissions_ADD_REQUEST,
  syncPermissions_ADD_SUCCESS,
  syncPermissions_ADD_FAILURE,
  RESET_SYNC_PERMISSIONS,
  SYNC_PERMISSIONS_REAUTHORIZATION,
} from './actions';

import microsoft from '../../../client/images/sync/MS Teams.png';
import fitbit from '../../../client/images/sync/Fitbit.png';
import cronometer from '../../../client/images/sync/Cronomter.jpg';
import googleFit from '../../../client/images/sync/Google fit.png';
import epic from '../../../client/images/sync/Epic My Chart.png';

import { SET_ACTIVE_USER } from '../active/actions';
import { mockData } from './mockData';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
const GENERAL_ERROR_MESSAGE =
  "We're sorry, this service is temporarily unavailable. Please try again later. If the problem persists, please contact support at support@agrinhealth.com";

const providerLogos = {
  // Cronometer provider id
  '016f3067-d149-4b80-80b0-6281735976aa': cronometer,

  // Epic provider id
  '0c541e4e-e203-448b-8c5e-ae9a138c5ee1': epic,

  // Fitbit provider id
  '016f3067-d149-4b80-80b0-6281735976bf': fitbit,

  //Google fit provider id
  'c7e39c5f-7120-ec11-981f-0050f29f7b3d': googleFit,

  // Microsoft provider Id
  '0dca9e3b-c973-4113-b9c1-11cc4847035f': microsoft,
};

const initialState = {
  activeUser: {},

  isFetching: false,
  isSubmitting: false,

  hasFetched: false,
  hasRecords: false,

  hasError: false,
  error: undefined,
  successMessage: undefined,

  isSubmitting: false,
  hasSubmitted: false,
  hasDeleted: false,

  list: {},
  listIds: [],
};

const reducers = [];

reducers[RESET_SYNC_PERMISSIONS] = (state, action) => initialState;

reducers[syncPermissions_REQUEST] = (state, action) => {
  return {
    ...initialState,
    isFetching: true,
    activeUser: action.payload.activeUser,
  };
};

reducers[syncPermissions_SUCCESS] = (state, action) => {
  if (action.meta.pid !== state.activeUser.personId) return state;
  const _payload = action.payload.map(item => {
    return {
      id: item.syncProviderId,
      name: item.name,
      description: item.description,
      links: {
        ...item.links,
      },
      image: providerLogos[item.syncProviderId]
        ? providerLogos[item.syncProviderId]
        : '',
      synced: Boolean(item.sync) && Boolean(item.sync.syncId),
      ...item,
    };
  });
  const listIds = getKeys('id', _payload);
  const list = arrayToHashMap('id', _payload || []);
  return {
    ...state,
    isFetching: false,
    hasRecords: true,
    hasFetched: true,
    listIds,
    list,
  };
};

reducers[syncPermissions_FAILURE] = (state, action) => {
  return {
    ...state,
    isFetching: false,

    hasFetched: true,
    hasRecords: false,

    hasError: true,
    error: GENERAL_ERROR_MESSAGE,
  };
};

reducers[SET_ACTIVE_USER] = (state, action) => {
  return Object.keys(state.activeUser).length &&
    state.activeUser.personId !== action.payload.personId
    ? {
        ...initialState,
        activeUser: {
          ...action.payload,
        },
      }
    : state;
};

// reducers[syncPermissions_ADD_REQUEST] = (state, action) => {
//   return {
//     ...state,
//     list: {
//       ...state.list,
//       [action.meta.sid]: {
//         ...state.list[action.meta.sid],
//         synced: true,
//       },
//     },
//   };
// };

// reducers[syncPermissions_ADD_FAILURE] = (state, action) => {
//   return {
//     ...state,
//     list: {
//       ...state.list,
//       [action.meta.sid]: {
//         ...state.list[action.meta.sid],
//         synced: false,
//       },
//     },
//     hasError: true,
//     error: GENERAL_ERROR_MESSAGE,
//   };
// };

reducers[syncPermissions_remove_REQUEST] = (state, action) => {
  return {
    ...state,
    list: {
      ...state.list,
      [action.meta.sid]: {
        ...state.list[action.meta.sid],
        sync: {
          ...state.list[action.meta.sid].sync,
          status: 'Revoked',
        },
        synced: false,
      },
    },
  };
};

// reducers[syncPermissions_remove_SUCCESS] = (state, action) => {
//   return {
//     ...state,
//     list: {
//       ...state.list,
//       [action.meta.sid]: {
//         ...state.list[action.meta.sid],
//         synced: false,
//       },
//     },
//   };
// };

reducers[syncPermissions_remove_FAILURE] = (state, action) => {
  return {
    ...state,
    list: {
      ...state.list,
      [action.meta.sid]: {
        ...state.list[action.meta.sid],
        synced: true,
      },
    },
    hasError: true,
    error: GENERAL_ERROR_MESSAGE,
  };
};

/**
 * We use this reducer to update the state of a particular Sync record
 * associated with a Sync record.
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
reducers[SYNC_PERMISSIONS_REAUTHORIZATION] = (state, action) => {
  return {
    ...state,
    list: {
      ...state.list,
      [action.payload.syncProviderId]: {
        ...state.list[action.payload.syncProviderId],
        sync: {
          ...state.list[action.payload.syncProviderId].sync,
          status: 'Active',
        },
      },
    },
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
