import React from 'react';
import { Field } from 'redux-form';
import { Typography, Grid, Select } from '@material-ui/core';
import SelectField from '../../../../library/forms/SelectField';
import InputField from '../../../../library/forms/InputField';
import { connect } from 'react-redux';

const isRequired = value => (value ? undefined : 'Required');

const DocumentFormFields = ({
  fileName,
  categoryOptions,
  disableCategoryField = false,
  disableDisplayName = false,
}) => {
  return (
    <Grid container spacing={1} style={{ marginTop: 24 }}>
      {!disableDisplayName && (
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ fontSize: '18px', color: '#444447' }}
          >
            {fileName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field
          type="text"
          name="category"
          label="Category"
          labelKey="longName"
          valueKey="shortName"
          options={categoryOptions || []}
          component={SelectField}
          disabled={disableCategoryField}
          props={{
            enableMinHeight: false,
            disabled: disableCategoryField,
          }}
          validate={isRequired}
          isSearchable
          isCreatable
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type="text"
          name="displayName"
          label="Title"
          component={InputField}
          props={{
            enableMinHeight: false,
            placeholder: fileName,
            HoverTooltipMsg: 'Defaults to File name',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Field
          type="date"
          label="Date"
          name="newEnteredOnDate"
          max="2999-12-31"
          shrink
          component={InputField}
          validate={isRequired}
          props={{
            enableMinHeight: false,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Field
          type="time"
          label="Time"
          name="newEnteredOnTime"
          shrink
          component={InputField}
          props={{
            enableMinHeight: false,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          type="text"
          name="description"
          label="Description"
          component={InputField}
          props={{
            enableMinHeight: false,
            multiline: true,
            rows: 4,
            placeholder: 'Notes/Description',
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  categoryOptions: state.codes.documentType,
});

export default connect(mapStateToProps)(DocumentFormFields);
