import React from 'react';
import {
  Grid,
  Paper,
  withStyles,
  Divider,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Elements, injectStripe } from 'react-stripe-elements';
import {
  Field,
  formValueSelector,
  reduxForm,
  getFormSyncErrors,
} from 'redux-form';
import validate from './validate';
import StripeForm from './StripeForm';

const formName = 'StripePurchaseForm';

// const validate = (values, props) => {
//   const errors = {};

//   console.log(props, props.checkoutTotal.checkoutTotal);
//   if (props.checkoutTotal >= STRIPE_MINIMUM_PRICE) {
//     if (!values.cardName || values.cardName.trim().length === 0) {
//       errors.cardName = 'Required';
//     }
//   }

//   return errors;
// };

const styles = theme => ({
  panel: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  footer: {
    width: '100%',
    background: '#f1f8e9',
    padding: theme.spacing(3),
    '& p': {
      marginRight: theme.spacing(2),
    },
  },
  container: {
    width: '100%',
  },
  hint: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '8px 12px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: theme.spacing(1),
    minHeight: '1em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: '1em',
  },
  innerContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  innerContainerHalf: {
    paddingLeft: 24,
    paddingRight: 24,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paymentContainer: {
    // padding: 24,
    // paddingTop: 8,
    width: '100%',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
});

const STRIPE_MINIMUM_PRICE = 0.5;

const StripePurchaseForm = ({
  children,
  classes,
  pristine,
  submitting,
  stripeError,
  product,
  handleSubmit,
  onSubmit,
  checkoutTotal,
  stripe,
  onFieldChange,
  revealPaymentForm,
  isSubmitting,
  couponSlot,
  summarySlot,
  formErrors,
  completeStripeFields,
  stripeFormElevation = 0,
}) => {
  const isComplete =
    0 + checkoutTotal < STRIPE_MINIMUM_PRICE ||
    (!!completeStripeFields.cardNumber &&
      !!completeStripeFields.cardCvc &&
      !!completeStripeFields.cardExpiry);

  const isDisabled = !product.data || submitting || isSubmitting || !isComplete;

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit(stripe))}
      className={classes.container}
    >
      <Grid container>
        {children(revealPaymentForm)}
        {revealPaymentForm && (
          <React.Fragment>
            {summarySlot && (
              <div className={classes.innerContainerHalf}>
                <Grid item sm={12}>
                  {summarySlot()}
                </Grid>
              </div>
            )}
            {couponSlot && (
              <div className={classes.innerContainer}>
                <Grid item sm={12}>
                  {couponSlot()}
                </Grid>
              </div>
            )}
            {checkoutTotal > 0 && (
              <div className={classes.paymentContainer}>
                <Grid container style={{ width: '100%' }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '16px',
                      paddingTop: 8,
                      paddingTop: 20,
                    }}
                  >
                    <Typography variant="h6" style={{ marginBottom: 0 }}>
                      Identity fee
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: 0 }}>
                      $1.00
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <StripeForm
                      error={stripeError}
                      classes={classes}
                      onFieldChange={onFieldChange}
                      revealPaymentForm={revealPaymentForm}
                      isDisabled={isDisabled}
                      isSubmitting={isSubmitting}
                      checkoutTotal={checkoutTotal}
                      product={product}
                    >
                      Loading checkout form...
                    </StripeForm>
                  </Grid>
                </Grid>
              </div>
            )}
          </React.Fragment>
        )}
      </Grid>
    </form>
  );
};

const ReduxForm = reduxForm({
  form: formName,
  validate,
})(withStyles(styles)(StripePurchaseForm));

const selector = formValueSelector(formName);

//TODO: Isolate stripe form to be the ReduxForm form component instead of its parent(StripePurchaseForm)
//      to get rid of console errors involving stripeForm styles

const InjectedForm = injectStripe(
  connect(state => {
    return {
      couponCode: selector(state, 'couponCode'),
      checkout_total: selector(state, 'checkout_total'),
      formErrors: getFormSyncErrors('StripePurchaseForm')(state),
    };
  })(ReduxForm)
);

export default props => (
  <Elements>
    <InjectedForm {...props} />
  </Elements>
);
