import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SubmissionError, reset } from 'redux-form';
import { withRouter } from 'react-router';
import BasePage from '../BasePage';
import { Grid, Drawer } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import ConditionsForm from '../../containers/ConditionsForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../store/reducers/ui/actions';

import {
  updateMedia,
  deleteMedia,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import { clearFiles, CONDITIONS } from '../../../store/reducers/mediaObject';
import {
  CONDITIONS_CREATE_FAILURE,
  CONDITIONS_UPDATE_SUCCESS,
  CONDITIONS_UPDATE_FAILURE,
  createCondition,
  updateCondition,
  clearSelectedId,
  CONDITIONS_CREATE_SUCCESS,
  submitForm,
} from '../../../store/reducers/conditions/actions';
import { fetchSurgicalHistoryType } from '../../../store/reducers/codes/actions';
import MediaSubmitProgress from '../../components/DocumentsDrawer/MediaObjectDrawer/MediaSubmitProgress';
import {
  getBundlesToUpload,
  hasMediaObjectUpdates,
} from '../../components/MediaObject/helpers';

const surgeryTypeAhead = props => (inputValue, callBack) => {
  // let { fetchMedicationList } = props;
  // fetchMedicationList(inputValue).then(res => {
  //   let cache = {};
  //   let options = [];
  //   res.payload.map(o => {
  //     // if (!cache[o.m])
  //     options.push({
  //       label: o.medicationShortName,
  //       value: o.medicationShortName,
  //     });
  //     // cache[o.medListId.toLowerCase()] = true;
  //   });
  //   console.log('MEDICATIONS!', options);
  //   callBack(options);
  // });
  // To my surprise keeping this function and triggering the call back speeds up
  // searching. Will be replaced with Elastic Search forthcoming implementation
  callBack(props.surgicalHistoryTypes);
};

const AddConditionModal = ({
  history,
  personId,
  selectedId,
  conditions,
  isFetchingSurgicalHistoryType,
  hasFetchedSurgicalHistoryType,
  isNavigationLocked,
  createCondition,
  modal,
  setModal,
  bundles,
  ...props
}) => {
  // Run when the component is loaded and only run once!
  useEffect(() => {
    // Ensure that form is cleared before using
    props.reset();

    return () => {
      // Ensure that navigation component is unlocked, and form
      // is reset when un-mounting the component.
      if (selectedId) {
        props.unblockNavigation();
        props.clearSelectedId();
        props.reset();
      }
    };
  }, []);

  useEffect(() => {
    if (!hasFetchedSurgicalHistoryType && !isFetchingSurgicalHistoryType)
      props.fetchSurgicalHistoryType();

    return () => props.unblockNavigation();
  }, [personId]);

  const onFormSubmit = async values => {
    const { mediaObjects, form, bundlesToDelete } = props;
    // The property is irrelevant to the creation of a Condition record.
    // the reason for the redundancy is due to how an object is index with our
    // optimistic UI algorithm. When an object is initially created, we create
    // a new GUID called tid (temporary ID). We use the tid to find a reference
    // to the object in the list tree, and update the object properties based
    // on the result of the API interaction. for example: if the record is
    // created successfully then we change the isCreated state. Because the "tid"
    // isn't an actual record GUID, we use the "tid" to get the real record reference
    // so we can interact with the record on the API. the "realId" is the actual
    // record primary key.

    const { bundlesToUpload, bundlesToUpdate } = getBundlesToUpload(
      mediaObjects,
      form
    );
    props.submitForm();

    // delete attachments
    if (bundlesToDelete.length) {
      for (const bundle of bundlesToDelete) {
        await props.deleteMedia(bundle.mediaObjectId, bundle.objectId);
      }
    }

    if (bundlesToUpdate.length) {
      for (const file of bundlesToUpdate) {
        await props.updateMedia({
          ...file,
          description: file.description,
        });
      }
    }
    const realId =
      selectedId && conditions && conditions.list
        ? conditions.list[selectedId].personDiagnosisId
        : null;
    let result;
    if (!selectedId) {
      result = await createCondition(personId, values, bundlesToUpload);
    } else {
      result = await props.updateCondition(
        personId,
        realId,
        values,
        bundlesToUpload
      );
    }
    console.log(result);
    if (
      result.type === CONDITIONS_CREATE_SUCCESS ||
      result.type === CONDITIONS_UPDATE_SUCCESS
    ) {
      props.unblockNavigation();
      props.clearSelectedId();
      props.reset();
      // props.clearFiles();
      console.log('SUB SUCCESS');
      setModal(false);
    } else if (
      result.type === CONDITIONS_CREATE_FAILURE ||
      result.type === CONDITIONS_UPDATE_FAILURE
    ) {
      throw new SubmissionError(result.payload.errors);
    }
  };

  const onFormChange = (_1, _2, formProps) => {
    if (!isNavigationLocked && !formProps.pristine && selectedId)
      props.blockNavigation();
  };

  const onFormCancel = async formProps => {
    if (!isNavigationLocked && formProps.pristine) props.reset();

    setModal(false);
  };

  return (
    <Drawer anchor="right" onClose={() => setModal(false)} open={modal}>
      <div style={{ width: '550px' }}>
        {conditions.isSubmitting && bundles.length ? (
          <div style={{ padding: 16, paddingTop: 24 }}>
            <Grid container spacing={2}>
              {bundles.map(bundle => (
                <Grid item xs={12}>
                  <MediaSubmitProgress bundle={bundle} />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <ConditionsForm
            action={selectedId ? 'UPDATE' : 'CREATE'}
            isFetchingSurgicalHistoryType={isFetchingSurgicalHistoryType}
            surgeryTypeAhead={surgeryTypeAhead(props)}
            hasMediaObjectUpdates={props.hasMediaObjectUpdates}
            surgicalHistoryTypes={props.surgicalHistoryTypes}
            isSubmitting={conditions.isSubmitting}
            onChange={onFormChange}
            onSubmit={onFormSubmit}
            onCancel={onFormCancel}
          />
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = state => {
  return {
    form: state.form,
    mediaObjects: state.conditions.mediaObjects,
    bundlesToDelete: state.conditions.bundlesToDelete,
    personId: state.active.user.personId,
    selectedId: state.conditions.selectedId,
    conditions: state.conditions,
    surgicalHistoryTypes: state.codes.surgicalHistoryTypes,
    isFetchingSurgicalHistoryType: state.codes.isFetchingSurgicalHistoryType,
    hasFetchedSurgicalHistoryType: state.codes.hasFetchedSurgicalHistoryType,
    isNavigationLocked: state.ui.isNavigationLocked,
    bundles: state.mediaObject.bundles,
    hasMediaObjectUpdates:
      hasMediaObjectUpdates(state) || state.conditions.bundlesToDelete.length,
  };
};

const mapDispatchToProps = dispatch => ({
  createCondition: (pid, payload, bundles) =>
    dispatch(createCondition(pid, payload, bundles)),
  updateCondition: (pid, conditionsId, payload, bundles) =>
    dispatch(updateCondition(pid, conditionsId, payload, bundles)),
  clearSelectedId: () => dispatch(clearSelectedId()),
  unblockNavigation: () => dispatch(unblockNavigation()),
  blockNavigation: () => dispatch(blockNavigationWithStandardMsg()),
  clearFiles: () => dispatch(clearFiles()),
  reset: () => dispatch(reset('conditions-form')),
  fetchSurgicalHistoryType: () => dispatch(fetchSurgicalHistoryType()),

  updateMedia: obj => dispatch(updateMedia(CONDITIONS, obj)),
  deleteMedia: (mid, oid) => dispatch(deleteMedia(mid, oid, CONDITIONS)),
  submitForm: () => dispatch(submitForm()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddConditionModal)
);
