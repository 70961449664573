import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ProgressButtonLinear from '../Buttons/ProgressButtonLinear';

const styles = theme => ({
  root: {
    padding: 24
  },
  cancel: {
    marginRight: theme.spacing(2),
  },
});

const FormButtonPanel = props => {
  const { classes } = props;
  const buttonDisabled = props.isDisabled || props.isSubmitting;

  const handleOnSubmit = (event) => {
    props?.onSubmit?.(event);
  }

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={6}> 
        <Button
          color="default"
          variant="text"
          size="large"
          onClick={props.onCancel}
          disabled={props.isSubmitting}
          fullWidth
          disableElevation
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <ProgressButtonLinear
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          onClick={handleOnSubmit}
          fullWidth
          disabled={buttonDisabled}
          isProgressing={props.isSubmitting}
          disableElevation
          style={
            buttonDisabled
              ? {}
              : {
                  backgroundColor: '#006AB0',
                  color: '#fff',
                }
          }
        >
          Save
        </ProgressButtonLinear>
      </Grid>
    </Grid>
  );
};

FormButtonPanel.propTypes = {
  actionText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default withStyles(styles)(FormButtonPanel);
