import React, { useState } from 'react';
import { withRouter } from 'react-router';

import { Popover, IconButton, Badge, makeStyles, Box } from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';

import NotificationModalHeader from './NotificationModalHeader';
import NotificationModalBody from './NotificationModalBody';
// import NotificationModalFooter from './NotificationModalFooter';

const useStyles = makeStyles(() => ({
  root: {
    width: 450,
    overflowY: 'hidden',
  },
  paper: {
    overflow: 'hidden',
    transform: 'translate3d(16px, 10px, 0px) !important',
  },
  historyButton: {
    color: '#999',
    '&:hover': {
      color: '#000',
    },
  },
  body: {
    maxHeight: 550,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  notificationIcon: {
    color: '#fff',
  },
  unreadCount: {
    position: 'absolute',
    top: '0px',
    left: '28px',
    fontSize: '10px',
    background: '#F03B2E',
    padding: '2px 3px',
    borderRadius: '100%',
    color: 'white',
    zIndex: '10',
    display: 'block',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
    transformOrigin: 'top center',
    fontFamily: 'Arial',
    fontWeight: '700',
    lineHeight: '100%',
  },
}));

const NotificationModal = ({
  data,
  onNotificationPressed,
  onNotificationOption,
  onClearNotifications,
}) => {
  const classes = useStyles();

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const handleOpen = event => {
    setNotificationAnchorEl(event.target);
  };

  const handleClose = () => {
    setNotificationAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-controls="notifications-modal"
        aria-haspopup={true}
        onClick={handleOpen}
      >
        <Badge
          overlap="rectangular"
          badgeContent={data.unreadNotificationIds.length}
          color="error"
          style={{ color: '#fff' }}
        >
          <NotificationsIcon className={classes.notificationIcon} />
        </Badge>
      </IconButton>
      <Popover
        id="notifications-modal"
        open={Boolean(notificationAnchorEl)}
        anchorEl={notificationAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ elevation: 3, className: classes.paper }}
      >
        <Box className={classes.root}>
          <NotificationModalHeader onClear={onClearNotifications} />
          <Box className={classes.body}>
            <NotificationModalBody
              isFetching={data.isFetching}
              listIds={data.listIds}
              list={data.list}
              onNotificationPressed={onNotificationPressed}
              onNotificationOption={onNotificationOption}
            />
          </Box>
          {/* <NotificationModalFooter>
            <Button size="small" className={classes.historyButton} onClick={handleClickHistory}>
              Notification History
            </Button>
          </NotificationModalFooter> */}
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default withRouter(NotificationModal);
