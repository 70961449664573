import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';

import { Grid, withStyles } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import { mask } from '../../../../library/helpers/format';
import FormFooter from '../FormFooter';
import PROVIDER_LABELS from './providerLabels';
import validate from './validateForm';

const styles = {
  field: {
    padding: `0 !important`,
    paddingLeft: `12px !important`,
    paddingRight: `12px !important`,
    height: 90,
  },
};

const phoneMask = value => mask(value, 'XXX-XXX-XXXX');

const Provider01Form = props => {
  const { classes, handleSubmit, links, valid } = props;

  useEffect(() => {
    props.onChangeForm({ valid });
  }, [valid]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.field}>
          <Field
            type="text"
            name="fullName"
            label="Full Name"
            // placeholder="Dr. Mary Robertson"
            component={InputField}
            hint="Required"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <Field
            type="text"
            name="email"
            label="Email"
            component={InputField}
            hint="Email is only required to grant the member access"
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <Field
            type="text"
            name="phoneNumber"
            label="Phone Number"
            // placeholder="234-945-4328"
            component={InputField}
            format={phoneMask}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.field}>
          <Field
            type="text"
            name="faxNumber"
            label="Fax Number"
            // placeholder="234-945-4328"
            component={InputField}
            format={phoneMask}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.field}>
          <Field
            name="labels"
            label="Member Labels"
            labelKey="longName"
            valueKey="shortName"
            hint="Labels help customize your care team member"
            component={SelectField}
            options={PROVIDER_LABELS}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
      </Grid>
      <FormFooter links={links} />
    </form>
  );
};

export default reduxForm({
  form: 'care-team-add',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(withStyles(styles)(Provider01Form));
