const staticTestName = [
  {
    shortName: '2,4 Dienoyl-CoA reductase deficiency',
  },
  {
    shortName: '2-Methyl-3-hydroxybutyric aciduria',
  },
  {
    shortName: '2-Methylbutyrylglycinuria',
  },
  {
    shortName: '3-Hydroxy-3-methyglutaric aciduria',
  },
  {
    shortName: '3-Methylcrotonyl-CoA carboxylase deficiency',
  },
  {
    shortName: '3-Methylglutaconic aciduria',
  },
  {
    shortName: 'Alanine Aminotransferase (ALT)',
  },
  {
    shortName: 'Alanine Transaminase',
  },
  {
    shortName: 'anti-HBc Test',
  },
  {
    shortName: 'anti-HBc, IgM Test',
  },
  {
    shortName: 'anti-Hbe Test',
  },
  {
    shortName: 'anti-HBs Test',
  },
  {
    shortName: 'Argininemia',
  },
  {
    shortName: 'Argininosuccinic aciduria',
  },
  {
    shortName: 'Autoimmune Deficiency Syndrom (AIDS) test',
  },
  {
    shortName: 'Benign hyperphenylalaninemia',
  },
  {
    shortName: 'Biopterin defect in cofactor biosynthesis',
  },
  {
    shortName: 'Biopterin defect in cofactor regeneration',
  },
  {
    shortName: 'Biotinidase deficiency',
  },
  {
    shortName: 'Blood Creatinine',
  },
  {
    shortName: 'Blood Sugar',
  },
  {
    shortName: 'Carnitine acylcarnitine translocase deficiency',
  },
  {
    shortName: 'Carnitine palmitoyltransferase type I deficiency',
  },
  {
    shortName: 'Carnitine palmitoyltransferase type II deficiency',
  },
  {
    shortName: 'Carnitine uptake defect/carnitine transport defect',
  },
  {
    shortName: 'CBC with Diff',
  },
  {
    shortName: 'CBC with Differential and Platelets',
  },
  {
    shortName: 'Cervical Cytology',
  },
  {
    shortName: 'Chlamydia Nucleic Acid Amplification Test (NAAT or NAT)',
  },
  {
    shortName: 'Chlamydia Testing',
  },
  {
    shortName: 'Chlamydia/GC STD Panel',
  },
  {
    shortName: 'Citrullinemia, type I',
  },
  {
    shortName: 'Citrullinemia, type II',
  },
  {
    shortName: 'Classic galactosemia',
  },
  {
    shortName: 'Classic phenylketonuria',
  },
  {
    shortName: 'Colonoscopy',
  },
  {
    shortName: 'Complete Blood Count (CBC with Diff)',
  },
  {
    shortName: 'Complete Blood Count (CBC)',
  },
  {
    shortName: 'Comprehensive Metabolic Panel',
  },
  {
    shortName: 'Computed Tomographic Colonography (CTC)',
  },
  {
    shortName: 'Congenital adrenal hyperplasia',
  },
  {
    shortName: 'COVID rapid antigen test',
  },
  {
    shortName: 'COVID-19 (test method unknown)',
  },
  {
    shortName: 'COVID-19 Ag test (nasal swab)',
  },
  {
    shortName: 'COVID-19 IgG, IgM antibody test (blood)',
  },
  {
    shortName: 'COVID-19 RT-PCR (nasal swab)',
  },
  {
    shortName: 'Creatinine',
  },
  {
    shortName: 'Critical congenital heart disease',
  },
  {
    shortName: 'Cystic fibrosis',
  },
  {
    shortName: 'Estradiol',
  },
  {
    shortName: 'Fasting Blood Glucose (FBG)',
  },
  {
    shortName: 'Fasting Blood Sugar (FBS)',
  },
  {
    shortName: 'Fasting Lipid Panel',
  },
  {
    shortName: 'Fasting Plasma Glucose (FPG)',
  },
  {
    shortName: 'Fecal Immunochemical Test (FIT)',
  },
  {
    shortName: 'Fecal Occult Blood Test (FOBT, gFOBT, or iFOBT)',
  },
  {
    shortName: 'Flexible sigmoidoscopy',
  },
  {
    shortName: 'Follicle Stimulating Hormone (FSH)',
  },
  {
    shortName: 'Galactoepimerase deficiency',
  },
  {
    shortName: 'Galactokinase deficiency',
  },
  {
    shortName: 'Glucose Tolerance Test (GTT or OGTT)',
  },
  {
    shortName: 'Glutamic-Pyruvic Transaminase (GPT)',
  },
  {
    shortName: 'Glutaric acidemia type I',
  },
  {
    shortName: 'Glutaric acidemia type II',
  },
  {
    shortName: 'Glycogen Storage Disease',
  },
  {
    shortName: 'Gonorrhea (NAAT or NAT)',
  },
  {
    shortName: 'Gonorrhea Testing',
  },
  {
    shortName: 'Gram Stain',
  },
  {
    shortName: 'HBeAg Test',
  },
  {
    shortName: 'HBsAg Test',
  },
  {
    shortName: 'HBV DNA Test',
  },
  {
    shortName: 'HBV Tests',
  },
  {
    shortName: 'Hearing loss',
  },
  {
    shortName: 'Hemoglobin A1c',
  },
  {
    shortName: 'Hep B Test',
  },
  {
    shortName: 'Hepatitis B Core Antibody Test',
  },
  {
    shortName: 'Hepatitis B e Antibody Test',
  },
  {
    shortName: 'Hepatitis B e Antigen Test',
  },
  {
    shortName: 'Hepatitis B Surface Antibody Test',
  },
  {
    shortName: 'Hepatitis B Surface Antigen Test',
  },
  {
    shortName: 'Hepatitis B Testing',
  },
  {
    shortName: 'Hepatitis C (HCV) Antibody Test',
  },
  {
    shortName: 'Hepatitis C (HCV) Anti-HCV Test',
  },
  {
    shortName: 'Hepatitis C (HCV) PCR Test',
  },
  {
    shortName: 'Hepatitis C (HCV) RNA Test',
  },
  {
    shortName: 'Hepatitis C (HCV) Viral Load Test',
  },
  {
    shortName: 'Hepatitis C Virus Genotype',
  },
  {
    shortName: 'Holocarboxylase synthase deficiency',
  },
  {
    shortName: 'Homocystinuria',
  },
  {
    shortName: 'Human Immunodeficiency Virus (HIV) test',
  },
  {
    shortName: 'Human Papillomavirus (HPV) Test',
  },
  {
    shortName: 'Hypermethioninemia',
  },
  {
    shortName: 'IGRA Tuberculosis (TB) Test',
  },
  {
    shortName: 'Interferon Gamma Release Assay',
  },
  {
    shortName: 'Iron',
  },
  {
    shortName: 'Isobutyrylglycinuria',
  },
  {
    shortName: 'Isovaleric acidemia',
  },
  {
    shortName: 'Latent Tuberculosis Infection Test',
  },
  {
    shortName: 'Lipid Panel',
  },
  {
    shortName: 'Long-chain L-3 hydroxyacyl-CoA dehydrogenase deficiency',
  },
  {
    shortName: 'Malonic acidemia',
  },
  {
    shortName: 'Mantoux',
  },
  {
    shortName: 'Maple syrup urine disease',
  },
  {
    shortName:
      'Medium/short-chain L-3-hydroxyacyl-CoA dehydrogenase deficiency',
  },
  {
    shortName: 'Medium-chain acyl-CoA dehydrogenase deficiency',
  },
  {
    shortName: 'Medium-chain ketoacyl-CoA thiolase deficiency',
  },
  {
    shortName: 'Methylmalonic acidemia (cobalamin disorders)',
  },
  {
    shortName: 'Methylmalonic acidemia (methylmalonyl-CoA mutase)',
  },
  {
    shortName: 'Methylmalonic acidemia  with homocystinuria',
  },
  {
    shortName: 'Mucopolysaccharidosis Type 1',
  },
  {
    shortName: 'Neisseria gonorrhoeae Nucleic Acid Amplification Test',
  },
  {
    shortName: 'Non-fasting Lipid Panel',
  },
  {
    shortName: 'p24 Antigen test',
  },
  {
    shortName: 'Pap Smear (Pap Test)',
  },
  {
    shortName: 'Primary congenital hypothyroidism',
  },
  {
    shortName: 'Propionic acidemia',
  },
  {
    shortName: 'Prostate Specific Antigen (PSA) test',
  },
  {
    shortName: 'Purified Protein Derivative (PPD)',
  },
  {
    shortName: 'QuantiFERON®-TB Gold In-Tube (QFT-GIT)',
  },
  {
    shortName: 'QuantiFERON®-TB Gold Plus (QFT-Plus)',
  },
  {
    shortName: 'S, βeta-thalassemia',
  },
  {
    shortName: 'S,C disease',
  },
  {
    shortName: 'S,S disease (Sickle cell anemia)',
  },
  {
    shortName: 'Serum Creatinine',
  },
  {
    shortName: 'Serum Glutamic-Pyruvic Transaminase (SGPT)',
  },
  {
    shortName: 'Severe combined Immunodeficiencies',
  },
  {
    shortName: 'Short-chain acyl-CoA dehydrogenase deficiency',
  },
  {
    shortName:
      'Spinal Muscular Atrophy due to homozygous deletion of exon 7 in SMN1',
  },
  {
    shortName: 'ß-Ketothiolase deficiency',
  },
  {
    shortName: 'TB QuantiFERON®-TB Gold (QFT-G)',
  },
  {
    shortName: 'T-cell related lymphocyte deficiencies',
  },
  {
    shortName: 'Testosterone',
  },
  {
    shortName: 'Testosterone, Free (Dialysis)',
  },
  {
    shortName: 'Thyroid Stimulating Hormone (TSH)',
  },
  {
    shortName: 'Total Iron Binding Capacity (TIBC)',
  },
  {
    shortName: 'Total Testosterone Test, LC/MS/MS',
  },
  {
    shortName: 'Trifunctional protein deficiency',
  },
  {
    shortName: 'T-Spot®',
  },
  {
    shortName: 'Tuberculosis (TB) Skin Test',
  },
  {
    shortName: 'Type II (Pompe)',
  },
  {
    shortName: 'Tyrosinemia, type I',
  },
  {
    shortName: 'Tyrosinemia, type II',
  },
  {
    shortName: 'Tyrosinemia, type III',
  },
  {
    shortName: 'Urinalysis (UA)',
  },
  {
    shortName: 'Urine Creatinine',
  },
  {
    shortName: 'Various other hemoglobinopathies',
  },
  {
    shortName: 'Very long-chain acyl-CoA dehydrogenase deficiency',
  },
  {
    shortName: 'Viral Hepatitis C Antibody Screen',
  },
  {
    shortName: 'Viral Hepatitis C RNA by PCR',
  },
  {
    shortName: 'Virtual colonoscopy',
  },
  {
    shortName: 'Vitamin D',
  },
  {
    shortName: 'X-linked Adrenoleukodystrophy',
  },
];

export default staticTestName;
