import React from 'react';
import { withStyles } from '@material-ui/core';

import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_EMOTIONS,
  FRAGMENT_BODY_METRICS,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_STRESS_MANAGEMENT,
  FRAGMENT_ACTIVITY_EXERCISE,
  FRAGMENT_MEDICATIONS,
} from './FragmentsList';

import { Dialog as WaterIntakeDialog } from './fragments/WaterIntake';
import { Dialog as BloodPressureDialog } from './fragments/BloodPressure';
import { Dialog as BodyMetricsDialog } from './fragments/BodyMetrics';
import { Dialog as SleepTrackingDialog } from './fragments/Sleep';
import { Dialog as MealTrackingDialog } from './fragments/MealTracker';
import { Dialog as ActivityExerciseDialog } from './fragments/ActivityExercise';
import { Dialog as MedicationsDialog } from './fragments/Medications';
import { Dialog as EmotionFragmentDialog } from './fragments/Emotions';
import { Dialog as StressManagementDialog } from './fragments/StressManagement';

const FragmentCompose = props => {
  const {
    classes,
    journalBody,
    journalTags,
    handleFragmentSave,
    onChangeJournalBody,
    onChangeJournalTags,
    fragment,
    user,
    toggleSaveButton,
  } = props;

  const fragmentComponents = {
    [FRAGMENT_BODY_MASS]: BodyMetricsDialog,
    [FRAGMENT_BLOOD_PRESSURE]: BloodPressureDialog,
    [FRAGMENT_WATER_INTAKE]: WaterIntakeDialog,
    [FRAGMENT_EMOTIONS]: EmotionFragmentDialog,
    [FRAGMENT_BODY_METRICS]: BodyMetricsDialog,
    [FRAGMENT_SLEEP_TRACKER]: SleepTrackingDialog,
    [FRAGMENT_MEAL_TRACKER]: MealTrackingDialog,
    [FRAGMENT_STRESS_MANAGEMENT]: StressManagementDialog,
    [FRAGMENT_ACTIVITY_EXERCISE]: ActivityExerciseDialog,
    [FRAGMENT_MEDICATIONS]: MedicationsDialog,
  };

  if (!props.fragment) {
    return null;
  }

  const FragmentDialog = fragmentComponents[fragment.fragmentType];

  return (
    <div className={classes.root}>
      <FragmentDialog
        personId={user ? user.personId : null}
        fragment={fragment}
        journalBody={journalBody}
        journalTags={journalTags}
        onChangeJournalBody={onChangeJournalBody}
        onChangeJournalTags={onChangeJournalTags}
        handleFragmentSave={handleFragmentSave}
        toggleSaveButton={toggleSaveButton}
      />
    </div>
  );
};

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
});

export default withStyles(styles)(FragmentCompose);
