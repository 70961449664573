import React, { Fragment } from 'react';
import {
  Grid,
  Icon,
  TableBody,
  TableRow,
  TableCell,
  Table,
  withStyles,
} from '@material-ui/core';
import ROLES from '../RoleSummary/data';
import { Illustration } from '..';

const styles = ({ theme }) => ({
  root: {
    border: '1px solid #CECECE',
    borderRadius: 4,
    paddingTop: 15,
    paddingLeft: 15,
    marginTop: 0,
  },
  header: {
    fontSize: 24,
    margin: 0,
    marginLeft: 8,
  },
  subHeader: {
    fontSize: 18,
    margin: '10px 0 15px',
  },
  editIcon: {
    marginRight: 8,
  },
  tableCell: {
    padding: 0,
    border: 'none',
    '&:last-child': {
      paddingRight: 10,
    },
  },
  tableRow: {
    height: 32,
    '&:nth-child(even)': {
      backgroundColor: '#F0F0F0',
    },
  },
});

const RoleSummaryDetailField = props => {
  const { classes } = props;
  const setAccessType = type => {
    if (type) {
      if (type === 'r') {
        return <Icon color="primary">remove_red_eye</Icon>;
      }
      if (type === 'rw') {
        return (
          <Fragment>
            <Icon color="secondary" className={classes.editIcon}>
              create
            </Icon>
            <Icon color="primary">remove_red_eye</Icon>
          </Fragment>
        );
      }
    }
    return <Icon>block</Icon>;
  };

  const role = props.role
    ? ROLES.find(data => data.value === props.role)
    : null;

  return (
    <div>
      {role ? (
        <Grid className={classes.root} container spacing={3}>
          <Grid item xs={5}>
            <Grid container alignItems="center">
              <Icon style={{ color: role.color }}>{role.icon}</Icon>
              <h2 className={classes.header}>{role.label}</h2>
            </Grid>
            <div className={classes.subHeader}>Abilities</div>
            <Table cellSpacing="0" cellPadding="0">
              <TableBody>
                {role.access.map(node => (
                  <TableRow className={classes.tableRow} key={node.value}>
                    <TableCell className={classes.tableCell}>
                      {node.label}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Grid container justify="flex-end">
                        {setAccessType(node.type)}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={7}>
            <Illustration svg={role.illustration} />
          </Grid>
        </Grid>
      ) : (
        <div>Select a Role</div>
      )}
    </div>
  );
};

export default withStyles(styles)(RoleSummaryDetailField);
