import React from 'react';

export default ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H5C3.9 3 3.01 3.9 3.01 5L3 19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 14H14V17C14 17.55 13.55 18 13 18H11C10.45 18 10 17.55 10 17V14H7C6.45 14 6 13.55 6 13V11C6 10.45 6.45 10 7 10H10V7C10 6.45 10.45 6 11 6H13C13.55 6 14 6.45 14 7V10H17C17.55 10 18 10.45 18 11V13C18 13.55 17.55 14 17 14Z"
      fill={fill}
    />
  </svg>
);
