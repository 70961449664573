import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
  TextField,
} from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';

const MealTrackerViewer = ({ data, classes }) => {
  const calories =
    data.manualCalories > 0 ? data.manualCalories : data.totalCalories;
  return calories ? (
    <InputField
      label="Total Calories"
      value={`${calories} ${
        !!data.totalCaloriesUnit ? data.totalCaloriesUnit : 'kcal'
      }`}
      shrink={true}
      InputProps={{ readOnly: true, disabled: true }}
      enableMinHeight={false}
    />
  ) : null;
};

export default MealTrackerViewer;
