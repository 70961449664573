import jwtDecode from 'jwt-decode';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import {
  localStorageGetObject,
  localStorageSetObject,
  apiPost,
  apiFetch,
} from '../../../library/helpers/fetch';

export const REQUEST_LOGIN = '@@redux/member/REQUEST_LOGIN';
export const RECEIVE_LOGIN = '@@redux/member/RECEIVE_LOGIN';
export const FAILURE_LOGIN = '@@redux/member/FAILURE_LOGIN';
export const LOGOUT_USER = '@@redux/member/LOGOUT_USER';

export const INITIALIZE_REQUEST = 'initialize/account/REQUEST';
export const INITIALIZE_SUCCESS = 'initialize/account/SUCCESS';
export const INITIALIZE_FAILURE = 'initialize/account/FAILURE';

export const initializeAccount = (id, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [INITIALIZE_REQUEST, INITIALIZE_SUCCESS, INITIALIZE_FAILURE],
    endpoint: `/api/member/${id}/profile-progress/initialize`,
    payload,
    meta: {
      config: {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    },
  },
});

export const loginRequest = credentials => ({
  type: REQUEST_LOGIN,
});

export const loginSuccess = user => ({
  type: RECEIVE_LOGIN,
  payload: user,
});

export const loginFailure = message => ({
  type: FAILURE_LOGIN,
  payload: message,
});

const encodeUrl = values =>
  Object.keys(values)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`)
    .join('&');

export const loginUser = credentials => {
  return async dispatch => {
    dispatch(loginRequest(credentials));

    try {
      const response = await apiFetch('/oauth2/token', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encodeUrl({ ...credentials, grant_type: 'password' }),
      });

      const json = await response.json();

      if (json.status >= 500) {
        dispatch(
          loginFailure({
            message: `${json.message}. Reference Code: ${json.referenceCode}`,
            responseType: json.responseType,
          })
        );

        return [json, undefined];
      }

      if (json.status === 400) {
        dispatch(
          loginFailure({
            message: json.message,
            responseType: json.responseType,
          })
        );

        return [json, undefined];
      }

      if (json.status === 200) {
        var decodedToken = jwtDecode(json.access_token);

        localStorageSetObject('session', json);

        var profile = localStorageGetObject('profile');

        localStorageSetObject('profile', {
          ...profile,
          avatarUrl: decodedToken.avatarUrl,
        });

        localStorageSetObject('active', {
          personId: decodedToken.personId,
        });

        dispatch(loginSuccess(decodedToken));
        return [json, decodedToken];
      }
    } catch (e) {
      dispatch(
        loginFailure({
          message:
            "We're sorry, this service is temporarily unavailable. Please try again later",
          responseType: 'NetworkError',
        })
      );

      throw e;
    }

    return [json, undefined];
  };
};

export const logoutUser = history => {
  return dispatch => {
    history.push('/'); // Push user to login page before global store is cleared to prevent any errors
    localStorage.removeItem('session');
    dispatch({ type: LOGOUT_USER });
  };
};
