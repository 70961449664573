import React, { Component } from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import ButtonGrid from '../../components/ButtonGrid';
// import MedicalRecordModal from '../../components/RequestMedicalRecord';
// import MedicalRecordPage from '../../components/RequestMedicalRecord/MedicalRecordPage';
import MedicalRecordPage from '../MedicalRecordPage/MedicalRecordPage';
import ToothButton from './medicalButtons/ToothButton';
import AssessmentButton from './medicalButtons/AssessmentButton';
import BehavioralHealthButton from './medicalButtons/BehavioralHealthButton';
import DoubleHelixButton from './medicalButtons/DoubleHelixButton';
import LocalHospitalButton from './medicalButtons/LocalHospitalButton';
import PillsButton from './medicalButtons/PillsButton';

const DISPLAY_DEMO_LINKS = true;
const DISABLE_DEMO_LINKS = true;

class MyHealthRecordPage extends Component {
  handleClick = e => {
    this.props.history.push(
      `/d/${this.props.match.params.user}/health-record/request-medical-history`
    );
  };

  // state = {
  //   modalOpen: false,
  // };

  // toggleModal = toggle => {
  //   this.setState({ ...this.state, modalOpen: toggle });
  // };

  render() {
    const {
      healthRecord,
      home,
      journal,
      matches,
    } = this.props.navigation.dashboard;

    const buttons = [
      {
        glyph: 'exclamation-triangle',
        route: healthRecord.allergies.read,
        label: 'Allergies / Adverse Reactions',
        display: true,
      },
      {
        route: '',
        label: 'Behavioral Health',
        display: DISPLAY_DEMO_LINKS,
        icon: BehavioralHealthButton,
        disabled: DISABLE_DEMO_LINKS,
      },
      {
        glyph: 'heartbeat',
        route: healthRecord.documents,
        label: 'Care Plans/ Discharge Plans',
        display: true,
        category: 'Care Plans',
      },
      {
        glyph: 'male',
        route: '',
        label: 'Dental Health',
        display: DISPLAY_DEMO_LINKS,
        disabled: DISABLE_DEMO_LINKS,
        icon: ToothButton,
      },
      {
        glyph: 'stethoscope',
        route: healthRecord.conditions.read,
        label: 'Conditions & Diagnoses',
        display: true,
        disabled: false,
        category: 'Conditions',
      },
      {
        glyph: 'users',
        route: healthRecord.familyHistory,
        label: 'Family Medical History',
        display: true,
      },
      {
        glyph: 'user',
        route: '',
        label: 'Genetic Profile',
        display: DISPLAY_DEMO_LINKS,
        disabled: DISABLE_DEMO_LINKS,
        icon: DoubleHelixButton,
      },
      {
        glyph: 'picture-o',
        route: healthRecord.documents,
        label: 'Imaging/ Scans',
        display: true,
        category: 'Scans',
      },
      {
        glyph: 'eyedropper',
        route: healthRecord.immunizations.read,
        label: 'Immunizations / Vaccinations',
        display: true,
      },
      {
        glyph: 'book',
        route: journal,
        label: 'Journal/ Health Events',
        display: DISPLAY_DEMO_LINKS,
        category: 'Labs',
      },
      {
        glyph: 'user-md',
        route: healthRecord.labs,
        label: 'Lab Tests/ Pathology/ Screening',
        display: true,
        category: 'Lab',
      },
      {
        glyph: 'gavel',
        route: healthRecord.documents,
        label: 'Legal Documents',
        display: true,
        category: 'Legal Documents',
      },
      {
        glyph: 'ambulance',
        route: '',
        label: 'Medical Care/ Treatment',
        display: DISPLAY_DEMO_LINKS,
        disabled: DISABLE_DEMO_LINKS,
      },
      {
        glyph: 'medkit',
        route: healthRecord.mvs.read,
        label: 'Medications, Vitamins, Supplements',
        display: true,
        icon: PillsButton,
      },
      {
        glyph: 'comments',
        route: '',
        label: 'Patient Communications',
        display: DISPLAY_DEMO_LINKS,
        disabled: DISABLE_DEMO_LINKS,
      },
      {
        glyph: 'stethoscope',
        route: healthRecord.surgicalHistory.read,
        label: 'Surgeries',
        display: true,
        icon: LocalHospitalButton,
      },
      {
        glyph: 'sticky-note-o',
        route: healthRecord.documents,
        label: 'Assessments/ Surveys',
        display: true,
        icon: AssessmentButton,
        category: 'Surveys',
      },
      {
        glyph: 'file',
        route: healthRecord.documents,
        label: 'My Documents',
        display: true,
      },
    ].sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return (
      <BasePage>
        {/* <MedicalRecordPage
          open={this.state.modalOpen}
          setOpen={this.toggleModal}
        /> */}
        <Grid item xs={12}>
          <PageHeader
            headerText="Health Record"
            backButtonText="Go Back"
            backLink={home}
            rightSlot={
              <Button
                variant="contained"
                style={{ float: 'right' }}
                color="primary"
                onClick={this.handleClick}
              >
                <i className="fa fa-plus" />
                &nbsp; Request My Medical History
              </Button>
            }
          />
          <Divider />
          <ButtonGrid data={buttons} />
        </Grid>
      </BasePage>
    );
  }
}

export default {
  component: MyHealthRecordPage,
};
