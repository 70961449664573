import { differenceInYears } from 'date-fns';
import { isValidSsn } from '../../../library/validations/Ssn';
import { isValidZipCode } from '../../../library/validations/ZipCode';

export default values => {
  const errors = {};

  const { ssn, dob, postalCode } = values;

  const required = [
    'firstName',
    'lastName',
    // 'ssn',
    'dob',
    // 'street',
    // 'city',
    // 'state',
    // 'postalCode',
    // 'gender',
  ];

  required.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (ssn && !isValidSsn(ssn)) {
    errors.ssn =
      'Invalid Social Security Number (SSN). Standard US format is XXX-XX-XXXX';
  }

  const dobYears = differenceInYears(new Date(), dob);
  if (dobYears > 17) {
    errors.dob =
      '17 is the maximum age allowed for a dependent. Please contact us for exceptions';
  }

  if (postalCode && !isValidZipCode(postalCode)) {
    errors.postalCode =
      'Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX';
  }

  return errors;
};
