import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const REQUEST_MEDICAL_RECORD_REQUEST = 'MedicalRecordRequest | Request';
export const REQUEST_MEDICAL_RECORD_SUCCESS = 'MedicalRecordRequest | Success';
export const REQUEST_MEDICAL_RECORD_FAILURE = 'MedicalRecordRequest | Failure';

export const createMedicalRecordRequest = payload => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      REQUEST_MEDICAL_RECORD_REQUEST,
      REQUEST_MEDICAL_RECORD_SUCCESS,
      REQUEST_MEDICAL_RECORD_FAILURE,
    ],
    endpoint: `/api/member/medical-record/request`,
    payload,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
        mode: 'cors',
      },
    },
  },
});
