import React from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    margin: '50px 0',
    padding: '25px 50px',
    [theme.breakpoints.down('lg')]: {
      margin: '20px 0',
      padding: '0px 20px',
    },
  },
  header: {
    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
    },
  },
  text: {
    lineHeight: '150%',
    marginBottom: 25,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 20,
    },
  },
  column: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
});

const TeamMemberDefaultView = ({ classes, baseRoutePath }) => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={6} className={classes.column}>
        <h2 className={classes.header}>Create Your Care Team</h2>

        <p className={classes.text}>
          Here is where you can keep track of your Care Team members and control
          their access to your information.
        </p>

        <p className={classes.text}>
          Care Team members are the people and/or organizations who play a role
          in your health and wellness. This can be your physician or preferred
          hospital, your spouse, a regular caregiver, or even your friend
          sharing a weight loss challenge with you. You decide!
        </p>

        <p className={classes.text}>
          Just select the Care Team member's role and whether you want them to
          have any access. If you choose to grant them access, their role will
          determine what information they can access. And you can change or
          withdraw their access at any time.
        </p>

        <Button
          style={{ color: '#fff' }}
          component={Link}
          to={`${baseRoutePath}/modify`}
          color="primary"
          variant="contained"
        >
          Create Your Team
        </Button>
      </Grid>
      <Grid item xs={12} md={6} className={classes.column}>
        <img
          src={require('../../../../client/svgs/care-team-default.svg')}
          alt="Care Team Default"
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TeamMemberDefaultView);
