import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import OffersPanel from './OffersPanel';
import PricingPanel from './PricingPanel';

const ResourcePanels = props => {
  const {
    resource: { prices, products },
  } = props;

  const [productsToPrices, setState] = useState([]);

  useEffect(() => {
    if (prices.length && products.length) {
      setState(
        prices.map(price => {
          const product = products.find(
            item => price.productId === item.productId
          );
          if (product) {
            return {
              name: `${product.name}`,
              price: `${price.unitPrice}${
                price.unit ? ` / ${price.unit}` : ''
              }`,
            };
          } else return price;
        })
      );
    }
  }, [prices, products]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <PricingPanel pricings={productsToPrices} />
      </Grid>
      <Grid item xs={12}>
        <OffersPanel
          offers={props.resource.offers}
          handleRedeemOffer={props.handleRedeemOffer}
        />
      </Grid>
    </Grid>
  );
};

export default ResourcePanels;
