import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { getCookie } from './util';

import Header from '../../components/Header';
import SigInFormMember from '../../containers/SignInFormMember';
import SigInFormProvider from '../../containers/SignInFormProvider';
import FlashMessage from '../../containers/FlashMessage';
import { loginUser } from '../../containers/SignInFormMember/actions';

import MessageComponent from './MessageComponent';

// eslint-disable-next-line
import indexCss from '../../index.css';
// eslint-disable-next-line
import signInPageCss from './SignInPage.css';

const styles = theme => ({
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '32px 0px',
  },
  button: {
    width: 180,
    marginLeft: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  buttonSignup: {
    margin: 'auto',
  },
  board: {
    paddingTop: theme.spacing(16),
    padding: theme.spacing(12),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(9),
    },
  },
  cantAccessButton: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(6),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  providerPaper: {
    border: 'solid 1px #eee',
  },
  splitBar: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(2) + 2,
    opacity: 0.8,
    marginRight: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: theme.spacing(2),
    borderLeft: `solid 5px ${theme.palette.warning.main}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  centeredButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    marginBottom: '16px',
  },
  flexEndAnchor: {
    display: 'flex',
    justifyContent: 'center',
  },
  dividerBorder: {
    borderBottom: '1px solid lightgray',
    width: '100%',
  },
  dividerContent: {
    paddingTop: 10,
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: 'lightgray',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },
});

const responseTypes = {
  InvalidCredentials: 'InvalidCredentials',
  LockedOut: 'LockedOut',
  NotVerified: 'NotVerified',
  NewAccount: 'NewAccount',
  EmailVerified: 'EmailVerified',
  Success: 'Success',
  ValidateOffline: 'ValidateOffline'
};

const SignInMessage = ({ classes, responseType, message }) => {
  if (!responseType || !message) return null;

  if (responseType === responseTypes.InvalidCredentials)
    return (
      <Grid className={classes.error}>
        <WarningIcon className={classes.icon} />
        {message}
      </Grid>
    );

  if (responseType === responseTypes.LockedOut)
    return (
      <Grid className={classes.error}>
        <WarningIcon className={classes.icon} />
        {message}
      </Grid>
    );

  return null;
};

const SignUpPage = props => {
  const {
    classes,
    history,
    loginUser,
    message,
    isFetching,
    responseType,
  } = props;
  const [rememberMe, setRememberMe] = useState(Boolean(getCookie('username')));
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     history.push('/d/me/home');
  //     return;
  //   }
  // }, [isAuthenticated]);

  const handleSubmit = async values => {
    const [json, token] = await loginUser(values, history);

    if (
      [responseTypes.NewAccount, responseTypes.EmailVerified, responseTypes.ValidateOffline].includes(
        json.responseType
      )
    ) {
      history.push('/registration/identity-verification', {
        userId: json.data.id,
      });
      return;
    }

    if (json.responseType === responseTypes.NotVerified) {
      console.log('Route to not verified pathway', {
        userId: json.data.id,
        ...values,
      });
      history.push('/registration/verify', { userId: json.data.id, ...values });
      return;
    }

    if (json.responseType === responseTypes.LockedOut) {
      console.log('Handle locked out user pathway');
      return;
    }

    if (json.responseType === responseTypes.Success) {
      if (rememberMe && !getCookie('username')) {
        document.cookie = `username=${values.username};`;
      } else if (!rememberMe) {
        document.cookie = `username=`;
      }
      if (token.initialized === 'False') {
        history.push('/account/initialize');
        return;
      }
      history.push('/d/me/home');
    }
  };

  const routeToHelp = () => {
    history.push();
  };

  return (
    <Grid container className={classes.board}>
      <Header title="Sign In" />

      <MessageComponent
        renderDashboardMessage={
          props.location.state && props.location.state.render_dashboard_message
        }
        {...props.location.state}
      />

      <Grid item xs={12}>
        <FlashMessage />
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={2} className={classes.paper}>
          <h3>Member Sign In</h3>
          <SigInFormMember
            form="member"
            isAuthenticating={isFetching}
            onSubmit={handleSubmit}
            rememberMe={rememberMe}
            handleRememberMe={handleRememberMe}
            messageSlot={() => (
              <SignInMessage
                classes={classes}
                responseType="InvalidCredentials"
                message={message}
              />
            )}
          />

          <div className={classes.dividerContainer}>
            <div className={classes.dividerBorder} />
            <span className={classes.dividerContent}>Not a Member?</span>
            <div className={classes.dividerBorder} />
          </div>

          <div className={classes.buttonWrapper}>
            <Button
              className={classes.buttonSignup}
              variant="contained"
              color="primary"
              size="large"
              component={props => (
                <Link to="/registration/member-details" {...props} />
              )}
              style={{
                color: '#fff',
              }}
            >
              Sign Up for FREE
            </Button>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper
          elevation={0}
          className={classNames(classes.paper, classes.providerPaper)}
        >
          <h3>Provider Sign In</h3>
          <SigInFormProvider form="provider" />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.centeredButton}>
          <Grid container spacing={1} className={classes.flexEndAnchor}>
            <Grid item>
              <Typography style={{ color: '#535461' }}>
                Can't access your account?
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <a
                  style={{ display: 'table-cell' }}
                  href="https://www.agrinhealth.com/support"
                  target="_blank"
                >
                  Contact Support
                </a>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => ({
  message: state.member.login.message,
  responseType: state.member.login.responseType,
  isFetching: state.member.login.isFetching,
  isAuthenticated: state.member.login.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  loginUser: (credentials, history) =>
    dispatch(loginUser(credentials, history)),
});

export default {
  component: withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpPage))
  ),
};
