export const styles = theme => {
  return {
    card: {
      borderRadius: 6,
      boxShadow: '0px 2px 10px rgba(83,86,94,.25)',
      overflow: 'hidden',
      padding: 24,
      position: 'relative',
      height: '100%',
      transition: 'all .2s',
    },
    overlay: {
      backgroundColor: 'rgba(68,68,71,.7)',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      transition: 'all 0.2s',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 1,
      },
    },
    icons: {
      position: 'absolute',
      top: 15,
      right: 15,
      zIndex: 10,
    },
    cardBottom: {
      marginTop: 16,
    },
    icon: {
      marginRight: 8,
    },
    topBar: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.primary.main,
    },
    topBarExpanded: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,

      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    expandButtonContainer: { position: 'absolute', top: '90px', right: '8px' },
  };
};
