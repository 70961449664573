import React from 'react';

export default props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#90B850" />
    <path
      d="M9.18605 22.7645L14.4861 17.4645L17.7361 20.7145C18.1461 21.1245 18.8061 21.1045 19.1861 20.6745L26.3561 12.6045C26.7061 12.2145 26.6861 11.6145 26.3161 11.2345C25.9161 10.8345 25.2461 10.8445 24.8661 11.2745L18.4761 18.4545L15.1861 15.1645C14.9992 14.9773 14.7456 14.872 14.4811 14.872C14.2165 14.872 13.9629 14.9773 13.7761 15.1645L7.68605 21.2645C7.29605 21.6545 7.29605 22.2845 7.68605 22.6745L7.77605 22.7645C8.16605 23.1545 8.80605 23.1545 9.18605 22.7645Z"
      fill="white"
    />
  </svg>
);
