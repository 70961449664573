import React from 'react';
import Form from '../LabsForm';
import SubmitViews from './SubmitViews';
import DocumentViews from './DocumentViews';
import { UPDATE, CREATE, IDLE, VIEW, SUBMIT } from '../hooks';

const Contents = props => {
  const { mediaObject, status } = props;
  switch (status) {
    case IDLE:
      return '';
    case UPDATE:
    case CREATE: {
      return (
        <Form
          onCancel={props.onCloseDrawer}
          onChange={() => {}}
          onSubmit={props.onSubmit}
          matches550={!props.matches550}
        />
      );
    }
    case SUBMIT: {
      return <SubmitViews mediaObject={mediaObject} {...props} />;
    }
    case VIEW:
      return <DocumentViews {...props} />;
  }
};

export default Contents;
