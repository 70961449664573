import React, { useState, useEffect } from 'react';
import FilterTextField from '../../../../../library/forms/FilterTextField';
import DateRangeFilter from '../../../../../library/forms/DateRangeFilter';
import { CircularProgress, Grid, Divider, Fade } from '@material-ui/core';
import { sortByStatus } from '../../index.js';

export default ({ filter, handleChange, setFilter, sortBy }) => {
  const { CATEGORIES, DATE, TITLE } = sortByStatus;
  const renderDocumentFilter = () => {
    switch (sortBy) {
      case DATE: {
        const { startDate = null, endDate = null } = filter;
        return (
          <DateRangeFilter
            onChange={setFilter}
            startDate={startDate}
            endDate={endDate}
          />
        );
      }
      case CATEGORIES:
      case TITLE:
      default:
        return (
          <FilterTextField
            placeholder={sortBy === CATEGORIES ? 'Categories' : 'Title'}
            value={filter}
            handleChange={handleChange}
            handleClear={() => setFilter('')}
          />
        );
    }
  };

  return (
    <FilterTextField
      placeholder=" Category | Title | Description "
      value={filter}
      handleChange={handleChange}
      handleClear={() => setFilter('')}
    />
  );
};
