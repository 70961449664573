import { SESSION_EXPIRED } from './app/actions';

import { combineReducers } from 'redux';
import allergenReducer from '../../shared/pages/AllergyPage/AllergyForm/reducers';
import userAllergyReducer from '../../shared/pages/AllergiesReactions/reducers';
import helpMessageReducer from '../../shared/pages/HelpPage/reducers';
import loginMemberReducer from '../../shared/containers/SignInFormMember/reducers';
import userImmunizationsReducer from './immunizations/reducers';
import userBasicInfoReducer from './person/reducers';
import accountSecurityReducer from '../../shared/pages/SecurityPage/reducers';
import insuranceReducer from '../../shared/pages/InsurancePolicyPage/reducers';
import emergencyInfoReducer from '../../shared/pages/EmergencyContactPage/reducers';
import userMedicalReducer from '../reducers/mvs/reducers';
import userProfileProgressReducer from '../../shared/pages/DashboardHomePage/reducers';
import applicationInvitationReducer from '../reducers/application-invitation/reducers';
import userAgreements from '../reducers/agreements/reducers';

const rootReducer = combineReducers({
  allergen: allergenReducer,
  userAllergy: userAllergyReducer,
  helpMessage: helpMessageReducer,
  login: loginMemberReducer,
  userImmunizations: userImmunizationsReducer,
  basicInfo: userBasicInfoReducer,
  accountSecurity: accountSecurityReducer,
  insurance: insuranceReducer,
  emergencyInfo: emergencyInfoReducer,
  medication: userMedicalReducer,
  userProfileProgress: userProfileProgressReducer,
  invitation: applicationInvitationReducer,
  agreements: userAgreements,
});

export default (state, action) =>
  action.type === SESSION_EXPIRED
    ? rootReducer(undefined, action)
    : rootReducer(state, action);
