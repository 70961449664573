import React from 'react';
import { Typography, Tooltip, withStyles, Grid } from '@material-ui/core';

export const VERIFIED = 'Verified';
export const UNVERIFIED = 'Unverified';
export const SELF_ATTESTED = 'SelfAttested';

const WhiteTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: '#fff',
    minWidth: '500px',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
})(Tooltip);

const StatusField = ({ isVerified, isSelfAttested }) => {
  return (
    <Grid
      xs={12}
      item
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '-16px',
        marginBottom: '8px',
      }}
    >
      <Typography style={{ whiteSpace: 'pre' }}>Status: </Typography>
      <WhiteTooltip
        arrow
        title={
          <div style={{ padding: '16px' }}>
            <Typography style={{ textAlign: 'center', paddingBottom: '8px' }}>
              Verification
            </Typography>
            <Typography style={{ textAlign: 'justified' }}>
              &nbsp; <span style={{ color: '#36B37E' }}>Verified</span> records
              are those which have confirmation from the source (for example:
              the lab that ran a test or your physician’s EMR).
            </Typography>
            <Typography>
              &nbsp; <span style={{ color: '#36B37E' }}>Self-Attested</span>{' '}
              records are unverified records that have been specifically
              attested to as accurate by the record owner. There are situations
              where you may need to share your health status credentials (such
              as going through customs when traveling internationally, or with
              an employer for return to in-person work). In the event your
              health status credentials must be verified in order to be
              accepted, we track that in your health records for you.
            </Typography>
          </div>
        }
        placement="left-start"
      >
        <Typography
          style={{
            color: !isSelfAttested && !isVerified ? '#00B8D9' : '#36B37E',
            textTransform: 'capitalize',
          }}
        >
          {isVerified
            ? 'Verified'
            : isSelfAttested
            ? 'Self Attested'
            : 'Unverified'}
        </Typography>
      </WhiteTooltip>
    </Grid>
  );
};

export default StatusField;
