// import differenceInYears from 'date-fns/differenceInYears';
import { isAfter, differenceInYears } from 'date-fns';
import { simpleEmailValidation } from '../validations/EmailAddress';
import { isValidPassword } from '../validations/Password';
import { isValidZipCode } from '../validations/ZipCode';

export const required = message => value => (!value ? message : undefined);

export const minLength = (length, message) => value =>
  value && value.length < length
    ? message.replace('{{min}}', length).replace('{{length}}', value.length)
    : undefined;

export const maxLength = (length, message) => value =>
  value && value.length > length
    ? message.replace('{{max}}', length).replace('{{length}}', value.length)
    : undefined;

export const minAge = (age, message) => value => {
  return value && differenceInYears(new Date(), new Date(value)) < age
    ? message.replace('{{age}}', age)
    : undefined;
};

export const maxAge = (age, message) => value =>
  value && differenceInYears(new Date(), new Date(value)) > age
    ? message.replace('{{age}}', age).replace('{{value}}', value)
    : undefined;

export const futureDate = (date, message) => value =>
  value && date && isAfter(value, date)
    ? message.replace('{{value}}', value).replace('{{maxDate}}', date)
    : undefined;

export const compare = (compareValue, message) => value =>
  !(value && compareValue && value === compareValue)
    ? message
        .replace('{{value}}', value)
        .replace('{{compareValue}}', compareValue)
    : undefined;

export const compareString = (string, message) => value =>
  string && value
    ? compare(string.toLowerCase(), message)(value.toLowerCase())
    : undefined;

export const zipCode = message => value =>
  value && !isValidZipCode(value)
    ? message.replace('{{value}}', value)
    : undefined;

export const email = message => value =>
  value && !simpleEmailValidation(value)
    ? message.replace('{{value}}', value)
    : undefined;

export const password = message => value =>
  value && !isValidPassword(value) ? message : undefined;

export const validate = (...validators) => value => {
  let message = '';
  for (let i = 0; i < validators.length; i++) {
    message = validators[i](value);
    if (message) return message;
  }
};
