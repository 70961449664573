import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  CircularProgress,
  Collapse,
  Fade,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import Slider from "./Slider"
import Slider from '../../../containers/Slider';
import CustomCard from './CustomCard';

const Sliders = props => {
  const {
    hasFavorites,
    listIds,
    resources,
    categories,
    categoryListIds,
    classes,
    filter,
    recommendations,
    hasRecommendations,
  } = props;

  const FavoritesCard = props => (
    <CustomCard fromFavoritesSlider={true} {...props} />
  );
  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid
        item
        xs={12}
        style={{
          background: '#DADADA',
        }}
      >
        <Collapse
          in={hasRecommendations}
          key="recommendations-slider"
          mountOnEnter
          unmountOnExit
        >
          <div style={{ padding: '16px 16px 0px 16px' }}>
            <Typography style={{ color: '#0068A8' }}>
              Recommended for you
            </Typography>
          </div>
          <div style={{ padding: '16px 16px 0px 16px' }}>
            <Slider
              listIds={recommendations.filter(
                id => resources[id] && !resources[id].isFavorite
              )}
              childElement={FavoritesCard}
              filter={filter}
              list={resources}
              newId={false}
              hasUpdates={false}
            />
          </div>
        </Collapse>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          background: '#DADADA',
          border: 'none',
          borderTop: '2px solid rgb(0,104,168)',
        }}
      >
        <Collapse
          in={hasFavorites}
          key="favorites-slider"
          mountOnEnter
          unmountOnExit
        >
          <div style={{ padding: '16px 16px 0px 16px' }}>
            <Typography style={{ color: '#0068A8' }}>My Favorites</Typography>
          </div>
          <div style={{ padding: '16px 16px 0px 16px' }}>
            <Slider
              listIds={listIds
                .filter(id => resources[id] && resources[id].isFavorite)
                .sort(function(a, b) {
                  if (resources[a].title < resources[b].title) {
                    return -1;
                  }
                  if (resources[a].title > resources[b].title) {
                    return 1;
                  }
                  return 0;
                })}
              childElement={FavoritesCard}
              filter={filter}
              list={resources}
              newId={false}
              hasUpdates={false}
            />
          </div>
        </Collapse>
      </Grid>
      {categories
        .sort((a, b) =>
          a.toUpperCase() < b.toUpperCase()
            ? -1
            : a.toUpperCase() > b.toUpperCase()
            ? 1
            : 0
        )
        .map((cat, index) => {
          const categoryListIds = listIds
            .filter(
              id =>
                resources[id].categories &&
                resources[id].categories.includes(cat)
            )
            .sort(function(a, b) {
              if (resources[a].title < resources[b].title) {
                return -1;
              }
              if (resources[a].title > resources[b].title) {
                return 1;
              }
              return 0;
            });

          return (
            <Grid key={`slider-${cat}`} item xs={12}>
              <Collapse in={categoryListIds.length} mountOnEnter unmountOnExit>
                <div style={{ padding: '16px 16px 0px 16px' }}>
                  <Typography style={{ color: '#0068A8' }}> {cat} </Typography>
                </div>
                <div style={{ padding: '16px 16px 0px 16px' }}>
                  <Slider
                    listIds={categoryListIds}
                    childElement={CustomCard}
                    filter={filter}
                    list={resources}
                    newId={false}
                    hasUpdates={false}
                    key={index + cat}
                  />
                </div>
              </Collapse>
            </Grid>
          );
        })}
    </Grid>
  );
};

Sliders.propTypes = {
  hasFavorites: PropTypes.bool.isRequired,
  listIds: PropTypes.array,
  resources: PropTypes.array,
  categories: PropTypes.array,
  categoryListIds: PropTypes.array,
  filter: PropTypes.string,
  recommendations: PropTypes.array,
  hasRecommendations: PropTypes.bool,
};

export default Sliders;
