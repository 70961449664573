import React from 'react';
import { withRouter } from 'react-router';
import {
  Divider,
  Container,
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core';

import PageHeader from '../../components/PageHeader';
import BasePage from '../../pages/BasePage';
import MedicalRecordForm from '../../components/RequestMedicalRecord/MedicalRecordForm';
import CompletedRequest from './CompletedRequest';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    padding: 0,
    borderRadius: 6,
    position: 'relative',
  },
  text: {
    marginBottom: theme.spacing(2),
    opacity: 0.7,
  },
}));

const MedicalRecordPage = ({ history, navigation: { dashboard } }) => {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(false);

  return (
    <BasePage>
      <Grid item xs={12}>
        <PageHeader
          headerText="Medical Records"
          subHeaderText="Request My Medical Record"
          backButtonText="Go Back"
          backLink={dashboard.healthRecord.self}
        />
        <Divider style={{ marginTop: 24, marginBottom: 12 }} />
      </Grid>
      <Grid item sm={12}>
        <Container maxWidth="lg" className={classes.root}>
          {completed ? (
            <CompletedRequest history={history} />
          ) : (
            <React.Fragment>
              <Typography className={classes.text}>
                Here you can instruct Agrin Health to contact your Provider on
                your behalf to collect your medical information and bring it
                into your Agrin Health. This is a paid service available from
                Agrin Health. You will be responsible for the $40 Agrin Health
                service fee (and any Provider service fees*) only if your
                Provider is willing and able to release the information you
                request.
              </Typography>
              <Typography className={classes.text}>
                *IMPORTANT TO KNOW: By law (under the Health Insurance
                Portability & Accountability Act known as{' '}
                <abbr title="Health Insurance Portability and Accountability Act">
                  HIPAA
                </abbr>
                ) your Provider may charge you for any labor associated with
                providing your records to you. Medical records stored a
                certified <abbr title="Electronic Medical Record">EMR</abbr> are
                deemed to require no labor and should not incur a fee.
              </Typography>
              <MedicalRecordForm setCompleted={setCompleted} />
            </React.Fragment>
          )}
        </Container>
      </Grid>
    </BasePage>
  );
};

export default {
  component: withRouter(MedicalRecordPage),
};
