import { apiDelete } from '../../../library/helpers/fetch';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { setMediaObjects } from '../../../store/reducers/mediaObject/actions';

export const INSURANCE_REQUEST = '@@redux/insurance/INSURANCE_REQUEST';
export const INSURANCE_SUCCESS = '@@redux/insurance/INSURANCE_SUCCESS';
export const INSURANCE_FAILURE = '@@redux/insurance/INSURANCE_FAILURE';

export const INSURANCE_CREATE_REQUEST =
  '@@redux/insurance/INSURANCE_CREATE_REQUEST';
export const INSURANCE_CREATE_SUCCESS =
  '@@redux/insurance/INSURANCE_CREATE_SUCCESS';
export const INSURANCE_CREATE_FAILURE =
  '@@redux/insurance/INSURANCE_CREATE_FAILURE';

export const INSURANCE_UPDATE_REQUEST =
  '@@redux/insurance/INSURANCE_UPDATE_REQUEST';
export const INSURANCE_UPDATE_SUCCESS =
  '@@redux/insurance/INSURANCE_UPDATE_SUCCESS';
export const INSURANCE_UPDATE_FAILURE =
  '@@redux/insurance/INSURANCE_UPDATE_FAILURE';

export const INSURANCE_DELETE_REQUEST =
  '@@redux/insurance/INSURANCE_DELETE_REQUEST';
export const INSURANCE_DELETE_SUCCESS =
  '@@redux/insurance/INSURANCE_DELETE_SUCCESS';
export const INSURANCE_DELETE_FAILURE =
  '@@redux/insurance/INSURANCE_DELETE_FAILURE';

export const INSURANCE_SELECT_ID = 'insurance/SELECT_ID';
export const INSURANCE_CLEAR_ID = 'insurance/CLEAR_ID';
export const MEDIA_REMOVE_REQUEST = 'insurance/media/remove/request';
export const MEDIA_REMOVE_FAILURE = 'insurance/media/remove/failure';
export const MEDIA_REMOVE_SUCCESS = 'insurance/media/remove/success';

export const insuranceSelectId = id => ({
  type: INSURANCE_SELECT_ID,
  payload: id,
});

export const insuranceClearSelectedId = () => ({
  type: INSURANCE_CLEAR_ID,
});

export const fetchInsurances = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [INSURANCE_REQUEST, INSURANCE_SUCCESS, INSURANCE_FAILURE],
    endpoint: `/api/member/insurance`,
  },
});

export const insuranceCreate = (pid, insurance, bundles) => {
  const payload = {
    ...insurance,
    personId: pid,
    coverageType: insurance.coverageType ? insurance.coverageType : '',
    coverageStartOn: insurance.coverageStartOn,
    coverageEndOn: insurance.coverageEndOn,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        INSURANCE_CREATE_REQUEST,
        INSURANCE_CREATE_SUCCESS,
        INSURANCE_CREATE_FAILURE,
      ],
      endpoint: `/api/member/insurance`,
      payload,
      meta: {
        mediaObject: setMediaObjects(
          bundles.filter(value => !!value),
          'insuranceId',
          'insurance_card'
        ),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(payload),
        },
      },
    },
  };
};

export const insuranceUpdate = (pid, insurance, bundles) => {
  const payload = {
    ...insurance,
    personId: pid,
    coverageType: insurance.coverageType ? insurance.coverageType : '',
    coverageStartOn: insurance.coverageStartOn,
    coverageEndOn: insurance.coverageEndOn,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        INSURANCE_UPDATE_REQUEST,
        INSURANCE_UPDATE_SUCCESS,
        INSURANCE_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/insurance`,
      payload,
      meta: {
        mediaObject: setMediaObjects(
          bundles.filter(value => !!value),
          'insuranceId',
          'insurance_card'
        ),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(payload),
        },
      },
    },
  };
};

export const insuranceDelete = insurance => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      INSURANCE_DELETE_REQUEST,
      INSURANCE_DELETE_SUCCESS,
      INSURANCE_DELETE_FAILURE,
    ],
    endpoint: `/api/member/insurance/${insurance.insuranceId}`,
    meta: {
      config: {
        method: 'DELETE',
        mode: 'cors',
        body: insurance.insuranceId,
      },
    },
  },
});

export const deleteExistingMedia = (
  mediaObjectId,
  objectId,
  referenceId = null
) => async dispatch => {
  dispatch({
    type: MEDIA_REMOVE_REQUEST,
    payload: {
      mediaObjectId,
      objectId,
      referenceId,
    },
  });

  const api = referenceId
    ? `/api/media/immunization/${mediaObjectId}/reference/${referenceId}`
    : `/api/media/${mediaObjectId}`;
  const response = await apiDelete(api, {
    mode: 'cors',
  });

  const json = await response.json();
  if (response.status === 200) {
    dispatch({ type: MEDIA_REMOVE_SUCCESS, payload: mediaObjectId });
  } else if (response.status >= 400) {
    dispatch({ type: MEDIA_REMOVE_FAILURE, payload: mediaObjectId });
  }
  return json;
};
