import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, withStyles } from '@material-ui/core';

const styles = {
  line: {
    color: '#444447',
    lineHeight: '180%',
  },
  icon: {
    color: '#444447',
    marginTop: 2,
  },
  iconContainer: {
    marginRight: 8,
  },

  input: {
    width: '100%',
    fontSize: 14,
    padding: 4,
    borderRadius: 4,
    border: '1px solid #ccc',
    outline: 'none',
  },
};

const KEY_ENTER = 13;

const MemberDetailField = ({
  icon,
  member,
  dataLine,
  dataForm,
  classes,
  onSubmit,
  isEditable,
}) => {
  const wrapperRef = useRef();
  const inputRef = useRef();

  const [value, setValue] = useState(isEditable ? dataLine : null);

  const [isEditing, setIsEditing] = useState(false);

  const prevFieldValue = isEditable ? dataLine : dataLine;
  const nextFieldValue = isEditable ? value : dataLine;

  const handlePress = e => {
    if (e.keyCode === KEY_ENTER) {
      setIsEditing(false);

      if (prevFieldValue !== nextFieldValue)
        // submit to API
        onSubmit(data);
    }
  };

  const handleClick = e => {
    if (isEditable && isEditing && !wrapperRef.current.contains(e.target)) {
      setIsEditing(false);

      if (prevFieldValue !== nextFieldValue) {
        // submit to API
        onSubmit(data);
      }
    }
  };

  const onFieldChange = e => setValue(e.target.value);

  const activateField = () => {
    if (isEditable) {
      setIsEditing(true);
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  useEffect(() => {
    if (isEditable) {
      document.addEventListener('click', handleClick);
      document.addEventListener('keyup', handlePress);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keyup', handlePress);
      }
    };
  }, [value, dataLine, isEditing]);

  return (
    <div onClick={activateField} ref={wrapperRef}>
      <Grid container>
        <Grid className={classes.iconContainer} item>
          <Icon className={classes.icon}>{icon}</Icon>
        </Grid>
        <Grid item>
          {!isEditable ? (
            <Fragment>
              <div className={classes.line}>{dataLine}</div>
            </Fragment>
          ) : isEditing ? (
            <input
              {...dataForm}
              className={classes.input}
              onChange={onFieldChange}
              value={value}
              ref={inputRef}
            />
          ) : (
            <Fragment>
              <div className={classes.line}>{dataLine}</div>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

MemberDetailField.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};

MemberDetailField.defaultProps = {
  isEditable: false,
};

export default withStyles(styles)(MemberDetailField);
