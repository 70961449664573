import React from 'react';
import { InputAdornment, Tooltip, ClickAwayListener } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const HoverTooltip = ({ text, placement }) => {
  return (
    <InputAdornment style={{ whiteSpace: 'pre-wrap' }} disableTypography>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        placement={placement ? placement : 'bottom'}
        style={{
          cursor: 'pointer',
          whiteSpace: 'pre-wrap',
        }}
        title={
          <React.Fragment>
            <div style={{ margin: '5px' }}>{text}</div>
          </React.Fragment>
        }
      >
        <InfoIcon fontSize="small" />
      </Tooltip>
    </InputAdornment>
  );
};

export default HoverTooltip;
