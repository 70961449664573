import React from 'react';

export default props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#90B850" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8492 15.1421C22.2592 16.7321 20.1092 17.2321 18.5792 16.5221L17.1092 17.9921L23.2892 24.1721C23.6792 24.5621 23.6792 25.1921 23.2892 25.5821C22.8992 25.9721 22.2692 25.9721 21.8792 25.5821L15.6992 19.4021L9.51922 25.5621C9.12922 25.9521 8.49922 25.9521 8.10922 25.5621C7.71922 25.1721 7.71922 24.5421 8.10922 24.1521L17.1592 15.1021C16.4492 13.5721 16.9492 11.4221 18.5392 9.83208C20.4592 7.92208 23.1992 7.56208 24.6592 9.02208C26.1292 10.4921 25.7592 13.2321 23.8492 15.1421ZM14.6292 15.5021L11.7992 18.3321L7.60922 14.1521C6.37922 12.9121 6.11922 11.0821 6.82922 9.59208C7.12922 8.97208 7.95922 8.84208 8.43922 9.32208L14.6292 15.5021Z"
      fill="white"
    />
  </svg>
);
