import React, { useEffect, useState, useRef } from 'react';
import Fade from '@material-ui/core/Fade';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

function UseErrorSnackbarHOC(props) {
  const { error, open, onClose } = props;
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (open !== active) setActive(open);
  }, [open]);

  const handleClose = () => {
    setActive(false);
    onClose();
  };

  return (
    <div>
      {props.children}
      <Fade in={active} mountOnEnter unmountOnExit>
        <div style={{ position: 'fixed', right: 20, bottom: 20 }}>
          <Alert
            onClose={handleClose}
            style={{ background: '#fff192' }}
            severity="warning"
          >
            {error}
          </Alert>
        </div>
      </Fade>
    </div>
  );
}

export default UseErrorSnackbarHOC;
