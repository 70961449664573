import React from 'react';
import SyncPermissionsSVG from './SyncPermissionsSVG';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';

const SyncPermissionsEmpty = ({ onClick }) => {
  const matches = useMediaQuery('(max-width:600px)');
  const matches1200 = useMediaQuery('(max-width:1200px)');
  return (
    <div>
      <Fade in={true} timeout={120}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SyncPermissionsSVG />
          </Grid>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {matches1200 ? (
              <Grid container spacing={1}>
                <Grid item xs={matches ? 12 : 10} md={10} lg={11}>
                  <Typography style={{ maxWidth: '670px' }}>
                    You are not currently exchanging data with any device, app,
                    electronic medical record (EMR), or data exchange service.
                    Click “Get Started” to browse our list of approved partners
                    and add a connection. You can withdraw your permission (and
                    stop the data sync) at any time.
                  </Typography>
                </Grid>
                <Grid item xs={matches ? 12 : 2} md={2} lg={1}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      padding: matches ? '0px 4px' : '16px',
                    }}
                  >
                    <Button
                      onClick={onClick}
                      size="small"
                      color="secondary"
                      variant="contained"
                      style={{
                        color: '#ffffff',
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ maxWidth: '670px' }}>
                  You are not currently exchanging data with any device, app,
                  electronic medical record (EMR), or data exchange service.
                  Click “Get Started” to browse our list of approved partners
                  and add a connection. You can withdraw your permission (and
                  stop the data sync) at any time.
                </Typography>
                <Button
                  onClick={onClick}
                  size="small"
                  color="secondary"
                  variant="contained"
                  style={{ margin: '0px 16px', color: '#ffffff' }}
                >
                  Get Started
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
};

export default SyncPermissionsEmpty;
