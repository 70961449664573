import React, { useEffect, useState } from 'react';
import DashboardMessage from '../../../components/DashboardContent/DashboardMessage';
import { makeStyles } from '@material-ui/core/styles';
import Sliders from './Sliders';
import ResourceCardView from './ResourceCardView';
import { connect } from 'react-redux';
import BaseLoader from '../../../components/BaseLoader';

const useStyles = makeStyles({
  root: {
    // width: '100%',
    // overflow: 'hidden',
  },
});

const filterByQuery = (value, query) => {
  if (!Boolean(value && value.length)) return false;
  if (value.constructor === Array) {
    return Boolean(
      value.find(x => x.toLowerCase().includes(query.toLowerCase()))
    );
  }
  if (typeof value === 'string') {
    return value && query && value.toLowerCase().includes(query.toLowerCase());
  }
};

const SliderContainer = props => {
  const {
    resourceList = {},
    listIdsProp = [],
    error,
    hasRecords,
    hasFetched,
    categories,
    filter,
    recommendations,
  } = props;

  const [listIds, setListIds] = useState([]);
  const [categoryListIds, setCategoryListIds] = useState([]);
  const [hasFavorites, setHasFavorites] = useState(false);

  const [machine, setMachine] = useState('loading');
  const classes = useStyles();

  useEffect(() => {
    if (hasRecords && listIds.length > 0 && categoryListIds.length > 0)
      setMachine('success');
  }, [listIds, categoryListIds]);

  useEffect(() => {
    if (hasFetched && !hasFavorites && listIds.length === 0 && !hasRecords)
      setMachine('empty');
  }, [hasFavorites, categoryListIds, hasFetched]);

  useEffect(() => {
    if (!!error && error.length > 0) setMachine('error');
  }, [error]);

  useEffect(() => {
    if (hasFetched && categories.length > 0) {
      let hasFavBool = false;
      const entities = categories.map(cat =>
        listIds.filter(id => {
          if (!hasFavBool && resourceList[id].isFavorite) hasFavBool = true;
          return resourceList[id].categories.includes(cat);
        })
      );
      setHasFavorites(hasFavBool);
      setCategoryListIds(entities);
    }
  }, [hasFetched, listIds, resourceList]);

  useEffect(() => {
    if (hasFetched && hasRecords) {
      setListIds(listIdsProp);
    } else if (!hasFetched) {
      setMachine('loading');
    }
  }, [hasFetched]);

  useEffect(() => {
    if (machine === 'success' || machine === 'empty') {
      let hasFavBool = false;
      const filteredId = listIdsProp.filter(id => {
        if (!hasFavBool && !!resourceList[id].isFavorite) hasFavBool = true;
        return (
          filterByQuery(resourceList[id].title, filter) ||
          filterByQuery(resourceList[id].categories, filter) ||
          filterByQuery(resourceList[id].description, filter) ||
          filterByQuery(resourceList[id].tags, filter)
        );
      });
      setHasFavorites(hasFavBool);
      setListIds(filter.length > 0 ? filteredId : listIdsProp);
    }
  }, [filter]);

  const renderSlider = () => {
    switch (machine) {
      case 'loading':
        return <BaseLoader loadingText="Loading Resources" />;
      case 'success':
        return filter.length ? (
          <ResourceCardView listIds={listIds} list={resourceList} />
        ) : (
          <Sliders
            hasFavorites={hasFavorites}
            listIds={listIds}
            resources={resourceList}
            categories={categories}
            categoryListIds={categoryListIds}
            classes={classes}
            filter={filter}
            hasRecommendations={Boolean(recommendations.length)}
            recommendations={recommendations}
          />
        );
      case 'error':
        return (
          <div style={{ margin: '10px' }}>
            <DashboardMessage canClose={false} type="warning">
              {error}
            </DashboardMessage>
          </div>
        );
      case 'empty':
        return (
          <div style={{ margin: '10px' }}>
            <DashboardMessage canClose={false}>
              <strong> No Records Found </strong>
            </DashboardMessage>
          </div>
        );
      default:
        return null;
    }
  };

  return renderSlider();
};

const mapStateToProps = state => ({
  resourceList: state.resourceLibrary.resourceList,
  listIdsProp: state.resourceLibrary.listIds,
  isFetching: state.resourceLibrary.isFetching,
  hasRecords: state.resourceLibrary.hasRecords,
  hasFetched:
    state.resourceLibrary.hasFetched &&
    state.resourceLibrary.hasFetchedFavorites,
  error: state.resourceLibrary.error,
  categories: state.resourceLibrary.categories,
  recommendations: state.resourceLibrary.recommendations,
});

export default connect(mapStateToProps, null)(SliderContainer);
