import React from 'react';
import EmptyCard from './EmptyCard';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { parseDate } from '../../../../../library/utils/date';
import { format } from 'date-fns';
import { useStyles } from './useStyles';
const DATE_FORMAT = 'MMM d, yyyy';
import {
  UNVERIFIED,
  VERIFIED,
  SELF_ATTESTED,
} from '../../../../components/StatusField';

export default function LabResultCard({
  labResult,
  isEmpty,
  onClickViewAll,
  viewRoute,
}) {
  const classes = useStyles();
  const renderViews = () => {
    if (isEmpty) {
      return <EmptyCard onClick={() => onClickViewAll(viewRoute, true)} />;
    }
    return <Content labResult={labResult} />;
  };
  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12} className={classes.fieldColumn}>
        <Typography component="h5" variant="h5" className={classes.header}>
          Lab Tests / Screenings
        </Typography>
        <Button
          onClick={() => onClickViewAll(viewRoute)}
          style={{ color: '#F8F8F8' }}
        >
          View All
        </Button>
      </Grid>
      {renderViews()}
    </Grid>
  );
}

const Content = ({
  labResult: {
    collectedOn,
    orderedOn,
    result,
    healthStatus,
    orderedBy,
    testName,
    recordStatus,
  },
}) => {
  const classes = useStyles();
  const isSelfAttested = recordStatus && recordStatus === SELF_ATTESTED;
  const isVerified = recordStatus && recordStatus === VERIFIED;
  return (
    <Grid item xs={12}>
      <Paper square={true} elevation={4}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} className={classes.statusField}>
            <Typography component="h5" variant="h5" style={{ padding: '8px' }}>
              <span className={classes.statusText}>Status:</span>{' '}
              <span className={classes.statusValue}>{healthStatus}</span>{' '}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.cardContent}>
            <div className={classes.fieldColumn}>
              <Typography variant="body1" className={classes.boldText}>
                {result}
              </Typography>
              <Typography variant="body1" className={classes.boldText}>
                As of:{' '}
                {collectedOn
                  ? format(parseDate(collectedOn, false), DATE_FORMAT)
                  : null}
              </Typography>
            </div>
            <div className={classes.fieldColumn}>
              <Typography variant="body1" className={classes.text}>
                {testName}
              </Typography>
            </div>
            <div className={classes.fieldColumn}>
              <Typography variant="body1" className={classes.text}>
                {orderedBy}
              </Typography>

              <Typography
                variant="body1"
                className={classes.text}
                style={{
                  color: !isSelfAttested && !isVerified ? '#00B8D9' : '#36B37E',
                  textTransform: 'capitalize',
                  fontWeight: 500,
                }}
              >
                {isVerified
                  ? 'Verified'
                  : isSelfAttested
                  ? 'Self Attested'
                  : 'Unverified'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
