import React from 'react';
import styles from './Header.css';
import cls from 'classnames';

const Header = ({ isDragActive }) => {
  return (
    <div
      className={cls({
        [styles.header]: true,
        [styles.headerActive]: isDragActive,
      })}
    >
      <div className={styles.headerGliphicon}>
        <img
          className={styles.dzOne}
          src={require('../../../client/images/dropzone/dz-left.png')}
          alt="Files"
        />
        <img
          className={styles.dzTwo}
          src={require('../../../client/images/dropzone/dz-center.png')}
          alt="Files"
        />
        <img
          className={styles.dzThree}
          src={require('../../../client/images/dropzone/dz-right.png')}
          alt="Files"
        />
      </div>
      <div className={styles.headerText}>
        <strong>Drag and Drop</strong>
        <div>
          your files here, or <span>browse</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
