import React, { Component, useState } from 'react';
import { Grid, Icon, withStyles, Switch } from '@material-ui/core';

const styles = {
  line: {
    color: '#444447',
    lineHeight: '180%',
  },
  icon: {
    color: '#444447',
    marginTop: 2,
  },
  iconContainer: {
    marginRight: 8,
  },
};

const CustomSwitch = withStyles(theme => ({
  root: {
    // width: 42,
    // height: 26,
    // padding: 0,
  },
  switchBase: {
    height: '100%',
    '& + $track': {
      backgroundColor: theme.palette.primary,
      opacity: 1,
      border: 'none',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: theme.palette.secondary,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      check
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

const MemberDetailToggleField = ({ field1, field2, classes }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = e => setChecked(e.target.checked);
  return (
    <Grid container style={{ marginLeft: -62 }}>
      <Grid item>
        <CustomSwitch onChange={handleChange} checked={checked} />
      </Grid>
      <Grid className={classes.iconContainer} item>
        <Icon className={classes.icon}>
          {checked ? field1.icon : field2.icon}
        </Icon>
      </Grid>
      <Grid item>
        <div className={classes.line}>
          {checked ? field1.value : field2.value}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MemberDetailToggleField);
