import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Grid,
  Button,
  Typography,
  Checkbox,
  Tooltip,
  withStyles,
  Collapse,
} from '@material-ui/core';
import DashboardMessage from '../../../components/DashboardContent/DashboardMessage';
import { useStyles } from './styles';
import SelectField from '../../../../library/forms/SelectField';
import InputField from '../../../../library/forms/InputField';
import HoverTooltip from '../../../../library/forms/HoverTooltip';
import { format, parseISO, isBefore, addDays } from 'date-fns';
import AttachmentPanel from './AttachmentPanel';
import StatusField, {
  VERIFIED,
  SELF_ATTESTED,
  UNVERIFIED,
} from '../../../components/StatusField';
import staticTestNameList from './staticTestNameList.js';
import {
  afterCollectedOn,
  afterOrderedOn,
  required,
  beforeCollectedOn,
} from './validations';
import FormPanelButton from '../../../components/Form/FormButtonPanel';
import DrawerAccordionStandardItems from '../../../components/DrawerAccordionStandardItems';

const dateFormat = 'yyyy-MM-dd';

const WhiteTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '500px',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
})(Tooltip);

const CustomTooltip = withStyles({
  tooltip: {
    marginTop: '-18px',
  },
})(Tooltip);

const staticResultOptions = [
  {
    shortName: 'Inconclusive',
  },
  {
    shortName: 'Negative',
  },
  {
    shortName: 'Positive',
  },
  {
    shortName: 'Rejected',
  },
  {
    shortName: 'Multiple - See Report for Details',
  },
];

const Form = props => {
  const {
    handleSubmit,
    matches550,
    initialValues,
    formValues = initialValues,
    updateField,
  } = props;

  const {
    isSelfAttested,
    testName,
    orderedOn,
    collectedOn,
    resultOn,
    result,
    patientNumber,
    labName,
    assessment,
  } = formValues;

  const status = initialValues.status
    ? initialValues.status
    : isSelfAttested
    ? SELF_ATTESTED
    : UNVERIFIED;

  useEffect(() => {
    if (
      status === SELF_ATTESTED &&
      JSON.stringify(formValues) !== JSON.stringify(initialValues)
    ) {
      updateField('isSelfAttested', false);
    }
  }, [
    testName,
    orderedOn,
    collectedOn,
    resultOn,
    result,
    patientNumber,
    labName,
    assessment,
  ]);
  const classes = useStyles();
  const isVerified = status.toLowerCase() === VERIFIED.toLowerCase();

  const buttonDisabled =
    Boolean(
      props.disableSubmit &&
        !props.hasBundles &&
        props.pristine &&
        !props.deletedMediaObject
    ) || props.isSubmitting;

  const onChangeCheckBox = () => {
    updateField('isSelfAttested', !isSelfAttested);
  };

  const HoverTooltipMsg = isVerified
    ? 'Verified data cannot be changed. You can delete this record and/or create a new one.'
    : '';

  return (
    <div
      style={{ width: !matches550 ? '100vw' : '550px', overflowX: 'hidden' }}
    >
      <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
            <CustomTooltip arrow placement="bottom-end" title={HoverTooltipMsg}>
              <div className={classes.primaryInput}>
                <div className={classes.BgContainer}>
                  <img
                    src={require('../../../../client/svgs/surgical-history-add.svg')}
                    alt=""
                    className={classes.Bg}
                  />
                </div>
                <Typography
                  variant="h6"
                  style={{ textShadow: '1px 1px 0px #fff' }}
                >
                  Enter your Test Results
                </Typography>
                <Field
                  name="testName"
                  label="Lab Test / Screening Name"
                  labelKey="shortName"
                  valueKey="shortName"
                  hint="Required"
                  options={staticTestNameList}
                  component={SelectField}
                  hint="Required"
                  isSearchable
                  isCreatable
                  textFieldProps={{
                    placeholder: 'Lab Test / Screening Name',
                  }}
                  props={{
                    containerStyle: {
                      backgroundColor: '#fff',
                    },
                    variant: 'filled',
                    HoverTooltipMsg
                  }}
                />
              </div>
            </CustomTooltip>
          </Grid>

        <Grid container spacing={1} style={{ padding: '24px' }}>
          <StatusField
            isVerified={isVerified}
            isSelfAttested={isSelfAttested}
          />
          {/* {isVerified && (
            <Grid style={{ padding: '8px 0px' }}>
              <Collapse in={isVerified} timeout={150}>
                <DashboardMessage type="info" canClose={false}>
                  Verified data cannot be changed. You can delete this record and/or create a new one.
                </DashboardMessage>
              </Collapse>
            </Grid>
          )} */}
          <Grid item xs={6}>
            <WhiteTooltip
              arrow
              title={
                <div style={{ padding: '16px' }}>
                  <Typography style={{ textAlign: 'justified' }}>
                    &nbsp; <b>Order Date</b> is the date on which the test was
                    ordered (typically by the physician unless it is one of the
                    at-home tests which do not require a prescription). This
                    date is typically found on your lab report.
                  </Typography>
                  <Typography>
                    &nbsp; <b>Collection Date</b> is the date on which the
                    specimen was actually collected from you (e.g. blood or
                    urine sample collected, cheek swabbed, etc.).
                  </Typography>
                  <Typography>
                    &nbsp; <b>Results Date</b> is the date the test result was
                    returned from the lab to the person who ordered the test
                    (typically the physician). The result report may be made
                    available to you on the same day or at a later date
                    depending upon your Provider’s medical record access and/or
                    the lab’s patient portal. This date is typically found on
                    your lab report.
                  </Typography>

                  <Typography>
                    &nbsp; While there are other test dates (such as lab access
                    date and the date the test was run by the lab), these dates
                    are the most relevant for helping your Care Team understand
                    the results and the implications for your diagnosis.
                  </Typography>
                </div>
              }
              placement="left-start"
            >
              <div>
                <Field
                  name="orderedOn"
                  label="Order Date"
                  component={InputField}
                  disabled={isVerified}
                  validate={[beforeCollectedOn]}
                  type="date"
                  shrink={true}
                  inputProps={{ max: format(new Date(), dateFormat) }}
                  props={{
                    HoverTooltipMsg,
                  }}
                />
              </div>
            </WhiteTooltip>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="collectedOn"
              label="Collection Date"
              component={InputField}
              disabled={isVerified}
              validate={[required, afterOrderedOn]}
              hint="required"
              type="date"
              shrink={true}
              props={{
                HoverTooltipMsg,
              }}
              // inputProps={{ max: format(new Date(), dateFormat) }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              name="resultOn"
              label="Result Date"
              disabled={isVerified}
              validate={afterCollectedOn}
              component={InputField}
              type="date"
              shrink={true}
              props={{
                HoverTooltipMsg,
              }}
              // inputProps={{ max: format(new Date(), dateFormat) }}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTooltip arrow placement="bottom-end" title={HoverTooltipMsg}>
              <div>
                <Field
                  name="result"
                  label="Result"
                  labelKey="shortName"
                  valueKey="shortName"
                  hint="Required"
                  validate={required}
                  disabled={isVerified}
                  isClearable
                  component={SelectField}
                  options={staticResultOptions}
                  isCreatable
                />
              </div>
            </CustomTooltip>
          </Grid>

          <Grid item xs={6}>
            <Field
              name="orderedBy"
              label="Ordered By"
              disabled={isVerified}
              component={InputField}
              type="text"
              props={{
                HoverTooltipMsg,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              name="patientId"
              label="Patient Id"
              disabled={isVerified}
              component={InputField}
              type="text"
              props={{
                HoverTooltipMsg,
              }}
            />
          </Grid>

          {isVerified ? (
            ''
          ) : (
            <Grid>
              <div className={classes.checkBoxFields}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox
                    // disableLabel={true}
                    onChange={onChangeCheckBox}
                    checked={isSelfAttested}
                    style={{
                      color: '#006AB0',
                    }}
                    color="primary"
                  />
                  <Typography color="primary">Optional Attestation:</Typography>
                </div>
                <Typography className={classes.checkBoxText}>
                  For purposes of any Health Status verifications I authorize, I
                  attest that this information is accurate to the best of my
                  knowledge.
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
        <DrawerAccordionStandardItems 
          additionalInformation={
            <Grid container>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="labName"
                  label="Lab Name"
                  component={InputField}
                  disabled={isVerified}
                  props={{
                    HoverTooltipMsg,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="assessments"
                  label="Assessments"
                  placeholder="Assessments (as reported by the Ordering Physician)"
                  component={InputField}
                  disabled={isVerified}
                  multiline
                  maxLength={1000}
                  normalize={value => {
                    if (value.length > 1000) return value.slice(0, 1000);
                    return value;
                  }}
                  props={{
                    HoverTooltipMsg,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="comments"
                  label="Notes"
                  multiline={true}
                  rows={4}
                  component={InputField}
                />
              </Grid>
            </Grid>
          } 
          attachments={<AttachmentPanel labResultId={props.initialValues.labResultId} />}  />
        <Grid container item xs={12}>
          <FormPanelButton
              actionText="Save"
              cancelText="Cancel"
              onCancel={props.onCancel}
              isDisabled={buttonDisabled}
              isSubmitting={props.isSubmitting}
            />
          </Grid>
      </form>
    </div>
  );
};

const LABS_FORM_NAME = 'labs-form';

const LabTestForm = reduxForm({
  form: LABS_FORM_NAME,
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(withRouter(Form));

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change(LABS_FORM_NAME, field, data)),
});

const selector = formValueSelector(LABS_FORM_NAME);
export default connect(state => {
  const {
    isSubmitting,
    list,
    selectedId,
    mediaObjects,
    bundlesToDelete,
  } = state.labs;
  const lab = list[selectedId] ? list[selectedId] : {};
  const syncErrors =
    state.form && state.form[LABS_FORM_NAME]
      ? state.form[LABS_FORM_NAME].syncErrors
      : {};
  const hasSyncErrors = syncErrors
    ? Boolean(Object.keys(syncErrors).length)
    : false;
  const hasBundles = Boolean(
    state.mediaObject.bundles.filter(item => item.appArea === 'LABRESULT')
      .length
  );
  const hasMediaObjectUpdates = Boolean(
    Object.values(mediaObjects).filter(x => !x).length
  );
  const disableSubmit = isSubmitting || hasSyncErrors || !hasMediaObjectUpdates;
  const initialValues = {
    ...lab,
    isSelfAttested: lab.status === SELF_ATTESTED,
    collectedOn: lab.collectedOn
      ? format(parseISO(lab.collectedOn), dateFormat)
      : '',
    resultOn: lab.resultOn ? format(parseISO(lab.resultOn), dateFormat) : '',
    orderedOn: lab.orderedOn ? format(parseISO(lab.orderedOn), dateFormat) : '',
  };

  return {
    hasMediaObjectUpdates,
    disableSubmit,
    hasSyncErrors,
    hasBundles,
    isSubmitting,
    action: state.ui.entity.action,
    deletedMediaObject: Boolean(bundlesToDelete.length),
    initialValues,
    formValues:
      state.form && state.form[LABS_FORM_NAME]
        ? state.form[LABS_FORM_NAME].values
        : initialValues,
  };
}, mapDispatchToProps)(LabTestForm);
