import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { Grid, withStyles } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import FormFooter from '../FormFooter';
import { mask } from '../../../../library/helpers/format';

import { fetchStateType } from '../../../../store/reducers/codes/actions';
import { ROLE_NAME_GUARDIAN_PRIMARY } from '../RoleSummary/data';
import validate from './ValidateForm';

const styles = {
  field: {
    padding: `0 !important`,
    paddingLeft: `12px !important`,
    paddingRight: `12px !important`,
    height: 90,
  },
};

const postalCodeMask = value => mask(value, 'XXXXX-XXXX');

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

const NonProvider01Form = props => {
  const {
    classes,
    handleSubmit,
    stateTypes,
    fetchStateType,
    links,
    valid,
    pristine,
    submitting,
    isUpdating,
    member,
  } = props;

  const disabled =
    isUpdating && member.relationshipType === ROLE_NAME_GUARDIAN_PRIMARY
      ? true
      : false;

  useEffect(() => {
    fetchStateType();
  }, []);

  useEffect(() => {
    props.onChangeForm({
      valid,
      pristine,
      submitting,
    });
  }, [valid, pristine, submitting]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {/* <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="address"
            label="Address"
            component={InputField}
            hint="Optional"
            disabled={disabled}
          />
        </Grid> */}
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="street"
            label="Street Address"
            hint="Optional"
            placeholder="E.g., 2010 Colonial Way"
            component={InputField}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="street2"
            label="Street Address 2"
            hint="Optional"
            placeholder="E.g., Apt 242 or Ste 201"
            component={InputField}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="city"
            label="City"
            component={InputField}
            hint="Optional"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            name="state"
            label="State"
            labelKey="longName"
            valueKey="shortName"
            hint="Optional"
            component={SelectField}
            options={stateTypes}
            isSearchable
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="postalCode"
            label="Zipcode"
            placeholder="XXXXX or XXXXX-XXXX"
            inputProps={{ maxLength: 10 }}
            component={InputField}
            format={postalCodeMask}
            normalize={postalCodeNormalizer}
            hint="Optional"
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <FormFooter links={links} />
    </form>
  );
};

const mapStateToProps = (state, props) => {
  return {
    stateTypes: state.codes.stateTypes,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchStateType: () => dispatch(fetchStateType()),
});

const form02 = reduxForm({
  form: 'care-team-add',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(withStyles(styles)(NonProvider01Form));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(form02));
