import React, { Component } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { slug } from '../../../library/helpers/string';
import PageHeader from '../../components/PageHeader';
import AccountInfoForm from './form/AccountInfoForm';
import BasePage from '../BasePage';

import {
  PERSON_INFO_UPDATE_SUCCESS,
  PERSON_INFO_UPDATE_FAILURE,
  fetchPersonInfo,
  updatePersonBasicInfo,
} from '../../../store/reducers/person/actions';

import {
  fetchEthnicityType,
  fetchBloodType,
  fetchReligionType,
  fetchMaritalType,
  fetchVeteranType,
  fetchStateType,
  fetchLanguageType,
  fetchGenderType,
  fetchContactType,
} from '../../../store/reducers/codes/actions';
import DependentForm from './DependentForm';
import { DashboardMessage } from '../../components/DashboardContent';

class BasicInformationPage extends Component {
  state = {
    personId: 0,
    currentEmail: '',
    height: {},
  };

  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    const {
      fetchPersonInfo,
      fetchEthnicityType,
      fetchBloodType,
      fetchReligionType,
      fetchMaritalType,
      fetchVeteranType,
      fetchStateType,
      fetchLanguageType,
      fetchGenderType,
      fetchContactType,
    } = this.props;

    fetchEthnicityType();
    fetchBloodType();
    fetchReligionType();
    fetchMaritalType();
    fetchVeteranType();
    fetchStateType();
    fetchLanguageType();
    fetchGenderType();
    fetchContactType();
    fetchPersonInfo(this.state.personId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.personId !== prevState.personId) {
      return {
        personId: nextProps.personId,
      };
    }

    // Needed for the disconnect in the DB model between the
    // Users table and person/account tables.
    if (
      nextProps.userInfo &&
      nextProps.userInfo.email !== prevState.currentEmail
    ) {
      return {
        // re-factor when the disconnect is corrected.
        currentEmail: nextProps.userInfo.email,
      };
    }

    {
      /*
         * let foot = 0;
        let inches = 0;
            if (
                nextProps.userInfo &&
                nextProps.userInfo.height != prevState.height.preHeight
            ) {
                foot = Math.floor(parseInt(nextProps.userInfo.height) / 12);
                inches = parseInt(nextProps.userInfo.height) % 12;
                if (foot !== prevState.height.foot || inches !== prevState.height.inches)
                    return {
                        height: {
                            foot,
                            inches,
                            preHeight: nextProps.userInfo.height,
                        },
                    };
            }
        */
    }

    return null;
  }

  // Dependent on getDerivedStateFromProps
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.personId !== this.state.personId) {
      this.props.fetchPersonInfo(this.state.personId);
    }
  }

  async formSubmit(form) {
    const value = { ...form };
    const { updatePersonBasicInfo, isDependent } = this.props;
    const { dashboard } = this.props.navigation;
    let height = 0;

    if (
      !this.state.height.error &&
      (this.state.height.foot || this.state.height.inches)
    ) {
      if (this.state.height.foot > 0) {
        height += this.state.height.foot * 12;
      }
      if (this.state.height.inches > 0) {
        height += this.state.height.inches;
      }

      console.log('HEIGHT:', height, this.state);
    } else if (typeof height === 'number') {
      height = value.height;
    }

    // Remove blank strings, null, and undefined values.
    const cleanValues = Object.keys(value).reduce((accumulator, key) => {
      if (value[key] !== false && !value[key]) return accumulator;
      accumulator[key] = value[key];
      return accumulator;
    }, {});

    const payload = {
      ...cleanValues,
      dob: value.dob ? value.dob : '',
      isOrganDonor: false,
      height: height > 0 ? height : '',
      // checkoutTotal: 0,
    };

    console.log('Payload', payload);

    const result = await updatePersonBasicInfo(payload, this.props.isDependent);
    if (result.type === PERSON_INFO_UPDATE_SUCCESS) {
      const fullName = `${result.payload.firstName} ${
        result.payload.lastName
      } ${result.payload.suffix ?? ''}`.trim();
      this.props.history.push(
        `/d/${isDependent ? slug(fullName) : 'me'}/account`
      );
    } else if (result.type === PERSON_INFO_UPDATE_FAILURE) {
      throw new SubmissionError(result.payload);
    }
  }

  onFormCancel = () => {
    this.props.history.push(this.props.navigation.dashboard.account.self);
  };

  onChange = (name, value) => {
    let error = '';

    if (name === 'init') {
      this.setState({
        ...this.state,
        height: {
          ...this.state.height,
          ...value,
        },
      });
      return;
    }

    //errors handling
    if (name === 'foot') {
      if (value > 9 || this.state.height.inches > 11) error = 'Invalid Entry';
    }
    if (name === 'inches') {
      if (value > 11 || this.state.height.foot > 9) error = 'Invalid Entry';
    }

    this.setState({
      ...this.state,
      height: {
        ...this.state.height,
        [name]: isNaN(value) ? 0 : value,
        error: error,
      },
    });
  };

  render() {
    let { uiState, userInfo } = this.props;
    const dashboardMessage =
      userInfo && userInfo.type === 'Pet'
        ? 'This information is used to accurately exchange information with your pet’s Care Team.  It may also be used to assist in completing patient paperwork and making recommendations such as vaccinations that are due.  The more complete this information is, the better your Care Team can work with you to help your pet.'
        : 'This information is used to accurately exchange information with any Care Team member you direct.  It may also be used to assist in completing patient paperwork and making recommendations such as when to seek a colonoscopy or take a risk assessment survey.  The more complete this information is, the better your Care Team can serve you.';

    return (
      <BasePage
        state={uiState}
        HeaderComponent={
          <PageHeader
            headerText="Basic Information"
            backButtonText="Go Back"
            subHeaderText="Manage Personal Details"
            rightSlot={
              userInfo ? (
                <DashboardMessage canClose={false}>
                  {dashboardMessage}
                </DashboardMessage>
              ) : (
                ''
              )
            }
            xs={{
              right: 8,
              left: 4,
            }}
          />
        }
      >
        <DependentForm
          userInfo={this.props.userInfo}
          onSubmit={this.formSubmit}
        />
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  isDependent: state.active.user.type === 'dependent',
  userInfo: state.member.basicInfo.userInfo,
  isUpdating: state.member.basicInfo.isUpdating,
  errorMessage: state.member.basicInfo.errorMessage,
  uiState: state.member.basicInfo.uiState,
  ethnicityTypes: state.codes.ethnicityTypes,
  bloodTypes: state.codes.bloodTypes,
  religionTypes: state.codes.religionTypes,
  maritalTypes: state.codes.maritalTypes,
  veteranTypes: state.codes.veteranTypes,
  stateTypes: state.codes.stateTypes,
  languageTypes: state.codes.languageTypes,
  genderTypes: state.codes.genderTypes,
  contactTypes: state.codes.contactTypes,
});

const mapDispatchToProps = dispatch => ({
  fetchPersonInfo: id => dispatch(fetchPersonInfo(id)),
  updatePersonBasicInfo: (person, isDependent) =>
    dispatch(updatePersonBasicInfo(person, isDependent)),
  fetchEthnicityType: () => dispatch(fetchEthnicityType()),
  fetchBloodType: () => dispatch(fetchBloodType()),
  fetchReligionType: () => dispatch(fetchReligionType()),
  fetchMaritalType: () => dispatch(fetchMaritalType()),
  fetchVeteranType: () => dispatch(fetchVeteranType()),
  fetchStateType: () => dispatch(fetchStateType()),
  fetchLanguageType: () => dispatch(fetchLanguageType()),
  fetchGenderType: () => dispatch(fetchGenderType()),
  fetchContactType: () => dispatch(fetchContactType()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(BasicInformationPage)),
};
