import React from 'react';
import {
  Grid,
  Button,
  withStyles,
  Typography,
  Hidden,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import EmptySVG from './EmptySVG';

const styles = {
  root: {
    marginTop: '72px',
    padding: '25px 50px',
    // backgroundColor: '#eee',
    borderRadius: 6,
  },
  column: {
    flex: 0.48,
  },
  header: {
    fontSize: 32,
    fontWeight: 500,
    paddingBottom: 16,
  },
  text: {
    lineHeight: '150%',
  },
};

const Empty = ({
  classes,
  onClick,
  name,
  type,
  history,
  pageHeaderProps = {},
  content,
  disableGoBack = true,
}) => {
  const onClickGoBack = event => {
    event.preventDefault();
    if (pageHeaderProps.backLink) {
      history.goBack(pageHeaderProps.backLink);
    }
    history.goBack();
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
      spacing={5}
    >
      <Hidden mdUp>
        <Grid item sm={12} style={{ paddingBottom: '16px' }}>
          <EmptySVG />
        </Grid>
      </Hidden>
      <Grid item sm={12} md={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.header}>
              Start your {name} List
            </Typography>
            {content ? (
              content
            ) : (
              <Typography variant="body1" className={classes.text}>
                Let’s make your health information work better for you! Get
                started by creating your first record in this section.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {!disableGoBack && (
              <Button
                variant="text"
                onClick={onClickGoBack}
                disableElevation
              >
                Go Back
              </Button>
            )}
            <Button
              style={{
                color: '#fff',
                marginLeft: !disableGoBack ? '16px' : '',
              }}
              aria-label="Add new condition/diagnosis"
              color="primary"
              variant="contained"
              onClick={onClick}
              disableElevation
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} md={6}>
          <EmptySVG />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(Empty));
