import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { HubConnectionBuilder } from '@microsoft/signalr';

import {
  AppBar,
  Typography,
  Grid,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Tooltip,
} from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ShareIcon from '@material-ui/icons/Share';

// Notifications Files Below
import NotificationModal from '../components/Notifications/NotificationModal/NotificationModal';

import { connect } from 'react-redux';

import './DashboardHeader.css';

import companyLogo from '../../client/images/agrinhealth.logo.png';
import companyTreeLogo from '../../client/images/healthebiography-tree-logo.png';
import {
  clearGlobalNotifications,
  receivedGlobalNotifications,
  receivedNotifications,
  markNotification,
} from '../../store/reducers/notifications/actions';

import { localStorageGetObject } from '../../library/helpers/fetch';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    width: '100%',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: 1300,
  },
  grow: {
    color: '#fff',
    // flexGrow: 1,
    textAlign: 'right',
    paddingRight: theme.spacing(2),
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  buttonSignOut: {
    border: 'solid 1px #fff',
  },
  dropdownPosition: {
    position: 'relative',
    left: '120px',
    top: '5px',
  },
  arrow: {
    position: 'relative',
    width: '20px',
    height: '20px',
    display: 'block',
    background: '#fff',
    transform: 'translateY(-50%) rotate(45deg)',
    left: '126px',
    top: '1px',
  },
  noticeHeader: {
    fontSize: '14px',
    top: '2px',
    color: '#2F2E41',
    position: 'relative',
    left: '18px',
    fontWeight: '500',
  },
  noticeCount: {
    position: 'absolute',
    top: '0px',
    left: '28px',
    fontSize: '10px',
    background: '#F03B2E',
    padding: '2px 3px',
    borderRadius: '100%',
    color: 'white',
    zIndex: '10',
    display: 'block',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
    transformOrigin: 'top center',
    fontFamily: 'Arial',
    fontWeight: '700',
    lineHeight: '100%',
  },
  dropdownHeader: {
    zIndex: '100',
    textAlign: 'center',
    height: 'auto',
    backgroundColor: '#fff',
    borderBottom: '2px solid #F2ECEC',
  },
  noticeLink: {
    position: 'relative',
    top: '-5px',
    left: '5px',
    fontSize: '14px',
    color: '#0068A8',
    marginLeft: '70px',
    textTransform: 'initial !important', //material button makes uppercase
  },
  modalStyle: {
    position: 'relative',
    maxWidth: '500px',
    maxHeight: '400px',
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: 'none',
    paddingTop: '0',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  Notification: {},
  noticePosition: {
    flexGrow: 60,
    textAlign: 'right',
    color: '#fff',
  },
  noticeIcon: {
    color: '#fff',
  },
  dropdownFooter: {
    textAlign: 'center',
    position: 'relative',
    bottom: '0',
    backgroundColor: '#fff',
    borderTop: '2px solid #F2ECEC',
  },
  divider: {
    width: 1,
    height: 20,
    backgroundColor: '#fff',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  new: {
    position: 'absolute',
    fontSize: 9,
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    padding: `2px 4px`,
    borderRadius: 2,
    top: 4,
    left: -12,
  },
});

const DashboardHeader = ({
  classes,
  emailAddress,
  logoutMemberDone,
  drawerToggle,
  reportsToggle,
  personNotifications,
  receivedGlobalNotifications,
  receivedNotifications,
  markNotification,
  clearGlobalNotifications,
  navigation,
  isHome,
}) => {
  useEffect(() => {
    const session = localStorageGetObject('session');

    const connection = new HubConnectionBuilder()
      .withUrl(`${window.__env.API_URL}/notifications`, {
        // https://github.com/whatwg/html/issues/3062
        accessTokenFactory: () => session.access_token,
      })
      .withAutomaticReconnect()
      .build();

    connection.on('ReceiveGlobalNotifications', payload =>
      receivedGlobalNotifications(payload)
    );

    connection.on('ReceiveNotifications', payload =>
      receivedNotifications(payload)
    );

    connection.on('ReceiveNotification', payload =>
      receivedNotifications(payload)
    );

    connection.start().then(function initializeConnection() {
      connection
        .invoke('InitializeConnection')
        .then(function initializeConnectionStart() {
          console.info('Connection established with notification hub');
        })
        .catch(function initializeConnectionError() {
          console.warn('Failed to connect with notification hub');
        });
    });
  }, []);

  const handleNotificationPressed = notification => {
    if (notification.readOn) {
      markNotification('unread', notification);
    } else {
      markNotification('read', notification);
    }
  };

  const handleNotificationOption = (action, data) => {
    switch (action) {
      case 'read':
        markNotification('read', data);
        break;
      case 'unread':
        markNotification('unread', data);
        break;
      case 'clear':
        markNotification('clear', data);
        break;
      default:
        throw new Error(
          `${action} is not a support action in handleSelectOption`
        );
    }
  };

  const handleClearNotifications = () => {
    clearGlobalNotifications();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar variant="dense" disableGutters>
          <Hidden smDown>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <div style={{ width: '40px' }}>
                {isHome ? (
                  <img height="40" src={companyLogo} alt="Agrin Health" />
                ) : (
                  <Link to={navigation.dashboard.home}>
                    <img height="40" src={companyLogo} alt="Agrin Health" />
                  </Link>
                )}
              </div>

              <Grid container alignItems="center" justifyContent="flex-end">
                <div className={classes.noticePosition}>
                  <Tooltip
                    placement="left"
                    title="Create a customized report to share your data"
                  >
                    <IconButton
                      style={{ position: 'relative' }}
                      onClick={reportsToggle(true)}
                    >
                      {/*<div className={classes.new}>Share</div>*/}
                      <ShareIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </Tooltip>

                  <NotificationModal
                    data={personNotifications}
                    onNotificationPressed={handleNotificationPressed}
                    onNotificationOption={handleNotificationOption}
                    onClearNotifications={handleClearNotifications}
                  />
                </div>
                <div className={classes.divider} />
                <Typography className={classes.grow} variant="subtitle1">
                  {emailAddress}
                </Typography>
                <IconButton onClick={logoutMemberDone} color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={drawerToggle}
                >
                  <MenuIcon></MenuIcon>
                </IconButton>
              </div>

              <div style={{ marginTop: 10 }}>
                <Link to="/">
                  <img src={companyTreeLogo} alt="Agrin Health" height="30px" />
                </Link>
              </div>

              <Grid container alignItems="center" justifyContent="flex-end">
                <div className={classes.noticePosition}>
                  <Tooltip
                    placement="left"
                    title="Create a customized report to share your data"
                  >
                    <IconButton
                      style={{ position: 'relative' }}
                      onClick={reportsToggle(true)}
                    >
                      {/* <div className={classes.new}>Share</div> */}
                      <ShareIcon style={{ color: '#fff' }} />
                    </IconButton>
                  </Tooltip>

                  <NotificationModal
                    data={personNotifications}
                    onNotificationPressed={handleNotificationPressed}
                    onNotificationOption={handleNotificationOption}
                    onClearNotifications={handleClearNotifications}
                  />
                </div>
                <div className={classes.divider} />
                <IconButton onClick={logoutMemberDone} color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => ({
  personNotifications: state.notification.person,
});

const mapDispatchToProps = dispatch => ({
  clearGlobalNotifications: () => dispatch(clearGlobalNotifications()),
  receivedGlobalNotifications: payload =>
    dispatch(receivedGlobalNotifications(payload)),
  receivedNotifications: payload => dispatch(receivedNotifications(payload)),
  markNotification: (action, notification) =>
    dispatch(markNotification(action, notification)),
});

DashboardHeader.propTypes = {
  memberName: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  logoutMemberDone: PropTypes.func.isRequired,
};

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardHeader));

export default Header;
