import React from 'react';
import MediaObjectDrawer from '../MediaObjectDrawer/MediaObjectDrawer';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditBorder from '@material-ui/icons/BorderColor';
import DeleteBorder from '@material-ui/icons/Delete';
import PhotosIcon from '@material-ui/icons/PhotoLibrary';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';

const iconStyles = isDeleting => ({
  color: '#757575',
  opacity: isDeleting ? '.5' : 1,
  cursor: 'pointer',
  margin: '0 8px',
});

const Control = ({ row, onEdit, onDelete, viewMediaObjects }) => {
  const id = row.original.id;
  const isDeleting = row.original.isDeleting;
  const shouldRenderMediaObjectDrawer =
    row.original.mediaObjects &&
    row.original.mediaObjects.length !== 0 &&
    row.original.type;
  const classes = useStyles();

  return (
    <div className={classes.controlContainer}>
      {shouldRenderMediaObjectDrawer && (
        <PhotosButton
          classes={classes}
          isDeleting={isDeleting}
          row={row}
          type={row.original.type}
          id={row.original.id}
          viewMediaObjects={viewMediaObjects}
        />
      )}
      <EditButton
        id={id}
        isDeleting={isDeleting}
        onEdit={onEdit}
        classes={classes}
      />
      {isDeleting ? (
        <div className={classes.progressContainer}>
          <CircularProgress size={24} disableShrink />
        </div>
      ) : (
        <DeleteButton
          classes={classes}
          row={row}
          onDelete={onDelete}
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

const PhotosButton = ({ type, id, isDeleting, viewMediaObjects, row }) =>
  viewMediaObjects ? (
    <Tooltip title="View Attachments">
      <PhotosIcon
        onClick={e => (isDeleting ? {} : viewMediaObjects(row.original))}
        style={iconStyles(isDeleting)}
      />
    </Tooltip>
  ) : (
    <div>
      <MediaObjectDrawer areaType={type} primaryId={id} />
    </div>
  );

const EditButton = ({ isDeleting, onEdit, id, classes }) => (
  <Tooltip title="Edit Record">
    <EditBorder style={iconStyles(isDeleting)} onClick={() => onEdit(id)} />
  </Tooltip>
);

const DeleteButton = ({ classes, row, onDelete, isDeleting }) => (
  <Tooltip title="Delete Record">
    <DeleteBorder
      style={iconStyles(isDeleting)}
      onClick={() => onDelete(row.original)}
    />
  </Tooltip>
);

export default Control;
