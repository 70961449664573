import React, { useEffect, useState } from 'react';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import { Skeleton } from '@material-ui/lab';
import { formatCurrency } from '../../../../library/helpers/money';
import { Field } from 'redux-form';
import InputField from '../../../../library/forms/InputField';
import cn from 'classnames';
import ProgressButtonLinear from '../../../components/Buttons/ProgressButtonLinear';
import InfoIcon from '@material-ui/icons/Info';
import {
  Button,
  Grid,
  Paper,
  Icon,
  Typography,
  withStyles,
  Container,
} from '@material-ui/core';

const STRIPE_MINIMUM_PRICE = 0.5;

const styles = theme => ({
  hint: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '8px 12px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    marginTop: theme.spacing(1),
    minHeight: '1em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: '1em',
  },
  error: {
    color: '#D50000 !important',
  },
});

const stripeElementStyle = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.47)',
        },
      },
      invalid: {
        color: '#D50000',
      },
    },
  };
};

const StripeForm = ({
  classes,
  error,
  children = null,
  onReady,
  onFieldChange,
  revealPaymentForm,
  isDisabled,
  isSubmitting,
  checkoutTotal,
  product,
}) => {
  const [fieldCount, setFieldCount] = useState(1);
  const [formStatus, setFormStatus] = useState('');

  // const [, setTick] = useState(0);

  // setInterval(() => {
  //   setTick(state => state + 1);
  // }, 5000);

  useEffect(() => {
    setFormStatus('loading');
  }, []);

  useEffect(() => {
    if (fieldCount === 3) {
      setFormStatus('loaded');
      if (onReady) onReady();
    }
  }, [fieldCount]);

  const handleFieldReady = event => {
    setFieldCount(state => state + 1);
  };

  const LoadingState = () => {
    return (
      <Grid container spacing={1}>
        <Grid item sm={12} md={4}>
          <Skeleton height={50} variant="wave" style={{ margin: '15px 0px' }} />
        </Grid>
        <Grid item sm={6} md={4}>
          <Skeleton height={50} variant="wave" style={{ margin: '15px 0px' }} />
        </Grid>
        <Grid item sm={3} md={2}>
          <Skeleton height={50} variant="wave" style={{ margin: '15px 0px' }} />
        </Grid>
        <Grid item sm={3} md={2}>
          <Skeleton height={50} variant="wave" style={{ margin: '15px 0px' }} />
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          background: '#F8F7F7',
          display: formStatus === 'loading' ? 'block' : 'none',
          position: 'absolute',
          zIndex: 1000,
          padding: 8,
        }}
      >
        <LoadingState />
      </div>
      <Grid
        container
        spacing={1}
        style={{ padding: 8, margin: 0, width: '100%' }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <Field
            name="cardName"
            label="Name on Card"
            hint="Required"
            type="text"
            component={InputField}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginBottom: 12, marginTop: 8 }}
        >
          <CardNumberElement
            {...stripeElementStyle()}
            onReady={handleFieldReady}
            onChange={onFieldChange}
          />
          <Typography
            variant="body2"
            className={cn(classes.hint, {
              [classes.error]: error.cardNumber && error.cardNumber.code,
            })}
          >
            {error.cardNumber && error.cardNumber.code
              ? error.cardNumber.message
              : 'Required'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2} style={{ marginTop: 8 }}>
          <CardExpiryElement
            {...stripeElementStyle()}
            onReady={handleFieldReady}
            onChange={onFieldChange}
          />

          <Typography
            variant="body2"
            className={cn(classes.hint, {
              [classes.error]: error.cardExpiry && error.cardExpiry.code,
            })}
          >
            {error.cardExpiry && error.cardExpiry.code
              ? error.cardExpiry.message
              : 'Required'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2} style={{ marginTop: 8 }}>
          <CardCVCElement
            {...stripeElementStyle()}
            onReady={handleFieldReady}
            onChange={onFieldChange}
          />
          <Typography
            variant="body2"
            className={cn(classes.hint, {
              [classes.error]: error.cardCVC && error.cardCVC.code,
            })}
          >
            {error.cardCVC && error.cardCVC.code
              ? error.cardCVC.message
              : 'Required'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.footer}>
        <Grid item xs={12} sm={7} lg={7}>
          <Grid container alignItems="center" spacing={1}>
            {revealPaymentForm ? (
              <React.Fragment>
                <Grid item xs={2}>
                  <Icon color="secondary" style={{ fontSize: 48 }}>
                    verified_user
                  </Icon>
                </Grid>
                <Grid item xs={9}>
                  <p style={{ marginLeft: 12 }}>
                    You’re using a secure encrypted payment method
                  </p>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={7}>
                *A one-time $1 Security Fee applies
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} lg={5} className={classes.buttonGroup}>
          <ProgressButtonLinear
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            className={classes.primaryButton}
            disabled={isDisabled}
            fullWidth
            disableElevation
            isProgressing={isSubmitting}
          >
            {!product.data
              ? 'Select Plan'
              : 0 + checkoutTotal < STRIPE_MINIMUM_PRICE
              ? 'Free'
              : `Verify & Pay ${formatCurrency(
                  checkoutTotal && checkoutTotal
                )}`}
          </ProgressButtonLinear>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(StripeForm);
