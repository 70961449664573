import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  textField: {
    color: '#0068A8',
    marginTop: '6px',
  },
  totalSleepFields: {
    marginTop: '30px',
  },
  totalSleep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '-10px',
  },
  focused: {},
  gridContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  sliderContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  vectorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'fit-content',
    transition: 'all .2s',
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  vectorHeader: {
    marginTop: '16px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#6D6D6D',
  },
  vectorContainerDiv: {
    margin: '0px 16px',
    minHeight: '75px',
    width: '75px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxFields: {
    minWidth: '200px',
    display: 'flex',
    textAlign: 'center',
    whiteSpace: 'pre',
    fontSize: '14px',
    color: '#535461',
    alignItems: 'flex-end',
  },
  checkBoxText: {
    fontSize: '13px',
    lineHeight: '1',
    height: '24px',
    margin: '9px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  totalSleepContainerDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  activeStyle: {
    opacity: 1,
  },
});

export default useStyles;
