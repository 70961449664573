import React from 'react';
import PropTypes from 'prop-types';

import {
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  Info as InfoIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';

const NotificationIcon = ({ type, className }) => {
  switch (type.toLowerCase()) {
    case 'alert':
      return <WarningIcon className={className} />;
    case 'activity':
      return <TimelineIcon className={className} />;
    case 'info':
      return <InfoIcon className={className} />;
    case 'report':
      return <InsertDriveFileIcon className={className} />;
    case 'message':
      return <EmailIcon className={className} />;
    case 'general':
      return <NotificationsIcon className={className} />;
    default:
      return null;
  }
};

NotificationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default NotificationIcon;
