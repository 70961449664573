import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import SelectField from '../../../library/forms/SelectField';

const SearchSelectField = props => {
  const {
    labelKey,
    valueKey,
    onTypeAhead,
    debounceTimeout,
    input,
    ...rest
  } = props;

  let debounceHandle = undefined;

  const cancelDebounce = () => {
    if (debounceHandle !== undefined) {
      debounceHandle.cancel();
    }
  };

  const loadOptions = (inputValue, callback) => {
    cancelDebounce();
    debounceHandle = debounce(
      () => {
        // based on the label and value key expose functions
        // getOptionLabel and getOptionValue to the outside world
        // through the onTypeAhead function. The implementation
        // is exactly the same as SelectField.
        props.onTypeAhead(inputValue, callback, {
          getOptionLabel: option => option[labelKey],
          getOptionValue: option => option[valueKey],
        });
      },
      debounceTimeout,
      { leading: false }
    );

    debounceHandle();
  };

  const handleBlur = inputValue => {
    if (input.value === inputValue) {
      return;
    }
    cancelDebounce();
  };

  const handleChange = option => {
    cancelDebounce();
  };

  return (
    <SelectField
      {...rest}
      input={input}
      isAsync
      labelKey={labelKey}
      valueKey={valueKey}
      onBlur={handleBlur}
      onChange={handleChange}
      loadOptions={loadOptions}
      disableOnBlur={false}
    />
  );
};

SearchSelectField.propTypes = {
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  debounceTimeout: PropTypes.number.isRequired,
  onTypeAhead: PropTypes.func.isRequired,
};

SearchSelectField.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
  debounceTimeout: 300,
};

export default SearchSelectField;
