import React, { Component, useEffect, useState, useRef } from 'react';
import { Grid, Button, Tooltip, Collapse } from '@material-ui/core';
import { apiFetchAuthorized } from '../../../../library/helpers/fetch';
import { getMediaType, downloadBlob } from '../helpers';
import PreviewObject from './PreviewObject';
import MediaObjectDetails from './MediaObjectDetails';
import MediaEditForm from './MediaEditForm';
import DashboardMessage from '../../DashboardContent/DashboardMessage';
import { getCategoryFromAreaType } from './categoryFromAreaType';
import styles from '../styles/MediaObjectDrawer.css';
import { values } from 'lodash';
import { format } from 'date-fns-tz';
import CustomFrame from '../../iFrame';
import {
  IMMUNIZATION,
  LABRESULT,
} from '../../../../store/reducers/mediaObject';

const noop = () => {};

const MediaObjectView = ({
  personId,
  currentMediaFile,
  onClickEdit,
  viewMode,
  dialogMode,
  onClickCancel,
  onSubmitUpdate,
  handleOnDelete,
  isSubmitting,
  areaType,
  //handleSubmit
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteClick = () => {
    if (isDeleting) handleOnDelete();
    else setIsDeleting(true);
  };
  const handleSubmit = values => {
    const {
      newEnteredOnTime,
      newEnteredOnDate,
      category,
      displayName,
      description,
    } = values;
    const normalizeDate = date => date.split('-').join('/');

    const dateTime = newEnteredOnDate
      ? newEnteredOnTime
        ? new Date(`${normalizeDate(newEnteredOnDate)} ${newEnteredOnTime}`)
        : new Date(`${normalizeDate(newEnteredOnDate)}`)
      : new Date();

    const payload = {
      personId,
      data: {
        category: category,
        displayName: displayName,
        description: description,
        enteredOn: format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX"),
      },
    };
    onSubmitUpdate(payload);
  };

  const mediaType = currentMediaFile.mediaType
    ? currentMediaFile.mediaType
    : getMediaType(currentMediaFile.mimeType);
  const showDownloadOption = ['WORD', 'EXCEL'].includes(mediaType);
  const [isDownloading, setIsDownloading] = useState(false);

  const requestControllerRef = useRef();

  useEffect(() => {
    requestControllerRef.current = new AbortController();

    () => requestControllerRef.current.abort();
  }, []);

  const onClickDownload = async () => {
    if (isDownloading) return noop();

    setIsDownloading(true);

    const response = await apiFetchAuthorized(
      currentMediaFile.links.download,
      {
        signal: requestControllerRef.current.signal,
      },
      false
    );

    const blob = await response.blob();
    downloadBlob(blob, currentMediaFile.fileName);

    setIsDownloading(false);
  };

  const shouldToggleCollapse = [IMMUNIZATION, LABRESULT];
  const toggleWarning =
    isDeleting &&
    shouldToggleCollapse.includes(currentMediaFile.applicationArea);
  // Boolean(currentMediaFile.id || currentMediaFile.immunizationMediaObjectId);
  //TODO: referenceId needs to be returned on the response to check for association

  return (
    <div>
      <Grid container spacing={2}>
        <Tooltip title="View file">
          <PreviewObject
            personId={personId}
            currentMediaFile={currentMediaFile}
            dialogMode={dialogMode}
            mediaType={mediaType}
            mode={viewMode}
            fileName={currentMediaFile.fileName}
          />
        </Tooltip>

        {viewMode === 'EDIT' ? (
          <MediaEditForm
            onClickCancel={onClickCancel}
            currentMediaFile={currentMediaFile}
            onSubmit={values => handleSubmit(values)}
            areaType={areaType}
          />
        ) : (
          <React.Fragment>
            <Grid
              item
              xs={mediaType === 'IMAGE' || mediaType === 'VIDEO' ? 12 : 7}
              className={`${styles.mBottom} ${styles.mediaDetailsBlock}`}
            >
              <MediaObjectDetails
                onClickEdit={onClickEdit}
                isDeleting={isDeleting}
                currentMediaFile={currentMediaFile}
                dialogMode={dialogMode}
                mediaType={mediaType}
                onClickDownload={onClickDownload}
                showDownloadOption={showDownloadOption}
                category={
                  currentMediaFile.category
                    ? currentMediaFile.category
                    : areaType
                    ? getCategoryFromAreaType(areaType).longName
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={toggleWarning}>
                <DashboardMessage type="warning" canClose={false}>
                  This file is associated with other records in your Health
                  Record and/or Journal. Deleting this record will remove the
                  attachment from the other record(s). Do you want to proceed?{' '}
                </DashboardMessage>
              </Collapse>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="default"
                variant="contained"
                size="medium"
                onClick={handleDeleteClick}
                disableElevation
              >
                {toggleWarning
                  ? 'Yes - Delete'
                  : isDeleting
                  ? 'Confirm Delete'
                  : 'Delete'}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                disableElevation
                color="primary"
                variant="contained"
                size="medium"
                onClick={
                  toggleWarning ? () => setIsDeleting(false) : onClickEdit
                }
              >
                {toggleWarning ? 'No - Cancel' : 'Edit'}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

export default MediaObjectView;
