import React from 'react';
import { Grid, makeStyles, Avatar, Typography } from '@material-ui/core';
import MarkChatReadIcon from '@material-ui/icons/HowToReg';

import NotificationBase from '../Notification/NotificationBase';
import NotificationGeneral from '../Notification/NotificationGeneral';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 630,
    overflow: 'hidden',
    padding: 0,
  },
  iconAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(4),
  },
}));

const noop = () => {};

const NotificationModalBody = ({
  isFetching,
  listIds,
  list,
  onNotificationPressed,
  onNotificationOption,
}) => {
  const classes = useStyles();

  const notifications =
    listIds.length > 0 ? listIds.filter(id => !list[id].clearedOn) : [];

  return (
    <Grid item container className={classes.root}>
      {isFetching ? (
        <NotificationLoading />
      ) : notifications.length > 0 ? (
        notifications.map((id, index) => {
          return (
            <Grid key={index} item xs={12}>
              <NotificationGeneral
                data={list[id]}
                onNotificationPressed={onNotificationPressed}
                onNotificationOption={onNotificationOption}
              >
                {list[id].body}
              </NotificationGeneral>
            </Grid>
          );
        })
      ) : (
        <NotificationEmpty />
      )}
    </Grid>
  );
};

const useEmptyStyles = makeStyles(theme => ({
  body: {
    color: '#666',
  },
  iconAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(4),
  },
}));

const NotificationEmpty = () => {
  const classes = useEmptyStyles();
  return (
    <NotificationBase
      Icon={
        <Avatar className={classes.iconAvatar}>
          <MarkChatReadIcon className={classes.icon} />
        </Avatar>
      }
      onNotificationPressed={noop}
      onSelectOption={noop}
      showOptions={false}
    >
      <span className={classes.body}>
        You have no unread notifications at this time. When you have new
        notifications, we'll let you know.
      </span>
    </NotificationBase>
  );
};

const NotificationLoading = () => {
  const classes = useEmptyStyles();
  return (
    <NotificationBase
      Icon={
        <Skeleton variant="circle">
          <Avatar className={classes.iconAvatar}></Avatar>
        </Skeleton>
      }
      onNotificationPressed={noop}
      onSelectOption={noop}
      showOptions={false}
    >
      <Typography variant="body2">
        <Skeleton variant="text" width={230} />
        <Skeleton variant="text" width={180} />
        <Skeleton variant="text" width={140} />
      </Typography>
    </NotificationBase>
  );
};

export default NotificationModalBody;
