import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { parseISO } from 'date-fns';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import NotificationBaseOptions from './NotificationBaseOptions';
import TimeAgo from '../TimeAgo';

const NotificationBase = ({
  data = {},
  children,
  Icon,
  ActionBar,
  onNotificationPressed,
  onNotificationOption,
  showOptions,
}) => {
  const { title, createdOn, readOn } = data;

  const [targetElement, setTargetElement] = useState(null);
  const [isRead, setIsRead] = useState(Boolean(readOn));

  const onOptionsPressed = event => {
    setTargetElement(event.target);
  };

  const onOptionsClose = () => {
    setTargetElement(null);
  };

  const handleNotificationPressed = () => {
    setIsRead(() => !isRead);
    onNotificationPressed(data);
  };

  const handleSelectOption = action => {
    setIsRead(() => action === 'read');
    onNotificationOption(action, data);
  };

  console.log('This should render the select style', isRead);

  const isReadOn = Boolean(readOn);

  return (
    <div
      style={{
        backgroundColor: isReadOn ? '#f5f5f5' : 'transparent',
        opacity: isReadOn ? 0.7 : 1,
        cursor: 'pointer',
        userSelect: 'text',
      }}
    >
      <Grid
        container
        justify="space-between"
        style={{
          fontSize: '95%',
          padding: 16,
          paddingBottom: 24,
        }}
      >
        <Grid item container xs={11} onClick={handleNotificationPressed}>
          <Grid item style={{ marginRight: 24 }}>
            {Icon}
          </Grid>

          <Grid item xs={8}>
            {title && (
              <Typography variant="subtitle1">
                <strong>{title}</strong>
              </Typography>
            )}
            <Typography variant="body2">{children}</Typography>

            {ActionBar}

            {createdOn && (
              <Tooltip enterDelay={1000} title={parseISO(createdOn).toString()}>
                <Typography variant="subtitle2" style={{ marginTop: 8 }}>
                  <TimeAgo timestamp={createdOn} />
                </Typography>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        {showOptions && (
          <Grid item container xs={1} justify="flex-end">
            <Grid item>
              <IconButton
                style={{ marginTop: -10 }}
                arial-controls="notification options"
                aria-haspopup
                onClick={onOptionsPressed}
                edge="end"
              >
                <MoreVertIcon />
              </IconButton>
              <NotificationBaseOptions
                anchorEl={targetElement}
                open={Boolean(targetElement)}
                onClose={onOptionsClose}
                onSelectOption={handleSelectOption}
                keepMounted
                isRead={isRead}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

NotificationBase.propTypes = {
  data: PropTypes.object,
  children: PropTypes.any,
  Icon: PropTypes.object,
  ActionBar: PropTypes.object,
  onNotificationPressed: PropTypes.func.isRequired,
  onNotificationOption: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
};

NotificationBase.defaultProps = {
  showOptions: true,
};

export default NotificationBase;
