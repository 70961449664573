import { useState, useEffect } from 'react';
import { filterByQuery } from '../helpers/utils';

/**
 * Hook queries a hashmap for values that match filter text
 *
 * @param {*} ids Pass listIds(Array of Ids)
 * @param {*} map map, Hashmap with Id as key
 * @param {*} keys keys, an array of the keys to be queried.
 * @param {*} formatFunction formatFunction { key: function }, to format key to desired value e.g., someKey to some_key
 */
const useMapQuery = (ids, map, keys, formatFunction) => {
  const [listIds, setListIds] = useState(ids);
  const [filter, setFilter] = useState('');
  const [hasFiltered, setHasFiltered] = useState(true);

  useEffect(() => {
    setHasFiltered(false);

    if (
      !Boolean(filter.length) ||
      !Boolean(Object.keys(map).length) ||
      !Boolean(ids.length)
    ) {
      setListIds(ids);
      setHasFiltered(true);
      return;
    }

    const filteredId = ids.filter(id => {
      const obj = map[id];
      return keys.filter(key => {
        if (!Boolean(obj[key])) return false;

        if (formatFunction && Boolean(formatFunction[key])) {
          return filterByQuery(formatFunction[key](obj[key]), filter);
        }

        return filterByQuery(obj[key], filter);
      }).length;
    });
    if (filteredId !== listIds) setListIds(filteredId);
    setHasFiltered(true);
  }, [ids, filter, map]);

  return {
    listIds,
    setFilter,
    filter,
    hasFiltered,
  };
};

export default useMapQuery;
