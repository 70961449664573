import React, { Component } from 'react';
import {
  isImageMimeType,
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
  MIME_TYPE_IMAGE_WEBP,
  MIME_TYPE_VIDEO_MP4,
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_APPLICATION_WORD,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_EXCEL,
  MIME_TYPE_APPLICATION_XLSX,
  MIME_TYPE_TEXT_CSV,
  MIME_TYPE_TEXT_PSV,
  MIME_TYPE_TEXT_TSV,
  MIME_TYPE_TEXT_PLAIN,
} from '../../../../library/helpers/mimeTypes';
import styles from '../styles/MediaObjectDrawer.css';

import Image from '../../Image';
import Video from '../../Video';

import wordicon from '../../../../client/images/word-icon.png';
import pdficon from '../../../../client/images/pdf-icon.png';
import excelicon from '../../../../client/images/excel-icon.png';

import { getBestTargetImageFormat } from '../../MediaObject/helpers';

import {
  WordIcon,
  PdfIcon,
  OtherIcon,
  ImageIcon,
} from '../EditMode/MediaObjectSvgIcons';

const MediaObjectDrawerPreview = ({
  personId,
  data: { formats, links, mimeType, mediaType, base64DataUriString },
  formatType = 'SM',
  displaySVG = true,
}) => {
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  };

  const previewStyle = {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  };
  switch (mimeType) {
    case MIME_TYPE_IMAGE_JPG:
    case MIME_TYPE_IMAGE_JPEG:
    case MIME_TYPE_IMAGE_PNG:
    case MIME_TYPE_IMAGE_WEBP:
      return displaySVG ? (
        <div style={iconStyle}>
          <ImageIcon width="48" height="100%" />
        </div>
      ) : (
        <Image
          personId={personId}
          src={links[getBestTargetImageFormat(formats, formatType)]}
          placeholder={base64DataUriString || null}
          className={styles.imgCover}
          height="100%"
          alt="Media Image"
        />
      );

    case MIME_TYPE_VIDEO_MP4:
      return displaySVG ? (
        <div style={iconStyle}>
          <ImageIcon width="48" height="100%" />
        </div>
      ) : (
        <Video
          personId={personId}
          src={links.self}
          controls={false}
          className={styles.videoThumb}
          height="100%"
          cover={true}
        />
      );

    case MIME_TYPE_APPLICATION_PDF:
      return displaySVG ? (
        <div style={iconStyle}>
          <PdfIcon width="48" height="100%" />
        </div>
      ) : (
        <div style={previewStyle}>
          <img
            src={pdficon}
            alt="PDF File"
            style={{ height: '100%', maxHeight: '48px', width: 'auto' }}
          />
        </div>
      );

    case MIME_TYPE_APPLICATION_WORD:
    case MIME_TYPE_APPLICATION_DOCX:
      return displaySVG ? (
        <div style={iconStyle}>
          <WordIcon width="48" height="100%" />
        </div>
      ) : (
        <div style={previewStyle}>
          <img
            src={wordicon}
            alt="Word File"
            style={{ height: '100%', maxHeight: '48px', width: 'auto' }}
          />
        </div>
      );

    case MIME_TYPE_APPLICATION_EXCEL:
    case MIME_TYPE_APPLICATION_XLSX:
      return displaySVG ? (
        <div style={iconStyle}>
          <OtherIcon width="48" height="100%" />
        </div>
      ) : (
        <div style={previewStyle}>
          <img
            src={excelicon}
            alt="Excel File"
            style={{ height: '100%', maxHeight: '48px', width: 'auto' }}
          />
        </div>
      );

    case MIME_TYPE_TEXT_PLAIN:
    case MIME_TYPE_TEXT_CSV:
    case MIME_TYPE_TEXT_PSV:
    case MIME_TYPE_TEXT_TSV:
    default:
      return (
        <div style={iconStyle}>
          <OtherIcon width="48" height="100%" />
        </div>
      );
    //throw new Error(`Unsupported mime type ${mimeType}`);
  }
};

export default MediaObjectDrawerPreview;
