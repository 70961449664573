import React, { useState } from 'react';
import MediaObjectDrawer from '../MediaObjectDrawer';
import PhotosIcon from '@material-ui/icons/PhotoLibrary';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../styles/MediaObjectDrawer.css';
import MediaObject from '../../../containers/MediaObject';
import { connect } from 'react-redux';
import { getMediaObjectsForRecordByAppArea } from '../helpers';
import {
  cancelAddMedia,
  setDrawerState,
  setCurrentItem,
  setMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import { toggleDocumentsDrawer } from '../../../../store/reducers/documents/actions';
import PropTypes from 'prop-types';

const MediaObjectDrawerWithButton = props => {
  const toggleDrawer = (event, status) => {
    if (
      Boolean(event && event.type) &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if (status) {
      props.setMedia([props.recordMediaObjects]);
      props.setCurrentItem(props.recordMediaObjects);
      props.setDrawerState('VIEW');
    }
    props.toggleDocumentsDrawer(status);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <Tooltip title={props.tooltip ? props.tooltip : ''}>
        <IconButton
          className={
            Boolean(props.disableMargin)
              ? styles.noMarginIconStyle
              : styles.iconsStyle
          }
          size="small"
          color="primary"
          onClick={e => {
            e.stopPropagation();
            toggleDrawer(e, true);
          }}
        >
          <PhotosIcon color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

MediaObjectDrawerWithButton.propTypes = {
  disableMargin: PropTypes.bool,
  areaType: PropTypes.string,
  recordMediaObjects: PropTypes.object.isRequired,
};

MediaObjectDrawerWithButton.defaultProps = {
  disableMargin: false,
};

const mapStateToProps = state => ({
  isDrawerOpen: state.documents.isDrawerOpen,
});

const mapDispatchToProps = dispatch => ({
  setMedia: bundle => dispatch(setMedia(bundle)),
  setDrawerState: type => dispatch(setDrawerState(type)),
  cancelAddMedia: () => dispatch(cancelAddMedia()),
  setCurrentItem: obj => dispatch(setCurrentItem(obj)),
  toggleDocumentsDrawer: bool => dispatch(toggleDocumentsDrawer(bool)),
});

export default connect(null, mapDispatchToProps)(MediaObjectDrawerWithButton);
