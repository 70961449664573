import React, { Component } from "react";
import PropTypes from "prop-types";

class Timer extends Component {
  
  interval = null;

  state = {
    timer: "00:00",
    isNegative: false
  };

  componentDidMount() {
    this.setState({ timer: this.props.duration });
    this.interval = setInterval(this.calculateTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateTime = () => {
    const { isNegative } = this.state;
    const { allowNegative, onFinished } = this.props;
    const parts = this.state.timer.split(":");
    let m = this.formatGroup(parts[0], "MINUTE");
    let s = this.formatGroup(parts[1] - 1, "SECOND");

    if (s === "59") {
      m--;
    }

    let hasTurnedNegative = isNegative;
    if (parseInt(m, 10) === 0 && s <= 0) {
      hasTurnedNegative = true;
    }

    if (!allowNegative && hasTurnedNegative) {
      clearInterval(this.interval);
      if (onFinished) {
        onFinished();
      }
    }

    this.setState({ timer: `${m}:${s}`, isNegative: hasTurnedNegative });
  };

  formatGroup = (part, type) => {
    switch (type) {
      case "HOUR":
      case "MINUTE":
        return part.length < 1 ? `0${part}` : part;
      case "SECOND":
        if (part < 10 && part >= 0) {
          return `0${part}`;
        } else if (part < 0) {
          return "59";
        }
        return part;
      default:
        return null;
    }
  };

  render() {
    const { positiveClassName, negativeClassName } = this.props;
    const { timer, isNegative } = this.state;
    const className = isNegative ? negativeClassName : positiveClassName;

    return <span className={className}>{timer}</span>;
  }
}

Timer.defaultProps = {
  allowNegative: false,
  positiveClassName: "positive",
  negativeClassName: "negative"
};

Timer.propTypes = {
  allowNegative: PropTypes.bool,
  positiveClassName: PropTypes.string.isRequired,
  negativeClassName: PropTypes.string.isRequired,
  onFinished: PropTypes.func
};

export default Timer;
