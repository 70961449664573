import { makeStyles } from '@material-ui/core';

export const styles = theme => {
  return {
    card: {
      borderRadius: 6,
      boxShadow: '0px 2px 10px rgba(83,86,94,.25)',
      overflow: 'hidden',
      position: 'relative',
      transition: 'all .2s',
    },
    overlay: {
      backgroundColor: 'rgba(68,68,71,.7)',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      transition: 'all 0.2s',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 1,
      },
    },
    icons: {
      position: 'absolute',
      top: 13,
      right: 8,
    },
    cardBottom: {
      marginTop: 16,
    },
    icon: {
      marginRight: 8,
      color: '#444447',
    },
    topBar: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.primary.main,
    },

    topBarExpanded: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,

      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    collapseText: {
      display: 'flex',
      color: '#444447',
      padding: '16px 8px',
    },
  };
};

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    gridGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
