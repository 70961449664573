import React, { useState, useEffect } from 'react';
import PageHeader from '../../components/PageHeader';
import BasePage from '../BasePage';
import { Grid, Typography, Button, Icon, Divider } from '@material-ui/core';
import Loading from './Loading';
import Empty from '../../components/Empty/Empty';
import Drawer from './Drawer';
import LabsTable from './LabsTable';
import { useLabActions, UPDATE, CREATE, IDLE, VIEW, SUBMIT } from './hooks';
import BaseLoader from '../../components/BaseLoader';

const LOADING = 'loading';
const EMPTY = 'empty';
const SUCCESS = 'success';

const LabsPage = props => {
  const [status, setStatus] = useState(IDLE);
  const { fetchLabTests, uiState, personId, labs, location } = props;
  const actions = useLabActions(props, setStatus);

  useEffect(() => {
    fetchLabTests();

    if (location.state && location.state.from === 'healthStatus') {
      actions.addLab();
    }
  }, [personId]);

  useEffect(() => {
    if (status === IDLE) {
      props.clearFiles();
    }
  }, [status]);

  const renderViews = () => {
    switch (uiState) {
      case LOADING:
        return <BaseLoader />;
      case EMPTY:
        return (
          <Empty
            name="Lab Tests / Pathology / Screening"
            onClick={actions.addLab}
            content={
              <div>
                <Typography style={{ fontSize: '16px', paddingBottom: '16px' }}>
                  Capturing your Lab Tests and Screening results helps your Care
                  Team better understand your medical history, and can help
                  reduce your medical expenses by eliminating the need for
                  duplicate tests.
                </Typography>
                <Typography style={{ fontSize: '16px' }}>
                  Additionally, combining all of your test results into one
                  place can help your Care Team discover interactions that can
                  help them devise the most effective treatment plan for you.
                </Typography>
              </div>
            }
          />
        );
      case SUCCESS:
        return (
          <LabsTable
            deleting={labs.deleting}
            listIds={labs.listIds}
            list={labs.list}
            onEdit={actions.updateLab}
            onDelete={actions.removeLab}
            viewMediaObjects={actions.viewMediaObjects}
          />
        );
    }
  };

  return (
    <BasePage>
      {uiState === SUCCESS && (
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <PageHeader
            headerText="Lab Tests / Pathology / Screening"
            subHeaderText="View Records"
            backButtonText="Go Back"
            backLink={props.navigation.dashboard.healthRecord.self}
            rightSlot={
              <Button
                color="primary"
                variant="contained"
                aria-label="Add"
                onClick={actions.addLab}
                style={{ float: 'right' }}
              >
                <Icon>add</Icon>
                New Record
              </Button>
            }
          />
          <Divider />
        </Grid>
      )}
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Drawer
          setStatus={setStatus}
          status={status}
          onClose={actions.handleCloseDrawer}
          onSubmit={actions.handleSubmit}
          onUpdateMediaObject={actions.onUpdateMediaObject}
          {...props}
        />
        {renderViews()}
      </Grid>
    </BasePage>
  );
};

export default LabsPage;
