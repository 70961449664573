import React from 'react';
import { format, parseISO } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import ProgressButton from '../../Buttons/ProgressButton/ProgressButton';
import Button from '@material-ui/core/Button';
import DocumentFormFields from '../AddMode/DocumentFormFields';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCategoryFromAreaType } from './categoryFromAreaType';
import '../styles/MediaObjectDrawer.css';
import { Field, reduxForm, submit } from 'redux-form';

const MediaEditForm = ({
  currentMediaFile,
  isSubmitting,
  onClickCancel,
  pristine,
  dispatch,
  onSubmit,
  isDocumentSubmitting,
  anyTouched,
  disableCategoryField,
}) => {
  return (
    <Grid item xs={12}>
      <form onSubmit={onSubmit}>
        <Grid container item spacing={2} style={{ padding: '8px' }}>
          <DocumentFormFields
            disableCategoryField={
              disableCategoryField ||
              currentMediaFile.applicationArea === 'IMMUNIZATION'
            }
            fileName={currentMediaFile.fileName}
          />
          <Grid item xs={6}>
            <Button
              color="default"
              variant="contained"
              size="large"
              onClick={onClickCancel}
              disableElevation
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <div style={{ display: 'inline-block' }}>
              <ProgressButton
                disableElevation
                disabled={pristine}
                isPending={isDocumentSubmitting}
                type="button"
                size="large"
                onClick={() => dispatch(submit('documentForm'))}
              >
                Save
              </ProgressButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
const mapStateToProps = (state, ownProps) => ({
  initialValues:
    ownProps.currentMediaFile.applicationArea === 'DOCUMENTS'
      ? {
          category: ownProps.areaType
            ? getCategoryFromAreaType(ownProps.areaType)
            : state.mediaObjectDrawer.currentMediaFile.category,
          displayName: state.mediaObjectDrawer.currentMediaFile.displayName
            ? state.mediaObjectDrawer.currentMediaFile.displayName
            : state.mediaObjectDrawer.currentMediaFile.fileName,
          description:
            state.mediaObjectDrawer.currentMediaFile.description &&
            state.mediaObjectDrawer.currentMediaFile.description,
          newEnteredOnDate:
            state.mediaObjectDrawer.currentMediaFile.enteredOn &&
            format(
              new Date(state.mediaObjectDrawer.currentMediaFile.enteredOn),
              'yyyy-MM-dd'
            ),
          newEnteredOnTime:
            state.mediaObjectDrawer.currentMediaFile.enteredOn &&
            format(
              new Date(state.mediaObjectDrawer.currentMediaFile.enteredOn),
              'HH:mm'
            ),
          enteredOn:
            state.mediaObjectDrawer.currentMediaFile.enteredOn &&
            new Date(state.mediaObjectDrawer.currentMediaFile.enteredOn),
        }
      : {
          category:
            getCategoryFromAreaType(
              ownProps.currentMediaFile.applicationArea.toLowerCase()
            ) || ownProps.currentMediaFile.category,
          enteredOn: ownProps.currentMediaFile.enteredOn,
          displayName: ownProps.currentMediaFile.displayName || '',
          description: ownProps.currentMediaFile.description || '',
          newEnteredOnDate: format(
            new Date(ownProps.currentMediaFile.enteredOn),
            'yyyy-MM-dd'
          ),
          newEnteredOnTime: format(
            new Date(ownProps.currentMediaFile.enteredOn),
            'HH:mm'
          ),
        },
  isDocumentSubmitting: state.documents.isSubmitting,
  disableCategoryField: Boolean(ownProps.areaType),
});

const MediaInfoDetailReduxForm = reduxForm({
  form: 'documentForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(MediaEditForm));

export default connect(mapStateToProps)(MediaInfoDetailReduxForm);
