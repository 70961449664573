import React from 'react';
import InfoCard from './InfoCard';
import WaysToShareEmptyView from './WaysToShareEmptyView.js';
import { Grid, Typography } from '@material-ui/core';

export const QR = 'QR';
export const DOWNLOAD = 'download';
export const LINK = 'link';

const wayToShareDate = [
  {
    id: QR,
    title: 'QR Code',
    content:
      'when traveling to a place that needs to verify your COVID Health Status like airports, cruiselines, event venues, etc.',
  },
  {
    id: DOWNLOAD,
    title: 'Download / Print',
    content:
      'when you need to take a printed copy of your report with you or want to store your report somewhere else. For example, to give to your employer or your doctor. ',
  },
  {
    id: LINK,
    title: 'Shareable Link',
    content:
      'when you want to share a digital copy of your report (and a QR code reader is not available), and/or limit access to the digital copy of your report. ',
  },
];

const WaysToShareInfo = ({ isEmpty, onClick, onSelectLearnMore }) => {
  return isEmpty ? (
    <WaysToShareEmptyView />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography
          component="h5"
          variant="h5"
          gutterBottom
          style={{ color: '#fff', fontSize: 20 }}
        >
          3 Ways to Share
        </Typography>
      </Grid>
      {wayToShareDate.map(item => (
        <InfoCard
          key={`${item.id}-waysToShare`}
          {...item}
          onClick={onClick}
          onSelectLearnMore={onSelectLearnMore}
        />
      ))}
    </Grid>
  );
};

export default WaysToShareInfo;
