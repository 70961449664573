import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  fieldContainer: { display: 'flex', marginTop: 16, justifyContent: 'center' },
  centeredFlex: { display: 'flex', justifyContent: 'center' },
  errorIcon: { color: '#E6BC05' },
  actionContainer: {
    display: 'flex',
    marginTop: 24,
    background: '#e3f2fd',
    padding: 24,
    justifyContent: 'flex-end',
  },
});
