import moment from 'moment';

export default values => {
  const errors = {};
  const dateStarted = moment(values.dateStarted);
  const dateDiscontinued = moment(values.dateDiscontinued);

  if (values.dose && values.dose < 0) {
    errors.dose = `Please enter a positive number`;
  }

  if (!values.name || (values.name && values.name.trim().length === 0)) {
    errors.name = 'Required';
  }

  if (values.dateDiscontinued && dateStarted.isAfter(dateDiscontinued)) {
    errors.dateStarted = 'Start date must come before the end date';
  }

  if (values.dateDiscontinued && dateDiscontinued.isBefore(dateStarted)) {
    errors.dateDiscontinued = 'End date must come after start date';
  }

  if (values.relatedconditions && values.relatedconditions.length > 40) {
    errors.relatedConditions = `Only a maximum of 40 reactions will be accepted. There are ${values.relatedconditions.length} reactions specified`;
  }

  if (values.sideeffects && values.sideeffects.length > 40) {
    errors.sideEffects = `Only a maximum of 40 treatments will be accepted. There are ${values.sideeffects.length} treatments specified`;
  }

  return errors;
};
