import React, { useState, useEffect } from 'react';
import InputField from '../../../../../library/forms/InputField';
import {
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  withStyles,
  Grid,
  Tooltip,
  Fade,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const RedToolTip = withStyles({
  tooltip: {
    color: '#611A15',
    backgroundColor: '#FDECEA',
    boxShadow: '-3px -6px 20px -6px #888888',
  },
  arrow: {
    color: '#FDECEA',
    textShadow: '-3px -6px 6px -6px #888888',
  },
})(Tooltip);

const useStyles = makeStyles({
  root: {
    minHeight: '80px',
  },
  customTooltip: {
    whiteSpace: 'pre-wrap',
  },
  customArrow: {
    color: '#FDECEA',
    textShadow: '-3px -6px 6px -6px #888888',
  },
});

const ErrorTooltip = props => {
  const classes = useStyles();
  const {
    tooltipPlacement,
    children,
    displayErrorEndAdornment,
    ...rest
  } = props;
  const [open, setIsOpen] = useState(false);
  const hasError = !!(props.meta.touched && props.meta.error);

  useEffect(() => {
    if (props.meta.active && hasError) setIsOpen(true);
    if (!hasError || !props.meta.active) setIsOpen(false);
  }, [props.meta.active, props.meta.error]);

  const shouldDisplayErrorEndAdornment =
    displayErrorEndAdornment && !open && hasError;

  return (
    <RedToolTip
      PopperProps={{
        disablePortal: true,
      }}
      // classes={{
      //   tooltip: classes.customTooltip,
      //   arrow: classes.customArrow,
      // }}
      arrow
      disableFocusListener
      disableTouchListener
      placement={tooltipPlacement ? tooltipPlacement : 'bottom'}
      title={
        <div
          style={{
            margin: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flexStart',
          }}
        >
          <ErrorOutlineIcon style={{ fontSize: '18px' }} />
          <Typography style={{ fontSize: '10px', marginLeft: '5px' }}>
            {props.meta.error}
          </Typography>
        </div>
      }
      leaveDelay={500}
      open={open}
    >
      {React.cloneElement(children, {
        ...rest,
        hasErrorTooltip: true,
        endAdornment: shouldDisplayErrorEndAdornment ? (
          <InputAdornment
            position="end"
            disableTypography
            style={{
              color: '#C22929',
              lineHeight: '1.5',
              marginRight: '-4px',
            }}
          >
            <Fade in={true} timeout={300}>
              <ErrorOutlineIcon />
            </Fade>
          </InputAdornment>
        ) : !!props.endAdornment ? (
          props.endAdornment
        ) : (
          ''
        ),
      })}
    </RedToolTip>
  );
};

export default ErrorTooltip;
