import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';

import { Grid, withStyles } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import { mask } from '../../../../library/helpers/format';
import FormFooter from '../FormFooter';
import validate from './validateForm';
import asyncValidate from './asyncValidate';
import { ROLE_NAME_GUARDIAN_PRIMARY } from '../RoleSummary/data';

const styles = {
  field: {
    padding: `0 !important`,
    paddingLeft: `12px !important`,
    paddingRight: `12px !important`,
    height: 90,
  },
};

const phoneMask = value => mask(value, 'XXX-XXX-XXXX');

const NonProvider01Form = props => {
  const { classes, handleSubmit, links, valid, member, isUpdating } = props;

  const disabled =
    isUpdating && member.relationshipType === ROLE_NAME_GUARDIAN_PRIMARY
      ? true
      : false;

  useEffect(() => {
    props.onChangeForm({ valid });
  }, [valid]);

  const defaultOptions = [
    {
      longName: 'Aunt',
      shortName: 'Aunt',
    },
    {
      longName: 'Brother',
      shortName: 'Brother',
    },
    {
      longName: 'Cousin',
      shortName: 'Cousin',
    },
    {
      longName: 'Dad',
      shortName: 'Dad',
    },
    {
      longName: 'Friend',
      shortName: 'Friend',
    },
    {
      longName: 'Grandma',
      shortName: 'Grandma',
    },
    {
      longName: 'Grandpa',
      shortName: 'Grandpa',
    },
    {
      longName: 'Husband',
      shortName: 'Husband',
    },
    {
      longName: 'Mom',
      shortName: 'Mom',
    },
    {
      longName: 'Sister',
      shortName: 'Sister',
    },
    {
      longName: 'Uncle',
      shortName: 'Uncle',
    },
    {
      longName: 'Wife',
      shortName: 'Wife',
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={2} className={classes.field}>
          <Field
            type="text"
            name="prefix"
            label="Prefix"
            // placeholder="Mrs"
            component={InputField}
            hint="Optional"
            disabled={disabled}
          />
          {/* <Field
            name="prefix"
            label="Prefix"
            labelKey="longName"
            valueKey="shortName"
            hint="Optional"
            component={SelectField}
            options={[]}
            isSearchable
            isCreatable
          /> */}
        </Grid>
        <Grid item xs={4} className={classes.field}>
          <Field
            type="text"
            name="firstName"
            label="First Name"
            // placeholder="Mary"
            component={InputField}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="lastName"
            label="Last Name"
            // placeholder="Poppins"
            component={InputField}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="email"
            label="Email"
            component={InputField}
            disabled={disabled}
            hint="Email is only required to grant the member access"
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="phoneNumber"
            label="Phone Number"
            // placeholder="234-945-4328"
            component={InputField}
            format={phoneMask}
            disabled={disabled}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <Field
            name="labels"
            label="Member Labels"
            labelKey="longName"
            valueKey="shortName"
            hint="Labels help customize your care team member"
            component={SelectField}
            options={defaultOptions}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
      </Grid>
      <FormFooter links={links} />
    </form>
  );
};

export default reduxForm({
  form: 'care-team-add',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(withStyles(styles)(NonProvider01Form));
