import { v1 as uuidv1 } from 'uuid';

import {
  SET_SELECTED_CONDITIONS,
  CLEAR_SELECTED_CONDITIONS,
  FAMILY_HISTORY_REQUEST,
  FAMILY_HISTORY_SUCCESS,
  FAMILY_HISTORY_FAILURE,
  FAMILY_HISTORY_UPDATE_REQUEST,
  FAMILY_HISTORY_UPDATE_SUCCESS,
  FAMILY_HISTORY_UPDATE_FAILURE,
  FAMILY_HISTORY_BULK_UPDATE_REQUEST,
  FAMILY_HISTORY_BULK_UPDATE_SUCCESS,
  FAMILY_HISTORY_BULK_UPDATE_FAILURE,
} from './actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

const initialState = {
  savingCondition: '',
  isFetchingFamilyHistory: false,
  isSubmitting: false,
  list: [],
  error: undefined,
  selected: [],

  uiState: LOADING,
};

const reducers = [];

reducers[SET_SELECTED_CONDITIONS] = (state, action) => {
  return {
    ...state,
    selected: action.payload,
  };
};

reducers[CLEAR_SELECTED_CONDITIONS] = (state, action) => {
  return {
    ...state,
    selected: [],
  };
};

reducers[FAMILY_HISTORY_REQUEST] = (state, _) => {
  return {
    ...state,
    isFetchingFamilyHistory: true,
    uiState: LOADING,
  };
};

reducers[FAMILY_HISTORY_SUCCESS] = (state, action) => {
  return {
    ...state,
    list: action.payload.map(record => ({
      tid: uuidv1(),
      ...record,
    })),
    isFetchingFamilyHistory: false,
    uiState: SUCCESS,
  };
};

reducers[FAMILY_HISTORY_FAILURE] = (state, _) => {
  return {
    ...state,
    list: [],
    isFetchingFamilyHistory: false,
    uiState: ERROR,
  };
};

reducers[FAMILY_HISTORY_UPDATE_REQUEST] = (state, action) => {
  const listIndex = state.list.findIndex(
    x => x.condition.toLowerCase() === action.payload.condition.toLowerCase()
  );

  const nextList = state.list.slice();

  nextList[listIndex] = {
    ...nextList[listIndex],
    ...action.payload,
    status: 'SAVING',
  };

  return {
    ...state,
    savingCondition: action.payload.condition,
    list: nextList,
    isSubmitting: true,
    error: undefined,
  };
};

reducers[FAMILY_HISTORY_UPDATE_SUCCESS] = (state, action) => {
  const listIndex = state.list.findIndex(
    x => x.condition.toLowerCase() === action.payload.condition.toLowerCase()
  );

  const nextList = state.list.slice();

  nextList[listIndex] = {
    ...nextList[listIndex],
    ...action.payload,
    status: 'SUCCESS',
  };

  return {
    ...state,
    savingCondition: '',
    list: nextList,
    isSubmitting: false,
    error: undefined,
  };
};

reducers[FAMILY_HISTORY_UPDATE_FAILURE] = (state, action) => {
  const listIndex = state.list.findIndex(
    x => x.condition.toLowerCase() === action.payload.condition.toLowerCase()
  );

  const nextList = state.list.slice();

  nextList[listIndex] = {
    ...nextList[listIndex],
    ...action.payload,
    status: 'FAILURE',
  };

  return {
    ...state,
    savingCondition: '',
    list: nextList,
    isSubmitting: false,
    error: action.payload,
  };
};

reducers[FAMILY_HISTORY_BULK_UPDATE_REQUEST] = (state, action) => {
  const list = state.list.slice();

  for (var i = 0; i < state.list.length; i++) {
    for (var j = 0; j < action.payload.length; j++) {
      if (
        state.list[i].condition.toUpperCase() ===
        action.payload[j].condition.toUpperCase()
      ) {
        list[i] = {
          ...state.list[i],
          ...action.payload[j],
          status: 'SAVING',
        };
      }
    }
  }

  return {
    ...state,
    list,
    isSubmitting: true,
    error: undefined,
  };
};

reducers[FAMILY_HISTORY_BULK_UPDATE_SUCCESS] = (state, action) => {
  const list = state.list.slice();

  for (var i = 0; i < state.list.length; i++) {
    for (var j = 0; j < action.payload.length; j++) {
      if (
        state.list[i].condition.toUpperCase() ===
        action.payload[j].condition.toUpperCase()
      ) {
        list[i] = {
          ...state.list[i],
          ...action.payload[j],
          status: 'SUCCESS',
        };
      }
    }
  }

  return {
    ...state,
    list,
    isSubmitting: true,
    error: undefined,
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
