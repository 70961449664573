import { defaultDataNormalizer } from '../utils';

const dataFields = {
  sleptOn: null,
  awokeOn: null,
  totalTimeSlept: '',
  sleepQuality: null,
  sleepIssues: [],
};

const normalizeData = defaultDataNormalizer;

const properties = {
  label: 'Sleep',
  color: '#68B3E0',
  icon: 'hotel',
  dataFields,
  normalizeData,
};

export { dataFields, properties };
