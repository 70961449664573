import React, { Component } from "react";
import PropTypes from "prop-types";

import IntegerInput from "./IntegerInput";

import styles from "./TimeInput.css";

class TimeInput extends Component {
  constructor(props) {
    super(props);

    this.refGroup = React.createRef();
    this.refHours = React.createRef();
    this.refMinutes = React.createRef();

    const hours = props.is24hr
      ? props.date.getHours()
      : ((props.date.getHours() + 11) % 12) + 1;

    this.state = {
      hours: hours,
      minutes: props.date.getMinutes(),
      period: props.date.getHours() >= 12 ? "PM" : "AM"
    };
  }

  onChange = field => (value, event) => {
    const state = {
      ...this.state,
      [field]: value,
      type: this.props.is24hr ? "24HR" : "12HR"
    };

    this.props.onChange(state);
    this.setState(state);
  };

  onPeriodChange = event => {
    const period = event.target.value.trim();
    const state = {
      ...this.state,
      period,
      type: this.props.is24hr ? "24HR" : "12HR"
    };

    this.props.onChange(state);
    this.setState(state);
  };

  render() {
    const { is24hr } = this.props;

    return (
      <div className={ styles.root }>
        <div ref={this.refGroup} className={ styles.group }>
          <div id="hours" className={ styles.digits }>
            <button onClick={e => this.refHours.current.increment()}>+</button>
            <IntegerInput
              ref={this.refHours}
              parentRef={this.refGroup}
              value={this.state.hours}
              onChange={this.onChange("hours")}
              minValue={is24hr ? 0 : 1}
              maxValue={is24hr ? 24 : 12}
              padLength={2}
              padString="0"
              isRotating={true}
            />
            <button onClick={e => this.refHours.current.decrement()}>-</button>
          </div>
          <div className={ styles.separator }>:</div>
          <div id="minutes" className={ styles.digits }>
            <button onClick={e => this.refMinutes.current.increment()}>
              +
            </button>
            <IntegerInput
              ref={this.refMinutes}
              parentRef={this.refGroup}
              value={this.state.minutes}
              onChange={this.onChange("minutes")}
              minValue={0}
              maxValue={59}
              padLength={2}
              padString="0"
              isRoatating={true}
            />
            <button onClick={e => this.refMinutes.current.decrement()}>
              -
            </button>
          </div>
          {!is24hr ? (
            <div id="period" className={ styles.digits }>
              <select value={this.state.period} onChange={this.onPeriodChange}>
                <option>AM</option>
                <option>PM</option>
              </select>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

TimeInput.propTypes = {
  isZulu: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

TimeInput.defaultProps = {
  date: new Date(),
  is24hr: false
};

export default TimeInput;
