import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import Iframe from 'react-iframe';
import { connect } from 'react-redux';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import FieldGroupHeader from '../../../components/Form/FieldGroupHeader';
import FormPanel from '../../../components/Form/FormPanel';
import CredentialFormFields from '../../FormFields/CredentialFormFields';
import GeneralSignupFormFields from '../../FormFields/GeneralSignupFormFields';
// eslint-disable-next-line
import asyncValidate from './asyncValidate';
import { useMediaQuery } from '@material-ui/core';
import validate from './validate';
import ProgressButtonLinear from '../../../components/Buttons/ProgressButtonLinear';
import { DashboardMessage } from '../../../components/DashboardContent';

const formName = 'memberSignup';

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  primaryButton: {
    color: '#fff',
  },
  icon: {
    fontSize: theme.spacing(2) + 2,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  info: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderLeft: `solid 4px ${theme.palette.primary.main}`,
  },
});

const ModalIFrame = ({ isVisible, url = '', onClose }) => (
  <Grid item xs={12} style={{ textAlign: 'center' }}>
    <Modal
      visible={isVisible}
      width="95%"
      height="95%"
      effect="fadeInUp"
      onClickAway={onClose}
    >
      <br />
      {url && url.length > 0 && (
        <Iframe
          display="initial"
          style={{ maxWidth: '100%;', marginTop: '20px;' }}
          height="90%"
          width="95%"
          url={url}
          position="relative"
        />
      )}
      <br />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onClose}
      >
        CLOSE
      </Button>
    </Modal>
  </Grid>
);

const Test = ({ handleSubmit, classes, pristine, submitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <FieldGroupHeader headerText="General" />
      <GeneralSignupFormFields />

      <FieldGroupHeader headerText="Credentials" />
      <CredentialFormFields />
      {/* <ModalIFrame
        url={iFrameURL}
        isVisible={iFrameURL !== null}
        onClose={closeIframe}
      /> */}

      <Grid container item spacing={3} justify="flex-end">
        <Grid container item xs={12} sm={6}>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            className={classes.primaryButton}
            disabled={pristine || submitting}
            fullWidth
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const TestForm = reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email'],
  validate,
  persistentSubmitErrors: true,
})(Test);

export { TestForm };

const GeneralInfoForm = ({
  submitting,
  pristine,
  invalid,
  handleSubmit,
  classes,
  disabled,
  failureMessage,
  formErrors,
}) => {
  const [iFrameURL, setIFrameURL] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    firstName,
    lastName,
    dob,
    postalCode,
    email,
    emailConfirm,
    password,
    passwordConfirm,
  } = formErrors;

  const hasFormErrors =
    !!firstName ||
    !!lastName ||
    !!dob ||
    !!postalCode ||
    !!email ||
    !!emailConfirm ||
    !!password ||
    !!passwordConfirm;

  const openIframe = payload => event => {
    event.preventDefault();
    setIFrameURL(payload);
  };

  const closeIframe = event => {
    event.preventDefault();
    setIFrameURL(null);
  };

  return (
    <FormPanel
      onSubmit={handleSubmit}
      elevation={isSmallScreen ? 0 : 1}
      formSlot={() => (
        <React.Fragment>
          {!isSmallScreen && (
            <Typography variant="h4" style={{ marginTop: '8px' }}>
              Create an Account
            </Typography>
          )}

          <FieldGroupHeader
            headerText="General"
            disableMargin={isSmallScreen}
          />
          <GeneralSignupFormFields disabled={disabled} />

          <FieldGroupHeader headerText="Credentials" />
          <CredentialFormFields disabled={disabled} />
          <ModalIFrame
            url={iFrameURL}
            isVisible={iFrameURL !== null}
            onClose={closeIframe}
          />
          {failureMessage ? (
            <div style={{ marginTop: 16 }}>
              <DashboardMessage type="danger" canClose={false}>
                {failureMessage}
              </DashboardMessage>
            </div>
          ) : null}
        </React.Fragment>
      )}
      bottomLeftSlot={() => (
        <React.Fragment>
          <p>
            {' '}
            By clicking Next, you agree to our&nbsp;
            <a
              href="https://www.agrinhealth.com/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </a>{' '}
            and&nbsp;
            <a
              href="https://www.agrinhealth.com/privacy-policy"
              target="_blank"
            >
              Privacy Policies
            </a>
          </p>
        </React.Fragment>
      )}
      bottomRightSlot={() => (
        <Grid container item spacing={3} justify="flex-end">
          <Grid container item xs={12} sm={6} justify="flex-end">
            <ProgressButtonLinear
              variant="contained"
              type="submit"
              color="secondary"
              size="large"
              className={classes.primaryButton}
              disabled={submitting}
              fullWidth
              isProgressing={submitting}
            >
              Next
            </ProgressButtonLinear>
          </Grid>
        </Grid>
      )}
    />
  );
};

GeneralInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
  form: formName,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  asyncValidate,
  asyncBlurFields: ['email'],
  validate,
  persistentSubmitErrors: false,
})(GeneralInfoForm);

const mapStateToProps = (state, ownProps) => {
  const { firstName, lastName, code } = state.member.invitation;
  const hasInvitation = firstName.length > 0 && lastName.length > 0;

  return {
    form: 'memberSignup',
    firstName: firstName,
    lastName: lastName,
    formErrors: getFormSyncErrors('memberSignup')(state),
    hasInvitation,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(form));
