import { v1 as uuidv1 } from 'uuid';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';

export const CARE_TEAM_REQUEST = 'care-team/REQUEST';
export const CARE_TEAM_SUCCESS = 'care-team/SUCCESS';
export const CARE_TEAM_FAILURE = 'care-team/FAILURE';

// export const CARE_TEAM_CREATE_PROVIDER_REQUEST =
//   'care-team/CREATE_PROVIDER_REQUEST';
// export const CARE_TEAM_CREATE_PROVIDER_SUCCESS =
//   'care-team/CREATE_PROVIDER_SUCCESS';
// export const CARE_TEAM_CREATE_PROVIDER_FAILURE =
//   'care-team/CREATE_PROVIDER_FAILURE';

export const CARE_TEAM_CREATE_REQUEST = 'care-team/CREATE_REQUEST';
export const CARE_TEAM_CREATE_SUCCESS = 'care-team/CREATE_SUCCESS';
export const CARE_TEAM_CREATE_FAILURE = 'care-team/CREATE_FAILURE';

export const CARE_TEAM_UPDATE_REQUEST = 'care-team/UPDATE_REQUEST';
export const CARE_TEAM_UPDATE_SUCCESS = 'care-team/UPDATE_SUCCESS';
export const CARE_TEAM_UPDATE_FAILURE = 'care-team/UPDATE_FAILURE';

export const CARE_TEAM_ARCHIVE_REQUEST = 'care-team/ARCHIVE_REQUEST';
export const CARE_TEAM_ARCHIVE_SUCCESS = 'care-team/ARCHIVE_SUCCESS';
export const CARE_TEAM_ARCHIVE_FAILURE = 'care-team/ARCHIVE_FAILURE';

export const CARE_TEAM_DELETE_REQUEST = 'care-team/DELETE_REQUEST';
export const CARE_TEAM_DELETE_SUCCESS = 'care-team/DELETE_SUCCESS';
export const CARE_TEAM_DELETE_FAILURE = 'care-team/DELETE_FAILURE';

export const CARE_TEAM_UPDATE_SET_ID = 'care-team/UPDATE_SET_ID';
export const CARE_TEAM_UPDATE_CLEAR_ID = 'care-team/UPDATE_CLEAR_ID';

export const setUpdateId = id => ({
  type: [CARE_TEAM_UPDATE_SET_ID],
  payload: id,
});

export const clearUpdateId = () => ({
  type: [CARE_TEAM_UPDATE_CLEAR_ID],
});

export const fetchCareTeam = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [CARE_TEAM_REQUEST, CARE_TEAM_SUCCESS, CARE_TEAM_FAILURE],
    endpoint: `/api/member/relationships`,
    payload: pid,
  },
});

export const archiveCareTeamMember = (pid, rid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      CARE_TEAM_ARCHIVE_REQUEST,
      CARE_TEAM_ARCHIVE_SUCCESS,
      CARE_TEAM_ARCHIVE_FAILURE,
    ],
    endpoint: `/api/member/relationships/archive/${rid}`,
    payload: rid,
    meta: {
      pid,
      rid,
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        mode: 'cors',
      },
    },
  },
});

export const createCareTeamProvider = (pid, data) => {
  const payload = {
    ...data,
    labels: data.labels ? codeObjectListToCsv(data.labels) : '',
  };

  const oid = uuidv1();

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CARE_TEAM_CREATE_REQUEST,
        CARE_TEAM_CREATE_SUCCESS,
        CARE_TEAM_CREATE_FAILURE,
      ],
      endpoint: `/api/member/relationships/provider`,
      payload,
      meta: {
        pid,
        oid,
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const updateCareTeamProvider = (pid, relationshipId, data) => {
  const payload = {
    ...data,
    fullName: data.displayName || data.fullName,
    labels: data.labels ? codeObjectListToCsv(data.labels) : '',
    relationshipId,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CARE_TEAM_UPDATE_REQUEST,
        CARE_TEAM_UPDATE_SUCCESS,
        CARE_TEAM_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/relationships/provider`,
      payload,
      meta: {
        pid,
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const createCareTeamMember = (pid, data) => {
  const payload = {
    ...data,
    labels: data.labels ? codeObjectListToCsv(data.labels) : '',
  };

  const oid = uuidv1();

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CARE_TEAM_CREATE_REQUEST,
        CARE_TEAM_CREATE_SUCCESS,
        CARE_TEAM_CREATE_FAILURE,
      ],
      endpoint: '/api/member/relationships/person',
      payload,
      meta: {
        pid,
        oid,
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const updateCareTeamMember = (pid, relationshipId, data) => {
  const payload = {
    ...data,
    labels: data.labels ? codeObjectListToCsv(data.labels) : '',
    relationshipId,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CARE_TEAM_UPDATE_REQUEST,
        CARE_TEAM_UPDATE_SUCCESS,
        CARE_TEAM_UPDATE_FAILURE,
      ],
      endpoint: '/api/member/relationships/person',
      payload,
      meta: {
        pid,
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const deleteCareTeamMember = (pid, rid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      CARE_TEAM_DELETE_REQUEST,
      CARE_TEAM_DELETE_SUCCESS,
      CARE_TEAM_DELETE_FAILURE,
    ],
    endpoint: `/api/member/relationships/${rid}`,
    meta: {
      pid,
      rid,
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});
