import React, { useMemo, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Icon,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { format } from 'date-fns';
import twemoji from 'twemoji';

import JournalFragmentIcons from './JournalFragmentIcons';
import MediaObjectDrawer from '../../components/MediaObjectDrawer/MediaObjectDrawer';
import { csvToCodeObjectList } from '../../../library/helpers/utils';
import {
  FRAGMENT_EMOTIONS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_BODY_MASS,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_ACTIVITY_EXERCISE,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_STRESS_MANAGEMENT,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEDICATIONS,
} from '../../components/JournalCompose/FragmentsList';
import {
  EmotionsViewer,
  WaterIntakeViewer,
  BodyMassViewer,
  BloodPressureViewer,
  ActivityExerciseViewer,
  MealTrackerViewer,
  StressManagementViewer,
  MedicationsViewer,
  TotalSleepViewer,
} from './fragmentLineItemViewers';

const styles = theme => ({
  root: {
    // width: 'calc((100% * 1/3) - 16px)',
    background: '#fff',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '.8em',
  },
  // title: {
  //   lineHeight: 1.3,
  //   marginBottom: theme.spacing(1),
  // },
  icon: {
    fontSize: 20,
    color: '#aaa',
  },
  titleWrapper: {
    // width: '40%',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  body: {
    '& img': {
      height: '1em',
      width: '1em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
  desc: {
    fontSize: 12,
    color: '#737373',
  },
  tag: {
    fontSize: 12,
    color: '#fff',
    borderRadius: 20,
    padding: '4px 12px',
    backgroundColor: theme.palette.primary.main,
    marginRight: 4,
    marginBottom: 4,
  },
});

const renderTitle = str => {
  if (str && str.length > 110) {
    return `${str.substring(0, 65)}...`;
  }
  return str;
};

const JournalBodyText = ({ classes, text }) => {
  var cutText = text || '';

  if (text && text.length > 110) {
    cutText = `${text.substring(0, 105)}...`;
  }

  try {
    const parsedText = useMemo(() => twemoji.parse(cutTex, { base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' }), [cutText]);

    return (
      <Typography className={classes.body} variant="body2">
        <span dangerouslySetInnerHTML={{ __html: parsedText }} />
      </Typography>
    );
  } catch (e) {
    console.caution(`Twemoji error parsing text "${cutText}"`);
  }

  return (
    <Typography className={classes.body} variant="body2">
      <span>{cutText}</span>
    </Typography>
  );
};

class JournalListItem extends Component {
  renderFragmentData = fragments => {
    if (!fragments[0]) {
      return null;
    }

    switch (fragments[0].fragmentType) {
      case FRAGMENT_EMOTIONS:
        return <EmotionsViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_WATER_INTAKE:
        return <WaterIntakeViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_BLOOD_PRESSURE:
        return <BloodPressureViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_BODY_MASS:
        return <BodyMassViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_ACTIVITY_EXERCISE:
        return <ActivityExerciseViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_MEAL_TRACKER:
        return <MealTrackerViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_STRESS_MANAGEMENT:
        return <StressManagementViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_SLEEP_TRACKER:
        return <TotalSleepViewer data={fragments[0].fragmentData} />;
      case FRAGMENT_MEDICATIONS:
        return <MedicationsViewer data={fragments[0].fragmentData} />;

      default:
        return null;
    }
  };

  render() {
    const {
      classes,
      title,
      body,
      date,
      tags,
      attachmentCount,
      fragments,
      isModifying,
      mediaObjects,
      journalEntryId,
      onClickUpdate,
      onClickDelete,
      onCreateDuplicateJournalEntry,
    } = this.props;

    return (
      <TableRow
        style={{ opacity: isModifying ? 0.5 : 1 }}
        // onClick={onClickViewMore}
      >
        <TableCell style={{ paddingRight: 0, width: 180 }}>
          <Grid container spacing={2} wrap="nowrap" style={{ maxWidth: 160 }}>
            <JournalFragmentIcons fragments={fragments} />
            <div style={{ marginLeft: 8, fontSize: 13 }}>
              <div>{format(date, 'MMMM d, yyyy')}</div>
              <div>{format(date, 'h:mm a')}</div>
            </div>
          </Grid>
        </TableCell>
        <TableCell style={{ width: '30%' }}>
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Typography className={classes.title} variant="body1">
              {renderTitle(title)}
            </Typography>
            {/*attachmentCount > 0 && (
              <Icon className={classes.icon}>attach_file_24px</Icon>
            )*/}
          </Grid>
          <JournalBodyText classes={classes} text={body} />
          {tags && (
            <Grid container>
              {csvToCodeObjectList(tags).map((tag, i) => (
                <div key={`${tag.shortName}${i}`} className={classes.tag}>
                  {tag.shortName}
                </div>
              ))}
            </Grid>
          )}
        </TableCell>
        <Hidden smDown>
          <TableCell style={{ width: '35%' }}>
            {this.renderFragmentData(fragments)}
          </TableCell>
        </Hidden>
        <TableCell>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            wrap="nowrap"
          >
            {mediaObjects.length !== 0 && (
              // <Tooltip title="View attached media">
              <IconButton
                style={{
                  height: '44px',
                  width: '44px',
                }}
              >
                <MediaObjectDrawer
                  areaType="Journal"
                  primaryId={journalEntryId}
                  class={classes.icon}
                  style={{
                    fontSize: '22px',
                    marginBottom: '-4px',
                  }}
                />
              </IconButton>
              // </Tooltip>
            )}
            <Tooltip title="Duplicate record">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onCreateDuplicateJournalEntry();
                }}
              >
                <Icon className={classes.icon}>content_copy</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit record">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onClickUpdate();
                }}
              >
                <Icon className={classes.icon}>border_color</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete record">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onClickDelete();
                }}
              >
                <Icon className={classes.icon}>delete</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
}

JournalListItem.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  attachmentCount: PropTypes.number.isRequired,
  fragmentCount: PropTypes.number.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickUpdate: PropTypes.func.isRequired,
  onCreateDuplicateJournalEntry: PropTypes.func.isRequired,
};

JournalListItem.defaultProps = {
  attachmentCount: 0,
  fragmentCount: 0,
};

export default withStyles(styles)(JournalListItem);
