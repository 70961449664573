import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { createQueryStringFromObject } from '../../../library/helpers/encode';

export const MESSAGE_HELP_CREATE_REQUEST = 'message-help/create/REQUEST';
export const MESSAGE_HELP_CREATE_SUCCESS = 'message-help/create/SUCCESS';
export const MESSAGE_HELP_CREATE_FAILURE = 'message-help/create/FAILURE';

export const KNOWN_ISSUES_REQUEST = 'known-issues/get/REQUEST';
export const KNOWN_ISSUES_SUCCESS = 'known-issues/get/SUCCESS';
export const KNOWN_ISSUES_FAILURE = 'known-issues/get/FAILURE';

export const getIssues = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [KNOWN_ISSUES_REQUEST, KNOWN_ISSUES_SUCCESS, KNOWN_ISSUES_FAILURE],
    endpoint: `/api/core/issues`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const createHelpMessage = (pid, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      MESSAGE_HELP_CREATE_REQUEST,
      MESSAGE_HELP_CREATE_SUCCESS,
      MESSAGE_HELP_CREATE_FAILURE,
    ],
    endpoint: `/api/member/${pid}/request-help`,
    payload,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
        body: createQueryStringFromObject(payload),
        mode: 'cors',
      },
    },
  },
});
