import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Divider,
  TableRow,
  TableCell,
  Typography,
  Button,
  Icon,
} from '@material-ui/core';
import {
  fetchJournalEntries,
  deleteJournalEntry,
  setSelectedJournalEntry,
  clearSelectedJournalEntry,
  fetchJournalEntriesByCursor,
  fetchByDate,
  fetchByQuery,
  fetchQueryCursor,
} from '../../../store/reducers/journalEntries/actions';
import BasePage from '../BasePage';
import {
  openJournalModal,
  JOURNAL_MODAL_CREATE,
  JOURNAL_MODAL_EDIT,
  JOURNAL_MODAL_COPY,
  populateJournalModal,
  createJournalDuplicate,
} from '../../../store/reducers/journalmodal/actions';
import { SUCCESS, LOADING, ERROR, EMPTY } from '../../../store/state';
import PageHeader from '../../components/PageHeader';
import JournalListGroup from './JournalListGroup';
import JournalCalendar from './JournalCalendar';
import JournalDetailView from './JournalDetailView';
import JournalViewToggles from './JournalViewToggles';
import { LIST_VIEW, CALENDAR_VIEW } from './data';
import { ReportModal } from '../../components/Reports';
import JournalNoRecords from './JournalNoRecords';
import JournalFragmentIcons from './JournalFragmentIcons';
import { fragmentsList } from '../../components/JournalCompose/FragmentsList';
import { yellow } from '@material-ui/core/colors';

const FAVORITES = fragmentsList.slice(1, 3);

const header = [
  {
    name: 'Title',
    colspan: '4',
    style: {
      width: 'calc(100% * 3/12)',
    },
  },
  {
    name: 'Body',
    colspan: '3',
    style: {
      width: 'calc(100% * 6/12)',
    },
  },
  {
    name: 'Created On',
    colspan: '3',
    style: {
      width: 'calc(100% * 3/12)',
    },
  },
];

const styles = theme => ({
  root: {
    background: '#fff',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(15),
    letterSpacing: '0.01071em',
  },
  favorite: {
    border: '1px solid #AEAEAE',
    width: 90,
    height: 90,
    marginRight: 18,
    borderRadius: 4,
    position: 'relative',
    cursor: 'pointer',
  },
  favoriteStar: {
    position: 'absolute',
    fontSize: 14,
    top: 6,
    color: yellow['800'],
    right: 6,
  },
});

class JournalPage extends Component {
  state = {
    detailModal: {
      open: false,
      data: null,
    },
    view: LIST_VIEW,
    selectedId: '',
    isFetching: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.personId !== prevProps.personId ||
        this.props.timeZoneIANA !== prevProps.timeZoneIANA ||
        !Boolean(this.props.uiState)) &&
      this.props.uiState !== LOADING
    ) {
      this.props.fetchJournalEntries({ timeZoneIANA: this.props.timeZoneIANA });
    }
  }

  renderTableHeader(columns) {
    return (
      <TableRow>
        {columns.map((column, index) => (
          <TableCell key={index} style={column.style}>
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  renderTableBody = row => {
    const keys = Object.keys(row);

    if (keys.length !== header.length)
      throw new Exception(
        'Row number of columns differ from that of the table header, both must be the same length'
      );

    return (
      <TableRow>
        {keys.map((key, index) => (
          <TableCell key={key} style={header[index].style}>
            {row[key]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  openJournalModal = fragment => () => {
    if (fragment) {
      this.props.openJournalModal(true, {
        modalState: JOURNAL_MODAL_CREATE,
        fragments: [fragment],
      });
    } else {
      this.props.openJournalModal(true, { modalState: JOURNAL_MODAL_CREATE });
    }
  };

  onDeleteJournalEntry = (item, selectedId) => {
    const result = confirm(
      'Are you sure you want to delete this journal entry?'
    );

    if (result) {
      const realId = this.props.list[selectedId].journalEntryId;
      this.props.deleteJournalEntry(item.personId, realId, selectedId);
    }
  };

  onCreateDuplicateJournalEntry = item => {
    this.props.openJournalModal(true, { modalState: JOURNAL_MODAL_CREATE });

    // Uncomment line 182-186 & 202-203 to add
    // Media Objects to duplicate records

    // let mediaObjectIds = [];
    // if (item.mediaObjects.length > 0)
    //   item.mediaObjects.forEach(item =>
    //     mediaObjectIds.push(item.mediaObjectId)
    //   );
    const fragments = Boolean(item.fragments.length)
      ? [
          {
            fragmentData: item.fragments[0].fragmentData,
            fragmentType: item.fragments[0].fragmentType,
          },
        ]
      : [];

    this.props.createJournalDuplicate({
      journalEntryId: item.journalEntryId,
      datetime: new Date(),
      title: item.title,
      tags: item.tags,
      body: item.body,
      fragments,
      modalState: JOURNAL_MODAL_COPY,
      //mediaObjects: item.mediaObjects,
      // mediaObjectIds,
    });
  };

  onFetchJournalEntriesByCursor = () => {
    if (this.props.pagination.nextPage)
      this.props.fetchJournalEntriesByCursor(this.props.pagination.nextPage);
  };

  onEditJournalEntry = (item, selectedId) => {
    this.props.openJournalModal(true, { modalState: JOURNAL_MODAL_EDIT });
    this.props.populateJournalModal({
      ...item,
      datetime: new Date(item.enteredOn),
      user: {
        label: this.props.user.label,
        value: this.props.user.personId,
      },
    });
  };

  toggleDetailModal = (isActive, data, selectedId) => {
    this.props.setSelectedJournalEntry(selectedId);
    if (isActive) {
      this.setState({
        ...this.state,
        detailModal: { open: true, data },
        selectedId: selectedId,
      });
    } else {
      this.setState({
        ...this.state,
        detailModal: { open: false, data: null },
        selectedId: '',
      });
    }
  };

  setJournalView = view => this.setState({ ...this.state, view });

  dictionaryToArray = dict => {
    const arr = [];
    for (const key in dict) {
      arr.push({ ...dict[key], enteredOn: new Date(dict[key].enteredOn) });
    }
    return arr;
  };

  renderJournalView = view => {
    switch (view) {
      case LIST_VIEW:
        return (
          <JournalListGroup
            personId={this.props.personId}
            list={this.props.list}
            listIds={this.props.listIds}
            pagination={this.props.pagination}
            onClickCardDelete={this.onDeleteJournalEntry}
            onClickCardUpdate={this.onEditJournalEntry}
            onClickDetailView={this.toggleDetailModal}
            onCreateDuplicateJournalEntry={this.onCreateDuplicateJournalEntry}
            onFetchJournalEntriesByCursor={this.onFetchJournalEntriesByCursor}
            fetchByQuery={this.props.fetchByQuery}
            activeView={view}
            setView={this.setJournalView}
            isFetching={this.props.uiState === LOADING}
            searchData={this.props.search}
            fetchQueryCursor={this.props.fetchQueryCursor}
          />
        );
      case CALENDAR_VIEW:
        return (
          <JournalCalendar
            entries={this.dictionaryToArray(this.props.list)}
            list={this.props.list}
            listIds={this.props.listIds}
            onSelect={this.onEditJournalEntry}
            selectedId={this.state.selectedId}
            pagination={this.props.pagination}
            onFetchJournalEntriesByCursor={this.onFetchJournalEntriesByCursor}
            isFetching={this.props.calendarFetching}
            fetchByDate={(startDate, endDate) => {
              if (
                this.props.pagination.totalRecords === this.props.listIds.length
              )
                return;
              this.props.fetchByDate(this.props.personId, startDate, endDate);
            }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { detailModal, view } = this.state;
    const { classes } = this.props;

    return (
      <BasePage
        className={classes.root}
        state={this.props.uiState}
        EmptyStateComponent={
          <JournalNoRecords openModal={this.openJournalModal()} />
        }
        DrawerComponent={
          detailModal.open ? (
            <JournalDetailView
              open={detailModal.open}
              data={this.props.list[this.state.selectedId]}
              selectedId={this.state.selectedId}
              onClose={this.toggleDetailModal}
              onDelete={this.onDeleteJournalEntry}
              onEdit={this.onEditJournalEntry}
              personId={this.props.personId}
            />
          ) : (
            ''
          )
        }
      >
        <React.Fragment>
          <Grid item xs={12}>
            <PageHeader
              headerText="Journal Entries"
              subHeaderText="View Records"
              backButtonText="Go Back"
              rightSlot={
                this.props.uiState === SUCCESS ? (
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      aria-label="Add"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={this.openJournalModal()}
                      startIcon={<Icon>add</Icon>}
                    >
                      Add Entry
                    </Button>
                  </Grid>
                ) : (
                  ''
                )
              }
            />
            <Divider />
            {view === CALENDAR_VIEW && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <JournalViewToggles
                  activeView={view}
                  setView={this.setJournalView}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {this.renderJournalView(view)}
          </Grid>
        </React.Fragment>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.active.user,
  personId: state.active.user.personId,
  list: state.journalEntries.list,
  listIds: state.journalEntries.listIds,
  selectedId: state.journalEntries.selectedId,
  timeZoneIANA: state.app.timeZoneIANA,
  uiState: state.journalEntries.uiState,
  pagination: state.journalEntries.pagination,
  calendarFetching: state.journalEntries.calendarFetching,
  search: state.journalEntries.search,
});

const mapDispatchToProps = dispatch => ({
  fetchJournalEntries: meta => dispatch(fetchJournalEntries(meta)),
  fetchJournalEntriesByCursor: link =>
    dispatch(fetchJournalEntriesByCursor(link)),
  deleteJournalEntry: (pid, jid, tid) =>
    dispatch(deleteJournalEntry(pid, jid, tid)),
  setSelectedJournalEntry: id => dispatch(setSelectedJournalEntry(id)),
  openJournalModal: (bool, payload) =>
    dispatch(openJournalModal(bool, payload)),
  populateJournalModal: payload => dispatch(populateJournalModal(payload)),
  createJournalDuplicate: payload => dispatch(createJournalDuplicate(payload)),
  fetchByDate: (pid, sDate, endDate) =>
    dispatch(fetchByDate(pid, sDate, endDate)),
  fetchByQuery: (pid, sDate, eDate, searhTerm) =>
    dispatch(fetchByQuery(pid, sDate, eDate, searhTerm)),
  fetchQueryCursor: link => dispatch(fetchQueryCursor(link)),
});

export default {
  component: withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(JournalPage)
  ),
};
