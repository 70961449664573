export const PROGRESS_BAR_SET = '@@redux/PROGRESS_BAR_SET';
export const PROGRESS_BAR_UPDATE = '@@redux/PROGRESS_BAR_UPDATE';
export const PROGRESS_BAR_COMPLETE = '@@redux/PROGRESS_BAR_COMPLETE';

export const setProgressBar = (value, total) => ({
  type: PROGRESS_BAR_SET,
  value,
  total,
  display: true,
});

export const updateProgressBar = (value) => ({
  type: PROGRESS_BAR_UPDATE,
  value,
}); 

export const hideProgressBar = (value) => ({
  type: PROGRESS_BAR_COMPLETE,
  value,
  display: false,
});



