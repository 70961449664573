import { isValidZipCode } from '../../../../library/validations/ZipCode';
import { hasNoSelection } from '../../../../library/validations/Default';
import { isValidSsn } from '../../../../library/validations/Ssn';
import { inObjectArray } from '../../../../library/validations/Compare';
import { differenceInCalendarYears } from 'date-fns';

export default (values, props) => {
  const errors = {};

  const {
    firstName,
    middleName,
    lastName,
    suffix,
    dob,
    ssn,
    postalCode,
    state,
    phoneNumber,
    phoneType,
    primaryLanguage,
    secondaryLanguage,
    ethnicity,
    religion,
    gender,
    bloodType,
    maritalStatus,
    veteranStatus,
    email,
    emailConfirm,
  } = values;

  const { emailDirty } = props;

  const required = ['firstName', 'lastName', 'email', 'emailConfirm'];

  required.forEach(field => {
    if (!values[field] || hasNoSelection(values[field])) {
      errors[field] = 'Required';
    }
  });

  if (firstName && firstName.length >= 101) {
    errors.firstName = `We only store up to 100 characters. The first name provided is ${firstName.length} characters long. Please provide a shorter name`;
  }

  if (middleName && middleName.length >= 101) {
    errors.middleName = `We only store up to 100 characters. The middle name provided is ${middleName.length} characters long. Please provide a shorter name`;
  }

  if (lastName && lastName.length >= 101) {
    errors.lastName = `We only store up to 100 characters. The last name provided is ${lastName.length} characters long. Please provide a shorter name`;
  }

  if (suffix && suffix.length >= 10) {
    errors.suffix = 'The suffix is too long';
  }

  if (props.isDependent) {
    const dobYears = differenceInCalendarYears(new Date(), dob);
    if (dobYears > 17) {
      errors.dob =
        '17 is the maximum age allowed for a dependent. Please contact us for exceptions';
    }
  } else {
    const totalYears = differenceInCalendarYears(new Date(), dob);
    if (totalYears <= 18) {
      errors.dob = 'Must be at least 18 years of age to become a member';
    } else if (totalYears >= 130) {
      errors.dob = 'Must be under 130 years of age';
    }

    if (phoneNumber && phoneNumber.length < 10) {
      errors.phoneNumber =
        'Invalid phone number. Correct format is XXX-XXX-XXXX';
    }

    if (
      phoneType &&
      !hasNoSelection(phoneType) &&
      !inObjectArray(props.contactTypes, 'shortName', phoneType)
    ) {
      errors.phoneType =
        'Invalid selection. Please make selection from dropdown';
    }
  }

  if (ssn && !isValidSsn(ssn)) {
    errors.ssn =
      'Invalid Social Security Number (SSN). Standard US format is XXX-XX-XXXX';
  }

  if (postalCode && !isValidZipCode(postalCode)) {
    errors.postalCode =
      'Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX';
  }

  if (
    state &&
    !hasNoSelection(state) &&
    !inObjectArray(props.stateTypes, 'shortName', state)
  ) {
    errors.state = 'Invalid selection. Please make selection from dropdown';
  }

  if (
    primaryLanguage &&
    !hasNoSelection(primaryLanguage) &&
    !inObjectArray(props.languageTypes, 'shortName', primaryLanguage)
  ) {
    errors.primaryLanguage =
      'Invalid selection. Please make selection from dropdown';
  }

  if (
    secondaryLanguage &&
    !hasNoSelection(secondaryLanguage) &&
    !inObjectArray(props.languageTypes, 'shortName', secondaryLanguage)
  ) {
    errors.secondaryLanguage =
      'Invalid selection. Please make selection from dropdown';
  }

  if (secondaryLanguage && !primaryLanguage) {
    errors.secondaryLanguage =
      'Primary language must be defined before secondary';
  } else if (
    primaryLanguage &&
    secondaryLanguage &&
    primaryLanguage === secondaryLanguage
  ) {
    errors.secondaryLanguage =
      'Primary language and secondary language cannot be the same';
  }

  if (
    ethnicity &&
    !hasNoSelection(ethnicity) &&
    !inObjectArray(props.ethnicityTypes, 'shortName', ethnicity)
  ) {
    errors.ethnicity = 'Invalid selection. Please make selection from dropdown';
  }

  if (
    religion &&
    !hasNoSelection(religion) &&
    !inObjectArray(props.religionTypes, 'shortName', religion)
  ) {
    errors.religion = 'Invalid selection. Please make selection from dropdown';
  }

  // TODO something strange is going on with gender. It's either a blank string or
  // a string that has the word "null" in it. Annoying, the value should be consistent
  // instead of flipflopping. Needs to be fixed.
  if (
    gender &&
    gender !== '' &&
    gender !== 'null' &&
    !inObjectArray(props.genderTypes, 'shortName', gender)
  ) {
    errors.gender = 'Invalid selection. Please make selection from dropdown';
  }

  // TODO: something strange is going on with blood type. It's either a blank string or
  // a string that has the word "null" in it. Annoying, the value should be consistent
  // instead of flipflopping. Needs to be fixed.
  if (
    bloodType &&
    !hasNoSelection(bloodType) &&
    !inObjectArray(props.bloodTypes, 'shortName', bloodType)
  ) {
    errors.bloodType = 'Invalid selection. Please make selection from dropdown';
  }

  if (
    maritalStatus &&
    !hasNoSelection(maritalStatus) &&
    !inObjectArray(props.maritalTypes, 'shortName', maritalStatus)
  ) {
    errors.maritalStatus =
      'Invalid selection. Please make selection from dropdown';
  }

  if (
    veteranStatus &&
    !hasNoSelection(veteranStatus) &&
    !inObjectArray(props.veteranTypes, 'shortName', veteranStatus)
  ) {
    errors.veteranStatus =
      'Invalid selection. Please make selection from dropdown';
  }

  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
    errors.email = 'Invalid Email';

  if (email && emailDirty && emailConfirm !== email)
    errors.emailConfirm = 'Email must match';

  return errors;
};
