import React from 'react'
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Grid, withStyles, Button, Icon } from '@material-ui/core'

const styles = {
  header: { fontSize: 24, marginBottom: 35 },
  controller: { marginTop: 35 },
  tab: { opacity: 1 },
  activeTab: { opacity: 0.4 },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#CECECE',
    margin: '0 8px',
    cursor: 'pointer'
  },
  activeDot: {
    backgroundColor: '#444447',
  },
  disabledDot: {
    cursor: 'auto'
  }
}

const FormFooter = (props) => {
  const {classes} = props;

  if (props.links.length < 2) return "";

  const leftLink = props.links[0]
  const rightLink = props.links[1]

  return (
    <Grid
        className={classes.controller}
        container
        justify="space-between"
        wrap="nowrap"
      >
        <Grid
          container
          alignItems="center"
          className={classes.tab}
        >
          <Button component={Link} to={leftLink.route} disabled={leftLink.current}>
            <Icon>arrow_back_ios</Icon>
            <div>{leftLink.label}</div>
          </Button>
        </Grid>
        <Grid container justify="center" alignItems="center">
          { props.links.map((link, index) => (
            link.disableSecondaryNav ? ( 
              <Link key={index} className={classNames(classes.dot, {[classes.activeDot]: link.current})} to={link.route}></Link>
            ) : (
              <div key={index} className={classNames(classes.dot, {[classes.activeDot]: link.current, [classes.disabledDot]: true})}></div>
            )
          )) }
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className={classes.tab}
        >
          <Button type="submit" disabled={rightLink.current}>
            <div>{rightLink.label}</div>
            <Icon>arrow_forward_ios</Icon>
          </Button>
        </Grid>
      </Grid>
  )
}

FormFooter.defaultProps = {
  links: []
}

export default withStyles(styles)(FormFooter);