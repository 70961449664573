import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { getMonths } from "./utils";

import classes from "./Calendar.css";

class MonthPanel extends React.Component {
  selectedMonth = (label, index) => {
    this.props.onClick(label, index);
  };

  render() {
    const monthLabels = getMonths("short");
    return (
      <div className={classes.monthPanel}>
        {monthLabels.map((label, index) => (
          <div
            key={index}
            className={classNames({
              [classes.current]: this.props.currentMonth === index
            })}
            onClick={() => this.selectedMonth(label, index)}
          >
            {label}
          </div>
        ))}
      </div>
    );
  }
}

MonthPanel.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default MonthPanel;
