import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import cx from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    bottom: '0',
    border: '0',
    outline: '0',
    padding: '0',
    margin: '10px 0',
    zIndex: '4',
    background: 'none',
  },
  buttonPrev: {
    left: '-10px',
  },
  buttonNext: {
    right: '0px',
  },
  span: {
    transition: 'all 150ms ease 150ms',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'block',
    margin: '0 auto',
    height: 'fit-content',
    background: '#fafafa',
    opacity: '.8',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      background: '#ffffff',
      opacity: '1',
      color: 'black',
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    },
  },
  spanNext: {
    transform: 'rotateZ(-90deg)',
  },
  spanPrev: {
    transform: 'rotateZ(90deg)',
  },
  icon: {
    fontSize: '1.8rem',
  },
});

const SlideButton = ({ onClick, type }) => {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.button, {
        [classes.buttonPrev]: type === 'prev',
        [classes.buttonNext]: type === 'next',
      })}
    >
      <IconButton
        className={cx(classes.span, {
          [classes.spanPrev]: type === 'prev',
          [classes.spanNext]: type === 'next',
        })}
        onClick={onClick}
      >
        <ExpandMoreIcon className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default SlideButton;
