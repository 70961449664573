import { apiFetchAuthorized } from '../../../../library/helpers/fetch';

export const queryForProviders = async (query, personId) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      personId,
      name: query.fullName,
      state: query.state,
      city: query.city,
      postalCode: query.postalCode,
    }),
  };

  try {
    const result = await apiFetchAuthorized(
      `/api/member/provider/search/${query.fullName}`,
      options
    );
    return new Promise(resolve =>
      result.json().then(data => resolve(data.data))
    );
  } catch (error) {
    return error;
  }
};
