import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import useDataPoints from '../useDataPoints';
import LineChart from '../../LineChart';
import styles from '../styles.css';
import { DATERANGE } from '../../ChartWrapper';
import { scaleTime } from 'd3-scale';
import { isWithinInterval, format } from 'date-fns';
import Tooltip from '../Tooltip';
import { getAverage } from '../';
import useWeightChart from './useWeightChart';

const formatByDay = e => `${e.getMonth() + 1}/${e.getDate()}`;
const formatByMonthName = e => format(e, 'MMMM');
const formatByMonthShort = e => format(e, 'MMM');

const ANNOTATION_CONSTANTS = {
  weight: {
    backgroundColor: '#BCDEF3',
    color: '#0068A8',
    title: 'Weight',
  },
  averageWeight: {
    backgroundColor: '#BCDEF3',
    color: '#0068A8',
    title: 'Average Weight',
  },
  bmi: {
    backgroundColor: '#BCDEF3',
    color: '#0068A8',
    title: 'BMI',
  },
  averageBmi: {
    backgroundColor: '#BCDEF3',
    color: '#0068A8',
    title: 'Average BMI',
  },
};

const WeightChart = props => {
  const {
    coordinates,
    dateExtent,
    tickValues,
    dateRange,
    setAnnotations,
    hasData,
    mode,
  } = props;
  const shouldDisplayAverage = [
    DATERANGE.ONE_YEAR,
    DATERANGE.SIX_MONTHS,
  ].includes(dateRange);
  const [netData] = useWeightChart(coordinates, dateRange);
  const lineData = shouldDisplayAverage ? netData : coordinates;
  const { upperLimit, lowerLimit } = useDataPoints(
    lineData.filter(item =>
      isWithinInterval(item.date, { start: dateExtent[0], end: dateExtent[1] })
    ),
    shouldDisplayAverage ? ['averageWeight'] : ['weight'],
    dateExtent
  );

  function getAnnotations() {
    if (coordinates.length === 0) return;
    const annot = getAverage(
      lineData.filter(item =>
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ),
      shouldDisplayAverage ? ['averageWeight', 'averageBmi'] : ['weight', 'bmi']
    );
    setAnnotations(
      annot.map(item => {
        return {
          value: item.average,
          ...ANNOTATION_CONSTANTS[item.title],
        };
      })
    );
  }

  useEffect(() => {
    getAnnotations();
  }, [coordinates, dateExtent]);

  const frameProps = {
    lines: [{ coordinates: lineData }],
    xAccessor: 'date',
    yAccessor: shouldDisplayAverage ? 'averageWeight' : 'weight',
    xExtent: dateExtent,
    yExtent: [lowerLimit, upperLimit],
    xScaleType: scaleTime(),

    hoverAnnotation: [
      { type: 'x', disable: ['connector', 'note'] },
      { type: 'frame-hover' },
      {
        type: 'vertical-points',
        threshold: 0.1,
        r: function() {
          return 5;
        },
      },
    ],
    axes: [
      { orient: 'left', className: styles.axes, baseline: false, label: 'lbs' },
      {
        orient: 'bottom',
        tickFormat: function(e) {
          if (
            dateRange === DATERANGE.ONE_WEEK ||
            dateRange === DATERANGE.ONE_MONTH
          ) {
            return formatByDay(e);
          }
          if (
            dateRange === DATERANGE.THREE_MONTHS ||
            dateRange === DATERANGE.SIX_MONTHS
          ) {
            return formatByMonthName(e);
          }
          return formatByMonthShort(e);
        },
        className: styles.axes,
        baseline: false,
        tickValues,
      },
    ],
    hoverAnnotation: true,
    tooltipContent: d => {
      return (
        <Tooltip
          d={d}
          color="#ffffff"
          keys={[
            {
              tag: shouldDisplayAverage ? 'Average Weight' : 'Weight',
              unit: 'lbs',
              value: shouldDisplayAverage ? d.averageWeight : d.weight,
            },
            {
              tag: shouldDisplayAverage ? 'Average BMI' : 'BMI',
              unit: null,
              value: shouldDisplayAverage ? d.averageBmi : d.bmi,
            },
          ]}
        />
      );
    },
    className: styles.frame,
  };
  return <LineChart mode={mode} frameProps={frameProps} hasData={hasData} />;
};

export default WeightChart;
