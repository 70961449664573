import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  MEDICATION_REQUEST,
  MEDICATION_SUCCESS,
  MEDICATION_FAILURE,
  MVS_CREATE_REQUEST,
  MVS_CREATE_SUCCESS,
  MVS_CREATE_FAILURE,
  MEDICATION_LIST_REQUEST,
  MEDICATION_LIST_SUCCESS,
  MEDICATION_LIST_FAILURE,
  MVS_DELETE_REQUEST,
  MVS_DELETE_SUCCESS,
  MVS_DELETE_FAILURE,
  SET_MEDICATION_TO_UPDATE,
  CLEAR_MEDICATION_TO_UPDATE,
  MVS_UPDATE_REQUEST,
  MVS_UPDATE_SUCCESS,
  MVS_UPDATE_FAILURE,
  CLEAR_MEDICATION_ERROR,
  MVS_SET_ID_TO_UPDATE,
  MVS_CLEAR_UPDATE_ID,
  MVS_REMOVE_MEDIA,
  MVS_DELETE_MEDIA,
  MVS_DELETE_MEDIA_CANCEL,
  MVS_UPDATE_MEDIA,
  MVS_SUBMITFORM,
} from './actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
} from '../mediaObject/actions';

import { SET_ACTIVE_USER } from '../active/actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';
import { MEDIA_UPDATE_REQUEST } from '../mediaObjectDrawer/actions';
import { MVS } from '../mediaObject';

const initialState = {
  isFetching: false,
  isSubmitting: false,
  didInvalidate: false,
  listIds: [],
  list: {},

  selectedId: null,
  errorMessage: undefined,
  medicationSearchResult: [],
  fetchingMedicationList: true,

  uiState: LOADING,
  activeUser: null,

  mediaObjects: {},
  uploadingMediaObjects: false,
  bundlesToDelete: [],
};

const reducers = [];

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  // Only process immunization associated actions.
  if (action.meta.resource !== 'MVS') return state;
  const resourceId = state.selectedId || action.meta.objectId;

  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    //   case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:{
    //     const _mediaObjects = resource ? [].concat(action.payload, resource.mediaObjects) : [...action.payload];
    //     return {
    //       ...state,
    //       list: {
    //         ...state.list,
    //         [action.meta.objectId]: {
    //           ...state.list[action.meta.objectId],
    //           mediaObjects: _mediaObjects
    //         },
    //       },
    //     };
    // }
    // Update the selected media object in the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE: {
      return {
        ...state,
        list: {
          ...state.list,
          [resourceId]: {
            ...state.list[resourceId],
            mediaObjects: state.list[resourceId].mediaObjects.map(
              updateMediaObject
            ),
          },
        },
      };
    }

    // Remove selected media object from the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE: {
      console.log('test', resourceId, action.payload);
      return {
        ...state,
        list: {
          ...state.list,
          [resourceId]: {
            ...state.list[resourceId],
            mediaObjects: state.list[resourceId].mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            bundle => bundle.mediaObjectId !== action.payload
          ),
        ],
        isSubmitting: true,
      };
    }
    default:
      return state;
  }
};

const medication = (state = initialState, action) => {
  switch (action.type) {
    case MVS_SUBMITFORM:
      return {
        ...state,
        isSubmitting: true,
      };
    case MEDIA_UPDATE_REQUEST: {
      if (action.appArea !== MVS) return state;
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case MEDICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        uiState: LOADING,
      };
    case MEDICATION_SUCCESS:
      const { payload = [] } = action;
      const listIds = getKeys('medicationId', payload);
      return {
        ...state,
        list: arrayToHashMap('medicationId', payload),
        listIds,
        uiState: listIds.length ? SUCCESS : EMPTY,
        isFetching: false,
      };
    case MEDICATION_FAILURE:
      return {
        ...state,
        uiState: ERROR,
      };
    case MVS_CREATE_REQUEST:
      return {
        ...state,
        errorMessage: undefined,
        list: {
          ...state.list,
          [action.meta.tid]: action.payload,
        },
        listIds: [].concat([action.meta.tid], state.listIds),
        isSubmitting: true,
      };

    case MVS_CREATE_SUCCESS: {
      const entry = state.list[action.meta.tid];
      const { mediaObjects, ...remainingEntry } = entry;

      const updateRecord = {
        ...entry,
        ...action.payload,
        mediaObjects: action.bundlesToAttach,
      };

      return {
        ...state,
        errorMessage: undefined,
        didInvalidate: false,
        isSubmitting: false,
        uploadingMediaObjects: false,

        list: Object.keys(state.list).reduce((object, key) => {
          if (key === action.meta.tid) {
            return { ...object, [action.payload.medicationId]: updateRecord };
          }
          return { ...object, [key]: state.list[key] };
        }, {}),
        listIds: state.listIds.map(listId => {
          if (listId === action.meta.tid) {
            return action.payload.medicationId;
          }
          return listId;
        }),
        uiState: state.uiState === EMPTY ? SUCCESS : state.uiState,
      };
    }
    case MVS_CREATE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        didInvalidate: false,
        errorMessage: action.payload.message,
      };
    case MVS_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        didInvalidate: false,
      };
    case MVS_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        didInvalidate: true,
        list: {
          ...state.list,
          [state.selectedId]: {
            ...action.payload,
            mediaObjects: [
              ...state.list[state.selectedId].mediaObjects,
              ...action.bundlesToAttach,
            ],
          },
        },
      };
    case MVS_UPDATE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        didInvalidate: false,
        errorMessage: action.payload.message,
      };

    case MEDICATION_LIST_REQUEST:
      return {
        ...state,
      };

    case MEDICATION_LIST_SUCCESS:
      let book = {};
      let options = [];
      action.payload.map(o => {
        // if(!book[o.medListId.toLowerCase()])
        options.push({
          label: o.medicationShortName,
          value: o.medicationShortName,
        });
        // book[o.medListId.toLowerCase()] = true;
      });

      return {
        ...state,
        medicationSearchResult: options,
      };

    case MEDICATION_LIST_FAILURE:
      return {
        ...state,
      };

    case MVS_DELETE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.deleting_id]: {
            ...state.list[action.meta.deleting_id],
            isDeleting: true,
          },
        },
      };
    case MVS_DELETE_SUCCESS:
      const medId = action.payload.medicationId;
      state.listIds = state.listIds.filter(idx => idx !== medId);
      delete state.list[medId];
      return {
        ...state,
        uiState: state.listIds.length > 0 ? SUCCESS : EMPTY,
      };
    case MVS_DELETE_FAILURE:
      delete state.list[action.meta.deleting_id].deleting;
      return {
        ...state,
        errorMessage: action.payload.message,
      };
    case SET_MEDICATION_TO_UPDATE:
      return {
        ...state,
        medicationId: action.payload,
        medicationSearchResult: [
          {
            label: state.list[action.payload].name,
            value: state.list[action.payload].name,
          },
        ],
      };
    case CLEAR_MEDICATION_TO_UPDATE:
      delete state['medicationId'];
      state.medicationSearchResult = [];
      return {
        ...state,
      };
    case CLEAR_MEDICATION_ERROR:
      return {
        ...state,
        errorMessage: undefined,
      };

    case MVS_SET_ID_TO_UPDATE:
      return {
        ...state,
        selectedId: action.payload,
        medicationSearchResult: [
          {
            label: state.list[action.payload].name,
            value: state.list[action.payload].name,
          },
        ],
      };

    case MVS_CLEAR_UPDATE_ID:
      return {
        ...state,
        selectedId: null,
      };

    case MVS_UPDATE_MEDIA: {
      const mediaObjects = state.mediaObjects;
      if (Boolean(action.payload.formName)) {
        mediaObjects[action.payload.formName] = action.payload.pristine;
      }
      return {
        ...state,
        mediaObjects,
      };
    }

    case MVS_DELETE_MEDIA_CANCEL: {
      return {
        ...state,
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            item => item.mediaObjectId !== action.payload
          ),
        ],
      };
    }

    case MVS_DELETE_MEDIA: {
      const _bundlesToDelete = Boolean(
        state.bundlesToDelete.find(
          item => item.mediaObjectId === action.payload.mediaObjectId
        )
      )
        ? [
            ...state.bundlesToDelete.map(item => {
              if (item.mediaObjectId === action.payload.mediaObjectId) {
                return action.payload;
              }
              return item;
            }),
          ]
        : [
            ...state.bundlesToDelete,
            {
              mediaObjectId: action.payload.mediaObjectId,
              objectId: action.payload.referenceId ? state.selectedId : null,
              referenceId: action.payload.referenceId || null,
            },
          ];

      return {
        ...state,
        bundlesToDelete: _bundlesToDelete,
      };
    }

    case MVS_REMOVE_MEDIA: {
      const mediaObjects = {
        ...state.mediaObjects,
      };
      delete mediaObjects[`bundle${action.payload}`];
      return {
        ...state,
        mediaObjects,
      };
    }

    case RESOURCE_MEDIA_MODIFIED:
      return reducers[RESOURCE_MEDIA_MODIFIED](state, action);

    default:
      return state;
  }
};

export default medication;

// export default (state = initialState, action) =>
//   action.type in reducers ? reducers[action.type](state, action) : state;
