import { simpleEmailValidation } from '../../../../library/validations/EmailAddress';

export default values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (values.email && !simpleEmailValidation(values.email)) {
    errors.email = 'Email address not valid';
  }

  if (values.phoneNumber && values.phoneNumber.length < 12) {
    errors.phoneNumber = 'Invalid phone number. Correct format is XXX-XXX-XXXX';
  }

  if (values.faxNumber && values.faxNumber.length < 12) {
    errors.faxNumber = 'Invalid fax number. Correct format is XXX-XXX-XXXX';
  }

  return errors;
};
