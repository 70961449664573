import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
  withStyles,
} from '@material-ui/core';
import SearchResults from './SearchResults';
import { queryForProviders } from './actions';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { states } from '../../../../library/static/data.js';

const styles = {
  header: {
    marginBottom: 4,
  },
  subHeader: {
    maxWidth: 650,
    lineHeight: '24px',
    color: '#444447',
    marginBottom: 24,
  },
  textField: {
    width: '100%',
    marginTop: 0,
  },
  textContainer: {
    width: '60%',
  },
};

const EMPTY_STATE = JSON.stringify({
  fullName: '',
  city: '',
  state: '',
  postalCode: '',
});

const ProviderSearch = ({
  classes,
  history,
  baseRoutePath,
  setSelectedProvider,
  selectedProvider,
  personId,
}) => {
  const [search, setSearch] = useState({
    fullName: null,
    city: null,
    state: null,
    postalCode: null,
  });
  const [saveDisabled, setSaveDisabled] = useState(true);

  const [results, setResults] = useState({
    initiated: false,
    loading: true,
    data: [],
  });

  useEffect(() => {
    const _isSaveDisabled = EMPTY_STATE === JSON.stringify(search);
    if (_isSaveDisabled !== saveDisabled) setSaveDisabled(_isSaveDisabled);
    const onKeypress = e => {
      if (e.code === 'Enter' && !_isSaveDisabled) {
        onSearchClick();
      }
    };

    document.addEventListener('keydown', onKeypress);
    return () => document.removeEventListener('keydown', onKeypress);
  }, [search]);

  const onSearchChange = e => {
    const { value, name } = e.target;
    const val = value.length ? value : null;
    switch (name) {
      case 'fullName': {
        setSearch({
          ...search,
          fullName: val,
        });
        return;
      }
      case 'state': {
        setSearch({
          ...search,
          state: val,
        });
        return;
      }
      case 'city': {
        setSearch({
          ...search,
          city: val,
        });
        return;
      }
      case 'postalCode': {
        setSearch({
          ...search,
          postalCode: val,
        });
        return;
      }
      default:
        return;
    }
  };

  const onManualClick = () => {
    history.push(`${baseRoutePath}/step-one`);
  };

  const onSearchClick = async () => {
    setSelectedProvider(null);
    setResults({ initiated: true, loading: true, data: [] });
    const data = await queryForProviders(search, personId);
    setResults({
      initiated: true,
      loading: false,
      data: data,
    });
  };

  const onResultClick = result => {
    setSelectedProvider({
      ...result,
      isManualEntry: false,
    });
  };

  const onEditProviderClick = () => {
    history.push(`${baseRoutePath}/edit`);
  };

  return (
    <Grid>
      <Grid>
        <Typography variant="h6" className={classes.header}>
          Search our provider database
        </Typography>
        <p className={classes.subHeader}>
          If your Provider is in our database, we can enter the details for you.
          You will have the option to edit the Provider's information and grant
          access later.
        </p>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              id="providerSearch"
              label="Provider Search"
              placeholder="Search by name"
              className={classes.textField}
              margin="normal"
              onChange={onSearchChange}
              name="fullName"
              value={search.name}
            />
          </Grid>
          <Grid xs={4} item md={2}>
            <TextField
              id="providerSearchCity"
              label="City"
              className={classes.textField}
              margin="normal"
              onChange={onSearchChange}
              name="city"
              value={search.city}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <FormControl
              className={classes.textField}
              style={{ marginBottom: '8px' }}
            >
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                value={search.state}
                onChange={onSearchChange}
                name="state"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {states.map(item => {
                  return (
                    <MenuItem value={item.abbreviation}>{item.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              id="providerSearchZipcode"
              label="Zipcode"
              className={classes.textField}
              margin="normal"
              onChange={onSearchChange}
              name="postalCode"
              value={search.postalCode}
            />
          </Grid>
          <Grid
            item
            container
            md={12}
            spacing={2}
            justify="flex-end"
            style={{ marginTop: '6px' }}
          >
            <Button
              variant="contained"
              disabled={saveDisabled}
              color="primary"
              style={{ marginRight: 16 }}
              onClick={onSearchClick}
            >
              {results.initiated ? 'Search Again' : 'Search Providers'}
            </Button>
            {selectedProvider ? (
              <Button
                onClick={onEditProviderClick}
                variant="contained"
                color="primary"
              >
                Edit Provider
              </Button>
            ) : (
              <Button onClick={onManualClick}>Add Manually</Button>
            )}
          </Grid>
        </Grid>

        <SearchResults
          results={results}
          onResultClick={onResultClick}
          selected={selectedProvider}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProviderSearch);
