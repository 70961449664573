import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { uploadFiles } from '../mediaObject';
import { documentUpdate, DOCUMENTS_UPDATE_SUCCESS } from '../documents/actions';
import { apiFetchAuthorized, apiDelete } from '../../../library/helpers/fetch';

export const LABS_CATEGORY = 'Lab Tests / Pathology / Screening';

export const LABS_REQUEST = 'labs/records/request';
export const LABS_SUCCESS = 'labs/records/success';
export const LABS_FAILURE = 'labs/records/failure';

export const LABS_CREATE_REQUEST = 'labs/records/create_request';
export const LABS_CREATE_SUCCESS = 'labs/records/create_success';
export const LABS_CREATE_FAILURE = 'labs/records/create_failure';

export const LABS_UPDATE_REQUEST = 'labs/records/update_request';
export const LABS_UPDATE_SUCCESS = 'labs/records/update_success';
export const LABS_UPDATE_FAILURE = 'labs/records/update_failure';

export const LABS_DELETE_REQUEST = 'labs/records/delete_request';
export const LABS_DELETE_SUCCESS = 'labs/records/delete_success';
export const LABS_DELETE_FAILURE = 'labs/records/delete_failure';

export const LABS_ATTACH_MEDIA = 'labs/records/attach_media';
export const LABS_UPDATE_MEDIA = 'labs/records/update_media';

export const LABS_REMOVE_MEDIA = 'labs/records/remove_media';
export const LABS_SELECT_ID = 'labs/records/select_id';
export const LABS_CLEAR_ID = 'labs/records/clear_id';

export const UPDATE_LABS_MEDIA = 'ADD_MEDIA/labs';

export const MEDIA_REMOVE_REQUEST = 'labs/delete_media_request';
export const MEDIA_REMOVE_SUCCESS = 'labs/delete_media_success';
export const MEDIA_REMOVE_FAILURE = 'labs/delete_media_failure';

export const LABS_MEDIA_TO_DELETE = 'labs/labs_delete_media';
export const LABS_MEDIA_TO_DELETE_CANCEL = 'labs/cancel_labs_delete_media';

//todo: check endpoints

export const labsSelectId = mid => ({
  type: LABS_SELECT_ID,
  payload: mid,
});

export const labsClearSelectedId = () => ({
  type: LABS_CLEAR_ID,
});

export const fetchLabTests = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [LABS_REQUEST, LABS_SUCCESS, LABS_FAILURE],
    endpoint: `/api/lab`,
  },
});

export const labsCreate = (pid, data, bundles, options) => {
  return async dispatch => {
    const payload = {
      ...data,
      personId: pid,
    };

    dispatch({
      type: LABS_CREATE_REQUEST,
      payload: {
        pid,
        data,
        bundles,
        options,
      },
    });

    try {
      const response = await apiFetchAuthorized(`/api/lab`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      if (bundles.length > 0) {
        await dispatch(
          uploadFiles(pid, json.data.labResultId, bundles, 'labresult')
        );
      }

      return await dispatch({
        type: LABS_CREATE_SUCCESS,
        payload: json.data,
        response: json,
      });
    } catch (error) {
      return dispatch({ type: LABS_CREATE_FAILURE, payload: error });
    }
  };
};

export const setToDelete = (mid, ID) => ({
  type: LABS_MEDIA_TO_DELETE,
  payload: {
    mediaObjectId: mid,
    referenceId: ID,
  },
});

export const cancelDelete = mid => ({
  type: LABS_MEDIA_TO_DELETE_CANCEL,
  payload: mid,
});

export const labsUpdate = (pid, objectId, data, bundles, options) => {
  return async dispatch => {
    let payload = {
      ...data,
      personId: pid,
      labResultId: objectId,
    };

    dispatch({
      type: LABS_UPDATE_REQUEST,
      payload: {
        pid,
        objectId,
        data,
        bundles,
        options,
      },
    });

    try {
      if (options.hasFormChanges) {
        const response = await apiFetchAuthorized(`/api/lab`, {
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const resJson = await response.json();
        payload = resJson.data;
      }

      // updates attachments
      const bundlesToUpdate = {};
      if (options && options.bundlesToUpdate.length > 0) {
        for (const bundle of options.bundlesToUpdate) {
          if (
            options.bundlesToDelete.find(
              item => item.mediaObjectId === bundle.mediaObjectId
            )
          )
            return;
          const content = await dispatch(
            documentUpdate(pid, bundle.mediaObjectId, bundle, objectId)
          );
          bundlesToUpdate[bundle.mediaObjectId] = content.payload;
        }
      }

      // upload attachments
      if (bundles.length > 0) {
        await dispatch(uploadFiles(pid, objectId, bundles, 'labresult'));
      }

      // delete attachments
      if (options.bundlesToDelete.length) {
        for (const bundle of options.bundlesToDelete) {
          await dispatch(
            deleteExistingMedia(
              bundle.mediaObjectId,
              bundle.objectId,
              bundle.referenceId
            )
          );
        }
      }

      return await dispatch({
        type: LABS_UPDATE_SUCCESS,
        payload,
        bundles: bundlesToUpdate,
      });
    } catch (error) {
      return dispatch({ type: LABS_UPDATE_FAILURE, payload: error });
    }
  };
};

export const labsDelete = (pid, mid) => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [LABS_DELETE_REQUEST, LABS_DELETE_SUCCESS, LABS_DELETE_FAILURE],
      endpoint: `/api/lab/${mid}`,
      payload: mid,
      meta: {
        config: {
          method: 'DELETE',
          mode: 'cors',
        },
      },
    },
  };
};

export const attachMediaToEntry = (mid, bundle) => ({
  type: LABS_ATTACH_MEDIA,
  payload: {
    labResultId: mid,
    bundle,
  },
});

export const updateMedia = (pid, mid, bundle, objectId) => {
  return async dispatch => {
    const res = await dispatch(documentUpdate(pid, mid, bundle, objectId));
    if (res.type === DOCUMENTS_UPDATE_SUCCESS) {
      dispatch({ type: LABS_UPDATE_MEDIA, payload: res.payload });
    }
  };
};

export const deleteMedia = index => ({
  type: LABS_REMOVE_MEDIA,
  payload: index,
});

export const deleteExistingMedia = (
  mediaObjectId,
  objectId,
  referenceId = null
) => async dispatch => {
  dispatch({
    type: MEDIA_REMOVE_REQUEST,
    payload: {
      mediaObjectId,
      objectId,
      referenceId,
    },
  });

  const api = referenceId
    ? `/api/media/labresult/${mediaObjectId}/reference/${referenceId}`
    : `/api/media/${mediaObjectId}`;

  const response = await apiDelete(api, {
    mode: 'cors',
  });

  const json = await response.json();
  if (response.status === 200) {
    dispatch({ type: MEDIA_REMOVE_SUCCESS, payload: mediaObjectId });
  } else if (response.status >= 400) {
    dispatch({ type: MEDIA_REMOVE_FAILURE, payload: mediaObjectId });
  }
  return json;
};
