import React, { useEffect } from 'react';
import {
  startOfMonth,
  lastDayOfMonth,
  getDay,
  getYear,
  getMonth,
  addDays,
  format,
  startOfYear,
  addMonths,
  getDate,
  addYears,
  isSameDay,
} from 'date-fns';
import cx from 'classnames';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import {
  Grid,
  ButtonBase,
  withStyles,
  makeStyles,
  Paper,
  Tooltip,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
  month: {
    margin: '5px 5px 15px 5px',
    padding: '10px',
  },
  monthName: {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '‭210‬px',
    marginBottom: '5px',
  },
  day: {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    textAlign: 'center',
    lineHeight: '30px',
  },
  dateButton: {
    width: '30px',
    height: '30px',
    background: 'white',
    borderRadius: '50px',
    border: 'none',
    outline: 'none',
  },
  dateButtonHovered: {
    transition: 'background 90ms ease 90ms',
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  dateButtonDisabled: {
    color: 'grey',
  },
  hasEvent1: {
    cursor: 'pointer',
    background: '#b3e5fc',
    transition: 'background 150ms ease 150ms',
    '&:hover': {
      background: '#e1f5fe',
    },
  },
  hasEvent3: {
    cursor: 'pointer',
    background: '#80d8ff',
    transition: 'background 150ms ease 150ms',
    '&:hover': {
      background: '#e1f5fe',
    },
  },
  hasEvent6: {
    cursor: 'pointer',
    background: '#40c4ff',
    transition: 'background 150ms ease 150ms',
    '&:hover': {
      background: '#e1f5fe',
    },
  },
  hasEvent9: {
    cursor: 'pointer',
    background: '#0277bd',
    color: 'white',
    transition: 'all 150ms ease 150ms',
    '&:hover': {
      background: '#e1f5fe',
      color: 'black',
    },
  },
});

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = new Date();
  } else {
    currentDate = new Date(currentDate.getTime());
  }

  const first = startOfMonth(new Date(currentDate.getTime()));
  const last = lastDayOfMonth(new Date(currentDate.getTime()));
  const weeksCount = Math.ceil((getDate(first) + getDate(last)) / 7);
  const calendar = Object.assign([], { currentDate, first, last });
  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = getYear(currentDate);
    calendar.month = getMonth(currentDate);

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = addDays(currentDate, day);
      date.calendar = calendar;
      week.push(date);
    }
  }
  return calendar;
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth, event } = props;
  const today = isSameDay(dateToRender, new Date()) ? 'today' : '';
  const classes = useStyles();

  if (getMonth(dateToRender) < getMonth(dateOfMonth)) {
    return (
      <ButtonBase
        focusRipple={false}
        disabled={true}
        className={cx(
          classes.dateButton,
          classes.dateButtonDisabled,
          {
            [classes.hasEvent]: !!event && event.length < 3,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 3,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 6,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 9,
          }
        )}
      >
        {format(dateToRender, 'dd')}
      </ButtonBase>
    );
  }

  if (getMonth(dateToRender) > getMonth(dateOfMonth)) {
    return (
      <ButtonBase
        focusRipple={false}
        disabled={true}
        className={cx(
          classes.dateButton,
          classes.dateButtonDisabled,
          {
            [classes.hasEvent]: !!event && event.length < 3,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 3,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 6,
          },
          {
            [classes.hasEvent]: !!event && event.length >= 9,
          }
        )}
      >
        {format(dateToRender, 'dd')}
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      focusRipple={true}
      disabled={!event}
      className={cx(
        classes.dateButton,
        {
          [classes.hasEvent1]: !!event && event.length < 3,
        },
        {
          [classes.hasEvent3]: !!event && event.length >= 3,
        },
        {
          [classes.hasEvent6]: !!event && event.length >= 6,
        },
        {
          [classes.hasEvent9]: !!event && event.length >= 9,
        }
      )}
      onClick={() => props.onClick(dateToRender)}
    >
      {format(dateToRender, 'dd')}
    </ButtonBase>
  );
}

function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Calendar = props => {
  const { date, event, onClick } = props;

  const classes = useStyles();
  const [calendar, setCalendar] = React.useState(undefined);
  const prevDate = usePrevious({ date: date });
  const [events, setEvents] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    if (event) {
      const entities = mapByformatValue(event, 'dd');
      setEvents(entities);
    }
  }, [event]);

  React.useEffect(() => {
    setCalendar(createCalendar(date));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (prevDate !== date) {
      setCalendar(createCalendar(date));
    }
  }, [date]);

  const hasEvent = (e, d, m) =>
    event && events[format(e, 'dd')] && getMonth(d) === getMonth(m)
      ? events[format(e, 'dd')]
      : false;

  return isLoading ? (
    <Skeleton
      variant="rect"
      width={230}
      height={200}
      animation="wave"
      className={classes.month}
    />
  ) : !calendar ? null : (
    <Fade timeout={200} in={!isLoading}>
      <Paper elevation={1} square className={classes.month}>
        <div className={classes.monthName}>
          {format(new Date(calendar.currentDate), 'MMMM')}
        </div>
        <div>
          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
            <span key={index + 'day'} className={classes.day}>
              {day}
            </span>
          ))}
        </div>
        {calendar.map((week, index) => (
          <div key={index + 'weeks'}>
            {week.map((dateItem, i) => {
              const dateHasEvent = hasEvent(
                dateItem,
                dateItem,
                calendar.currentDate
              );
              return (
                <Tooltip title={dateHasEvent ? 'View Date' : ''}>
                  <Badge
                    key={i + 'date'}
                    color="primary"
                    invisible={!dateHasEvent}
                    badgeContent={dateHasEvent ? dateHasEvent.length : ''}
                    max={9}
                    overlap="rectangular"
                  >
                    <CalendarDate
                      dateToRender={dateItem}
                      dateOfMonth={calendar.currentDate}
                      event={dateHasEvent}
                      onClick={dateItem => {
                        if (dateHasEvent) onClick(dateItem);
                      }}
                    />
                  </Badge>
                </Tooltip>
              );
            })}
          </div>
        ))}
      </Paper>
    </Fade>
  );
};

const mapByformatValue = (data, formatValue) => {
  let entities = {};
  data.forEach(e => {
    let a = format(new Date(e.createdOn), formatValue);
    if (!entities[a]) entities[a] = [e];
    else {
      entities[a].push(e);
    }
  });
  return entities;
};

class Year extends React.Component {
  componentDidMount() {}

  render() {
    let { date, ...props } = this.props;
    let range = Year.range(date);
    const months = [];
    const firstMonth = startOfYear(date);
    const data = mapByformatValue(props.events, 'M');

    for (let i = 0; i < 12; i++) {
      months.push(
        <Calendar
          key={i + 'calendar'}
          date={addMonths(firstMonth, i)}
          event={data[i + 1] ? data[i + 1] : false}
          onClick={e => this.props.onYearViewClick(e)}
        />
      );
    }
    return (
      <Grid container spacing={1}>
        {months.map((month, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={index + 'month'}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {month}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

Year.range = date => {
  return [startOfYear(date)];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return addYears(date, -1);

    case navigate.NEXT:
      return addYears(date, 1);

    default:
      return date;
  }
};

Year.title = date => format(date, 'yyyy');

export default Year;
