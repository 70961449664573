import React from 'react';
import { Grid } from '@material-ui/core';
import DrawerAccordion from '../DrawerAccordion/DrawerAccordion';

export const DrawerAccordionStandardItems = ({ additionalInformation, attachments }) => {
  return (
    <Grid container spacing={0} style={{ width: '100%', margin: '0', padding: 24 }}>
      <Grid item xs={12}>
        <DrawerAccordion summaryTitle="Additional Information">
          <Grid xs={12}>
            { additionalInformation }
          </Grid>
        </DrawerAccordion>
      </Grid>
      <Grid item xs={12}>
        { attachments }
      </Grid>
    </Grid>
  )
}

export default DrawerAccordionStandardItems;
