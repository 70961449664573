import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { withRouter } from 'react-router';
import { Divider } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';

import EmergencyContactForm from '../../containers/EmergencyContactForm';

import {
  EMERGENCY_CONTACTS_CREATE_SUCCESS,
  EMERGENCY_CONTACTS_CREATE_FAILURE,
  EMERGENCY_CONTACTS_UPDATE_SUCCESS,
  EMERGENCY_CONTACTS_UPDATE_FAILURE,
  createEmergencyContact,
  updateEmergencyContact,
} from './actions';

import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../store/reducers/ui/actions';

// eslint-disable-next-line
import formStyles from '../FormStyles.css';
import { cancelForm } from '../../../store/reducers/ui/actions';
import BasePage from '../BasePage';
import { Grid } from '@material-ui/core';
import { fetchStateType } from '../../../store/reducers/codes/actions';

const ErrorAlert = props => {
  return (
    <span
      className={'alert alert-warning'}
      style={{ display: 'table', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <strong>{props.error.message}</strong>
      <br />
      {/* {Object.keys(props.error.modelState).map((key, i) => {
        return <div key={'error_' + i}>{props.error.modelState[key][0]}</div>;
      })} */}
    </span>
  );
};

class EmergencyContactModifyPage extends Component {
  componentWillMount() {
    this.props.fetchStateType();
  }

  componentWillUnmount() {
    this.props.dispatch(unblockNavigation());
  }

  onFormCancel = () => {
    const { dashboard } = this.props.navigation;
    this.props.cancelForm();
    this.props.history.push(dashboard.account.emergencyContact.read);
  };

  onFormChange = (v, d, props) => {
    if (
      !props.pristine &&
      !this.props.ui.isNavigationLocked &&
      this.props.selectedId
    ) {
      this.props.dispatch(blockNavigationWithStandardMsg());
    }
  };

  onFormSubmit = async values => {
    const { account } = this.props.navigation.dashboard;

    const {
      personId,
      createEmergencyContact,
      updateEmergencyContact,
      dispatch,
      history,
    } = this.props;

    let result;
    if (!values.emergencyContactId) {
      delete values['emergencyContactId'];
      delete values['personId'];
      result = await createEmergencyContact(personId, values);
    } else {
      result = await updateEmergencyContact(values);
    }

    if (
      result.type === EMERGENCY_CONTACTS_CREATE_SUCCESS ||
      result.type === EMERGENCY_CONTACTS_UPDATE_SUCCESS
    ) {
      dispatch(unblockNavigation());
      history.push(account.emergencyContact.read);
    } else if (
      result.type === EMERGENCY_CONTACTS_CREATE_FAILURE ||
      result.type === EMERGENCY_CONTACTS_UPDATE_FAILURE
    ) {
      throw new SubmissionError(result.payload);
    }
  };

  render() {
    const { dashboard } = this.props.navigation;
    const { errorMessage, action } = this.props;
    const entityAction = action === 'CREATE' ? 'New' : 'Update';
    return (
      <BasePage>
        <Grid item xs={12} lg={8}>
          <PageHeader
            headerText="Emergency Contacts"
            subHeaderText={`${entityAction} Entry`}
            backButtonText="Go Back"
            backLink={dashboard.account.emergencyContact.read}
          />

          <Divider style={{ marginTop: 24, marginBottom: 24 }} />

          <EmergencyContactForm
            onSubmit={this.onFormSubmit}
            onCancel={this.onFormCancel}
            onChange={this.onFormChange}
          />

          {errorMessage && <ErrorAlert error={errorMessage} />}
        </Grid>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  action: state.ui.entity.action,
  personId: state.active.user.personId,
  errorMessage: state.member.emergencyInfo.errorMessage,
  ui: state.ui,
  phoneTypes: state.codes.phoneTypes,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  createEmergencyContact: (id, contact) =>
    dispatch(createEmergencyContact(id, contact)),
  updateEmergencyContact: contact => dispatch(updateEmergencyContact(contact)),
  cancelForm: () => dispatch(cancelForm()),
  fetchStateType: () => dispatch(fetchStateType()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(EmergencyContactModifyPage)),
};
