import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import InputField from '../../../library/forms/InputField';
import Button from '@material-ui/core/Button';
import { format } from 'date-fns-tz';
import { connect } from 'react-redux';

const AddInfo = props => {
  return (
    <div>
      <form>
        <Grid container spacing={1}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Field
                type="date"
                name={`date`}
                label="Date"
                shrink={true}
                onChange={props.handleDateChange}
                //onChange={event => this.onStartDateChange(event.target.value)}
                component={InputField}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Field
                type="time"
                name={`time`}
                label="Time"
                shrink={true}
                onChange={props.handleTimeChange}
                //onChange={event => this.onStartDateChange(event.target.value)}
                component={InputField}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              name={`description`}
              label="Notes"
              multiline
              rows={4}
              onChange={props.handleDescriptionChange}
              component={InputField}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: `addInfo.${ownProps.name}${ownProps.index}`,
    enableReinitialize:
      ownProps.initialValues &&
      Boolean(
        ownProps.initialValues.appArea || ownProps.initialValues.applicationArea
      ),
    initialValues: {
      ...ownProps.initialValues,
      notes:
        Boolean(ownProps.initialValues && ownProps.initialValues.description) ||
        ownProps.description,
      date: ownProps.date || format(new Date(), 'yyyy-MM-dd'),
      time: ownProps.time || format(new Date(), 'HH:mm'),
    },
  };
};

// redux connector which supplies the from fields value in specified form defined with reduxFrom
export default compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: true,
    updateUnregisteredFields: true,
  })
)(AddInfo);
