import React from 'react';

import {
  Grid,
  Typography,
  Button,
  withStyles,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';

const options = [
  {
    label: 'Select Category',
    shortname: 'Select Type',
    value: null,
  },
  {
    label: 'Allergies',
    value: 'ALLERGIES',
    shortname: 'Allergies',
  },
  {
    label: 'Medications, Vitamins, and Supplements',
    value: 'MVS',
    shortname: 'MVS',
  },
  {
    label: 'Family Medical History',
    value: 'MEDICAL_HX',
    shortname: 'Family History',
  },
  {
    label: 'Immunizations',
    value: 'IMMUNIZATIONS',
    shortname: 'Immunizations',
  },
  {
    label: 'Journal Entries',
    value: 'JOURNAL_ENTRIES',
    shortname: 'Journal Entries',
  },
  {
    label: 'Surgeries',
    shortname: 'Surgical History',
    value: 'SURGICAL_HX',
  },
  {
    label: 'Conditions / Diagnoses',
    shortname: 'Conditions / Diagnoses',
    value: 'CONDITIONS',
  },
];

const ReportModalFilter = ({
  classes,
  onClose,
  filters,
  template,
  applyFilter,
}) => {
  const handleChange = e => {
    applyFilter({ category: e.target.value });
  };

  const onClear = () => {
    applyFilter({ category: null, query: null, attachments: true });
    onClose();
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="space-between">
        <Typography>Filters</Typography>
        <Button onClick={onClear} color="primary">
          Clear All
        </Button>
      </Grid>
      {template === 'ALL' && (
        <React.Fragment>
          <Typography style={{ marginTop: 24 }}>By Category</Typography>

          <Select
            style={{ width: '100%' }}
            placeholder="Select category"
            value={filters.category}
            onChange={handleChange}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </React.Fragment>
      )}

      <Typography style={{ marginTop: 32 }}>Include Attachments</Typography>
      <Switch
        checked={filters.attachments}
        onChange={e => applyFilter({ attachments: e.target.checked })}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <Grid container justify="flex-end">
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </Grid>
    </div>
  );
};

const styles = theme => ({
  root: {
    padding: 20,
    minWidth: 320,
  },
});

export default withStyles(styles)(ReportModalFilter);
