import { placeItemAtBottom, sort } from '../../../library/helpers/sort';
import { titleCase } from '../../../library/helpers/utils';

import {
  BLOOD_TYPE_REQUEST,
  BLOOD_TYPE_SUCCESS,
  CONDITION_TYPE_REQUEST,
  CONDITION_TYPE_SUCCESS,
  CONTACT_TYPE_REQUEST,
  CONTACT_TYPE_SUCCESS,
  ETHNICITY_TYPE_REQUEST,
  ETHNICITY_TYPE_SUCCESS,
  GENDER_TYPE_REQUEST,
  GENDER_TYPE_SUCCESS,
  LANGUAGE_TYPE_REQUEST,
  LANGUAGE_TYPE_SUCCESS,
  MARITAL_TYPE_REQUEST,
  MARITAL_TYPE_SUCCESS,
  RELIGION_TYPE_REQUEST,
  RELIGION_TYPE_SUCCESS,
  STATE_TYPE_REQUEST,
  STATE_TYPE_SUCCESS,
  VETERAN_TYPE_REQUEST,
  VETERAN_TYPE_SUCCESS,
  VACCINATION_TYPE_REQUEST,
  VACCINATION_TYPE_SUCCESS,
  MEDUNITS_TYPE_REQUEST,
  MEDUNITS_TYPE_SUCCESS,
  DELIVERYMETHOD_TYPE_REQUEST,
  DELIVERYMETHOD_TYPE_SUCCESS,
  DELIVERYFORM_TYPE_SUCCESS,
  DELIVERYFORM_TYPE_REQUEST,
  SURGICALHISTORY_TYPE_REQUEST,
  SURGICALHISTORY_TYPE_SUCCESS,
  DOCUMENT_TYPE_REQUEST,
  DOCUMENT_TYPE_SUCCESS,
} from './actions';

const reducers = {};

const initialState = {
  isFetchingConditionType: true,
  isFetchingContactType: true,
  isFetchingStateType: true,
  isFetchingLanguageType: true,
  isFetchingEthnicityType: true,
  isFetchingReligionType: true,
  isFetchingGenderType: true,
  isFetchingBloodType: true,
  isFetchingMaritalType: true,
  isFetchingVeteranType: true,
  isFetchingVaccinationType: true,
  isFetchingMedUnits: true,
  isFetchingSurgicalHistoryType: false,
  hasFetchedSurgicalHistoryType: false,
  conditionTypes: [],
  contactTypes: [],
  stateTypes: [],
  languageTypes: [],
  ethnicityTypes: [],
  religionTypes: [],
  genderTypes: [],
  bloodTypes: [],
  maritalTypes: [],
  veteranTypes: [],
  vaccinationTypes: [],
  medUnitsTypes: [],
  deliveryMethodTypes: [],
  deliveryFormTypes: [],
  surgicalHistoryTypes: [],
  documentTypes: [],
};

reducers[CONDITION_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingConditionType: true,
});

reducers[CONDITION_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingConditionType: false,
  conditionTypes: action.payload,
});

reducers[CONTACT_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingContactType: true,
});

reducers[CONTACT_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingContactType: false,
  contactTypes: action.payload,
});

reducers[DOCUMENT_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingDocumentType: true,
});

reducers[DOCUMENT_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingDocumentType: false,
  documentType: placeItemAtBottom(
    action.payload.sort(sort('asc', 'shortName')),
    'shortName',
    'other'
  ),
});

reducers[BLOOD_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingBloodType: true,
});

reducers[BLOOD_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingBloodType: false,
  bloodTypes: action.payload,
});

reducers[STATE_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingStateType: true,
});

reducers[STATE_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingStateType: false,
  stateTypes: action.payload,
});

reducers[LANGUAGE_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingLanguageType: true,
});

reducers[LANGUAGE_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingLanguageType: false,
  languageTypes: action.payload,
});

reducers[ETHNICITY_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingEthnicityType: true,
});

reducers[ETHNICITY_TYPE_SUCCESS] = (state, action) => {
  const TERM = 'DECLINED';
  const foundIndex = action.payload.findIndex(
    x => x.longName.toUpperCase() === TERM
  );
  const slicedItem = action.payload.slice(foundIndex, foundIndex + 1)[0];
  const cleanArray = action.payload.filter(
    x => x.longName.toUpperCase() !== TERM
  );

  return {
    ...state,
    isFetchingEthnicityType: false,
    ethnicityTypes: [slicedItem, ...cleanArray],
  };
};

reducers[RELIGION_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingReligionType: true,
});

reducers[RELIGION_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingReligionType: false,
  religionTypes: action.payload,
});

reducers[GENDER_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingGenderType: true,
});

reducers[GENDER_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingGenderType: false,
  genderTypes: action.payload,
});

reducers[MARITAL_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingMaritalType: true,
});

reducers[MARITAL_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingMaritalType: false,
  maritalTypes: action.payload,
});

reducers[VETERAN_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingVeteranType: true,
});

reducers[VETERAN_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingVeteranType: false,
  veteranTypes: action.payload,
});

reducers[VACCINATION_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingVaccinationType: true,
});

reducers[VACCINATION_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingVaccinationType: false,
  vaccinationTypes: action.payload,
});

reducers[MEDUNITS_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingMedUnits: true,
});

reducers[MEDUNITS_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingMedUnits: false,
  medUnitsTypes: action.payload.map(m => m.shortName),
});

reducers[DELIVERYMETHOD_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingDeliveryMethods: true,
});

reducers[DELIVERYMETHOD_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingDeliveryMethods: false,
  deliveryMethodTypes: action.payload.map(d => ({
    value: d.shortName,
    label: d.shortName,
  })),
});

reducers[DELIVERYFORM_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingDeliveryForm: true,
});

reducers[DELIVERYFORM_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingDeliveryForm: false,
  deliveryFormTypes: action.payload.map(f => ({
    value: f.shortName,
    label: titleCase(f.shortName),
  })),
});

reducers[SURGICALHISTORY_TYPE_REQUEST] = state => ({
  ...state,
  isFetchingSurgicalHistoryType: true,
});

reducers[SURGICALHISTORY_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  isFetchingSurgicalHistoryType: false,
  hasFetchedSurgicalHistoryType: true,
  surgicalHistoryTypes: action.payload.map(f => ({
    value: f.shortName,
    label: titleCase(f.shortName),
  })),
});

const reducer = (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};

export default reducer;
