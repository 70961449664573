import React from 'react';
import DashboardMessage from '../../DashboardContent/DashboardMessage';
import {
  Grid,
  Button,
  Container,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { CLOSE_ACCOUNT } from './data';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginBottom: '0px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    backgroundColor: '#ED5158',
    color: '#ffffff',
  },
});

export default withStyles(styles)(({ onClick, classes }) => (
  <DashboardMessage
    type="warning"
    canClose={false}
    style={{ marginBottom: '0px' }}
  >
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.container}>
        <Container maxWidth="md">
          <Typography>
            I want to close my account and delete all of my health and wellness
            data forever.
          </Typography>
        </Container>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          onClick={() => onClick(CLOSE_ACCOUNT)}
          className={classes.button}
        >
          {`Close & Delete`}
        </Button>
      </Grid>
    </Grid>
  </DashboardMessage>
));
