/**
 * Using a JSON object create a query string with each
 * component URI encoded.
 *
 * @param {object} object
 * @param {string}
 */
export const createQueryStringFromObject = object =>
  Object.keys(object)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`)
    .join('&');

/**
 * Decode all (include spaces) URL encoded characters. The decodeURIComponent
 * doesn't decode spaces "+" this method does
 *
 * @param {string} value
 * @returns string with decoded URL characters
 */
export const decodeQueryParam = value =>
  decodeURIComponent(value).replace(/\+/g, ' ');

/**
 * Get decoded query string parameter from window.location
 *
 * @param {string} key query string parameter
 * @returns string | null value of query string parameter
 */
export const getQueryStringParam = key => {
  // Remove the ? from the start of the string
  const query = window.location.search.substring(1);
  const params = query.split('&');
  for (let i = 0; i < params.length; i++) {
    let [paramKey, paramValue] = params[i].split('=');
    if (decodeQueryParam(paramKey) == key) return decodeQueryParam(paramValue);
  }

  return null;
};
