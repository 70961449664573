import React, { Component } from 'react';
import { Grid, Divider, Button } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import BasePage from '../BasePage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from 'react-awesome-modal';
import Iframe from 'react-iframe';

import { fetchUserAgreements } from '../../../store/reducers/agreements/actions';

class UserAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentURL: '',
    };
  }

  openModal = item => {
    this.setState({
      visible: true,
      currentURL: item.absoluteUri,
    });
  };

  closeModal() {
    this.setState({
      visible: false,
      currentURL: '',
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { fetchUserAgreements } = this.props;
    fetchUserAgreements();
  }

  render() {
    return (
      <React.Fragment>
        <BasePage state={this.props.uiState}>
          <Grid item xs={12}>
            <PageHeader
              headerText="User Agreement(s)"
              backButtonText="Go Back"
            />
            <Divider style={{ marginBottom: 24 }} />
          </Grid>
          <Grid item xs={12}>
            {this.props.userAgreements.map((item, i) => {
              return (
                <Grid
                  key={i}
                  container
                  justify="space-between"
                  style={{
                    marginBottom: 10,
                    backgroundColor: '#f2f2f2',
                    padding: 10,
                  }}
                >
                  <Grid item xs={12} sm={8} md={6}>
                    <label
                      onClick={() => this.openModal()}
                      style={{
                        color: 'black',
                        marginRight: 15,
                        lineHeight: '35px',
                      }}
                    >
                      {decodeURI(item.name)}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={4} md={6}>
                    <Grid container justify="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.openModal(item)}
                      >
                        <i className={'fa fa-file-pdf-o'}></i>&nbsp;&nbsp;VIEW
                        PDF
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 24 }} />
                </Grid>
              );
            })}
          </Grid>
        </BasePage>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Modal
            visible={this.state.visible}
            width="95%"
            height="95%"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <br />
            {this.state.currentURL.length > 0 && (
              <Iframe
                display="initial"
                style={{ maxWidth: '100%;', marginTop: '20px;' }}
                height="90%"
                width="95%"
                url={this.state.currentURL}
                position="relative"
              />
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => this.closeModal()}
            >
              CLOSE
            </Button>
          </Modal>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userAgreements: state.agreements.userAgreements,
  uiState: state.agreements.uiState,
});

const mapDispatchToProps = dispatch => ({
  fetchUserAgreements: () => dispatch(fetchUserAgreements()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(UserAgreement)),
};
