import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, withStyles, Button } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import validate from './validateForm';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import { mask } from '../../../../library/helpers/format';
import { fetchStateType } from '../../../../store/reducers/codes/actions';
import PROVIDER_LABELS from '../Provider01Form/providerLabels';

const styles = {
  root: {},
  field: {},
  buttons: {
    marginTop: 35,
  },
};

const ENTITY_TYPE_ORGANIZATION = '2';

const postalCodeMask = value => mask(value, 'XXXXX-XXXX');

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

const phoneMask = value => mask(value, 'XXX-XXX-XXXX');

const ProviderEdit = ({
  handleSubmit,
  classes,
  stateTypes,
  fetchStateType,
  onCancel,
  member: { isManualEntry },
}) => {
  useEffect(() => {
    fetchStateType();
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isManualEntry ? (
          <Grid item xs={6} className={classes.field}>
            <Field
              type="text"
              name="fullName"
              label="Full Name"
              component={InputField}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="email"
            label="Email"
            component={InputField}
            hint="Email is only required to grant the member access"
          />
        </Grid>
        <Grid item xs={6} md={3} className={classes.field}>
          <Field
            type="text"
            name="phoneNumber"
            label="Phone Number"
            placeholder="234-945-4328"
            component={InputField}
            format={phoneMask}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={6} md={3} className={classes.field}>
          <Field
            type="text"
            name="faxNumber"
            label="Fax Number"
            placeholder="234-945-4328"
            component={InputField}
            format={phoneMask}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="street"
            label="Street Address"
            component={InputField}
            placeholder="E.g., 2010 Colonial Way"
            hint="Optional"
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="street2"
            label="Street Address 2"
            component={InputField}
            placeholder="E.g., Apt 242 or Ste 201"
            hint="Optional"
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            type="text"
            name="city"
            label="City"
            component={InputField}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={3} className={classes.field}>
          <Field
            name="state"
            label="State"
            labelKey="longName"
            valueKey="shortName"
            hint="Optional"
            component={SelectField}
            options={stateTypes}
            isSearchable
          />
        </Grid>
        <Grid item xs={3} className={classes.field}>
          <Field
            type="text"
            name="postalCode"
            label="Zipcode"
            placeholder="XXXXX or XXXXX-XXXX"
            inputProps={{ maxLength: 10 }}
            component={InputField}
            format={postalCodeMask}
            normalize={postalCodeNormalizer}
            hint="Optional"
          />
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            name="labels"
            label="Member Labels"
            labelKey="longName"
            valueKey="shortName"
            hint="Labels help customize your care team member"
            component={SelectField}
            options={PROVIDER_LABELS}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.buttons}
      >
        <Grid>
          <Button onClick={onCancel}>Cancel</Button>
        </Grid>
        <Grid style={{ marginLeft: 24 }}>
          <Button variant="contained" color="primary" type="submit">
            Update Info
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state, props) => {
  const { member } = props;
  const street2 = member.street2 || '';

  var fullName = member.displayName;

  var labels = undefined;
  if (member && member.labels && member.labels.length > 0)
    labels = member.labels;

  return {
    initialValues: {
      ...member,
      fullName,
      street2,
      labels,
      phoneNumber: phoneMask(member.phoneNumber),
      faxNumber: phoneMask(member.faxNumber),
      postalCode: postalCodeMask(member.postalCode),
    },
    stateTypes: state.codes.stateTypes,
  };
};

ProviderEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchStateType: () => dispatch(fetchStateType()),
});

const ConnectedForm = reduxForm({
  form: 'care-team-edit',
  validate,
  enableReinitialize: true,
})(withStyles(styles)(ProviderEdit));

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedForm);
