import React, { Component } from 'react';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  USER_PORTALS_CREATE_SUCCESS,
  USER_PORTALS_CREATE_FAILURE,
  USER_PORTALS_UPDATE_SUCCESS,
  USER_PORTALS_UPDATE_FAILURE,
  createUserPortal,
  updateUserPortal,
  clearSelectedPortalId,
} from '../../../store/reducers/portals';

import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../store/reducers/ui/actions';

import styles from './portal.css';
// import PortalForm from './PortalForm';
import PortalForm from '../../containers/PortalForm';
import PageHeader from '../../components/PageHeader';
import BasePage from '../BasePage';
import { Grid, Divider } from '@material-ui/core';

class CreateUpdatePortal extends Component {
  onFormChange = (v, d, props) => {
    const { ui, portals } = this.props;
    if (!props.pristine && !ui.isNavigationLocked && portals.selectedId) {
      this.props.dispatch(blockNavigationWithStandardMsg());
    }
  };

  onFormCancel = () => {
    const { dashboard } = this.props.navigation;
    this.props.history.push(dashboard.healthRecord.portals.read);
  };

  onFormSubmit = async values => {
    const { dashboard } = this.props.navigation;
    const { personId } = this.props;

    let result;

    if (!values.portalId) {
      result = await this.props.createUserPortal(personId, values);
    } else {
      result = await this.props.updateUserPortal(values);
    }

    if (
      result.type === USER_PORTALS_CREATE_SUCCESS ||
      result.type === USER_PORTALS_UPDATE_SUCCESS
    ) {
      this.props.unblockNavigation();
      this.props.history.push(dashboard.healthRecord.portals.read);
    } else if (
      result.type === USER_PORTALS_CREATE_FAILURE ||
      result.type === USER_PORTALS_UPDATE_FAILURE
    ) {
      throw new SubmissionError(result.payload);
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedPortalId();
    this.props.dispatch(unblockNavigation());
  }

  render() {
    const { portals } = this.props.navigation.dashboard.healthRecord;
    const { ui } = this.props;
    const entityAction = ui.entity.action === 'CREATE' ? 'New' : 'Update';

    return (
      <BasePage>
        <Grid item xs={12}>
          <PageHeader
            headerText="Patient Portals"
            subHeaderText={`${entityAction} Entry`}
            backButtonText="Go Back"
            backLink={portals.read}
          />
          <Divider style={{ marginTop: 24 }} />
        </Grid>
        <Grid item xs={12} lg={5}>
          <PortalForm
            onChange={this.onFormChange}
            onCancel={this.onFormCancel}
            onSubmit={this.onFormSubmit}
          />
        </Grid>
      </BasePage>
    );
  }
}

//portalName, providerName, portal url max 500 chars
//portal description 1000
//url validation
const mapStateToProps = state => ({
  portals: state.portals,
  personId: state.active.user.personId,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  blockNavigation: () => dispatch(blockNavigationWithStandardMsg()),
  unblockNavigation: () => dispatch(unblockNavigation()),
  createUserPortal: (id, data) => dispatch(createUserPortal(id, data)),
  updateUserPortal: data => dispatch(updateUserPortal(data)),
  clearSelectedPortalId: () => dispatch(clearSelectedPortalId()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(CreateUpdatePortal)),
};
