import React from 'react';
import { Stepper, Step, StepLabel, makeStyles } from '@material-ui/core';

import Connector from './Connector';
import ConnectorIcon from './ConnectorIcon';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  stepperRoot: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  completed: {
    color: '',
  },
  active: {},
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

function HorizontalStepper({ activeStep, data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        classes={{ root: classes.stepperRoot }}
        style={{ backgroundColor: 'transparent' }}
        orientation="horizontal"
        activeStep={activeStep}
        connector={<Connector />}
        nonLinear
      >
        {data.map(step => {
          const stepProps = {};
          const labelProps = {};

          if (step.optional) {
            labelProps.optional = (
              <Typography variant="caption">{step.optional}</Typography>
            );
          }

          return (
            <Step {...stepProps} key={step.label}>
              <StepLabel
                classes={{ label: classes.label }}
                {...labelProps}
                StepIconComponent={ConnectorIcon}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default HorizontalStepper;
