import React from 'react';
import {
  Grid,
  withStyles,
  Typography,
  IconButton,
  Icon,
  Input,
  Popover,
} from '@material-ui/core';
import SelectField from '../../../library/forms/SelectField';
import ReportModalFilter from './ReportModalFilter';

const defaultOption = {
  label: 'Select Report Type',
  shortname: 'Select Type',
  value: 'SELECT_TYPE',
};

const options = [
  {
    label: 'Allergies',
    value: 'ALLERGIES',
    shortname: 'Allergies',
  },
  {
    label: 'Medications, Vitamins, and Supplements',
    value: 'MVS',
    shortname: 'MVS',
  },
  {
    label: 'Family Medical History',
    value: 'MEDICAL_HX',
    shortname: 'Family History',
  },
  {
    label: 'Immunizations',
    value: 'IMMUNIZATIONS',
    shortname: 'Immunizations',
  },
  {
    label: 'Journal Entries',
    value: 'JOURNAL_ENTRIES',
    shortname: 'Journal Entries',
  },
  {
    label: 'Surgeries',
    shortname: 'Surgical History',
    value: 'SURGICAL_HX',
  },
  {
    label: 'Conditions / Diagnoses',
    shortname: 'Conditions / Diagnoses',
    value: 'CONDITIONS',
  },
  {
    label: 'Lab Results / Pathology',
    shortname: 'LABRESULTS',
    value: 'LABRESULTS',
  },
  // {
  //   label: 'Full Health Record',
  //   shortname: 'Full Record',
  //   value: 'ALL',
  // },
];

const sortOptions = options => {
  return options.sort((a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  });
};

const noop = () => {};

const ReportModalHeader = ({
  onTemplateChange,
  selected,
  classes,
  applyFilter,
  filters,
}) => {
  const [template, setTemplate] = React.useState(defaultOption);
  const [searchOpen, setSearchOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    onTemplateChange(template && template.label ? null : template);
  }, [template]);

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const getOptionLabel = option => option.shortname;
  const getOptionValue = option => option.value;

  const parseSelected = () => {
    const parsed = selected.reduce((acc, curr) => {
      if (acc[curr.recordType]) {
        // if record type already exists in object, add one to total
        return {
          ...acc,
          [curr.recordType]: {
            ...acc[curr.recordType],
            total: (acc[curr.recordType].total += 1),
          },
        };
      }
      // if does not exist in object, create new key with a total of 1
      return {
        ...acc,
        [curr.recordType]: { label: curr.recordType, total: 1 },
      };
    }, {});

    const parsedMap = [];

    // for each key in parsed object, push an element to be rendered to the UI

    for (const key in parsed) {
      const idx = options.map(o => o.value).indexOf(parsed[key].label);

      parsedMap.push({ ...parsed[key], label: options[idx].shortname });
    }

    return parsedMap;
  };

  const setHeader = () => {
    switch (template) {
      case 'ALLERGIES':
        return 'Allergies';
      case 'MVS':
        return 'Medications, Vitamins, and Supplements';
      case 'MEDICAL_HX':
        return 'Family Medical History';
      case 'IMMUNIZATIONS':
        return 'Immunizations';
      case 'JOURNAL_ENTRIES':
        return 'Journal Entries';
      case 'SURGICAL_HX':
        return 'Surgeries';
      case 'CONDITIONS':
        return 'Conditions / Diagnoses';
      case 'LABRESULTS':
        return 'Lab Results / Pathology';
      case 'ALL':
        return 'Full Health Record';
      default:
        return 'Records';
    }
  };

  const toggleSearch = toggle => () => {
    setSearchOpen(toggle);
  };

  const filterOpen = Boolean(anchorEl);

  const onSearchChange = e => {
    const query = e.target.value.toLowerCase();
    applyFilter({ query });
  };
  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm={4}>
          <SelectField
            labelKey="shortname"
            valueKey="value"
            options={sortOptions(options)}
            input={{
              value: template,
              onChange: setTemplate,
              onBlur: noop,
              onFocus: noop,
            }}
            meta={{
              touched: false,
              active: true,
              error: '',
            }}
          />
        </Grid>
        <Grid
          container
          wrap="nowrap"
          item
          xs={12}
          sm={8}
          className={classes.container}
          alignItems="center"
        >
          {parseSelected(selected).map((item, index) => (
            <div key={`${item.label}-rh${index}`} className={classes.chip}>
              <Grid
                container
                justify="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Typography
                  align="center"
                  variant="body2"
                  className={classes.total}
                >
                  {item.total}
                </Typography>
                <Typography className={classes.label} variant="body2">
                  {item.label}
                </Typography>
              </Grid>
            </div>
          ))}
        </Grid>
      </Grid>
      {template && template.value !== 'SELECT_TYPE' && (
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Typography variant="h6">{setHeader()}</Typography>
          <Grid
            item
            xs={6}
            wrap="nowrap"
            container
            alignItems="center"
            justify="flex-end"
          >
            {searchOpen && (
              <Input
                onChange={onSearchChange}
                autoFocus
                placeholder="Search by record name/title"
                className={classes.input}
                inputProps={{
                  'aria-label': 'search',
                }}
              />
            )}
            <IconButton onClick={toggleSearch(!searchOpen)}>
              <Icon>search</Icon>
            </IconButton>

            {/* Functions are not fully baked
            <IconButton onClick={handleFilterClick}>
              <Icon>filter_list</Icon>
            </IconButton>
            <Popover
              open={filterOpen}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <ReportModalFilter
                filters={filters}
                onClose={handleFilterClose}
                template={template}
                applyFilter={applyFilter}
              />
            </Popover> */}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const styles = theme => ({
  container: {
    overflowX: 'auto',
    marginTop: -12,
  },
  input: {
    marginRight: 12,
    width: '100%',
  },
  root: {
    marginTop: 18,
  },
  chip: {
    padding: 8,
    borderRadius: 40,
    backgroundColor: theme.palette.primary.main,
    marginRight: 12,
  },
  label: {
    color: '#fff',
    whiteSpace: 'nowrap',
  },
  total: {
    marginRight: 6,
    width: 28,
    height: 28,
    borderRadius: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(ReportModalHeader);
