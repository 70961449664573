import { properties as activityExerciseProperties } from './fragments/ActivityExercise';
import { properties as bloodPressureProperties } from './fragments/BloodPressure';
import { properties as bodyMetricsProperties } from './fragments/BodyMetrics';
import { properties as emotionsProperties } from './fragments/Emotions';
import { properties as mealTrackerProperties } from './fragments/MealTracker';
import { properties as medicationsProperties } from './fragments/Medications';
import { properties as waterIntakeProperties } from './fragments/WaterIntake';
import { properties as sleepProperties } from './fragments/Sleep';
import { properties as stressManagementProperties } from './fragments/StressManagement';

export const FRAGMENT_BODY_MASS = 'BodyMass';
export const FRAGMENT_BLOOD_PRESSURE = 'BloodPressure';
export const FRAGMENT_WATER_INTAKE = 'WaterIntake';
export const FRAGMENT_EMOTIONS = 'Emotions';
export const FRAGMENT_DEFAULT = 'default';
export const FRAGMENT_BODY_METRICS = 'BodyMetrics';
export const FRAGMENT_SLEEP_TRACKER = 'SleepTracker';
export const FRAGMENT_MEAL_TRACKER = 'MealEntry';
export const FRAGMENT_STRESS_MANAGEMENT = 'StressManagement';
export const FRAGMENT_ACTIVITY_EXERCISE = 'ActivityExercise';
export const FRAGMENT_MEDICATIONS = 'Medications';

export const defaultDataNormalizer = data => data;

export const getFragmentProperties = name => {
  const fragmentCollection = {
    [FRAGMENT_ACTIVITY_EXERCISE]: activityExerciseProperties,
    [FRAGMENT_BLOOD_PRESSURE]: bloodPressureProperties,
    [FRAGMENT_BODY_MASS]: bodyMetricsProperties,
    [FRAGMENT_EMOTIONS]: emotionsProperties,
    [FRAGMENT_MEAL_TRACKER]: mealTrackerProperties,
    [FRAGMENT_MEDICATIONS]: medicationsProperties,
    [FRAGMENT_WATER_INTAKE]: waterIntakeProperties,
    [FRAGMENT_SLEEP_TRACKER]: sleepProperties,
    [FRAGMENT_STRESS_MANAGEMENT]: stressManagementProperties,
  };

  return fragmentCollection[name];
};

export const fragmentList = [
  FRAGMENT_ACTIVITY_EXERCISE,
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_EMOTIONS,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_MEDICATIONS,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_STRESS_MANAGEMENT,
  FRAGMENT_SLEEP_TRACKER,
];
