import { parseISO } from 'date-fns';

export const required = value => (value ? undefined : 'Required');

const truncateTime = date => date.setHours(0, 0, 0, 0);
const currentDate = truncateTime(new Date());

const validateIsBeforeDate = (prevDate, futureDate) => {
  const truncatedPrevDate = truncateTime(parseISO(prevDate));
  const truncatedFutureDate = truncateTime(parseISO(futureDate));
  return (
    truncatedFutureDate &&
    truncatedPrevDate &&
    truncatedPrevDate !== truncatedFutureDate &&
    truncatedPrevDate < truncatedFutureDate
  );
};

export const beforeCollectedOn = (value, allValues) => {
  if (validateIsBeforeDate(allValues.collectedOn, value)) {
    return 'Order date must come before collection date';
  }
  return undefined;
};

export const afterCollectedOn = (value, allValues) => {
  if (value && currentDate < truncateTime(parseISO(value))) {
    return `Results date cannot in the future.`;
  }
  if (validateIsBeforeDate(value, allValues.collectedOn)) {
    return 'Result date must come after collection date';
  } else return undefined;
};

export const afterOrderedOn = (value, allValues) => {
  if (value && currentDate < truncateTime(parseISO(value))) {
    return `Collection date cannot be in the future.`;
  }
  if (validateIsBeforeDate(value, allValues.orderedOn)) {
    return 'Collection date must come after order date';
  } else return undefined;
};
