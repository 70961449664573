import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';

const EmptyComponent = ({ matches }) => {
  return (
    <Paper
      style={{
        maxWidth: '700px',
        width: '100%',
        padding: '16px',
        border: '5px solid #fff',
        borderRadius: '4px',
        background: '#0068A8',
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <Typography
            variant="h5"
            style={{ fontSize: matches ? '1.25rem' : '1rem' }}
          >
            OOPS! There is no data for this period.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontWeight: '200',
          }}
        >
          <Typography
            variant="body1"
            style={{ fontSize: matches ? '1rem' : '12px' }}
          >
            Please select another time period, or go to your <b>Journal</b> to
            start tracking.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmptyComponent;
