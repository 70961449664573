import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import MediaObject from '../../containers/MediaObject';
import Button from '@material-ui/core/Button';
import { clearFiles, uploadFiles } from '../../../store/reducers/mediaObject';
import {
  addMediaCollection,
  getMedia,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import { connect } from 'react-redux';

class MediaAddMode extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.clearFiles();
  }

  // function to change VIEW mode to EDIT mode in drawer container
  SubmitFiles = async () => {
    const { bundles, personId, onClickCancel } = this.props;

    const objectId = this.props.objectId;
    const areaType = this.props.areaType;

    if (areaType === 'medication') {
      await this.props.addMediaCollection(personId, objectId, bundles, 'mvs');
    } else {
      await this.props.addMediaCollection(
        personId,
        objectId,
        bundles,
        areaType.toLowerCase()
      );
    }

    if (this.props.allUploaded) {
      // this.props.getMedia(personId, objectId, areaType.toLowerCase());
      this.props.clearFiles();
      onClickCancel();
    }
  };

  render() {
    const { onClickCancel } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {this.props.bundle != null && this.props.bundle.length > 0 ? (
            <Grid item xs={12} md={12} lg={12} style={{ marginBottom: 50 }}>
              <MediaObject mode="drawer" />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12}>
              <MediaObject mode="drawer" />
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              size="medium"
              onClick={onClickCancel}
              disabled={this.props.uploadingProcess}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="medium"
              onClick={this.SubmitFiles}
              disabled={this.props.uploadingProcess}
            >
              Submit Files
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { isUploadingFiles, bundles } = state.mediaObject;
  const { personId } = state.active.user;
  const { allUploaded, uploadingProcess } = state.mediaObjectDrawer;
  return {
    bundles,
    personId,
    isUploadingFiles,
    allUploaded,
    uploadingProcess,
  };
};

const mapDispatchToProps = dispatch => ({
  uploadFiles: (files, meta) => dispatch(uploadFiles(files, meta)),
  clearFiles: () => dispatch(clearFiles()),
  getMedia: (pid, aid, area) => dispatch(getMedia(pid, aid, area)),
  addMediaCollection: (id, objectId, bundles, area) =>
    dispatch(addMediaCollection(id, objectId, bundles, area)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaAddMode);
