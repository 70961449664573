import React, { useState, useEffect } from 'react';
import { Grid, Button, Icon, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  mvsFetch,
  mvsDelete,
  mvsSetIdToUpdate,
  mvsClearUpdateId,
} from '../../../store/reducers/mvs/actions';
import {
  cancelFormReset,
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';
import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import MVSTable from './MVSTable';
import Drawer from './Drawer';
import { clearFiles } from '../../../store/reducers/mediaObject';

const dateFormat = 'yyyy-MM-dd';

const MVSPage = props => {
  const { dashboard } = props.navigation;
  const { listIds, list, isFetching } = props.medication;

  const [personId, setPersonId] = useState(0);
  const [personHasChanged, setPersonHasChanged] = useState(false);
  const [open, setOpen] = useState(0);

  useEffect(() => {
    const { mvsFetch, listIds, didInvalidate, cancelFormReset, ui } = props;

    if (didInvalidate || (listIds.length === 0 && !ui.isFormCanceled)) {
      mvsFetch(personId);
    }

    if (ui.isFormCanceled) {
      cancelFormReset();
    }
  }, []);

  useEffect(() => {
    setPersonId(props.personId);
    props.mvsFetch(props.personId);
    if (!personHasChanged) {
      setPersonHasChanged(true);
    }
  }, [props.personId]);

  const createRecord = () => {
    props.uiStateEntityCreate('MVS');
    props.mvsClearUpdateId();
    setOpen(true);
  };

  const editMed = med => {
    props.uiStateEntityUpdate('MVS');
    props.mvsSetIdToUpdate(med);
    setOpen(true);
  };

  const deleteMed = (data, e) => {
    props.mvsDelete(data.personId, data.medicationId);
  };

  return (
    <div>
      <Drawer open={open} setOpen={setOpen} clearFiles={props.clearFiles} />
      <BasePage
        state={props.uiState}
        emptyStateComponentProps={{
          name: 'Medications',
          type: 'Medications, Vitamins, and Supplements',
          onClick: createRecord,
        }}
      >
        <Grid item xs={12}>
          <PageHeader
            headerText="Medications, Vitamins, and Supplements"
            subHeaderText="View Records"
            backButtonText="Go Back"
            backLink={dashboard.healthRecord.self}
            rightSlot={
              <Button
                color="primary"
                variant="contained"
                aria-label="Add"
                onClick={createRecord}
                style={{ float: 'right' }}
              >
                <Icon>add</Icon>
                New Record
              </Button>
            }
          />
          <Divider style={{ marginTop: 24 }} />
        </Grid>
        <Grid item xs={12}>
          <MVSTable
            listIds={listIds}
            list={list}
            isFetching={isFetching}
            onEdit={editMed}
            onDelete={deleteMed}
          />
        </Grid>
      </BasePage>
    </div>
  );
};

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  medication: state.member.medication,
  didInvalidate: state.member.medication.didInvalidate,
  listIds: state.member.medication.listIds,
  list: state.member.medication.list,
  ui: state.ui,
  uiState: state.member.medication.uiState,
});

const mapDispatchToProps = dispatch => ({
  mvsFetch: id => dispatch(mvsFetch(id)),
  mvsDelete: (pid, medicationId) => dispatch(mvsDelete(pid, medicationId)),
  mvsSetIdToUpdate: id => dispatch(mvsSetIdToUpdate(id)),
  mvsClearUpdateId: () => dispatch(mvsClearUpdateId()),
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
  clearFiles: () => dispatch(clearFiles('MVS')),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(withRouter(MVSPage)),
};
