import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { withRouter } from 'react-router';
import { Typography, InputAdornment, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from '../FragmentDialogBase';
import InputField from '../../../../../library/forms/InputField';
import { required } from '../Validations';
import classNames from 'classnames';
import { format } from 'date-fns';

const useStyles = makeStyles({
  labelBottom: {
    width: '100%',
    fontSize: 16,
    marginTop: 12,
    marginBottom: -12,
  },
  calculateLabel: {
    marginTop: 4,
    marginBottom: -4,
  },
  root: {
    width: '100%',
  },
  bottleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    transition: 'all .2s',
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  bottleContainerActive: {
    opacity: 1,
  },
  bottleSm: {
    height: 60,
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  bottleMd: {
    height: 80,
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  bottleLg: {
    height: 100,
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  label: {
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    marginTop: 4,
    width: '100%',
  },
});
const currentTime = new Date();

export const WATER_INTAKE_FIELDS = {
  unit: 'oz',
  quantity: '0.0',
};

const formatWaterIntakeInput = value =>
  parseFloat(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

const MAXIMUM_VALUE = 99999;

const WaterIntakeDialog = ({ fragment, updateField }) => {
  const classes = useStyles();

  const onBottleClick = size => {
    setQuantity(parseFloat(size));
    updateField('quantity', parseFloat(size));
  };

  const [quantity, setQuantity] = useState(0);

  return (
    <FragmentDialogBase
      title="Water Intake Form"
      icon="invert_colors"
      color="#5A82F6"
      FormSlot1={
        <Grid container spacing={2} style={{ marginTop: 24 }}>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={4}
              container
              alignItems="flex-end"
              justify="center"
              className={classNames(
                classes.bottleContainer,
                quantity === 8 && classes.bottleContainerActive
              )}
              onClick={() => onBottleClick(8)}
            >
              <img
                className={classes.bottleSm}
                src={require('../../../../../client/images/bottle.png')}
              />
              <p className={classes.label}>8oz</p>
            </Grid>
            <Grid
              item
              xs={4}
              container
              alignItems="flex-end"
              justify="center"
              className={classNames(
                classes.bottleContainer,
                quantity === 16 && classes.bottleContainerActive
              )}
              onClick={() => onBottleClick(16)}
            >
              <img
                className={classes.bottleMd}
                src={require('../../../../../client/images/bottle.png')}
              />
              <p className={classes.label}>16oz</p>
            </Grid>
            <Grid
              item
              xs={4}
              container
              alignItems="flex-end"
              justify="center"
              className={classNames(
                classes.bottleContainer,
                quantity === 32 && classes.bottleContainerActive
              )}
              onClick={() => onBottleClick(32)}
            >
              <img
                className={classes.bottleLg}
                src={require('../../../../../client/images/bottle.png')}
              />
              <p className={classes.label}>32oz</p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.labelBottom}>Custom</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              type="number"
              normalize={value => {
                const temp = value.substring(
                  0,
                  value.endsWith('.') ? value.length - 1 : value.length
                );
                return temp;
              }}
              format={value => {
                let totalDigits = MAXIMUM_VALUE.toString().length;
                let normalizeValue = value.toString();
                let temp = normalizeValue.substring(
                  0,
                  normalizeValue.includes('.') ? totalDigits + 1 : totalDigits
                );
                return temp.substring(
                  0,
                  temp.endsWith('.') ? temp.length - 1 : temp.length
                );
              }}
              fullWidth
              name="quantity"
              component={InputField}
              validate={[required]}
              onChange={e => {
                const { value } = e.target;
                onBottleClick(value);
              }}
              inputProps={{
                step: 1.0,
                min: 0,
                max: MAXIMUM_VALUE,
              }}
              props={{
                label: 'Intake',
                type: 'number',
                attributes: {
                  endAdornment: (
                    <InputAdornment
                      disableTypography
                      style={{
                        fontSize: '0.875rem',
                        color: 'rgba(0, 0, 0, 0.54)',
                        lineHeight: '1.5',
                      }}
                      position="end"
                    >
                      oz
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => ({
  initialValues: {
    ...WATER_INTAKE_FIELDS,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : {}),
  },
});

const WaterIntakeReduxForm = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(WaterIntakeDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaterIntakeReduxForm);
