import React from 'react';
import PageHeader from '../../components/PageHeader';
import { IconButton, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const DocumentsPageHeader = ({ setMedia, onClick }) => (
  <Grid container>
    <Grid item xs={8}>
      <PageHeader headerText="My Documents" backButtonText="Go Back" />
    </Grid>
    <Grid item xs={4}>
      <div
        style={{ height: '100%', justifyContent: 'flex-end', display: 'flex' }}
      >
        <div>
          <IconButton
            variant="contained"
            style={{
              marginTop: 10,
              borderRadius: 0,
              background: '#006AB0',
              color: '#fff',
            }}
            onClick={onClick}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </Grid>
  </Grid>
);

export default DocumentsPageHeader;
