import React from 'react';
import { Typography } from '@material-ui/core';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

const ErrorMessage = ({ fileName = [], handleOnMessageClose }) => {
  return (
    <DashboardMessage
      type="warning"
      canClose={true}
      onClose={handleOnMessageClose}
    >
      <Typography>
        We're sorry, We are unable to upload{' '}
        {Boolean(fileName) && fileName.length > 1 ? (
          fileName.map((file, index) => {
            const decorator = index === fileName.length - 1 ? `. ` : ` and `;
            return (
              <span
                key={`rejected-files-${index}`}
                style={{ wordBreak: 'break-all', whiteSpace: 'preline' }}
              >
                <b>{file}</b>
                {decorator}
              </span>
            );
          })
        ) : (
          <span
            style={{
              wordBreak: 'break-all',
              whiteSpace: 'preline',
            }}
          >
            <b>{fileName[0]}</b>.{' '}
          </span>
        )}
        Please try again later. If the problem persists, please contact support
        at support@agrinhealth.com
      </Typography>
    </DashboardMessage>
  );
};

export default ErrorMessage;
