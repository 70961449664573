import { TREATMENTS_REQUEST, TREATMENTS_SUCCESS, TREATMENTS_FAILURE } from './actions';

const initialState = {
  isFetchingTreatments: true,
  fetchFailed: false,
  treatments: []
};

export default (state = initialState, action) => {

  // Short circuit if attempting to request again when a request is 
  // already being made. 
  if (action.type === TREATMENTS_REQUEST && state.isFetchingTreatments) {
    return state;
  }

  switch(action.type) {

    case TREATMENTS_REQUEST:
      return {
        ...state,
        isFetchingTreatments: true
      }
    
    case TREATMENTS_SUCCESS:
      return {
        ...state,
        isFetchingTreatments: false,
        fetchFailed: false,
        treatments: action.payload
      }

    case TREATMENTS_FAILURE:
      return {
        ...state,
        fetchFailed: true,
        isFetchingTreatments: false
      }

    default: 
      return state;
  }

}