import { useState, useEffect } from 'react';
import { sortOptions, status, sortByStatus } from '../index.js';
import { getMonth, parseISO, getYear } from 'date-fns';
import useToggleFilter from './useToggleFilter';

const useSliderContainer = (propListIds, list, sortBy, state, setState) => {
  const { CATEGORIES, DATE, TITLE } = sortByStatus;
  const { LOADING, EMPTY, SUCCESS, IDLE, ERROR } = status;
  const [keys, setKeys] = useState([]);
  const [initialListId, setInitialListId] = useState(propListIds);

  useEffect(() => {
    if (propListIds !== initialListId) setInitialListId(propListIds);
  }, [propListIds]);

  const { listIds, setFilter, filter, hasFiltered } = useToggleFilter(
    initialListId,
    list,
    sortBy
  );

  const isSliderEmpty = arr => {
    if (arr.length === 0 && state === LOADING) {
      setState(EMPTY);
    } else setState(IDLE);
  };

  useEffect(() => {
    if (!hasFiltered) setState(LOADING);
    else {
      if (Boolean(listIds) && listIds.length) {
        if (sortBy === TITLE) setState(IDLE);
        if (sortBy === CATEGORIES) getCategoriesList();
        if (sortBy === DATE) getMonthsList();
      } else setState(EMPTY);
    }
  }, [initialListId, listIds, sortBy, hasFiltered]);

  const getCategoriesList = () => {
    if (listIds.length === 0) setKeys([]);
    const categoryList = [];

    // Commented set of functions returns categories filtered by length,
    // changed to filter Alphabetically
    // listIds.forEach(id => {
    //   if (Boolean(list[id].category)) categoryList.push(list[id].category);
    // });
    // const cnts = categoryList.reduce(function(obj, val) {
    //   obj[val] = (obj[val] || 0) + 1;
    //   return obj;
    // }, {});
    // const categories = Object.keys(cnts).sort(function(a, b) {
    //   return cnts[b] - cnts[a];
    // });

    listIds.forEach(id => {
      if (categoryList.includes(list[id].category)) return;
      categoryList.push(list[id].category);
    });
    if (categoryList !== keys) {
      setKeys(categoryList.sort());
    }
    isSliderEmpty(categoryList);
    return;
  };

  const getMonthsList = () => {
    if (listIds.length === 0) setKeys([]);
    const dateList = [];
    listIds.forEach(id => {
      if (!Boolean(list[id].enteredOn)) return;
      const date = parseISO(list[id].enteredOn);
      const month = getMonth(date);
      const year = getYear(date);
      if (!isArrayInArray(dateList, [month, year]))
        dateList.push([month, year]);
    });
    if (dateList !== keys) {
      setKeys(
        dateList.sort((a, b) => {
          if (a[1] > b[1]) {
            return -1;
          }
          if (a[1] < b[1]) {
            return 1;
          } else {
            if (a[0] > b[0]) return -1;
            if (a[0] < b[0]) return 1;
            else return 0;
          }
        })
      );
    }
    isSliderEmpty(dateList);
    return;
  };

  return {
    listIds: getSortedListIds(listIds, list),
    setFilter,
    filter,
    hasFiltered,
    keys,
  };
};

const getSortedListIds = (listIds, list) => {
  if (!Boolean(listIds.length) || !Boolean(Object.keys(list).length)) return [];
  return [...listIds].sort((a, b) => {
    if (!Boolean(list[a].displayName)) return -1;
    if (!Boolean(list[b].displayName)) return 1;

    if (list[a].displayName.toLowerCase() < list[b].displayName.toLowerCase()) {
      return -1;
    }
    if (list[a].displayName.toLowerCase() > list[b].displayName.toLowerCase()) {
      return 1;
    }

    return 0;
  });
};

function isArrayInArray(arr, item) {
  var item_as_string = JSON.stringify(item);

  var contains = arr.some(function(ele) {
    return JSON.stringify(ele) === item_as_string;
  });
  return contains;
}

export default useSliderContainer;
