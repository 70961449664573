// accepts XXX.XX
export const measurementsValidation = value =>
  value && !/^\d{1,3}(\.\d{1,2})?$/.test(value)
    ? 'Invalid Format. e.g., 100.99'
    : undefined;

// accepts XXXX.XX
export const weightValidation = value =>
  value && !/^\d{1,4}(\.\d{1,2})?$/.test(value)
    ? 'Invalid Format. e.g., 1000.99'
    : undefined;

// accepts 1.00 to 100
export const percentageValidation = value =>
  value && !/^\d{1,2}(\.\d{1,2})?$/.test(value)
    ? 'Invalid Format. e.g., 47.50,'
    : undefined;

export const totalSleepValidation = value =>
  value && value > 24
    ? 'Must be less than 24'
    : value < 0
    ? 'Must be a positive number'
    : undefined;

export const maxLength = max => value =>
  value && value.length > max
    ? `${max} character limit. Please revise.`
    : undefined;

export const isPositive = value =>
  parseInt(value) < 0 ? `Please enter a positive number` : undefined;

export const required = value => (value ? undefined : 'Required');
