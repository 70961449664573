import React, { useEffect } from 'react';
import useDataPoints from '../useDataPoints';
import styles from '../styles.css';
import { DATERANGE } from '../../ChartWrapper';
import { format, isWithinInterval } from 'date-fns';
import LineChart from '../../LineChart';
import useNetCalories from './useNetCalories';
import DividedLine from 'semiotic/lib/DividedLine';
import Tooltip from '../Tooltip';
import { getAverage } from '../';

const formatByDay = e => `${e.getMonth() + 1}/${e.getDate()}`;
const formatByMonthName = e => format(e, 'MMMM');
const formatByMonthShort = e => format(e, 'MMM');

const ANNOTATION_CONSTANTS = {
  mealCalories: {
    backgroundColor: '#2AA5A2',
    title: 'In',
    color: '#fff',
  },
  averageMealCalories: {
    backgroundColor: '#2AA5A2',
    title: 'In',
    color: '#fff',
  },
  totalCalories: {
    backgroundColor: '#BCDEF3',
    title: 'Net',
    color: '#0068A8',
  },
  exerciseCalories: {
    backgroundColor: '#F65858',
    title: 'Out',
    color: '#fff',
  },
  averageExerciseCalories: {
    backgroundColor: '#F65858',
    title: 'Out',
    color: '#fff',
  },
};

const CaloriesChart = props => {
  const {
    coordinates,
    dateExtent,
    tickValues,
    dateRange,
    setAnnotations,
    hasData = true,
    mode,
    matches,
  } = props;
  const netData = useNetCalories(coordinates, tickValues, dateRange);
  const shouldDisplayAverage = [
    DATERANGE.ONE_YEAR,
    DATERANGE.SIX_MONTHS,
  ].includes(dateRange);
  const dataKeys = shouldDisplayAverage
    ? ['averageTotalCalories', 'averageMealCalories', 'averageExerciseCalories']
    : ['totalCalories', 'mealCalories', 'exerciseCalories'];
  const yAccessorKeys = shouldDisplayAverage
    ? function(e) {
        return [
          e.averageTotalCalories,
          e.averageExerciseCalories,
          e.averageMealCalories,
        ];
      }
    : function(e) {
        return [e.totalCalories, e.exerciseCalories, e.mealCalories];
      };
  const { upperLimit, lowerLimit } = useDataPoints(
    netData.filter(item =>
      isWithinInterval(item.date, { start: dateExtent[0], end: dateExtent[1] })
    ),
    dataKeys,
    dateExtent
  );

  function getAnnotations() {
    if (netData.length === 0) return;
    const annot = getAverage(
      netData.filter(item =>
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ),
      dataKeys
    );
    setAnnotations(
      annot.map(item => {
        return {
          value: item.average,
          ...ANNOTATION_CONSTANTS[item.title],
        };
      })
    );
  }

  useEffect(() => {
    getAnnotations();
  }, [netData, dateExtent]);

  const barWidth =
    dateRange === DATERANGE.ONE_YEAR
      ? 7
      : dateRange === DATERANGE.THREE_MONTHS
      ? 10
      : 15;
  const frameProps = {
    lines: [{ coordinates: hasData ? netData : [] }],
    points: hasData ? netData : [],
    xAccessor: 'date',
    yAccessor: yAccessorKeys,
    yExtent: [lowerLimit, upperLimit],
    xExtent: dateExtent,
    axes: [
      {
        orient: 'left',
        className: styles.axes,
        baseline: false,
        label: 'kcal',
      },
      {
        orient: 'bottom',
        baseline: false,
        tickFormat: function(e) {
          if (
            dateRange === DATERANGE.ONE_WEEK ||
            dateRange === DATERANGE.ONE_MONTH
          ) {
            return formatByDay(e);
          }
          if (
            dateRange === DATERANGE.THREE_MONTHS ||
            dateRange === DATERANGE.SIX_MONTHS
          ) {
            return formatByMonthName(e);
          }
          return formatByMonthShort(e);
        },
        className: styles.axes,
        tickValues,
      },
    ],
    customLineMark: ({ d, i, xScale, yScale }) => {
      return (
        <DividedLine
          key={`threshold-${i}`}
          data={[d]}
          parameters={p => {
            return {
              stroke: '#ffffff',
              fill: 'none',
              strokeWidth: '2px',
              zIndex: 9999,
            };
          }}
          customAccessors={{
            x: d => xScale(d.date),
            y: d => yScale(d.totalCalories),
          }}
          lineDataAccessor={d => d.data}
        />
      );
    },
    renderOrder: [
      'pieces',
      'summaries',
      'connectors',
      'edges',
      'nodes',
      'areas',
      'points',
      'lines',
    ],
    customPointMark: ({ d, xy, yScale }) => {
      const middle = yScale(xy.yMiddle);
      const mealY =
        yScale(shouldDisplayAverage ? d.averageMealCalories : d.mealCalories) -
        middle;
      const actY =
        yScale(
          shouldDisplayAverage ? d.averageExerciseCalories : d.exerciseCalories
        ) - middle;
      const middleY = yScale(0) - middle;
      return (
        <g key={`${d.mealCalories}-customPoint`}>
          <rect
            width={barWidth}
            x={-barWidth / 2}
            height={Math.abs(mealY - middleY)}
            y={Math.min(mealY, middleY)}
            fill="#2AA5A2"
            className={styles.rect}
          />
          <rect
            width={barWidth}
            x={-barWidth / 2}
            height={Math.abs(actY - middleY)}
            y={Math.min(actY, middleY)}
            fill="#F65858"
            className={styles.rect}
          />
        </g>
      );
    },
    annotations: [
      {
        type: 'y',
        y: 0,
        connector: { end: 'none' },
      },
    ],
    svgAnnotationRules: annotProps => {
      const { d, yScale } = annotProps;
      return (
        <g key={`${d}-annotation`}>
          <line
            y1={yScale(0)}
            y2={yScale(0)}
            x2={annotProps.adjustedSize[0]}
            stroke="#C4C4C4"
            strokeWidth="4"
            opacity=".3"
          />
        </g>
      );
    },
    hoverAnnotation: true,
    tooltipContent: d => {
      return (
        <Tooltip
          title="Calories"
          title={shouldDisplayAverage ? 'Weekly Average' : 'Total Daily'}
          d={d}
          color="#ffffff"
          keys={[
            {
              tag: 'Net Calories',
              unit: 'kcal',
              value: shouldDisplayAverage
                ? d.data.averageTotalCalories
                : d.data.totalCalories,
              color: '#FFFFFF',
            },
            {
              tag: 'In',
              unit: 'kcal',
              value: shouldDisplayAverage
                ? d.data.averageMealCalories
                : d.data.mealCalories,
              color: '#2AA5A2',
            },
            {
              tag: 'Out',
              unit: 'kcal',
              value: Math.abs(
                shouldDisplayAverage
                  ? d.data.averageExerciseCalories
                  : d.data.exerciseCalories
              ),
              color: '#F65858',
            },
          ]}
        />
      );
    },
  };

  return <LineChart mode={mode} hasData={hasData} frameProps={frameProps} />;
};

export default CaloriesChart;
