import { REACTIONS_REQUEST, REACTIONS_SUCCESS, REACTIONS_FAILURE } from './actions';

const initialState = {
  isFetchingReactions: true,
  fetchFailed: false,
  reactions: []
};

export default (state = initialState, action) => {

  // Short circuit if attempting to request again when a request is 
  // already being made. 
  if (action.type === REACTIONS_REQUEST && state.isFetchingReactions) {
    return state;
  }

  switch(action.type) {

    case REACTIONS_REQUEST:
      return {
        ...state,
        isFetchingReactions: true,
      }

    case REACTIONS_SUCCESS:  
      return {
        ...state,
        isFetchingReactions: false,
        fetchFailed: false,
        reactions: action.payload,
      }
    
    case REACTIONS_FAILURE:
      return {
        ...state,
        reactions: [],
        fetchFailed: true,
        isFetchingReactions: false,
      }

    default: 
      return state;
  }
}