import React from 'react';
import { renderRoutes } from 'react-router-config';
import App from './pages/App';
import SignInPage from './pages/SignInPage';
import MemberRegistrationPage from './pages/MemberRegistrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ForgotPasswordPage';

import DashboardPage from './pages/DashboardPage';
import DashboardContent from './components/DashboardContent';
import SignUpDependent from './pages/SignUpDependentPage';
import DashboardHomePage from './pages/DashboardHomePage';
import MyHealthRecordPage from './pages/MyHealthRecordPage';
import AllergyPage from './pages/AllergyPage';
// import { ModifyPage as AllergyModifyPage } from './pages/AllergiesReactions';
import ImmunizationPage from './pages/ImmunizationPage';
import LabsPage from './pages/Labs';
// import CreateUpdateImmunization from './pages/ImmunizationPage/CreateUpdateImmunization';
import InsurancePage from './pages/InsurancePolicyPage';
import FamilyHistoryPage from './pages/FamilyHistory/MedicalConditionsPage';
import AccountPage from './pages/AccountPage';
import EmergencyContactPage from './pages/EmergencyContactPage/EmergencyContactPage';
import EmergencyContactModifyPage from './pages/EmergencyContactPage/EmergencyContactModifyPage';
import BasicInformationPage from './pages/UserBasicInformation/BasicInformationPage';
import DependentsPage from './pages/DependentsPage';
import JournalPage from './pages/JournalPage';
import UserAgreements from './pages/UserAgreement/UserAgreement';
import InsuranceInformationPage from './pages/InsurancePolicyPage/InsuranceInformation';
import InsurancePolicyPageAddUpdated from './pages/InsurancePolicyPage/InsurancePolicyPageAddUpdated';
import SecurityPage from './pages/SecurityPage';
import ResourceLibraryPage from './pages/ResourceLibrary';

// Request Medical History Page
import MedicalRecordPage from './pages/MedicalRecordPage/MedicalRecordPage';

import AccountManagement from './pages/AccountManagement';
import DocumentsPage from './pages/DocumentsPage';
import SyncPermissionsPage from './pages/SyncPermissionsPage';
import ChartsPage from './pages/ChartsPage';
import {
  MainPage as MvsPage,
  // ModifyPage as MvsModifyPage,
} from './pages/MVSPages';

import {
  MainPage as PortalsPage,
  ModifyPage as PortalModifyPage,
} from './pages/PortalsPages';

import AccountInitializePage from './pages/AccountInitializePage';
import AccountActivatedPage from './pages/AccountActivatedPage';
import HelpPage from './pages/HelpPage';
import HelpRequestPage from './pages/HelpPage/HelpRequestPage';
import SignUpDependentPage from './pages/SignUpDependentPage';

import Root from './pages/Root';
import CareTeamPage from './pages/CareTeamPage/CareTeamPage';
import NotificationsHistoryPage from './pages/Notifications';
import {
  // AddSurgeryPage,
  SurgicalHistoryPage,
} from './pages/SurgicalHistoryPage';
// import ViewSurgeryPage from './pages/SurgicalHistoryPage/ViewSurgeryPage';
import { ConditionHistoryPage } from './pages/ConditionsPage';
import ViewConditionPage from './pages/ConditionsPage/ViewConditionPage';
import CloseTabPage from './pages/CloseTabPage';
import HealthStatus from './pages/HealthStatus';
import FamilyAccountsPage from './pages/FamilyAccountsPage';

const dashboardRoutes = [
  {
    ...DashboardPage,
    path: '/d/:user',
    routes: [
      {
        ...FamilyAccountsPage,
        path: '/d/dependents/signup',
        exact: true,
      },
      {
        ...DashboardHomePage,
        path: '/d/:user/home',
      },
      {
        ...JournalPage,
        path: '/d/:user/journal',
      },
      {
        ...DependentsPage,
        path: '/d/dependents',
        exact: true,
      },
      {
        ...AccountPage,
        path: '/d/:user/account',
        exact: true,
      },
      {
        ...BasicInformationPage,
        path: '/d/:user/account/info',
        exact: true,
      },
      {
        ...UserAgreements,
        path: '/d/account/userAgreement',
        exact: true,
      },
      {
        ...AccountManagement,
        path: '/d/:user/account/account-management',
        exact: true,
      },
      {
        ...UserAgreements,
        path: '/d/:user/account/userAgreement',
        exact: true,
      },
      {
        ...EmergencyContactPage,
        path: '/d/:user/account/emergency-contact',
        exact: true,
      },
      {
        ...EmergencyContactModifyPage,
        path: '/d/:user/account/emergency-contact/:action(add|edit)',
      },
      {
        ...InsuranceInformationPage,
        path: '/d/:user/account/insurance',
        exact: true,
      },
      {
        ...InsurancePolicyPageAddUpdated,
        path: '/d/:user/account/insurance/:action(add|edit)',
      },
      {
        ...SecurityPage,
        path: '/d/:user/account/security',
      },
      {
        ...MyHealthRecordPage,
        path: '/d/:user/health-record',
        exact: true,
      },
      // Request Medical History Page
      {
        ...MedicalRecordPage,
        path: '/d/:user/health-record/request-medical-history',
        exact: true,
      },
      {
        ...HealthStatus,
        path: '/d/:user/health-status',
        exact: true,
      },
      {
        ...AllergyPage,
        path: '/d/:user/health-record/allergy',
        exact: true,
      },
      // {
      //   ...AllergyModifyPage,
      //   path: '/d/:user/health-record/allergy/:action(add|edit)',
      //   exact: true,
      // },
      {
        ...ImmunizationPage,
        path: '/d/:user/health-record/immunization',
        exact: true,
      },
      {
        ...LabsPage,
        path: '/d/:user/health-record/labs',
        exact: true,
      },
      // {
      //   ...CreateUpdateImmunization,
      //   path: '/d/:user/health-record/immunization/:action(add|edit)',
      // },
      {
        ...MvsPage,
        path: '/d/:user/health-record/mvs',
        exact: true,
      },
      // {
      //   ...MvsModifyPage,
      //   path: '/d/:user/health-record/mvs/:action(add|edit)',
      // },
      {
        ...FamilyHistoryPage,
        path: '/d/:user/health-record/family-history',
      },
      {
        ...ConditionHistoryPage,
        path: '/d/:user/health-record/conditions-diagnoses',
        exact: true,
      },
      {
        ...ViewConditionPage,
        path: '/d/:user/health-record/conditions-diagnoses/:conditionsId',
        exact: true,
      },
      {
        ...SurgicalHistoryPage,
        path: '/d/:user/health-record/surgical-history',
        exact: true,
      },
      // {
      //   ...AddSurgeryPage,
      //   path: '/d/:user/health-record/surgical-history/:action(add|edit)',
      // },
      // {
      //   ...ViewSurgeryPage,
      //   path: '/d/:user/health-record/surgical-history/:surgicalHistoryId',
      // },
      {
        ...DocumentsPage,
        path: '/d/:user/health-record/documents',
      },
      {
        ...ChartsPage,
        path: '/d/:user/charts',
      },
      {
        ...PortalsPage,
        path: '/d/:user/portals',
        exact: true,
      },
      {
        ...PortalModifyPage,
        path: '/d/:user/portals/:action(add|edit)',
      },
      {
        ...HelpPage,
        path: '/d/help',
        exact: true,
      },
      {
        ...HelpRequestPage,
        path: '/d/:user/help/request',
      },
      {
        ...CareTeamPage,
        path: '/d/:user/care-team',
      },
      {
        ...ResourceLibraryPage,
        path: '/d/:user/resource-library',
      },
      {
        ...NotificationsHistoryPage,
        path: '/d/:user/history',
        exact: true,
      },
      {
        ...SyncPermissionsPage,
        path: '/d/:user/sync-permissions',
      },
    ],
  },
];

export default [
  {
    component: Root,
    routes: [
      {
        ...CloseTabPage,
        path: '/authorized/sync-permissions',
      },
      {
        ...AccountInitializePage,
        path: '/account/initialize/',
        exact: true,
      },
      {
        ...AccountActivatedPage,
        path: '/account/activated',
        exact: true,
      },
      {
        ...SignInPage,
        path: '/',
        exact: true,
      },
      {
        ...ForgotPasswordPage,
        path: '/account/forgot-password',
        exact: true,
      },
      {
        ...ResetPasswordPage,
        path: '/account/reset-password/:token',
        exact: true,
      },
      {
        ...MemberRegistrationPage,
        path: '/registration',
      },
      {
        component: App,
        routes: dashboardRoutes,
      },
    ],
  },
];
