import React, { Component } from 'react';
import { Button, Grid, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
// import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
import InputField from '../../../../library/forms/InputField';
import { mask } from '../../../../library/helpers/format';
import FieldGroupHeader from '../../../components/Form/FieldGroupHeader';
import FormPanel from '../../../components/Form/FormPanel';
// eslint-disable-next-line
import asyncValidateForm from './asyncValidate';
import validate from './validate';
import { apiPost } from '../../../../library/helpers/fetch';
import { DashboardMessage } from '../../../components/DashboardContent';
import ProgressButtonLinear from '../../../components/Buttons/ProgressButtonLinear';

const formName = 'member-signup';

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  primaryButton: {
    color: '#fff',
  },
  icon: {
    fontSize: theme.spacing(2) + 2,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  info: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderLeft: `solid 4px ${theme.palette.primary.main}`,
  },
  successContainer: {
    minHeight: 300,
  },
  successIcon: {
    color: theme.palette.secondary.main,
    fontSize: 64,
  },
  successHeader: {
    fontSize: 24,
    fontWeight: 500,
  },
  successSubHeader: {
    fontSize: 18,
    color: '#75747B',
  },
  container: {
    minHeight: 'calc(100vh - 62.28px)',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    display: 'flex',
  },
});

const noop = () => {};

class VerifyCodeForm extends Component {
  state = {
    isVisible: false,
    iframeUrl: '',
  };

  componentDidMount() {
    this.formRef = React.createRef();
  }

  onChange = (event, value) => {
    if (value.length > 6) {
      this.formRef.current.dispatchEvent(new Event('submit'));
    }
  };

  render() {
    const {
      submitting,
      pristine,
      valid,
      handleSubmit,
      classes,
      successMessage,
      failureMessage,
      isRequestingNewCode,
      onRequestNewCode,
    } = this.props;

    return (
      <div className={classes.container}>
        <FormPanel
          ref={this.formRef}
          onSubmit={handleSubmit}
          formSlot={() =>
            this.props.emailVerified ? (
              <VerificationSuccess classes={classes} />
            ) : (
              <React.Fragment>
                <h2>Verify Your Email</h2>
                <FieldGroupHeader headerText="Enter Verification Code" />
                <p>
                  We want to make sure you have access to the email account
                  entered. To verify your access, please enter the verification
                  code sent to <strong>"{this.props.email}"</strong>
                </p>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="verificationCode"
                      label="Verification Code"
                      type="text"
                      hint="Required"
                      autoComplete="off"
                      format={value => mask(value, 'XXX-XXX', true)}
                      normalize={value =>
                        value ? value.replace('-', '') : value
                      }
                      disabled={this.props.emailVerified}
                      inputProps={{ maxLength: 7 }}
                      component={InputField}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
                <div style={{ marginTop: 16, cursor: 'pointer' }}>
                  <a onClick={isRequestingNewCode ? noop : onRequestNewCode}>
                    {isRequestingNewCode
                      ? 'Requesting new code...'
                      : 'Request new code verification code'}
                  </a>
                  <div style={{ marginTop: 16 }}>
                    {successMessage && (
                      <DashboardMessage type="info" canClose={false}>
                        {successMessage}
                      </DashboardMessage>
                    )}
                    {failureMessage && (
                      <DashboardMessage type="warning" canClose={false}>
                        {failureMessage}
                      </DashboardMessage>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )
          }
          bottomLeftSlot={() => (
            <React.Fragment>
              <p></p>
            </React.Fragment>
          )}
          bottomRightSlot={() => (
            <Grid container item spacing={3} justify="flex-end">
              <ProgressButtonLinear
                type="submit"
                color="secondary"
                disabled={pristine || submitting || isRequestingNewCode}
                isProgressing={submitting || isRequestingNewCode}
                fullWidth
              >
                {submitting || isRequestingNewCode ? 'Verifying...' : 'Verify'}
              </ProgressButtonLinear>
            </Grid>
          )}
        />
      </div>
    );
  }
}

const VerificationSuccess = ({ classes }) => (
  <Grid
    className={classes.successContainer}
    container
    justify="center"
    alignItems="center"
    direction="column"
  >
    <Icon className={classes.successIcon}>check_circle</Icon>

    <Typography className={classes.successHeader} variant="body1">
      Verification Successful!
    </Typography>
    <Typography className={classes.successSubHeader} variant="body2">
      Select account type
    </Typography>
  </Grid>
);

VerifyCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
  form: formName,
  validate,
  persistentSubmitErrors: true,
})(VerifyCodeForm);

export default withStyles(styles)(form);
