import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import classes from './main.css';

const TextDivider = ({ startText, endText }) => {
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography variant="h5">{startText}</Typography>
      </div>
      <Typography>{endText}</Typography>
    </div>
  );
};

TextDivider.defaultProps = {
  startText: PropTypes.string.isRequired,
};

export default TextDivider;
