import React, { Component, useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import InputField from '../../../library/forms/InputField';
import FileDownload from '@material-ui/icons/GetApp';
import { Field, reduxForm } from 'redux-form';
import styles from './MediaObjectDrawer.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns';
import Image from '../../components/Image';
import Video from '../../components/Video';

import {
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
  MIME_TYPE_IMAGE_WEBP,
  MIME_TYPE_VIDEO_MP4,
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_APPLICATION_WORD,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_EXCEL,
  MIME_TYPE_APPLICATION_XLSX,
  MIME_TYPE_TEXT_PLAIN,
  MIME_TYPE_TEXT_CSV,
  MIME_TYPE_TEXT_PSV,
  MIME_TYPE_TEXT_TSV,
} from '../../../library/helpers/mimeTypes';
import ProgressButtonLinear from '../Buttons/ProgressButtonLinear';

import { getBestTargetImageFormat } from '../../components/MediaObject/helpers';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';

import { WordIcon, PdfIcon, OtherIcon } from './MediaObjectSvgIcons';

const getMediaType = mimeType => {
  switch (mimeType.toLowerCase()) {
    case MIME_TYPE_APPLICATION_PDF:
      return 'PDF';

    case MIME_TYPE_APPLICATION_WORD:
    case MIME_TYPE_APPLICATION_DOCX:
      return 'WORD';

    case MIME_TYPE_APPLICATION_EXCEL:
    case MIME_TYPE_APPLICATION_XLSX:
      return 'EXCEL';

    case MIME_TYPE_VIDEO_MP4:
      return 'VIDEO';

    case MIME_TYPE_IMAGE_JPG:
    case MIME_TYPE_IMAGE_JPEG:
    case MIME_TYPE_IMAGE_PNG:
    case MIME_TYPE_IMAGE_WEBP:
      return 'IMAGE';

    case MIME_TYPE_TEXT_PLAIN:
    case MIME_TYPE_TEXT_CSV:
    case MIME_TYPE_TEXT_PSV:
    case MIME_TYPE_TEXT_TSV:
      return 'TEXT';

    default:
      throw Error(`Mime type "${mimeType}" not supported`);
  }
};

const noop = () => {};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');

  tempLink.href = url;
  tempLink.download = filename;

  // Prevent potential memory leak
  // Cleanup objectURL resources that are no longer in use.
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      tempLink.removeEventListener('click', clickHandler);
      document.body.removeChild(tempLink);
    }, 123);
  };

  tempLink.addEventListener('click', clickHandler, false);

  document.body.appendChild(tempLink);

  tempLink.click();
};

const PreviewObject = ({
  personId,
  currentMediaFile,
  mediaType,
  mode,
  dialogMode,
}) => {
  mode = mode.toUpperCase();

  const [mediaFile, setMediaFile] = useState(currentMediaFile);
  const [mediaLink, setMediaLink] = useState(null);

  useEffect(() => {
    if (mediaType === 'IMAGE') {
      const format = getBestTargetImageFormat(currentMediaFile.formats, 'MD', [
        'XS',
      ]);
      setMediaLink(currentMediaFile.links[format]);
    }

    setMediaFile(currentMediaFile);
  }, [currentMediaFile]);

  if (mediaType === 'IMAGE')
    return (
      <Grid item md={12} className={styles.mBottom}>
        <div
          className={styles.mediaImageBlock}
          onClick={
            mode === 'EDIT'
              ? noop()
              : () =>
                  dialogMode(true, 'iframe', mediaFile.links.self, mediaFile)
          }
        >
          <div className={styles.responsive}>
            <Image
              personId={personId}
              placeholder={mediaFile.base64DataUriString}
              src={mediaLink}
              className={styles.imgCover}
              height="100%"
              alt="Media Image"
            />
          </div>
        </div>
      </Grid>
    );

  if (mediaType === 'VIDEO')
    return (
      <Grid item md={12} className={styles.mBottom}>
        <Video
          controls={true}
          personId={personId}
          src={currentMediaFile.links.self}
        />
      </Grid>
    );

  if (mediaType === 'PDF')
    return (
      <Grid item sm={12} md={4} className={styles.mBottom}>
        <div
          onClick={
            mode === 'EDIT'
              ? noop()
              : () =>
                  dialogMode(
                    true,
                    'iframe',
                    currentMediaFile.fileName,
                    currentMediaFile
                  )
          }
        >
          <div className={styles.iconViewer}>
            <PdfIcon />
          </div>
        </div>
      </Grid>
    );

  if (mediaType === 'WORD')
    return (
      <Grid item sm={12} md={4} className={styles.mBottom}>
        <div className={styles.iconViewer}>
          <WordIcon />
        </div>
      </Grid>
    );

  if (mediaType === 'EXCEL')
    return (
      <Grid item sm={12} md={4} className={styles.mBottom}>
        <div className={styles.iconViewer}>
          <OtherIcon />
        </div>
      </Grid>
    );

  return (
    <Grid item sm={12} md={4} className={styles.mBottom}>
      <div className={styles.iconViewer}>
        <OtherIcon />
      </div>
    </Grid>
  );
};

const MediaObjectView = ({
  personId,
  currentMediaFile,
  onClickCancel,
  onClickEdit,
  viewMode,
  dialogMode,
  isSubmitDisabled,
  isSubmitting,
}) => {
  const mediaType = getMediaType(currentMediaFile.mimeType);
  const showDownloadOption = true;
  const [isDownloading, setIsDownloading] = useState(false);

  const requestControllerRef = useRef();

  useEffect(() => {
    requestControllerRef.current = new AbortController();

    () => requestControllerRef.current.abort();
  }, []);

  const onClickDownload = async () => {
    if (isDownloading) return noop();

    setIsDownloading(true);

    const response = await apiFetchAuthorized(
      currentMediaFile.links.download,
      {
        signal: requestControllerRef.current.signal,
      },
      false
    );

    const blob = await response.blob();
    downloadBlob(blob, currentMediaFile.fileName);

    setIsDownloading(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <PreviewObject
          personId={personId}
          currentMediaFile={currentMediaFile}
          dialogMode={dialogMode}
          mediaType={mediaType}
          mode={viewMode}
        />
        {viewMode === 'EDIT' ? (
          <React.Fragment>
            <Grid item md={12}>
              <h3 className={styles.editMode}>{currentMediaFile.fileName}</h3>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  type="date"
                  label="Date"
                  name="newEnteredOnDate"
                  max="2999-12-31"
                  shrink
                  component={InputField}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  type="time"
                  label="Time"
                  name="newEnteredOnTime"
                  shrink
                  component={InputField}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <Field
                name="description"
                label="Notes"
                multiline
                rows={4}
                component={InputField}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Button
                type="submit"
                color="default"
                variant="contained"
                size="large"
                onClick={onClickCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm={12} md={6} align="right">
              <ProgressButtonLinear
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                disabled={isSubmitDisabled}
                isProgressing={isSubmitting}
              >
                Update
              </ProgressButtonLinear>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid
              item
              md={12}
              className={`${styles.mBottom} ${styles.mediaDetailsBlock}`}
            >
              <h3>
                <Tooltip title="Download File">
                  {showDownloadOption && (
                    <a onClick={onClickDownload}>
                      <FileDownload className={styles.downloadIcon} />
                    </a>
                  )}
                </Tooltip>
                {currentMediaFile.fileName}
              </h3>
              <span>
                {format(
                  parseISO(currentMediaFile.enteredOn),
                  'MMMM do yyyy, h:mm:ss a'
                )}
              </span>
              <p>{currentMediaFile.description}</p>
            </Grid>
            <Grid item sm={12} md={6}>
              <Button
                type="submit"
                color="default"
                variant="contained"
                size="large"
                onClick={() => dialogMode(true, 'delete', '', currentMediaFile)}
              >
                Delete
              </Button>
            </Grid>
            <Grid item sm={12} md={6} align="right">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                onClick={onClickEdit}
              >
                Edit
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
};

class MediaEditMode extends Component {
  render() {
    // props value which  is came from its parent component
    // #currentMediaFile contains current selected media object from the list of media object
    const {
      personId,
      handleSubmit,
      currentMediaFile,
      onClickCancel,
      onClickEdit,
      pristine,
      submitting,
      dialogMode,
      viewMode,
      onSubmitUpdate,
      isSubmitting,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmitUpdate)}>
        <MediaObjectView
          personId={personId}
          currentMediaFile={currentMediaFile}
          onClickCancel={onClickCancel}
          onClickEdit={onClickEdit}
          isSubmitDisabled={pristine || isSubmitting}
          isSubmitting={isSubmitting}
          dialogMode={dialogMode}
          viewMode={viewMode}
        />
      </form>
    );
  }
}

// initialize specific redux form with current routing component
const MediaInfoDetailReduxForm = reduxForm({
  form: 'media-viewer-form',
  enableReinitialize: true,
})(withRouter(MediaEditMode));

// redux connector which supplies the from fields value in specified form defined with reduxFrom
export default connect((_, props) => {
  return {
    initialValues: {
      mediaType: props.currentMediaFile.mimeType,
      index: props.pageIndex,
      personId: props.personId,
      mediaObjectId: props.currentMediaFile.mediaObjectId,
      objectId: props.primaryId,
      description:
        props.currentMediaFile.description &&
        props.currentMediaFile.description,
      newEnteredOnDate:
        props.currentMediaFile.enteredOn &&
        format(parseISO(props.currentMediaFile.enteredOn), 'yyyy-MM-dd'),
      newEnteredOnTime:
        props.currentMediaFile.enteredOn &&
        format(parseISO(props.currentMediaFile.enteredOn), 'HH:mm'),
      enteredOn:
        props.currentMediaFile.enteredOn &&
        new Date(props.currentMediaFile.enteredOn),
    },
  };
})(MediaInfoDetailReduxForm);
