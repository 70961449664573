import React, { useState } from 'react';

import ChartWrapper from '../ChartWrapper';
import WeightChart from './WeightChart';
import BloodPressureChart from './BloodPressureChart';
import CaloriesChart from './CaloriesChart';
import SleepChart from './SleepChart';
import WelcomeComponent from './WelcomeComponent';

import WaterChart from './WaterChart';
import { dataFragments } from '../../../pages/ChartsPage';

import { subYears } from 'date-fns';
const defaultPastDate = subYears(new Date(), 10);

const Charts = ({ data, selectedChart = '' }) => {
  const [
    FRAGMENT_BODY_MASS,
    FRAGMENT_BLOOD_PRESSURE,
    FRAGMENT_WATER_INTAKE,
    FRAGMENT_SLEEP_TRACKER,
    CALORIES_TRACKER,
  ] = dataFragments;

  const addLineChartExtension = () => {
    if (!selectedChart) return data;
    const _data = data[selectedChart].data.sort((a, b) => b.date - a.date);
    if (selectedChart !== CALORIES_TRACKER) {
      if (_data.length) {
        return [
          ..._data,
          { ..._data[_data.length - 1], date: defaultPastDate },
        ];
      } else return _data;
    } else return _data;
  };
  return selectedChart.length ? (
    <div>
      <ChartWrapper
        data={addLineChartExtension()}
        selectedChart={selectedChart}
      >
        {selectedChart === FRAGMENT_BODY_MASS ? (
          <WeightChart />
        ) : selectedChart === FRAGMENT_BLOOD_PRESSURE ? (
          <BloodPressureChart />
        ) : selectedChart === FRAGMENT_WATER_INTAKE ? (
          <WaterChart />
        ) : selectedChart === CALORIES_TRACKER ? (
          <CaloriesChart />
        ) : selectedChart === FRAGMENT_SLEEP_TRACKER ? (
          <SleepChart />
        ) : (
          ''
        )}
      </ChartWrapper>
    </div>
  ) : (
    <WelcomeComponent />
  );
};

export default Charts;
