import React from 'react';
import { connect } from 'react-redux';
import AttachmentsCompose from '../../../containers/AttachmentsCompose';
import DrawerAccordion from '~/src/shared/components/DrawerAccordion/DrawerAccordion';
import {
  updateMedia,
  deleteMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import { MVS } from '../../../../store/reducers/mediaObject';

const AttachmentPanel = props => {
  return (
    <DrawerAccordion summaryTitle="Attachments">
      <AttachmentsCompose
        appArea={MVS}
        isSubmitting={props.isUploadingFile}
        goBack={() => {}}
        mediaObjects={props.mediaObjects}
        bundlesToDelete={props.bundlesToDelete}
      />
    </DrawerAccordion>
  );
};

const mapStateToProps = state => ({
  bundlesToDelete: state.member.medication.bundlesToDelete,
  mediaObjects: state.mediaObject.bundles,
  isUploadingFile: state.mediaObject.isUploadingFiles,
  mediaObjects: !state.member.medication.selectedId
    ? []
    : state.member.medication.list[state.member.medication.selectedId]
        .mediaObjects,
});

const mapDispatchToProps = dispatch => ({
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  deleteMedia: (pid, aid, appArea, referenceId) =>
    dispatch(deleteMedia(pid, aid, appArea, referenceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentPanel);
