import {
  getKeys,
  arrayToHashMap,
  csvToCodeObjectList,
} from '../../../library/helpers/utils';

import {
  CONDITIONS_REQUEST,
  CONDITIONS_SUCCESS,
  CONDITIONS_FAILURE,
  CONDITIONS_SINGLE_REQUEST,
  CONDITIONS_SINGLE_SUCCESS,
  CONDITIONS_SINGLE_FAILURE,
  CONDITIONS_CREATE_REQUEST,
  CONDITIONS_CREATE_SUCCESS,
  CONDITIONS_CREATE_FAILURE,
  CONDITIONS_UPDATE_REQUEST,
  CONDITIONS_UPDATE_SUCCESS,
  CONDITIONS_UPDATE_FAILURE,
  CONDITIONS_DELETE_REQUEST,
  CONDITIONS_DELETE_SUCCESS,
  CONDITIONS_DELETE_FAILURE,
  CONDITIONS_UPDATE_SET_ID,
  CONDITIONS_UPDATE_CLEAR_ID,
  CONDITIONS_REMOVE_MEDIA,
  CONDITIONS_DELETE_MEDIA,
  CONDITIONS_DELETE_MEDIA_CANCEL,
  UPDATE_CONDITIONS_MEDIA,
  CONDITIONS_SUBMIT_FORM,
} from './actions';

import { SET_ACTIVE_USER } from '../active/actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
  MEDIA_OBJECT_UPLOAD_REQUEST,
} from '../mediaObject/actions';

import { CONDITIONS } from '../mediaObject';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

const initialState = {
  personId: null,
  isSubmitting: false,
  list: {},
  listIds: [],
  selectedId: null,
  uiState: LOADING,
  mediaObjects: {},
  uploadingMediaObjects: false,
  bundlesToDelete: [],
};

const reducers = {};

const getError = action => action.payload && action.payload.error;

reducers[CONDITIONS_SUBMIT_FORM] = (state, action) => {
  return {
    ...state,
    isSubmitting: true,
  };
};

reducers[UPDATE_CONDITIONS_MEDIA] = (state, action) => {
  const mediaObjects = state.mediaObjects;
  if (Boolean(action.payload.formName)) {
    mediaObjects[action.payload.formName] = action.payload.pristine;
  }
  return {
    ...state,
    mediaObjects,
  };
};

reducers[CONDITIONS_REMOVE_MEDIA] = (state, action) => {
  const mediaObjects = {
    ...mediaObjects,
  };
  delete mediaObjects[`bundle${action.payload}`];
  return {
    ...state,
    mediaObjects,
  };
};

reducers[CONDITIONS_DELETE_MEDIA] = (state, action) => {
  const _bundlesToDelete = Boolean(
    state.bundlesToDelete.find(
      item => item.mediaObjectId === action.payload.mediaObjectId
    )
  )
    ? [
        ...state.bundlesToDelete.map(item => {
          if (item.mediaObjectId === action.payload.mediaObjectId) {
            return action.payload;
          }
          return item;
        }),
      ]
    : [...state.bundlesToDelete, action.payload];

  return {
    ...state,
    bundlesToDelete: _bundlesToDelete,
  };
};

reducers[CONDITIONS_DELETE_MEDIA_CANCEL] = (state, action) => {
  return {
    ...state,
    bundlesToDelete: [
      ...state.bundlesToDelete.filter(
        item => item.mediaObjectId !== action.payload
      ),
    ],
  };
};

reducers[CONDITIONS_REQUEST] = (state, action) => {
  return {
    ...state,
    personId: action.meta.config.headers.Pid,
    isFetching: true,
    uiState: LOADING,
  };
};

reducers[CONDITIONS_SUCCESS] = (state, action) => {
  if (action.meta.config.headers.Pid !== state.personId) return state;
  const payload = action.payload.map(record => ({
    ...record,
  }));

  const list = arrayToHashMap('personDiagnosisId', payload);
  const listIds = getKeys('personDiagnosisId', payload);

  return {
    ...state,
    list,
    listIds,
    isFetching: false,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[CONDITIONS_FAILURE] = (state, action) => {
  return {
    ...initialState,
    personId: null,
    error: getError(action),
    isFetching: false,
    uiState: ERROR,
  };
};

reducers[CONDITIONS_SINGLE_REQUEST] = (state, action) => {
  return {
    ...state,
    personId: null,
    isFetching: true,
  };
};

reducers[CONDITIONS_SINGLE_SUCCESS] = (state, action) => {
  const list = {
    ...action.payload,
  };

  return {
    ...state,
    list,
    listIds: [payload.personDiagnosisId],
    isFetching: false,
  };
};

reducers[CONDITIONS_SINGLE_FAILURE] = (state, action) => {
  return {
    ...initialState,
    personId: null,
    error: getError(action),
  };
};

reducers[CONDITIONS_CREATE_REQUEST] = (state, action) => {
  return {
    ...state,
    errorMessage: undefined,
    list: {
      ...state.list,
      [action.meta.tid]: action.payload,
    },
    listIds: [].concat([action.meta.tid], state.listIds),
    isSubmitting: true,
  };
};

reducers[CONDITIONS_CREATE_SUCCESS] = (state, action) => {
  const entry = state.list[action.meta.tid];
  const { mediaObjects, ...remainingEntry } = entry;

  const updateRecord = {
    ...entry,
    ...action.payload,
    mediaObjects: action.bundlesToAttach,
  };

  return {
    ...state,
    errorMessage: undefined,
    didInvalidate: false,
    isSubmitting: false,
    uploadingMediaObjects: false,

    list: Object.keys(state.list).reduce((object, key) => {
      if (key === action.meta.tid) {
        return { ...object, [action.payload.personDiagnosisId]: updateRecord };
      }
      return { ...object, [key]: state.list[key] };
    }, {}),
    listIds: state.listIds.map(listId => {
      if (listId === action.meta.tid) {
        return action.payload.personDiagnosisId;
      }
      return listId;
    }),
    uiState: state.uiState === EMPTY ? SUCCESS : state.uiState,
  };
};

reducers[CONDITIONS_CREATE_FAILURE] = (state, action) => {
  // Replace code when optimistic UI pattern is fully implemented
  // const updatedRecord = {
  //   ...state.list[action.meta.tid],
  //   isCreating: false,
  //   hasFailed: true,
  // };

  // return {
  //   ...state,
  //   list: {
  //     ...state.list,
  //     [action.meta.tid]: updatedRecord,
  //   },
  //   error: getError(action),
  //   isSubmitting: false,
  // };
  const { tid } = action;

  const listIndex = state.listIds.indexOf(tid);
  const { [tid]: _, ...list } = state.list;
  const listIds = [
    ...state.listIds.slice(0, listIndex),
    ...state.listIds.slice(listIndex + 1),
  ];

  return {
    ...state,
    list,
    listIds,
    error: getError(action),
    isSubmitting: false,
  };
};

reducers[CONDITIONS_UPDATE_REQUEST] = (state, action) => {
  return {
    ...state,
    errorMessage: undefined,
    isSubmitting: true,
  };
};

reducers[CONDITIONS_UPDATE_SUCCESS] = (state, action) => {
  return {
    ...state,
    errorMessage: undefined,
    didInvalidate: true,
    isSubmitting: false,
    uploadingMediaObjects: false,
    list: {
      ...state.list,
      [state.selectedId]: {
        ...action.payload,
        mediaObjects: [
          ...state.list[state.selectedId].mediaObjects,
          ...action.bundlesToAttach,
        ],
      },
    },
    bundlesToDelete: [],
  };
};

reducers[CONDITIONS_UPDATE_FAILURE] = (state, action) => {
  const { selectedId } = state;

  const updatedRecord = {
    ...state.list[selectedId],
    isUpdating: false,
  };

  return {
    ...state,
    list: {
      [selectedId]: updatedRecord,
    },
    error: getError(action),
    isSubmitting: false,
  };
};

reducers[CONDITIONS_DELETE_REQUEST] = (state, action) => {
  const { tid } = action.meta;

  return {
    ...state,
    list: {
      ...state.list,
      [tid]: {
        ...state.list[tid],
        isDeleting: true,
      },
    },
    isSubmitting: true,
  };
};

reducers[CONDITIONS_DELETE_SUCCESS] = (state, action) => {
  const { tid } = action.meta;

  const listIndex = state.listIds.indexOf(tid);
  const { [tid]: _, ...list } = state.list;
  const listIds = [
    ...state.listIds.slice(0, listIndex),
    ...state.listIds.slice(listIndex + 1),
  ];

  return {
    ...state,
    list,
    listIds,
    isSubmitting: false,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[CONDITIONS_DELETE_FAILURE] = (state, action) => {
  const { tid } = action.meta;

  const updatedRecord = {
    ...state.list[tid],
    isDeleting: false,
    hasFailed: true,
  };

  return {
    ...state,
    list: {
      ...state.list,
      [tid]: updatedRecord,
    },
    error: getError(action),
    isSubmitting: false,
  };
};

reducers[CONDITIONS_UPDATE_SET_ID] = (state, action) => {
  return {
    ...state,
    selectedId: action.payload,
  };
};

reducers[CONDITIONS_UPDATE_CLEAR_ID] = (state, action) => {
  return {
    ...state,
    selectedId: null,
  };
};

reducers[SET_ACTIVE_USER] = (state, action) => {
  if (state.personId !== action.payload.personId) {
    return {
      ...initialState,
      personId: action.payload.personId,
    };
  }
  return state;
};

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  if (action.meta.resource !== CONDITIONS) return state;
  const resourceId = state.selectedId || action.meta.objectId;

  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  const resource = state.list[resourceId];

  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    // case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:
    //   return {
    //     ...state,
    //     list: {
    //       ...state.list,
    //       [action.meta.objectId]: {
    //         ...resource,
    //         mediaObjects: [].concat(
    //           {
    //             //TODO: currently create returns objectId as reference ID and get as id, update this function when changes to that are made on the API
    //             ...action.payload,
    //             id: action.payload.referenceId,
    //           },
    //           resource ? resource.mediaObjects : []
    //         ),
    //       },
    //     },
    //   };

    // Update the selected media object in the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE:
      return {
        ...state,
        list: {
          ...state.list,
          [resourceId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.map(updateMediaObject),
          },
        },
      };

    // Remove selected media object from the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [resourceId]: {
            ...state.list[resourceId],
            mediaObjects: state.list[resourceId].mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
      };

    default:
      return state;
  }
};

const conditionsReducer = (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;

export default conditionsReducer;
