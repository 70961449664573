import { useState, useEffect } from 'react';
import { sortOptions, status, sortByStatus } from '../index.js';
import useMapQuery from '../../../../library/hooks/useMapQuery';
import useDateQuery from './useDateQuery';

//HOOK CONTAINS TOGGLE FOR WHEN DATE FIELD IS NEEDED TO BE ADDED

const useToggleFilter = (_listIds, list, sortBy) => {
  // const { CATEGORIES, DATE, TITLE } = sortByStatus;
  // const useQuery = sortBy === DATE ? useDateQuery : useMapQuery;
  // const sortProps = {
  //   [CATEGORIES]: {
  //     listIds: _listIds,
  //     list: list,
  //     keys: ['category'],
  //   },
  //   [TITLE]: {
  //     listIds: _listIds,
  //     list: list,
  //     keys: ['title'],
  //   },
  //   [DATE]: {
  //     listIds: _listIds,
  //     list: list,
  //     keys: [],
  //   },
  // };

  return useMapQuery(_listIds, list, [
    'category',
    'displayName',
    'description',
  ]);
  //   sortProps[sortBy].listIds,
  //   sortProps[sortBy].list,
  //   sortProps[sortBy].keys
  // );
};

export default useToggleFilter;
