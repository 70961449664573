import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { format, isBefore } from 'date-fns';

import {
  REQUEST_MEDICAL_RECORD_SUCCESS,
  REQUEST_MEDICAL_RECORD_FAILURE,
  createMedicalRecordRequest,
} from '../../../store/reducers/medical-record-request';

import { fetchStateType } from '../../../store/reducers/codes/actions';

import {
  phoneValidation,
  emailValidation,
  providerZipValidation,
  maxLength,
  required,
  phoneMask,
} from './validations';
import InputField from '../../../library/forms/InputField';
import SelectField from '../../../library/forms/SelectField';
import { fetchPersonInfo } from '../../../store/reducers/person/actions';
import { mask } from '../../../library/helpers/format';

const maxLength100 = maxLength(100);
const maxLength255 = maxLength(255);

const postalCodeMask = value => mask(value, 'XXXXX-XXXX');
const isBeforeEndDate = (value, allValues) => {
  if (
    allValues.serviceEndOn &&
    isBefore(new Date(allValues.serviceEndOn), new Date(value))
  ) {
    return `The Start Date must be earlier than the End Date`;
  }
  return undefined;
};
const isAfterStartDate = (value, allValues) => {
  if (value && isBefore(new Date(), new Date(value))) {
    return `The End Date cannot be a future date`;
  }
  return undefined;
};

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
  grant: {
    marginBottom: 12,
  },
  root: {
    backgroundColor: '#EFF1F3',
    borderRadius: 4,
    padding: 18,
    marginTop: 24,
  },
  grid: {
    marginTop: 8,
  },
  loader: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
});

const MedicalRecordForm = ({
  createMedicalRecordRequest,
  basicInfo,
  activeId,
  fetchStateType,
  fetchPersonInfo,
  emailAddress,
  setCompleted,
  isValid,
  fields,
  stateTypes,
}) => {
  const classes = useStyles();
  const { userInfo: user } = basicInfo;

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    fetchStateType();
    fetchPersonInfo(activeId);
  }, [activeId]);

  const handleSubmit = async () => {
    setSubmitting(true);
    const result = await createMedicalRecordRequest(fields);
    if (result && result.type === REQUEST_MEDICAL_RECORD_SUCCESS) {
      setSubmitting(false);
      setCompleted(true);
    } else if (result && result.type === REQUEST_MEDICAL_RECORD_FAILURE) {
      console.log('SUBMIT ERROR', result);
      setSubmitting(false);
      setError(result.payload.data);
    }
  };

  const postalCodeMask = value => mask(value, 'XXXXX-XXXX');
  const postalCodeNormalizer = value => {
    // If the postal code is shorter than the long format
    // remove the dash, else preserve.
    if (value.length < 10) {
      return value.replace('-', '');
    }
    return value;
  };

  return (
    <form className={classes.root}>
      <Typography variant="body2">
        I hereby instruct Agrin Health to request medical record from the
        Provider below for the Dates of Service (time frame) specified:
      </Typography>
      {user && !basicInfo.isUpdating && !basicInfo.isFetching ? (
        <Grid className={classes.grid} container spacing={3}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              placeholder="First Name"
              label="Patient First Name"
              name="firstName"
              shrink={Boolean(user.firstName)}
              value={user.firstName}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              shrink={Boolean(user.middleName)}
              placeholder="Middle Name"
              label="Patient Middle Name"
              name="middleName"
              value={user.middleName}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              placeholder="Last Name"
              label="Patient Last Name"
              value={user.lastName}
              shrink={Boolean(user.lastName)}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              label="Patient Name Suffix"
              name="suffix"
              value={user.suffix}
              shrink={Boolean(user.suffix)}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              placeholder="SSN"
              label="Patient SSN"
              value={user.ssnPart ? `XXX-XX-${user.ssnPart}` : ''}
              shrink={Boolean(user.ssnPart)}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={2} md={3} sm={3} xs={12}>
            <InputField
              className={classes.input}
              variant="outlined"
              disabled
              placeholder="DOB"
              label="Patient DOB"
              value={format(new Date(user.dob), 'MM/dd/yyyy')}
              shrink={Boolean(user.dob)}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider Name"
              name="providerName"
              component={InputField}
              validate={[required, maxLength100]}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          {/* <Grid item sm={6} xs={12} /> */}
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider Phone"
              name="providerPhone"
              type="tel"
              placeholder="XXX-XXX-XXXX"
              component={InputField}
              validate={[required, phoneValidation]}
              format={phoneMask}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider Email"
              name="providerEmail"
              component={InputField}
              validate={[emailValidation]}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider Address"
              name="providerAddress"
              validate={[required, maxLength255]}
              component={InputField}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider City"
              name="providerCity"
              validate={[required, maxLength255]}
              component={InputField}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>

          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              name="providerState"
              variant="outlined"
              label="Provider State"
              validate={required}
              labelKey="longName"
              valueKey="shortName"
              component={SelectField}
              options={stateTypes}
              enableMinHeight={false}
              isSearchable
              whiteBG
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              label="Provider Zip"
              name="providerZip"
              format={postalCodeMask}
              normalize={postalCodeNormalizer}
              validate={[required, providerZipValidation]}
              component={InputField}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          {/* <Grid item sm={12} /> */}
          <Grid item lg={2} sm={3} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              type="date"
              label="Service Start Date"
              name="serviceStartOn"
              shrink
              validate={[required, isBeforeEndDate]}
              component={InputField}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          <Grid item lg={2} sm={3} xs={12}>
            <Field
              className={classes.input}
              variant="outlined"
              type="date"
              label="Service End Date"
              name="serviceEndOn"
              shrink
              validate={[required, isAfterStartDate]}
              component={InputField}
              enableMinHeight={false}
              whiteBG
            />
          </Grid>
          {/*
                      <Grid
                          item
                          xs={3}
                          style={{ display: 'flex', justifyContent: 'center' }}
                      >
                          <EasterEgg
                              id="3"
                              message="You found a super egg! CODE: EGGSPERTHUNTER"
                          />
                      </Grid>
                  */}
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.loader}>
          <CircularProgress color="primary" />
        </Grid>
      )}
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <Grid container style={{ marginTop: 24 }} spacing={3}>
        <Grid item xl={10} lg={9} md={9} sm={9} xs={4} />
        <Grid item xl={2} lg={3} md={3} sm={3} xs={8}>
          {user && !basicInfo.isUpdating && !basicInfo.isFetching && (
            <Button
              style={{ width: '100%' }}
              onClick={handleSubmit}
              disabled={!isValid || submitting}
              color="primary"
              variant="contained"
            >
              Submit Request
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

const codeSelector = (state, action) => state.codes[action];

const mapStateToProps = state => ({
  basicInfo: state.member.basicInfo,
  activeId: state.active.user.personId,
  emailAddress: state.member.login.details.unique_name,
  isValid: !Boolean(
    state.form &&
      state.form.requestMedicalRecordForm &&
      state.form.requestMedicalRecordForm.syncErrors
  ),
  fields:
    state.form &&
    state.form.requestMedicalRecordForm &&
    state.form.requestMedicalRecordForm.values,
  stateTypes: codeSelector(state, 'stateTypes'),
});

const mapDispatchToProps = dispatch => ({
  fetchPersonInfo: id => dispatch(fetchPersonInfo(id)),
  fetchStateType: () => dispatch(fetchStateType()),
  createMedicalRecordRequest: payload =>
    dispatch(createMedicalRecordRequest(payload)),
});

export default reduxForm({
  form: 'requestMedicalRecordForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(MedicalRecordForm));
