import React from 'react';
import { format, parseISO, formatDistanceToNow } from 'date-fns';

const toUtcDate = date =>
  String(date).indexOf('Z') === -1 ? `${date}Z` : date;

const TimeAgo = ({ timestamp }) => {
  console.log('TimeAgo', timestamp);
  const parsedTimestamp = parseISO(toUtcDate(timestamp));

  return (
    <span
      alt={format(parsedTimestamp, 'yyyy-MM-dd hh:mm:ss')}
      style={{ opacity: 0.8, letterSpacing: '-0.1px', fontSize: '85%' }}
    >
      {formatDistanceToNow(parsedTimestamp, { includeSeconds: true })} ago
    </span>
  );
};

export default TimeAgo;
