import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { range } from "./utils";

import classes from "./Calendar.css";

class YearPanel extends React.Component {
  selectYear = year => {
    this.props.onClick(year);
  };

  render() {
    const {
      minYear,
      maxYear,
      maxColumns,
      addRows,
      isAccurateRange
    } = this.props;

    let max = isAccurateRange ? maxYear : maxYear + maxColumns * addRows;
    max = isAccurateRange ? max : max + maxColumns - (max % maxColumns);
    const years = range(minYear, max).reverse();

    return (
      <div className={classes.yearPanel}>
        {years.map(year => (
          <div
            key={year}
            className={classNames({
              [classes.current]: this.props.currentYear === year
            })}
            onClick={() => this.selectYear(year)}
          >
            {year}
          </div>
        ))}
      </div>
    );
  }
}

YearPanel.propTypes = {
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number.isRequired,
  maxColumns: PropTypes.number.isRequired,
  isAccurateRange: PropTypes.bool.isRequired
};

YearPanel.defaultProps = {
  minYear: 1900,
  maxYear: 2019,
  maxColumns: 3,
  addRows: 2,
  isAccurateRange: false
};

export default YearPanel;
