import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop from 'react-image-crop';
import FileInput from '../FileInput';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogBase: {
    padding: theme.spacing(2),
    margin: 0,
    overflow: 'auto',
    '& .profile-crop img': {
      objectFit: 'contain',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
});

const ImageResizeModal = props => {
  const {
    onClose,
    onChange,
    classes,
    imageSrc,
    imageName,
    imageCrop,
    onImageSelect,
    onImageLoaded,
    onCropChange,
    onCropComplete,
    isDisplayed,
    hasImage,
    dialogTitleText,
    dialogDescriptionText,
    selectFileText,
    changeFileText,
  } = props;

  return (
    <Dialog open={isDisplayed} onClose={onClose} className="profile-image">
      <DialogTitle
        className={classes.dialogBase}
        id="customized-dialog-title"
        onClose={onClose}
      >
        {dialogTitleText}
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBase}>
        <div>
          <span>{dialogDescriptionText}</span> Use your mouse cursor to
          manipulate the boundaries of the selection box and arrow keys &nbsp;
          <i className="fa fa-arrow-left" /> &nbsp;
          <i className="fa fa-arrow-right" /> &nbsp;
          <i className="fa fa-arrow-up" /> &nbsp;
          <i className="fa fa-arrow-down" /> &nbsp; for fine tunning.
        </div>
        <FileInput
          fileName={imageName}
          name="card_image"
          onChange={onImageSelect}
          selectFileText={selectFileText}
          changeFileText={changeFileText}
          acceptedFileTypes="image/jpeg, image/png"
        />
        {hasImage && (
          <React.Fragment>
            <ReactCrop
              src={imageSrc}
              crop={imageCrop}
              ruleOfThirds
              circularCrop
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              className="profile-crop"
            />
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogBase}>
        {hasImage ? (
          <React.Fragment>
            <Button
              variant="text"
              className={classes.cancelButton}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onChange}>
              Select Image
            </Button>
          </React.Fragment>
        ) : (
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ImageResizeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  imageSrc: PropTypes.any,
  imageName: PropTypes.string.isRequired,
  imageCrop: PropTypes.object,
  onImageSelect: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  hasImage: PropTypes.bool.isRequired,
  dialogTitleText: PropTypes.string.isRequired,
  dialogDescriptionText: PropTypes.string.isRequired,
  selectFileText: PropTypes.string.isRequired,
  changeFileText: PropTypes.string.isRequired,
};

ImageResizeModal.defaultProps = {
  hasImage: false,
  isDisplayed: false,
  dialogTitleText: 'Select Image',
  dialogDescriptionText: '',
  selectFileText: 'Select Image',
  changeFileText: 'Change Image',
};

export default withStyles(styles)(ImageResizeModal);
