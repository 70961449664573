import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { createQueryStringFromObject } from '../../../library/helpers/encode';

export const EMERGENCY_CONTACTS_REQUEST = '@redux/emergency-contacts/REQUEST';
export const EMERGENCY_CONTACTS_SUCCESS = '@redux/emergency-contacts/SUCCESS';
export const EMERGENCY_CONTACTS_FAILURE = '@redux/emergency-contacts/FAILURE';

export const EMERGENCY_CONTACTS_CREATE = '@redux/emergency-contacts/CREATE';
export const EMERGENCY_CONTACTS_CREATE_SUCCESS =
  '@redux/emergency-contacts/CREATE_SUCCESS';
export const EMERGENCY_CONTACTS_CREATE_FAILURE =
  '@redux/emergency-contacts/CREATE_FAILURE';

export const EMERGENCY_CONTACTS_UPDATE = '@redux/emergency-contacts/UPDATE';
export const EMERGENCY_CONTACTS_UPDATE_SUCCESS =
  '@redux/emergency-contacts/UPDATE_SUCCESS';
export const EMERGENCY_CONTACTS_UPDATE_FAILURE =
  '@redux/emergency-contacts/UPDATE_FAILURE';

export const EMERGENCY_CONTACTS_DELETE = '@redux/emergency-contacts/DELETE';
export const EMERGENCY_CONTACTS_DELETE_SUCCESS =
  '@redux/emergency-contacts/DELETE_SUCCESS';
export const EMERGENCY_CONTACTS_DELETE_FAILURE =
  '@redux/emergency-contacts/DELETE_FAILURE';

export const EMERGENCY_CONTACT_GET = '@redux/emergency-contacts/GET';
export const EMERGENCY_CONTACT_CLEAR = '@redux/emergency-contacts/CLEAR';

export const getEmergencyContactToUpdate = contact => ({
  type: EMERGENCY_CONTACT_GET,
  payload: contact,
});

export const clearEmergencyContactToUpdate = contact => ({
  type: EMERGENCY_CONTACT_CLEAR,
  payload: contact,
});

export const fetchEmergencyContacts = id => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      EMERGENCY_CONTACTS_REQUEST,
      EMERGENCY_CONTACTS_SUCCESS,
      EMERGENCY_CONTACTS_FAILURE,
    ],
    endpoint: `/api/member/emergency-contacts`,
    meta: {
      config: {
        mode: 'cors',
      },
    },
  },
});

export const createEmergencyContact = (pid, contact) => {
  const payload = {
    ...contact,
    personId: pid,
  };
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        EMERGENCY_CONTACTS_CREATE,
        EMERGENCY_CONTACTS_CREATE_SUCCESS,
        EMERGENCY_CONTACTS_CREATE_FAILURE,
      ],
      endpoint: `/api/member/emergency-contact`,
      payload,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const updateEmergencyContact = contact => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        EMERGENCY_CONTACTS_UPDATE,
        EMERGENCY_CONTACTS_UPDATE_SUCCESS,
        EMERGENCY_CONTACTS_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/emergency-contact`,
      payload: contact,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(contact),
          mode: 'cors',
        },
      },
    },
  };
};

export const deleteEmergencyContact = contact => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        EMERGENCY_CONTACTS_DELETE,
        EMERGENCY_CONTACTS_DELETE_SUCCESS,
        EMERGENCY_CONTACTS_DELETE_FAILURE,
      ],
      endpoint: `/api/member/emergency-contact/${contact.emergencyContactId}`,
      payload: contact,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'DELETE',
          mode: 'cors',
        },
      },
    },
  };
};
