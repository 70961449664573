import React from 'react';
import Table from '../../components/ReactTable';
import { ALLERGY } from '../../../store/reducers/mediaObject';
import { dateTimeRender } from '../../components/ReactTable/utils';

const AllergyTable = props => {
  const getData = () =>
    props.listIds.map(id => {
      return {
        ...props.list[id],
        id,
        type: ALLERGY,
      };
    });

  React.useEffect(() => {
    setData(getData());
  }, [props.list, props.listIds]);
  const [data, setData] = React.useState(getData());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Allergen',
        accessor: 'allergenType',
        col: '10%',
        Cell: ({ value }) => value || 'Unknown',
      },
      {
        Header: 'Reactions',
        accessor: 'reaction',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Treatments',
        accessor: 'treatment',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Notes',
        accessor: 'comments',
        col: '25%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Start Date',
        accessor: 'startOn',
        col: '10%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'End Date',
        accessor: 'endOn',
        col: '10%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
    ],
    []
  );
  return (
    <Table
      data={data}
      columns={columns}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      rendersColGroup
    />
  );
};

export default AllergyTable;
