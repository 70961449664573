import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles, Grid, Button, Typography } from '@material-ui/core';
import { ModalHeader, ModalFooter } from '..';
import MemberPreviewRow from '../MemberPreviewRow/MemberPreviewRow';
import ReviewAccessItems from '../ReviewAccessItems/ReviewAccessItems';
import ProgressButtonLinear from '../../Buttons/ProgressButtonLinear/ProgressButtonLinear';

const styles = theme => ({
  header: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
});

const ReviewAccess = props => {
  const {
    classes,
    history,
    location,
    relationshipType,
    onClickGrantAccess,
    isUpdating,
    header,
    isForProvider,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const member =
    location.state && location.state.updatedProvider
      ? location.state.updatedProvider
      : props.member;

  const subHeader = isUpdating
    ? `3. Review updated information`
    : '3. Review information';

  const formAction = isUpdating ? 'Update' : 'Add';

  const handleClickGrantAccess = event => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      onClickGrantAccess(event, member);
    }
  };

  return (
    <Fragment>
      <div>
        <ModalHeader header={header} subHeader={subHeader} />
        <Grid container>
          <Grid item xs={12} md={8}>
            {isForProvider ? (
              ''
            ) : isUpdating ? (
              <React.Fragment>
                <Typography variant="h5" className={classes.header}>
                  Updating Member
                </Typography>
                <Typography variant="body1">
                  If the email address is updated and your Care Team already has
                  a Agrin Health account, we will send them a request to join
                  your team. If not, we will send them an invitation to create
                  an account
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="h5" className={classes.header}>
                  Confirm Member
                </Typography>
                <Typography variant="body1">
                  If your Care Team member already has a Agrin Health account,
                  we will send them a request to join your team. If not, we will
                  send them an invitation to create an account.
                </Typography>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            {member && <MemberPreviewRow member={member} />}
            {relationshipType && (
              <ReviewAccessItems setRole={relationshipType} />
            )}
          </Grid>
        </Grid>
      </div>
      <ModalFooter
        leftCol={<Button onClick={history.goBack}>Back</Button>}
        rightCol={
          <Grid item>
            <ProgressButtonLinear
              color="primary"
              variant="contained"
              onClick={handleClickGrantAccess}
              disabled={isSubmitting}
              isProgressing={isSubmitting}
            >
              {formAction} without access
            </ProgressButtonLinear>
          </Grid>
        }
      />
    </Fragment>
  );
};

ReviewAccess.propTypes = {
  isForProvider: PropTypes.bool.isRequired,
};

ReviewAccess.defaultProps = {
  isForProvider: false,
};

export default withStyles(styles)(withRouter(ReviewAccess));
