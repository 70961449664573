import { useState, useEffect } from 'react';
import { contentsStatus } from '../DrawerContents/Contents';

export const pageStatus = {
  //state enum
  LOADING: 'loading',
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const useImmunizationState = props => {
  const {
    userImmunizations: {
      listIds,
      hasFetchedImmunizations,
      errorMessage,
      uploadingMediaObjects,
    },
    hasFetchedVaccinationType,
    fetchVaccinationType,
    fetchImmunizations,
  } = props;
  const { LOADING, EMPTY, SUCCESS, ERROR } = pageStatus;
  const { IDLE, SUBMIT } = contentsStatus;
  const [state, setState] = useState(LOADING);
  const [status, setStatus] = useState(IDLE);

  // handles fetching
  useEffect(() => {
    if (Boolean(props.userImmunizations.activeUser)) {
      if (!hasFetchedVaccinationType) fetchVaccinationType();
      fetchImmunizations();
      setState(LOADING);
    }
  }, [props.userImmunizations.activeUser]);

  useEffect(() => {
    if (uploadingMediaObjects) {
      setStatus(SUBMIT);
    }
  }, [uploadingMediaObjects]);

  // handle UI state
  useEffect(() => {
    const hasRecords = Boolean(listIds.length);
    const hasError = Boolean(errorMessage && errorMessage.length);
    if (hasFetchedImmunizations) {
      if (hasRecords) {
        if (hasFetchedVaccinationType) {
          setState(SUCCESS);
        }
      }
      if (!hasRecords && !hasError) {
        setState(EMPTY);
      }
      if (!hasRecords && hasError) {
        setState(ERROR);
      }
    } else if (state !== LOADING) setState(LOADING);
  }, [props.userImmunizations]);

  return {
    state,
    status,
    setStatus,
  };
};
