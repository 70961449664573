export const useFetchCodeTypes = props => {
  const fetchTypes = () => {
    if (props.bloodTypes.length === 0) {
      props.fetchBloodType();
    }

    if (props.contactTypes.length === 0) {
      props.fetchContactType();
    }

    if (props.ethnicityTypes.length === 0) {
      props.fetchEthnicityType();
    }

    if (props.genderTypes.length === 0) {
      props.fetchGenderType();
    }

    if (props.languageTypes.length === 0) {
      props.fetchLanguageType();
    }

    if (props.maritalTypes.length === 0) {
      props.fetchMaritalType();
    }

    if (props.religionTypes.length === 0) {
      props.fetchReligionType();
    }

    if (props.stateTypes.length === 0) {
      props.fetchStateType();
    }

    if (props.veteranTypes.length === 0) {
      props.fetchVeteranType();
    }
  };

  return fetchTypes;
};
