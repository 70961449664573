import React from 'react';
import AutoSizeTextArea from 'react-autosize-textarea';
import './SmartTextArea.css';

const cloneComputedStyles = (fromNode, toNode, ommit = []) => {
  const computedStyle = window.getComputedStyle(fromNode);
  Array.from(computedStyle).forEach(
    key =>
      !ommit.includes(key) &&
      toNode.style.setProperty(
        key,
        computedStyle.getPropertyValue(key),
        computedStyle.getPropertyPriority(key)
      )
  );
};

class SmartTextArea extends React.Component {
  state = {
    contents: '',
    mirror: '',
    height: 0,
  };

  constructor(props) {
    super(props);

    this.mirror = React.createRef();
    this.tracker = React.createRef();
  }

  componentDidMount() {
    cloneComputedStyles(this.textarea, this.mirror.current, ['z-index']);
    if (this.props.value) {
      this.setState({
        contents: this.props.value,
        height: this.textarea.clientHeight,
      });
    } else {
      this.setState({
        height: this.textarea.clientHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    let contents = undefined;
    if (this.props.value !== prevProps.contents) {
      contents = this.props.value;
    }

    const position = this.tracker.current.getBoundingClientRect();
    const input = this.textarea.getBoundingClientRect();

    const nextY = position.y - input.y + 16 / 4;
    const nextX = position.x - input.x + 2;

    if (this.state.x !== nextX || this.state.y !== nextY) {
      this.setState({
        contents: contents ? contents : this.state.contents,
        x: nextX,
        y: nextY,
        height: this.textarea.clientHeight,
      });
    }
  }

  onClick = event => {
    this.setState({
      contents: event.target.value,
      mirror: event.target.value.substring(0, this.textarea.selectionStart),
      mirrorRight: event.target.value.substring(
        this.textarea.selectionStart,
        this.textarea.selectionStart + 100
      ),
    });
  };

  onChange = event => {
    cloneComputedStyles(this.textarea, this.mirror.current, [
      'z-index',
      'background',
    ]);

    if (this.props.onChange) {
      const caret = { x: this.state.x, y: this.state.y };
      this.props.onChange(event, caret);
    }
    this.setState({
      contents: event.target.value,
      mirror: event.target.value.substring(0, this.textarea.selectionStart),
      mirrorRight: event.target.value.substring(
        this.textarea.selectionStart,
        this.textarea.selectionStart + 100
      ),
    });
  };

  findPosition = event => {
    const position = event.target.getBoundingClientRect();
    console.log('[Position]', position.y, position.x);
  };

  render() {
    const { className } = this.props;

    const rootStyle = {
      height: this.state.height,
    };

    const markerStyle = {
      top: this.state.y,
      left: this.state.x,
    };
    return (
      <div className="root" style={rootStyle}>
        <div
          className="marker"
          style={markerStyle}
          onMouseOver={this.findPosition}
        />
        <AutoSizeTextArea
          {...this.props}
          className={className || 'input'}
          innerRef={ref => (this.textarea = ref)}
          onClick={this.onClick}
          onChange={this.onChange}
          value={this.props.value}
          style={{
            maxHeight: '97px',
            fontFamily: 'Roboto',
          }}
        />
        <div ref={this.mirror} className="mirror">
          {this.state.mirror}
          <span
            ref={this.tracker}
            className="tracker"
            onMouseOver={this.findPosition}
          />
          {this.state.mirrorRight}
        </div>
      </div>
    );
  }
}

export default SmartTextArea;
