import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { SET_ACTIVE_USER } from '../../../store/reducers/active/actions';

//get
export const syncPermissions_REQUEST = 'syncPermissions/REQUEST';
export const syncPermissions_SUCCESS = 'syncPermissions/SUCCESS';
export const syncPermissions_FAILURE = 'syncPermissions/FAILURE';
export const RESET_SYNC_PERMISSIONS = 'syncPermissions/RESET';
export const syncPermissions_remove_REQUEST = 'syncPermissions/REMOVE_REQUEST';
export const syncPermissions_remove_SUCCESS = 'syncPermissions/REMOVE_SUCCESS';
export const syncPermissions_remove_FAILURE = 'syncPermissions/REMOVE_FAILURE';
export const syncPermissions_ADD_REQUEST = 'syncPermissions/ADD_REQUEST';
export const syncPermissions_ADD_SUCCESS = 'syncPermissions/ADD_SUCCESS';
export const syncPermissions_ADD_FAILURE = 'syncPermissions/ADD_FAILURE';
export const SYNC_PERMISSIONS_REAUTHORIZATION =
  'sync/permission/reauthorization';

// export const fetchSyncPermissions = activeUser => ({
//   type: syncPermissions_REQUEST,
//   payload: {
//     activeUser,
//   },
// });

export const reset = activeUser => {
  return async dispatch => {
    dispatch({
      type: RESET_SYNC_PERMISSIONS,
      payload: { activeUser },
    });
    dispatch(fetchSyncPermissions(activeUser));
  };
};

export const testFetchSyncPermissionsSuccess = activeUser => ({
  type: syncPermissions_SUCCESS,
  payload: {
    activeUser,
  },
});

export const fetchSyncPermissions = activeUser => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      syncPermissions_REQUEST,
      syncPermissions_SUCCESS,
      syncPermissions_FAILURE,
    ],
    endpoint: `/api/external/auth/providers`,
    payload: {
      activeUser,
    },
    meta: {
      config: {
        mode: 'cors',
      },
    },
  },
});

export const successfulReauthorization = syncProviderId => ({
  type: SYNC_PERMISSIONS_REAUTHORIZATION,
  payload: {
    syncProviderId,
  },
});

export const removeSyncPermissions = (sid, id) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      syncPermissions_remove_REQUEST,
      syncPermissions_remove_SUCCESS,
      syncPermissions_remove_FAILURE,
    ],
    endpoint: `/api/external/auth/authorization/revoke/${sid}`,
    meta: {
      config: {
        method: 'DELETE',
      },
      sid: id,
    },
  },
});
