import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { reset } from 'redux-form';
import { withStyles, Grid, Button, Divider, Icon } from '@material-ui/core';

import { FORM_NAME } from '../../components/CareTeam/NonProvider';
import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import SelectField from '../../../library/forms/BareSelectField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  RELATIONSHIP_STATUS_APPROVED,
  RELATIONSHIP_STATUS_PENDING,
  RELATIONSHIP_STATUS_ARCHIVED,
  RELATIONSHIP_STATUS_DENIED_ACCESS,
} from '../../components/CareTeam/RoleSummary/data';

import {
  TeamMemberDefaultView,
  MemberList,
  ModifyModal,
} from '../../components/CareTeam';

import {
  CARE_TEAM_CREATE_SUCCESS,
  CARE_TEAM_CREATE_FAILURE,
  CARE_TEAM_UPDATE_SUCCESS,
  CARE_TEAM_UPDATE_FAILURE,
  fetchCareTeam,
  createCareTeamMember,
  createCareTeamProvider,
  updateCareTeamProvider,
  updateCareTeamMember,
  archiveCareTeamMember,
  deleteCareTeamMember,
  setUpdateId,
  clearUpdateId,
} from '../../../store/reducers/careTeam/actions';

import { createMessage } from '../../../store/reducers/flashMessenger/actions';

import FlashMessage from '../../containers/FlashMessage';

const styles = {
  input: {
    marginRight: '1em',
    width: '50%',
  },
  button: { marginRight: '1em' },
};

const CareTeamHeader = ({
  classes,
  select,
  backLink,
  onAddMember,
  subHeaderText,
}) => {
  // because PageHeader container is used by other components,
  // custom breakpoints are necessary
  const matches420 = useMediaQuery('(max-width: 420px)'); //mobile
  const matches500 = useMediaQuery('(max-width:500px)'); //small screens
  const matches600 = useMediaQuery('(max-width:650px)'); // xs
  const matchesMin500 = useMediaQuery('(min-width:500px)');
  const matches = useMediaQuery('(min-width:650px)');
  const divStyles = matches600
    ? matches420
      ? { margin: '8px', width: '100%' }
      : { margin: '8px' }
    : { margin: '0px 8px' };
  const buttonStyles =
    matchesMin500 && matches600
      ? { width: '200px' }
      : matches420
      ? { width: '100%' }
      : {};
  const selectStyles = matches500
    ? {
        width: '100%',
      }
    : {
        width: '200px',
      };

  return (
    <div>
      <PageHeader
        headerText="My Care Team"
        subHeaderText={subHeaderText}
        backButtonText="Go Back"
        backLink={backLink}
        xs={{
          left: matches500 ? (matches420 ? 12 : 8) : matches600 ? 5 : 4,
          right: matches500 ? (matches420 ? 12 : 4) : matches600 ? 7 : 8,
        }}
        rightSlot={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: matches ? 'center' : 'flex-end',
              flexDirection: !matches600 ? 'row' : 'column-reverse',
            }}
          >
            {!matches500 && (
              <div style={{ ...selectStyles, ...divStyles }}>
                <SelectField {...select} />
              </div>
            )}
            <div style={{ ...divStyles }}>
              <Button
                color="primary"
                onClick={onAddMember}
                variant="contained"
                style={{ ...buttonStyles }}
              >
                <Icon>add</Icon>
                &nbsp; Add Member
              </Button>
            </div>
            {/* <Button color="primary" variant="contained" className={classes.button}>
              <Icon>print</Icon>
              &nbsp; Print / Export
            </Button> */}
            {/* <Button style={{ color: '#FFF', textDecoration: 'none' }} component={Link} to={`${baseRoutePath}/modify`} color="primary" variant="contained">
              <Icon>add</Icon>
              &nbsp; Add Member
            </Button> */}
          </div>
        }
      />
      {matches500 && (
        <div style={{ ...selectStyles, ...divStyles }}>
          <SelectField {...select} />
        </div>
      )}
    </div>
  );
};

const filterOptions = [
  { label: 'All Members', value: 'All Members' },
  { label: 'Current Team', value: 'Current Team' },
  { label: 'Pending Members', value: 'Pending Members' },
  { label: 'Archived Members', value: 'Archived Members' },
];

const CareTeamPage = ({
  navigation,
  classes,
  history,
  careTeamState,
  dispatch,
  user,
}) => {
  const { home, careTeam } = navigation.dashboard;

  const [member, setMember] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [listFilter, setListFilter] = useState(filterOptions[1]);

  const getOptionLabel = option => option.label;
  const getOptionValue = option => option.value;

  const clearAll = () => {
    dispatch(reset(FORM_NAME));
    dispatch(clearUpdateId());
    setIsUpdating(false);
    setMember(null);
  };

  useEffect(() => {
    console.log(user.personId);
    dispatch(fetchCareTeam(user.personId));

    return function cleanup() {
      clearAll();
    };
  }, [user.personId]);

  const handleSubmit = async (values, dispatch) => {
    const { relationshipType } = values;

    const result = !isUpdating
      ? await dispatch(
          relationshipType === 'Provider'
            ? createCareTeamProvider(user.personId, values)
            : createCareTeamMember(user.personId, values)
        )
      : await dispatch(
          relationshipType === 'Provider'
            ? updateCareTeamProvider(
                user.personId,
                careTeamState.selectedId,
                values
              )
            : updateCareTeamMember(
                user.personId,
                careTeamState.selectedId,
                values
              )
        );

    var fullName;
    if (
      (values.relationshipType == 'Provider' && values.isManualEntry) ||
      values.entityTypeCode === '2'
    ) {
      fullName = !values.locationName
        ? values.organizationName
        : `${values.organizationName} - ${values.locationName}`;
    } else if (values.displayName) {
      fullName = values.displayName;
    } else {
      fullName = [
        values.prefix || '',
        values.firstName,
        values.lastName,
        values.suffix,
      ]
        .join(' ')
        .trim();
    }

    if (result.type === CARE_TEAM_CREATE_SUCCESS) {
      // if (values.hasAccess) {
      //   dispatch(
      //     createMessage(
      //       `${fullName} has been invited to your care team as a ${relationshipType}`,
      //       'success'
      //     )
      //   );
      // } else {
      //   dispatch(
      //     createMessage(
      //       `${fullName ||
      //         result.payload
      //           .displayName} is added to your care team with no access`,
      //       'success'
      //     )
      //   );
      // }
      dispatch(
        createMessage(
          `${fullName ||
            result.payload
              .displayName} is added to your care team with no access`,
          'success'
        )
      );
    } else if (result.type === CARE_TEAM_UPDATE_SUCCESS) {
      // if (values.hasAccess) {
      //   dispatch(
      //     createMessage(
      //       `${result.payload.displayName} care team record is updated`,
      //       'success'
      //     )
      //   );
      // } else {
      //   dispatch(
      //     createMessage(
      //       `${fullName ||
      //         result.payload
      //           .displayName} care team record is updated with no access`,
      //       'success'
      //     )
      //   );
      // }
      dispatch(
        createMessage(
          `${fullName ||
            result.payload
              .displayName} care team record is updated with no access`,
          'success'
        )
      );
    } else if (result.type === CARE_TEAM_UPDATE_FAILURE) {
      dispatch(
        createMessage(
          `Something went wrong... We were not able to update ${fullName} care team record. Please try again later`
        ),
        'danger'
      );
    } else if (result.type === CARE_TEAM_CREATE_FAILURE) {
      dispatch(
        createMessage(
          `Something went wrong... We were not able to add ${fullName} to your care team. Please try again later`
        ),
        'danger'
      );
    } else {
      throw new Exception(`Unsupported care team action type "${action.type}"`);
    }

    clearAll();
    history.push(careTeam);
  };

  const handleEdit = selectedMember => {
    dispatch(setUpdateId(selectedMember.relationshipId));
    setMember(selectedMember);
    setIsUpdating(true);

    if (selectedMember.relationshipType === 'Provider') {
      history.push(`${careTeam}/modify/provider/edit`);
    } else {
      history.push(`${careTeam}/modify/person/step-one`);
    }
  };

  const handleArchive = member => {
    dispatch(archiveCareTeamMember(user.personId, member.relationshipId));
  };

  const submitInlineEdit = async (relationshipId, values) => {
    await dispatch(updateCareTeamMember(user.personId, relationshipId, values));
  };

  const handleDelete = async relationshipId => {
    await dispatch(deleteCareTeamMember(user.personId, relationshipId));
  };

  const onCloseModal = () => {
    clearAll();
    history.push(careTeam);
  };

  const onCancel = () => {
    clearAll();
    history.goBack();
  };

  // if (careTeamState.isFetching) {
  //   return (
  //     <BasePage>
  //       <Grid item xs={12}>
  //         <h3>Loading...</h3>
  //       </Grid>
  //     </BasePage>
  //   );
  // }

  const pluralize = (string, length) => {
    return length > 0 ? `${string}s` : string;
  };

  const filterMembers = index => {
    const relationshipStatus = careTeamState.list[index].status;
    switch (listFilter.value) {
      // All Members
      case 'All Members':
        return true;

      // Display only active members
      case 'Current Team':
        return (
          relationshipStatus === RELATIONSHIP_STATUS_APPROVED ||
          relationshipStatus === RELATIONSHIP_STATUS_PENDING ||
          relationshipStatus === RELATIONSHIP_STATUS_DENIED_ACCESS
        );

      // Display only pending members
      case 'Pending Members':
        return relationshipStatus === RELATIONSHIP_STATUS_PENDING;

      // Display only archived members
      case 'Archived Members':
        return relationshipStatus === RELATIONSHIP_STATUS_ARCHIVED;

      default:
        return false;
    }
  };

  const filteredIds = careTeamState.listIds.filter(filterMembers);
  const totalMembers = filteredIds.length;

  return (
    <div>
      <Route
        path={`${careTeam}/modify`}
        render={props => (
          <ModifyModal
            {...props}
            open={true}
            member={member}
            navigation={navigation}
            isUpdating={isUpdating}
            baseRoutePath={careTeam}
            onClose={onCloseModal}
            onSubmit={handleSubmit}
          />
        )}
      />
      <BasePage
        state={careTeamState.uiState}
        HeaderComponent={
          <CareTeamHeader
            subHeaderText={
              totalMembers > 0
                ? `${totalMembers} ${pluralize('member', totalMembers)}`
                : 'No Members'
            }
            baseRoutePath={careTeam}
            classes={classes}
            backLink={home}
            select={{
              getOptionLabel,
              getOptionValue,
              options: filterOptions,
              input: { value: listFilter, onChange: setListFilter },
              meta: { touched: false, error: false },
            }}
            onAddMember={() => {
              clearAll();
              history.push(`${careTeam}/modify`);
            }}
          />
        }
        EmptyStateComponent={<TeamMemberDefaultView baseRoutePath={careTeam} />}
      >
        <Grid item xs={12}>
          <FlashMessage />
          <MemberList
            data={careTeamState}
            filteredIds={filteredIds}
            filter={listFilter.value}
            onEdit={handleEdit}
            onArchive={handleArchive}
            onDelete={handleDelete}
            submitInlineEdit={submitInlineEdit}
          />
        </Grid>
      </BasePage>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.active.user,
  careTeamState: state.careTeam,
});

export default {
  component: connect(mapStateToProps)(
    withStyles(styles)(withRouter(CareTeamPage))
  ),
};
