import React from 'react';
import {
  MediaObjectDrawerItems,
  MediaObjectDrawerHeader,
} from '../../../components/MediaObjectDrawer/MediaObjectDrawer';
import styles from '../../../components/MediaObjectDrawer/MediaObjectDrawer.css';
import { Tabs, Tab } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const ListMedia = props => {
  return (
    <div className={styles.rightDrawerPanel}>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={0}
        variant="fullWidth"
      >
        <Tab label="MEDIA VIEWER" />
      </Tabs>
      <div className={styles.mediaHolder}>
        <div className={`${styles.mediaGrid} ${styles.mediaList}`}>
          {props.labs.list[props.labs.selectedId].mediaObjects.map(
            (item, index) => (
              <MediaObjectDrawerItems
                key={`modi-${index}`}
                index={index}
                personId={props.personId}
                data={item}
                drawerDisplayMode={() => {}}
                onClick={() => props.selectMediaObject(item)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ListMedia;
