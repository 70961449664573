import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedContainer = props => {
  const { children, label, error, InputProps, hint, whiteBG = false } = props;

  return (
    <TextField
      variant="outlined"
      label={label}
      margin="dense"
      helperText={!!error ? error : !!hint ? hint : ''}
      error={!!error}
      disabled={props.disabled ? props.disabled : false}
      fullWidth
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...InputProps,
        inputComponent: InputComponent,
        style: {
          ...(whiteBG ? { background: '#FFF', borderRadius: '5px' } : {}),
        },
      }}
      inputProps={{
        children: children,
        style: {
          padding: '4.55px',
        },
      }}
      style={{
        marginTop: '8px',
      }}
    />
  );
};
export default OutlinedContainer;
