import React from 'react';
import { connect } from 'react-redux';
import AttachmentsCompose from '../AttachmentsCompose';
import {
  updateMedia,
  deleteMedia,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import { SURGICALHISTORY } from '../../../store/reducers/mediaObject';
import DrawerAccordion from '../../components/DrawerAccordion';

const AttachmentPanel = props => {
  return (
    <DrawerAccordion summaryTitle="Attachments">
      <AttachmentsCompose
        appArea={SURGICALHISTORY}
        isSubmitting={props.isUploadingFile}
        goBack={() => {}}
        mediaObjects={props.mediaObjects}
        bundlesToDelete={props.bundlesToDelete}
      />
    </DrawerAccordion>
  )
};

const mapStateToProps = state => ({
  bundlesToDelete: state.surgicalHistory.bundlesToDelete,
  mediaObjects: state.mediaObject.bundles,
  isUploadingFile: state.mediaObject.isUploadingFiles,
  mediaObjects: !state.surgicalHistory.selectedId
    ? []
    : state.surgicalHistory.list[state.surgicalHistory.selectedId].mediaObjects,
});

const mapDispatchToProps = dispatch => ({
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  deleteMedia: (pid, aid, appArea, referenceId) =>
    dispatch(deleteMedia(pid, aid, appArea, referenceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentPanel);
