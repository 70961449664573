import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import useContainerSize from './useContainerSize';

export const useSliding = (children = [], hasUpdates) => {
  const wrapperRef = useRef(null);
  const [childWidth, setChildWidth] = useState(0);

  const [index, setIndex] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [inViewport, setInViewPort] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);

  const { containerWidth, updateContainerWidth } = useContainerSize(wrapperRef);

  useEffect(() => {
    if (childrenCount !== children.length) {
      setChildrenCount(children.length);
      updateContainerWidth();
    }
    if (hasUpdates) {
      setIndex(0);
    }
  }, [children, hasUpdates]);

  useLayoutEffect(() => {
    if (Boolean(childWidth)) {
      const inView = Math.floor(containerWidth / childWidth);
      setInViewPort(inView <= 0 ? 1 : inView);
    }
  }, [childWidth, containerWidth]);

  useEffect(() => {
    if (index === 0) {
      setScrollIndex(0);
      return;
    }
    if (index > children.length - inViewport) {
      setScrollIndex(children.length - 1);
      return;
    }
    if (scrollIndex != null) setScrollIndex(null);
  }, [index]);

  const getChildWidth = val => {
    setChildWidth(val);
  };

  const handleWrapperScroll = e => {
    e.preventDefault();
    const { scrollLeft, scrollWidth } = wrapperRef.current;
    if (isScrolling) {
      return;
    } else {
      if (scrollLeft === 0 || scrollLeft <= childWidth / 2) {
        if (scrollIndex === 0) return;
        setScrollIndex(0);
      } else if (
        scrollLeft + containerWidth === scrollWidth ||
        scrollLeft + containerWidth >= scrollWidth - childWidth / 2
      ) {
        if (scrollIndex === children.length - 1) return;
        setScrollIndex(children.length - 1);
      } else if (scrollIndex != null) setScrollIndex(null);
      else return;
    }
  };

  const toggleScrolling = () => {
    clearTimeout(timeoutVar);
    if (!isScrolling) return;

    const timeoutVar = setTimeout(() => {
      setIsScrolling(false);
      setScroll();
    }, 1000);
  };

  const setScroll = () => {
    if (index === 0 || index === children.length - 1) {
      setScrollIndex(index);
    }
  };

  useEffect(() => {
    if (isScrolling) toggleScrolling();
  }, [isScrolling]);

  const handleClick = (type, value) => {
    setIsScrolling(true);
    if (Boolean(value)) {
      setIndex(value);
      return;
    }
    const { scrollLeft, scrollWidth } = wrapperRef.current;
    let _index = Math.floor((scrollLeft + containerWidth / 2) / childWidth);
    let direction;
    if (type === 'prev') {
      if (_index <= inViewport) direction = 0;
      else direction = _index - inViewport;
    } else if (type === 'next') {
      if (_index >= children.length - 1 - inViewport)
        direction = children.length - 1;
      else direction = _index + inViewport;
    }
    setIndex(direction);
  };

  return {
    wrapperRef,
    childWidth,
    index,
    scrollIndex,
    isScrolling,
    handleWrapperScroll,
    handleClick,
    getChildWidth,
    hasPrev: scrollIndex === null || scrollIndex >= 1,
    hasNext:
      scrollIndex === null ||
      (inViewport < children.length && scrollIndex !== children.length - 1),
    scrollPoints: childWidth * (inViewport + 1),
    setIndex,
  };
};
