import React, { useState, useRef, useEffect } from 'react';
import InputField from './InputField';
import {
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Fade,
  withStyles,
  ClickAwayListener,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DateTimePanel from '../components/DateTimeInput/DateTimePanel';
import ClearIcon from '@material-ui/icons/Clear';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { format, isBefore, isAfter } from 'date-fns';
import { maskedDateFormatter } from '../helpers/format';
import PropTypes from 'prop-types';

// Returns field name and a string of format yyyy-MM-dd (mimics input type="date")
// (name, date)
// Component disables native date picker and replaces it with
// the DateTimePanel component.

const dateMask = value => maskedDateFormatter('__/__/____', /[\d]/gi)(value);
function validateDate(testdate) {
  var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(testdate);
}
const DateTextField = ({
  onChange,
  date,
  name,
  label,
  hint,
  inputProps = {},
}) => {
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const panel = React.useRef();

  // type new Date
  const [value, setValue] = useState(null); // format yyyy-MM-dd
  const [fieldValue, setFieldValue] = useState(''); // format mm/dd/yyyy
  const [datePickerValue, setDatePickerValue] = useState(new Date()); // format date
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    //convert min/max date to be passed to calendar component
    if (Boolean(Object.keys(inputProps).length)) {
      if (Boolean(inputProps.min)) {
        setMinDate(formatDateForCalendar(inputProps.min, '-', [0, 1, 2]));
      } else {
        setMinDate(null);
      }
      if (Boolean(inputProps.max)) {
        setMaxDate(formatDateForCalendar(inputProps.max, '-', [0, 1, 2]));
      } else {
        setMaxDate(null);
      }
    } else {
      setMinDate(null);
      setMaxDate(null);
    }
  }, [inputProps, open]);

  const onDateTimePanel = e => {
    setOpen(e);
  };

  const onSelectDate = e => {
    setHasError(false);
    setDatePickerValue(e); // setValue
    setValue(format(e, 'yyyy-MM-dd').toString()); //format for list filter
    setFieldValue(format(e, 'MM/dd/yyyy').toString());
  };

  const formatDateForCalendar = (_d, separator, dateFormat) => {
    const d1 = _d ? _d.split(separator) : null;
    const date = new Date(
      d1[dateFormat[0]],
      parseInt(d1[dateFormat[1]]) - 1,
      d1[dateFormat[2]]
    );
    return date;
  };

  const onChangeDate = e => {
    const d = dateMask(e);
    setFieldValue(d); // set field value
    const error = !validateDate(d);
    if (error) {
      setHasError(true);
      return;
    } else {
      const fDate = e ? formatDateForCalendar(e, '/', [2, 0, 1]) : null;
      if (Boolean(fDate)) {
        const vDate = format(fDate, 'yyyy-MM-dd').toString();
        if (Boolean(minDate) && isBefore(fDate, minDate)) {
          setHasError(true);
          setError(`Enter a date after ${format(minDate, 'MM/dd/yyyy')}`);
          return;
        }
        if (Boolean(maxDate) && isAfter(fDate, maxDate)) {
          setHasError(true);
          setError(`Enter a date before ${format(maxDate, 'MM/dd/yyyy')}`);
          return;
        }
        setHasError(false);
        setDatePickerValue(fDate); // format for date picker
        setValue(vDate); //set value for onChange
      }
    }
  };

  const clearValues = () => {
    setHasError(false);
    setDatePickerValue(new Date());
    setFieldValue('');
    setValue(null);
  };

  useEffect(() => {
    onChange(name, value);
  }, [value]);

  return (
    <div>
      <InputField
        variant="outlined"
        onChange={e => onChangeDate(e.target.value)}
        value={fieldValue}
        placeholder={'mm/dd/yyyy'}
        label={label}
        hint={hint}
        error={hasError}
        hint={
          hasError ? (Boolean(error) ? error : 'Invalid Date Format') : hint
        }
        shrink={true}
        type="tel"
        inputProps={{
          ...inputProps,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="start"
                style={{ padding: '10px', cursor: 'pointer' }}
                onClick={() => onDateTimePanel(!open)}
                disabled={open}
                aria-label="Calendar"
              >
                <CalendarTodayIcon style={{ height: '20px', width: '20px' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        endAdornment={
          value ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => clearValues()}
                aria-label="Clear"
                size="small"
              >
                <ClearIcon style={{ height: '20px', width: '20px' }} />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        onBlur={e => {
          if (!e.target.value && date !== null) {
            onChange(name, null);
          }
          if (e.target.value === null) onChange(name, null);
        }}
      />
      {open && (
        <div
          style={{
            position: 'relative',
            transform: 'translate3d(10px, 0px, 10px)',
            zIndex: '99',
            transformOrigin: 'center',
          }}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => onDateTimePanel(false)}
          >
            <DateTimePanel
              innerRef={panel}
              date={datePickerValue}
              onChange={_date => onSelectDate(_date)}
              onCancel={() => onDateTimePanel(false)}
              open={open}
              disableTime
              min={minDate}
              max={maxDate}
            />
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

DateTextField.propTypes = {
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
};

export default DateTextField;
