import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, TextField, Button } from '@material-ui/core';

import { apiFetch } from '../../../library/helpers/fetch';
import { isValidPassword } from '../../../library/validations/Password';
import Header from '../../components/Header';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import { simpleEmailValidation } from '../../../library/validations/EmailAddress';

const styles = theme => ({
  resetPanelRoot: {
    position: 'absolute',
    top: 'calc((100vh / 2) / 1.6)',
    paddingBottom: theme.spacing(10),
  },
  resetPanelOuter: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(10),
    },
  },
  resetPanel: {
    width: 650,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(6),
  },
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    background: theme.palette.secondary.light,
    height: 'calc(100vh / 2)',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  alertRoot: {
    backgroundColor: theme.palette.warning.light,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(5),
    borderColor: theme.palette.warning.main,
    borderLeftStyle: 'solid',
    borderLeftWidth: 5,
    marginTop: theme.spacing(2),
  },
  messageRoot: {
    marginTop: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.dark,
    '& a': {
      color: theme.palette.warning.dark,
    },
  },
  passwordField: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  inputField: {
    width: '100%',
    marginTop: theme.spacing(3),
    height: 68,
  },
  cta: {
    background: theme.palette.secondary.main,
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
});

class PasswordResetPage extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    errorMessage: '',
    errorEmail: false,
    errorFirst: false,
    errorSecond: false,
    errorResettingPassword: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { email, password, confirmPassword } = this.state;

    let errorEmail = false;
    let errorFirst = false;
    let errorSecond = false;

    if (
      prevState.email !== email ||
      prevState.password !== password ||
      prevState.confirmPassword !== confirmPassword
    ) {
      if (!simpleEmailValidation(email)) {
        errorEmail = true;
      }

      if (!isValidPassword(password)) {
        errorFirst = true;
      }

      let message = '';
      if (password && password !== confirmPassword) {
        message = 'Both passwords must match';
        errorSecond = true;
      }

      this.setState({
        errorEmail,
        errorFirst,
        errorSecond,
        errorMessage: message,
      });
    }
  }

  handleSubmit = async event => {
    const {
      match: { params },
    } = this.props;

    event.preventDefault();
    if (this.state.errorMessage.length > 0) {
      return;
    }

    const result = await apiFetch('/api/Account/ResetPassword', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        // id: params.personId,
        token: params.token,
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      }),
    });

    if (result.ok) {
      this.props.createMessage(
        'Your password is reset. Please try to login now',
        'info'
      );
      this.props.history.push('/');
    } else {
      this.setState({ errorResettingPassword: true });
    }
  };

  handleFieldChange = field => event => {
    const value = event.target.value;
    this.setState({ [field]: value });
  };

  render() {
    const { classes } = this.props;
    const message =
      'Password must be a length of 6 or greater and contain at least one uppercase letter, one lowercase letter, one symbol, and one number.';
    return (
      <Grid container>
        <Header title="Account Recovery" />
        <div className={classes.backdrop}>
          <Grid item className={classes.resetPanelRoot}>
            <div className={classes.resetPanelOuter}>
              <Paper elevation={7} className={classes.resetPanel}>
                <Typography variant="h4" className={classes.header}>
                  Reset Password.
                </Typography>
                <Typography>
                  One last step. Please use the fields below to enter your new
                  password.
                </Typography>
                <div className={classes.messageRoot}>
                  <DashboardMessage type="info" canClose={false}>
                    We ask that you enter your email address again for your
                    security — apologies for the inconvenience.
                  </DashboardMessage>
                  {this.state.errorResettingPassword ? (
                    <DashboardMessage type="danger" canClose={false}>
                      Either the email entered is incorrect or the reset token
                      given is used. Please try again.
                    </DashboardMessage>
                  ) : (
                    ''
                  )}
                </div>
                {/* <div className={classes.alertRoot}>
                  <Typography className={classes.warning}>
                    Your account security <strong>is important to us</strong>. 
                    Just in case, we have temporarily locked your account until 
                    you provide us with a new password.
                  </Typography>
                </div> */}
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    type="email"
                    error={this.state.errorEmail}
                    className={classes.inputField}
                    label="Email"
                    placeholder="example@example.com"
                    margin="dense"
                    variant="outlined"
                    value={this.state.email}
                    helperText={this.state.errorEmail ? 'Email is invalid' : ''}
                    onChange={this.handleFieldChange('email')}
                  />
                  <TextField
                    type="password"
                    error={this.state.errorFirst}
                    className={classes.passwordField}
                    id="outlined-uncontrolled"
                    label="New Password"
                    placeholder="Enter your password here"
                    margin="dense"
                    variant="outlined"
                    value={this.state.password}
                    helperText={message}
                    onChange={this.handleFieldChange('password')}
                  />
                  <TextField
                    type="password"
                    error={this.state.errorSecond}
                    className={classes.inputField}
                    id="outlined-uncontrolled"
                    label="Confirm Password"
                    placeholder="Enter the same password as above"
                    margin="dense"
                    variant="outlined"
                    value={this.state.confirmPassword}
                    helperText={this.state.errorMessage}
                    onChange={this.handleFieldChange('confirmPassword')}
                  />

                  <div className={classes.buttons}>
                    <Button
                      type="submit"
                      size="large"
                      color="secondary"
                      variant="contained"
                      className={classes.cta}
                      disabled={this.state.errorMessage.length > 0}
                    >
                      Reset My Password
                    </Button>
                    <Button
                      size="large"
                      className={classes.cancelButton}
                      component={props => <Link to="/" {...props} />}
                    >
                      Back to login page
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </Grid>
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createMessage: (msg, type) => dispatch(createMessage(msg, type)),
});

export default {
  component: connect(
    null,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(PasswordResetPage))),
};
