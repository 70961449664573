import { omit } from 'lodash';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
import { format, toDate } from 'date-fns-tz';
import {
  parseCoordinateByType,
  getChartByType,
  getDefaultDate,
} from '../../../shared/pages/ChartsPage/useParseJournal';
import { SET_ACTIVE_USER } from '../active/actions';

import {
  JOURNAL_ENTRIES_REQUEST,
  JOURNAL_ENTRIES_SUCCESS,
  JOURNAL_ENTRIES_FAILURE,
  JOURNAL_ENTRY_DELETE_REQUEST,
  JOURNAL_ENTRY_DELETE_SUCCESS,
  JOURNAL_ENTRY_DELETE_FAILURE,
  JOURNAL_ENTRIES_ATTACH_MEDIA,
  JOURNAL_ENTRY_SELECT_ID,
  JOURNAL_ENTRY_CLEAR_ID,
  CLEAR_FRAGMENTS,
  FRAGMENT_TYPE_REQUEST,
  FRAGMENT_TYPE_SUCCESS,
  FRAGMENT_TYPE_FAILURE,
  JOURNAL_ENTRIES_PAGE_REQUEST,
  JOURNAL_ENTRIES_PAGE_SUCCESS,
  JOURNAL_ENTRIES_PAGE_FAILURE,
  JOURNAL_CALENDAR_FETCH_REQUEST,
  JOURNAL_CALENDAR_FETCH_SUCCESS,
  JOURNAL_CALENDAR_FETCH_ERROR,
  JOURNAL_ENTRIES_SEARCH_REQUEST,
  JOURNAL_ENTRIES_SEARCH_SUCCESS,
  JOURNAL_ENTRIES_SEARCH_FAILURE,
} from './actions';

import {
  JOURNAL_COMPOSITION_CREATE_SUCCESS,
  JOURNAL_COMPOSITION_CREATE_REQUEST,
  JOURNAL_COMPOSITION_CREATE_FAILURE,
  JOURNAL_COMPOSITION_UPDATE_SUCCESS,
} from '../journalmodal/actions';
import { MEDIA_OBJECT_UPLOAD_COMPLETE } from '../mediaObject/actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
} from '../mediaObject/actions';

import { SUCCESS, ERROR, EMPTY, LOADING, FETCHING } from '../../state';
const fragmentsInitialState = {
  fragments: {},
  isParsingFragments: false,
  hasFetchedFragments: false,
};

const initialState = {
  isFetching: false,
  hasFetched: false,
  list: {},
  listIds: [],
  error: '',
  selectedId: null,
  hasRecords: false,
  isSubmitting: false,
  currPersonId: null,
  pagination: {
    nextPage: null,
    uiState: null,
  },
  uiState: null,
  search: {
    list: [],
    listIds: [],
    uiState: null,
  },
  ...fragmentsInitialState,
};

const reducers = {};
const getError = action => action.payload.error;
const checkIfSerializable = fragment => {
  if (typeof fragment.fragmentData === 'string') {
    return {
      ...fragment,
      fragmentData: JSON.parse(fragment.fragmentData),
    };
  }
  return fragment;
};

const mapOverFragments = (entries, timeZone) =>
  entries.map(entry => {
    const enteredOn = format(
      toDate(entry.enteredOn),
      "yyyy-MM-dd'T'HH:mm:ssXXX",
      { timeZone }
    );

    return {
      ...entry,
      enteredOn,
      fragments: entry.fragments.map(checkIfSerializable),
    };
  });

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  // Only process allergy associated actions.
  if (action.meta.resource !== 'JOURNAL') return state;

  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  const resource = state.list[action.meta.objectId];
  if (!resource) return state;
  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: [].concat(action.payload, resource.mediaObjects),
          },
        },
      };

    // Update the selected media object in the associated allergy (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.map(updateMediaObject),
          },
        },
      };

    // Remove selected media object from the associated allergy (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
      };

    default:
      return state;
  }
};

reducers[SET_ACTIVE_USER] = (state, action) => {
  return action.payload.personId === state.currPersonId
    ? state
    : {
        ...initialState,
        currPersonId: action.payload.personId,
      };
};

reducers[JOURNAL_ENTRIES_REQUEST] = (state, _) => ({
  ...state,
  uiState: LOADING,
});

reducers[JOURNAL_ENTRIES_SUCCESS] = (state, action) => {
  const list = arrayToHashMap(
    'journalEntryId',
    mapOverFragments(action.payload, action.meta.timeZoneIANA)
  );
  const listIds = getKeys('journalEntryId', action.payload);

  return {
    ...state,
    list,
    listIds,
    uiState: listIds.length ? SUCCESS : EMPTY,
    pagination: {
      ...action.meta,
      ...state.pagination,
      nextPage: action.meta.links.nextPage,
      uiState: Boolean(action.meta.links.nextPage) ? LOADING : SUCCESS,
    },
  };
};

reducers[JOURNAL_ENTRIES_FAILURE] = (state, action) => ({
  ...initialState,
  error: action.payload,
  uiState: ERROR,
  pagination: {
    ...state.pagination,
    uiState: ERROR,
  },
});

reducers[JOURNAL_ENTRIES_PAGE_REQUEST] = (state, _) => ({
  ...state,
  pagination: {
    ...state.pagination,
    uiState: LOADING,
  },
});

reducers[JOURNAL_ENTRIES_PAGE_SUCCESS] = (state, action) => {
  const list = arrayToHashMap(
    'journalEntryId',
    mapOverFragments(action.payload, action.meta.timeZoneIANA)
  );
  const listIds = getKeys('journalEntryId', action.payload);

  const noMorePages = action.meta.links.nextPage === undefined;

  return {
    ...state,
    list: {
      ...state.list,
      ...list,
    },
    listIds: [...state.listIds, ...listIds],
    pagination: {
      ...action.meta,
      ...state.pagination,
      nextPage: action.meta.links.nextPage,
      uiState: noMorePages ? SUCCESS : LOADING,
    },
  };
};

reducers[JOURNAL_ENTRIES_PAGE_FAILURE] = (state, action) => ({
  ...state,
  error: action.payload,
  pagination: {
    ...state.pagination,
    uiState: ERROR,
  },
});

reducers[CLEAR_FRAGMENTS] = (state, action) => ({
  ...state,

  fragments: {},
  isParsingFragments: false,
  hasFetchedFragments: false,
});

reducers[FRAGMENT_TYPE_REQUEST] = (state, action) => ({
  ...state,
  isFetching: true,
  isParsingFragments: true,
  hasFetchedFragments: false,
});

reducers[FRAGMENT_TYPE_SUCCESS] = (state, action) => {
  if (state.currPersonId !== action.meta.config.headers.Pid) return state;
  const type = getChartByType(action.meta.type);
  const data =
    type.fragmentType === 'CaloriesTracker' &&
    state.fragments['CaloriesTracker'] &&
    state.fragments['CaloriesTracker'].data.length
      ? [...state.fragments['CaloriesTracker'].data]
      : [];

  action.payload.forEach(item => {
    if (Boolean(item.validToTs)) return;

    const res = parseCoordinateByType(
      item.fragmentType,
      item,
      toDate(item.journalEnteredOn),
      item.journalEntryId
    );
    if (!res) return;
    data.push(res);
  });

  const fragments = {
    ...state.fragments,
    [type.fragmentType]: {
      ...type,
      data,
    },
  };

  return {
    ...state,
    fragments,
    isFetching: false,
    isParsingFragments: false,
    hasFetchedFragments: Object.keys(fragments).length === 5,
  };
};

reducers[FRAGMENT_TYPE_FAILURE] = (state, action) => ({
  ...initialState,
  error: 'Something went wrong, please try again later.',
  isParsingFragments: false,
});

reducers[MEDIA_OBJECT_UPLOAD_COMPLETE] = (state, action) => {
  if (!Boolean(action.meta)) return state;
  const { tid } = action.meta;

  const entry = state.list[tid];

  return entry
    ? {
        ...state,
        list: {
          ...state.list,
          [tid]: {
            ...entry,
            mediaObjects: [].concat(entry.mediaObjects, action.payload.data),
          },
        },
      }
    : state;
};

reducers[JOURNAL_ENTRIES_ATTACH_MEDIA] = (state, action) => {
  const entry = state.list[action.payload.journalEntryId];
  return {
    ...state,
    list: {
      ...state.list,
      [action.payload.journalEntryId]: {
        ...entry,
        mediaObjects: [...entry.mediaObjects, ...action.payload.bundle],
      },
    },
  };
};

reducers[JOURNAL_COMPOSITION_CREATE_REQUEST] = (state, action) => {
  if (!action.meta.isCurrentPerson) return state;

  const payload = {
    ...action.payload,
    journalEntryId: '',
    mediaObjects: [],
    mediaObjectIds: [],
    isCreating: true,
  };

  return {
    ...state,
    list: {
      ...state.list,
      [action.meta.tid]: payload,
    },
    listIds: [].concat([action.meta.tid], state.listIds),
    uiState: SUCCESS,
    isSubmitting: true,
  };
};

const journalCreateCopyRequest = (state, action) => {
  if (!action.meta.isCurrentPerson) return state;

  const payload = {
    ...action.payload,
    journalEntryId: '',
    mediaObjects: [],
    mediaObjectIds: [],
    isCreating: true,
  };

  return {
    ...state,
    list: {
      ...state.list,
      [action.meta.tid]: payload,
    },
    listIds: [].concat([action.meta.tid], state.listIds),
    isSubmitting: true,
    uiState: SUCCESS,
  };
};

reducers[JOURNAL_COMPOSITION_CREATE_REQUEST] = journalCreateCopyRequest;

reducers[JOURNAL_COMPOSITION_CREATE_SUCCESS] = (state, action) => {
  if (!action.meta.isCurrentPerson) return state;

  const entry = state.list[action.meta.tid];
  const { mediaObjects, ...remainingEntry } = entry;

  const updateRecord = {
    ...remainingEntry,
    ...action.payload,
    mediaObjectIds: [],
    fragments: action.payload.fragments.map(checkIfSerializable),
    mediaObjects: action.payload.mediaObjects,
    isCreating: false,
  };

  const chartType = action.payload.fragments.length
    ? getChartByType(action.payload.fragments[0].fragmentType)
    : false;
  const coord = Boolean(chartType)
    ? parseCoordinateByType(
        action.payload.fragments[0].fragmentType,
        action.payload.fragments[0],
        toDate(action.payload.enteredOn),
        action.payload.journalEntryId
      )
    : null;

  const fragments =
    Boolean(chartType) && state.hasFetchedFragments
      ? {
          ...state.fragments,
          [chartType.fragmentType]: {
            ...state.fragments[chartType.fragmentType],
            data: [...state.fragments[chartType.fragmentType].data, coord],
          },
        }
      : state.fragments;

  return {
    ...state,
    fragments,
    list: Object.keys(state.list).reduce((object, key) => {
      if (key === action.meta.tid) {
        return { ...object, [action.payload.journalEntryId]: updateRecord };
      }
      return { ...object, [key]: state.list[key] };
    }, {}),
    listIds: state.listIds.map(listId => {
      if (listId === action.meta.tid) {
        return action.payload.journalEntryId;
      }
      return listId;
    }),
  };
};

reducers[JOURNAL_COMPOSITION_UPDATE_SUCCESS] = (state, action) => {
  if (!action.meta.isCurrentPerson) return state;

  const { journalEntryId } = action.payload;

  const chartType = action.payload.fragments.length
    ? getChartByType(action.payload.fragments[0].fragmentType)
    : false;

  const coord =
    Boolean(chartType) && state.hasFetchedFragments
      ? parseCoordinateByType(
          action.payload.fragments[0].fragmentType,
          action.payload.fragments[0],
          toDate(action.payload.enteredOn),
          action.payload.journalEntryId
        )
      : null;

  // Get journal entry to update
  var journalEntry = state.list[journalEntryId];

  // Create a copy of the fragments array.
  const updateFragments = [].concat(journalEntry.fragments);

  // If there's more then 1 fragment it means the fragment was updated (not just the journal entry)
  // With this case we'll need to close out the previous fragment, and shift the new fragment to
  // the top of the list, else do nothing
  if (action.payload.fragments.length > 1) {
    // Get the index of the fragment record that will be closed out.
    // closed out: the fragment is a history record and has the field validToTs set with
    // a timestamp.
    var closedOutFragmentIndex = journalEntry.fragments.findIndex(
      x =>
        x.fragmentId === action.payload.fragments[1].fragmentId &&
        x.validFromTs === action.payload.fragments[1].validFromTs
    );

    // Get fragment to update
    const closedOutFragment = journalEntry.fragments[closedOutFragmentIndex];

    // Replace the closed out fragment with updated detail from the last
    // API call.
    const updatedClosedOutFragment = {
      ...closedOutFragment,
      ...action.payload.fragments[1],
    };

    // Overwrite prev closed out fragment.
    updateFragments[closedOutFragmentIndex] = updatedClosedOutFragment;

    // Place new, current fragment at the top of the array.
    updateFragments.unshift(action.payload.fragments[0]);
  }

  return {
    ...state,
    list: {
      ...state.list,
      [journalEntryId]: {
        ...journalEntry,
        ...action.payload,
        fragments: updateFragments,
      },
    },
    fragments:
      state.hasFetchedFragments && Boolean(chartType)
        ? {
            ...state.fragments,
            [chartType.fragmentType]: {
              ...state.fragments[chartType.fragmentType],
              data: state.fragments[chartType.fragmentType].data.map(item => {
                if (item.journalEntryId !== action.payload.journalEntryId)
                  return item;
                return coord;
              }),
            },
          }
        : state.fragments,
    search: {
      ...state.search,
      list: state.search.list[journalEntryId]
        ? {
            ...state.search.list,
            [journalEntryId]: {
              ...journalEntry,
              ...action.payload,
              fragments: updateFragments,
            },
          }
        : state.search.list,
    },
  };
};

reducers[JOURNAL_COMPOSITION_CREATE_FAILURE] = (state, action) => {
  if (!action.payload.isCurrentPerson) return state;

  const { tid } = action.meta;

  const listIndex = state.listIds.indexOf(tid);
  const { [tid]: _, ...list } = state.list;
  const listIds = [
    ...state.listIds.slice(0, listIndex),
    ...state.listIds.slice(listIndex + 1),
  ];

  return {
    ...state,
    list,
    listIds,
    error: getError(action),
    isSubmitting: false,
    uiState: listIds.length ? SUCCESS : EMPTY,
  };
};

reducers[JOURNAL_ENTRY_DELETE_REQUEST] = (state, action) => {
  const { tid } = action.meta;

  return {
    ...state,
    list: {
      ...state.list,
      [tid]: {
        ...state.list[tid],
        isDeleting: true,
      },
    },
  };
};

reducers[JOURNAL_ENTRY_DELETE_SUCCESS] = (state, action) => {
  const { tid } = action.meta;

  const listIndex = state.listIds.indexOf(tid);
  const { [tid]: _, ...list } = state.list;
  const listIds = [
    ...state.listIds.slice(0, listIndex),
    ...state.listIds.slice(listIndex + 1),
  ];

  // General journal entries don't have fragments
  const currentFragment = action.payload.fragments[0];

  return {
    ...state,
    list,
    listIds,
    uiState: listIds.length ? SUCCESS : EMPTY,
    isSubmitting: false,
    // If no fragment is found return an empty array.
    // This will be the case for general journal entries.
    fragments: currentFragment
      ? state.hasFetchedFragments &&
        state.fragments[currentFragment.fragmentType]
        ? {
            ...state.fragments,
            [currentFragment.fragmentType]: {
              ...state.fragments[currentFragment.fragmentType],
              data: [
                ...state.fragments[currentFragment.fragmentType].data.filter(
                  item => item.journalEntryId !== action.payload.journalEntryId
                ),
              ],
            },
          }
        : state.fragments
      : [],
  };
};

reducers[JOURNAL_ENTRY_DELETE_FAILURE] = (state, action) => {
  const { tid } = action.meta;

  const updatedRecord = {
    ...state.list[tid],
    isDeleting: false,
    hasFailed: true,
  };

  return {
    ...state,
    list: {
      ...state.list,
      [tid]: updatedRecord,
    },
    error: getError(action),
    isSubmitting: false,
    uiState: state.listIds.length ? SUCCESS : EMPTY,
  };
};

reducers[JOURNAL_ENTRY_SELECT_ID] = (state, action) => {
  return {
    ...state,
    selectedId: action.payload,
  };
};

reducers[JOURNAL_ENTRY_CLEAR_ID] = (state, _) => {
  return {
    ...state,
    selectedId: null,
  };
};

reducers[JOURNAL_CALENDAR_FETCH_SUCCESS] = (state, action) => {
  const list = arrayToHashMap(
    'journalEntryId',
    mapOverFragments(action.payload, action.meta.timeZoneIANA)
  );
  const listIds = [
    ...state.listIds,
    ...getKeys('journalEntryId', action.payload),
  ];
  const paginationPage = Math.floor(listIds.length / 100) + 1;
  return {
    ...state,
    list: {
      ...state.list,
      ...list,
    },
    listIds,
    pagination: {
      ...state.pagination,
      nextPage: state.pagination.nextPage
        ? state.pagination.nextPage.split('page=')[0] + `page=${paginationPage}`
        : null,
      page: paginationPage,
      uiState: Boolean(state.pagination.nextPage) ? SUCCESS : LOADING,
    },
    calendarFetching: false,
  };
};

reducers[JOURNAL_CALENDAR_FETCH_REQUEST] = (state, action) => ({
  ...state,
  calendarFetching: true,
});

reducers[JOURNAL_CALENDAR_FETCH_ERROR] = (state, action) => ({
  ...initialState,
  error: action.payload,
  uiState: ERROR,
  calendarFetching: false,
});

reducers[JOURNAL_ENTRIES_SEARCH_REQUEST] = (state, action) => ({
  ...state,
});

reducers[JOURNAL_ENTRIES_SEARCH_SUCCESS] = (state, action) => {
  const sameSearch = state.search.page + 1 === action.meta.page;
  return {
    ...state,
    search: {
      ...action.meta,
      nextPage: action.meta.links.nextPage,
      uiState: Boolean(action.meta.links.nextPage) ? LOADING : SUCCESS,
      list: {
        ...(sameSearch ? state.search.list : {}),
        ...arrayToHashMap(
          'journalEntryId',
          mapOverFragments(action.payload, action.meta.timeZoneIANA)
        ),
      },
      listIds: [
        ...(sameSearch ? state.search.listIds : []),
        ...getKeys('journalEntryId', action.payload),
      ],
    },
  };
};

reducers[JOURNAL_ENTRIES_SEARCH_FAILURE] = (state, action) => ({
  ...state,
  error: action.payload,
  uiState: ERROR,
  calendarFetching: false,
});

export default (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};
