import React from 'react';
import { Grid, TextField, Typography, Hidden } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import InlineTextWrapper from './components/InlineTextWrapper';

const MedicationsViewer = ({ data }) => {
  const hasAmount = !!data.dosage && !!data.unit;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={hasAmount ? 6 : 12}>
        <InputField
          label="Name"
          value={data.name}
          shrink={true}
          hint={data.rxUserAction || ''}
          InputProps={{ readOnly: true, disabled: true }}
          enableMinHeight={false}
          hint={!!data.rxUserAction ? data.rxUserAction : ''}
        />
      </Grid>
      {hasAmount && (
        <Grid item xs={12} sm={6}>
          <InputField
            label="Amount"
            value={`${data.dosage} ${data.unit}`}
            shrink={true}
            InputProps={{ readOnly: true, disabled: true }}
            enableMinHeight={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MedicationsViewer;
