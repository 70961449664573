import React from 'react';

export default ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3C9 3 7.63108 4.41076 6.7398 5.50021C6.22181 6.13337 5.10397 8.52391 5.31013 9.64916C5.59643 10.781 5.29663 11.9795 4.50979 12.8488C3.92834 13.5252 3.66156 14.2017 4.72185 14.7428C4.87901 14.8593 4.98444 15.0314 5.01599 15.2231C5.23489 16.3663 5.43326 17.5095 5.61112 18.6595C5.61986 18.9443 5.74473 19.2136 5.95731 19.406C6.1699 19.5983 6.45213 19.6975 6.7398 19.681H8.34049C9.7086 19.681 9.81804 19.7351 10.3516 21L18 21C18 21 17.5 20 17.5 19C17.5 18.8976 17.4982 18.7933 17.4963 18.6869C17.4798 17.7541 17.4606 16.6646 18.656 15.3719C18.6658 15.3614 18.6777 15.3486 18.6917 15.3338C19.1667 14.828 21.9431 11.8719 20 7.5C18 4 14.8079 3 12.5 3ZM13.587 13.0717C13.587 11.7729 13.587 11.7188 14.9551 11.7188C15.6528 11.6985 16.0291 11.455 15.9333 10.6162C16.0222 9.87211 15.6323 9.6083 14.9483 9.6083C13.628 9.6083 13.5801 9.56771 13.5801 8.25539C13.5665 7.55865 13.3749 7.23395 12.5883 7.22718C11.8016 7.22042 11.5553 7.56541 11.5553 8.30274C11.5553 9.61506 11.4938 9.65565 10.1872 9.65565C9.51003 9.66918 9.15432 9.85182 9.14064 10.623C9.12696 11.3941 9.44162 11.6782 10.1941 11.6782C11.528 11.7256 11.5622 11.7527 11.5622 13.0311C11.569 13.7685 11.8495 14.1202 12.5951 14.1067C13.3407 14.0932 13.587 13.7482 13.587 13.0717Z"
      fill={fill}
    />
  </svg>
);
