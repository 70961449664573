import React from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = {
  root: {
    margin: '50px 0',
    padding: '15px 10px',
  },
  column: {
    flex: 0.48,
  },
  header: {
    fontSize: 32,
  },
  text: {
    lineHeight: '150%',
    marginLeft: 24,
    marginBottom: 25,
  },
};

const JournalNoRecords = ({ classes, openModal }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.column}>
        <h2 className={classes.header}>Record Once. Reference Whenever</h2>

        <p className={classes.text}>
          Your Journal allows you to record anything relevant to your
          Health+Wellness.
        </p>

        <p className={classes.text}>
          Appointments
          <br /> Food log
          <br /> Skin rash
          <br /> Medications
          <br /> Sleep
          <br /> Mood
          <br /> New job
          <br /> Foreign travel
          <br /> Anything you want
        </p>

        <Button
          style={{ color: '#fff', marginLeft: 24 }}
          component={Link}
          onClick={openModal}
          color="primary"
          variant="contained"
        >
          Add an Entry
        </Button>
      </div>
      <div className={classes.column}>
        <img
          src={require('../../../client/images/journal/running.svg')}
          alt="Journal Default"
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(JournalNoRecords);
