import {
  LABS_REQUEST,
  LABS_SUCCESS,
  LABS_FAILURE,
  LABS_REMOVE_MEDIA,
  LABS_CREATE_REQUEST,
  LABS_CREATE_SUCCESS,
  LABS_CREATE_FAILURE,
  LABS_UPDATE_REQUEST,
  LABS_UPDATE_SUCCESS,
  LABS_UPDATE_FAILURE,
  LABS_DELETE_REQUEST,
  LABS_DELETE_SUCCESS,
  LABS_DELETE_FAILURE,
  LABS_SELECT_ID,
  LABS_CLEAR_ID,
  UPDATE_LABS_MEDIA,
  MEDIA_REMOVE_SUCCESS,
  LABS_MEDIA_TO_DELETE,
  LABS_MEDIA_TO_DELETE_CANCEL,
  LABS_UPDATE_MEDIA,
} from './actions';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
import {
  sortByField,
  sort,
  normalizeSortDirection,
} from '../../../library/helpers/sort';
import { SET_ACTIVE_USER } from '../../../store/reducers/active/actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
  MEDIA_OBJECT_UPLOAD_REQUEST,
} from '../mediaObject/actions';

const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';
const EMPTY = 'empty';

const initialState = {
  uiState: LOADING,
  list: {},
  listIds: [],

  deleting: [],
  isSubmitting: false,
  labsToUpdate: {},
  selectedId: null,
  errorMessage: null,
  didInvalidate: false,
  activeUser: null,
  mediaObjects: {},
  uploadingMediaObjects: false,
  bundlesToDelete: [],
  sortBy: 'administrationOn',
  sortDirection: 'desc',
};

const sortData = state => {
  const listIds = state.listIds
    .concat()
    .map(x => state.list[x])
    .sort(sort(normalizeSortDirection(state.sortDirection), state.sortBy));

  return listIds || [];
};
const sortIds = state => getKeys('labResultId', sortData(state));

const reducers = [];

reducers[SET_ACTIVE_USER] = (state, action) => {
  return initialState;
};

reducers[LABS_REQUEST] = (state, action) => {
  return {
    ...initialState,
  };
};

reducers[LABS_SUCCESS] = (state, action) => {
  const { payload } = action;
  const list = arrayToHashMap('labResultId', payload || []);
  const listIds = sortIds({
    ...state,
    list,
    listIds: getKeys('labResultId', payload),
  });

  return {
    ...state,
    uiState: listIds.length ? SUCCESS : EMPTY,
    listIds,
    list,
  };
};

reducers[LABS_FAILURE] = (state, action) => {
  return {
    ...state,
  };
};

reducers[LABS_REMOVE_MEDIA] = (state, action) => {
  const mediaObjects = {
    ...state.mediaObjects,
  };
  delete mediaObjects[`bundle${action.payload}`];
  return {
    ...state,
    mediaObjects,
  };
};

reducers[LABS_CREATE_REQUEST] = (state, action) => {
  return {
    ...state,
    errorMessage: undefined,
    isSubmitting: true,
  };
};

reducers[LABS_CREATE_SUCCESS] = (state, action) => {
  const resourceMediaObjects =
    state.list[action.payload.labResultId] &&
    state.list[action.payload.labResultId].mediaObjects
      ? state.list[action.payload.labResultId].mediaObjects
      : [];

  const list = {
    ...state.list,
    [action.payload.labResultId]: {
      ...action.payload,
      mediaObjects: resourceMediaObjects,
    },
  };

  const listIds = sortIds({
    ...state,
    list,
    listIds: [action.payload.labResultId, ...state.listIds],
  });

  console.log('FFS List IDS?!', listIds);

  return {
    ...state,
    errorMessage: undefined,
    didInvalidate: true,
    isSubmitting: false,
    uploadingMediaObjects: false,
    uiState: listIds ? SUCCESS : EMPTY,
    list,
    listIds,
  };
};

reducers[LABS_CREATE_FAILURE] = (state, action) => {
  return {
    ...state,
    errorMessage: action.payload,
    isSubmitting: false,
  };
};

reducers[LABS_UPDATE_REQUEST] = (state, action) => {
  return {
    ...state,
    errorMessage: undefined,
    isSubmitting: true,
  };
};

reducers[LABS_UPDATE_SUCCESS] = (state, action) => {
  const _resourceMediaObjects = state.list[action.payload.labResultId]
    .mediaObjects
    ? state.list[action.payload.labResultId].mediaObjects
    : [];

  const list = {
    ...state.list,
    [action.payload.labResultId]: {
      ...action.payload,
      mediaObjects: Object.keys(action.bundles).length
        ? _resourceMediaObjects.map(x => {
            if (action.bundles[x.mediaObjectId])
              return {
                ...x,
                ...action.bundles[x.mediaObjectId],
              };
            else return x;
          })
        : _resourceMediaObjects,
    },
  };

  const listIds = sortIds({
    ...state,
    list,
    listIds: state.listIds,
  });

  return {
    ...state,
    errorMessage: undefined,
    didInvalidate: true,
    isSubmitting: false,
    uploadingMediaObjects: false,
    list,
    listIds,
    bundlesToDelete: [],
  };
};

reducers[LABS_UPDATE_FAILURE] = (state, action) => {
  return {
    ...state,
    errorMessage: action.payload,
    isSubmitting: false,
    bundlesToDelete: [],
  };
};

reducers[LABS_DELETE_REQUEST] = (state, action) => {
  console.log(state.list[action.payload]);
  return {
    ...state,
    list: {
      ...state.list,
      [action.payload]: {
        ...state.list[action.payload],
        isDeleting: true,
      },
    },
  };
};

reducers[LABS_DELETE_SUCCESS] = (state, action) => {
  let lid = action.payload.labResultId;
  state.listIds = state.listIds.filter(idx => idx !== lid);
  delete state.list[lid];
  return {
    ...state,
    didInvalidate: false,
    uiState: state.listIds.length ? SUCCESS : EMPTY,
  };
};

reducers[LABS_DELETE_FAILURE] = (state, action) => {
  return {
    ...state,
    didInvalidate: true,
    list: {
      ...state.list,
      [action.payload]: {
        ...state.list[action.payload],
        isDeleting: false,
      },
    },
  };
};

reducers[LABS_MEDIA_TO_DELETE] = (state, action) => {
  const _bundlesToDelete = Boolean(
    state.bundlesToDelete.find(
      item => item.mediaObjectId === action.payload.mediaObjectId
    )
  )
    ? [
        ...state.bundlesToDelete.map(item => {
          if (item.mediaObjectId === action.payload.mediaObjectId) {
            return action.payload;
          }
          return item;
        }),
      ]
    : [
        ...state.bundlesToDelete,
        {
          mediaObjectId: action.payload.mediaObjectId,
          objectId: action.payload.referenceId ? state.selectedId : null,
          referenceId: action.payload.referenceId || null,
        },
      ];

  return {
    ...state,
    bundlesToDelete: _bundlesToDelete,
  };
};

reducers[LABS_MEDIA_TO_DELETE_CANCEL] = (state, action) => {
  return {
    ...state,
    bundlesToDelete: [
      ...state.bundlesToDelete.filter(
        item => item.mediaObjectId !== action.payload
      ),
    ],
  };
};

reducers[UPDATE_LABS_MEDIA] = (state, action) => {
  const mediaObjects = state.mediaObjects;
  if (Boolean(action.payload.formName)) {
    mediaObjects[action.payload.formName] = action.payload.pristine;
  }
  return {
    ...state,
    mediaObjects,
  };
};

reducers[LABS_SELECT_ID] = (state, action) => {
  return {
    ...state,
    mediaObjects: {},
    selectedId: action.payload,
  };
};

reducers[LABS_CLEAR_ID] = (state, action) => {
  return {
    ...state,
    selectedId: null,
    mediaObjects: {},
  };
};

reducers[MEDIA_REMOVE_SUCCESS] = (state, action) => {
  return {
    ...state,
    bundlesToDelete: [
      ...state.bundlesToDelete.filter(
        item => item.mediaObjectId !== action.payload
      ),
    ],
    list: {
      ...state.list,
      [state.selectedId]: {
        ...state.list[state.selectedId],
        mediaObjects: state.list[state.selectedId].mediaObjects.filter(
          item => item.mediaObjectId !== action.payload
        ),
      },
    },
  };
};

reducers[LABS_UPDATE_MEDIA] = (state, action) => {
  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  return {
    ...state,
    list: {
      ...state.list,
      [action.payload.objectId]: {
        ...state.list[action.payload.objectId],
        mediaObjects: state.list[action.payload.objectId].mediaObjects.map(
          updateMediaObject
        ),
      },
    },
  };
};

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  if (action.meta.resource !== 'LABRESULT') return state;

  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  const resource =
    action.meta.objectId && state.list[action.meta.objectId]
      ? state.list[action.meta.objectId]
      : null;

  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: [].concat(
              {
                //TODO: currently create returns objectId as reference ID and get as id, update this function when changes to that are made on the API
                ...action.payload,
                id: action.payload.referenceId,
              },
              resource ? resource.mediaObjects : []
            ),
          },
        },
      };

    // Update the selected media object in the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.map(updateMediaObject),
          },
        },
      };

    // Remove selected media object from the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...state.list[action.meta.objectId],
            mediaObjects: state.list[action.meta.objectId].mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
      };

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};
