import { useReducer } from 'react';
import { TEMP_FIELD_NAME } from '../../JournalEntryDrawer';

const getMinuteAggregate = (hrs, min) => {
  if (!!hrs && !!min) {
    return parseInt(hrs) * 60 + parseInt(min);
  }
};

//returns excess hr value when minute > 60
const getHrsFromMinutes = minute => {
  if (parseInt(minute) > 60) {
    const hrs = Math.floor(parseInt(minute) / 60);
    const min = parseInt(minute) % 60;
    return [hrs, min];
  }
  return [0, parseInt(minute)];
};

export const useReducerHook = (
  updateField,
  initialValues,
  activityItems = [],
  totalActivityTimeMins
) => {
  const [state, dispatch] = useReducer(reducer, {
    loadedInitialValues: false,
    isDurationCalculated: true,
    isCalorieCalculated: true,
    isDistanceCalculated: true,
    shouldCalculate: true,
  });

  function reducer(state, action) {
    console.log(state, action);
    switch (action.type) {
      case 'LoadedInitialValues':
        let isInitCaloriesCalculated =
          initialValues.manualCaloriesBurned === 0 ? true : false;
        let toggleMinsState =
          initialValues.manualActivityTimeMins === 0 ? true : false;
        let isInitDistanceCalculated =
          initialValues.manualDistance === 0 ? true : false;

        initialValues.activityItems.length > 0 &&
          initialValues.activityItems.forEach((item, index) => {
            updateField(
              `activityItems[${index}].durationMins`,
              item.durationMinutes
            );
          });

        //map initial values to temp
        isInitCaloriesCalculated
          ? updateField(
              `${TEMP_FIELD_NAME}CaloriesBurned`,
              initialValues.totalCaloriesBurned
            )
          : !!initialValues.manualCaloriesBurned
          ? updateField(
              `${TEMP_FIELD_NAME}CaloriesBurned`,
              initialValues.manualCaloriesBurned
            )
          : null;

        toggleMinsState
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.totalActivityTimeMins)[0]
            )
          : !!initialValues.manualActivityTimeMins
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.manualActivityTimeMins)[0]
            )
          : null;

        toggleMinsState
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.totalActivityTimeMins)[1]
            )
          : !!initialValues.manualActivityTimeMins
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.manualActivityTimeMins)[1]
            )
          : null;

        isInitDistanceCalculated
          ? updateField(
              `${TEMP_FIELD_NAME}Distance`,
              initialValues.totalDistance
            )
          : !!initialValues.manualDistance
          ? updateField(
              `${TEMP_FIELD_NAME}Distance`,
              initialValues.manualDistance
            )
          : null;

        return {
          ...state,
          ...initialValues,
          loadedInitialValues: true,
          isCalorieCalculated: isInitCaloriesCalculated,
          isDurationCalculated: toggleMinsState,
          isDistanceCalculated: isInitDistanceCalculated,
          shouldUpdate:
            (isInitCaloriesCalculated && 'calories') ||
            (toggleMinsState && 'duration'),
        };
      case 'updateTemp':
        updateField(`${action.name}`, action.value);
        return state;
      case 'updateManualActivityTime':
        updateField('manualActivityTimeMins', action.value);
        updateField('totalActivityTimeMins', action.value);
        return state;
      case 'isCalorieCalculated':
        if (action.payload) {
          updateField('manualCaloriesBurned', 0);
        }
        if (!action.payload) {
          updateField(`${TEMP_FIELD_NAME}CaloriesBurned`, null);
          updateField('manualCaloriesBurned', 0);
        }
        return {
          ...state,
          isCalorieCalculated: action.payload,
          shouldUpdate: true,
        };
      case 'isDurationCalculated':
        if (!action.payload) {
          updateField('manualActivityTimeMins', 0);
          updateField(`${TEMP_FIELD_NAME}ActivityTimeMins`, null);
        }
        if (action.payload) {
          updateField('manualActivityTimeMins', 0);
        }
        return {
          ...state,
          isDurationCalculated: action.payload,
          shouldUpdate: true,
        };
      case 'isDistanceCalculated':
        if (action.payload) {
          updateField('manualDistance', 0);
        }
        if (!action.payload) {
          updateField(`${TEMP_FIELD_NAME}Distance`, null);
          updateField('manualDistance', 0);
        }
        return {
          ...state,
          isDistanceCalculated: action.payload,
          shouldUpdate: true,
        };
      default:
        return state;
    }
  }

  return [state, dispatch];
};
