import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 8,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '40vh',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  col: {
    wordWrap: 'break-word',
    maxWidth: '250px',
  },
  head: {
    backgroundColor: '#fff',
    position: 'sticky',
    zIndex: 10,
    top: 0,
  },

  //control
  controlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 0px',
  },
  iconButton: { color: '#444', margin: '0px 2px', padding: 8 },
  progressContainer: {
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const StyledTableRow = withStyles(theme => ({
  root: {
    minHeight: 55,
    padding: '8px',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
      padding: '8px',
    },
  },
  body: {
    fontSize: 14,
  },
}))(TableRow);

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#0068A8',
    color: '#fff',
    padding: '8px',
  },
  body: {
    fontSize: 14,
    padding: '8px',
  },
}))(TableCell);

export const StyledTableSortLabel = withStyles(theme => ({
  root: {
    position: 'absolute',
    right: 0,
    top: '3px',
  },
  icon: {
    backgroundColor: '#0068A8',
    fill: '#fff',
  },
}))(TableSortLabel);
