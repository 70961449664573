import React from 'react';
import { renderRoutes } from 'react-router-config';
import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import CssBaseline from './CssBaseline';

let theme = createTheme({
  palette: {
    primary: {
      light: '#F2F9FF',
      main: '#006AB0',
      dark: '#00406A',
    },
    red: {
      light: '#FBDCDE',
      main: '#ED5158',
    },
    secondary: {
      light: '#F0F5E9',
      main: '#97BA52',
      dark: '#5D7332',
    },
    error: {
      light: '#FAEDED',
      main: '#BF1F1F',
      dark: '#731212',
    },
    warning: {
      light: '#FAF7ED',
      main: '#E6BC05',
      dark: '#73600E',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

theme = responsiveFontSizes(theme);

export function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

const Root = ({ route, routes }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {renderRoutes(route.routes)}
  </MuiThemeProvider>
);

export default withRoot(Root);
