import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import classNames from 'classnames';

import { apiFetch } from '../../../library/helpers/fetch';
import { simpleEmailValidation } from '../../../library/validations/EmailAddress';
import Header from '../../components/Header';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

import './ForgotPasswordPage.css';

const styles = theme => ({
  resetPanelRoot: {
    position: 'absolute',
    top: 'calc((100vh / 2) / 1.6)',
  },
  resetPanelOuter: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(10),
    },
  },
  resetPanel: {
    width: 650,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(6),
  },
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    background: theme.palette.secondary.light,
    height: 'calc(100vh / 2)',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  inputField: {
    width: '100%',
    marginTop: theme.spacing(3),
    height: 68,
  },
  cta: {
    background: theme.palette.secondary.main,
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
});

class ForgotPasswordPage extends Component {
  state = {
    emailAddress: '',
    errorMessage: '',
    accountExist: true,
    isSubmitting: false,
  };

  handleSubmit = async event => {
    event.preventDefault();
    if (this.state.errorMessage.length > 0) {
      return;
    }

    this.setState({ isSubmitting: true });

    const result = await apiFetch('/api/Account/ForgotPassword', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ Email: this.state.emailAddress }),
    });

    if (result.status === 404) {
      this.setState({ accountExist: false });
    }

    if (result.ok) {
      this.props.createMessage(
        'An email is sent to the recovery email you provided. Follow the directions in the email to reset your password'
      );
      this.props.history.push('/');
    }

    this.setState({ isSubmitting: false });
  };

  handleEmailAddressChange = event => {
    const emailAddress = event.target.value;
    let errorMessage = '';

    if (!emailAddress) {
      errorMessage = 'Required';
    } else if (!simpleEmailValidation(emailAddress)) {
      errorMessage = 'Email address not valid';
    }

    this.setState({ emailAddress, errorMessage, isSubmitting: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Header title="Account Recovery" />
        <div className={classes.backdrop}>
          <Grid item className={classes.resetPanelRoot}>
            <div className={classes.resetPanelOuter}>
              <Paper elevation={7} className={classes.resetPanel}>
                <Typography variant="h4" className={classes.header}>
                  Forgot Password?
                </Typography>
                {!this.state.accountExist ? (
                  <DashboardMessage type="danger" canClose={false}>
                    Email account not found
                  </DashboardMessage>
                ) : null}
                <Typography>
                  Don't worry. Just enter the email address associated with your
                  Agrin Health account, and we'll send you an email with a link
                  to reset your password.
                </Typography>
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    error={this.state.errorMessage.length > 0}
                    className={classes.inputField}
                    id="outlined-uncontrolled"
                    label="Email address"
                    placeholder="Enter your email address here"
                    margin="dense"
                    variant="outlined"
                    value={this.state.emailAddress}
                    helperText={this.state.errorMessage}
                    onChange={this.handleEmailAddressChange}
                  />
                  <div className={classes.buttons}>
                    <Button
                      type="submit"
                      size="large"
                      color="secondary"
                      variant="contained"
                      className={classes.cta}
                      disabled={
                        this.state.errorMessage.length > 0 ||
                        this.state.isSubmitting
                      }
                    >
                      Send My Reset Link
                    </Button>
                    <Button
                      size="large"
                      className={classes.cancelButton}
                      component={props => <Link to="/" {...props} />}
                    >
                      Go Back
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </Grid>
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createMessage: (msg, type) => dispatch(createMessage(msg, type)),
});

export default {
  component: connect(
    null,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(ForgotPasswordPage))),
};
