import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import FragmentsList, { FRAGMENT_DEFAULT } from './FragmentsList';
import JournalCompose from './JournalCompose';
import AttachmentsList from './AttachmentsList';
import ProgressButton from '../Buttons/ProgressButton';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import {
  updateMedia,
  deleteMedia,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import {
  createFragment,
  updateFragment,
  deleteFragment,
  setFragmentForUpdate,
  openJournalFragmentDialog,
  closeJournalFragmentDialog,
  setJournalMessage,
  changeFragment,
  JOURNAL_MODAL_CREATE,
  JOURNAL_MODAL_COPY,
  JOURNAL_MODAL_EDIT,
  openJournalModal,
} from '../../../store/reducers/journalmodal/actions';
import {
  Typography,
  ExpansionPanel,
  Button,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Icon,
} from '@material-ui/core';

import { fragmentsList } from './FragmentsList';
import AttachmentsCompose from './AttachmentsCompose';
import { ExpandMoreOutlined } from '@material-ui/icons';
import NoteInput from '../NoteInput';
import FragmentCompose from './FragmentCompose';

import InputField from './fragments/InputField';

import Select from 'react-select/creatable';
import {
  csvToCodeObjectList,
  codeObjectListToCsv,
  compareObjects,
} from '../../../library/helpers/utils';
import { setFiles } from '../../../store/reducers/mediaObject';

import SelectField from '../../../library/forms/SelectField';
import EasterEgg from '../../../library/components/EasterEggComponents';

import JournalDetailForm from './JournalDetailForm';
import {
  omitPropsStartingWith,
  normalizeObjectKeys,
} from '../../../library/helpers/utils';

import FormButtonPanel from '../Form/FormButtonPanel';

const styles = theme => ({
  root: {},
  composeContainer: {
    minHeight: 0,
    padding: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
  },
  inputFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  header: {
    fontSize: '16px',
    fontWeight: '500',
    opacity: 0.8,
  },
  info: {
    flex: 1,
    color: '#aaa',
    display: 'flex',
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  errorCharCount: {
    color: '#ff0000',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  charCount: {
    color: '#aaa',
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const BLACKLIST = [
  'createFragment',
  'isFragmentDialogOpened',
  'closeJournalFragmentDialog',
  'openJournalFragmentDialog',
  'setJournalMessage',
];

const normalizeProps = (props, list) => {
  list.forEach(x => {
    if (props[x]) delete props[x];
  });

  return props;
};

export const ACTIVE_VIEW_ENTRY = 'view_entry';
export const SELECT_FRAGMENT = 'view_fragment';
export const ACTIVE_VIEW_ATTACHMENTS = 'view_attachments';

export const TEMP_FIELD_NAME = 'rftemp';

const omitTempFields = omitPropsStartingWith(TEMP_FIELD_NAME);

class JournalEntryDrawer extends Component {
  state = {
    activePanelIndex: 0,
    activeView: ACTIVE_VIEW_ENTRY,
    people: [],
    charCount: 0,
    body: '',
    title: '',
    isSaveDisabled: true,
    journalDateWasChanged: false,
  };

  componentDidUpdate(prevProps) {
    const { mediaObjects, fragments, open, journalCompose } = this.props;

    // if (prevProps.open !== open && !open) {
    //   this.props.changeFragment({
    //     fragmentType: FRAGMENT_DEFAULT,
    //   });
    // }

    if (prevProps.open !== open && open) {
      if (fragments.length !== 0) {
        this.setState({
          ...this.state,
          activeView: ACTIVE_VIEW_ENTRY,
          journalDateWasChanged: false,
        });

        this.props.changeFragment({
          fragmentType: fragments[0].fragmentType,
          fragmentData: fragments[0].fragmentData,
        });

        this.props.setJournalMessage(this.props.journalCompose);

        // this.props.setJournalMessage({
        //   ...this.props.journalCompose,
        //   datetime: toDate(this.props.journalCompose.enteredOn),
        //   title: fragments[0].name,
        // });
      } else if (
        journalCompose.modalState === JOURNAL_MODAL_EDIT ||
        journalCompose.modalState === JOURNAL_MODAL_COPY
      ) {
        this.setState({
          ...this.state,
          activeView: ACTIVE_VIEW_ENTRY,
        });
      } else {
        this.setState({
          ...this.state,
          activeView: SELECT_FRAGMENT,
        });
      }
    }
    // Check if entry is uploading files
    if (this.state.uploading) {
      // Check if all media objects are finished uploading
      const noMoreToUpload = mediaObjects.reduce(
        (acc, curr) => curr.isUploaded,
        false
      );

      if (noMoreToUpload) {
        // although current process closes modal after uploading, setting activeView state
        // to ACTIVE_VIEW_ENTRY after uploading causes reduxForm to reinitialize,
        // Setting state to SELECT_FRAGMENT prevents this
        // this.setState({ activeView: SELECT_FRAGMENT, uploading: false });
        this.setState({ uploading: false });
      }
    }
  }

  componentDidMount() {
    const {
      user: { personId },
      dependents,
    } = this.props;

    const mapDependent = ({ personId, firstName, lastName }) => ({
      label: `${firstName} ${lastName}`,
      value: personId,
    });

    const people = [
      {
        label: `Me`,
        value: personId,
      },
    ];

    this.setState({
      people: people.concat(dependents.records.map(mapDependent)),
      tags: this.props.tags || [],
      isSaveDisabled: this.props.isSaveDisabled,
    });

    this.props.setJournalMessage({
      ...this.props.journalCompose,
      //Sets user to default to current date and user
      user: people[0],
    });
  }

  onTabChange = (event, index) => {
    this.setState({ activePanelIndex: index });
  };

  onTabIndexChange = index => {
    this.setState({ activePanelIndex: index });
  };

  onChangeJournalCompose = (state, field) => {
    switch (field) {
      case 'datetime':
        this.setState({
          ...this.state,
          journalDateWasChanged: true,
        });
        this.props.setJournalMessage({
          ...this.props.journalCompose,
          datetime: state,
        });
        break;
      case 'user':
        this.props.setJournalMessage({
          ...this.props.journalCompose,
          user: state,
        });
        break;
      case 'tags':
      case 'body':
        this.props.setJournalMessage({
          ...this.props.journalCompose,
          [field]: state,
        });
        break;

      case 'title':
      case 'location':
        this.props.setJournalMessage({
          ...this.props.journalCompose,
          ...state,
          body: this.props.journalCompose.body,
        });
        break;
    }

    if (this.props.onChange) {
      this.props.onChange(state, action);
    }
  };

  handleFragmentSelection = (fragmentType, _, name) => {
    this.setState(state => ({
      ...state,
      activeView: ACTIVE_VIEW_ENTRY,
    }));

    this.props.setJournalMessage({
      ...this.props.journalCompose,
      title: name,
      user: {
        label: this.props.label,
        value: this.props.personId,
      },
    });

    this.props.changeFragment({
      fragmentType,
      fragmentData: null,
    });
  };

  handleFragmentSave = () => {
    this.setState({
      ...this.state,
      activeView: ACTIVE_VIEW_ENTRY,
    });
  };

  addAttachment = () => {
    this.setState({ ...this.state, activeView: ACTIVE_VIEW_ATTACHMENTS });
  };

  viewAttachment = () => {};

  backToEntryView = () => {
    this.setState({ ...this.state, activeView: ACTIVE_VIEW_ENTRY });
  };

  onBodyChange = body => {
    this.setState({
      charCount: body.length,
    });
  };

  setToUploading = () => {
    if (this.props.mediaObjects.length !== 0) {
      this.setState({
        ...this.state,
        uploading: true,
        activeView: ACTIVE_VIEW_ATTACHMENTS,
      });
    }
  };

  onSubmitEntry = event => {
    event.preventDefault();
    this.setToUploading();

    // Only allow journal entries with modalState = "journal-edit"
    // Prevents journal copies/duplicate as their journalEntryId
    // is undefined before createJournalEntry();
    if (
      this.props.journalCompose.mediaObjects &&
      this.props.journalCompose.modalState === JOURNAL_MODAL_EDIT
    ) {
      this.props.journalCompose.mediaObjects.forEach(object => {
        this.props.updateMedia(
          {
            mediaObjectId: object.mediaObjectId,
            personId: this.props.journalCompose.user
              ? this.props.journalCompose.user.value
              : this.props.user.personId,
            objectId: this.props.journalCompose.journalEntryId,
            description: object.description,
            date:
              object.date || format(new Date(object.enteredOn), 'yyyy-MM-dd'),
            time: object.time || '00:00',
          },
          'journal'
        );
      });
    }

    const fragment = this.props.journalCompose.fragments[0];

    const hasFragment =
      fragment &&
      (fragment.fragmentType || fragment.fragmentType !== FRAGMENT_DEFAULT);

    const journalState = {
      ...this.props.journalCompose,
      datetime: this.props.journalCompose.datetime
        ? this.props.journalCompose.datetime
        : new Date(),
      user: this.props.journalCompose.user,
      fragments: hasFragment
        ? [
            {
              ...fragment,
              fragmentData:
                fragment.fragmentType === 'Emotions'
                  ? [...this.props.reduxForm.reduxForm.values.emotions]
                  : Array.isArray(fragment.fragmentData)
                  ? fragment.fragmentData
                  : this.props.reduxForm.reduxForm
                  ? omitTempFields(this.props.reduxForm.reduxForm.values)
                  : null,
            },
          ]
        : [],
    };

    this.props.onSubmit(journalState);
  };

  handleDeleteMedia = async (mediaObjectId, referenceId) => {
    if (this.props.journalCompose.modalState !== JOURNAL_MODAL_EDIT) return;
    await this.props.deleteMedia(
      mediaObjectId,
      this.props.journalCompose.journalEntryId,
      'JOURNAL',
      referenceId
    );
  };

  handleNoteInputChange = values => {
    this.setState({
      ...this.state,
      ...values,
    });

    this.onChangeJournalCompose(
      {
        title: this.state.title,
        body: this.state.body,
        ...values,
      },
      'note'
    );
  };

  handleChangeDetails = data => {
    this.props.setJournalMessage(data);
  };

  handleChangeJournalBody = event => {
    console.log('[handleChangeJournalBody]', event.target.value);
    this.onChangeJournalCompose(event.target.value, 'body');
  };

  handleChangeJournalTags = values => {
    console.log('[handleChangeJournalTags]', codeObjectListToCsv(values));
    this.onChangeJournalCompose(codeObjectListToCsv(values), 'tags');
  };

  onTagChange = options => {
    console.log('Journal Entry Drawer', options);
    this.onChangeJournalCompose(codeObjectListToCsv(options), 'tags');
  };

  toggleSaveButton = val => {
    if (val !== this.state.isSaveDisabled)
      this.setState({
        ...this.state,
        isSaveDisabled: val,
      });
  };

  renderActiveView = () => {
    const {
      onCancel,
      onSubmit,
      fragments,
      journalCompose,
      hasMediaObjectUpdates,
    } = this.props;
    const fragmentType = fragments[0] ? fragments[0].fragmentType : 'default';
    const isFragmentDataPristine =
      fragmentType === 'default'
        ? true
        : journalCompose.modalState === 'journal-edit' &&
          fragments[0] &&
          fragments.length > 0 &&
          this.props.reduxForm &&
          this.props.reduxForm.reduxForm &&
          (fragmentType === 'Emotions'
            ? Boolean(
                JSON.stringify(fragments[0].fragmentData) ===
                  JSON.stringify(this.props.reduxForm.reduxForm.values.emotions)
              )
            : Boolean(
                JSON.stringify(
                  omitTempFields(this.props.reduxForm.reduxForm.values)
                ) ==
                  JSON.stringify(
                    normalizeObjectKeys(
                      omitTempFields(this.props.reduxForm.reduxForm.values),
                      fragments[0].fragmentData
                    )
                  )
              ));
    switch (this.state.activeView) {
      case SELECT_FRAGMENT:
        return (
          <FragmentsList
            activeId={
              fragments.length === 0
                ? FRAGMENT_DEFAULT
                : fragments[0].fragmentType
            }
            onClick={this.handleFragmentSelection}
          />
        );
      case ACTIVE_VIEW_ENTRY:
        const { classes, ...other } = this.props;
        return (
          <>
          <div className={classes.composeContainer}>
            {this.props.journalCompose.modalState === 'journal-copy' && (
              <Typography variant="h6" style={{ fontSize: '1rem' }}>
                Duplicate Journal Record
              </Typography>
            )}
            <JournalCompose
              data={this.props.journalCompose}
              people={this.state.people}
              activeUser={this.props.user}
              onChange={this.onChangeJournalCompose}
              onCancel={onCancel}
              onSubmit={onSubmit}
              fragments={fragments}
              setToUploading={this.setToUploading}
              viewAttachment={this.viewAttachment}
              addAttachment={this.addAttachment}
              mediaObjects={this.props.mediaObjects}
              onBodyChange={this.onBodyChange}
              bottomInputSlot={
                <div className={classes.inputFooter}>
                  <div className={classes.buttons}></div>
                  <div className={classes.info}>
                    <span
                      className={
                        this.props.body && this.props.body.length == 3000
                          ? classes.errorCharCount
                          : classes.charCount
                      }
                    >
                      {this.state.charCount}/ 3000
                    </span>
                  </div>
                </div>
              }
            />

            <FragmentCompose
              user={this.props.user}
              fragment={this.props.fragments[0]}
              journalBody={journalCompose.body}
              journalTags={csvToCodeObjectList(this.props.tags)}
              handleFragmentSave={this.handleFragmentSave}
              toggleSaveButton={this.toggleSaveButton}
            />

            {fragmentType === FRAGMENT_DEFAULT && (
              <JournalDetailForm
                data={this.props.journalCompose}
                onChange={this.handleChangeDetails}
              />
            )}

            <ExpansionPanel style={{ boxShadow: 'none' }}>
              <ExpansionPanelSummary
                style={{ paddingLeft: 0, paddingRight: 0 }}
                expandIcon={<ExpandMoreOutlined />}
              >
                <Typography
                  style={{
                    fontSize: '20px',
                    lineHeight: '25px',
                    fontWeight: '500',
                    color: '#000000',
                  }}
                >
                  Attachments
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <AttachmentsCompose
                  appArea="journal"
                  modalState={this.props.journalCompose.modalState}
                  isSubmitting={this.state.uploading}
                  goBack={this.backToEntryView}
                  mediaObjects={this.props.journalCompose.mediaObjects}
                  mediaObjectIds={this.props.journalCompose.mediaObjectIds}
                  deleteMedia={this.handleDeleteMedia}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div> 
          <div className={classes.footer}>
            <FormButtonPanel
              actionText="Save"
              cancelText="Cancel"
              onCancel={() => {
                this.props.openJournalModal(false);
                this.props.onCancel();
              }}
              onSubmit={this.onSubmitEntry}
              isDisabled={
                (journalCompose.isSaveDisabled && // checks changes in journalCompose
                !journalCompose.hasNewMediaObject && // checks changes in journalEntry mediaObjects
                isFragmentDataPristine && // checks changes in reduxForms
                  !hasMediaObjectUpdates &&
                  !this.state.journalDateWasChanged) ||
                this.state.isSaveDisabled ||
                this.props.syncErrors
              }
              isSubmitting={this.props.journalCompose.isSubmitting}
            />
          </div>
          </>
        );

      case ACTIVE_VIEW_ATTACHMENTS:
        return (
          <div className={this.props.classes.composeContainer}>
            <AttachmentsCompose
              appArea="journal"
              isDetailCollapseDisabled={true}
              modalState={this.props.journalCompose.modalState}
              isSubmitting={this.state.uploading}
              goBack={this.backToEntryView}
              deleteMedia={this.handleDeleteMedia}
              mediaObjects={this.props.journalCompose.mediaObjects}
            />
          </div>
        );
      default:
        return null;
    }
  };

  setFragmentMeta = type => {
    const idx = fragmentsList.map(frag => frag.fragmentType).indexOf(type);

    if (type !== -1) {
      return fragmentsList[idx];
    }
    return null;
  };

  render() {
    //fixes material ui console error
    const {
      classes,
      isFragmentDialogOpened,
      currentFragmentDialog,
      fragmentIndexToUpdate,
      journalCompose,
      mediaObjects,
      isUploadingFile,
      changeFragment,
      updateFragment,
      deleteFragment,
      setFragmentForUpdate,
      fragments,
      ...rest
    } = this.props;

    const other = normalizeProps(rest, BLACKLIST);

    const fragment = fragments[0]
      ? this.setFragmentMeta(fragments[0].fragmentType)
      : null;

    return (
      <Drawer anchor="right" {...other}>
        <div style={{ width: 550 }}>
          <div style={{ position: 'absolute', top: 0, right: 15, zIndex: 100 }}>
            <IconButton
              onClick={() => {
                this.props.openJournalModal(false);
                this.props.onCancel();
              }}
            >
              <Icon style={{ color: '#fff' }}>close</Icon>
            </IconButton>
          </div>
          <Tabs
            onChange={this.onTabChange}
            value={this.state.activePanelIndex}
            textColor={fragment ? '#fff' : '#fff'}
            indicatorColor={fragment ? fragment.color : 'primary'}
            variant="fullWidth"
            style={
              fragment
                ? {
                    backgroundColor: fragment.color,
                    border: 'none',
                    color: '#ffffff',
                  }
                : {
                    backgroundColor: '#006AB0',
                    color: '#ffffff',
                  }
            }
          >
            <Tab label={fragment ? `${fragment.name}` : 'Entry'} />
            {/* <Tab label="Attachments" /> */}
          </Tabs>
          <SwipeableViews
            onChangeIndex={this.onTabIndexChange}
            index={this.state.activePanelIndex}
          >
            <div style={{ minHeight: '500px', overflow: 'hidden' }}>
              {this.renderActiveView()}
              {/*
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '400px',
                                }}
                            >
                                <EasterEgg
                                    id="1"
                                    message="You found another egg! CODE: GOODEGG"
                                />
                            </div>
                        */}
            </div>
          </SwipeableViews>
        </div>
      </Drawer>
    );
  }
}

JournalEntryDrawer.propTypes = {
  onChange: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFragmentDialogOpened: state.journalCompose.isFragmentDialogOpened,
  currentFragmentDialog: state.journalCompose.currentFragmentDialog,
  body: state.journalCompose.body,
  tags: state.journalCompose.tags,
  fragments: state.journalCompose.fragments,
  fragmentIndexToUpdate: state.journalCompose.fragmentIndexToUpdate,
  journalCompose: state.journalCompose,
  dependents: state.dependents,
  user: state.active.user,
  mediaObjects: state.mediaObject.bundles,
  isUploadingFile: state.mediaObject.isUploadingFiles,
  reduxForm: state.form,
  syncErrors: !!state.form.reduxForm
    ? !!state.form.reduxForm.syncErrors
    : false,
  hasMediaObjectUpdates: state.journalCompose.hasMediaObjectUpdates.length > 0,
});

const mapDispatchToProps = dispatch => ({
  openJournalModal: (bool, payload) =>
    dispatch(openJournalModal(bool, payload)),
  openJournalFragmentDialog: type => dispatch(openJournalFragmentDialog(type)),
  closeJournalFragmentDialog: () => dispatch(closeJournalFragmentDialog()),
  createFragment: payload => dispatch(createFragment(payload)),
  changeFragment: payload => dispatch(changeFragment(payload)),
  updateFragment: (payload, index) => dispatch(updateFragment(payload, index)),
  deleteFragment: index => dispatch(deleteFragment(index)),
  setFragmentForUpdate: index => dispatch(setFragmentForUpdate(index)),
  setJournalMessage: payload => dispatch(setJournalMessage(payload)),
  setFiles: files => dispatch(setFiles(files)),
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  deleteMedia: (pid, aid, appArea, referenceId) =>
    dispatch(deleteMedia(pid, aid, appArea, referenceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(JournalEntryDrawer));
