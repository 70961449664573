import { loadUserInfo } from '../../../../store/reducers/application-invitation/actions';

const isEmailAddressUnique = email => {
  const options = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ email }),
  };

  return new Promise(resolve =>
    fetch(
      `${window.__env.API_URL}/api/registration/email/unique`,
      options
    ).then(response =>
      response.json().then(json => {
        if (!response.ok && json.code === 14) {
          resolve(false);
        }
        resolve(true);
      })
    )
  );
};

const isInvitationCodeValid = code =>
  new Promise(resolve =>
    fetch(
      `${window.__env.API_URL}/api/member/validate/invitation/${code}`
    ).then(response => {
      if (!response.ok) resolve(false);

      resolve(response);
    })
  );

export default (values, dispatch, props, currentFieldName) => {
  const previousErrors = props.asyncErrors;

  if (currentFieldName === 'invitationCode') {
    return isInvitationCodeValid(values.invitationCode).then(response => {
      if (!response) {
        throw {
          ...previousErrors,
          invitationCode:
            'Invitation Code is not valid or has already been used. Please try again or contact Agrin Health support at support@agrinhealth.com.',
        };
      }

      return response.json().then(json => {
        if (json.status === 200) {
          if (
            props.code !== json.data.code ||
            (!values.first_name && !values.lastName)
          ) {
            dispatch(props.change('firstName', json.data.firstName));
            dispatch(props.change('lastName', json.data.lastName));
          }
          dispatch(loadUserInfo(json));
        }

        return json;
      });
    });
  }

  if (currentFieldName === 'email') {
    return isEmailAddressUnique(values.email).then(result => {
      if (!result) {
        throw {
          ...previousErrors,
          email: 'Email address is already taken',
        };
      }
    });
  }

  return new Promise(resolve => resolve(true));
};
