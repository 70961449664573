import React, { useState } from 'react';
import {
  Avatar,
  withStyles,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  avatar: {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  typography: {
    letterSpacing: '-6px',
    color: 'white',
  },
  avatarTypography: {
    letterSpacing: '-2px',
  },
});

const COLORS = [
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#C2185B',
  '#00796B',
  '#388E3C',
  '#E64A19',
  '#E65100',
  '#616161',
  '#455A64',
];

const BASE_LINE = 5;

function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col.charAt(0) === '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

const DependentListItemAvatar = ({
  firstName,
  lastName,
  index,
  avatarUrl = '',
}) => {
  const classes = useStyles();
  const getFirstChar = text => (text && text[0] ? text[0] : '?');
  const getFirstCode = text => (text ? text.charCodeAt(0) : 0);

  const fNameChar = getFirstChar(firstName);
  const lNameChar = getFirstChar(lastName);
  const fNameCode = getFirstCode(fNameChar);
  const lNameCode = getFirstCode(lNameChar);
  const codeIndex = fNameCode + lNameCode - BASE_LINE;
  const colorsIdx = codeIndex % COLORS.length;

  const dark = lightenDarkenColor(COLORS[colorsIdx], 20);
  const light = lightenDarkenColor(COLORS[colorsIdx], 55);
  {
    /* commented until avatars are available
             * return
                !!avatarUrl ? (
                    <CardMedia
                        style={{
                            width: 40,
                            marginRight: 16
                        }}
                        className={classes.avatar}
                    >
                        <Avatar
                            src={avatarUrl}
                            variant="circle"
                            alt={`${firstName} ${lastName}`}
                            imgProps={{
                                style: { border: 0, height: "50px", width: "50px" }
                            }}
                        />
                    </CardMedia>
                ) : ( */
  }
  return (
    <CardMedia
      style={{
        width: 40,
        marginRight: 16,
      }}
      className={classes.avatar}
      alt={`${firstName} ${lastName}`}
    >
      <Avatar
        style={{
          background: `linear-gradient(135deg, ${light} 50%, ${dark} 50%)`,
        }}
        className={classes.avatar}
        alt={`${firstName} ${lastName}`}
      >
        <Typography className={classes.avatarTypography}>
          {getFirstChar(firstName).toUpperCase()}{' '}
          {getFirstChar(lastName).toUpperCase()}
        </Typography>
      </Avatar>
    </CardMedia>
  );
};

export default DependentListItemAvatar;
