import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CalendarInput from '../Calendar';
import TimeInput from '../TimeInput';

import classes from './DateTimeInput.css';

class DateTimeInput extends Component {
  state = {
    datePanelOpen: true,
    timePanelOpen: false,
    date: undefined,
    time: undefined,
  };

  componentDidMount() {
    this.setState({
      date: this.props.date,
    });
  }

  onClickPanel = (prop, bool) => event => {
    this.setState({
      [`${prop}PanelOpen`]: bool,
      [`${prop === 'date' ? 'time' : 'date'}PanelOpen`]: !bool,
    });
  };

  onTimeChange = value => {
    const state = {
      ...this.state,
      time: value,
    };

    this.setState(state);
  };

  onDateChange = (day, today) => {
    const state = {
      ...this.state,
      date: day,
    };

    this.setState(state);
  };

  onSelection = event => {
    const date = this.state.date ? this.state.date : this.props.date;
    const { time } = this.state;

    if (!this.state.time) {
      date.setHours(this.props.date.getHours(), this.props.date.getMinutes());
    } else {
      date.setHours(
        time.period === 'AM' && time.hours === 12
          ? 0
          : time.period === 'PM' && time.hours < 12
          ? time.hours + 12
          : time.hours,
        this.state.time.minutes
      );
    }

    this.props.onChange(date);
    this.props.onCancel(event);
  };

  render() {
    const {
      date,
      is24hr,
      onCancel,
      onChange,
      onDayClick,
      showCurrentDay,
      showSelectedDay,
      minYear,
      maxYear,
      maxColumnsYear,
      addColumnsYear,
      isAccurateYear,
      isWeekendsDisabled,
      disabledDays,
      innerRef,
      disableTime,
      min,
      max,
      ...rest
    } = this.props;

    return this.state.timePanelOpen ? (
      <div {...rest} ref={innerRef} className={classes.calendar}>
        <TimeInput date={date} is24hr={is24hr} onChange={this.onTimeChange} />
        <div className={classes.footer}>
          <div className={classes.switch}>
            <button
              className={classes.btn}
              onClick={this.onClickPanel('date', true)}
            >
              📅 Select Date
            </button>
          </div>
          <button className={classes.btn} onClick={onCancel}>
            Cancel
          </button>
          <button
            className={classNames(classes.btn, classes.cta)}
            onClick={this.onSelection}
          >
            Save
          </button>
        </div>
      </div>
    ) : (
      <div {...rest} ref={innerRef} className={classes.calendar}>
        <CalendarInput
          date={date}
          showCurrentDay={showCurrentDay}
          showSelectedDay={showSelectedDay}
          minYear={minYear}
          maxYear={maxYear}
          maxColumnsYear={maxColumnsYear}
          addColumnsYear={addColumnsYear}
          onDayClick={this.onDateChange}
          isAccurateYear={isAccurateYear}
          isWeekendsDisabled={isWeekendsDisabled}
          disabledDays={disabledDays}
          min={min}
          max={max}
        />
        <div className={classes.footer}>
          {!disableTime && (
            <div className={classes.switch}>
              <button
                className={classes.btn}
                onClick={this.onClickPanel('time', true)}
              >
                🕑 Select Time
              </button>
            </div>
          )}
          <button className={classes.btn} onClick={onCancel}>
            Cancel
          </button>
          <button
            className={classNames(classes.btn, classes.cta)}
            onClick={this.onSelection}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

DateTimeInput.propTypes = {
  date: PropTypes.object.isRequired,
  is24Hr: PropTypes.bool, //commented to prevent console error
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showCurrentDay: PropTypes.bool.isRequired,
  showSelectedDay: PropTypes.bool.isRequired,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number,
  maxColumnsYear: PropTypes.number.isRequired,
  addColumnsYear: PropTypes.number.isRequired,
  isAccurateYear: PropTypes.bool.isRequired,
  isWeekendsDisabled: PropTypes.bool.isRequired,
  disabledDays: PropTypes.array,
  disableTime: PropTypes.bool,
  min: PropTypes.object,
  max: PropTypes.object,
};

DateTimeInput.defaultProps = {
  date: new Date(),
  is24hr: false,
  showCurrentDay: true,
  showSelectedDay: true,
  minYear: 1900,
  maxYear: undefined,
  maxColumnsYear: 0,
  addColumnsYear: 0,
  isAccurateYear: false,
  isWeekendsDisabled: false,
  disableTime: false,
  min: null,
  max: null,
};

export default DateTimeInput;
