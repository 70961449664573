import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import DashboardSidebarNav from './DashboardSidebarNav';
import styles from './DashboardSidebar.css';

class DashboardSidebar extends React.Component {
  render() {
    const { navigation, reportModalOpen, toggleReportModal } = this.props;

    return (
      <div className={styles.sidebar}>
        <DashboardSidebarNav
          navigation={navigation}
          drawerToggle={this.props.drawerToggle}
          reportModalOpen={reportModalOpen}
          toggleReportModal={toggleReportModal}
        />
      </div>
    );
  }
}

DashboardSidebar.propTypes = {
  defaultImageSrc: PropTypes.string.isRequired,
  defaultAvatarImageSrc: PropTypes.string.isRequired,
  avatarImageSrc: PropTypes.string,
  onSubmitAvatar: PropTypes.func.isRequired,
};

export default withRouter(DashboardSidebar);
