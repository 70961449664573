import { Grid } from '@material-ui/core';
import React from 'react';
import InputField from '../../../../../library/forms/InputField';
import {
  postalCodeMask,
  postalCodeNormalizer,
} from '../../../../components/RequestMedicalRecord/validations';
import ProgressButton from '../../../../components/Buttons/ProgressButton';
import { Field } from 'redux-form';
import { isOver18 } from '../../../../components/RequestMedicalRecord/validations';

export default function SearchCard({
  canVerify,
  searchDependent,
  isSearching,
  canCreateDependent,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Adults can only be considered dependents under certain conditions listed
        below. Otherwise, they must create their own account.
      </Grid>
      <Grid item xs={12}>
        <ul>
          <li style={{ listStyleType: 'circle', marginBottom: '8px' }}>
            Person is at least 18 years of age, but has been found to be
            incapacitated or otherwise mentally unfit to manage their own
            affairs.
          </li>
          <li style={{ listStyleType: 'circle' }}>
            Person is at least 18 years of age and has granted authority to
            another person to manage their healthcare information.
          </li>
        </ul>
      </Grid>
      <Grid item xs={3}>
        <Field
          disabled={isSearching || canCreateDependent}
          name="firstName"
          label="First name"
          component={InputField}
          enableMinHeight={false}
          required
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          disabled={isSearching || canCreateDependent}
          name="lastName"
          label="Last name"
          component={InputField}
          enableMinHeight={false}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <Field
          disabled={isSearching || canCreateDependent}
          name="dob"
          label="Date of Birth"
          validate={[isOver18]}
          component={InputField}
          enableMinHeight={false}
          type="date"
          shrink
          required
        />
      </Grid>
      <Grid item xs={2}>
        <Field
          disabled={isSearching || canCreateDependent}
          name="postalCode"
          label="Zipcode"
          enableMinHeight={false}
          component={InputField}
          type="text"
          format={postalCodeMask}
          normalize={postalCodeNormalizer}
          inputProps={{ maxLength: 10 }}
          shrink
          required
        />
      </Grid>
      <Grid
        item
        xs={2}
        style={{ display: 'flex', alignItems: 'center', height: '77px' }}
      >
        <ProgressButton
          disabled={!canVerify || canCreateDependent}
          isPending={isSearching}
          onClick={searchDependent}
          color="primary"
        >
          Verify
        </ProgressButton>
      </Grid>
    </Grid>
  );
}
