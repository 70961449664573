import React from 'react';
import { Grid } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import {
  sleepQualityValues,
  SleepQuantityField,
} from '../../../components/JournalCompose/fragments/Sleep/Fields';

const TotalSleepViewer = ({ data, classes }) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={data.sleepQuality ? 8 : 12}>
        <SleepQuantityField
          label="Total Sleep"
          initialValue={data.totalTimeSlept}
          value={data.totalTimeSlept}
          disabled
        />
      </Grid>
      {data.sleepQuality && (
        <Grid item md={4}>
          <InputField
            label="Sleep Quality"
            shrink={true}
            value={sleepQualityValues[Math.ceil(data.sleepQuality)]}
            InputProps={{ readOnly: true, disabled: true }}
            enableMinHeight={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TotalSleepViewer;
