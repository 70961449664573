import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const NOTIFICATIONS_GLOBAL_CLEAR = 'notifications/local/cleared';
export const READ_NOTIFICATION = 'notification/read';

export const NOTIFICATIONS_GLOBAL_RECEIVED = 'notifications/global/RECEIVED';
export const NOTIFICATIONS_RECEIVED = 'notifications/RECEIVED';

export const NOTIFICATIONS_PERSON_CLEAR_REQUEST =
  'notifications/person/CLEAR_REQUEST';
export const NOTIFICATIONS_PERSON_CLEAR_SUCCESS =
  'notifications/person/CLEAR_SUCCESS';
export const NOTIFICATIONS_PERSON_CLEAR_FAILURE =
  'notifications/person/CLEAR_FAILURE';

export const NOTIFICATIONS_PERSON_REQUEST = 'notifications/person/REQUEST';
export const NOTIFICATIONS_PERSON_SUCCESS = 'notifications/person/SUCCESS';
export const NOTIFICATIONS_PERSON_FAILURE = 'notifications/person/FAILURE';

export const NOTIFICATIONS_GLOBAL_REQUEST = 'notifications/global/REQUEST';
export const NOTIFICATIONS_GLOBAL_SUCCESS = 'notifications/global/SUCCESS';
export const NOTIFICATIONS_GLOBAL_FAILURE = 'notifications/global/FAILURE';

export const NOTIFICATION_PERSON_MARK_REQUEST =
  'notifications/person/MARK_REQUEST';
export const NOTIFICATION_PERSON_MARK_SUCCESS =
  'notifications/person/MARK_SUCCESS';
export const NOTIFICATION_PERSON_MARK_FAILURE =
  'notifications/person/MARK_FAILURE';

export const NOTIFICATION_GLOBAL_MARK_REQUEST =
  'notifications/global/MARK_REQUEST';
export const NOTIFICATION_GLOBAL_MARK_SUCCESS =
  'notifications/global/MARK_SUCCESS';
export const NOTIFICATION_GLOBAL_MARK_FAILURE =
  'notifications/global/MARK_FAILURE';

export const receivedGlobalNotifications = payload => ({
  type: NOTIFICATIONS_GLOBAL_RECEIVED,
  payload,
});

export const receivedNotifications = payload => ({
  type: NOTIFICATIONS_RECEIVED,
  payload,
});

export const getGlobalNotifications = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      NOTIFICATIONS_GLOBAL_REQUEST,
      NOTIFICATIONS_GLOBAL_SUCCESS,
      NOTIFICATIONS_GLOBAL_FAILURE,
    ],
    endpoint: '/api/notifications/global',
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const getPersonNotifications = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      NOTIFICATIONS_PERSON_REQUEST,
      NOTIFICATIONS_PERSON_SUCCESS,
      NOTIFICATIONS_PERSON_FAILURE,
    ],
    endpoint: '/api/notifications/person',
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const setNotifications = payload => ({
  type: NOTIFICATIONS_RECEIVED,
  payload,
});

export const clearGlobalNotifications = () => (dispatch, getState) => {
  const state = getState();
  const personNotification = state.notification.person;
  const listIds = personNotification.listIds.filter(
    id => !Boolean(personNotification.list[id].clearedOn)
  );

  return dispatch({
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        NOTIFICATIONS_PERSON_CLEAR_REQUEST,
        NOTIFICATIONS_PERSON_CLEAR_SUCCESS,
        NOTIFICATIONS_PERSON_CLEAR_FAILURE,
      ],
      endpoint: '/api/notifications/person/mark/multiple',
      payload: listIds,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PATCH',
          mode: 'cors',
          body: JSON.stringify({
            personNotificationIds: listIds,
            action: 'clear',
          }),
        },
      },
    },
  });
};

export const markNotification = (action, notification, type = 'person') => {
  type = type.toLowerCase();
  if (type !== 'person' && type !== 'global') {
    throw new Error(`Unsupported notification mark type "${type}"`);
  }

  const isPersonType = type === 'person';
  const idPropName = isPersonType
    ? 'personNotificationId'
    : 'globalNotificationId';

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: isPersonType
        ? [
            NOTIFICATION_PERSON_MARK_REQUEST,
            NOTIFICATION_PERSON_MARK_SUCCESS,
            NOTIFICATION_PERSON_MARK_FAILURE,
          ]
        : [
            NOTIFICATION_GLOBAL_MARK_REQUEST,
            NOTIFICATION_GLOBAL_MARK_SUCCESS,
            NOTIFICATION_GLOBAL_MARK_FAILURE,
          ],
      endpoint: `/api/notifications/${type}/mark`,
      payload: {
        action,
        notification,
      },
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PATCH',
          mode: 'cors',
          body: JSON.stringify({
            [idPropName]: notification[idPropName],
            action,
          }),
        },
      },
    },
  };
};
