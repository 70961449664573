import React, { useState, useEffect } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import { Typography, TextField, InputAdornment, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from './FragmentDialogBase';
import { totalSleepValidation, maxLength, required } from './Validations';
import ErrorTooltip from './Forms/ErrorTooltip';

const maxLength4 = maxLength(4);

export const SLEEP_TRACKER_FIELDS = {
  sleptOn: null,
  awokeOn: null,
  totalTimeSlept: '',
};

const useStyles = makeStyles({
  formLabel: {
    fontSize: '12px',
  },

  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  rowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  rowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '2x',
  },
  header: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    marginTop: '-10px',
  },
  textField: {
    color: '#0068A8',
    marginTop: '6px',
  },
  totalSleepFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalSleep: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '-10px',
  },
  focused: {},
});

const TotalSleepForm = props => {
  const {
    input: { onChange, value, onFocus },
    meta: { error, touched },
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <div style={{ minHeight: '80px' }}>
      <ErrorTooltip {...props} displayErrorEndAdornment={true}>
        <TextField
          {...props}
          margin="dense"
          className={classes.textField}
          placeholder={'0.0'}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          name="totalTimeSlept"
          type="number"
          FormHelperTextProps={{ style: { color: 'red' } }}
          margin="dense"
          inputProps={{
            style: {
              color: '#0068A8',
              textAlign: 'center',
              padding: '9px 16px',
              marginLeft: '-9px',
              marginRight: '-10px',
            },
            min: 1,
            max: 24.0,
          }}
          InputProps={{
            style: {
              borderRadius: 4,
              border: '1px solid #0068A8',
              backgroundColor: '#EFF1F3',
            },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  color: '#0068A8 ',
                  marginRight: '25px',
                  lineHeight: '1.5',
                }}
              >
                hrs
              </InputAdornment>
            ),
          }}
        />
      </ErrorTooltip>
    </div>
  );
};

const RenderForm = ({ onChange }) => (
  <Field
    name="totalTimeSlept"
    component={TotalSleepForm}
    normalize={value => parseFloat(value)}
    validate={[maxLength4, required, totalSleepValidation]}
  />
);

const SleepTrackingDialog = ({ fragment }) => {
  const classes = useStyles();
  return (
    <FragmentDialogBase
      color="#2AA5A2"
      dateEndAdornment={'Drift - Sleep Start'}
      formTitle="Sleep"
      FormSlot={
        <Grid container spacing={0}>
          <Grid item xs={5} className={classes.totalSleep}>
            <Typography className={classes.header}>Total Sleep</Typography>
          </Grid>
          <Grid item xs={7} className={classes.totalSleepFields}>
            <RenderForm />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapStateToProps = state => ({
  initialValues: {
    ...SLEEP_TRACKER_FIELDS,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : {}),
  },
});

const SleepTrackerFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(SleepTrackingDialog));

export default connect(mapStateToProps)(SleepTrackerFormRedux);
