export const MIME_TYPE_TEXT_PLAIN = 'text/plain';
export const MIME_TYPE_TEXT_CSV = 'text/csv';
export const MIME_TYPE_TEXT_TSV = 'text/tsv';
export const MIME_TYPE_TEXT_PSV = 'text/psv';

export const MIME_TYPE_IMAGE_JPG = 'image/jpg';
export const MIME_TYPE_IMAGE_JPEG = 'image/jpeg';
export const MIME_TYPE_IMAGE_PNG = 'image/png';
export const MIME_TYPE_IMAGE_WEBP = 'image/webp';

export const MIME_TYPE_APPLICATION_PDF = 'application/pdf';
export const MIME_TYPE_APPLICATION_WORD = 'application/msword';
export const MIME_TYPE_APPLICATION_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MIME_TYPE_APPLICATION_EXCEL = 'application/vnd.ms-excel';
export const MIME_TYPE_APPLICATION_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const MIME_TYPE_VIDEO_MP4 = 'video/mp4';

export const MIME_TYPE_DATA = {
  [MIME_TYPE_APPLICATION_WORD]: {
    type: 'application',
    extension: 'doc',
    description: 'Microsoft Word document',
  },
  [MIME_TYPE_APPLICATION_DOCX]: {
    type: 'application',
    extension: 'docx',
    description: 'Microsoft Word document',
  },
  [MIME_TYPE_APPLICATION_PDF]: {
    type: 'application',
    extension: 'pdf',
    description: 'Adobe PDF (Portal document format)',
  },
  [MIME_TYPE_APPLICATION_EXCEL]: {
    type: 'application',
    extension: 'xls',
    description: 'Microsoft Excel spreed sheet',
  },
  [MIME_TYPE_APPLICATION_XLSX]: {
    type: 'application',
    extension: 'xlsx',
    description: 'Microsoft Excel spreed sheet',
  },
  [MIME_TYPE_TEXT_PLAIN]: {
    type: 'text',
    extension: 'txt',
    description: 'Text document',
  },
  [MIME_TYPE_TEXT_CSV]: {
    type: 'text',
    extension: 'csv',
    description: 'Comma separated value text document',
  },
  [MIME_TYPE_TEXT_PSV]: {
    type: 'text',
    extension: 'psv',
    description: 'Pipe separated value text document',
  },
  [MIME_TYPE_TEXT_TSV]: {
    type: 'text',
    extension: 'tsv',
    description: 'Tab separated value text document',
  },
  [MIME_TYPE_IMAGE_JPG]: {
    type: 'image',
    extension: 'jpg',
    description: 'Image',
  },
  [MIME_TYPE_IMAGE_JPEG]: {
    type: 'image',
    extension: 'jpg',
    description: 'Image',
  },
  [MIME_TYPE_IMAGE_PNG]: {
    type: 'image',
    extension: 'png',
    description: 'Image',
  },
  [MIME_TYPE_IMAGE_WEBP]: {
    type: 'image',
    extension: 'webp',
    description: 'Image',
  },
  [MIME_TYPE_VIDEO_MP4]: {
    type: 'video',
    extension: 'mp4',
    description: 'Video',
  },
};

export const MIME_TYPES_TEXT = [
  MIME_TYPE_TEXT_PLAIN,
  MIME_TYPE_TEXT_CSV,
  MIME_TYPE_TEXT_TSV,
  MIME_TYPE_TEXT_PSV,
];

export const MIME_TYPES_IMAGE = [
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
];

export const MIME_TYPES_VIDEO = [MIME_TYPE_VIDEO_MP4];

export const MIME_TYPES_APPLICATION = [
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_APPLICATION_WORD,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_EXCEL,
  MIME_TYPE_APPLICATION_XLSX,
];

export const MIME_TYPES_ALL = [
  ...MIME_TYPES_TEXT,
  ...MIME_TYPES_IMAGE,
  ...MIME_TYPES_VIDEO,
  ...MIME_TYPES_APPLICATION,
];

export const isTextMimeType = mimeType => MIME_TYPES_TEXT.includes(mimeType);

export const isApplicationMimeType = mimeType =>
  MIME_TYPE_APPLICATION.includes(mimeType);

export const isValidMimeType = mimeType => MIME_TYPES_ALL.includes(mimeType);

export const isVideoMimeType = mimeType => MIME_TYPES_VIDEO.includes(mimeType);

export const isImageMimeType = mimeType => MIME_TYPES_IMAGE.includes(mimeType);
