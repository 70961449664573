import React, { useState } from 'react';
import { Fade, makeStyles } from '@material-ui/core';

import ConfirmationModal from './ConfirmationModal';
import CustomCard from './CustomCard';
import constants from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    marginTop: '8px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    gridGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Component = ({ listIds, list, onClick, isBrowsing }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // TODO this operation should be handled in the SyncPermissionPage
  // instead of the inner component
  const handleClick = (id, type) => {
    if (type === constants.TYPE_REAUTH) {
      onClick(id, type);
      return;
    }

    setSelectedId(id);
    setModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        open={selectedId && modalOpen}
        onClick={onClick}
        handleClose={() => handleClick(null)}
        isBrowsing={isBrowsing}
        bundle={selectedId ? list[selectedId] : null}
      />
      {listIds
        .sort(function(a, b) {
          if (list[a].title < list[b].title) {
            return -1;
          }
          if (list[a].title > list[b].title) {
            return 1;
          }
          return 0;
        })
        .map(val => {
          return (
            <Fade key={`card` + list[val].id} in={true} timeout={200}>
              <CustomCard
                item={list[val]}
                width="100%"
                height="200px"
                onClick={handleClick}
              />
            </Fade>
          );
        })}
    </div>
  );
};

const ResourceCardView = React.memo(Component);
export default ResourceCardView;
