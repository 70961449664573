import {
  REPORTS_JOURNAL_CREATE_REQUEST,
  REPORTS_JOURNAL_CREATE_SUCCESS,
  REPORTS_JOURNAL_CREATE_FAILURE,
  REPORTS_DOWNLOAD_REQUEST,
  REPORTS_DOWNLOAD_SUCCESS,
  REPORTS_DOWNLOAD_FAILURE,
  REPORTS_ACTIVE_SET_DOWNLOAD,
  CLEAR_REPORTS,
} from './actions';

import {
  REPORT_HEALTH_STATUS_REQUEST,
  REPORT_HEALTH_STATUS_SUCCESS,
  REPORT_HEALTH_STATUS_FAILURE,
} from '../healthStatus/actions';

export const GENERAL = 'general';
export const HEALTH_STATUS = 'health_status';

const initialState = {
  isSubmitting: false,
  report: {},
  error: '',
  type: GENERAL,
};

const reducers = {};

reducers[REPORTS_ACTIVE_SET_DOWNLOAD] = (state, action) => ({
  ...state,
  report: action.payload,
});

reducers[REPORTS_JOURNAL_CREATE_REQUEST] = (state, action) => ({
  ...state,
  isSubmitting: true,
  type: GENERAL,
});

reducers[REPORTS_JOURNAL_CREATE_SUCCESS] = (state, action) => ({
  ...state,
  isSubmitting: false,
});

reducers[REPORTS_JOURNAL_CREATE_FAILURE] = (state, action) => ({
  ...state,
  isSubmitting: false,
  error: action.payload,
});

reducers[REPORTS_DOWNLOAD_REQUEST] = (state, action) => ({
  ...state,
  isSubmitting: true,
});

reducers[REPORTS_DOWNLOAD_SUCCESS] = (state, action) => ({
  ...state,
  isSubmitting: false,
});

reducers[REPORTS_DOWNLOAD_FAILURE] = (state, action) => ({
  ...state,
  isSubmitting: false,
  error: action.payload,
});

reducers[REPORT_HEALTH_STATUS_REQUEST] = (state, action) => {
  return {
    ...state,
    isSubmitting: true,
    type: HEALTH_STATUS,
  };
};

reducers[REPORT_HEALTH_STATUS_SUCCESS] = (state, action) => {
  return {
    ...state,
    isSubmitting: false,
    report: action.payload,
  };
};

reducers[REPORT_HEALTH_STATUS_FAILURE] = (state, action) => {
  return {
    ...state,
    isSubmitting: false,
    error: action.payload,
  };
};

reducers[CLEAR_REPORTS] = (state, action) => {
  return {
    ...state,
    report: {},
  };
};

export default (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};
