import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format, parseISO } from 'date-fns';
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  Checkbox,
  Tooltip,
  withStyles,
  InputAdornment,
  Collapse,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import { fetchCoreImmunizations } from '../../../store/reducers/core/actions';
// import validate from './ValidateForm';
import SelectField from '../../../library/forms/SelectField';
import InputField from '../../../library/forms/InputField';
import CheckBoxField from '../../../library/forms/CheckboxField';
import { csvToCodeObjectList } from '../../../library/helpers/utils';
import ProgressButtonLinear from '../../components/Buttons/ProgressButtonLinear/ProgressButtonLinear';
import AttachmentPanel from './AttachmentPanel';
import FormPanelButton from '../../components/Form/FormButtonPanel';
import StatusField, {
  VERIFIED,
  SELF_ATTESTED,
  UNVERIFIED,
} from '../../components/StatusField';
import { normalizeDecimal } from '../../../library/helpers/format';
import {
  isForCovid,
  required,
  beforeAdministrationOn,
  afterBoosterDate,
  doseValidation,
  totalDosesValidation,
} from './validations';

import DrawerAccordionStandardItems from '../../components/DrawerAccordionStandardItems';

const warn = values => {
  const warnings = {};
  return warnings;
};

const dateFormat = 'yyyy-MM-dd';
const useStyles = makeStyles({
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    padding: 24,
    marginBottom: 32,
    position: 'relative',
    paddingTop: 36,
    zIndex: 2,
  },
  BgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: -1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  Bg: {
    height: '100%',
    objectFit: 'cover',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px',
  },
  checkBoxFields: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: '200px',
    display: 'flex',
    textAlign: 'center',
    fontSize: '14px',
    color: '#535461',
    alignItems: 'flex-start',
  },
  checkBoxText: {
    fontSize: '14px',
    lineHeight: '1',
    height: '24px',
    margin: '8px',
    textAlign: 'start',
    marginTop: '0px',
  },

  //accordion
  root: {
    width: '100%',
  },
  heading: {
    padding: '0px 8px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
  accordion: {
    boxShadow: 'none',
  },
});

const CustomTooltip = withStyles({
  tooltip: {
    marginTop: '-20px',
  },
})(Tooltip);

const SelectFieldIcon = () => (
  <AddCircleIcon style={{ marginTop: 2, color: '#90B850' }} />
);

const ImmunizationForm = props => {
  const {
    handleSubmit,
    isFetchingVaccinationType,
    isFetchingReactionTypes,
    reactionTypes,
    disableSubmit,
    isSubmitting,
    matches550,
    pristine,
    hasBundles,
    deletedMediaObject,
    vaccineFor,
    updateField,
    fetchCoreImmunizations,
    coreVaccineOptions = [],
    initialValues,
    hasFetchedImmunization,
    isEditing,
  } = props;

  const { formValues = initialValues } = props;

  const {
    isSelfAttested,
    purpose,
    labelerName,
    lot,
    dose,
    administratedBy,
    patientNumber,
    totalDosesPerSeries,
    pricePerDose,
    name,
  } = formValues;

  const status = initialValues.status
    ? initialValues.status
    : isSelfAttested
    ? SELF_ATTESTED
    : UNVERIFIED;

  const initialLoad =
    JSON.stringify(formValues) === JSON.stringify(initialValues);
  const [vaccineOptions, setVaccineOptions] = useState(coreVaccineOptions);

  const clearVerifiableFields = () => {
    if (initialLoad) return;
    updateField('name', null);
    updateField('labelerName', null);
    updateField('lot', '');
    updateField('dose', 1);
    updateField('administratedBy', '');
    updateField('patientNumber', '');
    updateField('pricePerDose', 0);
    updateField('totalDosesPerSeries', '');
  };

  useEffect(() => {
    if (!hasFetchedImmunization) {
      fetchCoreImmunizations();
    }
  }, []);

  useEffect(() => {
    const _vaccineOptions = coreVaccineOptions.filter(
      item =>
        Boolean(item.purpose) &&
        purpose &&
        item.purpose.toLowerCase().includes(purpose.toLowerCase())
    );
    clearVerifiableFields();
    setVaccineOptions(_vaccineOptions);
  }, [purpose]);

  useEffect(() => {
    if (Boolean(name) && Boolean(labelerName) && vaccineOptions.length === 1)
      return;
    if (name) {
      const _labelerName = vaccineOptions.filter(item => {
        return (
          Boolean(item.shortName) &&
          name &&
          item.shortName.toLowerCase().includes(name.toLowerCase())
        );
      });
      if (
        _labelerName.length === 1 &&
        labelerName !== _labelerName[0].labelerName
      ) {
        updateField('labelerName', _labelerName[0].labelerName);
      }
      if (JSON.stringify(formValues) === JSON.stringify(initialValues)) {
        setVaccineOptions(_labelerName);
      }
    }
    if (name === null && purpose) {
      setVaccineOptions(
        coreVaccineOptions.filter(
          item =>
            Boolean(item.purpose) &&
            purpose &&
            item.purpose.toLowerCase().includes(purpose.toLowerCase())
        )
      );
    }
  }, [name]);

  useEffect(() => {
    const selected = name
      ? vaccineOptions.find(item => item.longName === name)
      : '';
    if (selected) {
      updateField('coreImmunizationId', selected.coreImmunizationId);
    }
  }, [name]);

  useEffect(() => {
    if (status === SELF_ATTESTED && !initialLoad) {
      updateField('isSelfAttested', false);
    }
  }, [name, purpose, labelerName, lot, dose, administratedBy, patientNumber]);

  useEffect(() => {
    if (initialLoad) return;
    const selectedManufacturer = vaccineOptions.find(
      item => item.labelerName === labelerName
    );
    if (labelerName && selectedManufacturer) {
      if (selectedManufacturer.totalDosesPerSeries > 0) {
        console.log(1);
        updateField(
          'totalDosesPerSeries',
          selectedManufacturer.totalDosesPerSeries
        );
      }
      updateField('pricePerDose', selectedManufacturer.pricePerDose);
    }
  }, [labelerName]);

  const classes = useStyles();
  const isVerified = status.toLowerCase() === VERIFIED.toLowerCase();
  const buttonDisabled =
    Boolean(disableSubmit && !hasBundles && pristine && !deletedMediaObject) ||
    isSubmitting ||
    !purpose;
  const _isForCovid = isForCovid(purpose);

  const requiredForCovid = _isForCovid ? required : [];
  const requiredForCovidHelperText = _isForCovid ? 'required' : '';
  const sortedVaccinationTypes = coreVaccineOptions
    .map(item => {
      return { purpose: item.purpose };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i
    )
    .sort((a, b) => a.purpose.localeCompare(b.purpose));

  const onChangeCheckBox = () => {
    updateField('isSelfAttested', !isSelfAttested);
  };

  const HoverTooltipMsg = isVerified
    ? 'Verified data cannot be changed. You can delete this record and/or create a new one.'
    : '';

  return (
    <div
      style={{ width: !matches550 ? '100vw' : '550px', overflowX: 'hidden' }}
    >
      <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTooltip placement="bottom-end" title={HoverTooltipMsg}>
              <div className={classes.primaryInput}>
                <div className={classes.BgContainer}>
                  <img
                    src={require('../../../client/svgs/surgical-history-add.svg')}
                    alt=""
                    className={classes.Bg}
                  />
                </div>
                <Typography
                  variant="h6"
                  style={{ textShadow: '1px 1px 0px #fff' }}
                >
                  Vaccination Record Details
                </Typography>
                <Field
                  name="purpose"
                  label="Vaccine for"
                  labelKey="purpose"
                  valueKey="purpose"
                  hint="Required"
                  options={sortedVaccinationTypes}
                  component={SelectField}
                  isSearchable
                  disabled={isVerified}
                  props={{
                    containerStyle: {
                      backgroundColor: '#fff',
                    },
                    variant: 'filled',
                  }}
                />
              </div>
            </CustomTooltip>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ padding: '24px' }}>
          <StatusField
            isVerified={isVerified}
            isSelfAttested={isSelfAttested}
          />
          <Grid item xs={12}>
            <CustomTooltip placement="bottom-end" title={HoverTooltipMsg}>
              <div>
                <Field
                  name="name"
                  label="Product Name"
                  labelKey="shortName"
                  valueKey="shortName"
                  isCreatable
                  isClearable
                  // displayEmpty={true}
                  options={vaccineOptions}
                  component={SelectField}
                  MenuProps={{
                    withReset: true,
                  }}
                  HoverTooltipMsg="There can be multiple vaccines for the same type of infection. For example, you may take the Trivalent or the Quadrivalent product for the Flu.  Product Name helps your Care Team know which vaccine product you had which is particularly helpful if you have any side effects or reactions."
                  disabled={isVerified}
                  disableBlur
                />
              </div>
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={8}>
            <CustomTooltip placement="bottom-end" title={HoverTooltipMsg}>
              <div>
                <Field
                  name="labelerName"
                  label="Manufacturer"
                  HoverTooltipMsg="Manufacturer and Lot Number are used to determine who needs to be notified if/when there is a issue with a particular batch."
                  validate={requiredForCovid}
                  labelKey="labelerName"
                  valueKey="labelerName"
                  isClearable
                  isCreatable
                  hint={requiredForCovidHelperText}
                  disabled={isVerified}
                  component={SelectField}
                  options={vaccineOptions}
                  MenuProps={{
                    withReset: true,
                  }}
                />
              </div>
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="lot"
              label="Lot Number"
              validate={requiredForCovid}
              hint={requiredForCovidHelperText}
              disabled={isVerified}
              HoverTooltipMsg={
                !isVerified
                  ? 'Manufacturer and Lot Number are used to determine who needs to be notified if/when there is a issue with a particular batch.'
                  : 'Verified data cannot be changed. You can delete this record and/or create a new one.'
              }
              component={InputField}
            />
          </Grid>

          <Grid item xs={4} lg={3}>
            <Field
              name="dose"
              label="Dose #"
              type="number"
              shrink={true}
              component={InputField}
              validate={doseValidation}
              hint={requiredForCovidHelperText}
              normalize={(value, previousValue, allValues) => {
                if (value > allValues.totalDosesPerSeries) {
                  if (value > 9) return allValues.totalDosesPerSeries;
                }
                if (previousValue === 0 && parseInt(value) === 0) return 0;
                if (value > 9) return parseInt(value[1]);
                if (value <= 0) return 1;
                return parseInt(value);
              }}
              disabled={isVerified}
              HoverTooltipMsg={
                isVerified
                  ? 'Verified data cannot be changed. You can delete this record and/or create a new one.'
                  : 'Some vaccines require a series of administrations (multiple doses) to be effective.  Use Dose # to track your progress in the series.  Enter 1 for 1st dose.  Enter 2 for the 2nd dose, and so on.'
              }
              inputProps={{
                min: 0,
                max: 9,
              }}
            />
          </Grid>
          <Grid item xs={8} md={6}>
            <CustomTooltip placement="bottom-end" title={HoverTooltipMsg}>
              <div>
                <Field
                  name="totalDosesPerSeries"
                  label="Total Doses"
                  type="number"
                  validate={totalDosesValidation}
                  hint={requiredForCovidHelperText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">of</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        in the series.
                      </InputAdornment>
                    ),
                  }}
                  shrink={true}
                  disabled={isVerified}
                  HoverTooltipMsg="Some vaccines require a series of administrations (multiple doses) to be effective.  Use Dose # to track your progress in the series.  Enter 1 for 1st dose.  Enter 2 for the 2nd dose, and so on."
                  inputProps={{
                    min: 0,
                    max: 9,
                  }}
                  component={InputField}
                />
              </div>
            </CustomTooltip>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              id="date"
              type="date"
              name="administrationOn"
              label="Administered On"
              hint="Required"
              disabled={isVerified}
              validate={[required, afterBoosterDate]}
              shrink={true}
              component={InputField}
              placeholder=""
              inputProps={{ max: format(new Date(), dateFormat) }}
              HoverTooltipMsg={HoverTooltipMsg}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              id="date"
              type="date"
              name="boosterDueOn"
              label="Next Dose / Booster Date"
              validate={beforeAdministrationOn}
              shrink={true}
              component={InputField}
              placeholder=""
              // inputProps={{ max: format(new Date(), dateFormat) }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              type="text"
              name="administratedBy"
              label="Administered By"
              placeholder="Name of physician, nurse, pharmacy or lab"
              validate={requiredForCovid}
              hint={requiredForCovidHelperText}
              disabled={isVerified}
              component={InputField}
              HoverTooltipMsg={HoverTooltipMsg}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="patientNumber"
              label="Patient Number"
              type="number"
              validate={requiredForCovid}
              hint={requiredForCovidHelperText}
              component={InputField}
              disabled={isVerified}
              HoverTooltipMsg="The Provider administering your vaccine will store the information in a medical record which has a Patient Number (aka Patient Id or Medical Record Number) assigned to your specific medical record.  If you know that number, enter it here. The CDC requires this to complete the COVID-19 Vaccination Card."
            />
          </Grid>

          {isVerified ? (
            ''
          ) : (
            <Grid>
              <div className={classes.checkBoxFields}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox
                    onChange={onChangeCheckBox}
                    checked={isSelfAttested}
                    style={{
                      color: '#006AB0',
                    }}
                    color="primary"
                  />
                  <Typography color="primary">Optional Attestation:</Typography>
                </div>
                <Typography className={classes.checkBoxText}>
                  For purposes of any Health Status verifications I authorize, I
                  attest that this information is accurate to the best of my
                  knowledge.
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
        <DrawerAccordionStandardItems 
          additionalInformation={
            <Grid container>
              <Grid item xs={6}>
                <Field
                  name="pricePerDose"
                  label="Price per Dose"
                  component={InputField}
                  normalize={value => normalizeDecimal(value)}
                  placeholder="0.00"
                  shrink={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  HoverTooltipMsg="This is the price for commercial use as published by the FDA. The price your insurance carrier negotiates may be different."
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="adverseEvent"
                  label="Reaction(s)"
                  labelKey="shortName"
                  valueKey="shortName"
                  placeholder="Any reactions?"
                  options={reactionTypes}
                  isLoading={isFetchingReactionTypes}
                  disabled={isFetchingReactionTypes}
                  component={SelectField}
                  IconComponent={SelectFieldIcon}
                  isSearchable
                  isCreatable
                  isMulti
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  type="text"
                  name="comments"
                  label="Notes"
                  multiline={true}
                  rows={4}
                  component={InputField}
                />
              </Grid>
            </Grid>
          } 
          attachments={
            <AttachmentPanel immunizationId={props.initialValues.immunizationId} />} 
        />
        <Grid container item xs={12}>
            <FormPanelButton
              actionText="Save"
              cancelText="Cancel"
              onCancel={props.onCancel}
              isDisabled={buttonDisabled}
              isSubmitting={isSubmitting}
            />
          </Grid>
      </form>
    </div>
  );
};

ImmunizationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

const ImmunizationReduxForm = reduxForm({
  form: 'immunization-form',
  touchOnChange: true,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(withRouter(ImmunizationForm));

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) =>
    dispatch(change('immunization-form', field, data)),
  fetchCoreImmunizations: () => dispatch(fetchCoreImmunizations()),
});

const selector = formValueSelector('immunization-form');
export default connect(state => {
  const {
    isSubmitting,
    list,
    selectedId,
    mediaObjects,
    bundlesToDelete,
  } = state.member.userImmunizations;
  const immunization = list[selectedId] ? list[selectedId] : {};
  const syncErrors =
    state.form && state.form['immunization-form']
      ? state.form['immunization-form'].syncErrors
      : {};
  const hasSyncErrors = syncErrors
    ? Boolean(Object.keys(syncErrors).length)
    : false;
  const hasBundles = Boolean(
    state.mediaObject.bundles.filter(item => item.appArea === 'IMMUNIZATION')
      .length
  );
  const hasMediaObjectUpdates = Boolean(
    Object.values(mediaObjects).filter(x => !x).length
  );
  const disableSubmit = isSubmitting || hasSyncErrors || !hasMediaObjectUpdates;
  const initialValues = {
    ...immunization,
    isSelfAttested: immunization.status === SELF_ATTESTED,
    boosterDueOn: immunization.boosterDueOn
      ? format(parseISO(immunization.boosterDueOn), dateFormat)
      : '',
    administrationOn: immunization.administrationOn
      ? format(parseISO(immunization.administrationOn), dateFormat)
      : '',
    adverseEvent: immunization.adverseEvent
      ? csvToCodeObjectList(immunization.adverseEvent)
      : '',
    status: immunization.status || UNVERIFIED,
    dose: immunization.dose || 1,
    pricePerDose: immunization.pricePerDose || 0,
  };

  return {
    hasMediaObjectUpdates,
    disableSubmit,
    hasSyncErrors,
    hasBundles,
    isSubmitting,
    action: state.ui.entity.action,
    deletedMediaObject: Boolean(bundlesToDelete.length),
    initialValues,
    isFetchingVaccinationType: state.codes.isFetchingVaccinationType,
    isFetchingReactionTypes: state.codes.isFetchingReactionTypes,
    reactionTypes: state.codes.reactionTypes,
    vaccinationTypes: state.codes.vaccinationTypes,
    vaccineFor: selector(state, 'purpose'),
    formValues:
      state.form && state.form['immunization-form']
        ? state.form['immunization-form'].values
        : initialValues,
    coreVaccineOptions: state.core.immunization,
    hasFetchedImmunization: state.core.hasFetchedImmunization,
    isEditing: Boolean(initialValues && initialValues.immunizationId),
  };
}, mapDispatchToProps)(ImmunizationReduxForm);
