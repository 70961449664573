export const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(14),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md', 'lg')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(4),
    },
    '& a': {
      color: '#5D7332',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
    },
  },
  stepper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md', 'lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0px',
      backgroundColor: '#fff',
    },
  },
  panel: {
    padding: theme.spacing(10),
  },
  progressBar: {
    marginBottom: theme.spacing(4),
  },
  progressData: {
    marginBottom: theme.spacing(1),
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderLeft: `solid 5px ${theme.palette.warning.main}`,
  },
});
