import React from 'react';
import InputField from '../../../library/forms/InputField';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ResourceFilter = React.memo(({ value, handleChange }) => {
  return (
    <div style={{ padding: '0px 10px 4px' }}>
      <InputField
        type="text"
        shrink
        value={value ? value : ''}
        onChange={e => handleChange(e.target.value)}
        enableMinHeight={false}
        placeholder=" Name | Category | Keyword "
        InputProps={{
          endAdornment:
            value.length === 0 ? (
              <SearchIcon />
            ) : (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => handleChange('')}
                  aria-label="Clear"
                  size="small"
                >
                  <ClearIcon style={{ height: '20px', width: '20px' }} />
                </IconButton>
              </InputAdornment>
            ),
        }}
      />
    </div>
  );
});

export default ResourceFilter;
