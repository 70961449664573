import React from 'react';
import { connect } from 'react-redux';
import { parse } from 'date-fns';

import { ReportModal } from '.';
import { isFetching, hasFetched } from '../../../store/state';
import { fetchLabTests } from '../../../store/reducers/labs/actions';
import { fetchJournalEntries } from '../../../store/reducers/journalEntries/actions';
import { fetchAllergies } from '../../../store/reducers/allergies/actions';
import { fetchConditions } from '../../../store/reducers/conditions/actions';
import { fetchFamilyHistory } from '../../../store/reducers/familyHistory/actions';
import { fetchImmunizations } from '../../../store/reducers/immunizations/actions';
import { mvsFetch } from '../../../store/reducers/mvs/actions';
import { fetchSurgicalHistories } from '../../../store/reducers/surgicalHistory/actions';
import { Typography, Chip, Grid } from '@material-ui/core';
import { REPORT_LOCKED, REPORT_SELECTION, PLAN_BASIC } from './ReportModal';
const LOADING = 'loading';
const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

const headersForAllergies = [
  {
    id: 'title',
    label: 'Allergy Name',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'endDate',
    label: 'End Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'attachments',
    label: 'Attachments',
    numeric: true,
    type: 'number',
    sortable: false,
  },
];

const headersForFamHx = [
  {
    id: 'title',
    label: 'Condition',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'relatives',
    label: 'Relatives',
    numeric: false,
    type: 'array',
    sortable: false,
  },
];

const headersForImmunizations = [
  {
    id: 'title',
    label: 'Vaccine for',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'date',
    label: 'Date Given',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'attachments',
    label: 'Attachments',
    numeric: true,
    type: 'number',
    sortable: false,
  },
];

const headersForJournal = [
  {
    id: 'title',
    label: 'Title',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Notes',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'date',
    label: 'Date/Time',
    numeric: true,
    type: 'datetime',
    sortable: true,
  },
  {
    id: 'attachments',
    label: 'Attachments',
    numeric: true,
    type: 'number',
    sortable: false,
  },
];

const headersForMVS = [
  {
    id: 'title',
    label: 'Name',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'dosage',
    label: 'Dosage',
    numeric: true,
    type: 'number',
    sortable: false,
  },
  {
    id: 'conditions',
    label: 'Related Conditions',
    numeric: false,
    type: 'array',
    sortable: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'endDate',
    label: 'End Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'attachments',
    label: 'Attachments',
    numeric: true,
    type: 'number',
    sortable: false,
  },
];

const headersForSurgicalHx = [
  {
    id: 'title',
    label: 'Title',
    numeric: false,
    type: 'string',
    sortable: true,
  },

  {
    id: 'date',
    label: 'Date/Time',
    numeric: true,
    type: 'date',
    sortable: true,
  },
];

const headersForConditions = [
  {
    id: 'diagnosisDesc',
    label: 'Condition',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'providerName',
    label: 'Diagnosed by',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'relatedMvs',
    label: 'Related Medications',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'diagnosedOn',
    label: 'Diagnosed On',
    numeric: true,
    type: 'date',
    sortable: true,
  },
];

const headersForAll = [
  {
    id: 'category',
    label: 'Category',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'title',
    label: 'Name/Title',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'details',
    label: 'Record Details',
    numeric: false,
    type: 'string',
    sortable: false,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'endDate',
    label: 'End Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'attachments',
    label: 'Attachments',
    numeric: true,
    type: 'number',
    sortable: false,
  },
];

const headersForLabs = [
  {
    id: 'testName',
    label: 'Test Name',
    numeric: false,
    type: 'string',
    sortable: true,
  },
  {
    id: 'collectedOn',
    label: 'Collection Date',
    numeric: true,
    type: 'date',
    sortable: true,
  },
  {
    id: 'results',
    label: 'Result',
    numeric: false,
    type: 'string',
    sortable: false,
  },
];

const ReportContainer = ({
  open,
  toggleOpen,
  personId,
  accountRole,
  ...props
}) => {
  const [template, setTemplate] = React.useState(null);
  const [activeModalState, setActiveModalState] = React.useState(
    REPORT_SELECTION
  );

  React.useEffect(() => {
    if (open && activeModalState === REPORT_SELECTION) {
      if (!hasFetched(props.journalRecords)) {
        props.fetchJournalEntries(personId);
      }
      if (!hasFetched(props.allergies)) {
        props.fetchAllergies(personId);
      }

      if (props.familyHistory.list.length <= 0) {
        props.fetchFamilyHistory(personId);
      }

      if (!hasFetched(props.immunizations)) {
        props.fetchImmunizations(personId);
      }

      if (!hasFetched(props.medications)) {
        props.fetchMVS(personId);
      }

      if (!hasFetched(props.surgicalHistory)) {
        props.fetchSurgicalHistory(personId);
      }

      if (!hasFetched(props.conditions)) {
        props.fetchConditions(personId);
      }

      if (!hasFetched(props.labs)) {
        props.fetchLabTests(personId);
      }
    }
  }, [open, REPORT_SELECTION]);

  React.useEffect(() => {
    // if user account isn't shareable set to locked
    if (accountRole === PLAN_BASIC) {
      setActiveModalState(REPORT_LOCKED);
    } else if (activeModalState === REPORT_LOCKED) {
      setActiveModalState(REPORT_SELECTION);
    }
  }, [accountRole, open]);

  const allItems = {
    ...props.allergies.list,
    ...props.journalRecords.list,
    ...props.immunizations.list,
    ...props.surgicalHistory.list,
    ...props.medications.list,
    ...props.conditions.list,
    ...props.labs.list,
    ...props.familyHistory.list.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.familyHistoryId]: curr,
      }),
      {}
    ),
  };

  const allFetched =
    !isFetching(props.allergies) &&
    !isFetching(props.journalRecords) &&
    !isFetching(props.familyHistory) &&
    !isFetching(props.immunizations) &&
    !isFetching(props.surgicalHistory) &&
    !isFetching(props.medications) &&
    !isFetching(props.conditions);

  const createHeadersForTemplate = () => {
    switch (template) {
      case 'ALLERGIES':
        return headersForAllergies;
      case 'JOURNAL_ENTRIES':
        return headersForJournal;
      case 'MEDICAL_HX':
        return headersForFamHx;
      case 'IMMUNIZATIONS':
        return headersForImmunizations;
      case 'SURGICAL_HX':
        return headersForSurgicalHx;
      case 'MVS':
        return headersForMVS;
      case 'CONDITIONS':
        return headersForConditions;
      case 'LABRESULTS':
        return headersForLabs;
      case 'ALL':
        return headersForAll;
      default:
        return [];
    }
  };

  const normalizeListForReports = () => {
    switch (template) {
      case 'ALLERGIES':
        return {
          resourceType: 'ALLERGIES',
          loading: isFetching(props.allergies),
          items: createList(props.allergies.list, forAllergy),
        };
      case 'JOURNAL_ENTRIES':
        return {
          resourceType: 'JOURNAL_ENTRIES',
          loading: isFetching(props.journalRecords),
          items: createList(props.journalRecords.list, forJournal),
        };
      case 'MEDICAL_HX':
        return {
          resourceType: 'MEDICAL_HX',
          loading: isFetching(props.familyHistory),
          items: props.familyHistory.list.map(forFamilyHx),
        };
      case 'IMMUNIZATIONS':
        return {
          resourceType: 'IMMUNIZATIONS',
          loading: isFetching(props.immunizations),
          items: createList(props.immunizations.list, forImmunizations),
        };
      case 'SURGICAL_HX':
        return {
          resourceType: 'SURGICAL_HX',
          loading: isFetching(props.surgicalHistory),
          items: createList(props.surgicalHistory.list, forSurgicalHx),
        };
      case 'MVS':
        return {
          resourceType: 'MVS',
          loading: isFetching(props.medications),
          items: createList(props.medications.list, forMVS),
        };
      case 'CONDITIONS':
        return {
          resourceType: 'CONDITIONS',
          loading: isFetching(props.conditions),
          items: createList(props.conditions.list, forConditions),
        };
      case 'LABRESULTS':
        return {
          resourceType: 'LABRESULTS',
          loading: props.labs.uiState === LOADING,
          items: createList(props.labs.list, forLabs),
        };
      case 'ALL':
        return {
          resourceType: 'ALL',
          loading: !allFetched,
          items: createList(allItems, forAll),
        };
      default:
        return { resourceType: 'NO_RESOURCE', loading: false, items: [] };
    }
  };

  const createList = (list, returnType) => {
    const items = [];
    for (const key in list) {
      items.push(returnType(list[key]));
    }
    return items;
  };

  const forAllergy = item => {
    return {
      id: item.allergyId,
      data: { ...item, recordType: 'ALLERGIES' },
      columns: [
        item.allergenType || '--',
        item.startOn ? new Date(item.startOn) : null,
        item.endOn && typeof item.endOn !== 'undefined'
          ? new Date(item.endOn)
          : null,
        item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
      ],
    };
  };

  const forJournal = item => {
    return {
      id: item.journalEntryId,
      data: { ...item, recordType: 'JOURNAL_ENTRIES' },
      columns: [
        item.title || '--',
        item.body
          ? item.body.length > 200
            ? `${item.body.slice(0, 200)}...`
            : item.body
          : '--',
        new Date(item.enteredOn),
        item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
      ],
      queryTags: [item.fragments[0] && item.fragments[0].fragmentType],
    };
  };

  const forFamilyHx = item => {
    return {
      id: item.familyHistoryId === GUID_EMPTY ? item.tid : item.familyHistoryId,
      isTemp: item.familyHistoryId === GUID_EMPTY ? true : false,
      data: {
        ...item,
        recordType: 'MEDICAL_HX',
      },
      columns: [
        item.condition || '--',
        <Grid
          key={item.familyHistoryId}
          container
          wrap="nowrap"
          alignItems="center"
          style={{ overflowX: 'auto' }}
        >
          {relativesList(item).map(data => (
            <Chip
              style={{ marginRight: 16 }}
              key={`fhx-${item.familyHistoryId}-${data}`}
              label={data}
              color="primary"
            />
          ))}
        </Grid>,
      ],
      queryTags: relativesList(item),
    };
  };

  const relativesList = item => {
    const items = [];
    if (item.isMe) items.push('Me');
    if (item.isMother) items.push('Mother');
    if (item.isFather) items.push('Father');
    if (item.isMaternalGrandmother) items.push('Maternal Grandmother');
    if (item.isMaternalGrandfather) items.push('Maternal Grandfather');
    if (item.isPaternalGrandmother) items.push('Paternal Grandmother');
    if (item.isPaternalGrandfather) items.push('Paternal Grandfather');
    if (item.isSon) items.push('Son');
    if (item.isDaughter) items.push('Daughter');
    if (item.isBrother) items.push('Brother');
    if (item.isSister) items.push('Sister');
    if (item.isNone) items.push('None');
    if (item.isUnknown) items.push('Unknown');
    return items;
  };

  const forImmunizations = item => {
    return {
      id: item.immunizationId,
      data: { ...item, recordType: 'IMMUNIZATIONS' },
      columns: [
        item.purpose || '--',
        item.administrationOn ? new Date(item.administrationOn) : null,
        item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
      ],
    };
  };

  const forMVS = item => {
    return {
      id: item.medicationId,
      data: { ...item, recordType: 'MVS' },
      columns: [
        item.name || '--',

        item.dose && item.unit ? (
          <Typography variant="body2">
            <span>
              <strong>{item.dose}</strong>
            </span>{' '}
            {item.unit}
          </Typography>
        ) : (
          '--'
        ),
        item.relatedConditions || '--',
        item.dateStarted ? new Date(item.dateStarted) : null,
        item.dateDiscontinued ? new Date(item.dateDiscontinued) : null,
        item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
      ],
    };
  };

  const forSurgicalHx = item => {
    return {
      id: item.surgicalHistoryId,
      data: { ...item, recordType: 'SURGICAL_HX' },
      columns: [
        item.surgery || '--',
        item.surgeryEndedOn ? new Date(item.surgeryEndedOn) : null,
      ],
    };
  };

  const forConditions = item => {
    return {
      id: item.personDiagnosisId,
      data: { ...item, recordType: 'CONDITIONS' },
      columns: [
        item.diagnosisDesc || '--',
        item.providerName || '--',
        item.relatedMvs || '--',
        item.diagnosedOn ? new Date(item.diagnosedOn) : null,
      ],
    };
  };

  const forLabs = item => {
    return {
      id: item.labResultId,
      data: { ...item, recordType: 'LABRESULTS' },
      columns: [
        item.testName || '--',
        item.collectedOn ? new Date(item.collectedOn) : '--',
        item.result || '--',
      ],
    };
  };

  const forAll = item => {
    const record = setRecordType(item);
    return {
      id: record.id,
      data: { ...item, recordType: record.type },
      columns: setAllFormats(item, record.type),
    };
  };

  const setAllFormats = (item, type) => {
    switch (type) {
      case 'ALLERGIES':
        return [
          'Allergies',
          item.allergenType || '',
          item.comments || '',
          new Date(item.startOn),
          new Date(item.endOn),
          item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
        ];
      case 'JOURNAL_ENTRIES':
        return [
          'Journal Entries',
          item.title || '--',
          item.body || '--',
          new Date(item.enteredOn),
          '',
          'Coming soon',
        ];
      case 'MEDICAL_HX':
        return [
          'Family History',
          item.condition || '--',
          relativesList(item).reduce((acc, curr) => `${acc}, ${curr}`, ''),
          '',
          '',
          '',
        ];
      case 'IMMUNIZATIONS':
        return [
          'Immunizations',
          item.name || '--',
          item.comments || '--',
          new Date(item.administrationOn),
          '',
          item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
        ];
      case 'SURGICAL_HX':
        return [
          'Surgeries',
          item.surgery || '--',
          item.notes || '--',
          new Date(item.surgeryEndedOn),
          '',
          item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
        ];
      case 'MVS':
        return [
          'MVS',
          item.name || '--',
          <React.Fragment>
            {item.dose && item.unit ? (
              <Typography variant="body2">
                <span>
                  <strong>{item.dose}</strong>
                </span>{' '}
                {item.unit}
              </Typography>
            ) : (
              ''
            )}
            <Typography variant="body2">
              {item.relatedConditions || ''}
            </Typography>
          </React.Fragment>,
          item.dateStarted ? new Date(item.dateStarted) : '',
          item.dateDiscontinued ? new Date(item.dateDiscontinued) : '',
          item.mediaObjects.length > 0 ? item.mediaObjects.length : 0,
        ];
      case 'CONDITIONS':
        return [
          'Conditions',
          item.diagnosisDesc || '--',
          item.providerName || '--',
          item.relatedMvs || '--',
          new Date(item.diagnosedOn),
          '',
        ];
      case 'LABRESULTS':
        return [
          'Lab Results',
          item.testName || '--',
          item.collectedOn ? new Date(item.collectedOn) : '--',
          item.result || '--',
        ];
    }
  };

  const setRecordType = item => {
    if (item.surgicalHistoryId) {
      return { type: 'SURGICAL_HX', id: item.surgicalHistoryId };
    }
    if (item.medicationId) {
      return { type: 'MVS', id: item.medicationId };
    }
    if (item.immunizationId) {
      return { type: 'IMMUNIZATIONS', id: item.immunizationId };
    }
    if (item.familyHistoryId) {
      return { type: 'MEDICAL_HX', id: item.familyHistoryId };
    }
    if (item.journalEntryId) {
      return { type: 'JOURNAL_ENTRIES', id: item.journalEntryId };
    }
    if (item.allergyId) {
      return { type: 'ALLERGIES', id: item.allergyId };
    }
    if (item.personDiagnosisId) {
      return { type: 'CONDITIONS', id: item.personDiagnosisId };
    }
    if (item.labResultId) {
      return { type: 'LABRESULTS', id: item.labResultId };
    }
    return { type: 'ANY', id: item.recordId };
  };

  return (
    <ReportModal
      onClose={toggleOpen(false)}
      open={open}
      setTemplate={setTemplate}
      headers={createHeadersForTemplate()}
      rows={normalizeListForReports()}
      template={template}
      accountRole={accountRole}
      setActiveModalState={setActiveModalState}
      activeModalState={activeModalState}
    />
  );
};

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  journalRecords: state.journalEntries,
  allergies: state.allergies,
  familyHistory: state.familyHistory,
  immunizations: state.member.userImmunizations,
  surgicalHistory: state.surgicalHistory,
  medications: state.member.medication,
  conditions: state.conditions,
  accountRole: state.app.user.accountRole,
  labs: state.labs,
});

const mapDispatchToProps = dispatch => ({
  fetchJournalEntries: pid => dispatch(fetchJournalEntries()),
  fetchAllergies: pid => dispatch(fetchAllergies(pid)),
  fetchFamilyHistory: pid => dispatch(fetchFamilyHistory(pid)),
  fetchImmunizations: id => dispatch(fetchImmunizations(id)),
  fetchMVS: id => dispatch(mvsFetch(id)),
  fetchSurgicalHistory: pid => dispatch(fetchSurgicalHistories(pid)),
  fetchConditions: pid => dispatch(fetchConditions(pid)),
  fetchLabTests: pid => dispatch(fetchLabTests(pid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
