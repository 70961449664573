import { parseISO } from 'date-fns';

/**
 * Parse ISO8601 datetime string to return a javascript Date object
 * @param {Date | string} dateTime 
 * @param {boolean} throwError 
 * @returns {Date | null}
 */
const parseDateTime = (dateTime, throwError = true) => {
  if (dateTime instanceof Date && !isNaN(date)) {
    return dateTime;
  }

  const dateTimeType = typeof dateTime;
  if (dateTimeType === 'string') {
    const parsedDateTime = parseISO(dateTime);
    if (isNaN(parsedDateTime.getTime())) {
      if (throwError) throw new Error(`Cannot parse date time value "${dateTime}"`);
      return null;
    }
    return parsedDateTime;
  }

  errorMessage = `Cannot parse datetime with type of "${dateTimeType}" only date and ISO8601 strings allowed with this method`
  if (throwError) throw new Error(errorMessage);

  console.error(errorMessage);
  return null;
}

const parseDate = (date, throwError = true) => {
  if (date instanceof Date && !isNaN(date)) {
    return date;
  } else if (typeof date === 'string') {
    if (date.includes('T00:00:00Z')) {
      var justDate = date.substring(0, date.indexOf('T'));
      return parseISO(justDate); 
    }

    const parsed = parseISO(date);

    if (isNaN(parsed.getTime())) {
      if (throwError) {
        throw new Error(`Cannot parse date with value "${date}"`);
      } else {
        console.log(`Cannot parse date with value "${date}`);
        return null;
      }
    }

    return parsed;
  }

  if (throwError) {
    throw new Error(
      `Cannot parse date with type "${typeof date}" only ISO strings and date objects allowed`
    );
  } else
    console.log(
      `Cannot parse date with type "${typeof date}" only ISO strings and date objects allowed`
    );

  return null;
};

/**
 * Parse ISO8601 datetime string and only factor the date component. The time component is ignored. Typically 
 * this function is used when working with dates that should not have timezone 
 * conversion applied e.g., birthdays.
 * 
 * @param {Date | string} date 
 * @param {boolean} throwError 
 * @returns {Date | null}
 */
const parseDateNew = (date, throwError = true) => {
  if (date instanceof Date && !isNaN(date)) {
    return date;
  }

  if (typeof date === 'string') {
    const dateComponent = _returnOnlyDateComponent(date);
    const parsedDate = parseISO(dateComponent);
    if (isNaN(parsedDate.getTime())) {
      if (throwError) throw new Error(`Cannot parse date time value "${date}"`);
      return null;
    }
    return parsedDate;
  }

  errorMessage = `Cannot parse datetime with type of "${date}" only date and ISO8601 strings allowed with this method`
  if (throwError) throw new Error(errorMessage);

  console.error(errorMessage);
  return null;
}

const _returnOnlyDateComponent = (dateTime) => {
  if (dateTime.includes('T')) {
    const date = dateTime.substring(0, dateTime.indexOf('T'));
    return date;
  }

  return dateTime;
}

export { parseDate, parseDateTime, parseDateNew };
