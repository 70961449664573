import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MyHealthRecordButton from '../MyHealthRecordButton';

const styles = theme => ({
  buttonGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    gridGap: `${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

const ButtonGrid = props => {
  const { classes } = props;
  return (
    <div className={classes.buttonGrid}>
      {props.data
        .filter(b => b.display && b.display === true)
        .map((button, index) => (
          <MyHealthRecordButton
            key={index}
            glyph={button.glyph}
            icon={button.icon || ''}
            route={button.route}
            label={button.label}
            disabled={button.disabled}
            icon={button.icon || ''}
            category={button.category || ''}
          />
        ))}
    </div>
  );
};

PropTypes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      glyph: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      display: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default withStyles(styles)(ButtonGrid);
