import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';

import './AccountActivatedPage.css';

class AccountActivatedPage extends Component {
  componentDidMount() {
    const { history, createMessage } = this.props;
    createMessage(
      'Your email address has been successfully verified and your account is now active! You are now free to login.',
      'success'
    );
    history.push('/');
  }

  render() {
    return (
      <div>
        <h1>Account has been activated!</h1>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createMessage: (msg, type) => dispatch(createMessage(msg, type)),
});

export default {
  component: connect(
    null,
    mapDispatchToProps
  )(withRouter(AccountActivatedPage)),
};
