import {
  localStorageSetObject,
  localStorageGetObject,
} from '../../../library/helpers/fetch';

import {
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  FAILURE_LOGIN,
  LOGOUT_USER,
} from './actions';

import {
  INITIALIZE_REQUEST,
  INITIALIZE_SUCCESS,
  INITIALIZE_FAILURE,
} from '../../../store/reducers/initialize/actions';

import { PERSON_INFO_UPDATE_SUCCESS } from '../../../store/reducers/person/actions';

import { UI_UPDATE_AVATAR } from '../../../store/reducers/ui/actions';

const initialState = {
  isAccountInitialized: false,
  isAuthenticated: false,
  isFetching: false,
  message: null,
  responseType: null,
  details: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        message: null,
        responseType: null,
      };

    case RECEIVE_LOGIN:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        details: action.payload,
        message: null,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        details: {},
      };

    case FAILURE_LOGIN:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        message: action.payload.message,
        responseType: action.payload.responseType,
      };

    case INITIALIZE_REQUEST:
      return state;

    case INITIALIZE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          initialize: 'True',
        },
      };

    case INITIALIZE_FAILURE:
      return {
        ...state,
        message: action.payload,
      };

    case UI_UPDATE_AVATAR:
      // With addition to updating the volatile state, update persistent state
      // (When the browser is refreshed, the new avatar url will be used instead of the old)
      const profile = localStorageGetObject('profile');
      localStorageSetObject('profile', {
        ...profile,
        avatarUrl: action.payload,
      });

      return {
        ...state,
        details: {
          ...state.details,
          avatarUrl: action.payload,
        },
      };

    case PERSON_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          name: `${action.payload.firstname} ${action.payload.lastname}`,
          sub: action.payload.email,
          unique_name: action.payload.email,
        },
      };

    default:
      return state;
  }
};

export default reducer;
