import React, { useState, useEffect } from 'react';
import DocumentsSlider from './DocumentsSlider';
import SelectField from '../../../../library/forms/SelectField';

import DocumentsByTitle from './Components/DocumentsByTitle';
import DocumentFilter from './Components/DocumentFilter';
import { DocumentsLoading } from '..';

import { Grid, Fade, Collapse } from '@material-ui/core';
import {
  sortOptions,
  status,
  sortByStatus,
  DocumentsEmpty,
  useSliderContainer,
} from '../index.js';

const DocumentSliderContainer = props => {
  const { list, hasUpdates, newId } = props.documents;
  const { CATEGORIES, DATE, TITLE } = sortByStatus;
  const { LOADING, EMPTY } = status;
  const [sortBy, setSortBy] = useState(CATEGORIES);
  const [state, setState] = useState(LOADING);

  const { listIds, setFilter, filter, keys } = useSliderContainer(
    props.documents.listIds,
    list,
    sortBy,
    state,
    setState
  );

  useEffect(() => {
    if (Boolean(props.from) && props.from.length) {
      setFilter(props.from);
      setSortBy(TITLE);
    }
  }, []);

  const handleFilter = e => {
    setFilter(e);
  };

  const handleSelect = e => {
    if (e !== sortBy) setState(LOADING);
    setSortBy(e);
  };

  const renderSliders = () => {
    switch (sortBy) {
      case CATEGORIES:
      case DATE:
        return (
          <Grid item xs={12}>
            {keys.map((val, index) => {
              return (
                <Fade in={true} timeout={150} key={`category-slider-${index}`}>
                  <div>
                    <DocumentsSlider
                      initialListId={props.documents.listIds}
                      list={list}
                      sortBy={val}
                      listIds={listIds}
                      personId={props.personId}
                      type={sortBy}
                      filter={filter}
                      hasUpdates={hasUpdates}
                      newId={newId}
                    />
                  </div>
                </Fade>
              );
            })}
          </Grid>
        );
      case TITLE: {
        return (
          <DocumentsByTitle
            listIds={listIds}
            initialList={props.documents.listIds}
            list={list}
            from={props.from}
            onDrop={props.onDrop}
            newId={newId}
            hasUpdates={hasUpdates}
          />
        );
      }
      default:
        return null;
    }
  };

  return state === LOADING ? (
    <DocumentsLoading />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <SelectField
          options={sortOptions}
          type="text"
          name="sortBy"
          label="Sort by"
          labelKey="longName"
          valueKey="shortName"
          meta={{ touched: false, error: null }}
          input={{ value: sortBy, onChange: handleSelect, onBlur: () => {} }}
        />
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={7}>
        <DocumentFilter
          filter={filter}
          handleChange={handleFilter}
          setFilter={setFilter}
          sortBy={sortBy}
        />
      </Grid>
      <Grid item xs={12}>
        {state === EMPTY && (
          <Collapse in={state === EMPTY} timeout={250}>
            <div>
              <DocumentsEmpty
                onDrop={props.onDrop}
                title="No Records Found"
                action="Create a document entry"
              />
            </div>
          </Collapse>
        )}
        {props.documents.hasFetched && state !== EMPTY && renderSliders()}
      </Grid>
    </Grid>
  );
};

export default DocumentSliderContainer;
