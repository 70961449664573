import React from 'react';
import PropTypes from 'prop-types';

import DisplayField from './DisplayField';
import DateTimePanel from './DateTimePanel';

const STATE_CHANGE_TIME_SYNC = 'time-sync';
const STATE_CHANGE_USER_SELECTION = 'user-selection';

class DateTimeInput extends React.Component {
  state = {
    date: undefined,
    showDateTimePanel: false,
  };

  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.panel = React.createRef();

    document.addEventListener('mousedown', this.onClick, false);
  }

  componentDidMount() {
    this.setState({ date: this.props.date });

    if (this.props.isTimeSynced) {
      this.intervalId = setInterval(() => {
        this.onChange(new Date(), STATE_CHANGE_TIME_SYNC);
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({
        date: this.props.date,
      });
    }

    if (this.props.isTimeSynced !== prevProps.isTimeSynced)
      if (this.props.isTimeSynced && !this.intervalId) {
        this.intervalId = setTimeout(() => {
          this.onChange(new Date(), STATE_CHANGE_TIME_SYNC);
        }, 1000);
      } else {
        if (!this.props.isTimeSynced && this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClick);
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  onClick = event => {
    if (this.props.isPanelKeptOpen) return;
    // Click is over parent or panel, disregard.
    if (
      (this.root.current && this.root.current.contains(event.target)) ||
      (this.panel.current && this.panel.current.contains(event.target))
    ) {
      return;
    }

    // Click is outside of parent and panel. Close date time panel.
    this.onDateTimePanel(false)(event);
  };

  onChange = (date, action) => {
    this.setState({ date });
    this.props.onChange(date, action);
  };

  onDateTimePanel = bool => event => {
    this.setState({ showDateTimePanel: bool });
  };

  render() {
    const { format, date } = this.props;

    return (
      <div style={{ position: 'relative' }} className="main">
        <DisplayField
          innerRef={this.root}
          date={this.state.date ? this.state.date : date}
          format={format}
          onClick={this.onDateTimePanel(!this.state.showDateTimePanel)}
        />
        {this.state.showDateTimePanel ? (
          <DateTimePanel
            {...this.props}
            innerRef={this.panel}
            date={this.state.date ? this.state.date : date}
            onChange={date => this.onChange(date, STATE_CHANGE_USER_SELECTION)}
            onCancel={this.onDateTimePanel(false)}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

DateTimeInput.propTypes = {
  date: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
  //is24hr: PropTypes.bool.isRequired,
  showCurrentDay: PropTypes.bool.isRequired,
  showSelectedDay: PropTypes.bool.isRequired,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number,
  maxColumnsYear: PropTypes.number.isRequired,
  addColumnsYear: PropTypes.number.isRequired,
  isAccurateYear: PropTypes.bool.isRequired,
  isWeekendsDisabled: PropTypes.bool.isRequired,
  isPanelKeptOpen: PropTypes.bool.isRequired,
  isTimeSynced: PropTypes.bool.isRequired,
  disabledDays: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

DateTimeInput.defaultProps = {
  date: new Date(),
  format: 'yyyy-MM-dd hh:mm a',
  //is24hr: false, commented to prevent console errors
  showCurrentDay: true,
  showSelectedDay: true,
  minYear: 1900,
  maxYear: undefined,
  maxColumnsYear: 0,
  addColumnsYear: 0,
  isAccurateYear: false,
  isWeekendsDisabled: false,
  isPanelKeptOpen: false,
  isTimeSynced: false,
};

export default DateTimeInput;
