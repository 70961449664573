import {
  USERAGREEMENTS_TYPE_REQUEST,
  USERAGREEMENTS_TYPE_SUCCESS,
} from './actions';

import { LOADING, SUCCESS, ERROR } from '../../state';

const initialState = {
  userAgreements: [],
  uiState: LOADING,
};

const reducers = {};

reducers[USERAGREEMENTS_TYPE_REQUEST] = (state, action) => ({
  ...state,
  uiState: LOADING,
});

reducers[USERAGREEMENTS_TYPE_SUCCESS] = (state, action) => ({
  ...state,
  uiState: SUCCESS,
  userAgreements: action.payload,
});

const reducer = (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};

export default reducer;
