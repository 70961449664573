import { apiFetch, apiFetchAuthorized } from '../helpers/fetch';

export const DISPATCH_API = 'DISPATCH_API';
export const DISPATCH_API_AUTHORIZED = 'DISPATCH_API_AUTHORIZED';
export const DISPATCH_API_MODIFY = 'DISPATCH_API_MODIFY';

const msgMissingTypes = name => `${name}: the types array property of the action 
payload must include types for REQUEST, SUCCESS, and FAILURE in the respective order.`;

const msgAllTypesMustBeStrings = name => `${name}: all types in the types array property of the action when triggering 
${name} should be strings`;

const msgNoEndPoint = name =>
  `${name}: an endpoint must be given. Without it, no request can be made to the API server`;

/**
 * Used for putting organized error messages into the web console.
 *
 * @param {string} name
 * @param {string} url
 * @param {mixed} data
 */
const dispatchToApiMsg = (url, name, data) => {
  console.groupCollapsed(`${name} Failed: ${url}`);
  console.log(data);
  console.groupEnd();
};

/**
 * Used to make a request to the server. Handles different types of errors/conditions.
 * If the request is intended to be one that requires authorization, then
 * the fetchApiAuthorized function will be used, else fetchApi.
 *
 * @param {*} url
 * @param {*} name
 * @param {*} authorized
 */
const dispatchToApi = (url, name, authorized, config) => {
  const func = authorized ? apiFetchAuthorized : apiFetch;
  return func(url, config).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        dispatchToApiMsg(url, name, json);
        return Promise.reject(json);
      } else {
        return Promise.resolve(!json.data ? json : json.data);
      }
    })
  );
  //.catch(error => dispatchToApiMsg(url, name, error)));
};

/**
 * Intercept actions DISPATCH_API and DISPATCH_API_AUTHORIZED. This is intended to handle
 * the common API request case. Perform the following steps:
 *
 *  1. Start the request by dispatching the *REQUEST* action from the types array (index #1),
 *  2. If the request is successfully, then dispatch the *SUCCESS* action from types array (index #2).
 *  2. If the request failed, then dispatch the *FAILURE* action from types array (index #3).
 *
 * Note: "fetch" action as a composition of three parts (or dispatch to the API). REQUEST, SUCCESS, and FAILURE. The fetch
 * action uses all three parts. REQUEST is when the request is made to the server, so before
 * that's fulfilled, we can modify the state to let the user know something is happening.
 * In return, we avoid error like rendering content that doesn't exist. SUCCESS is when the
 * request comes back without a problem, we modify the state again to specify we have content
 * and flag the appropriate flags and flash a message
 * (some sort of UI state change that shows the user their request has been fulfilled).
 * FAILURE is the inverse of SUCCESS.
 *
 * @param {object} store
 * @return {mixed}
 */
export default store => next => action => {
  const dispatchActionKey = [DISPATCH_API, DISPATCH_API_AUTHORIZED].find(
    x => typeof action[x] !== 'undefined'
  );

  if (typeof dispatchActionKey === 'undefined') {
    return next(action);
  }

  const { types = false, endpoint = false, meta, ...properties } = action[
    dispatchActionKey
  ];

  if (!types || !Array.isArray(types) || types.length !== 3) {
    throw new Error(msgMissingTypes(dispatchActionKey));
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error(msgAllTypesMustBeStrings(dispatchActionKey));
  }

  if (!endpoint) {
    throw new Error(msgNoEndPoint(dispatchActionKey));
  }

  const config = meta && meta.hasOwnProperty('config') ? meta.config : {};

  const [actionType, successType, failureType] = types;
  const shouldBeAuthorized = dispatchActionKey.includes('AUTHORIZED');

  next({ type: actionType, meta, ...properties });
  return dispatchToApi(
    endpoint,
    dispatchActionKey,
    shouldBeAuthorized,
    config
  ).then(
    response =>
      next({
        ...properties,
        type: successType,
        payload: response,
        meta,
      }),
    error =>
      next({
        ...properties,
        type: failureType,
        payload: error,
        meta,
        error: true,
      })
  );
};
