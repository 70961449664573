import MealFragmentButton from '../../buttons/MealFragmentButton';
import { defaultDataNormalizer } from '../utils';

const dataFields = {
  mealItems: [],
  ateSlowly: false,
  manualCalories: 0,
  manualCaloriesUnit: 'kcal',
  totalCalories: 0,
  totalCaloriesUnit: 'kcal',
};

const normalizeData = defaultDataNormalizer;

const properties = {
  label: 'Meal Log',
  color: '#90B850',
  icon: MealFragmentButton,
  dataFields,
  normalizeData,
};

export { dataFields, properties };
