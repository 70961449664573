import React from 'react';
import Table from '../../components/ReactTable';
import { ALLERGY } from '../../../store/reducers/mediaObject';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  UNVERIFIED,
  VERIFIED,
  SELF_ATTESTED,
} from '../../components/StatusField';
import { dateTimeRender } from '../../components/ReactTable/utils';

const ImmunizationTable = props => {
  const getData = () =>
    props.listIds.map(id => {
      return {
        ...props.list[id],
        id,
        type: ALLERGY,
      };
    });

  React.useEffect(() => {
    setData(getData());
  }, [props.list, props.listIds]);
  const [data, setData] = React.useState(getData());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        col: '5%',
        Cell: ({ value }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 14,
            }}
          >
            {value != null && value !== UNVERIFIED && (
              <Tooltip
                title={value === VERIFIED ? 'Verified' : 'Self Attested'}
              >
                <CheckCircleIcon
                  color="secondary"
                  style={{ marginRight: '8px' }}
                />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        Header: 'Date Given',
        accessor: 'administrationOn',
        col: '10%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'Vaccine for',
        accessor: 'purpose',
        col: '30%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Vaccine Name',
        accessor: 'name',
        col: '30%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Dose #',
        accessor: 'dose',
        col: '10%',
        Cell: ({ value }) => value || 'n/a',
      },
      {
        Header: 'Booster due date',
        accessor: 'boosterDueOn',
        col: '15%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
    ],
    []
  );
  return (
    <Table
      data={data}
      columns={columns}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      viewMediaObjects={props.viewMediaObjects}
      rendersColGroup
    />
  );
};

export default ImmunizationTable;
