import React, { useState } from 'react';
import { Button, Tab, Tabs, IconButton, Icon } from '@material-ui/core';
import { useStyles } from './styles';
import { reduxForm } from 'redux-form';
import NominationForm from './NominationForm';
import { connect } from 'react-redux';
import ProgressButtonLinear from '../../../../components/Buttons/ProgressButtonLinear';
import DashboardMessage from '../../../../components/DashboardContent/DashboardMessage';
import {
  nominateResource,
  NOMINATE_RESOURCE_SUCCESS,
  NOMINATE_RESOURCE_FAILURE,
} from '../../../../../store/reducers/resourceLibrary/actions';

const LOADING = 'loading';
const IDLE = 'idle';
const SUCCESS = 'success';
const ERROR = 'warning';

const NominationDrawer = ({
  onClose,
  categoryList,
  values,
  nominateResource,
  disableSubmit,
}) => {
  const classes = useStyles(IDLE);
  const [status, setStatus] = useState(IDLE);
  const handleSubmit = async () => {
    setStatus(LOADING);
    const res = await nominateResource(values);
    if (res.type === NOMINATE_RESOURCE_SUCCESS) {
      setStatus(SUCCESS);
    } else if (res.type === NOMINATE_RESOURCE_FAILURE) {
      setStatus(ERROR);
    }
  };

  const renderByStatus = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return (
          <div className={classes.control}>
            <div style={{ position: 'relative', margin: '8px' }}>
              <Button
                variant="contained"
                disableElevation
                style={{ width: '120px', height: '100%' }}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </div>
            <ProgressButtonLinear
              variant="contained"
              color="secondary"
              disableElevation
              style={{ color: '#FFF', width: '120px' }}
              onClick={handleSubmit}
              disabled={disableSubmit}
              isProgressing={status === LOADING}
            >
              Submit
            </ProgressButtonLinear>
          </div>
        );

      case SUCCESS: {
        return (
          <DashboardMessage
            type={status}
            onClose={() => onClose()}
            style={{ margin: '32px' }}
            canClose={false}
          >
            Thank you for nominating a resource. Your recommendation means a lot
            as we review hundreds of candidates for our community.
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
              }}
            >
              <Button
                variant="contained"
                disableElevation
                style={{ width: '120px' }}
                onClick={() => onClose()}
              >
                Close
              </Button>
            </div>
          </DashboardMessage>
        );
      }
      case ERROR: {
        return (
          <DashboardMessage
            type={status}
            onClose={() => onClose()}
            style={{ margin: '32px' }}
          >
            Something went wrong. Please try again later
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
              }}
            >
              <Button
                variant="contained"
                disableElevation
                style={{ width: '120px' }}
                onClick={() => onClose()}
              >
                Close
              </Button>
            </div>
          </DashboardMessage>
        );
      }
      default:
        '';
    }
  };

  return (
    <div>
      <div style={{ position: 'absolute', top: 0, right: 15, zIndex: 100 }}>
        <IconButton onClick={() => onClose()}>
          <Icon style={{ color: '#fff' }}>close</Icon>
        </IconButton>
      </div>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        style={{
          backgroundColor: '#006AB0',
          color: '#ffffff',
        }}
        value={0}
      >
        <Tab label="Resource Nomination" value={0} />
      </Tabs>
      <form>
        <NominationForm categoryList={categoryList} />
      </form>
      {renderByStatus()}
    </div>
  );
};

const mapStateToProps = state => ({
  categoryList: state.resourceLibrary.resourceTypes || [],
  values:
    (state.form &&
      state.form.nominateResource &&
      state.form.nominateResource.values) ||
    null,
  disableSubmit:
    state.form &&
    state.form.nominateResource &&
    state.form.nominateResource.syncErrors,
});

const mapDispatchToProps = dispatch => ({
  nominateResource: values => dispatch(nominateResource(values)),
});

export default reduxForm({
  form: 'nominateResource',
  touchOnChange: true,
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(NominationDrawer));
