/**
 * Used to validate if a password has at least one uppercase 
 * letter, symbol or space, lowercase letter, and number. 
 * 
 * @param {string} password 
 */
export const isValidPassword = (password) =>
  [
    /(?=.*[a-z])/,
    /(?=.*[A-Z])/,
    /(?=.*[0-9])/,
    /(?=.*[\s!@#$%^&*?])/,
  ].filter(rule => rule.test(password)).length >= 4 
    && password.length >= 6;