import React, { useState } from 'react';
import AddDependent from '../FamilyAccountsPage/Containers/AddDependent/AddDependent';
import AddAdultDependent from '../FamilyAccountsPage/Containers/AddAdultDependent/AddAdultDependent';
import AddPetDependent from '../FamilyAccountsPage/Containers/AddPetDependent/AddPetDependent';

const BottomPaddedWrapper = ({ children }) => (
  <div style={{ marginBottom: 400 }}>{children}</div>
);

export default function DependentForm({ userInfo, onSubmit }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async values => {
    setIsSubmitting(true);
    try {
      const { ssn, ...rest } = values;
      await onSubmit({
        ...(ssn ? values : rest),
        type: values.type === 'Pet' ? 'Pet' : 'Person',
      });
    } catch (e) {
      setIsSubmitting(false);
      throw e;
    }
  };

  const renderChild = () => {
    if (userInfo.type) {
      switch (userInfo.type.toLowerCase()) {
        case 'child':
          return (
            <BottomPaddedWrapper>
              <AddDependent
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
              />
            </BottomPaddedWrapper>
          );
        case 'adult':
          return (
            <BottomPaddedWrapper>
              <AddAdultDependent
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                setShowDashboardMessage={false}
                hasSignedAttentation={true}
              />
            </BottomPaddedWrapper>
          );
        case 'pet':
          return (
            <BottomPaddedWrapper>
              <AddPetDependent
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
              />
            </BottomPaddedWrapper>
          );
        default:
          return '';
      }
    }
    return '';
  };

  return renderChild();
}
