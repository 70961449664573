import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Typography,
  TextField,
  InputAdornment,
  withStyles,
  Grid,
} from '@material-ui/core';
import { Field, reduxForm, FieldArray, change } from 'redux-form';
import FragmentDialogBase from '../FragmentDialogBase';
import InputField from '../../../../../library/forms/InputField';
import {
  measurementsValidation,
  weightValidation,
  percentageValidation,
} from '../Validations';

import HeightField from '../../../../containers/FormFields/HeightFields';
import ErrorTooltip from '../Forms/ErrorTooltip';
import { normalizeDecimal } from '../../../../../library/helpers/format';
import { properties as fragmentProperties } from './properties';

export const BODY_METRICS_FIELDS = {
  weight: '',
  height: null,
  weightUnit: 'lbs',
  heightUnit: 'in',
  bmi: '',

  //bmiWeight: '',
  //bmiWeightUnit: 'kg',
  //bmiHeight: '',
  //bmiHeightUnit: 'm',

  bodyFatPercentage: '',
  musclePercentage: '',
  waterPercentage: '',
  boneMassPercentage: '',

  bodyFatMassWeight: '',
  bodyFatMassWeightUnit: 'lbs',
  skeletalMuscleWeight: '',
  skeletalMuscleWeightUnit: 'lbs',
  waterWeight: '',
  waterWeightUnit: 'lbs',

  waist: '',
  waistUnit: 'in',
  hips: '',
  hipsUnit: 'in',
  chest: '',
  chestUnit: 'in',
  neck: '',
  neckUnit: 'in',
  leftForearm: '',
  leftForearmUnit: 'in',
  rightForearm: '',
  rightForearmUnit: 'in',
  leftBicep: '',
  leftBicepUnit: 'in',
  rightBicep: '',
  rightBicepUnit: 'in',
  leftCalf: '',
  leftCalfUnit: 'in',
  rightCalf: '',
  rightCalfUnit: 'in',
  leftThigh: '',
  leftThighUnit: 'in',
  rightThigh: '',
  rightThighUnit: 'in',
};

const styles = theme => ({
  formLabel: {
    fontSize: '12px',
  },

  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  bmiRowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  bmiRowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  bmiRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '2x',
  },
  bmiHeader: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    padding: '18.5px 14px',
  },
  bmiTextField: {
    border: '1px solid #0068A8',
    color: '#0068A8',
    borderRadius: 4,
    backgroundColor: '#EFF1F3',
  },

  focused: {},
});

const InputFieldWithTooltip = props => {
  return (
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <InputField hasErrorTooltip={true} enableMinHeight={false} />
    </ErrorTooltip>
  );
};

//body composition
const bodyCompositionFields = [
  { id: 'bodyFatPercentage', label: 'Body Fat' },
  { id: 'musclePercentage', label: 'Muscle' },
  { id: 'waterPercentage', label: 'Water' },
  { id: 'boneMassPercentage', label: 'Bone Mass' },
];
const BodyCompositionForm = () => {
  return (
    <Grid container spacing={2}>
      {bodyCompositionFields.map(item => (
        <Grid item xs={3} key={item.id}>
          <Field
            id={item.id}
            name={item.id}
            label={item.label}
            placeholder="0"
            normalize={value => normalizeDecimal(value)}
            margin="dense"
            validate={percentageValidation}
            component={InputFieldWithTooltip}
            props={{
              shrink: true,
              bodyMetricForm: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    lineHeight: '1.5',
                    marginRight: '-4px',
                  }}
                >
                  %
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

//Weight Distribution
const weightDistributionFields = [
  { id: 'bodyFatMassWeight', label: 'Body Fat Mass' },
  { id: 'skeletalMuscleWeight', label: 'Skeletal Muscle' },
  { id: 'waterWeight', label: 'Water Weight' },
];

const WeightDistributionForm = () => {
  return (
    <Grid container spacing={2}>
      {weightDistributionFields.map(item => (
        <Grid item xs={4} key={item.id}>
          <Field
            id={item.id}
            name={item.id}
            label={item.label}
            placeholder="0.00"
            normalize={value => normalizeDecimal(value)}
            margin="dense"
            validate={measurementsValidation}
            component={InputFieldWithTooltip}
            props={{
              shrink: true,
              bodyMetricForm: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    lineHeight: '1.5',
                    marginRight: '-4px',
                  }}
                >
                  lbs
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

//Measurements
const measurementsFields = [
  { id: 'waist', label: 'Waist' },
  { id: 'hips', label: 'Hips' },
  { id: 'chest', label: 'Chest' },
  { id: 'neck', label: 'Neck' },
  { id: 'leftForearm', label: 'Left Forearm' },
  { id: 'rightForearm', label: 'Right Forearm' },
  { id: 'leftBicep', label: 'Left Bicep' },
  { id: 'rightBicep', label: 'Right Bicep' },
  { id: 'leftCalf', label: 'Left Calf' },
  { id: 'rightCalf', label: 'Right Calf' },
  { id: 'leftThigh', label: 'Left Thigh' },
  { id: 'rightThigh', label: 'Right Thigh' },
];
const MeasurementsForm = () => {
  return (
    <Grid container spacing={2}>
      {measurementsFields.map(item => (
        <Grid item xs={3} key={item.id}>
          <Field
            id={item.id}
            name={item.id}
            label={item.label}
            placeholder="0.00"
            normalize={value => normalizeDecimal(value)}
            margin="dense"
            component={InputFieldWithTooltip}
            validate={measurementsValidation}
            props={{
              shrink: true,
              bodyMetricForm: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    lineHeight: '1.5',
                    marginRight: '-4px',
                  }}
                >
                  In
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const BodyMetricsDialog = props => {
  const { classes, updateField } = props;

  const [weight, setWeight] = useState(props.initialValues.weight);

  const [heightFoot, setHeightFoot] = useState(null);

  const [heightInch, setHeightInch] = useState(null);

  const handleChangeWeight = e => {
    setWeight(e.target.value);
    updateField('weight', e.target.value);
  };

  const handleChangeHeight = (name, value) => {
    let foot = heightFoot;
    let inch = heightInch;
    let totalHeight = 0;

    switch (name) {
      case 'foot':
        foot = value;
        setHeightFoot(value);
        totalHeight = value * 12 + heightInch;
        break;
      case 'inches':
        inch = value;
        totalHeight = heightFoot * 12 + inch;
        setHeightInch(value);
        break;
    }

    updateField('height', totalHeight);
  };

  useEffect(() => {
    const foot = props.initialValues.height
      ? Math.floor(props.initialValues.height / 12) >= 0
        ? props.initialValues.height === 12
          ? 1
          : Math.floor(props.initialValues.height / 12)
        : null
      : null;

    const inch = props.initialValues.height
      ? props.initialValues.height % 12 >= 0
        ? props.initialValues.height === 12
          ? null
          : props.initialValues.height % 12
        : null
      : null;

    setHeightFoot(foot || '');

    setHeightInch(inch || '');
  }, [props.initialValues]);

  useEffect(() => {
    if (!!(heightInch || heightFoot) && !!weight) {
      const totalHeight = heightInch + heightFoot * 12;
      const bmiValue =
        (parseFloat(weight) / parseFloat(Math.pow(totalHeight, 2))) * 703;

      updateField('bmi', Math.round(bmiValue * 100) / 100);
    }
  }, [heightFoot, heightInch, weight]);

  const BMIField = ({ input: { value } }) => (
    <TextField
      id="bmi"
      shrink={true}
      margin="dense"
      name="bmi"
      value={value}
      disabled
      fullWidth
      inputProps={{
        style: {
          color: '#0068A8',
          textAlign: 'center',
          fontSize: '1rem',
          lineHeight: '1.1875em',
          paddingTop: '15px',
          paddingBottom: '15px',
        },
      }}
      InputProps={{
        disableUnderline: 'true',
      }}
      className={classes.bmiTextField}
      type="number"
    />
  );

  return (
    <FragmentDialogBase
      color="#2AA5A2"
      defaultTitle="Body Metrics"
      FormSlot1={
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.bmiRowFields}>
            <Typography className={classes.bmiRowHeader}>
              Total Body Weight
            </Typography>
            <Field
              id="weight"
              name="weight"
              normalize={value => normalizeDecimal(value)}
              placeholder="0.00"
              onBlur={handleChangeWeight}
              component={InputFieldWithTooltip}
              validate={weightValidation}
              props={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    disableTypography
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      lineHeight: '1.5',
                    }}
                  >
                    lbs
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} className={classes.bmiRowFields}>
            <Typography className={classes.bmiRowHeader}>Height</Typography>
            <HeightField
              noLabel={true}
              onChange={handleChangeHeight}
              initialInchesValue={heightInch}
              initialFeetValue={heightFoot}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3} className={classes.bmiRow}>
            <Typography className={classes.bmiHeader}>
              Calculated {'\n'} BMI
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.bmiRow}>
            <Field name="bmi" component={BMIField} defaultValue={0} />
          </Grid>
        </Grid>
      }
      FormSlot2={
        <div>
          <Typography className={classes.additionalInfoHeader}>
            Body Composition
          </Typography>

          <FieldArray name="Body Composition" component={BodyCompositionForm} />

          <Typography className={classes.additionalInfoHeader}>
            Weight Distribution
          </Typography>

          <FieldArray
            name="Weight Distribution"
            component={WeightDistributionForm}
          />

          <Typography className={classes.additionalInfoHeader}>
            Measurements
          </Typography>
          <FieldArray name="Body Measurements" component={MeasurementsForm} />
        </div>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = (state, props) => {
  const dependentRecord = state.dependents.tabs.find(
    x => x.personId === props.personId
  );

  const height = dependentRecord
    ? dependentRecord.height
    : state.active.user.height;

  const fragmentData =
    !!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : fragmentProperties.dataFields;

  const initialValues = {
    ...fragmentData,
    height: fragmentData.height ? fragmentData.height : height ? height : 0,
  };

  return { initialValues };
};

const BodyMetricsFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(BodyMetricsDialog));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BodyMetricsFormRedux));
