import React from 'react';
import Grid from '@material-ui/core/Grid';
import MediaSubmitProgress from '../../../components/DocumentsDrawer/MediaObjectDrawer/MediaSubmitProgress';

const SubmitViews = props => {
  const { mediaObject } = props;
  React.useEffect(() => {
    if (
      !props.userImmunizations.isSubmitting &&
      !mediaObject.isUploadingFiles
    ) {
      props.onCloseDrawer();
    }
  }, [props.userImmunizations.isSubmitting, mediaObject.isUploadingFiles]);
  return (
    <div
      style={{
        width: !props.matches550 ? '550px' : '100vw',
        padding: '32px',
      }}
    >
      <Grid container spacing={3}>
        {mediaObject.bundles.map(bundle => {
          return (
            <Grid item xs={12}>
              <MediaSubmitProgress
                bundle={bundle}
                fileName={bundle.file.name}
                loaded={bundle.loaded}
                total={bundle.total}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default SubmitViews;
