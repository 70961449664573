export function getWeekDays(format = 'short', locale = 'en') {
  return Array.from({ length: 7 }, (_, index) =>
    new Date(0, 0, index).toLocaleDateString(locale, {
      weekday: format,
    })
  );
}

export function getMonths(format = 'short', locale = 'en') {
  return Array.from({ length: 12 }, (_, index) =>
    new Date(0, index).toLocaleDateString(locale, {
      month: format,
    })
  );
}

// https://stackoverflow.com/questions/222309/calculate-last-day-of-month-in-javascript
export function getTotalDaysInMonth(m, y) {
  return m === 2
    ? y & 3 || (!(y % 25) && y & 15)
      ? 28
      : 29
    : 30 + ((m + (m >> 3)) & 1);
}

export function* rangeGen(min, max) {
  for (let i = min; i <= max; i++) {
    yield i;
  }
}

export function range(min, max) {
  return Array.from({ length: max - min + 1 }, (_, x) => min + x);
}

export function getDaysInMonth(date) {
  const object = date ? date : new Date();
  const length = getTotalDaysInMonth(
    object.getMonth() + 1,
    object.getFullYear()
  );
  return Array.from(
    { length },
    (_, x) => new Date(object.getFullYear(), object.getMonth(), x + 1)
  );
  // return Array.from({ length: getTotalDaysInMonth(m, y) }, (_, x) => x + 1);
}

export function padWeekDaysX(num, rootDate, flip = false) {
  const length = !flip ? num : 7 - num - 1;
  const temp = Array.from({ length }, (_, x) => (!flip ? rootDate - x : x + 1));
  return !flip ? temp.reverse() : temp;
}

export function padWeekDaysZ(num, rootDate, flip = false) {
  const length = !flip ? num : 7 - num - 1;
  const temp = Array.from({ length }, (_, x) => ({
    day: !flip ? rootDate - x : x + 1,
    type: 'blank',
  }));
  return !flip ? temp.reverse() : temp;
}

export function padWeekDays(day, date, flip) {
  const object = date ? date : new Date();
  const length = !flip ? day : 7 - day - 1;

  const next = new Date(
    object.getFullYear(),
    !flip ? object.getMonth() - 1 : object.getMonth() + 1,
    1
  );

  const totalDays = getTotalDaysInMonth(
    next.getMonth() + 1,
    next.getFullYear()
  );

  const temp = Array.from(
    { length },
    (_, x) =>
      new Date(
        next.getFullYear(),
        next.getMonth(),
        !flip ? totalDays - x : x + 1
      )
  );

  return !flip ? temp.reverse() : temp;
}

//console.log(padWeekDaysZ(4, new Date(), true));

export function getStartEndDate(date) {
  const obj = date ? date : new Date();
  obj.setDate(1);
  const start = obj.getDay();
  obj.setDate(getTotalDaysInMonth(obj.getMonth() + 1, obj.getFullYear()));
  const end = obj.getDay();
  return [start, end];
}

export function getDays(date) {
  // TODO: why can't a date be passed as reference?
  const object = date ? date : new Date();
  return getDaysInMonth(object);
}

export function chunkWeeks(array) {
  let temp = [];
  let length = array.length,
    size = 0,
    i = 0;

  // Array is balanced (always should be for the purposes of
  // a calendar)
  if (length % 7 === 0) {
    size = 7;
    while (i < length) {
      temp.push(array.slice(i, (i += size)));
    }
  } else {
    // In theory this shouldn't be triggered ever.
    throw RangeError('Array is not balanced');
  }

  return temp;
}
