import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import fieldStyles from './Field.css';
import { FormHelperText } from '@material-ui/core';

const styles = theme => ({
  formControl: {
    minHeight: theme.spacing(10),
  },
});

class CheckboxField extends Component {
  render() {
    const {
      input,
      label,
      checkedValue,
      meta: { asyncValidating, touched, error },
      enableMinHeight = true,
      classes,
      shrink,
      labelStyles = {},
      containerProps = {},
      ...attributes
    } = this.props;
    const hasError = !!(touched && error);

    return (
      <div
        className={classNames({
          [classes.formControl]: enableMinHeight,
          [fieldStyles.inputAsync]: asyncValidating,
        })}
        {...containerProps}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              value={input.value ? checkedValue : ''}
              {...attributes}
            />
          }
          label={label}
          {...labelStyles}
        />
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </div>
    );
  }
}

export default withStyles(styles)(CheckboxField);
