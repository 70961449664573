import React, { useState, useEffect } from 'react';
import AccordionPanel from './AccordionPanel';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DashboardMessage from '../../DashboardContent/DashboardMessage';
import { connect } from 'react-redux';
import { useStyles } from './useStyles';

const OffersPanel = ({
  offers = [],
  handleRedeemOffer,
  error,
  success,
  submittingOffers,
}) => {
  const classes = useStyles();
  const [renderMessage, setRenderMessage] = useState('');
  useEffect(() => {
    setRenderMessage(error ? error : success ? success : '');
  }, [error, success]);

  return (
    <AccordionPanel
      emptyState="No offers currently."
      detailsBackground="#ffffff"
      title={
        <div className={classes.titleContainerDiv}>
          <LoyaltyIcon className={classes.fontSize18} color="primary" />
          <Typography className={classes.marginLeft8}>Offers</Typography>
        </div>
      }
      details={
        !offers.length ? null : (
          <Grid container spacing={0} className={classes.offersContainerDiv}>
            <Grid item xs={12}>
              <Collapse in={renderMessage.length} timeout={200}>
                <DashboardMessage
                  type={error ? 'warning' : success ? 'success' : 'info'}
                  canClose={true}
                  onClose={() => setRenderMessage('')}
                >
                  {renderMessage}
                </DashboardMessage>
              </Collapse>
            </Grid>
            {offers.map((offer, index) => (
              <Grid
                item
                xs={12}
                key={`${index}-offer-resource`}
                className={classes.offersGridItem}
              >
                <Paper square className={classes.offersPaper} elevation={0}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className={classes.offerDetailsContainer}
                    >
                      <Typography
                        color="primary"
                        className={classes.offerTitle}
                      >
                        {offer.name}
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        onClick={() => handleRedeemOffer(offer.offerId)}
                        disabled={submittingOffers.length}
                      >
                        {submittingOffers.includes(offer.offerId) ? (
                          <div style={{ height: '22.67px', width: '52.27px' }}>
                            <CircularProgress size={22} />
                          </div>
                        ) : (
                          'Redeem'
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        color="#75747B"
                        className={classes.offerDetails}
                      >
                        {offer.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )
      }
    />
  );
};

const mapStateToProps = (state, props) => ({
  success: state.resourceLibrary.offerPanelSuccessMessage,
  error: state.resourceLibrary.offerPanelError,
  submittingOffers: state.resourceLibrary.submittingOffers,
});

export default connect(mapStateToProps, null)(OffersPanel);
