/**
 * Check if an object is a promise
 *
 * @param {*} value potential promise
 * @returns true if the object is a promise
 */
export const isPromise = value => {
  return Boolean(value && typeof value.then === 'function');
};

/**
 * Wrap a promise object to make queryable programmatically.
 * @param {*} promise
 * @returns wrapped promise
 */
export const wrapPromise = promise => {
  // Only wrap once.
  if (promise.isFulfilled) return promise;

  let isPending = false;
  let isRejected = false;
  let isFulfilled = false;

  var result = promise.then(
    r => {
      isPending = false;
      isFulfilled = true;
      return r;
    },
    e => {
      isRejected = false;
      throw e;
    }
  );

  result.isPending = () => isPending;
  result.isRejected = () => isRejected;
  result.isFulfilled = () => isFulfilled;

  return result;
};
