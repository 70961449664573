import React, { Component, useEffect, useState, useRef } from 'react';
import MediaObjectView from './MediaObjectView';
import '../styles/MediaObjectDrawer.css';

const MediaEditMode = ({
  personId,
  //handleSubmit,
  currentMediaFile,
  onClickCancel,
  onClickEdit,
  pristine,
  submitting,
  dialogMode,
  viewMode,
  onSubmitUpdate,
  isSubmitting,
  handleOnDelete,
  areaType = null,
}) => {
  return (
    <div>
      <MediaObjectView
        handleSubmit={(e, values) => handleSubmit(e, values)}
        onSubmitUpdate={onSubmitUpdate}
        handleOnDelete={handleOnDelete}
        personId={personId}
        currentMediaFile={currentMediaFile}
        onClickCancel={onClickCancel}
        onClickEdit={onClickEdit}
        isSubmitDisabled={pristine || isSubmitting}
        isSubmitting={isSubmitting}
        dialogMode={dialogMode}
        viewMode={viewMode}
        areaType={areaType}
      />
    </div>
  );
};

export default MediaEditMode;
