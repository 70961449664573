import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  formLabel: {
    fontSize: '12px',
  },
  additionalFields: {
    marginTop: '20px',
  },
  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  rowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  rowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '2x',
  },
  header: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    marginTop: '13px',
  },
  textField: {
    color: '#0068A8',
    marginTop: '12px',
    minHeight: '60px',
  },
  activityItemTextField: {
    color: '#0068A8',
  },
  totalCalories: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addActivityItemForm: {
    borderRadius: 4,
    border: '1px solid #0068A8',
    backgroundColor: '#FBFBFB',
    padding: '7px 15px',
    marginBottom: '20px',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  checkBoxFields: {
    maxWidth: 'fit-content',
    textAlign: 'center',
    whiteSpace: 'pre',
    fontSize: '14px',
    color: '#535461',
    marginTop: '-20px',
  },
  ateSlowlyField: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    margin: '5px 0px',
  },
  checkBoxText: {
    fontSize: '13px',
    lineHeight: '1',
  },
  removeField: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14x',
    letterSpacing: '0.15px',
  },
  addField: {
    display: 'flex',
    color: '#0068A8',
    marginLeft: '10px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  radioFields: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalCaloriesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  focused: {},
});
