import React, { useEffect, useState } from 'react';
import { DEPENDENT_SEARCH_FAILURE } from '../../../../../store/reducers/dependents/action';

const useSearchDepenent = (searchMember, formValues) => {
  const [canCreateDependent, setCanCreateDependent] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const searchDependent = async () => {
    setIsSearching(true);
    if (formValues) {
      const { firstName, lastName, postalCode, dob } = formValues;
      const res = await searchMember({ firstName, lastName, postalCode, dob });
      if (res.type === DEPENDENT_SEARCH_FAILURE) {
        setCanCreateDependent(true);
      } else {
        setCanCreateDependent(false);
      }
    }
    setIsSearching(false);
  };
  return {
    canCreateDependent,
    searchDependent,
    isSearching,
    setCanCreateDependent,
  };
};

export default useSearchDepenent;
