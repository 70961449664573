import React, { useState } from 'react';
import { Grid, Typography, Collapse, ButtonBase } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonBase: {
    border: 'none',
    borderTop: '2px solid rgb(0, 104, 168, .8)',
    background: '#ffffff',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  expandIcon: {
    transition: 'all 200ms ease 0s',
    marginRight: '3px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  emptyContainerDiv: {
    minHeight: '133px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    boxShadow: 'inset 0px 2px 10px rgba(83, 86, 94, 0.25)',
  },
  detailsDIV: {
    boxShadow: 'inset 0px 2px 10px rgba(83, 86, 94, 0.25)',
  },
});

const AccordionPanel = ({
  title,
  details = null,
  emptyState,
  detailsBackground = '#F0F0F0',
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const renderDetails = () => {
    if (details) {
      return <div className={classes.detailsDIV}>{details}</div>;
    } else {
      return (
        <div className={classes.emptyContainerDiv}>
          <Typography style={{ fontSize: '14px' }}> {emptyState} </Typography>
          <Typography style={{ fontSize: '14px' }}>
            {' '}
            Please try again later.{' '}
          </Typography>
        </div>
      );
    }
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <ButtonBase
          centerRipple
          focusRipple
          onClick={() => setOpen(!open)}
          className={classes.buttonBase}
        >
          {title}
          <div
            style={{ transform: !open ? 'scaleY(1)' : 'scaleY(-1)' }}
            className={classes.expandIcon}
          >
            <ExpandMoreIcon />
          </div>
        </ButtonBase>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          background: Boolean(details) ? detailsBackground : '#f0f0f0',
        }}
      >
        <Collapse in={open} timeout={200}>
          {renderDetails()}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default AccordionPanel;
