import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import {
  renderFormInputInline,
  renderFormInput,
} from '../../../library/forms/Field';
import validate from './Validate';
import asyncValidate from './ValidateAsync';
import submit from './SubmitForm';

// eslint-disable-next-line
import './ChangePasswordForm.css';
import InputField from '../../../library/forms/InputField';
import PasswordField from '../../../library/forms/PasswordField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  'w-100': {
    width: '100%',
  },
});

class ChangePasswordForm extends Component {
  render() {
    const { handleSubmit, isUpdating, classes } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form-horizontal">
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12} md={6} lg={5}>
            <Field
              type="password"
              name="oldPassword"
              label="Current Password"
              hint="Enter your old password"
              component={PasswordField}
              min={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} className={classes['w-100']}>
            <Field
              type="password"
              name="newPassword"
              label="New Password"
              hint="Enter your replacement password"
              component={PasswordField}
              min={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} className={classes['w-100']}>
            <Field
              type="password"
              name="conPassword"
              label="Confirm Password"
              hint="Re-enter the same replacement password"
              component={PasswordField}
              min={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              type="submit"
              color="primary"
              disabled={isUpdating}
            >
              Save New Password
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'change-password-form',
  onSubmit: submit,
  validate,
  asyncValidate,
  asyncBlurFields: ['oldPassword'],
  shouldAsyncValidate: ({ syncValidationPasses, trigger }) => {
    if (!syncValidationPasses) return false;
    return trigger !== 'submit';
  },
  touchOnChange: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(withStyles(styles)(ChangePasswordForm));
