import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const APP_INITIAL_SESSION_REQUEST = 'app/initial/session/request';
export const APP_INITIAL_SESSION_SUCCESS = 'app/initial/session/success';
export const APP_INITIAL_SESSION_FAILURE = 'app/initial/session/failure';

export const APP_AVATAR_CHANGE_ATTEMPT = 'app/avatar/change/request';
export const APP_AVATAR_CHANGE_SUCCESS = 'app/avatar/change/success';
export const APP_AVATAR_CHANGE_FAILURE = 'app/avatar/change/failure';

export const APP_TIMEZONE_CHANGE_REQUEST = 'app/timezone/change/request';
export const APP_TIMEZONE_CHANGE_SUCCESS = 'app/timezone/change/success';
export const APP_TIMEZONE_CHANGE_FAILURE = 'app/timezone/change/failure';

export const SESSION_EXPIRED = 'app/session/expired';

export const avatarUpdateAttempt = payload => ({
  type: APP_AVATAR_CHANGE_ATTEMPT,
  payload,
});

export const avatarUpdateSuccess = payload => ({
  type: APP_AVATAR_CHANGE_SUCCESS,
  payload,
});

export const avatarUpdateFailure = payload => ({
  type: APP_AVATAR_CHANGE_FAILURE,
  payload,
});

export const sessionExpired = () => ({
  type: SESSION_EXPIRED,
});

export const getUserInitialState = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      APP_INITIAL_SESSION_REQUEST,
      APP_INITIAL_SESSION_SUCCESS,
      APP_INITIAL_SESSION_FAILURE,
    ],
    endpoint: '/api/initialize/session',
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      },
    },
  },
});

export const setTimeZone = payload => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      APP_TIMEZONE_CHANGE_REQUEST,
      APP_TIMEZONE_CHANGE_SUCCESS,
      APP_TIMEZONE_CHANGE_FAILURE,
    ],
    payload,
    endpoint: '/api/member/preference/timezone',
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify(payload),
        mode: 'cors',
      },
    },
  },
});
