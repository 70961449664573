import React from 'react';
import { Button, Typography, makeStyles, Grid } from '@material-ui/core';

import ReportSVG from '../../../client/images/report-progress.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

const CompletedRequest = ({ history }) => {
  const classes = useStyles();

  const navigateBackToHealthRecordPage = () => {
    const newPath = history.location.pathname.split('/', 4).join('/');
    history.push(newPath);
  };

  return (
    <Grid
      className={classes.completeContainer}
      container
      justify="center"
      direction="column"
      alignItems="center"
    >
      <img className={classes.root} src={ReportSVG} />
      <Typography align="center" variant="h5">
        Medical Record Request Submitted
      </Typography>
      <Typography
        align="center"
        variant="body1"
        style={{ maxWidth: 640, marginBottom: 16, marginTop: 8, opacity: 0.6 }}
      >
        We will review your request and contact you with any questions. Status
        updates will be sent to your email on file. For questions or to cancel
        your request, please contact Support via the Help page or at
        Support@AgrinHealth.com
      </Typography>
      <Button
        onClick={navigateBackToHealthRecordPage}
        color="primary"
        variant="contained"
      >
        Return to Health Records
      </Button>
    </Grid>
  );
};

export default CompletedRequest;
