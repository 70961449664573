import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router';
import { getFormValues } from 'redux-form';

import { ModalHeader } from '..';
import { Grid, Button, Typography } from '@material-ui/core';
import ModalFooter from '../ModalFooter';

import Provider01Form from '../Provider01Form';
import Illustration from '../Illustration';
import Provider02Form from '../Provider02Form';
import ProviderIllustration from '../../../../client/svgs/providers.svg';

import ProgressButtonLinear from '../../Buttons/ProgressButtonLinear/ProgressButtonLinear';
import FormWizard from '../../FormWizard';
import ReviewAccess from '../ReviewAccess';
import ProviderSearch from '../ProviderSearch';
import ProviderRequestJoin from '../ProviderRequestJoin';
import ProviderEdit from '../ProviderEdit';

const ENTITY_TYPE_ORGANIZATION = '2';

export const FORM_NAME = 'care-team-add';

const Provider = props => {
  const {
    personId,
    relationshipType,
    baseRoutePath,
    history,
    location,
    member,
    updateMember,
    isUpdating,
    dispatch,
    onSubmit,
    header,
    navigation,
    onClickGoBack,
  } = props;

  const [selectedProvider, setSelectedProvider] = useState(
    props.selectedProvider
  );

  const [formState01, setFormState01] = useState({
    valid: false,
  });

  const [formState02, setFormState02] = useState({
    valid: false,
    pristine: true,
    submitting: false,
  });

  const [editValues, setEditValues] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (!relationshipType && editValues === null) {
      history.replace(`${baseRoutePath}/modify`);
    }
  }, [relationshipType, editValues]);

  const links = [
    {
      label: 'Name',
      route: `${baseRoutePath}/modify/provider/step-one`,
      current:
        `${baseRoutePath}/modify/provider/step-one` === location.pathname,
      disableSecondaryNav: formState01.valid,
    },
    {
      label: 'Location',
      route: `${baseRoutePath}/modify/provider/step-two`,
      current:
        `${baseRoutePath}/modify/provider/step-two` === location.pathname,
      disableSecondaryNav: formState01.valid,
    },
  ];

  const hasRoleChanged = isUpdating
    ? relationshipType !== updateMember.relationshipType
    : false;

  const isDisabledNoAccess =
    !isUpdating &&
    !hasRoleChanged &&
    !formState02.valid &&
    formState02.pristine &&
    !formState02.submitting;

  const isDisabledGrantAccess = isDisabledNoAccess || !(member && member.email);

  var fullName = '';
  if (selectedProvider) {
    if (selectedProvider.entityTypeCode === ENTITY_TYPE_ORGANIZATION) {
      fullName = !selectedProvider.locationName
        ? selectedProvider.organizationName
        : selectedProvider.locationName;
    } else if (selectedProvider.displayName) {
      fullName = selectedProvider.displayName;
    } else {
      fullName = [
        selectedProvider.prefix || '',
        selectedProvider.firstName || '',
        selectedProvider.lastName || '',
        selectedProvider.suffix || '',
      ]
        .join(' ')
        .trim();
    }
  }

  const handleSubmit = (event, values) => {
    event.preventDefault();

    onSubmit(
      {
        relationshipType,
        hasAccess: true,
        fullName,
        ...values,
      },
      dispatch
    );
  };

  const handleSubmitWithoutAccess = (isManualEntry = false) => event => {
    event.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      onSubmit(
        {
          relationshipType,
          hasAccess: false,
          isManualEntry,
          ...selectedProvider,
          ...member,
        },
        dispatch
      );
    }
  };

  const onClickReview = () => {
    history.push(`${props.baseRoutePath}/modify/provider/review`);
  };

  const onClickRequest = () => {
    history.push(`${props.baseRoutePath}/modify/provider/request-to-join`);
  };

  const onSubmitEdit = async (values, dispatch) => {
    const { careTeam } = navigation.dashboard;

    var fullName;
    if (values.relationshipType == 'Provider') {
      fullName = values.fullName;
    } else if (values.entityTypeCode === '2') {
      fullName = values.organizationName
        ? values.organizationName
        : values.locationName;
    } else {
      fullName = [
        values.prefix || '',
        values.firstName,
        values.lastName,
        values.suffix,
      ]
        .join(' ')
        .trim();
    }

    const newValues = {
      ...values,
      hasAccess: true,
      displayName: values.providerId ? fullName : fullName,
      isManualEntry: true,
      fullName,
    };

    setEditValues(newValues);

    history.push(`${careTeam}/modify/provider/review`, {
      updatedProvider: newValues,
    });
  };

  const formAction01 = isUpdating ? 'Update' : 'Add';
  const formAction02 = isUpdating ? 'Edit' : 'Enter';

  const modalHeader =
    selectedProvider &&
    selectedProvider.entityTypeCode === ENTITY_TYPE_ORGANIZATION
      ? selectedProvider.locationName
        ? `${selectedProvider.organizationName} - ${selectedProvider.locationName}`
        : selectedProvider.organizationName
      : fullName;

  return (
    <Switch>
      <Route
        path={`${props.baseRoutePath}/modify/provider/edit`}
        render={childProps => {
          if (!selectedProvider) return '';

          return (
            <React.Fragment>
              {selectedProvider && (
                <div>
                  <ModalHeader
                    header={header}
                    subHeader={`Information for ${modalHeader}`}
                  />
                  <ProviderEdit
                    {...childProps}
                    member={editValues || selectedProvider}
                    onCancel={onClickGoBack}
                    onSubmit={onSubmitEdit}
                    personId={personId}
                  />
                </div>
              )}
            </React.Fragment>
          );
        }}
      />
      <Route
        path={`${props.baseRoutePath}/modify/provider/request-to-join`}
        render={childProps => (
          <ProviderRequestJoin {...childProps} member={selectedProvider} />
        )}
      />
      <Route
        path={`${props.baseRoutePath}/modify/provider/search`}
        render={childProps => (
          <React.Fragment>
            <div>
              <ModalHeader
                header={header}
                subHeader={`2. Search for your provider`}
              />
              <ProviderSearch
                {...childProps}
                personId={personId}
                member={member}
                isUpdating={isUpdating}
                relationshipType={relationshipType}
                onClickGrantAccess={handleSubmit}
                history={history}
                baseRoutePath={`${props.baseRoutePath}/modify/provider`}
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
              />
            </div>
            <React.Fragment>
              {selectedProvider && (
                <ModalFooter
                  leftCol={<Button onClick={onClickGoBack}>Back</Button>}
                  rightCol={
                    <React.Fragment>
                      <Grid item>
                        {/* linearProgress */}
                        <ProgressButtonLinear
                          color="primary"
                          variant="contained"
                          onClick={handleSubmitWithoutAccess(true)}
                          isProgressing={isSubmitting}
                          disabled={isSubmitting}
                        >
                          {formAction01} Without Access
                        </ProgressButtonLinear>
                      </Grid>

                      {/* <Grid item>
                        {selectedProvider.onPlatform ? (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={onClickReview}
                          >
                            Review/Grant Access
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={onClickRequest}
                          >
                            Request To Join
                          </Button>
                        )}
                      </Grid> */}
                    </React.Fragment>
                  }
                />
              )}
            </React.Fragment>
          </React.Fragment>
        )}
      />
      <Route
        path={`${props.baseRoutePath}/modify/provider/review`}
        render={childProps => (
          <ReviewAccess
            {...childProps}
            header={header}
            member={member}
            isUpdating={isUpdating}
            relationshipType={relationshipType}
            onClickGrantAccess={handleSubmit}
            isForProvider
          />
        )}
      />
      <Route
        path={`${props.baseRoutePath}/modify/provider/:manual`}
        render={() => (
          <React.Fragment>
            <div>
              <ModalHeader
                header={header}
                subHeader={`2. ${formAction02} member's information`}
              />
              <Grid container spacing={6}>
                <Grid item xs={3}>
                  <Illustration svg={ProviderIllustration} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" style={{ marginBottom: 24 }}>
                    Tell us about your provider
                  </Typography>
                  <FormWizard
                    formName={FORM_NAME}
                    baseRoutePath={`${props.baseRoutePath}/modify/provider/`}
                    location={location}
                    onSubmit={() => {}}
                    initialValues={
                      isUpdating
                        ? {
                            prefix: updateMember.prefix,
                            firstName: updateMember.firstName,
                            lastName: updateMember.lastName,
                            email: updateMember.email,
                            phoneNumber: updateMember.phoneNumber,
                            labels: updateMember.labels,
                            address: updateMember.address,
                            street: updateMember.street,
                            street2: updateMember.street2,
                            city: updateMember.city,
                            state: updateMember.state,
                            postalCode: updateMember.postalCode,
                          }
                        : {}
                    }
                  >
                    <Provider01Form
                      routePath="step-one"
                      links={links}
                      onChangeForm={setFormState01}
                      personId={personId}
                    />
                    <Provider02Form
                      routePath="step-two"
                      links={links}
                      onChangeForm={setFormState02}
                    />
                  </FormWizard>
                </Grid>
              </Grid>
            </div>
            <ModalFooter
              leftCol={<Button onClick={onClickGoBack}>Back</Button>}
              rightCol={
                <React.Fragment>
                  <Grid item>
                    <i style={{ fontSize: 14, marginTop: 8 }}>
                      A manual provider will not have access
                    </i>
                  </Grid>
                  <Grid item>
                    <ProgressButtonLinear
                      color="primary"
                      variant="contained"
                      disabled={isDisabledNoAccess || isSubmitting}
                      onClick={handleSubmitWithoutAccess(true)}
                      isProgressing={isSubmitting}
                    >
                      {formAction01} Without Access
                    </ProgressButtonLinear>
                  </Grid>
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      />
    </Switch>
  );
};

const getSelectedCareTeamMember = ({ careTeam }) =>
  (careTeam.list && careTeam.list[careTeam.selectedId]) || null;

const mapStateToProps = state => ({
  member: getFormValues(FORM_NAME)(state),
  personId: state.active.user.personId,
  selectedProvider: getSelectedCareTeamMember(state),
});

export default connect(mapStateToProps)(withRouter(Provider));
