import { addWeeks, startOfMonth, startOfWeek, addDays } from 'date-fns';
import { DATERANGE } from '../ChartWrapper';
export const DISCREET_DATA_POINTS = 'DiscreetDataSet';
export const WEEKLY_AVERAGE = 'WeeklyAverageDataSet';
const { ONE_WEEK, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR } = DATERANGE;
export const whichDataSet = dateRange =>
  [DATERANGE.ONE_WEEK, DATERANGE.THREE_MONTHS, DATERANGE.ONE_MONTH].includes(
    dateRange
  )
    ? DISCREET_DATA_POINTS
    : WEEKLY_AVERAGE;

export const parseDate = (date, dateRange) => {
  switch (dateRange) {
    case ONE_MONTH:
    case ONE_WEEK:
    case THREE_MONTHS: {
      return date.setHours(12, 0, 0, 0);
    }
    case ONE_YEAR:
    case SIX_MONTHS: {
      return startOfWeek(date);
    }
    default: {
      return addWeeks(startOfMonth(date), 2).setHours(12);
    }
  }
};
