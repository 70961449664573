import React from 'react';
import { ALLERGY } from '../../../../store/reducers/mediaObject';
import DrawerAccordion from '~/src/shared/components/DrawerAccordion/DrawerAccordion';
import AttachmentsCompose from '../../../containers/AttachmentsCompose';

const AttachmentPanel = props => {

  return (
    <DrawerAccordion summaryTitle="Attachments">
      <AttachmentsCompose
            appArea={ALLERGY}
            isSubmitting={props.isUploadingFile}
            goBack={() => {}}
            mediaObjects={props.mediaObjects}
            bundlesToDelete={props.bundlesToDelete}
          />
    </DrawerAccordion>
  );
};

export default AttachmentPanel;
