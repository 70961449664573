import React from 'react';
import {
  Grid,
  Container,
  Paper,
  Typography,
  Collapse,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import MediaObjectHeader from '../../../containers/MediaObject/Header';
import DashboardMessage from '../../DashboardContent/DashboardMessage';
import {
  setFiles,
  removeFile,
  setDescription,
  setMediaDate,
  setMediaTime,
  uploadFiles,
} from '../../../../store/reducers/mediaObject';
import {
  setMedia,
  setCurrentItem,
} from '../../../../store/reducers/mediaObjectDrawer/actions';

import {
  MIME_TYPES_IMAGE,
  MIME_TYPES_ALL,
} from '../../../../library/helpers/mimeTypes';

const DropzoneComponent = props => {
  const { isSubmitting = false } = props;
  const [errorMessage, setErrorMessage] = React.useState({
    isOpen: false,
    filename: '',
  });
  const dropZoneStyle = {
    width: 'auto',
    height: 'auto',
    cursor: 'pointer',
  };

  const handleOnDrop = files => {
    const { bundles, appArea, setCurrentItem } = props;

    let temp = [];
    let found = false;
    handleOnMessageClose();
    if (bundles.length > 0)
      for (var i = 0; i < files.length; i++) {
        found = false;
        for (var j = 0; j < bundles.length; j++) {
          // If the file is found in the new files array, and in state.bundles
          // break from the
          if (files[i].name === bundles[j].file.name) {
            found = true;
            break;
          }
        }

        // If the file is not found, then it's considered unique.
        if (!found) {
          temp.push({
            appArea,
            file: files[i],
            description: '',
            loaded: 0,
            total: files[i].size,
            isUploading: false,
            isUploaded: false,
          });
        }
      }
    else {
      temp = files.map(file => ({
        appArea,
        file,
        description: '',
        loaded: 0,
        total: file.size,
        isUploading: false,
        isUploaded: false,
      }));
    }

    const newBundles = bundles.concat(temp);

    if (newBundles.length > 0) {
      props.setFiles(newBundles);
      props.onDrop(); //opens attachment compose drawer
    }
  };

  const handleOnMessageClose = () => {
    setErrorMessage({
      isOpen: false,
      filename: '',
    });
  };

  const handleOnDropRejected = file => {
    setErrorMessage({
      isOpen: true,
      filename: file.name,
    });
  };

  return (
    <React.Fragment>
      <Dropzone
        style={dropZoneStyle}
        onDropRejected={file => handleOnDropRejected(file[0])}
        onDrop={handleOnDrop}
        multiple={false}
        // accept="image/jpeg,image/png,video/mp4,application/pdf,.doc,.docx,text/plain"
        accept={MIME_TYPES_ALL.join(',')}
      >
        {({ isDragActive }) =>
          !isSubmitting && <MediaObjectHeader isDragActive={isDragActive} />
        }
      </Dropzone>
      <Collapse in={errorMessage.isOpen}>
        <DashboardMessage
          type="warning"
          onClose={handleOnMessageClose}
          canClose={true}
        >
          <Typography>
            <span>
              We're sorry, We are unable to upload{' '}
              <b>{errorMessage.fileName}</b>. Please try again later. If the
              problem persists, please contact support at
              support@agrinhealth.com
            </span>
          </Typography>
        </DashboardMessage>
      </Collapse>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { isUploadingFiles, bundles } = state.mediaObject;
  const { uploadingProcess } = state.mediaObjectDrawer;

  return {
    isUploadingFiles,
    bundles,
    uploadingProcess,
  };
};

const mapDispatchToProps = dispatch => ({
  uploadFiles: (files, meta) => dispatch(uploadFiles(files, meta)),
  setFiles: files => dispatch(setFiles(files)),
  setDescription: (index, description) =>
    dispatch(setDescription(index, description)),
  setMediaDate: (index, date) => dispatch(setMediaDate(index, date)),
  setMediaTime: (index, time) => dispatch(setMediaTime(index, time)),
  removeFile: index => dispatch(removeFile(index)),
  setCurrentItem: bundle => setCurrentItem(bundle),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropzoneComponent);
