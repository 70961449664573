import React, { Component } from 'react';
import {
  FormGroup,
  FormControl,
  InputGroup,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import VirtualizedSelect from 'react-virtualized-select';
import classNames from 'classnames';

import { mask } from '../helpers/format';
import fieldStyles from './Field.css';

/**
 * A wrapper for standard HTML input
 * intended to be used with redux-form Field Component.
 *
 * @param props
 * @return {Component}
 */
export const renderFormCheckbox = props => {
  const { input, label, ...attributes } = props;

  return (
    <div className="form-group">
      <div className="checkbox">
        <label>
          <input type="checkbox" {...input} {...attributes} /> {label}
        </label>
      </div>
    </div>
  );
};

/**
 * A wrapper for standard HTML input
 * intended to be used with redux-form Field component.
 *
 * @param props
 * @return {Component}
 */
export const renderFormInput = props => {
  const {
    input,
    label,
    hint,
    meta: { asyncValidating, touched, error },
    placeholder,
    ...attributes
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label}</label>
      <div className={asyncValidating ? fieldStyles.inputAsync : ''}>
        <input
          {...input}
          {...attributes}
          className="form-control"
          placeholder={placeholder || label}
        />
      </div>
      {hint && <small>{hint} </small>}
      {touched && error && (
        <span className={fieldStyles.errorMsg}>
          <i className="fa fa-exclamation-circle"></i> {error}
        </span>
      )}
    </div>
  );
};

export const renderFormInputInline = props => {
  const {
    input,
    label,
    hint,
    meta: { asyncValidating, touched, error },
    placeholder,
    ...attributes
  } = props;

  return (
    <div className="form-group">
      <label
        style={{ textAlign: 'right' }}
        className="col-md-3"
        htmlFor={input.name}
      >
        {label}
      </label>
      <div
        className={classNames({
          'col-md-9': true,
          [fieldStyles.inputAsyncInline]: asyncValidating,
        })}
      >
        <input
          {...input}
          {...attributes}
          className="form-control"
          placeholder={placeholder || label}
        />
      </div>
      {hint && <small>{hint} </small>}
      {touched && error && (
        <div className="col-md-offset-3 col-md-9">
          <span className={fieldStyles.errorMsg}>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            &nbsp; {error}
          </span>
        </div>
      )}
    </div>
  );
};

/**
 * A wrapper for standard HTML select input
 * intended to be used with redux-form Field component.
 *
 * @param props
 * @return {Component}
 */
export const renderFormSelect = props => {
  const {
    input,
    children,
    label,
    meta: { touched, error },
  } = props;
  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label}</label>
      <select className="form-control" {...input}>
        {children}
      </select>
      {touched && error && (
        <span className={fieldStyles.errorMsg}>
          <i className="fa fa-exclamation-circle"></i> {error}
        </span>
      )}
    </div>
  );
};

/**
 * A wrapper for standard HTML textarea input
 * intended to be used with redux-form Field component.
 *
 * @param props
 * @return {Component}
 */
export const renderFormTextarea = props => {
  const {
    input,
    label,
    meta: { touched, error },
    placeholder,
    ...attributes
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label}</label>
      <textarea
        {...input}
        {...attributes}
        className="form-control"
        placeholder={placeholder || label}
      >
        {props.value}
      </textarea>
      {touched && error && (
        <span className={fieldStyles.errorMsg}>
          <i className="fa fa-exclamation-circle"></i> {error}
        </span>
      )}
    </div>
  );
};

/**
 * A wrapper for react-virtualized-select intended to be used
 * with redux-form Field component.
 *
 * @param {*} props
 */
export const renderVirtualizedSelect = props => {
  const {
    input,
    label,
    meta: { touched, error },
    ...remaining
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label}</label>
      <VirtualizedSelect
        {...remaining}
        onBlur={() => input.onBlur()}
        onChange={value => {
          const { valueKey = 'value' } = props;

          if (!!!value || value === null || value === '') {
            input.onChange('null');
            return;
          }

          input.onChange(value[valueKey] ? value[valueKey] : '');
        }}
        value={input.value}
      />
      {touched && error && (
        <span className={fieldStyles.errorMsg}>
          <i className="fa fa-exclamation-circle"></i> {error}
        </span>
      )}
    </div>
  );
};

/**
 * Composite field that has a text field, and options list combined.
 * Intended to be used with redux-form Fields component.
 *
 * Note: Cannot use simple format property because when used will be applied to all
 * inputs with the given function. This is not ideal for composite input types
 * that hold different values. If a mask needs to be applied to the input
 * value, use prop inputFieldMask.
 *
 * @param {*} props
 */
export class FormInputWithOption extends Component {
  /**
   *
   * In order to set a value [title] for react-bootstrap DropdownButton, and because it doesn't
   * initially, internally manage state, the onChange method will need to be called to set
   * the initial value so redux-form will register it. By doing this, error checking
   * is much more manageable.
   *
   * Note: trigger and onChange will typically force a re-render of the component.
   * However, componentDidMount life-cycle method will mitigate it.
   */
  componentDidMount() {
    const { input } = this.props[this.props.optionFieldName];
    input.onChange(input.value || this.props.optionInitialValue);
  }

  handleOnSelect = (key, event) => {
    this.props[this.props.optionFieldName].input.onChange(event.target.text);
  };

  render() {
    const {
      inputFieldName,
      inputFieldMask,
      optionFieldName,
      optionInitialValue,
      optionStyle,
      label,
      placeholder,
      options,
    } = this.props;

    const inputField = this.props[inputFieldName];
    const inputValue = inputField.input.value;
    const value = inputFieldMask
      ? mask(inputValue, inputFieldMask)
      : inputValue;

    delete inputField.input.value;

    return (
      <FormGroup>
        <label htmlFor={inputFieldName}>{label}</label>
        <InputGroup>
          <FormControl
            {...inputField.input}
            value={value}
            type="text"
            placeholder={placeholder}
          />
          <DropdownButton
            style={optionStyle}
            title={
              this.props[optionFieldName].input.value || optionInitialValue
            }
            id={optionFieldName}
            componentClass={InputGroup.Button}
            onSelect={this.handleOnSelect}
          >
            {options.map(option => (
              <MenuItem key={option}>{option}</MenuItem>
            ))}
          </DropdownButton>
        </InputGroup>
        {inputField.meta.touched && inputField.meta.error && (
          <span className={fieldStyles.errorMsg}>{inputField.meta.error}</span>
        )}
      </FormGroup>
    );
  }
}

export class Input extends React.Component {
  handleChange(event) {
    this.props.input.onChange(event);
    if (this.props.hasOwnProperty('onInputChange')) {
      this.props.onInputChange(event);
    }
  }

  render() {
    const {
      label,
      input,
      type,
      meta: { touched, error },
    } = this.props;

    let { placeholder } = this.props;

    if (placeholder) {
      placeholder = label;
    }

    return (
      <div>
        <input
          type={type}
          {...input}
          onChange={e => input.onChange(this.handleChange(e))}
        />
        {touched && error && <span className="error-msg">{error}</span>}
      </div>
    );
  }
}
