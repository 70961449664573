import React from 'react';

export default ({ disabled }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67148 17.4L19.1215 9.92002C19.9315 9.57002 19.9315 8.43002 19.1215 8.08002L1.67148 0.600017C1.01148 0.310017 0.281484 0.800017 0.281484 1.51002L0.271484 6.12002C0.271484 6.62002 0.641484 7.05002 1.14148 7.11002L15.2715 9.00002L1.14148 10.88C0.641484 10.95 0.271484 11.38 0.271484 11.88L0.281484 16.49C0.281484 17.2 1.01148 17.69 1.67148 17.4Z"
      fill={disabled ? '#d3d3d3' : '#90B850'}
    />
  </svg>
);
