import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  searchMember,
  signElectronicSignatureRequest,
} from '../../../../../store/reducers/dependents/action';
import SearchCard from '../../Components/SearchCard/SearchCard';
import ESignCard from '../../Components/ESignCard/ESignCard';
import { Grid } from '@material-ui/core';
import useSearchDepenent from './useSearchDependent';
import { format } from 'date-fns';
import {
  contactInformationFields,
  identifyingInformationFields,
  otherInformationFields,
} from '../AddDependent/fields';
import { FormCard } from '../../Components/FormCard/FormCard';
import ProgressButton from '../../../../components/Buttons/ProgressButton';
import CantCreateDependentModal from './CantCreateDependentModal';

function AddAdultDependent({
  searchMember,
  formValues,
  signElectronicSignatureRequest,
  codeTypes,
  setShowDashboardMessage,
  handleSubmit,
  valid,
  isSubmitting,
  isNewDependent = false,
  meUser,
}) {
  const [hasSignedAttentation, setHasSignedAttentation] = useState(
    !isNewDependent
  );
  const {
    canCreateDependent,
    searchDependent,
    isSearching,
    setCanCreateDependent,
  } = useSearchDepenent(searchMember, formValues);

  if (hasSignedAttentation) {
    if (isNewDependent) {
      setShowDashboardMessage(true);
    }
    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormCard
              title="Identifying Information"
              fields={identifyingInformationFields(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCard
              title="Contact Information"
              fields={contactInformationFields(
                codeTypes.languageTypes,
                codeTypes.isFetchingLanguageType,
                codeTypes.stateTypes
              )}
              renderDefaultAddress={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCard
              title="Other Information"
              fields={otherInformationFields(
                codeTypes,
                formValues && formValues.gender
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ProgressButton
              type="submit"
              disabled={!valid}
              isPending={isSubmitting}
              style={{ width: '150px' }}
            >
              Save
            </ProgressButton>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <Grid container spacing={4} style={{ margin: '16px', width: '100%' }}>
      <CantCreateDependentModal
        open={canCreateDependent === false}
        onClose={() => setCanCreateDependent(null)}
      />
      <Grid item xs={12}>
        <SearchCard
          searchDependent={searchDependent}
          isSearching={isSearching}
          canCreateDependent={canCreateDependent}
          canVerify={
            formValues &&
            formValues.firstName &&
            formValues.lastName &&
            formValues.postalCode &&
            formValues.dob
          }
        />
      </Grid>
      {canCreateDependent ? (
        <Grid item xs={12}>
          <ESignCard
            meUser={meUser}
            setHasSignedAttentation={setHasSignedAttentation}
            signElectronicSignatureRequest={signElectronicSignatureRequest}
          />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
}

const mapStateToProps = state => ({
  codeTypes: state.codes,
  meUser: state.dependents.tabs.find(user => user.slug === 'me'),
  formValues:
    state.form['new-dependent-form'] && state.form['new-dependent-form'].values,
  initialValues:
    state.member && state.member.basicInfo && state.member.basicInfo.userInfo
      ? {
          ...state.member.basicInfo.userInfo,
          dob: state.member.basicInfo.userInfo.dob
            ? format(
                new Date(state.member.basicInfo.userInfo.dob),
                'yyyy-MM-dd'
              )
            : '',
        }
      : {},
});

const mapDispatchToProps = dispatch => ({
  signElectronicSignatureRequest: signature =>
    dispatch(signElectronicSignatureRequest(signature)),
  searchMember: search => dispatch(searchMember(search)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'new-dependent-form',
    asyncBlurFields: ['email'],
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      dob: '',
      postalCode: '',
    },
  })(AddAdultDependent)
);
