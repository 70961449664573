import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleoutlineIcon from '@material-ui/icons/AddCircleOutline';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import AddInfoForm from './PreviewMedia/AddInfoForm';
import styles from '../../containers/MediaObject/File.css';
import { shorten } from '../../../library/helpers/format';
import FileIcon from '../../containers/MediaObject/FileIcon';

const PreviewMedia = props => {
  const {
    bundle,
    personId,
    index,
    isSubmitting,
    removeObject,
    setCurrentIndex,
    isDocument = false,
  } = props;
  const key = `bundle${index}`;
  return (
    <div
      className={styles.block}
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <div>
        <div className={styles.preview}>
          {bundle.isUploaded && (
            <div className={styles.overlay}>
              <i className="fa fa-check-square fa-3x"></i>
            </div>
          )}
          <FileIcon bundle={bundle} />
        </div>
        <div className={styles.description}>
          <div className={styles.name}>
            {shorten(bundle.file.name, 20)}{' '}
            {!isSubmitting && (
              <div
                className={`${styles.incBtn} ${styles.incBtnClose}`}
                onClick={() => removeObject(index)}
              >
                <DeleteIcon className={styles.deleteIcon} />
              </div>
            )}
          </div>

          {!isSubmitting && (
            <div>
              {props.currentIndex !== key && (
                <div
                  className={styles.addInfoBtn}
                  onClick={() => setCurrentIndex(key)}
                >
                  <AddCircleoutlineIcon /> Details
                </div>
              )}

              {props.currentIndex === key && (
                <div
                  className={styles.addInfoBtn}
                  onClick={() => setCurrentIndex('')}
                >
                  <CollapseIcon /> Details
                </div>
              )}

              <AddInfoForm
                bundle={bundle}
                index={index}
                name={key}
                collapsed={props.currentIndex === key}
                type={props.type}
                isDocument={isDocument}
              />
            </div>
          )}
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
};

export default PreviewMedia;
