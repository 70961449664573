import React from 'react';
import { Paper, Grid, withStyles } from '@material-ui/core';

const styles = {
  root: {
    borderRadius: 4,
    boxShadow: '0 0 0 rgba(0,0,0,0)',
    border: '1px solid #CECECE',
    padding: '20px 32px',
  },
  circle: {
    backgroundColor: '#CECECE',
    width: 70,
    height: 70,
    borderRadius: 35,
    marginRight: 16,
  },
  line1: {
    backgroundColor: '#CECECE',
    width: '70%',
    height: 22,
  },
  line2: {
    backgroundColor: '#CECECE',
    width: '62%',
    height: 22,
    marginTop: 16,
  },
};

const SearchLoadingResult = ({ classes }) => {
  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item container spacing={2} xs={4} alignItems="center">
          <div className={classes.circle} />
          <div className={classes.line1} />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.line1} />
          <div className={classes.line2} />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.line1} />
          <div className={classes.line2} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(SearchLoadingResult);
