import { contentsStatus } from '../DrawerContents/Contents';
import { format } from 'date-fns-tz';
const normalizeDate = date => date.split('-').join('/');

export const useImmunizationActions = (props, setStatus) => {
  const {
    immunizationRecord,
    immunizationCreate,
    immunizationUpdate,
    immunizationSelectId,
    immunizationDelete,
    immunizationClearSelectedId,
    clearFiles,
    userImmunizations: { mediaObjects, selectedId, bundlesToDelete },
    form,
    personId,
    updateMedia,
  } = props;
  const { UPDATE, CREATE, IDLE, VIEW, SUBMIT } = contentsStatus;

  const removeImmunization = immunization => {
    immunizationDelete(immunization.personId, immunization.immunizationId);
  };

  const addImmunization = () => {
    setStatus(CREATE);
  };

  const updateImmunization = immunizationId => {
    immunizationSelectId(immunizationId);
    setStatus(UPDATE);
  };

  const viewMediaObjects = immunization => {
    immunizationSelectId(immunization.immunizationId);
    setStatus(VIEW);
  };

  const updateMediaObject = immunization => {};

  const handleCloseDrawer = () => {
    if (status !== IDLE) {
      clearFiles();
      immunizationClearSelectedId();
      setStatus(IDLE);
    }
  };

  const hasFormChanges = values => {
    return (
      form &&
      form['immunization-form'] &&
      JSON.stringify(form['immunization-form'].initial) !==
        JSON.stringify(values)
    );
  };

  const getDateTime = item => {
    const dateTime = item.newEnteredOnDate
      ? item.newEnteredOnTime
        ? new Date(
            `${normalizeDate(item.newEnteredOnDate)} ${item.newEnteredOnTime}`
          )
        : new Date(`${normalizeDate(item.newEnteredOnDate)}`)
      : new Date();
    return format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  const handleSubmit = async value => {
    const bundlesToUpload = [];
    const bundlesToUpdate = [];

    if (props.mediaObject.bundles.length > 0) {
      setStatus(SUBMIT);
    }

    Object.keys(mediaObjects).map(key => {
      const _k = key.split('.');
      const value = {
        ...form[_k[0]][_k[1]].values,
        category: 'Vaccination Records',
        enteredOn: getDateTime(form[_k[0]][_k[1]].values),
      };
      if (_k[1].includes('bundle')) {
        //bundlesToUpload[form[_k[0]][_k[1]].values.index] = value;
        bundlesToUpload.push(value);
      }
      if (_k[1].includes('previewMedia')) {
        if (!mediaObjects[key]) bundlesToUpdate.push(value);
        //bundlesToUpdate[form[_k[0]][_k[1]].values.index] = value;
      }
    });

    let result;
    if (Boolean(selectedId)) {
      result = await immunizationUpdate(
        personId,
        selectedId,
        value,
        bundlesToUpload,
        {
          hasFormChanges: hasFormChanges(value),
          bundlesToUpdate,
          bundlesToDelete,
        }
      );
    } else {
      result = await immunizationCreate(personId, value, bundlesToUpload);
    }
    handleCloseDrawer();
  };

  const onUpdateMediaObject = async (pid, mid, data) => {
    await updateMedia(pid, mid, {
      ...data.data,
      category: 'Vaccination Records',
      personId: data.personId,
    });
    handleCloseDrawer();
  };

  const actions = {
    removeImmunization,
    addImmunization,
    updateImmunization,
    viewMediaObjects,
    updateMediaObject,
    handleCloseDrawer,
    handleSubmit,
    onUpdateMediaObject,
  };

  return actions;
};
