import produce from 'immer';
import { TEMP_FIELD_NAME } from '../../../shared/components/JournalCompose/JournalEntryDrawer';

import {
  JOURNAL_MODAL_OPEN,
  JOURNAL_MODAL_CLOSE,
  JOURNAL_MODAL_COPY,
  JOURNAL_MODAL_CLEAR,
  JOURNAL_FRAGMENT_CREATE,
  JOURNAL_FRAGMENT_UPDATE,
  JOURNAL_FRAGMENT_DELETE,
  JOURNAL_FRAGMENT_FOR_UPDATE,
  JOURNAL_FRAGMENT_CHANGE,
  JOURNAL_COMPOSITION_SET,
  JOURNAL_COMPOSITION_CLEAR,
  JOURNAL_COMPOSITION_CREATE_DUPLICATE,
  JOURNAL_LOCATION_DATA_SET,
  JOURNAL_LOCATION_DATA_CLEAR,
  JOURNAL_FRAGMENT_DIALOG_OPEN,
  JOURNAL_FRAGMENT_DIALOG_CLOSE,
  JOURNAL_COMPOSITION_CREATE_REQUEST,
  JOURNAL_COMPOSITION_CREATE_SUCCESS,
  JOURNAL_COMPOSITION_CREATE_FAILURE,
  JOURNAL_COMPOSITION_UPDATE_REQUEST,
  JOURNAL_COMPOSITION_UPDATE_SUCCESS,
  JOURNAL_COMPOSITION_UPDATE_FAILURE,
  JOURNAL_COMPOSITION_COPY_REQUEST,
  JOURNAL_COMPOSITION_COPY_SUCCESS,
  JOURNAL_COMPOSITION_COPY_FAILURE,
  JOURNAL_MODAL_CREATE,
  JOURNAL_COMPOSITION_POPULATE,
  JOURNAL_MEDIA_DELETE,
  JOURNAL_MEDIA_UPDATE,
} from './actions';

import {
  MEDIA_OBJECT_SET_FILES,
  MEDIA_OBJECT_REMOVE_FILE,
  MEDIA_OBJECT_SET_DESCRIPTION,
  RESOURCE_MEDIA_MODIFIED,
} from '../mediaObject/actions';

const initialState = {
  journalEntryId: '',
  title: '',
  body: '',
  user: undefined,
  datetime: undefined,
  fragments: [],
  geoLocation: {
    coords: null,
  },
  isLocationSharingPermitted: false,
  isJournalModalOpened: false,
  isFragmentDialogOpened: false,
  isSubmitting: false,
  modalState: JOURNAL_MODAL_CREATE,
  currentFragmentDialog: undefined,
  fragmentIndexToUpdate: undefined,
  mediaObjects: [],
  fragmentGroupOrder: 0,
  tags: '',
  mediaObjectIds: [],
  isSaveDisabled: false,
  initial: {},
  hasNewMediaObject: false,

  initialMediaObject: {},
  hasMediaObjectUpdates: [],
};

const findFragmentIndex = (fragments, id) =>
  fragments.findIndex(f => f.id === id);

const reducers = {};

reducers['@@redux-form/INITIALIZE'] = (state, action) => {
  if (
    (state.modalState === 'journal-edit' ||
      state.modalState === 'journal-create') &&
    action.meta.form === 'reduxForm'
  ) {
    return {
      ...state,
      isSaveDisabled: true,
    };
  }
  if (
    state.modalState === 'journal-edit' &&
    action.meta.form.includes('addInfo')
  )
    return {
      ...state,
      initialMediaObject: {
        ...state.initialMediaObject,
        [action.meta.form]: {
          ...action.payload,
        },
      },
    };
  return state;
};

reducers['@@redux-form/CHANGE'] = (state, action) => {
  if (
    state.modalState === 'journal-create' &&
    state.isSaveDisabled &&
    !action.meta.field.includes(TEMP_FIELD_NAME)
  ) {
    return {
      ...state,
      isSaveDisabled: false,
      hasNewMediaObject: true,
    };
  }
  if (
    state.modalState === 'journal-edit' &&
    action.meta.form.includes('addInfo')
  ) {
    const initialValue =
      state.initialMediaObject[action.meta.form] &&
      state.initialMediaObject[action.meta.form][action.meta.field]
        ? state.initialMediaObject[action.meta.form][action.meta.field]
        : '';
    if (
      initialValue !== action.payload &&
      !state.hasMediaObjectUpdates.includes(action.meta.form)
    ) {
      return {
        ...state,
        hasMediaObjectUpdates: [
          ...state.hasMediaObjectUpdates,
          action.meta.form,
        ],
      };
    } else if (
      initialValue === action.payload &&
      state.hasMediaObjectUpdates.includes(action.meta.form)
    ) {
      return {
        ...state,
        hasMediaObjectUpdates: state.hasMediaObjectUpdates.filter(
          item => item !== action.meta.form
        ),
      };
    }
  }
  return state;
};

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  if (
    state.isSaveDisabled &&
    (state.modalState === 'journal-edit' || state.fragments.length === 0)
  ) {
    return {
      ...state,
      hasNewMediaObject: true,
    };
  }
  return state;
};

reducers[MEDIA_OBJECT_SET_FILES] = (state, action) => {
  if (
    state.isSaveDisabled &&
    (state.modalState === 'journal-edit' || state.fragments.length === 0)
  ) {
    return {
      ...state,
      hasNewMediaObject: true,
    };
  }
  return state;
};

reducers[MEDIA_OBJECT_SET_DESCRIPTION] = (state, action) => {
  if (
    state.isSaveDisabled &&
    (state.modalState === 'journal-edit' || state.fragments.length === 0)
  ) {
    return {
      ...state,
      isSaveDisabled: false,
    };
  }
  return state;
};

reducers[MEDIA_OBJECT_REMOVE_FILE] = (state, action) => {
  if (
    state.isSaveDisabled &&
    action.payload === 0 &&
    (state.modalState === 'journal-edit' || state.fragments.length === 0)
  ) {
    return {
      ...state,
      hasNewMediaObject: false,
    };
  }
  return state;
};

reducers[JOURNAL_MEDIA_DELETE] = (state, action) => ({
  ...state,
  mediaObjects: [
    ...state.mediaObjects.slice(0, action.payload),
    ...state.mediaObjects.slice(action.payload + 1),
  ],
  mediaObjectIds: [
    ...state.mediaObjectIds.slice(0, action.payload),
    ...state.mediaObjectIds.slice(action.payload + 1),
  ],
});

reducers[JOURNAL_MEDIA_UPDATE] = (state, action) => ({
  ...state,
  mediaObjects: [
    ...state.mediaObjects.slice(0, action.index),
    {
      ...state.mediaObjects[action.index],
      ...action.payload,
    },
    ...state.mediaObjects.slice(action.index + 1),
  ],
});

reducers[JOURNAL_MODAL_OPEN] = (state, action) => ({
  ...initialState,
  isJournalModalOpened: true,
  modalState: action.payload.modalState,
  // fragments: action.payload.fragments || [],
  // user: {
  //   label: 'Me',
  //   value: action.payload
  // }
});

reducers[JOURNAL_MODAL_CLOSE] = (state, action) => {
  const journalState =
    state.modalState === JOURNAL_MODAL_COPY ? initialState : state;
  return {
    ...state,
    isJournalModalOpened: false,
  };
};
reducers[JOURNAL_FRAGMENT_CREATE] = (state, action) => {
  const order = state.fragmentGroupOrder + 1;
  return {
    ...state,
    fragments: state.fragments.concat({
      groupOrder: order,
      ...action.payload,
    }),
    isFragmentDialogOpened: false,
    fragmentIndexToUpdate: undefined,
    fragmentGroupOrder: order,
  };
};

reducers[JOURNAL_FRAGMENT_CHANGE] = (state, action) => {
  if (action.payload.fragmentType === 'default') {
    return {
      ...state,
      fragments: [],
      isSaveDisabled: true,
      initial: {
        body: state.body,
        title: state.title,
        datetime: state.datetime,
        tags: state.tags,
      },
    };
  }
  return {
    ...state,
    fragments: [
      {
        ...action.payload,
      },
    ],
  };
};

// reducers[JOURNAL_FRAGMENT_UPDATE_DATA] = (state, action) => {
//   return {
//     ...state,
//     fragments: [{...state.fragments[0], ...action.payload}]
//   }
// }

reducers[JOURNAL_COMPOSITION_POPULATE] = (state, action) => ({
  ...state,
  ...action.payload,
  isSaveDisabled:
    action.payload.fragments.length === 0 ? true : state.isSaveDisabled,
  initial: {
    body: action.payload.body,
    title: action.payload.title,
    datetime: action.payload.datetime,
    tags: action.payload.tags,
    user: JSON.stringify(action.payload.user),
  },
});

reducers[JOURNAL_FRAGMENT_UPDATE] = (state, action) =>
  produce(state, draft => {
    // Find the index belonging to the fragment updated.
    // const index = findFragmentIndex(state.fragments, action.meta.id);
    // Replace the found index with new data.
    draft.fragments[action.meta.id] = action.payload;
    draft.currentFragmentDialog = undefined;
    draft.isFragmentDialogOpened = false;
    draft.fragmentIndexToUpdate = undefined;
  });

reducers[JOURNAL_FRAGMENT_DELETE] = (state, action) =>
  produce(state, draft => {
    // Find the index of the fragment selected for deletion.
    // const index = findFragmentIndex(state.fragments, action.meta.id);
    // Delete fragment.
    draft.fragments.splice(action.payload, 1);
  });

reducers[JOURNAL_FRAGMENT_FOR_UPDATE] = (state, action) => ({
  ...state,
  isFragmentDialogOpened: true,
  currentFragmentDialog: state.fragments[action.payload].type,
  fragmentIndexToUpdate: action.payload,
});

reducers[JOURNAL_COMPOSITION_SET] = (state, action) => {
  const { payload } = action;
  const initial = !!state.initial.user
    ? state.initial
    : {
        ...state.initial,
        user: JSON.stringify(action.payload.user),
      };
  const isSaveDisabled =
    state.modalState === 'journal-edit'
      ? JSON.stringify(initial) ==
        JSON.stringify({
          body: action.payload.body,
          title: action.payload.title,
          datetime: action.payload.datetime,
          tags: action.payload.tags,
          user: JSON.stringify(action.payload.user),
        })
      : state.fragments.length === 0
      ? // for when creating the general type, only check body, title, and tags
        JSON.stringify({
          body: initial.body,
          title: initial.title,
          tags: initial.tags,
        }) ===
        JSON.stringify({
          body: action.payload.body,
          title: action.payload.title,
          tags: action.payload.tags,
        })
      : state.isSaveDisabled;

  return {
    ...state,
    ...payload,
    initial,
    isSaveDisabled: isSaveDisabled,
  };
};

reducers[JOURNAL_COMPOSITION_CLEAR] = () => {
  return {
    ...initialState,
    modalState: JOURNAL_MODAL_CLEAR,
  };
};

reducers[JOURNAL_COMPOSITION_CREATE_DUPLICATE] = (state, action) => {
  const { payload } = action;
  return {
    ...initialState,
    ...payload,
    isJournalModalOpened: true,
  };
};

reducers[JOURNAL_LOCATION_DATA_SET] = (state, action) => ({
  ...state,
  geoLocation: action.payload,
});

reducers[JOURNAL_LOCATION_DATA_CLEAR] = () => {
  return initialState.geoLocation;
};

reducers[JOURNAL_FRAGMENT_DIALOG_OPEN] = (state, action) => ({
  ...state,
  currentFragmentDialog: action.payload,
  isFragmentDialogOpened: true,
  isJournalModalOpened: true,
});

reducers[JOURNAL_FRAGMENT_DIALOG_CLOSE] = (state, action) => ({
  ...state,
  isFragmentDialogOpened: false,
});

reducers[JOURNAL_COMPOSITION_CREATE_REQUEST] = (state, action) => ({
  ...state,
  isSubmitting: true,
});

reducers[JOURNAL_COMPOSITION_CREATE_SUCCESS] = (state, action) => ({
  ...state,
  isSubmitting: false,
});

reducers[JOURNAL_COMPOSITION_CREATE_FAILURE] = (state, action) => ({
  ...state,
  isSubmitting: false,
  error: action.payload,
});

reducers[JOURNAL_COMPOSITION_COPY_REQUEST] = (state, action) => ({
  ...state,
  isSubmitting: true,
});

reducers[JOURNAL_COMPOSITION_COPY_SUCCESS] = (state, action) => ({
  ...state,
  isSubmitting: false,
});

reducers[JOURNAL_COMPOSITION_COPY_FAILURE] = (state, action) => ({
  ...state,
  isSubmitting: false,
  error: action.payload,
});

reducers[JOURNAL_COMPOSITION_UPDATE_REQUEST] = (state, action) => ({
  ...state,
  isSubmitting: true,
});

reducers[JOURNAL_COMPOSITION_UPDATE_SUCCESS] = (state, action) => ({
  ...state,
  isSubmitting: false,
});

reducers[JOURNAL_COMPOSITION_UPDATE_FAILURE] = (state, action) => ({
  ...state,
  isSubmitting: false,
  error: action.payload,
});

export default (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};
