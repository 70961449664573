import React from 'react';
import ErrorTooltip from '../../Forms/ErrorTooltip';
import { TextField } from '@material-ui/core';

const ReduxTextField = props => {
  const {
    divStyle,
    hint,
    style,
    input: { onChange, value, onFocus, onBlur },
    meta: { error, touched },
    ...rest
  } = props;
  const hasError = !!(touched && error);
  return (
    <div style={{ maxWidth: '70px', width: '100%', ...divStyle }}>
      <ErrorTooltip {...props} displayErrorEndAdornment={true}>
        <TextField
          {...rest}
          onChange={onChange}
          onBlur={onBlur}
          error={hasError}
          onFocus={onFocus}
          fullWidth
          helperText={hint ? hint : ''}
          value={value}
          FormHelperTextProps={{
            style: {
              fontSize: '11px',
            },
          }}
          style={{
            ...style,
            marginBottom: hasError || hint ? '0px' : '22px',
          }}
        />
      </ErrorTooltip>
    </div>
  );
};

export default ReduxTextField;
