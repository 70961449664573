const isCurrentPassword = model =>
  new Promise(resolve =>
    fetch(`${window.__env.API_URL}/oauth2/confirm-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
    }).then(response => {
      if (response.ok) {
        resolve(true);
      }
      resolve(false);
    })
  );

// Email address is not original a part of the form data.
// The email address will need to be added to the payload sent to
// the server using identifyUserWith from props.
export default (values, dispatch, props) => {
  return isCurrentPassword({
    emailAddress: props.identifyUserWith,
    password: values.oldPassword,
  }).then(result => {
    if (!result) {
      throw {
        oldPassword:
          "The password used doesn't match the current password saved on record",
      };
    }
  });
};
