import {
  MEDIA_REMOVE_REQUEST,
  MEDIA_REMOVE_FAILURE,
  MEDIA_REMOVE_SUCCESS,
  MEDIA_UPDATE_REQUEST,
  MEDIA_UPDATE_FAILURE,
  MEDIA_UPDATE_SUCCESS,
  MEDIA_SET_MEDIA,
  MEDIA_FETCH_REQUEST,
  MEDIA_FETCH_SUCCESS,
  MEDIA_FETCH_FAILURE,
  MEDIA_UPLOAD_SUCCESS,
  MEDIA_UPLOAD_REQUEST,
  MEDIA_SET_CURRENT_ITEM,
  MEDIA_SET_ITEM,
  MEDIA_HEADER_SET_STATE,
  MEDIA_DRAWER_SET_STATE,
  DRAWER_STATUS_CLOSED,
  SET_AREA_TYPE,
} from './actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
} from '../mediaObject/actions';

import { DOCUMENTS_DRAWER_TOGGLE } from '../documents/actions';

export const DRAWER_STATUS_ADD = 'ADD';
const DRAWER_STATUS_LIST = 'LIST';
const DRAWER_STATUS_GRID = 'GRID';
const DRAWER_STATUS_VIEW = 'VIEW';
const DRAWER_STATUS_EDIT = 'EDIT';

const DRAWER_STATUS_FETCHING = 'FETCHING';
const DRAWER_STATUS_UPDATING = 'UPDATING';
const DRAWER_STATUS_ERROR = 'ERROR';

const DRAWER_STATUS_REMOVE = 'REMOVE';

const MEDIA_OBJECT_STATUS_UPDATING = 'UPDATING';
const MEDIA_OBJECT_STATUS_DELETING = 'DELETING';
const MEDIA_OBJECT_STATUS_ERROR = 'ERROR';

const sortDate = (a, b) => {
  const l = a.enteredOn;
  const r = b.enteredOn;

  if (!l) return -1;
  else if (!r) return 1;

  return -(l < r ? -1 : l > r ? 1 : 0);
};

const initialState = {
  isFetchingMedia: false,
  isMediaUpdate: false,
  isRemoveMedia: false,
  allUploaded: false,
  uploadingProcess: false,
  currentMediaFile: {},
  currentMediaFileIndex: null,
  mediaCollection: [],
  documentData: {},
  headerStatus: DRAWER_STATUS_LIST,
  drawerStatus: DRAWER_STATUS_FETCHING,
  areaType: null,
};

const reducers = {};

reducers[DRAWER_STATUS_CLOSED] = (state, action) => {
  return {
    ...initialState,
  };
};

reducers[SET_AREA_TYPE] = (state, action) => {
  return {
    ...state,
    areaType: action.payload,
  };
};

reducers[DOCUMENTS_DRAWER_TOGGLE] = (state, action) => {
  return action.payload
    ? state
    : {
        ...initialState,
      };
};

reducers[MEDIA_DRAWER_SET_STATE] = (state, action) => {
  return {
    ...state,
    drawerStatus: action.payload,
  };
};

reducers[MEDIA_HEADER_SET_STATE] = (state, action) => {
  return {
    ...state,
    headerStatus: action.payload,
  };
};

reducers[MEDIA_SET_MEDIA] = (state, action) => ({
  ...state,
  isFetchingMedia: true,
  mediaCollection:
    action.payload.length > 1 ? action.payload.sort(sortDate) : action.payload,
  drawerStatus:
    action.payload.length > 0 ? DRAWER_STATUS_LIST : DRAWER_STATUS_FETCHING,
});

reducers[MEDIA_SET_ITEM] = (state, action) => {
  const currentMediaFile = state.mediaCollection[action.payload];
  return {
    ...state,
    currentMediaFile,
    currentMediaFileIndex: action.payload,
    drawerStatus: DRAWER_STATUS_VIEW,
  };
};

reducers[MEDIA_SET_CURRENT_ITEM] = (state, action) => {
  const currentMediaFileIndex = state.mediaCollection.findIndex(
    x => x.mediaObjectId === action.payload.mediaObjectId
  );
  return {
    ...state,
    currentMediaFileIndex,
    currentMediaFile: action.payload,
    drawerStatus: DRAWER_STATUS_VIEW,
  };
};

reducers[MEDIA_FETCH_REQUEST] = (state, action) => ({
  ...state,
  isFetchingMedia: false,
  drawerStatus: DRAWER_STATUS_FETCHING,
});

reducers[MEDIA_FETCH_FAILURE] = (state, action) => ({
  ...state,
  isFetchingMedia: false,
  drawerStatus: DRAWER_STATUS_ERROR,
});

reducers[MEDIA_FETCH_SUCCESS] = (state, action) => {
  const { mediaObjects } = action.payload;

  return {
    ...state,
    isFetchingMedia: true,
    mediaCollection: mediaObjects.sort(sortDate),
    drawerStatus: state.headerStatus,
  };
};

reducers[MEDIA_REMOVE_REQUEST] = (state, action) => ({
  ...state,
  isRemoveMedia: false,
});

reducers[MEDIA_REMOVE_FAILURE] = (state, action) => ({
  ...state,
  isRemoveMedia: false,
});

reducers[MEDIA_REMOVE_SUCCESS] = (state, action) => {
  return {
    ...state,
    isRemoveMedia: true,
    mediaCollection: state.mediaCollection
      .filter(data => data.mediaObjectId !== action.payload)
      .sort(sortDate),
    drawerStatus: DRAWER_STATUS_LIST,
  };
};

reducers[MEDIA_UPDATE_REQUEST] = (state, action) => {
  const oldMediaFile = state.mediaCollection.find(
    x => x.mediaObjectId === action.payload.mediaObjectId
  );

  const currentMediaFile = {
    ...oldMediaFile,
    ...action.payload,
  };

  const updateObjectInArray = state.mediaCollection.map(obj => {
    if (obj.mediaObjectId !== action.payload.mediaObjectId) return obj;
    return currentMediaFile;
  });

  return {
    ...state,
    currentMediaFile,
    mediaCollection: updateObjectInArray,
    isMediaUpdate: false,
  };
};

reducers[MEDIA_UPDATE_FAILURE] = (state, action) => ({
  ...state,
  isRemoveMedia: false,
});

reducers[MEDIA_UPDATE_SUCCESS] = (state, action) => {
  return {
    ...state,
    isMediaUpdate: true,
    drawerStatus: DRAWER_STATUS_VIEW,
  };
};

reducers[MEDIA_UPLOAD_REQUEST] = (state, action) => ({
  ...state,
  uploadingProcess: true,
});

reducers[MEDIA_UPLOAD_SUCCESS] = (state, action) => ({
  ...state,
  allUploaded: true,
  uploadingProcess: false,
  drawerStatus: state.headerStatus,
});

// ===========================================
// Actions received externally
// ===========================================

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  if (action.meta.operation != RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE)
    return state;

  return {
    ...state,
    mediaCollection: []
      .concat(state.mediaCollection, action.payload)
      .sort(sortDate),
  };
};

const reducer = (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};

export default reducer;
