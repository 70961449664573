import React from 'react';
import { ButtonBase, Grid, Paper, Typography } from '@material-ui/core';
import { DashboardMessage } from '../../../../components/DashboardContent';

export default function AccountType({
  title,
  description,
  icon,
  value,
  onSelect,
}) {
  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={5}
        sm={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ButtonBase onClick={() => onSelect(value)}>
          <Paper
            elevation={2}
            style={{
              width: '163px',
              height: '127px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              cursor: 'pointer',
              backgroundColor: '#F0F1F2',
            }}
          >
            {React.cloneElement(icon)}
            <Typography color="primary">{title}</Typography>
          </Paper>
        </ButtonBase>
      </Grid>
      <Grid
        items
        xs={7}
        sm={9}
        md={5}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DashboardMessage canClose={false}>{description}</DashboardMessage>
      </Grid>
    </Grid>
  );
}
