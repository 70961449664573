import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Typography } from '@material-ui/core';

const RadioButtonGroup = ({ name, value, options, onChange }) => {
  return options.map(option => (
    <span key={name + option}>
      <Radio
        id={option}
        name={name}
        checked={value === option.toUpperCase()}
        onClick={() => onChange(option.toUpperCase())}
      />
      <label htmlFor={option} style={{ fontSize: '.8rem' }}>
        {option}
      </label>
    </span>
  ));
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
