// const states = [
//   {	state_full:	'Alabama',	state_abbr:	'AL'	},
//   {	state_full:	'Alaska',	state_abbr:	'AK'	},
//   {	state_full:	'Arizona',	state_abbr:	'AZ'	},
//   {	state_full:	'Arkansas',	state_abbr:	'AR'	},
//   {	state_full:	'California',	state_abbr:	'CA'	},
//   {	state_full:	'Colorado',	state_abbr:	'CO'	},
//   {	state_full:	'Connecticut',	state_abbr:	'CT'	},
//   {	state_full:	'Delaware',	state_abbr:	'DE'	},
//   {	state_full:	'Florida',	state_abbr:	'FL'	},
//   {	state_full:	'Georgia',	state_abbr:	'GA'	},
//   {	state_full:	'Hawaii',	state_abbr:	'HI'	},
//   {	state_full:	'Idaho',	state_abbr:	'ID'	},
//   {	state_full:	'Illinois',	state_abbr:	'IL'	},
//   {	state_full:	'Indiana',	state_abbr:	'IN'	},
//   {	state_full:	'Iowa',	state_abbr:	'IA'	},
//   {	state_full:	'Kansas',	state_abbr:	'KS'	},
//   {	state_full:	'Kentucky',	state_abbr:	'KY'	},
//   {	state_full:	'Louisiana',	state_abbr:	'LA'	},
//   {	state_full:	'Maine',	state_abbr:	'ME'	},
//   {	state_full:	'Maryland',	state_abbr:	'MD'	},
//   {	state_full:	'Massachusetts',	state_abbr:	'MA'	},
//   {	state_full:	'Michigan',	state_abbr:	'MI'	},
//   {	state_full:	'Minnesota',	state_abbr:	'MN'	},
//   {	state_full:	'Mississippi',	state_abbr:	'MS'	},
//   {	state_full:	'Missouri',	state_abbr:	'MO'	},
//   {	state_full:	'Montana',	state_abbr:	'MT'	},
//   {	state_full:	'Nebraska',	state_abbr:	'NE'	},
//   {	state_full:	'Nevada',	state_abbr:	'NV'	},
//   {	state_full:	'New Hampshire',	state_abbr:	'NH'	},
//   {	state_full:	'New Jersey',	state_abbr:	'NJ'	},
//   {	state_full:	'New Mexico',	state_abbr:	'NM'	},
//   {	state_full:	'New York',	state_abbr:	'NY'	},
//   {	state_full:	'North Carolina',	state_abbr:	'NC'	},
//   {	state_full:	'North Dakota',	state_abbr:	'ND'	},
//   {	state_full:	'Ohio',	state_abbr:	'OH'	},
//   {	state_full:	'Oklahoma',	state_abbr:	'OK'	},
//   {	state_full:	'Oregon',	state_abbr:	'OR'	},
//   {	state_full:	'Pennsylvania',	state_abbr:	'PA'	},
//   {	state_full:	'Rhode Island',	state_abbr:	'RI'	},
//   {	state_full:	'South Carolina',	state_abbr:	'SC'	},
//   {	state_full:	'South Dakota',	state_abbr:	'SD'	},
//   {	state_full:	'Tennessee',	state_abbr:	'TN'	},
//   {	state_full:	'Texas',	state_abbr:	'TX'	},
//   {	state_full:	'Utah',	state_abbr:	'UT'	},
//   {	state_full:	'Vermont',	state_abbr:	'VT'	},
//   {	state_full:	'Virginia',	state_abbr:	'VA'	},
//   {	state_full:	'Washington',	state_abbr:	'WA'	},
//   {	state_full:	'West Virginia',	state_abbr:	'WV'	},
//   {	state_full:	'Wisconsin',	state_abbr:	'WI'	},
//   {	state_full:	'Wyoming',	state_abbr:	'WY'	},
// ];

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District of Columbia', abbreviation: 'DC' },
  { name: 'Federated States of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'U.S. Virgin Islands', abbreviation: 'VI' },
  { name: 'United States Minor Outlying Islands', abbreviation: 'UM' },
];

export const phoneTypes = [
  { longName: 'Cell', shortName: 'Cell' },
  { longName: 'Home', shortName: 'Home' },
  { longName: 'Work', shortName: 'Work' },
  { longName: 'Other', shortName: 'Other' },
];

export const bloodTypes = [
  'O Negative',
  'O Positive',
  'A Negative',
  'A Positive',
  'B Negative',
  'B Positive',
  'AB Negative',
  'AB Positive',
  'Unknown',
];

export const martialStatusTypes = [
  'Single',
  'Engaged',
  'Partnered',
  'Married',
  'Divorced',
  'Living Common Law',
  'Separated',
  'Widowed',
];

export const veteranStatusTypes = [
  'Active',
  'Honorable Discharge (HD)',
  'General Discharge (GD)',
  'Discharge Under Honorable Conditions (UHC)',
  'Discharge Under Other Than Honorable Conditions (UOTHC)',
  'Undesirable Discharge (UD)',
  'Bad Conduct Discharge (BCD)',
  'Dishonorable Discharge (DD)',
  'Other',
];

export const religionsTypes = [
  'Declined',
  'Agnostic',
  'Amish',
  'Anglican',
  'Atheist',
  "Baha'i",
  'Baptist',
  'Buddhism',
  'Catholic',
  'Christianity',
  'Church of Christ',
  'Confucianism',
  'Gnosticism',
  'Hinduism',
  'Islam',
  'Judaism',
  'Lutheran',
  'Mennonite',
  'Methodist',
  'Mormon',
  'Muslim',
  'Pentecostal',
  'Presbyterian',
  'Scientology',
  'Seventh Day Adventist',
  'Sufism',
  'Taoism',
  'Other',
];

export const raceEthnicTypes = [
  'Asian',
  'American Indian or Alaska Native',
  'Black or African American',
  'Hispanic or Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White',
];

export const genderTypes = ['Female', 'Male'];

export const langaugeTypes = [
  'Arabic',
  'Chinese',
  'English',
  'French',
  'German',
  'Hindi',
  'Portuguese',
  'Russian',
  'Romanian',
  'Other',
];

export const relationshipTypes = [
  { value: 'Husband', label: 'Husband' },
  { value: 'Wife', label: 'Wife' },
  { value: 'Mother', label: 'Mother' },
  { value: 'Father', label: 'Father' },
  { value: 'Sister', label: 'Sister' },
  { value: 'Brother', label: 'Brother' },
  { value: 'Son', label: 'Son' },
  { value: 'Daughter', label: 'Daughter' },
  { value: 'Grandmother', label: 'Grandmother' },
  { value: 'Grandfather', label: 'Grandfather' },
  { value: 'Grandson', label: 'Grandson' },
  { value: 'Granddaughter', label: 'Granddaughter' },
  { value: 'Friend', label: 'Friend' },
  { value: 'Roommate', label: 'Roommate' },
  { value: 'Co-worker', label: 'Co-worker' },
  { value: 'Other', label: 'Other' },
];
