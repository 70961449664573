import { QR, DOWNLOAD, LINK } from './WaysToShareInfo/WaysToShareInfo';

export const modalData = {
  [QR]: {
    type: QR,
    header: 'QR Code',
    subtitle: `This creates a page with a QR code that can be scanned by a QR reader (most cell phones can do this). When the QR reader scans the code, it will open a secure web page containing your COVID Health Status Report.
      You can display the QR code on your device, or you can print or download this QR code page.`,
  },
  [DOWNLOAD]: {
    type: DOWNLOAD,
    header: 'Download/ Print',
    subtitle:
      'This opens the web page containing your COVID Health Status Report. You can print or download the report from your web browser.',
  },
  [LINK]: {
    type: QR,
    header: 'Shareable Link',
    subtitle:
      'This creates a URL link to the web page containing your COVID Health Status Report. You can text or email the link to a trusted receipient. The link will be good for 1 week.',
  },
};
