import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  withStyles,
  makeStyles,
  ButtonGroup,
  Typography,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginBottom: 15,
  },
  label: {
    fontSize: '1.7rem',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key

      justifyContent: 'center',
    },
  },
  navigationButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      justifyContent: 'center',
    },
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

function CustomToolbar(props) {
  const classes = useStyles();
  const { isFetching } = props;
  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid className={classes.navigationButton} item xs={12} md={4}>
        <ButtonGroup>
          <Button
            disabled={isFetching}
            onClick={() => props.onNavigate('TODAY')}
          >
            <span>Today</span>
          </Button>
          <Button
            disabled={isFetching}
            onClick={() => props.onNavigate('PREV')}
          >
            <span>Back</span>
          </Button>
          <Button
            disabled={isFetching}
            onClick={() => props.onNavigate('NEXT')}
          >
            <span>Next</span>
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} md={4} className={classes.labelContainer}>
        {isFetching ? (
          <CircularProgress
            size={24}
            variant="indeterminate"
            disableShrink={true}
          />
        ) : (
          <Typography className={classes.label} variant="h3">
            {props.label}
          </Typography>
        )}
      </Grid>
      <Grid className={classes.filterContainer} item xs={12} md={4}>
        <ButtonGroup>
          <Button disabled={isFetching} onClick={() => props.onView('day')}>
            <span>Day</span>
          </Button>
          <Button disabled={isFetching} onClick={() => props.onView('week')}>
            <span>Week</span>
          </Button>
          <Button disabled={isFetching} onClick={() => props.onView('month')}>
            <span>Month</span>
          </Button>
          {/* <Button disabled={isFetching} onClick={() => props.onView('year')}>
            <span>Year</span>
          </Button> */}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

export default CustomToolbar;
