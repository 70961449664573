import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import styles from '../../../containers/MediaObject/File.css';

const DeleteComponent = ({ toggleDelete }) => {
  return (
    <div>
      <Fade in={!toggleDelete}>
        <Typography
          className={styles.addInfoBtn}
          variant="caption"
          style={{
            color: 'black',
            pointer: 'auto',
            paddingTop: '8px',
          }}
        >
          Attachment will be deleted when record is saved.
        </Typography>
      </Fade>
    </div>
  );
};

export default DeleteComponent;
