import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { getCategoryFromAreaType } from '../EditMode/categoryFromAreaType';
import { withRouter } from 'react-router';
import DocumentFormFields from './DocumentFormFields';
import { format } from 'date-fns-tz';

const DocumentForm = props => {
  const { fileName, onSubmit } = props;
  return (
    <form onSubmit={onSubmit}>
      <DocumentFormFields fileName={fileName} />
    </form>
  );
};

let DocumentFormRedux = reduxForm({
  form: 'documentForm',
  touchOnChange: true,
  initialValues: {},
  destroyOnUnmount: true,
})(withRouter(DocumentForm));

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    category:
      Boolean(ownProps.areaType) && getCategoryFromAreaType(ownProps.areaType)
        ? getCategoryFromAreaType(ownProps.areaType)
        : null,
    newEnteredOnDate: format(new Date(), 'yyyy-MM-dd'),
    newEnteredOnTime: format(new Date(), 'HH:mm'),
  },
});

export default connect(mapStateToProps)(DocumentFormRedux);
