import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// eslint-disable-next-line
import styles from './FileInput.css';

const style = theme => ({
  formGroup: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    border: 'solid 1px #aaa',
  },
  fileButton: {
    fontSize: '14px',
    width: '280px',
    border: 'solid 1px #aaa',
    borderRight: 'solid 1px transparent',
    padding: theme.spacing(1),
  },
  hiddenInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
});

const FileInput = props => {
  const { classes, selectFileText, changeFileText, acceptedFileTypes } = props;
  const accept = acceptedFileTypes ? acceptedFileTypes : 'image/*,.pdf,.doc';
  const [file, setFile] = useState({
    name: props.fileName,
    hasFile: props.fileName !== '',
  });

  const onChange = event => {
    const { files, value } = event.target;
    const name = value.split('\\').pop();

    setFile({
      name,
      hasFile: files && files.length > 0,
    });

    props.onChange(event, name);
  };

  const buttonText = file.hasFile ? changeFileText : selectFileText;

  return (
    <React.Fragment>
      <input
        id="modal-file-input"
        className={classes.hiddenInput}
        type="file"
        onChange={onChange}
        accept={accept}
      />
      <div className={classes.formGroup}>
        <button className={classes.fileButton}>
          <label htmlFor="modal-file-input">{buttonText}</label>
        </button>
        <input readOnly className={classes.input} value={file.name} />
      </div>
    </React.Fragment>
  );
};

FileInput.propTypes = {
  fileName: PropTypes.string.isRequired,
  changeFileText: PropTypes.string.isRequired,
  selectFileText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FileInput.defaultProps = {
  fileName: '',
  changeFileText: 'Change Card Image',
  selectFileText: 'Select Card Image',
};

export default withStyles(style)(FileInput);
