import { mask } from '../../../library/helpers/format';
import { differenceInCalendarYears } from 'date-fns';
export const phoneValidation = value =>
  value && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
    ? 'Invalid Phone Number'
    : undefined;

export const emailValidation = value =>
  value && !value.includes('@') ? 'Invalid Email Address' : undefined;

export const providerZipValidation = value =>
  value && !/^[0-9]{5}(-[0-9]{4})?$/.test(value)
    ? 'Invalid Zipcode'
    : undefined;

export const maxLength = max => value =>
  value && value.length > max ? `Invalid Entry` : undefined;

export const isPositive = value =>
  parseInt(value) < 0 ? `Please enter a positive number` : undefined;

export const required = value => (value ? undefined : 'Required');

const formatMask = 'XXX-XXX-XXXX';
export const phoneMask = value => mask(value, formatMask);

export const postalCodeMask = value => mask(value, 'XXXXX-XXXX');

export const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

export const isOver18 = value =>
  differenceInCalendarYears(new Date(), new Date(value)) <= 18
    ? 'Must be at least 18 years of age'
    : undefined;
