import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Grid } from '@material-ui/core';

const Header = ({ index, classes }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const bodyVariant = isSmallScreen ? 'body2' : 'body1';
  return index < 2 ? (
    <Grid container spacing={2} className={classes.panel}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ marginBottom: '8px' }}>
          Verify Your Identity
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={bodyVariant}>
          Privacy and security are at the forefront of everything we do, and
          identity verification is one of the ways we protect our Members...
          taking an extra step to ensure there are no robots or fake accounts
          helps keep our platform safe from bad actors.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={bodyVariant}>
          There are a few ways you can verify your identity. If you have
          previously verified your identity, simply enter your ID Code.
          Otherwise, you can use a traceable financial transaction to verify
          your identity. The minimum amount allowed by the financial processors
          is $1.00. Alternatively, you can verify your identity offline;
          however, that process may take longer and will require sharing some
          personally identifiable information.
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} className={classes.panel}>
      <Grid item xs={12}>
        <Typography variant="h4">Verify Your Identity Offline</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={bodyVariant}>
          To verify your identity offline, you will need to present a US
          government-issued ID (such as a driver’s license, passport, or social
          security card) that matches the name on the account you are creating.
          You can do this by:
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">1. Email</Typography>
        <Typography variant={bodyVariant}>
          Send an electronic copy of the identification to
          Support@AgrinHealth.com. If you are using an email address other than
          the one associated with your Agrin Health account, be sure to include
          your account email.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">1. Phone</Typography>
        <Typography variant={bodyVariant}>
          Call Agrin Health at +1-615-721-2590 to speak with a Customer Service
          Representative. Please be ready with ID when you call.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant={bodyVariant}>
          You will have 30 days to verify your identity after which you will
          need to re-register. For security reasons, your account will be held
          in an identity-pending status until your identity is verified and will
          not be available for active use.
        </Typography>
        <Typography variant={bodyVariant} style={{ marginTop: 8 }}>
          For immediate verification, we recommend completing the verification
          online using the traceable financial transaction.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
