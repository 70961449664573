const PROVIDER_LABELS = [
  {
    longName: 'General Practitioner',
    shortName: 'General Practitioner',
  },
  {
    longName: 'Primary Care Provider',
    shortName: 'Primary Care Provider',
  },
  {
    longName: 'Pediatrician',
    shortName: 'Pediatrician',
  },
  {
    longName: 'OB/GYN',
    shortName: 'OB/GYN',
  },
  {
    longName: 'Internist',
    shortName: 'Internist',
  },
  {
    longName: 'Cardiologist',
    shortName: 'Cardiologist',
  },
  {
    longName: 'Endocrinologist',
    shortName: 'Endocrinologist',
  },
  {
    longName: 'Nurse',
    shortName: 'Nurse',
  },
  {
    longName: 'Nurse Practitioner',
    shortName: 'Nurse Practitioner',
  },
  {
    longName: 'Home Health Nurse',
    shortName: 'Home Health Nurse',
  },
  {
    longName: 'Dentist',
    shortName: 'Dentist',
  },
  {
    longName: 'Orthodontist',
    shortName: 'Orthodontist',
  },
  {
    longName: 'Ophthalmologist',
    shortName: 'Ophthalmologist',
  },
  {
    longName: 'Allergist',
    shortName: 'Allergist',
  },
  {
    longName: 'Orthopedic Surgeon',
    shortName: 'Orthopedic Surgeon',
  },
  {
    longName: 'Psychologist',
    shortName: 'Psychologist',
  },
  {
    longName: 'Psychiatrist',
    shortName: 'Psychiatrist',
  },
  {
    longName: 'Social Worker',
    shortName: 'Social Worker',
  },
  {
    longName: 'Clinical Psychiatric Worker',
    shortName: 'Clinical Psychiatric Worker',
  },
  {
    longName: 'Preferred Hospital',
    shortName: 'Preferred Hospital',
  },
  {
    longName: 'Hospital',
    shortName: 'Hospital',
  },
  {
    longName: 'Urgent Care Center',
    shortName: 'Urgent Care Center',
  },
  {
    longName: 'Preferred Emergency Room',
    shortName: 'Preferred Emergency Room',
  },
  {
    longName: 'Emergency Room',
    shortName: 'Emergency Room',
  },
  {
    longName: 'Birthing Center',
    shortName: 'Birthing Center',
  },
  {
    longName: 'Outpatient Clinic',
    shortName: 'Outpatient Clinic',
  },
  {
    longName: 'Short-term Nursing Facility (SNF)',
    shortName: 'Short-term Nursing Facility (SNF)',
  },
  {
    longName: 'Long-term Care Facility',
    shortName: 'Long-term Care Facility',
  },
  {
    longName: 'Hospice',
    shortName: 'Hospice',
  },
  {
    longName: 'Lab',
    shortName: 'Lab',
  },
  {
    longName: 'Pharmacy',
    shortName: 'Pharmacy',
  },
  {
    longName: 'Medical Transportation',
    shortName: 'Medical Transportation',
  },
];

export default PROVIDER_LABELS.sort((a, b) => {
  if (a.shortName < b.shortName) {
    return -1;
  }
  if (a.shortName > b.shortName) {
    return 1;
  }
  return 0;
});
