import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import { selectDependent } from '../../../store/reducers/dependents/action';
import { setActiveUser } from '../../../store/reducers/active/actions';
import { fetchPersonInfo } from '../../../store/reducers/person/actions';
import FlashMessage from '../../containers/FlashMessage';
import './DependentsPage.css';

import DependentList from '../../components/Dependents/DependentList';

const DependentsHeader = () => (
  <PageHeader
    headerText="Family Accounts"
    backButtonText="Go Back"
    xs={{ left: 6, right: 6 }}
    rightSlot={
      <div
        style={{ height: '100%', justifyContent: 'flex-end', display: 'flex' }}
      >
        {/*
        <div
          style={{
            position: 'relative',
            marginRight: '100px',
            marginTop: '-22px',
            zIndex: '1000',
          }}
            >
                    
          <EasterEgg
            id="1"
            message="You found another egg! CODE: EGGSARENICE"
                />
                
        </div>
            */}
        <div>
          <Link
            style={{ textDecoration: 'none', color: 'white' }}
            to="/d/dependents/signup"
          >
            <Button
              variant="contained"
              style={{ marginBottom: 10 }}
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Account
            </Button>
          </Link>
        </div>
      </div>
    }
  />
);

const DependentsPage = props => {
  const editDependent = (slug, id) => {
    const nextUser = props.dependents.tabs.find(
      //find slug
      x => x.slug === slug
    );
    if (nextUser) {
      props.setActiveUser(nextUser); //set as active user
      props.fetchPersonInfo();
      props.history.push(`/d/${slug}/account/info`);
    }
  };

  return (
    <BasePage HeaderComponent={<DependentsHeader />}>
      {props.hasMessage && (
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={8}
          xl={8}
          style={{ marginTop: '-16px' }}
        >
          <FlashMessage />
        </Grid>
      )}

      <Grid item xs={12}>
        {
          <DependentList
            dependents={props.dependents.records}
            personId={props.personId}
            onClickVisible={props.selectDependent}
            editDependent={editDependent}
          />
        }
      </Grid>
    </BasePage>
  );
};
const mapStateToProps = state => ({
  personId: state.app.user.personId,
  dependents: state.dependents,
  hasMessage: state.flashMessage.hasMessage,
});

const mapDispatchToProps = dispatch => ({
  selectDependent: (personId, secondaryPersonId) =>
    dispatch(selectDependent(personId, secondaryPersonId)),
  setActiveUser: user => dispatch(setActiveUser(user)),
  fetchPersonInfo: () => dispatch(fetchPersonInfo()),
});

export default {
  component: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DependentsPage)
  ),
};
