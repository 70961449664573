import React from 'react';
import CovidStatusInfo from './CovidStatusInfo';
import WaysToShareInfo from './WaysToShareInfo';
import { Grid, Divider } from '@material-ui/core';
import { modalData } from './modalData';
import SubscriptionModal from '../../../../components/AccountManagement/SubscriptionOptions/SubscriptionModal';

const InfoContainer = props => {
  const [open, setOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState({});
  const onClose = () => {
    setModalMessage({});
    setOpen(false);
  };
  const onSelectLearnMore = type => {
    setModalMessage(modalData[type]);
    setOpen(true);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CovidStatusInfo />
      </Grid>
      <Grid item xs={12}>
        <WaysToShareInfo {...props} onSelectLearnMore={onSelectLearnMore} />
      </Grid>
      <Grid>
        <SubscriptionModal
          {...modalMessage}
          onClick={() => {}}
          open={open}
          onClose={onClose}
          isSubmitting={false}
        />
      </Grid>
    </Grid>
  );
};

export default InfoContainer;
