import React, { useEffect, useState } from 'react';
import { withStyles, Icon } from '@material-ui/core';

const AttachmentsList = ({
  classes,
  addAttachment,
  viewAttachment,
  appArea,
  ...props
}) => {
  const [mediaObjects, setMediaObjects] = useState([]);

  useEffect(() => {
    if (props.mediaObjects.length !== 0) {
      const filteredObjects = props.mediaObjects.filter(
        x => x.appArea === appArea
      );

      setMediaObjects(filteredObjects.length !== 0 ? filteredObjects : []);
    }
  }, [props.mediaObjects]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>Attachments</div>
      <div className={classes.container}>
        {mediaObjects.map(media => (
          <div
            className={classes.thumbnail}
            style={{ backgroundImage: `url(${media.file.preview})` }}
            onClick={addAttachment}
          >
            <div className={classes.indicator}>
              {media.description ? (
                <Icon className={classes.complete}>info</Icon>
              ) : (
                <Icon className={classes.incomplete}>info</Icon>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = theme => ({
  root: {
    marginTop: 32,
  },
  container: {
    overflowX: 'auto',
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 12,
  },
  header: {
    fontSize: '15px',
    fontWeight: '500',
    opacity: 0.8,
    marginBottom: 2,
  },
  mediaAddText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: '600',
    marginTop: 4,
  },
  mediaAdd: {
    minWidth: 84,
    minHeight: 84,
    borderRadius: 4,
    backgroundColor: 'rgba(0,104,168,.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    opacity: 1,
    transition: 'all .2s',
    '&:hover': {
      opacity: 0.6,
    },
  },
  complete: {
    fontSize: 20,
    color: '#90B850',
    padding: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14,
  },
  incomplete: {
    fontSize: 20,
    color: '#F39F62',
    padding: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14,
  },
  indicator: {
    position: 'absolute',
    top: -8,
    right: -8,
    minWidth: 28,
    minHeight: 28,
    borderRadius: '14px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumbnail: {
    minWidth: 84,
    minHeight: 84,
    position: 'relative',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
    backgroundSize: 'cover',
    cursor: 'pointer',
    opacity: 1,
    transition: 'all .2s',
    '&:hover': {
      opacity: 0.6,
    },
  },
});

export default withStyles(styles)(AttachmentsList);
