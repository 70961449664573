import React, { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleMachine } from './toggleMachine';
import {
  selectResource,
  resourceLibraryFavorite,
  resourceLibraryRedeemOffer,
} from '../../../store/reducers/resourceLibrary/actions';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import Header from './Header';
import ResourceBody from './ResourceBody/ResourceBody';
import ResourcePanels from './ResourcePanels';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ResourceDrawer = ({
  selectedId,
  personResourceId,
  resource,
  onCloseDrawer,
  isSubmitting,
  resourceLibraryFavorite,
  resourceLibraryRedeemOffer,
}) => {
  const [state, send] = useMachine(toggleMachine);
  const matches550 = useMediaQuery('(max-width: 550px)'); //mobile

  useEffect(() => {
    send({ type: 'TOGGLE', data: resource });
  }, [selectedId]);

  useEffect(() => {
    if (state.value === 'inactive' && resource) onCloseDrawer();
  }, [state]);

  const handleFavoriteClick = () => {
    if (personResourceId) {
      resourceLibraryFavorite(
        { resourceId: resource.id, personResourceId: personResourceId },
        'DELETE'
      );
      return;
    } else {
      resourceLibraryFavorite(resource.id, 'POST');
    }
  };

  const handleOnClickLink = async () => {
    let link = '';
    if (resource.synapseType === 'Document') {
      link = resource.files[0].links.download;
    } else {
      const request = await apiFetchAuthorized(
        `/api/synapse/library/resource/link/${resource.id}`
      );
      const response = await request.json();
      link = response.data.siteUrl;
    }
    const tempLink = document.createElement('a');
    tempLink.href = link;
    tempLink.setAttribute('target', '_bank');
    tempLink.setAttribute('rel', 'norefferer');
    // Required for Firefox, element must be apart of the document tree
    document.body.appendChild(tempLink);
    // Simulate click on newly created element.
    tempLink.click();
    // Link is no longer needed remove from tree.
    document.body.removeChild(tempLink);
  };

  const handleRedeemOffer = id => {
    console.log(id);
    resourceLibraryRedeemOffer(id);
  };

  return (
    <Drawer
      anchor="right"
      open={state.value === 'active'}
      onClose={() => send('TOGGLE')}
    >
      <div
        style={
          matches550 ? { maxWidth: '550px', width: '100%' } : { width: '550px' }
        }
      >
        {state.value === 'active' && (
          <div>
            <Header title={resource.title} onClose={() => send('TOGGLE')} />
            <ResourceBody
              resource={resource}
              handleOnClickLink={handleOnClickLink}
              handleFavoriteClick={handleFavoriteClick}
              isSubmitting={isSubmitting}
            />
            <ResourcePanels
              resource={resource}
              handleRedeemOffer={handleRedeemOffer}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state, props) => ({
  selectedId: state.resourceLibrary.selectedId,
  personResourceId: state.resourceLibrary.personResourceId
    ? state.resourceLibrary.favorites[state.resourceLibrary.selectedId]
    : null,
  resource: state.resourceLibrary.selectedId
    ? state.resourceLibrary.resourceList[state.resourceLibrary.selectedId]
    : {},
  isSubmitting: state.resourceLibrary.selectedId
    ? state.resourceLibrary.submittingResource.includes(
        state.resourceLibrary.selectedId
      ) ||
      state.resourceLibrary.deletingResource.includes(
        state.resourceLibrary.selectedId
      )
    : false,
});

const mapDispatchToProps = dispatch => ({
  onCloseDrawer: () => dispatch(selectResource(null)),
  resourceLibraryFavorite: (id, method) =>
    dispatch(resourceLibraryFavorite(id, method)),
  resourceLibraryRedeemOffer: id => dispatch(resourceLibraryRedeemOffer(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDrawer);
