// import {CREATE_HELP_MESSAGE} from './actions';

import {KNOWN_ISSUES_REQUEST, KNOWN_ISSUES_SUCCESS, KNOWN_ISSUES_FAILURE} from './actions';


const initialState = {
    isPutting: false,
    message: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        // case CREATE_HELP_MESSAGE:
        //     return {
        //         ...state,
        //         message: {}
        //     }
        
        default:
            return state;
        
    }
}

export default reducer;