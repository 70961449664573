import React from 'react';
import FilterTextField from '../../../library/forms/FilterTextField';

const SearchComponent = ({ searchTerm, handleSearch }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <FilterTextField
        value={searchTerm}
        handleChange={handleSearch}
        placeholder="Search Family History"
        handleClear={() => handleSearch('')}
        label="Search"
      />
    </div>
  );
};

export default SearchComponent;
