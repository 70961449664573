import React, { useEffect, useRef, useState } from 'react';
import { useImageEditor } from '../ImageEditorModal';

export default function CardSelector({ render, onSelection, card }) {
  const fileInputRef = useRef();

  const [imageSrc, setImageSrc] = useState(null);

  const { openModal, closeModel, onProcess } = useImageEditor();

  useEffect(() => {
    if (!card) {
      setImageSrc(null);
    }
    return () => {
      // Cleanup resources mitigating memory leaks
      if (imageSrc) URL.revokeObjectURL(imageSrc);
    };
  }, [card]);

  // The onChange event is only triggered if something changes. If the same file
  // is selected again, a cached copy will be used which means the onChange event
  // will not trigger again. This is default browser behavior.
  const handleClick = event => (event.target.value = null);

  const handleOpenModal = () => {
    fileInputRef.current.click();
  };

  const handleImageSelection = event => {
    if (event.target.files.length <= 0) return;

    openModal({
      type: 'CARD_SELECTOR',
      source: event.target.files[0],
      onProcess: imageState => {
        setImageSrc(URL.createObjectURL(imageState.dest));
        if (onSelection) onSelection(imageState);

        closeModel();
      },
    });
  };

  return (
    <div onClick={handleOpenModal}>
      {render({ hasPreviewImage: imageSrc !== null, imageSrc })}
      <input
        ref={fileInputRef}
        style={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        }}
        type="file"
        accept="image/*"
        onClick={handleClick}
        onChange={handleImageSelection}
      />
    </div>
  );
}
