import React from 'react';
import LineChart from '../../LineChart';
import styles from '../styles.css';
import { DATERANGE } from '../../ChartWrapper';
import { scaleTime } from 'd3-scale';
import { format } from 'date-fns';
import Tooltip from '../Tooltip';

const formatByDay = e => `${e.getMonth() + 1}/${e.getDate()}`;
const formatByMonthName = e => format(e, 'MMMM');
const formatByMonthShort = e => format(e, 'MMM');

const SleepLineChart = props => {
  const {
    data,
    dateExtent,
    lowerLimit,
    upperLimit,
    tickValues,
    dateRange,
    hasData,
    mode,
    shouldDisplayAverage,
  } = props;

  const frameProps = {
    lines: [{ coordinates: data }],
    xAccessor: 'date',
    yAccessor: shouldDisplayAverage
      ? 'averageTotalTimeSlept'
      : 'totalTimeSlept',
    xExtent: dateExtent,
    yExtent: [lowerLimit, upperLimit > 24 ? 24 : upperLimit],
    xScaleType: scaleTime(),
    axes: [
      { orient: 'left', className: styles.axes, baseline: false, label: 'hrs' },
      {
        orient: 'bottom',
        tickFormat: function(e) {
          if (
            dateRange === DATERANGE.ONE_WEEK ||
            dateRange === DATERANGE.ONE_MONTH
          ) {
            return formatByDay(e);
          }
          if (
            dateRange === DATERANGE.THREE_MONTHS ||
            dateRange === DATERANGE.SIX_MONTHS
          ) {
            return formatByMonthName(e);
          }
          return formatByMonthShort(e);
        },
        className: styles.axes,
        tickValues,
        baseline: false,
      },
    ],
    hoverAnnotation: true,
    tooltipContent: d => {
      return (
        <Tooltip
          d={d}
          color="#ffffff"
          title="Sleep Amount"
          keys={[
            {
              tag: shouldDisplayAverage ? 'Weekly Average' : 'Total',
              unit: 'hrs',
              value: shouldDisplayAverage
                ? d.data.averageTotalTimeSlept
                : d.data.totalTimeSlept,
            },
          ]}
        />
      );
    },
  };
  return <LineChart mode={mode} hasData={hasData} frameProps={frameProps} />;
};

export default SleepLineChart;
