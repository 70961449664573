import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Drawer,
  Hidden,
  Button,
  Paper,
  Collapse,
} from '@material-ui/core';
import CheckoutForm from '../../../containers/RegistrationForms/CheckoutForm/CheckoutForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  typography: {
    width: '100%',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    background: '#006AB0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    color: '#fff',
  },
  checkoutMdDown: {
    marginTop: '25px',
    width: '100%',
    maxWidth: '1000px',
  },
  cancelButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
  },
});

const CheckoutComponent = ({
  productData,
  fetchAvailableProducts,
  checkout,
  onCheckout,
  onClose,
  products,
  classes,
}) => {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const divRef = useRef(null);

  useEffect(() => {
    if (isCheckoutOpen) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mdUp, isCheckoutOpen]);

  useEffect(() => {
    if (!!productData) {
      switch (productData.name) {
        case 'Shareable':
          setIsCheckoutOpen(true);
          break;
        default:
          setIsCheckoutOpen(false);
          break;
      }
      return;
    } else {
      setIsCheckoutOpen(false);
    }
  }, [productData]);

  const renderCheckout = () => (
    <React.Fragment>
      <div className={classes.typography}>
        <Typography variant="h5">Complete Your Upgrade Purchase</Typography>
      </div>
      <div ref={divRef}>
        <CheckoutForm
          products={products}
          fetchProducts={fetchAvailableProducts}
          failureMessage={checkout.failureMessage}
          isSubmitting={checkout.isSubmitting}
          productData={productData}
          displayPlanInformation={false}
          onSubmit={async values => {
            await onCheckout({
              ...values,
            });
          }}
        />
      </div>
    </React.Fragment>
  );

  return (
    <div>
      {Boolean(isCheckoutOpen) && (
        <Collapse in={!mdUp}>
          <Grid item xs={12}>
            <Paper className={classes.checkoutMdDown}>{renderCheckout()}</Paper>
          </Grid>
        </Collapse>
      )}
      <Drawer
        anchor="right"
        open={isCheckoutOpen && mdUp}
        onClose={() => onClose()}
      >
        <div style={{ width: 550 }}>
          {renderCheckout()}
          <div className={classes.cancelButton}>
            <Button variant="contained" onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(styles)(CheckoutComponent);
