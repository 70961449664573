import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import {
  nonMetaKeys,
  MEDIA_OBJECT_UPLOAD_REQUEST,
  onProgress,
  uploadFiles,
  doneUpload,
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  MEDIA_OBJECT_DOCUMENT_UPLOAD_COMPLETE,
  MEDIA_OBJECT_UPLOADING_FILE,
} from '../mediaObject/actions';
import { ajax } from '../../../library/helpers/fetch';
import { omit } from 'lodash';

const EMPTYOBJECTID = '00000000-0000-0000-0000-000000000000';
const DOCUMENTS = 'DOCUMENTS';

//get
export const DOCUMENTS_REQUEST = 'documents/REQUEST';
export const DOCUMENTS_SUCCESS = 'documents/SUCCESS';
export const DOCUMENTS_FAILURE = 'documents/FAILURE';

//post
export const DOCUMENTS_CREATE_REQUEST = 'documents/create/REQUEST';
export const DOCUMENTS_CREATE_SUCCESS = 'documents/create/SUCCESS';
export const DOCUMENTS_CREATE_FAILURE = 'documents/create/FAILURE';

//put
export const DOCUMENTS_UPDATE_REQUEST = 'documents/update/REQUEST';
export const DOCUMENTS_UPDATE_SUCCESS = 'documents/update/SUCCESS';
export const DOCUMENTS_UPDATE_FAILURE = 'documents/update/FAILURE';

//delete
export const DOCUMENTS_DELETE_REQUEST = 'documents/DELETE/REQUEST';
export const DOCUMENTS_DELETE_SUCCESS = 'documents/DELETE/SUCCESS';
export const DOCUMENTS_DELETE_FAILURE = 'documents/DELETE/FAILURE';

export const DOCUMENTS_DRAWER_TOGGLE = 'media_manager/TOGGLE';
export const DOCUMENTS_CLEAR_MESSAGE = 'documents/CLEAR_MESSSAGE';
export const DOCUMENTS_CLEAR_UPDATES = 'documents/_CLEAR_UPDATES';

export const clearUpdates = () => ({
  type: DOCUMENTS_CLEAR_UPDATES,
});

export const clearMessage = () => ({
  type: DOCUMENTS_CLEAR_MESSAGE,
});

export const toggleDocumentsDrawer = bool => {
  return {
    type: DOCUMENTS_DRAWER_TOGGLE,
    payload: bool,
  };
};

export const fetchDocuments = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS, DOCUMENTS_FAILURE],
    endpoint: `/api/media/documents`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json', Pid: pid },
      },
    },
  },
});

export const documentCreate = (id, document, bundle) => {
  return async dispatch => {
    dispatch({
      type: DOCUMENTS_CREATE_REQUEST,
      payload: { id, document, bundle },
    });
    try {
      const json = await dispatch(uploadDocument(id, document, bundle));
      return await dispatch({
        type: DOCUMENTS_CREATE_SUCCESS,
        payload: json,
        data: document,
      });
    } catch (error) {
      return dispatch({ type: DOCUMENTS_CREATE_FAILURE, message: error });
    }
  };
};

export const documentUpdate = (pid, documentId, document, objectId) => {
  const payload = {
    ...document,
    personId: pid,
    mediaObjectId: documentId,
    displayName: document.displayName || '',
    category: document.category,
    description: document.description || '',
    enteredOn: document.enteredOn,
    objectId,
  };
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        DOCUMENTS_UPDATE_REQUEST,
        DOCUMENTS_UPDATE_SUCCESS,
        DOCUMENTS_UPDATE_FAILURE,
      ],
      endpoint: `/api/media/details`,
      meta: {
        config: {
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      },
      payload,
    },
  };
};

export const documentDelete = (pid, documentid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      DOCUMENTS_DELETE_REQUEST,
      DOCUMENTS_DELETE_SUCCESS,
      DOCUMENTS_DELETE_FAILURE,
    ],
    endpoint: `/api/media/${documentid}`,
    meta: {
      config: {
        headers: { Pid: pid },
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});

export const uploadDocument = (id, document, bundle) => {
  return async dispatch => {
    dispatch({ type: MEDIA_OBJECT_UPLOAD_REQUEST, payload: [bundle] });
    const url = `/api/media/documents`;

    const formData = new FormData();
    formData.append('personId', id);
    formData.append('file', bundle.file);
    formData.append('displayName', document.displayName);
    formData.append('category', document.category || '');
    formData.append('enteredOn', document.enteredOn || '');
    formData.append('description', document.description || '');

    // Remove all non-metadata values. See meta variable for insight on what those are.
    const meta = Object.keys(omit(bundle, nonMetaKeys));

    // Using only the meta values, map to format data payload.
    for (let i = 0; i < meta.length; i++) {
      formData.append(meta[i], bundle[meta[i]]);
    }
    dispatch({ type: MEDIA_OBJECT_UPLOADING_FILE, payload: 0 });
    const result = await ajax(url, {
      method: 'POST',
      body: formData,
      contentType: 'application/json',
      Pid: id,
      onProgress: onProgress(bundle.file.name, dispatch),
    });

    const payload = { index: 0, DOCUMENTS, data: result };

    dispatch(doneUpload({ index: 0, DOCUMENTS }));

    dispatch({
      type: RESOURCE_MEDIA_MODIFIED,
      payload: result,
      meta: {
        resource: DOCUMENTS,
        operation: RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
      },
    });
    return result.data;
  };
};
