import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import FlashMessage from '../../containers/FlashMessage';
import styles from '../../components/DashboardContent/AlertsAndInsights.css';
import KnownIssues from './KnownIssues';
import BasePage from '../BasePage';
import { Grid, Button } from '@material-ui/core';

import EasterEgg from '../../../library/components/EasterEggComponents';

const blueHeader = { color: '#0269b0' };

class HelpPage extends Component {
  render() {
    return (
      <BasePage>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <FlashMessage />

              <h3 style={blueHeader}>Known Issues</h3>
              <KnownIssues />

              <h3 style={blueHeader}>Resources</h3>

              <div className={styles.alertsAndInsights}>
                <h3>Frequently Asked Questions</h3>
                <div>
                  <p style={{ borderBottom: 'solid #DDD 1px' }}>
                    Over time this section will contain frequently asked
                    questions and answers.
                    <br />
                    <br />
                  </p>

                  <h3>Training Videos</h3>

                  <h5>Agrin Health Introduction</h5>
                  <ul>
                    <li></li>
                    <li>
                      {/* <iframe width="100%" height="400px" allowFullScreen="allowfullscreen" src="https://www.youtube.com/embed/YCaGYUIfdy4" frameBorder="0" allow="autoplay; encrypted-media"></iframe> */}
                    </li>
                  </ul>
                  {/* <iframe src="https://player.vimeo.com/video/278689310" width="100%" height="360" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"></iframe> */}
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-summary.mp4"
                      type="video/mp4"
                    />
                  </video>
                  {/* <iframe src="https://hebcore.blob.core.windows.net/videos/heb-getting-started.mp4?st=2018-10-05T16%3A14%3A00Z&se=2020-12-01T16%3A14%3A00Z&sp=r&sv=2018-03-28&sr=b&sig=hsFukOXLcGmwzrbb1weWzusMvd3mfWw8icZrqASe7Ww%3D" 
                                        width="100%" height="360" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen="true"></iframe> */}

                  <h5>Emergency Contacts</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-emergency-contacts.mp4"
                      type="video/mp4"
                    />
                  </video>

                  <h5>Insurance</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-insurance.mp4"
                      type="video/mp4"
                    />
                  </video>

                  <h5>Allergies</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-allergies.mp4"
                      type="video/mp4"
                    />
                  </video>

                  <h5>Immunizations</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-immunizations.mp4"
                      type="video/mp4"
                    />
                  </video>

                  <h5>Medications, Vitamins, and Supplements (MVS)</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-mvs.mp4"
                      type="video/mp4"
                    />
                  </video>

                  <h5>Medical Family History</h5>
                  <video width="100%" height="360" controls>
                    <source
                      src="https://hebcore.blob.core.windows.net/videos/agrin-heb-mfh.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              {/*
              <div style={{ marginLeft: '30px', marginBottom: '60px' }}>
                <EasterEgg
                  message="You found another egg! CODE: EGGSAREFUN"
                  id="1"
                />
                        </div>
                        */}
            </Grid>

            <Grid item sm={4}>
              <h3 style={blueHeader}>Help Info</h3>
              <p style={{ fontSize: '13pt', color: '#333' }}>
                Email us at <strong>Support@AgrinHealth.com</strong>.<br />
                Our Help Desk is available M-F 9am - 5pm central.
              </p>

              <Button variant="outlined" size="large">
                <Link
                  to="/d/me/help/request"
                  style={{ textDecoration: 'none' }}
                >
                  <span>Request For Help</span>
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

export default {
  component: withRouter(HelpPage),
};
