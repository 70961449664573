import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  Fade,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { groupBy, orderBy } from 'lodash';
import JournalListFilter from './JournalListFilter';
import JournalViewToggles from './JournalViewToggles';

import JournalListItem from './JournalListItem';
import { LIST_VIEW, CALENDAR_VIEW, SEARCH_VIEW } from './data';

const styles = theme => ({
  marker: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  markerText: {
    fontSize: '1.2em',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
});

const useObserver = callback => {
  const rootRef = useRef();
  const [trigger, setTrigger] = useState(true);
  useEffect(() => {
    const intersection = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting && trigger) {
        setTrigger(false);
        callback();
      } else if (!entry.isIntersecting) {
        setTrigger(true);
      }
    });

    if (rootRef.current) intersection.observe(rootRef.current);
    return () => {
      if (rootRef.current) intersection.unobserve(rootRef.current);
    };
  }, [callback]);

  return rootRef;
};

const groupByMonth = array => {
  const ordered = orderBy(
    array.filter(item => item.visible),
    ['enteredOn'],
    ['desc']
  );
  return groupBy(ordered, item => format(toDate(item.enteredOn), 'yyyy-MM'));
};

const isValidDateRange = (date, min, max) => {
  const d1 = min ? min.split('-') : null;
  const d2 = max ? max.split('-') : null;

  const dateVal = typeof date === 'string' ? new Date(date) : date;
  const dateMin = min ? new Date(d1[0], parseInt(d1[1]) - 1, d1[2]) : null;
  const dateMax = max
    ? new Date(d2[0], parseInt(d2[1] - 1), d2[2]).setHours(23, 59, 59, 999)
    : null;

  return (
    (dateMin == null || dateVal >= dateMin) &&
    (dateMax == null || dateVal <= dateMax)
  );
};

const defaultToNull = value => (!value ? null : value);

const nullFilterState = {
  startDate: null,
  endDate: null,
  searchText: '',
};

const JournalListGroup = props => {
  const {
    personId,
    classes,
    list,
    listIds,
    pagination,
    isFetching,
    onClickCardDelete,
    onClickCardUpdate,
    onClickDetailView,
    onCreateDuplicateJournalEntry,
    onFetchJournalEntriesByCursor,
    fetchByQuery,
    view,
    setView,
    searchData,
    fetchQueryCursor,
  } = props;
  const [mode, setMode] = useState(LIST_VIEW);
  const [groupedData, setGroupData] = useState([]);
  const [groupedLabels, setGroupLabels] = useState([]);
  const [entityCount, setEntityCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(nullFilterState);
  const { startDate, endDate, searchText } = filters;

  const paginationRef = useObserver(() => {
    if (mode === LIST_VIEW) {
      if (pagination.uiState === 'loading' && !isLoading) {
        console.debug(`Fetching new page: ${pagination.nextPage}`);
        onFetchJournalEntriesByCursor();
      }
    } else {
      if (searchData.uiState === 'loading' && !isLoading) {
        console.debug(`Fetching new page: ${pagination.nextPage}`);
        fetchQueryCursor(searchData.nextPage);
      }
    }
  });

  const filterHandler = _filters => {
    const { startDate, endDate, searchText } = _filters;
    if (!(searchText && searchText.length) && !endDate && !startDate) {
      setMode(LIST_VIEW);
      return;
    } else {
      setIsLoading(true);
      setEntityCount(0);
      fetchByQuery(personId, startDate, endDate, searchText);
      setMode(SEARCH_VIEW);
    }
  };

  useEffect(() => {
    if (mode === SEARCH_VIEW) {
      processData();
    }
  }, [searchData.listIds]);

  useEffect(() => {
    if (mode === LIST_VIEW) {
      processData();
    }
  }, [listIds, mode]);

  const processData = () => {
    const _list = mode === LIST_VIEW ? list : searchData.list;
    const _listIds = mode === LIST_VIEW ? listIds : searchData.listIds;
    let filteredEntities = [..._listIds].map(id => {
      return {
        id,
        enteredOn: _list[id].enteredOn,
        visible: true,
      };
    });
    const data = groupByMonth(filteredEntities);
    setGroupData(data);
    setGroupLabels(Object.keys(data));
    setEntityCount(_listIds.length);
    if (_listIds.length === 0) {
      setIsLoading(false);
    }
  };

  const searchHandler = useCallback(debounce(filterHandler, 500), []);

  const onFilterChange = (name, value) => {
    if (filters[name] === value) return;
    const newFilters = {
      ...filters,
      [name]: value,
    };
    const { searchText, endDate, startDate } = newFilters;
    setFilters(newFilters);
    if (!(searchText && searchText.length) && !endDate && !startDate) {
      filterHandler({
        ...filters,
        [name]: value,
      });
    } else {
      searchHandler({
        ...filters,
        [name]: value,
      });
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={3} lg={6}>
        <Fade in={true} timeout={200}>
          <JournalViewToggles activeView={LIST_VIEW} setView={setView} />
        </Fade>
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={6}>
        <Fade in={true} timeout={200}>
          <JournalListFilter
            onChange={onFilterChange}
            searchText={!!searchText ? searchText : ''}
            startDate={startDate}
            endDate={endDate}
          />
        </Fade>
      </Grid>
      {entityCount > 0 &&
        groupedLabels.map((date, index) => (
          <Fade
            key={`journal-data-group-${index}`}
            in={true}
            unmountOnExit={false}
            timeout={200}
          >
            <Grid item xs={12}>
              <div className={classes.marker}>
                <Typography className={classes.markerText} variant="h2">
                  {format(toDate(date), 'MMM yyyy')}
                </Typography>
              </div>
              <Table>
                <TableBody>
                  {entityCount > 0 &&
                    groupedData[date] &&
                    groupedData[date].map((entity, i) => {
                      if (entity.visible)
                        return (
                          <JournalCard
                            key={`journal-entry-${entity.id}`}
                            item={
                              mode === LIST_VIEW
                                ? list[entity.id]
                                : searchData.list[entity.id]
                            }
                            index={entity.id}
                            onClickCardDelete={onClickCardDelete}
                            onClickCardUpdate={onClickCardUpdate}
                            onClickDetailView={onClickDetailView}
                            setFinishedLoading={() => setIsLoading(false)}
                            isLast={i === groupedData[date].length - 1}
                            onCreateDuplicateJournalEntry={
                              onCreateDuplicateJournalEntry
                            }
                          />
                        );
                    })}
                </TableBody>
              </Table>
            </Grid>
          </Fade>
        ))}
      {isFetching || isLoading ? (
        <Grid
          item
          xs={12}
          style={{
            marginTop: '32px',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress variant="indeterminate" disableShrink={true} />
        </Grid>
      ) : (
        ''
      )}
      {(mode === LIST_VIEW
        ? pagination.uiState !== 'success'
        : searchData.uiState !== 'success') && entityCount > 0 ? (
        <Grid
          ref={paginationRef}
          item
          xs={12}
          style={{
            marginTop: '32px',
            justifyContent: 'center',
            display: pagination.uiState === 'success' ? 'none' : 'flex',
          }}
        >
          <CircularProgress variant="indeterminate" disableShrink={true} />
        </Grid>
      ) : (
        ''
      )}
      {!isLoading && entityCount === 0 && (
        <Grid item xs={12}>
          No records found
        </Grid>
      )}
    </Grid>
  );
};

const Card = ({
  item,
  index,
  onClickCardDelete,
  onClickCardUpdate,
  onCreateDuplicateJournalEntry,
  onClickDetailView,
  setFinishedLoading,
  isLast,
}) => {
  useEffect(() => {
    if (isLast) {
      setFinishedLoading();
    }
  }, [isLast]);

  // TODO - We shouldn't have to check if the item is null, because the item should
  // not exist in the list to begin with. Somewhere a property is not being updated
  // which is causing a miss understanding in the logic. For now return null if
  // item doesn't exist. Will fix this later...
  if (item === undefined) {
    console.caution(
      'Expected JournalListItem item is undefined. Known bug, log entry added to increase visibility'
    );
    return null;
  }
  return (
    <JournalListItem
      title={item.title}
      body={item.body}
      date={toDate(item.enteredOn)}
      mediaObjects={item.mediaObjects}
      isModifying={item.isDeleting}
      attachmentCount={item.mediaObjects && item.mediaObjects.length}
      fragmentCount={item.fragments && item.fragments.length}
      onClickDelete={() => onClickCardDelete(item, index)}
      onClickUpdate={() => onClickCardUpdate(item, index)}
      onCreateDuplicateJournalEntry={() => onCreateDuplicateJournalEntry(item)}
      onClickViewMore={() => onClickDetailView(true, nextState, index)}
      {...item}
    />
  );
};

function propsAreEqual(prevProp, nextProp) {
  return prevProp.item === nextProp.item;
}
const JournalCard = React.memo(Card, propsAreEqual);

JournalListGroup.propTypes = {
  list: PropTypes.object.isRequired,
  listIds: PropTypes.array.isRequired,
  onClickCardDelete: PropTypes.func.isRequired,
};

JournalListGroup.defaultProps = {
  list: {},
  listIds: [],
};

export default withStyles(styles)(JournalListGroup);
