import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button, CardMedia } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';

import AccentedPaper from '../../../../../library/components/AccentedPaper';
import constants from '../../constants';

const SYNC_STATUS_ACTIVE = 'Active';
const SYNC_STATUS_INVALID = 'Invalid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    transition: 'transform 200ms ease 200ms',
    '&:hover': {
      transform: 'scale(1.2)',
      zIndex: '9',
    },
  },
  cardBody: {
    width: '100%',
    margin: '0px',
    padding: theme.spacing(1),
  },
  cardButtonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  title: {
    fontSize: '21px',
    color: '#444447',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '266px',
  },
  description: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.75)',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    backgroundColor: '#ffb74d',
  },
  authButton: {
    backgroundColor: '#ffb74d',
  },
  addButton: {
    backgroundColor: '#0068A8',
    color: 'white',
  },
  media: {
    height: '60px',
    width: '60px',
    backgroundColor: '#f2f2f2',
  },
}));

const ButtonRow = ({ item, classes, onClick }) => {
  const addOptions = {
    type: constants.TYPE_ADD,
    icon: <AddIcon />,
    className: classes.addButton,
    buttonLabel: 'Sync',
  };

  const cloOptions = {
    type: constants.TYPE_REMOVE,
    icon: <RemoveIcon />,
    className: classes.removeButton,
    buttonLabel: 'Remove',
  };

  const rauOptions = {
    type: constants.TYPE_REAUTH,
    icon: <SyncIcon />,
    className: classes.authButton,
    buttonLabel: 'Reauthorize',
  };

  const options =
    item.sync && item.sync.status === SYNC_STATUS_INVALID
      ? rauOptions
      : item.sync && item.sync.status === SYNC_STATUS_ACTIVE
      ? cloOptions
      : addOptions;

  if (options.type === constants.TYPE_REAUTH)
    return (
      <Grid item xs={12} className={classes.cardButtonRow}>
        <Button
          className={options.className}
          startIcon={options.icon}
          variant="contained"
          style={{ marginRight: 16 }}
          onClick={() => onClick(item.id, options.type)}
          disableElevation
        >
          {options.buttonLabel}
        </Button>
        <Button
          startIcon={cloOptions.icon}
          variant="link"
          onClick={() => onClick(item.id, cloOptions.type)}
        >
          {cloOptions.buttonLabel}
        </Button>
      </Grid>
    );

  console.log(options);

  return (
    <Grid item xs={12} className={classes.cardButtonRow}>
      <Button
        className={options.type === constants.TYPE_ADD ? options.className : ''}
        startIcon={options.icon}
        variant={options.type === constants.TYPE_ADD ? 'contained' : 'link'}
        style={{ marginLeft: 16 }}
        onClick={() => onClick(item.id, options.type)}
        disableElevation
      >
        {options.buttonLabel}
      </Button>
    </Grid>
  );
};

const Card = ({ item, width, height, onClick }) => {
  const classes = useStyles();

  return (
    <AccentedPaper elevation={2}>
      <Grid container spacing={3} className={classes.cardBody}>
        <Grid item>
          <CardMedia className={classes.media} image={item.image} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={9}
          spacing={3}
          style={{ height: 120 }}
        >
          <Grid item>
            <Typography className={classes.title}>{item.name || ''}</Typography>
            <Typography variant="body2">{item.description || ''}</Typography>
          </Grid>
        </Grid>

        <ButtonRow item={item} classes={classes} onClick={onClick} />
      </Grid>
    </AccentedPaper>
  );

  // return (
  //   <div style={{ flexGrow: 1 }}>
  //   <AccentedPaper className={classes.root} >
  //     <Grid container className={classes.gridContainer}>
  //       <Grid container item spacing={2} xs={3} style={{ marginRight: 4 }}>
  //         <Grid item>
  //           <CardMedia className={classes.media} image={item.image} />
  //         </Grid>
  //       </Grid>
  //       <Grid item container direction="column" xs={9} spacing={3}>
  //         <Grid item>
  //           <Typography className={classes.title}>
  //             {item.name || ''}
  //             </Typography>
  //           <Typography variant='body2'>
  //             {item.description || ''}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //     <ButtonRow item={item} classes={classes} onClick={onClick} />
  //   </AccentedPaper>
  //   </div>
  // )

  // return (
  //   <AccentedPaper className={classes.root} height={height} width={width}>
  //     <Grid container spacing={3} className={classes.gridContainer}>
  //       <Grid xs={2}>
  //         <CardMedia className={classes.media} image={item.image} />
  //       </Grid>
  //       <Grid
  //         item
  //         xs={10}
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Typography className={classes.title}>{item.name || ''}</Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography className={classes.description}>
  //           {item.description || ''}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //       <ButtonRow item={item} classes={classes} onClick={onClick} />
  //   </AccentedPaper>
  // );
};

Card.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

Card.defaultProps = {
  width: '345px',
};

const CustomCard = React.memo(Card);
export default CustomCard;
