import React from 'react';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import FileAddInfo from '../../../containers/MediaObject/FileAddInfo';

import DocumentFormFields from '../../DocumentsDrawer/AddMode/DocumentFormFields';
import {
  updateDocumentMedia,
  UPDATE_IMMUNIZATION_MEDIA,
} from '../../../../store/reducers/immunizations/actions';
import {
  UPDATE_LABS_MEDIA,
  LABS_CATEGORY,
} from '../../../../store/reducers/labs/actions';

const formType = {
  UPDATE_LABS_MEDIA: {
    type: 'labs',
  },
  UPDATE_IMMUNIZATION_MEDIA: {
    type: 'member.userImmunizations',
  },
};

const AddInfoForm = props => {
  React.useEffect(() => {
    props.updateDocumentMedia(props.form, props.pristine, props.type);
  }, [props.pristine]);

  return (
    <div>
      <Collapse in={props.collapsed && !props.isSubmitting} timeout={200}>
        <Paper
          variant="outlined"
          square
          style={{ background: '#FBFBFB', padding: '24px' }}
        >
          {props.isDocument ? (
            <DocumentForm {...props} />
          ) : (
            <FileAddInfo
              fileName={props.bundle.fileName}
              name={props.name}
              index={''}
              initialValues={props.bundle}
            />
          )}
        </Paper>
      </Collapse>
    </div>
  );
};

const DocumentForm = reduxForm({
  destroyOnUnmount: false,
  updateUnregisteredFields: true,
})(props => {
  return (
    <form>
      <DocumentFormFields
        fileName={props.bundle.fileName}
        disableDisplayName
        disableCategoryField
      />
    </form>
  );
});

const mapStateToProps = (state, ownProps) => {
  let stateByType = {};
  if (ownProps.type && ownProps.type.includes('.')) {
    let arr = ownProps.type.split('.');
    let o = { ...state[arr[0]] };
    stateByType = o[arr[1]];
  } else stateByType = state[ownProps.type];

  const _initialValues = {
    category: {
      longName:
        ownProps.bundle.applicationArea ||
        ownProps.bundle.appArea === 'LABRESULT'
          ? LABS_CATEGORY
          : 'Vaccination Records',
      shortName:
        ownProps.bundle.applicationArea ||
        ownProps.bundle.appArea === 'LABRESULT'
          ? LABS_CATEGORY
          : 'Vaccination Records',
    },
    displayName: ownProps.bundle.displayName
      ? ownProps.bundle.displayName
      : ownProps.bundle.file && ownProps.bundle.file.name
      ? ownProps.bundle.file.name
      : '',
    description: ownProps.bundle.description ? ownProps.bundle.description : '',
    newEnteredOnDate: ownProps.bundle.enteredOn
      ? format(new Date(ownProps.bundle.enteredOn), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    newEnteredOnTime: ownProps.bundle.enteredOn
      ? format(new Date(ownProps.bundle.enteredOn), 'HH:mm')
      : format(new Date(), 'HH:mm'),
    enteredOn: ownProps.bundle.enteredOn
      ? new Date(ownProps.bundle.enteredOn)
      : new Date(),
  };

  const _initialValuesProp = ownProps.isDocument
    ? _initialValues
    : {
        description: _initialValues.description,
        date: _initialValues.newEnteredOnDate,
        time: _initialValues.newEnteredOnTime,
      };

  return {
    form: `${ownProps.isDocument ? 'addInfoForm' : 'addInfo'}.${ownProps.name}`,
    isSubmitting:
      (stateByType &&
        formType[stateByType] &&
        formType[stateByType].type.isSubmitting) ||
      false,
    initialValues: {
      ...ownProps.bundle,
      name: ownProps.name,
      index: ownProps.index,
      ..._initialValuesProp,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  updateDocumentMedia: (form, pristine, type) =>
    dispatch(updateDocumentMedia(form, pristine, type)),
});

// redux connector which supplies the from fields value in specified form defined with reduxFrom
export default connect(mapStateToProps, mapDispatchToProps)(AddInfoForm);
