import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import IdentityCodeForm from './IdentityCodeForm';
import { withRouter } from 'react-router';
import { apiPost } from '../../../../library/helpers/fetch';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  AppBar: {
    background: '#e3f2fd',
  },
  footerHeader: {
    padding: '24px',
    paddingTop: 8,
    paddingBottom: 16,
    background: '#e3f2fd',
    color: 'black',
  },
  panel: {
    boxShadow: 'inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc',
  },
  offlineAppbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  tabLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

export default withRouter(function FullWidthTabs({
  value,
  handleChange,
  handleChangeIndex,
  history,
  userId,
  ...rest
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [lockNavigation, setLockNavigation] = React.useState(false);
  const hanldeLockNavigation = () => setLockNavigation(true);

  const handleOnFinish = async () => {
    const response = await apiPost(`/api/registration/new/validate-offline`, {
      userId,
    });
    const json = await response.json();
    if (json.status === 200) {
      history.push({
        pathname: '/',
        state: {
          render_dashboard_message: true,
          message: `Your Agrin Health account is pending identity verification. We have
          sent you an email that contains the information to verify your identity
          offline. If you do not receive the email within the next few minutes,
          please contact Customer Support at Support@AgrinHealth.com.`,
          type: 'warning',
        },
      });
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.AppBar}>
        {value < 2 ? (
          <Grid container>
            <Grid item xs={12} className={classes.footerHeader}>
              <Typography
                variant="body1"
                color="primary"
                style={{ fontWeight: 500 }}
              >
                Select your verification method:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                textColor="primary"
                aria-label="full width tabs example"
                className={classes.AppBar}
                disabled={lockNavigation}
              >
                <Tab
                  label="ID Code"
                  {...a11yProps(0)}
                  classes={{ root: classes.tabLabel }}
                />
                <Tab
                  label="Financial Transaction"
                  {...a11yProps(1)}
                  disabled={lockNavigation}
                  classes={{ root: classes.tabLabel }}
                />
                <Tab
                  label="Offline"
                  {...a11yProps(2)}
                  classes={{ root: classes.tabLabel }}
                  disabled={lockNavigation}
                />
              </Tabs>
            </Grid>
          </Grid>
        ) : (
          <div className={classes.offlineAppbar}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              size="large"
              style={{ color: '#fff' }}
              onClick={() => handleChangeIndex(0)}
            >
              Verify Online
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              size="large"
              onClick={handleOnFinish}
            >
              Finish
            </Button>
          </div>
        )}
      </AppBar>
      <Collapse in={value < 2}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className={classes.panel}
          >
            <IdentityCodeForm
              hanldeLockNavigation={hanldeLockNavigation}
              {...rest}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className={classes.panel}
          >
            <CheckoutForm
              displayPlanInformation={false}
              disableCouponSlot={true}
              disableSummary={true}
              {...rest}
            />
          </TabPanel>
        </SwipeableViews>
      </Collapse>
    </div>
  );
});
