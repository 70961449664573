import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'redux-form';

import InputField from '../../../library/forms/InputField';
import CheckBoxField from '../../../library/forms/CheckboxField';
import SelectField from '../../../library/forms/SelectField';
import { isOver18 } from '../../components/RequestMedicalRecord/validations';

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const maxLength25 = maxLength(25);
const maxLength150 = maxLength(150);

const educationOptions = [
  {
    shortName: 'some primary school',
    longName: 'some primary school',
  },
  {
    shortName: 'High School Diploma or equivalent',
    longName: 'High School Diploma or equivalent',
  },
  {
    shortName: "Associate's (2 yr) degree",
    longName: "Associate's (2 yr) degree",
  },
  {
    shortName: 'Undergraduate (or other 4 yr) degree',
    longName: 'Undergraduate (or other 4 yr) degree',
  },
  {
    shortName: "Master's degree",
    longName: "Master's degree",
  },
  {
    shortName: 'Doctorate degree',
    longName: 'Doctorate degree',
  },
  {
    shortName: 'other',
    longName: 'other',
  },
];

const GeneralInfoFormFields = props => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name="firstName"
            label="First Name *"
            type="text"
            hint="Required"
            autocomplete="off"
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="middleName"
            label="Middle Name"
            type="text"
            autocomplete="off"
            component={InputField}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name="lastName"
            label="Last Name *"
            hint="Required"
            type="text"
            autocomplete="off"
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="suffix"
            label="Suffix"
            type="text"
            autoComplete="new-password"
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="dob"
            label="Date of Birth *"
            hint="Required"
            type="date"
            defaultValue="2017-05-24"
            max="2999-12-31"
            autocomplete="off"
            shrink
            component={InputField}
            validate={props.isDependent ? null : isOver18}
            props={{
              HoverTooltipMsg: props.isDependent ? null : (
                <React.Fragment>
                  <span>
                    Users must be 18 years of age. {'\n'}
                    <br />
                    WHY ACCURACY MATTERS: {'\n'}
                    <br />
                    Most Providers require a DOB to exchange information in
                    order to insure that it is connected to the right patient.
                    If you plan to exchange data with your doctor or other
                    Providers, it will be necessary to enter an accurate DOB.
                    Additionally, DOB is used in some health calculations and
                    screening recommendations. A missing or false DOB could
                    break these informative features.
                  </span>
                </React.Fragment>
              ),
            }}
          />
        </Grid>
        {props.ssnField ? (
          <Grid item xs={12} sm={6}>
            {props.ssnField}
          </Grid>
        ) : (
          ''
        )}

        <Grid item xs={12} sm={6}>
          <Field
            name="eyeColor"
            label="Eye Color"
            component={InputField}
            type="text"
            validate={maxLength25}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="hairColor"
            label="Hair Color"
            component={InputField}
            type="text"
            validate={maxLength25}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="employer"
            label="Employer"
            component={InputField}
            type="text"
            validate={maxLength150}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="occupation"
            label="Occupation"
            component={InputField}
            type="text"
            validate={maxLength150}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="highestEducationLevel"
            label="Highest Level of Education"
            component={SelectField}
            labelKey="longName"
            valueKey="shortName"
            options={educationOptions}
            disableBlur
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.HeightFields}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="totalChildren"
            label="Number of Children"
            component={InputField}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="isCaregiver"
            component={CheckBoxField}
            label="Are you the Primary Caregiver for someone?"
            type="checkbox"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GeneralInfoFormFields;
