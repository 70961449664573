import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const boxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export default withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
    maxWidth: '260px',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: boxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: boxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-100%)',
    background: '#F7F7F7',
    minWidth: '80px',
    borderRadius: '50px',
    fontWeight: '500',
    fontSize: '14px',
    display: 'flex',
    boxShadow: boxShadow,
    justifyContent: 'center',
    '& *': {
      background: 'transparent',
      color: '#0068A8',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
    color: '#006818',
  },
  markLabel: {
    fontSize: '14px',
  },
  markLabelActive: {
    color: '#0068A8',
    fontSize: '14px',
  },
})(Slider);
