export default values => {
  const errors = {}
  
  //portal name
  if (values.portalName && values.portalName.length > 500) {
      errors.portalName = "Must be under 500 characters";
  }

  //provider
  if(!values.providerName) {
      errors.providerName = "Required";
  } else if (values.providerName.length > 500) {
      errors.providerName = "Must be under 500 characters";
  }

  //website
  if(!values.portalUrl) {
      errors.portalUrl = "Required"
  } else if(values.portalUrl.length > 500) {
      errors.portalUrl = "Must be under 500 characters";
  }

  //notes
  if(values.portalDescription && values.portalDescription.length > 1000)
      errors.portalDescription = "Must be under 1000 characters";
  
  return errors;
}
