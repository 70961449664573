import {
  REQUEST_USER_ALLERGIES,
  RECEIVE_USER_ALLERGIES,
  FAILURE_USER_ALLERGIES,
  CREATE_USER_ALLERGY,
} from './actions';

const initialState = {
  isFetchingAllergies: false,
  message: '',
  allergies: [],
};

const sort = sortBy => {
  return (a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] === b[sortBy]) {
      return 0;
    }
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER_ALLERGIES:
      return {
        ...state,
        isFetchingAllergies: true,
      };

    case RECEIVE_USER_ALLERGIES:
      return {
        ...state,
        isFetchingAllergies: false,
        allergies: action.payload,
      };

    case FAILURE_USER_ALLERGIES:
      return {
        ...state,
        message: action.message,
      };

    case CREATE_USER_ALLERGY:
      return {
        ...state,
        allergies: []
          .concat(state.allergies, [action.payload])
          .sort(sort('index')),
      };

    default:
      return state;
  }
};

export default reducer;
