import { Machine } from 'xstate';

export const toggleMachine = Machine({
  id: 'status',
  initial: 'inactive',
  states: {
    inactive: {
      on: {
        TOGGLE: [
          {
            target: 'active',
            cond: (ctx, event) => Boolean(Object.keys(event.data).length),
          },
          {
            target: 'inactive',
          },
        ],
      },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
});
