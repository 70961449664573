import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { setMediaObjects } from '../mediaObject/actions';
import { getFragmentProperties } from '../../../shared/components/JournalCompose/FragmentsList';

export const JOURNAL_MODAL_EDIT = 'journal-edit';
export const JOURNAL_MODAL_CREATE = 'journal-create';
export const JOURNAL_MODAL_CLEAR = 'journal-clear';
export const JOURNAL_MODAL_COPY = 'journal-copy';

export const JOURNAL_MODAL_OPEN = 'journal/modal/open';
export const JOURNAL_MODAL_CLOSE = 'journal/modal/close';

export const JOURNAL_COMPOSITION_SET = 'journal/composition/set';
export const JOURNAL_COMPOSITION_CLEAR = 'journal/composition/clear';
export const JOURNAL_COMPOSITION_CREATE_DUPLICATE =
  'journal/composition/create-duplicate';

export const JOURNAL_COMPOSITION_COPY_REQUEST =
  'journal/composition/create_request';
export const JOURNAL_COMPOSITION_COPY_SUCCESS =
  'journal/composition/create_success';
export const JOURNAL_COMPOSITION_COPY_FAILURE =
  'journal/composition/create_failure';

export const JOURNAL_LOCATION_DATA_SET = 'journal/location/data/set';
export const JOURNAL_LOCATION_DATA_CLEAR = 'journal/location/data/clear';

export const JOURNAL_FRAGMENT_CREATE = 'journal/fragment/create';
export const JOURNAL_FRAGMENT_CHANGE = 'journal/fragment/change';
export const JOURNAL_FRAGMENT_UPDATE = 'journal/fragment/update';
export const JOURNAL_FRAGMENT_DELETE = 'journal/fragment/delete';
export const JOURNAL_FRAGMENT_FOR_UPDATE = 'journal/fragment/for-update';

export const JOURNAL_FRAGMENT_DIALOG_OPEN = 'journal/fragment/dialog/open';
export const JOURNAL_FRAGMENT_DIALOG_CLOSE = 'journal/fragment/dialog/close';

export const JOURNAL_COMPOSITION_POPULATE = 'journal/composition/populate';

export const JOURNAL_MEDIA_UPDATE = 'journal/media/update';
export const JOURNAL_MEDIA_DELETE = 'journal/media/delete';

export const JOURNAL_COMPOSITION_CREATE_REQUEST =
  'journal/composition/create_request';
export const JOURNAL_COMPOSITION_CREATE_SUCCESS =
  'journal/composition/create_success';
export const JOURNAL_COMPOSITION_CREATE_FAILURE =
  'journal/composition/create_failure';

export const JOURNAL_COMPOSITION_UPDATE_REQUEST =
  'journal/composition/update_request';
export const JOURNAL_COMPOSITION_UPDATE_SUCCESS =
  'journal/composition/update_success';
export const JOURNAL_COMPOSITION_UPDATE_FAILURE =
  'journal/composition/update_failure';

export const JOURNAL_COMPOSITION_DELETE_REQUEST =
  'journal/composition/delete_request';
export const JOURNAL_COMPOSITION_DELETE_SUCCESS =
  'journal/composition/delete_success';
export const JOURNAL_COMPOSITION_DELETE_FAILURE =
  'journal/composition/delete_failure';

export const deleteMedia = idx => ({
  type: JOURNAL_MEDIA_DELETE,
  payload: idx,
});

export const updateMedia = (idx, payload) => ({
  type: JOURNAL_MEDIA_UPDATE,
  payload,
  index: idx,
});

export const openJournalModal = (bool, payload) => ({
  type: bool ? JOURNAL_MODAL_OPEN : JOURNAL_MODAL_CLOSE,
  payload,
});

export const populateJournalModal = payload => {
  const fragments = payload.fragments.map(fragment => {
    const fragmentProperties = getFragmentProperties(fragment.fragmentType);
    return {
      ...fragment,
      fragmentData: fragmentProperties.normalizeData(fragment.fragmentData),
    };
  });

  const preparedPayload = {
    ...payload,
    fragments,
  };

  return {
    type: JOURNAL_COMPOSITION_POPULATE,
    payload: preparedPayload,
  };
};

export const setJournalMessage = payload => ({
  type: JOURNAL_COMPOSITION_SET,
  payload,
});

export const updateJournalMessage = payload => ({
  type: JOURNAL_COMPOSITION_UPDATE,
  payload,
});

export const clearJournalEntry = () => ({
  type: JOURNAL_COMPOSITION_CLEAR,
});

export const changeFragment = payload => ({
  type: JOURNAL_FRAGMENT_CHANGE,
  payload,
});

export const createFragment = payload => ({
  type: JOURNAL_FRAGMENT_CREATE,
  payload,
});

export const updateFragment = (payload, index) => ({
  type: JOURNAL_FRAGMENT_UPDATE,
  payload,
  meta: {
    id: index,
  },
});

export const deleteFragment = index => ({
  type: JOURNAL_FRAGMENT_DELETE,
  payload: index,
});

export const setFragmentForUpdate = index => ({
  type: JOURNAL_FRAGMENT_FOR_UPDATE,
  payload: index,
});

export const openJournalFragmentDialog = type => ({
  type: JOURNAL_FRAGMENT_DIALOG_OPEN,
  payload: type,
});

export const closeJournalFragmentDialog = () => ({
  type: JOURNAL_FRAGMENT_DIALOG_CLOSE,
});

export const createJournalDuplicate = payload => ({
  type: JOURNAL_COMPOSITION_CREATE_DUPLICATE,
  payload,
});

// export const createJournalEntry = (personId, payload) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const mediaObject = state.mediaObject;

//     dispatch({ type: JOURNAL_COMPOSITION_CREATE_REQUEST });

//     try {
//       const response = await apiFetchAuthorized(
//         `/api/member/${personId}/journal/entry`,
//         {
//           method: 'POST',
//           mode: 'cors',
//           body: JSON.stringify(payload),
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const json = await response.json();

//       if (mediaObject.bundles.length > 0) {
//         await dispatch(
//           uploadFiles(
//             personId,
//             json.data.journalEntryId,
//             mediaObject.bundles,
//             'JOURNAL'
//           )
//         );
//       }

//       if (payload.error) {
//         return await dispatch({
//           type: JOURNAL_COMPOSITION_CREATE_FAILURE,
//           payload: json,
//         });
//       }

//       return await dispatch({
//         type: JOURNAL_COMPOSITION_CREATE_SUCCESS,
//         payload: json,
//       });
//     } catch (error) {
//       return dispatch({
//         type: JOURNAL_COMPOSITION_CREATE_FAILURE,
//         message: error,
//       });
//     }
//   };
// };

export const createJournalEntry = (pid, isCurrentPerson, payload, bundles) => {
  console.log(setMediaObjects(bundles, 'journalEntryId', 'journal'));
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_COMPOSITION_CREATE_REQUEST,
        JOURNAL_COMPOSITION_CREATE_SUCCESS,
        JOURNAL_COMPOSITION_CREATE_FAILURE,
      ],
      endpoint: `/api/member/journal/entry`,
      payload,
      meta: {
        pid,
        isCurrentPerson,
        mediaObject: setMediaObjects(bundles, 'journalEntryId', 'journal'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const copyJournalEntry = (pid, isCurrentPerson, payload, bundles) => {
  console.log(setMediaObjects(bundles, 'journalEntryId', 'journal'));
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_COMPOSITION_COPY_REQUEST,
        JOURNAL_COMPOSITION_COPY_SUCCESS,
        JOURNAL_COMPOSITION_COPY_FAILURE,
      ],
      endpoint: `/api/member/journal/entry/copy`,
      payload,
      meta: {
        pid,
        isCurrentPerson,
        mediaObject: setMediaObjects(bundles, 'journalEntryId', 'journal'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const updateJournalEntry = (pid, isCurrentPerson, payload, bundles) => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_COMPOSITION_UPDATE_REQUEST,
        JOURNAL_COMPOSITION_UPDATE_SUCCESS,
        JOURNAL_COMPOSITION_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/journal/entry`,
      payload,
      meta: {
        pid,
        isCurrentPerson,
        mediaObject: setMediaObjects(bundles, 'journalEntryId', 'journal'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};
