console.banner();

import 'babel-polyfill';
import React from 'react';
import jwtDecode from 'jwt-decode';
import { StripeProvider } from 'react-stripe-elements';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'; // changed from 'react-router-redux
import { createBrowserHistory } from 'history';
// import { ClickToComponent } from 'click-to-react-component';

import '../library/utils/log';
import createStore from '../store';
import Routes from '../shared/routes';

import {
  localStorageGetObject,
  isTokenExpired,
} from '../library/helpers/fetch';
import { loginSuccess } from '../shared/containers/SignInFormMember/actions';
import { updateAvatar } from '../store/reducers/ui/actions';
import '../client/favicon.ico';
import '../shared/overrides.css';
import {
  ImageEditorModal,
  ImageEditorModalProvider,
} from '../shared/components/ImageEditorModal';

const history = createBrowserHistory();
const initialState = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__ : {};
const store = createStore(initialState);

// Check to see if the token which contains a JWT exists.
// If it does, decode and put back into the redux state
// by manually calling the loginSuccess action creator.
if (localStorage.session) {
  const session = localStorageGetObject('session');
  const token = jwtDecode(session.access_token);
  console.log(token);
  // Expired token, handle.
  if (isTokenExpired(token)) {
    console.log(
      'Session is expired',
      token.exp - Math.floor(Date.now() / 1000)
    );
    // History doesn't work here. Force redirection to sign in page.
    window.location = window.location.origin + '/';
    localStorage.clear();
  }
  store.dispatch(loginSuccess(token));
}

if (localStorage.profile) {
  const profile = localStorageGetObject('profile');
  store.dispatch(updateAvatar(profile.avatarUrl));
}

render(
  <StripeProvider apiKey={STRIPE_PK}>
    {/* <ClickToComponent /> */}
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ImageEditorModalProvider>
          <BrowserRouter>
            <div>
              <ImageEditorModal />
              {renderRoutes(Routes)}
            </div>
          </BrowserRouter>
        </ImageEditorModalProvider>
      </ConnectedRouter>
    </Provider>
  </StripeProvider>,
  document.getElementById('root')
);
