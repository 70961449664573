import { useState, useEffect } from 'react';
import { parseDate } from '../utils';

const useNetCalories = (coordinates, tickValues, dateRange) => {
  const [netData, setNetData] = useState([]);

  useEffect(() => {
    const result = [];
    coordinates.forEach((item, index) => {
      if (!item) return;
      const itemCalories = item.totalCalories;
      const date = parseDate(item.date, dateRange);
      if (result[date]) {
        if (itemCalories > 0) {
          result[date].mealCalories += itemCalories;
          result[date].mealFreq += 1;
        } else {
          result[date].exerciseCalories += itemCalories;
          result[date].exerciseFreq += 1;
        }
      } else {
        result[date] = {
          totalCaloriesUnit: item.totalCaloriesUnit,
          date,
          exerciseCalories: itemCalories < 0 ? itemCalories : 0,
          mealCalories: itemCalories > 0 ? itemCalories : 0,
          exerciseFreq: itemCalories < 0 ? 1 : 0,
          mealFreq: itemCalories > 0 ? 1 : 0,
        };
      }
    });
    const _netData = Object.values(result).map(item => {
      if (item.exerciseFreq || item.mealFreq) {
        const averageMealCalories =
          item.mealCalories > 0 ? item.mealCalories / item.mealFreq : 0;
        const averageExerciseCalories =
          item.exerciseCalories < 0
            ? item.exerciseCalories / item.exerciseFreq
            : 0;
        return {
          ...item,
          totalCalories: item.mealCalories + item.exerciseCalories,
          averageTotalCalories: averageExerciseCalories + averageMealCalories,
          averageExerciseCalories,
          averageMealCalories,
        };
      }
    });

    setNetData(_netData);
  }, [coordinates, dateRange]);
  return netData;
};

export default useNetCalories;
