import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AccentedPaper from '../../../../../library/components/AccentedPaper';
import { apiFetchAuthorized } from '../../../../../library/helpers/fetch';
import { MediaObjectDrawerWithButton } from '../../../DocumentsDrawer/';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Typography, Tooltip } from '@material-ui/core';
import { downloadBlob } from '../../../DocumentsDrawer/helpers';
import MediaObjectDrawerPreview from '../../../DocumentsDrawer/MediaObjectDrawer/MediaObjectDrawerPreview';
import { format } from 'date-fns-tz';
import FileDownload from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
  root: {
    width: '100%',
    transition: 'transform 200ms ease 200ms',
    '&:hover': {
      transform: 'scale(1.2)',
      zIndex: '9',
    },
  },
  gridContainer: {
    padding: '12px',
    paddingTop: '4px',
  },
  category: {
    fontStyle: 'italic',
    fontSize: '14px',
    color: '#444447',
  },
  title: {
    fontSize: '18px',
    color: '#0068A8',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '266px',
  },
  date: {
    fontSize: '16px',
  },
  description: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.75)',
    textOverflow: 'ellipsis',
  },
  downloadIcon: {
    margin: '0px 5px 0 0',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Card = ({ item, width, height }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const requestControllerRef = useRef();

  useEffect(() => {
    requestControllerRef.current = new AbortController();

    () => requestControllerRef.current.abort();
  }, []);

  const onClickDownload = async () => {
    if (isDownloading) return noop();

    setIsDownloading(true);

    const response = await apiFetchAuthorized(
      item.links.download,
      {
        signal: requestControllerRef.current.signal,
      },
      false
    );

    const blob = await response.blob();
    downloadBlob(blob, item.fileName);

    setIsDownloading(false);
  };

  const classes = useStyles();
  return (
    <AccentedPaper className={classes.root} height={height} width={width}>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={9} className={classes.categoryContainer}>
          <Typography className={classes.category}>{item.category}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.actionContainer}>
          <div onClick={onClickDownload}>
            <Tooltip title="Download file">
              <IconButton size="small" className={classes.downloadIcon}>
                <FileDownload color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
          <MediaObjectDrawerWithButton
            disableMargin
            areaType="DOCUMENTS"
            primaryId={item.mediaObjectId}
            recordMediaObjects={item}
            tooltip="Edit Document"
          />
        </Grid>
        <Grid item xs={2}>
          <MediaObjectDrawerPreview
            formatType="grid"
            personId={item.personId}
            data={item}
            displaySVG={true}
            formatType="XS"
          />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.title}>
            {item.displayName || ''}
          </Typography>
          <Typography className={classes.date}>
            {item.enteredOn
              ? format(new Date(item.enteredOn), 'MM/dd/yyyy')
              : ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.description}>
            {item.description || ''}
          </Typography>
        </Grid>
      </Grid>
    </AccentedPaper>
  );
};

Card.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

Card.defaultProps = {
  width: '345px',
  height: '200px',
};

const CustomCard = React.memo(Card);
export default CustomCard;
