import React from 'react';

import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import GlobalFilter from './GlobalFilter';
import Control from './Control';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  useStyles,
  StyledTableRow,
  StyledTableCell,
  StyledTableSortLabel,
} from './styles';

const ReactTable = ({
  columns,
  data,
  updateMyData,
  onEdit,
  onDelete,
  viewMediaObjects = false,
  rendersColGroup = false,
}) => {
  const classes = useStyles();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    hooks => {
      hooks.allColumns.push(columns => [
        ...columns,
        {
          id: 'control',
          Header: () => <div style={{ width: '100px' }}></div>,
          Cell: ({ row }) => (
            <div>
              <Control
                row={row}
                onEdit={onEdit}
                onDelete={onDelete}
                viewMediaObjects={viewMediaObjects}
              />
            </div>
          ),
        },
      ]);
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  };

  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <TableContainer>
        <MaUTable
          size={'medium'}
          className={classes.table}
          {...getTableProps()}
        >
          {rendersColGroup ? (
            <colgroup>
              {columns.map((item, index) => {
                return (
                  <col width={item.col} key={`${index}-${item.col}-colgroup`} />
                );
              })}
            </colgroup>
          ) : (
            ''
          )}
          <TableHead className={classes.head}>
            {headerGroups.map(headerGroup => (
              <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div style={{ display: 'flex', position: 'relative' }}>
                      {column.render('Header')}
                      <StyledTableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    </div>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <StyledTableCell
                        {...cell.getCellProps()}
                        style={{ opacity: row.original.isDeleting ? 0.5 : 1 }}
                      >
                        {cell.render('Cell')}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: 'All', value: data.length },
                ]}
                colSpan={12}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </MaUTable>
      </TableContainer>
    </div>
  );
};

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  rendersColGroup: PropTypes.bool,
};

function propsAreEqual(prevProp, nextProp) {
  return prevProp.data === nextProp.data;
}

const Table = React.memo(ReactTable, propsAreEqual);

export default Table;
