import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Field,
  reduxForm,
  change as changeFieldValue,
  SubmissionError,
} from 'redux-form';
import {
  Grid,
  Paper,
  Divider,
  TextField,
  Button,
  Icon,
} from '@material-ui/core';

import {
  MESSAGE_HELP_CREATE_SUCCESS,
  createHelpMessage,
  MESSAGE_HELP_CREATE_FAILURE,
} from './actions';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import PageHeader from '../../components/PageHeader';
import FieldErrorMessage from '../../../library/forms/FieldErrorMessage';
import BasePage from '../BasePage';

class HelpRequestPage extends Component {
  handleSubmit = async (values, dispatch) => {
    const { email, phone, personId } = this.props;

    var contactMethod, contactDetail;
    if (values.email === true) {
      contactMethod = 'email';
      contactDetail = email;
    } else if (values.phone === true) {
      contactMethod = 'phone';
      contactDetail = phone;
    } else {
      contactMethod = 'none';
    }

    const payload = {
      contactMethod,
      contactDetail,
      message: values.message,
    };

    const result = await dispatch(createHelpMessage(personId, payload));
    if (result && result.type === MESSAGE_HELP_CREATE_SUCCESS) {
      dispatch(
        createMessage(
          'Your request has been successfully submitted and a support representative will contact you shortly.',
          'info',
          10 * 1000
        )
      );
      this.props.history.goBack();
    } else if (result && result.type === MESSAGE_HELP_CREATE_FAILURE) {
      throw new SubmissionError(result.payload.data);
    }
  };

  onCancel = () => this.props.history.goBack();

  render() {
    const { email, phone } = this.props;

    return (
      <BasePage>
        <Grid item xs={12} lg={8}>
          <PageHeader
            headerText="Help Request / Feedback"
            subHeaderHeaderText=""
            backButtonText="Go Back"
          />

          <RequestHelpForm
            onSubmit={this.handleSubmit}
            onCancel={this.onCancel}
            email={email}
            phone={phone}
          />
        </Grid>
      </BasePage>
    );
  }
}

const textAreaRender = props => {
  const { input, meta, ...rest } = props;
  return (
    <div>
      <TextField
        {...rest}
        value={input.value}
        onBlur={input.onBlur}
        multiline={true}
        variant="outlined"
        onChange={e => input.onChange(e)}
      />
      <FieldErrorMessage {...meta} />
    </div>
  );
};

const CheckBoxIcon = props => {
  return (
    <Paper elevation={1} style={{ marginBottom: 16 }}>
      <Button style={{ width: '100%', padding: 24, textAlign: 'left' }}>
        <Icon style={{ color: props.checked ? 'black' : '#AAA' }}>
          {props.checked ? 'check_circle' : 'check_circle_outlined'}
        </Icon>
        &nbsp;
        <span
          style={{ width: '100%', color: props.checked ? 'black' : '#AAA' }}
        >
          {props.label} - {props.display}
        </span>
      </Button>
    </Paper>
  );
};

const checkboxButtonRender = props => {
  return (
    <div
      type="button"
      className="list-group-item"
      onClick={() => {
        const label = props.label.toLowerCase();
        if (label === 'none' && props.input.value) {
          props.input.onChange(true);
          return;
        }

        if (props.input.value) {
          props.changeField('rh-form', 'none', true);
        } else if (label === 'none') {
          props.changeField('rh-form', 'email', false);
          props.changeField('rh-form', 'phone', false);
        } else if (label === 'email') {
          props.changeField('rh-form', 'phone', false);
          props.changeField('rh-form', 'none', false);
        } else if (label === 'phone') {
          props.changeField('rh-form', 'email', false);
          props.changeField('rh-form', 'none', false);
        }

        props.input.onChange(!props.input.value);
      }}
    >
      <CheckBoxIcon
        checked={props.input.value}
        label={props.label}
        display={props.display}
      />
    </div>
  );
};

const CircleMarker = props => (
  <span
    style={{
      display: 'inline-block',
      background: '#666',
      borderRadius: '50%',
      textAlign: 'center',
      width: 24,
      height: 22,
      fontSize: '92%',
      color: '#fff',
    }}
  >
    {props.children}
  </span>
);

let RequestHelpForm = props => {
  const { handleSubmit, onCancel } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div>
          <h3>
            <CircleMarker>1</CircleMarker>Tell us how we can help
          </h3>
          <Divider style={{ marginBottom: 16 }} />

          <Field
            name="message"
            label="Message"
            component={textAreaRender}
            placeholder="Provide details of the problem you're facing"
            rows="7"
            style={{ marginBottom: 24, width: '100%' }}
          />

          <h3>
            <CircleMarker>2</CircleMarker> How should we contact you regarding
            this message?
          </h3>
          <Divider style={{ marginBottom: 16 }} />

          <div className="list-group">
            <Field
              type="checkbox"
              name="email"
              label={'Email'}
              display={props.email}
              component={checkboxButtonRender}
              changeField={props.changeFieldValue}
              required
            />

            {props.phone && (
              <Field
                name="phone"
                type="checkbox"
                label={'Phone'}
                display={props.phone}
                component={checkboxButtonRender}
                changeField={props.changeFieldValue}
              />
            )}

            <Field
              name="none"
              type="checkbox"
              label={'None'}
              display={'no response needed'}
              component={checkboxButtonRender}
              changeField={props.changeFieldValue}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 24,
          }}
        >
          <Button
            style={{ marginRight: 16 }}
            size="large"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
          >
            Submit Request
          </Button>
        </div>
      </div>
    </form>
  );
};

const boundActionCreators = dispatch =>
  bindActionCreators({ changeFieldValue }, dispatch);

RequestHelpForm = reduxForm({
  form: 'rh-form',
  validate: values => {
    const errors = {};

    if (values.message && values.message.trim().length === 0) {
      errors.message = 'Required';
    }

    return errors;
  },
  onSubmit: () => {},
})(RequestHelpForm);

RequestHelpForm = connect(
  (state, props) => ({
    initialValues: {
      message: '',
      email: false,
      phone: true,
      none: false,
    },
  }),
  boundActionCreators
)(RequestHelpForm);

const mapStateToProps = state => ({
  personId: state.member.login.details.personId,
  email: state.member.login.details.unique_name,
  phone: state.member.login.details.phone,
});

export default {
  component: connect(mapStateToProps)(withRouter(HelpRequestPage)),
};
