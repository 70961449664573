import React, { useState } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

import Icon from './NotificationIcon';
import TimeAgo from '../TimeAgo';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    background: '#fff',
    borderBottom: '.7px solid #CECECE',
    minHeight: '70px',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '13px',
    paddingLeft: '23px',
    paddingRight: '0',
    position: 'relative',
  },
  notificationHistoryRoot: {
    background: '#fff',
    borderBottom: '.7px solid #CECECE', //    marginTop: "20px" doesn't work here
    minHeight: '70px',
    width: '100%',
    paddingBottom: '20px',
    paddingLeft: '23px',
    paddingTop: '20px',
    paddingRight: '0',
    position: 'relative',
  },
  noticeText: {
    fontSize: '10.5px',
    marginRight: '15px',
    paddingBottom: '15px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
  },
  notificationHistoryNoticeText: {
    fontSize: '14px',
    marginRight: '15px',
    paddingBottom: '15px',
  },
  rowStyle: {
    border: '1px solid #F2ECEC',
    paddingTop: theme.spacing.unit * 2,
    height: 'auto',
  },
  notificationHistoryIcon: {
    marginTop: '1px',
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  timerStyle: {
    top: '2px',
    fontSize: '100%',
    color: '#8B8B8B',
    position: 'relative',
    fontSize: '13px',
    transform: 'translateY(20%)',
  },
  timestampText: {
    position: 'relative',
    left: '5px',
    top: '2px',
    fontSize: '9px',
    color: '#75747B',
    userSelect: 'none',
    [theme.breakpoints.up('lg')]: {
      fontSize: '10px',
    },
  },
  notificationHistoryTimestampText: {
    position: 'relative',
    left: '5px',
    top: '2px',
    fontSize: '12px',
    color: '#75747B',
    userSelect: 'none',
  },
  timerRowStyle: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '14px',
  },
  notificationHistoryTimerRowStyle: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '20px',
  },
  button: {
    fontSize: '7px',
    fontWeight: '500',
    lineHeight: '8px',
  },
  read: {
    background: '#eee',
    color: '#75747B',
    fontWeight: '200 !important',
  },
  iconContainer: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    fontSize: '17px',
    marginTop: '2px',
  },
  messageContainer: {
    paddingLeft: '4%',
  },
});

const NotificationEvent = props => {
  const { classes, data, fromNotificationsHistory } = props;
  const [isRead, setIsRead] = useState(data.read ? data.read : false);

  const onClick = e => {
    props.onNotificationClick(data.id);

    if (!isRead) setIsRead(true);
  };

  return (
    <div
      //   key={props.data.id}
      className={classNames({
        [classes.read]: isRead,
        [classes.root]: !fromNotificationsHistory,
        [classes.notificationHistoryRoot]: fromNotificationsHistory,
      })}
      onClick={onClick}
    >
      <Grid
        container
        style={{ background: 'F0F0F0', margin: 'auto 0' }}
        spacing={8}
        className={classNames({
          [classes.notificationTextContainer]: fromNotificationsHistory,
          [classes.textContainer]: !fromNotificationsHistory,
        })}
      >
        <Grid
          className={classes.icon}
          item
          xs={1}
          sm={1}
          className={classNames({
            [classes.notificationHistoryIcon]: fromNotificationsHistory,
            [classes.iconContainer]: !fromNotificationsHistory,
          })}
        >
          <Icon type={data.type} />
          {/* {getIcon(data.type, classes.greyIconStyle)} */}
        </Grid>

        <Grid item sm={11} xs={11}>
          <div className={classes.messageContainer}>
            <Typography
              className={classNames({
                [classes.notificationHistoryNoticeText]: fromNotificationsHistory,
                [classes.noticeText]: !fromNotificationsHistory,
              })}
            >
              {data.body}
            </Typography>

            <div
              className={classNames({
                [classes.notificationHistoryTimerRowStyle]: fromNotificationsHistory,
                [classes.timerRowStyle]: !fromNotificationsHistory,
              })}
            >
              <TimerIcon className={classes.timerStyle} />
              <Typography
                className={classNames({
                  [classes.notificationHistoryTimestampText]: fromNotificationsHistory,
                  [classes.timestampText]: !fromNotificationsHistory,
                })}
              >
                <TimeAgo timestamp={data.createdOn} />
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(NotificationEvent);
