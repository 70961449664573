import React, { Component } from 'react';
import { shorten } from '../../../library/helpers/format';
import styles from './File.css';
import AddCircleoutlineIcon from '@material-ui/icons/AddCircleOutline';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';
import AddInfo from './FileAddInfo';
import FileIcon from './FileIcon';

class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: this.props.index,
    };
  }

  componentDidMount() {
    const { bundle } = this.props;
    this.setState({
      description: bundle.description,
      time: bundle.time,
      date: bundle.date,
    });
  }

  // function to open media file information form to fill up
  addInfoButtonClick = currentIndex => {
    this.props.changeCurrentIndex(currentIndex);
  };

  // handle the change event of form control
  handleDescriptionChange = event => {
    //event.stopPropagation();
    this.props.descriptionChanged(this.props.index, event.target.value);
    this.setState({ description: event.target.value });
  };

  // handle the change event of form control
  handleDateChange = event => {
    //event.stopPropagation();
    this.props.dateChanged(this.props.index, event.target.value);
    this.setState({ date: event.target.value });
  };

  // handle the change event of form control
  handleTimeChange = event => {
    //event.stopPropagation();
    this.props.timeChanged(this.props.index, event.target.value);
    this.setState({ time: event.target.value });
  };

  // calculation uploading progress of media which is in upload process
  renderProgressBar = (current, max) => {
    const calculation = Math.round((current / max) * 100);
    return (
      <div>
        <div className={styles.progressBar}>
          <div
            style={{ width: `${calculation}%` }}
            className={styles.progressData}
          ></div>
        </div>
        <div className={styles.info}>
          {calculation === 100 ? 'Upload Complete!' : `${calculation}%`}
        </div>
      </div>
    );
  };

  render() {
    const {
      bundle,
      uploadingProcess,
      removeObject,
      descriptionChanged,
      dateChanged,
      timeChanged,
      isSubmitting,
      mode,
      isDetailCollapseDisabled = false,
      ...rest
    } = this.props;

    const uploadProgressOption =
      mode === 'drawer' ? uploadingProcess : isSubmitting;
    // separate media file extension file wise and based on extension component will be render.
    return (
      <div
        className={styles.block}
        onClick={event => {
          event.stopPropagation();
        }}
        {...rest}
      >
        <div>
          <FileIcon bundle={bundle} />
          <div className={styles.description}>
            <div className={styles.name}>
              {shorten(bundle.file.name, 20)}
              {!uploadProgressOption && (
                <div
                  className={`${styles.incBtn} ${styles.incBtnClose}`}
                  onClick={removeObject}
                >
                  <DeleteIcon className={styles.deleteIcon} />
                </div>
              )}
            </div>
            {bundle.isUploading || bundle.isUploaded ? (
              this.renderProgressBar(bundle.loaded, bundle.total)
            ) : (
              <div>
                {!uploadProgressOption &&
                  this.props.currentIndex !== this.state.currentIndex && (
                    <div
                      className={styles.addInfoBtn}
                      onClick={() => this.addInfoButtonClick(this.props.index)}
                    >
                      <AddCircleoutlineIcon /> Details
                    </div>
                  )}

                {!uploadProgressOption &&
                  !isDetailCollapseDisabled &&
                  this.props.currentIndex === this.state.currentIndex && (
                    <div
                      className={styles.addInfoBtn}
                      onClick={() => this.addInfoButtonClick('')}
                    >
                      <CollapseIcon /> Details
                    </div>
                  )}

                {!uploadProgressOption &&
                  this.props.currentIndex === this.state.currentIndex && (
                    <AddInfo
                      index={this.props.index}
                      description={this.state.description}
                      date={this.state.date}
                      time={this.state.time}
                      name={bundle.file.name
                        .substring(0, 4)
                        .split('')
                        .join('')}
                      handleDescriptionChange={this.handleDescriptionChange}
                      handleDateChange={this.handleDateChange}
                      handleTimeChange={this.handleTimeChange}
                      addInfoButtonClick={() => this.addInfoButtonClick('')}
                    />
                  )}
              </div>
            )}
          </div>
          <div className="clear"></div>
        </div>
      </div>
    );
  }
}

export default File;
