import React from 'react';
import { Grid } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import { DISTANCE_UNITS } from '../../../components/JournalCompose/fragments/ActivityExercise/fields/UnitSelectField';

const ActivityExerciseViewer = ({ data }) => {
  const duration =
    data.manualActivityTimeMins > 0
      ? data.manualActivityTimeMins
      : data.totalActivityTimeMins;
  const hrs = Math.floor(duration / 60);
  const mins = duration % 60;

  const calories =
    data.manualCaloriesBurned > 0
      ? data.manualCaloriesBurned
      : data.totalCaloriesBurned;
  const distance =
    data.manualDistance > 0 ? data.manualDistance : data.totalDistance;

  const distanceUnit = data.distanceUnit
    ? data.distanceUnit.toLocaleLowerCase()
    : 'km';
  const distanceValue = `${distance} ${
    data.distanceUnit && DISTANCE_UNITS[distanceUnit]
      ? parseFloat(distance) > 2
        ? DISTANCE_UNITS[distanceUnit].plural
        : DISTANCE_UNITS[distanceUnit].singular
      : 'km'
  }`;

  return (
    <Grid container spacing={2}>
      {!!duration && (
        <Grid item sm={12} md={6} lg={4}>
          <InputField
            label="Total Exercise Time"
            value={`${hrs > 0 ? '' + hrs + ' hrs ' : ''}${
              mins > 0 ? '' + mins + ' min' : ''
            }`}
            shrink={true}
            InputProps={{ readOnly: true, disabled: true }}
            enableMinHeight={false}
          />
        </Grid>
      )}
      {!!calories && (
        <Grid item sm={12} md={6} lg={4}>
          <InputField
            label="Total Calories Burned"
            value={`${calories} ${
              data.totalCaloriesBurnedUnit
                ? data.totalCaloriesBurnedUnit
                : 'kcal'
            }`}
            shrink={true}
            InputProps={{ readOnly: true, disabled: true }}
            enableMinHeight={false}
          />
        </Grid>
      )}
      {!!distance && (
        <Grid item sm={12} md={6} lg={4}>
          <InputField
            label="Total Distance"
            value={distanceValue}
            shrink={true}
            InputProps={{ readOnly: true, disabled: true }}
            enableMinHeight={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityExerciseViewer;
