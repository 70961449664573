import { uniqBy } from 'lodash';

/**
 * Hash data structure to array.
 * Analogous to es2017 Object.values
 *
 * @param {Object} object
 * @return {Array}
 */
export const objectValues = object => {
  return Object.keys(object).reduce(
    (array, key) => array.push(object[key]) && array,
    []
  );
};

export const getKeys = (key, a) =>
  a && a.length > 0 ? a.map(x => x[key]) : [];

export const arrayToHashMap = (key, a) =>
  a && a.length > 0
    ? a.reduce((map, obj) => {
        map[obj[key]] = obj;
        return map;
      }, {})
    : [];

export const makeObjectIndex = (key, a) =>
  a && a.length > 0
    ? a.reduce((map, obj, index) => {
        map[obj[key]] = index;
        return map;
      }, {})
    : [];

export const deleteNullProps = obj => {
  var propNames = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (
      typeof obj[propName] == 'undefined' &&
      (obj[propName] === null || obj[propName] === undefined)
    ) {
      delete obj[propName];
    }
  }
};

export const titleCase = str => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function(word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
};

export const removeObjectDuplicates = (array, key) =>
  array && array.length > 0 ? uniqBy(array, rec => rec[key]) : [];

/**
 * Creates an object based on the code list schema, both the short name and
 * long name will be the same.
 *
 * @param {*} value the value that is mapped to both the short name and long name
 * @return object
 */
export const toCodeObject = value => {
  return { shortName: value, longName: value };
};

/**
 * Converts as csv list into object array that represents a code list. Each item
 * of the code lists array has the keys short and long name. The csv value will
 * be mapped to both.
 *
 * @param {*} string represents a csv list (values separated by commas)
 * @return array
 */
export const csvToCodeObjectList = string => {
  return string ? string.split(',').map(term => toCodeObject(term.trim())) : [];
};

export const codeObjectListToCsv = array => {
  return array && array.length > 0
    ? array.map(obj => obj.shortName).join(', ')
    : '';
};

export const omitPropsStartingWith = testString => object => {
  const temp = {};

  for (let prop in object) {
    if (!prop.startsWith(testString)) {
      if (
        object[prop] &&
        !Array.isArray(object[prop]) &&
        typeof object[prop] === 'object'
      ) {
        temp[prop] = omitPropsStartingWith(testString)(object[prop]);
      } else if (Array.isArray(object[prop])) {
        temp[prop] = object[prop].map(objectProp =>
          typeof objectProp === 'object'
            ? omitPropsStartingWith(testString)(objectProp)
            : objectProp
        );
      } else {
        temp[prop] = object[prop];
      }
    }
  }

  return temp;
};

export const normalizeObjectKeys = (obj1, obj2) => {
  const temp = {};
  const keys = Object.keys(obj1);
  for (let prop in obj2) {
    if (keys.includes(prop)) {
      if (
        obj2[prop] &&
        !Array.isArray(obj2[prop]) &&
        typeof obj2[prop] === 'object'
      ) {
        temp[prop] = normalizeObjectKeys(obj1[prop], obj2[prop]);
      } else if (Array.isArray(obj2[prop])) {
        temp[prop] = obj2[prop].map(objectProp => {
          return typeof objectProp === 'object'
            ? normalizeObjectKeys(obj1[prop][0], objectProp)
            : objectProp;
        });
      } else {
        temp[prop] = obj2[prop];
      }
    }
  }
  return temp;
};

export const filterByQuery = (value, query) =>
  value && query && value.toLowerCase().includes(query.toLowerCase());

// compare ONE-LEVEL only objects.
export const compareObjects = function(o1, o2) {
  if (o1 === null || o2 === null) return false;
  for (var p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  for (var p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }
  return true;
};
