/// <reference path="types.js" />

import CheckboxField from '../../../../../library/forms/CheckboxField';
import InputField from '../../../../../library/forms/InputField';
import SelectField from '../../../../../library/forms/SelectField';
import {
  phoneMask,
  postalCodeMask,
  postalCodeNormalizer,
} from '../../../../components/RequestMedicalRecord/validations';
import SsnFormField from '../../../../containers/FormFields/SsnFormField';
import SwitchField from '../../../../../library/forms/SwitchField';
import ConfirmEmailField from '../../../../containers/FormFields/ConfirmEmailField';
import HeightReduxField from '../../../../containers/FormFields/HeightReduxField';
import { required } from '../../../../components/JournalCompose/fragments/Validations';
import { isOver18 } from '../../../../components/RequestMedicalRecord/validations';
import { differenceInCalendarYears } from 'date-fns';
import { minLength } from '../../../../../library/validate';
import { get, groupBy, sortBy } from 'lodash';

/**
 * Get fields grouped and sorted by user defined position
 *
 * @param {Array<import('./types').FieldOptions>} fields - Array that represents HTML form field definitions
 * @param {import('./types').GroupOptions} groupOptions - see @link GroupOptions
 * @returns {Array<import('./types').FieldOptions>} Returns array sorted by field groups and field position
 */
export function getFieldsByGroup(fields, groupOptions = null) {
  // Group all the fields by meta.group name
  const groupedFields = groupBy(fields, x => get(x, 'meta.group', 'undefined'));

  // If no additional details for user defined groups is found
  // return grouped fields.
  if (groupOptions === null) return groupedFields;

  const groupedKeys = Object.keys(groupedFields);
  const groupOptionKeys = Object.keys(groupOptions);

  // Add group name to object. This is needed because we're
  // going to sort by key, and the key is not preserved.
  var normalizedGroupOptions = groupOptionKeys.map(key => ({
    __key: key,
    ...groupOptions[key],
  }));

  // Sort by group position
  var groupOptionsSorted = sortBy(normalizedGroupOptions, 'position');

  // If the undefined group doesn't exist in options (no user override)
  // then we add the "undefined" group to the sorted options because
  // we need to include grouped fields in the returned output.
  // Note: not this group is purposely placed at the bottom
  if (
    groupedKeys.includes('undefined') &&
    !groupOptionKeys.includes('undefined')
  ) {
    groupOptionsSorted.push({ __key: 'undefined' });
  }

  // Iterate over each subgroup and sort fields by their sort order
  return groupOptionsSorted.map(key => {
    return sortBy(groupedFields[key.__key], [o => o.meta?.position]);
  });
}

const minLength4 = minLength(
  4,
  'Street Address must be atleast {{min}} characters.'
);

export const isUnder18 = value =>
  differenceInCalendarYears(new Date(), new Date(value)) >= 18
    ? 'Must be under 18 years of age'
    : undefined;

/**
 *
 * @param {*} isChild
 * @returns {import('./types').FieldOptions}
 */
const IDENTIFYING_INFORMATION = isChild => [
  {
    name: 'salutation',
    label: 'Title',
    component: InputField,
    type: 'text',
    grid: {
      xs: 12,
      md: 2,
      lg: 1,
    },
  },
  {
    name: 'firstName',
    label: 'First Name*',
    component: InputField,
    type: 'text',
    validate: [required],
    grid: {
      xs: 12,
      md: 5,
      lg: 3,
    },
  },
  {
    name: 'middleName',
    label: 'Middle Name',
    component: InputField,
    type: 'text',
    grid: {
      xs: 12,
      md: 5,
      lg: 3,
    },
  },
  {
    name: 'lastName',
    label: 'Last Name*',
    validate: [required],
    component: InputField,
    type: 'text',
    grid: {
      xs: 12,
      md: 5,
      lg: 3,
    },
  },
  {
    name: 'suffix',
    label: 'Suffix',
    component: InputField,
    type: 'text',
    grid: {
      xs: 12,
      md: 2,
      lg: 1,
    },
  },
  {
    name: 'pronouns',
    label: 'Pronouns',
    component: SelectField,
    labelKey: 'longName',
    valueKey: 'shortName',
    options: [
      {
        longName: 'She/Her',
        shortName: 'She/Her',
      },
      {
        longName: 'He/Him',
        shortName: 'she/her',
      },
      {
        longName: 'They/Them',
        shortName: 'They/Them',
      },
    ],
    grid: {
      xs: 12,
      md: 5,
      lg: 3,
    },
  },
  {
    render: SsnFormField,
    props: {
      whiteBG: true,
      style: {
        width: '100%',
      },
    },
    grid: {
      xs: 12,
      md: 3,
    },
  },
  {
    name: 'dob',
    label: 'Date of Birth *',
    validate: isChild ? [required, isUnder18] : [required, isOver18],
    component: InputField,
    shrink: true,
    type: 'date',
    grid: {
      xs: 12,
      md: 3,
    },
  },
  {
    name: 'isDeceased',
    label: 'Deceased',
    component: CheckboxField,
    props: {
      enableMinHeight: false,
    },
    type: 'checkbox',
    grid: {
      xs: 12,
      md: 3,
    },
  },
];

/**
 *
 * @param {*} languageTypes
 * @param {*} isFetchingLanguageType
 * @param {*} stateTypes
 * @returns {import('./types').FieldOptions}
 */
const CONTACT_INFORMATION = (
  languageTypes,
  isFetchingLanguageType,
  stateTypes
) => [
  {
    name: 'email',
    component: ConfirmEmailField,
    type: 'text',
    grid: {
      xs: 12,
    },
  },
  {
    type: 'text',
    name: 'phone.cell',
    label: 'Cell Number',
    component: InputField,
    format: phoneMask,
    grid: {
      xs: 12,
      md: 4,
      lg: 2,
    },
  },
  {
    type: 'text',
    name: 'phone.home',
    label: 'Home Phone',
    component: InputField,
    format: phoneMask,
    grid: {
      xs: 12,
      md: 4,
      lg: 2,
    },
  },
  {
    type: 'text',
    name: 'phone.work',
    label: 'Work Phone',
    component: InputField,
    format: phoneMask,
    grid: {
      xs: 12,
      md: 4,
      lg: 2,
    },
  },
  {
    name: 'primaryLanguage',
    label: 'Primary Language',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: languageTypes,
    isLoading: isFetchingLanguageType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 12,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'secondaryLanguage',
    label: 'Secondary Language',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: languageTypes,
    isLoading: isFetchingLanguageType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 12,
      md: 4,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'street',
    label: 'Street Address',
    component: InputField,
    validate: [minLength4],
    grid: {
      xs: 12,
      md: 6,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'street2',
    label: 'Street Address 2',
    component: InputField,
    grid: {
      xs: 12,
      md: 6,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'city',
    label: 'City',
    component: InputField,
    grid: {
      xs: 12,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'state',
    label: 'State',
    labelKey: 'longName',
    valueKey: 'shortName',
    options: stateTypes,
    component: SelectField,
    isSearchable: true,
    grid: {
      xs: 12,
      md: 4,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'postalCode',
    label: 'Zipcode*',
    placeholder: 'XXXXX',
    inputProps: { maxLength: 10 },
    validate: [required],
    component: InputField,
    format: postalCodeMask,
    normalize: postalCodeNormalizer,
    grid: {
      xs: 12,
      md: 4,
      lg: 3,
    },
  },
];

/**
 *
 * @param {*} codeTypes
 * @param {*} gender
 * @returns {import('./types').FieldOptions}
 */
const OTHER_INFORMATION = (codeTypes, gender) => [
  {
    name: 'height',
    component: HeightReduxField,
    containerProps: {
      whiteBG: true,
    },
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    type: 'text',
    name: 'hairColor',
    label: 'Hair Color',
    component: InputField,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    type: 'text',
    name: 'eyeColor',
    label: 'Eye Color',
    component: InputField,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'bloodType',
    label: 'Blood Type',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: codeTypes.bloodTypes,
    isLoading: codeTypes.isFetchingBloodType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'maritalStatus',
    label: 'Marital Status',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: codeTypes.maritalTypes,
    isLoading: codeTypes.isFetchingMaritalType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'ethnicity',
    label: 'Race/Ethnicity',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: codeTypes.ethnicityTypes,
    isLoading: codeTypes.isFetchingEthnicityType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'gender',
    label: 'Gender',
    type: 'text',
    labelKey: 'longName',
    valueKey: 'shortName',
    hint: 'Assigned at birth',
    component: SelectField,
    options: codeTypes.genderTypes,
    isLoading: codeTypes.isFetchingGenderType,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    type: 'number',
    name: 'totalChildren',
    label: 'Number of Children',
    component: InputField,
    grid: {
      xs: 12,
      md: 2,
    },
  },
  ...(gender === 'F' ? FEMALE_ONLY_FIELDS : []),
  {
    name: 'isCaregiver',
    label: 'currently a primary caregiver to someone',
    component: SwitchField,
    props: {
      enableMinHeight: false,
    },
    type: 'switch',
    grid: {
      xs: 12,
      md: 3,
    },
  },
  {
    type: 'text',
    name: 'employer',
    label: 'Employer',
    component: InputField,
    grid: {
      xs: 12,
      md: 3,
    },
    meta: {
      group: 'work',
    },
  },
  {
    type: 'text',
    name: 'occupation',
    label: 'Occupation',
    component: InputField,
    grid: {
      xs: 12,
      md: 3,
    },
    meta: {
      group: 'work',
    },
  },
  {
    type: 'text',
    name: 'highestEducationLevel',
    label: 'Highest Level of Education',
    component: SelectField,
    labelKey: 'longName',
    valueKey: 'shortName',
    options: [
      {
        longName: 'Some Grade School',
        shortName: 'Some Grade School',
      },
      {
        longName: 'High School Degree or Equivalent',
        shortName: 'High School Degree or Equivalent',
      },
      {
        longName: 'Associate’s Degree',
        shortName: 'Associate’s Degree',
      },
      {
        longName: 'Associate’s Degree',
        shortName: 'Associate’s Degree',
      },
      {
        longName: 'College Degree',
        shortName: 'College Degree',
      },
      {
        longName: 'Master’s degree',
        shortName: 'Master’s degree',
      },
      {
        longName: 'Doctorate',
        shortName: 'Doctorate',
      },
      {
        longName: 'Other',
        shortName: 'Other',
      },
    ],
    grid: {
      xs: 12,
      md: 3,
    },
    meta: {
      group: 'work',
    },
  },
  {
    name: 'veteranStatus',
    label: 'Veteran Status',
    component: SelectField,
    options: codeTypes.veteranTypes,
    labelKey: 'shortName',
    valueKey: 'shortName',
    isLoading: codeTypes.isFetchingVeteranType,
    grid: {
      xs: 12,
      md: 3,
    },
    meta: {
      group: 'work',
    },
  },
];

/**
 * @type {FieldOptions}
 */
const FEMALE_ONLY_FIELDS = [
  {
    name: 'pregnant',
    label: 'currently pregnant',
    component: SwitchField,
    props: {
      enableMinHeight: false,
    },
    type: 'switch',
    grid: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'breastfeeding',
    label: 'currently breastfeeding',
    component: SwitchField,
    props: {
      enableMinHeight: false,
    },
    type: 'switch',
    grid: {
      xs: 12,
      md: 2,
    },
  },
];

/**
 * Factory function for identifying information HTML Form field definitions
 *
 * @param {bool} isChild - Used to omit or include fields specific to a child/adult
 * @returns {Array<import('./types').FieldOptions>} Idenditfying information field definitions
 */
export const identifyingInformationFields = isChild => [
  IDENTIFYING_INFORMATION(isChild),
];

/**
 * Factory function for contact information HTML Form field definitions
 *
 * @param {Array<import('./types').ListOption>} languageTypes - A list of language options
 * @param {bool} isFetchingLanguageType  - Indicate if the language options are being fetched from the server
 * @param {Array<import('./types').ListOption>} stateTypes - A list of state options
 * @returns {Array<import('./types').FieldOptions>} Contact/Information field deinfitions
 */
export const contactInformationFields = (
  languageTypes,
  isFetchingLanguageType,
  stateTypes
) => [CONTACT_INFORMATION(languageTypes, isFetchingLanguageType, stateTypes)];

/**
 * Factory function for other information HTML Form field deinfitions
 *
 * @param {Object<string, import('./types').ListOption>} codeTypes - List of options for specific fields
 * @param {String} gender - Used to render fields conditionally based on the user's gender
 * @returns {Array<import('./types').FieldOptions>} Other information field definitions normalized, ready for use
 */
export const otherInformationFields = (codeTypes, gender) =>
  getFieldsByGroup(OTHER_INFORMATION(codeTypes, gender), {
    undefined: { position: 0 },
    work: { position: 1 },
  });
