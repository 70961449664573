import React from 'react';
import DocumentSliderContainer from './Success/DocumentSliderContainer';
import DocumentsEmpty from './Empty';
import LoadingMessage from '../../../library/components/Loading';

import { useSliderContainer, useToggleFilter } from './hooks';

export const sortByStatus = {
  CATEGORIES: 'Categories',
  DATE: 'Date',
  TITLE: 'Title',
};

export const status = {
  LOADING: 'loading',
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
  IDLE: 'idle',
};

export const sortOptions = [
  {
    shortName: sortByStatus.CATEGORIES,
    longName: sortByStatus.CATEGORIES,
  },
  {
    shortName: sortByStatus.DATE,
    longName: sortByStatus.DATE,
  },
  {
    shortName: sortByStatus.TITLE,
    longName: sortByStatus.TITLE,
  },
];

const DocumentsLoading = () => <LoadingMessage message="Loading Documents" />;

export {
  DocumentSliderContainer,
  DocumentsEmpty,
  DocumentsLoading,
  useSliderContainer,
  useToggleFilter,
};
