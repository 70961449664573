import React, { useState } from 'react';
import InputField from './InputField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

const FilterTextField = ({
  value,
  handleChange,
  placeholder,
  handleClear,
  label = '',
}) => {
  return (
    <InputField
      type="text"
      shrink={true}
      label={label}
      value={value}
      onChange={e => handleChange(e.target.value)}
      endAdornment={
        value ? (
          <IconButton size="small" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        ) : (
          <SearchIcon />
        )
      }
      enableMinHeight={false}
      placeholder={placeholder || ''}
    />
  );
};

export default FilterTextField;
