import OrdinalFrame from 'semiotic/lib/OrdinalFrame';
import React from 'react';
import { Paper } from '@material-ui/core';
import styles from '../styles.css';
const MARGINS_X = 40;
const MARGINS_Y = 40;

const SleepViolinChart = ({ data, dimensions }) => {
  const tickValues = data.map(item => Math.round(item.totalTimeSlept));
  const frameProps = {
    data,
    className: styles.frame,
    size: [dimensions[0], 240],
    margin: {
      left: MARGINS_Y,
      right: MARGINS_Y,
      top: MARGINS_X,
      bottom: MARGINS_X,
    },
    type: 'point',
    summaryType: 'violin',
    projection: 'horizontal',
    oAccessor: 'Hours',
    rAccessor: 'totalTimeSlept',
    rExtent: [0, 24],
    style: d => {
      return {
        r: 1,
        fill: d && '#fff',
      };
    },
    summaryStyle: d => ({
      fill: d && '#fff',
      fillOpacity: 0.2,
      stroke: d && '#fff',
      strokeWidth: 0.5,
    }),
    title: 'Hours Slept by Frequency (all sleep records)',
    axes: [
      { orient: 'bottom', className: styles.axes, tickValues, baseline: false },
    ],
    oLabel: true,
  };
  return (
    <Paper
      elevation={4}
      style={{ backgroundColor: '#0068A8', width: dimensions[0] }}
    >
      <OrdinalFrame {...frameProps} />
    </Paper>
  );
};

export default SleepViolinChart;
