import React from 'react';
import wordicon from '../../../../client/images/word-icon.png';
import pdficon from '../../../../client/images/pdf-icon.png';
import excelicon from '../../../../client/images/excel-icon.png';
import { getBestTargetImageFormat } from '../../../components/MediaObject/helpers';
import Image from '../../../components/Image';
import Video from '../../../components/Video';
import styles from '../../../containers/MediaObject/File.css';

const PreviewIcon = ({ bundle }) => {
  // separate media file extension file wise and based on extension component will be render.
  let icon;
  const extensionType = bundle.fileName.split('.');
  if (extensionType[1] === 'pdf') {
    icon = <img src={pdficon} alt="PDF File" />;
  } else if (extensionType[1] === 'docx' || extensionType[1] === 'doc') {
    icon = <img src={wordicon} alt="Word File" />;
  } else if (extensionType[1] === 'xls' || extensionType[1] === 'xlsx') {
    icon = <img src={excelicon} alt="Excel File" />;
  } else if (
    extensionType[1] === 'jpeg' ||
    extensionType[1] === 'jpg' ||
    extensionType[1] === 'png'
  ) {
    icon = (
      <div className={styles.fileImageBlock}>
        <div className={styles.responsive}>
          <Image
            personId={bundle.personId}
            src={bundle.links[getBestTargetImageFormat(bundle.formats, 'SM')]}
            placeholder={bundle.base64DataUriString}
            height="100%"
            alt="Media Image"
            className={styles.imgCover}
          />
        </div>
      </div>
    );
  } else {
    icon = (
      <div className={styles.fileImageBlock}>
        <div className={styles.responsive}>
          <Video
            personId={bundle.personId}
            src={bundle.self}
            controls={false}
            height="100%"
            cover={true}
          />
        </div>
      </div>
    );
  }
  return icon;
};

export default PreviewIcon;
