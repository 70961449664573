import React from 'react';
import { withStyles, Grid, Icon } from '@material-ui/core';
import { CALENDAR_VIEW, LIST_VIEW } from './data';

const styles = {
  root: {
    marginTop: 28,
    borderRadius: 4,
    border: '1px solid #AEAEAE',
    cursor: 'pointer',
    width: 'fit-content',
  },
  divider: {
    width: 1,
    height: '100%',
    backgroundColor: '#AEAEAE',
  },
  icon: {
    color: '#AEAEAE',
  },
  iconActive: {
    color: '#fff',
  },
  item: {
    padding: '6px 8px',
  },
  itemActive: {
    padding: '6px 8px',
    backgroundColor: '#006AB0',
  },
};

const JournalViewToggles = ({ setView, classes, activeView }) => {
  const isActive = view => activeView === view;
  return (
    <div>
      <Grid container wrap="nowrap" className={classes.root}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          onClick={() => setView(LIST_VIEW)}
          className={isActive(LIST_VIEW) ? classes.itemActive : classes.item}
        >
          <Icon
            className={isActive(LIST_VIEW) ? classes.iconActive : classes.icon}
          >
            format_list_bulleted_24px
          </Icon>
        </Grid>
        <div />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          onClick={() => setView(CALENDAR_VIEW)}
          className={
            isActive(CALENDAR_VIEW) ? classes.itemActive : classes.item
          }
        >
          <Icon
            className={
              isActive(CALENDAR_VIEW) ? classes.iconActive : classes.icon
            }
          >
            date_range_24px
          </Icon>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(JournalViewToggles);
