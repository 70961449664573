import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputField from '../../../library/forms/InputField';
import { ajax } from '../../../library/helpers/fetch';
import { updateSyncErrors } from 'redux-form';
import { connect } from 'react-redux';

function ConfirmEmailField(props) {
  const {
    input: { onChange, onBlur, value, ...rest },
    updateSyncErrors,
  } = props;
  const [emailFieldValue, setEmailFieldValue] = useState(value);
  const [initialValue] = useState(value);
  const [confirmEmailValue, setConfirmEmailValue] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    updateSyncErrors(
      {
        email: error,
      },
      error
    );
    onBlur();
  }, [error]);

  const validateEmailAddress = async email => {
    const url = `/api/registration/email/unique`;
    const res = await ajax(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (res.status === 422) {
      setError('Email already exists');
    } else {
      setError(null);
      onChange(emailFieldValue);
    }
  };

  const handleChangeField = field => async event => {
    const value = event.target.value;
    switch (field) {
      case 'email':
        setEmailFieldValue(value);
        await validateEmailAddress(value);
        if (value !== confirmEmailValue) {
          setError('Email entered does not match');
        } else if (error) {
          await validateEmailAddress(value);
        }
        break;

      case 'confirmEmail':
        setConfirmEmailValue(value);
        if (value === emailFieldValue) {
          await validateEmailAddress(value);
        } else {
          setError('Email entered does not match');
        }
        break;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ display: 'flex', flexDirection: 'row' }}
      key="3"
    >
      <Grid item xs={6}>
        <InputField
          label="Email"
          type="text"
          key="1"
          value={emailFieldValue}
          disableUnderline
          whiteBG
          fullWidth
          {...props.meta}
          enableMinHeight={false}
          input={{
            onChange: handleChangeField('email'),
            ...rest,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {emailFieldValue && initialValue !== emailFieldValue ? (
          <InputField
            label="Confirm Email"
            type="text"
            key="2"
            whiteBG
            value={confirmEmailValue}
            disableUnderline
            fullWidth
            input={{
              error,
              onChange: handleChangeField('confirmEmail'),
              ...rest,
            }}
            meta={{ ...props.meta, error }}
            enableMinHeight={false}
          />
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateSyncErrors: (errors, error) =>
    dispatch(updateSyncErrors('new-dependent-form', errors, error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailField);
