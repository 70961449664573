import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import { getQueryStringParam } from '../../../library/helpers/url';

const STORAGE_ITEM_NAME = 'isAuthenticating';

const INITIAL = 'initial';
const SUCCESS = 'success';
const FAILURE = 'failure';

const CloseTabPage = () => {
  const [state, setState] = useState(INITIAL);

  useEffect(() => {
    const syncAuthorization = JSON.parse(
      window.localStorage.getItem('SYNC_AUTHORIZATION')
    );
    if (!syncAuthorization) return;

    if (syncAuthorization.isAuthenticating) {
      var param = getQueryStringParam('success');
      setState(param === 'true' ? SUCCESS : FAILURE);

      window.localStorage.setItem(
        'SYNC_AUTHORIZATION',
        JSON.stringify({
          ...syncAuthorization,
          isAuthenticating: false,
        })
      );

      window.dispatchEvent(new Event('storage'));
    }
  }, []);

  return components[state];
};

const AuthenticationSuccess = () => {
  return (
    <Paper
      elevation={5}
      style={{
        display: 'flex',
        height: '300px',
        maxWidth: '500px',
        width: '100%',
        margin: 'auto',
        marginTop: '48px',
        overflow: 'hidden',
      }}
    >
      <Grid container spacing={3} style={{ width: '100%', margin: '0px' }}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#006AB0',
          }}
        >
          <DoneAllIcon style={{ fontSize: '60px', color: '#ffffff' }} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            style={{ textAlign: 'center' }}
          >
            You successfully added sync permission.
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ textAlign: 'center', fontWeight: '300' }}
          >
            You may now close this page.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const AuthenticationFailure = () => {
  return (
    <Paper
      elevation={5}
      style={{
        display: 'flex',
        height: '300px',
        maxWidth: '500px',
        width: '100%',
        margin: 'auto',
        marginTop: '48px',
        overflow: 'hidden',
      }}
    >
      <Grid container spacing={3} style={{ width: '100%', margin: '0px' }}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#eea000',
          }}
        >
          <CloseIcon style={{ fontSize: '60px', color: '#ffffff' }} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            style={{ textAlign: 'center', color: '#eea000' }}
          >
            Failed to authenticate with service.
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ textAlign: 'center', fontWeight: '300', color: '#eea000' }}
          >
            Please try again later. You may now close this page.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const components = {
  [INITIAL]: 'Initialize...',
  [SUCCESS]: <AuthenticationSuccess />,
  [FAILURE]: <AuthenticationFailure />,
};

export default { component: withRouter(CloseTabPage) };
