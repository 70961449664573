import React from "react";
import PropTypes from "prop-types";
import { IconButton } from '@material-ui/core'
import {MyLocation, LocationDisabled} from "@material-ui/icons";

import Toggle from "./Toggle";

const ButtonWrapper = ({children, ariaLabel}) => 
  <IconButton aria-label={ ariaLabel }>
    { children }
  </IconButton>

const OnComponent = () =>
  <ButtonWrapper ariaLabel="Turn on location services">
    <MyLocation />
  </ButtonWrapper>

const OffComponent = () =>
  <ButtonWrapper ariaLabel="Turn off location services">
    <LocationDisabled />
  </ButtonWrapper>

const LocationButton = ({ onChange }) => (
  <Toggle
    onChange={onChange}
    OnComponent={OnComponent}
    OffComponent={OffComponent}
  />
);

LocationButton.propTypes = {
  onChange: PropTypes.func
};

export default LocationButton;
