import React, { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  Fade,
  Paper,
  Collapse,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import SmartText from './SmartText';
import { useStyles } from './useStyles';
import { renderImage } from './renderImage';

const ResourceBody = ({
  resource,
  handleOnClickLink,
  handleFavoriteClick,
  isSubmitting,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} style={{ width: '100%', margin: '0' }}>
      <Grid item xs={5}>
        <Paper
          style={{
            margin: '16px 0px 16px 16px',
            height: '100px',
            overflow: 'hidden',
          }}
          elevation={3}
        >
          {renderImage(resource.image, resource.synapseType)}
        </Paper>
      </Grid>
      <Grid item xs={5} className={classes.details}>
        <Typography variant="subtitle1" className={classes.location}>
          {resource.location}
        </Typography>
        <Typography variant="body2" className={classes.description}>
          {resource.subtitle.join(', ')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.cardControl}>
          <div className={classes.startIcon}>
            {isSubmitting ? (
              <IconButton
                size="small"
                aria-label="favorite"
                color="primary"
                style={{
                  height: '30px',
                }}
                disabled
              >
                <CircularProgress style={{ height: '1em', width: '1em' }} />
              </IconButton>
            ) : !!resource.isFavorite ? (
              <Tooltip title="Remove from Favorites" arrow placement="top">
                <IconButton
                  size="small"
                  aria-label="favorite"
                  onClick={handleFavoriteClick}
                  color="primary"
                >
                  <Fade
                    in={true}
                    style={{
                      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    }}
                  >
                    <Favorite style={{ color: '#ED5158' }} />
                  </Fade>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add to Favorites" arrow placement="top">
                <IconButton
                  size="small"
                  aria-label="remove-favorite"
                  onClick={handleFavoriteClick}
                  color="primary"
                >
                  <Fade in={true}>
                    <FavoriteBorder
                      style={{
                        color: '#0068A8',
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      }}
                    />
                  </Fade>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Link to resource" arrow placement="top">
              <IconButton
                size="small"
                aria-label="link"
                color="primary"
                onClick={handleOnClickLink}
              >
                <LinkIcon
                  style={{
                    color: '#0068A8',
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ background: '#F0F0F0', overflow: 'hidden' }}>
        <SmartText text={resource.description} />
      </Grid>
    </Grid>
  );
};

export default ResourceBody;
