import React from 'react';
import { Grid, Modal, CardMedia, Button, Typography } from '@material-ui/core';

const RemoveSyncPermissions = ({ bundle, handleClose, onClick }) => {
  const handleClick = () => {
    //TODO: circular progress
    onClick(bundle.id, 'remove');
    handleClose();
  };
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '450px',
        height: 'auto',
        background: '#fff',
        margin: 'auto',
        marginTop: '240px',
        border: '2px solid rgba(225, 241, 252)',
        padding: '40px',
      }}
    >
      <Grid
        container
        spacing={3}
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={{ fontWeight: '500' }}>
            Are you sure you want to remove this connection?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography>
            Data exchange will stop immediately, but data already exchanged
            cannot be undone. You can restore this connection at any time, but
            past data may not be available.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Button variant="contained" color="primary" onClick={handleClick}>
            Yes - Remove
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RemoveSyncPermissions;
