import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DependentListItemAvatar from './DependentListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import CakeIcon from '@material-ui/icons/Cake';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';

import AccentedPaper from '../../../library/components/AccentedPaper';
import { CardActionArea, Switch } from '@material-ui/core';
import { slug } from '../../../library/helpers/string';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: '500',
    lineHeight: '1.5',
    letterSpacing: '0.009375em',
    color: '#212121',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'left',
  },
  cover: {
    width: 121,
  },
  controls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '0px',
    transition: 'all .5s',
    '&:hover': {
      transition: 'all .5s',
      background: '#f5f5f5',
    },
  },
}));

function DependentListItem({ data, index, onChange, editDependent }) {
  const classes = useStyles();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const handleOnClick = () => {
    onChange(index, !data.isSelected);
    setShouldUpdate(true);
  };

  const handleOnEditClick = () => {
    const fullName = `${data.firstName} ${data.lastName} ${data.suffix ?? ''}`
      .trim()
      .toLowerCase();
    editDependent(slug(fullName), data.personId);
  };

  React.useEffect(() => {
    setShouldUpdate(false);
  }, [shouldUpdate]);

  const getDateOfBirth = () => {
    const dt = new Date(data.dob);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );
    return format(dtDateOnly, 'MMM d, yyyy');
  };

  return (
    <AccentedPaper
      elevation={data.isSelected ? 1 : 5}
      style={{
        maxWidth: '250px',
      }}
    >
      <Card
        className={classes.root}
        style={{
          boxShadow: 'none',
        }}
      >
        <CardContent className={classes.content}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} style={{ display: 'flex' }}>
              <DependentListItemAvatar
                index={index}
                firstName={data.firstName}
                lastName={data.lastName}
                avatarUrl={data.avatarUrl}
              />
              <div style={{ width: '100%' }}>
                <Typography
                  component="h6"
                  variant="h6"
                  style={{
                    fontSize: '16px',
                    width: '80%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data.firstName} {data.lastName}
                </Typography>
                {data.dob && (
                  <div style={{ display: 'flex' }}>
                    <CakeIcon
                      fontSize="small"
                      style={{ opacity: 0.5, marginRight: 3, fontSize: 15 }}
                    />
                    <Typography style={{ fontSize: 13 }}>
                      {getDateOfBirth()}
                    </Typography>
                  </div>
                )}
                {data.type && (
                  <div style={{ background: 'white' }}>
                    <Typography
                      style={{
                        fontSize: '13px',
                        background: 'white',
                      }}
                    >
                      {data.type}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <div
          style={{
            display: 'flex',
            border: '0',
            borderTop: '1px solid gray',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          <Tooltip title={data.isSelected ? 'Unpin Account' : 'Pin Account'}>
            <Switch
              checked={data.isSelected}
              onChange={() => handleOnClick()}
              aria-label={'Pin Account'}
            />
          </Tooltip>

          <Tooltip title={data.isSelected ? 'Edit Account' : ''}>
            <IconButton
              disabled={!data.isSelected}
              onClick={handleOnEditClick}
              aria-label="Edit Account"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/*
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            */}
        </div>
      </Card>
    </AccentedPaper>
  );
}

export default DependentListItem;
