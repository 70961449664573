import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import InputField from '../../../library/forms/InputField';
import PasswordField from '../../../library/forms/PasswordField';
import { renderFormInput } from '../../../library/forms/Field';
import { simpleEmailValidation } from '../../../library/validations/EmailAddress';

import signInPageStyles from '../../pages/SignInPage/SignInPage.css';
import { loginUser } from './actions';

const styles = theme => ({
  button: {
    width: 180,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forgotPassword: {
    width: 180,
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: theme.spacing(2) + 2,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  error: {
    backgroundColor: '#FBF5DD',
    color: '#887110',
    padding: theme.spacing(2),
    borderLeft: 'solid 4px #DAB305',
  },
});

const validate = values => {
  const errors = {};
  if (!values.email_address) {
    errors.email_address = 'Required';
  } else if (!simpleEmailValidation(values.email_address)) {
    errors.email_address = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};

class SignInFormProvider extends Component {
  componentDidMount() {
    // const token = JSON.parse(localStorage.getItem('token'));
    //   if (token !== null && token.access_token !== null) {
    //     this.props.goToDashboard()
    //   }
  }

  handleSubmit(event) {
    event.preventDefault();
    // this.props.userLogin({
    //    email_address: event.target.email_address.value,
    //    password: event.target.password.value
    // }).then(() => {
    //   this.props.history.push('/d/me');
    // });
  }

  render() {
    const { submitting, pristine, valid, classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Field
          name="email_address"
          type="text"
          label="Email Address"
          component={InputField}
        />
        <Field
          autoComplete="current-password"
          name="password"
          type="password"
          label="Password"
          component={PasswordField}
        />

        {/* { this.props.message && 
              <div className={ classes.error }>
                <WarningIcon className={ classes.icon } />
                {this.props.message}
              </div>} */}
        <div className={classes.buttonWrapper}>
          <Button color="primary" className={classes.forgotPassword}>
            Forgot password?
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            disabled={pristine || submitting || !valid}
          >
            Sign In
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  message: state.provider.login.message,
});

const mapDispatchToProps = dispatch => ({
  userLogin: credentials => dispatch(loginUser(credentials)),
  goToDashboard: () => dispatch(push('/d/me')),
});

const connForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignInFormProvider));

export default reduxForm({
  form: 'signin-form-provider',
  validate,
})(connForm);
