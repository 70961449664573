import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  csvToCodeObjectList,
  codeObjectListToCsv,
} from '../../../library/helpers/utils';

import NoteInput from '../NoteInput';
import SelectField from '../../../library/forms/SelectField';

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    // background: '#eee',
    fontSize: 18,
    fontWeight: 500,
    marginRight: theme.spacing(1),
    letterSpacing: '0.01071em',
  },
  divider: {
    marginTop: 24,
    marginBottom: 16,
  },
  container: {
    marginBottom: theme.spacing(1),
  },
  rootContainer: {
    padding: '25px',
    marginTop: '-25px',
  },
  iconContainer: {
    width: 28,
    height: 28,
    borderRadius: 40,
    marginRight: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expansionPanel: {
    boxShadow: 'none',
  },
  TitleFormField: {
    '& fieldset': {
      border: '2px solid rgba(0, 0, 0, 0.23)',
    },
  },
  expansionPanelSummary: {
    minHeight: '0',
    marginTop: '20px',
    padding: '0px',
  },
  expansionPanelSummaryExpanded: {
    minHeight: '0',
    margin: '0',
    padding: '0px',
    marginBottom: 32
  },
  expansionPanelTitle: {
    fontSize: '20px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
  additionalInfoForms: {
    flexDirection: 'column',
    marginTop: '-20px',
    padding: '0px',
  },
  expansionPanel: {
    boxShadow: 'none',
    marginTop: '0',
  },
});

const noop = () => {};

const SelectFieldIcon = () => (
  <AddCircleIcon style={{ marginTop: 2, color: '#90B850' }} />
);

const JournalDetailForm = props => {
  const { classes, DetailSlot, onChange, data } = props;

  const [tags, setTags] = React.useState(csvToCodeObjectList(data.tags));

  useEffect(() => {
    const options = csvToCodeObjectList(data.tags);
    setTags(options);
  }, [data.tags]);

  const handleChangeTags = options => {
    onChange({ ...data, tags: codeObjectListToCsv(options) });
  };

  const handleChangeJournalBody = event => {
    // setNote(event.body);
    // onChange({
    //   ...data,
    //   body: event.body,
    // });
  };

  const handleOnBlur = state => {
    onChange({
      ...data,
      body: state.body,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className={classes.expansionPanelTitle}>
              Additional Information
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails className={classes.additionalInfoForms}>
            {DetailSlot}
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
              <NoteInput
                rows={5}
                body={data.body}
                onChange={handleChangeJournalBody}
                onBlur={handleOnBlur}
              />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 8 }}>
              <SelectField
                name="tags"
                label="Tags"
                labelKey="shortName"
                valueKey="shortName"
                meta={{ touched: false, active: false, error: false }}
                options={tags}
                input={{
                  value: tags,
                  onChange: handleChangeTags,
                  onFocus: noop,
                  onBlur: noop,
                }}
                hint=" "
                shrink={true}
                isPreventingDuplicates={true}
                IconComponent={SelectFieldIcon}
                isSearchable
                isCreatable
                isMulti
              />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
};

JournalDetailForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  DetailSlot: PropTypes.object,
};

export default withStyles(styles)(JournalDetailForm);
