import React, { useState, useEffect } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import { Typography, TextField, InputAdornment, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from '../FragmentDialogBase';
import { maxLength, isPositive, required } from '../Validations';
import InputField from '../../../../../library/forms/InputField.jsx';
import ErrorTooltip from '../Forms/ErrorTooltip';
import { properties as fragmentProperties } from './properties';

const maxLength50 = maxLength(50);
const maxLength3 = maxLength(3);

const useStyles = makeStyles({
  formLabel: {
    fontSize: '12px',
  },

  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  rowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  rowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '2x',
  },
  header: {
    fontSize: '.9rem',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    marginTop: '20px',
    marginLeft: '11px',
  },
  textField: {
    color: '#0068A8',
    minHeight: '60px',
  },
  stressManagementContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  focused: {},
});

const InputFieldWithTooltip = props => {
  return (
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <InputField hasErrorTooltip={true} enableMinHeight={false} />
    </ErrorTooltip>
  );
};

const ReduxTextField = ({ input: { onChange, value }, ...props }) => (
  <TextField {...props} onChange={onChange} value={value} />
);

const RenderForm = () => (
  <Field
    name="activityDuration"
    component={ReduxTextField}
    validate={[maxLength3, isPositive, required]}
    type="number"
    normalize={value => {
      if (value > 999) return parseInt(value.slice(0, -1));
      if (value < 0) return 0;
      return parseInt(value);
    }}
    props={{
      placeholder: '0',
      margin: 'dense',
      inputProps: {
        style: {
          color: '#0068A8',
          textAlign: 'right',
          padding: '13px',
        },
        min: 1,
        max: 999,
      },
      InputProps: {
        style: {
          borderRadius: 4,
          border: '1px solid #0068A8',
          backgroundColor: '#EFF1F3',
        },
        disableUnderline: true,

        endAdornment: (
          <InputAdornment
            position="end"
            disableTypography
            style={{
              fontSize: '10px',
              color: '#0068A8 ',
              marginRight: '10px',
              lineHeight: '1.5',
            }}
          >
            min
          </InputAdornment>
        ),
      },
    }}
  />
);

const StressManagementDialog = ({ fragment }) => {
  const classes = useStyles();
  return (
    <FragmentDialogBase
      color="#2AA5A2"
      formTitle="Stress Management"
      FormSlot1={
        <Grid
          container
          spacing={0}
          className={classes.stressManagementContainer}
        >
          <Grid item xs={7}>
            <Field
              name="activityType"
              component={InputFieldWithTooltip}
              validate={[required, isPositive, maxLength50]}
              props={{
                label: 'Activity Type',
                inputProps: {
                  maxLength: 50,
                },
                multiline: true,
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.stressManagementFields}>
            <Typography className={classes.header}>Duration</Typography>
          </Grid>
          <Grid item xs={3} className={classes.stressManagementFields}>
            <RenderForm />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapStateToProps = state => ({
  initialValues: {
    ...fragmentProperties.dataFields,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? fragmentProperties.normalizeData(
          state.journalCompose.fragments[0].fragmentData
        )
      : {}),
  },
});

const StressManagementFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(StressManagementDialog));

export default connect(mapStateToProps)(StressManagementFormRedux);
