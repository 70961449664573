const treatments = [
  { shortName: 'Antihistamine' },
  { shortName: 'Medication' },
  { shortName: 'Doctor visit' },
  { shortName: 'Rest' },
];

export const REQUEST_TREATMENTS = 'REQUEST_TREATMENTS';
export const RECEIVE_TREATMENTS = 'RECEIVE_TREATMENTS';

export const TREATMENTS_REQUEST = '@@redux/api/TREATMENTS_REQUEST';
export const TREATMENTS_SUCCESS = '@@redux/api/TREATMENTS_SUCCESS';
export const TREATMENTS_FAILURE = '@@redux/api/TREATMENTS_FAILURE';

export const sleep = ms =>
  new Promise((resolve, reject) => setTimeout(resolve, ms));

export const requestTreatments = () => ({
  type: TREATMENTS_REQUEST,
});

export const receiveTreatments = data => ({
  type: TREATMENTS_SUCCESS,
  payload: data,
});

export const fetchTreatments = dispatch => dispatch => {
  dispatch(requestTreatments());
  return sleep(0).then(() => dispatch(receiveTreatments(treatments)));
};
