import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router';
import { getFormValues } from 'redux-form';

import { ModalHeader } from '..';
import { Grid, Button, Typography, Tooltip } from '@material-ui/core';
import ModalFooter from '../ModalFooter';

import NonProvider01Form from '../NonProvider01Form';
import Illustration from '../Illustration';
import NonProvider02Form from '../NonProvider02Form';
import NonProviderIllustration from '../../../../client/svgs/caretakers.svg';
import ProgressButtonLinear from '../../Buttons/ProgressButtonLinear/ProgressButtonLinear';

import FormWizard from '../../FormWizard';
import ReviewAccess from '../ReviewAccess';

export const FORM_NAME = 'care-team-add';

const NonProvider = props => {
  const {
    relationshipType,
    baseRoutePath,
    history,
    location,
    member,
    updateMember,
    isUpdating,
    dispatch,
    onSubmit,
    header,
    personId,
  } = props;

  const [formState01, setFormState01] = useState({
    valid: false,
  });

  const [formState02, setFormState02] = useState({
    valid: false,
    pristine: true,
    submitting: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!relationshipType) {
      history.replace(`${baseRoutePath}/modify`);
    }
  }, [relationshipType]);

  const links = [
    {
      label: 'Name',
      route: `${baseRoutePath}/modify/person/step-one`,
      current: `${baseRoutePath}/modify/person/step-one` === location.pathname,
      disableSecondaryNav: formState01.valid,
    },
    {
      label: 'Location',
      route: `${baseRoutePath}/modify/person/step-two`,
      current: `${baseRoutePath}/modify/person/step-two` === location.pathname,
      disableSecondaryNav: formState01.valid,
    },
  ];

  const hasRoleChanged = isUpdating
    ? relationshipType !== updateMember.relationshipType
    : false;

  const isDisabledNoAccess =
    !isUpdating &&
    !hasRoleChanged &&
    !formState02.valid &&
    formState02.pristine &&
    !formState02.submitting;
  // const isDisabledGrantAccess = isDisabledNoAccess || !(member && member.email);
  const isDisabledGrantAccess = true;

  const handleSubmit = event => {
    event.preventDefault();

    onSubmit(
      {
        relationshipType,
        hasAccess: true,
        ...member,
      },
      dispatch
    );
  };

  const handleSubmitWithoutAccess = () => {
    if (!isSubmitting) {
      setIsSubmitting(false);
      onSubmit(
        {
          relationshipType,
          hasAccess: false,
          ...member,
        },
        dispatch
      );
    }
  };

  const onClickReview = () => {
    history.push(`${props.baseRoutePath}/modify/person/review`);
  };

  const formAction01 = isUpdating ? 'Update' : 'Add';
  const formAction02 = isUpdating ? 'Edit' : 'Enter';

  const GrantAccessButton = p => (
    <div {...p}>
      <Button
        color="primary"
        variant="contained"
        onClick={onClickReview}
        disabled={isDisabledGrantAccess}
      >
        Review/Grant Access
      </Button>
    </div>
  );

  return (
    <Switch>
      <Route
        path={`${props.baseRoutePath}/modify/person/review`}
        render={childProps => (
          <ReviewAccess
            {...childProps}
            header={header}
            member={member}
            isUpdating={isUpdating}
            relationshipType={relationshipType}
            onClickGrantAccess={handleSubmit}
          />
        )}
      />
      <Route
        path={`${props.baseRoutePath}/modify/person/:other`}
        render={() => (
          <React.Fragment>
            <div>
              <ModalHeader
                header={header}
                subHeader={`2. ${formAction02} member's information`}
              />
              <Grid container spacing={5}>
                <Grid item xs={3}>
                  <Illustration svg={NonProviderIllustration} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="h6" style={{ marginBottom: 24 }}>
                    Tell us about your care team member
                  </Typography>
                  <FormWizard
                    formName={FORM_NAME}
                    baseRoutePath={`${props.baseRoutePath}/modify/person/`}
                    location={location}
                    onSubmit={() => {}}
                    initialValues={
                      isUpdating
                        ? {
                            prefix: updateMember.prefix,
                            firstName: updateMember.firstName,
                            lastName: updateMember.lastName,
                            email: updateMember.email,
                            phoneNumber: updateMember.phoneNumber,
                            labels: updateMember.labels,
                            street: updateMember.street,
                            street2: updateMember.street2,
                            city: updateMember.city,
                            state: updateMember.state,
                            postalCode: updateMember.postalCode,
                          }
                        : {}
                    }
                  >
                    {/** 
                      PersonId is used for async function which checks validates care member uniqueness.
                    */}
                    <NonProvider01Form
                      member={updateMember}
                      isUpdating={isUpdating}
                      routePath="step-one"
                      links={links}
                      onChangeForm={setFormState01}
                      personId={personId}
                    />
                    <NonProvider02Form
                      member={updateMember}
                      isUpdating={isUpdating}
                      routePath="step-two"
                      links={links}
                      onChangeForm={setFormState02}
                    />
                  </FormWizard>
                </Grid>
              </Grid>
            </div>
            <ModalFooter
              leftCol={
                <Button onClick={() => props.onClickGoBack()}>
                  {isUpdating ? 'Change Role' : 'Back'}
                </Button>
              }
              rightCol={
                <React.Fragment>
                  <Grid item>
                    <ProgressButtonLinear
                      color="primary"
                      variant="contained"
                      disabled={isDisabledNoAccess || isSubmitting}
                      isProgressing={isSubmitting}
                      onClick={handleSubmitWithoutAccess}
                    >
                      {formAction01} Without Access
                    </ProgressButtonLinear>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Coming soon" placement="top">
                      <GrantAccessButton />
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              }
            />
          </React.Fragment>
        )}
      />
    </Switch>
  );
};

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  member: getFormValues(FORM_NAME)(state),
});

export default connect(mapStateToProps)(withRouter(NonProvider));
