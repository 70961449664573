import { useEffect, useState } from 'react';
import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
} from '../../components/JournalCompose/FragmentsList';
import {
  subDays,
  addDays,
  subMonths,
  subWeeks,
  addMonths,
  subYears,
  isWithinInterval,
} from 'date-fns';

const CALORIES_TRACKER = 'CaloriesTracker';
export const dataFragments = [
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  CALORIES_TRACKER,
];

export const getChartByType = type => {
  switch (type) {
    case FRAGMENT_BODY_MASS: {
      return {
        name: 'Weight',
        fragmentType: FRAGMENT_BODY_MASS,
      };
    }
    case FRAGMENT_BLOOD_PRESSURE: {
      return {
        name: 'Blood Pressure',
        fragmentType: FRAGMENT_BLOOD_PRESSURE,
      };
    }
    case FRAGMENT_WATER_INTAKE: {
      return {
        name: 'Water Intake',
        fragmentType: FRAGMENT_WATER_INTAKE,
      };
    }
    case FRAGMENT_SLEEP_TRACKER: {
      return {
        name: 'Sleep',
        fragmentType: FRAGMENT_SLEEP_TRACKER,
      };
    }
    case FRAGMENT_MEAL_TRACKER: {
      return {
        name: 'Calories',
        fragmentType: CALORIES_TRACKER,
      };
    }
    case FRAGMENT_ACTIVITY_EXERCISE: {
      return {
        name: 'Calories',
        fragmentType: CALORIES_TRACKER,
      };
    }
    default:
      return null;
  }
};

export const parseCoordinateByType = (
  type,
  fragment,
  enteredOn,
  journalEntryId
) => {
  switch (type) {
    case FRAGMENT_BODY_MASS: {
      if (fragment.fragmentData.weight) {
        const weight =
          typeof fragment.fragmentData.weight === 'number'
            ? fragment.fragmentData.weight
            : parseFloat(fragment.fragmentData.weight);

        const bmi =
          typeof fragment.fragmentData.bmi === 'number'
            ? fragment.fragmentData.bmi
            : parseFloat(fragment.fragmentData.bmi);
        return {
          weight,
          journalEntryId,
          weightUnit: fragment.fragmentData.weightUnit,
          bmi,
          date: enteredOn,
        };
      }
      return;
    }
    case FRAGMENT_BLOOD_PRESSURE: {
      if (fragment.fragmentData.systolic && fragment.fragmentData.diastolic) {
        return {
          systolic:
            typeof fragment.fragmentData.systolic === 'number'
              ? fragment.fragmentData.systolic
              : parseFloat(fragment.fragmentData.systolic),
          diastolic:
            typeof fragment.fragmentData.diastolic === 'number'
              ? fragment.fragmentData.diastolic
              : parseFloat(fragment.fragmentData.diastolic),
          date: enteredOn,
          journalEntryId,
        };
      }
      return;
    }
    case FRAGMENT_WATER_INTAKE: {
      if (fragment.fragmentData.quantity) {
        const quantity =
          typeof fragment.fragmentData.quantity === 'number'
            ? fragment.fragmentData.quantity
            : parseFloat(fragment.fragmentData.quantity);
        return {
          quantity,
          unit: fragment.fragmentData.unit,
          date: enteredOn,
          journalEntryId,
        };
      }
      return;
    }
    case FRAGMENT_SLEEP_TRACKER: {
      if (fragment.fragmentData.totalTimeSlept) {
        const totalTimeSlept =
          typeof fragment.fragmentData.totalTimeSlept === 'number'
            ? fragment.fragmentData.totalTimeSlept
            : parseFloat(fragment.fragmentData.totalTimeSlept);
        return {
          totalTimeSlept,
          date: enteredOn,
          journalEntryId,
        };
      }
      return;
    }
    case FRAGMENT_MEAL_TRACKER: {
      const calories =
        fragment.fragmentData.manualCalories > 0
          ? fragment.fragmentData.manualCalories
          : fragment.fragmentData.totalCalories;
      if (calories) {
        enteredOn.setHours(0, 0, 0, 0);
        return {
          totalCalories:
            typeof calories === 'number' ? calories : parseFloat(calories),
          totalCaloriesUnit: fragment.fragmentData.totalCaloriesUnit,
          date: enteredOn,
          journalEntryId,
        };
      }
      return;
    }
    case FRAGMENT_ACTIVITY_EXERCISE: {
      const calories =
        parseFloat(fragment.fragmentData.manualCaloriesBurned) > 0
          ? fragment.fragmentData.manualCaloriesBurned
          : fragment.fragmentData.totalCaloriesBurned;
      if (calories) {
        enteredOn.setHours(0, 0, 0, 0);
        return {
          totalCalories:
            typeof calories === 'number' ? -calories : -parseFloat(calories),
          totalCaloriesUnit: fragment.fragmentData.totalCaloriesBurnedUnit,
          date: enteredOn,
          journalEntryId,
        };
      }
      return;
    }
    default:
      return;
  }
};

const defaultPastDate = subYears(new Date(), 10);
export const getDefaultDate = type => {
  switch (type) {
    case FRAGMENT_BODY_MASS:
      return {
        weight: 0,
        bmi: 0,
        date: defaultPastDate,
      };
    case FRAGMENT_BLOOD_PRESSURE:
      return {
        date: defaultPastDate,
        systolic: 0,
        diastolic: 0,
      };
    case FRAGMENT_WATER_INTAKE:
      return {
        date: defaultPastDate,
        quantity: 0,
      };
    case FRAGMENT_SLEEP_TRACKER:
      return {
        totalTimeSlept: 0,
        date: defaultPastDate,
      };
    default:
      return;
  }
};

const useParseJournal = (list, listIds, hasRecords, isFetching) => {
  const [data, setData] = useState({});
  const [isParsing, setIsParsing] = useState(true);

  useEffect(() => {
    if (!isFetching && hasRecords) {
      if (!isParsing) setIsParsing(true);
      const _data = {};
      // group records by fragmentType
      listIds.forEach(id => {
        if (list[id].fragments.length) {
          const coordinate = parseCoordinateByType(
            list[id].fragments[0].fragmentType,
            list[id].fragments[0],
            new Date(list[id].journalEnteredOn),
            list[id].fragments[0].journalEntryId
          );
          if (!coordinate) return;
          const chartType = getChartByType(list[id].fragments[0].fragmentType);
          if (!chartType) return;
          if (dataFragments.includes(chartType.fragmentType)) {
            if (_data[chartType.fragmentType])
              _data[chartType.fragmentType].data.push(coordinate);
            else {
              _data[chartType.fragmentType] = {
                ...chartType,
                data: [coordinate, getDefaultDate(chartType.fragmentType)],
              };
            }
          }
        }
      });
      setData(_data);
      setIsParsing(false);
    }
  }, [listIds]);

  return {
    isParsing,
    data,
  };
};

export default useParseJournal;
