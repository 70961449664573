import React, { useState } from 'react';
import EditMode from '../../../components/DocumentsDrawer/EditMode';
import ListMedia from './ListMedia';

const DocumentViews = props => {
  const [currentMediaFile, setCurrentMediaFile] = useState(null);
  const handleSelectMedia = m => setCurrentMediaFile(m);
  return Boolean(currentMediaFile) ? (
    <div
      style={{
        width: !props.matches550 ? '550px' : '100vw',
        padding: '24px',
      }}
    >
      <EditMode
        onSubmitUpdate={ee =>
          props.onUpdateMediaObject(
            props.personId,
            currentMediaFile.mediaObjectId,
            ee
          )
        }
        isSubmitting={props.userImmunizations.isSubmitting}
        handleOnDelete={props.handleOnDelete}
        personId={props.personId}
        currentMediaFile={currentMediaFile}
        onClickCancel={() => setCurrentMediaFile(null)}
        viewMode="EDIT"
        areaType="immunization"
      />
    </div>
  ) : (
    <ListMedia selectMediaObject={handleSelectMedia} {...props} />
  );
};

export default DocumentViews;
