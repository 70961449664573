import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  RESOURCE_REQUEST,
  RESOURCE_SUCCESS,
  RESOURCE_FAILURE,
  FAVORITE_RESOURCE_REQUEST,
  FAVORITE_RESOURCE_SUCCESS,
  FAVORITE_RESOURCE_FAILURE,
  FAVORITES_RESOURCE_REQUEST,
  FAVORITES_RESOURCE_SUCCESS,
  FAVORITES_RESOURCE_FAILURE,
  REMOVE_FAVORITE_RESOURCE_REQUEST,
  REMOVE_FAVORITE_RESOURCE_SUCCESS,
  REMOVE_FAVORITE_RESOURCE_FAILURE,
  CLEAR_ERRORS,
  SELECT_RESOURCE,
  RESOURCE_TYPES_SUCCESS,
  REDEEM_OFFER_REQUEST,
  REDEEM_OFFER_SUCCESS,
  REDEEM_OFFER_FAILURE,
  RECOMMENDED_RESOURCE_REQUEST,
  RECOMMENDED_RESOURCE_SUCCESS,
} from './actions';

import { SET_ACTIVE_USER } from '../active/actions';

const parseResource = (resource, favorites) => ({
  ...resource,
  id: resource.resourceId,
  image: resource.logo && resource.logo.imageUrl,
  imageBg: resource.logo && resource.logo.imageUrl,
  title: resource.name,
  description: resource.description,
  subtitle: resource.categories,
  link: resource.siteUrl,
  isFavorite: Boolean(favorites[resource.resourceId]),
  categories: resource.categories || [],
  location: getAddress(resource.locations[0]),
  tags: resource.tags || [],
  personResourceId: Boolean(favorites[resource.resourceId])
    ? favorites[resource.resourceId].personResourceId
    : null,
  synapseType: resource.type,
});
const getAddress = data => {
  if (!data) {
    return '';
  }
  if (Boolean(data.city) && Boolean(data.state)) {
    return `${data.city}, ${data.state}`;
  } else if (Boolean(data.state)) {
    return `${data.state}`;
  }
  return `${data.country}`;
};

const initialState = {
  isFetching: false,
  hasFetched: false,
  hasFetchedFavorites: false,
  hasRecords: false,
  favorites: {},
  recommendations: [],
  resourceList: {},
  listIds: [],
  selectedId: null,
  error: null,
  categories: [],

  deletingResource: [],
  submittingResource: [],

  resourceTypes: [],
  hasFetchedResourceTypes: false,
  submittingOffers: [],
  offerPanelError: null,
  offerPanelSuccessMessage: null,
};

const resourceLibraryReducer = (state = initialState, action) => {
  if (
    action.type !== SET_ACTIVE_USER &&
    Boolean(action.meta) &&
    action.meta.pid !== state.currUser
  )
    return state;

  switch (action.type) {
    case SET_ACTIVE_USER: {
      const nextState =
        action.payload.personId === state.currUser
          ? state
          : {
              ...initialState,
              currUser: action.payload.personId,
            };
      return nextState;
    }

    case RESOURCE_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
      };
    case RESOURCE_SUCCESS:
      // todo: figure out if API returns an id, set key to id
      const _resourceData = action.payload.map(resource =>
        parseResource(resource, state.favorites)
      );
      const resourceList = arrayToHashMap('id', _resourceData);
      const listIds = getKeys('id', _resourceData);
      let categories = [];
      let hasFavorites = Boolean(state.favorites.length);

      listIds.forEach(item => {
        if (resourceList[item].categories.length > 0) {
          resourceList[item].categories.forEach(cat => {
            if (cat === 'undefined' || !cat) return;
            if (!categories.includes(cat)) categories.push(cat);
          });
        }
      });

      return {
        ...state,
        resourceList,
        listIds,
        hasRecords: listIds.length > 0,
        isFetching: false,
        hasFetched: true,
        hasFavorites: hasFavorites,
        categories: categories,
      };

    case RESOURCE_FAILURE:
      return {
        ...initialState,
        error: 'Failed to fetch records',
        isFetching: false,
        hasRecords: false,
        hasFetched: true,
      };

    case RECOMMENDED_RESOURCE_REQUEST:
    case FAVORITES_RESOURCE_REQUEST: {
      return state;
    }
    case RECOMMENDED_RESOURCE_SUCCESS: {
      return {
        ...state,
        recommendations: action.payload.length
          ? action.payload
              .sort((a, b) => a.position - b.position)
              .map(item => item.libraryResource.resourceId)
          : [],
      };
    }
    case FAVORITES_RESOURCE_SUCCESS: {
      const favorites = {};
      action.payload.forEach(
        item =>
          (favorites[item.libraryResource.resourceId] = item.personResourceId)
      );
      const _resourceList = state.listIds.length
        ? arrayToHashMap(
            'id',
            state.listIds.map(id => {
              if (favorites[id])
                return {
                  ...state.resourceList[id],
                  isFavorite: true,
                  personResourceId: favorites[id],
                };
              return state.resourceList[id];
            })
          )
        : state.resourceList;
      return {
        ...state,
        favorites,
        resourceList: _resourceList,
        hasFetchedFavorites: true,
      };
    }
    case FAVORITE_RESOURCE_REQUEST:
      const updatedResource = {
        ...state.resourceList[action.payload.resourceId],
        isFavorite: true,
      };
      return {
        ...state,
        resourceList: {
          ...state.resourceList,
          [action.payload.resourceId]: updatedResource,
        },
        submittingResource: [
          ...state.submittingResource,
          action.payload.resourceId,
        ],
      };
    case FAVORITE_RESOURCE_SUCCESS:
      return {
        ...state,
        resourceList: {
          ...state.resourceList,
          [action.payload.libraryResource.resourceId]: {
            ...state.resourceList[action.payload.libraryResource.resourceId],
            personResourceId: action.payload.personResourceId,
          },
        },
        favorites: {
          ...state.favorites,
          [action.payload.libraryResource.resourceId]:
            action.payload.personResourceId,
        },
        submittingResource: [
          ...state.submittingResource.filter(
            x => x !== action.payload.libraryResource.resourceId
          ),
        ],
      };
    case FAVORITE_RESOURCE_FAILURE:
      const rid = JSON.parse(action.meta.config.body).resourceId;
      return {
        ...state,
        error: 'Failed to add resource to favorites',
        resourceList: {
          ...state.resourceList,
          [rid]: {
            ...state.resourceList[rid],
            personResourceId: null,
            isFavorite: false,
          },
        },
        favorites: {
          ...state.favorites,
          [rid]: null,
        },
        submittingResource: [
          ...state.submittingResource.filter(x => x !== rid),
        ],
      };

    case REMOVE_FAVORITE_RESOURCE_REQUEST: {
      return {
        ...state,
        deletingResource: [
          ...state.deletingResource,
          action.payload.resourceId,
        ],
      };
    }
    case REMOVE_FAVORITE_RESOURCE_SUCCESS: {
      return {
        ...state,
        favorites: {
          ...state.favorites,
          [action.meta.resourceId]: null,
        },
        resourceList: {
          ...state.resourceList,
          [action.meta.resourceId]: {
            ...state.resourceList[action.meta.resourceId],
            isFavorite: false,
          },
        },
        deletingResource: [
          ...state.deletingResource.filter(x => x !== action.meta.resourceId),
        ],
      };
    }
    case REMOVE_FAVORITE_RESOURCE_FAILURE: {
      return {
        ...state,
        error: 'Failed to remove resource to favorites',
        resourceList: {
          ...state.resourceList,
          [action.meta.config.payload.resourceId]: {
            ...state.resourceList[action.meta.config.payload.resourceId],
            personResourceId: action.meta.config.payload.personResourceId,
            isFavorite: true,
          },
        },
        favorites: {
          ...state.favorites,
          [action.meta.config.payload.resourceId]:
            action.meta.config.payload.personResourceId,
        },
        deletingResource: [
          ...state.deletingResource.filter(
            x => x !== action.meta.config.payload.resourceId
          ),
        ],
      };
    }

    case CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
        submittingOffers: [],
        offerPanelError: null,
        offerPanelSuccessMessage: null,
      };
    }

    case SELECT_RESOURCE: {
      return {
        ...state,
        selectedId: action.id,
        submittingOffers: [],
        offerPanelError: null,
        offerPanelSuccessMessage: null,
      };
    }

    case RESOURCE_TYPES_SUCCESS: {
      return {
        ...state,
        resourceTypes: action.payload,
        hasFetchedResourceTypes: true,
      };
    }
    case REDEEM_OFFER_REQUEST: {
      return {
        ...state,
        submittingOffers: [...state.submittingOffers, action.payload],
        offerPanelError: null,
        offerPanelSuccessMessage: null,
      };
    }

    case REDEEM_OFFER_FAILURE: {
      return {
        ...state,
        submittingOffers: state.submittingOffers.filter(
          item => item !== action.payload
        ),
        offerPanelError: 'Failed to redeem offer, please try again later.',
      };
    }

    case REDEEM_OFFER_SUCCESS: {
      return {
        ...state,
        submittingOffers: state.submittingOffers.filter(
          item => item !== action.payload.offerId
        ),
        offerPanelSuccessMessage:
          'Your offer was successfully added! Please check your email for further instructions on how to redeem offer.',
      };
    }

    default:
      return state;
  }
};

export default resourceLibraryReducer;
