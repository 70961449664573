import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { withRouter } from 'react-router';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import FragmentDialogBase from './FragmentDialogBase';
import InputField from '../../../../library/forms/InputField';
import ErrorTooltip from './Forms/ErrorTooltip';

import InfoIcon from '@material-ui/icons/Info';
import {
  FRAGMENT_BLOOD_PRESSURE,
  getFragmentProperties,
} from '../FragmentCollection';

export const BLOOD_PRESSURE_FIELDS = {
  systolic: '',
  diastolic: '',
};

// It's found that sometimes systolic and diastolic have string values rather than numbers
// We're unsure at the moment if this is due to tracking forms that are filled out by the
// mobile application. Regardless, these values should always be treated as a number
// and not a string
const fragmentProperties = getFragmentProperties(FRAGMENT_BLOOD_PRESSURE);

const validate = values => {
  const errors = {};
  if (!values.systolic) {
    errors.systolic = 'Required';
  } else if (values.systolic.length > 3) {
    errors.systolic = 'Must be 3 or less';
  } else if (values.systolic < 0) {
    errors.systolic = 'Must be a positive number';
  }

  if (!values.diastolic) {
    errors.diastolic = 'Required';
  } else if (values.diastolic.length > 3) {
    errors.diastolic = 'Must be 3 characters or less';
  } else if (values.diastolic < 0) {
    errors.diastolic = 'Must be a positive number';
  } else if (values.diastolic >= values.systolic) {
    errors.diastolic = 'Must be less than Systolic Pressure';
  }
  return errors;
};

const InputFieldWithTooltip = props => {
  return (
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <InputField hasErrorTooltip={true} enableMinHeight={false} />
    </ErrorTooltip>
  );
};
const BloodPressureDialog = () => {
  return (
    <FragmentDialogBase
      title="Blood Pressure Form"
      icon="show_chart"
      color="#3E47DD"
      FormSlot1={
        <Grid container spacing={2} style={{ marginTop: 24 }}>
          <Grid item xs={6}>
            <Field
              type="number"
              name="systolic"
              normalize={value => {
                if (value > 999) return parseInt(value.slice(0, -1));
                if (value < 0) return 0;
                return parseInt(value);
              }}
              component={InputFieldWithTooltip}
              props={{
                label: 'Systolic',
                endAdornment: (
                  <Tooltip
                    arrow
                    title={
                      <div>
                        <Typography
                          style={{
                            margin: '5px',
                            marginTop: '12px',
                            fontSize: '12px',
                          }}
                        >
                          Systolic pressure is the pressure of the blood in the
                          arteries when the heart pumps. It is the higher of two
                          blood pressure measurements.
                        </Typography>
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{
                        color: '#424242',
                      }}
                    />
                  </Tooltip>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              type="number"
              name="diastolic"
              normalize={value => {
                if (value > 999) return parseInt(value.slice(0, -1));
                if (value < 0) return 0;
                return parseInt(value);
              }}
              component={InputFieldWithTooltip}
              props={{
                label: 'Diastolic',
                endAdornment: (
                  <Tooltip
                    arrow
                    title={
                      <div>
                        <Typography
                          style={{
                            margin: '5px',
                            marginTop: '12px',
                            fontSize: '12px',
                            color: '#ffffff',
                          }}
                        >
                          Diastolic pressure is the pressure of the blood in the
                          arteries when the heart is filling. It is the lower of
                          two blood pressure measurements.
                        </Typography>
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{
                        color: '#424242',
                      }}
                    />
                  </Tooltip>
                ),
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => ({
  initialValues: {
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? fragmentProperties.normalizeData(
          state.journalCompose.fragments[0].fragmentData
        )
      : {}),
  },
});

const BloodPressureDialogRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
  validate,
})(withRouter(BloodPressureDialog));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BloodPressureDialogRedux);
