import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const RESOURCE_REQUEST = 'resourceLibrary/RESOURCE_REQUEST';
export const RESOURCE_SUCCESS = 'resourceLibrary/RESOURCE_SUCCESS';
export const RESOURCE_FAILURE = 'resourceLibrary/RESOURCE_FAILURE';

export const RECOMMENDED_RESOURCE_REQUEST =
  '@@redux/resourceLibrary/RECOMMENDED_RESOURCE_REQUEST';
export const RECOMMENDED_RESOURCE_SUCCESS =
  '@@redux/resourceLibrary/RECOMMENDED_RESOURCE_SUCCESS';
export const RECOMMENDED_RESOURCE_FAILURE =
  '@@redux/resourceLibrary/RECOMMENDED_RESOURCE_FAILURE';

export const FAVORITE_RESOURCE_REQUEST =
  '@@redux/resourceLibrary/FAVORITE_RESOURCE_REQUEST';
export const FAVORITE_RESOURCE_SUCCESS =
  '@@redux/resourceLibrary/FAVORITE_RESOURCE_SUCCESS';
export const FAVORITE_RESOURCE_FAILURE =
  '@@redux/resourceLibrary/FAVORITE_RESOURCE_FAILURE';

export const REMOVE_FAVORITE_RESOURCE_REQUEST =
  '@@redux/resourceLibrary/REMOVE_FAVORITE_RESOURCE_REQUEST';
export const REMOVE_FAVORITE_RESOURCE_SUCCESS =
  '@@redux/resourceLibrary/REMOVE_FAVORITE_RESOURCE_SUCCESS';
export const REMOVE_FAVORITE_RESOURCE_FAILURE =
  '@@redux/resourceLibrary/REMOVE_FAVORITE_RESOURCE_FAILURE';

export const FAVORITES_RESOURCE_REQUEST =
  'resourceLibrary/FAVORITES/RESOURCE_REQUEST';
export const FAVORITES_RESOURCE_SUCCESS =
  'resourceLibrary/FAVORITES/RESOURCE_SUCCESS';
export const FAVORITES_RESOURCE_FAILURE =
  'resourceLibrary/FAVORITES/RESOURCE_FAILURE';

export const REDEEM_OFFER_REQUEST = 'resourceLibrary/redeem_offer_request';
export const REDEEM_OFFER_SUCCESS = 'resourceLibrary/redeem_offer_success';
export const REDEEM_OFFER_FAILURE = 'resourceLibrary/redeem_offer_failure';

export const CLEAR_ERRORS = 'resourceLibrary/clear_error';
export const SELECT_RESOURCE = 'resourceLibrary/select_resource';

export const NOMINATE_RESOURCE_REQUEST =
  'resourceLibrary/nominate_resource_request';
export const NOMINATE_RESOURCE_SUCCESS =
  'resourceLibrary/nominate_resource_success';
export const NOMINATE_RESOURCE_FAILURE =
  'resourceLibrary/nominate_resource_failure';

export const RESOURCE_TYPES_REQUEST = 'resourceLibrary/nominate_types_request';
export const RESOURCE_TYPES_SUCCESS = 'resourceLibrary/nominate_types_success';
export const RESOURCE_TYPES_FAILURE = 'resourceLibrary/nominate_types_failure';

// export const resourceLibraryFetch = () => ({
//   //types: [RESOURCE_REQUEST, RESOURCE_SUCCESS, RESOURCE_FAILURE],
//   type: RESOURCE_SUCCESS,
// });

export const selectResource = id => ({
  type: SELECT_RESOURCE,
  id,
});

export const onCloseResourceLibrarySnackbar = () => ({
  type: CLEAR_ERRORS,
});

export const resourceLibraryFetch = () => {
  return async dispatch => {
    dispatch(getResourceLibrary());
    await dispatch(getResourceFavorites());
    dispatch(getRecommendedResources());
    return;
  };
};

export const getResourceLibraryTypes = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      RESOURCE_TYPES_REQUEST,
      RESOURCE_TYPES_SUCCESS,
      RESOURCE_TYPES_FAILURE,
    ],
    endpoint: `/api/synapse/library/resource/nominate/types`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
      },
    },
  },
});

export const getResourceLibrary = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [RESOURCE_REQUEST, RESOURCE_SUCCESS, RESOURCE_FAILURE],
    endpoint: `/api/synapse/library/resource`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
      },
    },
  },
});

export const getRecommendedResources = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      RECOMMENDED_RESOURCE_REQUEST,
      RECOMMENDED_RESOURCE_SUCCESS,
      RECOMMENDED_RESOURCE_FAILURE,
    ],
    endpoint: `/api/synapse/library/resource/recommendation`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
      },
    },
  },
});

export const getResourceFavorites = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      FAVORITES_RESOURCE_REQUEST,
      FAVORITES_RESOURCE_SUCCESS,
      FAVORITES_RESOURCE_FAILURE,
    ],
    endpoint: `/api/synapse/library/resource/favorites`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
      },
    },
  },
});

export const resourceLibraryFavorite = (id, method) => {
  return async dispatch => {
    if (method === 'POST') {
      dispatch(resourceLibraryFavoritePost(id));
    }
    if (method === 'DELETE') {
      dispatch(resourceLibraryFavoriteDelete(id));
    }
  };
};

export const resourceLibraryFavoritePost = id => {
  const payload = { resourceId: id };
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        FAVORITE_RESOURCE_REQUEST,
        FAVORITE_RESOURCE_SUCCESS,
        FAVORITE_RESOURCE_FAILURE,
      ],
      endpoint: `/api/synapse/library/resource/favorites`,
      payload,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(payload),
        },
      },
    },
  };
};

export const resourceLibraryFavoriteDelete = payload => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        REMOVE_FAVORITE_RESOURCE_REQUEST,
        REMOVE_FAVORITE_RESOURCE_SUCCESS,
        REMOVE_FAVORITE_RESOURCE_FAILURE,
      ],
      endpoint: `/api/synapse/library/resource/favorites/${payload.personResourceId}`,
      payload,
      meta: {
        resourceId: payload.resourceId,
        config: {
          method: 'DELETE',
          mode: 'cors',
          payload,
        },
      },
    },
  };
};

export const nominateResource = values => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        NOMINATE_RESOURCE_REQUEST,
        NOMINATE_RESOURCE_SUCCESS,
        NOMINATE_RESOURCE_FAILURE,
      ],
      endpoint: '/api/synapse/library/resource/nominate',
      body: JSON.stringify(values),
      meta: {
        config: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        },
      },
    },
  };
};

export const resourceLibraryRedeemOffer = id => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [REDEEM_OFFER_REQUEST, REDEEM_OFFER_SUCCESS, REDEEM_OFFER_FAILURE],
      endpoint: `/api/synapse/library/resource/redeem/${id}`,
      payload: id,
      meta: {
        config: {
          method: 'GET',
          mode: 'cors',
          offerId: id,
        },
      },
    },
  };
};
