import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, RoleSummaryDetailField } from '..';
import { Grid, Button } from '@material-ui/core';
import RoleButton from '../RoleButton';
import ROLES, {
  ROLE_NAME_GUARDIAN_ADDITIONAL,
  ROLE_NAME_PROVIDER,
} from './data';
import ModalFooter from '../ModalFooter';

// const RoleSummary = (props) => {
//   const [role, setRole] = useState(props.role || "")

//   useState(() => {}, [props.role])

//   const isActive = type => (role ? type.value === props.role : false)

//   const onSelectRole = () => {
//     console.log(role, props.role, '===>')
//     setRole(role.role);
//     props.onClickAddRole(role.value)
//   }

//   return (
//     <Fragment>
//       <div>
//         <ModalHeader
//           header="Add a care team member"
//           subHeader="1. Select a role"
//         />
//         <Grid container spacing={6}>
//           <Grid
//             item
//             container
//             xs={4}
//             spacing={2}
//             wrap="wrap"
//             alignContent="flex-start"
//           >
//             {ROLES.map(type => (
//               <RoleButton
//                 data={type}
//                 key={type.value}
//                 setRole={() => props.onClickSelectRole(type.value)}
//                 active={isActive(type)}
//               />
//             ))}
//           </Grid>
//           <Grid item xs={8}>
//             <RoleSummaryDetailField role={props.role} />
//           </Grid>
//         </Grid>
//       </div>
//       <ModalFooter
//         rightCol={
//           <Button color="primary" variant="contained" onClick={onSelectRole} disabled={!role}>
//             Set Role
//           </Button>
//         }
//       />
//     </Fragment>
//   )
// }

const RoleSummary = props => {
  const { loggedInUser, activeUser, member, isUpdating } = props;
  const [selectedRole, setSelectedRole] = useState(props.role);

  const onSelectRole = role => {
    setSelectedRole(role);
    props.onClickSelectRole(role);
  };

  const onAddRole = () => {
    props.onClickAddRole(selectedRole);
  };

  const isActive = type => (selectedRole ? type.value === selectedRole : false);

  return (
    <Fragment>
      <div>
        <ModalHeader header={props.header} subHeader="1. Select a role" />
        <Grid container spacing={5}>
          <Grid
            item
            container
            xs={4}
            spacing={2}
            wrap="wrap"
            alignContent="flex-start"
          >
            {ROLES.filter(type => {
              if (type.value === ROLE_NAME_GUARDIAN_ADDITIONAL) {
                return loggedInUser.personId !== activeUser.personId;
              }
              if (type.value === ROLE_NAME_PROVIDER && isUpdating && member) {
                if (member.relationshipType !== ROLE_NAME_PROVIDER) {
                  return false;
                }
                return true;
              }
              return true;
            }).map(type => (
              <RoleButton
                data={type}
                key={type.value}
                setRole={() => onSelectRole(type.value)}
                active={isActive(type)}
              />
            ))}
          </Grid>
          <Grid item xs={8}>
            <RoleSummaryDetailField role={selectedRole} />
          </Grid>
        </Grid>
      </div>
      <ModalFooter
        rightCol={
          <Button
            color="primary"
            variant="contained"
            onClick={onAddRole}
            disabled={!selectedRole}
          >
            Set Role
          </Button>
        }
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.app.user,
  activeUser: state.active.user,
});

export default connect(mapStateToProps)(RoleSummary);
