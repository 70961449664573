import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { setTimeZone } from '../../store/reducers/app/actions';
import { setActiveUser } from '../../store/reducers/active';
import { fetchPersonInfo } from '../../store/reducers/person/actions';
import styles from './DashboardMemberNav.css';

const DISABLED_FOR_SYNC_MESSAGE =
  'Sync is not currently available for dependents';
const DISABLED_FOR_PW_RESET = 'Account security is for the main User only.';

const CustomLink = withRouter(props => {
  let url;
  const onSyncPage = props.location.pathname.includes('sync-permissions');
  const onPasswordResetPage = props.location.pathname.includes(
    'account/security'
  );
  const isDependent = props.user.type === 'dependent';

  if (isDependent && (onSyncPage || onPasswordResetPage)) {
    return (
      <Tooltip
        placement="top"
        arrow
        title={onSyncPage ? DISABLED_FOR_SYNC_MESSAGE : DISABLED_FOR_PW_RESET}
      >
        <div>
          <Link className={props.styles.disabled} to={url}>
            {props.label}
          </Link>
        </div>
      </Tooltip>
    );
  }

  return (
    <Route
      path={url}
      exact={false}
      children={propsx => {
        const { pathname } = propsx.location;

        const userSlug =
          (pathname && pathname.match(/\/d\/([\w\d-]+)\//)) || [];
        const isActive =
          userSlug.length > 0 ? userSlug[1] === props.slug : false;

        url = pathname.replace(/\/d\/[\w\d-]+\//, `/d/${props.slug}/`);
        if (
          pathname.startsWith('/d/dependents') ||
          pathname.startsWith('/d/help')
        ) {
          url = `/d/${props.slug}/home`;
        } else if (pathname.search('/edit') >= 0) {
          url = url.replace('/edit', '');
        }

        return (
          <div onClick={props.onClick}>
            <Link className={isActive ? props.styles.active : ''} to={url}>
              {props.label}
            </Link>
          </div>
        );
      }}
    />
  );
});

class DashboardMemberNav extends React.Component {
  onNavButtonClick = (event, user) => {
    event.preventDefault();

    const { history } = this.props;
    const { pathname } = history.location;

    this.props.setActiveUser(user);
    this.props.fetchPersonInfo();

    const newUrl = pathname.replace(/\/d\/[\w\d-]+\//, `/d/${user.slug}/`);

    if (
      pathname.startsWith('/d/help') ||
      pathname.startsWith('/d/dependents')
    ) {
      this.props.history.push(`/d/${user.slug}/home`);
    } else {
      if (pathname.search('edit') >= 0) {
        const nextUrl = newUrl.replace('/edit', '');
        console.log(
          'Record cannot be edited when switching to another user',
          nextUrl
        );
        this.props.history.push(nextUrl);
      } else {
        this.props.history.push(newUrl);
      }
    }
  };

  renderDependentButton(user, isActive) {
    return (
      <li key={user.slug} className={styles.tab}>
        <a
          onClick={e => this.onNavButtonClick(e, user)}
          className={isActive ? styles.active : ''}
          href={`/d/${user.slug}`}
        >
          {user.label}
        </a>
      </li>
    );
  }

  render() {
    const { history, tabs } = this.props;
    console.log('Dashboard member nav ', styles);
    return (
      <div className={`row ${styles.rowExtendedRight}`}>
        <ul
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-4 ${styles.tabsWrap}`}
        >
          {tabs.map((tab, index) => {
            // const isDependentsViewActive = history.location.pathname.startsWith('/d/dependents')
            //   || history.location.pathname.startsWith('/d/help')
            // const isActive = !isDependentsViewActive && this.props.user.slug === tab.slug;
            // return this.renderDependentButton(tab, isActive);
            return (
              <li key={tab.slug + index} className={styles.tab}>
                <CustomLink
                  key={tab.slug + index + '-custom-link'}
                  label={tab.label}
                  slug={tab.slug}
                  styles={styles}
                  user={tab}
                  onClick={() => {
                    if (!this.props.isNavigationLocked) {
                      this.props.setActiveUser(tab);
                      this.props.fetchPersonInfo();
                    }

                    const timeZoneIANA = Intl.DateTimeFormat().resolvedOptions()
                      .timeZone;
                    this.props.setTimeZone({ timeZoneIANA });
                  }}
                />
              </li>
            );
          })}

          <li className={`${styles.tab} ${styles.help}`}>
            <NavLink to="/d/help" activeClassName={styles.active}>
              <i className="fa fa-question-circle"></i> Help&nbsp;
            </NavLink>
          </li>
          <li className={`${styles.tab} ${styles.noBorder} pull-right`}>
            <NavLink to="/d/dependents" activeClassName={styles.active}>
              <i className="fa fa-users"></i>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

DashboardMemberNav.propTypes = {
  memberName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: state.active.user,
  tabs: state.dependents.tabs,
  isNavigationLocked: state.ui.isNavigationLocked,
});

const mapDispatchToProps = dispatch => ({
  setActiveUser: user => dispatch(setActiveUser(user)),
  fetchPersonInfo: () => dispatch(fetchPersonInfo()),
  setTimeZone: payload => dispatch(setTimeZone(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardMemberNav)
);
