import {
  DOCUMENTS_REQUEST,
  DOCUMENTS_SUCCESS,
  DOCUMENTS_FAILURE,
  DOCUMENTS_UPDATE_REQUEST,
  DOCUMENTS_UPDATE_SUCCESS,
  DOCUMENTS_UPDATE_FAILURE,
  DOCUMENTS_CREATE_REQUEST,
  DOCUMENTS_CREATE_SUCCESS,
  DOCUMENTS_CREATE_FAILURE,
  DOCUMENTS_DELETE_REQUEST,
  DOCUMENTS_DELETE_SUCCESS,
  DOCUMENTS_DELETE_FAILURE,
  DOCUMENTS_DRAWER_TOGGLE,
  DOCUMENTS_CLEAR_MESSAGE,
  DOCUMENTS_CLEAR_UPDATES,
} from './actions';
import { SET_ACTIVE_USER } from '../active/actions';
import { DOCUMENT_TYPE_SUCCESS, DOCUMENT_TYPE_FAILURE } from '../codes/actions';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
import { DRAWER_STATUS_CLOSED } from '../mediaObjectDrawer/actions';

const GENERAL_ERROR_MESSAGE =
  "We're sorry, this service is temporarily unavailable. Please try again later. If the problem persists, please contact support at support@agrinhealth.com";

function fetchCategoryOptions(documentCat) {
  const arr = [];
  documentCat.forEach(cat => {
    arr.push({
      longName: cat.longName,
      shortName: cat.shortName,
    });
  });
  return arr;
}

const initialState = {
  activeUser: null,

  isFetching: false,
  isSubmitting: false,

  hasFetched: false,
  hasRecords: false,

  hasError: false,
  error: undefined,
  successMessage: undefined,

  isSubmitting: false,
  hasSubmitted: false,

  isDrawerOpen: false,
  newId: null,
  hasDeleted: false,
  list: {},
  listIds: [],

  categoryOptions: [],
  hasCategoryOptions: false,
};

const reducers = [];

reducers[DOCUMENTS_CLEAR_UPDATES] = (state, action) => {
  return {
    ...state,
    newId: null,
    hasDeleted: false,
    hasSubmitted: false,
  };
};

reducers[DOCUMENTS_CLEAR_MESSAGE] = (state, action) => {
  return {
    ...state,
    error: undefined,
    successMessage: undefined,
  };
};

reducers[DOCUMENTS_DRAWER_TOGGLE] = (state, action) => {
  let clearMessage = {};
  if (state.isDrawerOpen === false)
    clearMessage = {
      //clear message when drawer is opened
      hasError: false,
      error: undefined,
      successMessage: undefined,
    };
  return {
    ...state,
    isDrawerOpen: action.payload,
    ...clearMessage,
  };
};

reducers[DOCUMENTS_REQUEST] = (state, action) => {
  return {
    ...initialState,
    isFetching: true,
    activeUser: state.activeUser,
    hasCategoryOptions: state.hasCategoryOptions,
    categoryOptions: state.categoryOptions,
  };
};

reducers[DOCUMENTS_SUCCESS] = (state, action) => {
  const formattedPayload = action.payload.map(doc => {
    if (doc.applicationArea === 'DOCUMENTS') return doc;
    else
      return {
        ...doc,
        category:
          doc.applicationArea === 'IMMUNIZATION'
            ? 'Vaccination Records'
            : doc.category,
        displayName: doc.displayName ? doc.displayName : doc.fileName,
      };
  });
  console.log(formattedPayload);
  const listIds = getKeys(
    'mediaObjectId',
    formattedPayload.filter(x => x && x.personId === action.meta.pid)
  );
  const list = arrayToHashMap('mediaObjectId', formattedPayload || []);
  return {
    ...state,
    isFetching: false,
    hasRecords: listIds.length > 0,
    hasFetched: true,

    list: list,
    listIds: listIds,
  };
};

reducers[DOCUMENTS_FAILURE] = (state, action) => {
  return {
    ...state,
    isFetching: false,

    hasFetched: true,
    hasRecords: false,

    hasError: true,
    error: GENERAL_ERROR_MESSAGE,
  };
};

reducers[DOCUMENTS_CREATE_REQUEST] = (state, action) => {
  return {
    ...state,

    isSubmitting: true,
    hasSubmitted: false,
    error: undefined,
    successMessage: false,
    hasError: false,
  };
};

reducers[DOCUMENTS_CREATE_SUCCESS] = (state, action) => {
  return {
    ...state,
    listIds: [action.payload.mediaObjectId, ...state.listIds],
    list: {
      ...state.list,
      [action.payload.mediaObjectId]: {
        ...action.data,
        ...action.payload,
      },
    },

    hasRecords: true,
    newId: action.payload.mediaObjectId,
    isSubmitting: false,
    hasSubmitted: true,
    successMessage: state.hasFetched ? 'Successfully added document!' : null,
  };
};

reducers[DOCUMENTS_CREATE_FAILURE] = (state, action) => {
  return {
    ...state,
    hasError: true,
    isSubmitting: false,
    error: GENERAL_ERROR_MESSAGE,
  };
};

reducers[DOCUMENTS_UPDATE_REQUEST] = (state, action) => {
  return {
    ...state,

    isSubmitting: true,
    hasSubmitted: false,

    error: undefined,
    successMessage: false,
    hasError: false,
  };
};

reducers[DOCUMENTS_UPDATE_SUCCESS] = (state, action) => {
  const did = action.payload.mediaObjectId;
  return {
    ...state,
    list: {
      ...state.list,
      [did]: {
        ...state.list[did],
        ...action.payload,
      },
    },
    listIds: [...state.listIds.sort((x, y) => (x == did ? -1 : 1))],

    newId: did,
    isSubmitting: false,
    hasSubmitted: true,
    successMessage: state.hasFetched ? 'Successfully updated document!' : null,
  };
};

reducers[DOCUMENTS_UPDATE_FAILURE] = (state, action) => {
  return {
    ...state,
    isSubmitting: false,
    error: GENERAL_ERROR_MESSAGE,
  };
};

reducers[DOCUMENTS_DELETE_REQUEST] = (state, action) => {
  return {
    ...state,

    hasDeleted: false,
    isSubmitting: true,
    hasSubmitted: false,

    error: undefined,
    successMessage: false,
    hasError: false,
  };
};

reducers[DOCUMENTS_DELETE_SUCCESS] = (state, action) => {
  const id = action.payload.mediaObjectId;
  const newListIds = state.listIds.filter(x => x != id);
  return {
    ...state,
    listIds: newListIds,
    isSubmitting: false,
    hasSubmitted: true,

    hasRecords: Boolean(newListIds.length),
    isDrawerOpen: false,
    hasDeleted: true,
    successMessage: state.hasFetched ? 'Successfully deleted document!' : null,
  };
};

reducers[DOCUMENTS_DELETE_FAILURE] = (state, action) => {
  return {
    ...state,
    isSubmitting: false,
    hasSubmitted: true,
    error: GENERAL_ERROR_MESSAGE,
    isDrawerOpen: false,
  };
};

reducers[DRAWER_STATUS_CLOSED] = (state, action) => {
  return {
    ...state,

    isSubmitting: false,
    hasSubmitted: false,
  };
};

reducers[DOCUMENT_TYPE_SUCCESS] = (state, action) => {
  return {
    ...state,

    hasCategoryOptions: true,
    categoryOptions: fetchCategoryOptions(action.payload),
  };
};

reducers[DOCUMENT_TYPE_FAILURE] = (state, action) => {
  return {
    ...state,

    hasCategoryOptions: false,
    hasError: true,
    error: GENERAL_ERROR_MESSAGE,
    categoryOptions: [],
  };
};

reducers[SET_ACTIVE_USER] = (state, action) => {
  // when component mounts with a dependent selected,
  // state.active.user.personId is first set to the ME user
  // and then set to the dependent user once the SPA mounts
  // using the SET_ACTIVE_USER action, which causes the fetch function to fire
  // twice for both user personIds. When ME is selected when component mounts,
  // component mounts with ME user and the fires SET_ACTIVE_USER
  // making sure this reducer always fires.

  // sets a personId reference in this branch of the state to prevent
  // fetch before SET_ACTIVE_USER is fired
  const currentPersonId = action.payload.personId;
  const currentState =
    state.activeUser === currentPersonId
      ? state
      : {
          ...initialState,
          activeUser: action.payload.personId,
          hasCategoryOptions: state.hasCategoryOptions,
          categoryOptions: state.categoryOptions,
        };
  return {
    ...currentState,
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
