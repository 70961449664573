import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  location: {
    fontSize: '14px',
    color: '#75747B',
  },
  description: {
    fontSize: '14px',
    color: '#0068A8',
    fontStyle: 'italic',
  },
  notes: {
    fontSize: '14px',
    paddingTop: '5px',
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  isFavorite: {
    background: '#90B850',
    border: '1px solid #0068A8',
  },
  isDeleting: {
    opacity: '.6',
  },
  expansionDetails: {
    padding: '16px',
  },
  cardControl: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  startIcon: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 5px',
  },
  lineClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  noClamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': 'none',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  media: {
    height: '100%',
    width: '100%',
    backgroundColor: '#bdbdbd',
    display: 'Flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
  },
  CroppedIcon: {
    color: '#ffffff',
    height: '65px',
    width: '65px',
  },

  padding16: {
    padding: '16px',
  },
  showLessButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '-18px',
  },
  showlessButtonBase: {
    margin: '8px 0px',
  },
  showLessButtonTypography: {
    fontSize: '14px',
    fontWeight: '400',
  },
  lineClamp2: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
});
