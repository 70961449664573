import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import validate from './ValidateForm';
import submit from './SubmitForm';

import { cancelForm } from '../../../store/reducers/ui/actions';
import { clearFiles } from '../../../store/reducers/mediaObject';
import { Grid } from '@material-ui/core';
import InputField from '../../../library/forms/InputField';
import ProgressButtonLinear from '../../components/Buttons/ProgressButtonLinear';
import SelectField from '../../../library/forms/SelectField';
import Button from '@material-ui/core/Button';
import { mask } from '../../../library/helpers/format';
import { format, parseISO } from 'date-fns';
import { LOADING } from '../../../store/state';

const formatSsn = value => mask(value, 'XXX-XX-XXXX');
const dateFormat = 'yyyy-MM-dd';
const relationshipOptions = [
  {
    longName: 'Self',
    shortName: 'Self',
  },
  {
    longName: 'Spouse',
    shortName: 'Spouse',
  },
  {
    longName: 'Father',
    shortName: 'Father',
  },
  {
    longName: 'Mother',
    shortName: 'Mother',
  },
  {
    longName: 'Grandfather',
    shortName: 'Grandfather',
  },

  {
    longName: 'Grandmother',
    shortName: 'Grandmother',
  },
  {
    longName: 'Aunt',
    shortName: 'Aunt',
  },
  {
    longName: 'Uncle',
    shortName: 'Uncle',
  },
  {
    longName: 'Other',
    shortName: 'Other',
  },
];

class InsuranceForm extends Component {
  state = {
    show: false,
    startDate: null,
  };

  componentDidMount() {
    this.props.clearFiles();
  }

  componentWillUnmount() {
    this.props.clearFiles();
  }

  onCancel = () => {
    const { cancelForm, onCancel } = this.props;

    cancelForm();
    clearFiles();

    if (onCancel) {
      onCancel();
    }
  };

  onSelectedCard = face => {
    this.setState({ show: true, face });
  };

  handleClose = () => {
    this.setState({ show: false, face: '' });
  };

  onStartDateChange = date => {
    this.setState({ startDate: date });
  };

  validateIsPrimary = (data, allValues) => {
    if (
      data === 'Primary' &&
      this.props.primaryInsurance.includes(allValues.coverageType)
    ) {
      return 'You can only have 1 active insurance policy assigned to each place in the coverage order for each type of insurance. For example, only 1 medical insurance can be Primary (the first insurance to be billed) and only 1 dental insurance can be Primary, etc';
    }
    return '';
  };

  render() {
    const { handleSubmit, coverageTypes, action } = this.props;
    const entityAction = action === 'CREATE' ? 'Create' : 'Update';

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="healthPlanName"
              label="Carrier / Health Plan"
              hint="Required"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="coverageType"
              label="Coverage Type"
              labelKey="longName"
              valueKey="shortName"
              hint="Required"
              options={coverageTypes}
              component={SelectField}
              isSearchable
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="date"
              id="date"
              name="coverageStartOn"
              label="Policy Start Date"
              hint="Required"
              shrink={true}
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="date"
              id="date"
              name="coverageEndOn"
              label="Policy End Date"
              shrink={true}
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="coverageOrder"
              label="Coverage Order"
              component={SelectField}
              labelKey="name"
              valueKey="name"
              options={[
                {
                  name: 'Primary',
                },
                {
                  name: 'Secondary',
                },
                {
                  name: 'Tertiary',
                },
                {
                  name: 'Quaternary',
                },
                {
                  name: 'Unknown',
                },
              ]}
              defaultValue="Unknown"
              validate={this.validateIsPrimary}
              hint="Coverage Order helps your Provider determine which insurance to bill first (Primary), then second (Secondary), the third (Tertiary). etc"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="guarantorNumber"
              label="Guarantor Number"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="guarantorName"
              label="Guarantor Name"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="relationshipToInsured"
              label="Relationship to Insured"
              component={SelectField}
              options={relationshipOptions}
              labelKey="longName"
              valueKey="shortName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="guarantorSsn"
              label="Guarantor SSN"
              placeholder={`XXX-XX-XXXX`}
              format={formatSsn}
              inputProps={{ maxLength: 11 }}
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="policyNumber"
              label="Policy #"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="groupNumber"
              label="Group #"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="rxBin"
              label="RX Bin"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="rxGroup"
              label="RX Group"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="planNumber"
              label="Plan Number"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="subPlanNumber"
              label="Sub Plan Name"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="number"
              name="dependentNumber"
              label="Dependent Number"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="number"
              name="sequenceNumber"
              label="Sequence Number"
              component={InputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              type="text"
              name="networkName"
              label="Network Name"
              component={InputField}
            />
          </Grid>
        </Grid>
        {this.props.insuranceCardSlot}
        <Grid
          container
          style={{ marginTop: 24 }}
          justify="flex-end"
          spacing={3}
        >
          <Grid item xs={6} sm={2}>
            <Button
              type="button"
              fullWidth={true}
              color="default"
              onClick={this.onCancel}
              size="large"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <ProgressButtonLinear
              variant="contained"
              type="submit"
              disabled={
                ((!this.props.valid || this.props.pristine) &&
                  !this.props.hasNewInsuranceImages) ||
                this.props.isSubmitting
              }
              fullWidth={true}
              color="primary"
              size="large"
              isProgressing={this.props.isSubmitting}
            >
              {entityAction}
            </ProgressButtonLinear>
          </Grid>
        </Grid>
      </form>
    );
  }
}

InsuranceForm.propTypes = {
  coverageTypes: PropTypes.array.isRequired,
};

InsuranceForm = reduxForm({
  form: 'insurance-form',
  validate,
  onSubmit: submit,
  touchOnChange: true,
})(withRouter(InsuranceForm));

const mapStateToProps = state => {
  const { list, selectedId, listIds } = state.member.insurance;
  const insurance = selectedId ? list[selectedId] : {};
  return {
    action: state.ui.entity.action,
    isSubmitting: state.member.insurance.uiState === LOADING,
    primaryInsurance: listIds
      .filter(
        _insurance =>
          _insurance !== selectedId &&
          list[_insurance].coverageOrder === 'Primary'
      )
      .map(_insurance => list[_insurance].coverageType),
    initialValues: insurance
      ? {
          insuranceId: insurance.insuranceId || '',
          personId: insurance.personId || '',
          healthPlanName: insurance.healthPlanName || '',
          coverageStartOn: insurance.coverageStartOn
            ? format(parseISO(insurance.coverageStartOn), dateFormat)
            : '',
          coverageEndOn: insurance.coverageEndOn
            ? format(parseISO(insurance.coverageEndOn), dateFormat)
            : '',
          coverageType: insurance.coverageType || '',
          coverageOrder: insurance.coverageOrder || '',
          guarantorNumber: insurance.guarantorNumber || '',
          guarantorName: insurance.guarantorName || '',
          guarantorSsn: insurance.guarantorSsn || '',
          policyNumber: insurance.policyNumber || '',
          groupNumber: insurance.groupNumber || '',
          rxBin: insurance.rxBin || '',
          rxGroup: insurance.rxGroup || '',
          planNumber: insurance.planNumber || '',
          subPlanNumber: insurance.subPlanNumber || '',
          dependentNumber: insurance.dependentNumber || '',
          networkName: insurance.networkName || '',
          sequenceNumber: insurance.sequenceNumber || '',
          relationshipToInsured: insurance.relationshipToInsured || '',
        }
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  cancelForm: () => dispatch(cancelForm()),
  clearFiles: () => dispatch(clearFiles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceForm);
