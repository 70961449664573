// import moment from 'moment'

export default values => {
  const errors = {};

  if (
    values.surgery === undefined ||
    (values.surgery && values.surgery.trim().length === 0)
  ) {
    errors.surgery = 'Required';
  }

  return errors;
};
