export default values => {
  const errors = {};

  const required = ['verificationCode'];

  required.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.verificationCode && values.verificationCode.length < 6) {
    errors.verificationCode = `Verification code is invalid`;
  }

  return errors;
};
