import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from '../styles/MediaObjectDrawer.css';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';
import {
  getMedia,
  deleteMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import Image from '../../Image';
import Video from '../../Video';
import CustomFrame from '../../iFrame';

import {
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_VIDEO_MP4,
  isImageMimeType,
  isVideoMimeType,
} from '../../../../library/helpers/mimeTypes';

class MediaCustomDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMediaFile: this.props.currentMediaFile,
      currentKey: '',
      isResponseSuccess: false,
      isDeleting: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentKey: new Date(),
    });
  }

  // function to close dialog box
  closeDialogBox = () => {
    this.props.dialogMode(false, '', '');
  };

  // Delete media object
  removeFile = async () => {
    this.setState({ isDeleting: true });

    const personId = this.props.activeUser.personId;
    const objectId = this.props.primaryId;
    const areaType = this.props.areaType;
    await this.props.deleteMedia(
      this.state.currentMediaFile.mediaObjectId,
      objectId,
      areaType.toUpperCase()
    );

    if (this.props.isRemoveMedia) {
      // this.props.getMedia(personId, primaryId, areaType.toLowerCase());
      this.props.drawerDisplayMode('list');
      this.props.dialogMode(false, '', '');
    }

    this.setState({ isDeleting: false });
  };

  render() {
    // #containerSection variable is consisting of media object to be preview in iframe
    let containerSection = '';

    if (this.props.dialogType !== 'delete') {
      if (this.state.currentMediaFile.mimeType === MIME_TYPE_APPLICATION_PDF) {
        containerSection = (
          <CustomFrame
            personId={this.props.activeUser.personId}
            src={this.props.currentMediaFile.links.self}
          />
        );
      } else if (isVideoMimeType(this.state.currentMediaFile.mimeType)) {
        containerSection = (
          <Video
            control={true}
            personId={this.props.activeUser.personId}
            src={this.props.currentMediaFile.links.self}
            className={styles.videoThumb}
          />
        );
      } else if (isImageMimeType(this.state.currentMediaFile.mimeType)) {
        containerSection = (
          <Image
            personId={this.props.activeUser.personId}
            placeholder={this.props.currentMediaFile.base64DataUriString}
            src={this.props.currentMediaFile.links.self}
            className={styles.imgCover}
            height="100%"
            alt="Media Image"
          />
        );
      }
    }

    return (
      // There are two dialog box type
      // 1) DELETE : To delete any media object file
      // 2) PREVIEW : Preview any media object file in dialog box
      <React.Fragment>
        {this.props.dialogType == 'delete' ? (
          <Dialog
            key={this.state.currentKey}
            open={true}
            onClose={this.closeDialogBox}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.deleteDialogHolder}
            maxWidth="sm"
            variant="fullWidth"
          >
            <DialogTitle className={styles.dialogTitle} id="alert-dialog-title">
              {'Confirm Delete'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                className={styles.dialogTxt}
                id="alert-dialog-description"
              >
                Are you sure you want to delete this file?
              </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.dialogBtns}>
              <Button
                onClick={this.closeDialogBox}
                variant="contained"
                size="medium"
                disabled={this.state.isDeleting}
              >
                Cancel
              </Button>
              <Button
                onClick={this.removeFile}
                //color="primary"
                variant="contained"
                size="medium"
                autoFocus
                className={styles.deleteBtn}
                disabled={this.state.isDeleting}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={true}
            key={this.state.currentKey}
            onClose={this.closeDialogBox}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.deleteDialogHolder}
            maxWidth="lg"
            //fullScreen={true}
            fullWidth
            //variant="fullWidth"
          >
            <div className={styles.dialogHolder}>
              <React.Fragment>
                <Tooltip title="Close">
                  <div
                    className={styles.overviewBackBtn}
                    onClick={this.closeDialogBox}
                  >
                    <CloseIcon />
                  </div>
                </Tooltip>
              </React.Fragment>
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={0}
                variant="fullWidth"
              >
                <Tab label="MEDIA VIEWER" />
              </Tabs>
              <div className={styles.dialogContentHolder}>
                {containerSection}
              </div>
            </div>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  mediaCollection: state.mediaObjectDrawer.mediaCollection,
  activeUser: state.active.user,
  isRemoveMedia: state.mediaObjectDrawer.isRemoveMedia,
});

const mapDispatchToProps = dispatch => ({
  getMedia: (pid, aid, area) => dispatch(getMedia(pid, aid, area)),
  deleteMedia: (pid, aid, appArea) => dispatch(deleteMedia(pid, aid, appArea)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaCustomDialog);
