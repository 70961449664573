import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  fontSize14: {
    fontSize: '14px',
  },
  fontSize18: {
    fontSize: '18px',
  },
  marginLeft8: {
    marginLeft: '8px',
  },
  titleContainerDiv: {
    display: 'flex',
    alignItems: 'center',
  },

  //pricing
  pricingContainer: {
    margin: '0',
    width: '100%',
    padding: '16px',
  },
  pricingRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pricesRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80px',
  },

  //offers
  offersContainerDiv: {
    margin: '0',
    width: '100%',
    paddingBottom: '16px',
  },
  offersGridItem: {
    margin: '0px',
    marginTop: '16px',
  },
  offersPaper: {
    padding: '16px 24px',
    background: '#F0F0F0',
  },
  offerDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  offerTitle: {
    fontSize: 16,
    fontWeight: 700,
  },
  offerDetails: {
    fontSize: 14,
    marginTop: 8,
  },
});
