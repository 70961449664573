import { combineReducers } from 'redux';
import {
  reducer as formReducer,
  actionTypes as formActionTypes,
} from 'redux-form';
import { connectRouter } from 'connected-react-router';

import appReducer from './reducers/app/reducers';
import dependentsReducer from './reducers/dependents/reducers';

import activeReducer from './reducers/active/reducers';
import memberReducer from './reducers/member.reducer';
import providerReducer from './reducers/member.reducer';
import codeReducer from '../store/reducers/codes';
import progressBarReducer from '../store/reducers/progressbar';
import userInsuranceReducer from '../shared/pages/InsurancePolicyPage/reducers';
import flashMessageReducer from './reducers/flashMessenger/reducers';
import emergencyContactsReducer from '../shared/pages/EmergencyContactPage/reducers';
import allergyReducer from '../store/reducers/allergies/reducers';
import careTeamReducer from '../store/reducers/careTeam/reducers';
import healthStatusReducers from './reducers/healthStatus/reducers';
import { reducer as allergens } from './reducers/allergens';
import { reducer as reactions } from './reducers/reactions';
import { reducer as treatments } from './reducers/treatments';
import { reducer as portals } from './reducers/portals';
import mediaObjectReducer from './reducers/mediaObject/reducers';
import uiReducer from './reducers/ui/reducers';
import familyHistoryReducer from './reducers/familyHistory/reducers';
import journalComposeReducer from './reducers/journalmodal/reducers';
import journalEntriesReducer from './reducers/journalEntries/reducers';
import reportComposeReducer from './reducers/reportsModal/reducers';
import userAgreements from './reducers/agreements/reducers';
import { reducer as surgicalHistoryReducer } from './reducers/surgicalHistory/reducers';
import notificationReducer from './reducers/notifications/reducers';
import mediaObjectDrawer from './reducers/mediaObjectDrawer/reducers';
import conditionsReducer from './reducers/conditions/reducers';
import { CONDITIONS_UPDATE_CLEAR_ID } from './reducers/conditions/actions';
import resourceLibraryReducer from './reducers/resourceLibrary/reducers';
import { reducers as subscriptionChangeReducer } from './reducers/subscription';
import documentsReducer from './reducers/documents/reducers';
import { JOURNAL_MODAL_OPEN } from './reducers/journalmodal/actions';
import { IMMUNIZATION_CLEAR_ID } from './reducers/immunizations/actions';
import { MEDIA_OBJECT_CLEAR_FILES } from './reducers/mediaObject';
import syncPermissionsReducer from './reducers/syncPermissions/reducers';
import { SURGICAL_HISTORY_UPDATE_CLEAR_ID } from './reducers/surgicalHistory/actions';
import labsReducer from './reducers/labs/reducers';
import coreReducers from './reducers/core/reducers';

const clearStripePurchaseForm = (state, action) => {
  // StripePurchaseForm fails to unregister state.field.cardName,
  // which prevents form submission, plugin function detects the UNREGISTER_FIELD
  // action from StripePurchaseForm and returns an empty object as state,
  // effectively clearing the redux form state
  if (action.type !== formActionTypes.UNREGISTER_FIELD) return state;
  return {};
};

const clearAddInfoForm = (state, action) => {
  // because addInfo is not destroyed on unmount,
  // we need to clear the form when journal is cleared
  if (action.type === JOURNAL_MODAL_OPEN) return {};
  if (action.type === IMMUNIZATION_CLEAR_ID) return {};
  if (action.type === MEDIA_OBJECT_CLEAR_FILES) return {};
  if (action.type === SURGICAL_HISTORY_UPDATE_CLEAR_ID) return {};
  if (action.type === CONDITIONS_UPDATE_CLEAR_ID) return {};

  return state;
};

const apiReducer = combineReducers({
  allergens,
  reactions,
  treatments,
});

const createRootReducer = history =>
  combineReducers({
    app: appReducer,
    dependents: dependentsReducer,
    active: activeReducer,
    form: formReducer.plugin({
      StripePurchaseForm: clearStripePurchaseForm,
      addInfo: clearAddInfoForm,
      addInfoForm: clearAddInfoForm,
    }),
    healthStatus: healthStatusReducers,
    member: memberReducer,
    allergies: allergyReducer,
    provider: providerReducer, // This should be segregated into another application.
    codes: codeReducer,
    router: connectRouter(history),
    progressBar: progressBarReducer,
    userInsuranceReducer,
    flashMessage: flashMessageReducer,
    emergencyContacts: emergencyContactsReducer,
    api: apiReducer,
    mediaObject: mediaObjectReducer,
    mediaObjectDrawer: mediaObjectDrawer,
    ui: uiReducer,
    familyHistory: familyHistoryReducer,
    documents: documentsReducer,
    portals,
    journalCompose: journalComposeReducer,
    journalEntries: journalEntriesReducer,
    careTeam: careTeamReducer,
    reportCompose: reportComposeReducer,
    agreements: userAgreements,
    notification: notificationReducer,
    surgicalHistory: surgicalHistoryReducer,
    conditions: conditionsReducer,
    resourceLibrary: resourceLibraryReducer,
    subscription: subscriptionChangeReducer,
    syncPermissions: syncPermissionsReducer,
    labs: labsReducer,
    core: coreReducers,
  });

const rootReducer = history => (state, action) => {
  // Redux state resets to initialState when user logouts
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type == '@@redux/member/LOGOUT_USER') {
    state = undefined;
  }

  return createRootReducer(history)(state, action);
};

export default rootReducer;
