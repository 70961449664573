import React from 'react';

export default props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#ED5158" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3702 10.8235H23.9002C25.2602 10.8235 26.2202 12.1635 25.7902 13.4535L24.0102 18.8235L25.7902 24.1935C26.2202 25.4835 25.2502 26.8235 23.8902 26.8235H10.1102C8.75017 26.8235 7.78017 25.4835 8.22017 24.1935L10.0102 18.8235L8.21017 13.4535C7.78017 12.1635 8.74017 10.8235 10.1102 10.8235H20.7002L21.7202 8.00353C21.9602 7.35353 22.6802 7.01353 23.3302 7.25353C23.9802 7.48353 24.3202 8.20353 24.0802 8.85353L23.3702 10.8235ZM18.0102 19.8235H20.0102C20.5602 19.8235 21.0102 19.3735 21.0102 18.8235C21.0102 18.2735 20.5602 17.8235 20.0102 17.8235H18.0102V15.8235C18.0102 15.2735 17.5602 14.8235 17.0102 14.8235C16.4602 14.8235 16.0102 15.2735 16.0102 15.8235V17.8235H14.0102C13.4602 17.8235 13.0102 18.2735 13.0102 18.8235C13.0102 19.3735 13.4602 19.8235 14.0102 19.8235H16.0102V21.8235C16.0102 22.3735 16.4602 22.8235 17.0102 22.8235C17.5602 22.8235 18.0102 22.3735 18.0102 21.8235V19.8235Z"
      fill="white"
    />
  </svg>
);
