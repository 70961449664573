import React from 'react';
import { Grid, Typography, Fade } from '@material-ui/core';
import SubscriptionCard from './SubscriptionCard';
import { Skeleton } from '@material-ui/lab';

const SubscriptionView = ({
  isLoading,
  onClick,
  accountRole,
  startOn,
  isSubsidized,
  order,
  lastChargeAmount,
}) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <div
        style={{
          maxWidth: '370px',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        {' '}
        {!isLoading ? (
          <Typography style={{ fontSize: '16px', lineHeight: '19px' }}>
            Your Current Products and Services are:
          </Typography>
        ) : (
          <Skeleton variant="text" animation="wave" width="100%" height={19} />
        )}
      </div>
      {!isLoading ? (
        <Fade in={Boolean(startOn && accountRole)} timeout={300}>
          <div>
            <SubscriptionCard
              onClick={onClick}
              accountRole={accountRole}
              startOn={startOn}
              isSubsidized={isSubsidized}
              orderTotal={
                Boolean(order && order.orderTotal)
                  ? order.orderTotal
                  : Boolean(lastChargeAmount)
                  ? lastChargeAmount
                  : 0
              }
            />
          </div>
        </Fade>
      ) : (
        <div style={{ maxWidth: '370px' }}>
          <Skeleton variant="rect" animation="wave" width="100%" height={300} />
        </div>
      )}
    </Grid>
  );
};

export default SubscriptionView;
