import React from 'react'
import { withStyles, Grid } from '@material-ui/core'

const styles = {
  root: {
    overflow: 'hidden',
    transform: 'translate(12px, 12px)'
    // marginBottom: -28
  },
  img: {
    transform: 'translate(2px, 2px)'
  }
}

const Illustration = ({ svg, classes }) => (
  <Grid
    container
    className={classes.root}
    justify="flex-end"
    alignItems="flex-end"
  >
    <img className={classes.img} src={svg} alt="Illustration" />
  </Grid>
)

export default withStyles(styles)(Illustration)
