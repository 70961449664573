import React, { useEffect, useState } from 'react';
import SelectDependentType from './Containers/SelectDependentType/SelectDependentType';
import BasePage from '../BasePage';
import { Divider, Grid } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import { withRouter } from 'react-router';
import AddDependent from './Containers/AddDependent/AddDependent';
import { DashboardMessage } from '../../components/DashboardContent';
import AddPetDependent from './Containers/AddPetDependent/AddPetDependent';
import AddAdultDependent from './Containers/AddAdultDependent/AddAdultDependent';
import { useFetchCodeTypes } from './useFetchCodeTypes';
import {
  fetchBloodType,
  fetchContactType,
  fetchEthnicityType,
  fetchGenderType,
  fetchLanguageType,
  fetchMaritalType,
  fetchReligionType,
  fetchStateType,
  fetchVeteranType,
} from '../../../store/reducers/codes/actions';
import { connect } from 'react-redux';
import { createDependent } from '../../../store/reducers/dependents/action';
import { clearPersonInfo } from '../../../store/reducers/person/actions';

function FamilyAccountsPage(props) {
  const { createDependent, history, clearPersonInfo } = props;
  const fetchTypes = useFetchCodeTypes(props);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    clearPersonInfo();
    fetchTypes();
  }, [selectedType]);

  const [selectedType, setSelectedType] = useState();
  const [showDashboardMessage, setShowDashboardMessage] = useState(false);
  const handleNext = value => {
    if (value !== 'adult') {
      setShowDashboardMessage(true);
    }
    setSelectedType(value);
  };

  const handleSubmit = async values => {
    setIsSubmitting(true);
    try {
      await createDependent({
        ...values,
        type: selectedType === 'pet' ? 'Pet' : 'Person',
      });
      history.goBack();
    } catch (e) {
      throw e;
    }
    setIsSubmitting(false);
  };

  const renderChild = () => {
    switch (selectedType) {
      case 'child':
        return (
          <AddDependent isSubmitting={isSubmitting} onSubmit={handleSubmit} />
        );
      case 'adult':
        return (
          <AddAdultDependent
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            setShowDashboardMessage={setShowDashboardMessage}
            isNewDependent={true}
          />
        );
      case 'pet':
        return (
          <AddPetDependent
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
          />
        );
      default:
        return <SelectDependentType onNext={handleNext} />;
    }
  };

  const dashboardMessage =
    selectedType === 'pet'
      ? 'This information is used to accurately exchange information with your pet’s Care Team.  It may also be used to assist in completing patient paperwork and making recommendations such as vaccinations that are due.  The more complete this information is, the better your Care Team can work with you to help your pet.'
      : 'This information is used to accurately exchange information with any Care Team member you direct.  It may also be used to assist in completing patient paperwork and making recommendations such as when to seek a colonoscopy or take a risk assessment survey.  The more complete this information is, the better your Care Team can serve you.';

  return (
    <BasePage
      HeaderComponent={
        <PageHeader
          headerText="Family Accounts"
          subHeaderText={`Add ${
            selectedType
              ? selectedType[0].toUpperCase() + selectedType.slice(1) + ' '
              : ''
          }Account`}
          backButtonText="Go Back"
          rightSlot={
            showDashboardMessage ? (
              <DashboardMessage canClose={false}>
                {dashboardMessage}
              </DashboardMessage>
            ) : (
              ''
            )
          }
          xs={{
            right: 8,
            left: 4,
          }}
        />
      }
    >
      <Grid item lg={12} style={{ marginBottom: 400 }}>
        {renderChild()}
      </Grid>
    </BasePage>
  );
}

const mapStateToProps = state => ({
  codeTypes: state.codes,
  bloodTypes: state.codes.bloodTypes,
  ethnicityTypes: state.codes.ethnicityTypes,
  genderTypes: state.codes.genderTypes,
  languageTypes: state.codes.languageTypes,
  maritalTypes: state.codes.maritalTypes,
  contactTypes: state.codes.contactTypes,
  religionTypes: state.codes.religionTypes,
  stateTypes: state.codes.stateTypes,
  veteranTypes: state.codes.veteranTypes,
  personId: state.member.login.details.personId,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchBloodType: () => dispatch(fetchBloodType()),
  fetchContactType: () => dispatch(fetchContactType()),
  fetchEthnicityType: () => dispatch(fetchEthnicityType()),
  fetchGenderType: () => dispatch(fetchGenderType()),
  fetchLanguageType: () => dispatch(fetchLanguageType()),
  fetchMaritalType: () => dispatch(fetchMaritalType()),
  fetchReligionType: () => dispatch(fetchReligionType()),
  fetchStateType: () => dispatch(fetchStateType()),
  fetchVeteranType: () => dispatch(fetchVeteranType()),
  createDependent: values => dispatch(createDependent(values)),
  clearPersonInfo: () => dispatch(clearPersonInfo()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(FamilyAccountsPage)),
};
