import React from 'react';
import CovidImg from '../../../../../client/images/COVID.png';
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    // padding: "8px",
    backgroundColor: '#EFF1F3',
  },
  container: {
    margin: '0',
    width: '100%',
    // maxHeight: '350px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: 200,
  },
  imageCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CovidStatusInfo = props => {
  const classes = useStyles();
  const matches600 = useMediaQuery('(max-width:600px)');
  const textStyles = matches600
    ? {
        fontSize: '14px',
        margin: '8px 0',
        lineHeight: '18px',
      }
    : { margin: '8px 0', fontSize: '14px', lineHeight: 1.5 };
  return (
    <Paper className={classes.paper} elevation={6}>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={8} sm={7} className={classes.infoContainer}>
          <Typography style={{ ...textStyles }}>
            COVID test and/or immunization records are used to determine your
            COVID health status.
          </Typography>
          <Typography style={{ ...textStyles }}>
            You can store COVID test and immunization or vaccination records
            (along with related documents) that you may want to keep and/or
            share in your Health Record section of this app.
          </Typography>
          <Typography style={{ ...textStyles }}>
            You can also upload your electronic medical records from your
            healthcare providers to your Agrin Health.
          </Typography>
        </Grid>
        <Grid item xs={4} sm={5} className={classes.imageCenter}>
          <img src={CovidImg} className={classes.image} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CovidStatusInfo;
