import React from 'react';
import { connect } from 'react-redux';
import { markNotification } from '../../../store/reducers/notifications/actions';
import GlobalNotification from '../../components/Notifications/Global/GlobalNotification';
import { Typography } from '@material-ui/core';

const GlobalMessagesContainer = ({
  markNotification,
  notificationIds,
  notificationList,
}) => {
  const handleClose = notification => () => {
    if (notification.clearedOn || notification.ReadOn) return;

    console.log('Trying to close a notification', notificationList);
    markNotification(notification);
  };

  return notificationIds.map(id => {
    console.log('Preparing to render', notificationList[id]);
    return (
      <GlobalNotification
        key={id}
        type="info"
        isDisplayed
        canClose
        onClose={handleClose(notificationList[id])}
      >
        {notificationList[id].title && (
          <Typography>
            <strong>{notificationList[id].title}</strong>
          </Typography>
        )}

        <Typography variant="body2">{notificationList[id].body}</Typography>
      </GlobalNotification>
    );
  });
};

const mapStateToProps = state => ({
  notificationList: state.notification.global.list,
  notificationIds: state.notification.global.unreadNotificationIds,
});

const mapDispatchToProps = dispatch => ({
  markNotification: notification =>
    dispatch(markNotification('read', notification, 'global')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessagesContainer);
