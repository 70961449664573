import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
  TextField,
} from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';

const meta = {
  color: '#2AA5A2',
  icon: 'accessibility',
};

const formatWaterIntakeInput = value =>
  parseFloat(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

const WaterIntakeViewer = ({ data, classes }) => {
  return (
    <InputField
      label="Water Intake (oz)"
      value={formatWaterIntakeInput(data.quantity)}
      placeholder="0.00"
      shrink={true}
      InputProps={{ readOnly: true, disabled: true }}
      enableMinHeight={false}
    />
  );
};

export default WaterIntakeViewer;
