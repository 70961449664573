import { SET_ACTIVE_USER } from './actions';
import { APP_INITIAL_SESSION_SUCCESS } from '../app/actions';

import {
  PERSON_INFO_SUCCESS,
  PERSON_INFO_UPDATE_SUCCESS,
} from '../../../store/reducers/person/actions';
import { localStorageSetObject } from '../../../library/helpers/fetch';

const initialState = {
  user: {
    title: 'Me',
    personId: 0,
    firstName: '',
    lastName: '',
    slug: 'me',
    type: 'primary',
  },
};

const reducers = [];

reducers[PERSON_INFO_SUCCESS] = (state, action) => {
  return {
    ...state,
    activePersonRecord: {
      ...action.payload,
    },
  };
};

reducers[APP_INITIAL_SESSION_SUCCESS] = (state, action) => {
  const { personId, firstName, lastName, address } = action.payload;

  return {
    ...state,
    user: {
      title: 'Me',
      personId,
      firstName,
      lastName,
      slug: 'me',
      type: 'primary',
      address,
    },
  };
};

reducers[SET_ACTIVE_USER] = (state, action) => {
  if (
    action.payload.personId === state.user.personId &&
    action.payload.slug === state.user.slug
  )
    return state;
  localStorageSetObject('active', action.payload);
  return {
    ...state,
    user: action.payload,
  };
};

reducers[PERSON_INFO_UPDATE_SUCCESS] = (state, action) => {
  if (state.user.height !== action.payload.height) {
    return {
      ...state,
      user: {
        ...state.user,
        height: action.payload.height,
      },
    };
  } else
    return {
      ...state,
    };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
