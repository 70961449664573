import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import BaseLoader from '../../components/BaseLoader';
import PageHeader from '../../components/PageHeader';
import NoRecords from '../../components/NoRecords';
import Empty from '../../components/Empty';

const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';
const EMPTY = 'empty';

export const HEALTH_RECORDS = 'health_records';
export const HEALTH_SERVICES = 'health_services';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
});

const BasePage = props => {
  const {
    type = HEALTH_RECORDS,
    classes,
    pageHeaderProps = {},
    HeaderComponent = null,
    EmptyStateComponent = null,
    DrawerComponent = null,
    state = null,
    emptyStateComponentProps = {},
    healthServices = false,
    disablePadding = false,
  } = props;
  // const [state, setState] = React.useState(LOADING);
  // const changeState = () => {
  //   console.log(state);
  //   if (state === SUCCESS) setState(LOADING);
  //   if (state === LOADING) setState(ERROR);
  //   if (state === ERROR) setState(EMPTY);
  //   if (state === EMPTY) setState(SUCCESS);
  // };

  const renderState = () => {
    switch (state) {
      case LOADING: {
        return (
          <Fade in={true} timeout={150}>
            <BaseLoader loadingText={props.loadingText} />
          </Fade>
        );
      }
      case ERROR: {
        return (
          <Fade in={true} timeout={150}>
            <div>Error</div>
          </Fade>
        );
      }
      case EMPTY: {
        return (
          <Fade in={true} timeout={150}>
            {EmptyStateComponent ||
              (type === HEALTH_RECORDS ? (
                <NoRecords {...emptyStateComponentProps} {...pageHeaderProps} />
              ) : (
                <Empty {...emptyStateComponentProps} {...pageHeaderProps} />
              ))}
          </Fade>
        );
      }
      default:
        return (
          <Fade in={true} timeout={150}>
            <Grid container spacing={4}>
              {props.children}
            </Grid>
          </Fade>
        );
    }
  };

  const shouldRenderPageHeader =
    state !== EMPTY &&
    state !== LOADING &&
    (Boolean(HeaderComponent) || Object.keys(pageHeaderProps).length > 0);

  return (
    <div className={disablePadding ? {} : classes.root}>
      <Grid
        container
        spacing={4}
        style={disablePadding ? { margin: 0, width: '100%' } : {}}
      >
        {shouldRenderPageHeader && (
          <Grid item xs={12}>
            {HeaderComponent ? (
              React.cloneElement(HeaderComponent)
            ) : (
              <PageHeader {...pageHeaderProps} />
            )}
            <Divider />
          </Grid>
        )}
        <Grid item xs={12}>
          {state !== SUCCESS ? renderState() : props.children}
        </Grid>
        {DrawerComponent && (
          <Grid item xs={12}>
            {DrawerComponent}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(BasePage);
