import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import BigNumber from 'bignumber.js';

import RegistrationCoupon from './RegistrationCoupon';
import RegistrationPlans from './RegistrationPlans';
import StripePurchaseForm from './StripePurchaseForm';
import { DashboardMessage } from '../../../components/DashboardContent';
import { formatCurrency } from '../../../../library/helpers/money';

BigNumber.set({ DECIMAL_PLACES: 5, ROUNDING_MODE: BigNumber.ROUND_DOWN });

const CheckoutForm = props => {
  const {
    products,
    fetchProducts,
    failureMessage,
    isSubmitting,
    onSubmit,
    productData,
    displayPlanInformation,
    stripeFormElevation,
    disableCouponSlot,
    disableSummary,
  } = props;
  const [error, setError] = useState({});

  const [product, setProduct] = useState({ originalPrice: 0, data: null });
  const [fields, setFields] = useState({ couponCode: '', checkoutTotal: 0 });
  const [discount, setDiscount] = useState({ data: null });
  const [completeStripeFields, setCompleteStripeFields] = useState({});

  useEffect(() => {
    if (!products) {
      fetchProducts();
    }
  }, []);

  useEffect(() => {
    if (products) selectProduct(products[0]);
  }, [products, selectProduct]);

  useEffect(() => {
    if (productData) {
      const total = productData.price;

      setDiscount({ data: null });

      setProduct(state => ({
        ...state,
        originalPrice: total,
        factoredPrice: total,
        data: productData,
      }));

      setFields(state => ({
        ...state,
        checkoutTotal: total,
      }));
    }
  }, [productData]);

  const selectProduct = data => {
    const total = data.price;

    setDiscount({ data: null });

    setProduct(state => ({
      ...state,
      originalPrice: total,
      factoredPrice: total,
      data,
    }));

    setFields(state => ({
      ...state,
      checkoutTotal: total,
    }));
  };

  const onChange = change => {
    setError(state => ({
      ...state,
      [change.elementType]: change.error,
    }));

    if (change.complete) {
      setCompleteStripeFields(state => ({
        ...state,
        [change.elementType]: change.complete && !change.empty,
      }));
    }
  };

  const factorDiscount = data => {
    const total = BigNumber(product.originalPrice);
    let result = total;

    switch (data.type) {
      case 'Subtractive':
        result = total.minus(data.discountAmount);
        break;
      case 'Percentage':
        result = total.minus(total.multipliedBy(data.discountAmount));
        break;
      case 'Void':
        result = BigNumber(0);
        break;
      default:
        throw new Error('Discount type not supported');
    }

    return BigNumber.max(result, BigNumber(0)).toFixed(2);
  };

  const handlePayment = stripe => async (values, dispatch, formProps) => {
    // console.log('TOTAL', fields.checkoutTotal);
    if (fields.checkoutTotal >= 0.5) {
      const response = await stripe.createToken();

      if (response.error) {
        setError(response.error);
      } else {
        onSubmit({
          productId: product.data.productId,
          stripeToken: response.token.id,
          brand: response.token.card.brand,
          lastFourDigits: response.token.card.last4,
          cardExpirationMonth: response.token.card.exp_month,
          cardExpirationYear: response.token.card.exp_year,
          couponCode:
            fields.couponCode.trim().length > 0 ? fields.couponCode : null,
        });
      }
    } else {
      onSubmit({
        productId: product.data.productId,
        stripeToken: null,
        brand: null,
        lastFourDigits: null,
        cardExpirationMonth: null,
        cardExpirationYear: null,
        couponCode:
          fields.couponCode.trim().length > 0 ? fields.couponCode : null,
      });
    }
  };

  const displayDiscount = (data, total) => {
    if (!data) return (0).toFixed(2);

    total = BigNumber(total);
    let result = total;

    switch (data.type) {
      case 'Subtractive':
        result = BigNumber(data.discountAmount);
        break;
      case 'Percentage':
        result = total.multipliedBy(data.discountAmount);
        break;
      case 'Void':
        result = total;
        break;
      default:
        throw new Error('Discount type not supported');
    }
    return BigNumber.max(result, BigNumber(0.0)).toFixed(2);
  };

  const PlanInformation = useCallback(
    () => (
      <RegistrationPlans
        products={products}
        product={product}
        onSelect={selectProduct}
      />
    ),
    [products, product]
  );

  return (
    <StripePurchaseForm
      completeStripeFields={completeStripeFields}
      stripeError={error}
      product={product}
      onSubmit={handlePayment}
      onFieldChange={onChange}
      checkoutTotal={fields.checkoutTotal}
      success={false}
      // revealPaymentForm={fields.checkoutTotal >= 0.5}
      revealPaymentForm={product.data}
      isSubmitting={isSubmitting}
      stripeFormElevation={stripeFormElevation}
      couponSlot={() =>
        disableCouponSlot ? (
          ''
        ) : (
          <RegistrationCoupon
            coupon={discount}
            onChange={code => {
              setFields(state => ({
                ...state,
                couponCode: code,
              }));
            }}
            onSubmit={item => {
              setDiscount(item.data ? item : { data: null });
              setFields(state => ({
                ...state,
                checkoutTotal: item.data
                  ? factorDiscount(item.data)
                  : product.originalPrice,
              }));
            }}
          >
            {(state, x) => (
              <React.Fragment>
                {state === 'FETCHING' ? (
                  <DashboardMessage type="info" canClose={false}>
                    Checking...
                  </DashboardMessage>
                ) : state === 'FAILURE' ? (
                  <DashboardMessage type="warning" canClose={false}>
                    {x.error}
                  </DashboardMessage>
                ) : state === 'SUCCESS' ? (
                  <DashboardMessage type="success" canClose={false}>
                    <strong>{x.data.name}</strong>
                    <br />
                    <span>{x.data.description}</span>
                  </DashboardMessage>
                ) : null}
              </React.Fragment>
            )}
          </RegistrationCoupon>
        )
      }
      summarySlot={() =>
        disableSummary ? (
          ''
        ) : (
          <div style={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={8}>
                <strong>Security Fee</strong>{' '}
                <Tooltip
                  title={
                    <p>
                      This one-time Security Fee is to help us protect you. It
                      insures only actual Healthcare Consumers can create an
                      account.
                    </p>
                  }
                >
                  <InfoIcon style={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {product.data.securityFee > 0
                    ? formatCurrency(product.data.securityFee)
                    : 'Included'}
                </div>
              </Grid>
              <Grid item xs={8}>
                <strong>Subscription Fee</strong>
              </Grid>
              <Grid item xs={4}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {product.data.securityFee
                    ? '$0.00'
                    : formatCurrency(product.data.price)}
                </div>
              </Grid>

              {discount.data ? (
                <React.Fragment>
                  <Grid item xs={8}>
                    <strong>Discount</strong>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      -${displayDiscount(discount.data, product.data.price)}
                    </div>
                  </Grid>
                </React.Fragment>
              ) : null}

              <Grid style={{ marginTop: 16 }} item container>
                <Grid style={{ height: 1, marginBottom: 8 }} item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={8}>
                  <strong>Total</strong>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    {fields.checkoutTotal < 0.5
                      ? 'Free'
                      : formatCurrency(fields.checkoutTotal)}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
      }
    >
      {revealPaymentForm => (
        <React.Fragment>
          {displayPlanInformation && (
            <PlanInformation revealCouponForm={revealPaymentForm} />
          )}
          {failureMessage && (
            <Grid item xs={12} style={{ padding: 8 }}>
              <DashboardMessage
                type="warning"
                canClose={false}
                style={{ marginBottom: 0 }}
              >
                {failureMessage}
              </DashboardMessage>
            </Grid>
          )}
        </React.Fragment>
      )}
    </StripePurchaseForm>
  );
};

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

CheckoutForm.defaultProps = {
  displayPlanInformation: true,
};

export default CheckoutForm;
