import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, ButtonBase } from '@material-ui/core';
import { useStyles } from './useStyles';

const SmartText = ({ text }) => {
  const [showLess, setShowLess] = useState(true);
  const [disableShowLess, setDisableShowLess] = useState(false);
  const classes = useStyles();

  const ref = useRef();
  useEffect(() => {
    if (!ref || !ref.current) return;
    if (disableShowLess) return;
    if (ref.current.clientHeight === ref.current.scrollHeight) {
      if (!disableShowLess) {
        setDisableShowLess(true);
      }
    }
  }, [ref]);

  return !disableShowLess ? (
    <div className={classes.padding16}>
      <Typography
        ref={ref}
        style={{
          fontSize: '14px',
          transition: 'all 200ms',
        }}
        className={showLess ? classes.lineClamp : classes.noClamp}
      >
        {text}
      </Typography>
      <div className={classes.showLessButton}>
        <ButtonBase
          onClick={() => setShowLess(!showLess)}
          className={classes.showlessButtonBase}
        >
          <Typography className={classes.showLessButtonTypography}>
            {!showLess ? 'Read Less' : 'Read More'}
          </Typography>
        </ButtonBase>
      </div>
    </div>
  ) : (
    <div className={classes.padding16}>
      <Typography fontSize="14px"> {text}</Typography>
    </div>
  );
};

export default SmartText;
