import { ResponsiveXYFrame } from 'semiotic';
import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Paper } from '@material-ui/core';
import EmptyComponent from '../EmptyComponent';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MARGINS_X = 80;
const MARGINS_Y = 80;
export const drawMarginPath = ({ margin, size, inset = 0 }) => {
  const iSize = [size[0] - inset, size[1] - inset];
  return `M0,0 h${size[0]} v${size[1]} h-${size[0]}Z M${margin.left -
    inset},${margin.top - inset} v${size[1] +
    inset * 2 -
    margin.top -
    margin.bottom} h${iSize[0] +
    inset * 3 -
    margin.left -
    margin.right} v-${iSize[1] + inset * 3 - margin.top - margin.bottom}Z`;
};

const LineChart = props => {
  const { frameProps, hasData = true, mode = 'page' } = props;
  const [dimensions, setDimensions] = useState([800, 400]);
  const [node, setNode] = useState(null);
  const matches = useMediaQuery('(min-width:600px)');

  function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();
    return [!matches ? window.innerWidth - 48 : rect.width, rect.height];
  }

  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      };
    }
  }, [node]);

  const summaryModeProps =
    mode === 'summary' || !matches
      ? {
          axes:
            mode === 'summary'
              ? frameProps.axes
                ? [frameProps.axes.find(axis => axis.orient === 'left')]
                : [{}]
              : frameProps.axes,
          size:
            mode === 'summary' ? [dimensions[0], 135] : [dimensions[0], 145],
          margin:
            mode === 'summary'
              ? {
                  left: 80,
                  right: 80,
                  top: 20,
                  bottom: 20,
                }
              : {
                  left: 40,
                  right: 40,
                  top: 40,
                  bottom: 40,
                },
          annotations: [],
        }
      : {};

  return (
    <Paper
      elevation={4}
      style={{
        backgroundColor: '#0068A8',
        margin: mode === 'summary' ? '0px' : '50px 0px',
      }}
      ref={ref}
    >
      <ResponsiveXYFrame
        lineStyle={(d, i) => ({
          stroke: '#FFFFFF',
          strokeWidth: 3,
          fill: 'none',
        })}
        size={mode === 'summary' ? [dimensions[0], 135] : [dimensions[0], 300]}
        responsiveWidth={true}
        margin={{
          left: MARGINS_Y,
          right: MARGINS_Y,
          top: MARGINS_X,
          bottom: MARGINS_X,
        }}
        matte={
          mode === 'summary' || !matches ? (
            <path
              fill="#0068A8"
              transform={`translate(${-summaryModeProps.margin
                .left},${-summaryModeProps.margin.top})`}
              d={drawMarginPath({
                margin: summaryModeProps.margin,
                size: dimensions,
                inset: 0,
              })}
              className={`xyframe-matte`}
            />
          ) : (
            <path
              fill="#0068A8"
              transform={`translate(${-MARGINS_Y},${-MARGINS_X})`}
              d={drawMarginPath({
                margin: {
                  left: MARGINS_Y,
                  right: MARGINS_Y,
                  top: MARGINS_X,
                  bottom: MARGINS_X,
                },
                size: dimensions,
                inset: 0,
              })}
              className={`xyframe-matte`}
            />
          )
        }
        {...frameProps}
        {...summaryModeProps}
      />
      {!hasData ? (
        <div
          style={{
            width: dimensions[0],
            marginTop: -dimensions[1],
            height: dimensions[1],
            zIndex: 99,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyComponent matches={matches} />
        </div>
      ) : (
        ''
      )}
    </Paper>
  );
};

export default LineChart;
