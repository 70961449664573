import { isValidZipCode } from '../../../../library/validations/ZipCode';

export default (values) => {
  const errors = {}

  if (values.postalCode && !isValidZipCode(values.postalCode)) {
    errors.postalCode = 'Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX';
  }

  return errors
}
