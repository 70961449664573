// CONSTANSTS
export const DOWNGRADE = 'Downgrade';
export const UPGRADE = 'Upgrade';
export const SUBSIDIZED = 'Subsidized';
export const CLOSE_ACCOUNT = 'closeAccount';
export const CLOSE_WARNING = 'closeWarning';
export const CLOSE_WARNING_SUBSIDIZED = 'closeWarningSubsidized';
export const DOWNGRADE_SUBSIDIZED = 'downgradeSubsidized';
export const GENERAL_FAILURE_MESSAGE =
  "We're sorry, this service is temporarily unavailable. Please try again later. If the problem persists, please contact support at support@agrinhealth.com";

// Modal values for each action type
export const ModalData = {
  [DOWNGRADE]: {
    type: DOWNGRADE,
    header: 'WARNING',
    subHeader:
      'By downgrading your account, you will lose access to all data Sharing features.',
    subtitle:
      'To claim your money-back guarantee within 30 days of purchase, please contact Support@AgrinHealth.com',
    button1: 'Downgrade',
    button2: 'KEEP',
    warning: true,
  },
  [CLOSE_WARNING]: {
    type: CLOSE_ACCOUNT,
    //header: 'WARNING',
    subHeader:
      'To close your account and permanently delete your health data, please contact Agrin Health support at Support@AgrinHealth.com or open a Helpdesk ticket from the Help page.',
    //subHeader:
    //  'Once you close your account, your data will be deleted forever.',
    //subtitle:
    //  'Don’t forget! You can keep your data with the Basic subscription free forever.',
    //button1: 'Delete Forever',
    //button2: 'KEEP',
    //warning: true,
  },
  [DOWNGRADE_SUBSIDIZED]: {
    type: SUBSIDIZED,
    subHeader:
      'You are currently exchanging data with your Care Team. The data exchange service is not available for Basic subscribers.',
    subtitle:
      'In order to downgrade to Basic, you must first disconnect your Care Team’s access in the Care Team page.',
  },
  [CLOSE_WARNING_SUBSIDIZED]: {
    type: SUBSIDIZED,
    subHeader:
      'We cannot automatically close your account because you are currently exchanging data and receiving a subsidy from one of our Partners.',
    subtitle: 'Please contact Support@AgrinHealth.com so we can assist you.',
  },
};
