import {DISPATCH_API_AUTHORIZED} from "../../../library/middleware/dispatchApi";
import {createQueryStringFromObject} from "../../../library/helpers/encode";


export const ACCOUNT_PASSWORD_REQUEST = '@@redux/security/ACCOUNT_PASSWORD_REQUEST';
export const ACCOUNT_PASSWORD_SUCCESS = '@@redux/security/ACCOUNT_PASSWORD_SUCCESS';
export const ACCOUNT_PASSWORD_FAILURE = '@@redux/security/ACCOUNT_PASSWORD_FAILURE';

export const changePassword = (oldPassword, newPassword) => {
    return {
        [DISPATCH_API_AUTHORIZED]: {
            types: [ACCOUNT_PASSWORD_REQUEST, ACCOUNT_PASSWORD_SUCCESS, ACCOUNT_PASSWORD_FAILURE],
            endpoint: `/api/Account/ChangePassword`,
            payload: {
                "oldPassword":oldPassword,
                "newPassword":newPassword,
                "confirmPassword":newPassword
            },
            meta: {
                config: {
                    headers: {'Content-Type':'application/x-www-form-urlencoded'},
                    method: 'POST',
                    mode: 'cors',
                    body: createQueryStringFromObject({
                        "oldPassword":oldPassword,
                        "newPassword":newPassword,
                        "confirmPassword":newPassword
                    })
                }
            }
        }
    }
}
