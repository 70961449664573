import { simpleEmailValidation } from '../../../../library/validations/EmailAddress';
import { isValidZipCode } from '../../../../library/validations/ZipCode';

export default values => {
  const errors = {};

  if (values.email && !simpleEmailValidation(values.email)) {
    errors.email = 'Email address not valid';
  }

  if (values.phoneNumber && values.phoneNumber.length < 10) {
    errors.phoneNumber = 'Invalid phone number. Correct format is XXX-XXX-XXXX';
  }

  if (values.faxNumber && values.faxNumber.length < 10) {
    errors.faxNumber = 'Invalid fax number. Correct format is XXX-XXX-XXXX';
  }

  if (values.postalCode && !isValidZipCode(values.postalCode)) {
    errors.postalCode =
      'Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX';
  }

  return errors;
};
