import React from 'react';
import {
  Grid,
  Button,
  Container,
  Typography,
  Tooltip,
} from '@material-ui/core';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

export default ({ type, message, onClose, accountRole }) => {
  return (
    <DashboardMessage
      type={type}
      canClose={true}
      onClose={onClose}
      style={{ maxWidth: '650px' }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={10}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Container maxWidth="lg">
            <Typography>
              {message}
              {type === 'success' && (
                <span>
                  <b>{accountRole}</b>!
                </span>
              )}
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </DashboardMessage>
  );
};
