export const FLASH_MESSAGE_CREATE = '@@redux/flash-message/MESSAGE_CREATE';
export const FLASH_MESSAGE_REMOVE = '@@redux/flash-message/MESSAGE_REMOVE';

export const createMessage = (payload, type = 'info', timeout = null) => ({
  type: FLASH_MESSAGE_CREATE,
  payload,
  meta: {
    type,
    timeout,
  },
});

export const removeMessage = () => ({
  type: FLASH_MESSAGE_REMOVE
});