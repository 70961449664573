import { push } from 'connected-react-router';

export const REQUEST_LOGIN = '@@redux/provider/REQUEST_LOGIN';
export const RECEIVE_LOGIN = '@@redux/provider/RECEIVE_LOGIN';
export const FAILURE_LOGIN = '@@redux/provider/FAILURE_LOGIN';

export const requestLogin = credentials => ({
  type: REQUEST_LOGIN,
  credentials,
});

export const receiveLogin = user => ({
  type: RECEIVE_LOGIN,
  token: user.token,
});

export const loginFailure = message => ({
  type: FAILURE_LOGIN,
  message: message,
});

export const loginUser = credentials => {
  const formData = new FormData();
  formData.append('email_address', credentials.email_address);
  formData.append('password', credentials.password);
  formData.append('grant_type', 'password');

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // "Access-Control-Allow-Origin": "*",
    },
    method: 'POST',
    mode: 'cors',
    body: `username=${credentials.email_address}&password=${credentials.password}&grant_type=password`,
  };

  return dispatch => {
    dispatch(requestLogin(credentials));
    return (
      fetch(`${window.__env.API_URL}/oauth2/token`, config)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response);
          }

          return Promise.reject(new Error(response.statusText));
        })
        // .then(response => {
        //  console.log(response)

        //  return response.json()

        // if (response.ok) {
        //   return response.json()
        // } else {
        //   return response.json().then(obj => {
        //     Promise.reject({
        //       status: response.status,
        //       statusText: response.statusText,
        //       body: obj,
        //     })
        //   })
        // return Promise.reject({
        //   status: response.status,
        //   statusText: response.statusText,
        // })
        // }
        // })
        .then(response => response.json())
        .then(data => {
          // if (data.status === 200) {
          localStorage.setItem('token', JSON.stringify(data));
          dispatch(receiveLogin(data));
          dispatch(push('/d/me'));
          // }
        })
        .catch(error => {
          dispatch(loginFailure('Email address or password is incorrect'));
        })
    );
  };
};
