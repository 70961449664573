import React from 'react';
import CustomCard from './CustomCard';
import { Fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    marginTop: '8px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    gridGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Component = ({ listIds, list }) => {
  const classes = useStyles();
  return Boolean(listIds.length) ? (
    <div className={classes.root}>
      {listIds
        .sort(function(a, b) {
          if (list[a].title < list[b].title) {
            return -1;
          }
          if (list[a].title > list[b].title) {
            return 1;
          }
          return 0;
        })
        .map((val, index) => {
          return (
            <div
              key={`card` + index}
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Fade in={true} timeout={200}>
                <CustomCard
                  item={list[val]}
                  width="100%"
                  height="200px"
                  category={list[val].category}
                />
              </Fade>
            </div>
          );
        })}
    </div>
  ) : (
    <div style={{ margin: '16px' }}>No Resource Found</div>
  );
};

const ResourceCardView = React.memo(Component);
export default ResourceCardView;
