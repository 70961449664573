import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { setMediaObjects } from '../mediaObject/actions';

export const MEDICATION_REQUEST = '@@redux/medication/MEDICATION_REQUEST';
export const MEDICATION_SUCCESS = '@@redux/medication/MEDICATION_SUCCESS';
export const MEDICATION_FAILURE = '@@redux/medication/MEDICATION_FAILURE';

export const MVS_CREATE_REQUEST = 'mvs/MVS_CREATE_REQUEST';
export const MVS_CREATE_SUCCESS = 'mvs/MVS_CREATE_SUCCESS';
export const MVS_CREATE_FAILURE = 'mvs/MVS_CREATE_FAILURE';

export const MVS_UPDATE_REQUEST = 'mvs/MVS_UPDATE';
export const MVS_UPDATE_SUCCESS = 'mvs/MVS_UPDATE_SUCCESS';
export const MVS_UPDATE_FAILURE = 'mvs/MVS_UPDATE_FAILURE';

export const MVS_DELETE_REQUEST = '@@redux/medication/MVS_DELETE';
export const MVS_DELETE_SUCCESS = '@@redux/medication/MVS_DELETE_SUCCESS';
export const MVS_DELETE_FAILURE = '@@redux/medication/MVS_DELETE_FAILURE';

export const MEDICATION_LIST_REQUEST = '@@redux/medication/MEDICATION_LIST';
export const MEDICATION_LIST_SUCCESS =
  '@@redux/medication/MEDICATION_LIST_SUCCESS';
export const MEDICATION_LIST_FAILURE =
  '@@redux/medication/MEDICATION_LIST_FAILURE';

export const SET_MEDICATION_TO_UPDATE =
  '@@redux/medication/SET_MEDICATION_TO_UPDATE';
export const CLEAR_MEDICATION_TO_UPDATE =
  '@@redux/medication/CLEAR_MEDICATION_TO_UPDATE';

export const CLEAR_MEDICATION_ERROR =
  '@@redux/medication/CLEAR_MEDICATION_ERROR';

export const MVS_SET_ID_TO_UPDATE = 'mvs/update/SET_ID';
export const MVS_CLEAR_UPDATE_ID = 'mvs/update/CLEAR_ID';

export const MVS_REMOVE_MEDIA = 'mvs/remove_media';
export const MVS_DELETE_MEDIA = 'mvs/delete_media';
export const MVS_DELETE_MEDIA_CANCEL = 'mvs/delete_media_cancel';
export const MVS_UPDATE_MEDIA = 'mvs/update_media';
export const MVS_SUBMITFORM = 'mvs/submitForm';

export const submitForm = () => ({
  type: MVS_SUBMITFORM,
});

export const mvsSetIdToUpdate = id => ({
  type: MVS_SET_ID_TO_UPDATE,
  payload: id,
});

export const mvsClearUpdateId = () => ({
  type: MVS_CLEAR_UPDATE_ID,
});

export const fetchMedicationList = search => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      MEDICATION_LIST_REQUEST,
      MEDICATION_LIST_SUCCESS,
      MEDICATION_LIST_FAILURE,
    ],
    endpoint: `/api/member/medlist/${search}`,
  },
});

export const mvsFetch = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [MEDICATION_REQUEST, MEDICATION_SUCCESS, MEDICATION_FAILURE],
    endpoint: `/api/member/medications`,
    meta: {
      config: {
        headers: { Pid: pid },
      },
    },
  },
});

export const clearMedicationToUpdate = () => ({
  type: CLEAR_MEDICATION_TO_UPDATE,
});

export const clearMedicationError = () => ({
  type: CLEAR_MEDICATION_ERROR,
});

export const mvsCreate = (pid, mvs, bundles) => {
  const { medicationId, ...rest } = mvs;
  const payload = {
    ...rest,
    personId: pid,
    relatedConditions: codeObjectListToCsv(mvs.relatedConditions),
    sideEffects: codeObjectListToCsv(mvs.sideEffects),
    dateStarted: mvs.dateStarted ? mvs.dateStarted : '',
    dateDiscontinued: mvs.dateDiscontinued ? mvs.dateDiscontinued : '',
    mediaObjects: [],
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [MVS_CREATE_REQUEST, MVS_CREATE_SUCCESS, MVS_CREATE_FAILURE],
      endpoint: `/api/member/medication`,
      payload,
      meta: {
        pid,
        mediaObject: setMediaObjects(bundles, 'medicationId', 'mvs'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const mvsUpdate = (personId, medicationId, mvs, bundles) => {
  const payload = {
    ...mvs,
    personId,
    medicationId,
    relatedConditions: codeObjectListToCsv(mvs.relatedConditions),
    sideEffects: codeObjectListToCsv(mvs.sideEffects),
    dateStarted: mvs.dateStarted ? mvs.dateStarted : '',
    dateDiscontinued: mvs.dateDiscontinued ? mvs.dateDiscontinued : '',
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [MVS_UPDATE_REQUEST, MVS_UPDATE_SUCCESS, MVS_UPDATE_FAILURE],
      endpoint: `/api/member/medication`,
      payload,
      meta: {
        pid: personId,
        mediaObject: setMediaObjects(bundles, 'medicationId', 'mvs'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const mvsDelete = (pid, mid, tid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [MVS_DELETE_REQUEST, MVS_DELETE_SUCCESS, MVS_DELETE_FAILURE],
    endpoint: `/api/member/medication/${mid}`,
    meta: {
      tid,
      config: {
        headers: { Pid: pid },
        method: 'DELETE',
        mode: 'cors',
      },
      deleting_id: mid, //indicate loading
    },
  },
});
