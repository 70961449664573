import React, { useState, useEffect } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Typography, TextField, Grid, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from '../FragmentDialogBase';
import SelectField from '../../../../../library/forms/SelectField';
import SearchSelectField from '../../../SearchSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { isPositive, required } from '../Validations';

import {
  fetchMedUnitsType,
  fetchDeliveryMethodType,
  fetchDeliveryFormType,
} from '../../../../../store/reducers/codes/actions';

import { fetchMedicationList } from '../../../../../store/reducers/mvs/actions';
import { properties as fragmentProperties } from './properties';

const useStyles = makeStyles({
  formLabel: {
    fontSize: '12px',
  },

  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  rowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  rowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '2x',
  },
  header: {
    fontSize: '13px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  textField: {
    color: '#0068A8',
    marginTop: '6px',
    minHeight: '60px',
  },
  totalSleepFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rxActionContainer: {
    marginTop: 8,
    marginLeft: 24,
    marginRight: 0,
  },
  rxUserAction: {
    borderRadius: '4px',
    border: '1px solid #0068a8',
    background: '#f8f8f8',
    padding: 8,
  },
  rxUserActionHead: {
    color: '#0068A8',
    textAlign: 'center',
  },
  rxUserActionLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '20px 0px',
  },
  gridFormFields: {
    // marginBottom: '-22px',
  },
  focused: {},
});

const ReduxTextField = ({
  input: { onChange, value },
  meta: { touched },
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <TextField
      {...props}
      onChange={onChange}
      value={value}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      InputLabelProps={{
        style:
          focused || value
            ? {}
            : {
                transform: 'translate(14px, 16.6px) scale(1)',
              },
      }}
    />
  );
};

const MedicationFormFields = props => {
  const classes = useStyles();
  const {
    medUnitsTypes,
    deliveryMethodTypes,
    deliveryFormTypes,
    medicationSearchResult,
    medicationTypeAhead,
    handleRadioClick,
    rxUserAction,
  } = props;

  return (
    <Grid container spacing={2} style={{ marginTop: '50px' }}>
      <Grid item xs={12}>
        <Field
          name="name"
          label="Name"
          hint="Required"
          labelKey="label"
          valueKey="value"
          options={[]}
          validate={required}
          onTypeAhead={medicationTypeAhead}
          component={SearchSelectField}
          isSearchable
          isCreatable
          isClearable
        />
      </Grid>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.gridFormFields}>
            <Field
              name="dosage"
              component={ReduxTextField}
              validate={[isPositive]}
              normalize={(value, previousValue) => {
                if (value.includes('.')) {
                  if (value > 9999) {
                    return 9999;
                  } else if (value.split('.')[1].length >= 2) {
                    return parseFloat(value).toFixed(2);
                  } else {
                    return value;
                  }
                } else {
                  if (value.length > 4) {
                    return previousValue;
                  } else {
                    return parseFloat(value);
                  }
                }
              }}
              props={{
                variant: 'outlined',
                margin: 'dense',
                label: 'Dosage',
                type: 'number',
                fullWidth: true,
                inputProps: {
                  style: {
                    lineHeight: '1.187em',
                    padding: '15px',
                    marginLeft: '-3px',
                  },
                  max: 99999,
                  min: 1,
                  step: '1',
                },
                style: {
                  marginTop: '8px',
                },
              }}
            />
          </Grid>
          <Grid item xs={8} className={classes.gridFormFields}>
            <Field
              name="unit"
              label="Unit"
              labelKey="label"
              valueKey="value"
              options={medUnitsTypes}
              component={SelectField}
              isSearchable
            />
          </Grid>
          <Grid item xs={12} className={classes.gridFormFields}>
            <Field
              name="deliveryMethod"
              label="Delivery Method"
              labelKey="label"
              valueKey="value"
              options={deliveryMethodTypes}
              component={SelectField}
              isSearchable
            />
          </Grid>
          <Grid item xs={12} className={classes.gridFormFields}>
            <Field
              name="deliveryForm"
              label="Delivery Form"
              labelKey="label"
              valueKey="value"
              options={deliveryFormTypes}
              component={SelectField}
              isSearchable
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <div className={classes.rxActionContainer}>
          <Grid container item spacing={0} className={classes.rxUserAction}>
            <Grid item xs={12}>
              <Typography className={classes.rxUserActionHead}>
                Taken as Directed?
              </Typography>
            </Grid>
            <Grid item xs={3} onClick={() => handleRadioClick('Not Prescribed')}>
              <Field
                name="rxUserAction"
                component={Radio}
                checked={rxUserAction == 'Not Prescribed'}
              />{' '}
            </Grid>
            <Grid item xs={9} className={classes.rxUserActionLabel}>
              Not Prescribed
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleRadioClick('Taken as Prescribed')}
            >
              <Field
                name="rxUserAction"
                component={Radio}
                checked={rxUserAction == 'Taken as Prescribed'}
              />{' '}
            </Grid>
            <Grid item xs={9} className={classes.rxUserActionLabel}>
              Taken as Prescribed
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() =>
                handleRadioClick('Taken differently than Prescribed')
              }
            >
              <Field
                name="rxUserAction"
                component={Radio}
                checked={rxUserAction == 'Taken differently than Prescribed'}
              />{' '}
            </Grid>
            <Grid item xs={9} className={classes.rxUserActionLabel}>
              Taken differently than Prescribed
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => handleRadioClick('Skipped a Prescribed dose')}
            >
              <Field
                name="rxUserAction"
                component={Radio}
                checked={rxUserAction == 'Skipped a Prescribed dose'}
              />{' '}
            </Grid>
            <Grid item xs={9} className={classes.rxUserActionLabel}>
              Skipped a Prescribed dose
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const noop = () => {};

const createOption = (input, key) => ({
  [key]: input,
  __isNew__: true,
});

const AdditionalInfoFields = props => {
  const { updateField } = props;

  useEffect(() => {
    if (props.relatedConditions.length > 0) {
      const arr1 = [];
      props.relatedConditions.forEach(item => {
        arr1.push(createOption(item, 'shortName'));
      });
      setRelatedConditions(arr1);
    }
    if (props.sideEffects.length > 0) {
      const arr2 = [];
      props.sideEffects.forEach(item => {
        arr2.push(createOption(item, 'shortName'));
      });
      setSideEffects(arr2);
    }
  }, [props.relatedConditions, props.sideEffects]);
  const [relatedConditions, setRelatedConditions] = useState(
    props.relatedConditions
  );
  const [sideEffects, setSideEffects] = useState(props.sideEffects);

  const handleConditionChange = e => {
    updateField('relatedConditions', [...e.map(item => item.shortName)]);
  };
  const handleSideEffectChange = e => {
    updateField('sideEffects', [...e.map(item => item.shortName)]);
  };

  const SelectFieldIcon = () => (
    <AddCircleIcon style={{ marginTop: 2, color: '#90B850' }} />
  );

  return (
    <Grid
      container
      spacing={1}
      // style={{ margin: '20px 0px 0px 0px', marginBottom: '-12px' }}
    >
      <Grid item xs={12} style={{  }}>
        <SelectField
          name="relatedConditions"
          label="Related Condition(s)"
          placeholder="Type in a condition"
          labelKey="shortName"
          valueKey="shortName"
          meta={{ touched: false, active: false, error: false }}
          options={[]}
          input={{
            value: relatedConditions,
            onChange: handleConditionChange,
            onFocus: noop,
            onBlur: noop,
          }}
          IconComponent={SelectFieldIcon}
          shrink={true}
          isPreventingDuplicates={true}
          isSearchable
          isCreatable
          isMulti
        />
      </Grid>
      <Grid item xs={12} style={{  }}>
        <SelectField
          name="sideEffects"
          label="Side Effect(s)"
          labelKey="shortName"
          valueKey="shortName"
          placeholder="Type in a side effect"
          meta={{ touched: false, active: false, error: false }}
          options={[]}
          input={{
            value: sideEffects,
            onChange: handleSideEffectChange,
            onFocus: noop,
            onBlur: noop,
          }}
          shrink={true}
          IconComponent={SelectFieldIcon}
          isPreventingDuplicates={true}
          isSearchable
          isCreatable
          isMulti
        />
      </Grid>
    </Grid>
  );
};

const MedicationDialog = props => {
  const {
    medUnitsTypes,
    deliveryMethodTypes,
    deliveryFormTypes,
    fetchMedicationList,
    medicationSearchResult,
    isFetchingDeliveryForm,
    isFetchingMedUnits,
    isFetchingDeliveryMethods,
    updateField,
    name,
    initialValues,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [rxUserAction, setRxUserAction] = useState(
    initialValues.rxUserAction ? initialValues.rxUserAction : 'Not Prescribed'
  );

  useEffect(() => {
    fetchCodes();
  }, []);

  useEffect(() => {
    console.log(name);
  }, [name]);

  useEffect(() => {
    setIsLoading(
      isFetchingDeliveryForm || isFetchingMedUnits || isFetchingDeliveryMethods
    );
  }, [isFetchingDeliveryMethods, isFetchingMedUnits, isFetchingDeliveryForm]);

  const fetchCodes = () => {
    props.fetchMedUnitsType();
    props.fetchDeliveryMethodType();
    props.fetchDeliveryFormType();
  };

  const medicationTypeAhead = (inputValue, callBack) => {
    fetchMedicationList(inputValue).then(res => {
      let options = [];
      res.payload.map(o => {
        options.push({
          label: o.medicationShortName,
          value: o.medicationShortName,
        });
      });

      callBack(options);
    });
  };

  const handleRadioClick = value => {
    setRxUserAction(value);
    updateField('rxUserAction', value);
  };

  const classes = useStyles();
  return (
    <FragmentDialogBase
      color="#2AA5A2"
      dateEndAdornment={'Date and Time Taken'}
      formTitle="Medication / Supplement"
      FormSlot1={
        isLoading ? (
          <div className={classes.loadingSpinner}>
            <CircularProgress />
          </div>
        ) : (
          <MedicationFormFields
            medUnitsTypes={medUnitsTypes.map(v => ({ value: v, label: v }))}
            deliveryMethodTypes={deliveryMethodTypes}
            deliveryFormTypes={deliveryFormTypes}
            medicationTypeAhead={medicationTypeAhead}
            handleRadioClick={handleRadioClick}
            rxUserAction={rxUserAction}
          />
        )
      }
      FormSlot2={
        <AdditionalInfoFields
          sideEffects={
            initialValues.sideEffects ? initialValues.sideEffects : []
          }
          relatedConditions={
            initialValues.relatedConditions
              ? initialValues.relatedConditions
              : []
          }
          updateField={updateField}
        />
      }
    />
  );
};

MedicationDialog.propTypes = {
  deliveryMethodTypes: PropTypes.array,
  deliveryForm: PropTypes.array,
  medUnitsTypes: PropTypes.array,
};

const MedicationFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(MedicationDialog));

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
  fetchMedicationList: search => dispatch(fetchMedicationList(search)),
  fetchMedUnitsType: () => dispatch(fetchMedUnitsType()),
  fetchDeliveryMethodType: () => dispatch(fetchDeliveryMethodType()),
  fetchDeliveryFormType: () => dispatch(fetchDeliveryFormType()),
});

const mapStateToProps = state => ({
  medicationSearchResult: state.member.medication,
  isFetchingMedUnits: state.codes.isFetchingMedUnits,
  isFetchingDeliveryMethods: state.codes.isFetchingDeliveryMethods,
  isFetchingDeliveryForm: state.codes.isFetchingDeliveryForm,
  deliveryMethodTypes: state.codes.deliveryMethodTypes,
  deliveryFormTypes: state.codes.deliveryFormTypes,
  medUnitsTypes: state.codes.medUnitsTypes,

  initialValues: {
    ...fragmentProperties.dataFields,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? fragmentProperties.normalizeData(
          state.journalCompose.fragments[0].fragmentData
        )
      : {}),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicationFormRedux);
