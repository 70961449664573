import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styles from './PageHeader.css';
import { Grid, Typography } from '@material-ui/core';

class PageHeader extends Component {
  renderBackButton = () => {
    const {
      history,
      backButtonText,
      backLink,
      isHistoryBackDisabled = false,
    } = this.props;

    const onClickGoBack = event => {
      event.preventDefault();
      if (this.props.onBack) {
        this.props.onBack();
        if (isHistoryBackDisabled) return;
      }

      if (backLink) {
        history.push(backLink);
        return;
      }

      history.goBack();
    };

    return (
      <a
        className={styles.btnBack}
        onClick={onClickGoBack}
        role="button"
        tabIndex="0"
      >
        <i className="fa fa-angle-left" aria-hidden="true" />
        &nbsp;
        {backButtonText}
      </a>
    );
  };

  render() {
    const {
      headerText,
      subHeaderText,
      rightSlot,
      xs,
      rightSlotProps,
      leftSlotProps,
      containerStyle = {},
    } = this.props;
    return (
      <div className={styles.container}>
        <Grid container spacing={3} style={containerStyle}>
          <Grid item xs={12}>
            {this.renderBackButton()}
          </Grid>
          <Grid item xs={xs ? xs.left : 8} {...leftSlotProps}>
            <Typography variant="h5" style={{ fontSize: '1.5rem' }}>
              {headerText}
            </Typography>
            {subHeaderText && (
              <Typography
                variant="subtitle1"
                style={{ color: '#006ab0' }}
                gutterBottom
              >
                {subHeaderText}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={xs ? xs.right : 4}
            {...rightSlotProps}
            style={{ minHeight: '66px' }}
          >
            {rightSlot ? rightSlot : ''}
          </Grid>
        </Grid>
      </div>
    );
  }
}

PageHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string,
  backButtonText: PropTypes.string.isRequired,
  backLink: PropTypes.string,
  rightSlot: PropTypes.node,
  isHistoryBackDisabled: PropTypes.bool,
};

export default withRouter(PageHeader);
