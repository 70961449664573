import {
  PERSON_INFO_REQUEST,
  PERSON_INFO_SUCCESS,
  PERSON_INFO_FAILURE,
  PERSON_INFO_UPDATE_REQUEST,
  PERSON_INFO_UPDATE_SUCCESS,
  PERSON_INFO_UPDATE_FAILURE,
  CLEAR_PERSON_INFO,
} from './actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

const initialState = {
  isFetchingInfo: false,
  isFetchingBasicInfo: false,
  isUpdating: false,
  errorMessage: undefined,

  uiState: LOADING,
};

const reducer = (state = initialState, action) => {
  let { payload = {} } = action;
  switch (action.type) {
    //person info
    case PERSON_INFO_REQUEST:
      return {
        ...state,
        isFetchingInfo: true,
        uiState: LOADING,
      };
    case PERSON_INFO_REQUEST:
    case CLEAR_PERSON_INFO:
      return {
        ...state,
        userInfo: {},
      };
    case PERSON_INFO_SUCCESS:
      return {
        ...state,
        isFetchingInfo: false,
        userInfo: payload || {},
        uiState: SUCCESS,
      };
    case PERSON_INFO_FAILURE:
      return {
        ...state,
        uiState: ERROR,
      };

    //basic update
    case PERSON_INFO_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        errorMessage: undefined,
      };
    case PERSON_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        errorMessage: undefined,
        userInfo: payload || {},
      };
    case PERSON_INFO_UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        errorMessage: payload.message || ':( something went wrong, try again',
      };

    default:
      return state;
  }
};

export default reducer;
