import React, { useEffect, useState, useLayoutEffect } from 'react';
import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import { InfoContainer, PreviewButton, CardContainer } from './components';
import {
  QR,
  DOWNLOAD,
  LINK,
} from './components/InfoContainer/WaysToShareInfo/WaysToShareInfo';
import {
  fetchHealthStatus,
  getShareableLink,
  cleanUpReducer,
} from '../../../store/reducers/healthStatus/actions';
import { HEALTH_STATUS } from '../../../store/reducers/reportsModal/reducers';
import { cleanUpReportsState } from '../../../store/reducers/reportsModal/actions';
import { Grid, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Loading from '../Labs/Loading';

import ReportModal, {
  REPORT_DOWNLOAD_PROGRESS,
  REPORT_DOWNLOAD_COMPLETE,
  REPORT_LINK_PROGRESS,
  REPORT_LINK_COMPLETE,
  REPORT_ERROR,
  REPORT_SELECTION,
  REPORT_QR_COMPLETE,
} from '../../components/Reports/ReportModal';

const LOADING = 'loading';
const EMPTY = 'empty';
const SUCCESS = 'success';
const ERROR = 'error';

const HealthStatus = ({
  personId,
  fetchHealthStatus,
  healthStatus,
  uiState = LOADING,
  navigation,
  getShareableLink,
  report,
  cleanUpReducer,
  cleanUpReportsState,
}) => {
  const [open, setOpen] = useState(false);
  const [activeModalState, setActiveModalState] = useState(REPORT_SELECTION);

  useEffect(() => {
    fetchHealthStatus();
  }, [personId]);

  useLayoutEffect(() => {
    return () => {
      cleanUpReducer();
    };
  }, []);

  const toggleOpen = () => {
    if (!report) cleanUpReportsState();
    setOpen(false);
  };

  const handleOnClick = type => {
    switch (type) {
      case DOWNLOAD: {
        if (!report) {
          handleGenerateReport(REPORT_DOWNLOAD_COMPLETE);
          setActiveModalState(REPORT_DOWNLOAD_PROGRESS);
        } else setActiveModalState(REPORT_DOWNLOAD_COMPLETE);
        setOpen(true);
        return;
      }

      case QR: {
        if (!report) {
          handleGenerateReport(REPORT_QR_COMPLETE);
          setActiveModalState(REPORT_DOWNLOAD_PROGRESS);
        } else setActiveModalState(REPORT_QR_COMPLETE);

        setOpen(true);
        return;
      }

      case LINK: {
        if (!report) {
          handleGenerateReport(REPORT_LINK_COMPLETE);
          setActiveModalState(REPORT_LINK_PROGRESS);
        } else setActiveModalState(REPORT_LINK_COMPLETE);
        setOpen(true);
        return;
      }
    }
  };

  const handleGenerateReport = async success => {
    const result = await getShareableLink();
    if (result.error) {
      setActiveModalState(REPORT_ERROR);
    } else {
      setActiveModalState(success);
    }
  };

  function renderViews() {
    switch (uiState) {
      case LOADING: {
        return <Loading />;
      }
      case ERROR: {
        return 'error';
      }
      case EMPTY:
      case SUCCESS: {
        return (
          <React.Fragment>
            <Grid item xs={12} style={{ paddingBottom: '0px' }}>
              <PageHeader
                headerText="COVID Health Status"
                subHeaderText="Dashboard View"
                backButtonText="Go Back"
                // rightSlot={
                //   <div
                //     style={{
                //       width: '100%',
                //       display: 'flex',
                //       justifyContent: 'flex-end',
                //     }}
                //   >
                //     <PreviewButton />
                //   </div>
                // }
              />
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              style={{ background: '#0068A8' }}
            >
              <InfoContainer
                isEmpty={uiState === EMPTY}
                onClick={handleOnClick}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              style={{ background: '#0068A8' }}
            >
              <CardContainer
                records={healthStatus.records}
                isEmpty={uiState === EMPTY}
                navigation={navigation}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportModal
                onClose={toggleOpen}
                open={open}
                setActiveModalState={setActiveModalState}
                activeModalState={activeModalState}
                report={report}
                type={HEALTH_STATUS}
              />
            </Grid>
          </React.Fragment>
        );
      }
    }
  }

  return <BasePage disablePadding={true}>{renderViews()}</BasePage>;
};

const mapStateToProps = state => ({
  uiState: state.healthStatus.uiState,
  personId: state.active.user.personId,
  healthStatus: state.healthStatus,
  report: state.healthStatus.report,
});

const mapDispatchToProps = dispatch => ({
  fetchHealthStatus: () => dispatch(fetchHealthStatus()),
  getShareableLink: () => dispatch(getShareableLink()),
  cleanUpReducer: () => dispatch(cleanUpReducer()),
  cleanUpReportsState: () => dispatch(cleanUpReportsState()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(HealthStatus)),
};
