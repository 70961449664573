import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  formControl: {
    minHeight: theme.spacing(10),
  },
  label: {
    letterSpacing: 'normal',
    marginTop: '5px',
    '&$focusedLabel': {
      marginTop: '0px',
    },
    '&$erroredLabel': {
      marginTop: '5px',
      '&$focusedLabel': {
        marginTop: '0px',
      },
    },
  },
  focusedLabel: {},
  erroredLabel: {},
});

/**
 * A wrapper for standard HTML input
 * intended to be used with redux-form Field component.
 *
 * @param props
 * @return {Component}
 */

class InputField extends Component {
  render() {
    const {
      input,
      label,
      fullWidth,
      type,
      onChange,
      placeholder,
      classes,
      multiline,
      name,
      value,
      attributes,
      cropWidth,
      rows = 1,
      rowsMax,
      InputLabelProps,
      inputProps,
    } = this.props;

    const hasEndAdornment =
      attributes && attributes.endAdornment ? true : false;

    return (
      <TextField
        type={type}
        onChange={onChange}
        multiline={multiline ? true : false}
        rows={rows}
        rowsMax={rowsMax}
        label={label}
        margin="dense"
        placeholder={placeholder ? placeholder : ''}
        value={value}
        name={name}
        label={label}
        style={{
          width: cropWidth ? '86.5%' : '100%',
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            root: !!value ? classes.focusedLabel : classes.label,
            focused: classes.focusedLabel,
            error: !!value ? classes.focusedLabel : classes.erroredLabel,
          },
        }}
        FormHelperTextProps={{ style: { color: 'red' } }}
        FormHelperTextProps={{
          style: {
            fontSize: '0.75rem',
          },
        }}
        InputProps={{
          inputProps: {
            ...inputProps,
            style: hasEndAdornment
              ? {
                  padding: '15px 0px 15px 15px',
                }
              : !!multiline
              ? {
                  paddingTop: '15px',
                  paddingBottom: '15px',
                }
              : {
                  fontSize: '1rem',
                  lineHeight: '1.1875em',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                },
          },
          ...attributes,
        }}
        variant="outlined"
      />
    );
  }
}

export default withStyles(styles)(InputField);
