import React from 'react';
import DocumentsEmpty from '../../Empty/DocumentsEmpty';
import CustomCard from './CustomCard';
import { Fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    gridGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DocumentsByTitleComponent = ({ listIds, list, from, onDrop, newId }) => {
  const classes = useStyles();
  return Boolean(listIds.length) ? (
    <div className={classes.root}>
      {listIds.map((val, index) => {
        return (
          <div
            key={`card` + index}
            style={{
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Fade in={true} timeout={val === newId ? 400 : 200}>
              <CustomCard
                item={list[val]}
                width="100%"
                height="200px"
                category={list[val].category}
              />
            </Fade>
          </div>
        );
      })}
    </div>
  ) : Boolean(from) ? (
    <DocumentsEmpty
      onDrop={onDrop}
      title="No Records Found"
      action="Create a document entry"
    />
  ) : (
    'No Records Found'
  );
};

const DocumentsByTitle = React.memo(DocumentsByTitleComponent);
export default DocumentsByTitle;
