import React, { Component } from 'react';
import styles from './Loader.css';

class Loader extends Component {
  render() {
    return <div className={styles.simpleLoader}>{this.props.children}</div>;
  }
}

export default Loader;
