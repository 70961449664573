import React from 'react';
import Table from '../../components/ReactTable';
import { dateTimeRender } from '../../components/ReactTable/utils';
import { MVS } from '../../../store/reducers/mediaObject';

const MVSTable = props => {
  const getData = () =>
    props.listIds.map(id => {
      return {
        ...props.list[id],
        id,
        type: MVS,
      };
    });

  React.useEffect(() => {
    setData(getData());
  }, [props.list, props.listIds]);
  const [data, setData] = React.useState(getData());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        col: '20%',
        Cell: ({ value }) => value || 'Unknown',
      },
      {
        Header: 'Dosage',
        accessor: 'dose',
        col: '10%',
        Cell: props => {
          const { value } = props;
          const unit = props.row.original.unit;
          if (value) {
            if (unit) {
              return `${value} ${unit}`;
            } else return value;
          }
          return '--';
        },
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        col: '10%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Started Taking',
        accessor: 'dateStarted',
        col: '10%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'Stopped Taking',
        accessor: 'dateDiscontinued',
        col: '10%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'Prescribed By',
        accessor: 'prescribedBy',
        col: '10%',
        Cell: ({ value }) => value || '--',
      },
    ],
    []
  );
  return (
    <Table
      data={data}
      columns={columns}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      rendersColGroup
    />
  );
};

export default MVSTable;
