import { useEffect, useState } from 'react';

const useDataPoints = (coordinates, keys, reiterator = []) => {
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);

  useEffect(() => {
    if (coordinates.length === 0) {
      if (upperLimit !== 0) {
        setUpperLimit(0);
      }
      if (lowerLimit !== 0) {
        setLowerLimit(0);
      }
      return;
    }
    let max = coordinates[0][keys[0]];
    let min = coordinates[0][keys[0]];
    if (coordinates) {
      coordinates.forEach(item => {
        keys.forEach(key => {
          const _item = item[key];
          if (max < _item) max = _item;
          if (min > _item) min = _item;
        });
        return;
      });
    }

    const baseLine = Math.round(max === min ? max / 2 : (max - min) / 5);
    if (max != upperLimit) setUpperLimit(max + baseLine);
    if (min != lowerLimit) setLowerLimit(min - baseLine);
  }, [coordinates, reiterator]);

  return {
    upperLimit,
    lowerLimit,
  };
};

export default useDataPoints;
