import React from 'react';
import {
  Typography,
  Grid,
  Fade,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import ReduxTextField from './ReduxTextField';
import { maxLength, required, isPositive } from '../../Validations';
import { Field } from 'redux-form';
import { TEMP_FIELD_NAME } from '../../../JournalEntryDrawer';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DurationField from './DurationField';

const maxLength100 = maxLength(100);
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);

const AddActivityItemForm = ({
  fields,
  onChange,
  classes,
  onRemoveClick,
  distanceUnit,
  ...props
}) => {
  const removeField = index => {
    onChange('removedField');
    fields.splice(index, 1);
  };
  return (
    <div>
      {fields.map((activityItems, index) => (
        <Fade in={true} key={index}>
          <Paper>
            <Grid
              container
              spacing={1}
              key={index}
              className={classes.addActivityItemForm}
            >
              <Grid item xs={12} className={classes.removeField}>
                <div
                  onClick={() => removeField(index, 1)}
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  <Typography style={{ color: '#979797', fontSize: '13px' }}>
                    Remove
                  </Typography>
                  <RemoveCircleIcon
                    style={{
                      color: '#ED5158',
                      marginLeft: '3px',
                      fontSize: '21px',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={`${activityItems}.activityName`}
                  validate={[required, maxLength100]}
                  component={ReduxTextField}
                  props={{
                    placeholder: 'Activity / Exercise Name',
                    margin: 'dense',
                    variant: 'outlined',
                    hint: 'Required',
                    divStyle: {
                      maxWidth: '100%',
                    },
                    inputProps: {
                      style: {
                        lineHeight: '1.187em',
                        color: 'rgba(0, 104, 168, 0.65)',
                        fontWeight: '500',
                        fontSize: '.9rem',
                        lineHeight: '24x',
                        padding: '9px',
                      },
                      maxLength: 30,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography
                      style={{
                        color: '#0068A8',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      Distance
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Field
                      name={`${activityItems}.distance`}
                      component={ReduxTextField}
                      validate={[maxLength3, isPositive]}
                      onBlur={() => onChange('distance')}
                      normalize={(value, previousValue) =>
                        parseFloat(value) > 9999
                          ? parseFloat(previousValue)
                          : parseFloat(value)
                      }
                      type="number"
                      props={{
                        name: 'distance',
                        margin: 'dense',
                        placeholder: '0',
                        type: 'number',
                        divStyle: {
                          maxWidth: '100px',
                        },
                        inputProps: {
                          style: {
                            color: '#0068A8',
                            textAlign: 'center',
                            padding: '9px 0px',
                          },
                          min: 1,
                          max: 9999,
                          maxLength: 2,
                        },
                        InputProps: {
                          style: {
                            borderRadius: 4,
                            border: '1px solid #0068A8',
                            backgroundColor: '#EFF1F3',
                          },
                          disableUnderline: 'true',
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              disableTypography
                              style={{
                                fontSize: '10px',
                                color: 'rgba(0, 0, 0, 0.54)',
                                lineHeight: '1.5',
                                marginRight: '5px',
                                marginLeft: '-3px',
                              }}
                            >
                              {distanceUnit}
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: '#0068A8',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      Duration
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Field
                      name={`${activityItems}.durationMinutes`}
                      component={DurationField}
                      validate={[isPositive, maxLength4]}
                      disabled={false}
                      type="number"
                      props={{
                        isDurationCalculated: false,
                        controlledInput: true,
                        name: 'durationMinutes',
                        margin: 'dense',
                        placeholder: '0',
                        divStyle: {
                          marginLeft: '4px',
                        },
                        type: 'number',
                        inputProps: {
                          style: {
                            color: '#0068A8',
                            textAlign: 'center',
                            padding: '9px 0px',
                          },
                          min: 1,
                          maxLength: 2,
                        },
                        InputProps: {
                          style: {
                            borderRadius: 4,
                            border: '1px solid #0068A8',
                            backgroundColor: '#EFF1F3',
                          },
                          disableUnderline: 'true',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography
                      style={{
                        color: '#0068A8',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      {' '}
                      Calories {`\n`} Burned{' '}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Field
                      name={`${activityItems}.caloriesBurned`}
                      component={ReduxTextField}
                      validate={[maxLength3, isPositive]}
                      normalize={(value, previousValue) =>
                        parseFloat(value) > 9999
                          ? parseFloat(previousValue)
                          : parseFloat(value)
                      }
                      onBlur={() => onChange('calories')}
                      type="number"
                      props={{
                        name: 'caloriesBurned',
                        divStyle: {
                          maxWidth: '100px',
                        },
                        margin: 'dense',
                        placeholder: '0',
                        type: 'number',
                        inputProps: {
                          style: {
                            color: '#0068A8',
                            textAlign: 'center',
                            padding: '9px 0px',
                          },
                          min: 1,
                          max: 9999,
                          maxLength: 2,
                        },
                        InputProps: {
                          style: {
                            borderRadius: 4,
                            border: '1px solid #0068A8',
                            backgroundColor: '#EFF1F3',
                          },
                          disableUnderline: 'true',
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              disableTypography
                              style={{
                                fontSize: '10px',
                                color: 'rgba(0, 0, 0, 0.54)',
                                lineHeight: '1.5',
                                marginRight: '5px',
                                marginLeft: '-3px',
                              }}
                            >
                              kcal
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      ))}
      <Grid
        item
        xs={12}
        onClick={() =>
          fields.push({
            caloriesBurnedUnit: 'kcal',
          })
        }
        className={classes.addField}
      >
        <AddCircleIcon style={{ color: '#90B850', marginRight: '8px' }} />{' '}
        <Typography> Add Activity / Exercise </Typography>
      </Grid>
    </div>
  );
};

export default AddActivityItemForm;
