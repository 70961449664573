import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { setMediaObjects } from '../mediaObject/actions';

export const SURGICAL_HISTORY_REQUEST = 'surgical-history/request';
export const SURGICAL_HISTORY_SUCCESS = 'surgical-history/success';
export const SURGICAL_HISTORY_FAILURE = 'surgical-history/failure';

export const SURGICAL_HISTORY_SINGLE_REQUEST =
  'surgical-history/single/request';
export const SURGICAL_HISTORY_SINGLE_SUCCESS =
  'surgical-history/single/success';
export const SURGICAL_HISTORY_SINGLE_FAILURE =
  'surgical-history/single/failure';

export const SURGICAL_HISTORY_CREATE_REQUEST =
  'surgical-history/create/request';
export const SURGICAL_HISTORY_CREATE_SUCCESS =
  'surgical-history/create/success';
export const SURGICAL_HISTORY_CREATE_FAILURE =
  'surgical-history/create/failure';

export const SURGICAL_HISTORY_UPDATE_REQUEST =
  'surgical-history/update/request';
export const SURGICAL_HISTORY_UPDATE_SUCCESS =
  'surgical-history/update/success';
export const SURGICAL_HISTORY_UPDATE_FAILURE =
  'surgical-history/update/failure';

export const SURGICAL_HISTORY_DELETE_REQUEST =
  'surgical-history/delete/request';
export const SURGICAL_HISTORY_DELETE_SUCCESS =
  'surgical-history/delete/success';
export const SURGICAL_HISTORY_DELETE_FAILURE =
  'surgical-history/delete/failure';

export const SURGICAL_HISTORY_UPDATE_SET_ID = 'surgical-history/update/set-id';
export const SURGICAL_HISTORY_UPDATE_CLEAR_ID =
  'surgical-history/update/clear-id';

export const SURGERIES_REMOVE_MEDIA = 'SURGERIES/remove_media';
export const SURGERIES_DELETE_MEDIA = 'SURGERIES/delete_media';
export const SURGERIES_DELETE_MEDIA_CANCEL = 'SURGERIES/delete_media_cancel';
export const UPDATE_SURGERIES_MEDIA = 'ADD_MEDIA/SURGERIES';

export const fetchSurgicalHistories = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      SURGICAL_HISTORY_REQUEST,
      SURGICAL_HISTORY_SUCCESS,
      SURGICAL_HISTORY_FAILURE,
    ],
    endpoint: `/api/history/surgical`,
    meta: {
      pid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const fetchSurgicalHistory = (pid, surgicalHistoryId) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      SURGICAL_HISTORY_SINGLE_REQUEST,
      SURGICAL_HISTORY_SINGLE_SUCCESS,
      SURGICAL_HISTORY_SINGLE_FAILURE,
    ],
    endpoint: `/api/history/surgical/${surgicalHistoryId}`,
    meta: {
      pid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const createSurgicalHistory = (pid, surgery, bundles = []) => {
  const payload = {
    ...surgery,
    personId: pid,
    complications: codeObjectListToCsv(surgery.complications),
    relatedSurgicalPreparations: codeObjectListToCsv(
      surgery.relatedSurgicalPreparations
    ),
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        SURGICAL_HISTORY_CREATE_REQUEST,
        SURGICAL_HISTORY_CREATE_SUCCESS,
        SURGICAL_HISTORY_CREATE_FAILURE,
      ],
      endpoint: `/api/history/surgical`,
      payload: payload,
      meta: {
        pid,
        mediaObject: setMediaObjects(
          bundles,
          'surgicalHistoryId',
          'surgicalhistory'
        ),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const updateSurgicalHistory = (
  pid,
  surgicalHistoryId,
  surgery,
  bundles = []
) => {
  const payload = {
    ...surgery,
    personId: pid,
    surgicalHistoryId,
    complications: codeObjectListToCsv(surgery.complications),
    relatedSurgicalPreparations: codeObjectListToCsv(
      surgery.relatedSurgicalPreparations
    ),
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        SURGICAL_HISTORY_UPDATE_REQUEST,
        SURGICAL_HISTORY_UPDATE_SUCCESS,
        SURGICAL_HISTORY_UPDATE_FAILURE,
      ],
      endpoint: `/api/history/surgical`,
      payload: payload,
      meta: {
        pid,
        mediaObject: setMediaObjects(
          bundles,
          'surgicalHistoryId',
          'surgicalhistory'
        ),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

// export const updateSurgicalHistory = (
//   pid,
//   surgicalHistoryId,
//   surgery,
//   bundles = []
// ) => {
//   return async dispatch => {
//     const payload = {
//       ...surgery,
//       personId: pid,
//       surgicalHistoryId,
//       complications: codeObjectListToCsv(surgery.complications),
//       relatedSurgicalPreparations: codeObjectListToCsv(
//         surgery.relatedSurgicalPreparations
//       ),
//     };

//     dispatch({ type: SURGICAL_HISTORY_UPDATE_REQUEST, payload, bundles });

//     try {
//       const response = await apiFetchAuthorized(`/api/history/surgical`, {
//         method: 'PUT',
//         mode: 'cors',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       const json = await response.json();
//       if (bundles.length > 0) {
//         await dispatch(
//           uploadFiles(pid, surgicalHistoryId, bundles, 'surgicalhistory')
//         );
//       }

//       return await dispatch({
//         type: SURGICAL_HISTORY_UPDATE_SUCCESS,
//         payload: json.data,
//       });
//     } catch (error) {
//       return dispatch({
//         type: SURGICAL_HISTORY_UPDATE_FAILURE,
//         message: error,
//       });
//     }
//   };
// };

export const deleteSurgicalHistory = (pid, surgicalHistoryId, tid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      SURGICAL_HISTORY_DELETE_REQUEST,
      SURGICAL_HISTORY_DELETE_SUCCESS,
      SURGICAL_HISTORY_DELETE_FAILURE,
    ],
    endpoint: `/api/history/surgical/${surgicalHistoryId}`,
    meta: {
      pid,
      surgicalHistoryId,
      tid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});

export const setSelectedId = id => ({
  type: [SURGICAL_HISTORY_UPDATE_SET_ID],
  payload: id,
});

export const clearSelectedId = () => ({
  type: [SURGICAL_HISTORY_UPDATE_CLEAR_ID],
});
