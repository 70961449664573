import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import { useRef } from 'react';

export const VideoLoader = ({
  width,
  height,
  src,
  type,
  frame,
  cover,
  fit,
  controls,
  style,
  preventReload,
  ...rest
}) => {
  const [videoState, setVideoState] = useState({
    isLoaded: false,
    hasError: false,
  });
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    if (preventReload && videoState.isLoaded && videoState.hasError) return;
    setVideoState(state => ({ ...state, isLoaded: false }));

    if (!videoState.isLoaded && src) {
      const video = document.createElement('video');
      video.src = src;

      video.onloadeddata = function() {
        setVideoState({ isLoaded: true, hasError: false });
      };

      video.onerror = function() {
        setVideoState({ isLoaded: false, hasError: true });
      };
    }
  }, [src]);

  useEffect(() => {
    if (preventReload && showControls) return;
    setShowControls(cover ? false : controls);
  }, [cover, controls]);

  const videoStyle = {
    ...style,
    width: width || '100%',
    height: height || 'auto',
    objectFit: (cover || fit) && 'cover',
  };

  const errorStyle = {
    ...videoStyle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ddd',
    color: '#888',
  };

  const getVideoSrc = videoSrc =>
    cover ? `${videoSrc}#t=0.1` : frame ? `${videoSrc}#t=${frame}` : videoSrc;

  const textStyle = {
    textAlign: 'center',
    width: typeof width === 'string' ? width : Math.floor(width / 1.5),
    fontSize:
      typeof width === 'string' ? width : `${Math.floor(0.014 * width) / 2}em`,
  };

  return !src || (!videoState.isLoaded && !videoState.hasError) ? (
    <div style={errorStyle}>
      <p style={textStyle}>Loading video...</p>
    </div>
  ) : !videoState.isLoaded && videoState.hasError ? (
    <div style={errorStyle}>
      <p style={textStyle}>Error loading video</p>
    </div>
  ) : (
    <Fade in={true} timeout={100}>
      <video {...rest} style={videoStyle} controls={showControls}>
        {Array.isArray(src) ? (
          src.map(
            (video, index) =>
              console.log(video) || (
                <source
                  key={index}
                  src={getVideoSrc(video.src)}
                  type={video.type}
                />
              )
          )
        ) : (
          <source src={getVideoSrc(src)} type={type} />
        )}
        Cannot play videos!
      </video>
    </Fade>
  );
};

VideoLoader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        type: PropTypes.oneOf(['video/mp4', 'video/webm']),
      })
    ),
  ]),
  type: PropTypes.string,
  frame: PropTypes.number,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
  fit: PropTypes.bool,
};

VideoLoader.defaultProps = {
  width: '100%',
  height: 'auto',
  frame: 0.1,
  cover: false,
  fit: false,
};

const StyledVideoLoader = VideoLoader;

const VideoProtected = props => {
  const {
    src,
    placeholder,
    controls,
    classes,
    personId,
    preventReload = false,
    ...rest
  } = props;
  const [video, setVideo] = useState(null);

  const controllerRef = useRef();

  useEffect(() => {
    controllerRef.current = new AbortController();
  }, []);

  useEffect(() => {
    if (preventReload && video !== null) return;
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
    if (personId && src) {
      setVideo(null);

      apiFetchAuthorized(
        src,
        {
          signal: controllerRef.current.signal,
          headers: {
            Pid: personId,
          },
        },
        false
      )
        .then(res => res.blob())
        .then(blob => {
          if (!controllerRef.current.signal.aborted) {
            setVideo(URL.createObjectURL(blob));
          }

          //console.log('Video loaded:', URL.createObjectURL(blob));
        });
    }

    return () => controllerRef.current.abort();
  }, [personId, src]);

  return (
    <StyledVideoLoader
      src={video}
      key={new Date()}
      placeholder={placeholder}
      classes={classes}
      controls={controls}
      preventReload={preventReload}
      {...rest}
    />
  );
};

export default VideoProtected;
