import React, { useEffect, useReducer } from 'react';
import {
  Field,
  reduxForm,
  FieldArray,
  change,
  formValueSelector,
  getFormValues,
} from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import {
  Typography,
  TextField,
  Grid,
  Fade,
  Radio,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import { useStyles } from './useStyles';
import { AddActivityItemForm, TotalFormFields } from './fields';
import { useReducerHook } from './useReducer';
import { properties as fragmentProperties } from './properties';

import FragmentDialogBase from '../FragmentDialogBase';
import { TEMP_FIELD_NAME } from '../../JournalEntryDrawer';
import RadioSwitchButton from '../Forms/RadioSwitchButton';
import {
  JOURNAL_MODAL_EDIT,
  JOURNAL_MODAL_COPY,
} from '../../../../../store/reducers/journalmodal/actions';

import ErrorTooltip from '../Forms/ErrorTooltip';
const currentTime = new Date();

import { maxLength, required, isPositive } from '../Validations';

const maxLength2 = maxLength(2);
const maxLength8 = maxLength(8);
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);
const maxLength19 = maxLength(19);

const ActivityExerciseDialog = props => {
  const {
    updateField,
    fragment,
    initialValues,
    activityItems,
    rftempCaloriesBurned,
    totalActivityTimeMins,
    totalCaloriesBurned,
    manualCaloriesBurned,
    manualActivityTimeMins,
    manualDistance,
    totalDistance,
    rftempDistance,
    distanceUnit,
    modalState,
    toggleSaveButton,
    ...rest
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (
      !loadedInitialValues &&
      (modalState === JOURNAL_MODAL_EDIT || modalState === JOURNAL_MODAL_COPY)
    ) {
      dispatch({ type: 'LoadedInitialValues' });
    }
  }, [initialValues]);

  const [state, dispatch] = useReducerHook(
    updateField,
    initialValues,
    activityItems,
    totalActivityTimeMins
  );

  const {
    loadedInitialValues,
    isDurationCalculated,
    isCalorieCalculated,
    isDistanceCalculated,
    shouldCalculate,
  } = state;

  useEffect(() => {
    // functions like validate: required
    // as soon as any of these fields are filled,
    // enable save button
    toggleSaveButton(
      !Boolean(
        rftempCaloriesBurned ||
          rftempDistance ||
          totalActivityTimeMins ||
          manualActivityTimeMins
      )
    );
  }, [
    rftempCaloriesBurned,
    rftempDistance,
    totalActivityTimeMins,
    manualActivityTimeMins,
  ]);

  useEffect(() => {
    let totalCalories = 0;
    if (activityItems && activityItems.length > 0) {
      totalCalories = activityItems.reduce(
        (a, item) => a + parseInt(item.caloriesBurned || 0),
        0
      );
    }
    if (
      totalCaloriesBurned !== totalCalories &&
      totalCaloriesBurned !== undefined
    ) {
      updateField('totalCaloriesBurned', totalCalories);
    }
    if (isCalorieCalculated) {
      const _rftempCaloriesBurned = totalCalories > 0 ? totalCalories : null;
      if (
        _rftempCaloriesBurned !== rftempCaloriesBurned &&
        rftempCaloriesBurned !== undefined
      )
        updateField(`${TEMP_FIELD_NAME}CaloriesBurned`, _rftempCaloriesBurned);
    }

    let totalMinutes = 0;
    if (activityItems && activityItems.length > 0) {
      totalMinutes = activityItems.reduce(
        (a, item) => a + parseInt(item.durationMinutes || 0),
        0
      );
    }

    const _totalActivityTimeMins = !!totalMinutes ? totalMinutes : 0;
    if (
      _totalActivityTimeMins !== totalActivityTimeMins &&
      totalActivityTimeMins !== undefined
    )
      updateField('totalActivityTimeMins', _totalActivityTimeMins);

    let _totalDistance = 0;
    if (activityItems && activityItems.length > 0) {
      _totalDistance = activityItems.reduce(
        (a, item) => a + parseInt(item.distance || 0),
        0
      );
    }

    if (totalDistance !== _totalDistance && totalDistance !== undefined)
      updateField('totalDistance', _totalDistance);
    const _rftempDistance = _totalDistance > 0 ? _totalDistance : null;
    if (
      isDistanceCalculated &&
      _rftempDistance !== rftempDistance &&
      rftempDistance !== undefined
    ) {
      updateField(`${TEMP_FIELD_NAME}Distance`, _rftempDistance);
    }
  }, [
    activityItems,
    manualActivityTimeMins,
    manualCaloriesBurned,
    manualDistance,
    isCalorieCalculated,
    isDurationCalculated,
    isDistanceCalculated,
  ]);

  const handleRadioChange = (type, value) => {
    console.log(type, value);
    if (type === 'isCalorieCalculated' && !value) {
      dispatch({ type: 'isCalorieCalculated', payload: value });
      return;
    }

    if (type === 'isDurationCalculated' && !value) {
      dispatch({ type: 'isDurationCalculated', payload: value });
      return;
    }

    if (type === 'isDistanceCalculated' && !value) {
      dispatch({ type: 'isDistanceCalculated', payload: value });
      return;
    }
    dispatch({ type: type, payload: value });
  };

  const handleChange = (event, value) => {
    console.log(event, value);
    if (
      (event === 'removedField' && isDurationCalculated) ||
      isCalorieCalculated
    ) {
      dispatch({ type: 'CalculateCalories' });
      dispatch({ type: 'CalculateDuration' });
    }

    if (event === 'totalCalories') {
      updateField('manualCaloriesBurned', parseInt(value));
      updateField('totalCaloriesBurned', parseInt(value));
    }
    if (event === 'totalMinutes') {
      dispatch({ type: 'updateManualActivityTime', value: value });
    }
    if (event === 'totalDistance') {
      updateField('manualDistance', parseFloat(value));
      updateField('totalDistance', parseFloat(value));
    }

    if (event === 'calories') {
      dispatch({ type: 'calculateCaloriesBurned' });
    }
  };

  const onClick = type => {
    if (type === 'totalCalories') {
      dispatch({ type: 'isCalorieCalculated', payload: false });
    }
    if (type === 'totalMinutes') {
      dispatch({ type: 'isDurationCalculated', payload: false });
    }
    if (type === 'totalDistance') {
      dispatch({ type: 'isDistanceCalculated', payload: false });
    }
  };

  return (
    <FragmentDialogBase
      color="#2AA5A2"
      defaultTitle="Meal"
      FormSlot1={
        <div>
          <TotalFormFields
            onChange={handleChange}
            isCalorieCalculated={isCalorieCalculated}
            isDurationCalculated={isDurationCalculated}
            isDistanceCalculated={isDistanceCalculated}
            onClick={onClick}
            rftempDistance={rftempDistance}
            totalDistance={totalDistance}
          />
          <Grid container spacing={1} style={{ marginTop: '-24px' }}>
            <Grid item xs={4}>
              <div className={classes.radioFields}>
                <RadioSwitchButton
                  value={isDistanceCalculated}
                  options={['manual', 'calculated']}
                  onChange={() =>
                    handleRadioChange(
                      'isDistanceCalculated',
                      !isDistanceCalculated
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.radioFields}>
                <RadioSwitchButton
                  value={isDurationCalculated}
                  options={['manual', 'calculated']}
                  onChange={() =>
                    handleRadioChange(
                      'isDurationCalculated',
                      !isDurationCalculated
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.radioFields}>
                <RadioSwitchButton
                  value={isCalorieCalculated}
                  options={['manual', 'calculated']}
                  onChange={() =>
                    handleRadioChange(
                      'isCalorieCalculated',
                      !isCalorieCalculated
                    )
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      }
      FormSlot2={
        <Grid container spacing={0} className={classes.additionalFields}>
          <Grid item xs={12}>
            <FieldArray
              name="activityItems"
              classes={classes}
              component={AddActivityItemForm}
              onChange={handleChange}
              distanceUnit={distanceUnit}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => ({
  initialValues: {
    ...fragmentProperties.dataFields,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : {}),
  },
  modalState: state.journalCompose.modalState,
});

let ActivityExerciseFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(ActivityExerciseDialog));

const selector = formValueSelector('reduxForm');
ActivityExerciseFormRedux = connect(state => {
  const activityItems = selector(state, 'activityItems');
  const rftempCaloriesBurned = selector(
    state,
    `${TEMP_FIELD_NAME}CaloriesBurned`
  );
  const rftempDistance = selector(state, `${TEMP_FIELD_NAME}Distance`);

  const totalCaloriesBurned = selector(state, 'totalCaloriesBurned');
  const totalActivityTimeMins = selector(state, 'totalActivityTimeMins');
  const totalDistance = selector(state, 'totalDistance');

  const manualActivityTimeMins = selector(state, 'manualActivityTimeMins');
  const manualCaloriesBurned = selector(state, 'manualCaloriesBurned');
  const manualDistance = selector(state, 'manualDistance');
  const distanceUnit = selector(state, 'distanceUnit');
  return {
    activityItems,
    rftempCaloriesBurned,
    totalCaloriesBurned,
    totalActivityTimeMins,
    manualCaloriesBurned,
    manualActivityTimeMins,
    totalDistance,
    manualDistance,
    rftempDistance,
    distanceUnit,
  };
})(ActivityExerciseFormRedux);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityExerciseFormRedux);
