import React, { Fragment } from 'react';
import {
  Grid,
  Chip,
  TableBody,
  TableRow,
  TableCell,
  Table,
  withStyles,
  Icon,
} from '@material-ui/core';
import MemberDetailLabel from '../MemberDetailLabel';

import ROLES from '../RoleSummary/data';
import chunk from '../../../../library/helpers/chunk';

const styles = ({ theme }) => ({
  root: {
    border: '1px solid #CECECE',
    borderRadius: 4,
    paddingTop: 15,
    paddingLeft: 15,
    marginTop: 0,
  },
  header: {
    fontSize: 24,
    margin: 0,
    marginLeft: 8,
  },
  subHeader: {
    fontSize: 18,
    margin: '10px 0 15px',
  },
  editIcon: {
    marginRight: 8,
  },
  tableCell: {
    padding: 0,
    border: 'none',
    '&:last-child': {
      paddingRight: 10,
    },
  },
  tableRow: {
    height: 32,
    '&:nth-child(even)': {
      backgroundColor: '#F0F0F0',
    },
  },
});

const ReviewAccessItems = props => {
  const { setRole, classes } = props;
  const selectedRole = ROLES.find(role => role.value === setRole);
  const chunkedAccessTypes = chunk(selectedRole.access, 3);

  const setAccessType = type => {
    if (type) {
      if (type === 'r') {
        return <Icon color="primary">remove_red_eye</Icon>;
      }
      if (type === 'rw') {
        return (
          <Fragment>
            <Icon color="secondary" className={classes.editIcon}>
              create
            </Icon>
            <Icon color="primary">remove_red_eye</Icon>
          </Fragment>
        );
      }
    }
    return <Icon>block</Icon>;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <h3>Role</h3>
        <MemberDetailLabel label={setRole} forRole />
      </Grid>
      <Grid item container xs={10} spacing={2}>
        {chunkedAccessTypes.map((accessChunk, i) => (
          <Grid key={i} item xs={4}>
            <Table cellSpacing="0" cellPadding="0">
              <TableBody>
                {accessChunk.map(node => (
                  <TableRow className={classes.tableRow} key={node.value}>
                    <TableCell className={classes.tableCell}>
                      {node.label}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Grid container justify="flex-end">
                        {setAccessType(node.type)}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReviewAccessItems);
