import moment from 'moment';

import { isValidSsn } from '../../../library/validations/Ssn';

export default values => {
  const errors = {};

  const coverageStartOn = moment(values.coverageStartOn);
  const coverageEndOn = moment(values.coverageEndOn);

  if (
    (values.healthPlanName && values.healthPlanName.trim().length === 0) ||
    !values.healthPlanName
  ) {
    errors.healthPlanName = 'Required';
  }

  if (!!!values.coverageType) {
    errors.coverageType = 'Required';
  }

  if (!!!values.coverageStartOn || values.coverageStartOn.length === 0) {
    errors.coverageStartOn = 'Required';
  }

  if (coverageStartOn.isAfter(coverageEndOn)) {
    errors.coverageStartOn =
      'Policy start date must come before the policy end date';
  }

  if (coverageEndOn.isBefore(coverageStartOn)) {
    errors.coverageEndOn =
      'Policy end date must come after the policy start date';
  }

  if (values.guarantorSsn && !isValidSsn(values.guarantorSsn)) {
    errors.guarantorSsn =
      'Invalid Social Security Number (SSN). Standard US format is XXX-XX-XXXX';
  }

  return errors;
};
