import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';

import PageHeader from '../../components/PageHeader';
import BasePage from '../BasePage';
import ButtonGrid from '../../components/ButtonGrid';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

class AccountPage extends Component {
  render() {
    const { active, activeUserRecord } = this.props;
    const { account, home } = this.props.navigation.dashboard;
    const isDependent = Boolean(active.user.type === 'dependent');
    const isPet = Boolean(activeUserRecord && activeUserRecord.type === 'Pet');

    const buttons = [
      {
        glyph: 'user',
        route: account.information,
        label: 'Basic Information',
        display: true,
      },
      {
        glyph: 'id-card',
        route: account.insurance.read,
        label: 'Insurance Information',
        display: true,
      },
      {
        glyph: 'exclamation-circle',
        route: account.emergencyContact.read,
        label: 'Emergency Information',
        display: true,
      },
    ];

    if (!isPet) {
      buttons.push(
        {
          glyph: 'shield',
          route: account.security,
          label: 'Review & Edit Security',
          display: active.user.type === 'primary',
        },
        {
          glyph: 'file-text',
          route: account.userAgreements,
          label: 'User Agreements',
          display: true,
        },
        {
          glyph: 'usd fa-circle',
          route: account.accountManagement,
          label: 'Account Management',
          display: true,
          icon: 'monetization_on',
        }
      );
    }

    if (!isDependent && !isPet) {
      buttons.push({
        glyph: 'sync',
        icon: 'sync',
        route: account.syncPermissions,
        label: 'Sync Permissions',
        display: true,
        disabled: isDependent,
      });
    }

    return (
      <BasePage>
        <Grid item xs={12}>
          <PageHeader
            headerText="Profile / Account"
            backButtonText="Go Back"
            backLink={home}
          />
          <Divider />
          <ButtonGrid data={buttons} />
        </Grid>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  active: state.active,
  activeUserRecord: state.dependents.records.find(
    tab => state.active.user.personId === tab.personId
  ),
});

export default {
  component: connect(mapStateToProps)(AccountPage),
};
