import { isValidPassword } from '../../../library/validations/Password';

export default (values) => {
  const errors = {};

  const required = [
    'oldPassword', 
    'newPassword', 
    'conPassword',  
  ];

  for (let i = 0; i < required.length; i++) {
    if (!values[required[i]]) {
      errors[required[i]] = 'Required';
    }
  }

  if (!isValidPassword(values.oldPassword)) {
    errors.oldPassword = 'Password must be a length of 6 or greater and contain at least one uppercase letter, one lowercase letter, one symbol, and one number.'
  }

  if (!isValidPassword(values.newPassword)) {
    errors.newPassword = 'Password must be a length of 6 or greater and contain at least one uppercase letter, one lowercase letter, one symbol, and one number.'
  }

  if (values.oldPassword == values.newPassword) {
    errors.newPassword = 'The password entered matches the current password saved on record';
  }

  if (values.newPassword !== values.conPassword) {
    errors.conPassword = 'Both passwords should match';
  }

  return errors;
}