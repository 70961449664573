import React, { Component } from 'react';
import {
  Grid,
  Divider,
  Card,
  CardContent,
  CardActions,
  IconButton,
  CardActionArea,
  Button,
  Icon,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  fetchUserPortals,
  deleteUserPortal,
  selectPortalId,
  clearSelectedPortalId,
} from '../../../store/reducers/portals';

import { SUCCESS } from '../../../store/state';

import BasePage, { HEALTH_SERVICES } from '../BasePage';
import PageHeader from '../../components/PageHeader';
import LinkIcon from '@material-ui/icons/Link';
import CardHeader from '@material-ui/core/CardHeader';
import EnhancedTable from './Table';
import AccentedPaper from '../../../library/components/AccentedPaper';

const styles = theme => ({
  icon: {
    margin: theme.spacing(2),
  },
  cardActionFooter: {
    justifyContent: 'space-between',
    paddingRight: '0px',
    height: '48px',
    border: '0',
    borderTop: '2px solid gray',
  },
});

class PortalsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personId: 0,
      personHasChanged: false,
      height: 270,
    };

    this.removePortal = this.removePortal.bind(this);
    this.addPortal = this.addPortal.bind(this);
  }

  componentDidMount() {
    const { fetchUserPortals } = this.props;
    let { didInvalidate } = this.props;

    if (didInvalidate) fetchUserPortals(this.state.personId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.personId !== prevState.personId) {
      return {
        personId: nextProps.personId,
        personHasChanged: true,
      };
    }

    return null;
  }

  // Dependent on getDerivedStateFromProps
  componentDidUpdate(prevProps) {
    if (
      prevProps.personId !== this.state.personId &&
      this.state.personHasChanged
    ) {
      this.props.fetchUserPortals(this.state.personId);
      this.setState({ personHasChanged: false });
    }
  }

  _rowClassName({ index }) {
    if (index < 0) {
      return styles.headerRow;
    } else {
      return index % 2 === 0 ? styles.evenRow : styles.oddRow;
    }
  }

  _getDatum(list, index) {
    return list[index];
  }

  _urlRender(portal) {
    return (
      <a target="_blank" href={portal.cellData}>
        {portal.cellData}
      </a>
    );
  }

  openPortalTab(e, portal) {
    window.open(portal.portalUrl, '_blank');
  }

  addPortal() {
    const route = this.props.navigation.dashboard.healthRecord;

    this.props.clearSelectedPortalId();
    this.props.history.push(route.portals.create);
  }

  editPortal = (e, portal) => {
    const route = this.props.navigation.dashboard.healthRecord;
    this.props.selectPortalId(portal.portalId);
    this.props.history.push(route.portals.update);
  };

  removePortal = (e, portal) => {
    this.props.deleteUserPortal(portal);
  };

  render() {
    const { listIds, portalList, classes, uiState } = this.props;
    const { dashboard } = this.props.navigation;

    return (
      <BasePage
        type={HEALTH_SERVICES}
        state={uiState}
        emptyStateComponentProps={{
          name: 'Portals',
          type: 'Patient Portals',
          onClick: this.addPortal,
        }}
      >
        <Grid item xs={12}>
          <PageHeader
            headerText="Patient Portals"
            subHeaderText="View Records"
            backButtonText="Go Back"
            backLink={dashboard.account.self}
            rightSlot={
              <Button
                color="primary"
                variant="contained"
                aria-label="Add"
                onClick={this.addPortal}
                style={{ float: 'right' }}
              >
                <Icon>add</Icon>
                New Portal
              </Button>
            }
          />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: 16,
            }}
          >
            {listIds.map(key => {
              const portal = portalList[key];
              return (
                <div
                  key={portal.portalId}
                  style={{ margin: '3px 4px 3px 4px' }}
                >
                  <AccentedPaper>
                    <CardActionArea
                      onClick={e => this.openPortalTab(e, portal)}
                      style={{ height: '120px' }}
                    >
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                          height: '100%',
                          paddingBottom: '8px',
                        }}
                      >
                        <div>
                          <Typography
                            style={{ fontWeight: '700', color: '#444447' }}
                            variant="h6"
                          >
                            {portal.portalName}
                          </Typography>
                          <Typography
                            style={{ marginTop: '-2px' }}
                            color="primary"
                            variant="subtitle2"
                          >
                            {portal.providerName}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#AEAEAE',
                            }}
                            variant="caption"
                          >
                            <LinkIcon
                              style={{
                                height: '1rem',
                                width: '1rem',
                                marginBottom: '1px',
                                marginRight: '2px',
                              }}
                            />{' '}
                            {portal.portalUrl}
                          </Typography>
                        </div>
                      </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.cardActionFooter}>
                      <IconButton
                        size="small"
                        onClick={e => this.removePortal(e, portal)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        style={{
                          backgroundColor: '#90B850',
                          transform: 'scale(0.7)',
                        }}
                        onClick={e => this.editPortal(e, portal)}
                      >
                        <EditIcon />
                      </IconButton>
                    </CardActions>
                  </AccentedPaper>
                </div>
              );
            })}
          </div>
        </Grid>
      </BasePage>
    );
    {
      /* // return isFetching ? <div>Loading...</div> : (
        //     <BasePage>
        //         <Grid item xs={12}>
        //             <PageHeader
        //                 headerText="Patient Portals"
        //                 subHeaderText="View Records"
        //                 backButtonText="Go Back"
        //                 rightSlot={
        //                     <button className="btn btn-primary pull-right" onClick={this.addPortal}>
        //                         <i className="fa fa-plus" /> New Record
        //                     </button>
        //                 }
        //             />
        //         </Grid>
        //         <Grid item xs={12}>
        //             <div>
        //                 <EnhancedTable headerData={[
        //                         { id: 'providername', numeric: false, disablePadding: false, label: 'Provider' },
        //                         { id: 'portalname', numeric: false, disablePadding: false, label: 'Name' },
        //                         { id: 'portalurl', numeric: false, disablePadding: false, label: 'URL' }
        //                     ]} data={Object.keys(portalList).map((key) => portalList[key])} 
        //                     editRow={this.editPortal} 
        //                     deleteRow={this.removePortal}/>
        //             </div>
        //         </Grid>
        //     </BasePage>
        // ) */
    }
  }
}

const mapStateToProps = state => ({
  user: state.app.user,
  portalList: state.portals.portalList,
  listIds: state.portals.listIds,
  personId: state.active.user.personId,
  didInvalidate: state.portals.didInvalidate,
  uiState: state.portals.uiState,
});

const mapDispatchToProps = dispatch => ({
  fetchUserPortals: id => dispatch(fetchUserPortals(id)),
  deleteUserPortal: portal => dispatch(deleteUserPortal(portal)),
  selectPortalId: id => dispatch(selectPortalId(id)),
  clearSelectedPortalId: () => dispatch(clearSelectedPortalId()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(PortalsPage))),
};
