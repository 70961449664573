// import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { createQueryStringFromObject } from '../../../library/helpers/encode';
import { uploadFiles } from '../../../store/reducers/mediaObject';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';

export const REQUEST_USER_ALLERGIES = '@@redux/member/REQUEST_USER_ALLERGIES';
export const RECEIVE_USER_ALLERGIES = '@@redux/member/RECEIVE_USER_ALLERGIES';
export const FAILURE_USER_ALLERGIES = '@@redux/member/FAILURE_USER_ALLERGIES';
export const CREATE_USER_ALLERGY = '@@redux/member/CREATE_USER_ALLERGY';

export const USER_ALLERGY_CREATE = '@@redux/member/USER_ALLERGY_CREATE';
export const USER_ALLERGY_CREATE_SUCCESS =
  '@redux/member/USER_ALLERGY_CREATE_SUCCESS';
export const USER_ALLERGY_CREATE_FAILURE =
  '@redux/member/USER_ALLERGY_CREATE_FAILURE';

export const requestUserAllergies = () => ({
  type: REQUEST_USER_ALLERGIES,
});

export const receiveUserAllergies = allergies => ({
  type: RECEIVE_USER_ALLERGIES,
  payload: allergies,
});

export const failureUserAllergies = message => ({
  type: FAILURE_USER_ALLERGIES,
  message,
});

export const createUserAllergy = (id, allergy, bundles) => {
  const csvList = array =>
    array && array.length > 0 ? array.map(obj => obj.shortName).join(', ') : '';

  const payload = {
    name: '',
    allergenType: allergy.name ? allergy.name : '',
    startOn: allergy.startOn.format(),
    endOn: allergy.endOn ? allergy.endOn.format() : '',
    reaction: csvList(allergy.reaction),
    treatment: csvList(allergy.treatment),
    comments: allergy.comments ? allergy.comments : '',
  };

  return async dispatch => {
    dispatch({ type: USER_ALLERGY_CREATE });

    try {
      const response = await apiFetchAuthorized(`/api/member/${id}/allergy`, {
        method: 'POST',
        mode: 'cors',
        body: createQueryStringFromObject(payload),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const json = await response.json();

      if (bundles.length > 0) {
        await dispatch(
          uploadFiles(id, json.data.allergyId, bundles, 'ALLERGIES')
        );
      }

      await dispatch({ type: USER_ALLERGY_CREATE_SUCCESS, payload: json });
    } catch (error) {
      dispatch({ type: USER_ALLERGY_CREATE_FAILURE, message: error });
    }

    // dispatch({ [DISPATCH_API_AUTHORIZED]: {
    //     types: [CREATE_USER_ALLERGY, USER_ALLERGY_CREATE_SUCCESS, USER_ALLERGY_CREATE_FAILURE],
    //     endpoint: `/api/member/${id}/allergy`,
    //     payload,
    //     meta: {
    //       config: {
    //           headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //           method: 'POST',
    //           mode: 'cors',
    //           body: createQueryStringFromObject(payload)
    //       }
    //   }
    // }}).then(response => {

    //   dispatch(uploadFiles(id, response.payload.allergyId, bundles, 'ALLERGIES'))

    // })
    // .catch(error => console.log('Something went wrong!', error))
  };
};
