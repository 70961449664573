import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageHeader from '../../components/PageHeader';

// eslint-disable-next-line
import styles from '../FormStyles.css';
import Divider from '@material-ui/core/Divider';
import MedicalConditionsForm from '../../components/MedicalConditionsForm';
import {
  fetchFamilyHistory,
  updateFamilyHistoryCondition,
  updateFamilyHistoryConditionBulk,
} from '../../../store/reducers/familyHistory/actions';
import BasePage from '../BasePage';
import Grid from '@material-ui/core/Grid';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

class MedicalConditionPage extends Component {
  state = {
    personId: 0,
  };

  componentDidMount() {
    const { fetchFamilyHistory } = this.props;
    fetchFamilyHistory(this.state.personId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.personId !== prevState.updateReference) {
      return {
        personId: nextProps.personId,
      };
    }

    return null;
  }

  // Dependent on getDerivedStateFromProps
  componentDidUpdate(prevProps) {
    if (prevProps.personId !== this.state.personId) {
      this.props.fetchFamilyHistory(this.state.personId);
    }
  }

  handleOnSave = data => {
    const { updateFamilyHistoryCondition } = this.props;
    updateFamilyHistoryCondition(this.state.personId, data);
  };

  handleOnSaveBulk = data => {
    this.props.updateFamilyHistoryConditionBulk(data);
  };

  render() {
    const {
      savingCondition,
      isSubmitting,
      isFetchingFamilyHistory,
      list,
      navigation,
    } = this.props;

    return (
      <BasePage state={this.props.uiState}>
        <Grid item xs={12}>
          <PageHeader
            headerText="Family History"
            subHeaderText="Medical Conditions"
            backButtonText="Go Back"
            backLink={navigation.dashboard.healthRecord.self}
            rightSlot={
              false && (
                <button
                  type="button"
                  className={`btn btn-default pull-right ${styles.btnPe}`}
                >
                  <i className="fa fa-print fa-lg" aria-hidden="true"></i> Print
                  / Export
                </button>
              )
            }
          />
          <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        </Grid>
        <Grid item lg={10} md={12} sm={12}>
          <p>
            Use this section to keep track of your family's medical history.
            Each condition is initially set to <strong>Unknown</strong>. For
            each condition where you know you family's history, you will need to
            either indicate which family member(s) had the condition, or change
            to <strong>None</strong> if no one in your family has ever had that
            condition. If you're unsure of a particular condition in your
            family, leave as <strong>Unknown</strong>.
          </p>
          <MedicalConditionsForm
            historyList={list}
            submitting={isSubmitting}
            savingCondition={savingCondition}
            onSave={this.handleOnSave}
            onSaveBulk={this.handleOnSaveBulk}
          />
        </Grid>
      </BasePage>
    );
  }
}

MedicalConditionPage.propTypes = {
  list: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  active: state.active,
  user: state.app.user,
  personId: state.active.user.personId,
  savingCondition: state.familyHistory.savingCondition,
  isSubmitting: state.familyHistory.isSubmitting,
  isFetchingFamilyHistory: state.familyHistory.isFetchingFamilyHistory,
  list: state.familyHistory.list,
  uiState: state.familyHistory.uiState,
});

const mapDispatchToProps = dispatch => ({
  fetchFamilyHistory: pid => dispatch(fetchFamilyHistory(pid)),
  updateFamilyHistoryCondition: (pid, data) =>
    dispatch(updateFamilyHistoryCondition(pid, data)),
  updateFamilyHistoryConditionBulk: payload =>
    dispatch(updateFamilyHistoryConditionBulk(payload)),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(MedicalConditionPage),
};
