import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';

import CancelIcon from '@material-ui/icons/Cancel';

const styles = theme => ({
  input: {
    display: 'flex',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: '0px 10px',
    opacity: 0.7,
  },
  menu: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    zIndex: 999,
    left: 0,
    right: 0,
  },
  errorIndicatorDropdown: {
    color: theme.palette.error.main,
  },
  errorIndicatorSeparator: {
    backgroundColor: theme.palette.error.main,
  },
});

const NoOptionsMessage = props => {
  const {
    innerProps,
    selectProps: { classes },
    children,
  } = props;

  return (
    <Typography className={classes.noOptionsMessage} {...innerProps}>
      {children}
    </Typography>
  );
};

const Menu = props => {
  const {
    innerProps,
    selectProps: { classes },
    children,
  } = props;

  return (
    <Paper square elevation={10} className={classes.menu} {...innerProps}>
      {children}
    </Paper>
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const IndicatorSeparator = () => {
  return '';
};

const components = {
  Option,
  NoOptionsMessage,
  Menu,
  IndicatorSeparator,
};

const isValidNewOption = (inputValue, selectValue, selectOptions) => {
  const isNotEmpty = inputValue !== '';
  const isNotDuplicated = !selectOptions
    .map(option => option.shortName)
    .includes(inputValue);
  return isNotEmpty && isNotDuplicated;
};

const createOption = isValueLabel => (shortName, longName) => ({
  shortName: isValueLabel ? longName : shortName,
  longName,
  __isNew__: true,
});

const InputSelect = props => {
  const {
    theme,
    hint,
    options,
    isCreatable,
    isValueLabel = false,
    input: { value, onBlur, onFocus, onChange },
    meta: { touched, error, active },
  } = props;

  //Code needed for handling inputs that are not handled by redux form.

  const hasError = !!(touched && error);
  const Component = isCreatable ? Creatable : Select;

  const selectStyles = {
    control: (base, state) => ({
      // ...base,
      // background: 'red',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      outline: '0 !important',
      position: 'relative',
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      boxSizing: 'border-box',
      borderStyle: 'none',
      borderWidth: 0,
      borderRadius: 0,
      borderColor: 'transparent',
      transition: 'all 100ms',
      padding: '5px 10px',
      '&:hover': {
        background: '#eee',
      },
    }),
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
        padding: 10,
      },
      padding: 10,
      margin: 0,
      borderStyle: 'none',
      borderWidth: 0,
      borderRadius: 0,
      borderColor: 'transparent',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: base => ({
      ...base,
      color: hasError ? theme.palette.error.main : base.color,
    }),
    indicatorSeparator: base => ({
      ...base,
      background: hasError ? theme.palette.error.main : base.background,
      marginLeft: 8,
      marginRight: 8,
    }),
  };

  return (
    <NoSsr>
      <Component
        {...props}
        value={value}
        // onFocus={() => onFocus()}
        // onBlur={() => onBlur(value)}
        onChange={value => onChange(value)}
        isValidNewOption={isValidNewOption}
        getNewOptionData={createOption(isValueLabel)}
        options={options}
        components={components}
        styles={selectStyles}
      />
    </NoSsr>
  );
};

export default withStyles(styles, { withTheme: true })(InputSelect);
