import {
  required,
  minLength,
  maxLength,
  minAge,
  maxAge,
  compareString,
  zipCode,
  email,
  password,
  validate,
} from '../../../../library/validate/';

export default values => {
  const errors = {};

  errors.firstName = validate(
    required('Required'),
    minLength(2, 'Must have a minimum length of {{min}} characters'),
    maxLength(
      100,
      'We only store up to {{max}} characters. The first name provided is {{length}} characters long. Please provide a shorter name'
    )
  )(values.firstName);

  errors.lastName = validate(
    required('Required'),
    minLength(2, 'Must have a minimum length of {{min}} characters'),
    maxLength(
      100,
      'We only store up to {{max}} characters. The last name provided is {{length}} characters long. Please provide a shorter name'
    )
  )(values.lastName);

  errors.dob = validate(
    required('Required'),
    minAge(18, 'Must be at least {{age}} years of age to become a member'),
    maxAge(129, 'Must be under 130 years of age')
  )(values.dob);

  errors.postalCode = validate(
    required('Required'),
    zipCode('Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX')
  )(values.postalCode);

  errors.email = validate(
    required('Required'),
    email('Email address not valid')
  )(values.email);

  errors.emailConfirm = validate(
    required('Required'),
    compareString(values.email, 'Both emails must match')
  )(values.emailConfirm);

  errors.password = validate(
    required('Required'),
    password(
      'Password must be a length of 6 or greater and contain at least one uppercase letter, one lowercase letter, one symbol, and one number.'
    )
  )(values.password);

  errors.passwordConfirm = validate(
    required('Required'),
    compareString(values.password, 'Both passwords must match')
  )(values.passwordConfirm);

  return errors;
};
