import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Switch, Route } from 'react-router';
import { reset, SubmissionError } from 'redux-form';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';

/* ==================================================
/* Registration Form imports
/* ================================================== */
import Header from '../../components/Header';
import CheckoutForm from '../../containers/RegistrationForms/CheckoutForm';
import VerifyIdentityForm from '../../containers/RegistrationForms/VerifyIdentityForm';
import GeneralInfoForm from '../../containers/RegistrationForms/GeneralInfoForm';
import VerifyCodeForm from '../../containers/RegistrationForms/VerifyCodeForm';
import FinishedForm from '../../containers/RegistrationForms/FinishedForm';
import { styles } from './styles';
import { apiFetch, apiPost } from '../../../library/helpers/fetch';
import { loginUser } from '../../containers/SignInFormMember/actions';
import HorizontalStepper from '../../components/HorizontalStepper';

const GENERAL_FAILURE_MESSAGE =
  "We're sorry, this service is temporarily unavailable. Please try again later. If the problem persists, please contact support at support@agrinhealth.com";

export const getAvailableProducts = async () => {
  try {
    const response = await apiFetch('/api/products');
    const json = await response.json();
    if (json.status === 200) {
      return json.data;
    }
  } catch (e) {
    throw Error(e);
  }

  return null;
};

const steps = [{ label: 'Create Account' }, { label: 'Verify Identity' }];

const lowercase = value => (value ? value.toLowerCase() : null);

const MemberRegistrationPage = props => {
  const [products, setProducts] = useState(null);

  const [activeStep, setActiveStep] = useState(
    props.location.pathname === '/registration/identity-verification' ? 1 : 0
  );

  const [message, setMessage] = useState(null);

  const [user, setUser] = useState({
    id: props.location.state && props.location.state.userId,
    username: props.location.state && props.location.state.username,
    password: props.location.state && props.location.state.password,
  });

  const [verificationCode, setVerificationCode] = useState({
    successMessage: null,
    failureMessage: null,
    isRequesting: false,
  });

  const [checkout, setCheckout] = useState({
    isRequesting: false,
    failureMessage: null,
    success: false,
  });

  const fetchAvailableProducts = async () => {
    const data = await getAvailableProducts();
    setProducts(data);
  };

  useEffect(() => {
    if (
      (!user.id &&
        [
          '/registration/identity-verification',
          '/registration/verify',
        ].includes(props.location.pathname)) ||
      (!user.username &&
        !user.password &&
        props.location.pathname === '/registration/verify')
    ) {
      props.history.push('/');
    }
  }, [props.location]);

  useEffect(() => {
    fetchAvailableProducts();
  }, []);

  const registrationAccount = async values => {
    try {
      const response = await apiPost('/api/registration/new/1', {
        ...values,
        email: lowercase(values.email),
        emailConfirm: lowercase(values.emailConfirm),
      });

      const json = await response.json();
      if (json && json.data) {
        setUser(json.data);
        setActiveStep(state => state + 1);
        props.history.replace('/registration/identity-verification');
      } else if (json.status === 500) {
        setMessage(GENERAL_FAILURE_MESSAGE);
      } else {
        throw new SubmissionError(json.errors);
      }
    } catch (e) {
      if (e instanceof SubmissionError) {
        throw e;
      } else {
        setMessage(GENERAL_FAILURE_MESSAGE);
      }
    }
  };

  const registrationCheckout = async values => {
    if (!checkout.isSubmitting) {
      setCheckout({ isSubmitting: true });
      try {
        const response = await apiPost('/api/registration/new/3', values);
        const json = await response.json();
        if (json.status === 200) {
          setCheckout(state => ({ ...state, success: true }));
          return;
        }

        if (json.status === 400) {
          setCheckout({ failureMessage: json.message, isSubmitting: false });
          // props.history.push('/');
        }

        setCheckout({ failureMessage: json.message, isSubmitting: false });
      } catch (e) {
        setCheckout({
          isSubmitting: false,
          failureMessage: GENERAL_FAILURE_MESSAGE,
        });
        throw e;
      }
    }
  };

  const verifyEmail = async values => {
    try {
      const response = await apiPost('/api/registration/new/2', {
        id: user.id,
        code: values.verificationCode,
      });
      const json = await response.json();
      if (json.status === 200) {
        const [_, token] = await props.loginUser({
          username: props.location.state.username,
          password: props.location.state.password,
        });

        if (token.initialized === 'False') {
          props.history.push('/account/initialize');
          return;
        }

        props.history.push('/d/me/home');
      } else {
        throw new SubmissionError({ verificationCode: json.message });
      }
    } catch (e) {
      throw e;
    }
  };

  const handleSuccess = async values => {
    await registrationCheckout({
      userId: user.id,
      ...values,
    });
    setCheckout({
      isRequesting: false,
      failureMessage: null,
      success: true,
    });
  };

  const handleFinishFinishedForm = () =>
    props.history.push({
      pathname: '/',
      state: {
        render_dashboard_message: true,
        message: `Success! Your Agrin Health account has been created. You can now signin.`,
        type: 'success',
      },
    });

  const handleNewVerificationCode = async () => {
    if (!verificationCode.isRequesting) {
      setVerificationCode({ isRequesting: true });

      try {
        const response = await apiPost(
          '/api/registration/email/requestverificationcode',
          {
            id: props.location.state.userId,
          }
        );

        const json = await response.json();

        if (json.status === 200) {
          setVerificationCode(state => ({
            ...state,
            isRequesting: false,
            successMessage: 'New verification code sent. Check your email.',
          }));
          return;
        }

        setVerificationCode(state => ({
          ...state,
          isRequesting: false,
          failureMessage: GENERAL_FAILURE_MESSAGE,
        }));
      } catch (e) {
        setVerificationCode(state => ({
          ...state,
          isRequesting: false,
          failureMessage: GENERAL_FAILURE_MESSAGE,
        }));
        throw e;
      }
    }
  };

  const { classes } = props;

  // return (
  //   <Route exact path="/registration/member-details">
  //     <Grid className={classes.root} container justify="center">
  //       <Grid item md={8} lg={6} xl={6}>
  //         <CheckoutForm
  //           products={products}
  //           fetchProducts={fetchAvailableProducts}
  //           failureMessage={checkout.failureMessage}
  //           isSubmitting={checkout.isSubmitting}
  //           onSubmit={async values => {
  //             await registrationCheckout({
  //               ...values,
  //               userId: user.id,
  //             });
  //           }}
  //         />
  //       </Grid>
  //     </Grid>
  //   </Route>
  // );

  return (
    <React.Fragment>
      {checkout.success ||
      ~props.location.pathname.indexOf('/registration/verify') ? (
        ''
      ) : (
        <Grid container justify="center">
          <Grid item className={classes.stepper} xs={12} md={10} lg={8} xl={8}>
            <HorizontalStepper data={steps} activeStep={activeStep} />
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.root} justify="center">
        <Header title="Member Registration" />
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Switch>
            <Route exact path="/registration/member-details">
              <GeneralInfoForm
                classes={classes}
                failureMessage={message}
                onSubmit={async (values, _, formProps) => {
                  // This condition is needed to prevent the transition to the next
                  // form state. For reasons not understood atm, a invalid form still
                  // submits successfully when it should be blocked.
                  if (formProps.valid) {
                    await registrationAccount(values);
                  }
                }}
              />
            </Route>
            <Route
              exact
              path="/registration/identity-verification"
              render={routerProps =>
                !checkout.success ? (
                  <VerifyIdentityForm
                    {...routerProps}
                    products={products}
                    fetchProducts={fetchAvailableProducts}
                    failureMessage={checkout.failureMessage}
                    isSubmitting={checkout.isSubmitting}
                    handleSuccess={handleSuccess}
                    userId={user.id}
                    onSubmit={async values => {
                      await registrationCheckout({
                        ...values,
                        userId: user.id,
                      });
                      setActiveStep(state => state + 1);
                    }}
                  />
                ) : (
                  <FinishedForm onSubmit={handleFinishFinishedForm} />
                )
              }
            ></Route>
            <Route exact path="/registration/verify">
              <VerifyCodeForm
                email={
                  user.username === undefined || !user.username
                    ? ''
                    : user.username
                }
                successMessage={verificationCode.successMessage}
                failureMessage={verificationCode.failureMessage}
                isRequestingNewCode={verificationCode.isRequesting}
                onRequestNewCode={handleNewVerificationCode}
                onSubmit={verifyEmail}
              />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  loginUser: credentials => dispatch(loginUser(credentials)),
  createMessage: message => dispatch(createMessage(message, 'warning')),
});

export default {
  component: connect(
    state => state,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(MemberRegistrationPage))),
};
