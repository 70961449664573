import React from 'react';
import ImageProtected from '../../components/Image/Image';
import { IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EmptyIcon from '@material-ui/core/Icon';

const imageStyle = {
  width: '100%',
  height: 260,
  objectFit: 'cover',
  border: 'solid 1px #ccc',
  borderRadius: 5,
};

const previewImageStyle = {
  marginTop: 8,
  padding: 0,
  borderRadius: 5,
};

const boxStyle = {
  ...previewImageStyle,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#ccc',
  height: 260,
  padding: 24,
};

export default function InsuranceCardPreview({
  face,
  src,
  currentSelection,
  onDelete,
}) {
  const hasImagePreview = Boolean(src);
  const nomalizedFace = face.toLowerCase();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>Insurance Card ({face})</span>
        {currentSelection || hasImagePreview ? (
          <Tooltip title="Delete Card">
            <IconButton
              size="small"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onDelete(currentSelection || src, face);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            size="small"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(currentSelection || src, face);
            }}
            disabled
          >
            <EmptyIcon />
          </IconButton>
        )}
      </div>
      <div
        style={
          hasImagePreview || currentSelection ? previewImageStyle : boxStyle
        }
      >
        {currentSelection && !hasImagePreview ? (
          <ImageProtected
            style={imageStyle}
            personId={currentSelection.personId}
            placeholder={currentSelection.base64DataUriString}
            src={currentSelection.links.self}
            alt={currentSelection.description}
            height={260}
          />
        ) : hasImagePreview ? (
          <img
            style={imageStyle}
            src={src}
            alt={`${nomalizedFace} of insurance card`}
          />
        ) : (
          <div className="well" style={{ padding: 35 }}>
            Click here to select an image that represents the{' '}
            <strong>{nomalizedFace}</strong> of the insurance card
          </div>
        )}
      </div>
    </div>
  );
}
