import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Divider, Button, Fade, Collapse } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import BasePage from '../BasePage';
import DocumentsPageHeader from './DocumentsPageHeader';
import {
  fetchDocuments,
  toggleDocumentsDrawer,
  clearMessage,
  clearUpdates,
} from '../../../store/reducers/documents/actions';
import { setDrawerState } from '../../../store/reducers/mediaObjectDrawer/actions';
import { fetchDocumentType } from '../../../store/reducers/codes';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

import {
  DocumentSliderContainer,
  DocumentsEmpty,
} from '../../components/Documents';
import LoadingMessage from '../../../library/components/Loading';
import { Message } from '@material-ui/icons';

export const status = {
  //state enum
  LOADING: 'loading',
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
};

const DocumentsPage = props => {
  const {
    hasFetched,
    hasError,
    error,
    hasRecords,
    list,
    hasSubmitted,
    isDrawerOpen,
    newId,
    hasDeleted,
    successMessage,
    hasCategoryOptions,
    categoryOptions,
  } = props.documents;

  const { LOADING, EMPTY, SUCCESS, ERROR } = status;

  const [state, setState] = useState(LOADING);
  const [message, setMessage] = useState({
    hasMessage: false,
    type: null,
    message: '',
  });

  useEffect(() => {
    const hasUpdates = Boolean(Boolean(newId) || hasDeleted || hasSubmitted);
    if (hasUpdates) {
      setTimeout(() => {
        props.clearUpdates();
      }, 3000);
    }
  }, [newId, hasDeleted]);

  useEffect(() => {
    if (Boolean(successMessage)) {
      setMessage({
        hasMessage: true,
        type: 'success',
        message: successMessage,
      });
      setTimeout(() => {
        props.clearMessage();
        setMessage({
          hasMessage: false,
          type: null,
          message: '',
        });
      }, 10000);
      return;
    }
    if (Boolean(error)) {
      setMessage({
        hasMessage: true,
        type: 'warning',
        message: error,
      });
      return;
    }
  }, [successMessage, error]);

  useEffect(() => {
    if (hasFetched) {
      if (hasRecords) {
        if (hasCategoryOptions) {
          setState(SUCCESS);
        }
        // else if (hasError) { No need to switch state to error, just display error message
        //   setState(ERROR);
        // }
      }
      if (!hasRecords && !hasError) {
        setState(EMPTY);
      }
      if (!hasRecords && hasError) {
        setState(ERROR);
      }
    } else if (state !== LOADING) setState(LOADING);
  }, [props.documents]);

  useEffect(() => {
    if (Boolean(props.documents.activeUser)) {
      if (!hasCategoryOptions) props.fetchDocumentType();
      if (message.hasMessage) {
        setMessage({
          hasMessage: false,
          type: null,
          message: '',
        });
      }
      setState(LOADING);
      props.fetchDocuments(props.documents.activeUser);
    }
  }, [props.documents.activeUser]);

  const handleDrawerOpen = () => {
    props.setDrawerState('ADD');
    props.toggleDocumentsDrawer(true);
  };

  const handleOnDrop = () => {
    props.setDrawerState('ADD');
    props.toggleDocumentsDrawer(true);
  };

  const handleMessageClose = () => {
    props.clearMessage();
    setMessage({
      hasMessage: false,
      type: null,
      message: '',
    });
  };

  const renderDocumentsView = () => {
    switch (state) {
      case LOADING:
        return <LoadingMessage message="Loading Documents" />;
      case EMPTY:
        return (
          <DocumentsEmpty
            title="Welcome to My Documents!"
            subtitle={`This is where you can store any documents you may want to keep 
            and/or share... for example, vaccination record, living will, 
            hospital discharge instructions, etc.`}
            action="Add a document to get started!"
            onDrop={handleOnDrop}
          />
        );
      case SUCCESS:
        return (
          <DocumentSliderContainer
            personId={props.documents.activeUser}
            documents={props.documents}
            from={
              Boolean(props.location.state) &&
              Boolean(props.location.state.from) &&
              props.location.state.from
            }
            newId={newId}
            hasUpdates={Boolean(Boolean(newId) || hasDeleted || hasSubmitted)}
            onDrop={handleOnDrop}
          />
        );
      case ERROR:
        return <div></div>;
    }
  };

  return (
    <BasePage
      HeaderComponent={<DocumentsPageHeader onClick={handleDrawerOpen} />}
    >
      <Grid
        item
        xs={12}
        style={{ padding: message.hasMessage ? '16px' : '0px' }}
      >
        <Collapse in={message.hasMessage} timeout={150}>
          <div style={{ width: '100%', maxWidth: '600px', marginTop: '8px' }}>
            <DashboardMessage
              onClose={handleMessageClose}
              canClose={true}
              type={message.type || 'success'}
            >
              {message.message}
            </DashboardMessage>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        {renderDocumentsView()}
      </Grid>
      {/* Moved MediaObjectDrawer to DashboardPage to prevent needless rerenders in the Document module*/}
    </BasePage>
  );
};
const mapStateToProps = state => ({
  mediaObjects: state.mediaObject.bundles,
  isUploading: state.mediaObject.isUploadingFiles,
  documents: state.documents,
  activeUser: state.active.user,
});

const mapDispatchToProps = dispatch => ({
  setMedia: bundle => dispatch(setMedia(bundle)),
  setDrawerState: type => dispatch(setDrawerState(type)),
  fetchDocuments: id => dispatch(fetchDocuments(id)),
  toggleDocumentsDrawer: bool => dispatch(toggleDocumentsDrawer(bool)),
  clearMessage: () => dispatch(clearMessage()),
  clearUpdates: () => dispatch(clearUpdates()),
  fetchDocumentType: () => dispatch(fetchDocumentType()),
});

DocumentsPage.defaultProps = {
  location: {
    state: {
      from: '',
    },
  },
};

export default {
  component: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DocumentsPage)
  ),
};
