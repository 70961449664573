import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: '12px 0px 0px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1) / 2,
    top: theme.spacing(1) / 2,
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  dialogTitle: {
    fontWeight: '500',
    textAlign: 'center',
    fontSize: '18px',
  },
});

function DialogTitle(props) {
  const { children, classes, onClose } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.dialogTitle}>{children}</Typography>
      {onClose ? (
        <CloseIcon onClick={onClose} className={classes.closeButton} />
      ) : null}
    </div>
  );
}

DialogTitle.displayName = 'DialogTitle';

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  TypographyProps: PropTypes.object,
};

export default withStyles(styles)(DialogTitle);
