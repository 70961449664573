import React, { useState, useEffect } from 'react';
import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import { Grid, Divider, Button, Fade } from '@material-ui/core';
import SubscriptionOptionsView from '../../components/AccountManagement/SubscriptionOptions/SubscriptionOptionsView';
import SubscriptionView from '../../components/AccountManagement/SubscriptionCardView/SubscriptionView';
import SubscriptionModal from '../../components/AccountManagement/SubscriptionOptions/SubscriptionModal';
import {
  ModalData,
  CLOSE_WARNING_SUBSIDIZED,
  CLOSE_WARNING,
} from '../../components/AccountManagement/SubscriptionOptions/data';
import DashboardMessage from '../../components/AccountManagement/DisplayMessage';
import { connect } from 'react-redux';
import { fetchSubscription } from '../../../store/reducers/subscription/actions';
import { LOADING, ERROR, SUCCESS, EMPTY } from '../../../store/state';

export const SUBSCRIPTION_VIEW = 'subscription_view';
export const SUBSCRIPTION_OPTIONS = 'subscription_options';

const AccountManagement = props => {
  const {
    hasFetched,
    error,
    accountRole,
    startOn,
    isSubmitting,
    isSubsidized,
    order,
    lastChargeAmount,
    navigation: { dashboard },
    uiState,
  } = props;

  const [view, setView] = useState(SUBSCRIPTION_VIEW);
  const [modalMessage, setModalMessage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dashboardMessage, setDashboardMessage] = useState({
    isOpen: false,
  });

  useEffect(() => {
    if (!hasFetched || uiState === ERROR) {
      if (uiState === ERROR) {
        setDashboardMessage({
          isOpen: false,
        });
      }
      props.fetchSubscription();
    }
  }, []);

  useEffect(() => {
    if (hasFetched) {
      if (uiState === ERROR) {
        setDashboardMessage({
          type: 'warning',
          message: error,
          isOpen: true,
        });
      }
    }
  }, [hasFetched, uiState]);

  const handleOnViewChange = view => {
    setView(view);
    if (dashboardMessage.isOpen) {
      setDashboardMessage({
        isOpen: false,
      });
    }
  };

  const handleError = err => {
    setDashboardMessage({ type: 'warning', message: err, isOpen: true });
  };

  const handleSuccess = () => {
    setDashboardMessage({
      type: 'success',
      message: `Successfully changed your subscription to `,
      isOpen: true,
    });
    setView(SUBSCRIPTION_VIEW);
  };

  const onBackLink = () => {
    setView(SUBSCRIPTION_VIEW);
    return;
  };

  const renderView = () => {
    switch (view) {
      case SUBSCRIPTION_VIEW:
        return (
          <Grid item xs={12}>
            {Boolean(accountRole) && ( //only render dashboard message for error / empty state
              <SubscriptionView
                isLoading={uiState === LOADING}
                onClick={handleOnViewChange}
                accountRole={accountRole}
                startOn={startOn}
                isSubsidized={isSubsidized}
                order={order}
                lastChargeAmount={lastChargeAmount}
              />
            )}
          </Grid>
        );
      case SUBSCRIPTION_OPTIONS:
        return (
          <Grid item xs={12}>
            <SubscriptionOptionsView
              onViewChange={handleOnViewChange}
              accountRole={accountRole}
              handleError={handleError}
              handleSuccess={handleSuccess}
              isSubsidized={isSubsidized}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleOnDelete = () => {
    if (isSubsidized) {
      setModalMessage(ModalData[CLOSE_WARNING_SUBSIDIZED]);
      setIsModalOpen(true);
    } else {
      setModalMessage(ModalData[CLOSE_WARNING]);
      setIsModalOpen(true);
    }
  };

  const handleDeleteAccount = () => {
    alert('trigger delete account');
  };

  const AccountPageHeader = () => (
    <PageHeader
      headerText="Account Management"
      backButtonText="Go Back"
      subHeaderText={
        view === SUBSCRIPTION_VIEW
          ? 'View Account Details'
          : 'View Account Options'
      }
      rightSlot={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleOnDelete}
            style={{
              backgroundColor: '#ED5158',
              color: '#ffffff',
            }}
          >
            Delete account
          </Button>
        </div>
      }
      onBack={view === SUBSCRIPTION_VIEW ? '' : onBackLink}
      isHistoryBackDisabled={view === SUBSCRIPTION_VIEW ? false : true}
    />
  );

  return (
    <BasePage state={uiState}>
      <AccountPageHeader />
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      {Boolean(
        dashboardMessage.isOpen &&
          (accountRole || error) &&
          view === SUBSCRIPTION_VIEW
      ) && (
        <Grid item xs={12}>
          <DashboardMessage
            type={dashboardMessage.type}
            message={dashboardMessage.message}
            accountRole={accountRole}
            onClose={() => setDashboardMessage({ isOpen: false })}
          />
        </Grid>
      )}
      {renderView()}

      <SubscriptionModal
        {...modalMessage}
        open={isModalOpen}
        isSubmitting={false}
        onClick={handleDeleteAccount}
        clearMessage={() => setModalMessage({})}
        onClose={() => setIsModalOpen(false)}
      />
    </BasePage>
  );
};

const mapStateToProps = state => ({
  accountRole: state.subscription.accountRole,
  hasFetched: state.subscription.hasFetched,
  isFetching: state.subscription.isFetching,
  error: state.subscription.error,
  startOn: state.subscription.startOn,
  isSubmitting: state.subscription.isSubmitting,
  isSubsidized: state.app.user.isSubsidized,
  order: state.subscription.order,
  lastChargeAmount: state.subscription.lastChargeAmount,
  uiState: state.subscription.uiState,
});

const mapDispatchToProps = dispatch => ({
  fetchSubscription: () => dispatch(fetchSubscription()),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(AccountManagement),
};
