import OrdinalFrame from '../../../OrdinalFrame/OrdinalFrame';
import React from 'react';
import { theme } from './';

const WaterPieChart = props => {
  const { data } = props;
  const frameProps = {
    data,
    size: [400, 400],
    type: 'bar',
    projection: 'radial',
    dynamicColumnWidth: 'percentage',
    rAccessor: d => {
      return 100;
    },
    oAccessor: 'type',
    style: d => {
      return {
        fill: theme[d.data.type].background,
      };
    },
    oLabel: (d, i) => {
      const label = data.find(item => item.type === d);
      if (label.percentage > 4) return `${label.percentage}%`;
      return '';
    },
    labelSettingsProp: {
      orient: 'center',
      padding: -3,
    },
    labelStyle: (d, i) => {
      return {
        fill: theme[d].color,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: '16px',
      };
    },
  };
  return <OrdinalFrame {...frameProps} />;
};

export default WaterPieChart;
