import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Paper,
  makeStyles,
} from '@material-ui/core';
import useDateButtonGroup, { DATERANGE } from './useDateButtonGroup';
import { format } from 'date-fns';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles({
  buttonGroupContainer: {
    padding: '25px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  dateRange: {
    padding: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'flex',
      justifyContent: 'center',
    },
  },
});

const ChartWrapper = ({ data, children, selectedChart }) => {
  const {
    tickValues,
    dateExtent,
    onClick,
    dateRange,
    hasData,
  } = useDateButtonGroup(data);
  const classes = useStyles();
  const { ONE_WEEK, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR } = DATERANGE;
  const [annotations, setAnnotations] = useState([]);
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} className={classes.buttonGroupContainer}>
        <ButtonGroup disableElevation variant="contained" color="primary">
          {[ONE_WEEK, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR].map(
            (item, index) => {
              return (
                <Button
                  key={index}
                  disabled={item === dateRange}
                  onClick={() => onClick(item)}
                >
                  {item}
                </Button>
              );
            }
          )}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} md={6} className={classes.dateRange}>
        <Typography>
          {' '}
          {`${format(dateExtent[0], 'MM/dd/yyyy')} - ${format(
            dateExtent[1],
            'MM/dd/yyyy'
          )}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {annotations.length && hasData ? (
          <div
            style={{
              position: 'absolute',
              marginTop: !matches ? '0px' : '60px',
              right: '24px',
              zIndex: 999,
              display: 'flex',
            }}
          >
            {annotations.map(item => {
              return (
                <Paper
                  variant="outlined"
                  style={{
                    background: item.backgroundColor,
                    color: item.color,
                    margin: '0px 10px',
                    padding: '2px 10px',
                  }}
                >
                  <Typography>
                    {item.title}: {item.value}{' '}
                  </Typography>
                </Paper>
              );
            })}
          </div>
        ) : (
          ''
        )}
        {React.cloneElement(children, {
          coordinates: data,
          tickValues,
          dateExtent,
          dateRange,
          setAnnotations,
          hasData,
          mode: 'page',
          matches,
        })}
      </Grid>
    </Grid>
  );
};

export default ChartWrapper;
