import slugify from 'slugify';

// Equivalent to Java's String.hashCode function
// Heavily influenced by: http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export const hashCode = string => {
  let hash = 0;
  if (string.length == 0) return hash;

  return string.split('').reduce((hash, char) => {
    hash = (hash << 5) - hash + char.charCodeAt(0);
    return hash & hash;
  }, 0);
};

export const ignoreCase = value =>
  typeof value === 'string' ? value.toLocaleLowerCase() : value;

export const removeSubPaths = (path, num = 1) =>
  path
    .split('/')
    .slice(0, -num)
    .join('/');

export const slug = string =>
  slugify(string, {
    replacement: '-',
    remove: null,
    lower: true,
  });
