import React from 'react';
import { Typography, Grid, InputAdornment } from '@material-ui/core';
import { TEMP_FIELD_NAME } from '../../../JournalEntryDrawer';
import ReduxTextField from './ReduxTextField';
import { maxLength, isPositive, required } from '../../Validations';
import { Field } from 'redux-form';
import DurationField from './DurationField';
import UnitSelectField from './UnitSelectField';

const maxLength2 = maxLength(2);
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);

export const allowsTwoDecimals = value =>
  value && !/^\d{1,4}(\.\d{1,2})?$/.test(value)
    ? 'Invalid Format. e.g., 100.99'
    : undefined;

const TotalFormFields = ({
  onChange,
  isDurationCalculated,
  isCalorieCalculated,
  isDistanceCalculated,
  onClick,
  rftempDistance,
  totalDistance,
}) => (
  <Grid container spacing={1}>
    {/**
     * Total Distance
     */}
    <Grid xs={4} style={{ padding: '10px 0px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '16px',
              whiteSpace: 'break-spaces',
              color: '#0068A8',
              marginTop: '5px',
            }}
          >
            Total {`\n`} Distance
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Field
            name={`${TEMP_FIELD_NAME}Distance`}
            component={ReduxTextField}
            onFocus={() =>
              isDistanceCalculated ? onClick('totalDistance') : {}
            }
            validate={[isPositive, maxLength4, allowsTwoDecimals]}
            onChange={e => onChange('totalDistance', e.target.value)}
            normalize={(value, previousValue) =>
              parseFloat(value) > 9999
                ? parseFloat(previousValue)
                : parseFloat(value)
            }
            type="number"
            props={{
              margin: 'dense',
              placeholder: '0',
              type: 'number',
              inputProps: {
                style: {
                  color: '#0068A8',
                  textAlign: 'center',
                  padding: '9px 0px',
                },
                min: 1,
                max: 60,
                maxLength: 2,
              },
              InputProps: {
                style: {
                  borderRadius: 4,
                  border: '1px solid #0068A8',
                  backgroundColor: '#EFF1F3',
                },
                disableUnderline: 'true',
              },
            }}
          />
          <Field
            name="distanceUnit"
            component={UnitSelectField}
            validate={rftempDistance > 0 || totalDistance > 0 ? required : []}
            props={{
              singular: !Boolean(rftempDistance > 1),
            }}
          />
        </Grid>
      </Grid>
    </Grid>

    {/**
     * Total Exercise
     */}
    <Grid item xs={4} style={{ padding: '10px 0px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '16px',
              whiteSpace: 'break-spaces',
              color: '#0068A8',
              marginTop: '5px',
            }}
          >
            Total {`\n`} Exercise Time
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Field
            name={
              isDurationCalculated
                ? 'totalActivityTimeMins'
                : 'manualActivityTimeMins'
            }
            component={DurationField}
            validate={[isPositive]}
            onChange={value => onChange('totalMinutes', value)}
            onClick={onClick}
            type="number"
            props={{
              isDurationCalculated: isDurationCalculated,
              name: 'ActivityTimeMins',
              margin: 'dense',
              placeholder: '0',
              divStyle: {
                marginLeft: '4px',
              },
              type: 'number',
              inputProps: {
                style: {
                  color: '#0068A8',
                  textAlign: 'center',
                  padding: '9px 0px',
                },
                min: 1,
                maxLength: 2,
              },
              InputProps: {
                style: {
                  borderRadius: 4,
                  border: '1px solid #0068A8',
                  backgroundColor: '#EFF1F3',
                },
                disableUnderline: 'true',
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>

    {/**
     * Total Calories
     */}
    <Grid item xs={4} style={{ paddingTop: '10px', marginTop: '5px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '16px',
              whiteSpace: 'break-spaces',
              color: '#0068A8',
            }}
          >
            Total {`\n`} Calories Burned
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Field
            name="rftempCaloriesBurned"
            component={ReduxTextField}
            onFocus={() =>
              isCalorieCalculated ? onClick('totalCalories') : {}
            }
            validate={[isPositive, maxLength3]}
            onChange={e => onChange('totalCalories', e.target.value)}
            normalize={(value, previousValue) =>
              parseFloat(value) > 9999
                ? parseFloat(previousValue)
                : parseFloat(value)
            }
            type="number"
            props={{
              name: 'totalCaloriesBurned',
              margin: 'dense',
              placeholder: '0',
              type: 'number',
              divStyle: {
                maxWidth: '100px',
              },
              inputProps: {
                style: {
                  color: '#0068A8',
                  textAlign: 'center',
                  padding: '9px 0px',
                },
                maxLength: '4',
              },
              InputProps: {
                style: {
                  borderRadius: 4,
                  border: '1px solid #0068A8',
                  backgroundColor: '#EFF1F3',
                },
                disableUnderline: 'true',
                endAdornment: (
                  <InputAdornment
                    position="end"
                    disableTypography
                    style={{
                      fontSize: '10px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      lineHeight: '1.5',
                      marginRight: '5px',
                      marginLeft: '-3px',
                    }}
                  >
                    kcal
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TotalFormFields;
