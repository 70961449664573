import { useState, useEffect } from 'react';
import {
  subDays,
  addDays,
  subMonths,
  subWeeks,
  addMonths,
  subYears,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
} from 'date-fns';

const date = new Date();
const currDate = date.setHours(24, 0, 0, 0);

export const DATERANGE = {
  ONE_WEEK: '1 wk',
  ONE_MONTH: '1 mo',
  THREE_MONTHS: '3 mo',
  SIX_MONTHS: '6 mo',
  ONE_YEAR: '1 yr',
};

const defaultState = {
  dateRange: DATERANGE.ONE_WEEK,
  dateExtent: [subDays(currDate, 7), date],
  tickValues: [
    subDays(currDate, 7),
    subDays(currDate, 6),
    subDays(currDate, 5),
    subDays(currDate, 4),
    subDays(currDate, 3),
    subDays(currDate, 2),
    subDays(currDate, 1),
    date,
  ],
};

const useDateButtonGroup = data => {
  const [dateRange, setDateRange] = useState(defaultState.dateRange);
  const [hasData, setHasData] = useState(false);
  const onClick = value => setDateRange(value);
  const [dateExtent, setDateExtent] = useState(defaultState.dateExtent); // date axis' min/max
  const [tickValues, setTickValues] = useState(defaultState.tickValues);
  const { ONE_WEEK, ONE_MONTH, THREE_MONTHS, SIX_MONTHS, ONE_YEAR } = DATERANGE;

  // check if given interval has a data coordinate
  const checkHasData = (min, max) => {
    let _hasData = false;
    for (let item of data) {
      if (
        Boolean(item) &&
        isWithinInterval(item.date, { start: min, end: max })
      ) {
        _hasData = true;
        break;
      }
    }
    if (hasData !== _hasData) setHasData(_hasData);
  };

  useEffect(() => {
    if (data)
      switch (dateRange) {
        case ONE_MONTH: {
          setDateExtent([subWeeks(currDate, 4), addDays(currDate, 1)]);
          checkHasData(subWeeks(currDate, 4), addDays(currDate, 1));
          setTickValues([
            subWeeks(currDate, 4),
            subWeeks(currDate, 3),
            subWeeks(currDate, 2),
            subWeeks(currDate, 1),
            date,
          ]);
          return;
        }
        case THREE_MONTHS: {
          setDateExtent([
            subMonths(startOfMonth(currDate), 2),
            endOfMonth(currDate),
          ]);
          checkHasData(
            subMonths(startOfMonth(currDate), 2),
            addMonths(startOfMonth(currDate), 1)
          );
          setTickValues([
            subMonths(startOfMonth(currDate), 2),
            subMonths(startOfMonth(currDate), 1),
            startOfMonth(currDate),
          ]);
          return;
        }
        case SIX_MONTHS: {
          setDateExtent([
            subMonths(startOfMonth(currDate), 5),
            endOfMonth(currDate),
          ]);
          checkHasData(
            subMonths(startOfMonth(currDate), 5),
            addMonths(startOfMonth(currDate), 1)
          );

          setTickValues([
            subMonths(startOfMonth(currDate), 5),
            subMonths(startOfMonth(currDate), 4),
            subMonths(startOfMonth(currDate), 3),
            subMonths(startOfMonth(currDate), 2),
            subMonths(startOfMonth(currDate), 1),
            startOfMonth(currDate),
          ]);
          return;
        }
        case ONE_YEAR: {
          setDateExtent([
            subMonths(startOfMonth(currDate), 11),
            endOfMonth(currDate),
          ]);
          checkHasData(
            subMonths(startOfMonth(currDate), 11),
            addMonths(startOfMonth(currDate), 1)
          );
          setTickValues([
            subMonths(startOfMonth(currDate), 11),
            subMonths(startOfMonth(currDate), 10),
            subMonths(startOfMonth(currDate), 9),
            subMonths(startOfMonth(currDate), 8),
            subMonths(startOfMonth(currDate), 7),
            subMonths(startOfMonth(currDate), 6),
            subMonths(startOfMonth(currDate), 5),
            subMonths(startOfMonth(currDate), 4),
            subMonths(startOfMonth(currDate), 3),
            subMonths(startOfMonth(currDate), 2),
            subMonths(startOfMonth(currDate), 1),
            startOfMonth(currDate),
          ]);
          return;
        }
        default: {
          setDateExtent(defaultState.dateExtent);
          checkHasData(defaultState.dateExtent[0], defaultState.dateExtent[1]);
          setTickValues(defaultState.tickValues);
          return;
        }
      }
  }, [data, dateRange]);

  return {
    tickValues,
    dateExtent,
    onClick,
    dateRange,
    hasData,
  };
};

export default useDateButtonGroup;
