import React from 'react';
import { Field } from 'redux-form';
import { Grid, Paper, Typography } from '@material-ui/core';
import DefaultAddress from '../DefaultAddress/DefaultAddress';

/**
 * Get properties to apply to a field
 *
 * @param {import('../../Containers/AddDependent/types').FieldOption} field - field option
 * @returns
 */
const getFieldProps = field =>
  field.type === 'checkbox'
    ? {
        containerProps: {
          style: {
            minHeight: '60px',
            alignItems: 'center',
            display: 'flex',
          },
        },
      }
    : field.labelKey
    ? {
        props: {
          containerStyle: { backgroundColor: '#FFF', borderRadius: '5px' },
          variant: 'filled',
        },
      }
    : field.type !== 'switch'
    ? { whiteBG: true }
    : {};

/**
 * Render a group of HTML form fields
 *
 * @param {Object} props - FormCard properties
 * @param {String} props.title - Card group title
 * @param {Array<import('../../Containers/AddDependent/types').FieldOptions>} props.fields - Any array of card options
 * @returns {React.Component}
 */
export function FormCard({ title, fields, renderDefaultAddress = false }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          color="primary"
          variant="h6"
          style={{ fontWeight: '400', fontSize: '18px' }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          style={{
            backgroundColor: '#BCDEF3',
            borderRadius: '6px',
            border: 'solid 1px #006AB0',
          }}
        >
          <Grid container spacing={2} style={{ padding: '16px' }}>
            {fields.map((group, index) => (
              <Grid
                key={`field-group-${index}`}
                item
                container
                style={{ display: 'flex' }}
                xs={12}
                spacing={2}
              >
                {group.map(field => (
                  <Grid
                    key={`field-${field.name}`}
                    item
                    style={{ display: 'flex', alignItems: 'start' }}
                    {...field.grid}
                  >
                    {field.component ? (
                      <div style={{ width: '100%' }}>
                        <Field
                          {...field}
                          enableMinHeight={false}
                          {...getFieldProps(field)}
                        />
                      </div>
                    ) : (
                      <div style={{ width: '100%' }}>
                        {React.createElement(field.render, {
                          enableMinHeight: false,
                          ...field.props,
                        })}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            ))}
            {renderDefaultAddress ? (
              <Grid item xs={12}>
                <DefaultAddress />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
