import StressManagementIcon from '../../buttons/StressManagementButton';

const dataFields = {
  activityType: '',
  activityDuration: 0,
};

const normalizeData = data => ({
  ...data,
  activityDuration: parseInt(data.activityDuration),
});

const properties = {
  label: 'Stress Management',
  color: '#90B850',
  icon: StressManagementIcon,
  dataFields,
  normalizeData,
};

export { dataFields, properties };
