/**
 * Used to check if JWT token has expired using the exp (expiration time claim)
 * https://tools.ietf.org/html/rfc7519#section-4.1.4. The claim is optional so
 * if exp is not defined, then function will return false, else compare exp
 * with Date.now converted to seconds. If exp is less than current time
 * return false, else true.
 *
 * @param {object} jwt
 * @returns {boolean}
 */
export const isTokenExpired = jwt => {
  const current = Math.floor(Date.now() / 1000);
  return typeof jwt.exp === 'undefined' ? false : jwt.exp - current <= 0;
};

/**
 * Used to make API requests to the Agrin API server.
 *
 * @param {string} url
 * @param {object} options
 * @param {Promise}
 */
export const apiFetch = (url, options) =>
  fetch(window.__env.API_URL + url, options);

export const apiPost = (url, payload, options = null) =>
  fetch(window.__env.API_URL + url, {
    ...options,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

export const appFetch = (url, options) =>
  fetch(url, options)
    .then(handleSuccess)
    .catch(handleFailure);

const handleSuccess = response => {
  const contentType = response.headers.get('Content-Type');
  if (contentType.includes('application/json')) {
    return response.json().then(json => {
      if (response.ok) {
        return json;
      }

      if (response.status >= 500) {
        return Promise.reject({
          ...json,
          type: 'ServerError',
        });
      }

      if (response.status <= 500) {
        return Promise.reject({
          ...json,
          type: 'ApplicationError',
        });
      }
    });
  }

  throw new Error(`Unsupported Content-Type ${contentType}`);
};

const handleFailure = error => {
  throw new Error({
    type: error instanceof window.Response ? 'NetworkError' : 'ServerError',
    error,
  });
};

/**
 * Used to redirect to home page
 */
export const redirectHome = () =>
  window.location.replace(
    `${window.location.protocol}//${window.location.host}`
  );

/**
 * When using this method the user can only make authorized requests to the API.
 * If no user session is found, a general exception is thrown.
 *
 * @param {string} url URL path name
 * @param {object} options Fetch configuration options
 * @param {boolean} useBaseUrl Use fully qualified domain name instead of just the URL part.
 * @throws Exception - if no user session is active.
 * @returns {Promise}
 */
export const apiFetchAuthorized = (url, options = {}, useBaseUrl = true) => {
  if (!localStorage.session) {
    // Cannot make an authorized request to the server because the bearer token is
    // missing. Redirect the user back to the login page.
    redirectHome();
  }

  const { access_token } = localStorageGetObject('session');

  if (isTokenExpired(access_token)) {
    localStorage.clear();
    redirectHome();
    return;
  }

  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${access_token}`,
  };

  if (!options.headers['Pid']) {
    options.headers['Pid'] = localStorageGetObject('active').personId;
  }

  if (!useBaseUrl) {
    return fetch(url, options);
  }

  return fetch(window.__env.API_URL + url, options);
};

export const apiDelete = (url, options) => {
  return apiFetchAuthorized(url, {
    ...options,
    'Content-Type': 'application/json',
    method: 'DELETE',
  });
};

/**
 * Used to serialize data in javascript object form and store in localStorage.
 *
 * @param {string} key
 * @param {object} value
 */
export const localStorageSetObject = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Used to retrieve object that have been serialized from localStorage.
 * return ready to use javascript object
 *
 * @param {string} key
 * @return {object}
 */
export const localStorageGetObject = key => {
  const json = localStorage.getItem(key);
  if (!json || json === 'undefined') return null;

  return JSON.parse(json);
};

/**
 * Used to make request to the server that require on event hooks. This would be used with
 * processes that need to get feedback from the server, like uploading files.
 *
 * @param {string} url
 * @param {object} options
 */
export const ajax = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(options.method || 'GET', window.__env.API_URL + url, true);

    options.headers = options.headers || {};
    if (localStorage.session) {
      const { access_token } = localStorageGetObject('session');
      const { personId } = localStorageGetObject('active');

      options.headers['Authorization'] = `Bearer ${access_token}`;
      options.headers['Pid'] = personId;
    }

    // if (options.mode && options.mode.toUpperCase() === 'CORS') {
    //   xhr.withCredentials = true;
    // }

    for (var key in options.headers) {
      xhr.setRequestHeader(key, options.headers[key]);
    }

    xhr.onload = event => resolve(JSON.parse(event.target.responseText));

    xhr.onerror = reject;
    if (xhr.upload && options.onProgress) {
      xhr.upload.onprogress = options.onProgress;
    }

    xhr.send(options.body);
  });
};
