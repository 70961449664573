import React from 'react';

export default () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M43.0078 26.25H58.125C58.6328 26.25 59.0723 26.4355 59.4434 26.8066C59.8145 27.1777 60 27.6172 60 28.125C60 28.6328 59.8145 29.0723 59.4434 29.4434C59.0723 29.8145 58.6328 30 58.125 30H39.375C38.8672 30 38.4277 29.8145 38.0566 29.4434C37.6855 29.0723 37.5 28.6328 37.5 28.125C37.5 27.5781 37.6953 27.1289 38.0859 26.7773L54.4922 3.75H39.375C38.8672 3.75 38.4277 3.56445 38.0566 3.19336C37.6855 2.82227 37.5 2.38281 37.5 1.875C37.5 1.36719 37.6855 0.927734 38.0566 0.556641C38.4277 0.185547 38.8672 0 39.375 0H58.125C58.5938 0 59.0234 0.224609 59.4141 0.673828C59.8047 1.12305 60 1.52344 60 1.875C60 2.22656 59.8438 2.63672 59.5312 3.10547L43.0078 26.25ZM33.75 46.875C33.75 47.3828 33.5645 47.8223 33.1934 48.1934C32.8223 48.5645 32.3828 48.75 31.875 48.75H16.875C16.3672 48.75 15.9277 48.5645 15.5566 48.1934C15.1855 47.8223 15 47.3828 15 46.875C15 46.3281 15.1953 45.8789 15.5859 45.5273L28.2422 30H16.875C16.3672 30 15.9277 29.8145 15.5566 29.4434C15.1855 29.0723 15 28.6328 15 28.125C15 27.6172 15.1855 27.1777 15.5566 26.8066C15.9277 26.4355 16.3672 26.25 16.875 26.25H31.875C32.3438 26.25 32.7734 26.4746 33.1641 26.9238C33.5547 27.373 33.75 27.7734 33.75 28.125C33.75 28.4766 33.5938 28.8867 33.2812 29.3555L20.5078 45H31.875C32.3828 45 32.8223 45.1855 33.1934 45.5566C33.5645 45.9277 33.75 46.3672 33.75 46.875ZM11.25 58.125C11.25 58.6328 11.0645 59.0723 10.6934 59.4434C10.3223 59.8145 9.88281 60 9.375 60H1.875C1.36719 60 0.927734 59.8145 0.556641 59.4434C0.185547 59.0723 0 58.6328 0 58.125C0 57.5781 0.195313 57.1289 0.585938 56.7773L5.74219 48.75H1.875C1.36719 48.75 0.927734 48.5645 0.556641 48.1934C0.185547 47.8223 0 47.3828 0 46.875C0 46.3672 0.185547 45.9277 0.556641 45.5566C0.927734 45.1855 1.36719 45 1.875 45H9.375C9.84375 45 10.2734 45.2246 10.6641 45.6738C11.0547 46.123 11.25 46.5234 11.25 46.875C11.25 47.2266 11.0938 47.6367 10.7812 48.1055L5.50781 56.25H9.375C9.88281 56.25 10.3223 56.4355 10.6934 56.8066C11.0645 57.1777 11.25 57.6172 11.25 58.125Z"
        fill="#0068A8"
      />
    </svg>
  );
};
