import { useState, useEffect } from 'react';
import { parseDate } from '../utils';
import { DATERANGE } from '../../ChartWrapper';

const useWeightChart = (coordinates, dateRange) => {
  const [netData, setNetData] = useState([]);

  useEffect(() => {
    setNetData([]);
    calculateData();
  }, [coordinates]);

  const calculateData = () => {
    const result = [];
    coordinates.forEach(item => {
      const weight = item.weight;
      const bmi = item.bmi;
      let date = new Date(item.date);
      date.setHours(0, 0, 0, 0);
      date = parseDate(date, DATERANGE.SIX_MONTHS);

      if (result[date]) {
        result[date].totalWeight += weight;
        result[date].totalBmi += bmi;
        result[date].freq += 1;
        result[date].averageWeight = Math.round(
          result[date].totalWeight / result[date].freq
        );
        result[date].averageBmi = Math.round(
          result[date].totalBmi / result[date].freq
        );
      } else {
        result[date] = {
          totalWeight: weight,
          totalBmi: bmi,
          unit: item.unit,
          date,
          freq: 1,
          averageWeight: weight,
          averageBmi: bmi,
        };
      }
    });

    setNetData(Object.values(result));
  };

  return [netData];
};

export default useWeightChart;
