import React from 'react';
import AccordionPanel from './AccordionPanel';
import { Grid, Typography } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useStyles } from './useStyles';

const PricingPanel = ({ pricings = [] }) => {
  const classes = useStyles();
  return (
    <AccordionPanel
      emptyState="Pricing not currently available."
      title={
        <div className={classes.titleContainerDiv}>
          <AttachMoneyIcon className={classes.fontSize18} color="secondary" />{' '}
          <Typography className={classes.marginLeft8}>Pricing</Typography>
        </div>
      }
      details={
        pricings.length ? (
          <Grid container spacing={1} className={classes.pricingContainer}>
            {pricings.map((item, index) => (
              <Grid item xs={12} key={`${index}-resource-pricing`}>
                <div square className={classes.pricingRoot}>
                  <Typography className={classes.fontSize14}>
                    {item.name || `Product/Service ${index + 1}`}
                  </Typography>
                  <div>
                    {item.price ? (
                      <div className={classes.pricesRoot}>
                        <Typography className={classes.fontSize14}>
                          ${item.price}
                        </Typography>
                      </div>
                    ) : (
                      <Typography className={classes.fontSize14}>
                        not available
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        ) : null
      }
    />
  );
};

export default PricingPanel;
