import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const DEPENDENT_CREATE_REQUEST = 'dependent/create/request';
export const DEPENDENT_CREATE_SUCCESS = 'dependent/create/success';
export const DEPENDENT_CREATE_FAILURE = 'dependent/create/failure';

export const DEPENDENT_SELECT_REQUEST = 'dependent/select/request';
export const DEPENDENT_SELECT_SUCCESS = 'dependent/select/success';
export const DEPENDENT_SELECT_FAILURE = 'dependent/select/failure';

export const DEPENDENT_E_SIGN_REQUEST = 'dependent/esign/request';
export const DEPENDENT_E_SIGN_SUCCESS = 'dependent/esign/success';
export const DEPENDENT_E_SIGN_FAILURE = 'dependent/esign/failure';

export const DEPENDENT_SEARCH_REQUEST = 'dependent/search/request';
export const DEPENDENT_SEARCH_SUCCESS = 'dependent/search/success';
export const DEPENDENT_SEARCH_FAILURE = 'dependent/search/failure';

export const DEPENDENT_CLEAR = 'dependent/clear';

export const createDependent = payload => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      DEPENDENT_CREATE_REQUEST,
      DEPENDENT_CREATE_SUCCESS,
      DEPENDENT_CREATE_FAILURE,
    ],
    endpoint: `/api/family/member/create`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
        mode: 'cors',
      },
    },
  },
});

export const selectDependent = (personId, memberId) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      DEPENDENT_SELECT_REQUEST,
      DEPENDENT_SELECT_SUCCESS,
      DEPENDENT_SELECT_FAILURE,
    ],
    endpoint: `/api/family/member/select/${memberId}`,
    meta: {
      personId,
      memberId,
      config: {
        method: 'PUT',
        mode: 'cors',
      },
    },
  },
});

export const signElectronicSignatureRequest = signature => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      DEPENDENT_E_SIGN_REQUEST,
      DEPENDENT_E_SIGN_SUCCESS,
      DEPENDENT_E_SIGN_FAILURE,
    ],
    endpoint: `/api/e-signature/sign`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          signature,
          service: 'FAMILY_DEPENDENT_ACCOUNT',
        }),
      },
    },
  },
});

export const searchMember = search => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      DEPENDENT_SEARCH_REQUEST,
      DEPENDENT_SEARCH_SUCCESS,
      DEPENDENT_SEARCH_FAILURE,
    ],
    endpoint: `/api/member/search`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(search),
      },
    },
  },
});
