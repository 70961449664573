import LabsPage from './LabsPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  fetchLabTests,
  labsDelete,
  labsCreate,
  labsUpdate,
  labsSelectId,
  updateMedia,
  labsClearSelectedId,
} from '../../../store/reducers/labs/actions';

// NPM cannot correctly handle this line?
import { clearFiles as actionClearFiles } from '../../../store/reducers/mediaObject';

const mapStateToProps = state => ({
  uiState: state.labs.uiState,
  personId: state.active.user.personId,
  labs: state.labs,
  mediaObject: state.mediaObject,
  form: state.form,
});

const mapDispatchToProps = dispatch => ({
  fetchLabTests: () => dispatch(fetchLabTests()),
  labsDelete: (pid, lid) => dispatch(labsDelete(pid, lid)),
  labsCreate: (pid, data, bundles, options) =>
    dispatch(labsCreate(pid, data, bundles, options)),
  labsUpdate: (pid, mid, data, bundles, options) =>
    dispatch(labsUpdate(pid, mid, data, bundles, options)),
  labsSelectId: id => dispatch(labsSelectId(id)),
  labsClearSelectedId: () => dispatch(labsClearSelectedId()),
  clearFiles: () => dispatch(actionClearFiles('LABRESULT')),
  updateMedia: (pid, documentId, document, oid) =>
    dispatch(updateMedia(pid, documentId, document, oid)),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(withRouter(LabsPage)),
};
