import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    margin: 0,
    padding: 24,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: 16,
    },
  },
  container: {
    width: '100%',
  },
}));
