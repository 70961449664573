export const inArray = (array, item) => (
  array !== undefined && array.contains(item)
);

export const inObject = (obj, key, item) => (
  obj !== undefined && obj[key] !== undefined && obj[key] === item
)

// export const inObjectArray = (obj, key, item) => {
//   const items = obj.reduce((i, value) => i.concat(value[key]), []);
//   return inArray(items, item);
// }

export const inObjectArray = (obj, key, item) => {
  const result = obj.find(x => x[key] === item);
  return result !== undefined;
}