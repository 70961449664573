import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';

import styles from './DashboardLinkButton.css';

// renders icons rendered from FA
export const renderGlyph = glyph => (
  <span
    style={{
      width: '100%',
      textAlign: 'center',
      height: 56,
    }}
  >
    <i
      className={`fa fa-${glyph}`}
      style={{ fontSize: '47px', marginTop: '2px' }}
    ></i>
  </span>
);

function DashboardLinkButton(props) {
  let icon;
  if (Boolean(props.glyph)) {
    icon = renderGlyph(props.glyph);
  } else if (props.icon) {
    icon = (
      <Icon style={{ width: '100%', textAlign: 'center', fontSize: 56 }}>
        {props.icon}
      </Icon>
    );
  } else if (props.src) {
    icon = <img src={props.src} role="presentation" />;
  }

  return (
    <div className={styles.dashboardLinkButton}>
      <Link to={props.to} className={props.disabled ? styles.disabledLink : ''}>
        {icon}
        <span>{props.label}</span>
      </Link>
    </div>
  );
}

export default DashboardLinkButton;
