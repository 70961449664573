import { combineReducers } from 'redux';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  EMERGENCY_CONTACTS_REQUEST,
  EMERGENCY_CONTACTS_SUCCESS,
  EMERGENCY_CONTACTS_FAILURE,
  EMERGENCY_CONTACTS_CREATE,
  EMERGENCY_CONTACTS_CREATE_SUCCESS,
  EMERGENCY_CONTACTS_CREATE_FAILURE,
  EMERGENCY_CONTACTS_UPDATE,
  EMERGENCY_CONTACTS_UPDATE_SUCCESS,
  EMERGENCY_CONTACTS_UPDATE_FAILURE,
  EMERGENCY_CONTACTS_DELETE,
  EMERGENCY_CONTACTS_DELETE_SUCCESS,
  EMERGENCY_CONTACTS_DELETE_FAILURE,
  EMERGENCY_CONTACT_GET,
  EMERGENCY_CONTACT_CLEAR,
} from './actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../../store/state';

const initialState = {
  isFetching: false,
  didInvalidate: false,
  emergencyContactsList: {},
  listIds: [],
  contactToEdit: {},
  uiState: LOADING,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EMERGENCY_CONTACTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: undefined,
        uiState: LOADING,
      };

    case EMERGENCY_CONTACTS_SUCCESS:
      const listIds = getKeys('emergencyContactId', action.payload || []);
      return {
        ...state,
        isFetching: false,
        emergencyContactsList: arrayToHashMap(
          'emergencyContactId',
          action.payload || []
        ),
        listIds,
        uiState: listIds.length > 0 ? SUCCESS : EMPTY,
      };

    case EMERGENCY_CONTACTS_FAILURE:
      return {
        ...state,
        emergencyContactsList: {},
        listIds: [],
        isFetching: false,
        errorMessage: action.payload,
        uiState: ERROR,
      };

    case EMERGENCY_CONTACTS_CREATE:
      return {
        isFetching: false,
        didInvalidate: false,
        errorMessage: undefined,
      };

    case EMERGENCY_CONTACTS_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        errorMessage: undefined,
        uiState: SUCCESS,
      };

    case EMERGENCY_CONTACTS_CREATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case EMERGENCY_CONTACTS_UPDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        errorMessage: undefined,
      };

    case EMERGENCY_CONTACTS_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        errorMessage: undefined,
      };

    case EMERGENCY_CONTACTS_UPDATE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case EMERGENCY_CONTACTS_DELETE_SUCCESS:
      let contactId = action.payload.emergencyContactId;
      state.listIds = state.listIds.filter(idx => idx !== contactId);
      delete state.emergencyContactsList[contactId];

      return {
        ...state,
        errorMessage: undefined,
        uiState: state.listIds.length > 0 ? SUCCESS : EMPTY,
      };

    case EMERGENCY_CONTACTS_DELETE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case EMERGENCY_CONTACTS_DELETE:
      return {
        ...state,
        errorMessage: undefined,
      };

    case EMERGENCY_CONTACT_GET:
      return {
        ...state,
        contactToEdit: action.payload,
        didInvalidate: false,
      };

    case EMERGENCY_CONTACT_CLEAR:
      return {
        ...state,
        contactToEdit: {},
        didInvalidate: true,
      };

    default:
      return state;
  }
};

export default reducer;
