import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const ALLERGEN_REQUEST = '@@redux/allergy/ALLERGEN_REQUEST';
export const ALLERGEN_SUCCESS = '@@redux/allergy/ALLERGEN_SUCCESS';
export const ALLERGEN_FAILURE = '@@redux/allergy/ALLERGEN_FAILURE';

export const fetchAllergens = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [ALLERGEN_REQUEST, ALLERGEN_SUCCESS, ALLERGEN_FAILURE],
    endpoint: '/api/code-table/allergen/1',
  }
});