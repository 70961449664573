import React, { useState, useEffect } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import FragmentDialogBase from '../FragmentDialogBase';
import { totalSleepValidation, maxLength, required } from '../Validations';
import {
  CheckboxFields,
  SliderField,
  VectorFields,
  TotalSleepForm,
} from './Fields';
import { subHours } from 'date-fns';
import useStyles from './useStyles';
import SleepQuantityField from './Fields/SleepQuantityField';
import ErrorTooltip from '../Forms/ErrorTooltip';
import { properties as fragmentProperties } from './properties';
import { format } from 'date-fns';

const maxLength4 = maxLength(4);

export const SLEEP_TRACKER_FIELDS = {
  totalTimeSlept: '',
  sleepQuality: null,
  sleepIssues: [],
};

const SleepForm = props => (
  <div style={{ minHeight: '80px', maxWidth: '300px' }}>
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <div>
        <SleepQuantityField
          {...props}
          onChange={props.input.onChange}
          initialValue={props.meta.initial}
          value={props.input.value}
          error={props.meta.error && props.meta.touched}
        />
      </div>
    </ErrorTooltip>
  </div>
);

const RenderForm = ({ onChange }) => (
  <Field
    name="totalTimeSlept"
    onChange={onChange}
    component={SleepForm}
    validate={[required]}
  />
);

const SleepTrackingDialog = props => {
  const { updateField, initialValues } = props;
  const classes = useStyles();
  const [totalTimeSlept, setTotalTimeSlept] = useState(
    initialValues.totalTimeSlept
  );
  const [tempSleepIssues, setTempSleepIssues] = useState([
    ...initialValues.sleepIssues,
  ]);

  const onChange = size => {
    setTotalTimeSlept(parseFloat(size));
    updateField('totalTimeSlept', parseFloat(size));
  };

  const handleSliderChange = val => {
    updateField('sleepQuality', val);
  };

  useEffect(() => {
    const slepOnDate = format(
      new Date(getSleepTime(new Date(initialValues.awokeOn), totalTimeSlept)),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    updateField('sleptOn', slepOnDate);
  }, [totalTimeSlept]);

  // useEffect(() => {
  //   updateField(
  //     'awokeOn',
  //     format(new Date(initialValues.awokeOn), "yyyy-MM-dd'T'HH:mm:ss")
  //   );
  // }, [initialValues.awokeOn]);

  const onCheckBoxChange = e => {
    const val = e.target.value;
    if (tempSleepIssues.includes(val)) {
      setTempSleepIssues(tempSleepIssues.filter(x => x !== val));
    } else {
      setTempSleepIssues([...tempSleepIssues, val]);
    }
  };

  useEffect(() => {
    updateField('sleepIssues', tempSleepIssues);
  }, [tempSleepIssues]);

  return (
    <FragmentDialogBase
      color="#2AA5A2"
      dateEndAdornment={'Drift - Sleep Start'}
      formTitle="Sleep"
      FormSlot1={
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.vectorFieldsGrid}>
            <VectorFields
              onSleepClick={onChange}
              totalTimeSlept={totalTimeSlept}
            />
          </Grid>
          <Grid item xs={12} className={classes.totalSleepFields}>
            <div className={classes.totalSleepContainerDiv}>
              <Typography className={classes.header}>Total Sleep</Typography>
              <RenderForm onChange={setTotalTimeSlept} />
            </div>
          </Grid>
        </Grid>
      }
      FormSlot2={
        <Grid container spacing={1} style={{ marginTop: '27px' }}>
          <Grid item xs={8}>
            <SliderField
              onChange={handleSliderChange}
              initialValue={initialValues.sleepQuality}
            />
          </Grid>
          <Grid item xs={4} className={classes.checkBoxFieldsContainer}>
            <CheckboxFields
              onCheckBoxChange={onCheckBoxChange}
              tempSleepIssues={tempSleepIssues}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const getSleepTime = (date, amt) => {
  return subHours(date, amt);
};

const mapStateToProps = state => {
  const awokeOn =
    !!state.journalCompose &&
    !!state.journalCompose.createdOn &&
    !!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData.awokeOn
      ? state.journalCompose.fragments[0].fragmentData.awokeOn
      : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  return {
    initialValues: {
      ...fragmentProperties.dataFields,
      ...(!!state.journalCompose.fragments[0] &&
      !!state.journalCompose.fragments[0].fragmentData
        ? fragmentProperties.normalizeData(
            state.journalCompose.fragments[0].fragmentData
          )
        : {}),
      awokeOn,
      sleepQuality:
        !!state.journalCompose &&
        !!state.journalCompose.createdOn &&
        !!state.journalCompose.fragments[0] &&
        !!state.journalCompose.fragments[0].fragmentData
          ? state.journalCompose.fragments[0].fragmentData.sleepQuality
          : 3,
      sleptOn:
        !!state.journalCompose &&
        !!state.journalCompose.createdOn &&
        !!state.journalCompose.fragments[0] &&
        !!state.journalCompose.fragments[0].fragmentData &&
        !!state.journalCompose.fragments[0].fragmentData.totalTimeSlept
          ? format(
              getSleepTime(
                new Date(awokeOn),
                state.journalCompose.fragments[0].fragmentData.totalTimeSlept
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
          : null,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const SleepTrackerFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  // enableReinitialize: true,
})(SleepTrackingDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SleepTrackerFormRedux);
