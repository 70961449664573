import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { withStyles, Typography, Grid } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import FormPanelButton from '../../../components/Form/FormButtonPanel';
import {DrawerAccordionStandardItems} from '../../../components/DrawerAccordionStandardItems';
import { hasMediaObjectUpdates } from '../../../components/MediaObject/helpers';
import validate from './ValidateForm';
import submit from './SubmitForm';
import { cancelForm } from '../../../../store/reducers/ui/actions';
import { csvToCodeObjectList } from '../../../../library/helpers/utils';
import { parseDate } from '../../../../library/utils/date';
import HoverTooltip from '../../../../library/forms/HoverTooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachmentPanel from './AttachmentPanel';
import 'react-dates/lib/css/_datepicker.css';

const dateFormat = 'yyyy-MM-dd';

export const styles = {
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    position: 'relative',
    padding: 32,
    paddingBottom: 16,
  },
  allergyBgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  allergyBg: {
    height: '100%',
    objectFit: 'cover',
  },
  accordion: {
    boxShadow: 'none',
  },
  heading: {
    padding: '0px 8px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
};

const AllergyForm = props => {
  const { classes, handleSubmit, action } = props;
  const entityAction = action === 'CREATE' ? 'Create' : 'Update';

  const [showUploadModal, setShowUpdateModal] = useState(false);
  const [startOn, setStartOn] = useState(false);

  useEffect(() => {
    if (props.initialValues && props.initialValues.startOn) {
      setStartOn(props.initialValues.startOn);
    }
  }, []);

  const onCancel = () => {
    props.onCloseDrawer();
  };

  const onStartDateChange = date => {
    setStartOn(date);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.primaryInput}>
            <div className={classes.allergyBgContainer}>
              <img
                src={require('../../../../client/svgs/surgical-history-add.svg')}
                alt=""
                className={classes.allergyBg}
              />
            </div>
            <Typography variant="h6">Enter Allergen</Typography>
            <Field
              type="text"
              name="name"
              label="Allergen"
              labelKey="longName"
              valueKey="shortName"
              hint="Required"
              options={props.allergensList}
              isLoading={props.isFetchingAllergens}
              component={SelectField}
              isSearchable
              isCreatable
              props={{
                InputLabelProps: {
                  style: {
                    letterSpacing: 'normal',
                  },
                },
                containerStyle: {
                  backgroundColor: '#fff',
                },
                variant: 'filled',
              }}
            />
          </div>
        </Grid>
        </Grid>
      <Grid
        container
        spacing={3}
        style={{ width: '100%', margin: 0, padding: 8 }}
      >
        <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
          </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Field
              id="date"
              shrink={true}
              name="startOn"
              type="date"
              label="Start Date"
              hint="Required"
              component={InputField}
              onChange={event => onStartDateChange(event.target.value)}
              inputProps={{ max: format(new Date(), dateFormat) }}
              props={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HoverTooltip text="If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team." />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Field
              id="date"
              shrink={true}
              name="endOn"
              type="date"
              label="End Date"
              component={InputField}
              inputProps={{
                min: startOn ? format(parseDate(startOn), dateFormat) : null,
                max: format(new Date(), dateFormat),
              }}
              props={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HoverTooltip text="If this is still ongoing, leave blank. Otherwise enter the date it was concluded. If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team." />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Field
            name="reaction"
            label="Reaction(s)"
            labelKey="shortName"
            valueKey="shortName"
            component={SelectField}
            options={props.reactionsList}
            isLoading={props.isFetchingReactions}
            fieldType="tags"
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="treatment"
            label="Treatment(s)"
            labelKey="shortName"
            valueKey="shortName"
            options={props.treatmentsList}
            fieldType="tags"
            isLoading={props.isFetchingTreatments}
            component={SelectField}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
      </Grid>
      <DrawerAccordionStandardItems 
        additionalInformation={
          <Field
            name="prescription"
            label="Notes"
            multiline
            rows={4}
            component={InputField}
          />} 
        attachments={<AttachmentPanel />} 
      />
      <Grid container item xs={12}>
        <FormPanelButton
          actionText={`${entityAction} Record`}
          cancelText="Cancel"
          onCancel={props.onCancel}
          isDisabled={props.pristine && !props.hasMediaObjectUpdates}
          isSubmitting={props.isSubmitting}
        />
      </Grid>
    </form>
  );
};

AllergyForm.propTypes = {
  allergensList: PropTypes.array.isRequired,
  reactionsList: PropTypes.array.isRequired,
  treatmentsList: PropTypes.array.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
};

const formName = 'allergy-form';
const AllergyFormRedux = reduxForm({
  form: formName,
  validate,
  onSubmit: submit,
  initialValues: {
    name: 'Unknown',
  },
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(AllergyForm));

const mapStateToProps = state => {
  const { selectedId, list, isSubmitting, bundlesToDelete } = state.allergies;
  const { isFetchingAllergens } = state.api.allergens;
  const { isFetchingReactions } = state.api.reactions;
  const { isFetchingTreatments } = state.api.treatments;
  const allergy = selectedId ? list[selectedId] : {};

  return {
    isFetchingAllergens,
    isFetchingReactions,
    isFetchingTreatments,
    action: state.ui.entity.action,
    isSubmitting,
    hasMediaObjectUpdates: Boolean(
      hasMediaObjectUpdates(state) || bundlesToDelete.length
    ),
    pristine:
      state.form &&
      state.form[formName] &&
      JSON.stringify(state.form[formName].initial) ===
        JSON.stringify(state.form[formName].values),
    initialValues: {
      name: allergy.allergenType ? allergy.allergenType : undefined,
      startOn: allergy.startOn
        ? format(parseDate(allergy.startOn), dateFormat)
        : undefined,
      endOn: allergy.endOn
        ? format(parseDate(allergy.endOn), dateFormat)
        : undefined,
      reaction: allergy.reaction
        ? csvToCodeObjectList(allergy.reaction)
        : undefined,
      treatment: allergy.treatment
        ? csvToCodeObjectList(allergy.treatment)
        : undefined,
      comments: allergy.comments,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  cancelForm: () => dispatch(cancelForm()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AllergyFormRedux)
);
