import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Contents from '../Contents';
import { IDLE } from '../Contents/useSurgicalHistory';

const SurgicalDrawer = ({ status, onClose, setStatus }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (status === IDLE) {
      setOpen(false);
      onClose();
      return;
    }
    if (!open) setOpen(true);
  }, [status]);

  const onCloseDrawer = () => {
    setStatus(IDLE);
  };

  return (
    <Drawer anchor="right" onClose={onCloseDrawer} open={status !== IDLE}>
      <div style={{ width: '550px' }}>
        <Contents
          status={status}
          setStatus={setStatus}
          onCloseDrawer={onCloseDrawer}
        />
      </div>
    </Drawer>
  );
};

export default SurgicalDrawer;
