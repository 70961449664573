import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ChildCare, Face, Pets } from '@material-ui/icons';

import AccountType from '../../Components/AccountType/AccountType';

const accountTypes = [
  {
    value: 'child',
    title: 'Child',
    description:
      "Children's accounts remain under your account until they turn 18. Then their account is automatically promoted to an adult record they can keep with them for life.",
    icon: (
      <img
        src={require('../../../../../client/svgs/familyView/child.svg')}
        alt="Conditions Default"
      />
    ),
  },
  {
    value: 'adult',
    title: 'Adult',
    description:
      'Taking care of an aging parent?  You can do that here! (Legal attestation to having the proper permission to create an account on their behalf is required.)',
    icon: (
      <img
        src={require('../../../../../client/svgs/familyView/elderly.svg')}
        alt="Conditions Default"
      />
    ),
  },
  {
    value: 'pet',
    title: 'Pet',
    icon: <Pets color="primary" style={{ width: '46px', height: '50px' }} />,
    description:
      "Pets need care, too! Create your an account for your family's treasured pet to capture all of the vaccinations, vet bills, medicines and symptom tracking you want.",
  },
];

export default function SelectDependentType({ onNext }) {
  return (
    <Grid container spacing={12}>
      <Grid item xs={12}>
        <Typography>
          Select the kind of family account you want to create:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ paddingTop: '24px' }} spacing={4}>
          {accountTypes.map(type => (
            <Grid item xs={12} key={'account-type' + type.value}>
              <AccountType onSelect={onNext} {...type} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
