import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const PERSON_INFO_REQUEST = 'person/info/REQUEST';
export const PERSON_INFO_SUCCESS = 'person/info/SUCCESS';
export const PERSON_INFO_FAILURE = 'person/info/FAILURE';

export const PERSON_INFO_UPDATE_REQUEST = 'person/info/UPDATE_REQUEST';
export const PERSON_INFO_UPDATE_SUCCESS = 'person/info/UPDATE_SUCCESS';
export const PERSON_INFO_UPDATE_FAILURE = 'person/info/UPDATE_FAILURE';

export const CLEAR_PERSON_INFO = 'person/info/cleared';

export const fetchPersonInfo = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [PERSON_INFO_REQUEST, PERSON_INFO_SUCCESS, PERSON_INFO_FAILURE],
    endpoint: `/api/member/person-view`,
  },
});

export const clearPersonInfo = () => ({
  type: CLEAR_PERSON_INFO,
});

export const updatePersonBasicInfo = (person, isDependent = false) => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        PERSON_INFO_UPDATE_REQUEST,
        PERSON_INFO_UPDATE_SUCCESS,
        PERSON_INFO_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/person-view`,
      payload: person,
      meta: {
        isDependent,
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(person),
        },
      },
    },
  };
};
