import React, { useState } from 'react';
import { Avatar, withStyles, Icon, Tooltip } from '@material-ui/core';

import {
  RELATIONSHIP_STATUS_APPROVED,
  RELATIONSHIP_STATUS_PENDING,
  RELATIONSHIP_STATUS_ARCHIVED,
  RELATIONSHIP_STATUS_DENIED_ACCESS,
} from '../RoleSummary/data';

const styles = {
  root: {
    position: 'relative',
  },
  iconContainer: {
    width: 18,
    height: 18,
    position: 'absolute',
    top: 0,
    right: 14,
    zIndex: 1,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 0 2px #ffffff',
  },
  icon: {
    fontSize: 18,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  brightnessIcon: {
    fontSize: 17,
    position: 'absolute',
    top: 0,
    right: 14,
    zIndex: 1,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 0 2px #ffffff',
    backgroundColor: '#ffffff',
  },
  avatar: {
    marginRight: 10,
    width: 70,
    height: 70,
  },
  tooltip: {
    backgroundColor: '#222',
    opacity: 1,
    left: -10,
    top: -2,
    position: 'absolute',
    maxWidth: 'none',
  },
};

const BASE_LINE = 5;

const COLORS = [
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0288D1',
  '#C2185B',
  '#00796B',
  '#388E3C',
  '#E64A19',
  '#E65100',
  '#616161',
  '#455A64',
];

//https://css-tricks.com/snippets/javascript/lighten-darken-color/
function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

const MemberDetailAvatar = ({ member, classes, isLetterAvatar = false }) => {
  const { firstName = '', lastName = '', displayName, avatar } = member;

  const getFirstChar = text => (text && text[0] ? text[0] : '?');
  const getFirstCode = text => (text ? text.charCodeAt(0) : 0);

  const fNameChar = getFirstChar(firstName);
  const lNameChar = getFirstChar(lastName);
  const fNameCode = getFirstCode(fNameChar);
  const lNameCode = getFirstCode(lNameChar);
  const codeIndex = fNameCode + lNameCode - BASE_LINE;
  const colorsIdx = codeIndex % COLORS.length;

  const dark = lightenDarkenColor(COLORS[colorsIdx], 20);
  const light = lightenDarkenColor(COLORS[colorsIdx], 55);

  const status = member.status ? member.status : '';

  const setIconType = () => {
    switch (status) {
      case RELATIONSHIP_STATUS_APPROVED:
        return (
          <Icon color="secondary" className={classes.icon}>
            brightness_1
          </Icon>
        );

      case RELATIONSHIP_STATUS_ARCHIVED:
        return (
          <Icon className={classes.icon} style={{ color: '#666' }}>
            archive
          </Icon>
        );

      case RELATIONSHIP_STATUS_PENDING:
        return (
          <Icon className={classes.icon} style={{ color: '#FFA561' }}>
            sync
          </Icon>
        );

      case RELATIONSHIP_STATUS_DENIED_ACCESS:
        return (
          <Icon className={classes.icon} style={{ color: '#ED5158' }}>
            remove_circle
          </Icon>
        );

      default:
        return '';
    }
  };

  const renderAvatar = () => {
    return !!member.subsidy ? (
      <Tooltip
        title="Your account is receiving a subsidy from this Provider. Please contact Support@AgrinHealth.com to withdraw access."
        placement="right-start"
        classes={{ tooltip: classes.tooltip }}
      >
        <Icon color="secondary" className={classes.brightnessIcon}>
          brightness_1
        </Icon>
      </Tooltip>
    ) : status !== '' ? (
      <Tooltip
        title={status}
        placement="right-start"
        classes={{ tooltip: classes.tooltip }}
        style={{
          textTransform: 'capitalize',
          background: '#ffffff',
        }}
      >
        <div className={classes.iconContainer}>{setIconType()}</div>
      </Tooltip>
    ) : null;
  };

  return (
    <div className={classes.root}>
      {renderAvatar()}
      {isLetterAvatar || !avatar ? (
        <Avatar
          style={{
            background: `linear-gradient(135deg, ${light} 50%, ${dark} 50%)`,
          }}
          className={classes.avatar}
          alt={`${firstName} ${lastName}`}
        >
          {getFirstChar(!firstName ? displayName : firstName).toUpperCase()}{' '}
          {lastName && getFirstChar(lastName).toUpperCase()}
        </Avatar>
      ) : (
        <Avatar
          className={classes.avatar}
          alt={`${firstName} ${lastName}`}
          src={avatar}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(MemberDetailAvatar);
