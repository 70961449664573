import React from 'react';
import { Collapse } from '@material-ui/core';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

const MessageComponent = ({ renderDashboardMessage, type, message }) => (
  <Collapse in={renderDashboardMessage}>
    <DashboardMessage type={type} canClose={false}>
      {message}
    </DashboardMessage>
  </Collapse>
);

export default MessageComponent;
