import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Header from '../../components/Header';
import InitialQuestionsForm from '../../components/InitialQuestionForm';
import {
  initializeAccount,
  logoutUser,
  INITIALIZE_SUCCESS,
} from '../../containers/SignInFormMember/actions';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import { localStorageGetObject } from '../../../library/helpers/fetch';
import styles from './AccountInitializePage.css';

class AccountInitializePage extends Component {
  questions = [
    {
      key: 'allergies',
      text: 'Do you have any current or past allergies?',
      selected: true,
    },
    {
      key: 'immunizations',
      text: 'Do you have any immunizations or vaccinations?',
      selected: true,
    },
    {
      key: 'mvs',
      text:
        'Have you, or do you take any medications, vitamins, or supplements?',
      selected: true,
    },
    {
      key: 'insurance',
      text: 'Do you have medical insurance?',
      selected: true,
    },
    {
      key: 'contacts',
      text: 'Do you have an emergency contact?',
      selected: true,
    },
    {
      key: 'portals',
      text: 'Do you have or use any patient portals?',
      selected: true,
    },
    {
      key: 'careTeam',
      text:
        'Do you have any Care Team members (physician, hospital, personal trainer, etc.)?',
      selected: true,
    },
    {
      key: 'labResult',
      text: 'Do you have any lab tests or medical screenings?',
      selected: true,
    },
    {
      key: 'personDiagnosis',
      text: 'Do you have any current or past conditions or diagnoses?',
      selected: true,
    },
    {
      key: 'surgicalHistory',
      text: 'Have you had any surgeries?',
      selected: true,
    },
  ];

  componentDidMount() {
    const { isAccountInitialized, history, navigation } = this.props;
    if (isAccountInitialized == 'True') {
      history.push('/d/me/home');
    }

    // There's potential the local storage object may not exist.
    // If this is the case, redirect the user back to the login page
    // (presumably, they are not logged in)
    const session = localStorageGetObject('session');
    if (!session) {
      history.push('/');
    }
  }

  handleSubmit = async values => {
    const { personId, history, initializeAccount } = this.props;
    const result = await initializeAccount(personId, values);

    if (result && result.type === INITIALIZE_SUCCESS) {
      history.push('/d/me/home');
    } else {
      console.error('Cannot initialize account');
      this.props.createMessage(
        'Cannot initialize account because something went wrong behind the scenes. Please try again.',
        'warning'
      );
      this.props.logoutUser(history);
    }
  };

  render() {
    const { firstName } = this.props;
    return (
      <div className={styles.body}>
        <Header />
        <Row>
          <div
            style={{ maxWidth: 600, margin: '0 auto', paddingBottom: '50px' }}
          >
            <InitialQuestionsForm
              userFirstName={firstName}
              questions={this.questions}
              onSubmit={this.handleSubmit}
            />
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const login = state.member.login.details.name;
  if (!login) {
    return {
      firstName: null,
      personId: null,
      isAccountInitialized: false,
    };
  }

  return {
    firstName: state.member.login.details.name.split(' ')[0],
    personId: state.member.login.details.personId,
    isAccountInitialized: state.member.login.details.initialized,
  };
};

const mapDispatchToProps = dispatch => ({
  initializeAccount: (id, payload) => dispatch(initializeAccount(id, payload)),
  createMessage: (message, type) => dispatch(createMessage(message, type)),
  logoutUser: history => dispatch(logoutUser(history)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountInitializePage),
};
