import React from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import JournalDetailForm from '../JournalDetailForm';

import { setJournalMessage } from '../../../../store/reducers/journalmodal/actions';

const styles = theme => ({
  root: {
    width: '100%',
  },
  container: {
    marginBottom: theme.spacing(1),
  },
});

const FragmentDialogBase = props => {
  const {
    classes,
    FormSlot,
    FormSlot1,
    FormSlot2,
    setJournalMessage,
    journalCompose,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {FormSlot && (
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              {FormSlot}
            </Grid>
          </Grid>
        )}

        {FormSlot1}

        <JournalDetailForm
          data={journalCompose}
          onChange={data => setJournalMessage(data)}
          DetailSlot={FormSlot2}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setJournalMessage: payload => dispatch(setJournalMessage(payload)),
});

const mapStateToProps = state => ({
  journalCompose: state.journalCompose,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FragmentDialogBase));
