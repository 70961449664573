import * as React from 'react';

import { nest } from 'd3-collection';

import {
  scaleBand,
  scaleOrdinal,
  scaleLinear,
  ScaleLinear,
  ScaleBand,
} from 'd3-scale';

import { sum, max, min, extent } from 'd3-array';

import { arc } from 'd3-shape';

import {
  orFrameChangeProps,
  xyframeproptypes,
  ordinalframeproptypes,
  networkframeproptypes,
} from 'semiotic/lib/constants/frame_props';
import {
  svgORRule,
  svgHighlightRule,
  svgOrdinalLine,
  basicReactAnnotationRule,
  svgEncloseRule,
  svgRectEncloseRule,
  svgRRule,
  svgCategoryRule,
  htmlFrameHoverRule,
  htmlColumnHoverRule,
  screenProject,
  findIDPiece,
  getColumnScreenCoordinates,
} from 'semiotic/lib/annotationRules/orframeRules';

import { desaturationLayer } from 'semiotic/lib/annotationRules/baseRules';

import { findFirstAccessorValue } from 'semiotic/lib/data/multiAccessorUtils';

import Frame from 'semiotic/lib/Frame';

import DownloadButton from 'semiotic/lib/DownloadButton';

import { orDownloadMapping } from 'semiotic/lib/downloadDataMapping';

import {
  calculateMargin,
  objectifyType,
  keyAndObjectifyBarData,
  //  generateOrdinalFrameEventListeners,
  adjustedPositionSize,
  orFrameConnectionRenderer,
  orFrameAxisGenerator,
} from 'semiotic/lib/svg/frameFunctions';
import {
  pointOnArcAtAngle,
  renderLaidOutPieces,
} from 'semiotic/lib/svg/pieceDrawing';
import {
  clusterBarLayout,
  barLayout,
  pointLayout,
  swarmLayout,
  timelineLayout,
} from 'semiotic/lib/svg/pieceLayouts';

import {
  drawSummaries,
  renderLaidOutSummaries,
} from 'semiotic/lib/svg/summaryLayouts';
import { stringToFn, stringToArrayFn } from 'semiotic/lib/data/dataFunctions';

import { genericFunction } from 'semiotic/lib/generic_utilities/functions';

const xScale = scaleLinear();
const yScale = scaleLinear();

const midMod = d => (d.middle ? d.middle : 0);
const zeroFunction = genericFunction(0);
const twoPI = Math.PI * 2;

const naturalLanguageTypes = {
  bar: { items: 'bar', chart: 'bar chart' },
  clusterbar: { items: 'bar', chart: 'grouped bar chart' },
  swarm: { items: 'point', chart: 'swarm plot' },
  point: { items: 'point', chart: 'point plot' },
  timeline: { items: 'bar', chart: 'timeline' },
};

const projectedCoordinatesObject = { y: 'y', x: 'x' };

const defaultOverflow = { top: 0, bottom: 0, left: 0, right: 0 };

const layoutHash = {
  clusterbar: clusterBarLayout,
  bar: barLayout,
  point: pointLayout,
  swarm: swarmLayout,
  timeline: timelineLayout,
};

class OrdinalFrame extends React.Component {
  static defaultProps = {
    annotations: [],
    foregroundGraphics: [],
    annotationSettings: {},
    projection: 'vertical',
    size: [500, 500],
    className: '',
    data: [],
    oScaleType: scaleBand,
    rScaleType: scaleLinear,
    type: 'none',
    summaryType: 'none',
    useSpans: false,
    optimizeCustomTooltipPosition: false,
  };

  static displayName = 'OrdinalFrame';

  constructor(props) {
    super(props);

    this.state = {
      adjustedPosition: [],
      adjustedSize: [],
      backgroundGraphics: undefined,
      foregroundGraphics: undefined,
      axisData: undefined,
      renderNumber: 0,
      oLabels: [],
      oAccessor: stringToArrayFn('renderKey'),
      rAccessor: stringToArrayFn('value'),
      oScale: scaleBand(),
      rScale: scaleLinear(),
      axes: undefined,
      calculatedOExtent: [],
      calculatedRExtent: [0, 1],
      columnOverlays: [],
      dataVersion: undefined,
      legendSettings: {},
      margin: { top: 0, bottom: 0, left: 0, right: 0 },
      oExtent: [],
      oScaleType: scaleBand(),
      orFrameRender: {},
      pieceDataXY: [],
      pieceIDAccessor: stringToFn('semioticPieceID'),
      projectedColumns: {},
      rExtent: [],
      rScaleType: scaleLinear(),
      summaryType: { type: 'none' },
      title: {},
      type: { type: 'none' },
    };
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this.props, this.state);
    }
  }

  calculateOrdinalFrame = currentProps => {
    let oLabels;
    const projectedColumns = {};

    const {
      oPadding: padding = 0,
      summaryType: baseSummaryType,
      type: baseType,
      connectorType: baseConnectorType,
      oAccessor: baseOAccessor,
      rAccessor: baseRAccessor,
      connectorStyle: baseConnectorStyle,
      style: baseStyle,
      rExtent: baseRExtent,
      oSort,
      sortO = oSort,
      pieceClass: basePieceClass,
      summaryStyle: baseSummaryStyle,
      summaryClass: baseSummaryClass,
      dynamicColumnWidth,
      projection,
      customHoverBehavior,
      customClickBehavior,
      customDoubleClickBehavior,
      size,
      pixelColumnWidth,
      title: baseTitle,
      oLabel,
      hoverAnnotation,
      pieceHoverAnnotation,
      summaryHoverAnnotation,
      backgroundGraphics,
      foregroundGraphics,
      oScaleType,
      rScaleType,
      legend,
      renderKey: baseRenderKey,
      data,
      margin: baseMargin,
      oExtent: baseOExtent,
      axes,
      axis: baseAxis = axes,
      pieceIDAccessor: basePieceIDAccessor,
      summaryPosition: baseSummaryPosition,
      multiAxis,
      baseMarkProps = {},
      annotations,
    } = currentProps;

    const summaryType = objectifyType(baseSummaryType);
    const pieceType = objectifyType(baseType);
    const connectorType = objectifyType(baseConnectorType);
    const oAccessor = stringToArrayFn(baseOAccessor, d => d.renderKey);
    const rAccessor = stringToArrayFn(baseRAccessor, d => d.value || 1);
    const renderKey = stringToFn(baseRenderKey, (d, i) => i);

    const eventListenersGenerator = () => ({});

    const connectorStyle = stringToFn(baseConnectorStyle, () => ({}), true);
    const summaryStyle = stringToFn(baseSummaryStyle, () => ({}), true);

    const pieceStyle = stringToFn(baseStyle, () => ({}), true);
    const pieceClass = stringToFn(basePieceClass, () => '', true);
    const summaryClass = stringToFn(baseSummaryClass, () => '', true);
    const summaryPosition = baseSummaryPosition || (position => position);
    const title =
      typeof baseTitle === 'object' &&
      !React.isValidElement(baseTitle) &&
      baseTitle !== null
        ? baseTitle
        : { title: baseTitle, orient: 'top' };

    const pieceIDAccessor = stringToFn(basePieceIDAccessor, () => '');

    const originalRAccessor = Array.isArray(baseRAccessor)
      ? baseRAccessor
      : [baseRAccessor];

    const originalOAccessor = Array.isArray(baseOAccessor)
      ? baseOAccessor
      : [baseOAccessor];

    const { allData, multiExtents } = keyAndObjectifyBarData({
      data,
      renderKey,
      oAccessor,
      rAccessor,
      originalRAccessor,
      originalOAccessor,
      multiAxis,
    });

    let arrayWrappedAxis;

    if (Array.isArray(baseAxis)) {
      arrayWrappedAxis = baseAxis.map(axisFnOrObject =>
        typeof axisFnOrObject === 'function'
          ? axisFnOrObject({ size: currentProps.size })
          : axisFnOrObject
      );
    } else if (baseAxis) {
      arrayWrappedAxis = [baseAxis].map(axisFnOrObject =>
        typeof axisFnOrObject === 'function'
          ? axisFnOrObject({ size: currentProps.size })
          : axisFnOrObject
      );
    }

    if (multiExtents && baseAxis) {
      arrayWrappedAxis.forEach((d, i) => {
        d.extentOverride = multiExtents[i];
      });
    }

    const margin = calculateMargin({
      margin: baseMargin,
      axes: arrayWrappedAxis,
      title,
      oLabel,
      projection,
      size,
    });

    const { adjustedPosition, adjustedSize } = adjustedPositionSize({
      size,
      margin,
      projection,
    });

    const oExtentSettings =
      baseOExtent === undefined || Array.isArray(baseOExtent)
        ? { extent: baseOExtent }
        : baseOExtent;

    const calculatedOExtent = allData.reduce((p, c) => {
      const baseOValue = c.column;
      const oValue = baseOValue !== undefined ? String(baseOValue) : baseOValue;

      if (p.indexOf(oValue) === -1) {
        p.push(oValue);
      }
      return p;
    }, []);

    let oExtent = oExtentSettings.extent || calculatedOExtent;

    if (pieceType.type === 'barpercent') {
      const oExtentSums = oExtent
        .map(d =>
          allData
            .filter(p => String(p.column) === d)
            .reduce((p, c) => p + c.value, 0)
        )
        .reduce((p, c, i) => {
          p[oExtent[i]] = c;
          return p;
        }, {});

      allData.forEach(d => {
        d.value =
          (oExtentSums[d.column] && d.value / oExtentSums[d.column]) || 0;
      });

      pieceType.type = 'bar';
    }

    if (pixelColumnWidth) {
      if (projection === 'radial') {
        console.error('pixelColumnWidth is not honored in radial mode');
      } else if (projection === 'vertical') {
        adjustedSize[0] = oExtent.length * pixelColumnWidth;
      } else {
        adjustedSize[1] = oExtent.length * pixelColumnWidth;
      }
    }

    const oDomain = (projection === 'vertical' && [0, adjustedSize[0]]) || [
      0,
      adjustedSize[1],
    ];

    const cwHash = oExtent.reduce(
      (p, c) => {
        p[c] = (1 / oExtent.length) * oDomain[1];
        p.total += p[c];
        return p;
      },
      { total: 0 }
    );

    const castOScaleType = oScaleType;

    const oScale = dynamicColumnWidth ? scaleOrdinal() : castOScaleType();

    oScale.domain(oExtent);

    let maxColumnValues;

    if (dynamicColumnWidth) {
      let columnValueCreator;
      if (typeof dynamicColumnWidth === 'string') {
        columnValueCreator = d => sum(d.map(p => p.data[dynamicColumnWidth]));
      } else {
        columnValueCreator = d => dynamicColumnWidth(d.map(p => p.data));
      }
      const thresholdDomain = [0];
      maxColumnValues = 0;
      const columnValues = [];

      oExtent.forEach(d => {
        const oValues = allData.filter(p => p.column === d);
        const columnValue = columnValueCreator(oValues);

        columnValues.push(columnValue);
        maxColumnValues += columnValue;
      });

      cwHash.total = 0;
      oExtent.forEach((d, i) => {
        const oValue = columnValues[i];
        const stepValue =
          (oValue / maxColumnValues) * (oDomain[1] - oDomain[0]);
        cwHash[d] = stepValue;
        cwHash.total += stepValue;
        if (i !== oExtent.length - 1) {
          thresholdDomain.push(stepValue + thresholdDomain[i]);
        }
      });
      oScale.range(thresholdDomain);
    } else {
      oScale.range(oDomain);
    }

    const rExtentSettings =
      baseRExtent === undefined || Array.isArray(baseRExtent)
        ? {
            extent: baseRExtent,
            onChange: undefined,
            includeAnnotations: false,
          }
        : baseRExtent;

    let rExtent = rExtentSettings.extent;
    let subZeroRExtent = [0, 0];

    if (
      pieceType.type === 'bar' &&
      summaryType.type &&
      summaryType.type !== 'none'
    ) {
      pieceType.type = 'none';
    }

    const annotationsForExtent = [];

    if (rExtentSettings.includeAnnotations && annotations) {
      rAccessor.forEach(actualRAccessor => {
        annotations.forEach((annotation, annotationIndex) => {
          const r = actualRAccessor(annotation, annotationIndex);
          if (isFinite(r)) {
            annotationsForExtent.push(r);
          }
        });
      });
    }

    if (pieceType.type === 'timeline') {
      const rData = allData.map(d => d.value);
      const leftExtent = extent(rData.map(d => d[0]));
      const rightExtent = extent(rData.map(d => d[1]));
      rExtent = extent([
        ...leftExtent,
        ...rightExtent,
        ...annotationsForExtent,
      ]);
    } else if (pieceType.type !== 'bar') {
      rExtent = extent([...allData.map(d => d.value), ...annotationsForExtent]);
    } else {
      const positiveData = allData.filter(d => d.value >= 0);
      const negativeData = allData.filter(d => d.value < 0);

      const nestedPositiveData = nest()
        .key(d => d.column)
        .rollup(leaves => sum(leaves.map(d => d.value)))
        .entries(positiveData);

      const nestedNegativeData = nest()
        .key(d => d.column)
        .rollup(leaves => sum(leaves.map(d => d.value)))
        .entries(negativeData);

      const positiveAnnotations = annotationsForExtent.filter(d => d > 0);

      rExtent = [
        0,
        nestedPositiveData.length === 0 && positiveAnnotations.length === 0
          ? 0
          : Math.max(
              max([
                ...nestedPositiveData.map(d => d.value),
                ...positiveAnnotations,
              ]),
              0
            ),
      ];

      const negativeAnnotations = annotationsForExtent.filter(d => d < 0);

      subZeroRExtent = [
        0,
        nestedNegativeData.length === 0
          ? 0
          : Math.min(
              min([
                ...nestedNegativeData.map(d => d.value),
                ...negativeAnnotations,
              ]),
              0
            ),
      ];
      rExtent = [subZeroRExtent[1], rExtent[1]];
    }

    if ((pieceType.type === 'clusterbar' || multiAxis) && rExtent[0] > 0) {
      rExtent[0] = 0;
    }

    const calculatedRExtent = rExtent;

    if (
      rExtentSettings.extent &&
      rExtentSettings.extent[0] !== undefined &&
      rExtentSettings.extent[1] !== undefined
    ) {
      rExtent = rExtentSettings.extent;
    } else {
      if (
        rExtentSettings.extent &&
        rExtentSettings.extent[1] !== undefined &&
        rExtentSettings.extent[0] === undefined
      ) {
        rExtent[1] = rExtentSettings.extent[1];
      }

      if (
        rExtentSettings.extent &&
        rExtentSettings.extent[0] !== undefined &&
        rExtentSettings.extent[1] === undefined
      ) {
        rExtent[0] = rExtentSettings.extent[0];
      }
    }

    if (
      currentProps.invertR ||
      (rExtentSettings.extent &&
        rExtentSettings.extent[0] > rExtentSettings.extent[1])
    ) {
      rExtent = [rExtent[1], rExtent[0]];
    }

    const nestedPieces = {};
    nest()
      .key(d => d.column)
      .entries(allData)
      .forEach(d => {
        nestedPieces[d.key] = d.values;
      });

    if (sortO !== undefined) {
      oExtent = oExtent.sort((a, b) =>
        sortO(
          a,
          b,
          nestedPieces[a].map(d => d.data),
          nestedPieces[b].map(d => d.data)
        )
      );

      oScale.domain(oExtent);
    }

    const rDomain = (projection === 'vertical' && [0, adjustedSize[1]]) || [
      0,
      adjustedSize[0],
    ];

    const castRScaleType = rScaleType;

    const instantiatedRScaleType = rScaleType.domain
      ? rScaleType
      : castRScaleType();

    const rScale = instantiatedRScaleType
      .copy()
      .domain(rExtent)
      .range(rDomain);

    const rScaleReverse = instantiatedRScaleType
      .copy()
      .domain(rDomain)
      .range(rDomain.reverse());

    const rScaleVertical = instantiatedRScaleType
      .copy()
      .domain(rExtent)
      .range(rDomain);

    const columnWidth = cwHash ? 0 : oScale.bandwidth();

    let pieceData = [];

    let mappedMiddleSize = adjustedSize[1];
    if (projection === 'vertical') {
      mappedMiddleSize = adjustedSize[0];
    }
    const mappedMiddles = this.mappedMiddles(oScale, mappedMiddleSize, padding);

    pieceData = oExtent.map(d => (nestedPieces[d] ? nestedPieces[d] : []));

    const zeroValue =
      projection === 'vertical' ? rScaleReverse(rScale(0)) : rScale(0);

    oExtent.forEach((o, i) => {
      projectedColumns[o] = {
        name: o,
        padding,
        pieceData: pieceData[i],
        pieces: pieceData[i],
      };
      projectedColumns[o].x = oScale(o) + padding / 2;
      projectedColumns[o].y = 0;
      projectedColumns[o].middle = mappedMiddles[o] + padding / 2;

      let negativeOffset = zeroValue;
      let positiveOffset = zeroValue;

      let negativeBaseValue = 0;
      let positiveBaseValue = 0;

      projectedColumns[o].pieceData.forEach(piece => {
        let valPosition;

        if (pieceType.type === 'timeline') {
          piece.scaledValue = rScale(piece.value[0]);
          piece.scaledEndValue = rScale(piece.value[1]);
          piece.scaledVerticalValue = rScaleVertical(piece.value[0]);
        } else if (
          pieceType.type !== 'bar' &&
          pieceType.type !== 'clusterbar'
        ) {
          piece.scaledValue = rScale(piece.value);
          piece.scaledVerticalValue = rScaleVertical(piece.value);
        } else if (pieceType.type === 'clusterbar') {
          valPosition =
            projection === 'vertical'
              ? rScaleReverse(rScale(piece.value))
              : rScale(piece.value);
          piece.scaledValue = Math.abs(zeroValue - valPosition);
        }

        piece.x = projectedColumns[o].x;
        if (piece.value >= 0) {
          if (pieceType.type === 'bar') {
            piece.scaledValue =
              projection === 'vertical'
                ? positiveOffset -
                  rScaleReverse(rScale(positiveBaseValue + piece.value))
                : rScale(positiveBaseValue + piece.value) - positiveOffset;

            positiveBaseValue += piece.value;
          }
          piece.base = zeroValue;
          piece.bottom = pieceType.type === 'bar' ? positiveOffset : 0;
          piece.middle = piece.scaledValue / 2 + positiveOffset;
          positiveOffset =
            projection === 'vertical'
              ? positiveOffset - piece.scaledValue
              : positiveOffset + piece.scaledValue;
          piece.negative = false;
        } else {
          if (pieceType.type === 'bar') {
            piece.scaledValue =
              projection === 'vertical'
                ? Math.abs(rScale(piece.value) - rScale(0))
                : Math.abs(rScale(piece.value) - zeroValue);

            negativeBaseValue += piece.value;
          }
          piece.base = zeroValue;
          piece.bottom = pieceType.type === 'bar' ? negativeOffset : 0;
          piece.middle = negativeOffset - piece.scaledValue / 2;
          negativeOffset =
            projection === 'vertical'
              ? negativeOffset + piece.scaledValue
              : negativeOffset - piece.scaledValue;
          piece.negative = true;
        }
      });

      if (cwHash) {
        projectedColumns[o].width = cwHash[o] - padding;

        if (currentProps.ordinalAlign === 'center') {
          if (i === 0) {
            projectedColumns[o].x =
              projectedColumns[o].x - projectedColumns[o].width / 2;
            projectedColumns[o].middle =
              projectedColumns[o].middle - projectedColumns[o].width / 2;
          } else {
            projectedColumns[o].x =
              projectedColumns[oExtent[i - 1]].x +
              projectedColumns[oExtent[i - 1]].width;
            projectedColumns[o].middle =
              projectedColumns[o].x + projectedColumns[o].width / 2;
          }
        }

        projectedColumns[o].pct = cwHash[o] / cwHash.total;
        projectedColumns[o].pct_start =
          (projectedColumns[o].x - oDomain[0]) / cwHash.total;
        projectedColumns[o].pct_padding = padding / cwHash.total;
        projectedColumns[o].pct_middle =
          (projectedColumns[o].middle - oDomain[0]) / cwHash.total;
      } else {
        projectedColumns[o].width = columnWidth - padding;
        if (currentProps.ordinalAlign === 'center') {
          projectedColumns[o].x =
            projectedColumns[o].x - projectedColumns[o].width / 2;
          projectedColumns[o].middle =
            projectedColumns[o].middle - projectedColumns[o].width / 2;
        }

        projectedColumns[o].pct = columnWidth / adjustedSize[1];
        projectedColumns[o].pct_start =
          (projectedColumns[o].x - oDomain[0]) / adjustedSize[1];
        projectedColumns[o].pct_padding = padding / adjustedSize[1];
        projectedColumns[o].pct_middle =
          (projectedColumns[o].middle - oDomain[0]) / adjustedSize[1];
      }
    });

    const labelArray = [];

    const pieArcs = [];

    const labelSettings =
      typeof oLabel === 'object'
        ? Object.assign({ label: true, padding: 5 }, oLabel)
        : this.props.labelSettingsProp
        ? { ...this.props.labelSettingsProp, label: oLabel }
        : { orient: 'default', label: oLabel, padding: 5 };

    if (oLabel || hoverAnnotation) {
      const offsetPct =
        (pieceType.offsetAngle && pieceType.offsetAngle / 360) || 0;

      const rangePct = (pieceType.angleRange &&
        pieceType.angleRange.map(d => d / 360)) || [0, 1];
      const rangeMod = rangePct[1] - rangePct[0];

      const adjustedPct =
        rangeMod < 1
          ? scaleLinear()
              .domain([0, 1])
              .range(rangePct)
          : d => d;

      oExtent.forEach(d => {
        const arcGenerator = arc()
          .innerRadius(0)
          .outerRadius(rScale.range()[1] / 2);

        const angle = projectedColumns[d].pct * rangeMod;
        const startAngle = adjustedPct(
          projectedColumns[d].pct_start + offsetPct
        );

        const endAngle = startAngle + angle;
        const midAngle = startAngle + angle / 2;

        const markD = arcGenerator({
          startAngle: startAngle * twoPI,
          endAngle: endAngle * twoPI,
        });
        const translate = [adjustedSize[0] / 2, adjustedSize[1] / 2];
        const centroid = arcGenerator.centroid({
          startAngle: startAngle * twoPI,
          endAngle: endAngle * twoPI,
        });

        const addedPadding =
          centroid[1] > 0 &&
          (!labelSettings.orient ||
            labelSettings.orient === 'default' ||
            labelSettings.orient === 'edge')
            ? 8
            : 0;

        const outerPoint = pointOnArcAtAngle(
          [0, 0],
          midAngle,
          rScale.range()[1] / 2 + labelSettings.padding + addedPadding
        );

        pieArcs.push({
          startAngle,
          endAngle,
          midAngle,
          markD,
          translate,
          centroid,
          outerPoint,
        });
      });
    }

    if (currentProps.oLabel) {
      let labelingFn;
      if (labelSettings.label) {
        labelingFn = (d, p, i) => {
          const labelStyle = this.props.labelStyle
            ? {
                ...this.props.labelStyle(d, i),
                textAnchor: 'middle',
              }
            : {
                textAnchor: 'middle',
              };
          if (projection === 'horizontal' && labelSettings.orient === 'right') {
            labelStyle.textAnchor = 'start';
          } else if (
            projection === 'horizontal' &&
            labelSettings.orient === 'left'
          ) {
            labelStyle.textAnchor = 'end';
          }
          const additionalStyle = {};
          let transformRotate;

          if (projection === 'radial' && labelSettings.orient === 'stem') {
            transformRotate = `rotate(${
              pieArcs[i].outerPoint[0] < 0
                ? pieArcs[i].midAngle * 360 + 90
                : pieArcs[i].midAngle * 360 - 90
            })`;
          } else if (
            projection === 'radial' &&
            labelSettings.orient !== 'center'
          ) {
            transformRotate = `rotate(${
              pieArcs[i].outerPoint[1] < 0
                ? pieArcs[i].midAngle * 360
                : pieArcs[i].midAngle * 360 + 180
            })`;
          }
          if (
            projection === 'radial' &&
            labelSettings.orient === 'stem' &&
            ((pieArcs[i].outerPoint[0] > 0 && labelSettings.padding < 0) ||
              (pieArcs[i].outerPoint[0] < 0 && labelSettings.padding >= 0))
          ) {
            additionalStyle.textAnchor = 'end';
          } else if (
            projection === 'radial' &&
            labelSettings.orient === 'stem'
          ) {
            additionalStyle.textAnchor = 'start';
          }
          const labelText =
            typeof labelSettings.label === 'function'
              ? labelSettings.label(d, i)
              : d;

          return (
            <text
              {...labelStyle}
              {...additionalStyle}
              transform={transformRotate}
            >
              {labelText}
            </text>
          );
        };
      }

      oExtent.forEach((d, i) => {
        let xPosition = projectedColumns[d].middle;
        let yPosition = 0;

        if (projection === 'horizontal') {
          yPosition = projectedColumns[d].middle;
          if (labelSettings.orient === 'right') {
            xPosition = adjustedSize[0] + 3;
          } else if (labelSettings.orient === 'center') {
            xPosition = adjustedSize[0] / 2;
          } else {
            xPosition = -3;
          }
        } else if (projection === 'radial') {
          if (labelSettings.orient === 'center') {
            xPosition = pieArcs[i].centroid[0] + pieArcs[i].translate[0];
            yPosition = pieArcs[i].centroid[1] + pieArcs[i].translate[1];
          } else {
            xPosition = pieArcs[i].outerPoint[0] + pieArcs[i].translate[0];
            yPosition = pieArcs[i].outerPoint[1] + pieArcs[i].translate[1];
          }
        }

        const label = labelingFn(
          d,
          projectedColumns[d].pieceData.map(d => d.data),
          i
          //          ,{ arc: pieArcs[i], data: projectedColumns[d] }
        );
        labelArray.push(
          <g
            key={`olabel-${i}`}
            transform={`translate(${xPosition},${yPosition})`}
          >
            {label}
          </g>
        );
      });

      if (projection === 'vertical') {
        let labelY;
        if (labelSettings.orient === 'top') {
          labelY = -15;
        } else {
          labelY = 15 + rScale.range()[1];
        }
        oLabels = (
          <g
            key="ordinalframe-labels-container"
            className="ordinal-labels"
            transform={`translate(${margin.left},${labelY + margin.top})`}
          >
            {labelArray}
          </g>
        );
      } else if (projection === 'horizontal') {
        oLabels = (
          <g
            key="ordinalframe-labels-container"
            className="ordinal-labels"
            transform={`translate(${margin.left},${margin.top})`}
          >
            {labelArray}
          </g>
        );
      } else if (projection === 'radial') {
        oLabels = (
          <g
            key="ordinalframe-labels-container"
            className="ordinal-labels"
            transform={`translate(${margin.left},${margin.top})`}
          >
            {labelArray}
          </g>
        );
      }
    }

    let columnOverlays;

    if (currentProps.hoverAnnotation) {
      columnOverlays = oExtent.map((d, i) => {
        const barColumnWidth = projectedColumns[d].width;
        let xPosition = projectedColumns[d].x;
        let yPosition = 0;
        let height = rScale.range()[1];
        let width = barColumnWidth + padding;
        if (projection === 'horizontal') {
          yPosition = projectedColumns[d].x;
          xPosition = 0;
          width = rScale.range()[1];
          height = barColumnWidth;
        }

        if (projection === 'radial') {
          const { markD, centroid, translate, midAngle } = pieArcs[i];
          const radialMousePackage = {
            type: 'column-hover',
            column: projectedColumns[d],
            pieces: projectedColumns[d].pieceData,
            summary: projectedColumns[d].pieceData,
            arcAngles: {
              centroid,
              translate,
              midAngle,
              length: rScale.range()[1] / 2,
            },
          };
          return {
            markType: 'path',
            key: `hover${d}`,
            d: markD,
            transform: `translate(${translate.join(',')})`,
            style: { opacity: 0, fill: 'pink' },
            overlayData: radialMousePackage,
            onDoubleClick:
              customDoubleClickBehavior &&
              (() => {
                customDoubleClickBehavior(radialMousePackage);
              }),
            onClick:
              customClickBehavior &&
              (() => {
                customClickBehavior(radialMousePackage);
              }),
            onMouseEnter:
              customHoverBehavior &&
              (() => {
                customHoverBehavior(radialMousePackage);
              }),
            onMouseLeave:
              customHoverBehavior &&
              (() => {
                customHoverBehavior();
              }),
          };
        }

        const baseMousePackage = {
          type: 'column-hover',
          column: projectedColumns[d],
          pieces: projectedColumns[d].pieceData,
          summary: projectedColumns[d].pieceData,
        };
        return {
          markType: 'rect',
          key: `hover-${d}`,
          x: xPosition,
          y: yPosition,
          height: height,
          width: width,
          style: { opacity: 0, stroke: 'black', fill: 'pink' },
          onDoubleClick:
            customDoubleClickBehavior &&
            (() => {
              customDoubleClickBehavior(baseMousePackage);
            }),
          onClick:
            customClickBehavior &&
            (() => {
              customClickBehavior(baseMousePackage);
            }),
          onMouseEnter:
            customHoverBehavior &&
            (() => {
              customHoverBehavior(baseMousePackage);
            }),
          onMouseLeave: () => ({}),
          overlayData: baseMousePackage,
        };
      });
    }

    const {
      renderMode,
      canvasSummaries,
      summaryRenderMode,
      connectorClass,
      connectorRenderMode,
      canvasConnectors,
      canvasPieces,
    } = currentProps;

    let pieceDataXY;
    const pieceRenderMode = stringToFn(renderMode, undefined, true);
    const pieceCanvasRender = stringToFn(canvasPieces, undefined, true);
    const summaryCanvasRender = stringToFn(canvasSummaries, undefined, true);
    const connectorCanvasRender = stringToFn(canvasConnectors, undefined, true);

    const pieceTypeForXY =
      pieceType.type && pieceType.type !== 'none' ? pieceType.type : 'point';
    const pieceTypeLayout =
      typeof pieceTypeForXY === 'function'
        ? pieceTypeForXY
        : layoutHash[pieceTypeForXY];

    const calculatedPieceData = pieceTypeLayout({
      type: pieceType,
      data: projectedColumns,
      renderMode: pieceRenderMode,
      eventListenersGenerator,
      styleFn: pieceStyle,
      projection,
      classFn: pieceClass,
      adjustedSize,
      chartSize: size,
      margin,
      rScale,
      baseMarkProps,
    });

    const keyedData = calculatedPieceData.reduce((p, c) => {
      if (c.o) {
        if (!p[c.o]) {
          p[c.o] = [];
        }
        p[c.o].push(c);
      }
      return p;
    }, {});

    Object.keys(projectedColumns).forEach(d => {
      projectedColumns[d].xyData = keyedData[d] || [];
    });
    let calculatedSummaries = {};

    if (summaryType.type && summaryType.type !== 'none') {
      calculatedSummaries = drawSummaries({
        data: projectedColumns,
        type: summaryType,
        renderMode: stringToFn(summaryRenderMode, undefined, true),
        styleFn: stringToFn(summaryStyle, () => ({}), true),
        classFn: stringToFn(summaryClass, () => '', true),
        //        canvasRender: stringToFn(canvasSummaries, undefined, true),
        positionFn: summaryPosition,
        projection,
        eventListenersGenerator,
        adjustedSize,
        baseMarkProps,
        //        chartSize: size,
        margin,
      });

      calculatedSummaries.originalData = projectedColumns;
    }

    const yMod = projection === 'horizontal' ? midMod : zeroFunction;
    const xMod = projection === 'vertical' ? midMod : zeroFunction;
    const basePieceData = calculatedPieceData
      .map(d => {
        if (d.piece && d.xy) {
          return {
            ...d.piece,
            type: 'frame-hover',
            x: d.xy.x + xMod(d.xy),
            y: d.xy.y + yMod(d.xy),
          };
        }
        return null;
      })
      .filter(d => d);

    if (
      (pieceHoverAnnotation &&
        ['bar', 'clusterbar', 'timeline'].indexOf(pieceType.type) === -1) ||
      summaryHoverAnnotation
    ) {
      if (summaryHoverAnnotation && calculatedSummaries.xyPoints) {
        pieceDataXY = calculatedSummaries.xyPoints.map(d =>
          Object.assign({}, d, {
            type: 'frame-hover',
            isSummaryData: true,
            x: d.x,
            y: d.y,
          })
        );
      } else if (pieceHoverAnnotation && calculatedPieceData) {
        pieceDataXY = basePieceData;
      }
    }

    const { axis, axesTickLines } = orFrameAxisGenerator({
      axis: arrayWrappedAxis,
      data: allData,
      projection,
      adjustedSize,
      size,
      rScale,
      rScaleType: instantiatedRScaleType.copy(),
      pieceType,
      rExtent,
      maxColumnValues,
      xyData: basePieceData,
      margin,
    });

    if (
      pieceHoverAnnotation &&
      ['bar', 'clusterbar', 'timeline'].indexOf(pieceType.type) !== -1
    ) {
      const yMod = projection === 'horizontal' ? midMod : zeroFunction;
      const xMod = projection === 'vertical' ? midMod : zeroFunction;

      columnOverlays = calculatedPieceData.map((d, i) => {
        const mousePackage = {
          ...d.piece,
          x: d.xy.x + xMod(d.xy),
          y: d.xy.y + yMod(d.xy),
        };
        if (React.isValidElement(d.renderElement)) {
          return {
            renderElement: d.renderElement,
            overlayData: mousePackage,
          };
        }
        return {
          ...d.renderElement,
          key: `hover-${i}`,
          type: 'frame-hover',
          style: { opacity: 0, stroke: 'black', fill: 'pink' },
          overlayData: mousePackage,
          onClick:
            customClickBehavior &&
            (() => {
              customClickBehavior(mousePackage.data);
            }),
          onDoubleClick:
            customDoubleClickBehavior &&
            (() => {
              customDoubleClickBehavior(mousePackage.data);
            }),
          onMouseEnter:
            customHoverBehavior &&
            (() => {
              customHoverBehavior(mousePackage.data);
            }),
          onMouseLeave:
            customHoverBehavior &&
            (() => {
              customHoverBehavior();
            }),
        };
      });
    }

    const typeAriaLabel = (pieceType.type !== undefined &&
      typeof pieceType.type !== 'function' &&
      naturalLanguageTypes[pieceType.type]) || {
      items: 'piece',
      chart: 'ordinal chart',
    };

    const orFrameRender = {
      connectors: {
        accessibleTransform: (data, i) => data[i],
        projection,
        data: keyedData,
        styleFn: stringToFn(connectorStyle, () => ({}), true),
        classFn: stringToFn(connectorClass, () => '', true),
        renderMode: stringToFn(connectorRenderMode, undefined, true),
        canvasRender: connectorCanvasRender,
        behavior: orFrameConnectionRenderer,
        type: connectorType,
        eventListenersGenerator,
        pieceType,
      },
      summaries: {
        accessibleTransform: (data, i) => {
          const columnName = oExtent[i];

          const summaryPackage = {
            type: 'column-hover',
            column: projectedColumns[columnName],
            pieces: projectedColumns[columnName].pieceData,
            summary: projectedColumns[columnName].pieceData,
            oAccessor,
          };
          return summaryPackage;
        },
        data: calculatedSummaries.marks,
        behavior: renderLaidOutSummaries,
        canvasRender: summaryCanvasRender,
        styleFn: stringToFn(summaryStyle, () => ({}), true),
        classFn: stringToFn(summaryClass, () => '', true),
      },
      pieces: {
        accessibleTransform: (data, i) => ({
          ...(data[i].piece ? { ...data[i].piece, ...data[i].xy } : data[i]),
          type: 'frame-hover',
        }),
        shouldRender: pieceType.type && pieceType.type !== 'none',
        data: calculatedPieceData,
        behavior: renderLaidOutPieces,
        canvasRender: pieceCanvasRender,
        styleFn: stringToFn(pieceStyle, () => ({}), true),
        classFn: stringToFn(pieceClass, () => '', true),
        axis: arrayWrappedAxis,
        ariaLabel: typeAriaLabel,
      },
    };

    if (
      rExtentSettings.onChange &&
      (this.state.calculatedRExtent || []).join(',') !==
        (calculatedRExtent || []).join(',')
    ) {
      rExtentSettings.onChange(calculatedRExtent);
    }

    if (
      oExtentSettings.onChange &&
      (this.state.calculatedOExtent || []).join(',') !==
        (calculatedOExtent || []).join(',')
    ) {
      oExtentSettings.onChange(calculatedOExtent);
    }

    this.setState({
      pieceDataXY,
      adjustedPosition,
      adjustedSize,
      backgroundGraphics,
      foregroundGraphics,
      axisData: arrayWrappedAxis,
      axes: axis,
      axesTickLines,
      oLabels,
      title,
      columnOverlays,
      renderNumber: this.state.renderNumber + 1,
      oAccessor,
      rAccessor,
      oScaleType,
      rScaleType: instantiatedRScaleType,
      oExtent,
      rExtent,
      oScale,
      rScale,
      calculatedOExtent,
      calculatedRExtent,
      projectedColumns,
      margin,
      legendSettings: legend,
      orFrameRender,
      summaryType,
      type: pieceType,
      pieceIDAccessor,
    });
  };

  componentWillMount() {
    // disabled prop checking to enable custom props

    // Object.keys(this.props).forEach(d => {
    //   if (!ordinalframeproptypes[d]) {
    //     if (xyframeproptypes[d]) {
    //       console.error(
    //         `${d} is an XYFrame prop are you sure you're using the right frame?`
    //       )
    //     } else if (networkframeproptypes[d]) {
    //       console.error(
    //         `${d} is a NetworkFrame prop are you sure you're using the right frame?`
    //       )
    //     } else {
    //       console.error(`${d} is not a valid OrdinalFrame prop`)
    //     }
    //   }
    // })

    this.calculateOrdinalFrame(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (this.state.dataVersion &&
        this.state.dataVersion !== nextProps.dataVersion) ||
      !this.state.projectedColumns
    ) {
      this.calculateOrdinalFrame(nextProps);
    } else if (
      this.props.size[0] !== nextProps.size[0] ||
      this.props.size[1] !== nextProps.size[1] ||
      (!this.state.dataVersion &&
        orFrameChangeProps.find(d => {
          return this.props[d] !== nextProps[d];
        }))
    ) {
      this.calculateOrdinalFrame(nextProps);
    }
  }

  defaultORSVGRule = ({ d, i, annotationLayer }) => {
    const { projection } = this.props;

    const {
      adjustedPosition,
      adjustedSize,
      oAccessor,
      rAccessor,
      oScale,
      rScale,
      projectedColumns,
      orFrameRender,
      pieceIDAccessor,
      rScaleType,
    } = this.state;

    let screenCoordinate = [0, 0];

    //TODO: Support radial??
    if (d.coordinates || (d.type === 'enclose' && d.neighbors)) {
      screenCoordinates = (d.coordinates || d.neighbors).map(p => {
        const pO = findFirstAccessorValue(oAccessor, p) || p.column;
        const oColumn = projectedColumns[pO];
        const idPiece = findIDPiece(pieceIDAccessor, oColumn, p);

        return screenProject({
          p,
          adjustedSize,
          rScale,
          rAccessor,
          idPiece,
          projection,
          oColumn,
          rScaleType,
        });
      });
    } else {
      const pO = findFirstAccessorValue(oAccessor, d) || d.column;
      const oColumn = projectedColumns[pO];
      const idPiece = findIDPiece(pieceIDAccessor, oColumn, d);

      screenCoordinates = screenProject({
        p: d,
        adjustedSize,
        rScale,
        rAccessor,
        idPiece,
        projection,
        oColumn,
        rScaleType,
      });
    }

    const { voronoiHover } = annotationLayer;

    //TODO: Process your rules first
    const customAnnotation =
      this.props.svgAnnotationRules &&
      this.props.svgAnnotationRules({
        d,
        i,
        oScale,
        rScale,
        oAccessor,
        rAccessor,
        orFrameProps: this.props,
        orFrameState: this.state,
        screenCoordinates,
        adjustedPosition,
        adjustedSize,
        annotationLayer,
        categories: this.state.projectedColumns,
        voronoiHover,
      });
    if (this.props.svgAnnotationRules && customAnnotation !== null) {
      return customAnnotation;
    } else if (d.type === 'desaturation-layer') {
      return desaturationLayer({
        style: d.style instanceof Function ? d.style(d, i) : d.style,
        size: adjustedSize,
        i,
        key: d.key,
      });
    } else if (d.type === 'ordinal-line') {
      return svgOrdinalLine({ d, screenCoordinates, voronoiHover });
    } else if (d.type === 'or') {
      return svgORRule({ d, i, screenCoordinates, projection });
    } else if (d.type === 'highlight') {
      return svgHighlightRule({
        d,
        pieceIDAccessor,
        orFrameRender,
        oAccessor,
      });
    } else if (d.type === 'react-annotation' || typeof d.type === 'function') {
      return basicReactAnnotationRule({ d, i, screenCoordinates });
    } else if (d.type === 'enclose') {
      return svgEncloseRule({ d, i, screenCoordinates });
    } else if (d.type === 'enclose-rect') {
      return svgRectEncloseRule({ d, screenCoordinates, i });
    } else if (d.type === 'r') {
      return svgRRule({
        d,
        i,
        screenCoordinates,
        rScale,
        rAccessor,
        projection,
        adjustedSize,
        adjustedPosition,
      });
    } else if (d.type === 'category') {
      return svgCategoryRule({
        projection,
        d,
        i,
        categories: this.state.projectedColumns,
        adjustedSize,
      });
    }
    return null;
  };

  defaultORHTMLRule = ({ d, i, annotationLayer }) => {
    const {
      adjustedPosition,
      adjustedSize,
      oAccessor,
      rAccessor,
      oScale,
      rScale,
      projectedColumns,
      summaryType,
      type,
      pieceIDAccessor,
      rScaleType,
    } = this.state;
    const {
      htmlAnnotationRules,
      tooltipContent,
      optimizeCustomTooltipPosition,
      projection,
      size,
      useSpans,
    } = this.props;
    let screenCoordinate = [0, 0];

    const { voronoiHover } = annotationLayer;

    if (d.coordinates || (d.type === 'enclose' && d.neighbors)) {
      screenCoordinates = (d.coordinates || d.neighbors).map(p => {
        const pO = findFirstAccessorValue(oAccessor, p) || p.column;
        const oColumn = projectedColumns[pO];
        const idPiece = findIDPiece(pieceIDAccessor, oColumn, p);

        return screenProject({
          p,
          adjustedSize,
          rScale,
          rAccessor,
          idPiece,
          projection,
          oColumn,
          rScaleType,
        });
      });
    } else if (d.type === 'column-hover') {
      const {
        coordinates: [xPosition, yPosition],
      } = getColumnScreenCoordinates({
        d,
        projectedColumns,
        oAccessor,
        summaryType,
        type,
        projection,
        adjustedPosition,
        adjustedSize,
      });
      screenCoordinates = [xPosition, yPosition];
    } else {
      const pO = findFirstAccessorValue(oAccessor, d) || d.column;
      const oColumn = projectedColumns[pO];
      const idPiece = findIDPiece(pieceIDAccessor, oColumn, d);

      screenCoordinates = screenProject({
        p: d,
        adjustedSize,
        rScale,
        rAccessor,
        idPiece,
        projection,
        oColumn,
        rScaleType,
      });
    }

    const flippedRScale =
      projection === 'vertical'
        ? rScaleType.domain(rScale.domain()).range(rScale.range().reverse())
        : rScale;
    //TODO: Process your rules first
    const customAnnotation =
      htmlAnnotationRules &&
      htmlAnnotationRules({
        d,
        i,
        oScale,
        rScale: flippedRScale,
        oAccessor,
        rAccessor,
        orFrameProps: this.props,
        screenCoordinates,
        adjustedPosition,
        adjustedSize,
        annotationLayer,
        orFrameState: this.state,
        categories: this.state.projectedColumns,
        voronoiHover,
      });

    if (htmlAnnotationRules && customAnnotation !== null) {
      return customAnnotation;
    }

    if (d.type === 'frame-hover') {
      return htmlFrameHoverRule({
        d,
        i,
        rAccessor,
        oAccessor,
        projection,
        tooltipContent,
        optimizeCustomTooltipPosition,
        projectedColumns,
        useSpans,
        pieceIDAccessor,
        adjustedSize,
        rScale,
        type,
        rScaleType,
      });
    } else if (d.type === 'column-hover') {
      return htmlColumnHoverRule({
        d,
        i,
        summaryType,
        oAccessor,
        projectedColumns,
        type,
        adjustedPosition,
        adjustedSize,
        projection,
        tooltipContent,
        optimizeCustomTooltipPosition,
        useSpans,
      });
    }
    return null;
  };

  mappedMiddles(oScale, middleMax, padding) {
    const oScaleDomainValues = oScale.domain();

    const mappedMiddles = {};
    oScaleDomainValues.forEach((p, q) => {
      const base = oScale(p) - padding;
      const next = oScaleDomainValues[q + 1]
        ? oScale(oScaleDomainValues[q + 1])
        : middleMax;
      const diff = (next - base) / 2;
      mappedMiddles[p] = base + diff;
    });

    return mappedMiddles;
  }

  render() {
    const {
      className,
      annotationSettings,
      downloadFields,
      rAccessor,
      oAccessor,
      name,
      download,
      annotations,
      matte,
      renderKey,
      interaction,
      customClickBehavior,
      customHoverBehavior,
      customDoubleClickBehavior,
      projection,
      backgroundGraphics,
      foregroundGraphics,
      afterElements,
      beforeElements,
      disableContext,
      summaryType,
      summaryHoverAnnotation,
      pieceHoverAnnotation,
      hoverAnnotation,
      canvasPostProcess,
      baseMarkProps,
      useSpans,
      canvasPieces,
      canvasSummaries,
      canvasConnectors,
      renderOrder,
      additionalDefs,
    } = this.props;

    const {
      orFrameRender,
      projectedColumns,
      adjustedPosition,
      adjustedSize,
      legendSettings,
      columnOverlays,
      axesTickLines,
      axes,
      margin,
      pieceDataXY,
      oLabels,
      title,
    } = this.state;

    let downloadButton;

    const size = [
      adjustedSize[0] + margin.left + margin.right,
      adjustedSize[1] + margin.top + margin.bottom,
    ];

    if (download) {
      downloadButton = (
        <DownloadButton
          csvName={`${name || 'orframe'}-${new Date().toJSON()}`}
          width={size[0]}
          data={orDownloadMapping({
            data: projectedColumns,
            rAccessor: stringToArrayFn(rAccessor),
            oAccessor: stringToArrayFn(oAccessor),
            fields: downloadFields,
          })}
        />
      );
    }

    let interactionOverflow;

    if (summaryType && summaryType.amplitude) {
      if (projection === 'horizontal') {
        interactionOverflow = {
          top: summaryType.amplitude,
          bottom: 0,
          left: 0,
          right: 0,
        };
      } else if (projection === 'radial') {
        interactionOverflow = defaultOverflow;
      } else {
        interactionOverflow = {
          top: 0,
          bottom: 0,
          left: summaryType.amplitude,
          right: 0,
        };
      }
    }

    const renderedForegroundGraphics =
      typeof foregroundGraphics === 'function'
        ? foregroundGraphics({ size, margin })
        : foregroundGraphics;

    return (
      <Frame
        name="ordinalframe"
        renderPipeline={orFrameRender}
        adjustedPosition={adjustedPosition}
        adjustedSize={adjustedSize}
        size={size}
        xScale={xScale}
        yScale={yScale}
        axes={axes}
        useSpans={useSpans}
        axesTickLines={axesTickLines}
        title={title}
        matte={matte}
        additionalDefs={additionalDefs}
        className={`${className} ${projection}`}
        frameKey={'none'}
        renderFn={renderKey}
        projectedCoordinateNames={projectedCoordinatesObject}
        defaultSVGRule={this.defaultORSVGRule.bind(this)}
        defaultHTMLRule={this.defaultORHTMLRule.bind(this)}
        hoverAnnotation={
          summaryHoverAnnotation || pieceHoverAnnotation || hoverAnnotation
        }
        annotations={annotations}
        annotationSettings={annotationSettings}
        legendSettings={legendSettings}
        interaction={
          interaction && {
            ...interaction,
            brush: interaction.columnsBrush !== true && 'oBrush',
            projection,
            projectedColumns,
          }
        }
        customClickBehavior={customClickBehavior}
        customHoverBehavior={customHoverBehavior}
        customDoubleClickBehavior={customDoubleClickBehavior}
        points={pieceDataXY}
        margin={margin}
        columns={projectedColumns}
        backgroundGraphics={backgroundGraphics}
        foregroundGraphics={[renderedForegroundGraphics, oLabels]}
        beforeElements={beforeElements}
        afterElements={afterElements}
        downloadButton={downloadButton}
        overlay={columnOverlays}
        rScale={this.state.rScale}
        projection={projection}
        disableContext={disableContext}
        interactionOverflow={interactionOverflow}
        canvasPostProcess={canvasPostProcess}
        baseMarkProps={baseMarkProps}
        canvasRendering={
          !!(canvasPieces || canvasSummaries || canvasConnectors)
        }
        renderOrder={renderOrder}
        disableCanvasInteraction={true}
      />
    );
  }
}

export default OrdinalFrame;
