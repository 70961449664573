import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const SuccessTooltip = withStyles({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: '#90B850',
  },
  tooltip: {
    padding: 8,
    backgroundColor: '#90B850',
    border: '1px solid #E6E8ED',
    color: '#fff',
    fontSize: '14px',
  },
})(Tooltip);

export default SuccessTooltip;
