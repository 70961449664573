import WeightChart from './WeightChart';
import BloodPressureChart from './BloodPressureChart';
import CaloriesChart from './CaloriesChart';
import SleepChart from './SleepChart';
import WaterChart from './WaterChart';
import Charts from './Charts';

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function getAverage(obj, keys) {
  const result = {};
  obj.forEach(item => {
    keys.forEach(key => {
      if (!item[key] || item[key] === 0) return;
      const value = item[key];
      if (result[key]) {
        result[key].value += value;
        result[key].freq += 1;
      } else {
        result[key] = {
          value,
          freq: 1,
          title: key,
        };
      }
    });
  });
  const averages = Object.values(result).map(item => {
    return {
      average: round(item.value / item.freq, 1),
      title: item.title,
    };
  });
  return averages;
}

export {
  WeightChart,
  BloodPressureChart,
  WaterChart,
  CaloriesChart,
  SleepChart,
};

export default Charts;
