import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutlinedContainer from './OutlinedContainer';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

function HeightField(props) {
  const [fieldFootValue, setFieldFootValue] = useState('');
  const [fieldInchValue, setFieldInchValue] = useState('');
  const [isLoadingProps, setIsLoadingProps] = useState(true);

  useEffect(() => {
    if (isLoadingProps) {
      //prevent initialValues to affect state when component receives props
      setFieldFootValue(props.initialFeetValue);
      setFieldInchValue(props.initialInchesValue);
    }
  }, [props.initialFeetValue, props.initialInchesValue]);

  const handleChangeField = field => event => {
    if (isLoadingProps) setIsLoadingProps(false);
    const value = event.target.value ? parseInt(event.target.value) : '';
    switch (field) {
      case 'foot':
        if (value > 9) return;
        setFieldFootValue(value);
        if (props.shouldSendBothValues) {
          props.onChange({
            foot: value,
            inches: fieldInchValue,
          });
        } else {
          props.onChange('foot', value);
        }
        break;

      case 'inches':
        if (value > 11) return;
        setFieldInchValue(value);
        if (props.shouldSendBothValues) {
          props.onChange({
            foot: fieldFootValue,
            inches: value,
          });
        } else {
          props.onChange('inches', value);
        }
        break;
    }
  };

  return (
    <OutlinedContainer
      label={props.noLabel ? '' : 'Height'}
      error={props.error}
      disabled={props.disabled}
      {...props.containerProps}
    >
      <Grid
        spacing={0}
        style={{ display: 'flex', flexDirection: 'row' }}
        key="3"
      >
        <Grid item xs={6}>
          <Input
            type="number"
            key="2"
            style={{ margin: '0', padding: '0px 10px', height: '18px' }}
            value={fieldFootValue ? fieldFootValue : ''}
            placeholder="0"
            maxLength="2"
            inputProps={{
              min: 0,
              max: 9,
            }}
            onChange={handleChangeField('foot')}
            endAdornment={<InputAdornment position="end">ft</InputAdornment>}
            disabled={props.disabled ? props.disabled : false}
            disableUnderline
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="number"
            key="1"
            style={{ margin: '0', padding: '0 10px', height: '18px' }}
            value={fieldInchValue ? fieldInchValue : ''}
            placeholder="0"
            maxLength="2"
            inputProps={{
              min: 0,
              max: 11,
            }}
            endAdornment={<InputAdornment position="end">in</InputAdornment>}
            onChange={handleChangeField('inches')}
            disabled={props.disabled ? props.disabled : false}
            disableUnderline
            fullWidth
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  );
}

HeightField.propTypes = {
  initialFeetValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  initialInchesValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default HeightField;
