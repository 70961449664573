import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { setMediaObjects } from '../mediaObject/actions';

export const ALLERGY_REQUEST = 'allergy/ALLERGY_REQUEST';
export const ALLERGY_SUCCESS = 'allergy/ALLERGY_SUCCESS';
export const ALLERGY_FAILURE = 'allergy/ALLERGY_FAILURE';

export const ALLERGY_CREATE_REQUEST = 'allergy/ALLERGY_CREATE_REQUEST';
export const ALLERGY_CREATE_SUCCESS = 'allergy/ALLERGY_CREATE_SUCCESS';
export const ALLERGY_CREATE_FAILURE = 'allergy/ALLERGY_CREATE_FAILURE';

export const ALLERGY_UPDATE_REQUEST = 'allergy/ALLERGY_UPDATE_REQUEST';
export const ALLERGY_UPDATE_SUCCESS = 'allergy/ALLERGY_UPDATE_SUCCESS';
export const ALLERGY_UPDATE_FAILURE = 'allergy/ALLERGY_UPDATE_FAILURE';

export const ALLERGY_DELETE = 'allergy/ALLERGY_DELETE';
export const ALLERGY_DELETE_SUCCESS = 'allergy/ALLERGY_DELETE_SUCCESS';
export const ALLERGY_DELETE_FAILURE = 'allergy/ALLERGY_DELETE_FAILURE';

export const ALLERGY_SELECT_ID = 'allergy/SELECT_ID';
export const ALLERGY_CLEAR_ID = 'allergy/CLEAR_ID';

export const ALLERGY_REMOVE_MEDIA = 'allergy/remove_media';
export const ALLERGY_DELETE_MEDIA = 'allergy/delete_media';
export const ALLERGY_DELETE_MEDIA_CANCEL = 'allergy/delete_media_cancel';
export const ALLERGY_UPDATE_MEDIA = 'allergy/update_media';

export const ALLERGY_SUBMIT = 'ALLERGY/submitForm';

export const submitForm = () => ({
  type: ALLERGY_SUBMIT,
});

export const allergySelectId = id => ({
  type: ALLERGY_SELECT_ID,
  payload: id,
});

export const allergyClearSelectedId = () => ({
  type: ALLERGY_CLEAR_ID,
});

export const fetchAllergies = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [ALLERGY_REQUEST, ALLERGY_SUCCESS, ALLERGY_FAILURE],
    endpoint: `/api/member/allergies`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json', Pid: pid },
      },
    },
  },
});

// export const allergyCreate = (id, allergy, bundles) => {
//   return async dispatch => {
//     const tid = uuidv1();
//     const payload = {
//       ...allergy,
//       personId: id,
//       name: '',
//       allergenType: allergy.name ? allergy.name : '',
//       startOn: allergy.startOn,
//       endOn: allergy.endOn ? allergy.endOn : '',
//       reaction: codeObjectListToCsv(allergy.reaction),
//       treatment: codeObjectListToCsv(allergy.treatment),
//       comments: allergy.comments ? allergy.comments : '',
//     };

//     dispatch({ type: ALLERGY_CREATE_REQUEST, tid, payload });

//     try {
//       const response = await apiFetchAuthorized(`/api/member/allergy`, {
//         method: 'POST',
//         mode: 'cors',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       const json = await response.json();

//       if (bundles.length > 0) {
//         await dispatch(
//           uploadFiles(id, json.data.allergyId, bundles, 'allergy')
//         );
//       }

//       return await dispatch({
//         type: ALLERGY_CREATE_SUCCESS,
//         payload: json.data,
//         tid,
//         bundles,
//       });
//     } catch (error) {
//       return dispatch({ type: ALLERGY_CREATE_FAILURE, message: error });
//     }
//   };
// };

export const allergyCreate = (pid, allergy, bundles) => {
  const payload = {
    ...allergy,
    personId: pid,
    name: '',
    allergenType: allergy.name ? allergy.name : '',
    startOn: allergy.startOn,
    endOn: allergy.endOn ? allergy.endOn : '',
    reaction: codeObjectListToCsv(allergy.reaction),
    treatment: codeObjectListToCsv(allergy.treatment),
    comments: allergy.comments ? allergy.comments : '',
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        ALLERGY_CREATE_REQUEST,
        ALLERGY_CREATE_SUCCESS,
        ALLERGY_CREATE_FAILURE,
      ],
      endpoint: `/api/member/allergy`,
      payload,
      meta: {
        pid,
        // isCurrentPerson,
        mediaObject: setMediaObjects(bundles, 'allergyId', 'allergy'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

// export const allergyUpdate = (pid, aid, allergy, bundles) => {
//   return async dispatch => {
//     const payload = {
//       ...allergy,
//       personId: pid,
//       allergyId: aid,
//       name: '',
//       allergenType: allergy.name ? allergy.name : '',
//       startOn: allergy.startOn,
//       endOn: allergy.endOn ? allergy.endOn : '',
//       reaction: codeObjectListToCsv(allergy.reaction),
//       treatment: codeObjectListToCsv(allergy.treatment),
//       comments: allergy.comments ? allergy.comments : '',
//     };

//     dispatch({ type: ALLERGY_UPDATE_REQUEST });

//     try {
//       const response = await apiFetchAuthorized(`/api/member/allergy`, {
//         method: 'PUT',
//         mode: 'cors',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       const json = await response.json();
//       if (bundles.length > 0) {
//         await dispatch(uploadFiles(pid, aid, bundles, 'allergy'));
//       }

//       return await dispatch({
//         type: ALLERGY_UPDATE_SUCCESS,
//         payload: json.data,
//         bundles,
//       });
//     } catch (error) {
//       return dispatch({ type: ALLERGY_UPDATE_FAILURE, message: error });
//     }
//   };
// };

export const allergyUpdate = (pid, aid, allergy, bundles) => {
  const payload = {
    ...allergy,
    personId: pid,
    allergyId: aid,
    name: '',
    allergenType: allergy.name ? allergy.name : '',
    startOn: allergy.startOn,
    endOn: allergy.endOn ? allergy.endOn : '',
    reaction: codeObjectListToCsv(allergy.reaction),
    treatment: codeObjectListToCsv(allergy.treatment),
    comments: allergy.comments ? allergy.comments : '',
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        ALLERGY_UPDATE_REQUEST,
        ALLERGY_UPDATE_SUCCESS,
        ALLERGY_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/allergy`,
      payload,
      meta: {
        pid,
        // isCurrentPerson,
        mediaObject: setMediaObjects(bundles, 'allergyId', 'allergy'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

export const allergyDelete = (pid, aid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [ALLERGY_DELETE, ALLERGY_DELETE_SUCCESS, ALLERGY_DELETE_FAILURE],
    endpoint: `/api/member/allergy/${aid}`,
    payload: aid,
    meta: {
      config: {
        headers: { Pid: pid },
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});
