import { LABS_CATEGORY } from '../../../../store/reducers/labs/actions';
import { getBundlesToUpload } from '../../../components/MediaObject/helpers';

export const UPDATE = 'update';
export const CREATE = 'create';
export const IDLE = 'idle';
export const VIEW = 'view';
export const SUBMIT = 'submit';

export const useLabActions = (props, setStatus) => {
  const {
    labsCreate,
    labsUpdate,
    labsSelectId,
    labsDelete,
    labsClearSelectedId,
    clearFiles,
    labs: { mediaObjects, selectedId, bundlesToDelete },
    form,
    personId,
    updateMedia,
  } = props;

  const removeLab = lab => {
    labsDelete(lab.personId, lab.labResultId);
  };

  const addLab = () => {
    setStatus(CREATE);
  };

  const updateLab = id => {
    labsSelectId(id);
    setStatus(UPDATE);
  };

  const viewMediaObjects = lab => {
    labsSelectId(lab.labResultId);
    setStatus(VIEW);
  };

  const updateMediaObject = lab => {};

  const handleCloseDrawer = () => {
    if (status !== IDLE) {
      clearFiles();
      labsClearSelectedId();
      setStatus(IDLE);
    }
  };

  const hasFormChanges = values => {
    return (
      form &&
      form['labs-form'] &&
      JSON.stringify(form['labs-form'].initial) !== JSON.stringify(values)
    );
  };

  const handleSubmit = async value => {
    if (props.mediaObject.bundles.length > 0) {
      setStatus(SUBMIT);
    }

    const { bundlesToUpload, bundlesToUpdate } = getBundlesToUpload(
      mediaObjects,
      form,
      LABS_CATEGORY
    );

    let result;
    if (Boolean(selectedId)) {
      result = await labsUpdate(personId, selectedId, value, bundlesToUpload, {
        hasFormChanges: hasFormChanges(value),
        bundlesToUpdate,
        bundlesToDelete,
      });
    } else {
      result = await labsCreate(personId, value, bundlesToUpload);
    }
    handleCloseDrawer();
  };

  const onUpdateMediaObject = async (pid, mid, data, oid) => {
    await updateMedia(
      pid,
      mid,
      {
        ...data.data,
        category: LABS_CATEGORY,
        personId: data.personId,
      },
      oid
    );
    handleCloseDrawer();
  };

  const actions = {
    removeLab,
    addLab,
    updateLab,
    viewMediaObjects,
    updateMediaObject,
    handleCloseDrawer,
    handleSubmit,
    onUpdateMediaObject,
  };

  return actions;
};
