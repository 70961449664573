import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import validate from './validate';
import Loader from '../../components/Loader';
import { renderFormInput } from '../../../library/forms/Field';
import InputField from '../../../library/forms/InputField';
import { getCookie } from '../../pages/SignInPage/util';
import PasswordField from '../../../library/forms/PasswordField';

import signInPageStyles from '../../pages/SignInPage/SignInPage.css';
import { loginUser } from './actions';

const styles = theme => ({
  button: {
    color: '#fff',
    width: 180,
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&$disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      // backgroundColor: theme.palette.secondary.dark,
    },
  },
  disabled: {}, // Needed to override disabled class
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    fontSize: theme.spacing(2) + 2,
    opacity: 0.8,
    marginRight: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: theme.spacing(2),
    borderLeft: `solid 5px ${theme.palette.warning.main}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  formControlLabel: {
    fontSize: '14px',
  },
});

const SignInForm = ({
  classes,
  history,
  valid,
  pristine,
  submitting,
  handleSubmit,
  rememberMe,
  handleRememberMe,
  isAuthenticating,
  messageSlot,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {isAuthenticating && <Loader>Verifying...</Loader>}
      <Grid className={classes.field}>
        <Field
          name="username"
          type="text"
          enableMinHeight={true}
          label="Email Address"
          component={InputField}
        />
      </Grid>
      <Grid className={classes.field}>
        <Field
          name="password"
          type="password"
          label="Password"
          component={PasswordField}
        />
      </Grid>
      {messageSlot && messageSlot()}
      <FormGroup>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Checkbox checked={rememberMe} onChange={handleRememberMe} />
          }
          label="Remember my login ID"
        />
      </FormGroup>
      <Grid className={classes.buttonWrapper}>
        <Button
          className={classes.forgotPassword}
          onClick={() => history.push('/account/forgot-password')}
          color="primary"
        >
          Forgot password?
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          size="large"
          classes={{ root: classes.button, disabled: classes.disabled }}
          disabled={pristine || submitting || !valid || isAuthenticating}
        >
          Sign In
        </Button>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const username = getCookie('username');
  return {
    initialValues: {
      username,
    },
  };
};

let SignInFormRedux = reduxForm({
  form: 'signin-form-member',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepValues: false,
  forceUnregisterOnUnmount: true,
})(SignInForm);

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, null)(SignInFormRedux))
);
