import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

const styles = theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(5),
    borderLeftStyle: 'solid',
    borderLeftWidth: 5,
  },
  info: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
  success: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.main,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.main,
    '& a': {
      color: theme.palette.warning.dark,
    },
  },
  danger: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.main,
    '& a': {
      color: theme.palette.error.dark,
    },
  },
  button: {
    position: 'absolute',
    background: 'transparent',
    color: 'inherit',
    border: 'transparent',
    top: 15,
    right: 5,
    cursor: 'pointer',
  },
});

class DashboardMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closed: false,
    };

    this.closeClick = this.closeClick.bind(this);
  }

  closeClick() {
    this.setState({
      closed: true,
    });
  }

  render() {
    const { classes, canClose, type } = this.props;

    return this.state.closed ? null : (
      <div
        className={classNames({
          [classes.root]: classes.root,
          [classes[type]]: true,
        })}
        style={{
          ...this.props.style,
        }}
      >
        {this.props.children}
        {canClose && (
          <button
            type="button"
            className={classes.button}
            data-dismiss="alert"
            aria-label="Close"
            onClick={this.props.onClose ? this.props.onClose : this.closeClick}
          >
            <CloseIcon fontSize="small" />
          </button>
        )}
      </div>
    );
  }
}

DashboardMessage.propTypes = {
  onClose: PropTypes.func,
  canClose: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
};

DashboardMessage.defaultProps = {
  canClose: true,
  type: 'info',
  style: {},
};

export default withStyles(styles)(DashboardMessage);
