import { LABS_CATEGORY } from '../../../../store/reducers/labs/actions';

const categoryFromAreaType = {
  immunization: {
    longName: 'Vaccination Records',
    shortName: 'Vaccination Records',
  },
  labresult: {
    longName: LABS_CATEGORY,
    shortName: LABS_CATEGORY,
  },
};

export const getCategoryFromAreaType = areaType =>
  categoryFromAreaType[areaType]
    ? categoryFromAreaType[areaType].longName
    : null;
