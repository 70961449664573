import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, withStyles } from '@material-ui/core';

const styles = theme => ({
  line: {
    color: '#444447',
    lineHeight: '180%',
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  icon: {
    color: '#444447',
    marginTop: 2,
  },
  iconContainer: {
    marginRight: 8,
  },
  input: {
    width: '100%',
    fontSize: 14,
    margin: 3,
    padding: 4,
    borderRadius: 4,
    border: '1px solid #ccc',
    outline: 'none',
  },
  inputWrapper: {
    marginRight: 4,
  },
});

const DEFAULT_FIELD_VALUE = 'Not Specified';
const ENTITY_TYPE_ORGANIZATION = 2;

const toString = value => (value === undefined || value === null ? '' : value);

const MemberAddressField = ({
  member,
  icon,
  classes,
  onSubmit,
  isEditable,
}) => {
  const initialState = {
    address: member.address ? member.address : '',
    street: member.street || '',
    postalCode: member.postalCode ? member.postalCode : '',
    city: member.city ? member.city : '',
    state: member.state ? member.state : '',
    renderLocationName:
      member.entityTypeCode === '2' &&
      member.locationName &&
      member.locationName != member.organizationName,
    locationName: member.locationName ? member.locationName : '',
  };

  const wrapperRef = useRef();

  const [data, setData] = useState(initialState);
  const [isEditing, setIsEditing] = useState(false);

  const handlePress = e => {
    if (e.keyCode === KEY_ENTER) {
      setIsEditing(false);

      if (data !== member)
        // submit to API
        onSubmit(data);
    }
  };

  const handleClick = e => {
    if (isEditable && isEditing && !wrapperRef.current.contains(e.target)) {
      setIsEditing(false);

      if (data !== member) {
        // submit to API
        onSubmit(data);
      }
    }
  };

  useEffect(() => {
    if (isEditable) {
      document.addEventListener('click', handleClick);
      document.addEventListener('keyup', handlePress);
    }

    return () => {
      if (isEditable) {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keyup', handlePress);
      }
    };
  }, [member, isEditing]);

  const onFieldChange = e =>
    setData({ ...data, [e.target.name]: e.target.value });

  const renderAddressLine2 = () => {
    const { city = '', state = '', postalCode = '' } = member;
    if (
      city.trim().length + state.trim().length + postalCode.trim().length >
      0
    ) {
      return `${city}, ${state} ${postalCode}`;
    }
    return null;
  };

  let hasAddress = member.street;
  let address = hasAddress
    ? `${member.street} ${toString(member.street2)}`.trim()
    : DEFAULT_FIELD_VALUE;

  // if (member.street && member.street !== 'NULL') {
  //   address = `${member.street} ${
  //     member.street2 !== 'NULL' ? member.street2 : ''
  //   }`.trim();
  // } else {
  //   address = member.address || DEFAULT_FIELD_VALUE;
  // }

  const addressLine2 = renderAddressLine2();

  return (
    <div onClick={handleClick} ref={wrapperRef}>
      <Grid container wrap="nowrap">
        <Grid item className={classes.iconContainer}>
          <Icon className={classes.icon}>{icon}</Icon>
        </Grid>
        <Grid item>
          {isEditing ? (
            <Grid container style={{ width: 'calc(100% - 40px)' }}>
              <Grid item xs={12}>
                <input
                  className={classes.input}
                  value={data.address}
                  placeholder="12 Main St"
                  name="address"
                  onChange={onFieldChange}
                />
              </Grid>
              <Grid container wrap="nowrap" xs={12}>
                <Grid item xs={6} className={classes.inputWrapper}>
                  <input
                    className={classes.input}
                    value={data.city}
                    placeholder="Rochester"
                    name="city"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={3} className={classes.inputWrapper}>
                  <input
                    className={classes.input}
                    value={data.state}
                    placeholder="NY"
                    name="state"
                    onChange={onFieldChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <input
                    className={classes.input}
                    value={data.postalCode}
                    name="postalCode"
                    placeholder="14623"
                    onChange={onFieldChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Fragment>
              {data.renderLocationName && (
                <div className={classes.line}>{data.locationName}</div>
              )}
              <div className={classes.line}>{address}</div>
              {addressLine2 ? (
                <div className={classes.line}>{addressLine2}</div>
              ) : (
                <div className={classes.line}>&nbsp;</div>
              )}
            </Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

MemberAddressField.propTypes = {
  isEditable: PropTypes.bool.isRequired,
};

MemberAddressField.defaultProps = {
  isEditable: false,
};

export default withStyles(styles)(MemberAddressField);
