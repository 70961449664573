import React from 'react';
import { Grid, Typography, Button, withStyles } from '@material-ui/core';

const styles = {
  root: {
    height: '100%',
  },
  header: {
    fontWeight: 700,
  },
  subHeader: {
    maxWidth: 520,
    lineHeight: '24px',
    marginTop: 8,
    marginBottom: 25,
  },
};

const ProviderRequestJoin = ({ member, history, classes }) => {
  const renderName = () => {
    return member ? `${member.firstName} ${member.lastName}` : 'your provider';
  };

  const handleClick = () => history.push('/d/me/care-team');

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Typography
        className={classes.header}
        variant="h5"
      >{`Requested ${renderName()} to join Agrin Health`}</Typography>
      <p className={classes.subHeader}>
        Thanks for letting us know that having your Provider on our platform is
        important. We will reach out to see if they will join us.
      </p>
      <img
        style={{ marginBottom: 25 }}
        src={require('../../../../client/svgs/request-to-join.svg')}
      />
      <Button variant="contained" color="primary" onClick={handleClick}>
        View My Care Team
      </Button>
    </Grid>
  );
};

export default withStyles(styles)(ProviderRequestJoin);
