import {
  DISPATCH_API_AUTHORIZED,
  DISPATCH_API,
} from '../../../../library/middleware/dispatchApi';

const allergens = [
  { label: 'Balsam or Peru', value: 1 },
  { label: 'Garlic', value: 4 },
  { label: 'Beef', value: 3 },
  { label: 'Dairy', value: 7 },
  { label: 'Peanut', value: 8 },
  { label: 'Fish or Shellfish', value: 9 },
  { label: 'Soy', value: 10 },
  { label: 'Tree nut', value: 11 },
  { label: 'Wheat', value: 12 },
  { label: 'Hot peppers', value: 13 },
  { label: 'Gluten', value: 14 },
  { label: 'Egg', value: 15 },
  { label: 'Tartrazine', value: 16 },
  { label: 'Sulfites', value: 17 },
  { label: 'Cat', value: 18 },
  { label: 'Dog', value: 19 },
  { label: 'Mold', value: 20 },
  { label: 'Pollen', value: 21 },
];

const reactions = [
  { label: 'Dry eyes', value: 1 },
  { label: 'Itchy eyes', value: 2 },
  { label: 'Shortness of breath', value: 3 },
];

const treatments = [
  { label: 'Antihistamine', value: 1 },
  { label: 'Medication', value: 2 },
  { label: 'Doctor visit', value: 3 },
  { label: 'Rest', value: 4 },
];

export const REQUEST_ALLERGENS = 'REQUEST_ALLERGENS';
export const RECEIVE_ALLERGENS = 'RECEIVE_ALLERGENS';

export const ALLERGEN_REQUEST = '@@redux/allergy/ALLERGEN_REQUEST';
export const ALLERGEN_SUCCESS = '@@redux/allergy/ALLERGEN_SUCCESS';
export const ALLERGEN_FAILURE = '@@redux/allergy/ALLERGEN_FAILURE';

export const sleep = ms =>
  new Promise((resolve, reject) => setTimeout(resolve, ms));

export const requestAllergens = () => ({
  type: ALLERGEN_REQUEST,
});

export const receiveAllergens = data => ({
  type: ALLERGEN_SUCCESS,
  allergens: data,
});

export const fetchAllergens = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [ALLERGEN_REQUEST, ALLERGEN_SUCCESS, ALLERGEN_FAILURE],
    endpoint: '/api/code-table/allergen/1',
  },
});

export const REQUEST_REACTIONS = 'REQUEST_REACTIONS';
export const RECEIVE_REACTIONS = 'RECEIVE_REACTIONS';

export const REACTIONS_REQUEST = '@@redux/allergy/REACTIONS_REQUEST';
export const REACTIONS_SUCCESS = '@@redux/allergy/REACTIONS_SUCCESS';
export const REACTIONS_FAILURE = '@@redux/allergy/REACTIONS_FAILURE';

export const requestReactions = () => ({
  type: REQUEST_REACTIONS,
});

export const receiveReactions = data => ({
  type: RECEIVE_REACTIONS,
  reactions: data,
});

export const fetchReactions = () => ({
  [DISPATCH_API]: {
    types: [REACTIONS_REQUEST, REACTIONS_SUCCESS, REACTIONS_FAILURE],
    endpoint: '/api/code-table/reaction/1',
  },
});

export const fetchReactionsX = () => dispatch => {
  dispatch(requestReactions());
  return sleep(1500).then(() => dispatch(receiveReactions(reactions)));
};

export const REQUEST_TREATMENTS = 'REQUEST_TREATMENTS';
export const RECEIVE_TREATMENTS = 'RECEIVE_TREATMENTS';

export const requestTreatments = () => ({
  type: REQUEST_TREATMENTS,
});

export const receiveTreatments = data => ({
  type: RECEIVE_TREATMENTS,
  treatments: data,
});

export const fetchTreatments = dispatch => dispatch => {
  dispatch(requestTreatments());
  return sleep(1200).then(() => dispatch(receiveTreatments(treatments)));
};
