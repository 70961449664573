import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activePanels: [],
      lastActive: '',
    };
  }

  /**
   * Map all AccordionPanel components that are active to 
   * component state
   */
  componentDidMount() {
    this.setState((nextState, props) => {
      let activePanels = [];
      Children.forEach(props.children, (child, index) => {
        const { key, props: { id, isActive }} = child;
        const childKey = key || id || String(index);
        if (isActive) {
          activePanels.push(childKey);

          // only need to record the first AccordionPanel that isActive.
          // if multi (multiple) selections are not allowed.
          if (this.props.multi) {
            return;
          }
        }
      });
      return { activePanels }
    });
  }

  /**
   * Handle click events of children components, AccordionPanel. If props.multi
   * is true, then state of opened AccordionPanels will be managed. If props.multi
   * is false, only one panel will be open at a time. For the last closed AccordionPanel
   * the child's props.onBeforeClose will trigger.
   * 
   * @param {String} key identifier for a AccordionPanel
   */
  onPanelClick = (key) => {

    const { multi } = this.props;
    const { lastActive } = this.state;

    let activePanels = this.state.activePanels,
        isActive = false,
        index = 0;
        
    if (multi) {

      index = activePanels.indexOf(key);
      isActive = index > -1;
      if (isActive) {
        activePanels.splice(index, 1);
      } else {
        activePanels.push(key);
      }

    } else {
      
      if (lastActive) {

        // If last open AccordionPanel doesn't match current then
        // find the last open AccordionPanel, and if the callback
        // function props.onBeforeClose exists, call it.
        Children.forEach(this.props.children, (child) => {
          if (lastActive === child.key && ('onBeforeClose' in child.props)) {
            child.props.onBeforeClose(lastActive);
          }
        });
      }

      activePanels = activePanels[0] === key ? [] : [key];
    }

    // if props.onChange is provided, then trigger it for
    // every state change.
    if (this.props.onChange) {
      this.props.onChange({ key, index, activePanels, lastActive });
    } 

    this.setState((nextState, props) => ({
      activePanels,
      lastActive: key,
    }));

  }

  /**
   * Get children and determine what AccordionPanels are open.
   * The Accordion component will set the key, id, isActive, 
   * lastActive, and onPanelClick props for children AccordionPanels
   * 
   */
  renderPanels() {
    const { children, multi } = this.props;
    const { activePanels, lastActive } = this.state;

    const components = Children.map(children, (child, index) => {

      const key = child.key || child.id || String(index);
      let isActive = false;

      if (multi) {
        isActive = activePanels.indexOf(key) > -1; 
      } else {
        isActive = activePanels[0] === key;
      }

      return React.cloneElement(child, {
        key,
        id: key,
        isActive,
        lastActive,
        onPanelClick: this.onPanelClick.bind(this, key),
      });
    });
    
    return components;
  }

  /**
   * Render component
   * 
   */
  render() {
    const { ...attributes } = this.props;
    
    delete attributes.multi;
    delete attributes.onBeforeOpen;
    delete attributes.onBeforeClose;

    return (
      <div {...attributes} >
        { this.renderPanels() }
      </div>
    )
  }

}

Accordion.propTypes = {
  multi: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

Accordion.defaultProps = {
  multi: false,
}


export default Accordion;