import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Grid, Paper } from '@material-ui/core';
import { isWithinInterval } from 'date-fns';
import SleepLineChart from './SleepLineChart';
import SleepViolinChart from './SleepViolinChart';
import useNetTimeSlept from './useNetTimeSlept';
import { whichDataSet } from '../utils';
import useDataPoints from '../useDataPoints';
import { getAverage } from '../';
import { DATERANGE } from '../../ChartWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SleepChart = props => {
  const {
    coordinates,
    dateExtent,
    tickValues,
    dateRange,
    setAnnotations,
    hasData,
    mode,
  } = props;
  const [netData] = useNetTimeSlept(coordinates, dateRange);
  const shouldDisplayAverage = [
    DATERANGE.ONE_YEAR,
    DATERANGE.SIX_MONTHS,
  ].includes(dateRange);
  const _whichDataSet = whichDataSet(dateRange);
  const lineData = netData[_whichDataSet] || [];
  const matches = useMediaQuery('(min-width:600px)');

  const { upperLimit, lowerLimit } = useDataPoints(
    lineData.filter(item =>
      isWithinInterval(item.date, { start: dateExtent[0], end: dateExtent[1] })
    ),
    [shouldDisplayAverage ? 'averageTotalTimeSlept' : 'totalTimeSlept'],
    dateExtent
  );

  const [dimensions, setDimensions] = useState([800, 240]);
  const [node, setNode] = useState(null);

  function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();
    return [!matches ? window.innerWidth - 48 : rect.width, rect.height];
  }

  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      };
    }
  }, [node]);

  const dataProps = {
    upperLimit,
    lowerLimit,
    dateExtent,
    tickValues,
    dateRange,
    hasData,
  };

  function getAnnotations() {
    if (lineData.length === 0) return;
    const annot = getAverage(
      lineData.filter(item =>
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ),
      ['totalTimeSlept']
    );
    setAnnotations(
      annot.map(item => {
        return {
          value: item.average,
          title: 'Hrs/day',
          backgroundColor: '#BCDEF3',
          color: '#0068A8',
        };
      })
    );
  }

  useEffect(() => {
    getAnnotations();
  }, [lineData, props.dateExtent]);

  const matchStyles = !matches
    ? { maxWidth: `${window.innerWidth - 48}px` }
    : {};
  const disableViolin = !hasData || mode === 'summary';
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div ref={ref} style={matchStyles}>
          <SleepLineChart
            coordinates={coordinates}
            mode={mode}
            data={lineData}
            upperLimit={upperLimit}
            shouldDisplayAverage={shouldDisplayAverage}
            {...dataProps}
          />
        </div>
      </Grid>
      {disableViolin ? (
        ''
      ) : (
        <Grid item xs={12}>
          <SleepViolinChart
            data={coordinates}
            {...dataProps}
            dimensions={dimensions}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SleepChart;
