import React from 'react';

export default ({ fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5834 21.1412C14.0875 20.9275 13.5839 20.7215 13.0956 20.4926C12.9285 20.4079 12.7678 20.311 12.6149 20.2027C12.3708 20.0425 12.386 19.8899 12.6149 19.6915C13.2024 19.1269 13.767 18.5394 14.3469 17.9596C15.9644 16.337 17.587 14.7169 19.2146 13.0994C19.7945 12.5196 19.9166 12.5196 20.2752 13.2444C20.5035 13.7703 20.6972 14.3107 20.855 14.8619L21 15.8538C20.855 17.8985 20.0768 19.5008 18.3449 20.4316C17.9252 20.6529 17.4903 20.8589 17.0554 21.0725L14.5834 21.1412Z"
      fill={fill}
    />
    <path
      d="M3.00898 12.8477C2.92603 11.5972 3.42428 10.3783 4.35944 9.54399C6.07613 7.83493 7.77755 6.11062 9.50187 4.40919C10.6875 3.07921 12.5818 2.63924 14.2323 3.31051C15.877 3.89653 17.0301 5.3863 17.185 7.12537C17.2705 8.07241 17.0163 9.01898 16.4678 9.79577C16.3742 9.91115 16.2409 9.98735 16.094 10.0094C15.8758 10.0324 15.6559 10.0324 15.4378 10.0094C15.1936 10.0094 14.9419 10.0094 14.6748 9.956L15.0563 9.4448C15.9092 8.42183 16.0107 6.96745 15.3081 5.83595C14.907 5.12845 14.2319 4.6177 13.442 4.42419C12.6521 4.23068 11.8173 4.37155 11.1346 4.81356C10.71 5.13474 10.3072 5.48394 9.92914 5.85883C9.35691 6.40817 8.80757 6.99566 8.23534 7.545C8.12877 7.64323 8.06815 7.78156 8.06815 7.92649C8.06815 8.07142 8.12877 8.20975 8.23534 8.30798C9.12039 9.17776 9.9978 10.0628 10.8752 10.9402C10.9794 11.0596 11.0915 11.1718 11.2109 11.2759C11.4627 11.4819 11.5543 11.6422 11.2643 11.9397C10.9728 12.2649 10.717 12.6205 10.5014 13.0003C9.99374 13.887 9.75515 14.9024 9.81469 15.9224C9.81469 16.4565 9.55528 16.6854 9.05172 16.8914C7.88764 17.3664 6.57738 17.3293 5.44205 16.7892C4.30672 16.2491 3.45124 15.256 3.08527 14.0532C3.01013 13.656 2.9845 13.2511 3.00898 12.8477Z"
      fill={fill}
    />
    <path
      d="M10.4096 16.0748C10.2234 13.4047 12.1135 11.0371 14.7585 10.6272C15.9746 10.425 17.2232 10.643 18.2987 11.2452C18.457 11.3447 18.603 11.4625 18.7336 11.5962C18.871 11.7183 18.9015 11.848 18.7336 11.9853C18.5658 12.1226 18.5124 12.2066 18.4056 12.3134L12.1416 18.5774C12.0576 18.6613 11.9737 18.7529 11.8745 18.8368C11.5312 19.1496 11.4167 19.1496 11.1497 18.7529C10.6666 18.0862 10.4002 17.2871 10.3867 16.4639L10.4096 16.0748Z"
      fill={fill}
    />
  </svg>
);
