import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { uploadFiles } from '../mediaObject';

export const HEALTH_STATUS_FETCH_REQUEST = 'Health_Status/fetch/request';
export const HEALTH_STATUS_FETCH_SUCCESS = 'Health_Status/fetch/success';
export const HEALTH_STATUS_FETCH_FAILURE = 'Health_Status/fetch/failure';

export const HEALTH_STATUS_CLEAN_UP = 'Health_Status/Clean_up';

export const REPORT_HEALTH_STATUS_REQUEST =
  'Health_Status/create_report/request';
export const REPORT_HEALTH_STATUS_SUCCESS =
  'Health_Status/create_report/success';
export const REPORT_HEALTH_STATUS_FAILURE =
  'Health_Status/create_report/failure';

export const cleanUpReducer = () => ({
  type: HEALTH_STATUS_CLEAN_UP,
});

export const fetchHealthStatus = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      HEALTH_STATUS_FETCH_REQUEST,
      HEALTH_STATUS_FETCH_SUCCESS,
      HEALTH_STATUS_FETCH_FAILURE,
    ],
    endpoint: `/api/health/status/covid`,
  },
});

export const getShareableLink = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      REPORT_HEALTH_STATUS_REQUEST,
      REPORT_HEALTH_STATUS_SUCCESS,
      REPORT_HEALTH_STATUS_FAILURE,
    ],
    endpoint: '/api/member/report/health-status',
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        mode: 'cors',
      },
    },
  },
});
