import React, { Component, Fragment } from 'react';
import { Fade, Tooltip } from '@material-ui/core';

export default class Slider extends Component {
  constructor(props) {
    super(props);
    props.listIds.forEach((item, index) => {
      this[props.listIds.indexOf(item)] = React.createRef();
    });
  }

  componentDidMount(prevProps) {
    if (Boolean(this[0])) {
      this.props.getChildWidth(this[0].current.offsetWidth);
    }
  }

  componentDidUpdate(prevProps) {
    this.handleScroll(this.props.index);
    if (prevProps.listIds !== this.props.listIds) {
      this.props.listIds.forEach((item, index) => {
        if (Boolean(this[index]) && Boolean(this[index].current)) return;
        this[index] = React.createRef();
      });
    }

    if (Boolean(this.props.newId)) {
      if (
        Boolean(this[this.listIds.indexOf(newId)]) &&
        Boolean(this[this.listIds.indexOf(newId)].current)
      ) {
        this[this.listIds.indexOf(newId)].current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }
  }

  handleScroll = value => {
    if (this.props.isScrolling) {
      this[value].current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  render() {
    const { listIds, classes, list, childElement, newId } = this.props;
    return (
      <Fragment>
        {listIds.map((item, index) => {
          const isNewId = Boolean(newId) && item === newId;
          return (
            <div
              key={`slider-item-${index}`}
              className={classes.child}
              ref={this[index]}
            >
              <Fade in={true} timeout={isNewId ? 400 : 250}>
                <div>
                  {React.createElement(childElement, { item: list[item] })}
                </div>
              </Fade>
            </div>
          );
        })}
      </Fragment>
    );
  }
}
