import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { Grid, Button } from '@material-ui/core';

import InputField from '../../../library/forms/InputField';
import validate from './ValidateForm';
import UrlSelectForm from './UrlSelectForm';

// eslint-disable-next-line
import './PortalForm.css';
import { required } from '../ImmunizationForm/validations';
import { isValidUrl } from '../../../library/validations';

const warn = values => {
  const warnings = {};

  return warnings;
};

const validateURL = value =>
  value && isValidUrl(value) ? '' : 'URL provided is invalid';

const PortalForm = props => {
  const { handleSubmit, pristine, submitting, initialValues, invalid } = props;
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            type="text"
            name="portalName"
            label="Portal Name"
            component={InputField}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            type="text"
            name="providerName"
            label="Provider"
            hint="Required"
            component={InputField}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            type="url"
            name="portalUrl"
            label="Website"
            hint="(Should start with http:// or https://)"
            placeholder="E.g., https://medicalportal.com"
            component={InputField}
            initialValue={initialValues.portalUrl}
            validate={[validateURL, required]}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="portalDescription"
            label="Notes"
            rows={4}
            rowsMax={4}
            component={InputField}
            multiline
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginTop: 24, marginBottom: 24 }}
        justify="flex-end"
        spacing={3}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Button
            type="button"
            size="large"
            fullWidth={true}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={invalid || pristine || submitting}
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const PortalReduxForm = reduxForm({
  form: 'portalForm',
  validate,
  warn,
})(withRouter(PortalForm));

export default connect(state => {
  const { portalList, selectedId } = state.portals;
  const portal = portalList ? portalList[selectedId] : {};

  return {
    initialValues: {
      portalName: selectedId ? portal.portalName : '',
      providerName: selectedId ? portal.providerName : '',
      portalUrl: selectedId ? portal.portalUrl : '',
      portalDescription: selectedId ? portal.portalDescription : undefined,
      portalId: selectedId ? portal.portalId : '',
      personId: selectedId ? portal.personId : '',
    },
  };
})(PortalReduxForm);
