import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BasePage from '../BasePage';
import NoConditions from './NoConditions';
import {
  Grid,
  Button,
  Icon,
  Typography,
  withStyles,
  IconButton,
  Collapse,
} from '@material-ui/core';
import { format } from 'date-fns';

import PageHeader from '../../components/PageHeader';
import {
  cancelFormReset,
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';
import {
  fetchConditions,
  deleteCondition,
  setSelectedId,
  clearSelectedId,
} from '../../../store/reducers/conditions/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Empty from '../../components/Empty/Empty';

import { parseDate } from '../../../library/utils/date';

import SURGERY_LIST from './mockData';
import AddConditionModal from './AddConditionModal';
import ConditionsTable from './ConditionsTable';
import { clearFiles, CONDITIONS } from '../../../store/reducers/mediaObject';

const styles = theme => {
  return {
    card: {
      borderRadius: 6,
      boxShadow: '0px 2px 10px rgba(83,86,94,.25)',
      overflow: 'hidden',
      padding: 24,
      position: 'relative',
      height: '100%',
      transition: 'all .2s',
    },
    overlay: {
      backgroundColor: 'rgba(68,68,71,.7)',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10,
      transition: 'all 0.2s',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        opacity: 1,
      },
    },
    icons: {
      position: 'absolute',
      top: 15,
      right: 15,
      zIndex: 10,
    },
    cardBottom: {
      marginTop: 16,
    },
    icon: {
      marginRight: 8,
    },
    topBar: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.primary.main,
    },
    topBarExpanded: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      position: 'absolute',
      top: 0,

      left: 0,
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.secondary.main,
    },
    expandButtonContainer: { position: 'absolute', top: '90px', right: '8px' },
  };
};

const ConditionHistoryPage = props => {
  const {
    history,
    uiStateEntityCreate,
    navigation: { dashboard },
    conditions,
    user,
  } = props;

  const [modal, setModal] = React.useState(false);

  useEffect(() => {
    console.log(user.personId);
    props.fetchConditions(user.personId);
  }, [user.personId]);

  useEffect(() => {
    if (!modal) {
      props.clearSelectedId();
      props.clearFiles();
    }
  }, [modal]);

  const createRecord = () => {
    uiStateEntityCreate('Condition');
    props.clearSelectedId();
    setModal(true);
  };

  const onClickUpdateCard = selectedId => {
    console.log('edit');
    props.setSelectedId(selectedId);
    setModal(true);
  };

  const onClickDeleteCard = record => {
    const realId = record.personDiagnosisId;
    props.deleteCondition(user.personId, realId, record.id);
    props.clearSelectedId();
  };

  return (
    <BasePage
      state={conditions.uiState}
      emptyStateComponentProps={{
        name: 'Conditions',
        type: 'Conditions & Diagnoses',
        onClick: createRecord,
      }}
      DrawerComponent={<AddConditionModal modal={modal} setModal={setModal} />}
    >
      <Grid item xs={12}>
        {conditions.listIds.length ? (
          <React.Fragment>
            <PageHeader
              headerText="Conditions & Diagnoses"
              subHeaderText="View Records"
              backButtonText="Go Back"
              backLink={dashboard.healthRecord.self}
              rightSlot={
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Add"
                  onClick={createRecord}
                  style={{ float: 'right' }}
                >
                  <Icon>add</Icon>
                  New Record
                </Button>
              }
            />
            <Divider />
          </React.Fragment>
        ) : (
          ''
        )}
        {conditions.isFetching ? (
          <Typography>Loading...</Typography>
        ) : (
          // ) : !conditions.hasRecords ? (
          //   <NoConditions
          //     baseRoutePath={dashboard.healthRecord.conditions.read}
          //     onClickAddCondition={createRecord}
          //   />
          <Grid item xs={12}>
            {conditions.listIds.length ? (
              <ConditionsTable
                listIds={conditions.listIds}
                list={conditions.list}
                onEdit={onClickUpdateCard}
                onDelete={onClickDeleteCard}
              />
            ) : (
              <Empty
                name="Conditions"
                onClick={createRecord}
                type="Conditions & Diagnoses history"
              />
            )}
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

const ConditionCard = ({ data, classes, onClickUpdate, onClickDelete }) => {
  const [expand, setExpand] = React.useState(false);
  const isNull = input =>
    input ? (
      <div>{input}</div>
    ) : (
      <div>
        <i>Not Set</i>
      </div>
    );

  const renderDate = date =>
    date ? format(parseDate(date), 'MMMM do, yyyy') : null;

  const isModifying =
    data && data.isUpdating ? true : data && data.isDeleting ? true : false;

  return (
    <Grid
      style={{ opacity: isModifying ? 0.5 : 1 }}
      item
      xs={12}
      sm={6}
      md={4}
      className={classes.root}
    >
      <div className={classes.card}>
        <Grid
          className={classes.icons}
          container
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid>
            <IconButton
              style={{ color: '#444', margin: '10px 0px' }}
              onClick={onClickUpdate}
            >
              <Icon>edit</Icon>
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              style={{ color: '#ED5158', margin: '10px 0px' }}
              onClick={onClickDelete}
            >
              <Icon>delete</Icon>
            </IconButton>
          </Grid>
        </Grid>
        <div className={expand ? classes.topBarExpanded : classes.topBar} />
        <div>
          <Typography variant="h6">{data.diagnosisDesc}</Typography>
        </div>
        <Grid container spacing={2} className={classes.cardBottom}>
          <Grid item lg={6} xs={12} container alignItems="center" wrap="nowrap">
            <Icon className={classes.icon}>date_range</Icon>
            {isNull(renderDate(data.diagnosedOn))}
          </Grid>
          <div className={classes.expandButtonContainer}>
            <IconButton size="small" onClick={() => setExpand(!expand)}>
              <ExpandMoreIcon
                style={{ transform: expand ? 'scale(1,-1)' : 'scale(1,1)' }}
              />
            </IconButton>
          </div>
        </Grid>
        <Collapse in={expand}>
          <Grid container spacing={2} className={classes.cardBottom}>
            <Grid item xs={12} container alignItems="center" wrap="nowrap">
              <Icon className={classes.icon}>account_box</Icon>
              {isNull(data.providerName)}
            </Grid>
            <Grid item xs={12} container alignItems="center" wrap="nowrap">
              <Icon className={classes.icon}>healing</Icon>
              {isNull(data.relatedMvs)}
            </Grid>
            <Grid item xs={12} container alignItems="center" wrap="nowrap">
              <Icon className={classes.icon}>assignment_turned_in</Icon>
              {isNull(data.relatedTreatments)}
            </Grid>
            <Grid item xs={12} container alignItems="center" wrap="nowrap">
              <Icon className={classes.icon}>assignment_late</Icon>
              {isNull(data.relatedSymptoms)}
            </Grid>
          </Grid>
        </Collapse>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => ({
  conditions: state.conditions,
  ui: state.ui,
  user: state.active.user,
});

const mapDispatchToProps = dispatch => ({
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
  fetchConditions: pid => dispatch(fetchConditions(pid)),
  deleteCondition: (pid, conditionsId, tid) =>
    dispatch(deleteCondition(pid, conditionsId, tid)),
  setSelectedId: conditionsId => dispatch(setSelectedId(conditionsId)),
  clearSelectedId: () => dispatch(clearSelectedId()),
  clearFiles: () => dispatch(clearFiles(CONDITIONS)),
});

export default {
  component: withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(ConditionHistoryPage))
  ),
};
