import { v1 as uuidv1 } from 'uuid';
import {
  apiFetch,
  apiFetchAuthorized,
  localStorageGetObject,
} from '../helpers/fetch';
import { uploadFiles } from '../../store/reducers/mediaObject';
import { isPromise, quickHash } from '../utils';

export const DISPATCH_API = 'DISPATCH_API';
export const DISPATCH_API_AUTHORIZED = 'DISPATCH_API_AUTHORIZED';
export const DISPATCH_API_MODIFY = 'DISPATCH_API_MODIFY';

const msgMissingTypes = name => `${name}: the types array property of the action 
payload must include types for REQUEST, SUCCESS, and FAILURE in the respective order.`;

const msgAllTypesMustBeStrings = name => `${name}: all types in the types array property of the action when triggering 
${name} should be strings`;

const msgNoEndPoint = name =>
  `${name}: an endpoint must be given. Without it, no request can be made to the API server`;

/**
 * Used for putting organized error messages into the web console.
 *
 * @param {string} name
 * @param {string} url
 * @param {mixed} data
 */
const dispatchToApiMsg = (url, name, data) => {
  console.groupCollapsed(`${name} Failed: ${url}`);
  console.groupEnd();
};

const cached = {};

/**
 * Only intended to be used with the Redux DispatchAPI middleware. Switches between
 * authorized and unauthorized requests (included bearer token or not). Implements
 * rudimentary cache to mitigate double requests to the same resource, and inherit
 * problem with Redux. Once the request is added, until the promise is fulfilled
 * no additional attempts to make a request to the same resource are allowed
 * instead the promise is returned. When the request has fulfilled, the cached
 * for the request is cleaned up
 *
 * @param {string} url
 * @param {string} name
 * @param {boolean}} authorized
 * @param {object} config
 * @param {string} personId
 * @returns
 */
const dispatchToApiCached = (url, name, authorized, config, personId) => {
  const fetchFunction = authorized ? apiFetchAuthorized : apiFetch;

  // Because the API_URL is handled by injecting the environment.js file into index.html and binding the
  // parameters object to window.__env it's very easy to change the parameter values using the web IDE
  // and other advanced javascript methods. This is a wide open attack vector if we didn't have a CPS
  // because the attacker can simply change the value to be whatever they want and mock our API
  // requests intercepting all creations/edits the user submits.
  //
  // We can't prevent the change of the API_URL but we can detect and log it as well as bring it to
  // the users attention. Also, we have banner in the Web IDE console informing the user not to copy
  // and paste things into the console they do not know and understand.
  if (
    window.location.origin.endsWith('healthebiography.com') &&
    window.__env.API_URL.includes('localhost')
  ) {
    console.debug(
      `Development build detected on production website. API_URL is "${window.__env.API_URL}"`
    );
    window.localStorage('DEVELOPMENT_BUILD_DETECTED', true);
  }

  // Calculate a quick, unique hash based on string which is used
  // to identify the request in the cache
  const key = quickHash(`${url}-${personId || ''}`);

  // Prevent duplicate fetches on the same url
  if (cached[key] && isPromise(cached[key])) return cached[key];

  cached[key] = fetchFunction(url, config).then(response => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then(json => {
        if (response.ok || response.status === 304) {
          const tuple = [json.meta, json.data ? json.data : json];
          return Promise.resolve(tuple);
        } else {
          dispatchToApiMsg(url, name, json);
          const tuple = [null, json];
          return Promise.reject(tuple);
        }
      });
    } else {
      return response.blob().then(blob => {
        if (!response.ok) {
          dispatchToApiMsg(url, name, blob);
          const tuple = [null, blob];
          return Promise.reject(tuple);
        } else {
          const tuple = [null, blob.data ? blob.data : blob];
          return Promise.resolve(tuple);
        }
      });
    }
  });

  // Once a promise is resolved or rejected, remove request from URL
  // so the application will be permitted to make another for the same
  // resource if required
  cached[key]
    .then(_ => {
      console.debug(`success: promise fulfilled ${url} (${key})`);
      delete cached[key];
    })
    .catch(_ => {
      console.debug(`failure: promise fulfilled ${url} (${key})`);
      delete cached[key];
    });

  return cached[key];
};

const getHttpMethod = config =>
  config && config.method ? config.method : 'GET';

/**
 * Intercept actions DISPATCH_API and DISPATCH_API_AUTHORIZED. This is intended to handle
 * the common API request case. Perform the following steps:
 *
 *  1. Start the request by dispatching the *REQUEST* action from the types array (index #1),
 *  2. If the request is successfully, then dispatch the *SUCCESS* action from types array (index #2).
 *  2. If the request failed, then dispatch the *FAILURE* action from types array (index #3).
 *
 * Note: "fetch" action as a composition of three parts (or dispatch to the API). REQUEST, SUCCESS, and FAILURE. The fetch
 * action uses all three parts. REQUEST is when the request is made to the server, so before
 * that's fulfilled, we can modify the state to let the user know something is happening.
 * In return, we avoid error like rendering content that doesn't exist. SUCCESS is when the
 * request comes back without a problem, we modify the state again to specify we have content
 * and flag the appropriate flags and flash a message
 * (some sort of UI state change that shows the user their request has been fulfilled).
 * FAILURE is the inverse of SUCCESS.
 *
 * @param {object} store
 * @return {mixed}
 */
export default store => next => async action => {
  const dispatchActionKey = [DISPATCH_API, DISPATCH_API_AUTHORIZED].find(
    x => typeof action[x] !== 'undefined'
  );

  // If action type is not DISPATCH_API or DISPATCH_API_AUTHORIZED
  // then move to next middleware
  if (typeof dispatchActionKey === 'undefined') {
    return next(action);
  }

  const {
    types = false,
    endpoint = false,
    meta = {},
    payload,
    ...properties
  } = action[dispatchActionKey];

  // Event must include actions ending in REQUEST, SUCCESS, and FAILURE.
  if (!types || !Array.isArray(types) || types.length !== 3) {
    throw new Error(msgMissingTypes(dispatchActionKey));
  }

  // Action types  must be string
  if (!types.every(type => typeof type === 'string')) {
    throw new Error(msgAllTypesMustBeStrings(dispatchActionKey));
  }

  if (!endpoint) {
    throw new Error(msgNoEndPoint(dispatchActionKey));
  }

  const config = meta && 'config' in meta ? meta.config : {};
  const activePerson = localStorageGetObject('active') || {};
  const personId =
    payload && payload.personId
      ? payload.personId
      : activePerson
      ? activePerson.personId
      : {};

  const { mediaObject, ...restMeta } = meta;
  const [actionType, successType, failureType] = types;
  const cancelType = `${actionType}/CANCELLED`;
  const authorizeRequest = dispatchActionKey.includes('AUTHORIZED');

  // Create temporary ID (tid) if one doesn't exist and include active personId (pid)
  const appMeta = {
    ...restMeta,
    // If the personId is defined in the payload, the developer has a reason
    // for the override. Use the payload personId instead of the active personId
    pid: personId,
    tid: restMeta.tid ? restMeta.tid : uuidv1(),
  };

  try {
    let bundlesToAttach = [];
    next({ type: actionType, meta: appMeta, payload, ...properties });

    const [responseMeta, response] = await dispatchToApiCached(
      endpoint,
      dispatchActionKey,
      authorizeRequest,
      {
        ...config,
        headers: {
          ...config.headers,
          Pid: appMeta.pid,
        },
      },
      personId
    );

    const finalMeta = {
      ...appMeta,
      ...responseMeta,
    };

    const httpVerb = getHttpMethod(config);

    // Counters race condition bug when switching to different dependents while fetching
    const currActivePerson = localStorageGetObject('active') || {};
    const currActivePersonPid = currActivePerson && currActivePerson.personId;
    if (httpVerb === 'GET' && appMeta.pid !== currActivePersonPid)
      return next({
        ...properties,
        type: cancelType,
        payload: response,
        meta: finalMeta,
        error: false,
        message: 'Request was cancelled after changing activeUser',
      });

    // Process media object files if any are found and only if the
    // HTTP method/verb is POST or PUT for create and update respectively.
    if (
      (httpVerb === 'POST' || httpVerb === 'PUT') &&
      mediaObject &&
      mediaObject.bundles &&
      mediaObject.bundles.length > 0
    ) {
      const objectId =
        httpVerb === 'POST'
          ? response[mediaObject.objectIdKey]
          : payload[mediaObject.objectIdKey];

      bundlesToAttach = await store.dispatch(
        uploadFiles(
          personId,
          objectId,
          mediaObject.bundles,
          mediaObject.appArea,
          appMeta
        )
      );
    }

    return next({
      ...properties,
      type: successType,
      payload: response,
      meta: finalMeta,
      error: false,
      bundlesToAttach,
    });
  } catch (error) {
    return next({
      ...properties,
      type: failureType,
      payload: error,
      meta: appMeta,
      error: true,
    });
  }
};
