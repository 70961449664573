import React from 'react';

const QRSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 45 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M14.0625 0.975586H0V15.7837H14.0625V0.975586ZM11.25 12.8221H2.8125V3.93722H11.25V12.8221Z"
          fill="#A3A3A3"
        />
        <path
          d="M8.4375 6.89844H5.625V9.86007H8.4375V6.89844Z"
          fill="#A3A3A3"
        />
        <path
          d="M30.9375 0.975586V15.7837H45V0.975586H30.9375ZM42.1875 12.8221H33.75V3.93722H42.1875V12.8221Z"
          fill="#A3A3A3"
        />
        <path
          d="M39.375 6.89844H36.5625V9.86007H39.375V6.89844Z"
          fill="#A3A3A3"
        />
        <path
          d="M0 48.3619H14.0625V33.5537H0V48.3619ZM2.8125 36.5153H11.25V45.4002H2.8125V36.5153Z"
          fill="#A3A3A3"
        />
        <path
          d="M8.4375 39.4766H5.625V42.4382H8.4375V39.4766Z"
          fill="#A3A3A3"
        />
        <path
          d="M28.125 0.975586H22.5V3.93722H28.125V0.975586Z"
          fill="#A3A3A3"
        />
        <path
          d="M22.5 12.8224H25.3125V9.86076H28.125V6.89913H19.6875V3.9375H16.875V6.89913V8.37995V9.86076H22.5V12.8224Z"
          fill="#A3A3A3"
        />
        <path
          d="M19.6875 12.8223H16.875V15.7839H19.6875V12.8223Z"
          fill="#A3A3A3"
        />
        <path
          d="M28.125 15.7842H25.3125V18.7458H16.875V21.7074H28.125V15.7842Z"
          fill="#A3A3A3"
        />
        <path
          d="M2.8125 27.63H5.625V24.6684H2.8125V18.7451H0V30.5916H2.8125V27.63Z"
          fill="#A3A3A3"
        />
        <path
          d="M8.4375 18.7451H5.625V21.7067H8.4375V18.7451Z"
          fill="#A3A3A3"
        />
        <path
          d="M16.875 30.5916H22.5V27.63H19.6875V24.6684H14.0625V18.7451H11.25V24.6684H8.4375V30.5916H11.25V27.63H12.6562H14.0625H16.875V30.5916Z"
          fill="#A3A3A3"
        />
        <path
          d="M28.125 24.6689H25.3125V30.5922H28.125V24.6689Z"
          fill="#A3A3A3"
        />
        <path
          d="M25.3125 33.5537H16.875V36.5153H22.5V45.4002H16.875V48.3619H25.3125V45.4002H30.9375V42.4386H25.3125V33.5537Z"
          fill="#A3A3A3"
        />
        <path
          d="M19.6875 39.4766H16.875V42.4382H19.6875V39.4766Z"
          fill="#A3A3A3"
        />
        <path
          d="M30.9375 33.5537H28.125V39.477H30.9375V33.5537Z"
          fill="#A3A3A3"
        />
        <path
          d="M42.1875 39.4766H33.75V48.3615H36.5625V42.4382H42.1875V39.4766Z"
          fill="#A3A3A3"
        />
        <path d="M45 45.4004H39.375V48.362H45V45.4004Z" fill="#A3A3A3" />
        <path d="M45 33.5537H42.1875V36.5153H45V33.5537Z" fill="#A3A3A3" />
        <path
          d="M33.75 36.5148H36.5625V27.6299H30.9375V30.5915H33.75V36.5148Z"
          fill="#A3A3A3"
        />
        <path
          d="M39.375 21.7067H36.5625V18.7451H33.75V21.7067H30.9375V24.6684H42.1875V21.7067H45V18.7451H39.375V21.7067Z"
          fill="#A3A3A3"
        />
        <path d="M45 27.6299H39.375V30.5915H45V27.6299Z" fill="#A3A3A3" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="45"
            height="47.3861"
            fill="white"
            transform="translate(0 0.975586)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QRSvg;
