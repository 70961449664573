import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const ErrorTooltip = withStyles({
  arrow: {
    '&:before': {
      border: '1px solid #E6BC05',
    },
    color: '#FAF7ED',
  },
  tooltip: {
    padding: 8,
    backgroundColor: '#FAF7ED',
    border: '1px solid #E6BC05',
    color: '#73600E',
    fontSize: '14px',
    fontWeight: '400',
  },
})(Tooltip);

export default ErrorTooltip;
