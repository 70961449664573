import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  HeaderText: {
    fontWeight: '400',
    fontSize: '24px',
    // ['@media (min-width:800px)']: {
    //   fontSize: '24px',
    // },
    // ['@media (max-width:450px)']: {
    //   fontSize: '16px',
    // },
    // ['@media (max-width:400px)']: {
    //   fontSize: '14px',
    // },
  },
  subText: {
    marginTop: '-8px',
    fontWeight: '400',
    fontSize: '18px',
    // ['@media (min-width:800px)']: {
    //   fontSize: '20px',
    // },
    // ['@media (max-width:450px)']: {
    //   fontSize: '16px',
    // },
    // ['@media (max-width:400px)']: {
    //   fontSize: '14px',
    // },
  },
  synapseText: {
    fontFamily: `'Over the Rainbow', cursive`,
  },
  headerContainer: {
    height: '90px',
    background: '#0068A8',
    color: 'white',
    overflow: 'hidden',
    ['@media (max-width:450px)']: {
      height: '100px',
    },
  },
  headerTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const WaysToShareEmptyView = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} className={classes.headerContainer}>
      <Grid item xs={12} className={classes.headerTextContainer}>
        <Typography className={classes.HeaderText}>
          {' '}
          <span className={classes.synapseText}> Sorry </span>
        </Typography>
        <Typography className={classes.subText}>
          {' '}
          You have no COVID records yet
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WaysToShareEmptyView;
