/**
 * Using a JSON object create a query string with each
 * component URI encoded.
 * 
 * @param {object} object 
 * @param {string}
 */
export const createQueryStringFromObject = (object) => 
  Object.keys(object).map(key =>
      `${ encodeURIComponent(key) }=${ encodeURIComponent(object[key]) }`
  ).join('&');