import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  header: {
    marginTop: theme.spacing(4),
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const FieldGroupHeader = ({
  headerText,
  classes,
  IsNotMiddleHeading,
  disableMargin,
}) => (
  <React.Fragment>
    <Typography
      variant="h6"
      className={IsNotMiddleHeading || disableMargin ? {} : classes.header}
    >
      {headerText}
    </Typography>
    {/* <Divider className={ classes.divider } /> */}
  </React.Fragment>
);

FieldGroupHeader.propTypes = {
  IsNotMiddleHeading: PropTypes.bool.isRequired,
};

FieldGroupHeader.defaultProps = {
  IsNotMiddleHeading: false,
};

export default withStyles(styles)(FieldGroupHeader);
