import {
  SUBSCRIPTION_CHANGE_REQUEST,
  SUBSCRIPTION_CHANGE_SUCCESS,
  SUBSCRIPTION_CHANGE_FAILURE,
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_SUCCESS,
} from './actions';
import { format } from 'date-fns';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

const GENERAL_ERROR_MESSAGE =
  "We're sorry, this service is temporarily unavailable. Please try again later. If the problem persists, please contact support at support@agrinhealth.com";

const initialState = {
  isSubmitting: false,
  isFetching: false,
  hasRecords: false,
  hasFetched: false,
  hasError: false,
  error: '',

  lastChargeAmount: null,
  startOn: null,
  accountRole: null,
  order: {},

  uiState: LOADING,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SUBSCRIPTION_REQUEST:
      return {
        ...state,
        ...initialState,
        uiState: LOADING,
        hasFetched: false,
      };

    case SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: GENERAL_ERROR_MESSAGE,
        uiState: ERROR,
        hasFetched: true,
      };

    case SUBSCRIPTION_SUCCESS:
      return {
        ...payload,
        ...state,
        hasFetched: true,
        startOn: format(new Date(payload.startOn), 'MM/dd/yyyy'),
        accountRole: payload.product.name,
        lastChargeAmount: payload.lastChargeAmount,
        order: payload.order,
        uiState: SUCCESS,
      };

    case SUBSCRIPTION_CHANGE_REQUEST:
      return {
        ...state,
        isSubmitting: false,
      };

    case SUBSCRIPTION_CHANGE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        accountRole: payload.subscription.accountRole,
        startOn: format(new Date(payload.subscription.startOn), 'MM/dd/yyyy'),
        order: payload.order,
      };

    case SUBSCRIPTION_CHANGE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        //hasError: true,
        //error: GENERAL_ERROR_MESSAGE,
      };

    default:
      return state;
  }
};
