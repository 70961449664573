import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';
import ErrorTooltip from './ErrorTooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SuccessTooltip from './SuccessTooltip';
import ActionComponent from './ActionComponent';
import { useStyles } from './styles';
import SubmitIcon from '../SubmitSVG';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { apiFetch } from '../../../../../library/helpers/fetch';

const IDLE = 'IDLE';
const ERROR = 'ERROR';
const ACCEPTED = 'ACCEPTED';
const SUBMITTING = 'SUBMITTING';

const IdentityCodeForm = ({ hanldeLockNavigation, handleSuccess }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const bodyVariant = isSmallScreen ? 'body2' : 'body1';
  const [value, setValue] = useState('');
  const [formState, setFormState] = useState(IDLE);

  const handleOnChange = e => {
    if (formState !== IDLE) setFormState(IDLE);
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (!value) return;
    setFormState(SUBMITTING);

    try {
      const response = await apiFetch(`/api/discount/${value}`);
      const json = await response.json();
      if (json.status === 200) {
        setFormState(ACCEPTED);
        hanldeLockNavigation();
      } else {
        setFormState(ERROR);
      }
    } catch (e) {
      setFormState(ERROR);
    }
  };

  const renderEndAdornment = () => {
    switch (formState) {
      case SUBMITTING: {
        return <CircularProgress size={30} />;
      }
      case ACCEPTED: {
        return (
          <SuccessTooltip open={true} title="Accepted" placement="right" arrow>
            <IconButton
              aria-label="Success"
              edge="end"
              color="secondary"
              disableFocusRipple
              disableRipple
            >
              <CheckCircleIcon />
            </IconButton>
          </SuccessTooltip>
        );
      }
      case ERROR: {
        return (
          <ErrorTooltip
            open={true}
            title="Declined. Please retry your code or
    select another way to verify."
            placement="top"
            arrow
          >
            <IconButton
              aria-label="Error"
              edge="end"
              disableFocusRipple
              disableRipple
            >
              <ErrorIcon className={classes.errorIcon} />
            </IconButton>
          </ErrorTooltip>
        );
      }
      default:
        return (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleSubmit}
            onMouseDown={handleSubmit}
            disabled={!value}
            edge="end"
          >
            <SubmitIcon disabled={!value} />
          </IconButton>
        );
    }
  };

  return (
    <div>
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            className={classes.centeredFlex}
          >
            <Typography variant={bodyVariant}>
              If you have previously verified your identity, enter your Identity
              Code here:
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <TextField
              label="Code"
              variant="outlined"
              value={value}
              disabled={formState === ACCEPTED}
              onChange={handleOnChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {renderEndAdornment()}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <ActionComponent
        handleSuccess={() =>
          handleSuccess({
            couponCode: value,
          })
        }
        accepted={formState === ACCEPTED}
      />
    </div>
  );
};

export default IdentityCodeForm;
