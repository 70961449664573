import React, { useState, useEffect } from 'react';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';

// Thanks to https://stackoverflow.com/questions/6018611/smallest-data-uri-iframe-possible-for-a-transparent-iframe
const BLANK_TRANSPARENT_IMAGE_PIXEL =
  'https://www.youtube.com/embed/owsfdh4gxyc';

const iFrameLoader = props => {
  const {
    placeholder,
    src,
    alt,
    width,
    height,
    classes,
    style,
    ...rest
  } = props;

  const [iframeState, setiFrameState] = useState({
    isLoaded: false,
    hasError: false,
  });

  useEffect(() => {
    if (!iframeState.isLoaded && src) {
      const iframe = document.createElement('iframe');
      console.log(src);

      iframe.src = src;

      setiFrameState({
        isLoaded: true,
      });

      iframe.onerror = function() {
        setiFrameState({
          hasError: true,
        });
      };
    }
  }, [src]);

  const iframeUrl = iframeState.isLoaded ? src : placeholder;

  const baseStyle = {
    ...style,
    display: 'block',
    width: width ? width : '100%',
  };

  return (
    <iframe
      {...rest}
      display="initial"
      style={baseStyle}
      frameBorder="0"
      width="100%"
      src={iframeUrl}
      position="relative"
    ></iframe>
  );
};

// const StylediFrameLoader = withStyles(styles)(iFrameLoader);
const StylediFrameLoader = iFrameLoader;

const iFrameProtected = props => {
  const { src, placeholder, control, classes, personId, ...rest } = props;
  const [iframe, setiFrame] = useState(null);

  useEffect(() => {
    if (personId && !iframe) {
      apiFetchAuthorized(
        src,
        {
          headers: {
            Pid: personId,
          },
        },
        false
      )
        .then(res => res.blob())
        .then(blob => {
          setiFrame(URL.createObjectURL(blob));
          console.log('iFrame loaded:', URL.createObjectURL(blob));
        });
    }
  }, [personId, iframe]);

  return (
    <StylediFrameLoader
      src={iframe}
      placeholder={placeholder}
      classes={classes}
      control={control}
      {...rest}
    />
  );
};

export default iFrameProtected;
