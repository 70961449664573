import { useState, useEffect } from 'react';
import { DATERANGE } from '../../ChartWrapper';
import { parseDate, whichDataSet, WEEKLY_AVERAGE } from '../utils';

const useBloodPressureChart = (coordinates, dateRange) => {
  const [netData, setNetData] = useState({});
  const dataSet = whichDataSet(dateRange);

  useEffect(() => {
    if (netData[dataSet]) return;
    calculateData();
  }, [dateRange]);

  useEffect(() => {
    setNetData([]);
    calculateData();
  }, [coordinates]);

  const calculateData = () => {
    const result = [];
    const line1 = [];
    const line2 = [];

    if (dataSet === WEEKLY_AVERAGE) {
      coordinates.forEach(item => {
        const diastolic = item.diastolic;
        const systolic = item.systolic;

        let date = new Date(item.date);
        date.setHours(0, 0, 0, 0);
        date = parseDate(date, DATERANGE.SIX_MONTHS);

        if (result[date]) {
          result[date].totalDiastolic += diastolic;
          result[date].totalSystolic += systolic;

          result[date].freq = result[date].freq + 1;
          result[date].averageDiastolic = Math.round(
            result[date].totalDiastolic / result[date].freq
          );
          result[date].averageSystolic = Math.round(
            result[date].totalSystolic / result[date].freq
          );
        } else {
          result[date] = {
            date,
            totalDiastolic: diastolic,
            averageDiastolic: diastolic,
            totalSystolic: systolic,
            averageSystolic: systolic,
            freq: 1,
          };
        }
      });
      Object.values(result).forEach(item => {
        line1.push({
          date: item.date,
          data: item.averageDiastolic,
        });

        line2.push({
          date: item.date,
          data: item.averageSystolic,
        });
      });
    } else {
      coordinates.forEach(item => {
        line1.push({
          date: item.date,
          data: item.diastolic,
        });

        line2.push({
          date: item.date,
          data: item.systolic,
        });
      });
    }

    setNetData({
      ...netData,
      [dataSet]: [line1, line2],
    });
  };

  return [netData];
};

export default useBloodPressureChart;
