import { format, addDays } from 'date-fns';

export const isForCovid = value =>
  value === 'Covid-19 (coronavirus)' || value === 'Covid-19';
export const required = value => (value ? undefined : 'Required');
export const beforeAdministrationOn = (value, allValues) => {
  if (
    value &&
    Boolean(allValues.administrationOn) &&
    value < allValues.administrationOn
  ) {
    return `Next Dose must be after ${format(
      addDays(new Date(allValues.administrationOn), 1),
      'MMM d, yyyy'
    )}`;
  } else return undefined;
};

export const afterBoosterDate = (value, allValues) => {
  if (new Date(value) > new Date()) return `Date must be in the past`;
  if (!value) return 'required';
  if (Boolean(allValues.boosterDueOn) && value > allValues.boosterDueOn) {
    return `Must be before ${format(
      addDays(new Date(allValues.boosterDueOn), 1),
      'MMM d, yyyy'
    )}`;
  } else return undefined;
};

export const doseValidation = (value, allValues) => {
  if (isForCovid(allValues.purpose) && !value) return 'Required';
  if (
    value &&
    allValues.totalDosesPerSeries &&
    value > allValues.totalDosesPerSeries
  ) {
    return `Dose # must not be greater than Total Doses`;
  } else return undefined;
};

export const totalDosesValidation = (value, allValues) => {
  if (isForCovid(allValues.purpose) && !value) return 'Required';
  if (value && value === 0) {
    return `Total Doses in series must be a number greater than zero.`;
  }
  if (value && allValues.dose && allValues.dose > value) {
    return `Total Doses must be a number greater than Dose #`;
  }
  return undefined;
};
