/**
 * Format string by using supplied mask. Example 222222222 with
 * a mask of XXX-XX-XXXX (standard US social security number)
 * will become 222-22-2222.
 *
 * Also supports the use of mixed characters e.g., mask XXX-XXX with
 * Canadian postal code 1A2C5B will become 1A2-C5B
 *
 * @param {string} text the string to format
 * @param {string} mask used to format the string
 * @param {boolean} mixed between numbers and alphanumeric
 * @param {string} sep separator
 */
export const mask = (text, mask, mixed = false, sep = '-') => {
  if (!text) {
    return '';
  }

  const partSizes = mask.split(sep).map(x => x.length);

  let clean;
  if (!mixed) {
    clean = text.replace(/\D/g, '');
  } else {
    clean = text.replace(/\W/g, '');
  }

  let temp = [];

  for (let i in partSizes) {
    if (clean.length > partSizes[i]) {
      temp.push(clean.substr(0, partSizes[i]));
      clean = clean.substr(partSizes[i]);
    } else {
      break;
    }
  }

  temp.push(clean);

  const string = temp.join(sep);
  return string.substr(0, mask.length);
};

/**
 * Format number of bytes into human readable multiples.
 * Supports both metric (decimal) and IEC (binary) formats
 *
 * See link for more info:
 * https://en.wikipedia.org/wiki/Kilobyte
 *
 * @param {number} bytes number to format
 * @param {boolean} eic if eic then unit size is 1024, else 1000 (metric)
 * @param {number} scale number of decimal for an IEC formated number
 */
export const readableFileSize = (bytes, iec = false, scale = 2) => {
  const i = iec ? 'i' : '',
    unit = iec ? 1024 : 1000,
    multiples = [
      `K${i}B`,
      `M${i}B`,
      `G${i}B`,
      `T${i}B`,
      `P${i}B`,
      `E${i}B`,
      `Z${i}B`,
      `Y${i}B`,
    ];
  let result;

  for (
    let multiple = 0, approx = bytes / unit;
    approx > 1;
    approx /= unit, multiple++
  ) {
    result =
      (iec ? approx.toFixed(scale) : Math.round(approx)) +
      ` ${multiples[multiple]}`;
  }

  return result;
};

/**
 * Truncate a given string by a maximum number of allowed characters.
 * Append trailing ellipses, or a given string to end.
 *
 * @param {*} string
 * @param {*} max maximum number of characters to display
 * @param {*} end by default append trailing ellipses.
 * @return string
 */
export const shorten = (string, max, end = '...') =>
  string.length > max ? string.substring(0, max) + end : string;

/**
 *
 *  returns total height in inches from a string value of
 *  height with format `X'XX`
 *
 */
export const heightStrToInches = height => {
  const arr = height.split("'");
  return parseInt(arr[0]) * 12 + parseInt(arr[1]);
};

export const normalizeDecimal = value => {
  // returns decimal. prevents entry of chars, -, +
  if (value.endsWith('.') && value.indexOf('.') === value.lastIndexOf('.'))
    return value;
  if (value > 9999) return parseFloat(value.slice(0, -1));
  if (isNaN(parseFloat(value))) return '';
  return parseFloat(value);
};

export const maskedDateFormatter = (mask, acceptRegexp) => value => {
  return value
    .split('')
    .map((char, i) => {
      acceptRegexp.lastIndex = 0;

      if (i > mask.length - 1) {
        return '';
      }

      const maskChar = mask[i];
      const nextMaskChar = mask[i + 1];

      const acceptedChar = acceptRegexp.test(char) ? char : '';
      const formattedChar =
        maskChar === '_' ? acceptedChar : maskChar + acceptedChar;

      if (i === value.length - 1 && nextMaskChar && nextMaskChar !== '_') {
        // when cursor at the end of mask part (e.g. month) prerender next symbol "21" -> "21/"
        return formattedChar ? formattedChar + nextMaskChar : '';
      } else {
        return formattedChar;
      }
    })
    .join('');
};
