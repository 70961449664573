import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  panel: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    background: '#f1f8e9',
    padding: theme.spacing(3),
    '& p': {
      marginRight: theme.spacing(2),
    },
  },
  container: {
    width: '100%',
  },
});

const FormPanel = React.forwardRef((props, ref) => {
  const {
    classes,
    formSlot,
    bottomLeftSlot,
    bottomRightSlot,
    onSubmit,
    elevation = 1,
  } = props;

  return (
    <Grid container className={classes.container}>
      <form
        autoComplete="off"
        onSubmit={onSubmit}
        className={classes.container}
        ref={ref}
      >
        <Grid item sm={12}>
          <Paper elevation={elevation}>
            <div className={classes.panel}>{formSlot()}</div>
            <div className={classes.footer}>
              <Grid container>
                <Grid item xs={12} sm={7} lg={7}>
                  {bottomLeftSlot()}
                </Grid>
                <Grid
                  item
                  className={classes.buttonGroup}
                  xs={12}
                  sm={5}
                  lg={5}
                >
                  {bottomRightSlot()}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </form>
    </Grid>
  );
});

export default withStyles(styles)(FormPanel);
