import React from 'react';
import { useStyles } from './useStyles';
import { CardMedia } from '@material-ui/core';
import { PdfIcon } from '../../DocumentsDrawer/EditMode/MediaObjectSvgIcons';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';

export const renderImage = (image, synapseType) => {
  const classes = useStyles();
  if (Boolean(image)) {
    return <CardMedia className={classes.media} image={image} />;
  } else {
    return (
      <div className={classes.media}>
        {synapseType === 'Document' ? (
          <PdfIcon width="65px" height="65px" fill="#ffffff" />
        ) : (
          <CropOriginalIcon className={classes.CroppedIcon} />
        )}
      </div>
    );
  }
};
