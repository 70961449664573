import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Switch, Typography } from '@material-ui/core';

const SwitchField = ({ input: { onChange, value }, label }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', minHeight: '60px' }}>
      <Switch
        checked={value}
        onChange={() => onChange(!value)}
        name="checkedC"
      />
      <Typography style={{ fontSize: '14px', marginLeft: '8px' }}>
        {label}
      </Typography>
    </div>
  );
};

SwitchField.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};

export default SwitchField;
