import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { getMonth, parseISO, getYear } from 'date-fns';
import { getMonths } from '../../../../library/components/Calendar/utils';
import Slider from '../../../containers/Slider';
import CustomCard from './Components/CustomCard';
import { sortByStatus } from '../index.js';

let DocumentsSlider = props => {
  const { list, sortBy, type, filter, key, listIds, hasUpdates, newId } = props;
  const { DATE, CATEGORIES } = sortByStatus;
  const renderSlider = () => {
    let data;
    let label;
    switch (type) {
      case DATE: {
        label = `${getMonths('long')[sortBy[0]]} ${sortBy[1]}`;
        data = listIds.filter(item => {
          return (
            Boolean(list[item].enteredOn) &&
            getMonth(parseISO(list[item].enteredOn)) === sortBy[0] &&
            getYear(parseISO(list[item].enteredOn)) === sortBy[1]
          );
        });
        break;
      }
      case CATEGORIES: {
        label = sortBy;
        data = listIds.filter(
          item =>
            Boolean(list[item]) &&
            Boolean(list[item].category) &&
            list[item].category.includes(sortBy)
        );
        break;
      }
    }

    return (
      <Grid container key={key} spacing={0} style={{ marginBottom: '16px' }}>
        <Grid item xs={12}>
          <Typography style={{ color: '#0068A8' }}>{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider
            listIds={data}
            childElement={CustomCard}
            filter={filter}
            list={list}
            newId={newId}
            hasUpdates={hasUpdates}
            sliderStyles={{
              maxWidth: '1600px',
              backgroundColor: '#ffffff',
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return <React.Fragment>{renderSlider()}</React.Fragment>;
};

DocumentsSlider = React.memo(DocumentsSlider);
export default DocumentsSlider;
