import React, { Component } from 'react';
import { Modal, Grid, withStyles, IconButton, Icon } from '@material-ui/core';

const styles = theme => ({
  overlay: {
    overflow: 'auto',
  },
  root: {
    background: '#fff',
    borderRadius: 6,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '95%',
    maxWidth: 1160,
    position: 'relative',
    outline: 'none',
  },
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
});

const ModalBase = ({
  classes,
  showCloseIcon,
  onClose,
  open,
  height,
  children,
  minHeight,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus={true}
      className={classes.overlay}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.root}
        style={
          minHeight
            ? { margin: '100px auto', minHeight }
            : height
            ? { height, top: `calc(50% - (${height}px/2))`, margin: '0 auto' }
            : { margin: '100px auto' }
        }
      >
        {showCloseIcon && (
          <IconButton className={classes.close} onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        )}
        {children}
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(ModalBase);
