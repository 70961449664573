import React, { useState, useEffect } from 'react';
import {
  Grid,
  Icon,
  Typography,
  withStyles,
  Divider,
  TextField,
  Grow,
} from '@material-ui/core';
import { BLOOD_PRESSURE_FIELDS } from './fragments/BloodPressureDialog';
import { WATER_INTAKE_FIELDS } from './fragments/WaterIntakeDialog';
import { BODY_METRICS_FIELDS } from './fragments/BodyMetricsDialog';
import { SLEEP_TRACKER_FIELDS } from './fragments/SleepTrackingDialog';
import { MEAL_TRACKING_FIELDS } from './fragments/MealTrackingDialog';
import { STRESS_MANAGEMENT_FIELDS } from './fragments/StressManagementDialog';
import { ACTIVITY_EXERCISE_FIELDS } from './fragments/ActivityExerciseDialog';
import { MEDICATION_FIELDS } from './fragments/MedicationsDialog';

export const FRAGMENT_BODY_MASS = 'BodyMass';
export const FRAGMENT_BLOOD_PRESSURE = 'BloodPressure';
export const FRAGMENT_WATER_INTAKE = 'WaterIntake';
export const FRAGMENT_EMOTIONS = 'Emotions';
export const FRAGMENT_DEFAULT = 'default';
export const FRAGMENT_BODY_METRICS = 'BodyMetrics';
export const FRAGMENT_SLEEP_TRACKER = 'SleepTracker';
export const FRAGMENT_MEAL_TRACKER = 'MealEntry';
export const FRAGMENT_STRESS_MANAGEMENT = 'StressManagement';
export const FRAGMENT_ACTIVITY_EXERCISE = 'ActivityExercise';
export const FRAGMENT_MEDICATIONS = 'Medications';

import StressManagementIcon from './buttons/StressManagementButton';
import MealFragmentButton from './buttons/MealFragmentButton';
import ActivityExerciseButton from './buttons/ActivityExerciseButton';
import MedicationButton from './buttons/MedicationButton';
import BloodPressureButton from './buttons/BloodPressureButton';

import { properties as bloodPressureProperties } from './fragments/BloodPressure/properties';

const defaultFragment = {
  color: '#75747B',
  icon: 'library_add',
  name: 'General Entry',
  fragmentType: FRAGMENT_DEFAULT,
  fragmentData: {},
};

const defaultDataNormalizer = data => data;

const getFragmentProperties = name => {
  const fragmentCollection = {
    [FRAGMENT_ACTIVITY_EXERCISE]: {
      label: 'Activity / Exercise',
      color: '#90B850',
      icon: ActivityExerciseButton,
      dataFields: ACTIVITY_EXERCISE_FIELDS,
      normalizeData: data => ({
        ...data,
        activityItems:
          data.activityItems && data.activityItems.length > 0
            ? data.activityItems.map(item => ({
                ...item,
                durationMinutes: parseInt(item.durationMinutes),
              }))
            : [],
        totalActivityTimeMins: parseInt(data.totalActivityTimeMins),
        manualActivityTimeMins: parseInt(data.manualActivityTimeMins),
        totalCaloriesBurned: parseInt(data.totalCaloriesBurned),
        manualCaloriesBurned: parseInt(data.manualCaloriesBurned),
      }),
    },
    [FRAGMENT_BODY_MASS]: {
      label: 'Body Metrics',
      color: '#90B850',
      icon: 'accessibility',
      dataFields: BODY_METRICS_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_BLOOD_PRESSURE]: bloodPressureProperties,
    [FRAGMENT_EMOTIONS]: {
      label: 'Emotions',
      color: '#FFA561',
      icon: 'insert_emoticon',
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_MEAL_TRACKER]: {
      label: 'Meal Log',
      color: '#90B850',
      icon: MealFragmentButton,
      dataFields: MEAL_TRACKING_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_MEDICATIONS]: {
      label: 'Medications',
      color: '#ED5158',
      icon: MedicationButton,
      dataFields: MEDICATION_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_WATER_INTAKE]: {
      label: 'Water Intake',
      color: '#68B3E0',
      icon: 'invert_colors',
      dataFields: WATER_INTAKE_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_STRESS_MANAGEMENT]: {
      label: 'Stress Management',
      color: '#90B850',
      icon: StressManagementIcon,
      dataFields: STRESS_MANAGEMENT_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
    [FRAGMENT_SLEEP_TRACKER]: {
      label: 'Sleep',
      color: '#68B3E0',
      icon: 'hotel',
      dataFields: SLEEP_TRACKER_FIELDS,
      normalizeData: defaultDataNormalizer,
    },
  };

  return fragmentCollection[name];
};

// TODO - Eventually the contents of this array will replace the fragmentList values
const fragmentCollection = [
  FRAGMENT_ACTIVITY_EXERCISE,
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_EMOTIONS,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_MEDICATIONS,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_STRESS_MANAGEMENT,
  FRAGMENT_SLEEP_TRACKER,
];

export { defaultDataNormalizer, getFragmentProperties, fragmentCollection };

export const fragmentsList = [
  {
    color: '#90B850',
    icon: 'accessibility',
    name: 'Body Metrics',
    fragmentType: FRAGMENT_BODY_MASS,
    fragmentData: BODY_METRICS_FIELDS,
  },
  {
    color: '#ED5158',
    icon: BloodPressureButton,
    name: 'Blood Pressure',
    fragmentType: FRAGMENT_BLOOD_PRESSURE,
    fragmentData: BLOOD_PRESSURE_FIELDS,
  },

  {
    color: '#68B3E0',
    icon: 'invert_colors',
    name: 'Water',
    fragmentType: FRAGMENT_WATER_INTAKE,
    fragmentData: WATER_INTAKE_FIELDS,
  },

  {
    color: '#68B3E0',
    icon: 'hotel',
    name: 'Sleep',
    fragmentType: FRAGMENT_SLEEP_TRACKER,
    fragmentData: SLEEP_TRACKER_FIELDS,
  },
  {
    color: '#90B850',
    icon: MealFragmentButton,
    name: 'Meal Log',
    fragmentType: FRAGMENT_MEAL_TRACKER,
    fragmentData: MEAL_TRACKING_FIELDS,
  },
  {
    color: '#90B850',
    icon: StressManagementIcon,
    name: 'Stress Management',
    fragmentType: FRAGMENT_STRESS_MANAGEMENT,
    fragmentData: STRESS_MANAGEMENT_FIELDS,
  },
  {
    color: '#90B850',
    icon: ActivityExerciseButton,
    name: 'Activity/ Exercise',
    fragmentType: FRAGMENT_ACTIVITY_EXERCISE,
    fragmentData: ACTIVITY_EXERCISE_FIELDS,
  },
  {
    color: '#ED5158',
    icon: MedicationButton,
    name: 'Medications/ Supplements',
    fragmentType: FRAGMENT_MEDICATIONS,
    fragmentData: MEDICATION_FIELDS,
  },
  {
    color: '#FFA561',
    icon: 'insert_emoticon',
    name: 'Emotions',
    fragmentType: FRAGMENT_EMOTIONS,
    fragmentData: [],
  },
];

const styles = theme => ({
  card: {
    borderRadius: 4,
    border: '1px solid #eee',
    transition: 'all .2s',
    padding: 18,
    height: '100%',
    minHeight: 125,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  disabled: {
    opacity: 0.4,
    cursor: 'default',
    '&:hover': {
      opacity: 0.4,
    },
  },
  iconContainer: {
    width: 30,
    height: 30,
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {},
  text: {
    width: '100%',
    fontSize: '15px',
    fontWeight: '600',
    color: '#222',
  },
  root: {
    overflowX: 'auto',
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 32,
    paddingRight: 32,
  },
});

const FragmentView = ({ data, onClick, classes, isVisible }) => (
  <Grid
    item
    xs={4}
    onClick={() =>
      !data.disabled &&
      onClick(data.fragmentType, data.fragmentData, data.name, data.color)
    }
  >
    <Grow in={isVisible}>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={`${classes.card} ${data.disabled && classes.disabled}`}
      >
        <div
          style={{ backgroundColor: data.color }}
          className={classes.iconContainer}
        >
          {typeof data.icon === 'function' ? (
            React.createElement(data.icon)
          ) : (
            <Icon className={classes.icon} style={{ color: '#ffffff' }}>
              {data.icon}
            </Icon>
          )}
        </div>
        <Typography align="center" className={classes.text}>
          {data.name}
        </Typography>
      </Grid>
    </Grow>
  </Grid>
);

const FragmentsList = ({ activeId, onClick, classes }) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredFragments, setFilteredFragments] = useState([]);

  useEffect(() => {
    const filteredArr = fragmentsList
      .map((fragment, index) => {
        if (fragment.name.toLowerCase().includes(searchValue.toLowerCase()))
          fragment.isVisible = true;
        else fragment.isVisible = false;
        return fragment;
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        return a.isVisible === b.isVisible ? 0 : b.isVisible ? 1 : -1;
      });
    setFilteredFragments(filteredArr);
    console.log(filteredArr, fragmentsList);
  }, [searchValue]);

  const renderActiveForm = () => {
    const idx = fragmentsList.map(f => f.fragmentType).indexOf(activeId);
    return (
      <FragmentView
        classes={classes}
        data={fragmentsList[idx]}
        onClick={onClick}
      />
    );
  };
  return (
    <React.Fragment>
      <div style={{ marginTop: 32 }}>
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid
            style={{ marginRight: 16 }}
            item
            onClick={() =>
              onClick(
                defaultFragment.fragmentType,
                defaultFragment.fragmentData,
                defaultFragment.name
              )
            }
          >
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ width: 125 }}
              className={`${classes.card}`}
            >
              <div
                style={{ backgroundColor: '#75747B' }}
                className={classes.iconContainer}
              >
                <Icon className={classes.icon} style={{ color: '#fff' }}>
                  library_add
                </Icon>
              </div>
              <Typography align="center" className={classes.text}>
                General Entry
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              label="Search"
              helperText="Search all tracking forms"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider
          style={{
            marginTop: 24,
            marginBottom: 12,
            marginLeft: 16,
            marginRight: 16,
          }}
        />
      </div>
      <Grid container spacing={3} className={classes.root}>
        {activeId === FRAGMENT_DEFAULT
          ? filteredFragments.map(form => (
              <FragmentView
                classes={classes}
                data={form}
                onClick={onClick}
                key={form.fragmentType}
                isVisible={form.isVisible}
              />
            ))
          : renderActiveForm()}
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(FragmentsList);
