import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    position: 'relative',
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
  },
});

class ProgressButton extends Component {
  render() {
    const {
      classes,
      children,
      normalText,
      successText,
      failureText,
      isPending,
      hasError,
      disabled,
      ...rest
    } = this.props;
    return (
      <div className={classes.root}>
        <Button {...rest} disabled={isPending || hasError || disabled}>
          {children}
        </Button>
        {isPending ? (
          <CircularProgress size={24} className={classes.buttonProgress} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

ProgressButton.propTypes = {
  isPending: PropTypes.bool.isRequired,
};

ProgressButton.defaultProps = {
  isPending: false,
  disabled: false,
  normalText: 'Submit',
  successText: 'Submitted!',
  failureText: 'Error',
  variant: 'contained',
  color: 'primary',
};

export default withStyles(styles)(ProgressButton);
