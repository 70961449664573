import React, { useEffect, useState, Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class FormWizard extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      route: null,
      childIndex: 0,
      childrenLength: 0,
    };

    this.child = React.createRef();
  }

  /**
   * Always mount the first child form when the wizard component mounts.
   * Set browser history to represent the first form component.
   * If a user tries to pass in a manual route, override with the
   * first form child. User shouldn't be able to manually control which
   * forms to interact with. Forms should be visited by the sequence defined
   * by the developer.
   */
  componentDidMount() {
    const { children, location, initialValues } = this.props;

    const qualifiedPath =
      this.props.baseRoutePath + children[0].props.routePath;

    if (qualifiedPath !== location.pathname) {
      this.props.history.push(
        this.props.baseRoutePath + children[0].props.routePath
      );
    }

    this.setState({
      childIndex: 0,
      childrenLength: React.Children.count(children),
      initialValues,
    });
  }

  componentWillUnmount() {
    this.child = undefined;
  }

  /**
   * Get the correct child form using either the nextPage and previousPage
   * functions, or using the browser forward and back buttons.
   */
  static getDerivedStateFromProps(props, state) {
    const { children, baseRoutePath, location, initialValues } = props;

    // If a new child form is visible, this will change.
    if (location.pathname !== state.route) {
      // get index of active child form.
      const childIndex = React.Children.toArray(children).findIndex(
        child => baseRoutePath + child.props.routePath === location.pathname
      );

      return {
        route: location.pathname,
        childIndex: childIndex < 0 ? 0 : childIndex,
        initialValues,
      };
    }

    return null;
  }

  /**
   * componentWillReceiveProps handles getting the correct
   * child form. Push next route so that native browser
   * functionality is supported.
   */
  nextPage = () => {
    const { baseRoutePath, children } = this.props;
    const nextChildIndex = this.state.childIndex + 1;
    const fullyQualifiedPath =
      baseRoutePath + children[nextChildIndex].props.routePath;

    // Provide an interface for absolute control over sub-form submission.
    // if "shouldAllowSubmission" is null or undefined, default to true.
    // This means that the user has not defined the function and default
    // behavior is expected.
    const nextForm =
      !this.child || !this.child.shouldAllowSubmission
        ? true
        : this.child.shouldAllowSubmission();

    if (nextForm) this.props.history.push(fullyQualifiedPath);
  };

  /**
   * componentWillReceiveProps handles getting the correct
   * child form. Pop last route from browser history.
   */
  previousPage() {
    this.props.history.goBack();
  }

  /**
   * Render a single child form component based on page state.
   * If a progress component has been provided, render component,
   */
  render() {
    const { children, onSubmit } = this.props;
    const { childIndex, childrenLength } = this.state;

    if (typeof this.props.formName === 'object') {
      console.log('FORM NAME OBJECT?', this.props.formName);
    } else {
      console.log('Form name', this.props.formName);
    }

    return (
      <div>
        {this.props.progressComponent &&
          React.createElement(this.props.progressComponent, {
            value: childIndex + 1,
            max: childrenLength,
          })}
        {React.createElement(
          console.log('Wizard props', children[childIndex].props) ||
            children[childIndex].type,
          {
            // Get child reference used to call child functions.
            onRef: ref => (this.child = ref),
            form: this.props.formName,
            initialValues: this.props.initialValues,
            onSubmit:
              childIndex + 1 === childrenLength ? onSubmit : this.nextPage,
            previousPage: this.previousPage,
            ...children[childIndex].props,
          }
        )}
      </div>
    );
  }
}

// const FormWizard = props => {

//   const { history, children, onSubmit, formName, initialValues, baseRoutePath } = props;
//   const child = React.createRef(null);

//   const [ childrenLength, setChildrenLength] = useState(0);
//   const [ childrenIndex, setChildrenIndex ] = useState(0);

//   useEffect(() => {

//     const index = React.Children
//         .toArray(children)
//         .findIndex(c => baseRoutePath + c.props.routePath === location.pathname);

//     setChildrenLength(React.Children.count(children));
//     setChildrenIndex(index)
//   }, [children, initialValues])

//   const onNextPage = () => {
//     const fullPath = baseRoutePath + child.current.routePath;
//     const moveNext = !child.current.shouldAllowSubmission ? true : child.current.shouldAllowSubmission();
//     setChildrenIndex(childrenIndex + 1)

//     if (moveNext){
//       history.push(fullPath)
//     }
//   }

//   const onPrevPage = () => {
//     history.goBack()
//   }

//   return (
//     <React.Fragment>
//       {
//         React.createElement(children[childrenIndex].type, {
//           onRef: child,
//           form: formName,
//           onSubmit: childrenIndex === childrenLength ? onSubmit : onNextPage,
//           previousPage: onPrevPage,
//           ...children[childrenIndex].props
//         })
//       }
//     </React.Fragment>
//   )
// }

FormWizard.propTypes = {
  formName: PropTypes.string.isRequired,
  baseRoutePath: PropTypes.string.isRequired,
  progressComponent: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(FormWizard);
