import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, InputAdornment } from '@material-ui/core';

import InputField from '../../../../library/forms/InputField';
import { apiFetch } from '../../../../library/helpers/fetch';

const RegistrationCoupon = ({ code, children, coupon, onChange, onSubmit }) => {
  const [state, setState] = useState('IDLE');
  const [codeValue, setCodeValue] = useState('');
  const [discount, setDiscount] = useState({
    ...coupon,
    data: null,
    error: false,
  });

  useEffect(() => {
    setCodeValue(code);
  }, [code]);

  useEffect(() => {
    if (!coupon.data) {
      setState('IDLE');
      setCodeValue('');
      setDiscount(state => ({ ...state, data: null }));
    }
  }, [coupon.data]);

  const handleChange = event => {
    const text = event.target.value;
    setCodeValue(text);
    onChange(text);
  };

  const onClear = () => {
    const resetDiscount = { data: null, error: false };
    setState('IDLE');
    setCodeValue('');
    setDiscount(resetDiscount);
    onSubmit(resetDiscount);
  };

  const handleDiscount = async () => {
    if (!codeValue) return;

    // setDiscount(state => ({ ...state, data: null));

    setState('FETCHING');

    try {
      const response = await apiFetch(`/api/discount/${codeValue}`);
      const json = await response.json();

      const data = {
        data: json.data,
        error: json.status !== 200 ? json.message : false,
      };

      setDiscount(data);
      onSubmit(data);
      setState(json.status !== 200 ? 'FAILURE' : 'SUCCESS');
    } catch (e) {
      setState('FAILURE');
      setDiscount(state => ({
        ...state,
        error: true,
      }));
    }
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} sm={6}>
        <InputField
          type="text"
          name="couponCode"
          label="Coupon Code"
          // enableMinHeight={false}
          meta={{
            validating: false,
            asyncValidating: false,
            touched: false,
            error: false,
          }}
          input={{ value: codeValue ? codeValue : '', onChange: handleChange }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={!discount.data ? handleDiscount : onClear}>
                  {!discount.data ? 'Apply' : 'Clear'}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        {children ? children(state, discount) : null}
      </Grid>
    </Grid>
  );
};

RegistrationCoupon.propTypes = {
  code: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RegistrationCoupon;
