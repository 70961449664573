import React, { useState, useRef } from 'react';
import InputField from '../../../library/forms/InputField';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DateTextField from '../../../library/forms/DateTextField';

export default ({ onChange, searchText, startDate, endDate }) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={5} md={4}>
          <DateTextField
            name="startDate"
            label="from"
            date={startDate}
            hint="Filter range by date range"
            onChange={onChange}
            inputProps={{
              max: endDate || '',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={5} md={4}>
          <DateTextField
            name="endDate"
            label="thru"
            date={endDate}
            onChange={onChange}
            inputProps={{
              min: startDate || '',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={4}>
          <InputField
            type="text"
            variant="outlined"
            onChange={e => onChange('searchText', e.target.value)}
            label="Search"
            hint="Search Journal record names and tags"
            value={searchText}
            shrink={true}
            InputProps={{
              endAdornment:
                searchText.length === 0 ? (
                  <SearchIcon />
                ) : (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => onChange('searchText', null)}
                      aria-label="Clear"
                      size="small"
                    >
                      <ClearIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
