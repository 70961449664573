import {
  LABS_MEDIA_TO_DELETE, // delete
  LABS_MEDIA_TO_DELETE_CANCEL, // cancel
  LABS_REMOVE_MEDIA, // deleteMedia
  UPDATE_LABS_MEDIA,
} from '../../../store/reducers/labs/actions';

import {
  IMMUNIZATION_MEDIA_TO_DELETE, // delete
  IMMUNIZATION_MEDIA_TO_DELETE_CANCEL, // cancel
  IMMUNIZATION_REMOVE_MEDIA, // deleteMedia
  UPDATE_IMMUNIZATION_MEDIA,
} from '../../../store/reducers/immunizations/actions';

import {
  CONDITIONS_REMOVE_MEDIA,
  CONDITIONS_DELETE_MEDIA,
  CONDITIONS_DELETE_MEDIA_CANCEL,
  UPDATE_CONDITIONS_MEDIA,
} from '../../../store/reducers/conditions/actions';

import {
  SURGERIES_REMOVE_MEDIA,
  SURGERIES_DELETE_MEDIA,
  SURGERIES_DELETE_MEDIA_CANCEL,
  UPDATE_SURGERIES_MEDIA,
} from '../../../store/reducers/surgicalHistory/actions';

import {
  ALLERGY_REMOVE_MEDIA,
  ALLERGY_DELETE_MEDIA,
  ALLERGY_DELETE_MEDIA_CANCEL,
  ALLERGY_UPDATE_MEDIA,
} from '../../../store/reducers/allergies/actions';

import {
  MVS_REMOVE_MEDIA,
  MVS_DELETE_MEDIA,
  MVS_DELETE_MEDIA_CANCEL,
  MVS_UPDATE_MEDIA,
} from '../../../store/reducers/mvs/actions';

import {
  IMMUNIZATION,
  LABRESULT,
  CONDITIONS,
  SURGICALHISTORY,
  ALLERGY,
  MVS,
} from '../../../store/reducers/mediaObject';

export const attachmentTypes = {
  [MVS]: {
    deleteMedia: MVS_REMOVE_MEDIA,
    cancelDelete: MVS_DELETE_MEDIA_CANCEL,
    setToDelete: MVS_DELETE_MEDIA,
  },
  [ALLERGY]: {
    deleteMedia: ALLERGY_REMOVE_MEDIA,
    cancelDelete: ALLERGY_DELETE_MEDIA_CANCEL,
    setToDelete: ALLERGY_DELETE_MEDIA,
  },
  [LABRESULT]: {
    deleteMedia: LABS_REMOVE_MEDIA,
    cancelDelete: LABS_MEDIA_TO_DELETE_CANCEL,
    setToDelete: LABS_MEDIA_TO_DELETE,
  },
  [IMMUNIZATION]: {
    deleteMedia: IMMUNIZATION_REMOVE_MEDIA,
    cancelDelete: IMMUNIZATION_MEDIA_TO_DELETE_CANCEL,
    setToDelete: IMMUNIZATION_MEDIA_TO_DELETE,
  },
  [CONDITIONS]: {
    deleteMedia: CONDITIONS_REMOVE_MEDIA,
    cancelDelete: CONDITIONS_DELETE_MEDIA_CANCEL,
    setToDelete: CONDITIONS_DELETE_MEDIA,
  },
  [SURGICALHISTORY]: {
    deleteMedia: SURGERIES_REMOVE_MEDIA,
    cancelDelete: SURGERIES_DELETE_MEDIA_CANCEL,
    setToDelete: SURGERIES_DELETE_MEDIA,
  },
};

export const getId = (object, appArea) => {
  switch (appArea) {
    case MVS: {
      return object.medicationId;
    }
    case ALLERGY: {
      return object.allergyId;
    }
    case LABRESULT: {
      return object.labResultId;
    }
    case IMMUNIZATION: {
      return object.immunizationId;
    }
    case CONDITIONS: {
      return object.conditionsId;
    }
    case SURGICALHISTORY: {
      return object.surgicalHistoryId;
    }
  }
};

export const getFormType = {
  [MVS]: MVS_UPDATE_MEDIA,
  [ALLERGY]: ALLERGY_UPDATE_MEDIA,
  [LABRESULT]: UPDATE_LABS_MEDIA,
  [IMMUNIZATION]: UPDATE_IMMUNIZATION_MEDIA,
  [CONDITIONS]: UPDATE_CONDITIONS_MEDIA,
  [SURGICALHISTORY]: UPDATE_SURGERIES_MEDIA,
};

export const isDocument = appArea =>
  [IMMUNIZATION, LABRESULT].includes(appArea);
