import { useState, useEffect } from 'react';
import { DATERANGE } from '../../ChartWrapper';
import {
  parseDate,
  DISCREET_DATA_POINTS,
  WEEKLY_AVERAGE,
  whichDataSet,
} from '../utils';

const useNetTimeSlept = (coordinates, dateRange) => {
  const [netData, setNetData] = useState([]);
  const dataSet = whichDataSet(dateRange);

  useEffect(() => {
    if (netData[dataSet]) return;
    calculateData();
  }, [dateRange]);

  useEffect(() => {
    setNetData([]);
    calculateData();
  }, [coordinates]);

  const calculateData = () => {
    const result = [];

    coordinates.forEach(item => {
      const totalTimeSlept = item.totalTimeSlept;
      let date = new Date(item.date);
      if (dataSet === WEEKLY_AVERAGE) {
        date.setHours(0, 0, 0, 0);
        date = parseDate(date, DATERANGE.SIX_MONTHS);
      }
      if (result[date]) {
        result[date].totalTimeSlept += totalTimeSlept;
        if (result[date].totalTimeSlept > 24) {
          result[date].totalTimeSlept = 24;
          result[date].total += totalTimeSlept;
          result[date].freq = result[date].freq + 1;
          result[date].averageTotalTimeSlept = Math.round(
            result[date].total / result[date].freq
          );
        }
      } else {
        result[date] = {
          totalTimeSlept,
          unit: item.unit,
          date,
          total: totalTimeSlept,
          averageTotalTimeSlept: totalTimeSlept,
          freq: 1,
        };
      }
    });

    setNetData({
      ...netData,
      [dataSet]: Object.values(result),
    });
  };

  return [netData];
};

export default useNetTimeSlept;
