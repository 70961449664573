import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { round } from '../';
import styles from '../styles.css';

const tooltipStyles = {
  header: {
    fontWeight: 'bold',
    borderBottom: 'thin solid black',
    textAlign: 'center',
    paddingBottom: '4px',
    marginBottom: '4px',
  },
  lineItem: { position: 'relative', display: 'block', textAlign: 'left' },
  title: {
    display: 'inline-block',
    margin: '0 5px 0 15px',
    fontWeight: 'bold',
  },
  value: { display: 'inline-block', margin: '0' },
  wrapper: {
    background: 'rgba(255,255,255,0.9)',
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
    padding: '8px',
  },
};
const Tooltip = ({ title = '', d, keys }) => {
  const returnArray = [];
  keys.forEach(key => {
    if (!key.value) return;
    const valString = `${round(key.value, 1)} ${key.unit ? key.unit : ''}`;
    returnArray.push([
      <div key={`tooltip_line_${valString}`} style={tooltipStyles.lineItem}>
        {key.color ? (
          <p
            key={`tooltip_color_${valString}`}
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: key.color,
              display: 'inline-block',
              position: 'absolute',
              top: '8px',
              left: '0',
              margin: '0',
            }}
          />
        ) : (
          ''
        )}
        <Typography
          key={`tooltip_p_${valString}`}
          style={tooltipStyles.title}
        >{`${key.tag}`}</Typography>
        <Typography
          key={`tooltip_p_val_${valString}`}
          style={tooltipStyles.value}
        >
          = {valString}
        </Typography>
      </div>,
    ]);
  });

  return (
    <Paper className={styles.tooltipContent} style={tooltipStyles.wrapper}>
      {title.length ? (
        <Typography key={'header_multi'} style={tooltipStyles.header}>
          {title}
        </Typography>
      ) : (
        ''
      )}
      {returnArray}
    </Paper>
  );
};

export default Tooltip;
