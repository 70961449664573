import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './InitialQuestionForm.css';

import Question from '../Question';

class InitialQuestionsForm extends Component {
  state = { answeredQuestions: {} };

  componentDidMount() {
    const { questions } = this.props;
    let answeredQuestions = {},
      current;

    for (var i = 0; i < questions.length; i++) {
      current = questions[i];
      answeredQuestions[current.key] = !!current.selected;
    }

    this.setState({ answeredQuestions: answeredQuestions });
  }

  onQuestionAnswered = key => {
    const { answeredQuestions } = this.state;

    this.setState({
      ...this.state,
      answeredQuestions: {
        ...answeredQuestions,
        [key]: !answeredQuestions[key],
      },
    });
  };

  render() {
    const { userFirstName, questions, onSubmit } = this.props;
    const { answeredQuestions } = this.state;

    return (
      <div className={styles.formBody}>
        <h2 style={{ marginBottom: '16px' }}>
          Welcome to <br /> Agrin Health, {userFirstName}
        </h2>
        <p>
          To personalize your experience, we need just a few more things from
          you. Please indicate if any of the following does not apply.
        </p>
        <hr />
        {questions
          .sort((aq, bq) => {
            if (aq.key < bq.key) {
              return -1;
            }
            if (aq.key > bq.key) {
              return 1;
            }
            return 0;
          })
          .map(({ key, text }) => (
            <Question
              key={key}
              text={text}
              value={key}
              answerDefault="yes"
              onChange={this.onQuestionAnswered}
              selected={answeredQuestions[key]}
            />
          ))}
        <button
          className={`${styles.btn} ${styles.btnSubmit}`}
          onClick={e => onSubmit(answeredQuestions)}
        >
          Save and Continue
        </button>
      </div>
    );
  }
}

InitialQuestionsForm.propTypes = {
  userFirstName: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
};

export default InitialQuestionsForm;
