import React, { useEffect } from 'react';
import useDataPoints from '../useDataPoints';
import LineChart from '../../LineChart';
import styles from '../styles.css';
import { DATERANGE } from '../../ChartWrapper';
import { scaleTime } from 'd3-scale';
import { format, isWithinInterval } from 'date-fns';
import useBloodPressureChart from './useBloodPressureChart';
import { DISCREET_DATA_POINTS, WEEKLY_AVERAGE, whichDataSet } from '../utils';
import Tooltip from '../Tooltip';
import { getAverage } from '../';

const formatByDay = e => `${e.getMonth() + 1}/${e.getDate()}`;
const formatByMonthName = e => format(e, 'MMMM');
const formatByMonthShort = e => format(e, 'MMM');
const theme = ['#F65858', '#ffffff'];
const ANNOTATION_CONSTANTS = {
  systolic: {
    backgroundColor: '#BCDEF3',
    title: 'Systolic',
    color: '#0068A8',
  },
  diastolic: {
    backgroundColor: '#BCDEF3',
    title: 'Diastolic',
    color: '#0068A8',
  },
};

const BloodPressureChart = props => {
  const {
    coordinates,
    dateExtent,
    tickValues,
    dateRange,
    setAnnotations,
    hasData,
    mode,
  } = props;
  const [netData] = useBloodPressureChart(coordinates, dateRange);
  const shouldDisplayAverage = [
    DATERANGE.ONE_YEAR,
    DATERANGE.SIX_MONTHS,
  ].includes(dateRange);
  const lineData = netData[whichDataSet(dateRange)];
  const { upperLimit, lowerLimit } = useDataPoints(
    coordinates.filter(item =>
      isWithinInterval(item.date, { start: dateExtent[0], end: dateExtent[1] })
    ),
    ['systolic', 'diastolic'],
    dateExtent
  );

  function getAnnotations() {
    if (coordinates.length === 0) return;
    const annot = getAverage(
      coordinates.filter(item =>
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ),
      ['systolic', 'diastolic']
    );
    setAnnotations(
      annot.map(item => {
        return {
          value: item.average,
          ...ANNOTATION_CONSTANTS[item.title],
        };
      })
    );
  }

  useEffect(() => {
    getAnnotations();
  }, [coordinates, dateExtent]);

  const systolicLine = {
    title: 'Systolic',
    coordinates: coordinates.map(item => {
      return {
        data: item.systolic,
        date: item.date,
      };
    }),
  };

  const diastolicLine = {
    title: 'Diastolic',
    coordinates: coordinates.map(item => {
      return {
        data: item.diastolic,
        date: item.date,
      };
    }),
  };

  const frameProps = {
    lines: [
      {
        title: 'Diastolic',
        coordinates: lineData ? lineData[0] : [],
      },
      {
        title: 'Systolic',
        coordinates: lineData ? lineData[1] : [],
      },
    ],
    xAccessor: 'date',
    yAccessor: 'data',
    xExtent: dateExtent,
    yExtent: [lowerLimit, upperLimit],
    xScaleType: scaleTime(),
    axes: [
      { orient: 'left', className: styles.axes, baseline: false },
      {
        orient: 'bottom',
        tickFormat: function(e) {
          if (
            dateRange === DATERANGE.ONE_WEEK ||
            dateRange === DATERANGE.ONE_MONTH
          ) {
            return formatByDay(e);
          }
          if (
            dateRange === DATERANGE.THREE_MONTHS ||
            dateRange === DATERANGE.SIX_MONTHS
          ) {
            return formatByMonthName(e);
          }
          return formatByMonthShort(e);
        },
        className: styles.axes,
        tickValues,
        baseline: false,
      },
    ],
    hoverAnnotation: true,
    tooltipContent: d => {
      return (
        <Tooltip
          d={d}
          color="#ffffff"
          keys={[
            {
              tag: shouldDisplayAverage
                ? `Weekly Average ${d.parentLine.title}`
                : d.parentLine.title,
              unit: null,
              value: d.data.data,
              color: theme[d.parentLine.key],
            },
          ]}
        />
      );
    },
    lineStyle: (d, i) => ({
      stroke: theme[i],
      strokeWidth: 3,
      fill: 'none',
    }),
  };
  return <LineChart mode={mode} hasData={hasData} frameProps={frameProps} />;
};

export default BloodPressureChart;
