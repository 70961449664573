import React, { Fragment, useState } from 'react';
import {
  Typography,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  ROLE_NAME_PROVIDER,
  ROLE_NAME_GUARDIAN_PRIMARY,
  RELATIONSHIP_STATUS_ARCHIVED,
} from '../RoleSummary/data';

import ConfirmActionDialog from '../../Dialogs/ConfirmActionDialog';

const styles = {
  tooltip: {
    backgroundColor: '#222',
    opacity: 1,
    //left: -10,
    //top: -2,
    //position: 'absolute',
    maxWidth: '200px',
  },
};

const MemberActionButtons = ({
  member,
  onEdit,
  isSubsidy,
  onArchive,
  onDelete,
  isDeletable,
  isArchivable,
  isEditable,
  classes,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    onEdit();
  };

  const handleArchive = () => {
    handleClose();
    onArchive();
  };

  const handleDelete = () => {
    setAlertOpen(true);
    handleClose();
  };

  const renderEditButton = () => {
    return member.status !== RELATIONSHIP_STATUS_ARCHIVED ? (
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
    ) : (
      ''
    );
  };

  const renderArchiveButton = () => {
    return (
      <MenuItem onClick={handleArchive}>
        {member.status === RELATIONSHIP_STATUS_ARCHIVED
          ? 'Unarchive'
          : 'Archive'}
      </MenuItem>
    );
  };

  const renderDeleteButton = () => {
    return <MenuItem onClick={handleDelete}>Delete</MenuItem>;
  };

  return member.relationshipType !== ROLE_NAME_GUARDIAN_PRIMARY ? (
    <Fragment>
      <ConfirmActionDialog
        titleText="Confirm Delete?"
        buttonConfirmText="Delete"
        isOpened={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={onDelete}
      >
        <Typography variant="body1">
          Are you sure you want to delete <strong>{member.displayName}</strong>{' '}
          from your Care Team? All of their history with you, including any
          access history, will be lost. If you want to retain the history, you
          can Archive them instead.
        </Typography>
      </ConfirmActionDialog>
      <Tooltip
        title={
          isSubsidy
            ? 'Your account is receiving a subsidy from this Provider. Please contact Support@AgrinHealth.com to withdraw access.'
            : ''
        }
        placement="left-start"
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <IconButton
            aria-label="More"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disabled={isSubsidy}
          >
            <Icon>more_horiz</Icon>
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {isEditable && renderEditButton()}
        {isArchivable && renderArchiveButton()}
        {isDeletable && renderDeleteButton()}
      </Menu>
    </Fragment>
  ) : (
    ''
  );
};

export default withStyles(styles)(MemberActionButtons);
