import React, { useEffect } from 'react';
import { Grid, makeStyles, Fade } from '@material-ui/core';
import { isWithinInterval } from 'date-fns';
import WaterLineChart from './WaterLineChart';
import WaterPieChart from './WaterPieChart';
import useNetWaterIntake from './useNetWaterIntake';
import { whichDataSet } from '../utils';
import WaterStackedBarChart from './WaterStackedBarChart';
import WaterChartLegend from './WaterChartLegend';
import { getAverage } from '../';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles({
  pieChart: {
    display: 'flex',
    alignItems: 'center',
    ['@media (min-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      justifyContent: 'flex-end',
    },
    justifyContent: 'center',
  },
});

const WaterIntakeChart = props => {
  const {
    hasData,
    dateExtent,
    setAnnotations,
    coordinates,
    mode,
    dateRange,
  } = props;
  const [netData, pieData] = useNetWaterIntake(
    coordinates,
    dateExtent,
    dateRange
  );
  const lineData = netData[whichDataSet(dateRange)] || [];
  const matches = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  function getAnnotations() {
    if (lineData.length === 0) return;
    const annot = getAverage(
      lineData.filter(item =>
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ),
      ['quantity']
    );
    setAnnotations(
      annot.map(item => {
        return {
          value: item.average,
          title: 'Oz/day',
          backgroundColor: '#BCDEF3',
          color: '#0068A8',
        };
      })
    );
  }

  useEffect(() => {
    getAnnotations();
  }, [lineData, dateExtent]);

  const scaledVectorStyles = matches
    ? {}
    : {
        transform: 'scale(0.8)',
        marginTop: '-20px',
      };

  const renderGraphics = () => {
    return hasData ? (
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item md={5} xs={12} className={classes.pieChart}>
            <Fade in={hasData} mountOnEnter unmountOnExit timeout={200}>
              <div style={{ marginLeft: '0px', ...scaledVectorStyles }}>
                <WaterPieChart data={pieData} {...props} />
              </div>
            </Fade>
          </Grid>
          <Grid
            item
            md={2}
            xs={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={hasData} mountOnEnter unmountOnExit timeout={200}>
              <div style={scaledVectorStyles}>
                <WaterChartLegend />
              </div>
            </Fade>
          </Grid>
          <Grid
            item
            md={5}
            xs={8}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Fade in={hasData} mountOnEnter unmountOnExit timeout={200}>
              <div style={{ marginLeft: '-40px', ...scaledVectorStyles }}>
                <WaterStackedBarChart data={pieData} {...props} />
              </div>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      ''
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ maxWidth: `${window.innerWidth - 48}px` }}>
          <WaterLineChart data={lineData} {...props} />
        </div>
      </Grid>
      {mode === 'page' && renderGraphics()}
    </Grid>
  );
};

export default WaterIntakeChart;
