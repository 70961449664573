export const hasDefault = (field, string = 'default') => (
  field === undefined || field === string
);

export const hasNoSelection = (field) => (
  field === null 
    || field === '' 
    || field === 'null' 
    || field === undefined 
    || field === 'no_selection'
);