import {
  SURGICAL_HISTORY_CREATE_SUCCESS,
  SURGICAL_HISTORY_UPDATE_SUCCESS,
} from '../../../../store/reducers/surgicalHistory/actions';
import { getBundlesToUpload } from '../../../components/MediaObject/helpers';

const UPDATE = 'update';
const CREATE = 'create';
const VIEW = 'view';
const IDLE = 'idle';

const useSurgicalHistory = (
  {
    status,
    createSurgicalHistory,
    updateSurgicalHistory,
    personId,
    setSelectedId,
    selectedId,
    surgeryList,
    setStatus,
    deleteSurgicalHistory,
    bundles,
    form,
    mediaObjects,
    bundlesToDelete,
    deleteMedia,
    updateMedia,
  },
  setIsSubmitting
) => {
  const onFormSubmit = async values => {
    let result;
    setIsSubmitting(true);

    const { bundlesToUpload, bundlesToUpdate } = getBundlesToUpload(
      mediaObjects,
      form
    );

    // delete attachments
    if (bundlesToDelete.length) {
      for (const bundle of bundlesToDelete) {
        await deleteMedia(bundle.mediaObjectId, bundle.objectId);
      }
    }

    if (bundlesToUpdate.length) {
      for (const file of bundlesToUpdate) {
        await updateMedia({
          ...file,
          description: file.description,
        });
      }
    }

    if (status === CREATE) {
      result = await createSurgicalHistory(personId, values, bundlesToUpload);
    }
    if (status === UPDATE) {
      result = await updateSurgicalHistory(
        personId,
        surgeryList[selectedId].surgicalHistoryId,
        values,
        bundlesToUpload
      );
    }
    if (
      result.type === SURGICAL_HISTORY_CREATE_SUCCESS ||
      result.type === SURGICAL_HISTORY_UPDATE_SUCCESS
    ) {
      setIsSubmitting(false);
      setStatus(IDLE);
    }
  };

  const onClickDeleteCard = () => {
    deleteSurgicalHistory(
      personId,
      surgeryList[selectedId].surgicalHistoryId,
      selectedId
    );
    onFormCancel();
  };

  const onClickUpdateCard = () => {
    setSelectedId(selectedId);
    setStatus(UPDATE);
  };
  const actions =
    status === UPDATE || status === CREATE
      ? {
          onFormSubmit,
        }
      : {
          onClickDeleteCard,
          onClickUpdateCard,
        };
  return actions;
};

export { UPDATE, CREATE, VIEW, IDLE, useSurgicalHistory };
