import React, { useEffect, useState } from 'react';
import { DashboardMessage } from '../../../../components/DashboardContent';
import { Grid, TextField } from '@material-ui/core';
import ProgressButton from '../../../../components/Buttons/ProgressButton';

export default function ESignCard({
  signElectronicSignatureRequest,
  setHasSignedAttentation,
  meUser,
}) {
  const [signature, setSignature] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const expectedSignature = `${meUser.firstName} ${meUser.lastName}`;
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await signElectronicSignatureRequest(signature);
      setHasSignedAttentation(true);
    } catch (e) {
      throw e;
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (signature && signature !== expectedSignature) {
      setError('Expected signature of primary user.');
    } else {
      setError('');
    }
  }, [signature]);

  return (
    <DashboardMessage
      canClose={false}
      style={{ padding: 0, margin: '0', maxWidth: '750px' }}
    >
      <Grid container spacing={2} style={{ padding: '16px' }}>
        <Grid item xs={12} lg={10}>
          Under penalty of law, I hereby assert and affirm that I have the legal
          authority to create an account on behalf of the person identified
          above and to access their healthcare information.
        </Grid>
        <Grid
          item
          xs={10}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            label="Digital Signature"
            variant="outlined"
            hint="Required"
            placeholder={expectedSignature}
            error={error}
            onChange={e => setSignature(e.target.value)}
            value={signature}
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
          <ProgressButton
            disabled={error || !signature}
            isPending={isSubmitting}
            onClick={handleSubmit}
          >
            Submit
          </ProgressButton>
        </Grid>
      </Grid>
    </DashboardMessage>
  );
}
