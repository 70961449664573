import React, { useLayoutEffect, useState } from 'react';

function useContainerSize(containerRef) {
  const [containerWidth, setContainerWidth] = useState(0);

  function updateContainerWidth() {
    setContainerWidth(containerRef.current.clientWidth);
  }

  useLayoutEffect(() => {
    if (Boolean(containerRef.current)) {
      window.addEventListener('resize', updateContainerWidth);
      updateContainerWidth();
      return () => window.removeEventListener('resize', updateContainerWidth);
    }
  }, []);
  return {
    containerWidth,
    updateContainerWidth,
  };
}

export default useContainerSize;
