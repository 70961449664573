import { apiFetchAuthorized } from '../../../../library/helpers/fetch';

const isEmailUnique = (personId, email) => {
  const options = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ personId, email }),
  };

  return new Promise(resolve => {
    apiFetchAuthorized(`/api/member/relationships/invite/unique`, options).then(
      response => {
        if (!response.ok) resolve(false);

        resolve(true);
      }
    );
  });
};

export default (values, dispatch, props, currentFieldName) => {
  const { asyncErrors, personId } = props;

  if (
    currentFieldName === 'email' &&
    props.initialValues &&
    props.initialValues.email !== values.email
  ) {
    if (!personId || !values.email) {
      return new Promise(resolve => resolve(true));
    }

    return isEmailUnique(personId, values.email).then(result => {
      if (!result)
        throw {
          ...asyncErrors,
          email:
            'You already have a care team member with that email in your list',
        };
    });
  }

  return new Promise(resolve => resolve(true));
};
