import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';

const meta = {
  color: '#2AA5A2',
  icon: 'accessibility',
};

const WaterIntakeViewer = ({ data, classes }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="h6">Water Intake (oz)</Typography>
        <Typography variant="body1">{data.quantity}</Typography>
      </Grid>
    </Grid>
  );
};

export default WaterIntakeViewer;
