import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import ImmunizationCard from './ImmunizationCard';
import LabResultCard from './LabResultCard';
import { withRouter } from 'react-router';

function CardContainer(props) {
  const { records, isEmpty, navigation, history } = props;
  const immunizationRoute =
    navigation.dashboard.healthRecord.immunizations.read;
  const labResultRoute = navigation.dashboard.healthRecord.labs;

  const onClickViewAll = (route, _isEmpty = false) => {
    const to = _isEmpty
      ? {
          pathname: route,
          state: { from: 'healthStatus' },
        }
      : route;
    history.push(to);
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ImmunizationCard
          immunization={records.immunization || {}}
          isEmpty={isEmpty || !records.immunization}
          viewRoute={immunizationRoute}
          onClickViewAll={onClickViewAll}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LabResultCard
          labResult={records.labResult || {}}
          isEmpty={isEmpty || !records.labResult}
          viewRoute={labResultRoute}
          onClickViewAll={onClickViewAll}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(CardContainer);
