import React from 'react';
import Grid from '@material-ui/core/Grid';
import DependentListItem from './DependentListItem';

const DependentsList = props => {
  const { dependents, personId, onClickVisible, editDependent } = props;

  const [dependentsList, setDependentsList] = React.useState(
    [...dependents].sort(function(a, b) {
      // Ensure all record selected are first sorted to the top.
      if (a.isSelected > b.isSelected) return -1;
      if (a.isSelected < b.isSelected) return 1;

      // Secondly, ensure all selected records are sorted by name
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;
    })
  );

  const handleOnChange = (index, value) => {
    const newArray = [...dependentsList];
    newArray[index].isSelected = value;
    setDependentsList(newArray);
    onClickVisible(personId, dependentsList[index].personId);
  };

  return (
    <Grid container spacing={2}>
      {dependentsList.map((item, index) => {
        return (
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '16px',
            }}
          >
            <DependentListItem
              data={item}
              index={index}
              onChange={handleOnChange}
              editDependent={editDependent}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DependentsList;
