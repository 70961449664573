import React, { useState, useEffect } from 'react';
import {
  Field,
  reduxForm,
  FieldArray,
  change,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Typography,
  TextField,
  Grid,
  Fade,
  Radio,
  Paper,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Checkbox from './Forms/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from './FragmentDialogBase';
import RadioButtonGroup from '../../RadioButtonGroup';
import { TEMP_FIELD_NAME } from '../JournalEntryDrawer';
import RadioSwitchButton from './Forms/RadioSwitchButton';

import ErrorTooltip from './Forms/ErrorTooltip';
import {
  maxLength,
  required,
  isPositive,
  measurementsValidation,
} from './Validations';
import { normalizeDecimal } from '../../../../library/helpers/format';
const maxLength8 = maxLength(8);
const maxLength4 = maxLength(4);
const maxLength20 = maxLength(20);

export const MEAL_ITEM_INITIAL_VALUES = {
  mealName: '',
  calories: null,
  hasProtein: false,
  hasVegetable: false,
  hasComplexCarbohydrates: false,
  hasSugar: false,
  hasHealthyFat: false,
};

export const MEAL_TRACKING_FIELDS = {
  mealItems: [],
  ateSlowly: false,
  manualCalories: 0,
  manualCaloriesUnit: 'kcal',
  totalCalories: 0,
  totalCaloriesUnit: 'kcal',
};

const useStyles = makeStyles({
  additionalFields: {
    marginTop: '20px',
  },
  header: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    marginTop: '-10px',
  },
  totalCalories: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addMealEntryForm: {
    borderRadius: 4,
    border: '1px solid #0068A8',
    backgroundColor: '#FBFBFB',
    padding: '7px 15px',
    marginBottom: '20px',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  checkBoxFields: {
    maxWidth: 'fit-content',
    textAlign: 'center',
    whiteSpace: 'pre',
    fontSize: '14px',
    color: '#535461',
    marginTop: '-20px',
  },
  ateSlowlyField: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    margin: '5px 0px',
  },
  checkBoxText: {
    fontSize: '13px',
    lineHeight: '1',
  },
  removeField: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14x',
    letterSpacing: '0.15px',
    cursor: 'pointer',
  },
  addField: {
    display: 'flex',
    color: '#0068A8',
    marginLeft: '10px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  radioFields: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  totalCaloriesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  focused: {},
});

const ReduxTextField = props => {
  const {
    input: { onChange, value, onFocus, onBlur },
    meta: { touched, error, active },
    hint,
    style,
    displayErrors = false,
    ...rest
  } = props;
  const hasError = !!(touched && error);
  return (
    <div style={{ minHeight: '80px' }}>
      <ErrorTooltip {...props} displayErrorEndAdornment={false}>
        <TextField
          {...rest}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          fullWidth
          value={value}
          error={hasError}
          helperText={
            active ? '' : hasError && displayErrors ? error : hint ? hint : ''
          }
          FormHelperTextProps={{
            style: {
              fontSize: '11px',
            },
          }}
          style={{
            ...style,
            marginBottom: hasError || hint ? '0px' : '22px',
          }}
        />
      </ErrorTooltip>
    </div>
  );
};

const AddMealEntryForm = ({ fields, onChange, onRemoveClick }) => {
  const classes = useStyles();
  return (
    <div>
      {fields.map((mealItem, index) => (
        <Fade in={true} key={index}>
          <Paper>
            <Grid container spacing={1} className={classes.addMealEntryForm}>
              <Grid item xs={12} className={classes.removeField}>
                <div
                  onClick={() => fields.splice(index, 1)}
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    marginBottom: '-7px',
                  }}
                >
                  <Typography style={{ color: '#979797', fontSize: '13px' }}>
                    Remove
                  </Typography>
                  <RemoveCircleIcon
                    style={{
                      color: '#ED5158',
                      marginLeft: '3px',
                      fontSize: '21px',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`${mealItem}.mealName`}
                  validate={[required]}
                  component={ReduxTextField}
                  hint="Required"
                  props={{
                    variant: 'outlined',
                    placeholder: 'Food Item',
                    fullWidth: true,
                    displayErrors: true,
                    inputProps: {
                      style: {
                        lineHeight: '1.187em',
                        color: 'rgba(0, 104, 168, 0.65)',
                        fontWeight: '500',
                        lineHeight: '24x',
                      },
                    },
                    style: {
                      marginTop: '8px',
                    },
                    margin: 'dense',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name={`${mealItem}.servingSize`}
                  component={ReduxTextField}
                  validate={[isPositive, measurementsValidation]}
                  normalize={value => normalizeDecimal(value)}
                  props={{
                    placeholder: 'Amt',
                    variant: 'outlined',
                    margin: 'dense',
                    inputProps: {
                      inputmode: 'numeric',
                      style: {
                        fontSize: '1rem',
                        paddingLeft: '10px',
                        marginRight: '-10px',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`${mealItem}.servingMeasurement`}
                  component={ReduxTextField}
                  validate={maxLength20}
                  props={{
                    placeholder: 'Measure',
                    variant: 'outlined',
                    margin: 'dense',
                    inputProps: {
                      fontSize: '10px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  inputMode="numeric"
                  name={`${mealItem}.calories`}
                  component={ReduxTextField}
                  normalize={(value, previousValue) => {
                    if (parseFloat(value) > 9999)
                      return parseFloat(previousValue);
                    if (isNaN(parseFloat(value))) return '';
                    return parseFloat(value);
                  }}
                  validate={[maxLength4]}
                  props={{
                    placeholder: '0',
                    margin: 'dense',
                    style: {
                      marginRight: '1px',
                    },
                    inputProps: {
                      style: {
                        color: '#0068A8',
                        textAlign: 'center',
                        padding: '9px 16px',
                        marginLeft: '-9px',
                        marginRight: '-15px',
                      },
                      min: 1,
                      max: 9999,
                    },
                    InputProps: {
                      style: {
                        borderRadius: 4,
                        border: '1px solid #0068A8',
                        backgroundColor: '#EFF1F3',
                      },
                      disableUnderline: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: '13px' }}>Contains:</Typography>
              </Grid>
              <Grid item xs={12} className={classes.checkBoxFieldsContainer}>
                <div className={classes.checkBoxFields}>
                  <Checkbox name={`${mealItem}.hasProtein`} />
                  <Typography className={classes.checkBoxText}>
                    Protein
                  </Typography>
                </div>

                <div className={classes.checkBoxFields}>
                  <Checkbox name={`${mealItem}.hasVegetable`} />
                  <Typography className={classes.checkBoxText}>
                    Vegetable
                  </Typography>
                </div>

                <div className={classes.checkBoxFields}>
                  <Checkbox name={`${mealItem}.hasComplexCarbohydrates`} />
                  <Typography className={classes.checkBoxText}>
                    Complex {'\n'} Carbohydrates
                  </Typography>
                </div>

                <div className={classes.checkBoxFields}>
                  <Checkbox name={`${mealItem}.hasSugar`} />
                  <Typography className={classes.checkBoxText}>
                    Sugar
                  </Typography>
                </div>

                <div className={classes.checkBoxFields}>
                  <Checkbox name={`${mealItem}.hasHealthyFat`} />
                  <Typography className={classes.checkBoxText}>
                    Healthy Fat
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      ))}
      <Grid
        item
        xs={12}
        onClick={() => fields.push({})}
        className={classes.addField}
      >
        <AddCircleIcon style={{ color: '#90B850', marginRight: '8px' }} />{' '}
        <Typography> Add Food </Typography>
      </Grid>
    </div>
  );
};

const RenderCaloriesForm = ({ hint, isDisabled, onChange, onClick }) => (
  <Field
    inputMode="numeric"
    name={`${TEMP_FIELD_NAME}Calories`}
    component={ReduxTextField}
    onChange={onChange}
    onClick={onClick}
    validate={required}
    normalize={(value, previousValue) => {
      if (parseFloat(value) > 9999) return parseFloat(previousValue);
      if (isNaN(parseFloat(value))) return '';
      return parseFloat(value);
    }}
    props={{
      placeholder: '0',
      margin: 'dense',
      style: {
        marginRight: '1px',
      },
      hint: 'Required',
      inputProps: {
        style: {
          color: '#0068A8',
          textAlign: 'center',
          padding: '9px 16px',
          marginLeft: '-9px',
          marginRight: '-10px',
        },
        min: 1,
        max: 9999,
      },
      InputProps: {
        style: {
          borderRadius: 4,
          border: '1px solid #0068A8',
          backgroundColor: '#EFF1F3',
        },
        disableUnderline: true,
      },
    }}
  />
);

const CalculateRadioButtonGroup = props => (
  <RadioButtonGroup options={['Manual', 'Calculate']} {...props} />
);

const calculateItemCalories = items => {
  return items.reduce((a, item) => a + parseInt(item.calories || 0), 0);
};

const MealTrackingDialog = ({ updateField, reduxForm, mealItems }) => {
  const classes = useStyles();

  const [calculationState, setCalculationState] = useState('MANUAL');
  const [isInitialized, setIsInitialized] = useState(false);

  // If the user changes the calculation state, update all values.
  const onRadioChange = toggleValue => {
    if (!reduxForm) return;
    const total = calculateItemCalories(reduxForm.values.mealItems);
    if (toggleValue) {
      updateField('manualCalories', 0);
      updateField(`${TEMP_FIELD_NAME}Calories`, total);
    } else {
      updateField('manualCalories', 0);
      updateField(`${TEMP_FIELD_NAME}Calories`, null);
    }
    setCalculationState(toggleValue ? 'CALCULATE' : 'MANUAL');
  };

  // If the user adds an item, re-calculate total calories
  useEffect(() => {
    if (!reduxForm || !isInitialized) return;
    const total = calculateItemCalories(reduxForm.values.mealItems);

    if (calculationState === 'CALCULATE')
      updateField(`${TEMP_FIELD_NAME}Calories`, total);

    updateField('totalCalories', total);

    // Add unit field
    if (reduxForm.values.mealItems.length > 0) {
      const index = reduxForm.values.mealItems.length - 1;
      const fieldName = `mealItems[${index}]`;

      if (
        reduxForm.values.mealItems[index].calories &&
        !reduxForm.values.mealItems[index].caloriesUnit
      )
        updateField(`${fieldName}.caloriesUnit`, 'kcal');
    }
  }, [mealItems]);

  // Only applies to entries that need updating.
  useEffect(() => {
    if (!reduxForm || isInitialized) return;

    const calcState =
      reduxForm.values.manualCalories === 0 ? 'CALCULATE' : 'MANUAL';

    if (calculationState !== calcState) setCalculationState(calcState);

    updateField(
      `${TEMP_FIELD_NAME}Calories`,
      calcState === 'CALCULATE'
        ? reduxForm.values.totalCalories
        : reduxForm.values.manualCalories
    );

    setIsInitialized(true);
  }, [reduxForm]);

  const handleCaloriesChange = event => {
    const nextManualCalories =
      calculationState === 'MANUAL' ? parseInt(event.target.value) : 0;

    const total = calculateItemCalories(reduxForm.values.mealItems);

    updateField('manualCalories', nextManualCalories);
    updateField('totalCalories', total);
  };

  const handleOnClick = () => {
    if (calculationState === 'CALCULATE') {
      updateField('manualCalories', 0);
      updateField(`${TEMP_FIELD_NAME}Calories`, null);
      setCalculationState('MANUAL');
    }
  };

  return (
    <FragmentDialogBase
      color="#2AA5A2"
      defaultTitle="Meal"
      FormSlot={
        <Grid container spacing={0} className={classes.totalCaloriesContainer}>
          <Grid item xs={4} className={classes.totalCalories}>
            <Typography className={classes.header}>
              Total {'\n'} Calories
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <RenderCaloriesForm
              hint={'Required'}
              isDisabled={calculationState === 'CALCULATE'}
              onChange={handleCaloriesChange}
              onClick={handleOnClick}
            />
          </Grid>
        </Grid>
      }
      FormSlot1={
        <Grid item xs={12}>
          <div className={classes.radioFields}>
            <RadioSwitchButton
              value={calculationState === 'CALCULATE'}
              onChange={onRadioChange}
              options={['Manual', 'Calculate']}
            />
          </div>
        </Grid>
      }
      FormSlot2={
        <Grid container spacing={0} className={classes.additionalFields}>
          <Grid item xs={12} className={classes.ateSlowlyField}>
            <Checkbox name="ateSlowly" component={Checkbox} />
            <Typography> Ate slowly</Typography>
          </Grid>
          <Grid item xs={12}>
            <FieldArray name="mealItems" component={AddMealEntryForm} />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => ({
  reduxForm: state.form['reduxForm'],
  initialValues: {
    ...MEAL_TRACKING_FIELDS,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : {}),
  },
});

let MealTrackingFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(MealTrackingDialog));

const selector = formValueSelector('reduxForm');
MealTrackingFormRedux = connect(state => {
  const mealItems = selector(state, 'mealItems');
  return {
    mealItems,
  };
})(MealTrackingFormRedux);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealTrackingFormRedux);
