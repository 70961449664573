import React, { useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import SwitchField from '../../../../../library/forms/SwitchField';
import { withRouter } from 'react-router';

function DefaultAddress({
  updateField,
  defaultAddressValues,
  shouldRender,
  history,
}) {
  const [addressIsDefaulted, setAddressIsDefaulted] = useState(false);
  const isSignupPage = history.location.pathname === '/d/dependents/signup';
  const onChange = value => {
    setDefaultValues(value);
    setAddressIsDefaulted(value);
  };

  const setDefaultValues = shouldDefault => {
    ['postalCode', 'state', 'street', 'street2', 'city'].forEach(key => {
      updateField(key, shouldDefault ? defaultAddressValues[key] : '');
    });
  };

  return shouldRender || isSignupPage ? (
    <SwitchField
      input={{ onChange: onChange, value: addressIsDefaulted }}
      label="Address same as User."
    />
  ) : (
    ''
  );
}

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) =>
    dispatch(change('new-dependent-form', field, data)),
});

const mapStateToProps = state => ({
  defaultAddressValues: state.app.user.address,
  shouldRender: state.app.user.personId !== state.active.user.personId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefaultAddress));
