import React from 'react';
import { Field, reduxForm, isDirty } from 'redux-form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import InputField from '../../../../library/forms/InputField';
import FieldGroupHeader from '../../../components/Form/FieldGroupHeader';
import asyncValidate from './asyncValidate';
import validate from './ValidateForm';

import HeightFields from '../../../containers/FormFields/HeightFields';
import GeneralFormInfoFields from '../../../containers/FormFields/GeneralFormInfoFields';
import SsnFormField from '../../../containers/FormFields/SsnFormField';
import PhoneFormFields from '../../../containers/FormFields/PhoneFormFields';
import AddressFormFields from '../../../containers/FormFields/AddressFormFields';
import DemographicFormFields from '../../../containers/FormFields/DemographicFormFields';

// eslint-disable-next-line
import styles from '../../../pages/MemberRegistrationPage/MemberRegistrationPage.css';

const dateFormat = 'yyyy-MM-dd';

const AccountInfoForm = props => {
  const {
    handleSubmit,
    contactTypes,
    emailDirty,
    initialValues,
    isDependent,
  } = props;

  React.useEffect(() => {
    const foot =
      initialValues.height === 12
        ? 1
        : Math.floor(parseInt(initialValues.height) / 12);
    const inches =
      initialValues.height === 12 ? 0 : parseInt(initialValues.height) % 12;

    if (foot > 0) {
      props.onChange('init', { foot: foot, inches: inches });
    }
  }, [initialValues.height]);

  const getSsnPart = () =>
    props.ssnPart ? `XXX-XX-${props.ssnPart}` : undefined;

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <FieldGroupHeader headerText="General" />
      <GeneralFormInfoFields
        isDependent={isDependent}
        ssnField={
          <SsnFormField
            placeholder={getSsnPart()}
            required={false}
            shrink={true}
          />
        }
        HeightFields={
          <HeightFields
            onChange={props.onChange}
            error={props.heightFieldErrors}
            initialFeetValue={
              !!(Math.floor(parseInt(initialValues.height) / 12) > 0)
                ? parseInt(initialValues.height) === 12
                  ? 1
                  : Math.floor(initialValues.height / 12)
                : null
            }
            initialInchesValue={
              !!(parseInt(initialValues.height) % 12 > 0)
                ? initialValues.height === 12
                  ? null
                  : parseInt(initialValues.height) % 12
                : null
            }
          />
        }
      />

      <FieldGroupHeader headerText="Address" />
      <AddressFormFields stateTypes={props.stateTypes} />

      <FieldGroupHeader headerText="Contact" />
      <Grid container spacing={3}>
        {!props.isDependent ? (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <Field
                name="email"
                label="Email"
                type="email"
                component={InputField}
              />
            </Grid>

            {emailDirty && (
              <Grid item xs={12} md={6}>
                <Field
                  name="emailConfirm"
                  type="email"
                  label="Confirm Email"
                  component={InputField}
                />
              </Grid>
            )}
          </React.Fragment>
        ) : (
          ''
        )}
      </Grid>
      <PhoneFormFields required={false} contactTypes={contactTypes} />

      <FieldGroupHeader headerText="Demographic" />
      <DemographicFormFields
        genderTypes={props.genderTypes}
        bloodTypes={props.bloodTypes}
        languageTypes={props.languageTypes}
        ethnicityTypes={props.ethnicityTypes}
        religionTypes={props.religionTypes}
        maritalTypes={props.maritalTypes}
        veteranTypes={props.veteranTypes}
      />

      {/* <Grid item xs={12} lg={6}>
                    <Field
                        name="organDonor"
                        label="Organ donor"
                        checkedValue="Y"
                        component={CheckboxField} />
                </Grid> */}

      <Grid container style={{ marginTop: 24 }} justify="flex-end" spacing={3}>
        <Grid item xs={6} sm={2}>
          <Button
            type="button"
            fullWidth={true}
            color="default"
            onClick={props.onCancel}
            size="large"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant="contained"
            type="submit"
            fullWidth={true}
            color="primary"
            size="large"
            disabled={props.isUpdating}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const AccountInfoReduxForm = reduxForm({
  form: 'AccountInfoForm',
  enableReinitialize: true,
  asyncBlurFields: ['email'],
  asyncValidate,
  validate,
  touchOnChange: true,
})(withRouter(AccountInfoForm));

export default connect(state => {
  let { userInfo = {} } = state.member.basicInfo;
  // const sel_filter = (list, value) => list.filter(v => v.shortname == value)[0];

  // const {stateTypes, ethnicityTypes, bloodTypes, languageTypes, religionTypes, maritalTypes, veteranTypes, genderTypes, contactTypes} = state.codes;
  // const stateSel = sel_filter(stateTypes, userInfo.state);
  // const ethnicitySel = sel_filter(ethnicityTypes, userInfo.ethnicity);
  // const bloodTypeSel = sel_filter(bloodTypes, userInfo.bloodType);
  // const languagePrimarySel = sel_filter(languageTypes, userInfo.primaryLanguage);
  // const languageSecondarySel =  sel_filter(languageTypes, userInfo.secondaryLanguage);
  // const religionSel = sel_filter(religionTypes, userInfo.religion);
  // const maritualSel = sel_filter(maritalTypes, userInfo.maritalStatus);
  // const veteranstatusSel = sel_filter(veteranTypes, userInfo.veteranStatus);
  // const genderSel = sel_filter(genderTypes, userInfo.gender);
  // const contactTypeSel = sel_filter(contactTypes, userInfo.phoneType);

  const nullToUndefined = value =>
    value === 'undefined' ||
    value === 'null' ||
    value === undefined ||
    value === null
      ? ''
      : value;

  return {
    ssnPart: userInfo.ssnPart,
    emailDirty: isDirty('AccountInfoForm')(state, ['email']),
    isDependent: Boolean(state.active.user.type === 'dependent'),
    initialValues: {
      personId: userInfo.personId || '',
      firstName: userInfo.firstName || '',
      middleName: userInfo.middleName || '',
      lastName: userInfo.lastName || '',
      suffix: userInfo.suffix || '',

      primaryLanguage: nullToUndefined(userInfo.primaryLanguage),
      secondaryLanguage: nullToUndefined(userInfo.secondaryLanguage),
      bloodType: nullToUndefined(userInfo.bloodType),
      ethnicity: nullToUndefined(userInfo.ethnicity),
      religion: nullToUndefined(userInfo.religion),
      maritalStatus: nullToUndefined(userInfo.maritalStatus),
      veteranStatus: nullToUndefined(userInfo.veteranStatus),

      dob: userInfo.dob ? format(parseISO(userInfo.dob), dateFormat) : '',
      ssn: '',
      phoneNumber: userInfo.phoneNumber || '',
      phoneType: nullToUndefined(userInfo.phoneType),
      email: userInfo.email || '',
      email2: '',
      isDeceased: userInfo.isDeceased || '',
      organDonor: userInfo.organDonor || '',
      gender: userInfo.gender || '',
      street: userInfo.street || '',
      street2: userInfo.street2 || '',
      city: userInfo.city || '',
      state: userInfo.state || '',
      postalCode: userInfo.postalCode || '',

      hairColor: userInfo.hairColor || '',
      eyeColor: userInfo.eyeColor || '',
      occupation: userInfo.occupation || '',
      employer: userInfo.employer || '',

      totalChildren: userInfo.totalChildren || '',
      height: userInfo.height || '',
      isCaregiver: userInfo.isCaregiver || false,
      highestEducationLevel: userInfo.highestEducationLevel || null,
    },
  };
})(AccountInfoReduxForm);
