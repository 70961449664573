import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const JOURNAL_ENTRIES_REQUEST = 'journal/entries/request';
export const JOURNAL_ENTRIES_SUCCESS = 'journal/entries/success';
export const JOURNAL_ENTRIES_FAILURE = 'journal/entries/failure';

export const JOURNAL_ENTRIES_PAGE_REQUEST = 'journal/entries/page/request';
export const JOURNAL_ENTRIES_PAGE_SUCCESS = 'journal/entries/page/success';
export const JOURNAL_ENTRIES_PAGE_FAILURE = 'journal/entries/page/failure';

export const JOURNAL_ENTRIES_SEARCH_REQUEST = 'journal/entries/search/request';
export const JOURNAL_ENTRIES_SEARCH_SUCCESS = 'journal/entries/search/success';
export const JOURNAL_ENTRIES_SEARCH_FAILURE = 'journal/entries/search/failure';

export const JOURNAL_ENTRIES_ATTACH_MEDIA = 'journal/entries/attach-media';

export const JOURNAL_ENTRY_DELETE_REQUEST = 'journal/entry/delete/request';
export const JOURNAL_ENTRY_DELETE_SUCCESS = 'journal/entry/delete/success';
export const JOURNAL_ENTRY_DELETE_FAILURE = 'journal/entry/delete/failure';

export const JOURNAL_ENTRY_SELECT_ID = 'journal/entry/select-id';
export const JOURNAL_ENTRY_CLEAR_ID = 'journal/entry/clear-id';

export const FRAGMENT_TYPE_REQUEST = 'FRAGMENT/type/request';
export const FRAGMENT_TYPE_SUCCESS = 'FRAGMENT/type/success';
export const FRAGMENT_TYPE_FAILURE = 'FRAGMENT/type/failure';

export const CLEAR_FRAGMENTS = 'FRAGMENTS/clear';

export const JOURNAL_CALENDAR_FETCH_REQUEST =
  'journal/entries/calendar/request';
export const JOURNAL_CALENDAR_FETCH_SUCCESS =
  'journal/entries/calendar/success';
export const JOURNAL_CALENDAR_FETCH_ERROR = 'journal/entries/calendar/error';

export const fetchFragmentsByType = (type, params, pid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      FRAGMENT_TYPE_REQUEST,
      FRAGMENT_TYPE_SUCCESS,
      FRAGMENT_TYPE_FAILURE,
    ],
    endpoint: `/api/member/fragment/type/${type}${params}`,
    meta: {
      type,
      config: {
        headers: { 'Content-Type': 'application/json', Pid: pid },
      },
    },
  },
});

export const clearFragments = () => ({
  type: CLEAR_FRAGMENTS,
});

export const setSelectedJournalEntry = jid => ({
  type: JOURNAL_ENTRY_SELECT_ID,
  payload: jid,
});

export const clearSelectedJournalEntry = () => ({
  type: JOURNAL_ENTRY_CLEAR_ID,
});

export const attachMediaToEntry = (journalEntryId, bundle) => ({
  type: JOURNAL_ENTRIES_ATTACH_MEDIA,
  payload: {
    journalEntryId,
    bundle,
  },
});

export const fetchJournalEntries = meta => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      JOURNAL_ENTRIES_REQUEST,
      JOURNAL_ENTRIES_SUCCESS,
      JOURNAL_ENTRIES_FAILURE,
    ],
    endpoint: `/api/member/journal/entries`,
    meta,
  },
});

export const fetchJournalEntriesByCursor = link => {
  const url = new URL(link);
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_ENTRIES_PAGE_REQUEST,
        JOURNAL_ENTRIES_PAGE_SUCCESS,
        JOURNAL_ENTRIES_PAGE_FAILURE,
      ],
      endpoint: url.href.replace(url.origin, ''),
    },
  };
};

export const deleteJournalEntry = (pid, jid, tid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      JOURNAL_ENTRY_DELETE_REQUEST,
      JOURNAL_ENTRY_DELETE_SUCCESS,
      JOURNAL_ENTRY_DELETE_FAILURE,
    ],
    endpoint: `/api/member/journal/entry/${jid}`,
    meta: {
      pid,
      jid,
      tid,
      config: {
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});

export const fetchByDate = (pid, startDate, endDate) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      JOURNAL_CALENDAR_FETCH_REQUEST,
      JOURNAL_CALENDAR_FETCH_SUCCESS,
      JOURNAL_CALENDAR_FETCH_ERROR,
    ],
    endpoint: `/api/member/${pid}/journal/entries/date/${startDate}/${endDate}`,
    meta: {
      pid,
      config: {
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const fetchByQuery = (pid, startDate, endDate, searchTerm) => {
  var endpoint = `/api/member/journal/entries/search?PageSize=100`;
  if (startDate) {
    endpoint += `&StartDate=${new Date(startDate).toISOString()}`;
  }
  if (endDate) {
    endpoint += `&EndDate=${new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).setDate(
        new Date(endDate).getDate() + 1
      )
    ).toISOString()}`;
  }
  if (searchTerm) {
    endpoint += `&Query=${searchTerm}`;
  }
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_ENTRIES_SEARCH_REQUEST,
        JOURNAL_ENTRIES_SEARCH_SUCCESS,
        JOURNAL_ENTRIES_SEARCH_FAILURE,
      ],
      endpoint,
      meta: {
        pid,
        config: {
          method: 'GET',
          mode: 'cors',
        },
      },
    },
  };
};

export const fetchQueryCursor = link => {
  const url = new URL(link);
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        JOURNAL_ENTRIES_SEARCH_REQUEST,
        JOURNAL_ENTRIES_SEARCH_SUCCESS,
        JOURNAL_ENTRIES_SEARCH_FAILURE,
      ],
      endpoint: url.href.replace(url.origin, ''),
    },
  };
};
