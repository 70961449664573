import React from 'react';
import {
  Grid,
  InputAdornment,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from 'redux-form';

import InputField from '../../../library/forms/InputField';
import PasswordField from '../../../library/forms/PasswordField';

const lowercase = value => (value ? value.toLowerCase() : '');

const CredentialFormFields = ({ disabled }) => {
  const [toolTips, setTooltips] = React.useState({
    password: false,
  });

  const openTooltip = name => () => {
    setTooltips({ ...toolTips, [name]: true });
  };

  const closeTooltip = name => () => {
    setTooltips({ ...toolTips, [name]: false });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Field
          name="email"
          label="Email Address"
          type="text"
          hint="Required"
          autoComplete="off"
          disabled={disabled}
          component={InputField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="emailConfirm"
          label="Confirm Email Address"
          type="text"
          hint="Required"
          autoComplete="off"
          disabled={disabled}
          component={InputField}
          onPaste={e => e.preventDefault()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="password"
          label="Password"
          type="password"
          hint="Required"
          autoComplete="off"
          disabled={disabled}
          component={PasswordField}
          helperText={
            <div>
              <b>Required</b> Password must be a length of 6 or greater and
              contain at least one uppercase letter, one lowercase letter, one
              symbol, and one number.
            </div>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="passwordConfirm"
          label="Confirm Password"
          type="password"
          hint="Required"
          autoComplete="off"
          disabled={disabled}
          component={PasswordField}
        />
      </Grid>
    </Grid>
  );
};

export default CredentialFormFields;
