import { required, email, validate } from '../../../library/validate';

export default values => {
  const errors = {};

  errors.email_address = validate(
    required('Required'),
    email('Invalid email address')
  )(values.email_address);

  errors.password = validate(required('Required'))(values.password);

  return errors;
};
