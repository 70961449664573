import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';

import { removeMessage } from '../../../store/reducers/flashMessenger/actions';

import './FlashMessage.css';

class FlashMessage extends Component {

  constructor(props) {
    super(props);

    this.timeoutID = null;
  }

  /**
   * Make sure the message is reset once component is no longer visible
   * and clear timeout if exists.
   */
  componentWillUnmount() {
    this.props.removeMessage();
    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
  }

  /**
   * If a timeout has been provided in the flash message action payload
   * then remove the message by triggering the remove message action.
   */
  removeAfterTimeout = (timeout) => {
    if (this.props.timeout) {
      this.timeoutID = window.setTimeout(this.props.removeMessage, this.props.timeout);
    }
  }
  
  /**
   * If the user hovers over (mouse enter) the message div
   * then pause the message timeout.
   */
  onHoverEnter = () => {
    window.clearTimeout(this.timeoutID);
  }

  /**
   * If there has been a timeout specified, then reactive the
   * timeout when the user mouse pointer leaves the message div.
   */
  onHoverLeave = () => {
    this.removeAfterTimeout();
  }

  render() {

    const { hasMessage, message, removeMessage, type } = this.props;
    
    this.removeAfterTimeout();

    return (
      <div onMouseEnter={ this.onHoverEnter } onMouseLeave={ this.onHoverLeave } >
      { hasMessage && 
        <DashboardMessage onClose={ removeMessage } type={ type }> 
          <div style={{width: '85%'}}>
            { message } 
          </div>
        </DashboardMessage> 
      }
      </div>
    );

  }
}

const mapStateToProps = (state) => ({
  hasMessage: state.flashMessage.hasMessage,
  message: state.flashMessage.message,
  timeout: state.flashMessage.timeout,
  type: state.flashMessage.type
});

const mapDispatchToProps = (dispatch) => ({
  removeMessage: () => dispatch(removeMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);