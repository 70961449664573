import React from 'react';
import { Field } from 'redux-form';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';

class CheckBoxInput extends React.Component {
  render() {
    const { label, input, disableLabel = false } = this.props;
    let name = input.name;

    return (
      <div>
        {!disableLabel && <InputLabel htmlFor={label}> {label} </InputLabel>}
        <FormControl {...input}>
          <Checkbox
            name={name}
            label={label}
            color="default"
            checked={input.value ? true : false}
            onChange={() => input.onChange()}
          />
        </FormControl>
      </div>
    );
  }
}

const CheckBox = props => (
  <Field component={CheckBoxInput} name={props.name} {...props} />
);
export default CheckBox;
