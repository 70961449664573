import React from 'react';

export const WordIcon = ({ width = 63, height = 83 }) => {
  return (
    <div style={{ objectFit: 'contain' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 62 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M36.4768 21.83V0L4.50441 0C2.37825 0 0.667725 1.7175 0.667725 3.85234V78.331C0.667725 80.4658 2.37825 82.1833 4.50441 82.1833H58.218C60.3442 82.1833 62.0547 80.4658 62.0547 78.331V25.6823H40.3135C38.2033 25.6823 36.4768 23.9487 36.4768 21.83ZM45.6049 41.0917H49.4256C50.6565 41.0917 51.5677 42.2313 51.296 43.4512L45.2212 70.4176C45.0294 71.3005 44.2461 71.9104 43.3508 71.9104H37.2761C36.3969 71.9104 35.6295 71.3005 35.4217 70.4497C31.2973 53.8365 32.0966 57.416 31.3292 52.7129H31.2493C31.0735 55.0083 30.8656 55.5059 27.1568 70.4497C26.949 71.3005 26.1817 71.9104 25.3024 71.9104H19.3716C18.4763 71.9104 17.693 71.2844 17.5012 70.4016L11.4584 43.4352C11.1866 42.2313 12.0978 41.0917 13.3288 41.0917H17.2454C18.1566 41.0917 18.9559 41.7337 19.1318 42.6487C21.6256 55.1688 22.345 60.225 22.4889 62.2635C22.7446 60.6263 23.6559 57.0147 27.1888 42.5684C27.3966 41.7016 28.164 41.1077 29.0592 41.1077H33.7112C34.6064 41.1077 35.3737 41.7177 35.5816 42.5845C39.4182 58.7001 40.1856 62.4882 40.3135 63.355C40.2815 61.5573 39.8978 60.4979 43.7665 42.6166C43.9263 41.7177 44.7097 41.0917 45.6049 41.0917V41.0917ZM62.0547 19.5667V20.5458H41.5924V0L42.5675 0C43.5906 0 44.5658 0.401286 45.2852 1.1236L60.9357 16.854C61.655 17.5763 62.0547 18.5555 62.0547 19.5667Z"
            fill="#737373"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect x="0.667603" width="61.3869" height="82.1833" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const PdfIcon = ({ width = 63, height = 83 }) => {
  return (
    <div style={{ objectFit: 'contain' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 61 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <g clip-path="url(#clip1)">
            <path
              d="M29.0766 41.7784C28.2774 39.1683 28.2933 34.1275 28.7569 34.1275C30.0996 34.1275 29.9718 40.1471 29.0766 41.7784ZM28.8049 49.4783C27.574 52.7736 26.0395 56.542 24.2651 59.7067C27.1904 58.5648 30.4993 56.9008 34.3197 56.1341C32.2896 54.568 30.3394 52.3168 28.8049 49.4783ZM13.763 69.8373C13.763 69.9678 15.873 68.9564 19.3418 63.2794C18.2708 64.3071 14.6902 67.2761 13.763 69.8373ZM39.6427 26.1013H61.3822L61.3822 79.609C61.3822 81.7787 59.6718 83.5242 57.5458 83.5242H3.83639C1.71039 83.5242 0 81.7787 0 79.609L0 3.9152C0 1.74552 1.71039 0 3.83639 0L35.8063 0V22.1861C35.8063 24.3395 37.5326 26.1013 39.6427 26.1013ZM38.3639 54.1276C35.1669 52.1374 33.0409 49.3967 31.5383 45.351C32.2576 42.3331 33.3925 37.749 32.5294 34.8779C31.7781 30.0818 25.7517 30.5548 24.8886 33.7686C24.0893 36.7539 24.8246 40.9627 26.1833 46.3298C24.3291 50.8323 21.5957 56.8682 19.6615 60.3266C19.6455 60.3266 19.6455 60.343 19.6295 60.343C15.2976 62.6105 7.86459 67.6024 10.9177 71.436C11.8129 72.5616 13.4753 73.0673 14.3545 73.0673C17.2158 73.0673 20.0611 70.1309 24.1213 62.9857C28.2454 61.5991 32.7691 59.8699 36.7494 59.201C40.2181 61.126 44.2783 62.3821 46.9797 62.3821C51.6474 62.3821 51.9671 57.1619 50.1288 55.3021C47.9069 53.0835 41.449 53.7197 38.3639 54.1276V54.1276ZM60.2632 17.129L44.598 1.14193C43.8787 0.407833 42.9036 0 41.8806 0L40.9215 0V20.881H61.3822V19.8859C61.3822 18.8582 60.9826 17.8631 60.2632 17.129ZM48.4184 58.7769C49.0738 58.3364 48.0188 56.8356 41.5768 57.3087C47.5073 59.8862 48.4184 58.7769 48.4184 58.7769Z"
              fill="#737373"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="61" height="83" fill="white" />
          </clipPath>
          <clipPath id="clip1">
            <rect width="61.3822" height="83.5242" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const ImageIcon = ({ width = 63, height = 83 }) => {
  return (
    <div style={{ objectFit: 'contain' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 63 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M62.0195 19.5805V20.5534H41.3464V0H42.3249C43.3529 5.34383e-06 44.3388 0.405999 45.0657 1.12867L60.8841 16.8554C61.6111 17.5782 62.0195 18.5584 62.0195 19.5805V19.5805ZM40.0543 25.6918C37.9224 25.6918 36.1781 23.9576 36.1781 21.838V0H3.87622C1.73542 0 0 1.72536 0 3.85377V78.36C0 80.4884 1.73542 82.2138 3.87622 82.2138H58.1433C60.2841 82.2138 62.0195 80.4884 62.0195 78.36V25.6918H40.0543ZM18.1771 28.261C22.4587 28.261 25.9295 31.7117 25.9295 35.9685C25.9295 40.2253 22.4587 43.6761 18.1771 43.6761C13.8954 43.6761 10.4246 40.2253 10.4246 35.9685C10.4246 31.7117 13.8956 28.261 18.1771 28.261ZM51.771 66.7987H10.4246L10.5029 59.0133L16.885 52.6682C17.6418 51.9157 18.7906 51.9936 19.5475 52.7461L25.9295 59.0911L42.6481 42.4693C43.405 41.7169 44.6321 41.7169 45.3891 42.4693L51.771 48.8144V66.7987V66.7987Z"
            fill="#737373"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="62.0195" height="82.2138" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const OtherIcon = ({ width = 63, height = 83 }) => {
  return (
    <div style={{ objectFit: 'contain' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 63 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.75 22.3125V0H3.9375C1.75547 0 0 1.75547 0 3.9375V80.0625C0 82.2445 1.75547 84 3.9375 84H59.0625C61.2445 84 63 82.2445 63 80.0625V26.25H40.6875C38.5219 26.25 36.75 24.4781 36.75 22.3125ZM47.25 61.0312C47.25 62.1141 46.3641 63 45.2812 63H17.7188C16.6359 63 15.75 62.1141 15.75 61.0312V59.7188C15.75 58.6359 16.6359 57.75 17.7188 57.75H45.2812C46.3641 57.75 47.25 58.6359 47.25 59.7188V61.0312ZM47.25 50.5312C47.25 51.6141 46.3641 52.5 45.2812 52.5H17.7188C16.6359 52.5 15.75 51.6141 15.75 50.5312V49.2188C15.75 48.1359 16.6359 47.25 17.7188 47.25H45.2812C46.3641 47.25 47.25 48.1359 47.25 49.2188V50.5312ZM47.25 38.7188V40.0312C47.25 41.1141 46.3641 42 45.2812 42H17.7188C16.6359 42 15.75 41.1141 15.75 40.0312V38.7188C15.75 37.6359 16.6359 36.75 17.7188 36.75H45.2812C46.3641 36.75 47.25 37.6359 47.25 38.7188ZM63 19.9992V21H42V0H43.0008C44.0508 0 45.0516 0.410156 45.7898 1.14844L61.8516 17.2266C62.5898 17.9648 63 18.9656 63 19.9992Z"
          fill="#737373"
        />
      </svg>
    </div>
  );
};
