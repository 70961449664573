import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';

const styles = {
  root: {
    marginBottom: 40,
  },
  header: {
    margin: 0,
    marginBottom: 4,
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  subHeader: {
    fontSize: 18,
    color: '#666',
  },
};

const ModalHeader = ({ header, subHeader, classes }) => (
  <Grid className={classes.root}>
    <Typography variant="h5" className={classes.header}>
      {header}
    </Typography>
    <Typography variant="body1" className={classes.subHeader}>
      {subHeader}
    </Typography>
  </Grid>
);

export default withStyles(styles)(ModalHeader);
