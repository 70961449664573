import { defaultDataNormalizer } from '../utils';

const dataFields = {
  weight: '',
  height: null,
  weightUnit: 'lbs',
  heightUnit: 'in',
  bmi: '',

  //bmiWeight: '',
  //bmiWeightUnit: 'kg',
  //bmiHeight: '',
  //bmiHeightUnit: 'm',

  bodyFatPercentage: '',
  musclePercentage: '',
  waterPercentage: '',
  boneMassPercentage: '',

  bodyFatMassWeight: '',
  bodyFatMassWeightUnit: 'lbs',
  skeletalMuscleWeight: '',
  skeletalMuscleWeightUnit: 'lbs',
  waterWeight: '',
  waterWeightUnit: 'lbs',

  waist: '',
  waistUnit: 'in',
  hips: '',
  hipsUnit: 'in',
  chest: '',
  chestUnit: 'in',
  neck: '',
  neckUnit: 'in',
  leftForearm: '',
  leftForearmUnit: 'in',
  rightForearm: '',
  rightForearmUnit: 'in',
  leftBicep: '',
  leftBicepUnit: 'in',
  rightBicep: '',
  rightBicepUnit: 'in',
  leftCalf: '',
  leftCalfUnit: 'in',
  rightCalf: '',
  rightCalfUnit: 'in',
  leftThigh: '',
  leftThighUnit: 'in',
  rightThigh: '',
  rightThighUnit: 'in',
};

const normalizeData = defaultDataNormalizer;

const properties = {
  label: 'Body Metrics',
  color: '#90B850',
  icon: 'accessibility',
  dataFields,
  normalizeData,
};

export { dataFields, properties };
