import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  paper: {
    // padding: '16px',
  },
  statusField: {
    background: '#CECECE',
  },
  statusText: {
    color: '#444447',
    fontWeight: '500',
    fontSize: '20px',
    marginLeft: '8px',
  },
  statusValue: {
    color: '#0068A8',
    fontWeight: '500',
    fontSize: '20px',
  },
  boldText: {
    fontWeight: '500',
    padding: '0px 16px',
    fontSize: '20px',
    color: '#444447',
  },
  container: {
    margin: 0,
    width: '100%',
  },
  header: {
    color: '#fff',
  },
  vielAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    padding: '0px 16px',
    fontSize: '20px',
    color: '#444447',
  },
  cardContent: {
    minHeight: '150px',
    height: '100%',
  },
  fieldColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px',
  },
  headerFieldColumn: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    background: '#0068A8',
  },
});
