// import moment from 'moment'

export default values => {
  const errors = {};

  if (
    values.diagnosisDesc === null ||
    values.diagnosisDesc === undefined ||
    values.diagnosisDesc.trim().length === 0
  ) {
    errors.diagnosisDesc = 'Required';
  }

  return errors;
};
