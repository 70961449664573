import React from 'react';
import DashboardMessage from './DashboardMessage';
import GoalProgress from './GoalProgress';
import DashboardLinkButtons from './DashboardLinkButtons';
import ProgressBar from './ProgressBar';
import Today from './Today';
import AlertsAndInsights from './AlertsAndInsights';
import { Col, Row } from 'react-bootstrap';

import classes from './DashboardContent.css';

class DashboardContent extends React.Component {
  render() {
    return (
      <Col className={classes.dashboardContent}>
        <Row>
          <div className="col-md-8">
            <DashboardMessage>
              <strong>Welcomes to Agrin Health</strong>, did you know you can
              connect your fitness tracking device so we can offer you better
              recommendations? Go ahead and{' '}
              <a href="#">
                <strong>add one</strong>
              </a>
              .
            </DashboardMessage>
            <GoalProgress />
            <DashboardLinkButtons />
          </div>
          <div className="col-md-4">
            {/* <ProgressBar /> */}
            <Today />
            <AlertsAndInsights />
          </div>
        </Row>
      </Col>
    );
  }
}

export default DashboardContent;
