import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  USER_PORTALS_REQUEST,
  USER_PORTALS_SUCCESS,
  USER_PORTALS_FAILURE,
  USER_PORTALS_CREATE,
  USER_PORTALS_CREATE_SUCCESS,
  USER_PORTALS_CREATE_FAILURE,
  USER_PORTALS_UPDATE,
  USER_PORTALS_UPDATE_SUCCESS,
  USER_PORTALS_UPDATE_FAILURE,
  USER_PORTALS_DELETE,
  USER_PORTALS_DELETE_SUCCESS,
  USER_PORTALS_DELETE_FAILURE,
  PORTALS_SELECT_ID,
  PORTALS_CLEAR_ID,
} from './actions';

import { SET_ACTIVE_USER } from '../active/actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';
import ErrorMessage from '../../../shared/containers/MediaObject/ErrorMessage';

const initialState = {
  didInvalidate: true,
  portalList: {},
  listIds: [],
  selectedId: null,
  uiState: LOADING,
};

const sort = sortBy => {
  return (a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] === b[sortBy]) {
      return 0;
    }
  };
};

const reducer = (state = initialState, action) => {
  let portalId = '';

  switch (action.type) {
    case SET_ACTIVE_USER:
      return {
        ...state,
        didInvalidate: true,
      };

    case USER_PORTALS_REQUEST:
      return {
        ...state,
        uiState: LOADING,
        didInvalidate: true,
      };

    case USER_PORTALS_SUCCESS:
      const { payload = [] } = action;
      const listIds = getKeys('portalId', payload);

      return {
        ...state,
        didInvalidate: false,
        // Convert array into hashmap using portalId as the key
        portalList: arrayToHashMap('portalId', payload),
        // Make an index which will be used to find specific items
        // by the portalid in the portal list array.
        listIds,
        lastUpdated: Date.now(),
        uiState: listIds.length ? SUCCESS : EMPTY,
      };

    case USER_PORTALS_FAILURE:
      return {
        ...state,
        portalList: {},
        listIds: [],
        uiState: ERROR,
      };

    case USER_PORTALS_CREATE:
      return {
        ...state,
        didInvalidate: true,
      };

    case USER_PORTALS_CREATE_SUCCESS:
      return {
        ...state,
        didInvalidate: true,
        portal: action.portal,
        uiState: SUCCESS,
      };

    case USER_PORTALS_UPDATE:
      return {
        ...state,
        didInvalidate: false,
      };

    case USER_PORTALS_UPDATE_SUCCESS:
      const portalList = {
        ...state.portalList,
        [state.selectedId]: action.payload,
      };

      return {
        ...state,
        portalList,
        selectedId: null,
      };

    case USER_PORTALS_DELETE_SUCCESS:
      portalId = action.payload.portalId;
      state.listIds = state.listIds.filter(idx => idx !== portalId);
      delete state.portalList[portalId];

      return {
        ...state,
        didInvalidate: true,
        uiState: state.listIds.length ? SUCCESS : EMPTY,
      };

    case PORTALS_SELECT_ID:
      return {
        ...state,
        selectedId: action.payload,
      };

    case PORTALS_CLEAR_ID:
      return {
        ...state,
        selectedId: null,
      };

    default:
      return state;
  }
};

export default reducer;
