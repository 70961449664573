import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField.jsx';

const StressManagementViewer = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={8}>
        <InputField
          label="Activity Type"
          value={data.activityType}
          shrink={true}
          InputProps={{ readOnly: true, disabled: true }}
          enableMinHeight={false}
          multiline
        />
      </Grid>
      <Grid item md={4}>
        <InputField
          label="Duration"
          shrink={true}
          value={`${data.activityDuration} minutes`}
          InputProps={{ readOnly: true, disabled: true }}
          enableMinHeight={false}
        />
      </Grid>
    </Grid>
  );
};

export default StressManagementViewer;
