import React from 'react';
import { makeStyles } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import cn from 'classnames';

const connectorIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    height: 24,
    alignItems: 'center',
  },
  active: {
    color: '#0068A8',
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    // color: 'rgba(0, 0, 0, 0.20)',
    color: 'rgba(0, 0, 0, 0.20)',
    zIndex: 1,
    fontSize: 24,
  },
});

const ConnectorIcon = props => {
  // console.log(props);
  const { active, completed, icon } = props;
  const classes = connectorIconStyles();

  const icons = {
    1: <LibraryBooksIcon />,
    2: <AccountCircleIcon />,
  };

  return (
    <div className={cn(classes.root, { [classes.active]: active })}>
      {active || icon == 2 ? (
        icons[icon]
      ) : (
        <Check className={classes.completed} />
      )}
    </div>
  );
};

export default ConnectorIcon;
