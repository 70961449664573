import React, { useState, useEffect } from 'react';
import Slider from '../../Forms/Slider';
import useStyles from '../useStyles';
import { Typography, Grid } from '@material-ui/core';

const marks = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

export const sleepQualityValues = {
  1: 'Poor',
  2: 'Fair',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

function valueLabelFormat(value) {
  return `${sleepQualityValues[Math.floor(value)]}`;
}

const SliderField = ({ onChange, initialValue }) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Grid container spacing={0} className={classes.sliderContainer}>
      <Grid item xs={12}>
        <Typography className={classes.header}>Sleep Quality</Typography>
      </Grid>
      <Grid item xs={12} className={classes.sliderContainer}>
        <Slider
          marks={marks}
          step={1}
          min={1}
          max={5}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChangeCommitted={handleChange}
          defaultValue={initialValue || 1}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
          track={false}
          valueLabelDisplay="on"
        />
      </Grid>
    </Grid>
  );
};

export default SliderField;
