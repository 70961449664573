import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { objectValues } from '../../helpers/utils';
import { Grid } from '@material-ui/core';

class ItemSelection extends Component {
  /**
   * Class constructor
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      selections: {},
      totalSelected: 0,
    };
  }

  /**
   * Map items passed by props into internal data
   * structure.
   *
   */
  componentDidMount() {
    this.setState((nextState, props) => {
      const { itemKey } = props;

      let totalSelected = 0;

      const selections = {};
      this.props.items.forEach(data => {
        const selected = String(data.selected) === 'true';

        selections[data[itemKey]] = {
          ...data,
          selected,
        };

        if (selected) {
          totalSelected++;
        }
      });

      return {
        totalSelected,
        selections,
      };
    });
  }

  /**
   * Update state when item has been selected, and
   * shared updated state with user defined
   * props.onChange function
   *
   * @param {Event} event on click Event
   * @param {Object} data
   */
  handleOnClick = (event, data) => {
    this.setState((nextState, props) => {
      const { itemKey } = this.props;
      let { totalSelected } = nextState;
      let changeSelections = undefined;

      const selectionsData = {
        ...this.state.selections,
        [data[itemKey]]: {
          ...this.state.selections[data[itemKey]],
          selected: !data.selected,
        },
      };

      if (totalSelected < 0) {
        totalSelected = 0;
      } else {
        totalSelected = !data.selected ? totalSelected + 1 : totalSelected - 1;
      }

      // Apply users changes to selection state is beforeChange prop is provided.
      if (this.props.beforeChange) {
        changeSelections = this.props.beforeChange(
          nextState.selections,
          selectionsData,
          totalSelected
        );
      }

      const selections =
        changeSelections === undefined ? selectionsData : changeSelections;

      // Because there's a possibility for negative numbers, depending on upstream
      // implementation, a full recount of all selected items will need to be factored
      // to ensure that the upstream implementation meets expectations.
      totalSelected = objectValues(selections).filter(x => x.selected).length;

      // Send the UI event, a list of all selections, and last item selected.
      this.props.onChange(
        event,
        objectValues(selections),
        data,
        this.props.name
      );

      return {
        totalSelected,
        selections,
      };
    });
  };

  preventEvent(event) {
    event.stopPropagation();
  }

  /**
   * Render individual items.
   *
   * if a itemComponent has not been defined, then use default renderer.
   *
   * @param {Object} data
   */
  renderItem(data) {
    const { itemComponent, itemKey, itemValue } = this.props;

    const selectedClassName = data.selected ? this.props.selectedClassName : '';

    if (itemComponent) {
      return React.createElement(itemComponent, {
        onClick: event => this.handleOnClick(event, data),
        onChange: this.preventEvent,
        selectedClassName,
        key: data[itemKey],
        data,
      });
    }

    return (
      <div
        key={data[itemKey]}
        className={selectedClassName}
        onClick={event => this.handleOnClick(event, data)}
      >
        <input
          id={data[itemKey]}
          type="checkbox"
          onChange={this.preventEvent} // required to prevent event injection for top level onClick
          checked={data.selected}
        />
        <label>&nbsp;{data[itemValue]}</label>
      </div>
    );
  }

  /**
   * Used to render primary component
   */
  render() {
    const { Tag, children, ...attributes } = this.props;
    const { selections } = this.state;

    // Remove attributes that are not valid HTML attributes
    delete attributes.items;
    delete attributes.itemKey;
    delete attributes.itemValue;
    delete attributes.selectedClassName;
    delete attributes.itemComponent;
    delete attributes.beforeChange;

    return (
      <Grid container spacing={1}>
        {/* <Tag {...attributes} > */}
        {objectValues(selections).map(data => this.renderItem(data))}
        {children}
        {/* </Tag> */}
      </Grid>
    );
  }
}

ItemSelection.propTypes = {
  name: PropTypes.string.isRequired,
  Tag: PropTypes.string.isRequired,
  selectedClassName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
  itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    .isRequired,
  beforeChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

ItemSelection.defaultProps = {
  Tag: 'div',
  itemKey: 'key',
  itemValue: 'value',
};

export default ItemSelection;
