import { DISPATCH_API_AUTHORIZED } from "../../../library/middleware/dispatchApi";

export const INITIALIZE_REQUEST = 'initialize/account/REQUEST';
export const INITIALIZE_SUCCESS = 'initialize/account/SUCCESS';
export const INITIALIZE_FAILURE = 'initialize/account/FAILURE';

export const initializeAccount = (id, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [BLOOD_TYPE_REQUEST, BLOOD_TYPE_SUCCESS, BLOOD_TYPE_FAILURE],
    endpoint: `/api/member/${ id }/profile-progress/initialize`,
    payload: JSON.stringify(payload),
    meta: {
      config: {
        method: 'put',
        headers: { 'Content-Type' : 'application/json' },
        mode: 'cors'
      }
    }
  }
})