import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const REPORTS_JOURNAL_CREATE_REQUEST = 'reports/journal/create_request';
export const REPORTS_JOURNAL_CREATE_SUCCESS = 'reports/journal/create_success';
export const REPORTS_JOURNAL_CREATE_FAILURE = 'reports/journal/create_failure';

export const REPORTS_DOWNLOAD_REQUEST = 'reports/download_request';
export const REPORTS_DOWNLOAD_SUCCESS = 'reports/download_success';
export const REPORTS_DOWNLOAD_FAILURE = 'reports/download_failure';

export const REPORTS_ACTIVE_SET_DOWNLOAD =
  'reports/journal/set_active_download';

export const CLEAR_REPORTS = 'reports/clear_state';

export const cleanUpReportsState = () => ({
  type: CLEAR_REPORTS,
});

export const setActiveDownload = payload => ({
  type: REPORTS_ACTIVE_SET_DOWNLOAD,
  payload,
});

export const createJournalReport = (pid, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      REPORTS_JOURNAL_CREATE_REQUEST,
      REPORTS_JOURNAL_CREATE_SUCCESS,
      REPORTS_JOURNAL_CREATE_FAILURE,
    ],
    endpoint: `/api/member/report/journal`,
    payload,
    meta: {
      pid,
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
        mode: 'cors',
      },
    },
  },
});

export const downloadReport = (pid, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      REPORTS_DOWNLOAD_REQUEST,
      REPORTS_DOWNLOAD_SUCCESS,
      REPORTS_DOWNLOAD_FAILURE,
    ],
    endpoint: `/api/media/${payload.entityId}/download`,
    payload,
    meta: {
      pid,
      responseType: 'blob',
      config: {
        responseType: 'blob',
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});
