import { USER_PROGRESS_REQUEST, USER_PROGRESS_SUCCESS, USER_PROGRESS_FAILURE } from './actions';
import { combineReducers } from 'redux';

const initialState = {

}

const profileProgress = (state = initialState, action) => {
    switch (action.type) {
        case USER_PROGRESS_REQUEST:
            return {
                ...state
            };

        case USER_PROGRESS_SUCCESS:
            const { payload = {} } = action;
            return {
                ...state,
                progress: payload
            }
        default:
            return state;
    }
}

const userProfileProgressReducer = combineReducers({
    profileProgress
})

export default userProfileProgressReducer;