import {
  required,
  minLength,
  maxLength,
  validate,
} from '../../../../library/validate/';

export default values => {
  console.log(values);
  const errors = {};

  // const required = [
  //   'firstName',
  //   'lastName',
  //   'emailAddressConfirm',
  //   'password',
  //   'passwordConfirm',
  // ];

  //creditcardnumber
  //cvc
  //expiration date

  errors.cardName = validate(
    required('Required'),
    minLength(2, 'Must have a minimum length of {{min}} characters'),
    maxLength(
      100,
      'We only store up to {{max}} characters. The first name provided is {{length}} characters long. Please provide a shorter name'
    )
  )(values.cardName);

  return errors;
};
