import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Divider, Collapse } from '@material-ui/core';

import {
  fetchSyncPermissions,
  removeSyncPermissions,
  successfulReauthorization,
} from '../../../store/reducers/syncPermissions/actions';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import LoadingMessage from '../../../library/components/Loading';
import SyncPermissionsHeader from './SyncPermissionsHeader';
import SyncPermissionsEmpty from './Components/Empty/SyncPermissionsEmpty';
import SyncPermissionsGrid from './Components/Success/SyncPermissionsGrid';
import FeatureLockedModal from './FeatureLockedModal';
import BasePage from '../BasePage';

import constants from './constants';

export const status = {
  //state enum
  LOADING: 'loading',
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
  ADD: 'add',
};

const SYNC_AUTHORIZATION = 'SYNC_AUTHORIZATION';

const useTriggerOnMount = (callback, cleanup) => {
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) {
      callback();
      ref.current = true;
    }

    return () => {
      cleanup();
    };
  }, []);
};

const SyncPermissionsPage = props => {
  const {
    hasFetched,
    hasError,
    error,
    hasRecords,
    successMessage,
    list,
    listIds,
  } = props.syncPermissions;

  const { LOADING, EMPTY, SUCCESS, ERROR, ADD } = status;

  const onClickPermissions = (id, type) => {
    console.log(type, constants.TYPE_ADD, constants.TYPE_REAUTH, constants);
    if (type === 'add' || type === 'auth') {
      const _arr = list[id].links.authorize.split('/');

      var tempLink = document.createElement('a');
      tempLink.href = `${window.__env.API_URL}/api/external/auth/authorize/${
        props.personId
      }/${_arr[_arr.length - 1]}`;
      tempLink.setAttribute('target', '_blank');

      function clickHandler() {
        setTimeout(() => {
          tempLink.removeEventListener('click', clickHandler);
          document.body.removeChild(tempLink);
        }, 123);
      }

      tempLink.addEventListener('click', clickHandler, false);

      document.body.appendChild(tempLink);

      tempLink.click();

      // window.localStorage.setItem('isAuthenticating', true);

      window.localStorage.setItem(
        'SYNC_AUTHORIZATION',
        JSON.stringify({
          id,
          operation: type,
          isAuthenticating: true,
        })
      );
    } else {
      const _arr = list[id].links.revoke.split('/');
      props.removeSyncPermissions(_arr[_arr.length - 1], id);
    }
  };

  // Trigger this method with the dispatch 'storage' event is received
  const storageTrigger = () => {
    const storage = JSON.parse(window.localStorage.getItem(SYNC_AUTHORIZATION));

    switch (storage.operation) {
      case constants.TYPE_REAUTH:
        props.successfulReauthorization(storage.id);
        window.localStorage.removeItem(SYNC_AUTHORIZATION);
        break;

      default:
        throw new Error(`Storage operation ${storage.operation} not supported`);
    }

    console.log('FIRED STORAGE!!!', storage);
  };

  // Ensure this biding only runs once.
  // 1. Bind to custom storage event to handle store changes.
  // 2. Remove bound event and the storage item
  useTriggerOnMount(
    function bindStorageListener() {
      window.addEventListener('storage', storageTrigger);
    },
    function cleanupStorage() {
      window.removeEventListener('storage', storageTrigger);
      window.localStorage.removeItem(SYNC_AUTHORIZATION);
    }
  );

  const checkState = (hasFetched, hasRecords, hasError) => {
    if (hasFetched) {
      if (hasRecords) {
        return SUCCESS;
      }
      if (!hasRecords && !hasError) {
        return EMPTY;
      }
      if (!hasRecords && hasError) {
        return ERROR;
      }
    } else return LOADING;
  };

  const [state, setState] = useState(
    checkState(hasFetched, hasRecords, hasError)
  );
  const [featureLockedModalOpen, setFeatureLockedModalOpen] = useState(false);
  const [message, setMessage] = useState({
    hasMessage: false,
    type: null,
    message: '',
  });

  useEffect(() => {
    console.log('this', checkState(hasFetched, hasRecords, hasError));
    setState(checkState(hasFetched, hasRecords, hasError));
  }, [hasFetched, hasRecords, hasError]);

  useEffect(() => {
    if (Boolean(successMessage)) {
      setMessage({
        hasMessage: true,
        type: 'success',
        message: successMessage,
      });
      setTimeout(() => {
        props.clearMessage();
        setMessage({
          hasMessage: false,
          type: null,
          message: '',
        });
      }, 10000);
      return;
    }
    if (Boolean(error)) {
      setMessage({
        hasMessage: true,
        type: 'warning',
        message: error,
      });
      return;
    }
  }, [successMessage, error]);

  useEffect(() => {
    if (Boolean(props.activeUser)) {
      if (message.hasMessage) {
        setMessage({
          hasMessage: false,
          type: null,
          message: '',
        });
      }
      props.fetchSyncPermissions(props.activeUser);
    }
  }, [props.activeUser]);

  const onAddPermissions = () => {
    setState(ADD);
  };

  const handleMessageClose = () => {
    props.clearMessage();
    setMessage({
      hasMessage: false,
      type: null,
      message: '',
    });
  };

  const onClickBack = () => {
    if (hasRecords) {
      setState(SUCCESS);
    } else setState(EMPTY);
  };

  const renderViews = () => {
    switch (state) {
      case LOADING:
        return <LoadingMessage message="Loading Sync Permissions" />;
      case EMPTY:
        return <SyncPermissionsEmpty onClick={onAddPermissions} />;
      case SUCCESS:
      case ADD:
        const _ids = listIds.filter(item =>
          state === ADD ? !list[item].synced : list[item].synced
        );
        if (!_ids.length) {
          setState(EMPTY);
          return '';
        }
        return (
          <SyncPermissionsGrid
            list={list}
            listIds={_ids}
            onClick={onClickPermissions}
            isBrowsing={state === ADD}
          />
        );
      case ERROR:
        return <div></div>;
    }
  };

  return (
    <BasePage>
      <Grid item xs={12}>
        <SyncPermissionsHeader
          onClick={onAddPermissions}
          isBrowsing={state === ADD}
          isEmpty={state === EMPTY}
          onClickBack={onClickBack}
          disableButton={props.allSynced}
        />
        <Divider />
        <Collapse in={message.hasMessage} timeout={150}>
          <div style={{ width: '100%', maxWidth: '600px', marginTop: '8px' }}>
            <DashboardMessage
              onClose={handleMessageClose}
              canClose={true}
              type={message.type || 'success'}
            >
              {message.message}
            </DashboardMessage>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        {renderViews()}
      </Grid>
      <FeatureLockedModal
        open={featureLockedModalOpen}
        onClose={() => setFeatureLockedModalOpen(false)}
      />
    </BasePage>
  );
};

const mapStateToProps = state => ({
  syncPermissions: state.syncPermissions,
  activeUser: state.active.user,
  personId: state.active.user.personId,
  allSynced: Boolean(
    state.syncPermissions.listIds.length &&
      !Boolean(
        state.syncPermissions.listIds.filter(
          item => !state.syncPermissions.list[item].synced
        ).length
      )
  ),
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage()),
  clearUpdates: () => dispatch(clearUpdates()),
  removeSyncPermissions: (sid, id) => dispatch(removeSyncPermissions(sid, id)),
  fetchSyncPermissions: id => dispatch(fetchSyncPermissions(id)),
  successfulReauthorization: id => dispatch(successfulReauthorization(id)),
});

export default {
  component: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SyncPermissionsPage)
  ),
};
