import React from 'react';
import { Grid } from '@material-ui/core';

const ModalFooter = ({ rightCol, leftCol }) => {
  return (
    <Grid container justify="space-between" alignItems="center" spacing={3}>
      <Grid container item spacing={3} xs={12} sm={4}>
        {leftCol}
      </Grid>
      <Grid container item justify="flex-end" spacing={3} xs={12} sm={8}>
        {rightCol}
      </Grid>
    </Grid>
  );
};

export default ModalFooter;
