import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import Select, { components, createFilter } from 'react-select';
import AsyncSelect from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import NoSsr from '@material-ui/core/NoSsr';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HoverTooltip from './HoverTooltip';
import { ignoreCase } from '../helpers/string';

const DEFAULT = 'default';
const TAGS = 'tags';

// #region Styles
const styles = theme => ({
  formControl: {
    // minHeight: theme.spacing(10),
    marginTop: theme.spacing(1),
  },
  input: {
    paddingTop: '6.5px',
    paddingBottom: '6.5px',
    height: 'auto',
  },
  label: {
    letterSpacing: 'normal',
    marginTop: '10px',
    '&$focusedLabel': {
      marginTop: '0px',
    },
  },
  selectLabelAdjustment: {
    transform: 'translate(16px, 16px)'
  },
  focusedLabel: {},
  erroredLabel: {},
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    flex: 1,
    fontSize: 16,
    width: '100%',
    resize: 'none',
    font: 'inherit',
    padding: 0,
    cursor: 'inherit',
    boxSizing: 'border-box',
    lineHeight: 'inherit',
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
  multiValue: {
    flexWrap: 'wrap',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  menu: {
    background: '#fff',
    position: 'absolute',
    marginTop: theme.spacing(1),
    zIndex: 999,
    left: 0,
    right: 0,
  },
  menuItem: {
    background: '#fff',
  },
  option: {
    background: '#fff',
    '&:hover': {
      background: 'blue'
    }
  },
  errorIndicatorDropdown: {
    color: theme.palette.error.main,
  },
  errorIndicatorSeparator: {
    backgroundColor: theme.palette.error.main,
  },
});

// #region Control
const NewControl = ({InputProps, ...props}) => {

  return (
    <TextField 
      fullWidth
      classes={props.selectProps.classes}
      onBlur={props.selectProps.onBlur}
      onFocus={props.selectProps.onFocus}
      onClick={props.selectProps.onClick}
      onChange={props.selectProps?.onChange ?? props.selectProps.onInputChange}
      {...props.selectProps.textFieldProps}
      InputLabelProps={{
        shrink: Boolean(props.hasValue) || Boolean(props.isFocused) || Boolean(props.selectProps.placeholder),
        classes: {
          root: props.selectProps.classes.selectLabelAdjustment
        }
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          ...props,
          className: props.selectProps.classes.input,
        },
        endAdornment:
          InputProps && InputProps.endAdornment ? (
            InputProps.endAdornment
          ) : props.selectProps.HoverTooltipMsg ? (
            <HoverTooltip text={props.selectProps.HoverTooltipMsg} />
          ) : null,
      }}
      FormHelperTextProps={{
        error: props.error,
      }}
      focused={props.isFocused} />
  )
}

function inputComponent({ classes, inputRef, ...props }) {
  return (
    <components.Control ref={inputRef} {...props}>
      {props.children}
    </components.Control>
  );
}

// #region Menu
const Menu = (props) => {
  const {
    innerProps,
    selectProps: { classes },
    children,
  } = props;

  return (
    <Paper square elevation={8} className={classes.menu}>
      <components.Menu {...props}>{children}</components.Menu>
    </Paper>
  );
};

// #region Option
const Option = (props) => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
    >
      <components.Option {...props} />
    </MenuItem>
  )
};

// #region ValueContainer
const ValueContainer = ({ children, ...rest }) => {
  return (
    <components.ValueContainer {...rest}>{children}</components.ValueContainer>
  );
};

// #region DropdownIndicator
const DropdownIndicator = ({
  selectProps: { classes, IconComponent, isDisabled, fieldType },
}) => {
  const style = isDisabled
    ? {
        opacity: '0.6',
        margin: '4px',
      }
    : { margin: '4px' };
  return fieldType === TAGS ? (
    <div style={style}>
      <AddCircleIcon style={{ color: '#90B850' }} />
    </div>
  ) : IconComponent ? (
    <div style={style}>
      {' '}
      <IconComponent />{' '}
    </div>
  ) : (
    <div style={style}>
      {' '}
      <KeyboardArrowDownIcon />{' '}
    </div>
  );
};

const SingleValue = props => {
  const {
    selectProps,
    children,
  } = props;

  React.useEffect(() => {
    if (!Boolean(selectProps.textFieldProps.value?.hasOwnProperty(selectProps.valueKey))) {
      props.clearValue();
    }
  }, [selectProps.textFieldProps.value]);

  return (
    <div onClick={props.onFocus}>
      <components.SingleValue {...props}>{children}</components.SingleValue>
    </div>
  );
};

const MultiValue = props => {
  const {
    isFocused,
    selectProps: { classes },
    children,
  } = props;

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(classes.chip, {
        [classes.chipFocused]: isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
};

const Placeholder = ({children, ...props}) => {
  return <components.Placeholder {...props}>{children}
  </components.Placeholder>;
};

// #region NoOptionsMessage
const NoOptionsMessage = ({
  innerProps,
  selectProps: { classes },
  children,
}) => {
  return (
    <Typography className={classes.noOptionsMessage} {...innerProps}>
      {children}
    </Typography>
  );
};

// #region Select Engine
function useSelectEngine(input, options, labelKey, valueKey) {

  const handleSelectValue = (value) => {
    var normalizedValue = normalizeValue(value);
    if (!normalizedValue || !localOptions || localOptions.length === 0)
      return normalizedValue;

    var foundValue = localOptions.find(x => x[valueKey] === normalizedValue[valueKey]);
    return foundValue || normalizedValue
  }

  const [selectedValue, setSelectedValue] = useState(handleSelectValue(input.value));
  const [localOptions, setLocalOptions] = useState(normalizeOptions(options));

  useEffect(() => {
    var _options = options || [];
    setLocalOptions(state => addOptionsThatDontExists(state, _options));
  }, [options]);

  useEffect(() => {
    setSelectedValue(handleSelectValue(input.value));
    setLocalOptions(state => addOptionsThatDontExists(state, input.value));
  }, [input.value]);

  const addOptionsThatDontExists = (oldOptions, newOptions) => {
    if (newOptions.length === 0) 
      return oldOptions;

    // On initial load the input is an array which has a single value. Normalize options
    const firstItem = newOptions[0] || newOptions;
    const hasExpectedProperties = firstItem.hasOwnProperty(valueKey) && firstItem.hasOwnProperty(getOptionLabelKeyName());
    if (!hasExpectedProperties) return oldOptions;

    const finalOptions = [...oldOptions];
    const newOptionsArray = Array.isArray(newOptions) ? newOptions : [newOptions];
    const normalizeNewOptions = normalizeOptions(newOptionsArray);
  
    for (let newOption of normalizeNewOptions) {
      let newOptionLabel = getOptionLabel(newOption);
      let found = oldOptions.find(x => getOptionLabel(x) === newOptionLabel);
      if (!found) finalOptions.push(newOption);
    }

    return finalOptions;
  };

  function normalizeOptions(options) {
    if (!options || options.length === 0 || labelKey !== valueKey) 
      return options || [];

    const _labelKey = getOptionLabelKeyName();

    return options.map(option => {
      if (option.hasOwnProperty(_labelKey)) 
        return option;

      return {
        ...option,
        [_labelKey]: option[labelKey]
      }
    })
  }

  function getOptionLabelKeyName() {
    return labelKey === valueKey ? 'placeholder_label' : labelKey;
  }

  function normalizeValue(value) {
    if (!value) return;

    const _labelKey = getOptionLabelKeyName();

    if (Array.isArray(value)) {
      return normalizeOptions(value);
    }

    if (value.constructor === Object) {
      return {
        ...value,
        [_labelKey]: value[labelKey]
      };
    } 
  
    return createOption(value); 
  }

  function createOption(input) {
    const _labelKey = getOptionLabelKeyName();
    return {
      [_labelKey]: input,
      [valueKey]: input,
      isNew: true,
    };
  };

  const getNewOptionData = (inputValue, optionLabel) => {
    const _labelKey = getOptionLabelKeyName();
    return {
      [_labelKey]: optionLabel,
      [valueKey]: inputValue,
      __isNew__: true,
    }
  };

  const handleCreateOption = input => {
    setLocalOptions(state => [].concat(state, createOption(input)));
  };

  const getOptionLabel = option => option && option[getOptionLabelKeyName()];
  const getOptionValue = option => option && option[valueKey];

  const compareOption = (inputValue = '', option) => {
    const candidate = String(inputValue)
      .trim()
      .toLowerCase();

    const optionLabel = String(getOptionLabel(option))
      .trim()
      .toLowerCase();

    // const optionValue = String(getOptionValue(option))
    //   .trim()
    //   .toLowerCase();

    return ( 
      candidate === optionLabel 
      // || candidate === optionValue
    );
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    return !(
      !inputValue ||
      selectValue.some(option => compareOption(inputValue, option)) ||
      selectOptions.some(option => compareOption(inputValue, option))
    );
  };

  return {
    selectedValue,
    setSelectedValue: (value) => setSelectedValue(handleSelectValue(value)),
    options: localOptions,
    setOptions: setLocalOptions,
    createOption: handleCreateOption,
    getNewOptionData,
    isValidNewOption,
    getOptionLabel,
    getOptionValue,
    getOptionLabelKeyName
  };
}

const getSelectComponent = (isAsync, isCreatable) => {
  if (isAsync && isCreatable) return AsyncCreatableSelect;
  if (isAsync) return AsyncSelect;
  if (isCreatable) return CreatableSelect;

  return Select;
};

/**
 * Does this work?
 *
 * @param {import("react-select").Props} props
 * @returns
 */
// #region Select Component
const BaseSelect = (props) => {

  const {
    input,
    theme,
    placeholder = "",
    meta: { touched, error },
    labelKey,
    valueKey,
    onClick,
    label,
    hint,
    shrink,
    disabled,
    textFieldProps,
    isAsync,
    isCreatable,
    isPreventingDuplicates,
    isAppendingNewOptions,
    isValueLabel = false,
    enableMinHeight,
    ...rest
  } = props;

  const {
    options,
    selectedValue,
    setSelectedValue,
    createOption,
    getNewOptionData,
    isValidNewOption,
    getOptionLabel,
    getOptionValue,
    getOptionLabelKeyName,
  } = useSelectEngine(input, rest.options, labelKey, valueKey);

  const hasError = !!(touched && error);
  const shouldShrink = shrink || input.value !== '';

  const handleBlur = event => {
    if (rest.disableBlur) return;
    const currentValue = getOptionValue(selectedValue);
    const nextValue = rest.isMulti ? selectedValue : currentValue;
    input.onFocus?.(nextValue);
    rest.onFocus?.(nextValue);
  };

  const handleFocus = event => {
    input.onFocus?.(event);
  };

  const handleOnClick = event => {
    if (onClick) {
      onClick();
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = (option, actionMeta) => {
    const currentValue = getOptionValue(actionMeta.option ?? option);
    const nextValue = rest.isMulti ? option : currentValue;

    switch (actionMeta?.action) {
      case 'create-option':
        createOption(currentValue);
        break;
    }
    
    setSelectedValue(option);

    input.onChange?.(nextValue);
    rest.onChang?.(nextValue);
  };

  const Component = getSelectComponent(isAsync, isCreatable);

  const selectStyles = {
    control: base => ({
      background: '#FFF',
      display: 'flex',
      flex: 1,
    }),
    input: base => ({
      display: "flex",
      flex: 1,
      height: "auto",
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
      padding: 0,
      margin: 0,
    }),
    menu: () => ({}),
    option: () => ({ width: '100%' }),
    label: () => ({}),
    placeholder: (base) => ({...base}),
    singleValue: (base) => ({
      display: 'flex',
      flex: 1
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      padding: '0 2px',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: hasError ? theme.palette.error.main : base.color,
    }),
    indicatorSeparator: base => ({
      ...base,
      background: hasError ? theme.palette.error.main : base.background,
      marginLeft: 8,
      marginRight: 8,
    }),
  };

  return (
    <NoSsr>
      <div
        className={classNames({
          [rest.classes.formControl]: true,
        })}
      >
      <Component
        {...rest}
        styles={selectStyles}
        value={selectedValue}
        labelKey={getOptionLabelKeyName()}
        valueKey={valueKey}
        options={options}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getNewOptionData={getNewOptionData}
        isValidNewOption={isValidNewOption}
        filterOption={createFilter({ ignoreCase: true, ignoreAccents: true, trim: true, matchFrom: 'start' })}
        textFieldProps={{
          ...textFieldProps,
          disabled: disabled,
          label: label,
          error: hasError,
          helperText: hasError ? error : hint ? hint : ' ',
          InputLabelProps: {
            shrink: shouldShrink,
            margin: 'dense',
          },
          variant: 'outlined',
          value: selectedValue,
        }}
        components={{
          Control: NewControl,
          Menu,
          Option,
          ValueContainer,
          DropdownIndicator,
          Placeholder,
          SingleValue,
          MultiValue,
          NoOptionsMessage,
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={handleOnClick}
        onChange={handleChange}
      />
      </div>
    </NoSsr>
  );
};

const SelectField = withStyles(styles, { withTheme: true })(BaseSelect);
SelectField.displayName = 'SelectField';

export { SelectField };
export default SelectField;
