import React from 'react';
import PageHeader from '../../components/PageHeader';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ChartsHeader = ({
  data = {},
  selectedChart,
  handleChange,
  isLoading,
  isEmpty,
  state,
}) => {
  const matches = useMediaQuery('(min-width:600px)');
  const renderMenuItems = () => {
    return Object.values(data)
      .map((fragment, index) => {
        const { fragmentType, name, data } = fragment;
        return {
          fragmentType,
          name,
          data,
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .reduce((acc, element) => {
        if (selectedChart == element.fragmentType) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, [])
      .map(({ fragmentType, name, index, data }) => {
        return (
          <MenuItem key={`${name}-${index}`} value={fragmentType.toString()}>
            {name}
          </MenuItem>
        );
      });
  };

  return (
    <PageHeader
      headerText="My Charts"
      backButtonText="Go Back"
      xs={{
        right: matches ? 4 : 12,
        left: matches ? 8 : 12,
      }}
      rightSlot={
        isLoading || isEmpty ? (
          ''
        ) : (
          <div
            style={{
              height: '100%',
              margin: '0px',
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              marginTop: '-8px',
            }}
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Charts</InputLabel>
              <Select
                value={selectedChart}
                onChange={handleChange}
                label="Charts"
              >
                {renderMenuItems()}
              </Select>
            </FormControl>
          </div>
        )
      }
    />
  );
};

export default ChartsHeader;
