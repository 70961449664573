import React from 'react';
import useDataPoints from '../useDataPoints';
import LineChart from '../../LineChart';
import styles from '../styles.css';
import { DATERANGE } from '../../ChartWrapper';
import { format, isWithinInterval } from 'date-fns';
import Tooltip from '../Tooltip';

const formatByDay = e => `${e.getMonth() + 1}/${e.getDate()}`;
const formatByMonthName = e => format(e, 'MMMM');
const formatByMonthShort = e => format(e, 'MMM');

const WaterLineChart = props => {
  const { dateExtent, tickValues, dateRange, hasData, mode } = props;
  const shouldDisplayAverage = [
    DATERANGE.ONE_YEAR,
    DATERANGE.SIX_MONTHS,
  ].includes(dateRange);
  const data = shouldDisplayAverage ? props.data : props.coordinates;
  const { upperLimit, lowerLimit } = useDataPoints(
    data.filter(item =>
      isWithinInterval(item.date, { start: dateExtent[0], end: dateExtent[1] })
    ),
    [shouldDisplayAverage ? 'averageWaterIntake' : 'quantity'],
    dateExtent
  );

  const frameProps = {
    lines: [{ coordinates: data }],
    xAccessor: 'date',
    yAccessor: shouldDisplayAverage ? 'averageWaterIntake' : 'quantity',
    xExtent: dateExtent,
    yExtent: [lowerLimit, upperLimit],
    axes: [
      { orient: 'left', className: styles.axes, baseline: false, label: 'oz' },
      {
        orient: 'bottom',
        tickFormat: function(e) {
          if (
            dateRange === DATERANGE.ONE_WEEK ||
            dateRange === DATERANGE.ONE_MONTH
          ) {
            return formatByDay(e);
          }
          if (
            dateRange === DATERANGE.THREE_MONTHS ||
            dateRange === DATERANGE.SIX_MONTHS
          ) {
            return formatByMonthName(e);
          }
          return formatByMonthShort(e);
        },
        className: styles.axes,
        baseline: false,
        tickValues,
      },
    ],
    hoverAnnotation: true,
    tooltipContent: d => {
      return (
        <Tooltip
          d={d}
          color="#ffffff"
          title="Water Intake"
          keys={[
            {
              tag: shouldDisplayAverage ? 'Weekly Average' : 'Net',
              unit: d.data.unit,
              value: shouldDisplayAverage
                ? d.data.averageWaterIntake
                : d.data.quantity,
            },
          ]}
        />
      );
    },
  };
  return <LineChart mode={mode} hasData={hasData} frameProps={frameProps} />;
};

export default WaterLineChart;
