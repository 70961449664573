import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import classnames from 'classnames';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import defaultImageSrc from '../../../client/images/default-profile-pic.png';
import { setActiveUser } from '../../../store/reducers/active/actions';
import { fetchPersonInfo } from '../../../store/reducers/person/actions';
// FIX THIS only for demo
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import { attachMediaToEntry } from '../../../store/reducers/journalEntries/actions';
import {
  getGlobalNotifications,
  getPersonNotifications,
} from '../../../store/reducers/notifications/actions';
import { onCloseResourceLibrarySnackbar } from '../../../store/reducers/resourceLibrary/actions';
import {
  clearJournalEntry,
  createJournalEntry,
  updateFragment,
  JOURNAL_COMPOSITION_CREATE_FAILURE,
  JOURNAL_COMPOSITION_CREATE_SUCCESS,
  openJournalModal,
  JOURNAL_MODAL_CREATE,
  updateJournalEntry,
  JOURNAL_COMPOSITION_UPDATE_SUCCESS,
  JOURNAL_COMPOSITION_UPDATE_FAILURE,
  JOURNAL_MODAL_EDIT,
  JOURNAL_MODAL_COPY,
} from '../../../store/reducers/journalmodal/actions';
import {
  clearFiles,
  setFiles,
  uploadFile,
} from '../../../store/reducers/mediaObject';
import { updateMedia } from '../../../store/reducers/mediaObjectDrawer/actions';
import {
  unblockNavigation,
  updateAvatar,
} from '../../../store/reducers/ui/actions';
import DashboardHeader from '../../components/DashboardHeader';
import DashboardMemberNav from '../../components/DashboardMemberNav';
import DashboardSidebar from '../../components/DashboardSidebar';
import DetectInactivity from '../../components/DetectInactivity';
import InactivityModal from '../../components/InactivityModal';
import JournalEntryDrawer from '../../components/JournalCompose/JournalEntryDrawer';
import ProgressBar from '../../containers/ProgressBar';
import {
  hideProgressBar,
  setProgressBar,
} from '../../containers/ProgressBar/actions';
import { logoutUser } from '../../containers/SignInFormMember/actions';
import './DashboardPage.css';

import ReportContainer from '../../components/Reports/ReportContainer';

import { parseDate } from '../../../library/utils/date';
import UseScrollHOC from './UseScrollHOC';
import { toggleDocumentsDrawer } from '../../../store/reducers/documents/actions';
import { cancelAddMedia } from '../../../store/reducers/mediaObjectDrawer/actions';
import MediaObjectDrawer from '../../components/DocumentsDrawer/';
import UseErrorSnackbarHOC from './UseErrorSnackbarHOC';

const pingServerDuration = 1000 * 60 * 8;
const warnInactivityDuration = 1000 * 60 * 8;
const idleInactivityDuration = 1000 * 60 * 10;

const isoDateFormat = "yyyy-MM-dd'T'HH:mm:ssXXX";

const navBranchMvc = mod => (userSlug, controller) => {
  let base = '';
  if (mod === null) {
    base = `/d/${userSlug}/${controller}`;
  } else {
    base = `/d/${userSlug}/${mod}/${controller}`;
  }

  return {
    read: base,
    create: `${base}/add`,
    update: `${base}/edit`,
    delete: `${base}/delete`,
  };
};

const prepareNavigation = userSlug => {
  const healthRecordNav = navBranchMvc('health-record');
  const accountNav = navBranchMvc('account');
  const portalNav = navBranchMvc(null);

  return {
    dashboard: {
      home: `/d/${userSlug}/home`,
      careTeam: `/d/${userSlug}/care-team`,
      journal: `/d/${userSlug}/journal`,
      resourceLibrary: `/d/${userSlug}/resource-library`,
      charts: `/d/${userSlug}/charts`,
      history: `/d/${userSlug}/history`,
      healthStatus: `/d/${userSlug}/health-status`,
      healthRecord: {
        self: `/d/${userSlug}/health-record`,
        allergies: healthRecordNav(userSlug, 'allergy'),
        familyHistory: `/d/${userSlug}/health-record/family-history`,
        immunizations: healthRecordNav(userSlug, 'immunization'),
        mvs: healthRecordNav(userSlug, 'mvs'),
        // Question: is portals a function of account?
        portals: portalNav(userSlug, 'portals'),
        surgicalHistory: healthRecordNav(userSlug, 'surgical-history'),
        conditions: healthRecordNav(userSlug, 'conditions-diagnoses'),
        documents: `/d/${userSlug}/health-record/documents`,
        labs: `/d/${userSlug}/health-record/labs`,
      },
      account: {
        self: `/d/${userSlug}/account`,
        information: `/d/${userSlug}/account/info`,
        insurance: accountNav(userSlug, 'insurance'),
        emergencyContact: accountNav(userSlug, 'emergency-contact'),
        security: `/d/${userSlug}/account/security`,
        userAgreements: `/d/${userSlug}/account/userAgreement`,
        accountManagement: `/d/${userSlug}/account/account-management`,
        syncPermissions: `/d/${userSlug}/sync-permissions`,
      },
    },
  };
};

const styles = theme => ({
  pageContentWrapper: {
    width: '100%',
    paddingTop: '50px',
    background: '#fff',
    minHeight: '100vh',
    paddingLeft: '220px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  drawerOpen: {
    transform: 'translateX(220px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerClose: {
    transform: 'translateX(0px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  sidebarWrapper: {
    width: '220px',
    height: '100%',
    overflowY: 'auto',
  },
  sidebarBack: {
    width: '220px',
    top: '55px',
    [theme.breakpoints.down('sm')]: {
      top: '0px',
    },
    left: '0px',
    bottom: '0',
    position: 'fixed',
    boxShadow: 'inset -2px 2px 3px #ddd',
    backgroundPosition: 'center 95%',
    backgroundImage: 'url(/src/client/images/aside-tree.jpg)',
    backgroundColor: 'rgb(247,247,247)',
  },
});

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showScrollToTop: false,
      renderScrollToTop: false,
      resetActiveUser: false,
      warnOfInactivity: false,
      displayInactivityModal: false,
      drawerDisplay: false,
      reportsModal: {
        open: false,
      },
    };

    this.debouncedUserIsActive = debounce(
      this.userIsActive,
      pingServerDuration
    );
    this.toProfile = this.toProfile.bind(this);
  }

  componentDidMount() {
    // Check to see if a token object exist, and if an access token is present.
    // If both are NULL, then the user isn't signed in.
    // The user will be redirected to the login screen.
    if (!this.props.isAuthenticated) {
      this.props.history.push('/');
    }

    // The default user is set to 'me', however, it's possible for the page to be loaded
    // pointing to a dependent. When need to ensure the context for this user is selected
    // overriding the default user.
    const matches =
      this.props.location.pathname.match(/\/d\/([\w\d-]+)\//) || null;
    const userSlug = matches ? matches[1] : 'me';
    const nextUser = this.props.dependents.tabs.find(x => x.slug === userSlug);
    this.props.setActiveUser(nextUser);
    this.props.fetchPersonInfo();

    window.addEventListener('mousemove', this.debouncedUserIsActive);
    window.addEventListener('click', this.debouncedUserIsActive);
    window.addEventListener('keypress', this.debouncedUserIsActive);
    window.addEventListener('scroll', this.debouncedUserIsActive);
    if (
      this.props.history.location.pathname === `/d/${nextUser.slug}/journal` ||
      (this.props.history.location.pathname ===
        `/d/${nextUser.slug}/resource-library` &&
        !this.state.renderScrollToTop)
    ) {
      this.setState({
        ...this.state,
        renderScrollToTop: false,
      });
    }

    console.log('Notifications are loaded here...');

    this.props.getGlobalNotifications();
    this.props.getPersonNotifications();

    console.log('This is where SignalR socket connection is registered');
  }

  componentDidUnmount() {
    console.log('The dashboard page is unmounting');
  }

  static getDerivedStateFromProps(props, state) {
    const personChanged = slug => props.location.pathname.search(slug) >= 0;
    const genericRoutes = location =>
      location.pathname.startsWith('/d/dependents') ||
      location.pathname.startsWith('/d/account') ||
      location.pathname.startsWith('/d/help');

    if (
      !genericRoutes(props.location) &&
      !personChanged(props.active.user.slug) &&
      !state.resetActiveUser
    ) {
      return {
        resetActiveUser: true,
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.resetActiveUser) {
      // The default user is set to 'me', however, it's possible for the page to be loaded
      // pointing to a dependent. When need to ensure the context for this user is selected
      // overriding the default user.
      const matches =
        this.props.location.pathname.match(/\/d\/([\w\d-]+)\//) || null;
      const userSlug = matches ? matches[1] : 'me';
      const nextUser = this.props.dependents.tabs.find(
        x => x.slug === userSlug
      );
      if (nextUser.slug !== this.props.active.user.slug) {
        this.props.setActiveUser(nextUser);
        this.props.fetchPersonInfo();

        this.setState({ resetActiveUser: false });
      }
    }

    const _shoulRenderScrollToTop =
      this.props.history.location.pathname ===
        `/d/${this.props.active.user.slug}/journal` ||
      this.props.history.location.pathname ===
        `/d/${this.props.active.user.slug}/resource-library`;

    if (_shoulRenderScrollToTop && !this.state.renderScrollToTop) {
      this.setState({
        ...this.state,
        renderScrollToTop: true,
      });
    }

    if (!_shoulRenderScrollToTop && this.state.renderScrollToTop) {
      this.setState({
        ...this.state,
        renderScrollToTop: false,
      });
    }
  }

  /**
   * Remove all event listeners that detect if the user
   * is no longer inactive
   */
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.debouncedUserIsActive);
    window.removeEventListener('click', this.debouncedUserIsActive);
    window.removeEventListener('keypress', this.debouncedUserIsActive);
    window.removeEventListener('scroll', this.debouncedUserIsActive);

    window.removeEventListener('mousemove', this.hideInactivityWarn);
    window.removeEventListener('click', this.hideInactivityWarn);
    window.removeEventListener('keypress', this.hideInactivityWarn);
    window.removeEventListener('scroll', this.hideInactivityWarn);
    window.removeEventListener('scroll', this.hideInactivityWarn);
  }

  handleSubmitAvatar = async state => {
    const { personId, uploadFile, updateAvatar, clearFiles } = this.props;

    const sx = state.image.naturalWidth / state.image.width;
    const sy = state.image.naturalHeight / state.image.height;

    const bundle = {
      appArea: 'avatar',
      file: state.file,
      description: 'Profile picture',
      loaded: 0,
      total: state.image.size,
      isUploading: false,
      isUploaded: false,
      ...state.imagePxCrop,
      x: Math.floor(state.imagePxCrop.x * sx),
      y: Math.floor(state.imagePxCrop.y * sy),
      width: Math.floor(state.imagePxCrop.width * sx),
      height: Math.floor(state.imagePxCrop.height * sy),
    };

    var result = await uploadFile(personId, bundle);
    if (result.status === 201) {
      updateAvatar(result.data.links.self);
      clearFiles('avatar');
    }
  };

  userIsActive = () => {
    console.log('Simulation: sending ping to server to reset timeout on JWT');
  };

  /**
   * Log out the user and flash a good bye message.
   */

  doLogoutUser = () => {
    const {
      history,
      createMessage,
      logoutMember,
      unblockNavigation,
    } = this.props;

    unblockNavigation();
    createMessage('You have been successfully signed out!', 'info', 5000);
    logoutMember(history);
  };

  /**
   * User is no longer inactive. Remove all events that detect if the user is
   * active, and hide the inactivity modal.
   */
  hideInactivityWarn = () => {
    window.removeEventListener('mousemove', this.hideInactivityWarn);
    window.removeEventListener('click', this.hideInactivityWarn);
    window.removeEventListener('keypress', this.hideInactivityWarn);
    window.removeEventListener('scroll', this.hideInactivityWarn);

    this.setState({
      warnOfInactivity: false,
      displayInactivityModal: false,
    });
  };

  /**
   * User has been detected as inactive. Setup event listeners that will
   * detect if the user is inactive and display the inactivity modal.
   */
  handleInactivityWarn = () => {
    console.log('Warning, you will be logged out due to inactivity');

    window.addEventListener('mousemove', this.hideInactivityWarn);
    window.addEventListener('click', this.hideInactivityWarn);
    window.addEventListener('keypress', this.hideInactivityWarn);
    window.addEventListener('scroll', this.hideInactivityWarn);

    this.setState({
      warnOfInactivity: true,
      displayInactivityModal: true,
    });
  };

  /**
   * The user is considered idle. Flash message explaining why the user is logged out
   * and terminate their session.
   */
  handleInactivityIdle = () => {
    const {
      history,
      createMessage,
      logoutMember,
      unblockNavigation,
    } = this.props;

    unblockNavigation();
    createMessage(
      'For your security and protection we logged you out due to inactivity. Please login again.',
      'warning'
    );
    logoutMember(history);
  };

  toProfile() {
    this.props.history.push('/d/me/home');
  }

  openJournalDrawer = (
    open,
    modalState = JOURNAL_MODAL_CREATE,
    saveUpdate = true
  ) => () => {
    const { reduxForm, journalCompose, updateFragment } = this.props;
    // close journal before updating fragment data to prevent slide in view from displaying
    // null fragmentData view before closing
    this.props.clearFiles('journal');
    this.props.openJournalModal(open, { modalState });
    if (
      !open &&
      saveUpdate &&
      reduxForm &&
      reduxForm.reduxForm &&
      reduxForm.reduxForm.values
    )
      updateFragment(
        {
          ...journalCompose.fragments[0],
          fragmentData: { ...reduxForm.reduxForm.values },
        },
        0
      );
  };

  cancelJournalEntry = event => {
    this.openJournalDrawer(false, null, false)(event);
  };

  submitEditJournalEntry = async state => {
    const {
      journalEntry,
      updateJournalEntry,
      active,
      journalCompose,
      mediaObject,
    } = this.props;

    const coords = journalEntry.geoLocation.coords;

    const personId =
      state.user && state.user.value ? state.user.value : active.user.personId;

    const payload = {
      journalEntryId: journalEntry.journalEntryId,
      personId,
      type: 'GENERAL',
      enteredOn: journalCompose.datetime
        ? format(journalCompose.datetime, isoDateFormat)
        : format(parseDate(state.datetime), isoDateFormat),
      title: state.title,
      body: state.body,
      tags: state.tags,
      longitude: coords ? coords.longitude : 0,
      latitude: coords ? coords.latitude : 0,
      accuracy: coords ? coords.accuracy : 0,
      heading: coords ? coords.heading : 0,
      altitude: coords ? coords.altitude : 0,
      altitudeAccuracy: coords ? coords.altitudeAccuracy : 0,
      speed: coords ? coords.speed : 0,
      fragments: state.fragments,
    };

    const result = await updateJournalEntry(
      personId,
      personId === active.user.personId, // isCurrentPerson
      payload,
      mediaObject.bundles
    );

    if (result.type === JOURNAL_COMPOSITION_UPDATE_SUCCESS) {
      if (
        mediaObject.bundleToAttach &&
        mediaObject.bundleToAttach.length !== 0
      ) {
        this.props.attachMediaToEntry(
          result.payload.journalEntryId,
          mediaObject.bundleToAttach
        );
      }
      this.openJournalDrawer(false)();
    } else if (result.type === JOURNAL_COMPOSITION_UPDATE_FAILURE) {
      console.log('Failed to update journal entry', result);
    }

    console.log('Journal entry updated', state, payload);
  };

  submitJournalEntry = async state => {
    const {
      journalEntry,
      user,
      createJournalEntry,
      updateJournalEntry,
      active,
      fragments,
      reduxForm,
      mediaObject,
    } = this.props;

    if (journalEntry.modalState === JOURNAL_MODAL_EDIT) {
      await this.submitEditJournalEntry(state);
    } else if (journalEntry.modalState === JOURNAL_MODAL_COPY) {
      await this.submitCopyJournalEntry(state);
    } else {
      const coords = journalEntry.geoLocation.coords;
      const defaultDate = format(new Date(), isoDateFormat);

      const personId =
        state.user && state.user.value
          ? state.user.value
          : active.user.personId;

      const payload = {
        personId,
        type: 'GENERAL',
        enteredOn: journalEntry.datetime
          ? format(parseDate(state.datetime), isoDateFormat)
          : defaultDate,
        title: journalEntry.title,
        body: journalEntry.body,
        tags: journalEntry.tags,
        longitude: coords ? coords.longitude : 0,
        latitude: coords ? coords.latitude : 0,
        accuracy: coords ? coords.accuracy : 0,
        heading: coords ? coords.heading : 0,
        altitude: coords ? coords.altitude : 0,
        altitudeAccuracy: coords ? coords.altitudeAccuracy : 0,
        speed: coords ? coords.speed : 0,
        fragments: state.fragments,
        isCurrentPerson: personId === active.user.personId,
        // TODO: Evaluate if this code is better then the previous solution.
        // fragments: journalEntry.fragments.map(fragment => ({
        //   ...fragment,
        //   fragmentData: fragment.fragmentData,
        // })),
      };

      const result = await createJournalEntry(
        personId,
        personId === active.user.personId, // isCurrentPerson
        payload,
        mediaObject.bundles
      );
      if (result.type === JOURNAL_COMPOSITION_CREATE_SUCCESS) {
        if (
          this.props.mediaObject.bundleToAttach &&
          this.props.mediaObject.bundleToAttach.length !== 0
        ) {
          this.props.attachMediaToEntry(
            result.payload.journalEntryId,
            this.props.mediaObject.bundleToAttach
          );
        }

        this.openJournalDrawer(false)();
      } else if (result.type === JOURNAL_COMPOSITION_CREATE_FAILURE) {
        console.log('Failed to created journal entry', result);
      }

      console.log('Journal entry submitted', state, payload);
    }
  };

  submitCopyJournalEntry = async state => {
    const {
      journalEntry,
      journalCompose,
      user,
      createJournalEntry,
      updateJournalEntry,
      active,
      fragments,
      reduxForm,
      mediaObject,
    } = this.props;

    const coords = journalEntry.geoLocation.coords;
    const defaultDate = format(new Date(), isoDateFormat);

    const personId =
      state.user && state.user.value ? state.user.value : active.user.personId;

    const payload = {
      journalEntryId: journalEntry.journalEntryId,
      personId,
      type: 'GENERAL',
      enteredOn: journalCompose.datetime
        ? format(parseDate(state.datetime), isoDateFormat)
        : defaultDate,
      title: journalEntry.title,
      body: journalEntry.body,
      tags: journalEntry.tags,
      longitude: coords ? coords.longitude : 0,
      latitude: coords ? coords.latitude : 0,
      accuracy: coords ? coords.accuracy : 0,
      heading: coords ? coords.heading : 0,
      altitude: coords ? coords.altitude : 0,
      altitudeAccuracy: coords ? coords.altitudeAccuracy : 0,
      speed: coords ? coords.speed : 0,
      fragments: state.fragments,
      isCurrentPerson: personId === active.user.personId,
      mediaObjectIds: journalCompose.mediaObjectIds,
    };

    const result = await createJournalEntry(
      personId,
      personId === active.user.personId, // isCurrentPerson
      payload,
      mediaObject.bundles
    );
    if (result.type === JOURNAL_COMPOSITION_CREATE_SUCCESS) {
      const bundlesToAttach = [];
      if (
        this.props.mediaObject.bundleToAttach &&
        this.props.mediaObject.bundleToAttach.length !== 0
      ) {
        this.props.mediaObject.bundleToAttach.forEach(item =>
          bundlesToAttach.push(item)
        );
      }

      if (bundlesToAttach.length > 0) {
        this.props.attachMediaToEntry(
          result.payload.journalEntryId,
          bundlesToAttach
        );
      }
      this.openJournalDrawer(false)();
    } else if (result.type === JOURNAL_COMPOSITION_CREATE_FAILURE) {
      console.log('Failed to create journal entry', result);
    }

    console.log('Journal entry submitted', state, payload);
  };

  handleDocumentsDrawerClose = () => {
    this.props.cancelAddMedia();
    this.props.toggleDocumentsDrawer(false);
  };

  drawerToggle = () =>
    this.setState({ drawerDisplay: !this.state.drawerDisplay });

  toggleReportsModal = toggle => () => {
    this.setState({ ...this.state, reportsModal: { open: toggle } });
  };

  DashboardHOC = ({ children }) =>
    this.state.renderScrollToTop ? (
      <UseScrollHOC>{children}</UseScrollHOC>
    ) : Boolean(this.props.resourceLibrary.error) ? (
      <UseErrorSnackbarHOC
        error={this.props.resourceLibrary.error}
        open={Boolean(this.props.resourceLibrary.error)}
        onClose={this.props.onCloseResourceLibrarySnackbar}
      >
        {children}
      </UseErrorSnackbarHOC>
    ) : (
      children
    );

  render() {
    const {
      user,
      emailAddress,
      avatarUrl,
      avatarPlaceholder,
      active,
      journalEntry,
      classes,
      personId,
      isDocumentsDrawerOpen,
    } = this.props;
    const navigation = prepareNavigation(active.user.slug);

    const avatarPlaceholderDefault =
      avatarPlaceholder && avatarPlaceholder.length > 0
        ? avatarPlaceholder
        : defaultImageSrc;

    const fullName = `${user.firstName} ${user.lastName}`;
    return (
      <React.Fragment>
        <this.DashboardHOC>
          <ReportContainer
            open={this.state.reportsModal.open}
            toggleOpen={this.toggleReportsModal}
          />
          <div
            className={classnames({
              [classes.drawerOpen]: this.state.drawerDisplay,
              [classes.drawerClose]: !this.state.drawerDisplay,
            })}
          >
            <DetectInactivity
              onWarn={this.handleInactivityWarn}
              onIdle={this.handleInactivityIdle}
              warnDuration={warnInactivityDuration}
              idleDuration={idleInactivityDuration}
            />

            {this.state.warnOfInactivity && (
              <InactivityModal
                open={this.state.displayInactivityModal}
                onHide={this.hideInactivityWarn}
                duration="02:00"
              />
            )}

            <DashboardHeader
              toProfile={this.toProfile}
              memberName={fullName || ''}
              emailAddress={emailAddress || ''}
              drawerToggle={this.drawerToggle}
              drawerDisplay={this.state.drawerDisplay}
              logoutMemberDone={this.doLogoutUser}
              reportsToggle={this.toggleReportsModal}
              navigation={navigation}
              isHome={
                this.props.history.location.pathname ===
                `/d/${this.props.active.user.slug}/home`
              }
            />

            {active.user.type === 'primary' ? <ProgressBar /> : ''}

            {/*Desktop View */}
            <nav>
              <Hidden smDown>
                <div>
                  <div className={classes.sidebarWrapper}>
                    <div className={classes.sidebarBack}>
                      <DashboardSidebar
                        personId={user.personId}
                        memberName={fullName || ''}
                        avatarImageSrc={avatarUrl}
                        defaultAvatarImageSrc={avatarPlaceholderDefault}
                        defaultImageSrc={avatarUrl}
                        toProfile={this.toProfile}
                        onSubmitAvatar={this.handleSubmitAvatar}
                        navigation={navigation}
                        reportModalOpen={this.state.reportsModal.open}
                        toggleReportModal={this.toggleReportsModal}
                      />
                    </div>
                  </div>
                </div>
              </Hidden>

              {/*Small device View */}
              <Hidden mdUp>
                <Drawer
                  variant="temporary"
                  open={this.state.drawerDisplay}
                  onClose={this.drawerToggle}
                >
                  <div className={classes.sidebarWrapper}>
                    <div className={classes.sidebarBack}>
                      <DashboardSidebar
                        personId={user.personId}
                        memberName={fullName || ''}
                        avatarImageSrc={avatarUrl}
                        defaultAvatarImageSrc={avatarPlaceholderDefault}
                        defaultImageSrc={avatarUrl}
                        toProfile={this.toProfile}
                        onSubmitAvatar={this.handleSubmitAvatar}
                        drawerToggle={this.drawerToggle}
                        navigation={navigation}
                        reportModalOpen={this.state.reportsModal.open}
                        toggleReportModal={this.toggleReportsModal}
                      />
                    </div>
                  </div>
                </Drawer>
              </Hidden>
            </nav>

            <div className={classes.pageContentWrapper}>
              <DashboardMemberNav
                memberName={fullName || ''}
                toProfile={this.toProfile}
              />
              <Grid container>
                <Grid item sm={12} md={12} style={{ width: '100%' }}>
                  {renderRoutes(this.props.route.routes, { navigation })}
                  {this.props.children}
                </Grid>
              </Grid>
            </div>
            <JournalEntryDrawer
              open={journalEntry.isJournalModalOpened}
              // onChange={ (data, action) => {

              //     console.log('[Journal Message]', data, action);

              // }}
              onClose={this.openJournalDrawer(false)}
              onCancel={this.cancelJournalEntry}
              onSubmit={this.submitJournalEntry}
              isSubmitting={journalEntry.isSubmitting}
            />
            <MediaObjectDrawer
              areaType="DOCUMENTS"
              disablePhotosButton={true}
              open={isDocumentsDrawerOpen}
              onClose={this.handleDocumentsDrawerClose}
              isAddingDocument={true}
            />
          </div>
          {/*<div style={{ position: 'fixed', right: 20, bottom: 20 }}>
          <Fab
            onClick={this.openJournalDrawer(true, JOURNAL_MODAL_CREATE)}
            color="secondary"
          >
            <EditIcon />
                </Fab>
            </div>
                */}
        </this.DashboardHOC>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.app.user,
  active: state.active,
  dependents: state.dependents,
  personId: state.active.user.personId,
  avatarUrl: state.member.login.details.avatarUrl,
  avatarPlaceholder: state.app.user.avatarPlaceholder,
  isAuthenticated: state.member.login.isAuthenticated,
  emailAddress: state.member.login.details.unique_name,
  journalCompose: state.journalCompose,
  fragments: state.journalCompose.fragments,
  journalEntry: state.journalCompose,
  mediaObject: state.mediaObject,
  reduxForm: state.form,
  isDocumentsDrawerOpen: state.documents.isDrawerOpen,
  resourceLibrary: state.resourceLibrary,
});

const mapDispatchToProps = dispatch => ({
  setActiveUser: user => dispatch(setActiveUser(user)),
  fetchPersonInfo: () => dispatch(fetchPersonInfo()),
  setProgressBar: (value, total) => dispatch(setProgressBar(value, total)),
  hideProgressBar: () => dispatch(hideProgressBar()),
  logoutMember: history => dispatch(logoutUser(history)),
  createMessage: (msg, type, timeout) =>
    dispatch(createMessage(msg, type, timeout)),
  setFiles: files => dispatch(setFiles(files)),
  uploadFile: (pid, bundle) => dispatch(uploadFile(pid, bundle, 'avatar')),
  clearFiles: appArea => dispatch(clearFiles(appArea)),
  updateAvatar: path => dispatch(updateAvatar(path)),
  unblockNavigation: () => dispatch(unblockNavigation()),
  openJournalModal: (bool, payload) =>
    dispatch(openJournalModal(bool, payload)),
  updateFragment: (payload, index) => dispatch(updateFragment(payload, index)),
  createJournalEntry: (pid, isCurrentPerson, payload, bundles) =>
    dispatch(createJournalEntry(pid, isCurrentPerson, payload, bundles)),
  updateJournalEntry: (pid, isCurrentPerson, payload, bundles) =>
    dispatch(updateJournalEntry(pid, isCurrentPerson, payload, bundles)),
  clearJournalEntry: () => dispatch(clearJournalEntry()),
  attachMediaToEntry: (journalId, bundle) =>
    dispatch(attachMediaToEntry(journalId, bundle)),
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  uploadFiles: (personId, objectId, bundles, area, meta) =>
    dispatch(uploadFile(personId, objectId, bundles, area, meta)),
  cancelAddMedia: () => dispatch(cancelAddMedia()),
  toggleDocumentsDrawer: bool => dispatch(toggleDocumentsDrawer(bool)),
  getGlobalNotifications: () => dispatch(getGlobalNotifications()),
  getPersonNotifications: () => dispatch(getPersonNotifications()),
  onCloseResourceLibrarySnackbar: () =>
    dispatch(onCloseResourceLibrarySnackbar()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(DashboardPage))),
};
