import React from 'react'
import classNames from 'classnames'
import { Icon, withStyles, Grid, Tooltip, Button } from '@material-ui/core'

const styles = {
  card: {
    borderRadius: 4,
    border: '1px solid #CECECE',
    padding: 22,
    width: '100%',
    cursor: 'pointer',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#EFF1F3'
    }
  },
  activeCard: {
    borderRadius: 4,
    padding: 22,
    width: '100%',
    cursor: 'pointer',
    border: '1px solid #fff'
  },
  icon: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 30
  },
  label: {
    textAlign: 'center',
    marginTop: 8
  },
  subLabel: {
    color: '#AEAEAE',
    fontSize: 12,
    marginTop: 1,
    textAlign: 'center'
  },
  disabled: {
    background: '#eee !important',
    color: '#999 !important',
    cursor: 'not-allowed'
  }
}

const noop = () => {};

const RoleButton = ({ data, classes, setRole, active }) => {
  const activeColor = active ? { color: '#fff' } : {}
  const activeBgColor = active ? { background: data.color } : {}
  
  const ButtonComponent = props => (
      <div
        {...props}
        className={classNames(classes.card, {
          [classes.activeCard]: active && !data.disabled,
          [classes.disabled]: data.disabled
        })}
        style={activeBgColor}
      >
        <div className={classes.icon}>
          <Icon style={activeColor} fontSize="inherit">
            {data.icon}
          </Icon>
        </div>
        <div style={activeColor} className={classes.label}>
          {data.label}
        </div>
        {data.subLabel && (
          <div style={activeColor} className={classes.subLabel}>
            {data.subLabel}
          </div>
        )}
      </div>
  )
  
  return (
    <Grid
      container
      item
      xs={6}
      justify="center"
      alignItems="center"
      onClick={data.disabled ? noop : () => setRole(data)}
    >
      { data.disabled ? (
        <Tooltip title="Coming soon">
          <ButtonComponent />
        </Tooltip>
      ) : (
        <ButtonComponent />
      )}
    </Grid>
  )
}
export default withStyles(styles)(RoleButton)
