import React from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = {
  root: {
    padding: '25px 50px',
    // backgroundColor: '#eee',
    borderRadius: 6,
  },
  column: {
    flex: 0.48,
  },
  header: {
    fontSize: 32,
  },
  text: {
    lineHeight: '150%',
    marginBottom: 25,
  },
};

const NoConditions = ({ classes, baseRoutePath, onClickAddCondition }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.column}>
        <h2 className={classes.header}>Start your Conditions List</h2>

        <p className={classes.text}>
          Capturing your Conditions & Diagnoses helps your Care Team tailor your
          Care Plan to be more effective. It can also aid your physician in a
          faster, more accurate diagnosis for any issues you may have in the
          future.
        </p>

        <Button
          style={{ color: '#fff' }}
          // component={Link}
          // to={`${baseRoutePath}/add`}
          aria-label="Add new condition/diagnosis"
          color="primary"
          variant="contained"
          onClick={onClickAddCondition}
        >
          Add Condition
        </Button>
      </div>
      <div className={classes.column}>
        <img
          src={require('../../../client/svgs/care-team-default.svg')}
          alt="Conditions Default"
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(NoConditions);
