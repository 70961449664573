import React from 'react';
import { Paper, Typography, Grid, Container } from '@material-ui/core';

const EmptyComponent = ({ type }) => {
  return (
    <Paper
      style={{
        maxWidth: '700px',
        width: '100%',
        padding: '16px',
        borderRadius: '4px',
        background: '#0068A8',
        margin: '80px auto',
      }}
    >
      {' '}
      <Container
        maxWidth="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '25px',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
            }}
          >
            {type === 'empty' ? (
              <Typography variant="body1" style={{ fontWeight: 200 }}>
                No Chart Data found. Add records to{' '}
                <span style={{ fontWeight: 400 }}>Journal</span> to start
                tracking
              </Typography>
            ) : (
              <Typography variant="body1">
                {' '}
                Select a Chart Type to begin{' '}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default EmptyComponent;
