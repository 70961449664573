import React from 'react';
import { Grid, Container, Paper, Typography } from '@material-ui/core';
import DropzoneComponent from './DropzoneComponent';

const DocumentsEmpty = props => {
  return (
    <Paper style={{ background: '#f5f5f5' }}>
      <Container
        maxWidth="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '25px',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {props.title && (
              <Typography
                align="center"
                variant="h5"
                style={{ padding: '25px', fontWeight: '500' }}
              >
                {props.title}
              </Typography>
            )}
            {props.subtitle && (
              <Typography
                align="center"
                variant="body1"
                style={{ whiteSpace: 'unset' }}
              >
                {props.subtitle}
              </Typography>
            )}
          </Grid>
          {props.action && (
            <Grid item xs={12}>
              <Typography align="center" variant="body1">
                {props.action}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <DropzoneComponent onDrop={props.onDrop} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default DocumentsEmpty;
