import {
    ACCOUNT_PASSWORD_REQUEST,
    ACCOUNT_PASSWORD_SUCCESS,
    ACCOUNT_PASSWORD_FAILURE
} from "./actions";

const initialState = {
    isUpdating: false
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case ACCOUNT_PASSWORD_REQUEST:
            return {
                ...state,
                isUpdating: true
            }
        case ACCOUNT_PASSWORD_SUCCESS:
            return {
                ...state,
                isUpdating: false
            }
        case ACCOUNT_PASSWORD_FAILURE:
            return {
                ...state,
                isUpdating: false
            }
        default:
            return state;

    }
}

export default reducer;