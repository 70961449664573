import React from 'react';
import { StepConnector, withStyles } from '@material-ui/core';

const Connector = withStyles({
  // active: {
  //   '& $line': {
  //     borderColor: '#0068A8',
  //   },
  // },
  // completed: {
  //   '& $line': {
  //     borderColor: '#eee',
  //   },
  // },
  line: {
    borderColor: '#eee',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export default Connector;
