import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, withStyles, Typography, Fab } from '@material-ui/core';
import InfiniteScroll from './InfiniteScroll';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons';

// import Notifications from '../../components/Notifications/Notifications';
import data from '../../components/Notifications/testData';
import {
  NotificationEvent,
  NotificationMessage,
} from '../../components/Notifications/Event';

const styles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '10px',
  },
  container: {
    fontFamily: 'Roboto',
    fontSize: '30px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
    borderTop: '1px solid #CECECE',
  },
  notification: {},
  Header: {
    marginLeft: '5.2%',
    fontSize: '20px',
  },
  progress: {},
  progressContainer: {
    maxHeight: '80px',
    margin: '0 auto',
  },
  scrollTopContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    bottom: '20px',
    position: 'sticky',
    left: '100%',
  },
  endText: { fontWeight: '500', fontSize: '10.5px', marginTop: '10px' },
  scrollTopButton: { right: '90px' },
};

const renderMessageType = (data, readNotification) => {
  const onNotificationClick = id => {
    readNotification(id);
  };
  if (data && data.type) {
    switch (data.type) {
      case 'alert':
      case 'activity':
      case 'info':
      case 'report':
        return (
          <NotificationEvent
            data={data}
            key={data.id}
            fromNotificationsHistory={true}
            onNotificationClick={onNotificationClick}
          />
        );

      case 'message':
      case 'general':
        return (
          <NotificationMessage
            data={data}
            key={data.id}
            fromNotificationsHistory={true}
          />
        );

      default:
        return 'NOT SUPPORTED';
    }
  }
};

const NotificationsHistoryPage = props => {
  const { classes, list, listIds, ...rest } = props;
  const localNotifications = { ...list };
  const containerRef = useRef();

  useEffect(() => {
    if (listIds.length == 0) setListItems([]);
  });

  const [listItems, setListItems] = useState(listIds.slice(0, 10));
  const [isFetching, setIsFetching] = InfiniteScroll(fetchMoreListItems);
  const [notificationCount, setNotificationCount] = useState(10);

  function fetchMoreListItems() {
    setTimeout(() => {
      if (notificationCount == listIds.length) setIsFetching(false);

      if (notificationCount < 10 && notificationCount > 0) {
        setNotificationCount(listIds.length);
        setListItems(listIds.slice(0, notificationCount));
        setIsFetching(false);
      }

      if (notificationCount >= 10) {
        setNotificationCount(notificationCount + 10);
        setListItems(listIds.slice(0, notificationCount));
        setIsFetching(false);
      }
    }, 1000);
  }

  const handleArrowClick = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <Grid
        ref={containerRef}
        container
        direction="row"
        alignItems="flex-start"
        className={classes.root}
      >
        <h2 className={classes.Header}>Your Notifications History</h2>
        <div className={classes.container} ref={containerRef}>
          {listItems.map(id =>
            renderMessageType(localNotifications[id], props.readNotification)
          )}
        </div>

        <div className={classes.progressContainer}>
          {isFetching && listIds.length > notificationCount && (
            <CircularProgress
              disableShrink
              className={classes.progress}
              color="primary"
            />
          )}
          {notificationCount >= listIds.length && (
            <Typography
              variant="body2"
              gutterBottom
              className={classes.endText}
            >
              You do not have any {listItems.length > 0 && 'more'} notifications
              at this time. Please check back again soon!
            </Typography>
          )}
        </div>
      </Grid>
      {document.documentElement.scrollTop >= 250 && (
        <div className={classes.scrollTopContainer}>
          <Fab className={classes.scrollTopButton} onClick={handleArrowClick}>
            <ArrowUpwardIcon color="primary" aria-label="scrollToTop" />
          </Fab>
        </div>
      )}
    </React.Fragment>
  );
};

NotificationsHistoryPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  action: state.ui.entity.action,
  list: state.notification.person.list,
  listIds: state.notification.person.listIds,
  hasNotification: state.notification.person.hasNotification,
});

const mapDispatchToProps = dispatch => ({
  readNotification: id => dispatch(readNotification(id)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(withStyles(styles)(NotificationsHistoryPage))),
};
