import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideProgressBar } from './actions';

class ProgressBar extends Component {
  componentWillReceiveProps(props) {
    const { value, total, display, hideProgressBar } = props;
    if (!display || total === value) {
      hideProgressBar();
    }
  }

  render() {
    const color = 'red';
    const { total, value, display } = this.props;
    const percent = (value / total) * 100;
    return (
      display && (
        <div
          className="progress"
          style={{
            width: '100%',
            position: 'absolute',
            left: 0,
            top: '50px',
            zIndex: 9999,
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              background: 'lightblue',
              width: `${percent}%`,
              height: '5px',
            }}
          >
            <span className="sr-only">{percent}% Complete</span>
            <span
              style={{
                paddingLeft: '1px',
                display: 'block',
                textAlign: 'left',
              }}
            ></span>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  total: state.progressBar.total,
  value: state.progressBar.value,
  display: state.progressBar.display,
});

const mapDispatchToProps = dispatch => ({
  hideProgressBar: () => {
    return dispatch(hideProgressBar());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
