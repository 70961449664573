import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { uploadFiles } from '../mediaObject';
import { documentUpdate, DOCUMENTS_UPDATE_SUCCESS } from '../documents/actions';
import { apiFetchAuthorized, apiDelete } from '../../../library/helpers/fetch';

const normalizeDate = date => date.split('-').join('/');

export const IMMUNIZATION_REQUEST = 'immunizations/IMMUNIZATION_REQUEST';
export const IMMUNIZATION_SUCCESS = 'immunizations/IMMUNIZATION_SUCCESS';
export const IMMUNIZATION_FAILURE = 'immunizations/IMMUNIZATION_FAILURE';

export const IMMUNIZATION_CREATE = 'immunization/IMMUNIZATION_CREATE';
export const IMMUNIZATION_CREATE_SUCCESS =
  'immunization/IMMUNIZATION_CREATE_SUCESS';
export const IMMUNIZATION_CREATE_FAILURE =
  'immunization/IMMUNIZATION_CREATE_FAILURE';

export const IMMUNIZATION_DELETE = 'immunization/IMMUNIZATION_DELETE';
export const IMMUNIZATION_DELETE_SUCCESS =
  'immunization/IMMUNIZATION_DELETE_SUCESS';
export const IMMUNIZATION_DELETE_FAILURE =
  'immunization/IMMUNIZATION_DELETE_FAILURE';

export const IMMUNIZATION_UPDATE = 'immunization/IMMUNIZATION_UPDATE';
export const IMMUNIZATION_UPDATE_SUCCESS =
  'immunization/IMMUNIZATION_UPDATE_SUCESS';
export const IMMUNIZATION_UPDATE_FAILURE =
  'immunization/IMMUNIZATION_UPDATE_FAILURE';

export const IMMUNIZATION_SELECT_ID = 'immunization/SELECT_ID';
export const IMMUNIZATION_CLEAR_ID = 'immunization/CLEAR_ID';

export const RESOURCE_MEDIA_INFO_UPDATED = 'immunization/MEDIA_UPDATED';
export const IMMUNIZATIONS_ATTACH_MEDIA = 'immunization/attach_media';
export const IMMUNIZATION_UPDATE_MEDIA = 'immunization/update_media';
export const IMMUNIZATION_REMOVE_MEDIA = 'immunization/remove_media';

export const MEDIA_REMOVE_REQUEST = 'immunization/delete_media_request';
export const MEDIA_REMOVE_SUCCESS = 'immunization/delete_media_success';
export const MEDIA_REMOVE_FAILURE = 'immunization/delete_media_failure';

export const IMMUNIZATION_MEDIA_TO_DELETE =
  'immunization/immunization_delete_media';
export const IMMUNIZATION_MEDIA_TO_DELETE_CANCEL =
  'immunization/cancel_immunization_delete_media';

export const UPDATE_IMMUNIZATION_MEDIA = 'ADD_MEDIA/member.userImmunizations';
export const SORT_BY = 'general/data/sort-by/';

export const updateDocumentMedia = (formName, pristine, type) => {
  return {
    type,
    payload: {
      formName,
      pristine,
    },
  };
};

export const immunizationSelectId = mid => ({
  type: IMMUNIZATION_SELECT_ID,
  payload: mid,
});

export const immunizationClearSelectedId = () => ({
  type: IMMUNIZATION_CLEAR_ID,
});

export const fetchImmunizations = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [IMMUNIZATION_REQUEST, IMMUNIZATION_SUCCESS, IMMUNIZATION_FAILURE],
    endpoint: `/api/member/immunizations`,
  },
});

export const sortBy = (field, direction = 'DESC') => ({
  type: SORT_BY,
  payload: {
    field,
    direction,
  },
});

export const immunizationCreate = (pid, data, bundles, options) => {
  return async dispatch => {
    const payload = {
      ...data,
      personId: pid,
      administrationOn: data.administrationOn ? data.administrationOn : '',
      adverseEvent: codeObjectListToCsv(data.adverseEvent),
    };

    dispatch({
      type: IMMUNIZATION_CREATE,
      payload: {
        pid,
        data,
        bundles,
        options,
      },
    });

    try {
      const response = await apiFetchAuthorized(`/api/member/immunization`, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();

      if (bundles.length > 0) {
        await dispatch(
          uploadFiles(pid, json.data.immunizationId, bundles, 'immunization')
        );
      }

      return await dispatch({
        type: IMMUNIZATION_CREATE_SUCCESS,
        payload: json.data,
      });
    } catch (error) {
      return dispatch({ type: IMMUNIZATION_CREATE_FAILURE, payload: error });
    }
  };
};

export const setToDelete = (mid, iid) => ({
  type: IMMUNIZATION_MEDIA_TO_DELETE,
  payload: {
    mediaObjectId: mid,
    immunizationMediaObjectId: iid,
  },
});

export const cancelDelete = mid => ({
  type: IMMUNIZATION_MEDIA_TO_DELETE_CANCEL,
  payload: mid,
});

export const immunizationUpdate = (pid, mid, data, bundles, options) => {
  return async dispatch => {
    let payload = {
      ...data,
      personId: pid,
      immunizationId: mid,
      name: data.name,
      administrationOn: data.administrationOn ? data.administrationOn : '',
      adverseEvent: codeObjectListToCsv(data.adverseEvent),
    };

    dispatch({
      type: IMMUNIZATION_UPDATE,
      payload: {
        pid,
        mid,
        data,
        bundles,
        options,
      },
    });

    try {
      // update immunization form record
      if (options.hasFormChanges) {
        const response = await apiFetchAuthorized(`/api/member/immunization`, {
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const resJson = await response.json();
        payload = resJson.data;
      }

      // updates attachments
      const bundlesToUpdate = {};
      if (options && options.bundlesToUpdate.length > 0) {
        for (const bundle of options.bundlesToUpdate) {
          const content = await dispatch(
            documentUpdate(pid, bundle.mediaObjectId, bundle, mid)
          );
          bundlesToUpdate[bundle.mediaObjectId] = content.payload;
        }
      }

      // upload attachments
      if (bundles.length > 0) {
        await dispatch(
          uploadFiles(pid, payload.immunizationId, bundles, 'immunization')
        );
      }

      // delete attachments
      if (options.bundlesToDelete.length) {
        for (const bundle of options.bundlesToDelete) {
          console.log('aa');
          await dispatch(
            deleteExistingMedia(
              bundle.mediaObjectId,
              payload.immunizationId,
              bundle.immunizationMediaObjectId
            )
          );
        }
      }

      return await dispatch({
        type: IMMUNIZATION_UPDATE_SUCCESS,
        payload,
        bundles: bundlesToUpdate,
      });
    } catch (error) {
      return dispatch({ type: IMMUNIZATION_UPDATE_FAILURE, payload: error });
    }
  };
};

export const immunizationDelete = (pid, mid) => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        IMMUNIZATION_DELETE,
        IMMUNIZATION_DELETE_SUCCESS,
        IMMUNIZATION_CREATE_FAILURE,
      ],
      endpoint: `/api/member/immunization/${mid}`,
      payload: mid,
      meta: {
        config: {
          method: 'DELETE',
          mode: 'cors',
        },
      },
    },
  };
};

export const attachMediaToEntry = (mid, bundle) => ({
  type: IMMUNIZATIONS_ATTACH_MEDIA,
  payload: {
    immunizationId: mid,
    bundle,
  },
});

export const updateMedia = (pid, mid, bundle) => {
  return async dispatch => {
    const res = await dispatch(documentUpdate(pid, mid, bundle));
    if (res.type === DOCUMENTS_UPDATE_SUCCESS) {
      dispatch({ type: IMMUNIZATION_UPDATE_MEDIA, payload: res.payload });
    }
  };
};

export const deleteMedia = index => ({
  type: IMMUNIZATION_REMOVE_MEDIA,
  payload: index,
});

export const deleteExistingMedia = (
  mediaObjectId,
  objectId,
  referenceId = null
) => async dispatch => {
  dispatch({
    type: MEDIA_REMOVE_REQUEST,
    payload: {
      mediaObjectId,
      objectId,
      referenceId,
    },
  });

  const api = referenceId
    ? `/api/media/immunization/${mediaObjectId}/reference/${referenceId}`
    : `/api/media/${mediaObjectId}`;
  const response = await apiDelete(api, {
    mode: 'cors',
  });

  const json = await response.json();
  if (response.status === 200) {
    dispatch({ type: MEDIA_REMOVE_SUCCESS, payload: mediaObjectId });
  } else if (response.status >= 400) {
    dispatch({ type: MEDIA_REMOVE_FAILURE, payload: mediaObjectId });
  }
  return json;
};
