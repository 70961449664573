import React, { useEffect } from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
  Button,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import {
  BodyMassViewer,
  BloodPressureViewer,
  // EmotionsViewer,
  WaterIntakeViewer,
} from './fragmentViewers';
import {
  FRAGMENT_DEFAULT,
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_EMOTIONS,
  FRAGMENT_WATER_INTAKE,
} from '../../components/JournalCompose/FragmentsList';
import Image from '../../components/Image';
import Video from '../../components/Video';
import MediaWord from '../../../client/images/mediaWord.jpg';
import MediaPdf from '../../../client/images/mediaPdf.jpg';

import { FRAGMENT_SUPPORTED_VIEWS } from './data';
import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import { getBestTargetImageFormat } from '../../components/MediaObject/helpers';

import {
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
  MIME_TYPE_VIDEO_MP4,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_PDF,
  isImageMimeType,
} from '../../../library/helpers/mimeTypes';

import { MIME_TYPE_DATA } from '../../../library/helpers/mimeTypes';

const styles = theme => ({
  overlay: {
    overflow: 'auto',
  },
  root: {
    background: '#fff',
    borderRadius: 6,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '95%',
    maxWidth: 1160,
    position: 'relative',
    outline: 'none',
    minHeight: 400,
    top: `calc(50% - 400px/2)`,
    margin: '0 auto',
  },
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  title: {
    marginBottom: 21,
    wordWrap: 'break-word',
  },
  body1: {
    wordWrap: 'break-word',
  },
  media: {
    width: '100%',
    height: 150,
    marginBottom: 24,
    borderRadius: 4,
    overflow: 'hidden',
    display: 'flex',
  },
  nonImagePlaceholder: {
    marginBottom: 12,
    objectFit: 'cover',
    width: '100%',
    height: 76,
  },
  nonImage: {
    width: '100%',
    height: '150px',
  },
  nonImageText: {
    fontSize: 14,
  },
});

const getMediaTypeIcon = mediaType => {
  switch (mediaType) {
    case MIME_TYPE_APPLICATION_DOCX:
      return MediaWord;
    case MIME_TYPE_APPLICATION_PDF:
      return MediaPdf;
  }
};

const JournalDetailView = ({
  classes,
  onClose,
  open,
  children,
  data,
  onDelete,
  onEdit,
  personId,
  selectedId,
}) => {
  const { enteredOn: date, title, body, fragments, mediaObjects } = data;

  const [images, setImages] = React.useState(
    mediaObjects.map(object => ({
      id: object.mediaObjectId,
      loading: true,
      source: null,
    }))
  );

  useEffect(() => {
    const fetchImageSources = () => {
      const promises = mediaObjects.map(object => {
        if (isImageMimeType(object.mimeType)) {
          return apiFetchAuthorized(`/api/media/${object.mediaObjectId}/SM`, {
            responseType: 'arraybuffer',
          });
        }

        return null;
      });

      Promise.all(promises.filter(x => x)).then(responses => {
        setImages(
          responses.map((source, idx) => ({
            id: images[idx].id,
            loading: false,
            source: source.body,
          }))
        );
      });
    };

    fetchImageSources();
  }, []);

  const onDeleteClick = () => {
    onDelete(data, selectedId);
    onClose(false);
  };

  const onEditClick = () => {
    onEdit(data, selectedId);
    onClose(false);
  };

  const fragmentViewers = {
    [FRAGMENT_BODY_MASS]: BodyMassViewer,
    [FRAGMENT_BLOOD_PRESSURE]: BloodPressureViewer,
    // [FRAGMENT_EMOTIONS]: EmotionsViewer,
    [FRAGMENT_WATER_INTAKE]: WaterIntakeViewer,
  };

  const renderFragment = () => {
    const firstFragment = fragments.length !== 0 && fragments[0];

    if (!firstFragment) return null;

    const FragmentComponent = fragmentViewers[firstFragment.fragmentType];
    if (!FragmentComponent) {
      console.caution(
        `Unsupported Tracking Form / Fragment view "${firstFragment.fragmentType}"`
      );

      return null;
    }

    const data = firstFragment.fragmentData;
    return <FragmentComponent data={data} />;
  };

  const renderMediaType = data => {
    // separate media file extension file wise and based on extension component will be render.
    const extensionType = data.fileName.split('.');

    const mimeType = MIME_TYPE_DATA[data.mimeType];

    if (!mimeType) {
      console.warn(
        `Unsupported mime type "${data.mimeType}" in journal detail view`
      );
      return '';
    }

    switch (mimeType.type) {
      case 'application':
      case 'text':
        return (
          <div className={classes.nonImage}>
            <img
              className={classes.nonImagePlaceholder}
              src={getMediaTypeIcon(data.mimeType)}
              alt={mimeType.description}
            />
            <Typography className={classes.nonImageText} variant="body1">
              {extensionType[0]}
            </Typography>
          </div>
        );

      case 'image':
        const format = getBestTargetImageFormat(data.formats, 'SM', ['XS']);
        return (
          <Image
            // onClick={() => setMediaViewer({ open: true, data })}
            personId={personId}
            src={data.links[format]}
            placeholder={data.base64DataUriString}
            height="100%"
            style={{ cursor: 'pointer' }}
            alt={mimeType.description}
          />
        );

      case 'video':
        return (
          <Video
            // onClick={() => setMediaViewer({ open: true, data })}
            personId={personId}
            src={data.links.self}
            controls={false}
            cover
          />
        );
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      disableAutoFocus={true}
      className={classes.overlay}
    >
      <Grid container justify="space-between" className={classes.root}>
        <IconButton className={classes.close} onClick={() => onClose(false)}>
          <Icon>close</Icon>
        </IconButton>

        <Grid
          container
          spacing={4}
          alignItems="flex-start"
          style={{ paddingBottom: 24 }}
        >
          <Grid item xs={6}>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            <Typography className={classes.body1} variant="body1">
              {body}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container alignItems="center">
            <Grid
              xs={6}
              container
              alignItems="center"
              direction="row"
              wrap="nowrap"
              item
            >
              <Icon className={classes.icon}>date_range_24px</Icon>
              <div>{format(parseISO(date), 'MMMM d, yyyy')}</div>
            </Grid>
            <Grid
              xs={6}
              container
              alignItems="center"
              direction="row"
              wrap="nowrap"
              item
            >
              <Icon className={classes.icon}>access_time_24px</Icon>
              <div>{format(parseISO(date), 'h:mm a')}</div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={7}>
              {renderFragment()}
            </Grid>
            <Grid item xs={5} container spacing={2}>
              {/* <Grid item xs={12}>
                <Typography variant="h6">Media Objects</Typography>
              </Grid> */}
              {mediaObjects.map((object, index) => (
                <Grid
                  key={`jmoi-${index}`}
                  item
                  xs={4}
                  className={classes.media}
                >
                  {renderMediaType(object)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} alignItems="flex-end" container>
          <Button
            variant="outlined"
            onClick={() => onDeleteClick(personId, selectedId)}
          >
            Delete
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            variant="contained"
            color="primary"
            onClick={onEditClick}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(JournalDetailView);
