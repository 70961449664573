import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Timer from '../Timer';

import './InactivityModal.css';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogBase: {
    padding: theme.spacing(2),
    margin: 0,
    overflow: 'hidden',
  },
});

class InactivityModal extends Component {
  render() {
    const { classes, onHide, duration, open } = this.props;
    return (
      <Dialog open={open}>
        <DialogTitle className={classes.dialogBase} onClose={onHide}>
          Are you still here?
          <IconButton className={classes.closeButton} onClick={onHide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogBase}>
          <h4>
            {' '}
            We're concerned about your security and value your privacy as you
            do!{' '}
          </h4>
          <Divider />
          <p>
            When we detect inactivity for more than five minutes, that is when
            it appears you're not using the application, we'll log you out to
            prevent unwanted access of your information by prying eyes. If
            you're still around, move your mouse.
            <br />
          </p>
          <h5>
            Else we'll log you out in{' '}
            <strong>
              <Timer duration={duration} />
            </strong>
          </h5>
          <br />
        </DialogContent>
        <DialogActions className={classes.dialogBase}>
          <Button color="primary" variant="contained" onClick={onHide}>
            I'm still here!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InactivityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  duration: PropTypes.string,
};

export default withStyles(styles)(InactivityModal);
