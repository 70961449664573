import React, { useState, useEffect } from 'react';
import WeightChart from '../../components/ChartComponents/Charts/WeightChart';
import BloodPressureChart from '../../components/ChartComponents/Charts/BloodPressureChart';
import CaloriesChart from '../../components/ChartComponents/Charts/CaloriesChart';
import SleepChart from '../../components/ChartComponents/Charts/SleepChart';
import WaterChart from '../../components/ChartComponents/Charts/WaterChart';
import { dataFragments } from '../../pages/ChartsPage';
import { Paper, Typography, Grid, Fade, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import TextDivider from '../../components/TextDivider';
import useParseJournal from '../../pages/ChartsPage/useParseJournal';
import EmptyComponent from '../../components/ChartComponents/Charts/EmptyComponent';
import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
} from '../../components/JournalCompose/FragmentsList';
import { format } from 'date-fns-tz';
import { subMonths, subYears, addMonths } from 'date-fns';

const date = new Date();
const currDate = date.setHours(24, 0, 0, 0);
const defaultPastDate = subYears(new Date(), 10);
const fragmentList = [
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
];

const ChartsContainer = ({
  fragments,
  hasFetchedFragments,
  personId,
  timeZoneIANA,
  clearFragments,
  fetchFragmentsByType,
  route,
}) => {
  const [data, setData] = useState({});
  const [currPerson, setCurrPerson] = useState(personId);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!hasFetchedFragments) {
      fetchFragments();
      return;
    }
    const _data = {};
    Object.keys(fragments).forEach(item => {
      if (fragments[item].data.length > 1) _data[item] = fragments[item];
    });
    setCurrPerson(personId);
    setData(_data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (currPerson !== personId) {
      clearFragments();
      setData({});
      setIsLoading(true);
      fetchFragments();
      setCurrPerson(personId);
    }
  }, [personId, timeZoneIANA]);

  useEffect(() => {
    if (hasFetchedFragments) {
      const _data = {};
      Object.keys(fragments).forEach(item => {
        if (fragments[item].data.length > 1) _data[item] = fragments[item];
      });
      setData(_data);
      setIsLoading(false);
    }
  }, [hasFetchedFragments, fragments]);

  const fetchFragments = () => {
    const startDate = format(
      subMonths(currDate, 12),
      "yyyy-MM-dd'T'HH:mm:ssXXX",
      { timeZoneIANA }
    );
    const endDate = format(currDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZoneIANA,
    });
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    clearFragments();
    fragmentList.forEach(type => {
      fetchFragmentsByType(type, params, personId);
    });
  };

  const [
    FRAGMENT_BODY_MASS,
    FRAGMENT_BLOOD_PRESSURE,
    FRAGMENT_WATER_INTAKE,
    FRAGMENT_SLEEP_TRACKER,
    CALORIES_TRACKER,
  ] = dataFragments;

  const props = {
    tickValues: [
      subMonths(currDate, 11),
      subMonths(currDate, 10),
      subMonths(currDate, 9),
      subMonths(currDate, 8),
      subMonths(currDate, 7),
      subMonths(currDate, 6),
      subMonths(currDate, 5),
      subMonths(currDate, 4),
      subMonths(currDate, 3),
      subMonths(currDate, 2),
      subMonths(currDate, 1),
      date,
    ],
    dateExtent: [subMonths(currDate, 11), addMonths(currDate, 1)],
    dateRange: '1 yr',
    setAnnotations: () => {},
    mode: 'summary',
  };

  const LinkWrapper = ({ children, fragmentType }) => {
    return (
      <Link
        to={{
          pathname: route,
          state: { fragmentType },
        }}
      >
        {children}
      </Link>
    );
  };

  const Loading = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ padding: '24px' }}>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton
            variant="rect"
            component="div"
            width="100%"
            height={135}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '24px' }}>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton
            variant="rect"
            component="div"
            width="100%"
            height={135}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '24px' }}>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton
            variant="rect"
            component="div"
            width="100%"
            height={135}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '24px' }}>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton
            variant="rect"
            component="div"
            width="100%"
            height={135}
            animation="wave"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '24px' }}>
          <Skeleton variant="text" width={60} height={24} />
          <Skeleton
            variant="rect"
            component="div"
            width="100%"
            height={135}
            animation="wave"
          />
        </Grid>
      </Grid>
    );
  };

  const dateRange = `${format(props.dateExtent[0], 'MM/dd/yyyy')} - ${format(
    props.dateExtent[1],
    'MM/dd/yyyy'
  )}`;
  const addDefaultDate = arr => [
    ...arr,
    { ...arr[arr.length - 1], date: defaultPastDate },
  ];

  const CaloriesPressureFragment = () => {
    if (data[CALORIES_TRACKER] && data[CALORIES_TRACKER].data) {
      const _data = data[CALORIES_TRACKER].data.sort((a, b) => b.date - a.date);
      return (
        <Grid item xs={12}>
          <Typography>{data[CALORIES_TRACKER].name}</Typography>
          <LinkWrapper fragmentType={CALORIES_TRACKER}>
            <CaloriesChart coordinates={addDefaultDate(_data)} {...props} />
          </LinkWrapper>
        </Grid>
      );
    } else return '';
  };

  const BloodPressureFragment = () => {
    if (data[FRAGMENT_BLOOD_PRESSURE] && data[FRAGMENT_BLOOD_PRESSURE].data) {
      const _data = data[FRAGMENT_BLOOD_PRESSURE].data.sort(
        (a, b) => b.date - a.date
      );
      return (
        <Grid item xs={12}>
          <Typography>{data[FRAGMENT_BLOOD_PRESSURE].name}</Typography>
          <LinkWrapper fragmentType={FRAGMENT_BLOOD_PRESSURE}>
            <BloodPressureChart
              coordinates={addDefaultDate(_data)}
              {...props}
            />
          </LinkWrapper>
        </Grid>
      );
    } else return '';
  };

  const SleepTrackerFragment = () => {
    if (data[FRAGMENT_SLEEP_TRACKER] && data[FRAGMENT_SLEEP_TRACKER].data) {
      const _data = data[FRAGMENT_SLEEP_TRACKER].data.sort(
        (a, b) => b.date - a.date
      );
      return (
        <Grid item xs={12}>
          <Typography>{data[FRAGMENT_SLEEP_TRACKER].name}</Typography>
          <LinkWrapper fragmentType={FRAGMENT_SLEEP_TRACKER}>
            <SleepChart coordinates={addDefaultDate(_data)} {...props} />
          </LinkWrapper>
        </Grid>
      );
    } else return '';
  };

  const WaterIntakeFragment = () => {
    if (data[FRAGMENT_WATER_INTAKE] && data[FRAGMENT_WATER_INTAKE].data) {
      const _data = data[FRAGMENT_WATER_INTAKE].data.sort(
        (a, b) => b.date - a.date
      );
      return (
        <Grid item xs={12}>
          <Typography>{data[FRAGMENT_WATER_INTAKE].name}</Typography>
          <LinkWrapper fragmentType={FRAGMENT_WATER_INTAKE}>
            <WaterChart coordinates={addDefaultDate(_data)} {...props} />
          </LinkWrapper>
        </Grid>
      );
    } else return '';
  };

  const BodyMassFragment = () => {
    if (data[FRAGMENT_BODY_MASS] && data[FRAGMENT_BODY_MASS].data) {
      const _data = data[FRAGMENT_BODY_MASS].data.sort(
        (a, b) => b.date - a.date
      );
      return (
        <Grid item xs={12}>
          <Typography>{data[FRAGMENT_BODY_MASS].name}</Typography>
          <LinkWrapper fragmentType={FRAGMENT_BODY_MASS}>
            <WeightChart coordinates={addDefaultDate(_data)} {...props} />
          </LinkWrapper>
        </Grid>
      );
    } else return '';
  };

  return (
    <div style={{ marginTop: '56px' }}>
      <Grid style={{ marginBottom: 32 }} item spacing={3}>
        <TextDivider startText="My Charts" endText={dateRange} />
      </Grid>
      <Fade timeout={200} in={true}>
        <Grid container spacing={3}>
          {isLoading ? (
            <Loading />
          ) : Object.values(data).length ? (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <CaloriesPressureFragment />
                <BloodPressureFragment />
                <SleepTrackerFragment />
                <WaterIntakeFragment />
                <BodyMassFragment />
              </Grid>
            </Grid>
          ) : (
            <EmptyComponent type="empty" />
          )}
        </Grid>
      </Fade>
    </div>
  );
};

export default ChartsContainer;
