import {DISPATCH_API_AUTHORIZED} from '../../../library/middleware/dispatchApi';

import { createQueryStringFromObject } from '../../../library/helpers/encode';

export const USERAGREEMENTS_TYPE_REQUEST = 'agreementdetails/USERAGREEMENTS_TYPE_REQUEST';
export const USERAGREEMENTS_TYPE_SUCCESS = 'agreementdetails/USERAGREEMENTS_TYPE_SUCCESS';
export const USERAGREEMENTS_TYPE_FAILURE = 'agreementdetails/USERAGREEMENTS_TYPE_FAILURE';

export const fetchUserAgreements = () => {
    const formData = new URLSearchParams();
    formData.append('ContainerName', 'agreements');
    return {
        [DISPATCH_API_AUTHORIZED]: {
            types: [USERAGREEMENTS_TYPE_REQUEST, USERAGREEMENTS_TYPE_SUCCESS , USERAGREEMENTS_TYPE_FAILURE],
            endpoint: '/azurestorage/getfilesfromcontainers',
            meta: {
                config: {
                    headers: {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'},
                    method: 'POST'  ,
                    mode: 'cors',
                    body: formData
                }
            }
        }
    }
}

