import React, { Component } from 'react';
import styles from './ProgressBar.css';

class ProgressBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { percent, message } = this.props;
    return (
      <div className={styles.progressBarRoot}>
        <p>
          <strong>Progress</strong>
        </p>
        <div className={styles.progress}>
          <div
            className={styles.progressBar}
            role="progressbar"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: percent }}
          ></div>
        </div>
        {message}
      </div>
    );
  }
}

export default ProgressBar;
