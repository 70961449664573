import { useState, useEffect } from 'react';

export const status = {
  //state enum
  LOADING: 'loading',
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
};

const useUIState = (isLoading, error, data, personId) => {
  const { LOADING, EMPTY, SUCCESS, ERROR } = status;
  const [state, setState] = useState(LOADING);
  const hasError = Boolean(error.length);
  useEffect(() => {
    let hasRecords = false;
    Object.keys(data).forEach(key => {
      if (data[key].data.length) hasRecords = true;
    });
    if (!isLoading) {
      if (hasRecords) {
        setState(SUCCESS);
      }
      if (!hasRecords && !hasError) {
        setState(EMPTY);
      }
      if (hasError) {
        setState(ERROR);
      }
    } else if (state !== LOADING) setState(LOADING);
  }, [isLoading, error, personId, data]);

  return [state, setState];
};

export default useUIState;
