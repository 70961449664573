import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';
import { Grid, Divider } from '@material-ui/core';
import { SubmissionError } from 'redux-form';

import BasePage from '../../BasePage';
import PageHeader from '../../../components/PageHeader';

import { fetchAllergens } from '../../../../store/reducers/allergens';
import { fetchReactions } from '../../../../store/reducers/reactions';
import { fetchTreatments } from '../../../../store/reducers/treatments';
import {
  uploadFiles,
  clearFiles,
  ALLERGY,
} from '../../../../store/reducers/mediaObject';
import {
  deleteMedia,
  updateMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import MediaSubmitProgress from '../../../components/DocumentsDrawer/MediaObjectDrawer/MediaSubmitProgress';
import MediaObject from '../../../containers/MediaObject';
import { getBundlesToUpload } from '../../../components/MediaObject/helpers';

import { setProgressBar } from '../../../../store/reducers/progressbar';

import AllergyForm from '../AllergyForm';

// eslint-disable-next-line
import {
  ALLERGY_CREATE_SUCCESS,
  ALLERGY_CREATE_FAILURE,
  ALLERGY_UPDATE_SUCCESS,
  ALLERGY_UPDATE_FAILURE,
  allergyCreate,
  allergyUpdate,
  submitForm,
} from '../../../../store/reducers/allergies/actions';
import { SUCCESS } from '../../../../store/state';
import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../../store/reducers/ui/actions';

import './AllergyPage.css';

const FormContainer = props => {
  const { action, isSubmitting, isUploadingFiles, bundles } = props;
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const {
      history,
      selectedId,
      action,
      allergens,
      reactions,
      treatments,
    } = props;
    // if (!selectedId && action !== 'CREATE') {
    //   console.log(props)
    //   history.goBack();
    // }
    if (!allergens.length) {
      props.fetchAllergens();
    }
    if (!reactions.length) {
      props.fetchReactions();
    }
    if (!treatments.length) {
      props.fetchTreatments();
    }
    return () => {
      props.dispatch(unblockNavigation());
    };
  }, []);

  const renderMediaObjects = () => {
    const { selectedId, list } = props;

    if (!selectedId) {
      return '';
    }

    const { mediaObjects } = list[selectedId];

    if (mediaObjects && mediaObjects.length === 0) {
      return '';
    }

    return <div>Total number of images found {mediaObjects.length}</div>;
  };

  const onFormChange = (v, d, formProps) => {
    if (
      !formProps.pristine &&
      !props.ui.isNavigationLocked &&
      props.selectedId
    ) {
      props.dispatch(blockNavigationWithStandardMsg());
    }
  };

  const onFormSubmit = async (values, dispatch, parentProps) => {
    const {
      personId,
      allergyCreate,
      allergyUpdate,
      selectedId,
      clearFiles,
      mediaObjects,
      form,
      bundlesToDelete,
    } = props;

    const { bundlesToUpload, bundlesToUpdate } = getBundlesToUpload(
      mediaObjects,
      form
    );

    let result;
    if (bundlesToUpdate.length) {
      for (const file of bundlesToUpdate) {
        await props.updateMedia({
          ...file,
          description: file.notes || file.description,
        });
      }
    }

    // delete attachments
    if (bundlesToDelete.length) {
      for (const bundle of bundlesToDelete) {
        await props.deleteMedia(bundle.mediaObjectId, bundle.objectId);
      }
    }

    if (!selectedId) {
      result = await allergyCreate(personId, values, bundlesToUpload);
    } else {
      result = await allergyUpdate(
        personId,
        selectedId,
        values,
        bundlesToUpload
      );
    }

    dispatch(unblockNavigation());

    if (
      result.type === ALLERGY_CREATE_SUCCESS ||
      result.type === ALLERGY_UPDATE_SUCCESS
    ) {
      clearFiles();
      parentProps.reset();
      props.onCloseDrawer();
    } else if (
      result.type === ALLERGY_CREATE_FAILURE ||
      result.type === ALLERGY_UPDATE_FAILURE
    ) {
      throw new SubmissionError(result.payload);
    }
  };

  return (
    <div
      style={{
        width: !props.matches550 ? '100hw' : '550px',
        overflowX: 'hidden',
      }}
    >
      {' '}
      {isSubmitting && bundles.length ? (
        <div style={{ padding: 16, paddingTop: 24 }}>
          <Grid container spacing={2}>
            {bundles.map(bundle => (
              <Grid item xs={12}>
                <MediaSubmitProgress bundle={bundle} />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <AllergyForm
          allergensList={props.allergens}
          reactionsList={props.reactions}
          treatmentsList={props.treatments}
          onChange={onFormChange}
          onCancel={props.onCloseDrawer}
          onSubmit={onFormSubmit}
          disableSubmit={isSubmitting || isUploadingFiles}
          mediaObjectSlot={
            <Grid item xs={12}>
              {/* {renderMediaObjects()} */}
              <MediaObject mode="add" isSubmitting={isSubmitting} />
            </Grid>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { isFetchingAllergens, allergens } = state.api.allergens;
  const { isFetchingReactions, reactions } = state.api.reactions;
  const { isFetchingTreatments, treatments } = state.api.treatments;
  const {
    isSubmitting,
    selectedId,
    list,
    mediaObjects,
    bundlesToDelete,
  } = state.allergies;
  const { isUploadingFiles, bundles } = state.mediaObject;
  const { personId } = state.active.user;

  return {
    form: state.form,
    selectedId,
    allergies: state.member.userAllergy.allergies,
    isFetchingAllergens,
    allergens,
    isFetchingReactions,
    reactions,
    isFetchingTreatments,
    treatments,
    isUploadingFiles,
    bundles,
    personId,
    list,
    isSubmitting,
    ui: state.ui,
    mediaObjects,
    bundlesToDelete,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  setProgressBar: (value, total) => dispatch(setProgressBar(value, total)),
  fetchAllergens: () => dispatch(fetchAllergens()),
  fetchReactions: () => dispatch(fetchReactions()),
  fetchTreatments: () => dispatch(fetchTreatments()),
  allergyCreate: (id, data, bundles) =>
    dispatch(allergyCreate(id, data, bundles)),
  allergyUpdate: (pid, aid, data, bundles) =>
    dispatch(allergyUpdate(pid, aid, data, bundles)),
  uploadFiles: (files, meta) => dispatch(uploadFiles(files, meta)),
  clearFiles: () => dispatch(clearFiles()),
  updateMedia: obj => dispatch(updateMedia(ALLERGY, obj)),
  deleteMedia: (mid, oid) => dispatch(deleteMedia(mid, oid, ALLERGY)),
  submitForm: () => dispatch(submitForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormContainer));
