import React from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import SelectField from '../../../library/forms/SelectField';
import SearchComponent from './SearchComponent';

const changeOptions = [
  {
    shortname: 'Unknown',
    longname: 'Unknown',
  },
  {
    shortname: 'None',
    longname: 'None',
  },
];

const styles = theme => ({
  root: {
    overflow: 'visible',
  },
  paper: {
    overflow: 'visible',
  },
});

const BulkChangeConditions = ({
  onChange,
  classes,
  handleSearch,
  searchTerm,
}) => {
  const [open, setOpen] = React.useState(false);
  const [toValue, setToValue] = React.useState('None');
  const [fromValue, setFromValue] = React.useState('Unknown');

  const [toError, setToError] = React.useState(null);
  const [fromError, setFromError] = React.useState(null);

  React.useEffect(() => {
    setFromError('');
    setToError('');
  }, [toValue, fromValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!fromValue) {
      return setFromError('Required');
    }
    if (!toValue) {
      return setToError('Required');
    }
    if (fromValue === toValue) {
      return setToError('Both values cannot be the same');
    }
    onChange(fromValue, toValue);
    setFromValue('Unknown');
    setToValue('None');
    setOpen(false);
  };

  const hasError = fromError || toError;
  return (
    <Grid container spacing={2} style={{ marginBottom: 24 }}>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          color="primary"
          style={{ height: '49px', marginTop: 8 }}
          onClick={handleClickOpen}
        >
          Bulk Change
        </Button>
      </Grid>
      <Grid item xs={8}>
        <SearchComponent searchTerm={searchTerm} handleSearch={handleSearch} />
      </Grid>
      <Dialog
        classes={classes}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Bulk Change</DialogTitle>
        <DialogContent classes={classes}>
          <DialogContentText>
            This tool allows you to change all Family Medical History records
            marked as Unknown to None (or reverse). This will not affect any
            records tagged with you or a Family member.
          </DialogContentText>
          <Grid
            justify="space-between"
            container
            alignItems="center"
            style={{ marginTop: 16 }}
            wrap="nowrap"
          >
            <div style={{ width: '47%' }}>
              <SelectField
                type="text"
                name="fromValue"
                label="From Value"
                labelKey="longname"
                valueKey="shortname"
                options={changeOptions}
                meta={{ touched: false, error: fromError }}
                input={{ value: fromValue, onChange: setFromValue }}
              />
            </div>
            <Typography
              variant="body1"
              style={{ marginTop: -10, marginLeft: 8, marginRight: 8 }}
            >
              to
            </Typography>
            <div style={{ width: '47%' }}>
              <SelectField
                type="text"
                name="toValue"
                label="To Value"
                labelKey="longname"
                valueKey="shortname"
                options={changeOptions}
                meta={{ touched: false, error: toError }}
                input={{ value: toValue, onChange: setToValue }}
              />
            </div>
          </Grid>
          {hasError && (
            <Typography variant="body1" style={{ color: 'red' }}>
              {fromError || toError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit Change
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles)(BulkChangeConditions);
