import {
  ALLERGEN_REQUEST,
  ALLERGEN_SUCCESS,
  ALLERGEN_FAILURE,
  REACTIONS_REQUEST,
  REACTIONS_SUCCESS,
  REACTIONS_FAILURE,
  REQUEST_ALLERGENS,
  RECEIVE_ALLERGENS,
  REQUEST_REACTIONS,
  RECEIVE_REACTIONS,
  REQUEST_TREATMENTS,
  RECEIVE_TREATMENTS,
} from './actions';

const initialState = {
  isFetchingAllergens: true,
  isFetchingReactions: true,
  isFetchingTreatments: true,
  allergens: [],
  reactions: [],
  treatments: [],
};

const handleAllergens = (state = initialState, action) => {
  // Short circuit if attempting to request again when a request is
  // already being made.
  if (action.type === ALLERGEN_REQUEST && state.isFetchingAllergens) {
    return state;
  }

  switch (action.type) {
    case ALLERGEN_REQUEST:
      return {
        ...state,
        isFetchingAllergens: true,
      };

    case ALLERGEN_SUCCESS:
      return {
        ...state,
        isFetchingAllergens: false,
        allergens: action.payload,
      };

    default:
      return state;
  }
};

const handleReactionsReducer = (state = initialState, action) => {
  // Short circuit if attempting to request again when a request is
  // already being made.
  if (action.type === REACTIONS_REQUEST && state.isFetchingReactions) {
    return state;
  }

  switch (action.type) {
    case REACTIONS_REQUEST:
      return {
        ...state,
        isFetchingReactions: true,
      };

    case REACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingReactions: false,
        reactions: action.payload,
      };

    default:
      return state;
  }
};

const handleTreatmentsReducer = (state = initialState, action) => {
  // Short circuit if attempting to request again when a request is
  // already being made.
  if (action.type === REQUEST_TREATMENTS && state.isFetchingTreatments) {
    return state;
  }

  switch (action.type) {
    case REQUEST_TREATMENTS:
      return {
        ...state,
        isFetchingTreatments: true,
      };

    case RECEIVE_TREATMENTS:
      return {
        ...state,
        isFetchingTreatments: false,
        treatments: action.treatments,
      };

    default:
      return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALLERGEN_REQUEST:
      return {
        ...state,
        isFetchingAllergens: true,
      };

    case ALLERGEN_SUCCESS:
      return {
        ...state,
        isFetchingAllergens: false,
        allergens: action.payload,
      };

    case REACTIONS_REQUEST:
      return {
        ...state,
        isFetchingReactions: true,
      };

    case REACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingReactions: false,
        reactions: action.payload,
      };

    case REQUEST_TREATMENTS:
      return {
        ...state,
        isFetchingTreatments: true,
      };

    case RECEIVE_TREATMENTS:
      return {
        ...state,
        isFetchingTreatments: false,
        treatments: action.treatments,
      };

    default:
      return state;
  }
};

export default reducer;
