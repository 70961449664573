import React from 'react';
import { connect } from 'react-redux';
import AttachmentsCompose from '../AttachmentsCompose';
import {
  updateMedia,
  deleteMedia,
} from '../../../store/reducers/mediaObjectDrawer/actions';
import { IMMUNIZATION } from '../../../store/reducers/mediaObject';
import DrawerAccordion from '../../components/DrawerAccordion/DrawerAccordion';

const AttachmentPanel = props => {
  return (
    <DrawerAccordion summaryTitle="Attachments">
      <AttachmentsCompose
        appArea={IMMUNIZATION}
        isSubmitting={props.isUploadingFile}
        goBack={() => {}}
        mediaObjects={props.mediaObjects}
        deleteMedia={props.handleDeleteMedia}
        bundlesToDelete={props.bundlesToDelete}
        immunizationId={props.immunizationId}
      />
    </DrawerAccordion>
  )
};

const mapStateToProps = state => ({
  bundlesToDelete: state.member.userImmunizations.bundlesToDelete,
  mediaObjects: state.mediaObject.bundles,
  isUploadingFile: state.mediaObject.isUploadingFiles,
  mediaObjects: !state.member.userImmunizations.selectedId
    ? []
    : state.member.userImmunizations.list[
        state.member.userImmunizations.selectedId
      ].mediaObjects,
});

const mapDispatchToProps = dispatch => ({
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  deleteMedia: (pid, aid, appArea, referenceId) =>
    dispatch(deleteMedia(pid, aid, appArea, referenceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentPanel);
