import React from 'react';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Grid, Typography, Container, Collapse } from '@material-ui/core';

export default ({ filename }) => {
  return (
    <Container style={{ marginTop: '70px' }}>
      <Collapse in={true} timeout={150}>
        <div
          style={{
            height: '260px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div>
            <IndeterminateCheckBoxIcon
              style={{
                fontSize: '5rem',
                color: '#ED5158',
              }}
            />
          </div>
          <div>
            <div
              style={{
                fontSize: '18px',
                fontWeight: '500',
                marginTop: '6px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography style={{ fontWeight: '500', fontSize: '24px' }}>
                File Deleted
              </Typography>
            </div>
            <div
              style={{
                fontSize: '18px',
                fontWeight: '500',
                marginTop: '6px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography> {filename} </Typography>
            </div>
          </div>
        </div>
      </Collapse>
    </Container>
  );
};
