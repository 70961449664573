import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0
    },
  },
  accordionSummaryContent: {
    '&.Mui-expanded': {
      margin: 0
    }
  },
  accordionDetails: {
    padding: 4,
  },
  heading: {
    padding: '0px 4px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
}));


const DrawerAccordion = ({ summaryTitle, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          classes={{
            root: classes.accordionSummary,
            content: classes.accordionSummaryContent,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6" className={classes.heading}>{summaryTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          { children }
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DrawerAccordion;
export { DrawerAccordion }; 
