import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import {getIssues, KNOWN_ISSUES_SUCCESS, KNOWN_ISSUES_FAILURE} from './actions';

class KnownIssues extends Component {
    state = {
        issues: []
    }

    componentDidMount = () => {
        this.getIssues();
    }

    getIssues = async () => {
        const {getIssues} = this.props;

        const result = await getIssues();

        if(result && result.type == KNOWN_ISSUES_SUCCESS) {
            this.setState(() => ({issues: result.payload || []}))
        } else if (result && result.type == KNOWN_ISSUES_FAILURE) {
            //
        }
    }

    render(){
        const {issues} = this.state;
        return issues && issues.length > 0 ? (
            <div style={{marginBottom: '10px'}}>
                {issues.map( issue => <Issue {...issue} key={issue.issueid}/>)}
            </div>
        ) : (
            <div className="well">
                <span style={{ color: '#BBB', fontSize: '140%' }}>
                <i className="fa fa-check-circle" aria-hidden="true"></i> No issues currently opened
                </span>
            </div>
        )
    }
}

const Issue = (props) => {
    const STATUS_COLORS = {
        OPEN: '#eea236',
        IN_PROGRESS: '#17a2b8',
        DONE: '#28a745'
    };

    const date_format = moment(props.createdon || new Date()).format('MM-DD-YYYY h:mm a');

    return (
        <div className="list-group-item" style={{borderLeft: 'solid 3px ' + (STATUS_COLORS[props.status] || '#eea236'), marginBottom: '15px'}}>
            <h5 className="list-group-item-heading">
                <i className="fa fa-bug" aria-hidden="true"></i> {props.status}

                <span className="pull-right text-muted">
                    {date_format}
                </span>
            </h5>

            <p>{props.description}</p>
        </div>
    )
}

export default connect((state) => ({}), (dispatch) => ({getIssues: () => dispatch(getIssues())}))((KnownIssues));