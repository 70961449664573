import React from 'react';
import Table from '../../../components/ReactTable';
import { SURGICALHISTORY } from '../../../../store/reducers/mediaObject';
import { dateTimeRender } from '~/src/shared/components/ReactTable/utils';

const getShortname = input => {
  return input && input.length
    ? input
        .map(x => x.shortName)
        .reduce(
          (text, value, i, array) =>
            text + (i < array.length - 1 ? ', ' : ' and ') + value
        )
    : '';
};

const SurgicalTable = props => {
  const getData = () =>
    props.listIds.map(id => {
      return {
        ...props.list[id],
        complications: getShortname(props.list[id].complications),
        relatedSurgicalPreparations: getShortname(
          props.list[id].relatedSurgicalPreparations
        ),
        id,
        type: SURGICALHISTORY,
      };
    });
  const [data, setData] = React.useState(
    React.useMemo(() => getData(), [props])
  );
  React.useEffect(() => {
    setData(getData());
  }, [props.list, props.listIds]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Surgery Date',
        accessor: 'surgeryEndedOn',
        col: '15%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'Surgery name',
        accessor: 'surgery',
        col: '30%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Facility',
        accessor: 'hospitalFacility',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Preparations',
        accessor: 'relatedSurgicalPreparations',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Complications',
        accessor: 'complications',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
    ],
    []
  );
  return (
    <Table
      data={data}
      columns={columns}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      rendersColGroup
    />
  );
};

export default SurgicalTable;
