import React, { useState, useEffect } from 'react';
import Product from '../../../containers/RegistrationForms/CheckoutForm/Product';
import {
  Grid,
  Divider,
  Typography,
  Paper,
  Button,
  makeStyles,
  Container,
  Fade,
  Hidden,
} from '@material-ui/core';
import LoadingComponent from './LoadingComponent';
import productImages from '../../../containers/RegistrationForms/CheckoutForm/RegistrationPlans';
import { DOWNGRADE, UPGRADE } from './data';

const useStyles = makeStyles({
  root: {
    padding: '25px',
  },
  product: {
    paddingTop: '25px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '20px 0px',
    color: '#ffffff',
    minWidth: '100px',
    width: '160px',
  },
  buttonDisabled: {
    margin: '20px 0px',
    minWidth: '100px',
    width: '160px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  displayProduct: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  containerPaper: {
    paddingTop: '24px',
  },
  avatarSkeletonContainer: {
    height: 0,
    overflow: 'hidden',
    paddingTop: '100%',
    position: 'relative',
  },
  avatarLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const SubscriptionOptions = ({
  products,
  product,
  onSelect,
  accountRole,
  productData,
  hasFetched,
}) => {
  //paddingTop 60px for xs
  const classes = useStyles();
  const [hasProductData, setHasProductData] = useState(false); //Product is selected ?

  useEffect(() => {
    if (!!productData) {
      setHasProductData(true);
    } else setHasProductData(false);
  }, [productData]);

  const handleOnSelect = index => {
    switch (accountRole) {
      case 'Shareable':
        onSelect(DOWNGRADE, products[index]);
        break;
      case 'Basic':
        onSelect(UPGRADE, products[index]);
        break;
      default:
        return null;
    }
  };

  const renderButton = index => {
    const isCurrentPlan = accountRole === products[index].name;
    const buttonContent = isCurrentPlan
      ? 'Current Plan'
      : accountRole === 'Basic'
      ? 'Upgrade'
      : 'Downgrade';
    const buttonStyle = isCurrentPlan ? {} : { backgroundColor: '#90B850' };
    return (
      <Button
        style={buttonStyle}
        className={classes.button}
        disabled={accountRole === products[index].name}
        variant="contained"
        onClick={() => handleOnSelect(index)}
        fullWidth
      >
        {buttonContent}
      </Button>
    );
  };

  return !hasFetched ? (
    <LoadingComponent classes={classes} />
  ) : (
    <Grid container spacing={2}>
      {products.map((data, index) => (
        <Grid item xs={12} sm={12} md={6} key={'PROD' + index}>
          <Fade in={true} timeout={100} key={'FADE' + index}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.product}>
                  <Product
                    onSelect={onSelect}
                    disableUnselectedStyles={true}
                    key={data.productId}
                    elevation={8}
                    data={{ ...data, image: productImages[0] }}
                    status={
                      accountRole === data.name ? 'SELECTED' : 'NO_SELECTION'
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={classes.buttonContainer}>
                  {renderButton(index)}
                </div>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
      ))}
    </Grid>
  );
};

export default SubscriptionOptions;
