import React from 'react';
// import Checkbox from '../../Forms/CheckBox';
import { Typography, Grid, Checkbox } from '@material-ui/core';
import useStyles from '../useStyles';

const CheckboxFields = ({ onCheckBoxChange, tempSleepIssues }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography
          className={classes.header}
          style={{ marginLeft: '13px', textAlign: 'left' }}
        >
          Sleep Difficulties
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.checkBoxFields}>
          <Checkbox
            disableLabel
            value="Nightsweats"
            onChange={onCheckBoxChange}
            checked={tempSleepIssues.includes('Nightsweats')}
          />
          <Typography className={classes.checkBoxText}>Nightsweats</Typography>
        </div>

        <div className={classes.checkBoxFields}>
          <Checkbox
            disableLabel
            value="Falling Asleep"
            onChange={onCheckBoxChange}
            checked={tempSleepIssues.includes('Falling Asleep')}
          />
          <Typography className={classes.checkBoxText}>
            Falling Asleep
          </Typography>
        </div>

        <div className={classes.checkBoxFields}>
          <Checkbox
            disableLabel
            value="Staying Asleep"
            onChange={onCheckBoxChange}
            checked={tempSleepIssues.includes('Staying Asleep')}
          />
          <Typography className={classes.checkBoxText}>
            Staying Asleep
          </Typography>
        </div>

        <div className={classes.checkBoxFields}>
          <Checkbox
            disableLabel
            value="Nightmares"
            onChange={onCheckBoxChange}
            checked={tempSleepIssues.includes('Nightmares')}
          />
          <Typography className={classes.checkBoxText}>Nightmares</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default CheckboxFields;
