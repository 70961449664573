import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Popover } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import validate from './ValidateForm';
import submit from './SubmitForm';
import { cancelForm } from '../../../../store/reducers/ui/actions';
import { csvToCodeObjectList } from '../../../../library/helpers/utils';
import InputField from '../../../../library/forms/InputField';
import SelectField from '../../../../library/forms/SelectField';
import SearchSelectField from '../../../components/SearchSelect';
import { format } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormPanelButton from '../../../components/Form/FormButtonPanel';
import { hasMediaObjectUpdates } from '../../../components/MediaObject/helpers';
import DrawerAccordion from '../../../components/DrawerAccordion';
import {
  withStyles,
  Typography,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import { parseDate } from '../../../../library/utils/date';
import EasterEgg from '../../../../library/components/EasterEggComponents';
import HoverTooltip from '../../../../library/forms/HoverTooltip';
import AttachmentPanel from './AttachmentPanel';
import DrawerAccordionStandardItems from '~/src/shared/components/DrawerAccordionStandardItems/DrawerAccordionStandardItems';

const DATE_FORMAT = 'yyyy-MM-dd';

export const styles = {
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    position: 'relative',
    padding: 24,
    paddingBottom: 16,
  },
  mvsBGContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mvsBG: {
    height: '100%',
    objectFit: 'cover',
  },
  accordion: {
    padding: 24,
    // boxShadow: 'none',
  },
  accordionSummary: {
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0
    },
  },
  accordionSummaryContent: {
    '&.Mui-expanded': {
      margin: 0
    }
  },
  accordionDetails: {
    padding: 4,
  },
  heading: {
    padding: '0px 4px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
};

const MVSForm = props => {
  const {
    handleSubmit,
    medicationTypeAhead,
    medicationSearchResult,
    disableSubmit,
    action,
    medUnitsTypes,
    deliveryMethodTypes,
    deliveryFormTypes,
    classes,
  } = props;
  const entityAction = action === 'CREATE' ? 'Create' : 'Update';
  const [prescribedDate, setPrescribedDate] = useState(null);

  const onCancel = () => {
    props.cancelForm();
    props.onCancel();
  };

  const onStartDateChange = date => {
    setPrescribedDate(date);
  };

  const renderPopover = props => {
    const { message } = props;
    return (
      <Popover id="prescribed-popover" title="Field Hint">
        {message}
      </Popover>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
            <div className={classes.primaryInput}>
              <div className={classes.mvsBGContainer}>
                <img
                  src={require('../../../../client/svgs/surgical-history-add.svg')}
                  alt=""
                  className={classes.mvsBG}
                />
              </div>
              <Typography variant="h6">Enter Medication</Typography>
              <Field
                name="name"
                label="Name"
                hint="Required"
                labelKey="label"
                valueKey="value"
                options={[]}
                onTypeAhead={medicationTypeAhead}
                component={SearchSelectField}
                isSearchable
                isCreatable
                isClearable
                props={{
                  InputLabelProps: {
                    style: {
                      letterSpacing: 'normal',
                    },
                  },
                  containerStyle: {
                    backgroundColor: '#fff',
                  },
                  variant: 'filled',
                }}
              />
            </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ width: '100%', margin: 0, padding: 24 }}
      >
        <Grid item xs={12}>
          <Typography variant="h6">Details</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            name="genericName"
            label="Generic Name"
            type="text"
            component={InputField}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            type="text"
            name="prescribedBy"
            label="Prescribed By"
            component={InputField}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Field
            type="date"
            id="date"
            name="dateStarted"
            label="Started Taking Date"
            shrink={true}
            onChange={event => onStartDateChange(event.target.value)}
            component={InputField}
            props={{
              endAdornment: (
                <InputAdornment position="end">
                  <HoverTooltip text="If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team." />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Field
            type="date"
            id="date"
            name="dateDiscontinued"
            label="Stopped Taking Date"
            shrink={true}
            inputProps={{
              min: prescribedDate
                ? format(parseDate(prescribedDate), DATE_FORMAT)
                : null,
            }}
            component={InputField}
            props={{
              endAdornment: (
                <InputAdornment position="end">
                  <HoverTooltip text="If this is still ongoing, leave blank. Otherwise enter the date it was concluded. If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team." />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name="dose"
            label="Dosage"
            type="number"
            component={InputField}
            normalize={(value, previousValue) => {
              if (value.includes('.')) {
                if (value > 9999) {
                  return 9999;
                } else if (value.split('.')[1].length >= 2) {
                  return parseFloat(value).toFixed(2);
                } else {
                  return value;
                }
              } else {
                if (value.length > 4) {
                  return previousValue;
                } else {
                  return parseFloat(value);
                }
              }
            }}
            props={{
              inputProps: {
                max: 9999,
              },
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Field
            name="unit"
            label="Unit"
            labelKey="label"
            valueKey="value"
            options={medUnitsTypes}
            component={SelectField}
            isSearchable
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            name="deliveryMethod"
            label="Delivery Method"
            labelKey="label"
            valueKey="value"
            options={deliveryMethodTypes}
            component={SelectField}
            isSearchable
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            name="deliveryForm"
            label="Delivery Form"
            labelKey="label"
            valueKey="value"
            options={deliveryFormTypes}
            component={SelectField}
            isSearchable
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="text"
            name="frequency"
            label="Frequency"
            placeholder="Frequency"
            component={InputField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="relatedConditions"
            label="Related Condition(s)"
            placeholder="Type in a condition"
            labelKey="shortName"
            valueKey="longName"
            options={[]}
            fieldType="tags"
            component={SelectField}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="sideEffects"
            label="Side Effect(s)"
            labelKey="shortName"
            valueKey="longName"
            fieldType="tags"
            placeholder="Type in a side effect"
            options={[]}
            component={SelectField}
            isSearchable
            isCreatable
            isMulti
          />
        </Grid>
      </Grid>

      <DrawerAccordionStandardItems 
        additionalInformation={
          <Field
            name="prescription"
            label="Notes"
            multiline
            rows={4}
            component={InputField}
          />} 
        attachments={<AttachmentPanel />} 
      />
      <Grid container item xs={12}>
        <FormPanelButton
          actionText={`Save`}
          cancelText="Cancel"
          onCancel={onCancel}
          isDisabled={
            disableSubmit || (props.pristine && !props.hasMediaObjectUpdates)
          }
          isSubmitting={props.isSubmitting}
        />
      </Grid>
    </form>
  );
};

const formName = 'mvs-form';
const MVSFormRedux = reduxForm({
  form: formName,
  validate,
  onSubmit: submit,
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(MVSForm));

const mapStateToProps = state => {
  const {
    list,
    selectedId,
    isSubmitting,
    bundlesToDelete,
  } = state.member.medication;
  const mvs = selectedId ? list[selectedId] : {};
  return {
    isSubmitting,
    pristine:
      state.form &&
      state.form[formName] &&
      JSON.stringify(state.form[formName].initial) ===
        JSON.stringify(state.form[formName].values),
    hasMediaObjectUpdates: Boolean(
      hasMediaObjectUpdates(state) || bundlesToDelete.length
    ),
    initialValues: {
      personId: mvs.personId || '',
      medicationId: mvs.medicationId || '',
      name: mvs.name || '',
      genericName: mvs.genericName || '',
      dateStarted: mvs.dateStarted
        ? format(parseDate(mvs.dateStarted), DATE_FORMAT)
        : undefined,
      dateDiscontinued: mvs.dateDiscontinued
        ? format(parseDate(mvs.dateDiscontinued), DATE_FORMAT)
        : undefined,
      // dateStarted: mvs.datestarted ? moment(mvs.datestarted) : undefined,
      // dateDiscontinued: mvs.datediscontinued ? moment(mvs.datediscontinued) : undefined,
      dose: mvs.dose || '',
      route: mvs.route || '',
      frequency: mvs.frequency || '',
      prescribedBy: mvs.prescribedBy || '',
      prescription: mvs.prescription || '',
      deliveryMethod: mvs.deliveryMethod || '',
      deliveryForm: mvs.deliveryForm || '',
      relatedConditions: mvs.relatedConditions
        ? csvToCodeObjectList(mvs.relatedConditions)
        : undefined,
      sideEffects: mvs.sideEffects
        ? csvToCodeObjectList(mvs.sideEffects)
        : undefined,
      unit: mvs.unit || undefined,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  cancelForm: () => dispatch(cancelForm()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MVSFormRedux)
);
