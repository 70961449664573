import Contents from './Contents';
import { connect } from 'react-redux';
import {
  createSurgicalHistory,
  updateSurgicalHistory,
  deleteSurgicalHistory,
  setSelectedId,
} from '../../../../store/reducers/surgicalHistory/actions';
import {
  updateMedia,
  deleteMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import { SURGICALHISTORY } from '../../../../store/reducers/mediaObject';

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  surgicalHistory: state.surgicalHistory,
  selectedId: state.surgicalHistory.selectedId,
  surgeryList: state.surgicalHistory.list,
  surgicalHistoryTypes: state.codes.surgicalHistoryTypes,
  bundles: state.mediaObject.bundles,
  form: state.form,
  bundlesToDelete: state.surgicalHistory.bundlesToDelete,
  mediaObjects: state.surgicalHistory.mediaObjects,
});

const mapDispatchToProps = dispatch => ({
  fetchSurgicalHistory: (pid, surgicalHistoryId) =>
    dispatch(fetchSurgicalHistory(pid, surgicalHistoryId)),
  deleteSurgicalHistory: (pid, surgicalHistoryId, tid) =>
    dispatch(deleteSurgicalHistory(pid, surgicalHistoryId, tid)),
  setSelectedId: surgicalHistoryId =>
    dispatch(setSelectedId(surgicalHistoryId)),
  createSurgicalHistory: (pid, payload, bundles) =>
    dispatch(createSurgicalHistory(pid, payload, bundles)),
  updateSurgicalHistory: (pid, surgicalHistoryId, payload, bundles) =>
    dispatch(updateSurgicalHistory(pid, surgicalHistoryId, payload, bundles)),

  updateMedia: obj => dispatch(updateMedia(SURGICALHISTORY, obj)),
  deleteMedia: (mid, oid) => dispatch(deleteMedia(mid, oid, SURGICALHISTORY)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
