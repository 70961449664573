import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { objectValues } from '../../../library/helpers/utils';
import {
  Accordion,
  AccordionPanel,
} from '../../../library/components/Accordion';
import acStyles from './Accordion.css';
import ItemSelection from '../../../library/components/ItemSelection';

import styles from './MedicalConditionsForm.css';
import { Grid } from '@material-ui/core';
import BulkChangeConditions from './BulkChangeConditions';

const filterItem = (item, searchTerm = '') => {
  const { isUnknown, condition, ...rest } = item;
  if (condition.toLowerCase().includes(searchTerm.toLowerCase())) return true;
  return (
    Object.keys(rest).length &&
    Object.keys(rest).filter(val => {
      const value = item[val];
      if (val.toLowerCase().includes(searchTerm.toLowerCase())) {
        if (typeof value === 'boolean') {
          return value;
        } else {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
      } else return false;
    }).length
  );
};

class MedicalConditionsForm extends Component {
  state = {
    searchTerm: '',
  };

  relativesOptions = [
    { label: 'Me', value: 'isMe' },
    { label: 'Mother', value: 'isMother' },
    { label: 'Father', value: 'isFather' },
    { label: 'Maternal Grandmother', value: 'isMaternalGrandmother' },
    { label: 'Maternal Grandfather', value: 'isMaternalGrandfather' },
    { label: 'Paternal Grandmother', value: 'isPaternalGrandmother' },
    { label: 'Paternal Grandfather', value: 'isPaternalGrandfather' },
    { label: 'Daughter', value: 'isDaughter' },
    { label: 'Son', value: 'isSon' },
    { label: 'Brother', value: 'isBrother' },
    { label: 'Sister', value: 'isSister' },
    { label: 'None', value: 'isNone' },
    { label: 'Unknown', value: 'isUnknown' },
  ];

  constructor(props, context) {
    super(props, context);

    this.debounceSave = debounce(this.debounceSave, 1500);
  }

  /**
   * React life cycle method
   *
   * Transforms incoming historyList data into a format the accordion and related
   * UI components understand. This transformed data is saved into the component state
   *
   * @memberof MedicalConditionsForm
   */
  componentDidMount() {
    const { historyList } = this.props;

    // Only use records that have been previously populated, not
    // the default history records.
    // "00000000-0000-0000-0000-000000000000" represents default GUID id (or not set)
    const selectedItems = historyList.filter(
      c => c.familyHistoryId !== '00000000-0000-0000-0000-000000000000'
    );

    const collection = {};
    let temp;
    let key;

    // loop through only items that the user has previously populated
    for (let i = 0; i < selectedItems.length; i++) {
      temp = {
        changed: false,
        description: selectedItems[i].description,
        values: [],
      };

      // Loop through all supported boolean fields
      for (let j = 0; j < this.relativesOptions.length; j++) {
        key = this.relativesOptions[j].value;
        if (selectedItems[i][key])
          // Construct object with selected attribute (needed for selection preview)
          temp.values.push({
            ...this.relativesOptions[j],
            selected: !!selectedItems[i][key],
          });
      }

      // // Loop through all supported boolean fields
      // for (let j = 0; j < this.relativesOptions.length; j++) {
      //   if (selectedItems[i][this.relativesOptions[j].value.toLowerCase()])
      //    // temp.values.push(this.relativesOptions[j].value);
      //     temp.values.push(this.relativesOptions[j]);
      // }

      collection[selectedItems[i].condition] = temp;
    }

    this.setState({ ...collection });
  }

  handleSearch = e => {
    this.setState({
      ...this.state,
      searchTerm: e,
    });
  };

  /**
   * Every time a selection has been made, update state associated with
   * specific name (named ItemSelection container).
   *
   * @param {Event} event mouse or touch event
   * @param {object} data holds all items
   * @param {object} lastSelected holds the data of the last selected object
   * @param {String} name name given to the ItemSelection component
   */
  handleOnChangeRelativeSelection = (event, data, lastSelected, name) => {
    event.persist();

    this.setState((nextState, props) => ({
      [name]: {
        ...nextState[name],
        condition: name,
        changed: true,
        //values: data.filter(x=>x.selected).map(x=>x.value),
        values: data,
      },
    }));

    this.debounceSave(name);
  };

  /**
   * This function is debounced, or prevents execution right away
   * if called repeatedly.
   *
   * @memberof MedicalConditionsForm
   *
   * @param {object} data accordion internal format
   */
  debounceSave = name => {
    this.save(name);
  };

  /**
   * Used to dispatch user save event. Convert data from internal format
   * into external format.
   *
   * @memberof MedicalConditionsForm
   *
   * @param {object} data accordion internal format
   */
  save = name => {
    const data = this.state[name];
    if (!data) {
      return;
    }

    const payload = {
      condition: data.condition,
      description: data.description || '',
    };

    let lSide, rSide;
    for (let i = 0; i < data.values.length; i++) {
      for (let j = 0; j < this.relativesOptions.length; j++) {
        lSide = data.values[i].value.toLowerCase();
        rSide = this.relativesOptions[j].value.toLowerCase();

        if (lSide === rSide) {
          payload[this.relativesOptions[j].value] =
            data.values[i].selected || false;
          break;
        }
      }
    }

    if (this.props.onSave) {
      this.props.onSave(payload);
    }
  };

  bulkSave = data => {
    const records = [];
    for (const key in data) {
      const item = data[key];

      const payload = {
        condition: key,
        description: item.description || '',
      };

      let lSide, rSide;
      for (let i = 0; i < item.values.length; i++) {
        for (let j = 0; j < this.relativesOptions.length; j++) {
          lSide = item.values[i].value.toLowerCase();
          rSide = this.relativesOptions[j].value.toLowerCase();

          if (lSide === rSide) {
            payload[this.relativesOptions[j].value] =
              item.values[i].selected || false;
            break;
          }
        }
      }

      const notSelected = this.relativesOptions
        .filter(option => !payload.hasOwnProperty(option.value))
        .reduce((acc, option) => ({ ...acc, [option.value]: false }), {});

      records.push({ ...payload, ...notSelected });

      // if (this.props.onSave) {
      //   this.props.onSave({ ...payload, ...notSelected });
      // }
    }

    this.props.onSaveBulk(records);

    console.log('[BulkSave - MedHx Records', records);
  };

  /**
   * Used to update the description associated with a specific condition
   *
   * @memberof MedicalConditionsForm
   *
   * @param {Event} event
   * @param {String} name
   */
  handleDescriptionUpdate = (event, name) => {
    event.stopPropagation();
    event.persist();

    this.setState((nextState, props) => ({
      [name]: {
        ...nextState[name],
        condition: name,
        description: event.target.value,
      },
    }));

    this.debounceSave(name);
  };

  /**
   * Used to render each option in the ItemSelection component
   *
   * @param {object} props
   */
  renderOptionButton(props) {
    const {
      // eslint-disable-next-line
      selectedClassName,
      data,
      ...rest
    } = props;

    const allClasses = classNames({
      [styles.relOption]: true,
      [styles.relOptionSelected]: data.selected,
    });

    return (
      <Grid item xs={4} sm={3} md={2} lg={2}>
        <div className={styles.normalizePad} {...rest}>
          <div className={allClasses}>{data.label}</div>
        </div>
      </Grid>
    );
  }

  /**
   * This pure function will ensure the expected behavior for when
   * default option 'Unknown' is selected:
   *
   * 1. If no items are selected, Unknown will be selected (default case).
   * 2. If "Unknown" has been selected, all other items will be deselected.
   * 3. If "None" has been selected, all other items will be deselected.
   * 4. If any other items are selected with the exception of "Unknown" and
   *    "None" then both "Unknown" and "None" will be deselected
   * 5. If other items were selected, and "Unknown" or "None" was selected again, then
   *    all the other selected options will be deselected.
   *
   *
   * @param {object} prevData previous selections state
   * @param {object} nextData upcoming selections state
   * @param {int} total total number of selected items.
   * @return {object} upcoming selections state
   */
  handelBeforeChangeSelection(prevData, nextData, total) {
    // If no items are selected "Unknown" item will be selected by default
    if (total === 0) {
      nextData.Unknown.selected = true;

      // Deselect everything except "Unknown" if selected (the default option)
    } else if (!prevData.Unknown.selected && nextData.Unknown.selected) {
      objectValues(nextData)
        .filter(x => x.label !== 'Unknown')
        .forEach(data => {
          data.selected = false;
        });

      // Deselect all items except "None" if selected.
    } else if (!prevData.None.selected && nextData.None.selected) {
      objectValues(nextData)
        .filter(x => x.label !== 'None')
        .forEach(data => {
          data.selected = false;
        });

      // If there's more than one item selected, deselect "Unknown" and "None" items.
    } else if (total > 1) {
      nextData.Unknown.selected = false;
      nextData.None.selected = false;
    }

    return nextData;
  }

  handleBulkChange = (currentType, updatedType) => {
    let matchingCurrentType = [];

    if (currentType === 'Unknown') {
      this.props.historyList.forEach(item => {
        if (item.personId === '00000000-0000-0000-0000-000000000000') {
          matchingCurrentType.push(item);
        }
      });
    }

    for (const key in this.state) {
      const idx = this.state[key].values.map(v => v.label).indexOf(currentType);

      const selected =
        this.state[key].values[idx] && this.state[key].values[idx].selected;

      if (selected) {
        matchingCurrentType.push({ ...this.state[key], condition: key });
      }
    }

    const parsedStateValues = matchingCurrentType.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.condition]: {
          ...this.state[curr.condition],
          changed: true,
          values: [
            {
              label: updatedType,
              value: `is${updatedType}`,
              selected: true,
            },
          ],
        },
      };
    }, {});

    this.setState({
      ...this.state,
      ...parsedStateValues,
    });

    this.bulkSave(parsedStateValues);
  };

  render() {
    const { savingCondition, submitting, historyList } = this.props;

    const findRelativeFull = value =>
      this.relativesOptions.find(obj => obj.value === value).label;

    const getAttribute = (state, attr) =>
      typeof state !== 'undefined' && attr in state && state[attr]
        ? state[attr]
        : '';

    let selectedRelatives;
    let key;
    let isSelected;
    let isUnknown;
    let conditionItems = [];

    return (
      <React.Fragment>
        <BulkChangeConditions
          onChange={this.handleBulkChange}
          searchTerm={this.state.searchTerm}
          handleSearch={this.handleSearch}
        />
        <Accordion className={acStyles.accordionWrapper}>
          {historyList
            .filter(item => filterItem(item, this.state.searchTerm.trim()))
            .sort((a, b) => {
              if (a.condition > b.condition) return 1;
              if (a.condition < b.condition) return -1;
              return 0;
            })
            .map((fmhItem, index) => {
              key = '';
              isSelected = false;
              isUnknown = true;
              conditionItems = [];

              // Short circuit evaluation if condition is unknown or no history record exists
              if (
                fmhItem.familyHistoryId ===
                  '00000000-0000-0000-0000-000000000000' ||
                !!fmhItem.isUnknown
              ) {
                const unknownIndex = this.relativesOptions.findIndex(
                  x => x.label === 'Unknown'
                );

                const options = this.relativesOptions.slice();
                conditionItems = options;
                conditionItems[unknownIndex] = {
                  ...options[unknownIndex],
                  selected: true,
                };
              } else {
                // Loop through all relative options
                for (let i = 0; i < this.relativesOptions.length; i++) {
                  // Get the key for the boolean flag. Note: must be lowercase
                  // because of how the field name was implemented on stream -- err.
                  key = this.relativesOptions[i].value;

                  // selected boolean flag should evaluate as a boolean, either true or false.
                  isSelected = !!fmhItem[key];

                  // Check if boolean flag is selected and the selected options is still unknown.
                  // If not, then set unknown as false since we know at least one value is true.
                  // The isUnknown flag is set once for the entirety of the loop.
                  if (isSelected === true && isUnknown === true)
                    isUnknown = false;

                  // Populate items expected for interface component.
                  conditionItems.push({
                    ...this.relativesOptions[i],
                    selected: key === 'isUnknown' ? isUnknown : isSelected,
                  });
                }
              }

              const conditionName = fmhItem.condition;

              selectedRelatives = '';
              if (
                this.state[conditionName] &&
                this.state[conditionName].values
              ) {
                selectedRelatives = this.state[conditionName].values.map(
                  (v, i) =>
                    v.selected === true && (
                      <span className={styles.relSelection} key={i}>
                        {findRelativeFull(v.value)}{' '}
                      </span>
                    )
                );
              }

              const SavingIndicator = ({ isSaving }) =>
                isSaving && (
                  <div className="pull-right" style={{ color: '#AAA' }}>
                    Saving...
                  </div>
                );

              return (
                <AccordionPanel
                  key={index}
                  header={
                    <div>
                      {conditionName}
                      <SavingIndicator isSaving={fmhItem.status === 'SAVING'} />
                    </div>
                  }
                  headerClosed={
                    <div>
                      <div>
                        {conditionName}
                        <SavingIndicator
                          isSaving={fmhItem.status === 'SAVING'}
                        />
                      </div>
                      {selectedRelatives}
                    </div>
                  }
                >
                  <div>
                    <div className={styles.noGutterLeft} key={index}>
                      <ItemSelection
                        name={conditionName}
                        itemKey="label"
                        itemValue="value"
                        selectedClassName="selected-item"
                        items={conditionItems}
                        itemComponent={this.renderOptionButton}
                        beforeChange={this.handelBeforeChangeSelection}
                        onChange={this.handleOnChangeRelativeSelection}
                      />
                    </div>

                    <div style={{ paddingRight: '10px' }}>
                      <textarea
                        onChange={event =>
                          this.handleDescriptionUpdate(event, conditionName)
                        }
                        className={styles.notes}
                        name="notes"
                        cols="30"
                        rows="4"
                        placeholder="Additional notes..."
                        value={getAttribute(
                          this.state[conditionName],
                          'description'
                        )}
                      ></textarea>
                    </div>
                  </div>
                </AccordionPanel>
              );
            })}
        </Accordion>
      </React.Fragment>
    );
  }
}

MedicalConditionsForm.propTypes = {
  historyList: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default MedicalConditionsForm;
