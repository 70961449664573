import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';
import { Grid, Divider } from '@material-ui/core';

import {
  fetchBloodType,
  fetchContactType,
  fetchEthnicityType,
  fetchGenderType,
  fetchLanguageType,
  fetchMaritalType,
  fetchReligionType,
  fetchStateType,
  fetchVeteranType,
} from '../../../store/reducers/codes/actions';

import { createMessage } from '../../../store/reducers/flashMessenger/actions';

import {
  DEPENDENT_CREATE_SUCCESS,
  DEPENDENT_CREATE_FAILURE,
  createDependent,
} from '../../../store/reducers/dependents/action';

import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../store/reducers/ui/actions';

import BasePage from '../BasePage';
import PageHeader from '../../components/PageHeader';
import DependentForm from '../../containers/DependentForm';
// import './SignUpDependentPage.css';

class SignUpDependentPage extends Component {
  state = {
    personId: 0,
    currentEmail: '',
    height: {
      foot: 0,
      inches: 0,
    },
  };

  componentDidMount() {
    if (this.props.bloodTypes.length === 0) {
      this.props.fetchBloodType();
    }

    if (this.props.contactTypes.length === 0) {
      this.props.fetchContactType();
    }

    if (this.props.ethnicityTypes.length === 0) {
      this.props.fetchEthnicityType();
    }

    if (this.props.genderTypes.length === 0) {
      this.props.fetchGenderType();
    }

    if (this.props.languageTypes.length === 0) {
      this.props.fetchLanguageType();
    }

    if (this.props.maritalTypes.length === 0) {
      this.props.fetchMaritalType();
    }

    if (this.props.religionTypes.length === 0) {
      this.props.fetchReligionType();
    }

    if (this.props.stateTypes.length === 0) {
      this.props.fetchStateType();
    }

    if (this.props.veteranTypes.length === 0) {
      this.props.fetchVeteranType();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unblockNavigation());
  }

  onSubmit = async (values, dispatch) => {
    const { state, gender } = values;
    let height = 0;

    if (
      (this.state.height.error &&
        !this.state.height.error.length > 0 &&
        this.state.height.foot) ||
      this.state.height.inches
    ) {
      if (this.state.height.foot > 0) height += this.state.height.foot * 12;
      if (this.state.height.inches) height += this.state.height.inches;
    }

    const payload = {
      ...values,
      height: height > 0 ? height : '',
      // state: state ? state : undefined,
      // gender: gender ? gender : undefined
    };

    console.log('payload', payload);
    const result = await dispatch(
      createDependent(this.props.personId, payload)
    );
    if (result.type === DEPENDENT_CREATE_SUCCESS) {
      dispatch(unblockNavigation());
      dispatch(
        createMessage(
          'We successfully added your dependent! ' +
            'So you can easily access this new dependents information, we made them accessible through a tab in the member/dependents bar.' +
            'You can control the visibility of all tabs using the dependents page.',
          'success'
        )
      );
      this.props.history.push('/d/dependents');
    } else if (result.type === DEPENDENT_CREATE_FAILURE) {
      throw new SubmissionError(result.payload.errors);
    }
  };

  onCancel = () => {
    this.props.history.push('/d/dependents');
  };

  onHeightChange = (name, value) => {
    let error = '';

    //errors handling
    if (name === 'foot') {
      if (value > 9 || this.state.height.inches > 11) error = 'Invalid Entry';
    }
    if (name === 'inches') {
      if (value > 11 || this.state.height.foot > 9) error = 'Invalid Entry';
    }

    if (typeof value === 'number') {
      //filter out NaN values
      this.setState({
        ...this.state,
        height: {
          ...this.state.height,
          [name]: value,
          error: error,
        },
      });
    }
  };

  render() {
    return (
      <BasePage>
        <Grid item xs={12}>
          <PageHeader
            headerText="Dependents"
            subHeaderText="New Dependent"
            backButtonText="Go Back"
          />
          <Divider style={{ marginTop: 24 }} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <DependentForm
            isNavigationLocked={this.props.ui.isNavigationLocked}
            lockedMessage={this.props.ui.lockedMessage}
            bloodTypes={this.props.bloodTypes}
            ethnicityTypes={this.props.ethnicityTypes}
            genderTypes={this.props.genderTypes}
            languageTypes={this.props.languageTypes}
            maritalTypes={this.props.maritalTypes}
            contactTypes={this.props.contactTypes}
            religionTypes={this.props.religionTypes}
            stateTypes={this.props.stateTypes}
            veteranTypes={this.props.veteranTypes}
            isFetchingGenderType={this.props.codeTypes.isFetchingGenderType}
            isFetchingBloodType={this.props.codeTypes.isFetchingBloodType}
            isFetchingLanguageType={this.props.codeTypes.isFetchingLanguageType}
            isFetchingEthnicityType={
              this.props.codeTypes.isFetchingEthnicityType
            }
            isFetchingReligionType={this.props.codeTypes.isFetchingReligionType}
            isFetchingMaritalType={this.props.codeTypes.isFetchingMaritalType}
            isFetchingVeteranType={this.props.codeTypes.isFetchingVeteranType}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onChange={(v, d, props) => {
              if (!props.pristine && !this.props.ui.isNavigationLocked) {
                this.props.dispatch(blockNavigationWithStandardMsg());
              }
            }}
            onHeightChange={this.onHeightChange}
            heightFieldErrors={this.state.height.errors}
          />
        </Grid>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  codeTypes: state.codes,
  bloodTypes: state.codes.bloodTypes,
  ethnicityTypes: state.codes.ethnicityTypes,
  genderTypes: state.codes.genderTypes,
  languageTypes: state.codes.languageTypes,
  maritalTypes: state.codes.maritalTypes,
  contactTypes: state.codes.contactTypes,
  religionTypes: state.codes.religionTypes,
  stateTypes: state.codes.stateTypes,
  veteranTypes: state.codes.veteranTypes,
  personId: state.member.login.details.personId,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchBloodType: () => dispatch(fetchBloodType()),
  fetchContactType: () => dispatch(fetchContactType()),
  fetchEthnicityType: () => dispatch(fetchEthnicityType()),
  fetchGenderType: () => dispatch(fetchGenderType()),
  fetchLanguageType: () => dispatch(fetchLanguageType()),
  fetchMaritalType: () => dispatch(fetchMaritalType()),
  fetchReligionType: () => dispatch(fetchReligionType()),
  fetchStateType: () => dispatch(fetchStateType()),
  fetchVeteranType: () => dispatch(fetchVeteranType()),
});

export default {
  component: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SignUpDependentPage)
  ),
};
