import React from 'react';
import PageHeader from '../../components/PageHeader';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const SyncPermissionsHeader = ({
  onClick,
  isBrowsing,
  onClickBack,
  isEmpty,
  disableButton,
}) => {
  const BrowsingProps = isBrowsing
    ? {
        isHistoryBackDisabled: true,
        onBack: onClickBack,
      }
    : {};

  return (
    <PageHeader
      headerText={isBrowsing ? 'Browse Sync Partners' : 'Sync Permissions'}
      subHeaderText={isBrowsing ? '' : 'Manage Data exchange Settings'}
      backButtonText="Go Back"
      containerStyle={{ minHeight: '102px' }}
      {...BrowsingProps}
      rightSlot={
        isEmpty || isBrowsing || disableButton ? (
          ''
        ) : (
          <div
            style={{
              height: '100%',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <div>
              <IconButton
                variant="contained"
                style={{
                  marginTop: 10,
                  borderRadius: 0,
                  background: '#006AB0',
                  color: '#fff',
                }}
                onClick={onClick}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        )
      }
    />
  );
};

export default SyncPermissionsHeader;
