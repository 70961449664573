import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  withStyles,
  Typography,
} from '@material-ui/core';
import { RedToolTip } from '../../Forms/ErrorTooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const SelectComponent = withStyles({
  underline: {
    display: 'none',
  },
})(Select);

export const DISTANCE_UNITS = {
  km: {
    unit: 'km',
    singular: 'km',
    plural: 'km',
  },
  laps: {
    unit: 'laps',
    singular: 'lap',
    plural: 'laps',
  },
  miles: {
    unit: 'miles',
    singular: 'mile',
    plural: 'miles',
  },
  steps: {
    unit: 'steps',
    singular: 'step',
    plural: 'steps',
  },
  // See HS-1227 for more details
  // floor: {
  //   unit: 'floors',
  //   singular: 'floor',
  //   plural: 'floors',
  // },
};

const UNITS = Object.keys(DISTANCE_UNITS);
const UnitSelectField = props => {
  const {
    input: { onChange, value },
    singular,
    meta: { error },
    ...rest
  } = props;
  return (
    <div>
      <RedToolTip
        PopperProps={{
          disablePortal: true,
        }}
        // classes={{
        //   tooltip: classes.customTooltip,
        //   arrow: classes.customArrow,
        // }}
        arrow
        disableFocusListener
        disableTouchListener
        placement="bottom"
        title={
          <div
            style={{
              margin: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flexStart',
            }}
          >
            <ErrorOutlineIcon style={{ fontSize: '18px' }} />
            <Typography style={{ fontSize: '10px', marginLeft: '5px' }}>
              {error}
            </Typography>
          </div>
        }
        leaveDelay={500}
        open={Boolean(error)}
      >
        <div>
          <FormControl
            fullWidth
            style={{
              marginTop: '14px',
              height: '39px',
              marginLeft: '4px',
              maxWidth: '70px',
            }}
          >
            <SelectComponent
              value={value}
              onChange={onChange}
              displayEmpty
              style={{
                fontSize: '14px',
                opacity: value === '' ? '0.5' : 1,
                color: '#0068A8',
              }}
            >
              <MenuItem
                key="default-menu"
                value=""
                disabled
                style={{ fontSize: '14px', opacity: '0.5', color: '#0068A8' }}
              >
                Select
              </MenuItem>
              {UNITS.reduce((acc, element) => {
                if (value == element) {
                  return [element, ...acc];
                }
                return [...acc, element];
              }, []).map((unit, index) => {
                return (
                  <MenuItem
                    key={`${unit}-${index}`}
                    value={DISTANCE_UNITS[unit].unit}
                    style={{ fontSize: '14px', color: '#0068A8' }}
                  >
                    {singular
                      ? DISTANCE_UNITS[unit].singular
                      : DISTANCE_UNITS[unit].plural}
                  </MenuItem>
                );
              })}
            </SelectComponent>
          </FormControl>
        </div>
      </RedToolTip>
    </div>
  );
};

export default UnitSelectField;
