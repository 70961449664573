import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import GeneralFormInfoFields from '../FormFields/GeneralFormInfoFields';
import SsnFormField from '../FormFields/SsnFormField';
import AddressFormFields from '../FormFields/AddressFormFields';
import PhoneFormFields from '../FormFields/PhoneFormFields';
import DemographicFormFields from '../FormFields/DemographicFormFields';
import FieldGroupHeader from '../../components/Form/FieldGroupHeader';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import HeightFields from '../../containers/FormFields/HeightFields';
import ProgressButtonLinear from '../../components/Buttons/ProgressButtonLinear';

import validate from './ValidateForm';
import submit from './SubmitForm';

// eslint-disable-next-line
import './DependentForm.css';

class DependentForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting, onHeightChange } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FieldGroupHeader headerText="General" IsNotMiddleHeading />
        <GeneralFormInfoFields
          ssnField={<SsnFormField />}
          isDependent={true}
          HeightFields={
            <HeightFields
              onChange={onHeightChange}
              error={this.props.heightFieldErrors}
              initialFeetValue={null}
              initialInchesValue={null}
            />
          }
        />

        <FieldGroupHeader headerText="Address" />
        <AddressFormFields stateTypes={this.props.stateTypes} />

        <FieldGroupHeader headerText="Contact" />
        <PhoneFormFields
          required={false}
          contactTypes={this.props.contactTypes}
        />

        <FieldGroupHeader headerText="Demographic" />
        <DemographicFormFields
          genderTypes={this.props.genderTypes}
          bloodTypes={this.props.bloodTypes}
          languageTypes={this.props.languageTypes}
          ethnicityTypes={this.props.ethnicityTypes}
          religionTypes={this.props.religionTypes}
          maritalTypes={this.props.maritalTypes}
          veteranTypes={this.props.veteranTypes}
          isFetchingGenderType={this.props.isFetchingGenderType}
          isFetchingBloodType={this.props.isFetchingBloodType}
          isFetchingLanguageType={this.props.isFetchingLanguageType}
          isFetchingEthnicityType={this.props.isFetchingEthnicityType}
          isFetchingReligionType={this.props.isFetchingReligionType}
          isFetchingMaritalType={this.props.isFetchingMaritalType}
          isFetchingVeteranType={this.props.isFetchingVeteranType}
        />

        <Grid
          container
          style={{ marginTop: 24 }}
          justify="flex-end"
          spacing={3}
        >
          <Grid item xs={12}>
            <DashboardMessage canClose={false}>
              By clicking the register dependent button, I hereby declare that I
              have the authority to manage this dependent’s health and medical
              information. Further, I acknowledge that the dependent will not
              have their own login.
            </DashboardMessage>
          </Grid>
          <Grid item xs={6} sm={2} style={{ display: 'flex' }}>
            <Button
              type="button"
              fullWidth={true}
              color="default"
              onClick={this.props.onCancel}
              size="large"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={4}>
            <ProgressButtonLinear
              variant="contained"
              type="submit"
              fullWidth={true}
              size="large"
              isProgressing={submitting}
              disabled={pristine || submitting}
              onClick={this.props.onSubmit}
            >
              Register Dependent
            </ProgressButtonLinear>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const connectedReduxForm = reduxForm({
  form: 'dependent-form',
  forceUnregisterOnUnmount: true,
  validate,
  touchOnChange: true,
})(DependentForm);

export default withRouter(connectedReduxForm);
