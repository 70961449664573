import React from 'react';
import AccentedPaper from '../../../../library/components/AccentedPaper/AccentedPaper';
import { Grid, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { SUBSCRIPTION_OPTIONS } from '../../../pages/AccountManagement/AccountManagement';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import BigNumber from 'bignumber.js';

BigNumber.set({ DECIMAL_PLACES: 2, ROUNDING_MODE: BigNumber.ROUND_DOWN });

const useStyles = makeStyles({
  root: {
    padding: '25px',
  },
  subscription: {
    color: '#444447',
  },
  text1: {
    fontSize: '14px',
    fontStyle: 'italic',
    color: '#444447',
  },
  text2: {
    fontSize: '14px',
    color: '#444447',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '10px',
  },
});

const productData = {
  Shareable: {
    subscriptionPeriod: 'yearly',
  },
  Basic: {
    subscriptionPeriod: 'Free forever',
  },
};

const SubscriptionCard = ({
  onClick,
  accountRole,
  startOn,
  isSubsidized,
  orderTotal,
}) => {
  const classes = useStyles();
  return (
    <AccentedPaper width="100%">
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {/* {accountRole} */} Universal Health Record
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography className={classes.text1}>As of:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.text2}>{startOn}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography className={classes.text1}>Price:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.text2}>$0.00 / mo</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.text1}>Expires On:</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.text2}>Never</Typography>
        </Grid>
        {isSubsidized && (
          <Grid item xs={12}>
            <Typography className={classes.text1}>
              You are receiving a Subscription subsidy from one of our Partners.
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* <div className={classes.cardActions}>
        <Tooltip arrow placement="top" title="Edit Subscription">
          <IconButton
            color="primary"
            disableFocusRipple
            onClick={() => onClick(SUBSCRIPTION_OPTIONS)}
          >
            <i className="fa fa-edit" />
          </IconButton>
        </Tooltip>
      </div> */}
    </AccentedPaper>
  );
};

SubscriptionCard.propTypes = {
  onClick: PropTypes.func,
  accountRole: PropTypes.string,
  startOn: PropTypes.string,
};

SubscriptionCard.defaultProps = {};

export default SubscriptionCard;
