/**
 * Return the best image format based on purpose. If the purpose is
 * "grid" return the smallest format out of LG, MD, and SM. Return T0
 * (thumbnail size) if not formats are provided and type is "grid." If the
 * purpose is "preview" return the largest format specified (ignoring XL). Or
 * if no format is provided, OG (default original size)
 *
 * @param {Array} formats LG, MD, SM
 * @param {string} type preview | grid.
 * @returns {string}
 */

import {
  MVS,
  JOURNAL,
  ALLERGY,
  IMMUNIZATION,
  DOCUMENTS,
  LABRESULT,
  INSURANCE_CARD,
} from '../../../store/reducers/mediaObject';

export const getBestImageFormat = (formats, type = 'preview') => {
  if (!formats) return 'self';

  type = type.toLowerCase();

  const rankFormats = {
    LG: 0,
    MD: 1,
    SM: 2,
  };

  const slotLength = Object.keys(rankFormats).length;
  const slots = Array.from({ length: slotLength }, () => 0);

  formats
    .filter(x => rankFormats[x] >= 0)
    .forEach(x => (slots[rankFormats[x]] = x));

  const final = slots.filter(x => x !== 0);

  if (final.length === 0 && type === 'grid') return 'formatT0';

  if (final.length === 0) return 'self';

  if (type === 'preview') return `format${final[0]}`;

  if (type === 'grid') return `format${final.reverse()[0]}`;

  return '';
};

import {
  MIME_TYPE_IMAGE_JPG,
  MIME_TYPE_IMAGE_JPEG,
  MIME_TYPE_IMAGE_PNG,
  MIME_TYPE_IMAGE_WEBP,
  MIME_TYPE_VIDEO_MP4,
  MIME_TYPE_APPLICATION_PDF,
  MIME_TYPE_APPLICATION_WORD,
  MIME_TYPE_APPLICATION_DOCX,
  MIME_TYPE_APPLICATION_EXCEL,
  MIME_TYPE_APPLICATION_XLSX,
  MIME_TYPE_TEXT_PLAIN,
  MIME_TYPE_TEXT_CSV,
  MIME_TYPE_TEXT_PSV,
  MIME_TYPE_TEXT_TSV,
} from '../../../library/helpers/mimeTypes';

export const getMediaType = mimeType => {
  switch (mimeType.toLowerCase()) {
    case MIME_TYPE_APPLICATION_PDF:
      return 'PDF';

    case MIME_TYPE_APPLICATION_WORD:
    case MIME_TYPE_APPLICATION_DOCX:
      return 'WORD';

    case MIME_TYPE_APPLICATION_EXCEL:
    case MIME_TYPE_APPLICATION_XLSX:
      return 'EXCEL';

    case MIME_TYPE_VIDEO_MP4:
      return 'VIDEO';

    case MIME_TYPE_IMAGE_JPG:
    case MIME_TYPE_IMAGE_JPEG:
    case MIME_TYPE_IMAGE_PNG:
    case MIME_TYPE_IMAGE_WEBP:
      return 'IMAGE';

    case MIME_TYPE_TEXT_PLAIN:
    case MIME_TYPE_TEXT_CSV:
    case MIME_TYPE_TEXT_PSV:
    case MIME_TYPE_TEXT_TSV:
      return 'TEXT';

    default:
      throw Error(`Mime type "${mimeType}" not supported.`);
  }
};

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');

  tempLink.href = url;
  tempLink.download = filename;

  // Prevent potential memory leak
  // Cleanup objectURL resources that are no longer in use.
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      tempLink.removeEventListener('click', clickHandler);
      document.body.removeChild(tempLink);
    }, 123);
  };

  tempLink.addEventListener('click', clickHandler, false);

  document.body.appendChild(tempLink);

  tempLink.click();
};

export const getMediaObjectsForRecordByAppArea = (state, objectId, appArea) => {
  switch (appArea.toUpperCase()) {
    case ALLERGY:
      return state.allergies.list[objectId]
        ? state.allergies.list[objectId].mediaObjects
        : null;
    case MVS:
      return state.member.medication.list[objectId]
        ? state.member.medication.list[objectId].mediaObjects
        : null;
    case IMMUNIZATION:
      return state.member.userImmunizations.list[objectId]
        ? state.member.userImmunizations.list[objectId].mediaObjects
        : null;
    case JOURNAL:
      return state.journalEntries.list[objectId]
        ? state.journalEntries.list[objectId].mediaObjects
        : null;
    case DOCUMENTS:
      return state.documents.list[objectId]
        ? state.documents.list[objectId].mediaObjects
        : null;
    default:
      throw Error(`Unsupported app area ${appArea}`);
  }
};
