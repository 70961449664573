const ENV_DEVELOPMENT = 'development';
const ENV_STAGING = 'staging';
const ENV_TESTING = 'testing';

const isProtectedEnvironment = () =>
  [ENV_DEVELOPMENT, ENV_TESTING, ENV_STAGING].includes(process.env.NODE_ENV);

/**
 * The purpose of the code below, override the default behaviors of the window.console methods.
 * This code will override log, info, warn and error. In addition a new custom method is added,
 * caution (which produces a stack trace).
 *
 * Note: these log messages will only render in a non-production environment.
 */
const console = (prevConsole => {
  return {
    ...prevConsole,

    log: (...args) => {
      if (isProtectedEnvironment()) prevConsole.log.apply(null, args);
    },

    info: (...args) => {
      if (isProtectedEnvironment()) prevConsole.info.apply(null, args);
    },

    warn: (...args) => {
      if (isProtectedEnvironment()) prevConsole.warn.apply(null, args);
    },

    error: (...args) => {
      if (isProtectedEnvironment()) prevConsole.error.apply(null, args);
    },

    banner: () => {
      prevConsole.log(
        `

      %c🚨 WARNING %c
      %c
    Be cautious of what you copy and paste here.
    No AgrinHealth representative will ever ask you to execute code in the console.
    For further assistance please contact mailto://support@agrinhealth.com\n\n`,
        'display: block; font-size: 24px; margin-top: 25px; padding: 8px; background-color: rgba(41, 0, 1, 1); color: rgba(222, 39, 44, 1); border-radius: 5px; border:solid 1px rgba(222, 39, 44, 1);',
        '',
        'display: block; font-size: 14px; padding: 8px;'
      );
    },

    // Custom log message type which produces a stack trace
    // (line number being the desired element) and does not
    // throw an exception.
    caution: message => {
      if (isProtectedEnvironment()) prevConsole.error(new Error(message).stack);
    },
  };
})(window.console);

window.console = console;
