import React from 'react';
import Table from '../../../components/ReactTable';
import { CONDITIONS } from '../../../../store/reducers/mediaObject';
import { dateTimeRender } from '~/src/shared/components/ReactTable/utils';

const ConditionsTable = props => {
  const getData = () =>
    props.listIds.map(id => {
      return {
        ...props.list[id],
        id,
        type: CONDITIONS,
      };
    });

  React.useEffect(() => {
    setData(getData());
  }, [props.list, props.listIds]);
  const [data, setData] = React.useState(getData());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Diagnosis Date',
        accessor: 'diagnosedOn',
        col: '15%',
        Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
      },
      {
        Header: 'Diagnosis name',
        accessor: 'diagnosisDesc',
        col: '30%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Medications',
        accessor: 'relatedMvs',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Treatments',
        accessor: 'relatedTreatments',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
      {
        Header: 'Symptoms',
        accessor: 'relatedSymptoms',
        col: '15%',
        Cell: ({ value }) => value || '--',
      },
    ],
    []
  );
  return (
    <Table
      data={data}
      columns={columns}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      rendersColGroup
    />
  );
};

export default ConditionsTable;
