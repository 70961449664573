import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';

import SelectField from '../../../library/forms/BareSelectField';
import DateTimeInput from '../../../library/components/DateTimeInput';

const styles = theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  datetime: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expand: {
    width: '100%',
  },
});

const WhoDatetimeInput = props => {
  const { classes } = props;

  const [isTimeSynced, setTimeSynced] = useState(true);

  useEffect(() => {
    if (props.date) {
      setTimeSynced(false);
    }
  }, [props.isJournalOpened, props.date, props.usersList, props.selectedUser]);

  const getOptionLabel = option => option.label;
  const getOptionValue = option => option.value;

  return (
    <div className={classes.expand}>
      <Grid container>
        <Grid item xs={6} alignItems="center" spacing={1}>
          <div className={classes.expand}>
            <SelectField
              options={props.usersList}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isSearchable
              // React redux expects these fields, however, they are not being used atm.
              input={{
                value: props.usersList.find(
                  user => user.value === props.selectedUser
                ),
                onChange: props.onChangeUser,
              }}
              meta={{
                touched: false,
                active: true,
                error: '',
              }}
              noOptionsMessage={() => (
                <span>
                  No <strong>"user"</strong> or <strong>"dependent"</strong> is
                  found
                </span>
              )}
            />
          </div>
        </Grid>
        <Grid container item xs={6} className={classes.datetime}>
          <DateTimeInput
            date={props.date}
            onChange={(date, action) => {
              if (action === 'user-selection') {
                props.onChangeDateTime(date);
                setTimeSynced(false);
              }
            }}
            isTimeSynced={isTimeSynced}
          />
          {/* {props.locationButton} */}
        </Grid>
      </Grid>
    </div>
  );
};

// class WhoDatetimeInput extends React.Component {

//   component

//   render() {
//     const { classes } = this.props;

//     const getOptionLabel = option => option.label;
//     const getOptionValue = option => option.value;

//     return (
//       <div className={ classes.expand }>
//         <Grid container>
//           <Grid container item xs={6} alignItems="center">
//             <div className={ classes.expand }>
//               <SelectField
//                 options={this.props.usersList}
//                 getOptionLabel={getOptionLabel}
//                 getOptionValue={getOptionValue}
//                 isSearchable
//                 // React redux expects these fields, however, they are not being used atm.
//                 input={{value: this.props.selectedUser, onChange: this.props.onChangeUser}}
//                 meta={{touched: false, error: '', active: true }}
//                 noOptionsMessage={() => <span>No <strong>"user"</strong> or <strong>"dependent"</strong> is found</span>}/>
//             </div>
//           </Grid>
//           <Grid container item xs={6} className={classes.datetime}>
//             <DateTimeInput
//               date={this.props.date}
//               onChange={this.props.onChangeDatetime} />
//             { this.props.locationButton }
//           </Grid>
//         </Grid>
//         <Divider className={ classes.divider } />
//       </div>
//     )
//   }
// }

WhoDatetimeInput.propTypes = {
  onChangeUser: PropTypes.func.isRequired,
  onChangeDatetime: PropTypes.func,
  usersList: PropTypes.array,
  selectedUser: PropTypes.object,
};

export default withStyles(styles)(WhoDatetimeInput);
