import { ALLERGEN_REQUEST, ALLERGEN_SUCCESS, ALLERGEN_FAILURE } from './actions';

const initialState = {
  isFetchingAllergens: true,
  fetchFailed: false,
  allergens: []
};

export default (state = initialState, action) => {

  // Short circuit if attempting to request again when a request is 
  // already being made. 
  if (action.type === ALLERGEN_REQUEST && state.isFetchingAllergens) {
    return state;
  }

  switch(action.type) {

    case ALLERGEN_REQUEST:
      return {
        ...state,
        isFetchingAllergens: true,
      }

    case ALLERGEN_SUCCESS:
      return {
        ...state,
        isFetchingAllergens: false,
        fetchFailed: false,
        allergens: action.payload,
      }

    case ALLERGEN_FAILURE:
      return {
        ...state,
        allergens: [],
        fetchFailed: true,
        isFetchingAllergens: false
      }

    default: 
      return state;
  }
}