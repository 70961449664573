import React, { useState, useEffect } from 'react';
import wordicon from '../../../client/images/word-icon.png';
import pdficon from '../../../client/images/pdf-icon.png';
import excelicon from '../../../client/images/excel-icon.png';
import IconButton from '@material-ui/core/IconButton';
import styles from './File.css';

export default ({ bundle }) => {
  const renderIcon = () => {
    if (Object.keys(bundle).length) {
      let icon;
      const extensionType = bundle.file.name.split('.');
      if (extensionType[1] === 'pdf') {
        icon = <img src={pdficon} alt="PDF File" />;
      } else if (
        extensionType[1] === 'docx' ||
        extensionType[1] === 'doc' ||
        extensionType[1] === 'txt'
      ) {
        icon = <img src={wordicon} alt="Word File" />;
      } else if (extensionType[1] === 'xls' || extensionType[1] === 'xlsx') {
        icon = <img src={excelicon} alt="Excel File" />;
      } else if (
        extensionType[1] === 'jpeg' ||
        extensionType[1] === 'jpg' ||
        extensionType[1] === 'png'
      ) {
        icon = (
          <div className={styles.fileImageBlock}>
            <div className={styles.responsive}>
              <img
                src={bundle.file.preview}
                alt={bundle.file.name}
                className={styles.imgCover}
              />
            </div>
          </div>
        );
      } else {
        icon = (
          <div className={styles.fileImageBlock}>
            <div className={styles.responsive}>
              <video className={styles.imgCover}>
                <source src={bundle.file.preview} type="video/mp4" />
              </video>
            </div>
          </div>
        );
      }
      return icon;
    }
  };

  return (
    <div className={styles.preview}>
      {Object.keys(bundle).length && bundle.isUploaded && (
        <div className={styles.overlay}>
          <i className="fa fa-check-square fa-3x"></i>
        </div>
      )}
      {renderIcon()}
    </div>
  );
};
