import {FLASH_MESSAGE_CREATE, FLASH_MESSAGE_REMOVE} from './actions';

const initialState = {
  hasMessage: false,
  message: ''
};

export default (state = initialState, action) => {

  switch(action.type) {
    case FLASH_MESSAGE_CREATE:
      return {
        ...state,
        hasMessage: true,
        message: action.payload,
        timeout: action.meta.timeout || null,
        type: action.meta.type
      }

    case FLASH_MESSAGE_REMOVE:
      return {
        ...state,
        hasMessage: false,
        message: '',
        timeout: null,
        type: ''
      };

    default: 
      return state
  }
}