import React from 'react';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FullWidthTabs from './Tabs';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';

const VerifyIdentityForm = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const tabsProps = { handleChangeIndex, handleChange, value, ...props };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Paper elevation={isSmallScreen ? 0 : 1}>
          <Header index={value} classes={classes} />
          <div className={classes.footer}>
            <FullWidthTabs {...tabsProps} />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VerifyIdentityForm;
