import React from 'react';
import { makeStyles, withStyles, darken } from '@material-ui/core';
import { Box, Button, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // alignItems: 'center',
    position: 'relative',
  },
  buttonProgress: {
    width: 'calc(100% - 10px)',
    position: 'absolute',
    top: '88%',
    left: 5,
  },
}));

const ProgressBar = withStyles({
  root: {
    height: 3,
    // Not support by the current version of material UI.
    // backgroundColor: darken('#fff', 0.2),

    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    // Not support by the current version of material UI.
    // backgroundColor: darken('#fff', 0.05),
  },
})(LinearProgress);

const ProgressButtonLinear = ({
  children,
  isProgressing,
  color = 'secondary',
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Button {...props} color={color} variant="contained" size="large">
        {children}
      </Button>
      <Box className={classes.buttonProgress}>
        {isProgressing && <ProgressBar />}
      </Box>
    </Box>
  );
};

export default ProgressButtonLinear;
