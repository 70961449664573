import React from 'react';
import HeightField from './HeightFields';

export default function HeightReduxField({
  input,
  initialValues,
  meta,
  ...rest
}) {
  const { initial } = meta;
  return (
    <HeightField
      initialFeetValue={
        initial && !!(Math.floor(parseInt(initial) / 12) > 0)
          ? parseInt(initial) === 12
            ? 1
            : Math.floor(initial / 12)
          : null
      }
      initialInchesValue={
        initial && !!(parseInt(initial) % 12 > 0)
          ? initial === 12
            ? null
            : parseInt(initial) % 12
          : null
      }
      {...meta}
      {...input}
      {...rest}
      shouldSendBothValues={true}
      onChange={value => {
        let valueInInches = value['inches'] ? value['inches'] : 0;
        if (value['foot']) {
          valueInInches += value['foot'] * 12;
        }
        input.onChange(valueInInches);
      }}
    />
  );
}
