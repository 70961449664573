import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  fetchJournalEntries,
  fetchFragmentsByType,
  clearFragments,
} from '../../../store/reducers/journalEntries/actions';
import { Grid, Divider, Button, Fade, Collapse } from '@material-ui/core';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import BasePage from '../BasePage';
import WelcomeComponent from '../../components/ChartComponents/Charts/WelcomeComponent';
import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
} from '../../components/JournalCompose/FragmentsList';
import useUIState, { status } from './useUIState';
import { format } from 'date-fns-tz';
import { subMonths, subYears } from 'date-fns';

import Loading from '../../components/ChartComponents/Loading';
import Chart from '../../components/ChartComponents/Charts';
import ChartsHeader from './ChartsHeader';

const date = new Date();
const currDate = date.setHours(24, 0, 0, 0);
const fragmentList = [
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
];

function ChartsPage(props) {
  const {
    clearFragments,
    hasFetchedFragments,
    timeZoneIANA,
    personId,
    isParsing,
    fragments,
    error,
    fetchFragmentsByType,
  } = props;

  const [data, setData] = useState({});
  const [selectedChart, setSelectedChart] = useState('');
  const [currPerson, setCurrPerson] = useState(personId);
  const { LOADING, EMPTY, SUCCESS, ERROR } = status;
  const [state, setState] = useUIState(
    !hasFetchedFragments,
    error,
    data,
    props.personId
  );

  const fetchFragments = () => {
    const startDate = format(
      subMonths(currDate, 12),
      "yyyy-MM-dd'T'HH:mm:ssXXX",
      { timeZoneIANA }
    );
    const endDate = format(currDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZoneIANA,
    });
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    fragmentList.forEach(type => {
      fetchFragmentsByType(type, params, personId);
    });
  };

  useEffect(() => {
    if (!hasFetchedFragments) {
      fetchFragments();
      return;
    }
    const _data = {};
    Object.keys(fragments).forEach(item => (_data[item] = fragments[item]));
    setData(_data);
    setSelectedChart(
      props.location.state && props.location.state.fragmentType
        ? props.location.state.fragmentType
        : ''
    );
  }, []);

  useEffect(() => {
    setState(LOADING);
    if (currPerson !== personId) {
      clearFragments();
      setData({});
      fetchFragments();
      setCurrPerson(personId);
    }
  }, [personId, timeZoneIANA]);

  useEffect(() => {
    if (hasFetchedFragments) {
      const _data = {};
      Object.keys(fragments).forEach(item => (_data[item] = fragments[item]));
      setData(_data);
      setSelectedChart(
        props.location.state && props.location.state.fragmentType
          ? props.location.state.fragmentType
          : ''
      );
    }
  }, [hasFetchedFragments, fragments]);

  const renderView = () => {
    switch (state) {
      case LOADING:
        return <Loading />;
      case EMPTY:
        return <WelcomeComponent type="empty" />;
      case SUCCESS:
        return <Chart data={data} selectedChart={selectedChart} />;
      case ERROR:
        return <div></div>;
    }
  };

  const handleChange = e => setSelectedChart(e.target.value);

  return (
    <BasePage
      HeaderComponent={
        <ChartsHeader
          data={data}
          selectedChart={selectedChart}
          handleChange={handleChange}
          isLoading={state === LOADING}
          isEmpty={state === EMPTY}
          state={state}
        />
      }
    >
      <Grid item xs={12}>
        <Collapse in={Boolean(error.length)} timeout={150}>
          <div style={{ width: '100%', maxWidth: '600px', marginTop: '8px' }}>
            <DashboardMessage canClose={true} type="warning">
              {error}
            </DashboardMessage>
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        {renderView()}
      </Grid>
    </BasePage>
  );
}

const mapStateToProps = state => {
  return {
    timeZoneIANA: state.app.timeZoneIANA,
    fragments: state.journalEntries.fragments,
    isParsing: state.journalEntries.isParsingFragments,
    hasFetchedFragments: state.journalEntries.hasFetchedFragments,
    hasRecords: state.journalEntries.hasRecords,
    error: state.journalEntries.error,
    personId: state.active.user.personId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchJournalEntries: meta => dispatch(fetchJournalEntries(meta)),
  fetchFragmentsByType: (type, params, pid) =>
    dispatch(fetchFragmentsByType(type, params, pid)),
  clearFragments: () => dispatch(clearFragments()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ChartsPage)),
};
