import WaterChart from './WaterIntakeChart';

export const theme = {
  '<10 oz': {
    background: '#99CFF1',
    color: '#212121',
  },
  '10 - 24 oz': {
    background: '#6AB3E0',
    color: '#212121',
  },
  '25 - 39 oz': {
    background: '#1886CA',
    color: '#212121',
  },
  '40 - 54 oz': {
    background: '#0068A8',
    color: '#f5f5f5',
  },
  '55+ oz': {
    background: '#083957',
    color: '#f5f5f5',
  },
};

export default WaterChart;
