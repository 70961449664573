import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Paper,
  Grid,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import MemberDetailAvatar from '../MemberDetailAvatar';
import MemberDetailLabel from '../MemberDetailLabel';
import { MemberDetailField } from '..';
import {
  RELATIONSHIP_STATUS_ARCHIVED,
  ROLE_NAME_GUARDIAN_PRIMARY,
  ROLE_NAME_PROVIDER,
} from '../RoleSummary/data';
import MemberAddressField from '../MemberAddressField';
import MemberActionButtons from './MemberActionButtons';
import MemberDetailToggleField from '../MemberDetailToggleField';

const styles = {
  root: {
    padding: 15,
    margin: '1.5em 0',
    boxShadow: '0 1px 6px rgba(216,220,222,.67)',
  },
  header: {
    fontWeight: 500,
    margin: '0px 0 4px',
  },
  date: {
    fontWeight: 400,
    color: '#AEAEAE',
  },
};

const DEFAULT_FIELD_VALUE = 'Not Specified';

const defaultValue = (value, replacement) => {
  return value === undefined || value === 'NULL' || value.trim().length === 0
    ? replacement
    : value;
};

const MemberDetailRow = props => {
  const { member, classes } = props;
  const [isEditable, setIsEditable] = useState(true);
  const [isDeletable, setIsDeletable] = useState(true);
  const [isArchivable, setIsArchivable] = useState(true);
  const [isSubsidy, setIsSubsidy] = useState(false);

  useEffect(() => {
    if (member.relationshipType === ROLE_NAME_GUARDIAN_PRIMARY) {
      setIsEditable(false);
    }
    if (!!member.subsidy) {
      setIsSubsidy(true);
    }
  }, []);

  const renderDate = () =>
    `Since ${format(parseISO(member.associatedOn), 'MMMM do, yyyy')}`;

  const submitInlineEdit = values => {
    console.log('');
    props.submitInlineEdit(member.relationshipId, { ...member, ...values });
  };

  const handleEdit = () => {
    props.onEdit(member);
  };

  const handleArchive = () => {
    props.onArchive(member);
  };

  const handleDelete = () => {
    props.onDelete(member.relationshipId);
  };

  // const prefix = member.prefix ? `${member.prefix}.` : ""
  // const displayName = `${prefix} ${member.firstName} ${member.lastName}`.trim()

  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center">
        <Grid item container xs={4} direction="row" alignItems="center">
          <MemberDetailAvatar member={member} />
          <Grid item xs={9}>
            <Typography variant="subtitle2" className={classes.date}>
              {renderDate()}
            </Typography>
            <Typography variant="h5" className={classes.header}>
              {member.displayName}
            </Typography>
            <Grid container justify="flex-start" direction="row">
              <MemberDetailLabel label={member.relationshipType} forRole />
              {member.labels &&
                member.labels.map(label => (
                  <MemberDetailLabel
                    key={label.longName}
                    label={label.shortName}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MemberAddressField
            onSubmit={submitInlineEdit}
            icon="place"
            member={member}
            isEditable={false}
          />
        </Grid>
        <Grid item xs={3}>
          {member.relationshipType === ROLE_NAME_PROVIDER ? (
            <MemberDetailToggleField
              field1={{
                icon: 'local_phone',
                value: defaultValue(member.phoneNumber, DEFAULT_FIELD_VALUE),
              }}
              field2={{
                icon: 'print',
                value: defaultValue(member.faxNumber, DEFAULT_FIELD_VALUE),
              }}
            />
          ) : (
            <MemberDetailField
              icon="local_phone"
              isEditable={false}
              dataLine={defaultValue(member.phoneNumber, DEFAULT_FIELD_VALUE)}
              onSubmit={submitInlineEdit}
            />
          )}
          <MemberDetailField
            icon="email"
            isEditable={false}
            dataLine={defaultValue(member.email, DEFAULT_FIELD_VALUE)}
            onSubmit={submitInlineEdit}
          />
        </Grid>
        <Grid item container xs={1} justify="flex-end" wrap="nowrap">
          <MemberActionButtons
            isSubsidy={isSubsidy}
            member={member}
            onEdit={handleEdit}
            onArchive={handleArchive}
            onDelete={handleDelete}
            isEditable={isEditable}
            isDeletable={isDeletable}
            isArchivable={isArchivable}
          />
        </Grid>
        {/* {member.relationshipType !== ROLE_NAME_PROVIDER ? (
            <IconButton color="secondary" onClick={handleEdit}>
              <Icon>create</Icon>
            </IconButton>
          ) : (
            ""
          )}
          {member.relationshipType !== ROLE_NAME_GUARDIAN_PRIMARY &&
          member.status === RELATIONSHIP_STATUS_ARCHIVED ? (
            <IconButton color="secondary" onClick={handleArchive}>
              <Icon>unarchive</Icon>
            </IconButton>
          ) : member.relationshipType !== ROLE_NAME_GUARDIAN_PRIMARY ? (
            <IconButton style={{ color: "#ED5158" }} onClick={handleArchive}>
              <Icon>archive</Icon>
            </IconButton>
          ) : (
            ""
          )} */}
      </Grid>
    </Paper>
  );
};

MemberDetailRow.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
};

export default withStyles(styles)(MemberDetailRow);
