import React, { Component } from "react";
import PropTypes from "prop-types";

class Toggle extends Component {
  state = {
    isOn: false
  };

  componentDidMount() {
    this.setState({ isOn: this.props.isOn });
  }

  onClick = () => {
    const isOn = !this.state.isOn;
    this.props.onChange(isOn);
    this.setState({ isOn });
  };

  render() {
    const { OnComponent, OffComponent } = this.props;
    return (
      <div onClick={this.onClick}>
        {this.state.isOn ? (
          <OnComponent />
        ) : (
          <OffComponent style={{ opacity: 0.4 }} />
        )}
      </div>
    );
  }
}

Toggle.propTypes = {
  isOn: PropTypes.bool.isRequired,
  OnComponent: PropTypes.func.isRequired,
  OffComponent: PropTypes.func.isRequired
};

Toggle.defaultProps = {
  isOn: false
};

export default Toggle;
