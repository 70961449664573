import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedContainer from '../FormFields/OutlinedContainer';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UrlSelectForm = props => {
  const {
    input,
    label,
    hint,
    meta: { touched, error },
    shrink,
    theme,
    valueKey,
    options,
    initialValue,
    ...attributes
  } = props;

  const classes = useStyles();
  const [protocol, setProtocol] = React.useState('https://');
  const [url, setUrl] = React.useState('');

  React.useState(() => {
    if (!!initialValue && initialValue.includes('http://')) {
      setProtocol('http://');
    }
    if (!!initialValue) {
      setUrl(initialValue.replace(/(^\w+:|^)\/\//, ''));
    }
  }, [initialValue]);

  const handleChange = event => {
    setUrl(event.target.value);
    input.onChange(`${protocol}${event.target.value}`);
  };

  const handleProtocolChange = event => {
    setProtocol(event.target.value);

    if (url) {
      input.onChange(`${event.target.value}${url}`);
    }
  };

  return (
    <div style={{ marginBottom: '-4px', minHeight: '80px' }}>
      <OutlinedContainer
        label="Website"
        InputProps={{
          style: { padding: '4.025px 14px' },
        }}
        hint="Required"
      >
        <TextField
          id="standard-select-currency"
          select
          value={protocol}
          onChange={handleProtocolChange}
          style={{
            maxWidth: 'fit-content',
          }}
          InputProps={{
            disableUnderline: true,
          }}
        >
          <MenuItem key={'1'} value={'http://'}>
            http://
          </MenuItem>
          <MenuItem key={'2'} value={'https://'}>
            https://
          </MenuItem>
        </TextField>
        <TextField
          id="standard-select-currency"
          value={url}
          onChange={e => handleChange(e)}
          required
          placeholder="E.g., medicalportal.com"
          style={{
            width: '80%',
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </OutlinedContainer>
    </div>
  );
};

export default UrlSelectForm;
