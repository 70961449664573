import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
export const SUBSCRIPTION_CHANGE_REQUEST = 'subscription/change/request';
export const SUBSCRIPTION_CHANGE_SUCCESS = 'subscription/change/success';
export const SUBSCRIPTION_CHANGE_FAILURE = 'subscription/change/failure';

export const SUBSCRIPTION_REQUEST = 'subsription/person/request';
export const SUBSCRIPTION_SUCCESS = 'subsription/person/success';
export const SUBSCRIPTION_FAILURE = 'subsription/person/failure';

export const subscriptionChangeRequest = payload => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      SUBSCRIPTION_CHANGE_REQUEST,
      SUBSCRIPTION_CHANGE_SUCCESS,
      SUBSCRIPTION_CHANGE_FAILURE,
    ],
    endpoint: `/api/subscriptions/change`,
    payload,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
        mode: 'cors',
      },
    },
  },
});

export const fetchSubscription = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [SUBSCRIPTION_REQUEST, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_FAILURE],
    endpoint: `/api/subscriptions/person`,
  },
});
