import { omit } from 'lodash';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  ALLERGY_REQUEST,
  ALLERGY_SUCCESS,
  ALLERGY_FAILURE,
  ALLERGY_CREATE_REQUEST,
  ALLERGY_CREATE_SUCCESS,
  // ALLERGY_CREATE_FAILURE,
  ALLERGY_UPDATE_REQUEST,
  ALLERGY_UPDATE_SUCCESS,
  ALLERGY_UPDATE_FAILURE,
  ALLERGY_DELETE,
  ALLERGY_DELETE_SUCCESS,
  ALLERGY_DELETE_FAILURE,
  ALLERGY_SELECT_ID,
  ALLERGY_CLEAR_ID,
  ALLERGY_UPDATE_MEDIA,
  ALLERGY_DELETE_MEDIA_CANCEL,
  ALLERGY_DELETE_MEDIA,
  ALLERGY_REMOVE_MEDIA,
  ALLERGY_SUBMIT,
} from './actions';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
} from '../mediaObject/actions';
import { ALLERGY } from '../mediaObject';

import { MEDIA_UPDATE_REQUEST } from '../mediaObjectDrawer/actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

// isFetching: indicates that a request has been sent to the server to download data
// isSubmitting: indicates attempt for creating a new entity
// didInvalidate: indicates if a data is stale (changes have been made and store needs to be refreshed)
// list: contains entity object data in a hash map data structure
// listIds: contains all entity ids (are used to select and interact with entities in a list)
// selectedId: contains ID of selected entity which a user intends to interact with
// page: used for pagination logic, number represents current page.
// totalPages: used for pagination, indicates total number of pages.
// draft: contains user's unfinished entity entry.
const initialState = {
  isFetching: false,
  isSubmitting: false,
  list: {},
  listIds: [],
  selectedId: null,
  draft: {},
  page: 0,
  totalPages: 0,
  uiState: LOADING,

  mediaObjects: {},
  uploadingMediaObjects: false,
  bundlesToDelete: [],
};

const reducers = [];

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  // Only process allergy associated actions.
  if (action.meta.resource !== 'ALLERGY') return state;
  const resourceId = state.selectedId || action.meta.objectId;
  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  const resource = state.list[resourceId];

  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    // case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:
    //   return {
    //     ...state,
    //     list: {
    //       ...state.list,
    //       [resourceId]: {
    //         ...resource,
    //         mediaObjects: [].concat(action.payload, resource.mediaObjects),
    //       },
    //     },
    //   };

    // Update the selected media object in the associated allergy (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE:
      return {
        ...state,
        list: {
          ...state.list,
          [resourceId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.map(updateMediaObject),
          },
        },
      };

    // Remove selected media object from the associated allergy (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE:
      return {
        ...state,
        isSubmitting: true,
        list: {
          ...state.list,
          [resourceId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            x => x.mediaObjectId !== action.payload
          ),
        ],
      };

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case ALLERGY_SUBMIT: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case MEDIA_UPDATE_REQUEST: {
      if (action.appArea !== ALLERGY) return state;
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case ALLERGY_REQUEST:
      return {
        ...state,
        uiState: LOADING,
        isFetching: true,
      };

    case ALLERGY_SUCCESS:
      const listIds = getKeys('allergyId', payload) || [];
      return {
        ...state,
        isFetching: false,
        list: arrayToHashMap('allergyId', payload) || {},
        listIds,
        uiState: listIds.length ? SUCCESS : EMPTY,
      };

    case ALLERGY_FAILURE:
      return {
        ...state,
        list: {},
        listIds: [],
        isFetching: false,
        uiState: ERROR,
      };

    case ALLERGY_CREATE_REQUEST:
      return {
        ...state,
        errorMessage: undefined,
        list: {
          ...state.list,
          [action.meta.tid]: action.payload,
        },
        listIds: [].concat([action.meta.tid], state.listIds),
        isSubmitting: true,
      };

    case ALLERGY_CREATE_SUCCESS:
      const entry = state.list[action.meta.tid];
      const { mediaObjects, ...remainingEntry } = entry;

      const updateRecord = {
        ...entry,
        ...action.payload,
        mediaObjects: action.bundlesToAttach,
      };

      return {
        ...state,
        errorMessage: undefined,
        didInvalidate: false,
        isSubmitting: false,
        uploadingMediaObjects: false,

        list: Object.keys(state.list).reduce((object, key) => {
          if (key === action.meta.tid) {
            return { ...object, [action.payload.allergyId]: updateRecord };
          }
          return { ...object, [key]: state.list[key] };
        }, {}),
        listIds: state.listIds.map(listId => {
          if (listId === action.meta.tid) {
            return action.payload.allergyId;
          }
          return listId;
        }),
        uiState: state.uiState === EMPTY ? SUCCESS : state.uiState,
      };

    case ALLERGY_UPDATE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };

    case ALLERGY_UPDATE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        errorMessage: action.payload.message,
      };

    case ALLERGY_UPDATE_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        didInvalidate: true,
        list: {
          ...state.list,
          [state.selectedId]: {
            ...action.payload,
            mediaObjects: [
              ...state.list[state.selectedId].mediaObjects,
              ...action.bundlesToAttach,
            ],
          },
        },
      };
    }

    case ALLERGY_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload]: {
            ...state.list[action.payload],
            isDeleting: true,
          },
        },
      };

    case ALLERGY_DELETE_SUCCESS:
      const allergyId = action.payload.allergyId;
      const newListIds = state.listIds.filter(idx => idx !== allergyId);
      const newAllergyList = omit(state.list, allergyId);

      return {
        ...state,
        list: newAllergyList,
        listIds: newListIds,
        uiState: newListIds.length > 0 ? SUCCESS : EMPTY,
      };

    case ALLERGY_DELETE_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload]: {
            ...state.list[action.payload],
            isDeleting: false,
          },
        },
      };

    case ALLERGY_SELECT_ID:
      return {
        ...state,
        selectedId: action.payload,
      };

    case ALLERGY_CLEAR_ID:
      return {
        ...state,
        selectedId: null,
      };

    case ALLERGY_UPDATE_MEDIA: {
      const mediaObjects = state.mediaObjects;
      if (Boolean(action.payload.formName)) {
        mediaObjects[action.payload.formName] = action.payload.pristine;
      }
      return {
        ...state,
        mediaObjects,
      };
    }

    case ALLERGY_DELETE_MEDIA_CANCEL: {
      return {
        ...state,
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            item => item.mediaObjectId !== action.payload
          ),
        ],
      };
    }

    case ALLERGY_DELETE_MEDIA: {
      const _bundlesToDelete = Boolean(
        state.bundlesToDelete.find(
          item => item.mediaObjectId === action.payload.mediaObjectId
        )
      )
        ? [
            ...state.bundlesToDelete.map(item => {
              if (item.mediaObjectId === action.payload.mediaObjectId) {
                return action.payload;
              }
              return item;
            }),
          ]
        : [
            ...state.bundlesToDelete,
            {
              mediaObjectId: action.payload.mediaObjectId,
              objectId: action.payload.referenceId ? state.selectedId : null,
              referenceId: action.payload.referenceId || null,
            },
          ];

      return {
        ...state,
        bundlesToDelete: _bundlesToDelete,
      };
    }

    case ALLERGY_REMOVE_MEDIA: {
      const mediaObjects = {
        ...state.mediaObjects,
      };
      delete mediaObjects[`bundle${action.payload}`];
      return {
        ...state,
        mediaObjects,
      };
    }

    // TODO: We need to refactor this reducer to match the standard
    // pattern. For now we'll take a shortcut.
    case RESOURCE_MEDIA_MODIFIED:
      return reducers[RESOURCE_MEDIA_MODIFIED](state, action);

    default:
      return state;
  }
};
