import React from 'react';
import { Button, Grid, Icon, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import FormPanel from '../../../components/Form/FormPanel';
// eslint-disable-next-line
import ProgressButtonLinear from '../../../components/Buttons/ProgressButtonLinear';
import { DashboardMessage } from '../../../components/DashboardContent';

const formName = 'member-signup';

const styles = theme => ({
  container: {
    minHeight: '100vh',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    display: 'flex',
  },
  successContainer: {
    minHeight: 300,
  },
  successIcon: {
    color: theme.palette.secondary.main,
    fontSize: 76,
  },
  successHeader: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  successSubHeader: {
    fontSize: 18,
    color: '#75747B',
    marginBottom: theme.spacing(3),
  },
  warningMessage: {
    marginBottom: theme.spacing(3),
  },
});

const VerificationSuccess = ({ classes }) => (
  <Grid
    className={classes.successContainer}
    container
    justify="center"
    alignItems="center"
    direction="column"
  >
    <Icon className={classes.successIcon}>check_circle</Icon>

    <Typography className={classes.successHeader} variant="body1">
      Success!
    </Typography>
    <Typography className={classes.successSubHeader} variant="body2">
      Your Agrin Health account has been created. Now you can signin.
    </Typography>
  </Grid>
);

const GeneralInfoForm = ({ submitting, handleSubmit, classes }) => {
  return (
    <div className={classes.container}>
      <FormPanel
        onSubmit={handleSubmit}
        formSlot={() => (
          <React.Fragment>
            <VerificationSuccess classes={classes} />
          </React.Fragment>
        )}
        bottomLeftSlot={() => {}}
        bottomRightSlot={() => (
          <Grid
            container
            item
            spacing={3}
            justify="flex-end"
            style={{ display: 'flex' }}
          >
            <Grid
              container
              item
              xs={12}
              sm={6}
              justify="flex-end"
              style={{ display: 'flex' }}
            >
              <ProgressButtonLinear
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                className={classes.primaryButton}
                fullWidth
                isProgressing={submitting}
              >
                Finished
              </ProgressButtonLinear>
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
};

GeneralInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
  form: formName,
})(GeneralInfoForm);

export default withStyles(styles)(form);
