import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import MediaObjectHeader from './Header';
import MediaObjectFile from './File';
import { Collapse, Typography } from '@material-ui/core';
import {
  setFiles,
  removeFile,
  setDescription,
  setMediaDate,
  setMediaTime,
  uploadFiles,
} from '../../../store/reducers/mediaObject';
import DashboardMessage from './ErrorMessage';
import {
  MIME_TYPES_IMAGE,
  MIME_TYPES_ALL,
} from '../../../library/helpers/mimeTypes';

class MediaObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: '',
      error: {
        isOpen: false,
        fileName: [],
      },
    };
  }

  /**
   * Every time a user provides new files this method will be called.
   * The user can provide 1:N files. Files that are found as duplicates will not
   * be added to the bundles array.
   *
   * @param files (FileList)
   * @return void
   */
  handleOnDrop = files => {
    const { bundles, appArea } = this.props;

    let temp = [];
    let found = false;

    if (bundles.length > 0)
      for (var i = 0; i < files.length; i++) {
        found = false;
        for (var j = 0; j < bundles.length; j++) {
          // If the file is found in the new files array, and in state.bundles
          // break from the
          if (files[i].name === bundles[j].file.name) {
            found = true;
            break;
          }
        }

        // If the file is not found, then it's considered unique.
        if (!found) {
          temp.push({
            appArea,
            file: files[i],
            description: '',
            loaded: 0,
            total: files[i].size,
            isUploading: false,
            isUploaded: false,
          });
        }
      }
    else {
      temp = files.map(file => ({
        appArea,
        file,
        description: '',
        loaded: 0,
        total: file.size,
        isUploading: false,
        isUploaded: false,
      }));
    }

    const newBundles = bundles.concat(temp);

    if (newBundles.length > 0) {
      this.props.setFiles(newBundles);
    }

    if (this.state.error.isOpen) {
      this.setState({
        ...this.state,
        error: {
          isOpen: false,
          fileName: [],
        },
      });
    }
  };

  handleDescriptionChanged = (index, description) => {
    this.props.setDescription(index, description);
  };

  handleDateChanged = (index, date) => {
    this.props.setMediaDate(index, date);
  };

  handleTimeChanged = (index, time) => {
    this.props.setMediaTime(index, time);
  };

  handleRemoveMediaObject = (event, index) => {
    event.stopPropagation();
    this.props.removeFile(index);
  };

  changeCurrentIndex = index => {
    this.setState({
      currentIndex: index,
    });
  };

  handleUploadFiles = (event, _) => {
    event.stopPropagation();
    this.props.uploadFiles(this.props.bundles, { id: 1 });
  };

  handleOnDropRejected = files => {
    this.setState({
      ...this.state,
      error: {
        isOpen: true,
        fileName: files.map(file => file.name),
      },
    });
  };

  handleOnMessageClose = () => {
    this.setState({
      ...this.state,
      error: {
        isOpen: false,
        fileName: [],
      },
    });
  };

  render() {
    const {
      bundles,
      uploadingProcess,
      isSubmitting,
      mode,
      appArea,
      multiple = true,
      disabledMap = false,
      ...rest
    } = this.props;

    const dropZoneStyle = {
      width: 'auto',
      height: 'auto',
      cursor: 'pointer',
    };

    return (
      <React.Fragment>
        {!this.props.disableDropzone && (
          <Dropzone
            style={dropZoneStyle}
            onDropRejected={files => this.handleOnDropRejected(files)}
            onDrop={this.handleOnDrop}
            multiple={multiple}
            // accept="image/jpeg,image/png,video/mp4,application/pdf,.doc,.docx,text/plain"
            accept={MIME_TYPES_ALL.join(',')}
          >
            {({ isDragActive }) =>
              !isSubmitting && <MediaObjectHeader isDragActive={isDragActive} />
            }
          </Dropzone>
        )}
        <Collapse in={this.state.error.isOpen}>
          <DashboardMessage
            fileName={this.state.error.fileName}
            handleOnMessageClose={this.handleOnMessageClose}
          />
        </Collapse>
        {!disabledMap &&
          bundles
            .filter(x => x.appArea === appArea)
            .map((bundle, index) => (
              <MediaObjectFile
                key={bundle.file.name}
                index={index}
                mode={mode}
                isSubmitting={isSubmitting}
                currentIndex={this.state.currentIndex}
                bundle={bundle}
                uploadingProcess={uploadingProcess}
                changeCurrentIndex={this.changeCurrentIndex}
                removeObject={event =>
                  this.handleRemoveMediaObject(event, index)
                }
                descriptionChanged={this.handleDescriptionChanged}
                dateChanged={this.handleDateChanged}
                timeChanged={this.handleTimeChanged}
              />
            ))}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  const { isUploadingFiles, bundles } = state.mediaObject;
  const { uploadingProcess } = state.mediaObjectDrawer;
  const disableDropzone =
    state.mediaObjectDrawer.drawerStatus === 'ADD' && bundles.length > 0;
  return {
    isUploadingFiles,
    bundles,
    uploadingProcess,
    disableDropzone,
  };
};

const mapDispatchToProps = dispatch => ({
  uploadFiles: (files, meta) => dispatch(uploadFiles(files, meta)),
  setFiles: files => dispatch(setFiles(files)),
  setDescription: (index, description) =>
    dispatch(setDescription(index, description)),
  setMediaDate: (index, date) => dispatch(setMediaDate(index, date)),
  setMediaTime: (index, time) => dispatch(setMediaTime(index, time)),
  removeFile: index => dispatch(removeFile(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaObject);
