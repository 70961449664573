import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Tabs, Tab, Tooltip, IconButton } from '@material-ui/core/';
import styles from '../styles/MediaObjectDrawer.css';
import MediaEditMode from '../EditMode';
import MediaAddMode from '../AddMode/MediaAddMode';
import MediaDeleteMode from '../DeleteMode/MediaObjectDelete';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getMedia,
  setMedia,
  updateMedia,
  setCurrentItem,
  setItemByIndex,
  setHeaderState,
  setDrawerState,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import { DRAWER_STATUS_ADD } from '../../../../store/reducers/mediaObjectDrawer/reducers';
import {
  documentCreate,
  documentDelete,
  documentUpdate,
  toggleDocumentsDrawer,
} from '../../../../store/reducers/documents/actions';
import MediaCustomDialog from './MediaCustomDialog';
import MediaSubmitProgress from './MediaSubmitProgress';

class MediaObjectDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right: false,
      setOpen: false,
      fileURL: '',
      primaryId: 0,
      areaType: this.props.areaType,
      isSubmitting: false,
      disablePhotosButton: Boolean(this.props.disablePhotosButton)
        ? true
        : false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.areaType !== prevProps.areaType) {
      if (Boolean(this.props.isAddingDocument)) {
        this.props.setDrawerState(DRAWER_STATUS_ADD);
      }
    }

    if (this.props.currentMediaFile !== prevProps.currentMediaFile) {
      this.setState({
        currentMediaFile: this.props.currentMediaFile,
      });
    }

    if (this.props.areaType !== prevProps.areaType) {
      this.setState({ areaType: this.props.areaType });
    }
  }

  // function to open drawer with view mode and edit mode
  // 1) VIEW Mode : to display media files list with grid view and list view structure
  // 2) EDIT Mode : for initialize or open edit mode which allow to update information of selected media file
  drawerDisplayMode = (modeType, filetype, fileObject, index) => {
    this.setState({
      mode: modeType,
      filetype: filetype,
      // currentMediaFile: fileObject,
      currentMediaIndex: index,
      offset: index,
    });
  };

  // There are two dialog box type
  // 1) DELETE : To delete any media object file
  // 2) PREVIEW : Preview any media object file in dialog box
  // Function to change the state or selection of dialog box which is currently needs to be display
  dialogMode = (status, type, fileURL, mediaObj) => {
    // console.log(status, type, fileURL, mediaObj);
    if (type) {
      this.setState({
        setOpen: status,
        dialogType: type,
        fileURL: fileURL,
        // currentMediaFile: mediaObj,
      });
    } else {
      this.setState({
        setOpen: status,
        // currentMediaFile: mediaObj,
      });
    }
  };

  // function to submit form data
  onSubmitMediaObject = async payload => {
    const {
      drawerStatus,
      setDrawerState,
      documentCreate,
      documentUpdate,
      currentMediaFile,
      toggleDocumentsDrawer,
    } = this.props;
    if (
      drawerStatus === 'ADD' ||
      (drawerStatus === 'SUBMITTING' && !this.state.isSubmitting)
    ) {
      const { personId, data, bundle } = payload;
      this.setState({
        ...this.state,
        isSubmitting: true,
        bundle: bundle,
      });
      setDrawerState('SUBMITTING');
      await documentCreate(personId, data, bundle);
    } else if (drawerStatus === 'EDIT') {
      const { personId, data } = payload;
      // console.log(payload);
      this.setState({
        ...this.state,
        isSubmitting: true,
      });
      await documentUpdate(personId, currentMediaFile.mediaObjectId, data);
    }
    toggleDocumentsDrawer(false);
  };

  handleOnDelete = async document => {
    const {
      setDrawerState,
      documentDelete,
      activeUser,
      currentMediaFile,
    } = this.props;
    setDrawerState('DELETING');
    await documentDelete(activeUser.personId, currentMediaFile.mediaObjectId);
  };

  renderView = () => {
    switch (this.props.drawerStatus) {
      case 'DELETING': {
        return (
          <MediaDeleteMode fileName={this.props.currentMediaFile.fileName} />
        );
      }
      case 'SUBMITTING': {
        return (
          <MediaSubmitProgress
            bundle={this.state.bundle}
            uploadingProcess={this.props.uploadingProcess}
          />
        );
      }
      case 'ADD':
        return (
          <MediaAddMode
            areaType={this.state.areaType}
            objectId={this.state.primaryId}
            onClickCancel={() => this.props.onClose()}
            onSubmit={this.onSubmitMediaObject}
          />
        );
      case 'VIEW':
      case 'EDIT':
        if (this.props.isFetchingMedia) {
          return (
            <React.Fragment>
              {Boolean(Object.keys(this.props.currentMediaFile).length) && (
                <MediaEditMode
                  primaryId={this.state.primaryId}
                  personId={this.props.activeUser.personId}
                  currentMediaFile={this.props.currentMediaFile}
                  dialogMode={this.dialogMode}
                  viewMode={this.props.drawerStatus}
                  onSubmitUpdate={this.onSubmitMediaObject}
                  onClickCancel={() => this.props.setDrawerState('VIEW')}
                  onClickEdit={() => this.props.setDrawerState('EDIT')}
                  isSubmitting={this.state.isSubmitting}
                  handleSubmit={this.onSubmitMediaObject}
                  handleOnDelete={this.handleOnDelete}
                />
              )}
            </React.Fragment>
          );
        } else {
          return <div>Loading...</div>;
        }
      default:
        return <div></div>;
    }
  };

  render() {
    return (
      <div onClick={e => e.stopPropagation()}>
        <Drawer
          anchor="right"
          open={this.props.open}
          onClose={e => this.props.onClose(e, false)}
        >
          <div className={styles.rightDrawerPanel}>
            <Tooltip title="Close">
              <div className={styles.overviewBackBtn}>
                <IconButton
                  size="small"
                  onClick={e => this.props.onClose(e, false)}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={0}
              variant="fullWidth"
            >
              <Tab label="MEDIA MANAGER" disableFocusRipple disableRipple />
            </Tabs>
            <div className={styles.mediaHolder}>{this.renderView()}</div>
            {this.state.setOpen && (
              <MediaCustomDialog
                primaryId={this.state.primaryId}
                areaType={this.state.areaType}
                drawerDisplayMode={this.drawerDisplayMode}
                currentMediaFile={this.props.currentMediaFile}
                fileURL={this.state.fileURL}
                dialogType={this.state.dialogType}
                dialogMode={this.dialogMode}
              />
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentMediaFile: state.mediaObjectDrawer.currentMediaFile,
  currentMediaFileIndex: state.mediaObjectDrawer.currentMediaFileIndex,
  drawerStatus: state.mediaObjectDrawer.drawerStatus,
  headerStatus: state.mediaObjectDrawer.headerStatus,
  isFetchingMedia: state.mediaObjectDrawer.isFetchingMedia,
  activeUser: state.active.user,
  isSubmitting: state.documents.isSubmitting,
  isDrawerOpen: state.documents.isDrawerOpen,
});

const mapDispatchToProps = dispatch => ({
  getMedia: (pid, aid, area) => dispatch(getMedia(pid, aid, area)),
  setMedia: bundle => dispatch(setMedia(bundle)),
  updateMedia: (obj, appArea) => dispatch(updateMedia(appArea, obj)),
  setItemByIndex: index => dispatch(setItemByIndex(index)),
  setHeaderState: state => dispatch(setHeaderState(state)),
  setDrawerState: state => dispatch(setDrawerState(state)),
  setCurrentItem: obj => dispatch(setCurrentItem(obj)),
  documentCreate: (id, objectId, bundles, area) =>
    dispatch(documentCreate(id, objectId, bundles, area)),
  documentDelete: (id, did) => dispatch(documentDelete(id, did)),
  documentUpdate: (pid, did, document) =>
    dispatch(documentUpdate(pid, did, document)),
  toggleDocumentsDrawer: bool => dispatch(toggleDocumentsDrawer(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaObjectDrawer);
