import CheckboxField from '../../../../../library/forms/CheckboxField';
import InputField from '../../../../../library/forms/InputField';
import SelectField from '../../../../../library/forms/SelectField';
import {
  postalCodeMask,
  postalCodeNormalizer,
} from '../../../../components/RequestMedicalRecord/validations';
import { required } from '../../../../components/JournalCompose/fragments/Validations';

/**
 * @type {import('../AddDependent/types').FieldOptions}
 */
const IDENTIFYING_INFORMATION = [
  {
    name: 'firstName',
    label: 'First Name',
    component: InputField,
    type: 'text',
    validate: [required],
    required: true,
    grid: {
      xs: 6,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'middleName',
    label: 'Middle Name',
    component: InputField,
    type: 'text',
    grid: {
      xs: 6,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    validate: [required],
    component: InputField,
    type: 'text',
    required: true,
    grid: {
      xs: 6,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'suffix',
    label: 'Suffix',
    component: InputField,
    type: 'text',
    grid: {
      xs: 2,
      md: 3,
      lg: 1,
    },
  },
  {
    name: 'dob',
    label: 'Date of Birth *',
    validate: [required],
    component: InputField,
    shrink: true,
    type: 'date',
    grid: {
      xs: 4,
      md: 3,
    },
  },
  {
    name: 'isDeceased',
    label: 'Deceased',
    component: CheckboxField,
    props: {
      enableMinHeight: false,
    },
    type: 'checkbox',
    grid: {
      xs: 3,
    },
  },
];

/**
 * @param {*} codeTypes
 * @returns {import('../AddDependent/types').FieldOptions}
 */
const OTHER_INFORMATION = codeTypes => [
  {
    type: 'text',
    name: 'street',
    label: 'Street Address',
    component: InputField,
    grid: {
      xs: 12,
      md: 6,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'street2',
    label: 'Street Address 2',
    component: InputField,
    grid: {
      xs: 12,
      md: 6,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'city',
    label: 'City',
    component: InputField,
    grid: {
      xs: 4,
      md: 4,
      lg: 3,
    },
  },
  {
    name: 'state',
    label: 'State',
    labelKey: 'longName',
    valueKey: 'shortName',
    options: codeTypes.stateTypes,
    component: SelectField,
    isSearchable: true,
    grid: {
      xs: 4,
      md: 4,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'postalCode',
    label: 'Zipcode',
    placeholder: 'XXXXX',
    inputProps: { maxLength: 10 },
    component: InputField,
    format: postalCodeMask,
    normalize: postalCodeNormalizer,
    grid: {
      xs: 4,
      md: 4,
      lg: 3,
    },
  },
  {
    type: 'text',
    name: 'animalClass',
    label: 'Type of Animal',
    component: InputField,
    grid: {
      xs: 6,
      md: 3,
    },
  },
  {
    type: 'text',
    name: 'animalBreed',
    label: 'Breed',
    component: InputField,
    grid: {
      xs: 6,
      md: 3,
    },
  },
  {
    type: 'text',
    name: 'animalColor',
    label: 'Color',
    component: InputField,
    grid: {
      xs: 6,
      md: 3,
    },
  },
  {
    name: 'gender',
    label: 'Gender',
    type: 'text',
    labelKey: 'longName',
    valueKey: 'shortName',
    component: SelectField,
    options: codeTypes.genderTypes,
    isLoading: codeTypes.isFetchingGenderType,
    isSearchable: true,
    isClearable: true,
    grid: {
      xs: 6,
      md: 2,
    },
  },
];

/**
 * Factory function for identifying information HTML Form field definitions
 *
 * @returns {Array<import('../AddDependent/types').FieldOptions>} Idenditfying information field definitions
 */
export const identifyingInformationFields = () => [IDENTIFYING_INFORMATION];

/**
 * Factory function for other information HTML Form field deinfitions
 *
 * @param {Object<string, import('../AddDependent/types').ListOption>} codeTypes - List of options for specific fields
 * @returns {Array<import('../AddDependent/types').FieldOptions>} Other information field definitions normalized, ready for use
 */
export const otherInformationFields = codeTypes => [
  OTHER_INFORMATION(codeTypes),
];
