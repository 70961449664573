import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BasePage from '../BasePage';
import { Grid, Button, Icon, Typography, withStyles } from '@material-ui/core';
import { clearFiles } from '../../../store/reducers/mediaObject';
import PageHeader from '../../components/PageHeader';
import {
  cancelFormReset,
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';
import Divider from '@material-ui/core/Divider';
import {
  fetchAllergies,
  allergyDelete,
  allergySelectId,
  allergyClearSelectedId,
} from '../../../store/reducers/allergies/actions';
import AllergyTable from './AllergyTable';
import Drawer from './Drawer';
import { styles } from './styles';
import { ALLERGY } from '../../../store/reducers/mediaObject';

const AllergyPage = props => {
  const {
    history,
    uiStateEntityCreate,
    navigation: { dashboard },
    allergies,
    personId,
    allergyClearSelectedId,
    allergySelectId,
    allergyDelete,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.fetchAllergies();
  }, [personId]);

  const createRecord = () => {
    allergyClearSelectedId();
    setOpen(true);
  };

  const onClickUpdate = aid => {
    uiStateEntityUpdate('ALLERGY');
    allergySelectId(aid);
    setOpen(true);
  };

  const onClickDelete = record => {
    allergyDelete(personId, record.allergyId);
  };

  const handleOnCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <div>
      <Drawer open={open} setOpen={setOpen} clearFiles={props.clearFiles} />
      <BasePage
        state={props.allergies.uiState}
        emptyStateComponentProps={{
          name: 'Allergies',
          type: 'Allergies and Reactions',
          onClick: createRecord,
        }}
      >
        <Grid item xs={12}>
          {allergies.listIds.length ? (
            <React.Fragment>
              <PageHeader
                headerText="Allergies and Reactions"
                subHeaderText="View Records"
                backButtonText="Go Back"
                backLink={dashboard.healthRecord.self}
                rightSlot={
                  <Button
                    color="primary"
                    variant="contained"
                    aria-label="Add"
                    onClick={createRecord}
                    style={{ float: 'right' }}
                  >
                    <Icon>add</Icon>
                    New Record
                  </Button>
                }
              />
              <Divider />{' '}
            </React.Fragment>
          ) : (
            ''
          )}

          <Grid item xs={12}>
            <AllergyTable
              listIds={allergies.listIds}
              list={allergies.list}
              onEdit={onClickUpdate}
              onDelete={onClickDelete}
            />
          </Grid>
        </Grid>
      </BasePage>
    </div>
  );
};

const mapStateToProps = state => {
  const { isFormCanceled } = state.ui;
  const { isFetching, list, listIds, didInvalidate } = state.allergies;
  const { personId } = state.active.user;

  return {
    isFormCanceled,
    isFetching,
    allergies: state.allergies,
    didInvalidate,
    personId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAllergies: pid => dispatch(fetchAllergies(pid)),
  allergyDelete: (pid, aid) => dispatch(allergyDelete(pid, aid)),
  allergySelectId: aid => dispatch(allergySelectId(aid)),
  allergyClearSelectedId: () => dispatch(allergyClearSelectedId()),
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
  clearFiles: () => dispatch(clearFiles(ALLERGY)),
});

export default {
  component: withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(AllergyPage))
  ),
};
