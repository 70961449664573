import { format } from 'date-fns-tz';

/**
 * Return the target format if the format exists in the supported (formats) array.
 * If it doesn't, then return the best image format up the chain which is in the
 * supported format array. Example if the target format is "MD" and the supported
 * array has "OG", "XL", "SM", "XS" then the return format is "XL".
 *
 * Note: order is very important.
 *
 * @param {array} formats contains all supported formats (e.g, "XL", "LG", "MD", "SM", "XS")
 * @param {string} desiredFormat the target format
 * @param {array} omit contains formats that are not used.
 * @return string
 */
export const getBestTargetImageFormat = (
  supported,
  desiredFormat,
  omit = []
) => {
  if (!supported || supported.length === 0) return 'self';

  desiredFormat = desiredFormat.toUpperCase();
  omit = omit.map(x => x.toUpperCase());

  if (
    supported.find(
      x => x.toUpperCase() === desiredFormat && !omit.includes(desiredFormat)
    )
  )
    return `format${desiredFormat}`;

  const rankFormats = { XL: 0, LG: 1, MD: 2, SM: 3, XS: 4 };

  const filteredFormats = supported.reduce((accumulator, x) => {
    if (
      rankFormats[x] >= 0 &&
      !omit.includes(x) &&
      rankFormats[x] < rankFormats[desiredFormat]
    )
      accumulator.push(x);

    return accumulator;
  }, []);

  if (filteredFormats.length !== 0) {
    const size = filteredFormats[filteredFormats.length - 1];
    return `format${size}`;
  }

  return 'self';
};

const normalizeDate = date => date.split('-').join('/');
const getDateTime = item => {
  const dateTime =
    item && item.newEnteredOnDate
      ? item.newEnteredOnTime
        ? new Date(
            `${normalizeDate(item.newEnteredOnDate)} ${item.newEnteredOnTime}`
          )
        : new Date(`${normalizeDate(item.newEnteredOnDate)}`)
      : new Date();
  return format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
};

export const getBundlesToUpload = (mediaObjects, form, category = '') => {
  const bundlesToUpload = [];
  const bundlesToUpdate = [];

  Object.keys(mediaObjects).map(key => {
    const _k = key.split('.');
    if (!form[_k[0]] || !form[_k[0]][_k[1]]) return;
    if (
      !_k[1].includes('bundle') &&
      form[_k[0]][_k[1]].initial &&
      JSON.stringify(form[_k[0]][_k[1]].initial) ===
        JSON.stringify(form[_k[0]][_k[1]].values)
    )
      return;
    const categoryProp = category ? { category } : {};
    const value = {
      ...form[_k[0]][_k[1]].values,
      ...categoryProp,
      enteredOn: getDateTime(form[_k[0]][_k[1]].values),
    };
    if (_k[1].includes('bundle')) {
      //bundlesToUpload[form[_k[0]][_k[1]].values.index] = value;
      bundlesToUpload.push(value);
    }
    if (_k[1].includes('previewMedia')) {
      if (!mediaObjects[key]) bundlesToUpdate.push(value);
      //bundlesToUpdate[form[_k[0]][_k[1]].values.index] = value;
    }
  });

  return {
    bundlesToUpload,
    bundlesToUpdate,
  };
};

export const hasMediaObjectUpdates = state => {
  return (
    (state.form.addInfo &&
      Object.values(state.form.addInfo).length &&
      Object.values(state.form.addInfo).filter(
        item => JSON.stringify(item.values) !== JSON.stringify(item.initial)
      ).length) ||
    state.mediaObject.bundles.length
  );
};
