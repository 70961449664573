import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Grid } from '@material-ui/core';

import WhoDatetimeInput from './WhoDatetimeInput';
import NoteInput from '../NoteInput';
import LocationButtonToggle from '../Toggle/LocationButton';
import NoteMessage from '../DashboardContent/DashboardMessage';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  inputFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  info: {
    flex: 1,
    color: '#aaa',
    display: 'flex',
    textAlign: 'right',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const BlockedLocationSharing = props => {
  const { isDisplayed, children } = props;
  return isDisplayed ? <NoteMessage type="danger">{children}</NoteMessage> : '';
};

const JournalComposeInput = props => {
  const { classes, fragments } = props;

  let intervalId;

  const [user, setUser] = useState({});
  const [noteInputValues, setNoteInputValues] = useState({});

  const [locationData, setLocationData] = useState({
    geoLocation: {
      coords: null,
      timestamp: '',
      code: '',
      error: '',
    },
    isLocationSharingPermitted: false,
  });

  //useEffect(() => {
  //  if (props.data.user) {
  //    setUser(props.data.user);
  //  }

  // if (props.data.datetime === undefined) {
  //   intervalId = setInterval(() => {
  //     setDateTime(new Date());
  //   }, 1000);
  // }

  // return () => {
  //   clearInterval(intervalId);
  // }
  //}, [props.data]);

  const handleUserChange = nextUser => {
    setUser(user);
    props.onChange(
      {
        ...user,
        ...nextUser,
      },
      'user'
    );
  };

  const handleDateTimeChange = nextDateTime => {
    props.onChange(nextDateTime, 'datetime');
    // if (intervalId) {
    //   clearInterval(this.intervalId);
    // }
  };

  const onTitleChange = e => {
    handleNoteInputChange({
      title: e.currentTarget.value,
      body: noteInputValues.body,
    });
  };

  const handleNoteInputChange = values => {
    setNoteInputValues({
      ...values,
    });

    props.onChange(
      {
        ...noteInputValues,
        ...values,
      },
      'title'
    );
  };

  const handleLocationSharingChange = bool => {
    // For reasons not understood: sometimes the coords object is null when the
    // user has not blocked location sharing. If location sharing isn't blocked
    // make a final attempt to get position data.

    // old code
    // if (locationData.geoLocation.coords === null && bool) {
    //   console.log('[Wow]', locationData);
    //   navigator.geolocation.getCurrentPosition(
    //     handlePositionChange,
    //     handlePositionError
    //   );
    // }

    bool &&
      navigator.geolocation.getCurrentPosition(
        function(position) {
          console.log('Got position!', position);
          handlePositionChange(position);
        },
        function(e) {
          console.log(`We're working on it!`);
          handlePositionError(e);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );

    const state = {
      geoLocation: bool ? locationData.geoLocation : null,
      isLocationSharingPermitted: bool,
    };

    setLocationData(state);

    props.onChange(state, 'location');
  };

  const handlePositionChange = position => {
    const state = {
      ...locationData,
      geoLocation: {
        coords: position.coords,
        timestamp: position.timestamp,
      },
    };

    setLocationData(state);
    props.onChange(state, 'location');
  };

  const handlePositionError = error => {
    const state = {
      ...locationData,
      geoLocation: { error },
    };

    setLocationData(state);
    props.onChange(state, 'location');
  };

  const userDeniedLocationSharing = () => {
    return (
      locationData.geoLocation &&
      locationData.geoLocation.error &&
      locationData.geoLocation.code ===
        locationData.geoLocation.error.PERMISSION_DENIED
    );
  };

  const showErrorMessage =
    userDeniedLocationSharing() && !locationData.isLocationSharingPermitted;

  return (
    <div className={classes.root}>
      <BlockedLocationSharing isDisplayed={showErrorMessage}>
        You've activated location sharing. However, it appears your web browser
        is blocking position sharing.
      </BlockedLocationSharing>
      <Grid container spacing={1}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            hint="Required"
            fullWidth
            value={props.data.title}
            label="Title"
            placeholder={props.data.title}
            onChange={onTitleChange}
            inputProps={{
              style: {
                color: 'rgba(0, 104, 168, 0.65)',
                fontWeight: '500',
                // fontSize: '21px',
                // lineHeight: '24x',
              },
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <WhoDatetimeInput
            isJournalOpened={props.open}
            date={props.data.datetime}
            locationButton={
              <LocationButtonToggle onChange={handleLocationSharingChange} />
            }
            usersList={props.people}
            selectedUser={
              props.data.user && props.data.user.value
                ? props.data.user.value
                : props.activeUser.personId
            }
            onChangeUser={handleUserChange}
            onChangeDateTime={handleDateTimeChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

JournalComposeInput.propTypes = {
  allowLocationSharing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

JournalComposeInput.defaultProps = {
  allowLocationSharing: false,
};

export default withStyles(styles)(JournalComposeInput);
