import React, { useEffect, useState, useRef } from 'react';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function useScroll(callback) {
  const tickRef = useRef(false);
  useEffect(() => {
    const updateTick = () => {
      if (!tickRef.current) {
        window.requestAnimationFrame(() => {
          callback(window.scrollY, window.scrollX);
          tickRef.current = false;
        });
        tickRef.current = true;
      }
    };

    window.addEventListener('scroll', updateTick);
    return () => window.removeEventListener('scroll', updateTick);
  }, [callback]);
}

export default function UseScrollHOC(props) {
  const [isHidden, setIsHidden] = useState(true);

  function update(y) {
    if (y >= 150) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }

  function handleOnClickToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useScroll(update);

  return (
    <div>
      {props.children}
      <Fade in={!isHidden} mountOnEnter unmountOnExit timeout={150}>
        <div style={{ position: 'fixed', right: 20, bottom: 20 }}>
          <Fab onClick={() => handleOnClickToTop()} color="primary">
            <ArrowUpwardIcon />
          </Fab>
        </div>
      </Fade>
    </div>
  );
}
