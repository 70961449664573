//import 'whatwg-fetch';
import { DISPATCH_API } from '../../../library/middleware/dispatchApi-OLD';

export const BLOOD_TYPE_REQUEST = 'code-table/BLOOD_TYPE_REQUEST';
export const BLOOD_TYPE_SUCCESS = 'code-table/BLOOD_TYPE_SUCCESS';
export const BLOOD_TYPE_FAILURE = 'code-table/BLOOD_TYPE_FAILURE';

export const CONDITION_TYPE_REQUEST = 'code-table/CONDITION_TYPE_REQUEST';
export const CONDITION_TYPE_SUCCESS = 'code-table/CONDITION_TYPE_SUCCESS';
export const CONDITION_TYPE_FAILURE = 'code-table/CONDITION_TYPE_FAILURE';

export const CONTACT_TYPE_REQUEST = 'code-table/CONTACT_TYPE_REQUEST';
export const CONTACT_TYPE_SUCCESS = 'code-table/CONTACT_TYPE_SUCCESS';
export const CONTACT_TYPE_FAILURE = 'code-table/CONTACT_TYPE_RECEIVE';

export const DOCUMENT_TYPE_REQUEST = 'code-table/DOCUMENT_TYPE_REQUEST';
export const DOCUMENT_TYPE_SUCCESS = 'code-table/DOCUMENT_TYPE_SUCCESS';
export const DOCUMENT_TYPE_FAILURE = 'code-table/DOCUMENT_TYPE_FAILURE';

export const ETHNICITY_TYPE_REQUEST = 'code-table/ETHNICITY_TYPE_REQUEST';
export const ETHNICITY_TYPE_SUCCESS = 'code-table/ETHNICITY_TYPE_RECEIVE';
export const ETHNICITY_TYPE_FAILURE = 'code-table/ETHNICITY_TYPE_FAILURE';

export const GENDER_TYPE_REQUEST = 'code-table/GENDER_TYPE_REQUEST';
export const GENDER_TYPE_SUCCESS = 'code-table/GENDER_TYPE_SUCCESS';
export const GENDER_TYPE_FAILURE = 'code-table/GENDER_TYPE_FAILURE';

export const LANGUAGE_TYPE_REQUEST = 'code-table/LANGUAGE_TYPE_REQUEST';
export const LANGUAGE_TYPE_SUCCESS = 'code-table/LANGUAGE_TYPE_SUCCESS';
export const LANGUAGE_TYPE_FAILURE = 'code-table/LANGUAGE_TYPE_FAILURE';

export const MARITAL_TYPE_REQUEST = 'code-table/MARITAL_TYPE_REQUEST';
export const MARITAL_TYPE_SUCCESS = 'code-table/MARITAL_TYPE_SUCCESS';
export const MARITAL_TYPE_FAILURE = 'code-table/MARITAL_TYPE_FAILURE';

export const RELIGION_TYPE_REQUEST = 'code-table/RELIGION_TYPE_REQUEST';
export const RELIGION_TYPE_SUCCESS = 'code-table/RELIGION_TYPE_SUCCESS';
export const RELIGION_TYPE_FAILURE = 'code-table/RELIGION_TYPE_FAILURE';

export const STATE_TYPE_REQUEST = '@@reducer/STATE_TYPE_REQUEST';
export const STATE_TYPE_SUCCESS = '@@reducer/STATE_TYPE_SUCCESS';
export const STATE_TYPE_FAILURE = '@@reducer/STATE_TYPE_FAILURE';

export const VETERAN_TYPE_REQUEST = 'code-table/VETERAN_TYPE_REQUEST';
export const VETERAN_TYPE_SUCCESS = 'code-table/VETERAN_TYPE_SUCCESS';
export const VETERAN_TYPE_FAILURE = 'code-table/VETERAN_TYPE_FAILURE';

export const VACCINATION_TYPE_REQUEST = 'code-table/VACCINATION_TYPE_REQUEST';
export const VACCINATION_TYPE_SUCCESS = 'code-table/VACCINATION_TYPE_SUCCESS';
export const VACCINATION_TYPE_FAILURE = 'code-table/VACCINATION_TYPE_FAILURE';

export const MEDUNITS_TYPE_REQUEST = 'code-table/MEDUNITS_TYPE_REQUEST';
export const MEDUNITS_TYPE_SUCCESS = 'code-table/MEDUNITS_TYPE_SUCCESS';
export const MEDUNITS_TYPE_FAILURE = 'code-table/MEDUNITS_TYPE_FAILURE';

export const DELIVERYMETHOD_TYPE_REQUEST =
  'code-table/DELIVERYMETHOD_TYPE_REQUEST';
export const DELIVERYMETHOD_TYPE_SUCCESS =
  'code-table/DELIVERYMETHOD_TYPE_SUCCESS';
export const DELIVERYMETHOD_TYPE_FAILURE =
  'code-table/DELIVERYMETHOD_TYPE_FAILURE';

export const DELIVERYFORM_TYPE_REQUEST = 'code-table/DELIVERYFORM_TYPE_REQUEST';
export const DELIVERYFORM_TYPE_SUCCESS = 'code-table/DELIVERYFORM_TYPE_SUCCESS';
export const DELIVERYFORM_TYPE_FAILURE = 'code-table/DELIVERYFORM_TYPE_FAILURE';

export const SURGICALHISTORY_TYPE_REQUEST =
  'code-table/SURGICAL_HISTORY_REQUEST';
export const SURGICALHISTORY_TYPE_SUCCESS =
  'code-table/SURGICAL_HISTORY_SUCCESS';
export const SURGICALHISTORY_TYPE_FAILURE =
  'code-table/SURGICAL_HISTORY_FAILURE';

/**
 * Redux actions for BLOOD types
 */

export const fetchBloodType = () => ({
  [DISPATCH_API]: {
    types: [BLOOD_TYPE_REQUEST, BLOOD_TYPE_SUCCESS, BLOOD_TYPE_FAILURE],
    endpoint: '/api/code-table/blood/1',
  },
});

/**
 * Redux actions for CONDITION types
 */

export const fetchConditionType = () => ({
  [DISPATCH_API]: {
    types: [
      CONDITION_TYPE_REQUEST,
      CONDITION_TYPE_SUCCESS,
      CONDITION_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/condition/1',
  },
});

/**
 * Redux actions for CONTACT types
 */

export const fetchContactType = () => ({
  [DISPATCH_API]: {
    types: [CONTACT_TYPE_REQUEST, CONTACT_TYPE_SUCCESS, CONTACT_TYPE_FAILURE],
    endpoint: '/api/code-table/contact/1',
  },
});

/**
 * Redux actions for CONTACT types
 */

export const fetchDocumentType = () => ({
  [DISPATCH_API]: {
    types: [
      DOCUMENT_TYPE_REQUEST,
      DOCUMENT_TYPE_SUCCESS,
      DOCUMENT_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/document/1',
  },
});

/**
 * Redux actions for ETHNICITY types
 */

export const fetchEthnicityType = () => ({
  [DISPATCH_API]: {
    types: [
      ETHNICITY_TYPE_REQUEST,
      ETHNICITY_TYPE_SUCCESS,
      ETHNICITY_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/ethnicity/1',
  },
});

/**
 * Redux actions for GENDER types
 */

export const fetchGenderType = () => ({
  [DISPATCH_API]: {
    types: [GENDER_TYPE_REQUEST, GENDER_TYPE_SUCCESS, GENDER_TYPE_FAILURE],
    endpoint: '/api/code-table/gender/1',
    meta: {
      size: 6,
      ttl: '6hrs',
    },
  },
});

/**
 * Redux actions for LANGUAGE types
 */

export const fetchLanguageType = () => ({
  [DISPATCH_API]: {
    types: [
      LANGUAGE_TYPE_REQUEST,
      LANGUAGE_TYPE_SUCCESS,
      LANGUAGE_TYPE_SUCCESS,
    ],
    endpoint: '/api/code-table/language/1',
  },
});

/**
 * Redux actions for MARITAL types
 */

export const fetchMaritalType = () => ({
  [DISPATCH_API]: {
    types: [MARITAL_TYPE_REQUEST, MARITAL_TYPE_SUCCESS, MARITAL_TYPE_FAILURE],
    endpoint: '/api/code-table/marital/1',
  },
});

/**
 * Redux actions for RELIGION types
 */

export const fetchReligionType = () => ({
  [DISPATCH_API]: {
    types: [
      RELIGION_TYPE_REQUEST,
      RELIGION_TYPE_SUCCESS,
      RELIGION_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/religion/1',
  },
});

/**
 * Redux actions for STATE types
 */

export const fetchStateType = () => ({
  [DISPATCH_API]: {
    types: [STATE_TYPE_REQUEST, STATE_TYPE_SUCCESS, STATE_TYPE_FAILURE],
    endpoint: '/api/code-table/state/1',
  },
});

/**
 * Redux actions for VETERAN types
 */

export const fetchVeteranType = () => ({
  [DISPATCH_API]: {
    types: [VETERAN_TYPE_REQUEST, VETERAN_TYPE_SUCCESS, VETERAN_TYPE_FAILURE],
    endpoint: '/api/code-table/military/1',
  },
});

/**
 * Redux actions for VACCINATION types
 */

export const fetchVaccinationType = () => ({
  [DISPATCH_API]: {
    types: [
      VACCINATION_TYPE_REQUEST,
      VACCINATION_TYPE_SUCCESS,
      VACCINATION_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/vaccination/1',
  },
});

/**
 * Redux actions for MEDUNITS types
 */

export const fetchMedUnitsType = () => ({
  [DISPATCH_API]: {
    types: [
      MEDUNITS_TYPE_REQUEST,
      MEDUNITS_TYPE_SUCCESS,
      MEDUNITS_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/medunits/1',
  },
});

export const fetchDeliveryMethodType = () => ({
  [DISPATCH_API]: {
    types: [
      DELIVERYMETHOD_TYPE_REQUEST,
      DELIVERYMETHOD_TYPE_SUCCESS,
      DELIVERYMETHOD_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/deliverymethod/1',
  },
});

export const fetchDeliveryFormType = () => ({
  [DISPATCH_API]: {
    types: [
      DELIVERYFORM_TYPE_REQUEST,
      DELIVERYFORM_TYPE_SUCCESS,
      DELIVERYFORM_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/deliveryform/1',
  },
});

export const fetchSurgicalHistoryType = () => ({
  [DISPATCH_API]: {
    types: [
      SURGICALHISTORY_TYPE_REQUEST,
      SURGICALHISTORY_TYPE_SUCCESS,
      SURGICALHISTORY_TYPE_FAILURE,
    ],
    endpoint: '/api/code-table/surgicalhistory/1',
  },
});
