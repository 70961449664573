import { PROGRESS_BAR_SET, PROGRESS_BAR_UPDATE, PROGRESS_BAR_COMPLETE } from './actions';

const initialState = {
  total: 0,
  value: 0,
  display: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS_BAR_SET:
      return {
        ...state,
        total: action.total,
        value: action.value,
        display: true,
      }
    case PROGRESS_BAR_UPDATE:
      return {
        ...state,
        value: action.value,
        display: true,
      }
    case PROGRESS_BAR_COMPLETE:
      return {
        ...state,
        value: action.value,
        display: false,
      }
    default: 
      return state;
  }
}