import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography } from '@material-ui/core';

import MediaObject from '../../containers/MediaObject/MediaObject';
import Image from '../Image';
import DashboardMessage from '../DashboardContent/DashboardMessage';
import { getBestTargetImageFormat } from '../MediaObject/helpers';
import ExistingFileEdit from '../../../shared/containers/MediaObject/ExistingFileEdit';
import { format } from 'date-fns-tz';

import {
  updateMedia,
  deleteMedia,
} from '../../../store/reducers/journalmodal/actions';

const AttachmentsCompose = props => {
  const {
    classes,
    goBack,
    isSubmitting,
    appArea,
    mediaObjects,
    style,
    mediaObjectIds = [],
    modalState,
    isDetailCollapseDisabled = false,
    ...rest
  } = props;

  const [currentIndex, setCurrentIndex] = React.useState('');

  const handleDescriptionChanged = (index, description) => {
    rest.setDescription(index, description);
  };

  const handleDateChanged = (index, date) => {
    rest.setMediaDate(index, date);
  };

  const handleTimeChanged = (index, time) => {
    rest.setMediaTime(index, time);
  };

  const handleRemoveMediaObject = (id, referenceId, index) => {
    event.stopPropagation();
    rest.deleteMedia(id, referenceId);
    rest.removeFile(index);
  };

  const changeCurrentIndex = index => {
    setCurrentIndex(index);
  };

  return (
    <div className={classes.root} style={{ ...style }}>
      {modalState === 'journal-copy' && (
        <DashboardMessage type="info">
          {' '}
          <Typography>Attachments are not duplicated</Typography>{' '}
        </DashboardMessage>
      )}
      <MediaObject mode="modal" appArea={appArea} isSubmitting={isSubmitting} />
      {mediaObjects &&
        mediaObjects.map((object, index) => {
          // disable addInfo for duplicate mediaObject records
          const _isDetailCollapseDisabled =
            mediaObjectIds.includes(object.mediaObjectId) ||
            object.type === 'RecordAttachmentCopy' ||
            !!isDetailCollapseDisabled;
          return (
            <ExistingFileEdit
              personId={rest.activeUser.personId}
              key={`${object.fileName}-${index}`}
              index={index}
              mode="modal"
              currentIndex={currentIndex}
              bundle={{
                ...object,
                description: object.description,
                date: format(new Date(object.enteredOn), 'yyyy-MM-dd'),
                time: format(new Date(object.enteredOn), 'HH:mm'),
              }}
              isSubmitting={isSubmitting}
              changeCurrentIndex={changeCurrentIndex}
              removeObject={() =>
                handleRemoveMediaObject(
                  object.mediaObjectId,
                  object.journalEntryMediaObjectId,
                  index
                )
              }
              isDetailCollapseDisabled={_isDetailCollapseDisabled}
              descriptionChanged={handleDescriptionChanged}
              dateChanged={handleDateChanged}
              timeChanged={handleTimeChanged}
            />
          );
        })}
      {/* {!isSubmitting && (
        <div className={classes.footer}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={goBack}
          >
            Done
          </Button>
        </div>
      )} */}
    </div>
  );
};

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
  },
});

const mapStateToProps = state => ({
  activeUser: state.active.user,
});

const mapDispatchToProps = dispatch => ({
  setDescription: (index, description) =>
    dispatch(updateMedia(index, { description })),
  setMediaDate: (index, date) => dispatch(updateMedia(index, { date })),
  setMediaTime: (index, time) => dispatch(updateMedia(index, { time })),
  removeFile: index => dispatch(deleteMedia(index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AttachmentsCompose));
