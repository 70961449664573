import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format as dateFormat } from 'date-fns';
import { Icon, Button } from '@material-ui/core';

class DisplayField extends Component {
  render() {
    const { date, format, innerRef, onClick, ...rest } = this.props;
    const formatted = dateFormat(date, format);
    return (
      <React.Fragment>
        <div
          style={{
            color: '#000',
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.875rem',
            boxSizing: 'border-box',
            // minHeight: 36,
            // lineHeight: 1.5,
            padding: '8px 0px',
            // letterSpacing: '0.02857em'
          }}
          onClick={onClick}
        >
          {/* <input
            {...rest}
            ref={innerRef}
            type="text"
            value={formatted}
            readOnly={true}
          /> */}
          <span {...rest} ref={innerRef}>
            {formatted}
          </span>
          <Icon>expand_more</Icon>
        </div>
        {/* <Button 
          {...rest}
        innerRef={innerRef}
        >
          {formatted}
          <Icon>
            expand_more
          </Icon>
        </Button> */}
      </React.Fragment>
    );
  }
}

DisplayField.propTypes = {
  date: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

DisplayField.defaultProps = {
  date: new Date(),
  format: 'yyyy-MM-dd hh:mm a',
};

export default DisplayField;
