import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  withStyles,
  InputAdornment,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import InputField from '../../../library/forms/InputField';
import SelectField from '../../../library/forms/SelectField';
import { cancelForm } from '../../../store/reducers/ui/actions';
import validate from './ValidateForm';
import HoverTooltip from '../../../library/forms/HoverTooltip';
import FormPanelButton from '../../components/Form/FormButtonPanel';
import { hasMediaObjectUpdates } from '../../components/MediaObject/helpers';
import AttachmentPanel from './AttachmentPanel';
import DrawerAccordionStandardItems from '../../components/DrawerAccordionStandardItems';

export const styles = {
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    position: 'relative',
    padding: 32,
    paddingBottom: 16,
  },
  surgeryBgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  surgeryBg: {
    height: '100%',
    objectFit: 'cover',
  },
  label: {
    letterSpacing: 'normal',
  },

  root: {
    width: '100%',
  },
  heading: {
    padding: '0px 8px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
  accordion: {
    boxShadow: 'none',
  },
};

const noop = () => {};
const dateFormat = 'yyyy-MM-dd';

const SurgicalHistory = props => {
  const {
    handleSubmit,
    surgicalHistoryTypes,
    action,
    complicationsList,
    cancelForm,
    onCancel,
    classes,
    surgeryTypeAhead,
    isFetchingSurgicalHistoryType,
    isSubmitting,
    pristine,
    submitDisabled,
    matches550,
  } = props;

  const onCancelForm = () => {
    // cancelForm();
    onCancel();
  };

  const [approxDate, setApproxDate] = React.useState(false);
  const entityAction = action === 'create' ? 'Create' : 'Update';
  const disableSubmit =
    (isSubmitting || pristine) && !props.hasMediaObjectUpdates;

  return (
    <div
      style={{ width: !matches550 ? '100hw' : '550px', overflowX: 'hidden' }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.primaryInput}>
              <div className={classes.surgeryBgContainer}>
                <img
                  src={require('../../../client/svgs/surgical-history-add.svg')}
                  alt=""
                  className={classes.surgeryBg}
                />
              </div>
              <Typography variant="h6">Enter your surgery</Typography>
              <Field
                name="surgery"
                label="Name"
                hint="Required"
                labelKey="label"
                valueKey="value"
                options={surgicalHistoryTypes}
                component={SelectField}
                props={{
                  InputLabelProps: {
                    style: {
                      letterSpacing: 'normal',
                    },
                  },
                  containerStyle: {
                    backgroundColor: '#fff',
                  },
                  variant: 'filled',
                }}
                isLoading={isFetchingSurgicalHistoryType}
                isSearchable
                isCreatable
                isClearable
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{ padding: 24, paddingBottom: 0, margin: 0, width: '100%' }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <Field
              type="date"
              shrink={true}
              name="surgeryEndedOn"
              label="Surgery Date"
              component={InputField}
              props={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HoverTooltip text="If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team." />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={7}>
            <Field
              name="surgeonName"
              label="Surgeon Name"
              type="text"
              component={InputField}
              // props={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <FaceIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              type="text"
              name="hospitalFacility"
              label="Hospital / Facility"
              component={InputField}
              // props={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <BusinessIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              name="complications"
              label="Complications"
              labelKey="shortName"
              valueKey="longName"
              options={[]}
              component={SelectField}
              fieldType="tags"
              isMulti
              isCreatable
              disableBlur
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              name="relatedSurgicalPreparations"
              label="Related Surgical Preparations"
              labelKey="shortName"
              valueKey="longName"
              options={[]}
              component={SelectField}
              fieldType="tags"
              isMulti
              isCreatable
              disableBlur
            />
          </Grid>
        </Grid>
        <DrawerAccordionStandardItems 
          additionalInformation={
            <Field
              name="notes"
              label="Notes"
              multiline
              rows={4}
              fullWidth
              component={InputField}
            />
          } 
          attachments={<AttachmentPanel />} />
        <Grid container item xs={12}>
            <FormPanelButton
              actionText={`${entityAction} Record`}
              cancelText="Cancel"
              onCancel={onCancelForm}
              isDisabled={Boolean(
                submitDisabled || disableSubmit || props.formSubmitting
              )}
              isSubmitting={props.formSubmitting}
            />
          </Grid>
      </form>
    </div>
  );
};

const SurgicalHistoryRedux = reduxForm({
  form: 'surgical-history-form',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(SurgicalHistory));

const mapStateToProps = state => {
  if (!state.surgicalHistory.selectedId)
    return {
      initialValues: {},
      submitDisabled:
        state.form['surgical-history-form'] &&
        state.form['surgical-history-form'].syncErrors &&
        Boolean(
          Object.keys(state.form['surgical-history-form'].syncErrors).length
        ),
    };

  const {
    surgery = '',
    surgeonName,
    surgeryEndedOn,
    hospitalFacility,
    complications = [],
    relatedSurgicalPreparations = [],
    notes,
  } = state.surgicalHistory.list[state.surgicalHistory.selectedId];

  return {
    initialValues: {
      surgery,
      surgeonName,
      surgeryEndedOn: surgeryEndedOn
        ? format(parseISO(surgeryEndedOn), dateFormat)
        : undefined,
      hospitalFacility,
      complications,
      relatedSurgicalPreparations,
      notes,
    },
    hasMediaObjectUpdates:
      hasMediaObjectUpdates(state) ||
      state.surgicalHistory.bundlesToDelete.length,
    submitDisabled:
      state.form['surgical-history-form'] &&
      state.form['surgical-history-form'].syncErrors &&
      Boolean(
        Object.keys(state.form['surgical-history-form'].syncErrors).length
      ),
  };
};

const mapDispatchToProps = dispatch => ({
  cancelForm: () => dispatch(cancelForm()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SurgicalHistoryRedux)
);
