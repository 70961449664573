import React from 'react';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';

import InputField from '../../../library/forms/InputField';
import SelectField from '../../../library/forms/SelectField';
import { mask } from '../../../library/helpers/format';

const postalCodeMask = value => mask(value, 'XXXXX-XXXX');

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  return value;
};

const AddressFormFields = props => {
  const {
    streetAddressRequired = false,
    cityRequired = false,
    stateRequired = false,
    postalCodeRequired = false,
    stateTypes,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Field
          name="street"
          label={`Street Address Line 1 ${streetAddressRequired ? '*' : ''}`}
          type="text"
          hint={streetAddressRequired ? 'Required' : ''}
          component={InputField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="street2"
          label={`Street Address Line 2 ${streetAddressRequired ? '*' : ''}`}
          type="text"
          hint={streetAddressRequired ? 'Required' : ''}
          component={InputField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="city"
          label={`City ${cityRequired ? '*' : ''}`}
          type="text"
          hint={cityRequired ? 'Required' : ''}
          component={InputField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          name="state"
          label={`State ${stateRequired ? '*' : ''}`}
          hint={stateRequired ? 'Required' : ''}
          labelKey="longName"
          valueKey="shortName"
          component={SelectField}
          options={stateTypes}
          isSearchable
          isClearable
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          name="postalCode"
          label={`Zipcode ${postalCodeRequired ? '*' : ''}`}
          type="text"
          hint={postalCodeRequired ? 'Required' : ''}
          placeholder="XXXXX or XXXXX-XXXX"
          inputProps={{ maxLength: 10 }}
          component={InputField}
          format={postalCodeMask}
          normalize={postalCodeNormalizer}
          props={{
            HoverTooltipMsg: (
              <p>
                Users must be residents of the United States of America to own a
                Agrin Health account
              </p>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFormFields;
