import React from 'react';
import {
  CircularProgress,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';

export default ({ onClick }) => (
  <Container
    maxWidth="md"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '400px',
    }}
  >
    <CircularProgress size={40} thickness={4} />
    <Typography style={{ marginTop: '10px' }}>
      Preparing an easier way to manage your health data...
    </Typography>
  </Container>
);
