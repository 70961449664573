import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  mvsCreate,
  mvsUpdate,
  fetchMedicationList,
  clearMedicationToUpdate,
  clearMedicationError,
  submitForm,
} from '../../../../store/reducers/mvs/actions';
import {
  fetchMedUnitsType,
  fetchDeliveryMethodType,
  fetchDeliveryFormType,
} from '../../../../store/reducers/codes/actions';
import MediaSubmitProgress from '../../../components/DocumentsDrawer/MediaObjectDrawer/MediaSubmitProgress';
import { clearFiles, MVS } from '../../../../store/reducers/mediaObject';
import { Grid } from '@material-ui/core';
import {
  deleteMedia,
  updateMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import MVSForm from '../MVSForm';
import { useMVSForm } from './useMVSForm';

const FormContainer = props => {
  const {
    medicationSearchResult,
    isSubmitting,
    isUploadingFiles,
    medUnitsTypes,
    deliveryMethodTypes,
    deliveryFormTypes,
    action,
    onCloseDrawer,
    bundles,
  } = props;

  const { medicationTypeAhead, onFormSubmit, onFormChange } = useMVSForm(props);
  return (
    <div
      style={{
        width: !props.matches550 ? '100hw' : '550px',
        overflowX: 'hidden',
      }}
    >
      {isSubmitting && bundles.length ? (
        <div style={{ padding: 16, paddingTop: 24 }}>
          <Grid container spacing={2}>
            {bundles.map(bundle => (
              <Grid item xs={12}>
                <MediaSubmitProgress bundle={bundle} />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <MVSForm
          medicationTypeAhead={medicationTypeAhead}
          medicationSearchResult={medicationSearchResult}
          disableSubmit={isSubmitting || isUploadingFiles}
          action={action}
          medUnitsTypes={medUnitsTypes.map(v => ({ value: v, label: v }))}
          deliveryMethodTypes={deliveryMethodTypes}
          deliveryFormTypes={deliveryFormTypes}
          onChange={onFormChange}
          onCancel={onCloseDrawer}
          onSubmit={onFormSubmit}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { personId } = state.active.user;
  const {
    isSubmitting,
    selectedId,
    medicationId = 0,
    medicationSearchResult,
    errorMessage,
    list,
    mediaObjects,
    bundlesToDelete,
  } = state.member.medication;

  const { action } = state.ui.entity;
  const { bundles, isUploadingFiles } = state.mediaObject;
  const { medUnitsTypes, deliveryMethodTypes, deliveryFormTypes } = state.codes;

  return {
    form: state.form,
    personId,
    isSubmitting,
    selectedId,
    medicationId,
    medicationSearchResult,
    errorMessage,
    action,
    bundles,
    isUploadingFiles,
    medUnitsTypes,
    deliveryMethodTypes,
    deliveryFormTypes,
    ui: state.ui,
    list,
    mediaObjects,
    bundlesToDelete,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  mvsCreate: (id, mvs, bundles) => dispatch(mvsCreate(id, mvs, bundles)),
  mvsUpdate: (id, sid, mvs, bundles) =>
    dispatch(mvsUpdate(id, sid, mvs, bundles)),
  clearMedicationToUpdate: () => dispatch(clearMedicationToUpdate()),
  fetchMedicationList: search => dispatch(fetchMedicationList(search)),
  clearMedicationError: () => dispatch(clearMedicationError()),
  clearFiles: () => dispatch(clearFiles()),
  fetchMedUnitsType: () => dispatch(fetchMedUnitsType()),
  fetchDeliveryMethodType: () => dispatch(fetchDeliveryMethodType()),
  fetchDeliveryFormType: () => dispatch(fetchDeliveryFormType()),
  updateMedia: obj => dispatch(updateMedia(MVS, obj)),
  deleteMedia: (mid, oid) => dispatch(deleteMedia(mid, oid, MVS)),
  submitForm: () => dispatch(submitForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormContainer));
