import ActivityExerciseButton from '../../buttons/ActivityExerciseButton';

const dataFields = {
  activityItems: [],
  totalActivityTimeMins: 0,
  manualActivityTimeMins: 0,
  totalCaloriesBurnedUnit: 'kcal',
  totalCaloriesBurned: 0,
  manualCaloriesBurnedUnit: 'kcal',
  manualCaloriesBurned: 0,
  manualDistance: 0,
  totalDistance: 0,

  rftempCaloriesBurned: null,
  rftempDistance: null,
  rftempActivityTimeMins: 0,
};

const normalizeData = data => ({
  ...data,
  activityItems: data.activityItems.map(item => ({
    ...item,
    durationMinutes: parseInt(item.durationMinutes),
  })),
  totalActivityTimeMins: parseInt(data.totalActivityTimeMins),
  manualActivityTimeMins: parseInt(data.manualActivityTimeMins),
  totalCaloriesBurned: parseInt(data.totalCaloriesBurned),
  manualCaloriesBurned: parseInt(data.manualCaloriesBurned),
});

const properties = {
  label: 'Activity / Exercise',
  color: '#90B850',
  icon: ActivityExerciseButton,
  dataFields,
  normalizeData,
};

export { dataFields, properties };
