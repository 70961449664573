import {
  CORE_IMMUNIZATION_REQUEST,
  CORE_IMMUNIZATION_SUCCESS,
  CORE_IMMUNIZATION_FAILURE,
} from './actions';

const initialState = {
  immunization: [],
  hasFetchedImmunization: false,
};

const reducers = {};

reducers[CORE_IMMUNIZATION_REQUEST] = (state, action) => {
  return {
    ...state,
    hasFetchedImmunization: false,
    immunization: [],
  };
};

reducers[CORE_IMMUNIZATION_SUCCESS] = (state, action) => {
  return {
    ...state,
    immunization: action.payload,
    hasFetchedImmunization: true,
  };
};

reducers[CORE_IMMUNIZATION_FAILURE] = (state, action) => {
  return {
    ...initialState,
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
