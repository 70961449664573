import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import Sliders from './Sliders';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import HeaderBanner from './HeaderBanner';
import ResourceFilter from './ResourceFilter';
import {
  resourceLibraryFetch,
  getResourceLibraryTypes,
} from '../../../store/reducers/resourceLibrary/actions';
import { connect } from 'react-redux';
import ResourceDrawer from '../../components/ResourceDrawer';
import NominateButton from './Nomination/NominateButton';
import NominationDrawer from './Nomination/NominationDrawer';

const ResourceLibraryPage = ({
  hasFetched,
  isFetching,
  resourceLibraryFetch,
  getResourceLibraryTypes,
  hasFetchedResourceTypes,
}) => {
  const [filter, setFilter] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleChange = e => {
    setFilter(e);
  };

  const onClickNominate = () => setIsDrawerOpen(true);

  useEffect(() => {
    if (!hasFetched && !isFetching) resourceLibraryFetch();
    if (!hasFetchedResourceTypes) getResourceLibraryTypes();
  }, [hasFetched]);

  return (
    <Grid container spacing={0} style={{ overflow: 'hidden' }}>
      <Grid item xs={12}>
        <HeaderBanner />
      </Grid>
      <Grid item xs={12}>
        <ResourceDrawer />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          background: '#90B850',
          border: 'none',
          boxShadow: 'rgba(83, 86, 94, 0.25) 0px 2px 10px inset',
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={false}
            sm={12}
            md={7}
            lg={8}
            xl={8}
            style={{ display: 'flex' }}
          >
            <NominateButton onClickNominate={onClickNominate} />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <ResourceFilter handleChange={handleChange} value={filter} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Sliders filter={filter} />
      </Grid>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div style={{ width: 550 }}>
          <NominationDrawer onClose={() => setIsDrawerOpen(false)} />
        </div>
      </Drawer>
    </Grid>
  );
};

const mapStateToProps = state => ({
  hasFetched: state.resourceLibrary.hasFetched,
  isFetching: state.resourceLibrary.isFetching,
  error: state.resourceLibrary.error,
  hasFetchedResourceTypes: state.resourceLibrary.hasFetchedResourceTypes,
});

const mapDispatchToProps = dispatch => ({
  resourceLibraryFetch: () => dispatch(resourceLibraryFetch()),
  getResourceLibraryTypes: () => dispatch(getResourceLibraryTypes()),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(ResourceLibraryPage),
};
