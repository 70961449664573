import { v1 as uuidv1 } from 'uuid';
import { loremIpsum } from 'lorem-ipsum';

export default [
  {
    id: uuidv1(),
    type: 'activity',
    message: 'Tom Jones has accepted your invitation to join your Care Team.',
    timestamp: new Date(2019, 6, 2).setMinutes(5),
  },
  {
    id: uuidv1(),
    type: 'info',
    message: 'A new Medical History form has been released',
    timestamp: new Date().setMinutes(15),
  },
  {
    id: uuidv1(),
    type: 'message',
    message:
      'Karen has invited you to join her Care Team! Please accept or decline.',
    timestamp: new Date(2019, 6, 2).setMinutes(10),
  },
  {
    id: uuidv1(),
    type: 'report',
    message: loremIpsum(),
    timestamp: new Date().setMinutes(15),
  },
  {
    id: uuidv1(),
    type: 'alert',
    message: 'Friendly Reminder: Time to take your medicine!',
    timestamp: new Date(2019, 6, 2).setMinutes(10),
  },
  {
    id: uuidv1(),
    type: 'general',
    message:
      'General notifcation message here. This is your first notification ever!',
    timestamp: new Date().setMinutes(25),
    readOn: new Date(),
  },

  {
    id: uuidv1(),
    type: 'info',
    message: loremIpsum(),
    timestamp: new Date(2019, 7, 20).setMinutes(5),
  },

  {
    id: uuidv1(),
    type: 'activity',
    message: loremIpsum(),
    timestamp: new Date(2019, 7, 2).setMinutes(30),
  },
  {
    id: uuidv1(),
    type: 'alert',
    message: 'Your Dr. Appt is tomorrow',
    timestamp: new Date(2019, 7, 15).setMinutes(35),
  },
  {
    id: uuidv1(),
    type: 'general',
    message: loremIpsum(),
    timestamp: new Date().setMinutes(25),
    readOn: new Date(),
  },
  {
    id: uuidv1(),
    type: 'message',
    message: loremIpsum(),
    timestamp: new Date(2019, 6, 2).setMinutes(10),
  },
  {
    id: uuidv1(),
    type: 'report',
    message: loremIpsum(),
    timestamp: new Date().setMinutes(15),
  },
  {
    id: uuidv1(),
    type: 'message',
    message: loremIpsum(),
    timestamp: new Date(2019, 6, 2).setMinutes(10),
  },
  {
    id: uuidv1(),
    type: 'general',
    message: loremIpsum(),
    timestamp: new Date(2015, 7, 15).setMinutes(45),
  },
  {
    id: uuidv1(),
    type: 'message',
    message: loremIpsum(),
    timestamp: new Date(2015, 7, 15).setMinutes(45),
  },
  {
    id: uuidv1(),
    type: 'activity',
    message: loremIpsum(),
    timestamp: new Date(2019, 6, 2).setMinutes(5),
  },
  {
    id: uuidv1(),
    type: 'alert',
    message: loremIpsum(),
    timestamp: new Date(2019, 6, 2).setMinutes(10),
  },
  {
    id: uuidv1(),
    type: 'info',
    message: loremIpsum(),
    timestamp: new Date().setMinutes(15),
  },
  {
    id: uuidv1(),
    type: 'general',
    message: loremIpsum(),
    timestamp: new Date().setMinutes(25),
    readOn: new Date(),
  },
  {
    id: uuidv1(),
    type: 'activity',
    message: loremIpsum(),
    timestamp: new Date(2019, 7, 2).setMinutes(30),
  },
  {
    id: uuidv1(),
    type: 'alert',
    message: loremIpsum(),
    timestamp: new Date(2019, 7, 15).setMinutes(35),
  },
  {
    id: uuidv1(),
    type: 'info',
    message: loremIpsum(),
    timestamp: new Date(2019, 7, 20).setMinutes(5),
  },
  {
    id: uuidv1(),
    type: 'general',
    message: loremIpsum(),
    timestamp: new Date(2015, 7, 15).setMinutes(45),
  },
  {
    id: uuidv1(),
    type: 'message',
    message: loremIpsum(),
    timestamp: new Date(2015, 7, 15).setMinutes(45),
  },
];
