const dataFields = {
  unit: 'oz',
  quantity: '',
};

const normalizeData = data => ({
  ...data,
  quantity: parseInt(data.quantity),
});

const properties = {
  label: 'Water Intake',
  color: '#68B3E0',
  icon: 'invert_colors',
  dataFields,
  normalizeData,
};

export { dataFields, properties };
