import React from 'react';
import { Grid, Icon, withStyles } from '@material-ui/core';
import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_EMOTIONS,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_STRESS_MANAGEMENT,
  FRAGMENT_ACTIVITY_EXERCISE,
  FRAGMENT_MEDICATIONS,
  fragmentsList,
} from '../../components/JournalCompose/FragmentsList';

const styles = theme => ({
  iconContainer: {
    minWidth: 24,
    maxWidth: 24,
    height: 24,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: 16,
  },
  root: {
    marginRight: 0,
  },
});

const FragmentIcon = ({ icon, color, classes }) => (
  <div style={{ backgroundColor: color }} className={classes.iconContainer}>
    {typeof icon === 'function' ? (
      React.createElement(icon)
    ) : (
      <Icon className={classes.icon} style={{ color: '#fff' }}>
        {icon}
      </Icon>
    )}
  </div>
);

const JournalFragmentIcons = ({ fragments, classes }) => {
  const renderIcon = fragment => {
    const fragmentDefinition = fragmentsList.find(
      x => x.fragmentType === fragment.fragmentType
    );

    if (fragmentDefinition)
      return (
        <FragmentIcon
          color={fragmentDefinition.color}
          icon={fragmentDefinition.icon}
          classes={classes}
        />
      );

    return <FragmentIcon color="#aaa" icon="library_add" classes={classes} />;

    switch (fragment.fragmentType) {
      case FRAGMENT_BODY_MASS:
        return (
          <FragmentIcon
            color="#2AA5A2"
            icon="accessibility"
            classes={classes}
          />
        );
      case FRAGMENT_BLOOD_PRESSURE:
        return (
          <FragmentIcon color="#3E47DD" icon="show_chart" classes={classes} />
        );
      case FRAGMENT_WATER_INTAKE:
        return (
          <FragmentIcon
            color={fragmentDefinition.color}
            icon={fragmentDefinition.icon}
            classes={classes}
          />
        );
      case FRAGMENT_EMOTIONS:
        return (
          <FragmentIcon
            color="#FFA561"
            icon="insert_emoticon"
            classes={classes}
          />
        );

      case FRAGMENT_SLEEP_TRACKER:
      case FRAGMENT_MEAL_TRACKER:
      case FRAGMENT_STRESS_MANAGEMENT:
      case FRAGMENT_ACTIVITY_EXERCISE:
      case FRAGMENT_MEDICATIONS:
        return (
          <FragmentIcon
            color={fragmentDefinition.color}
            icon={fragmentDefinition.icon}
            classes={classes}
          />
        );

      default:
        return (
          <FragmentIcon color="#aaa" icon="library_add" classes={classes} />
        );
    }
  };

  if (!fragments[0]) {
    return <FragmentIcon color="#aaa" icon="library_add" classes={classes} />;
  }

  return renderIcon(fragments[0]);
};

export default withStyles(styles)(JournalFragmentIcons);
