import {
  HEALTH_STATUS_FETCH_REQUEST,
  HEALTH_STATUS_FETCH_SUCCESS,
  HEALTH_STATUS_FETCH_FAILURE,
  REPORT_HEALTH_STATUS_SUCCESS,
  HEALTH_STATUS_CLEAN_UP,
} from './actions';

const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';
const EMPTY = 'empty';

const initialState = {
  uiState: LOADING,
  records: [],
  status: null,
  report: null,
};

const reducers = [];

reducers[HEALTH_STATUS_CLEAN_UP] = (state, action) => {
  return initialState;
};
reducers[HEALTH_STATUS_FETCH_REQUEST] = (state, action) => {
  return {
    ...initialState,
    uiState: LOADING,
  };
};

reducers[HEALTH_STATUS_FETCH_FAILURE] = (state, action) => {
  return {
    ...state,
    uiState: ERROR,
  };
};

reducers[HEALTH_STATUS_FETCH_SUCCESS] = (state, action) => {
  return {
    ...state,
    uiState:
      action.payload.labResult || action.payload.immunization ? SUCCESS : EMPTY,
    records: action.payload,
  };
};

reducers[REPORT_HEALTH_STATUS_SUCCESS] = (state, action) => {
  return {
    ...state,
    report: action.payload,
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
