import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Contents from '../DrawerContents';

const IDLE = 'idle';
const LabsDrawer = props => {
  const { status, onClose, setStatus } = props;
  const matches550 = useMediaQuery('(max-width: 550px)'); //mobile
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (status === IDLE) {
      setOpen(false);
      onClose();
      return;
    }
    if (!open) setOpen(true);
  }, [status]);

  return (
    <Drawer anchor="right" onClose={props.onClose} open={status !== IDLE}>
      <Contents
        status={status}
        setStatus={setStatus}
        onCloseDrawer={props.onClose}
        matches550={matches550}
        {...props}
      />
    </Drawer>
  );
};

export default LabsDrawer;
