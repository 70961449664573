import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import dispatchApi from '../library/middleware/dispatchApi';
import rootReducer from './reducers';

// export default (initialState = {}) => {
//   const history = createBrowserHistory();
//   const middleware = [thunk, routerMiddleware(history), dispatchApi];

//   return createStore(rootReducer, initialState, applyMiddleware(...middleware));
// };

// Configured to work with 'connected-react-router' since, react-router-redux is deprecated

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
  const middleware = [thunk, routerMiddleware(history), dispatchApi];

  const store = createStore(
    rootReducer(history), // root reducer with router state
    initialState,
    compose(applyMiddleware(...middleware))
  );

  return store;
}
