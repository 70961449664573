import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { codeObjectListToCsv } from '../../../library/helpers/utils';
import { setMediaObjects } from '../mediaObject/actions';

export const CONDITIONS_REQUEST = 'conditions/request';
export const CONDITIONS_SUCCESS = 'conditions/success';
export const CONDITIONS_FAILURE = 'conditions/failure';

export const CONDITIONS_SINGLE_REQUEST = 'conditions/single/request';
export const CONDITIONS_SINGLE_SUCCESS = 'conditions/single/success';
export const CONDITIONS_SINGLE_FAILURE = 'conditions/single/failure';

export const CONDITIONS_CREATE_REQUEST = 'conditions/create/request';
export const CONDITIONS_CREATE_SUCCESS = 'conditions/create/success';
export const CONDITIONS_CREATE_FAILURE = 'conditions/create/failure';

export const CONDITIONS_UPDATE_REQUEST = 'conditions/update/request';
export const CONDITIONS_UPDATE_SUCCESS = 'conditions/update/success';
export const CONDITIONS_UPDATE_FAILURE = 'conditions/update/failure';

export const CONDITIONS_DELETE_REQUEST = 'conditions/delete/request';
export const CONDITIONS_DELETE_SUCCESS = 'conditions/delete/success';
export const CONDITIONS_DELETE_FAILURE = 'conditions/delete/failure';

export const CONDITIONS_UPDATE_SET_ID = 'conditions/update/set-id';
export const CONDITIONS_UPDATE_CLEAR_ID = 'conditions/update/clear-id';

export const CONDITIONS_REMOVE_MEDIA = 'conditions/remove_media';
export const CONDITIONS_DELETE_MEDIA = 'conditions/delete_media';
export const CONDITIONS_DELETE_MEDIA_CANCEL = 'conditions/delete_media_cancel';
export const UPDATE_CONDITIONS_MEDIA = 'ADD_MEDIA/conditions';
export const CONDITIONS_SUBMIT_FORM = 'CONDITIONS/submit_form';

export const submitForm = () => ({
  type: CONDITIONS_SUBMIT_FORM,
});

export const fetchConditions = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [CONDITIONS_REQUEST, CONDITIONS_SUCCESS, CONDITIONS_FAILURE],
    endpoint: `/api/member/diagnoses`,
    meta: {
      pid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const fetchCondition = (pid, conditionId) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      CONDITIONS_SINGLE_REQUEST,
      CONDITIONS_SINGLE_SUCCESS,
      CONDITIONS_SINGLE_FAILURE,
    ],
    endpoint: `/api/member/diagnosis/${conditionId}`,
    meta: {
      pid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'GET',
        mode: 'cors',
      },
    },
  },
});

export const createCondition = (pid, condition, bundles) => {
  const payload = {
    ...condition,
    relatedTreatments: codeObjectListToCsv(condition.relatedTreatments),
    relatedSymptoms: codeObjectListToCsv(condition.relatedSymptoms),
    relatedMvs: codeObjectListToCsv(condition.relatedMvs),
    personId: pid,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CONDITIONS_CREATE_REQUEST,
        CONDITIONS_CREATE_SUCCESS,
        CONDITIONS_CREATE_FAILURE,
      ],
      endpoint: `/api/member/diagnosis`,
      payload,
      meta: {
        pid,
        mediaObject: setMediaObjects(bundles, 'personDiagnosisId', 'diagnosis'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

// export const createCondition = (pid, condition, bundles = []) => {
//   return async dispatch => {
//     const tid = uuidv1();
//     const payload = {
//       ...condition,
//       relatedTreatments: codeObjectListToCsv(condition.relatedTreatments),
//       relatedSymptoms: codeObjectListToCsv(condition.relatedSymptoms),
//       relatedMvs: codeObjectListToCsv(condition.relatedMvs),
//       personId: pid,
//     };
//     dispatch({ type: CONDITIONS_CREATE_REQUEST, tid, payload, bundles });

//     try {
//       const response = await apiFetchAuthorized(`/api/member/diagnosis`, {
//         method: 'POST',
//         mode: 'cors',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       const json = await response.json();

//       if (bundles.length > 0) {
//         await dispatch(
//           uploadFiles(pid, json.data.personDiagnosisId, bundles, 'diagnosis')
//         );
//       }

//       return await dispatch({
//         type: CONDITIONS_CREATE_SUCCESS,
//         payload: json.data,
//         tid,
//         bundles,
//       });
//     } catch (error) {
//       return dispatch({ type: CONDITIONS_CREATE_FAILURE, message: error, tid });
//     }
//   };
// };

export const updateCondition = (pid, conditionId, condition, bundles) => {
  const payload = {
    ...condition,
    personId: pid,
    personDiagnosisId: conditionId,
    relatedTreatments: codeObjectListToCsv(condition.relatedTreatments),
    relatedSymptoms: codeObjectListToCsv(condition.relatedSymptoms),
    relatedMvs: codeObjectListToCsv(condition.relatedMvs),
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        CONDITIONS_UPDATE_REQUEST,
        CONDITIONS_UPDATE_SUCCESS,
        CONDITIONS_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/diagnosis`,
      payload,
      meta: {
        pid,
        mediaObject: setMediaObjects(bundles, 'personDiagnosisId', 'diagnosis'),
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(payload),
          mode: 'cors',
        },
      },
    },
  };
};

// export const updateCondition = (pid, conditionId, condition, bundles = []) => {
//   return async dispatch => {
//     const payload = {
//       ...condition,
//       personId: pid,
//       personDiagnosisId: conditionId,
//       relatedTreatments: codeObjectListToCsv(condition.relatedTreatments),
//       relatedSymptoms: codeObjectListToCsv(condition.relatedSymptoms),
//       relatedMvs: codeObjectListToCsv(condition.relatedMvs),
//     };

//     dispatch({ type: CONDITIONS_UPDATE_REQUEST, payload });

//     try {
//       const response = await apiFetchAuthorized(`/api/member/diagnosis`, {
//         method: 'PUT',
//         mode: 'cors',
//         body: JSON.stringify(payload),
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       const json = await response.json();
//       if (bundles.length > 0) {
//         await dispatch(
//           uploadFiles(pid, json.data.personDiagnosisId, bundles, 'diagnosis')
//         );
//       }
//       console.log(json);
//       return await dispatch({
//         type: CONDITIONS_UPDATE_SUCCESS,
//         payload: json.data,
//         bundles,
//       });
//     } catch (error) {
//       return dispatch({ type: CONDITIONS_UPDATE_FAILURE, message: error });
//     }
//   };
// };

export const deleteCondition = (pid, conditionId, tid) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      CONDITIONS_DELETE_REQUEST,
      CONDITIONS_DELETE_SUCCESS,
      CONDITIONS_DELETE_FAILURE,
    ],
    endpoint: `/api/member/diagnosis/${conditionId}`,
    meta: {
      pid,
      personDiagnosis: conditionId,
      tid,
      config: {
        headers: {
          'Content-Type': 'application/json',
          Pid: pid,
        },
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});

export const setSelectedId = id => ({
  type: [CONDITIONS_UPDATE_SET_ID],
  payload: id,
});

export const clearSelectedId = () => ({
  type: [CONDITIONS_UPDATE_CLEAR_ID],
});
