import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PageHeader from '../../components/PageHeader';
import {
  ACCOUNT_PASSWORD_SUCCESS,
  ACCOUNT_PASSWORD_FAILURE,
  changePassword,
} from './actions';
import { createMessage } from '../../../store/reducers/flashMessenger/actions';
import ChangePasswordForm from '../../containers/ChangePasswordForm';
import FlashMessage from '../../containers/FlashMessage';
import BasePage from '../BasePage';
import { Grid, Divider } from '@material-ui/core';

let logInHistory = [
  {
    city: 'Detroit, MI',
    timestamp: 1510548327327,
    device: 'android',
    index: 0,
  },
  { city: 'Troy, MI', timestamp: 1510548327327, device: 'windows', index: 1 },
];

class LogInHistoryComponent extends Component {
  deviceIcon(device) {
    let icon = 'fa fa-' + device;
    return <i className={icon} aria-hidden="true"></i>;
  }

  dateFilter(date) {
    return <span>{Date(date)}</span>;
  }

  render() {
    return (
      false && (
        <div className="panel panel-default">
          <div className="panel-heading">Log In History</div>
          <ul className="list-group">
            {logInHistory.map(h => {
              return (
                <li className="list-group-item" key={h.index}>
                  <h4 className="list-group-item-heading">
                    {this.deviceIcon(h.device)} {h.city}
                  </h4>
                  <p className="list-group-item-text">
                    {this.dateFilter(h.timestamp)}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  }
}

class LoginMethodComponent extends Component {
  render() {
    const {
      updatePasswordSubmit,
      showChangePassword,
      isUpdating,
      onClick,
      identifyUserWith,
    } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="panel panel-default">
            <ul className="list-group">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-xs-7">
                    <h4 className="list-group-item-heading">Change Password</h4>
                    <p className="list-group-item-text">
                      To reset your password, provide your current password.
                      Make sure you use a strong password that's not used with
                      other services or websites.
                    </p>
                  </div>
                </div>
              </li>
              <li
                className={
                  'list-group-item' + (showChangePassword ? '' : ' hidden')
                }
              >
                <div className="row">
                  <div
                    style={{ paddingTop: 20 }}
                    className="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6"
                  >
                    <ChangePasswordForm
                      identifyUserWith={identifyUserWith}
                      onSubmit={updatePasswordSubmit}
                      isUpdating={isUpdating}
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    );
  }
}

class SecurityPage extends Component {
  state = {
    isChangePasswordPanelOpen: false,
  };

  updatePassword = async (values, dispatch, props) => {
    const { changePassword, createMessage } = this.props;

    const result = await changePassword(values.oldPassword, values.newPassword);

    if (result.type === ACCOUNT_PASSWORD_SUCCESS) {
      createMessage(
        'Your password has been successfully updated!',
        'info',
        5000
      );
    } else if (result.type === ACCOUNT_PASSWORD_FAILURE) {
      createMessage(
        'Could not update your password at this time, please try again later.',
        'warning'
      );
    }

    this.setState({ isChangePasswordPanelOpen: false });

    props.reset();
  };

  toggleChangePasswordPanel = () => {
    this.setState({
      isChangePasswordPanelOpen: !this.state.isChangePasswordPanelOpen,
    });
  };

  render() {
    let { isUpdating } = this.props;
    const { dashboard } = this.props.navigation;

    return (
      <BasePage>
        <Grid item xs={12}>
          <PageHeader
            headerText="Security"
            backButtonText="Go Back"
            backLink={dashboard.account.self}
          />

          <Divider />
          <FlashMessage />
          <h4>
            <i className="fa fa-shield" aria-hidden="true"></i>
            &nbsp; Help us keep your account safe
          </h4>
          <br />
          {/*loginhistory*/}
          <LogInHistoryComponent />

          <LoginMethodComponent
            identifyUserWith={this.props.emailAddress}
            onClick={this.toggleChangePasswordPanel}
            updatePasswordSubmit={this.updatePassword}
            showChangePassword={this.state.isChangePasswordPanelOpen}
            isUpdating={isUpdating}
          />
        </Grid>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  personId: state.member.login.details.personId,
  isUpdating: state.member.accountSecurity.isUpdating,
  emailAddress: state.member.login.details.sub,
});

const mapDispatchToProps = dispatch => ({
  changePassword: (newPassword, oldPassword) =>
    dispatch(changePassword(newPassword, oldPassword)),
  createMessage: (msg, type, timeout) =>
    dispatch(createMessage(msg, type, timeout)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(SecurityPage)),
};
