import React, { useRef, useState } from 'react';

import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultImageOrienter,

  // Plugins to use
  setPlugins,
  plugin_crop,
  plugin_finetune,
  effectBrightness,
  effectContrast,
  effectExposure,
  effectClarity,

  // Locales to use with plugins
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
} from '@pqina/pintura';

import { PinturaEditor } from '@pqina/react-pintura';
import { Box, Modal, Paper } from '@material-ui/core';

// Import the editor styles
import '@pqina/pintura/pintura.css';
import './ImageEditorModal.css';

const editorConfig = {
  imageReader: createDefaultImageReader({ orientImage: true }),
  imageWriter: createDefaultImageWriter(),
  imageOrienter: createDefaultImageOrienter(),

  cropMaskOpacity: 0.75,
  cropImageSelectionCornerStyle: 'hook',
  cropInteractionFocus: 'image',
  cropSelectPresetOptions: [
    // Position matters. The default is "Card Long" because it takes position 0 of the array
    [16 / 9, 'Card Long'],
    [3 / 2, 'Card Short'],
  ],

  // Finetune configuration
  finetuneControlConfiguration: {
    brightness: effectBrightness,
    contrast: effectContrast,
    exposure: effectExposure,
    clarity: effectClarity,
  },

  finetuneOptions: [
    ['brightness', locale => locale.finetuneLabelBrightness],
    ['contrast', locale => locale.finetuneLabelContrast],
    ['exposure', locale => locale.finetuneLabelExposure],
    ['clarity', locale => locale.finetuneLabelClarity],
  ],

  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
  },

  utils: ['crop', 'finetune'],
};

setPlugins(plugin_crop, plugin_finetune);

const ImageEditorModalContext = React.createContext(null);
ImageEditorModalContext.displayName = 'ImageEditorModelContext';

const initialState = {
  opened: false,
  source: null,
  type: null, // CARD_SELECTOR | AVATAR_SELECTOR | IMAGE_SELECTOR,
  onProcess: null,
};

export const ImageEditorModalProvider = ({ children }) => {
  const [state, openModal] = useState(initialState);

  const cachedState = React.useMemo(
    () => ({
      ...state,
      openModal,
    }),
    [state, openModal]
  );

  return (
    <ImageEditorModalContext.Provider value={cachedState}>
      {children}
    </ImageEditorModalContext.Provider>
  );
};

const useImageEditorContext = () => {
  const context = React.useContext(ImageEditorModalContext);

  if (!context)
    throw new Error(
      'Call made outside provider. You must use useImageEditorContext within the ImageEditorModelContext provider.'
    );

  return context;
};

const ModalComponent = ({ ref, type, source, onProcess }) => {
  const availableComponents = {
    AVATAR_SELECTOR: null,
    IMAGE_SELECTOR: null,
    CARD_SELECTOR: (
      <Box
        sx={{
          background: '#fff',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          height: 600,
          boxShadow: '0px 0px 10px 2px #666',
          borderRadius: 5,
          p: 2,
        }}
      >
        <PinturaEditor
          ref={ref}
          src={source}
          {...editorConfig}
          // Set the default to "Card Long"
          imageCropAspectRatio={editorConfig.cropSelectPresetOptions[0][0]}
          onProcess={onProcess}
        />
      </Box>
    ),
  };

  const component = availableComponents[type];
  if (!component)
    throw new Error(`Selected image editor component ${type} doesn't exist`);

  return component;
};

export const useImageEditor = () => {
  const {
    opened,
    source,
    type,
    openModal,
    onProcess,
  } = useImageEditorContext();

  const handleOpenModal = state => {
    openModal({
      ...state,
      opened: true,
    });
  };

  const handleCloseModal = () => {
    openModal(initialState);
  };

  return {
    opened,
    source,
    type,
    openModal: handleOpenModal,
    closeModel: handleCloseModal,
    onProcess,
  };
};

export default function ImageEditorModal() {
  const pinturaEditorRef = useRef();

  const { opened, source, type, onProcess, closeModel } = useImageEditor();

  const handleProcess = imageState => {
    if (!onProcess) return null;
    onProcess(imageState);
  };

  return (
    <>
      <Modal open={opened} onClose={closeModel}>
        <ModalComponent
          ref={pinturaEditorRef}
          type={type}
          source={source}
          onProcess={handleProcess}
        />
      </Modal>
    </>
  );
}
