import moment from 'moment';

export default values => {
  const errors = {};
  const startOn = moment(values.startOn);
  const endOn = moment(values.endOn);

  if (!values.name || (values.name && values.name.trim().length === 0)) {
    errors.name = 'Required';
  }

  if (values.startOn === null || typeof values.startOn === 'undefined') {
    errors.startOn = 'Required';
  }

  if (startOn.isAfter(endOn)) {
    errors.startOn = 'Start date must come before the end date';
  }

  if (endOn.isBefore(startOn)) {
    errors.endOn = 'End date must come after start date';
  }

  if (startOn.isAfter(moment())) {
    errors.startOn =
      'Future Start Dates are not permitted. Please enter the first date you became aware of your allergy.';
  }

  if (values.reaction && values.reaction.length > 40) {
    errors.reaction = `Only a maximum of 40 reactions will be accepted. There are ${values.reaction.length} reactions specified`;
  }

  if (values.treatment && values.treatment.length > 40) {
    errors.treatment = `Only a maximum of 40 treatments will be accepted. There are ${values.treatment.length} treatments specified`;
  }

  return errors;
};
