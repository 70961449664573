import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  fetchFragmentsByType,
  clearFragments,
} from '../../../store/reducers/journalEntries/actions';
import BasePage from '../BasePage';
import DashboardMessage from '../../components/DashboardContent/DashboardMessage';
import GlobalMessagesContainer from './GlobalMessagesContainer';
import GlobalMessage from '../../components/Notifications/Global/GlobalNotification';
import GoalProgress from '../../components/DashboardContent/GoalProgress';
import DashboardLinkButtons from '../../components/DashboardContent/DashboardLinkButtons';
import ProgressBar from '../../components/DashboardContent/ProgressBar';
import Today from '../../components/DashboardContent/Today';
import AlertsAndInsights from '../../components/DashboardContent/AlertsAndInsights';
import ChartsContainer from '../../containers/ChartsContainer';
import '../../components/DashboardContent/DashboardContent.css';
import {
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
} from '../../components/JournalCompose/FragmentsList';
import { format } from 'date-fns-tz';
import { subMonths, subYears } from 'date-fns';
import { fetchUserProfileProgress } from './actions';

const date = new Date();
const currDate = date.setHours(24, 0, 0, 0);
const fragmentList = [
  FRAGMENT_BODY_MASS,
  FRAGMENT_BLOOD_PRESSURE,
  FRAGMENT_WATER_INTAKE,
  FRAGMENT_SLEEP_TRACKER,
  FRAGMENT_MEAL_TRACKER,
  FRAGMENT_ACTIVITY_EXERCISE,
];

class DashboardHomePage extends Component {
  componentDidMount() {
    let { fetchUserProfileProgress, active, timeZoneIANA } = this.props;
    fetchUserProfileProgress(active.user.personId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.personId != prevProps.personId) {
      let { fetchUserProfileProgress, active, timeZoneIANA } = this.props;
      if (!Object.keys(this.props.fragments).length) {
        const startDate = format(
          subMonths(currDate, 12),
          "yyyy-MM-dd'T'HH:mm:ssXXX",
          { timeZoneIANA }
        );
        const endDate = format(currDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
          timeZoneIANA,
        });
        const params = `?startDate=${startDate}&endDate=${endDate}`;
        this.props.clearFragments();
        fragmentList.forEach(type => {
          this.props.fetchFragmentsByType(type, params, this.props.personId);
        });
      }
    }
  }

  profileProgressPercent(total, completed) {
    return ((completed / total) * 100 || 0).toFixed(0) + '%';
  }

  profileProgressNextStep(total, completed, progress) {
    const { account, healthRecord, careTeam } = this.props.navigation.dashboard;

    const user = 'me';

    const routeMap = {
      allergies: {
        route: healthRecord.allergies.read,
        message: 'add your allergies',
      },
      emergencyinfo: {
        route: account.emergencyContact.read,
        message: 'add your emergency contacts',
      },
      immunizations: {
        route: healthRecord.immunizations.read,
        message: 'add your immunizations',
      },
      medicalhx: {
        route: healthRecord.familyHistory,
        message: 'add your family medical history',
      },
      mvs: { route: healthRecord.mvs.read, message: 'add your medications' },
      portals: {
        route: healthRecord.portals.read,
        message: 'add your service portals',
      },
      insurance: {
        route: account.insurance.read,
        message: 'add your insurance info',
      },
      basicinfo: {
        route: account.information,
        message: 'add your basic information',
      },
      labresult: {
        route: healthRecord.labs,
        message: 'add a lab result',
      },
      surgicalhistory: {
        route: healthRecord.surgicalHistory.read,
        message: 'add a surgical history record',
      },
      careteam: {
        route: careTeam,
        message: 'add a care team member',
      },
      persondiagnosis: {
        route: healthRecord.conditions.read,
        message: 'add your condition',
      },
      //ignored for now
      // haslegaldocuments: {route:'', message: 'add your legal documents'},
      // hasjournal: {route:'', message: 'add your journal'},
    };

    const nextStep = progress.filter(c => !c.value).shift();
    const name = nextStep ? nextStep.name.toLowerCase() : '';

    return completed === total ? (
      <p>All done!</p>
    ) : nextStep ? (
      <p>
        Next step: &nbsp;
        <Link to={routeMap[name].route}>{routeMap[name].message}</Link>
      </p>
    ) : (
      <p>...</p>
    );
  }

  render() {
    const { progress = [], active, navigation } = this.props;

    const total = progress.length;
    const completed =
      total > 0 && progress.filter(c => c.value === true).length;

    return (
      <BasePage>
        <Grid item xs={12} md={8}>
          <GlobalMessagesContainer />

          <DashboardMessage canClose={false}>
            <strong>Welcome</strong> to your Family Manager. To assist you in
            getting the most from your app, the Progress bar will guide you
            through getting started, and the Help page is available for
            information, tutorial videos and Support requests. We want you to
            love your experience - please share your feedback as you have
            comments or thoughts about your app experience using the link at the
            bottom of the left-hand navigation section.
          </DashboardMessage>
          {/* <GoalProgress/> */}
          <DashboardLinkButtons
            isDependent={this.props.isDependent}
            navigation={navigation}
          />
          <ChartsContainer
            fragments={this.props.fragments}
            hasFetchedFragments={this.props.hasFetchedFragments}
            timeZoneIANA={this.props.timeZoneIANA}
            clearFragments={this.props.clearFragments}
            fetchFragmentsByType={this.props.fetchFragmentsByType}
            personId={this.props.personId}
            route={this.props.navigation.dashboard.charts}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {active.user.type === 'primary' ? (
            <ProgressBar
              percent={this.profileProgressPercent(total, completed)}
              message={this.profileProgressNextStep(total, completed, progress)}
            />
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <video width="100%" controls>
              <source
                src="https://hebcore.blob.core.windows.net/videos/agrin-heb-summary.mp4"
                type="video/mp4"
              />
            </video>
            <p>
              <Link to="/d/help">Additional Tutorials</Link>
            </p>
          </Grid>
          {/* <Today /> */}
          {/* <AlertsAndInsights /> */}
        </Grid>
      </BasePage>
    );
  }
}

const mapStateToProps = state => ({
  timeZoneIANA: state.app.timeZoneIANA,
  active: state.active,
  progress: state.member.userProfileProgress.profileProgress.progress,
  fragments: state.journalEntries.fragments,
  isParsing: state.journalEntries.isParsingFragments,
  hasFetchedFragments: state.journalEntries.hasFetchedFragments,
  personId: state.active.user.personId,
  isDependent: Boolean(state.active.user.type === 'dependent'),
});

const mapDispatchToProps = dispatch => ({
  fetchUserProfileProgress: id => dispatch(fetchUserProfileProgress(id)),
  fetchFragmentsByType: (type, params, pid) =>
    dispatch(fetchFragmentsByType(type, params, pid)),
  clearFragments: () => dispatch(clearFragments()),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(DashboardHomePage)),
};
