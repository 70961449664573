import React, { useState } from 'react';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

import NotificationIcon from '../Event/NotificationIcon';
import TimeAgo from '../TimeAgo';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    background: '#fff',
    borderBottom: '.7px solid #CECECE', //    marginTop: "20px" doesn't work here
    minHeight: '70px',
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '13px',
    paddingLeft: '23px',
    paddingRight: '0',
    position: 'relative',
  },
  notificationHistoryRoot: {
    background: '#fff',
    borderBottom: '.7px solid #CECECE', //    marginTop: "20px" doesn't work here
    minHeight: '70px',
    width: '100%',
    paddingBottom: '20px',
    paddingLeft: '23px',
    paddingTop: '20px',
    paddingRight: '0',
    position: 'relative',
  },
  noticeText: {
    fontWeight: '500',
    fontSize: '10.5px',
    marginRight: '15px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
    },
  },
  notificationHistoryNoticeText: {
    fontWeight: '500',
    fontSize: '14px',
    marginRight: '15px',
  },
  rowStyle: {
    border: '1px solid #F2ECEC',
    paddingTop: theme.spacing.unit * 2,
    height: 'auto',
  },
  timerStyle: {
    fontSize: '100%',
    color: '#8B8B8B',
    position: 'relative',
    fontSize: '13px',
    transform: 'translateY(20%)',
  },
  notificationHistoryTimerStyle: {
    fontSize: '100%',
    color: '#8B8B8B',
    position: 'relative',
    fontSize: '13px',
    transform: 'translateY(20%)',
    top: '2px',
  },
  timestampText: {
    position: 'relative',
    left: '5px',
    top: '2px',
    fontSize: '9px',
    color: '#75747B',
    userSelect: 'none',
    [theme.breakpoints.up('lg')]: {
      fontSize: '10px',
    },
  },
  notificationHistoryTimestampText: {
    position: 'relative',
    left: '5px',
    top: '2px',
    fontSize: '12px',
    color: '#75747B',
    userSelect: 'none',
  },
  timerRowStyle: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '14px',
  },
  notificationHistoryTimerRowStyle: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '20px',
  },
  buttonBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginTop: '-10px',
    // marginBottom: theme.spacing.unit,
  },
  notificationHistoryButtonBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginTop: '-15px',
    // marginBottom: theme.spacing.unit,
  },
  button: {},
  decline: {
    background: '#90B850',
    color: '#fff',
    fontSize: '10px',
    padding: '0 10px',
    marginRight: '10px',
    margin: '0 10px',
  },
  accept: {
    background: '#ED5158',
    color: '#fff',
    fontSize: '10px',
    padding: '0 10px',
    margin: '0 10px',
  },
  iconContainer: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    fontSize: '17px',
    marginTop: '2px',
  },
  notificationHistoryIcon: {
    marginTop: '1px',
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
  messageContainer: {
    paddingLeft: '4%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
});

const NotificationMessage = props => {
  const { data, classes, fromNotificationsHistory } = props;

  const onClickButton = e => {
    e.stopPropagation();
    alert('These are so annoying');
  };

  return (
    data !=
    null(
      <div
        key={data.id}
        className={classNames({
          [classes.notificationHistoryRoot]: fromNotificationsHistory,
          [classes.root]: !fromNotificationsHistory,
        })}
      >
        <Grid
          container
          style={{
            background: 'F0F0F0',
            paddingBottom: '10px',
            margin: 'auto 0',
          }}
          spacing={8}
          className={classNames({
            [classes.notificationTextContainer]: fromNotificationsHistory,
            [classes.textContainer]: !fromNotificationsHistory,
          })}
        >
          <Grid
            className={classes.icon}
            item
            xs={1}
            sm={1}
            className={classNames({
              [classes.notificationHistoryIcon]: fromNotificationsHistory,
              [classes.iconContainer]: !fromNotificationsHistory,
            })}
          >
            <NotificationIcon type={data.type} />
            {/* {getNotificationIcon(data.type, classes.greyIconStyle)} */}
          </Grid>

          <Grid item sm={11} xs={11}>
            <div className={classes.messageContainer}>
              <Typography
                variant="body2"
                gutterBottom
                className={classNames({
                  [classes.notificationHistoryNoticeText]: fromNotificationsHistory,
                  [classes.noticeText]: !fromNotificationsHistory,
                })}
              >
                {data.message}
              </Typography>

              <Grid
                container
                spacing={8}
                className={classNames({
                  [classes.notificationHistoryButtonBar]: fromNotificationsHistory,
                  [classes.buttonBar]: !fromNotificationsHistory,
                })}
              >
                <Grid item sm={12}>
                  <Button
                    size="small"
                    className={`${classes.button} ${classes.accept}`}
                    onClick={onClickButton}
                  >
                    Accept
                  </Button>

                  <Button
                    size="small"
                    className={`${classes.button} ${classes.decline}`}
                    onClick={onClickButton}
                  >
                    Decline
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                className={classNames({
                  [classes.notificationHistoryTimerRowStyle]: fromNotificationsHistory,
                  [classes.timerRowStyle]: !fromNotificationsHistory,
                })}
              >
                <TimerIcon
                  className={classNames({
                    [classes.notificationHistoryTimerStyle]: fromNotificationsHistory,
                    [classes.timerStyle]: !fromNotificationsHistory,
                  })}
                />
                <Typography
                  className={classNames({
                    [classes.notificationHistoryTimestampText]: fromNotificationsHistory,
                    [classes.timestampText]: !fromNotificationsHistory,
                  })}
                >
                  <TimeAgo timestamp={data.createdOn} />
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default withStyles(styles)(NotificationMessage);
