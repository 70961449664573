import {
  getKeys,
  arrayToHashMap,
  csvToCodeObjectList,
} from '../../../library/helpers/utils';

import {
  CARE_TEAM_REQUEST,
  CARE_TEAM_SUCCESS,
  CARE_TEAM_FAILURE,
  CARE_TEAM_CREATE_REQUEST,
  CARE_TEAM_CREATE_SUCCESS,
  CARE_TEAM_CREATE_PROVIDER_SUCCESS,
  CARE_TEAM_CREATE_FAILURE,
  CARE_TEAM_UPDATE_REQUEST,
  CARE_TEAM_UPDATE_SUCCESS,
  CARE_TEAM_UPDATE_FAILURE,
  CARE_TEAM_ARCHIVE_SUCCESS,
  CARE_TEAM_UPDATE_SET_ID,
  CARE_TEAM_UPDATE_CLEAR_ID,
  CARE_TEAM_DELETE_SUCCESS,
} from './actions';

import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

const initialState = {
  // This field is used to prevent the flickering change over between two view states "no members"
  // and "has members". By default it's set to true to render members, however, if members are found
  // then it's set to false. The result is calculated in CARE_TEAM_SUCCESS and CARE_TEAM_FAILURE
  list: {},
  listIds: [],
  selectedId: null,
  creatingId: null,

  uiState: LOADING,
};

const reducers = {};

reducers[CARE_TEAM_REQUEST] = (state, action) => {
  return {
    ...state,
    uiState: LOADING,
  };
};

reducers[CARE_TEAM_SUCCESS] = (state, action) => {
  const data = action.payload.map(d => ({
    ...d,
    labels: csvToCodeObjectList(d.labels),
  }));

  const list = arrayToHashMap('relationshipId', data);
  const listIds = getKeys('relationshipId', data);
  return {
    ...state,
    list,
    listIds,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[CARE_TEAM_FAILURE] = (state, action) => {
  return {
    ...state,
    list: {},
    listIds: [],
    error: action.payload.error,
    uiState: ERROR,
  };
};

reducers[CARE_TEAM_CREATE_REQUEST] = (state, action) => {
  return {
    ...state,
    creatingId: action.meta.oid,
  };
};

reducers[CARE_TEAM_CREATE_SUCCESS] = (state, action) => {
  const { relationshipId } = action.payload;

  return {
    ...state,
    list: {
      ...state.list,
      [relationshipId]: {
        ...action.payload,
        labels: csvToCodeObjectList(action.payload.labels),
      },
    },
    listIds: [].concat(state.listIds, [relationshipId]),
    creatingId: null,
    uiState: SUCCESS,
  };
};

reducers[CARE_TEAM_CREATE_FAILURE] = (state, action) => {
  return {
    ...state,
    error: action.payload.error,
    uiState: ERROR,
  };
};

reducers[CARE_TEAM_UPDATE_REQUEST] = (state, action) => {
  return state;
};

reducers[CARE_TEAM_UPDATE_SUCCESS] = (state, action) => {
  const updatedRecord = {
    // ...state.list[action.payload.relationshipId],
    ...action.payload,
    labels: csvToCodeObjectList(action.payload.labels),
  };

  return {
    ...state,
    list: {
      ...state.list,
      [action.payload.relationshipId]: updatedRecord,
    },
  };
};

reducers[CARE_TEAM_UPDATE_FAILURE] = (state, action) => {
  return {
    ...state,
    error: action.payload.error,
  };
};

reducers[CARE_TEAM_ARCHIVE_SUCCESS] = (state, action) => {
  const member = {
    ...state.list[action.meta.rid],
    archivedOn: action.payload.archivedOn,
    status: action.payload.status,
  };

  return {
    ...state,
    list: {
      ...state.list,
      [action.meta.rid]: member,
    },
  };
};

reducers[CARE_TEAM_DELETE_SUCCESS] = (state, action) => {
  const memberList = state.list;
  const listIndex = state.listIds.indexOf(action.payload.relationshipId);
  delete memberList[action.payload.relationshipId];
  const listIds = [
    ...state.listIds.slice(0, listIndex),
    ...state.listIds.slice(listIndex + 1),
  ];

  return {
    ...state,
    list: {
      ...memberList,
    },
    listIds,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[CARE_TEAM_UPDATE_SET_ID] = (state, action) => {
  return {
    ...state,
    selectedId: action.payload,
  };
};

reducers[CARE_TEAM_UPDATE_CLEAR_ID] = (state, action) => {
  return {
    ...state,
    selectedId: null,
  };
};

const reducer = (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};

export default reducer;
