import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';

const BloodPressureViewer = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <InputField
          label="Systolic"
          value={data.systolic}
          placeholder="0.00"
          shrink={true}
          InputProps={{ readOnly: true, disabled: true }}
          enableMinHeight={false}
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          label="Diastolic"
          value={data.diastolic}
          placeholder="0.00"
          shrink={true}
          InputProps={{ readOnly: true, disabled: true }}
          enableMinHeight={false}
        />
      </Grid>
    </Grid>
  );
};

export default BloodPressureViewer;
