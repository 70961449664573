import React from 'react';
import Fade from '@material-ui/core/Fade';
import AddCircleoutlineIcon from '@material-ui/icons/AddCircleOutline';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import AddInfoForm from './AddInfoForm';
import DeleteComponent from './DeleteComponent';
import styles from '../../../containers/MediaObject/File.css';

const AddDetails = props => {
  const {
    bundle,
    index,
    setCurrentIndex,
    isDeleting,
    isDocument = false,
  } = props;
  const key = `previewMedia${index}`;
  return (
    <Fade in={true}>
      <div>
        {props.currentIndex !== key && (
          <div
            className={styles.addInfoBtn}
            onClick={() => setCurrentIndex(key)}
          >
            <AddCircleoutlineIcon /> Details
          </div>
        )}

        {props.currentIndex === key && (
          <div
            className={styles.addInfoBtn}
            onClick={() => setCurrentIndex('')}
          >
            <CollapseIcon /> Details
          </div>
        )}
        <AddInfoForm
          bundle={bundle}
          index={index}
          name={key}
          collapsed={props.currentIndex === key}
          type={props.type}
          isDocument={isDocument}
        />
      </div>
    </Fade>
  );
};

const PreviewDetails = props => {
  const { toggleDelete, isDeleting } = props;

  return !toggleDelete ? (
    isDeleting ? (
      <DeleteComponent toggleDelete={toggleDelete} />
    ) : (
      <AddDetails {...props} />
    )
  ) : (
    ''
  );
};

export default PreviewDetails;
