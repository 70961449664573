import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BasePage from '../BasePage';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';

import {
  setSelectedId,
  clearSelectedId,
  fetchCondition,
  deleteCondition,
} from '../../../store/reducers/conditions/actions';

import { ViewCondition } from '.';

const ViewConditionPage = ({
  navigation: { dashboard },
  history,
  personId,
  selectedId,
  conditionsList,
  conditions,
  ...props
}) => {
  useEffect(() => {
    if (!conditions.isFetching && !selectedId) {
      // When the browser is refreshed the current person defaults to the signed in person.
      // This is a known bug that will be removed when the new person/dependent session system
      // is implemented. For now just redirect the person back to the main view page.
      // const result = props
      //   .fetchCondition(personId, props.match.params.conditionsId)
      //   .then(res => {
      //     if (res.error) history.goBack();

      //     props.setSelectedId(props.match.params.conditionsId);
      //   });
      history.goBack();
    }
  }, [personId]);

  const onClickDeleteCard = () => {
    const realId = conditions.list[selectedId].conditionsId;
    props.deleteCondition(personId, realId, selectedId);
    history.push(dashboard.healthRecord.conditions.read);
    props.clearSelectedId();
  };

  const onClickUpdateCard = () => {
    props.setSelectedId(selectedId);
    history.push(dashboard.healthRecord.conditions.update);
  };

  const onCancel = () => {
    history.push(dashboard.healthRecord.conditions.read);
    props.clearSelectedId();
    props.reset();
  };

  const { classes } = props;

  return (
    <BasePage>
      <Grid item xs={12} md={7} lg={7}>
        <PageHeader
          headerText="Conditions & Diagnoses"
          subHeaderText={`View Diagnosis`}
          backButtonText="Go Back"
          backLink={dashboard.healthRecord.conditions.read}
          onBack={onCancel}
        />
        {conditionsList[selectedId] && (
          <ViewCondition
            onCancel={onCancel}
            onDelete={onClickDeleteCard}
            onUpdate={onClickUpdateCard}
            data={conditionsList[selectedId]}
          />
        )}
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = state => ({
  personId: state.active.user.personId,
  conditions: state.conditions,
  selectedId: state.conditions.selectedId,
  conditionsList: state.conditions.list,
});

const mapDispatchToProps = dispatch => ({
  clearSelectedId: () => dispatch(clearSelectedId()),
  fetchCondition: (pid, conditionsId) =>
    dispatch(fetchCondition(pid, conditionsId)),
  deleteCondition: (pid, conditionsId, tid) =>
    dispatch(deleteCondition(pid, conditionsId, tid)),
  setSelectedId: conditionsId => dispatch(setSelectedId(conditionsId)),
});

export default {
  component: withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ViewConditionPage)
  ),
};
