import React, { Component, useMemo } from 'react';
import { Grid, Button, Icon } from '@material-ui/core';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';

import Table from '../../components/ReactTable';

import {
  fetchInsurances,
  insuranceSelectId,
  insuranceClearSelectedId,
  insuranceDelete,
} from './actions';
import {
  cancelFormReset,
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';

import PageHeader from '../../components/PageHeader';
import BasePage, { HEALTH_SERVICES } from '../BasePage';
import styles from '../Table.css';
import { dateTimeRender } from '../../components/ReactTable/utils';

const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const sort = sortBy => {
  return (a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] === b[sortBy]) {
      return 0;
    }
  };
};

class InsuranceInformationPage extends Component {
  state = {
    personId: 0,
    personHasChanged: false,
  };

  componentDidMount() {
    const {
      personId,
      fetchInsurances,
      list,
      isFormCanceled,
      cancelFormReset,
    } = this.props;

    if (!list && !isFormCanceled) {
      fetchInsurances(personId);
    }

    if (isFormCanceled) {
      cancelFormReset();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.personId !== prevState.personId) {
      return {
        personId: nextProps.personId,
        personHasChanged: true,
      };
    }

    return null;
  }

  // Dependent on getDerivedStateFromProps
  componentDidUpdate(prevProps) {
    if (
      prevProps.personId !== this.state.personId ||
      this.state.personHasChanged
    ) {
      this.props.fetchInsurances(this.state.personId);
      this.setState({ personHasChanged: false });
    }
  }

  createPolicy = () => {
    const {
      history,
      uiStateEntityCreate,
      clearEditUserInsurancePage,
    } = this.props;

    const route = this.props.navigation.dashboard.account;

    uiStateEntityCreate('INSURANCE');
    history.push(route.insurance.create);
  };

  updatePolicy = id => {
    const { history, uiStateEntityUpdate, insuranceSelectId } = this.props;
    const route = this.props.navigation.dashboard.account;

    uiStateEntityUpdate('INSURANCE');
    insuranceSelectId(id);
    history.push(route.insurance.update);
  };

  removePolicy = row => {
    const { insuranceDelete } = this.props;
    insuranceDelete(row);
  };

  render() {
    const { dashboard } = this.props.navigation;
    const { list, listIds, uiState } = this.props.insurance;

    return (
      <BasePage
        type={HEALTH_SERVICES}
        state={uiState}
        emptyStateComponentProps={{
          name: 'Insurance Policy',
          type: 'Insurance Policies',
          onClick: this.createPolicy,
        }}
      >
        <Grid item xs={12}>
          <PageHeader
            headerText="Insurance Policies"
            subHeaderText="View Records"
            backButtonText="Go Back"
            backLink={dashboard.account.self}
            rightSlot={
              <Button
                color="primary"
                variant="contained"
                onClick={this.createPolicy}
                style={{ float: 'right' }}
              >
                <Icon>add</Icon>
                New Policy
              </Button>
            }
          />
          <div>
            <InfoTable
              data={list}
              onEdit={this.updatePolicy}
              onDelete={this.removePolicy}
            />
            {/* <InsuranceInformationTable
            isFetching={uiState === LOADING}
            listIds={listIds}
            list={list}
            editInsurance={this.updatePolicy}
            removeInsurance={this.removePolicy}
          /> */}
          </div>
        </Grid>
      </BasePage>
    );
  }
}

function mapDataToTableRows(data) {
  return Object.keys(data).map(key => ({
    id: key,
    ...data[key],
  }));
}

const InfoTable = ({ data, onEdit, onDelete }) => {
  var cachedData = useMemo(() => mapDataToTableRows(data), [data]);

  const columns = [
    {
      Header: 'Type',
      accessor: 'coverageType',
      col: '10%',
      Cell: ({ value }) => value || '--',
    },
    {
      Header: 'Carrier',
      accessor: 'healthPlanName',
      col: '15%',
      Cell: ({ value }) => value || '--',
    },
    {
      Header: 'Network',
      accessor: 'networkName',
      col: '15%',
      Cell: ({ value }) => value || '--',
    },
    {
      Header: 'Effective Date',
      accessor: 'coverageStartOn',
      col: '25%',
      Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
    },
    {
      Header: 'End Date',
      accessor: 'coverageEndOn',
      col: '25%',
      Cell: ({ value }) => (value ? dateTimeRender(value, { dateOnly: true }) : '--'),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={cachedData}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};

class InsuranceInformationTable extends Component {
  _rowClassName({ index }) {
    if (index < 0) {
      return styles.headerRow;
    } else {
      return index % 2 === 0 ? styles.evenRow : styles.oddRow;
    }
  }

  _dateRender(rowData) {
    return rowData.cellData
      ? dateTimeRender(rowData.cellData, { dateOnly: true })
      : 'None';
  }

  _editInsurance = (e, row) => {
    this.props.editInsurance(row.rowData.insuranceId);
  };

  _removeInsurance = (e, row) => {
    this.props.removeInsurance(e, row);
  };

  _rowGetter = ({ index }) => {
    const { list, listIds } = this.props;
    return list[listIds[index]];
  };

  render() {
    const { listIds = [], list = {}, isFetching } = this.props;

    return (
      <>
        Testing
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              height={600}
              width={width}
              headerHeight={50}
              rowHeight={60}
              // TODO -- This needs to be fixed. For reason not understood, the
              // listIds is not being treated as an array, and thus doesn't have
              // the .length property which disrupts the rendering of the app
              rowCount={isFetching ? 0 : listIds.length}
              headerClassName={styles.headerColumn}
              rowClassName={this._rowClassName}
              rowGetter={this._rowGetter}
            >
              <Column width={300} dataKey="coverageType" label="Type" />
              <Column width={300} dataKey="healthPlanName" label="Carrier" />
              <Column width={300} dataKey="networkName" label="Network" />
              <Column
                width={75}
                dataKey=""
                label="Card"
                cellRenderer={({ rowData }) => {
                  return (
                    <button type="button" className="btn btn-link btn-sm">
                      <i className="fa fa-credit-card"></i>
                    </button>
                  );
                }}
              />
              <Column
                width={200}
                flexShrink={1}
                dataKey="coverageStartOn"
                label="Effective Date"
                cellRenderer={this._dateRender}
              />
              <Column
                width={200}
                flexShrink={1}
                dataKey="coverageEndOn"
                label="End Date"
                cellRenderer={this._dateRender}
              />
              <Column
                label=""
                width={100}
                flexGrow={1}
                flexShrink={1}
                dataKey="id"
                headerRenderer={() => ''}
                cellRenderer={rowData => {
                  return (
                    <div className="pull-right">
                      <button
                        type="button"
                        className="btn btn-default btn-sm"
                        onClick={e => this._editInsurance(e, rowData)}
                      >
                        <i className="fa fa-edit" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-sm"
                        onClick={e => this._removeInsurance(e, rowData)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  );
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  insurance: state.member.insurance,
  personId: state.active.user.personId,
  isFormCanceled: state.ui.isFormCanceled,
});

const mapDispatchToProps = dispatch => ({
  fetchInsurances: id => dispatch(fetchInsurances(id)),
  insuranceSelectId: id => dispatch(insuranceSelectId(id)),
  insuranceClearSelectedId: () => dispatch(insuranceClearSelectedId()),
  insuranceDelete: insurance => dispatch(insuranceDelete(insurance)),
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InsuranceInformationPage)),
};
