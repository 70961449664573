import { omit } from 'lodash';
import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';

import {
  INSURANCE_REQUEST,
  INSURANCE_SUCCESS,
  INSURANCE_FAILURE,
  INSURANCE_CREATE_REQUEST,
  INSURANCE_CREATE_SUCCESS,
  INSURANCE_CREATE_FAILURE,
  INSURANCE_UPDATE_REQUEST,
  INSURANCE_UPDATE_SUCCESS,
  INSURANCE_UPDATE_FAILURE,
  INSURANCE_DELETE_REQUEST,
  INSURANCE_DELETE_SUCCESS,
  INSURANCE_DELETE_FAILURE,
  INSURANCE_SELECT_ID,
  INSURANCE_CLEAR_ID,
  MEDIA_REMOVE_SUCCESS,
} from './actions';

import { LOADING, ERROR, EMPTY, SUCCESS } from '../../../store/state';

const initialState = {
  didInvalidate: false,
  list: {},
  listIds: [],
  selectedId: null,

  uiState: LOADING,
};

const reducers = {};

reducers[INSURANCE_REQUEST] = (state, action) => ({
  ...state,
  uiState: LOADING,
  didInvalidate: true,
});

reducers[INSURANCE_SUCCESS] = (state, action) => {
  const listIds = getKeys('insuranceId', action.payload);
  return {
    ...state,
    list: arrayToHashMap('insuranceId', action.payload),
    listIds,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[INSURANCE_FAILURE] = (state, action) => ({
  ...state,
  list: {},
  listIds: [],
  uiState: ERROR,
});

reducers[INSURANCE_CREATE_REQUEST] = (state, action) => ({
  ...state,
});

reducers[INSURANCE_CREATE_SUCCESS] = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    [action.payload.insuranceId]: action.payload,
  },
  listIds: state.listIds.concat(action.payload.insuranceId),
  uiState: SUCCESS,
});

reducers[INSURANCE_CREATE_FAILURE] = (state, action) => ({
  ...state,
  list: {},
  listIds: [],
});

reducers[INSURANCE_UPDATE_REQUEST] = (state, action) => ({
  ...state,
  uiState: LOADING,
});

reducers[INSURANCE_UPDATE_SUCCESS] = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    [action.payload.insuranceId]: action.payload,
  },
  uiState: SUCCESS,
});

reducers[INSURANCE_UPDATE_FAILURE] = (state, action) => ({
  ...state,
  uiState: ERROR,
});

reducers[INSURANCE_DELETE_REQUEST] = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    [action.meta.config.body]: {
      ...state.list[action.meta.config.body],
      isDeleting: true,
    },
  },
});

reducers[INSURANCE_DELETE_SUCCESS] = (state, action) => {
  const { insuranceId } = action.payload;
  const list = omit(state.list, insuranceId);
  const listIds = state.listIds.filter(idx => idx !== insuranceId);

  return {
    ...state,
    list,
    listIds,
    uiState: listIds.length > 0 ? SUCCESS : EMPTY,
  };
};

reducers[INSURANCE_DELETE_FAILURE] = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    [action.meta.config.body]: {
      ...state.list[action.meta.config.body],
      isDeleting: true,
    },
  },
});

reducers[INSURANCE_SELECT_ID] = (state, action) => ({
  ...state,
  selectedId: action.payload,
});

reducers[INSURANCE_CLEAR_ID] = (state, action) => ({
  ...state,
  selectedId: null,
});

reducers[MEDIA_REMOVE_SUCCESS] = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    [state.selectedId]: {
      ...state.list[state.selectedId],
      mediaObject: state.list[state.selectedId].mediaObject.filter(
        item => item.mediaObjectId !== action.payload
      ),
    },
  },
});

export default (state = initialState, action) => {
  return action.type in reducers ? reducers[action.type](state, action) : state;
};
