import React, { useMemo, Component } from 'react';
import classNames from 'classnames';
import { withStyles, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, FieldArray, change } from 'redux-form';
import twemoji from 'twemoji';

import FragmentDialogBase from '../FragmentDialogBase';
import { properties as fragmentProperties } from './properties';

// All icons representing emotions should either be gender and race neutral or
// have icons that represent both male and female (which should encourage a stronger bond with the imagery)
// See unicode spec: https://www.unicode.org/reports/tr51/tr51-18.html
// See unicode: https://unicode.org/Public/emoji/1.0/emoji-data.txt
// See unicode map: https://unicode.org/Public/emoji/13.0/emoji-test.txt
export const emotions = [
  {
    icon: '🙂',
    label: 'Serenity',
    value: 'serenity',
    description: 'The state of being calm, peaceful, and untroubled.',
  },
  {
    icon: '😁',
    label: 'Joy',
    value: 'joy',
    description: 'A feeling of great pleasure and happiness',
  },
  {
    icon: '😊',
    label: 'Ecstasy',
    value: 'ecstasy',
    description:
      'An overwhelming feeling of great happiness or joyful excitement.',
  },
  // { icon: '👍', label: 'Acknowledgment', value: 'acknowledgment', description: "Acceptance of the truth or existence of something."},
  // { icon: '❤️', label: 'Love', value: 'love', description: 'An intense feeling of deep affection.'},
  // { icon: '😍', label: 'Devotion', value: 'devotion', description: 'Love, loyalty, or enthusiasm for a person, activity, or cause.'},
  {
    icon: '👌',
    label: 'Acceptance',
    value: 'acceptance',
    description:
      'The action of consenting to receive or undertake something offered.',
  },
  {
    icon: '🤝',
    label: 'Trust',
    value: 'trust',
    description:
      'Firm belief in the reliability, truth, ability, or strength of someone or something.',
  },
  {
    icon: '🙃',
    label: 'Admire',
    value: 'admire',
    description:
      'Regard (an object, quality, or person) with respect or warm approval.',
  },
  // { icon: '😎', label: 'Acquiescence', value: 'acquiescence', description: "The reluctant acceptance of something without protest."},
  // { icon: '🙇', label: 'Submission', value: 'submission', description: "The action or fact of accepting or yielding to a superior force or to the will or authority of another person."},
  // { icon: '😇', label: 'Subservience', value: 'subservience', description: "Willingness to obey others unquestioningly."},
  {
    icon: '😬',
    label: 'Apprehension',
    value: 'apprehension',
    description:
      'Anxiety or fear that something bad or unpleasant will happen.',
  },
  {
    icon: '😨',
    label: 'Fear',
    value: 'fear',
    description:
      'An unpleasant emotion caused by the belief that someone or something is dangerous, likely to cause pain, or a threat.',
  },
  {
    icon: '😱',
    label: 'Terror',
    value: 'terror',
    description: 'Extreme fear.',
  },
  // { icon: '😕', label: 'Wariness', value: 'wariness', description: "Caution about possible dangers or problems."},
  // { icon: '😯', label: 'Awe', value: 'Awe', description: 'A feeling of reverential respect mixed with fear or wonder.'},
  // { icon: '😵', label: 'Petrified', value: 'petrified', description: 'So frightened that one is unable to move; terrified.'},
  {
    icon: '😜',
    label: 'Distraction',
    value: 'distraction',
    description:
      'A thing that prevents someone from giving full attention to something else.',
  },
  {
    icon: '😮',
    label: 'Surprise',
    value: 'surprise',
    description: 'An unexpected or astonishing event, fact, or thing.',
  },
  // Mind blown emoji would be perfect here. However, it doesn't exist in my version of OS.
  {
    icon: '✨',
    label: 'Amazement',
    value: 'amazement',
    description: 'Greatly surprised; astonished.',
  },
  // { icon: '🤦‍', label: 'Dismay', value: 'dismay', description: "Consternation and distress, typically that caused by something unexpected."},
  // { icon: '😒', label: 'Disapproval', value: 'disapproval', description: "Possession or expression of an unfavorable opinion."},
  // { icon: '👻', label: 'Horror', value: 'horror', description: 'An intense feeling of fear, shock, or disgust.'},
  {
    icon: '🤔',
    label: 'Pensiveness',
    value: 'pensiveness',
    description:
      'Engaged in, involving, or reflecting deep or serious thought.',
  },
  {
    icon: '😭',
    label: 'Sadness',
    value: 'sadness',
    description: 'The condition or quality of being sad.',
  },
  {
    icon: '😔',
    label: 'Grief',
    value: 'grief',
    description: "Deep sorrow, especially that caused by someone's death.",
  },
  // { icon: '😑', label: 'Listlessness', value: 'listlessness', description: 'Having or showing little or no interest in anything.'},
  // { icon: '😩', label: 'Remorse', value: 'remorse', description: 'Deep regret or guilt for a wrong committed.'},
  // { icon: '😞', label: 'Shame', value: 'shame', description: 'A painful feeling of humiliation or distress caused by the consciousness of wrong or foolish behavior.'},
  {
    icon: '😐',
    label: 'Boredom',
    value: 'boredom',
    description: 'The state of feeling bored.',
  },
  {
    icon: '🤢',
    label: 'Disgust',
    value: 'disgust',
    description:
      'A feeling of revulsion or strong disapproval aroused by something unpleasant or offensive.',
  },
  {
    icon: '💩',
    label: 'Loathing',
    value: 'loathing',
    description: 'A feeling of intense dislike or disgust; hatred.',
  },
  // { icon: '⌚️', label: 'Impatience', value: 'impatience', description: 'The tendency to be impatient; irritability or restlessness.'},
  // { icon: '👿', label: 'Contempt', value: 'contempt', description: 'The feeling that a person or a thing is beneath consideration, worthless, or deserving scorn.'},
  // { icon: '😠', label: 'Hatred', value: 'hatred', description: 'Intense dislike or ill will.'},
  {
    icon: '🙄',
    label: 'Annoyance',
    value: 'annoyance',
    description: 'The feeling or state of being annoyed; irritation.',
  },
  {
    icon: '😠',
    label: 'Anger',
    value: 'anger',
    description: 'A strong feeling of annoyance, displeasure, or hostility.',
  },
  {
    icon: '😡',
    label: 'Rage',
    value: 'rage',
    description: 'Violent, uncontrollable anger.',
  }, // Missing emoji
  // { icon: '👎', label: 'Disfavor', value: 'disfavor', description: 'Disapproval or dislike.'},
  // { icon: '😤', label: 'Aggression', value: 'aggression', description: 'Hostile or violent behavior or attitudes toward another; readiness to attack or confront.'},
  // { icon: '😈', label: 'Domination', value: 'domination', description: 'The exercise of control or influence over someone or something, or the state of being so controlled.'},
  {
    icon: '😉',
    label: 'Interest',
    value: 'interest',
    description:
      'The state of wanting to know or learn about something or someone.',
  }, // Missing emoji
  {
    icon: '🤓',
    label: 'Anticipation',
    value: 'anticipation',
    description:
      'The action of anticipating something; expectation or prediction.',
  },
  {
    icon: '😶',
    label: 'Vigilance',
    value: 'vigilance',
    description:
      'The action or state of keeping careful watch for possible danger or difficulties.',
  }, // Missing emoji
  // { icon: '😏', label: 'Witty', value: 'witty', description: 'Showing or characterized by quick and inventive verbal humor.'},
  // { icon: '😂', label: 'Funny', value: 'funny', description: 'Causing laughter or amusement; humorous.'},
  // { icon: '🤣', label: 'Hilarious', value: 'hilarious', description: 'Extremely amusing or funny.'},
  // { icon: '🤷‍♂️', label: 'Bemusement', value: 'bemusement', description: 'The fact or condition of being bemused; puzzlement.'},
  // { icon: '🤠', label: 'Optimism', value: 'optimism', description: 'Hopefulness and confidence about the future or the successful outcome of something.'},
  // { icon: '😃', label: 'Zeal', value: 'zeal', description: 'Great energy or enthusiasm in pursuit of a cause or an objective.'},
];

const groupLabels = [
  'Optimism',
  'Love',
  'Submission',
  'Awe',
  'Disapproval',
  'Remorse',
  'Contempt',
  'Aggressiveness',
];

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    boxSizing: 'border-box',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
    marginBottom: 64
  },
  emotion: {
    cursor: 'pointer',
    opacity: 0.5,
    width: `calc(100% * (1/3) - ${theme.spacing(4)}px)`,
    padding: theme.spacing(2),
    '&:hover': {
      background: '#F5F5F5',
      opacity: 1,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: '48px',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    '& img': {
      height: '1.3em',
      width: '1.3em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
  label: {
    textAlign: 'center',
  },
  divider: {
    position: 'relative',
    color: '#ccc',
    width: '100%',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    textAlign: 'center',
    borderBottom: 'solid 1px #ccc',
    '& span': {
      position: 'absolute',
      top: -15,
      left: 'calc((100% - 200px) / 2)',
      background: '#fff',
      width: 200,
      height: 30,
      lineHeight: 1.8,
      textAlign: 'center',
    },
  },
  selected: {
    background: '#F5F5F5',
    opacity: 1,
    borderRadius: 3,
    padding: theme.spacing(2) - 1,
    border: 'solid 1px #eee',
    boxShadow: '2px 2px 0px 1px #e0e0e0',
    '& span': {
      textShadow: '0 0 0px #333, 0 0 0px #333',
    },
  },
});

const EmotionTooltip = ({ description, children }) => {
  return (
    <Tooltip title={
      <React.Fragment>
        <Typography style={{ fontSize: 16 }}>{ description }</Typography>
      </React.Fragment>
    } enterDelay={850} arrow>
      { children }
    </Tooltip>
  )
}

const EmotionIcon = ({ description, code, ...rest }) => {
  const emojiText = useMemo(
    () =>
      twemoji.parse(code, {
        base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
        folder: 'svg',
        ext: '.svg',
        className: 'emotion-emoji',
      }),
    [code]
  );

  return (
      <div {...rest} dangerouslySetInnerHTML={{ __html: emojiText }} />
  );
};

class EmotionFragmentDialog extends Component {
  state = {
    selected: {},
    action: 'create',
  };

  componentDidMount() {
    if (this.props.fragment.fragmentData) {
      this.setState({
        ...this.state,
        isNewFragment: 'update',
      });
    }

    if (
      this.props.initialValues.emotions &&
      this.props.initialValues.emotions.length > 0
    ) {
      const selected = this.props.initialValues.emotions.reduce((obj, x) => {
        obj[x] = true;
        return obj;
      }, {});

      this.setState({ selected });
    }
  }

  onSelectEmotion = emotion => () => {
    const emotionNormalized = emotion.toLowerCase();
    const bool = this.state.selected[emotionNormalized]
      ? !this.state.selected[emotionNormalized]
      : true;
    const state = {
      selected: {
        ...this.state.selected,
        [emotionNormalized]: bool,
      },
    };

    this.setState(state);

    let temp = [];
    let value = '';
    const keys = Object.keys(state.selected);

    // Get only values that have been selected (those values that are set to true)
    for (let i = 0; i < keys.length; i++) {
      value = state.selected[keys[i]];
      if (value) {
        temp.push(keys[i]);
      }
    }
    console.log(this.state);
    this.props.updateField('emotions', temp);
  };

  renderEmotionItem = counter => (obj, index) => {
    const { classes } = this.props;

    if (index % 3 === 0) {
      counter++;
    }

    return (
      <React.Fragment key={index}>
        {index % 3 === 0 ? (
          <div className={classes.divider}>
            <span>{groupLabels[counter - 1]}</span>
          </div>
        ) : (
          ''
        )}
         <EmotionTooltip description={obj.description}>
        <div
          key={obj.label}
          className={classNames(classes.emotion, {
            [classes.selected]: this.state.selected[obj.value],
          })}
          onClick={this.onSelectEmotion(obj.value)}
        >
          {/* <div className={classes.icon}>{obj.icon}</div> */}
          <EmotionIcon className={classes.icon} description={obj.description} code={obj.icon} />
          <div className={classes.label}>
            <span>{obj.label}</span>
          </div>
        </div>
        </EmotionTooltip>
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    let counter = 0;
    return (
      <FragmentDialogBase
        FormSlot1={
          <div className={classes.root}>
            {emotions.map(this.renderEmotionItem(counter))}
          </div>
        }
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => {
  const normalizedData =
    !!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? fragmentProperties.normalizeData(
          state.journalCompose.fragments[0].fragmentData
        )
      : [];

  return {
    // data: state.form.reduxForm && state.form.reduxForm.values.emotions || [],
    // data is unnecessary here, component tracts emotions internally, we just need to
    // map initialValues
    initialValues: {
      emotions: normalizedData,
    },
  };
};

EmotionFragmentDialog = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(EmotionFragmentDialog));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmotionFragmentDialog));
