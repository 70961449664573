import React, { useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import {
  Grid,
  Button,
  Icon,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';

const styles = theme => {
  return {
    icons: {
      position: 'absolute',
      top: 15,
      right: 15,
    },
    cardBottom: {
      marginTop: 16,
    },
    icon: {
      marginRight: 8,
    },
    input: {
      marginBottom: 24,
    },
    value: {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 500,
    },
    complication: {
      backgroundColor: '#ED5158',
      borderRadius: 24,
      color: '#fff',
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 12,
      fontSize: 14,
      paddingLeft: 12,
      display: 'inline-block',
      marginRight: 18,
    },
    root: {
      padding: 24,
    },
  };
};

const ViewSurgery = ({ data, classes, onCancel, onDelete, onUpdate }) => {
  const isNull = input =>
    input ? (
      <div className={classes.value}>{input}</div>
    ) : (
      <div className={classes.value}>
        <i>Not Set</i>
      </div>
    );

  const renderComplications = input => {
    return input.length !== 0 ? (
      <Grid container alignItems="center" className={classes.value}>
        {input.map(node => (
          <div key={node.shortName} className={classes.complication}>
            {node.shortName}
          </div>
        ))}
      </Grid>
    ) : (
      <div className={classes.value}>
        <i>None</i>
      </div>
    );
  };

  const renderDate = date =>
    date ? format(parseISO(date), 'MMMM do, yyyy') : null;

  // const isModifying =
  //   data && data.isUpdating ? true : data && data.isDeleting ? true : false;
  return (
    <Grid item className={classes.root}>
      <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.5em' }}>
        {data.surgery}
      </Typography>
      <Grid container spacing={3} className={classes.cardBottom}>
        <Grid item lg={4} xs={4} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>date_range</Icon>
            <div>Surgery Date</div>
          </Grid>
          {isNull(renderDate(data.surgeryEndedOn))}
        </Grid>
        <Grid item lg={4} xs={4} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>business</Icon>
            <div>Hospital / Facility</div>
          </Grid>
          {isNull(data.hospitalFacility)}
        </Grid>
        <Grid item lg={4} xs={4} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>face_range</Icon>
            <div>Surgeon Name</div>
          </Grid>
          {isNull(data.surgeonName)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>error_outline</Icon>
            <div>Complications</div>
          </Grid>
          {renderComplications(data.complications)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>edit</Icon>
            <div>Notes</div>
          </Grid>
          <div className={classes.value}>{data.notes}</div>
        </Grid>
        <Grid item container lg={12} xs={12} alignItems="center">
          <Button onClick={onCancel} style={{ marginRight: 24 }}>
            Cancel
          </Button>
          <Button onClick={onDelete} style={{ marginRight: 24 }}>
            Delete
          </Button>
          <Button onClick={onUpdate} color="primary" variant="contained">
            Edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ViewSurgery);
