import React from 'react';
import { withStyles, Paper, Grid } from '@material-ui/core';
import MemberDetailAvatar from '../MemberDetailAvatar';
import MemberDetailLabel from '../MemberDetailLabel';
import MemberAddressField from '../MemberAddressField';
import { MemberDetailField } from '..';

const styles = {
  root: {
    padding: 15,
    margin: '1.5em 0',
    boxShadow: '0 1px 6px rgba(216,220,222,.67)',
  },
  header: {
    fontSize: 21,
    fontWeight: 700,
    margin: '2px 0 4px',
  },
  date: {
    fontSize: 14,
    fontWeight: 400,
    color: '#AEAEAE',
  },
};

const defaultValue = (value, replacement) => {
  return value === undefined ? replacement : value;
};

const getLabel = value => value.shortName;

const MemberPreviewRow = ({ member, classes }) => {
  const displayName = member.fullName || member.displayName;

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item container xs={5} direction="row" alignItems="center">
          <MemberDetailAvatar member={member} />
          <Grid item xs={9}>
            <h2 className={classes.header}>{displayName}</h2>
            <Grid container justify="flex-start" direction="row">
              {member.labels &&
                member.labels.map(label => (
                  <MemberDetailLabel
                    key={getLabel(label)}
                    label={getLabel(label)}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MemberAddressField
            onSubmit={() => {}}
            icon="place"
            member={member}
            isEditable={false}
          />
        </Grid>
        <Grid item xs={3}>
          <MemberDetailField
            icon="local_phone"
            dataLine={defaultValue(member.phoneNumber, 'Not specified')}
            isEditable={false}
          />
          <MemberDetailField
            icon="email"
            dataLine={defaultValue(member.email, 'Not specified')}
            isEditable={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(MemberPreviewRow);
