import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import Button from '@material-ui/core/Button';

const RemoteSubmitButton = ({ dispatch, ...rest }) => (
  <Button
    type="button"
    color="primary"
    variant="contained"
    size="medium"
    onClick={() => dispatch(submit('documentForm'))}
    disableElevation
  >
    Submit
  </Button>
);

export default connect()(RemoteSubmitButton);
