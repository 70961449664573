import React from 'react';
import { format, parseISO } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FileDownload from '@material-ui/icons/GetApp';
import PhotosIcon from '@material-ui/icons/PhotoLibrary';
import { MIME_TYPE_APPLICATION_PDF } from '../../../../library/helpers/mimeTypes';
import { connect } from 'react-redux';
const MediaObjectDetails = ({
  dialogMode,
  currentMediaFile,
  onClickDownload,
  category,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            color="primary"
            style={{
              fontSize: '18px',
              marginBottom: '8px',
              fontWeight: '500',
              lineHeight: '30px',
            }}
          >
            {currentMediaFile.displayName
              ? currentMediaFile.displayName
              : currentMediaFile.fileName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          {/*currentMediaFile.mimeType === MIME_TYPE_APPLICATION_PDF && (
            <Tooltip
              title='View file'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginRight: '5px',
                }}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    dialogMode(
                      true,
                      'iframe',
                      currentMediaFile.links.self,
                      currentMediaFile
                    )
                  }
                >
                  <PhotosIcon color='primary' />
                </IconButton>
              </div>
            </Tooltip>
                )*/}
          <div
            onClick={onClickDownload}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Tooltip title="Download file">
              <IconButton size="small">
                <FileDownload color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{ fontSize: '16px', color: '#737373', marginBottom: '8px' }}
          >
            {category}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            style={{ fontSize: '16px', color: '#737373' }}
          >
            {format(
              new Date(currentMediaFile.enteredOn),
              'MMMM do yyyy, h:mm a'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '16px', color: '#737373', marginBottom: '8px' }}
          >
            {currentMediaFile.description}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MediaObjectDetails;
