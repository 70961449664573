import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import { Skeleton } from '@material-ui/lab';
import { Fade } from '@material-ui/core';

// Thanks to https://stackoverflow.com/questions/6018611/smallest-data-uri-image-possible-for-a-transparent-image
const BLANK_TRANSPARENT_IMAGE_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const ImageLoader = ({
  style,
  width,
  height,
  placeholder,
  src,
  contain,
  innerRef,
  ...rest
}) => {
  const imageRef = useRef();

  const [imageState, setImageState] = useState({
    isLoaded: false,
    hasError: false,
  });

  const [container, setContainer] = useState({ width, height });

  useEffect(() => {
    setImageState(state => ({ ...state, isLoaded: false }));

    if (imageRef.current) imageRef.current.src = BLANK_TRANSPARENT_IMAGE_PIXEL;
  }, [placeholder, src]);

  useEffect(() => {
    if (!imageState.isLoaded && src) {
      imageRef.current = new Image();
      imageRef.current.src = src;

      imageRef.current.onload = function() {
        setImageState({
          isLoaded: true,
        });
      };

      imageRef.current.onerror = function() {
        setImageState({ isLoaded: false, hasError: true });
      };
    }
  }, [src]);

  useEffect(() => {
    let element = innerRef && innerRef.current;
    let wNext = element ? parseInt(element.style.width, 10) : width;
    let hNext = element ? parseInt(element.style.height, 10) : height;

    setContainer({ width: wNext, height: hNext });
  }, [innerRef, width, height]);

  const placeholderStyle = {
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center',
    backgroundOrigin: 'border-box',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: container.width || '100%',
    height: container.height || 'auto',
    transition: 'opacity 1s ease-in',
    borderRadius:
      innerRef && innerRef.current && innerRef.current.style.borderRadius,
    ...style,
  };

  const transition = {
    opacity: imageState.isLoaded ? 1 : 0,
    transition: 'opacity .3s ease-in',
  };

  const imageStyle = {
    display: 'block',
    width: container.width || '100%',
    height: container.height || 'auto',
    objectFit: contain ? 'contain' : 'cover',
    objectPosition: 'center',
    opacity: imageState.isLoaded ? 1 : 0,
    borderRadius: 'inherit',
    transition: 'opacity .3s ease-in',
  };

  const errorStyle = {
    width: container.width || '100%',
    height: container.height || 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ddd',
    color: '#888',
    ...transition,
  };

  const imageUrl =
    src && imageState.isLoaded ? src : BLANK_TRANSPARENT_IMAGE_PIXEL;

  return imageState.hasError ? (
    <div style={errorStyle}>
      <p
        style={{
          textAlign: 'center',
          width: Math.floor(container.width / 1.5),
          fontSize: `${Math.floor(0.014 * container.width) / 2}em`,
        }}
      >
        Error loading image
      </p>
    </div>
  ) : imageState.isLoaded ? (
    <Fade in={true} timeout={150}>
      <div style={placeholderStyle}>
        <img {...rest} style={imageStyle} alt="test" src={imageUrl} />
      </div>
    </Fade>
  ) : (
    <Skeleton variant="rect" animation="wave" height="100%" />
  );
};

const ImageProtected = props => {
  const { src, placeholder, classes, personId, ...rest } = props;
  const [image, setImage] = useState(null);
  const controllerRef = useRef();

  useEffect(() => {
    controllerRef.current = new AbortController();
    // return () => controllerRef.current.abort();
  }, []);

  useEffect(() => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();

    if (personId && src) {
      setImage(null);

      apiFetchAuthorized(
        src,
        {
          signal: controllerRef.current.signal,
          headers: {
            Pid: personId,
          },
        },
        false
      )
        .then(res => res.blob())
        .then(blob => {
          if (!controllerRef.current.signal.aborted) {
            setImage(URL.createObjectURL(blob));
          }
          //console.log('Image loaded:', URL.createObjectURL(blob));
        });

      return () => controllerRef.current.abort();
    }
  }, [personId, src]);

  return (
    <ImageLoader
      src={image}
      placeholder={placeholder}
      classes={classes}
      {...rest}
    />
  );
};

ImageLoader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  contain: PropTypes.bool,
};

ImageLoader.defaultProps = {
  width: null,
  height: null,
  placeholder: null,
  contain: false,
  placeholder: BLANK_TRANSPARENT_IMAGE_PIXEL,
};

ImageProtected.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  contain: PropTypes.bool,
};

ImageProtected.defaultProps = {
  width: null,
  height: null,
  placeholder: null,
  contain: false,
  placeholder: BLANK_TRANSPARENT_IMAGE_PIXEL,
};

export default ImageProtected;
