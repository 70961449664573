import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  withStyles,
  Icon,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { format, isPast } from 'date-fns';

import InputField from '../../../library/forms/InputField';
import SelectField from '../../../library/forms/SelectField';
import SearchSelectField from '../../components/SearchSelect';
import FormPanelButton from '../../components/Form/FormButtonPanel';
import { cancelForm } from '../../../store/reducers/ui/actions';
import validate from './ValidateForm';
import { csvToCodeObjectList } from '../../../library/helpers/utils';
import HoverTooltip from '../../../library/forms/HoverTooltip';
import AttachmentPanel from './AttachmentPanel';
import { parseDate } from '../../../library/utils/date';
import DrawerAccordionStandardItems from '../../components/DrawerAccordionStandardItems';

const styles = {
  primaryInput: {
    borderRadius: 6,
    backgroundColor: '#EFF1F3',
    position: 'relative',
    padding: 32,
    paddingBottom: 16,
  },
  surgeryBgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    top: 0,
    right: 0,
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  surgeryBg: {
    height: '100%',
    objectFit: 'cover',
  },
  label: {
    letterSpacing: 'normal',
  },

  root: {
    width: '100%',
  },
  heading: {
    padding: '0px 8px',
    lineHeight: '25px',
    fontWeight: '500',
    color: '#000000',
  },
  accordion: {
    boxShadow: 'none',
  },
};

const ConditionsForm = props => {
  const {
    handleSubmit,
    action,
    surgeryTypeAhead,
    onCancel,
    classes,
    isFetchingSurgicalHistoryType,
    surgicalHistoryTypes,
    isSubmitting,
    pristine,
    hasMediaObjectUpdates,
  } = props;

  const onCancelForm = () => {
    // cancelForm();
    onCancel(props);
  };

  const [approxDate, setApproxDate] = React.useState(false);

  const entityAction = action === 'CREATE' ? 'Create' : 'Update';
  const disableSubmit = (isSubmitting || pristine) && !hasMediaObjectUpdates;

  return (
    <div style={{ width: '550px', overflowX: 'hidden' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.primaryInput}>
              <div className={classes.surgeryBgContainer}>
                <img
                  src={require('../../../client/svgs/surgical-history-add.svg')}
                  alt=""
                  className={classes.surgeryBg}
                />
              </div>
              <Typography variant="h6">Enter your condition</Typography>
              <Field
                name="diagnosisDesc"
                label="Name"
                hint="Required"
                labelKey="label"
                valueKey="value"
                options={surgicalHistoryTypes}
                onTypeAhead={surgeryTypeAhead}
                component={SearchSelectField}
                props={{
                  InputLabelProps: {
                    style: {
                      letterSpacing: 'normal',
                    },
                  },
                  containerStyle: { backgroundColor: '#fff' },
                  variant: 'filled',
                  InputProps: {
                    style: {
                      background: '#ffff'
                    }
                  }
                }}
                isLoading={isFetchingSurgicalHistoryType}
                isSearchable
                isCreatable
                isClearable
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{ padding: 24, margin: 0, paddingBottom: 0, width: '100%' }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              type="date"
              shrink={true}
              name="diagnosedOn"
              label="Date you were diagnosed"
              default={null}
              component={InputField}
              props={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HoverTooltip
                      placement="bottom-start"
                      text="If you don't know the exact date, you can enter your best guess in the Notes. Even just the year may be helpful to your Care Team."
                    />
                  </InputAdornment>
                ),
                inputProps: {
                  max: format(new Date(), 'yyyy-MM-dd'),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              name="providerName"
              label="Physician Name"
              component={InputField}
              // props={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <Icon>account_box</Icon>
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="relatedMvs"
              label="Associated Medications"
              labelKey="shortName"
              valueKey="longName"
              options={[]}
              component={SelectField}
              fieldType="tags"
              isSearchable
              isMulti
              isCreatable
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="relatedTreatments"
              label="Related Treatments"
              labelKey="shortName"
              valueKey="longName"
              options={[]}
              component={SelectField}
              fieldType="tags"
              isSearchable
              isMulti
              isCreatable
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="relatedSymptoms"
              label="Related Symptoms"
              labelKey="shortName"
              valueKey="longName"
              options={[]}
              component={SelectField}
              fieldType="tags"
              isSearchable
              isMulti
              isCreatable
            />
          </Grid>
        </Grid>
        <DrawerAccordionStandardItems 
          additionalInformation={
            <Field
              name="notes"
              label="Notes"
              multiline
              rows={4}
              component={InputField}
            />} 
          attachments={<AttachmentPanel />} 
        />
        <Grid container item xs={12}>
          <FormPanelButton
            actionText={`${entityAction} Record`}
            cancelText="Cancel"
            onCancel={onCancelForm}
            isDisabled={Boolean(disableSubmit || isSubmitting)}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </form>
    </div>
  );
};

const ConditionsRedux = reduxForm({
  form: 'conditions-form',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(ConditionsForm));

const mapStateToProps = state => {
  if (!state.conditions.selectedId)
    return {
      initialValues: {},
    };

  const {
    personDiagnosisId,
    diagnosisDesc,
    providerId,
    providerName,
    relatedTreatments,
    relatedSymptoms,
    notes,
    diagnosedOn,
    mvsIds,
    relatedMvs,
  } = state.conditions.list[state.conditions.selectedId];

  return {
    initialValues: {
      personDiagnosisId,
      diagnosisDesc,
      providerId,
      providerName,
      relatedTreatments: relatedTreatments
        ? csvToCodeObjectList(relatedTreatments)
        : null,
      relatedSymptoms: relatedSymptoms
        ? csvToCodeObjectList(relatedSymptoms)
        : null,
      relatedMvs: relatedMvs ? csvToCodeObjectList(relatedMvs) : null,
      notes,
      diagnosedOn: diagnosedOn
        ? format(parseDate(diagnosedOn), 'yyyy-MM-dd')
        : undefined,
      mvsIds,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  cancelForm: () => dispatch(cancelForm()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ConditionsRedux)
);
