import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons/';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderLeftStyle: 'solid',
    borderLeftWidth: 5,
  },
  info: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
  success: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.main,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.main,
    '& a': {
      color: theme.palette.warning.dark,
    },
  },
  danger: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.main,
    '& a': {
      color: theme.palette.error.dark,
    },
  },
  button: {
    position: 'absolute',
    background: 'transparent',
    color: 'inherit',
    border: 'transparent',
    top: 15,
    right: 5,
    cursor: 'pointer',
  },
});

const noop = () => {};

const GlobalNotification = ({
  children,
  classes,
  type,
  canClose,
  isDisplayed,
  onClose,
}) => {
  return isDisplayed ? (
    <div className={classNames(classes.root, { [classes[type]]: true })}>
      <Grid container>
        <Grid item xs={10}>
          {children}
        </Grid>
        <Grid item container xs={2} justify="flex-end" alignItems="flex-start">
          {canClose && (
            <IconButton
              edge={false}
              size="small"
              onClick={onClose ? onClose : noop}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  ) : null;
};

GlobalNotification.propTypes = {
  onClose: PropTypes.func,
  canClose: PropTypes.bool.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
};

GlobalNotification.default = {
  canClose: false,
  isDisplayed: true,
  type: 'info',
};

export default withStyles(styles)(GlobalNotification);
