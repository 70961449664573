import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DetectInactivity.css';

class DetectInactivity extends Component {

  constructor(props) {
    super(props);

    this.warnTimeoutId = null;
    this.idleTimeoutId = null;
  }

  /**
   * Once this component mounts anytime an event load, mousemove,
   * click, keypress, and scroll have been detected, 
   * reset inactivity timeout
   */
  componentDidMount() {
    window.addEventListener('load', this.resetTimeout);
    window.addEventListener('mousemove', this.resetTimeout);
    window.addEventListener('click', this.resetTimeout);
    window.addEventListener('keypress', this.resetTimeout);
    window.addEventListener('scroll', this.resetTimeout);
  }

  /**
   * When component is removed (unmounted) Clear inactivity timeout and remove 
   * all events load, mousemove, click, keypress, and scroll.
   */
  componentWillUnmount() {
    window.removeEventListener('load', this.resetTimeout);
    window.removeEventListener('mousemove', this.resetTimeout);
    window.removeEventListener('click', this.resetTimeout);
    window.removeEventListener('keypress', this.resetTimeout);
    window.removeEventListener('scroll', this.resetTimeout);
    window.clearTimeout(this.warnTimeoutId);
    window.clearTimeout(this.idleTimeoutId);
  }

  /**
   * Once triggered, reset timeout for idle and warn if onWarn has been defined.
   * Immediately after set the timeout for idle and if on Warn has been 
   * defined with user defined function(s) passed in by props 
   */
  resetTimeout = () => {

    const { onWarn, onIdle, warnDuration, idleDuration } = this.props;
    
    if (onWarn) {
      window.clearTimeout(this.warnTimeoutId);
      this.warnTimeoutId = window.setTimeout(onWarn, warnDuration)
    }

    window.clearTimeout(this.idleTimeoutId);
    this.idleTimeoutId = window.setTimeout(onIdle, idleDuration);
  }

  render() {

    return (
      <div>{ this.props.children }</div>
    );

  }
}

DetectInactivity.propTypes = {
  onIdle: PropTypes.func.isRequired,
  onWarn: PropTypes.func,
  warnDuration: PropTypes.number,
  idleDuration: PropTypes.number,
}

export default DetectInactivity;