import React from 'react';
import {
  Modal,
  Grid,
  withStyles,
  IconButton,
  Icon,
  Typography,
} from '@material-ui/core';

const meta = {
  color: '#2AA5A2',
  icon: 'accessibility',
};

const BloodPressureViewer = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Typography variant="h6">Systolic</Typography>
        <Typography variant="body1">{data.systolic}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6">Diastolic</Typography>
        <Typography variant="body1">{data.diastolic}</Typography>
      </Grid>
    </Grid>
  );
};

export default BloodPressureViewer;
