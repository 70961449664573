import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import fieldStyles from './Field.css';
import HoverTooltip from './HoverTooltip';

const styles = theme => ({
  formControl: {
    // minHeight: theme.spacing(10),
  },
  label: {
    letterSpacing: 'normal',
    marginTop: '5px',
    '&$focusedLabel': {
      marginTop: '0px',
    },
    '&$erroredLabel': {
      marginTop: '5px',
      '&$focusedLabel': {
        marginTop: '0px',
      },
    },
  },
  focusedLabel: { letterSpacing: 'normal' },
  erroredLabel: { letterSpacing: 'normal' },
});

/**
 * A wrapper for standard HTML input
 * intended to be used with redux-form Field component.
 *
 * @param props
 * @return {Component}
 */

class InputField extends Component {
  render() {
    const {
      input,
      label,
      hint,
      meta: { validating, asyncValidating, touched, error },
      placeholder,
      classes,
      multiline,
      shrink,
      endAdornment,
      enableMinHeight,
      whiteBG,
      HoverTooltipMsg,
      hasErrorTooltip,
      ...attributes
    } = this.props;

    const hasError = !!(touched && error);

    return (
      <div
        className={classNames({
          [classes.formControl]: enableMinHeight,
          [fieldStyles.inputAsync]: asyncValidating || validating,
        })}
      >
        <TextField
          fullWidth
          multiline={multiline ? true : false}
          error={hasError}
          label={label}
          margin="dense"
          placeholder={placeholder}
          helperText={
            hasErrorTooltip ? '' : hasError ? error : hint ? hint : ' '
          }
          {...input}
          {...attributes}
          InputLabelProps={{
            shrink,
            classes: {
              root:
                !!input.value && input.value.length !== 0
                  ? classes.focusedLabel
                  : shrink
                  ? classes.focusedLabel
                  : this.props.type === 'date'
                  ? classes.focusedLabel
                  : this.props.bodyMetricForm
                  ? classes.focusedLabel
                  : classes.label,
              focused: classes.focusedLabel,
              error:
                !!input && input.value
                  ? classes.focusedLabel
                  : shrink
                  ? classes.focusedLabel
                  : classes.erroredLabel,
            },
          }}
          FormHelperTextProps={{
            error: hasError || this.props.error,
            style: {
              fontSize: '0.75rem',
              lineHeight: '1em',
              letterSpacing: 'normal',
            },
          }}
          inputProps={{
            ...attributes.inputProps,
            style: !!multiline
              ? {
                  background: '#fff',
                  fontSize: '1rem',
                  padding: '4.5px 0px',
                }
              : {
                  background: '#fff',
                  fontSize: '1rem',
                  lineHeight: '1.1875em',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                },
          }}
          InputProps={{
            ...this.props.InputProps,
            style: {
              ...(whiteBG ? { background: '#FFF', borderRadius: '5px' } : {}),
            },
            endAdornment:
              this.props.InputProps && this.props.InputProps.endAdornment ? (
                this.props.InputProps.endAdornment
              ) : endAdornment ? (
                endAdornment
              ) : HoverTooltipMsg ? (
                <HoverTooltip text={HoverTooltipMsg} />
              ) : null,
          }}
          // onKeyPress={e => {
          //   if (e.key === 'Enter') e.preventDefault();
          // }}
          variant="outlined"
        />
      </div>
    );
  }
}

InputField.defaultProps = {
  enableMinHeight: true,
  whiteBG: false,
  meta: {
    validating: false,
    asyncValidating: false,
    touched: false,
    error: false,
  },
  input: {},
};

export default withStyles(styles)(InputField);
