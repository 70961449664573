import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const CORE_IMMUNIZATION_REQUEST = 'core/immunization_request';
export const CORE_IMMUNIZATION_SUCCESS = 'core/immunization_success';
export const CORE_IMMUNIZATION_FAILURE = 'core/immunization_failure';

export const fetchCoreImmunizations = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      CORE_IMMUNIZATION_REQUEST,
      CORE_IMMUNIZATION_SUCCESS,
      CORE_IMMUNIZATION_FAILURE,
    ],
    endpoint: `/api/core/immunization`,
  },
});
