import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import QRSvg from './svg/QRSvg';
import HttpSvg from './svg/HttpSvg';
import GetAppIcon from '@material-ui/icons/GetApp';
import { QR, DOWNLOAD, LINK } from './WaysToShareInfo';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    background: '#0A6DAB',
    // boxShadow: "none",
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  media: {
    height: 140,
  },
  httpIcon: {
    width: '90px',
    height: 'auto',
    padding: '8px',
  },
  iconContainer: {
    height: 53,
    width: 53,
    marginBottom: '8px',
  },
  getAppIcon: {
    width: 75,
    marginBottom: '8px',
  },
  icon: {
    height: 'auto',
    width: '100%',
    color: '#A3A3A3',
    marginTop: '-7px',
  },
  mediaContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: '145px',
    width: '145px',
    backgroundColor: '#EFF1F3',
    padding: '16px',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    // padding: '8px',
    color: '#006AB0',
    fontSize: '14px',
  },
  text: {
    width: '100%',
    textAlign: 'center',
    color: '#fff',
  },
  button: {
    color: '#fff',
  },
  cardActionArea: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100% - 46px)',
    '-webkit-filter': `brightness(100%)`,
    '&:hover': {
      '-webkit-filter': ' brightness(80%)',
      '-webkit-transition': 'all .5s ease',
      '-moz-transition': 'all .5s ease',
      '-o-transition': 'all .5s ease',
      '-ms-transition': 'all .5s ease',
      transition: 'all .5s ease',
    },
  },
});

export default function InfoCard({
  id,
  title,
  content,
  onClick,
  onSelectLearnMore,
}) {
  const classes = useStyles();
  const renderIconComponent = () => {
    switch (id) {
      case QR:
        return (
          <div className={classes.iconContainer}>
            <QRSvg />
          </div>
        );
      case DOWNLOAD:
        return (
          <div className={classes.getAppIcon}>
            <GetAppIcon className={classes.icon} />
          </div>
        );
      case LINK:
        return (
          <div className={classes.httpIcon}>
            <HttpSvg />
          </div>
        );
    }
  };

  const handleClick = e => {
    e.preventDefault();
    onClick(id);
  };
  return (
    <Grid item xs={4}>
      <Card className={classes.root} raised={false} square={true} elevation={6}>
        <CardActionArea
          onClick={handleClick}
          className={classes.cardActionArea}
        >
          <div className={classes.mediaContainer}>
            <Paper className={classes.paper} elevation={0}>
              <div style={{ height: 60, display: 'flex', marginBottom: '8px' }}>
                {renderIconComponent()}
              </div>
              <Typography className={classes.title}>{title}</Typography>
            </Paper>
          </div>
          <div>
            <Typography variant="body2" component="p" className={classes.text}>
              Use this {content}
            </Typography>
          </div>
        </CardActionArea>
        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="small"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={() => onSelectLearnMore(id)}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
