import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import PreviewIcon from './PreviewIcon';
import PreviewDetails from './PreviewDetails';
import { shorten } from '../../../../library/helpers/format';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DashboardMessage from '../../DashboardContent/DashboardMessage';
import styles from '../../../containers/MediaObject/File.css';

const PreviewMedia = props => {
  const {
    bundle,
    index,
    isSubmitting,
    removeObject,
    isDeleting, // toggles wether media is set for deletion on update
    cancelDelete,
    setCurrentIndex,
    currentIndex,
    id,
    disableDropDown = false,
  } = props;

  const [toggleDelete, setToggleDelete] = React.useState(false); // toggles delete option message
  const key = `previewMedia${index}`;

  React.useEffect(() => {
    if (toggleDelete && currentIndex !== key) setToggleDelete(false);
  }, [currentIndex]);

  const handleDelete = () => {
    if (disableDropDown) {
      if (isDeleting) {
        cancelDelete(bundle.mediaObjectId);
      } else handleDeleteAllRecords(bundle.mediaObjectId);
      return;
    }
    if (isDeleting) {
      cancelDelete(bundle.mediaObjectId);
    } else {
      if (!toggleDelete) {
        setToggleDelete(true);
        setCurrentIndex(key);
      }
    }
    return;
  };

  const handleClose = () => {
    setCurrentIndex('');
    setToggleDelete(false);
  };

  const handleDeleteAttachment = () => {
    removeObject(bundle.mediaObjectId, id);
    handleClose();
  };
  const handleDeleteAllRecords = () => {
    removeObject(bundle.mediaObjectId, null);
    handleClose();
  };

  return (
    <div>
      <div
        className={styles.block}
        onClick={event => {
          event.stopPropagation();
        }}
        style={{
          opacity: isDeleting ? 0.7 : 1,
        }}
      >
        <div>
          <div className={styles.preview}>
            <PreviewIcon bundle={bundle} />
          </div>
          <div className={styles.description}>
            <div className={styles.name}>
              {shorten(bundle.fileName, 20)}{' '}
              {!isSubmitting && (
                <div
                  className={`${styles.incBtn} ${styles.incBtnClose}`}
                  onClick={handleDelete}
                >
                  {isDeleting ? (
                    <Tooltip title="Cancel">
                      <CancelIcon className={styles.deleteIcon} />
                    </Tooltip>
                  ) : (
                    !toggleDelete && (
                      <Tooltip title="Delete attachment">
                        <DeleteIcon className={styles.deleteIcon} />
                      </Tooltip>
                    )
                  )}
                </div>
              )}
            </div>
            <Fade in={!isSubmitting}>
              <PreviewDetails
                toggleDelete={toggleDelete}
                key={key}
                {...props}
              />
            </Fade>
          </div>
          <div className="clear"></div>
        </div>
      </div>
      <Collapse
        in={toggleDelete && currentIndex === key && !disableDropDown}
        timeout={150}
      >
        <DashboardMessage
          type="warning"
          canClose={false}
          style={{
            borderLeft: 'none',
            marginTop: '8px',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">
                This file is also available in My Documents. Do you want to
                delete all copies?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button size="small" onClick={handleDeleteAttachment}>
                Delete only this attachment
              </Button>
              <Button size="small" onClick={handleDeleteAllRecords}>
                Delete ALL copies
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button size="small" onClick={handleClose}>
                cancel
              </Button>
            </Grid>
          </Grid>
        </DashboardMessage>
      </Collapse>
    </div>
  );
};

export default PreviewMedia;
