import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    background: '#ffffff',
    //minHeight: '220px',
  },
  accent: {
    height: '6px',
    display: 'block',
    overflow: 'hidden',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
});

function AccentedPaper({
  height,
  width,
  accentColor,
  backgroundColor,
  children,
  style,
}) {
  const classes = useStyles();
  return (
    <Paper
      elevation={2}
      className={classes.root}
      style={{
        height: height,
        width: width,
        background: backgroundColor,
        ...style,
      }}
    >
      <span
        className={classes.accent}
        style={{
          background: accentColor,
        }}
      />
      {children}
    </Paper>
  );
}

AccentedPaper.propTypes = {
  accentColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  spacing: PropTypes.number,
};

AccentedPaper.defaultProps = {
  accentColor: '#0068A8',
  height: 'fit-content',
  width: '370px',
  backgroundColor: '#FFFFFF',
  spacing: 1,
  style: {},
};

export default AccentedPaper;
