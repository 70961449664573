import React from 'react';
import { Modal } from '@material-ui/core';
import AddSyncPermissions from './AddSyncPermissions';
import RemoveSyncPermissions from './RemoveSyncPermissions';

const ConfirmationModal = ({
  open,
  handleClose,
  isBrowsing,
  bundle,
  onClick,
}) => {
  const renderBody = () => {
    if (isBrowsing) {
      return (
        <AddSyncPermissions
          bundle={bundle}
          handleClose={handleClose}
          onClick={onClick}
        />
      );
    } else {
      return (
        <RemoveSyncPermissions
          bundle={bundle}
          handleClose={handleClose}
          onClick={onClick}
        />
      );
    }
  };
  return (
    <Modal open={Boolean(open)} onClose={handleClose}>
      {open && renderBody()}
    </Modal>
  );
};

export default ConfirmationModal;
