import React, { useMemo } from 'react';
import { Grid, withStyles, Tooltip, Typography } from '@material-ui/core';
import twemoji from 'twemoji';

import { emotions } from '../../../components/JournalCompose/fragments/EmotionFragmentDialog';

const styles = () => ({
  emotion: {
    opacity: 1,
  },
  icon: {
    cursor: 'pointer',
    fontSize: '24px',
    textAlign: 'center',
    '& img': {
      height: '1em',
      width: '1em',
      margin: '0 .05em 0 .1em',
      verticalAlign: '-0.1em',
    },
  },
  label: {
    textAlign: 'center',
  },
});

const EmotionIcon = ({ label, description, code, ...rest }) => {
  const emojiText = useMemo(
    () =>
      twemoji.parse(code, {
        base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
        folder: 'svg',
        ext: '.svg',
        className: 'emotion-emoji',
      }),
    [code]
  );

  return <EmotionsViewerTooltip label={label} description={description}><div {...rest} dangerouslySetInnerHTML={{ __html: emojiText }} /></EmotionsViewerTooltip>;
};

const EmotionsViewerTooltip = ({ label, description, children }) => {
  return (
    <Tooltip title={
      <React.Fragment>
        <Typography style={{ fontWeight: 500, fontSize: 16 }}>{ label }</Typography>
        <Typography style={{ fontSize: 16 }}>{ description }</Typography>
      </React.Fragment>
    } enterDelay={500} arrow>
      { children }
    </Tooltip>
  )
}

const EmotionsViewer = ({ data, classes }) => {
  const renderEmotionItem = obj => {
    return (
      <div key={obj.label} className={classes.emotion}>
          <EmotionIcon className={classes.icon} label={obj.label} description={obj.description} code={obj.icon} />
      </div>
    );
  };

  return (
    <Grid container>
      {emotions
        .filter(emotion => {
          const idx = Array.isArray(data) ? data.indexOf(emotion.value) : -1;
          return idx !== -1;
        })
        .map(renderEmotionItem)}
    </Grid>
  );
};

export default withStyles(styles)(EmotionsViewer);
