import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  getDays,
  getWeekDays,
  getMonths,
  padWeekDays,
  getTotalDaysInMonth,
  getStartEndDate,
  chunkWeeks,
} from './utils';
import { isBefore, isAfter } from 'date-fns';
import MonthPanel from './MonthPanel.jsx';
import YearPanel from './YearPanel.jsx';
import styles from './Calendar.css';

class Calendar extends React.Component {
  state = {
    today: new Date(),
    current: new Date(),
    selected: undefined,
    isMonthPanelOpened: false,
    isYearPanelOpened: false,
  };

  isCurrentMonth(day) {
    const { current } = this.state;

    return (
      current.getFullYear() === day.getFullYear() &&
      current.getMonth() === day.getMonth()
    );
  }

  isSelected = day => {
    const { today } = this.state;
    const selected = this.state.selected
      ? this.state.selected
      : this.props.date;
    return (
      selected !== undefined &&
      day.getFullYear() === selected.getFullYear() &&
      day.getMonth() === selected.getMonth() &&
      day.getDate() === selected.getDate()
    );
  };

  onDayClick = (day, today, disabled) => event => {
    if (disabled) {
      return;
    }

    let current = this.state.current;
    const isCurrentMonth = this.isCurrentMonth(day);

    this.props.onDayClick(day, today);

    this.setState({
      current: isCurrentMonth
        ? current
        : new Date(day.getFullYear(), day.getMonth()),
      selected: day,
    });
  };

  incrementMonth = event => {
    const current = new Date(
      this.state.current.getFullYear(),
      this.state.current.getMonth() + 1
    );
    this.setState({ current });
  };

  decrementMonth = event => {
    const current = new Date(
      this.state.current.getFullYear(),
      this.state.current.getMonth() - 1
    );
    this.setState({ current });
  };

  openMonthPanel = () => {
    this.setState({ isMonthPanelOpened: true });
  };

  openYearPanel = () => {
    this.setState({ isYearPanelOpened: true });
  };

  monthChanged = (label, index) => {
    const { current } = this.state;
    this.setState({
      current: new Date(current.getFullYear(), index),
      isMonthPanelOpened: false,
    });
  };

  yearChanged = year => {
    const { current } = this.state;
    this.setState({
      current: new Date(year, current.getMonth()),
      isYearPanelOpened: false,
    });
  };

  setToToday = () => {
    this.setState({
      current: new Date(),
    });
  };

  renderWeekDayLabel(label, index) {
    return (
      <span key={index} className={classNames(styles.day, styles.label)}>
        {label}
      </span>
    );
  }

  renderDay(key, date, day) {
    const {
      showCurrentDay,
      showSelectedDay,
      disabledDays,
      isWeekendsDisabled,
    } = this.props;

    const { today, selected } = this.state;
    let isToday = false,
      isDisabled = false;

    const isMonth = this.isCurrentMonth(day);
    const isSelected = this.isSelected(day);

    if (
      today.getFullYear() === day.getFullYear() &&
      today.getMonth() === day.getMonth() &&
      today.getDate() === day.getDate()
    ) {
      isToday = true;
    }

    isDisabled =
      (disabledDays &&
        disabledDays.find(d => {
          return (
            d.getFullYear() === day.getFullYear() &&
            d.getMonth() === day.getMonth() &&
            d.getDate() === day.getDate()
          );
        }) !== undefined) ||
      (isWeekendsDisabled && !(day.getDay() > 0 && day.getDay() < 6)) ||
      (Boolean(this.props.min) && isBefore(day, this.props.min)) ||
      (Boolean(this.props.max) && isAfter(day, this.props.max));

    return (
      <div
        key={key}
        className={classNames(styles.day, {
          [styles.blank]: !isMonth,
          [styles.current]: showCurrentDay && isToday,
          [styles.selected]: showSelectedDay && isSelected,
          [styles.disabled]: isDisabled,
        })}
        onClick={this.onDayClick(day, today, isDisabled)}
      >
        {day.getDate()}
      </div>
    );
  }

  render() {
    const {
      onChange,
      onDayClick,
      showCurrentDay,
      showSelectedDay,
      minYear,
      maxYear,
      maxColumnsYear,
      addColumnsYear,
      isAccurateYear,
    } = this.props;

    const { selected } = this.state;

    const date = this.state.current;
    const [sMonthDay, eMonthDay] = getStartEndDate(date);
    const sDaysPad = padWeekDays(sMonthDay, date);
    const eDaysPad = padWeekDays(eMonthDay, date, true);
    const mergedDays = [].concat(sDaysPad, getDays(date), eDaysPad);
    const dayGroups = chunkWeeks(mergedDays);

    const monthLabels = getMonths('long');
    const weekDayLabels = getWeekDays('narrow');

    const month = monthLabels[date.getMonth()];
    const year = date.getFullYear();

    const isAccurateRange = this.props.maxYear ? true : false;

    return this.state.isMonthPanelOpened ? (
      <div className={styles.root}>
        <MonthPanel
          currentMonth={date.getMonth()}
          onClick={this.monthChanged}
        />
      </div>
    ) : this.state.isYearPanelOpened ? (
      <div className={styles.root}>
        <YearPanel
          minYear={minYear}
          maxYear={maxYear ? maxYear : this.state.today.getFullYear()}
          maxColumnsYear={maxColumnsYear}
          addColumnsYear={addColumnsYear}
          isAccurateYear={isAccurateYear}
          isAccurateRange={isAccurateRange}
          currentYear={date.getFullYear()}
          onClick={this.yearChanged}
        />
      </div>
    ) : (
      <div className={styles.root}>
        <div className={styles.header}>
          <div onClick={this.openMonthPanel} id="month">
            {month}
          </div>
          <div onClick={this.openYearPanel} id="year">
            {year}
          </div>
          <div className={styles.buttons}>
            <button type="button" className="btn" onClick={this.decrementMonth}>
              &lt;
            </button>
            <button type="button" className="btn" onClick={this.setToToday}>
              =
            </button>
            <button type="button" className="btn" onClick={this.incrementMonth}>
              &gt;
            </button>
          </div>
        </div>
        <div className={styles.labels}>
          {weekDayLabels.map(this.renderWeekDayLabel)}
        </div>
        <div className={styles.body}>
          {dayGroups.map((group, y) => (
            <div key={y} className={styles.row}>
              {group.map((day, x) => this.renderDay(y + x, date, day))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  onChange: PropTypes.func,
  onDayClick: PropTypes.func.isRequired,
  showCurrentDay: PropTypes.bool.isRequired,
  showSelectedDay: PropTypes.bool.isRequired,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number,
  maxColumnsYear: PropTypes.number.isRequired,
  addColumnsYear: PropTypes.number.isRequired,
  isAccurateYear: PropTypes.bool.isRequired,
  isWeekendsDisabled: PropTypes.bool.isRequired,
  disabledDays: PropTypes.array,
  min: PropTypes.object,
  max: PropTypes.object,
};

Calendar.defaultProps = {
  showCurrentDay: true,
  showSelectedDay: true,
  minYear: 1900,
  maxYear: undefined,
  maxColumnsYear: 0,
  addColumnsYear: 0,
  isAccurateYear: false,
  isWeekendsDisabled: true,
  min: null,
  max: null,
};

export default Calendar;
