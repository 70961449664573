import React, { useState } from 'react';
import AccentedPaper from '../../../../library/components/AccentedPaper';
import { apiFetchAuthorized } from '../../../../library/helpers/fetch';
import {
  IconButton,
  Grid,
  Card,
  CardMedia,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  ClickAwayListener,
  Tooltip,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  resourceLibraryFavorite,
  selectResource,
} from '../../../../store/reducers/resourceLibrary/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import cx from 'classnames';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { PdfIcon } from '../../../components/DocumentsDrawer/EditMode/MediaObjectSvgIcons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '320px',
    transition: 'transform 200ms ease 200ms',
  },
  open: {
    // '&:hover': {
    //   // transform: 'scale(1.1)',
    //   zIndex: '9',
    // },
  },
  media: {
    height: '100px',
    width: '190px',
  },
  title: {
    background: 'red',
    width: '100%',
  },
  topAccent: {
    background: 'blue',
    height: '10px',
  },
  iconContainer: {
    border: '1px solid red',
    background: 'black',
  },
  cardControl: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  startIcon: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 5px',
  },
  showDetails: {
    height: '200px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mediaRow: {
    height: '105',
    width: '190',
  },
  cardContent: {
    padding: '0px',
  },
  location: {
    fontSize: '14px',
  },
  description: {
    fontSize: '14px',
    color: '#0068A8',
  },
  notes: {
    fontSize: '14px',
    paddingTop: '5px',
  },
  expansionDetails: {
    padding: '4px 12px 12px',
  },
  isFavorite: {
    background: '#90B850',
    border: '1px solid #0068A8',
  },
  isDeleting: {
    opacity: '.6',
  },
  addFilter: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
});

export const renderImage = (image, synapseType) => {
  const classes = useStyles();
  if (Boolean(image)) {
    return <CardMedia className={classes.media} image={image} />;
  } else {
    return (
      <div
        className={classes.media}
        style={{
          backgroundColor: '#bdbdbd',
          display: 'Flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
        }}
      >
        {synapseType === 'Document' ? (
          <PdfIcon width="65px" height="65px" fill="#ffffff" />
        ) : (
          <CropOriginalIcon
            style={{ color: '#ffffff', height: '65px', width: '65px' }}
          />
        )}
      </div>
    );
  }
};

const CustomCard = ({
  item,
  resourceLibraryFavorite,
  isFavorite = false,
  favorites,
  submittingResource,
  deletingResource,
  fromFavoritesSlider = false,
  selectResource,
  selectedId,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const onClickResource = () =>
    Boolean(selectedId) ? {} : selectResource(item.id);

  const handleFavoriteClick = () => {
    if (item.isFavorite) {
      resourceLibraryFavorite(
        { resourceId: item.id, personResourceId: favorites[item.id] },
        'DELETE'
      );
    } else {
      resourceLibraryFavorite(item.id, 'POST');
    }
  };

  const handleOnClickLink = async () => {
    let link = '';
    if (item.synapseType === 'Document') {
      link = item.files[0].links.download;
    } else {
      const request = await apiFetchAuthorized(
        `/api/synapse/library/resource/link/${item.id}`
      );
      const response = await request.json();
      link = response.data.siteUrl;
    }

    const tempLink = document.createElement('a');
    tempLink.href = link;
    tempLink.setAttribute('target', '_bank');
    tempLink.setAttribute('rel', 'norefferer');

    // Required for Firefox, element must be apart of the document tree
    document.body.appendChild(tempLink);

    // Simulate click on newly created element.
    tempLink.click();

    // Link is no longer needed remove from tree.
    document.body.removeChild(tempLink);
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.open]: !expanded,
        [classes.isFavorite]: isFavorite,
        [classes.isDeleting]:
          deletingResource.includes(item.id) && fromFavoritesSlider,
      })}
    >
      <AccentedPaper
        width={320}
        backgroundColor={isFavorite ? '#90B850' : '#ffffff'}
      >
        <Grid container spacing={0} className={classes.mediaContainer}>
          <Grid item xs={10} className={classes.mediaRow}>
            {renderImage(item.image, item.synapseType)}
          </Grid>
          <Grid item xs={2}>
            <div className={classes.cardControl}>
              <div className={classes.startIcon}>
                {submittingResource.includes(item.id) ||
                deletingResource.includes(item.id) ? (
                  <IconButton
                    size="small"
                    color="primary"
                    disabled
                    style={{ height: '30px' }}
                  >
                    <CircularProgress style={{ height: '1em', width: '1em' }} />
                  </IconButton>
                ) : !!item.isFavorite ? (
                  <Tooltip title="Remove from favorites">
                    <IconButton
                      size="small"
                      aria-label="favorite"
                      onClick={handleFavoriteClick}
                      color="primary"
                      disabled={
                        submittingResource.includes(item.id) ||
                        deletingResource.includes(item.id)
                      }
                    >
                      <Fade in={true} className={classes.addFilter}>
                        <Favorite style={{ color: '#ED5158' }} />
                      </Fade>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip arrow title="Add to favorites" placement="top">
                    <IconButton
                      size="small"
                      aria-label="remove-favorite"
                      onClick={handleFavoriteClick}
                      color="primary"
                      disabled={
                        submittingResource.includes(item.id) ||
                        deletingResource.includes(item.id)
                      }
                    >
                      <Fade in={true} className={classes.addFilter}>
                        <FavoriteBorder style={{ color: '#0068A8' }} />
                      </Fade>
                    </IconButton>
                  </Tooltip>
                )}
                {/* </Tooltip> */}
                <Tooltip title="Link to resource" arrow placement="top">
                  <IconButton
                    size="small"
                    aria-label="link"
                    color="primary"
                    onClick={handleOnClickLink}
                    className={classes.addFilter}
                  >
                    <LinkIcon style={{ color: '#0068A8' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography style={{ fontSize: '14px' }} variant="body1">
                {item.title}
              </Typography>
              <Tooltip arrow placement="top" title="Show details">
                <AddCircleIcon
                  style={{ marginTop: 2, color: '#90B850', cursor: 'pointer' }}
                  onClick={onClickResource}
                />
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </AccentedPaper>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedId: state.resourceLibrary.selectedId,
  favorites: state.resourceLibrary.favorites,
  submittingResource: state.resourceLibrary.submittingResource,
  deletingResource: state.resourceLibrary.deletingResource,
});

const mapDispatchToProps = dispatch => ({
  resourceLibraryFavorite: (id, method) =>
    dispatch(resourceLibraryFavorite(id, method)),
  selectResource: id => dispatch(selectResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomCard);
