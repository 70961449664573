import React, { useEffect, useReducer } from 'react';
import {
  Field,
  reduxForm,
  FieldArray,
  change,
  formValueSelector,
  getFormValues,
} from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { format } from 'date-fns';
import {
  Typography,
  TextField,
  Grid,
  Fade,
  Radio,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import FragmentDialogBase from './FragmentDialogBase';
import { TEMP_FIELD_NAME } from '../JournalEntryDrawer';

import ErrorTooltip from './Forms/ErrorTooltip';
const currentTime = new Date();

import { maxLength, required, isPositive } from './Validations';

const maxLength2 = maxLength(2);
const maxLength8 = maxLength(8);
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);
const maxLength19 = maxLength(19);

export const ACTIVITY_EXERCISE_ITEM = {
  activityName: '',
  caloriesBurned: 0,
  caloriesBurnedUnit: 'kcal',
  DurationHrs: null,
  durationHrsUnit: 'hrs',
  durationMinsUnit: 'min',
};

export const ACTIVITY_EXERCISE_FIELDS = {
  activityItems: [],
  totalActivityTimeMins: 0,
  manualActivityTimeMins: 0,
  totalCaloriesBurnedUnit: 'kcal',
  totalCaloriesBurned: 0,
  manualCaloriesBurnedUnit: 'kcal',
  manualCaloriesBurned: 0,
};

const useStyles = makeStyles({
  formLabel: {
    fontSize: '12px',
  },
  additionalFields: {
    marginTop: '20px',
  },
  additionalInfoHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    marginTop: '17px',
  },

  rowFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '50px',
  },
  rowHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '2x',
  },
  header: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#0068A8',
    whiteSpace: 'pre',
    textAlign: 'center',
    marginTop: '13px',
  },
  textField: {
    color: '#0068A8',
    marginTop: '12px',
    minHeight: '60px',
  },
  activityItemTextField: {
    color: '#0068A8',
  },
  totalCalories: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addActivityItemForm: {
    borderRadius: 4,
    border: '1px solid #0068A8',
    backgroundColor: '#FBFBFB',
    padding: '7px 15px',
    marginBottom: '20px',
  },
  checkBoxFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  checkBoxFields: {
    maxWidth: 'fit-content',
    textAlign: 'center',
    whiteSpace: 'pre',
    fontSize: '14px',
    color: '#535461',
    marginTop: '-20px',
  },
  ateSlowlyField: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    margin: '5px 0px',
  },
  checkBoxText: {
    fontSize: '13px',
    lineHeight: '1',
  },
  removeField: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14x',
    letterSpacing: '0.15px',
  },
  addField: {
    display: 'flex',
    color: '#0068A8',
    marginLeft: '10px',
    fontSize: '18px',
    cursor: 'pointer',
  },
  radioFields: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  totalCaloriesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  focused: {},
});

const ReduxTextField = props => {
  const {
    hint,
    style,
    input: { onChange, value, onFocus, onBlur },
    meta: { error, touched },
    ...rest
  } = props;
  const hasError = !!(touched && error);
  return (
    <ErrorTooltip {...props} displayErrorEndAdornment={true}>
      <TextField
        {...rest}
        onChange={onChange}
        onBlur={onBlur}
        error={hasError}
        onFocus={onFocus}
        fullWidth
        helperText={hint ? hint : ''}
        value={value}
        FormHelperTextProps={{
          style: {
            fontSize: '11px',
          },
        }}
        style={{
          ...style,
          marginBottom: hasError || hint ? '0px' : '22px',
        }}
      />
    </ErrorTooltip>
  );
};

const AddActivityItemForm = ({ fields, onChange, onRemoveClick, ...props }) => {
  const classes = useStyles();

  const removeField = index => {
    onChange('removedField');
    fields.splice(index, 1);
  };
  return (
    <div>
      {fields.map((activityItems, index) => (
        <Fade in={true} key={index}>
          <Paper>
            <Grid
              container
              spacing={1}
              key={index}
              className={classes.addActivityItemForm}
            >
              <Grid item xs={12} className={classes.removeField}>
                <div
                  onClick={() => removeField(index, 1)}
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    cursor: 'pointer',
                  }}
                >
                  <Typography style={{ color: '#979797', fontSize: '13px' }}>
                    Remove
                  </Typography>
                  <RemoveCircleIcon
                    style={{
                      color: '#ED5158',
                      marginLeft: '3px',
                      fontSize: '21px',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`${activityItems}.activityName`}
                  validate={[required]}
                  component={ReduxTextField}
                  props={{
                    placeholder: 'Activity / Exercise Name',
                    margin: 'dense',
                    variant: 'outlined',
                    hint: 'Required',
                    inputProps: {
                      style: {
                        lineHeight: '1.187em',
                        color: 'rgba(0, 104, 168, 0.65)',
                        fontWeight: '500',
                        fontSize: '.9rem',
                        lineHeight: '24x',
                        padding: '9px',
                      },
                      maxLength: 30,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    color: '#0068A8',
                    fontSize: '14px',
                    marginTop: '-15px',
                  }}
                >
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Field
                  name={`${activityItems}.${TEMP_FIELD_NAME}durationHrs`}
                  component={ReduxTextField}
                  validate={[isPositive, maxLength2]}
                  onBlur={() => onChange('duration', index)}
                  type="number"
                  props={{
                    name: 'durationHrs',
                    margin: 'dense',
                    placeholder: '0',
                    type: 'number',
                    inputProps: {
                      style: {
                        color: '#0068A8',
                        textAlign: 'center',
                        padding: '9px 0px',
                      },
                      min: 1,
                      max: 24,
                      maxLength: 2,
                    },
                    InputProps: {
                      style: {
                        borderRadius: 4,
                        border: '1px solid #0068A8',
                        backgroundColor: '#EFF1F3',
                      },
                      disableUnderline: 'true',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disableTypography
                          style={{
                            fontSize: '10px',
                            color: 'rgba(0, 0, 0, 0.54)',
                            lineHeight: '1.5',
                            marginRight: '5px',
                            marginLeft: '-3px',
                          }}
                        >
                          hrs
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name={`${activityItems}.${TEMP_FIELD_NAME}durationMins`}
                  component={ReduxTextField}
                  validate={[isPositive, maxLength2]}
                  onBlur={() => onChange('duration', index)}
                  type="number"
                  props={{
                    name: 'durationMins',
                    margin: 'dense',
                    placeholder: '0',
                    type: 'number',
                    inputProps: {
                      style: {
                        color: '#0068A8',
                        textAlign: 'center',
                        padding: '9px 0px',
                      },
                      min: 1,
                      max: 60,
                      maxLength: 2,
                    },
                    InputProps: {
                      style: {
                        borderRadius: 4,
                        border: '1px solid #0068A8',
                        backgroundColor: '#EFF1F3',
                      },
                      disableUnderline: 'true',
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disableTypography
                          style={{
                            fontSize: '10px',
                            color: 'rgba(0, 0, 0, 0.54)',
                            lineHeight: '1.5',
                            marginRight: '5px',
                            marginLeft: '-3px',
                          }}
                        >
                          min
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <Typography
                      style={{
                        color: '#0068A8',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      {' '}
                      Calories {`\n`} Burned{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name={`${activityItems}.caloriesBurned`}
                      component={ReduxTextField}
                      validate={[maxLength3, isPositive]}
                      onBlur={() => onChange('calories')}
                      type="number"
                      normalize={value => parseInt(value)}
                      style={{ marginLeft: '6px' }}
                      props={{
                        name: 'caloriesBurned',
                        margin: 'dense',
                        placeholder: '0',
                        type: 'number',
                        inputProps: {
                          style: {
                            color: '#0068A8',
                            textAlign: 'center',
                            padding: '9px 0px',
                          },
                          min: 1,
                          max: 9999,
                          maxLength: 2,
                        },
                        InputProps: {
                          style: {
                            borderRadius: 4,
                            border: '1px solid #0068A8',
                            backgroundColor: '#EFF1F3',
                          },
                          disableUnderline: 'true',
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              disableTypography
                              style={{
                                fontSize: '10px',
                                color: 'rgba(0, 0, 0, 0.54)',
                                lineHeight: '1.5',
                                marginRight: '5px',
                                marginLeft: '-3px',
                              }}
                            >
                              kcal
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      ))}
      <Grid
        item
        xs={12}
        onClick={() =>
          fields.push({
            caloriesBurnedUnit: 'kcal',
          })
        }
        className={classes.addField}
      >
        <AddCircleIcon style={{ color: '#90B850', marginRight: '8px' }} />{' '}
        <Typography> Add Activity / Exercise </Typography>
      </Grid>
    </div>
  );
};

const TotalFormFields = ({
  onChange,
  isDurationCalculated,
  isCalorieCalculated,
}) => (
  <Grid container spacing={1} style={{ paddingTop: '25px' }}>
    <Grid item xs={2} style={{ padding: '10px 0px' }}>
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '12px',
          color: '#0068A8',
          marginTop: '5px',
        }}
      >
        Total Exercise {`\n`} Time
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Field
        name={`${TEMP_FIELD_NAME}ActivityTimeHrs`}
        component={ReduxTextField}
        validate={[isPositive, maxLength2]}
        onChange={() => onChange('totalMinutes')}
        disabled={isDurationCalculated}
        style={{ paddingLeft: '2px' }}
        type="number"
        props={{
          name: 'totalActivityTimeHrs',
          margin: 'dense',
          placeholder: '0',
          type: 'number',
          inputProps: {
            style: {
              color: '#0068A8',
              textAlign: 'center',
              padding: '9px 0px',
            },
            min: 1,
            max: 24,
            maxLength: 2,
          },
          InputProps: {
            style: {
              borderRadius: 4,
              border: '1px solid #0068A8',
              backgroundColor: '#EFF1F3',
            },
            disableUnderline: 'true',
            endAdornment: (
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '10px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                  marginRight: '5px',
                  marginLeft: '-3px',
                }}
              >
                hrs
              </InputAdornment>
            ),
          },
        }}
      />
    </Grid>
    <Grid xs={2} style={{ padding: '4px' }}>
      <Field
        name={`${TEMP_FIELD_NAME}ActivityTimeMins`}
        component={ReduxTextField}
        validate={[isPositive, maxLength2]}
        onChange={() => onChange('totalMinutes')}
        disabled={isDurationCalculated}
        type="number"
        style={{ paddingLeft: '2px' }}
        props={{
          margin: 'dense',
          placeholder: '0',
          type: 'number',
          inputProps: {
            style: {
              color: '#0068A8',
              textAlign: 'center',
              padding: '9px 0px',
            },
            min: 1,
            max: 60,
            maxLength: 2,
          },
          InputProps: {
            style: {
              borderRadius: 4,
              border: '1px solid #0068A8',
              backgroundColor: '#EFF1F3',
            },
            disableUnderline: 'true',
            endAdornment: (
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '10px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                  marginRight: '5px',
                  marginLeft: '-3px',
                }}
              >
                min
              </InputAdornment>
            ),
          },
        }}
      />
    </Grid>
    <Grid item xs={3} style={{ paddingTop: '10px', marginTop: '5px' }}>
      <Typography
        style={{
          textAlign: 'center',
          fontSize: '12px',
          color: '#0068A8',
          paddingLeft: '30px',
        }}
      >
        Total Calories {`\n`} Burned
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Field
        name="rftempCaloriesBurned"
        component={ReduxTextField}
        validate={[required, isPositive, maxLength4]}
        onChange={e => onChange('totalCalories', e.target.value)}
        disabled={isCalorieCalculated}
        type="number"
        props={{
          name: 'totalCaloriesBurned',
          margin: 'dense',
          placeholder: '0',
          type: 'number',
          inputProps: {
            style: {
              color: '#0068A8',
              textAlign: 'center',
              padding: '9px 0px',
            },
            maxLength: '4',
          },
          InputProps: {
            style: {
              borderRadius: 4,
              border: '1px solid #0068A8',
              backgroundColor: '#EFF1F3',
            },
            disableUnderline: 'true',
            endAdornment: (
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '10px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                  marginRight: '5px',
                  marginLeft: '-3px',
                }}
              >
                kcal
              </InputAdornment>
            ),
          },
        }}
      />
    </Grid>
  </Grid>
);

const ActivityExerciseDialog = ({
  updateField,
  fragment,
  initialValues,
  activityItems = {},
  rftempActivityTimeHrs,
  rftempActivityTimeMins,
  rftempCaloriesBurned,
  totalActivityTimeMins,
  totalCaloriesBurned,
  manualCaloriesBurned,
  manualActivityTimeMins,
  ...props
}) => {
  const classes = useStyles();

  //returns excess hr value when minute > 60
  const getHrsFromMinutes = minute => {
    if (parseInt(minute) > 60) {
      const hrs = Math.floor(parseInt(minute) / 60);
      const min = parseInt(minute) % 60;
      return [hrs, min];
    }
    return [0, parseInt(minute)];
  };

  const getMinuteAggregate = (hrs, min) => {
    if (!!hrs && !!min) {
      return parseInt(hrs) * 60 + parseInt(min);
    }
  };

  useEffect(() => {
    if (!loadedInitialValues) {
      dispatch({ type: 'LoadedInitialValues' });
    }
  }, [initialValues]);

  function reducer(state, action) {
    switch (action.type) {
      case 'LoadedInitialValues':
        let isInitCaloriesCalculated =
          initialValues.manualCaloriesBurned === 0 &&
          initialValues.totalCaloriesBurned > 0;

        let toggleMinsState =
          initialValues.manualActivityTimeMins === 0 &&
          initialValues.totalActivityTimeMins > 0;

        initialValues.activityItems.length > 0 &&
          initialValues.activityItems.forEach((item, index) => {
            const tempDurations = getHrsFromMinutes(item.durationMinutes);
            updateField(
              `activityItems[${index}].${TEMP_FIELD_NAME}durationHrs`,
              tempDurations[0]
            );
            updateField(
              `activityItems[${index}].${TEMP_FIELD_NAME}durationMins`,
              tempDurations[1]
            );
          });

        //map initial values to temp
        isInitCaloriesCalculated
          ? updateField(
              `${TEMP_FIELD_NAME}CaloriesBurned`,
              initialValues.totalCaloriesBurned
            )
          : !!initialValues.manualCaloriesBurned
          ? updateField(
              `${TEMP_FIELD_NAME}CaloriesBurned`,
              initialValues.manualCaloriesBurned
            )
          : null;

        toggleMinsState
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeHrs`,
              getHrsFromMinutes(initialValues.totalActivityTimeMins)[0]
            )
          : !!initialValues.manualActivityTimeMins
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeHrs`,
              getHrsFromMinutes(initialValues.manualActivityTimeMins)[0]
            )
          : null;

        toggleMinsState
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.totalActivityTimeMins)[1]
            )
          : !!initialValues.manualActivityTimeMins
          ? updateField(
              `${TEMP_FIELD_NAME}ActivityTimeMins`,
              getHrsFromMinutes(initialValues.manualActivityTimeMins)[1]
            )
          : null;
        return {
          ...state,
          ...initialValues,
          loadedInitialValues: true,
          isCalorieCalculated: isInitCaloriesCalculated,
          isDurationCalculated: toggleMinsState,
          shouldUpdate:
            (isInitCaloriesCalculated && 'calories') ||
            (toggleMinsState && 'duration'),
        };
      case 'updateTemp':
        updateField(`${action.name}`, action.value);
        return state;
      case 'updateManualActivityTime':
        let totalManualMinutes =
          parseInt(rftempActivityTimeHrs) > 0
            ? getMinuteAggregate(rftempActivityTimeHrs, rftempActivityTimeMins)
            : parseInt(rftempActivityTimeMins);
        updateField('manualActivityTimeMins', totalManualMinutes);
        updateField('totalActivityTimeMins', totalManualMinutes);
        return state;
      case 'isCalorieCalculated':
        if (!action.payload) {
          updateField(`${TEMP_FIELD_NAME}CaloriesBurned`, null);
        }
        if (action.payload) {
          updateField('manualCaloriesBurned', 0);
        }
        return {
          ...state,
          isCalorieCalculated: action.payload,
          shouldUpdate: true,
        };
      case 'isDurationCalculated':
        if (!action.payload) {
          updateField(`${TEMP_FIELD_NAME}ActivityTimeMins`, null);
          updateField(`${TEMP_FIELD_NAME}ActivityTimeHrs`, null);
        }
        if (action.payload) {
          const hrsFromMinutes = getHrsFromMinutes(totalActivityTimeMins);
          updateField('manualActivityTimeMins', 0);
        }
        return {
          ...state,
          isDurationCalculated: action.payload,
          shouldUpdate: true,
        };
      case 'calculateDurationMinutes':
        const durationMinutes =
          (parseInt(activityItems[action.value].rftempdurationHrs) > 0
            ? parseInt(activityItems[action.value].rftempdurationHrs) * 60
            : 0) +
          (parseInt(activityItems[action.value].rftempdurationMins) > 0
            ? parseInt(activityItems[action.value].rftempdurationMins)
            : 0);

        updateField(
          `activityItems[${action.value}].durationMinutes`,
          durationMinutes
        );

        return state;
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    loadedInitialValues: false,
    isDurationCalculated: false,
    isCalorieCalculated: false,
    shouldCalculate: true,
  });

  const {
    loadedInitialValues,
    isDurationCalculated,
    isCalorieCalculated,
    shouldCalculate,
  } = state;

  useEffect(() => {
    let totalCalories = 0;
    if (activityItems.length > 0) {
      totalCalories = activityItems.reduce(
        (a, item) => a + parseInt(item.caloriesBurned || 0),
        0
      );
    }
    updateField('totalCaloriesBurned', totalCalories);
    if (!isCalorieCalculated) {
      totalCalories = totalCalories + manualCaloriesBurned;
      updateField('totalCaloriesBurned', totalCalories);
    }
    if (isCalorieCalculated) {
      updateField(
        `${TEMP_FIELD_NAME}CaloriesBurned`,
        totalCalories > 0 ? totalCalories : null
      );
    }

    let totalMinutes = 0;
    if (activityItems.length > 0) {
      totalMinutes = activityItems.reduce(
        (a, item) => a + parseInt(item.durationMinutes || 0),
        0
      );
    }
    updateField('totalActivityTimeMins', !!totalMinutes ? totalMinutes : 0);

    let totalHoursFromMinutes = getHrsFromMinutes(totalMinutes);

    if (!isDurationCalculated) {
      updateField(
        'totalActivityTimeMins',
        totalMinutes + manualActivityTimeMins
      );
    }
    if (isDurationCalculated) {
      updateField(
        `${TEMP_FIELD_NAME}ActivityTimeMins`,
        totalHoursFromMinutes[1] > 0 ? totalHoursFromMinutes[1] : null
      );
      updateField(
        `${TEMP_FIELD_NAME}ActivityTimeHrs`,
        totalHoursFromMinutes[0] > 0 ? totalHoursFromMinutes[0] : null
      );
    }
  }, [
    activityItems,
    manualActivityTimeMins,
    manualCaloriesBurned,
    isCalorieCalculated,
    isDurationCalculated,
  ]);

  const handleRadioChange = (type, value) => {
    if (type === 'isCalorieCalculated' && !value) {
      dispatch({
        type: 'updateTemp',
        name: `${TEMP_FIELD_NAME}CaloriesBurned`,
        value: null,
      });
      dispatch({ type: 'isCalorieCalculated', payload: false });
      return;
    }

    if (type === 'isDurationCalculated' && !value) {
      dispatch({
        type: 'updateTemp',
        name: `${TEMP_FIELD_NAME}ActivityTimeMins`,
        value: null,
      });
      dispatch({ type: 'isDurationCalculated', payload: false });
      return;
    }
    dispatch({ type: type, payload: value });
  };

  const handleChange = (event, value) => {
    //set manual === rftempCaloriesBurned
    if (
      (event === 'removedField' && isDurationCalculated) ||
      isCalorieCalculated
    ) {
      dispatch({ type: 'CalculateCalories' });
      dispatch({ type: 'CalculateDuration' });
    }

    if (event === 'totalCalories') {
      updateField('manualCaloriesBurned', parseInt(value));
      updateField('totalCaloriesBurned', parseInt(value));
    }
    if (event === 'totalMinutes') {
      dispatch({ type: 'updateManualActivityTime' });
    }

    //calculate durationMins, value === 'index'
    if (event === 'duration') {
      dispatch({ type: 'calculateDurationMinutes', value: value });
    }

    if (event === 'calories') {
      dispatch({ type: 'calculateCaloriesBurned' });
    }
  };

  return (
    <FragmentDialogBase
      color="#2AA5A2"
      defaultTitle="Meal"
      FormSlot1={
        <div>
          <TotalFormFields
            onChange={handleChange}
            isCalorieCalculated={isCalorieCalculated}
            isDurationCalculated={isDurationCalculated}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={classes.radioFields}>
                <Radio
                  checked={!isDurationCalculated}
                  onClick={() =>
                    handleRadioChange('isDurationCalculated', false)
                  }
                />
                <Typography style={{ fontSize: '.8rem' }}>Manual </Typography>
                <Radio
                  checked={isDurationCalculated}
                  onClick={() =>
                    handleRadioChange('isDurationCalculated', true)
                  }
                />
                <Typography style={{ fontSize: '.8rem' }}>
                  {' '}
                  Calculated{' '}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.radioFields}>
                <Radio
                  checked={!isCalorieCalculated}
                  onClick={() =>
                    handleRadioChange('isCalorieCalculated', false)
                  }
                />
                <Typography style={{ fontSize: '.8rem' }}>Manual </Typography>
                <Radio
                  checked={isCalorieCalculated}
                  onClick={() => handleRadioChange('isCalorieCalculated', true)}
                />
                <Typography style={{ fontSize: '.8rem' }}>
                  {' '}
                  Calculated{' '}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      }
      FormSlot2={
        <Grid container spacing={0} className={classes.additionalFields}>
          <Grid item xs={12}>
            <FieldArray
              name="activityItems"
              component={AddActivityItemForm}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = dispatch => ({
  updateField: (field, data) => dispatch(change('reduxForm', field, data)),
});

const mapStateToProps = state => ({
  initialValues: {
    ...ACTIVITY_EXERCISE_FIELDS,
    ...(!!state.journalCompose.fragments[0] &&
    !!state.journalCompose.fragments[0].fragmentData
      ? state.journalCompose.fragments[0].fragmentData
      : {}),
  },
});

let ActivityExerciseFormRedux = reduxForm({
  form: 'reduxForm',
  touchOnChange: true,
  enableReinitialize: true,
})(withRouter(ActivityExerciseDialog));

const selector = formValueSelector('reduxForm');
ActivityExerciseFormRedux = connect(state => {
  const activityItems = selector(state, 'activityItems');
  const rftempActivityTimeMins = selector(
    state,
    `${TEMP_FIELD_NAME}ActivityTimeMins`
  );
  const rftempActivityTimeHrs = selector(
    state,
    `${TEMP_FIELD_NAME}ActivityTimeHrs`
  );
  const rftempCaloriesBurned = selector(
    state,
    `${TEMP_FIELD_NAME}CaloriesBurned`
  );
  const totalCaloriesBurned = selector(state, 'totalCaloriesBurned');
  const totalActivityTimeMins = selector(state, 'totalActivityTimeMins');
  const manualActivityTimeMins = selector(state, 'manualActivityTimeMins');
  const manualCaloriesBurned = selector(state, 'manualCaloriesBurned');
  return {
    activityItems,
    rftempCaloriesBurned,
    rftempActivityTimeMins,
    rftempActivityTimeHrs,
    totalCaloriesBurned,
    totalActivityTimeMins,
    manualCaloriesBurned,
    manualActivityTimeMins,
  };
})(ActivityExerciseFormRedux);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityExerciseFormRedux);
