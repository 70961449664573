import React from 'react';
import { Grid } from '@material-ui/core';

import BasicImage from '../../../../client/images/products/basic.png';
import ShareableImage from '../../../../client/images/products/board.png';
import Product from './Product';

export const productImages = [BasicImage, ShareableImage];

const RegistrationPlan = ({ products, product, onSelect }) => {
  return (
    <Grid container spacing={3} direction="row" justify="center">
      <Grid item xs={12}>
        <h2 style={{ margin: '0' }}>
          Choose a subscription to meet your needs
        </h2>
      </Grid>
      {products &&
        products.map((data, index) => (
          <Grid item xs={12} sm={6} md={6}>
            <Product
              key={data.productId}
              data={{ ...data, image: productImages[index] }}
              status={
                !product || product.data === null
                  ? 'NO_SELECTION'
                  : product.data.productId === data.productId
                  ? 'SELECTED'
                  : 'UNSELECTED'
              }
              onSelect={onSelect}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default RegistrationPlan;
