import React, { Component } from "react";
import PropTypes from "prop-types";

class IntegerInput extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      value: props.value
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }

  padString = string => {
    return String(string)
      .padStart(this.props.padLength, this.props.padString)
      .substring(0, String(this.props.maxValue).length);
  };

  getCalculatedValue = (value, factorRotation = false) => {
    const { minValue, maxValue, isRotating } = this.props;

    return value < minValue
      ? isRotating && factorRotation
        ? maxValue
        : minValue
      : value > maxValue
      ? isRotating && factorRotation
        ? minValue
        : maxValue
      : value;
  };

  increment = event => {
    let { value } = this.state;
    value = this.getCalculatedValue(value + 1, true);
    this.props.onChange(value, event);
    this.setState({ value });
  };

  decrement = event => {
    let { value } = this.state;
    value = this.getCalculatedValue(value - 1, true);
    this.props.onChange(value, event);
    this.setState({ value });
  };

  onClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  onKeyDown = event => {
    const { maxValue } = this.props;
    const strValue = String(parseInt(event.target.value));
    const strMaxValue = String(maxValue);
    let value = 0,
      index = 0,
      factorRation = false;

    // Only check for neighbors if the parentRef is defined
    if (this.props.parentRef) {
      const inputNodes = this.props.parentRef.current.getElementsByTagName(
        "input"
      );

      index = Array.from(inputNodes).indexOf(this.ref.current);
      index = event.key === "ArrowLeft" ? index - 1 : index + 1;
      
      /**
       * Lets have a dicussion about whats most accepted from users.
       * Tab is used to navigate 
       * Repurpsing the arrows keys will create confusion.
       * Arrow keys should only be moving the cursor? 
       */
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        if (index > -1 && index < inputNodes.length) {
          inputNodes[index].focus();
        }
        return;
      }
    }

    /**
     * keyCode is deprecated? 
     * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
     * 
     * Mobile Keycodes are different ... http://keycode.info/
     */
    // if (
    //   !(event.keyCode >= 48 && event.keyCode <= 57) &&
    //   ![37, 38, 39, 40, 8].includes(event.keyCode)
    // )
    //   return;

    if (event.key == "Backspace") { 
        value = this.state.value != undefined && this.state.value.length > 0 ? parseInt(this.state.value.substring(0, this.state.value.length - 1)) : '';
    } else if(isNaN(event.key)) {
      return;
    } else if (event.key === "ArrowUp") {
      value = parseInt(this.state.value) + 1;
      factorRation = true;
    } else if (event.key === "ArrowDown") {
      value = parseInt(this.state.value) - 1;
      factorRation = true;
    } else if (strValue.length >= strMaxValue.length) {
      value = parseInt(event.key);
    } else {
      value = parseInt(this.state.value) + event.key;
    }

    value = this.getCalculatedValue(value, factorRation);
    this.setState({ value });

    if (this.props.onChange) {
      this.props.onChange(value, event);
    }
  };

  render() {
    const {
      minValue,
      maxValue,
      padLength,
      padString,
      isRoatating,
      isDefaultingToMaxValue,
      onChange,
      parentRef,
      ...rest
    } = this.props;

    return (
      <input
        {...rest}
        type="text"
        ref={this.ref}
        pattern="[0-9]*"
        inputMode="numeric"
        onChange={() => 0}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        value={this.padString(this.state.value)}
      />
    );
  }
}

IntegerInput.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  padLength: PropTypes.number.isRequired,
  padString: PropTypes.string.isRequired,
  isRotating: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

IntegerInput.defaultProps = {
  minValue: 0,
  padLength: 2,
  padString: "0",
  isRotating: true
};

export default IntegerInput;
