import { ajax } from '../../../library/helpers/fetch';
import { omit } from 'lodash';

import { format } from 'date-fns-tz';
import { avatarUpdateAttempt, avatarUpdateSuccess } from '../app/actions';
import {
  MVS,
  JOURNAL,
  ALLERGY,
  IMMUNIZATION,
  LABRESULT,
  INSURANCE_CARD,
  CONDITIONS,
  SURGICALHISTORY,
} from '.';

export const MEDIA_OBJECT_UPLOAD_REQUEST = '@redux/media-object/UPLOAD_REQUEST';
export const MEDIA_OBJECT_UPLOAD_SUCCESS = '@redux/media-object/UPLOAD_SUCCESS';
export const MEDIA_OBJECT_UPLOAD_FAILURE = '@redux/media-object/UPLOAD_FAILURE';
export const MEDIA_OBJECT_ALL_DONE = '@redux/media-object/ALL_DONE';

export const MEDIA_OBJECT_SET_FILES = '@redux/media-object/SET_FILES';
export const MEDIA_OBJECT_REMOVE_FILE = '@redux/media-objects/REMOVE_FILE';
export const MEDIA_OBJECT_CLEAR_FILES = '@redux/media-object/CLEAR_FILES';
export const MEDIA_OBJECT_SET_DESCRIPTION =
  '@redux/media-objects/UPDATE_DESCRIPTION';
export const MEDIA_OBJECT_UPDATE_PROGRESS =
  '@redux/media-object/UPDATE_PROGRESS';
export const MEDIA_OBJECT_UPLOAD_COMPLETE =
  '@redux/media-object/UPLOAD_COMPLETE';
export const MEDIA_OBJECT_UPLOADING_FILE = '@redux/media-object/UPLOADING_FILE';
export const MEDIA_OBJECT_SET_META = 'media-object/set/META';
export const MEDIA_OBJECT_SET_DATE = '@redux/media-objects/UPDATE_DATE';
export const MEDIA_OBJECT_SET_TIME = '@redux/media-objects/UPDATE_TIME';

export const RESOURCE_MEDIA_MODIFIED = 'mediaObject/RESOURCE_MEDIA_MODIFIED';
export const RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE = 'CREATE';
export const RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE = 'UPDATE';
export const RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE = 'DELETE';

export const deleteMedia = (index, type) => ({
  type,
  payload: index,
});

export const setToDelete = (mid, ID, type) => ({
  type,
  payload: {
    mediaObjectId: mid,
    referenceId: ID,
  },
});

export const cancelDelete = (mid, type) => ({
  type,
  payload: mid,
});

export const setFiles = files => ({
  type: MEDIA_OBJECT_SET_FILES,
  payload: files,
});

export const removeFile = id => ({
  type: MEDIA_OBJECT_REMOVE_FILE,
  payload: id,
});

export const setDescription = (index, description) => ({
  type: MEDIA_OBJECT_SET_DESCRIPTION,
  payload: { index, description },
});

export const setMediaDate = (index, date) => ({
  type: MEDIA_OBJECT_SET_DATE,
  payload: { index, date },
});

export const setMediaTime = (index, time) => ({
  type: MEDIA_OBJECT_SET_TIME,
  payload: { index, time },
});

/**
 * Sometimes It's advantageous to include attributes that describe, organize, or help transform a
 * media object. Calling this action will attach attributes defined in the "meta" object parameter
 * to a specified, by index, media object.
 *
 * @example we want to extract on a specified area of an image (media object) that exists at index 5
 * we would do the the following: setMeta(5, { x: 10, y: 10, width: 280, height: 180 }). Once the
 * reducer has run our final object will look something like the following:
 *
 * {
 *  file: ...,
 *  description: ...,
 *  loaded: 0,
 *  total: ...,
 *  x: 10,
 *  y: 10,
 *  width: 280,
 *  height: 180,
 *  isUploading: false,
 *  isUploaded: false,
 * }
 *
 * Note: The additional metadata will be sent as a post field in the HTTP request.
 *
 * @param {*} index index where the media object exists in the array.
 * @param {object} meta attributes list to be bound to specified media object
 */
export const setMeta = (index, meta) => ({
  type: MEDIA_OBJECT_SET_META,
  payload: { index, meta },
});

export const clearFiles = appArea => ({
  type: MEDIA_OBJECT_CLEAR_FILES,
  payload: appArea,
});

export const updateUploadProgress = (name, event) => ({
  type: MEDIA_OBJECT_UPDATE_PROGRESS,
  payload: {
    name,
    loaded: event.loaded,
    total: event.total,
  },
});

export const doneUpload = (payload, meta) => ({
  type: MEDIA_OBJECT_UPLOAD_COMPLETE,
  meta,
  payload,
});

export const setMediaObjects = (bundles, objectIdKey, appArea) => ({
  bundles,
  objectIdKey,
  appArea,
});

export const onProgress = (name, dispatch) => event => {
  if (event.lengthComputable && event.loaded !== event.total) {
    dispatch(updateUploadProgress(name, event));
  }
};

export const nonMetaKeys = [
  'file',
  'description',
  'loaded',
  'total',
  'isUploading',
  'isUploaded',
];

export const uploadFile = (uid, bundle, area) => {
  return async dispatch => {
    // Convert bundle into array utilizing existing API.
    dispatch({ type: MEDIA_OBJECT_UPLOAD_REQUEST, payload: [bundle] });

    const formData = new FormData();
    formData.append('personId', uid);
    formData.append('file', bundle.file);
    formData.append('description', bundle.description);

    // Remove all non-metadata values. See meta variable for insight on what those are.
    const meta = Object.keys(omit(bundle, nonMetaKeys));

    // Using only the meta values, map to format data payload.
    for (let i = 0; i < meta.length; i++) {
      formData.append(meta[i], bundle[meta[i]]);
    }

    let url;

    const appArea = area.toUpperCase();
    switch (appArea) {
      case 'AVATAR':
        //url = `/api/member/${uid}/person/mediaobject`;
        url = `/api/media/profile/avatar`;
        dispatch(avatarUpdateAttempt(bundle));
        break;

      default:
        throw Error(`Unsupported app area "${area}"`);
    }

    var result = await ajax(url, {
      method: 'POST',
      body: formData,
      onProgress: onProgress(bundle.file.name, dispatch),
    });

    switch (appArea) {
      case 'AVATAR':
        dispatch(avatarUpdateSuccess(result.data));
        break;
    }

    dispatch(doneUpload({ index: 0, appArea }));

    return result;
  };
};

export const uploadFiles = (personId, objectId, bundles, area, meta) => {
  const generator = function*(bundles) {
    for (var i = 0; i < bundles.length; i++) {
      yield bundles[i];
    }
  };

  return async dispatch => {
    let next = null;
    let index = 0;
    let formData = null;

    const iterator = generator(bundles);
    const _bundles = [];
    dispatch({ type: MEDIA_OBJECT_UPLOAD_REQUEST, payload: bundles });

    while (true) {
      next = iterator.next();

      if (next.done || next.value === null) {
        dispatch({ type: MEDIA_OBJECT_ALL_DONE });
        break;
      }

      // If a file is already uploaded, then prevent from uploading
      // the file again. Note: the uploaded file is kept in the global
      // collection for UI purposes.
      if (next.value.isUploaded) {
        continue;
      }

      dispatch({ type: MEDIA_OBJECT_UPLOADING_FILE, payload: index });
      const currDate = next.value.date
        ? next.value.date
        : format(new Date(), 'yyyy-MM-dd');
      const date = next.value.time
        ? new Date(`${currDate} ${next.value.time}`)
        : new Date(`${currDate}`);
      const newDateTime = format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
      let url = '';
      formData = new FormData();
      formData.append('personId', personId);
      formData.append('objectId', objectId);
      formData.append('file', next.value.file);
      formData.append('description', next.value.description);
      if (next.value.date || next.value.time) {
        formData.append('enteredOn', newDateTime);
      }
      if (next.value.displayName) {
        formData.append('displayName', next.value.displayName);
      }
      if (next.value.category) {
        formData.append('category', next.value.category);
      }

      // Remove all non-metadata values. See meta variable for insight on what those are.
      const fileMeta = Object.keys(omit(next.value, nonMetaKeys));

      // Using only the meta values, map to format data payload.
      for (let i = 0; i < fileMeta.length; i++) {
        formData.append(fileMeta[i], next.value[fileMeta[i]]);
      }

      const appArea = area.toUpperCase();

      switch (appArea) {
        case SURGICALHISTORY:
        case CONDITIONS:
        case MVS:
        case JOURNAL:
        case ALLERGY:
        case IMMUNIZATION:
        case LABRESULT:
          // url = `/api/member/${uid}/allergymediaobject/`
          url = `/api/media/${area}`;
          break;
        case INSURANCE_CARD:
          // url = `/api/member/${uid}/insurance/card/mediaobject`;
          url = `/api/media/insurance/card`;
          break;

        default:
          throw Error(`Unsupported app area "${area}"`);
      }

      const result = await ajax(url, {
        method: 'POST',
        body: formData,
        onProgress: onProgress(next.value.file.name, dispatch),
      });

      const payload = { index, appArea, data: result.data };

      dispatch(doneUpload(payload, { ...meta, objectId }));

      dispatch({
        type: RESOURCE_MEDIA_MODIFIED,
        payload: result.data,
        meta: {
          resource: appArea,
          operation: RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
          objectId,
        },
      });
      _bundles.push(result.data);
      index++;
    }
    return _bundles;
  };
};
