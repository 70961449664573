import { apiFetchAuthorized } from '../../../library/helpers/fetch';
import { createQueryStringFromObject } from '../../../library/helpers/encode';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const USER_PROGRESS_REQUEST = '@@redux/profile-progress/USER_PROGRESS_REQUEST';
export const USER_PROGRESS_FAILURE = '@@redux/profile-progress/USER_PROGRESS_FAILURE';
export const USER_PROGRESS_SUCCESS = '@@redux/profile-progress/USER_PROGRESS_SUCCESS';

export const fetchUserProfileProgress = (id) =>({
    [DISPATCH_API_AUTHORIZED]: {
        types: [USER_PROGRESS_REQUEST, USER_PROGRESS_SUCCESS, USER_PROGRESS_FAILURE],
        endpoint: `/api/member/${ id }/profile-progress`
    }
})