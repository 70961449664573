import SurgicalHistoryPage from './SurgicalHistoryPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reset } from 'redux-form';
import { styles } from './styles';
import { withStyles } from '@material-ui/core';
import {
  cancelFormReset,
  uiStateEntityUpdate,
} from '../../../../store/reducers/ui/actions';
import {
  fetchSurgicalHistories,
  deleteSurgicalHistory,
  setSelectedId,
  clearSelectedId,
} from '../../../../store/reducers/surgicalHistory/actions';
import { fetchSurgicalHistoryType } from '../../../../store/reducers/codes/actions';
import { clearFiles } from '../../../../store/reducers/mediaObject';
import { SURGICALHISTORY } from '../../../../store/reducers/mediaObject';

const mapStateToProps = state => ({
  surgicalHistory: state.surgicalHistory,
  user: state.active.user,
  isFetchingSurgicalHistoryType: state.codes.isFetchingSurgicalHistoryType,
  hasFetchedSurgicalHistoryType: state.codes.hasFetchedSurgicalHistoryType,
  deletingList: state.surgicalHistory.deletingList,
});

const mapDispatchToProps = dispatch => ({
  cancelFormReset: () => dispatch(cancelFormReset()),
  // uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
  fetchSurgicalHistories: pid => dispatch(fetchSurgicalHistories(pid)),
  deleteSurgicalHistory: (pid, surgicalHistoryId, tid) =>
    dispatch(deleteSurgicalHistory(pid, surgicalHistoryId, tid)),
  setSelectedId: surgicalHistoryId =>
    dispatch(setSelectedId(surgicalHistoryId)),
  clearSelectedId: () => dispatch(clearSelectedId()),
  reset: () => dispatch(reset('surgical-history-form')),
  fetchSurgicalHistoryType: () => dispatch(fetchSurgicalHistoryType()),
  clearFiles: () => dispatch(clearFiles(SURGICALHISTORY)),
});

export default {
  component: withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(SurgicalHistoryPage))
  ),
};
