import ImmunizationPage from './ImmunizationPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  fetchImmunizations,
  immunizationDelete,
  immunizationSelectId,
  immunizationClearSelectedId,
  immunizationUpdate,
  immunizationCreate,
  attachMediaToEntry,
  updateMedia,
} from '../../../store/reducers/immunizations/actions';
import { fetchVaccinationType } from '../../../store/reducers/codes/actions';
import { cancelFormReset } from '../../../store/reducers/ui/actions';
import {
  uiStateEntityCreate,
  uiStateEntityUpdate,
} from '../../../store/reducers/ui/actions';
import { clearFiles } from '../../../store/reducers/mediaObject/actions';
import { documentUpdate } from '../../../store/reducers/documents/actions';

const mapStateToProps = state => ({
  didInvalidate: state.member.userImmunizations.didInvalidate,
  userImmunizations: state.member.userImmunizations,
  immunizationRecord: state.member.userImmunizations.selectedId
    ? state.member.userImmunizations.list[
        state.member.userImmunizations.selectedId
      ]
    : null,
  personId: state.active.user.personId,
  ui: state.ui,
  hasFetchedVaccinationType: Boolean(state.codes.vaccinationTypes.length),
  form: state.form,
  mediaObject: state.mediaObject,
});

const mapDispatchToProps = dispatch => ({
  immunizationCreate: (pid, data, bundles, options) =>
    dispatch(immunizationCreate(pid, data, bundles, options)),
  immunizationUpdate: (pid, mid, data, bundles, options) =>
    dispatch(immunizationUpdate(pid, mid, data, bundles, options)),
  fetchImmunizations: () => dispatch(fetchImmunizations()),
  immunizationDelete: (pid, mid) => dispatch(immunizationDelete(pid, mid)),
  immunizationSelectId: id => dispatch(immunizationSelectId(id)),
  cancelFormReset: () => dispatch(cancelFormReset()),
  uiStateEntityCreate: subject => dispatch(uiStateEntityCreate(subject)),
  uiStateEntityUpdate: subject => dispatch(uiStateEntityUpdate(subject)),
  fetchVaccinationType: () => dispatch(fetchVaccinationType()),
  immunizationClearSelectedId: () => dispatch(immunizationClearSelectedId()),
  clearFiles: () => dispatch(clearFiles('IMMUNIZATION')),
  attachMediaToEntry: (mid, bundle) =>
    dispatch(attachMediaToEntry(mid, bundle)),
  updateMedia: (pid, documentId, document) =>
    dispatch(updateMedia(pid, documentId, document)),
});

export default {
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ImmunizationPage)),
};
