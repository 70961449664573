import React from 'react';
import { useStyles } from './useStyles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';

const EmptyCard = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper square={true} elevation={4}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} className={classes.statusField}>
            <Typography component="h5" variant="h5" style={{ padding: '8px' }}>
              <span className={classes.statusText}>Status:</span>{' '}
              <span className={classes.statusValue}>Incomplete</span>{' '}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.cardContent}>
            <div
              style={{
                height: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>No COVID Lab Test record found</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                margin: '0px 16px',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                style={{ color: '#fff' }}
                onClick={onClick}
              >
                Add a Record
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default EmptyCard;
