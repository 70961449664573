const dataFields = [];

const normalizeData = data =>
  Array.isArray(data) ? data.map(x => x.toLowerCase()) : data;

const properties = {
  label: 'Emotions',
  color: '#FFA561',
  icon: 'insert_emoticon',
  dataFields,
  normalizeData,
};

export { dataFields, properties };
