export const surgeryTypeAhead = props => (inputValue, callBack) => {
  // let { fetchMedicationList } = props;
  // fetchMedicationList(inputValue).then(res => {
  //   let cache = {};
  //   let options = [];
  //   res.payload.map(o => {
  //     // if (!cache[o.m])
  //     options.push({
  //       label: o.medicationShortName,
  //       value: o.medicationShortName,
  //     });
  //     // cache[o.medListId.toLowerCase()] = true;
  //   });
  //   console.log('MEDICATIONS!', options);
  //   callBack(options);
  // });
  // To my surprise keeping this function and triggering the call back speeds up
  // searching. Will be replaced with Elastic Search forthcoming implementation
  callBack(props.surgicalHistoryTypes);
};
