import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
import {
  sortByField,
  sort,
  normalizeSortDirection,
} from '../../../library/helpers/sort';

import {
  IMMUNIZATION_REQUEST,
  IMMUNIZATION_SUCCESS,
  IMMUNIZATION_FAILURE,
  IMMUNIZATION_UPDATE,
  IMMUNIZATION_UPDATE_SUCCESS,
  IMMUNIZATION_UPDATE_FAILURE,
  IMMUNIZATION_CREATE,
  IMMUNIZATION_CREATE_SUCCESS,
  IMMUNIZATION_CREATE_FAILURE,
  IMMUNIZATION_DELETE,
  IMMUNIZATION_DELETE_SUCCESS,
  IMMUNIZATION_DELETE_FAILURE,
  IMMUNIZATION_SELECT_ID,
  IMMUNIZATION_CLEAR_ID,
  RESOURCE_MEDIA_INFO_UPDATED,
  IMMUNIZATION_UPDATE_MEDIA,
  IMMUNIZATION_REMOVE_MEDIA,
  SORT_BY,
  IMMUNIZATION_MEDIA_TO_DELETE,
  MEDIA_REMOVE_SUCCESS,
  IMMUNIZATION_MEDIA_TO_DELETE_CANCEL,
  UPDATE_IMMUNIZATION_MEDIA,
} from './actions';

import { SET_ACTIVE_USER } from '../../../store/reducers/active/actions';

import { UI_CANCELED_FORM } from '../../../store/reducers/ui/actions.js';
import { LOADING, SUCCESS, ERROR, EMPTY } from '../../state';

import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
  MEDIA_OBJECT_UPLOAD_REQUEST,
} from '../mediaObject/actions';

const initialState = {
  isFetching: false,
  hasFetchedImmunizations: false,
  isSubmitting: false,
  didInvalidate: false,
  list: {},
  listIds: [],
  immunizationToUpdate: {},
  selectedId: null,
  errorMessage: null,
  activeUser: null,
  mediaObjects: {},
  uploadingMediaObjects: false,
  bundlesToDelete: [],
  sortBy: 'administrationOn',
  sortDirection: 'desc',

  uiState: LOADING,
  deleting: [],
};

const reducers = [];

reducers[RESOURCE_MEDIA_MODIFIED] = (state, action) => {
  // Only process immunization associated actions.
  if (action.meta.resource !== 'IMMUNIZATION') return state;

  const updateMediaObject = item => {
    if (item.mediaObjectId !== action.payload.mediaObjectId) return item;
    return {
      ...item,
      ...action.payload,
    };
  };

  const resource =
    action.meta.objectId && state.list[action.meta.objectId]
      ? state.list[action.meta.objectId]
      : null;

  // Create the selected media object in the associated resource (objectId)
  switch (action.meta.operation) {
    case RESOURCE_MEDIA_MODIFIED_OPERATION_CREATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: [].concat(
              action.payload,
              resource ? resource.mediaObjects : []
            ),
          },
        },
      };

    // Update the selected media object in the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...resource,
            mediaObjects: resource.mediaObjects.map(updateMediaObject),
          },
        },
      };

    // Remove selected media object from the associated resource (objectId)
    case RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.meta.objectId]: {
            ...state.list[action.meta.objectId],
            mediaObjects: state.list[action.meta.objectId].mediaObjects.filter(
              x => x.mediaObjectId !== action.payload
            ),
          },
        },
      };

    default:
      return state;
  }
};

const sortData = state => {
  const listIds = state.listIds
    .concat()
    .map(x => state.list[x])
    .sort(sort(normalizeSortDirection(state.sortDirection), state.sortBy));

  return listIds || [];
};

const sortData2 = state => {
  console.log('State', state);
  const listIds = state.listIds.concat();
  console.log('List IDs', listIds);
  const pass2 = listIds.map(x => state.list[x]);
  console.log('Pass2', pass2);
  const pass3 = pass2.sort(sort(normalizeSortDirection(), state.sortBy));
  console.log('Pass3', pass3);

  const pass4 = getKeys('immunizationId', pass3);
  console.log('Pass4', pass4);

  return [];
};

const sortIds = state => getKeys('immunizationId', sortData(state));

const reducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case IMMUNIZATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasFetchedImmunizations: false,
        activeUser: state.activeUser,
        uiState: LOADING,
      };

    case IMMUNIZATION_SUCCESS: {
      const list = arrayToHashMap('immunizationId', payload || []);
      const listIds = sortIds({
        ...state,
        list,
        listIds: getKeys('immunizationId', payload),
      });

      return action.meta.pid === state.activeUser
        ? {
            ...state,
            isFetching: false,
            hasFetchedImmunizations: true,
            list,
            listIds,
            uiState: listIds.length ? SUCCESS : EMPTY,
          }
        : initialState;
    }
    case IMMUNIZATION_FAILURE:
      return {
        ...state,
        list: {},
        listIds: [],
        isFetching: true,
        hasFetchedImmunizations: false,
        errorMessage: 'Failed to fetch immunization records',
        uiState: ERROR,
      };

    case IMMUNIZATION_CREATE:
      return {
        ...state,
        errorMessage: undefined,
        isSubmitting: true,
      };

    case IMMUNIZATION_CREATE_SUCCESS: {
      const resourceMediaObjects =
        state.list[action.payload.immunizationId] &&
        state.list[action.payload.immunizationId].mediaObjects
          ? state.list[action.payload.immunizationId].mediaObjects
          : [];

      const list = {
        ...state.list,
        [action.payload.immunizationId]: {
          ...action.payload,
          mediaObjects: resourceMediaObjects,
        },
      };

      const listIds = sortIds({
        ...state,
        list,
        listIds: [action.payload.immunizationId, ...state.listIds],
      });

      console.log('FFS List IDS?!', listIds);

      return {
        ...state,
        errorMessage: undefined,
        didInvalidate: true,
        isSubmitting: false,
        uploadingMediaObjects: false,
        list,
        listIds,
        uiState: SUCCESS,
      };
    }

    case IMMUNIZATION_CREATE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSubmitting: false,
      };

    case IMMUNIZATION_UPDATE:
      return {
        ...state,
        errorMessage: undefined,
        isSubmitting: true,
      };

    case IMMUNIZATION_UPDATE_SUCCESS: {
      const _resourceMediaObjects = state.list[action.payload.immunizationId]
        .mediaObjects
        ? state.list[action.payload.immunizationId].mediaObjects
        : [];

      const list = {
        ...state.list,
        [action.payload.immunizationId]: {
          ...action.payload,
          mediaObjects: Object.keys(action.bundles).length
            ? _resourceMediaObjects.map(x => {
                if (action.bundles[x.mediaObjectId])
                  return {
                    ...x,
                    ...action.bundles[x.mediaObjectId],
                  };
                else return x;
              })
            : _resourceMediaObjects,
        },
      };

      const listIds = sortIds({
        ...state,
        list,
        listIds: state.listIds,
      });

      return {
        ...state,
        errorMessage: undefined,
        didInvalidate: true,
        isSubmitting: false,
        uploadingMediaObjects: false,
        list,
        listIds,
        bundlesToDelete: [],
      };
    }

    case IMMUNIZATION_UPDATE_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isSubmitting: false,
        bundlesToDelete: [],
      };

    case IMMUNIZATION_DELETE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload]: {
            ...state.list[action.payload],
            isDeleting: true,
          },
        },
      };

    case IMMUNIZATION_DELETE_SUCCESS:
      let immId = action.payload.immunizationId;
      state.listIds = state.listIds.filter(idx => idx !== immId);
      delete state.list[immId];
      return {
        ...state,
        didInvalidate: true,
        uiState: state.listIds.length ? SUCCESS : EMPTY,
        deleting: [
          ...state.deleting.filter(id => id !== action.payload.immunizationId),
        ],
      };

    case IMMUNIZATION_DELETE_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.immunizationId]: {
            ...state.list[action.payload.immunizationId],
            isDeleting: false,
          },
        },
      };

    case UI_CANCELED_FORM: {
      return {
        ...state,
        errorMessage: undefined,
      };
    }

    case IMMUNIZATION_SELECT_ID:
      return {
        ...state,
        mediaObjects: {},
        selectedId: action.payload,
      };

    case IMMUNIZATION_CLEAR_ID:
      return {
        ...state,
        mediaObjects: {},
        selectedId: null,
        bundlesToDelete: [],
      };

    // case :
    // const selectedMediaObjects = state.list[action.payload.immunizationId].mediaObjects
    // ? state.list[action.payload.immunizationId].mediaObjects : [];
    // console.log(selectedMediaObjects, action.payload.bundle)
    // return {
    //   ...state,
    //   list: {
    //     ...state.list,
    //     [action.payload.immunizationId]: {
    //       ...state.list[action.payload.immunizationId],
    //       mediaObjects: [
    //         ...selectedMediaObjects,
    //         ...action.payload.bundle
    //       ]
    //     }
    //   }
    // }

    case SORT_BY:
      const listIds = getKeys(
        'immunizationId',
        sortData({
          ...state,
          sortBy: payload.field,
          sortDirection: payload.direction,
        })
      );

      return {
        ...state,
        listIds,
        sortBy: payload.field,
        sortDirection: payload.direction,
      };

    case SET_ACTIVE_USER: {
      const currentPersonId = action.payload.personId;
      const currentState =
        state.activeUser === currentPersonId
          ? state
          : {
              ...initialState,
              activeUser: action.payload.personId,
              hasFetchedImmunizations: state.hasFetchedImmunizations,
            };
      return {
        ...currentState,
      };
    }

    case UPDATE_IMMUNIZATION_MEDIA:
    case RESOURCE_MEDIA_INFO_UPDATED: {
      const mediaObjects = state.mediaObjects;
      if (Boolean(action.payload.formName)) {
        mediaObjects[action.payload.formName] = action.payload.pristine;
      }
      return {
        ...state,
        mediaObjects,
      };
    }

    case IMMUNIZATION_UPDATE_MEDIA: {
      const resourceMediaObjects = state.list[
        state.selectedId
      ].mediaObjects.map(obj => {
        if (action.payload.mediaObjectId === obj.mediaObjectId) {
          return {
            ...obj,
            ...action.payload,
          };
        } else return obj;
      });
      return {
        ...state,
        list: {
          ...state.list,
          [state.selectedId]: {
            ...state.list[state.selectedId],
            mediaObjects: resourceMediaObjects,
          },
        },
      };
    }

    case MEDIA_OBJECT_UPLOAD_REQUEST: {
      return {
        ...state,
        uploadingMediaObjects: true,
      };
    }

    case IMMUNIZATION_REMOVE_MEDIA: {
      const mediaObjects = {
        ...mediaObjects,
      };
      delete mediaObjects[`bundle${action.payload}`];
      return {
        ...state,
        mediaObjects,
      };
    }

    case RESOURCE_MEDIA_MODIFIED:
      return reducers[RESOURCE_MEDIA_MODIFIED](state, action);

    case IMMUNIZATION_MEDIA_TO_DELETE:
      const _bundlesToDelete = Boolean(
        state.bundlesToDelete.find(
          item => item.mediaObjectId === action.payload.mediaObjectId
        )
      )
        ? [
            ...state.bundlesToDelete.map(item => {
              if (item.mediaObjectId === action.payload.mediaObjectId) {
                return action.payload;
              }
              return item;
            }),
          ]
        : [...state.bundlesToDelete, action.payload];

      return {
        ...state,
        bundlesToDelete: _bundlesToDelete,
      };

    case IMMUNIZATION_MEDIA_TO_DELETE_CANCEL:
      return {
        ...state,
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            item => item.mediaObjectId !== action.payload
          ),
        ],
      };

    case MEDIA_REMOVE_SUCCESS: {
      return {
        ...state,
        bundlesToDelete: [
          ...state.bundlesToDelete.filter(
            item => item.mediaObjectId !== action.payload
          ),
        ],
        list: {
          ...state.list,
          [state.selectedId]: {
            ...state.list[state.selectedId],
            mediaObjects: state.list[state.selectedId].mediaObjects.filter(
              item => item.mediaObjectId !== action.payload
            ),
          },
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
