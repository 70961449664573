import reducer from './reducers';
import {
  fetchBloodType,
  fetchContactType,
  fetchDocumentType,
  fetchEthnicityType,
  fetchGenderType,
  fetchLanguageType,
  fetchMaritalType,
  fetchReligionType,
  fetchStateType,
  fetchVeteranType,
  fetchVaccinationType,
} from './actions';

export default reducer;

export {
  fetchBloodType,
  fetchContactType,
  fetchDocumentType,
  fetchEthnicityType,
  fetchGenderType,
  fetchLanguageType,
  fetchMaritalType,
  fetchReligionType,
  fetchStateType,
  fetchVeteranType,
  fetchVaccinationType,
};
