import React from 'react';
import { Grid, Box, Icon, withStyles, Tooltip, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { formatCurrency } from '../../../../library/helpers/money';
import MoneyBackGuarantee from '../../../../client/images/MoneyBackGuarantee.png';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

import cn from 'classnames';
import xclasses from './Product.css';

const styles = theme => ({
  primaryButton: {
    color: '#fff',
  },
  product: {
    display: 'border-box',
    width: '100%',
    padding: 20,
    border: '2px solid #CECECE',
    borderRadius: 6,
    cursor: 'pointer',
    marginBottom: 24,
  },
  onSaleMessage: {},
  productActive: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  root: {
    width: '100%',
  },
  img: {
    width: '100%',
    objectPosition: 'center',
    maxHeight: 95,
    objectFit: 'contain',
    marginRight: 16,
  },
  upperContainer: {
    marginBottom: 12,
  },
  header: {
    margin: 0,
    fontSize: 21,
  },
  featureContainer: {
    width: `calc(100% / 3 - 20px)`,
    marginRight: 20,
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 22,
    '&:before': {
      display: 'block',
      content: 'close-quote',
      width: 6,
      height: 3,
      marginRight: 8,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    fontSize: 18,
    marginLeft: 6,
  },
  label: {
    fontSize: 14,
    color: '#75747B',
  },
  labelContainer: {
    marginBottom: 4,
    marginRight: 24,
  },
  value: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  saleIcon: {
    color: '#fc8a00',
    fontSize: '21px',
    marginRight: '2.7px',
  },
  saleAlertContainer: {
    height: 'auto',
    width: '100%',
    backgroundColor: '#FFE164',
  },
  saleAlert: {
    transform: 'scale(0.9)',
    padding: '0px',
    backgroundColor: '#FFE164',
  },
  saleAlertMsg: {
    color: '#fc8a00',
    textAlign: 'center',
    fontSize: '20px',
  },
  guaranteeContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saleIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

function normalizeCurrency(number, fixed = 2) {
  return Number(number)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
}

function getCurrencyParts(text) {
  text = String(text);
  let symbol,
    digits,
    decimal,
    decimalPos = -1;

  const charCode = text.charCodeAt(0);
  if (!(charCode >= 48 && charCode <= 57)) {
    symbol = text[0];
  }

  decimalPos = text.indexOf('.');
  if (~decimalPos) {
    decimal = text.substring(decimalPos + 1);
  }

  const parts = ~decimalPos
    ? text.substring(symbol ? 1 : 0, decimalPos)
    : text.substring(1);

  digits = parts.split(',');
  return {
    symbol,
    digits,
    decimal,
  };
}

const formatPaymentInterval = interval => {
  const normalizedInterval = interval.toLowerCase();

  if (!interval || normalizedInterval === 'free') return 'year';

  return `${normalizedInterval}`;
};

const Product = ({
  onSelect,
  classes,
  status,
  data,
  disableUnselectedStyles = false,
  elevation = 0,
}) => {
  let price = getCurrencyParts(normalizeCurrency(data.price ? data.price : 0));

  return (
    <Paper
      item
      elevation={elevation}
      container
      className={cn(xclasses.planSummary, {
        [xclasses.selected]: status === 'SELECTED',
        [xclasses.unselected]:
          status === 'UNSELECTED' && !disableUnselectedStyles,
        [xclasses.paper]: elevation > 0,
      })}
      onClick={() => onSelect(data)}
    >
      <div className={xclasses.planHeader}>{data.name}</div>
      <div item className={xclasses.planPrice}>
        <div></div>
        <div>
          <div className={xclasses.priceContainer}>
            <div className={cn(xclasses.priceContainer, xclasses.sign)}>$</div>
            <div className={cn(xclasses.priceContainer, xclasses.amount)}>
              {data.securityFee ? 0 : price.digits.join(',')}
            </div>
            <div className={cn(xclasses.priceContainer, xclasses.decimal)}>
              {data.securityFee ? null : price.decimal}
            </div>
            <div className={cn(xclasses.priceContainer, xclasses.month)}>
              /{formatPaymentInterval(data.paymentInterval)}
            </div>
          </div>
          <div className={cn(xclasses.priceContainer, xclasses.extra)}>
            <span>
              {data.securityFee ? (
                <span>
                  <strong>Free </strong>forever*
                </span>
              ) : (
                <span>Share with anyone</span>
              )}
            </span>
          </div>
        </div>
      </div>
      {!data.securityFee && (
        <div className={classes.saleAlertContainer}>
          <Alert
            severity="warning"
            iconMapping={{
              warning: (
                <div className={classes.saleAlert}>
                  <LoyaltyIcon className={classes.saleIcon} />
                </div>
              ),
            }}
            className={classes.saleAlert}
          >
            <div className={classes.saleAlertMsg}>On Sale Now</div>
          </Alert>
        </div>
      )}
      <div item className={xclasses.planBody}>
        <ul className={cn(xclasses.list, xclasses.checkmark)}>
          {data.name === 'Shareable' && (
            <li className={cn(xclasses.listHeader)}>
              All the features of Basic plus:
            </li>
          )}
          {data.features.map(text => (
            <li key={text}>{text}</li>
          ))}
          {data.name === 'Shareable' && (
            <Tooltip title="If you are not completely satisfied, simply contact us within 30 days of purchase to receive 100% of your paid subscription fee back">
              <div className={classes.guaranteeContainer}>
                <img
                  src={MoneyBackGuarantee}
                  alt="Money Back Guarantee"
                  height="120"
                />
              </div>
            </Tooltip>
          )}
        </ul>
      </div>
      {/* <Grid item className={xclasses.securityFee} xs={12}>
          <strong>*One-time</strong>{' '}
          {data.securityFee ? formatCurrency(data.securityFee) : 'Included'}
          &#32;{} Security fee applies
        </Grid> */}
    </Paper>
  );
};

const ProductOld = ({ onSelect, classes, selected, data }) => (
  <Grid
    onClick={() => onSelect(data)}
    justify="flex-start"
    container
    className={`${classes.product} ${selected && classes.productActive}`}
  >
    <Grid item xs={2}>
      <img src={data.image} className={classes.img} />
    </Grid>
    <Grid item xs={10} className={classes.upperContainer}>
      <div style={{ marginLeft: 24 }}>
        <Grid container>
          <h4 className={classes.header}>{data.name}</h4>
          <p style={{ marginTop: 4 }}>{data.description}</p>
          <small>{data.disclaimer}</small>
        </Grid>
        <Grid container alignItems="center">
          <Grid>
            <Grid
              container
              alignItems="center"
              className={classes.labelContainer}
            >
              <div className={classes.label}>{data.paymentInterval}</div>
            </Grid>

            <div className={classes.value}>
              {data.paymentInterval === 'Free'
                ? 'Free'
                : formatCurrency(data.price)}
            </div>
          </Grid>
          <Grid>
            <Grid
              container
              alignItems="center"
              className={classes.labelContainer}
            >
              <div className={classes.label}>Security Fee</div>
              <Icon className={classes.icon}>info</Icon>
            </Grid>
            <div className={classes.value}>
              {data.securityFee ? formatCurrency(data.securityFee) : 'Included'}
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
    <Grid container>
      {data.features.map(text => (
        <Grid key={text} className={classes.featureContainer}>
          <div className={classes.feature}>{text}</div>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Product);
