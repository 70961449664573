import React from 'react';

import { Grid, Button, Typography, makeStyles } from '@material-ui/core';

import ClearAllIcon from '@material-ui/icons/ClearAll';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: 'solid 1px #eee',
    padding: theme.spacing(1),
  },
}));

const NotificationModalHeader = ({ onClear }) => {
  const classes = useStyles();
  return (
    <Grid item container className={classes.root}>
      <Grid item xs={6}>
        <Typography
          style={{ padding: '4px 5px', fontSize: '0.8125rem' }}
          variant="button"
          display="block"
        >
          <strong>Notifications</strong>
        </Typography>
      </Grid>
      <Grid item container xs={6} justify="flex-end">
        <Grid item>
          <Button size="small" startIcon={<ClearAllIcon />} onClick={onClear}>
            Clear All
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationModalHeader;
