import React, { useState, useEffect } from 'react';
import { Grid, Paper, Container, Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getAvailableProducts,
  registrationCheckout,
} from '../../../pages/MemberRegistrationPage/MemberRegistrationPage';
import CheckoutComponent from './CheckoutComponent';
import SubscriptionOptions from './SubscriptionOptions';
import SubscriptionModal from './SubscriptionModal';
import DeleteAccount from './DeleteAccount';

import {
  DOWNGRADE,
  UPGRADE,
  SUBSIDIZED,
  CLOSE_ACCOUNT,
  CLOSE_WARNING,
  CLOSE_WARNING_SUBSIDIZED,
  DOWNGRADE_SUBSIDIZED,
  GENERAL_FAILURE_MESSAGE,
  ModalData,
} from './data';

import {
  subscriptionChangeRequest,
  SUBSCRIPTION_CHANGE_SUCCESS,
  SUBSCRIPTION_CHANGE_FAILURE,
} from '../../../../store/reducers/subscription';

const SubscriptionOptionsView = ({
  accountRole,
  handleError,
  handleSuccess,
  onViewChange,
  subscriptionChangeRequest,
  isSubsidized,
}) => {
  const [hasFetched, setHasFetched] = useState(false);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState(null);
  const [checkout, setCheckout] = useState({
    isRequesting: false,
    failureMessage: null,
    success: false,
    isSubmitting: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const onCheckout = async values => {
    if (!checkout.isSubmitting) {
      setCheckout({ isSubmitting: true });
      const result = await subscriptionChangeRequest(values);
      if (result.type === SUBSCRIPTION_CHANGE_SUCCESS) {
        clearModalState();
        setCheckout({ ...checkout, success: true });
        onViewChange('subscription_view');
        handleSuccess(result.payload.subscription.accountRole);
        return;
      } else if (result.type === SUBSCRIPTION_CHANGE_FAILURE) {
        if (result.payload.status === 400 && Boolean(result.payload.message)) {
          setCheckout({
            isSubmitting: false,
            failureMessage: result.payload.message,
          });
          return;
        } else {
          setCheckout({
            isSubmitting: false,
            failureMessage: GENERAL_FAILURE_MESSAGE,
          });
          clearModalState();
          handleError(GENERAL_FAILURE_MESSAGE);
        }
      }
    }
  };

  const onDowngrade = () => {
    onCheckout({
      productId: productData.productId,
    });
  };

  const clearModalState = () => {
    setIsModalOpen(false);
    setProductData(null);
    setModalMessage({});
  };

  const handleActionClick = type => {
    switch (type) {
      case DOWNGRADE:
        onDowngrade();
        break;
        {
          /*case CLOSE_ACCOUNT:
        if (isSubsidized) {
          handleCloseAccount
        } else {
          handleCloseAccount
        }
        console.log(CLOSE_ACCOUNT);
          handleCloseAccount
        break;
      */
        }
      default:
        return;
    }
  };

  const selectProduct = (type, data = {}) => {
    console.log(type);
    switch (type) {
      case UPGRADE:
        setProductData(data);
        break;
      case DOWNGRADE:
        if (isSubsidized) {
          setModalMessage(ModalData[DOWNGRADE_SUBSIDIZED]);
          setIsModalOpen(true);
        } else {
          setProductData(data);
          setModalMessage(ModalData[DOWNGRADE]);
          setIsModalOpen(true);
        }
        break;
      case CLOSE_ACCOUNT:
        if (isSubsidized) {
          setModalMessage(ModalData[CLOSE_WARNING_SUBSIDIZED]);
          setIsModalOpen(true);
        } else {
          setModalMessage(ModalData[CLOSE_WARNING]);
          setIsModalOpen(true);
          //close modal
        }
        break;
      default:
        return;
    }
  };

  const fetchAvailableProducts = async () => {
    const data = await getAvailableProducts();
    setProducts(data);
  };

  useEffect(() => {
    fetchAvailableProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      console.log(products);
      setHasFetched(true);
    }
  }, [products]);

  useEffect(() => {
    if (hasFetched && products.length === 0) {
      handleError('Something went wrong, we are working on it');
    }
  }, [hasFetched]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Paper style={{ width: '100%', maxWidth: '1000px' }} elevation={3} square>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubscriptionOptions
              products={products}
              hasFetched={hasFetched}
              onSelect={selectProduct}
              accountRole={accountRole}
              productData={productData}
            />
          </Grid>
          {hasFetched && (
            <Grid item xs={12}>
              <DeleteAccount onClick={selectProduct} />
            </Grid>
          )}
        </Grid>
      </Paper>
      <CheckoutComponent
        productData={productData}
        fetchAvailableProducts={fetchAvailableProducts}
        checkout={checkout}
        onCheckout={onCheckout}
        onClose={() => setProductData(null)}
        products={products}
      />
      <SubscriptionModal
        {...modalMessage}
        open={isModalOpen}
        isSubmitting={checkout.isSubmitting}
        onClick={handleActionClick}
        clearMessage={() => setModalMessage({})}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  subscriptionChangeRequest: values =>
    dispatch(subscriptionChangeRequest(values)),
});

export default connect(null, mapDispatchToProps)(SubscriptionOptionsView);
