export const styles = () => ({
  accordionWrapper: {
    borderBottom: 'solid 1px #ddd',
    marginBottom: '30px',
  },
  accordionPanelHeader: {
    position: 'relative',
    display: 'block',
    fontSize: '110%',
    borderLeft: 'solid 1px #ddd',
    borderRight: 'solid 1px #ddd',
    borderTop: 'solid 1px #ddd',
    padding: '10px 10px 10px 28px',
    color: '#000',
    '&:hover': {
      background: '#f5f5f5',
      textDecoration: 'none',
    },
    '&:before': {
      position: 'absolute',
      content: '',
      display: 'inline-block',
      marginRight: '5px',
      fontSize: '110%',
      width: '0px',
      height: '0px',
      textAlign: 'center',
      top: '15px',
      left: '10px',
      borderTop: 'solid 5px transparent',
      borderLeft: 'solid 8px #000',
      borderBottom: 'solid 5px transparent',
    },
  },
  accordionPanelHeaderActive: {
    background: '#f5f5f5',
    color: '#006ab0',
    '&:before': {
      position: 'absolute',
      marginRight: '5px',
      fontSize: '110%',
      width: '0px',
      height: '0px',
      borderLeft: 'solid 5px transparent',
      borderRight: 'solid 5px transparent',
      borderTop: 'solid 8px #006ab0',
      top: '17px',
    },
  },
  accordionPanelHeaderInactive: {
    color: '#000',
  },
  accordionPanelBody: {
    background: '#f5f5f5',
    padding: '10px 10px 10px 10px',
    paddingRight: '0',
    borderLeft: 'solid 1px #ddd',
    borderRight: 'solid 1px #ddd',
  },
});
