import { sortEnsureTop, sortByField } from '../../../library/helpers/sort';
import { slug } from '../../../library/helpers/string';

import {
  DEPENDENT_CREATE_SUCCESS,
  DEPENDENT_SELECT_REQUEST,
  DEPENDENT_SELECT_SUCCESS,
} from './action';

import { PERSON_INFO_UPDATE_SUCCESS } from '../person/actions';

import {
  APP_INITIAL_SESSION_REQUEST,
  APP_INITIAL_SESSION_SUCCESS,
} from '../app/actions';

const dependentIsSelected = dependent => dependent.isSelected;

const tabRecord = id => dependent => {
  const fullName = `${dependent.firstName} ${
    dependent.lastName
  } ${dependent.suffix ?? ''}`.trim();

  return {
    personId: dependent.personId,
    label: fullName,
    firstName: dependent.firstName,
    lastName: dependent.lastName,
    slug: slug(fullName),
    gender: dependent.gender,
    dob: dependent.dob,
    height: dependent.height,
    heightUnit: dependent.heightUnit,
    type: dependent.personId === id ? 'primary' : 'dependent',
  };
};

const initialState = {
  isInvalid: false,
  isFetching: false,
  records: [],
  tabs: [],
};

const reducers = [];

reducers[APP_INITIAL_SESSION_REQUEST] = state => ({
  ...state,
  isFetching: true,
});

reducers[APP_INITIAL_SESSION_SUCCESS] = (state, action) => {
  const {
    familyMembers,
    personId,
    firstName,
    lastName,
    dbo,
    gender,
    height,
    heightUnit,
  } = action.payload;

  const meTab = {
    personId: personId,
    label: 'Me',
    firstName: firstName,
    lastName: lastName,
    slug: `me`,
    gender,
    dbo,
    height: height || 0,
    heightUnit,
    type: 'primary',
  };

  const tabs = familyMembers
    .filter(dependentIsSelected)
    .map(tabRecord(personId))
    .concat(meTab)
    .sort(sortEnsureTop({ label: 'me' }));

  return {
    ...state,
    records: familyMembers,
    tabs,
    isFetching: false,
  };
};

/**
 * Note: it's not clear where but a mutation of state.records is performed.
 * before this logic can finalized. As a result, [index].isSelected is already
 * changed the the expected value. Before the negation of (![index].isSelected)
 * would flip the value to match the user select option, but before the change,
 * would just reverse the user operation.
 *
 * Because of this strange mutation of data, techincally, this code could be
 * removed. However, will preserve for now with hope to prevent this unwanted
 * mutation.
 */
reducers[DEPENDENT_SELECT_REQUEST] = (state, action) => {
  const { personId, memberId } = action.meta;

  const found = state.tabs.find(x => x.personId === memberId);
  const dependent = state.records.find(x => x.personId === memberId);
  const tabs = found
    ? state.tabs.filter(x => x.personId !== memberId)
    : state.tabs
        .concat(tabRecord(personId)(dependent))
        .sort(sortEnsureTop({ label: 'me' }));

  const foundRecordIndex = state.records.findIndex(
    x => x.personId === memberId
  );
  const replaceIndex = foundRecordIndex + 1;

  const replacement = {
    ...state.records[foundRecordIndex],
    // Note: used to be !state.records[foundRecordIndex].isSelected -- see comment above
    isSelected: state.records[foundRecordIndex].isSelected,
  };

  return {
    ...state,
    tabs,
    records: [
      ...state.records.slice(0, replaceIndex - 1),
      replacement,
      ...state.records.slice(replaceIndex),
    ],
  };
};

reducers[DEPENDENT_CREATE_SUCCESS] = (state, action) => {
  const data = action.payload;
  const dob = JSON.parse(action.meta.config.body).dob;

  const newDependent = {
    personId: data.personId,
    firstName: data.firstName,
    lastName: data.lastName,
    avatarUrl: '',
    dob: dob ? dob : '',
    isSelected: true,
    type: data.type,
  };

  return {
    ...state,
    records: state.records
      .slice()
      .concat(newDependent)
      .sort(sortByField('firstName')),
    tabs: state.tabs
      .slice()
      .concat(tabRecord(action.meta.pid)(newDependent))
      .sort(sortEnsureTop({ label: 'me' })),
  };
};

reducers[PERSON_INFO_UPDATE_SUCCESS] = (state, action) => {
  const { personId, firstName, lastName } = action.payload;

  const tabIndex = state.tabs.findIndex(x => x.personId === personId) + 1;

  // TODO - refactor this code. "" is used to force the "dependent" type
  const replacement = tabRecord('')({
    personId,
    firstName,
    lastName,
  });

  return {
    ...state,
    tabs: [
      ...state.tabs.slice(0, tabIndex - 1),
      replacement,
      ...state.tabs.slice(tabIndex),
    ],
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
