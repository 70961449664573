import React from 'react';
const SyncPermissionsSVG = () => (
  <div style={{ maxWidth: '650px', margin: 'auto' }}>
    <svg
      width="100%"
      height="auto"
      viewBox="0 0 745 503"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M677.534 256.53C677.534 380.704 552.316 481.542 397.612 481.542C242.909 481.542 117.691 380.704 117.691 256.53C117.691 132.356 242.909 31.5186 397.612 31.5186C552.316 31.5186 677.534 132.356 677.534 256.53Z"
        fill="#E0E0E0"
        stroke="#E8F5FF"
      />
      <rect
        x="55.98"
        y="261.126"
        width="397.728"
        height="209.613"
        rx="17.5"
        fill="black"
        stroke="#D6DBE6"
        stroke-width="5"
      />
      <rect
        x="68.8264"
        y="271.62"
        width="372.035"
        height="188.625"
        fill="#0068A8"
      />
      <rect
        x="16.7416"
        y="469.467"
        width="476.205"
        height="7.545"
        fill="#C4C4C4"
      />
      <rect
        x="22.7871"
        y="474.497"
        width="465.044"
        height="7.545"
        rx="3.7725"
        fill="#C4C4C4"
      />
      <g filter="url(#filter0_f)">
        <rect
          x="27.4376"
          y="469.467"
          width="26.0424"
          height="12.9942"
          fill="#AFB3BD"
        />
      </g>
      <g filter="url(#filter1_f)">
        <rect
          x="456.208"
          y="470.305"
          width="26.0424"
          height="12.1558"
          fill="#AFB3BD"
        />
      </g>
      <g filter="url(#filter2_f)">
        <line
          x1="27.4376"
          y1="481.461"
          x2="482.25"
          y2="481.461"
          stroke="black"
          stroke-width="2"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M254.844 331.736V337.988C271.973 337.988 285.847 350.493 285.847 365.933C285.847 369.566 285.072 373.059 283.638 376.237C282.591 378.578 279.259 379.206 277.282 377.425C276.236 376.482 275.809 375.05 276.391 373.792C277.515 371.382 278.096 368.692 278.096 365.933C278.096 354.371 267.671 344.974 254.844 344.974V351.227C254.844 352.799 252.751 353.567 251.511 352.485L240.699 342.739C239.924 342.04 239.924 340.957 240.699 340.259L251.55 330.513C252.751 329.395 254.844 330.164 254.844 331.736ZM231.592 365.933C231.592 377.495 242.017 386.892 254.844 386.892V380.639C254.844 379.067 256.937 378.299 258.138 379.382L268.95 389.127C269.725 389.826 269.725 390.909 268.95 391.607L258.138 401.353C256.937 402.471 254.844 401.702 254.844 400.13V393.878C237.715 393.878 223.841 381.373 223.841 365.933C223.841 362.301 224.616 358.808 226.05 355.629C227.096 353.289 230.429 352.66 232.406 354.441C233.452 355.384 233.878 356.817 233.297 358.074C232.173 360.484 231.592 363.174 231.592 365.933Z"
        fill="white"
      />
      <rect
        x="568.923"
        y="278.312"
        width="91.7291"
        height="158.894"
        rx="17.5"
        fill="black"
        stroke="#D6DBE6"
        stroke-width="5"
      />
      <rect
        x="575.724"
        y="290.482"
        width="78.1273"
        height="119.882"
        fill="#0068A8"
      />
      <ellipse
        cx="614.788"
        cy="424.197"
        rx="7.90574"
        ry="7.12583"
        fill="#2A2937"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M614.788 333.325V336.451C623.352 336.451 630.289 342.704 630.289 350.423C630.289 352.24 629.902 353.986 629.185 355.575C628.662 356.746 626.995 357.06 626.007 356.169C625.484 355.698 625.271 354.982 625.561 354.353C626.123 353.148 626.414 351.803 626.414 350.423C626.414 344.642 621.201 339.944 614.788 339.944V343.07C614.788 343.856 613.741 344.24 613.121 343.699L607.715 338.826C607.328 338.477 607.328 337.936 607.715 337.586L613.141 332.713C613.741 332.155 614.788 332.539 614.788 333.325ZM603.162 350.423C603.162 356.205 608.374 360.903 614.788 360.903V357.776C614.788 356.99 615.834 356.606 616.435 357.148L621.841 362.02C622.228 362.37 622.228 362.911 621.841 363.26L616.435 368.133C615.834 368.692 614.788 368.308 614.788 367.522V364.396C606.223 364.396 599.286 358.143 599.286 350.423C599.286 348.607 599.674 346.861 600.391 345.271C600.914 344.101 602.58 343.787 603.569 344.677C604.092 345.149 604.305 345.865 604.014 346.494C603.452 347.699 603.162 349.044 603.162 350.423Z"
        fill="white"
      />
      <ellipse
        cx="536.195"
        cy="108.145"
        rx="84.638"
        ry="72.0967"
        fill="#0068A8"
      />
      <ellipse
        cx="607.812"
        cy="159.283"
        rx="58.1305"
        ry="51.1383"
        fill="#0068A8"
      />
      <ellipse cx="429.468" cy="120.72" rx="59.2931" ry="50.3" fill="#0068A8" />
      <ellipse
        cx="370.175"
        cy="161.379"
        rx="60.9207"
        ry="49.0425"
        fill="#0068A8"
      />
      <rect
        x="343.202"
        y="129.942"
        width="291.117"
        height="80.48"
        rx="40.24"
        fill="#0068A8"
      />
      <path
        d="M409.238 187.062H494.341"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M468.17 97.6587H418.882C417.754 97.6587 416.841 98.6459 416.841 99.8638V184.656C416.841 185.874 417.754 186.861 418.882 186.861H477.791C478.918 186.861 479.832 185.874 479.832 184.656V110.257L468.17 97.6587Z"
        fill="#99D7E9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M468.17 97.6587H418.882C417.754 97.6587 416.841 98.6459 416.841 99.8638V184.656C416.841 185.874 417.754 186.861 418.882 186.861H477.791C478.918 186.861 479.832 185.874 479.832 184.656V110.257L468.17 97.6587Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.856 97.6587H421.568C420.441 97.6587 419.527 98.6459 419.527 99.8638V184.656C419.527 185.874 420.441 186.861 421.568 186.861H480.477C481.604 186.861 482.518 185.874 482.518 184.656V110.257L470.856 97.6587Z"
        fill="#C0E4EE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.856 97.6587H421.568C420.441 97.6587 419.527 98.6459 419.527 99.8638V184.656C419.527 185.874 420.441 186.861 421.568 186.861H480.477C481.604 186.861 482.518 185.874 482.518 184.656V110.257L470.856 97.6587Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M473.542 97.6587H424.254C423.126 97.6587 422.213 98.6459 422.213 99.8638V184.656C422.213 185.874 423.126 186.861 424.254 186.861H483.163C484.29 186.861 485.204 185.874 485.204 184.656V110.257L473.542 97.6587Z"
        fill="#E6F5F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M475.236 100.778H442.418C441.667 100.778 441.059 101.395 441.059 102.157V155.187C441.059 155.949 441.667 156.566 442.418 156.566H481.643C482.393 156.566 483.002 155.949 483.002 155.187V108.657L475.236 100.778Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M425.959 184.037C424.831 184.037 423.917 183.05 423.917 181.832V97.6958C422.951 97.8699 422.213 98.7708 422.213 99.8642V184.656C422.213 185.874 423.126 186.861 424.254 186.861H483.163C484.29 186.861 485.204 185.874 485.204 184.656V184C485.094 184.02 484.983 184.037 484.868 184.037H425.959Z"
        fill="#C4DCE9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M485.204 111.702H475C473.456 111.702 472.205 110.35 472.205 108.682V97.6592H473.542L485.204 110.258V111.702Z"
        fill="#DAF6FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M473.542 97.6587H424.254C423.126 97.6587 422.213 98.6459 422.213 99.8638V184.656C422.213 185.874 423.126 186.861 424.254 186.861H483.163C484.29 186.861 485.204 185.874 485.204 184.656V110.257L473.542 97.6587Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M430.226 96.4774C430.226 95.5131 430.69 94.7314 431.582 94.7314C432.475 94.7314 432.938 95.5131 432.938 96.4774V104.743C432.938 105.707 432.214 106.489 431.322 106.489C430.429 106.489 429.706 105.707 429.706 104.743V98.5726"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M441.531 125.568H432.513C431.856 125.568 431.322 124.991 431.322 124.28V113.808C431.322 113.097 431.856 112.521 432.513 112.521H441.531C442.189 112.521 442.723 113.097 442.723 113.808V124.28C442.723 124.991 442.189 125.568 441.531 125.568Z"
        fill="#FFD46B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M441.531 125.568H432.513C431.856 125.568 431.322 124.991 431.322 124.28V113.808C431.322 113.097 431.856 112.521 432.513 112.521H441.531C442.189 112.521 442.723 113.097 442.723 113.808V124.28C442.723 124.991 442.189 125.568 441.531 125.568Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M474.326 156.567H432.735C431.955 156.567 431.322 155.883 431.322 155.04V139.005C431.322 138.162 431.955 137.479 432.735 137.479H474.326C475.107 137.479 475.739 138.162 475.739 139.005V155.04C475.739 155.883 475.107 156.567 474.326 156.567Z"
        fill="#9AFFDB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M474.326 156.567H432.735C431.955 156.567 431.322 155.883 431.322 155.04V139.005C431.322 138.162 431.955 137.479 432.735 137.479H474.326C475.107 137.479 475.739 138.162 475.739 139.005V155.04C475.739 155.883 475.107 156.567 474.326 156.567Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M474.326 156.567H432.735C431.955 156.567 431.322 155.883 431.322 155.04V139.005C431.322 138.162 431.955 137.479 432.735 137.479H474.326C475.107 137.479 475.739 138.162 475.739 139.005V155.04C475.739 155.883 475.107 156.567 474.326 156.567Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M446.752 112.521H472.461"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M446.752 118.739H475.147"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M446.752 124.75H464.211"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M431.322 130.563H475.147"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M435.432 164.129H438.694"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M445.409 164.129H466.321"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M435.432 170.347H438.694"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M445.409 170.347H475.147"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M435.432 176.357H438.694"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.142 150.809C460.66 145.052 456.423 147.849 451.816 147.849C447.208 147.849 445.933 140.875 440.082 140.875C434.232 140.875 431.322 147.849 431.322 147.849V150.809H475.74"
        fill="#00D9AA"
      />
      <path
        d="M445.409 176.357H468.24"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M470.142 150.809C460.66 145.052 456.423 147.849 451.816 147.849C447.208 147.849 445.933 140.875 440.082 140.875C434.232 140.875 431.322 147.849 431.322 147.849V150.809H475.74"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M485.204 110.234H476.067C474.684 110.234 473.563 109.023 473.563 107.53V97.6587C477.444 101.85 481.324 106.042 485.204 110.234Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M485.204 110.234H476.067C474.684 110.234 473.563 109.023 473.563 107.53V97.6587C477.444 101.85 481.324 106.042 485.204 110.234Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M449.697 196.321H530.615"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M505.73 119.464H458.866C457.794 119.464 456.926 120.313 456.926 121.36V194.253C456.926 195.299 457.794 196.148 458.866 196.148H514.878C515.95 196.148 516.819 195.299 516.819 194.253V130.294L505.73 119.464Z"
        fill="#99D7E9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M505.73 119.464H458.866C457.794 119.464 456.926 120.313 456.926 121.36V194.253C456.926 195.299 457.794 196.148 458.866 196.148H514.878C515.95 196.148 516.819 195.299 516.819 194.253V130.294L505.73 119.464Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M508.284 119.464H461.42C460.348 119.464 459.48 120.313 459.48 121.36V194.253C459.48 195.299 460.348 196.148 461.42 196.148H517.432C518.504 196.148 519.373 195.299 519.373 194.253V130.294L508.284 119.464Z"
        fill="#C0E4EE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M508.284 119.464H461.42C460.348 119.464 459.48 120.313 459.48 121.36V194.253C459.48 195.299 460.348 196.148 461.42 196.148H517.432C518.504 196.148 519.373 195.299 519.373 194.253V130.294L508.284 119.464Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M510.838 119.464H463.974C462.902 119.464 462.033 120.313 462.033 121.36V194.253C462.033 195.299 462.902 196.148 463.974 196.148H519.986C521.058 196.148 521.927 195.299 521.927 194.253V130.294L510.838 119.464Z"
        fill="#E6F5F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M512.449 122.145H481.245C480.531 122.145 479.952 122.676 479.952 123.33V168.919C479.952 169.574 480.531 170.105 481.245 170.105H518.541C519.254 170.105 519.833 169.574 519.833 168.919V128.919L512.449 122.145Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M465.595 193.72C464.523 193.72 463.654 192.872 463.654 191.825V119.495C462.735 119.645 462.033 120.419 462.033 121.359V194.252C462.033 195.299 462.902 196.148 463.974 196.148H519.986C521.058 196.148 521.927 195.299 521.927 194.252V193.689C521.822 193.706 521.717 193.72 521.607 193.72H465.595Z"
        fill="#C4DCE9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M521.927 131.536H512.225C510.757 131.536 509.567 130.374 509.567 128.94V119.464H510.838L521.927 130.294V131.536Z"
        fill="#DAF6FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M510.838 119.464H463.974C462.902 119.464 462.033 120.313 462.033 121.36V194.253C462.033 195.299 462.902 196.148 463.974 196.148H519.986C521.058 196.148 521.927 195.299 521.927 194.253V130.294L510.838 119.464Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M469.653 118.448C469.653 117.619 470.093 116.947 470.942 116.947C471.791 116.947 472.231 117.619 472.231 118.448V125.554C472.231 126.383 471.543 127.055 470.695 127.055C469.846 127.055 469.158 126.383 469.158 125.554V120.249"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M480.402 143.456H471.828C471.202 143.456 470.694 142.961 470.694 142.35V133.347C470.694 132.736 471.202 132.24 471.828 132.24H480.402C481.027 132.24 481.535 132.736 481.535 133.347V142.35C481.535 142.961 481.027 143.456 480.402 143.456Z"
        fill="#FFD46B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M480.402 143.456H471.828C471.202 143.456 470.694 142.961 470.694 142.35V133.347C470.694 132.736 471.202 132.24 471.828 132.24H480.402C481.027 132.24 481.535 132.736 481.535 133.347V142.35C481.535 142.961 481.027 143.456 480.402 143.456Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M511.584 170.105H472.038C471.296 170.105 470.695 169.517 470.695 168.793V155.008C470.695 154.283 471.296 153.695 472.038 153.695H511.584C512.326 153.695 512.928 154.283 512.928 155.008V168.793C512.928 169.517 512.326 170.105 511.584 170.105Z"
        fill="#9AFFDB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M511.584 170.105H472.038C471.296 170.105 470.695 169.517 470.695 168.793V155.008C470.695 154.283 471.296 153.695 472.038 153.695H511.584C512.326 153.695 512.928 154.283 512.928 155.008V168.793C512.928 169.517 512.326 170.105 511.584 170.105Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M511.584 170.105H472.038C471.296 170.105 470.695 169.517 470.695 168.793V155.008C470.695 154.283 471.296 153.695 472.038 153.695H511.584C512.326 153.695 512.928 154.283 512.928 155.008V168.793C512.928 169.517 512.326 170.105 511.584 170.105Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M485.366 132.241H509.81"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M485.366 137.586H512.364"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M485.366 142.753H501.966"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M470.695 147.75H512.364"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M474.603 176.606H477.704"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M484.089 176.606H503.973"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M474.603 181.952H477.704"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M484.089 181.952H512.364"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M474.603 187.119H477.704"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M507.605 165.156C498.59 160.207 494.561 162.611 490.181 162.611C485.8 162.611 484.587 156.616 479.024 156.616C473.461 156.616 470.695 162.611 470.695 162.611V165.156H512.928"
        fill="#00D9AA"
      />
      <path
        d="M484.089 187.119H505.797"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M507.605 165.156C498.59 160.207 494.561 162.611 490.181 162.611C485.8 162.611 484.587 156.616 479.024 156.616C473.461 156.616 470.695 162.611 470.695 162.611V165.156H512.928"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M521.927 130.274H513.239C511.924 130.274 510.859 129.233 510.859 127.95V119.464C514.548 123.067 518.238 126.671 521.927 130.274Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M521.927 130.274H513.239C511.924 130.274 510.859 129.233 510.859 127.95V119.464C514.548 123.067 518.238 126.671 521.927 130.274Z"
        stroke="#2B3554"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M566.423 81.912V88.1646C583.552 88.1646 597.426 100.67 597.426 116.109C597.426 119.742 596.651 123.235 595.217 126.414C594.171 128.754 590.838 129.383 588.861 127.601C587.815 126.658 587.389 125.226 587.97 123.968C589.094 121.558 589.675 118.869 589.675 116.109C589.675 104.547 579.25 95.1507 566.423 95.1507V101.403C566.423 102.975 564.33 103.744 563.09 102.661L552.278 92.9152C551.503 92.2166 551.503 91.1337 552.278 90.4351L563.129 80.6895C564.33 79.5717 566.423 80.3402 566.423 81.912ZM543.171 116.11C543.171 127.672 553.596 137.068 566.423 137.068V130.816C566.423 129.244 568.516 128.475 569.717 129.558L580.53 139.304C581.305 140.002 581.305 141.085 580.53 141.784L569.717 151.529C568.516 152.647 566.423 151.879 566.423 150.307V144.054C549.294 144.054 535.42 131.549 535.42 116.11C535.42 112.477 536.195 108.984 537.629 105.805C538.676 103.465 542.008 102.836 543.985 104.618C545.031 105.561 545.457 106.993 544.876 108.251C543.752 110.661 543.171 113.35 543.171 116.11Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f"
          x="23.4376"
          y="465.467"
          width="34.0424"
          height="20.9942"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter1_f"
          x="452.208"
          y="466.305"
          width="34.0424"
          height="20.1558"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter2_f"
          x="23.4376"
          y="476.461"
          width="462.813"
          height="10"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  </div>
);

export default SyncPermissionsSVG;
