import { useEffect } from 'react';
import { getBundlesToUpload } from '../../../components/MediaObject/helpers';
import { SubmissionError } from 'redux-form';
import {
  MVS_CREATE_SUCCESS,
  MVS_CREATE_FAILURE,
  MVS_UPDATE_SUCCESS,
  MVS_UPDATE_FAILURE,
} from '../../../../store/reducers/mvs/actions';
import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../../store/reducers/ui/actions';

export const useMVSForm = props => {
  const { medication_to_update } = props;

  useEffect(() => {
    fetchCodes();
    return () => {
      props.clearMedicationError();
      if (medication_to_update) props.clearMedicationToUpdate();

      props.dispatch(unblockNavigation());
    };
  }, []);

  const fetchCodes = () => {
    const {
      fetchMedUnitsType,
      medUnitsTypes,
      fetchDeliveryMethodType,
      deliveryMethodTypes,
      deliveryFormTypes,
      fetchDeliveryFormType,
    } = props;

    medUnitsTypes.length || fetchMedUnitsType();
    deliveryMethodTypes.length || fetchDeliveryMethodType();
    deliveryFormTypes.length || fetchDeliveryFormType();
  };

  const medicationTypeAhead = (inputValue, callBack) => {
    let { fetchMedicationList } = props;

    fetchMedicationList(inputValue).then(res => {
      let book = {};
      let options = [];
      res.payload.map(o => {
        // if (!book[o.m])
        options.push({
          label: o.medicationShortName,
          value: o.medicationShortName,
        });
        // book[o.medListId.toLowerCase()] = true;
      });

      callBack(options);
    });
  };

  const onFormSubmit = async (values, dispatch) => {
    const {
      personId,
      selectedId,
      bundles,
      mvsCreate,
      mvsUpdate,
      history,
      onCloseDrawer,
      mediaObjects,
      form,
      bundlesToDelete,
    } = props;

    const { bundlesToUpload, bundlesToUpdate } = getBundlesToUpload(
      mediaObjects,
      form
    );

    let result;

    // delete attachments
    if (bundlesToDelete.length) {
      for (const bundle of bundlesToDelete) {
        await props.deleteMedia(bundle.mediaObjectId, bundle.objectId);
      }
    }

    if (bundlesToUpdate.length) {
      for (const file of bundlesToUpdate) {
        await props.updateMedia({
          ...file,
          description: file.notes || file.description,
        });
      }
    }

    if (!selectedId) {
      result = await mvsCreate(personId, values, bundlesToUpload);
    } else {
      result = await mvsUpdate(personId, selectedId, values, bundlesToUpload);
    }

    if (
      result.type === MVS_CREATE_SUCCESS ||
      result.type === MVS_UPDATE_SUCCESS
    ) {
      dispatch(unblockNavigation());
      onCloseDrawer();
    } else if (
      result.type === MVS_CREATE_FAILURE ||
      result.type === MVS_UPDATE_FAILURE
    ) {
      throw new SubmissionError(result.payload.errors);
    }
  };

  const onFormChange = (v, d, formProps) => {
    if (
      !formProps.pristine &&
      !props.ui.isNavigationLocked &&
      props.selectedId
    ) {
      props.dispatch(blockNavigationWithStandardMsg());
    }
  };

  return {
    medicationTypeAhead,
    onFormSubmit,
    onFormChange,
  };
};
