import React from 'react';
import SurgicalHistoryForm from '../../../containers/SurgicalHistoryForm';
import { ViewSurgery } from '../../../components/SurgicalHistory';
import MediaSubmitProgress from '../../../components/DocumentsDrawer/MediaObjectDrawer/MediaSubmitProgress';
import Grid from '@material-ui/core/Grid';
import {
  UPDATE,
  CREATE,
  IDLE,
  VIEW,
  useSurgicalHistory,
} from './useSurgicalHistory';
import { surgeryTypeAhead } from './utils';

const Contents = props => {
  const {
    isFetchingSurgicalHistoryType,
    status,
    surgicalHistoryTypes,
    selectedId,
    surgeryList,
    onCloseDrawer,
    surgicalHistory,
    matches550,
    bundles,
  } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const actions = useSurgicalHistory(props, setIsSubmitting);
  const noop = () => {};

  const renderContents = () => {
    if (isSubmitting && bundles.length) {
      return (
        <div style={{ padding: 16, paddingTop: 24 }}>
          <Grid container spacing={2}>
            {bundles.map(bundle => (
              <Grid item xs={12}>
                <MediaSubmitProgress bundle={bundle} />
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
    switch (status) {
      case IDLE:
        return '';
      case UPDATE:
      case CREATE: {
        return (
          <SurgicalHistoryForm
            action={status}
            isFetchingSurgicalHistoryType={isFetchingSurgicalHistoryType}
            surgeryTypeAhead={surgeryTypeAhead(props)}
            surgicalHistoryTypes={surgicalHistoryTypes}
            isSubmitting={surgicalHistory.isSubmitting}
            onSubmit={actions.onFormSubmit}
            onCancel={onCloseDrawer}
            formSubmitting={isSubmitting}
            onChange={noop}
            matches550={matches550}
          />
        );
      }
      case VIEW: {
        return (
          <ViewSurgery
            onCancel={onCloseDrawer}
            onDelete={actions.onClickDeleteCard}
            onUpdate={actions.onClickUpdateCard}
            data={surgeryList[selectedId]}
          />
        );
      }
    }
  };
  return <div>{renderContents()}</div>;
};

export default Contents;
