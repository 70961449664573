import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

export default ({ classes }) => (
  <Grid container spacing={2} style={{ padding: '25px' }}>
    <Grid item xs={12} sm={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.product}>
            <Skeleton
              variant="rect"
              animation="wave"
              width={375}
              height={470}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.product}>
            <Skeleton
              variant="rect"
              animation="wave"
              width={375}
              height={470}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
