import reducer from './reducers';
import {
  setProgressBar,
  updateProgressBar,
  hideProgressBar
} from './actions';

export default reducer;
export {
  setProgressBar,
  updateProgressBar,
  hideProgressBar
}