import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { styles } from './styles.js';

class AccordionPanel extends Component {
  /**
   * Delegate click event to parent Accordion component.
   * Callback function props.onBeforeOpen if has been selected and is defined
   * Callback function props.onBeforeClose if has been deselected and is defined
   *
   */
  handleOnClick = () => {
    const { onBeforeOpen, onBeforeClose, id, isActive } = this.props;

    this.props.onPanelClick();

    if (!isActive) {
      if (onBeforeOpen) {
        onBeforeOpen(id);
      }
    } else {
      if (onBeforeClose) {
        onBeforeClose(id);
      }
    }
  };

  /**
   * Render AccordionPanel
   */
  render() {
    const {
      children,
      header,
      headerClosed,
      isActive,
      ...attributes
    } = this.props;

    const noDisplay = {
      display: 'none',
    };

    const headerClasses = classNames({
      [this.props.classes.accordionPanelHeader]: true,
      [this.props.classes.accordionPanelActive]: isActive,
      [this.props.classes.accordionPanelInactive]: !isActive,
    });

    const bodyClasses = classNames({
      [this.props.classes.accordionPanelBody]: true,
    });

    delete attributes.onPanelClick;
    delete attributes.classNames;
    delete attributes.lastActive;
    delete attributes.onBeforeOpen;
    delete attributes.onBeforeClose;

    console.log(headerClasses, bodyClasses);

    return (
      <div {...attributes}>
        <a className={headerClasses} onClick={this.handleOnClick}>
          {(isActive && header) || headerClosed}
        </a>
        <div className={bodyClasses} style={isActive ? {} : noDisplay}>
          {children}
          <div style={{ float: 'none', clear: 'both' }}></div>
        </div>
      </div>
    );
  }
}

AccordionPanel.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  headerClosed: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  classNames: PropTypes.shape({
    header: PropTypes.string,
    headerActive: PropTypes.string,
    headerInactive: PropTypes.string,
    body: PropTypes.string,
    bodyActive: PropTypes.string,
    bodyInactive: PropTypes.string,
  }),
  onBeforeOpen: PropTypes.func,
  onBeforeClose: PropTypes.func,
};

AccordionPanel.defaultProps = {
  classNames: {
    header: 'AccordionPanel-header',
    headerActive: 'AccordionPanel-header-active',
    headerInactive: 'AccordionPanel-header-inactive',
    body: 'AccordionPanel-body',
    bodyActive: 'AccordionPanel-body-active',
    bodyInactive: 'AccordionPanel-body-inactive',
  },
};

export default withStyles(styles)(AccordionPanel);
