import React from 'react';
import { Tabs, Tab, Tooltip, IconButton } from '@material-ui/core/';
import ClearIcon from '@material-ui/icons/Clear';
import styles from '../DocumentsDrawer/styles/MediaObjectDrawer.css';

const Header = ({ onClose, title }) => (
  <div>
    <Tooltip title="Close">
      <div className={styles.overviewBackBtn}>
        <IconButton size="small" onClick={e => onClose()}>
          <ClearIcon />
        </IconButton>
      </div>
    </Tooltip>

    <Tabs
      value={0}
      variant="fullWidth"
      style={{
        backgroundColor: '#006AB0',
        color: '#ffffff',
      }}
      indicatorColor="primary"
    >
      <Tab label={title} disableFocusRipple disableRipple />
    </Tabs>
  </div>
);

export default Header;
