import React, { PureComponent, Fragment } from 'react';
import PropType from 'prop-types';
import styles from './Field.css';

class FieldErrorMessage extends PureComponent {

  render() {
    const { error, touched } = this.props;
    return (
      <div>
      {touched && error &&
        <span className={styles.errorMsg}>
          <i className="fa fa-exclamation-circle"></i> {error}
        </span>}
      </div>
    )
  }
  
}

FieldErrorMessage.propType = {
  error: PropType.bool,
  touched: PropType.bool,
}

export default FieldErrorMessage