import {
  Icon,
  IconButton,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '32px 24px',
    position: 'relative',
  },
  close: {
    position: 'absolute',
    top: 8,
    right: 8,
    padding: '4px',
  },
}));

export default function CantCreateDependentModal(props) {
  const classes = useStyles();
  return (
    <Modal {...props} className={classes.modal}>
      <Paper className={classes.paper}>
        <IconButton className={classes.close} onClick={props.onClose}>
          <Icon>close</Icon>
        </IconButton>
        <Typography
          variant="h6"
          style={{ paddingBottom: '24px', textAlign: 'center' }}
        >
          This person already has an Agrin Health account.
        </Typography>

        <Typography>
          Please check with them to see if you need to be added to their
          account, or if someone else has already claimed them as their
          dependent.
        </Typography>
      </Paper>
    </Modal>
  );
}
