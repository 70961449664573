import { ignoreCase } from './string';

export const sortEnsureBottom = object => (a, b) => {
  return -1 * sortEnsureTop(object)(a, b);
};

export const sortEnsureTop = object => (a, b) => {
  const k0 = Object.keys(object)[0];
  const v0 = ignoreCase(object[k0]);
  const o1 = ignoreCase(a[k0]);
  const o2 = ignoreCase(b[k0]);

  if (o1 === v0) return -1;
  if (o2 === v0) return 1;

  return o1 < o2 ? -1 : o1 > o2 ? 1 : 0;
};

export const sortByField = field => (a, b) => {
  const o1 = ignoreCase(a[field]);
  const o2 = ignoreCase(b[field]);

  return o1 < o2 ? -1 : o1 > o2 ? 1 : 0;
};

// TODO - Newer sort functions will replace the functions above

const SORT_ASCENDING = 'asc';
const SORT_DESCENDING = 'desc';

export const sort = (order = 'asc', field) => (a, b) => {
  if (a[field] === b[field]) return 0;

  var typeOfField = typeof a[field];

  const result =
    typeOfField === 'number'
      ? sortNumber(a, b, field)
      : typeOfField === 'string'
      ? sortString(a, b, field)
      : a[field] instanceof Date
      ? sortDate(a, b, field)
      : null;

  return order === SORT_ASCENDING ? +result : -result;
};

export const sortDate = (a, b, field) => {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  if (!l) return 1;
  else if (!r) return -1;

  const lx = l.getTime();
  const rx = r.getTime();

  return lx < rx ? -1 : lx > rx ? 1 : 0;
};

export const sortNumber = (a, b, field) => {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  if (!l) return 1;
  else if (!r) return -1;

  return l - r;
};

export const sortString = (a, b, field) => {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  if (!l) return 1;
  else if (!r) return -1;

  return l < r ? -1 : l > r ? 1 : 0;
};

export const getNextSortOrder = order =>
  order === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING;

export const normalizeSortDirection = direction =>
  direction.toLowerCase() === SORT_ASCENDING ? SORT_ASCENDING : SORT_DESCENDING;

export const placeItemAtBottom = (data, field, key) => {
  const item = data.filter(x => x[field].toLowerCase() === key.toLowerCase());
  if (!item) throw new Error(`Cannot find item "${key}" in list`);
  const rest = data.filter(x => x[field].toLowerCase() !== key.toLowerCase());
  return [].concat(rest, item);
};
