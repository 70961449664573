export const UI_CANCELED_FORM = 'ui/form/CANCEL';
export const UI_CANCELED_FORM_RESET = 'ui/form/CANCEL_RESET';
export const UI_STATE_ENTITY_CREATE = 'ui/state/ENTITY_CREATE';
export const UI_STATE_ENTITY_UPDATE = 'ui/state/ENTITY_UPDATE';

export const UI_UPDATE_AVATAR = 'ui/avatar/UPDATE';

export const UI_NAVIGATION_LOCKED = 'ui/form/lock';
export const UI_NAVIGATION_UNLOCK = 'ui/form/unlock';

export const updateAvatar = path => ({
  type: UI_UPDATE_AVATAR,
  payload: path
});

export const cancelForm = () => ({
  type: UI_CANCELED_FORM
});

export const cancelFormReset = () => ({
  type: UI_CANCELED_FORM_RESET
});

export const uiStateEntityCreate = subject => ({
  type: UI_STATE_ENTITY_CREATE,
  payload: subject
});

export const uiStateEntityUpdate = subject => ({
  type: UI_STATE_ENTITY_UPDATE,
  payload: subject
});

export const blockNavigationWithStandardMsg = () =>
  blockNavigation("It looks like you've inserted something into this form. Are you sure you want to leave? Take note. The information entered into this form will be lost if you go.");

export const blockNavigation = (message) => ({
  type: UI_NAVIGATION_LOCKED,
  payload: message
});

export const unblockNavigation = () => ({
  type: UI_NAVIGATION_UNLOCK
});
