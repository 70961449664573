import React from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import InputField from '../../../../library/forms/InputField';
import HeightField from '../../../containers/FormFields/HeightFields';

const BodyMassViewer = ({ data }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <InputField
          label={`Weight`}
          name="weight"
          input={{
            value: !!data.weight ? data.weight : '',
          }}
          shrink={true}
          enableMinHeight={false}
          InputProps={{
            disabled: true,
            endAdornment: (
              <InputAdornment
                position="end"
                disableTypography
                style={{
                  fontSize: '0.875rem',
                  color: 'rgba(0, 0, 0, 0.54)',
                  lineHeight: '1.5',
                }}
              >
                lbs
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <HeightField
          disabled={true}
          enableMinHeight={false}
          initialFeetValue={
            data.height
              ? Math.floor(data.height / 12) > 0
                ? Math.floor(data.height / 12)
                : null
              : null
          }
          initialInchesValue={
            data.height
              ? data.height % 12 > 0
                ? data.height % 12
                : null
              : null
          }
        />
      </Grid>
    </Grid>
  );
};

export default BodyMassViewer;
