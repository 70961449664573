import BloodPressureButton from '../../buttons/BloodPressureButton';

const dataFields = {
  systolic: '',
  diastolic: '',
};

const normalizeData = data => ({
  systolic: parseFloat(data.systolic),
  diastolic: parseFloat(data.diastolic),
});

const properties = {
  label: 'Blood Pressure',
  color: '#ED5158',
  icon: BloodPressureButton,
  dataFields,
  normalizeData,
};

export { dataFields, properties };
