import React, { useState, useEffect } from 'react';
import CustomYearView from './CustomYearView';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CircularProgress, withStyles } from '@material-ui/core';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import CalendarToolbar from './CalendarToolbar';

import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import getDay from 'date-fns/getDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  startOfWeek,
  endOfWeek,
  getDay,
  format,
  locales,
});

const styles = {
  root: {
    marginTop: 35,
    width: '100%',
    minHeight: '700px',
  },
  event: {
    // position: 'absolute'
  },
};

const JournalCalendar = ({
  list,
  listIds,
  onSelect,
  classes,
  pagination,
  fetchByDate,
  isFetching,
  onFetchJournalEntriesByCursor,
}) => {
  const [view, setView] = useState('week');
  const [entities, setEntities] = useState([]);
  const [date, setDate] = useState(startOfWeek(new Date()));
  const [lastDate, setLastDate] = useState(new Date());
  const [entitiesByYear, setEntitiesByYear] = useState({});
  const [loading, setLoading] = useState(false);

  const handleOnYearViewClick = _date => {
    setDate(_date);
    setView('day');
  };

  const onNavigate = _date => {
    if (isBefore(_date, lastDate) && pagination.nextPage) {
      setLoading(true);
      onFetchJournalEntriesByCursor();
      setLastDate(_date);
    }
    setDate(_date);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    const newlastDate = new Date(list[listIds[listIds.length - 1]].enteredOn);
    if (
      !isBefore(newlastDate, lastDate) &&
      pagination.nextPage &&
      entities.length !== 0
    ) {
      onFetchJournalEntriesByCursor();
    } else {
      var groupByYear = {};
      var _lastDate = new Date();
      const _entities = listIds.map(id => {
        const enteredOn = new Date(list[id].enteredOn);
        const year = list[id].enteredOn.split('-')[0];
        const val = {
          id,
          ...list[id],
          enteredOn,
        };
        if (year && groupByYear[year]) {
          groupByYear = {
            ...groupByYear,
            [year]: [...groupByYear[year], val],
          };
        } else {
          groupByYear[year] = [val];
        }
        if (isBefore(enteredOn, lastDate)) {
          _lastDate = enteredOn;
        }
        return val;
      });
      setEntitiesByYear(groupByYear);
      setLastDate(_lastDate);
      setEntities(_entities);
      setLoading(false);
    }
  }, [listIds]);

  return (
    <Calendar
      views={{ day: true, week: true, month: true, year: CustomYearView }}
      view={view}
      date={date}
      className={classes.root}
      popup
      localizer={localizer}
      events={
        view === 'year'
          ? entitiesByYear[date.getFullYear()]
            ? entitiesByYear[date.getFullYear()]
            : []
          : entities
      }
      startAccessor="enteredOn"
      endAccessor="enteredOn"
      onSelectEvent={event => onSelect(event, event.id)}
      onView={e => setView(e)}
      onNavigate={e => onNavigate(e)}
      onYearViewClick={handleOnYearViewClick}
      messages={{ year: 'Year' }}
      components={{
        event: props => <JournalEvent {...props} classes={classes} />,
        toolbar: props => <CalendarToolbar {...props} isFetching={loading} />,
      }}
    />
  );
};

const JournalEvent = ({ classes, title }) => (
  <div className={`rbc-event-content ${classes.event}`} title={title}>
    {title}
  </div>
);

export default withStyles(styles)(JournalCalendar);
