import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import MediaObject from '../../../containers/MediaObject';
import Button from '@material-ui/core/Button';
import {
  clearFiles,
  uploadFiles,
} from '../../../../store/reducers/mediaObject';
import {
  addMediaCollection,
  getMedia,
} from '../../../../store/reducers/mediaObjectDrawer/actions';
import { documentCreate } from '../../../../store/reducers/documents/actions';
import { connect, submit } from 'react-redux';
import FileIcon from '../../../containers/MediaObject/FileIcon';
import DocumentForm from './DocumentForm';
import RemoteSubmitButton from './RemoteSubmitButton';
import { format } from 'date-fns-tz';

class MediaAddMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBundles: this.props.bundles != null && this.props.bundles.length > 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.bundles !== prevProps.bundles) {
      const hasBundles =
        this.props.bundles != null && this.props.bundles.length > 0;
      if (hasBundles !== this.state.hasBundles)
        this.setState({
          hasBundles: hasBundles,
        });
    }
  }

  handleSubmit = document => {
    const { bundles, personId } = this.props;
    const {
      newEnteredOnTime,
      newEnteredOnDate,
      category,
      displayName,
      description,
    } = document;
    const normalizeDate = date => date.split('-').join('/');
    // new Date with yyyy-mm-dd returns one day late but yyyy/mm/dd returns correct day
    // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
    const dateTime = newEnteredOnDate
      ? newEnteredOnTime
        ? new Date(`${normalizeDate(newEnteredOnDate)} ${newEnteredOnTime}`)
        : new Date(`${normalizeDate(newEnteredOnDate)}`)
      : new Date();
    const payload = {
      personId,
      data: {
        category: category,
        displayName: Boolean(displayName) ? displayName : bundles[0].file.name,
        description: description,
        enteredOn: format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX"),
        // creating documents needs a format of "yyyy-MM-dd'T'HH:mm:ssXXX"
        // updating needs "yyyy-MM-dd'T'HH:mm:ss"
      },
      bundle: bundles[0],
    };
    this.props.onSubmit(payload);
  };

  render() {
    const { onClickCancel } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          {this.state.hasBundles ? (
            <React.Fragment>
              <Grid item xs={2}>
                <div style={{ paddingTop: '35px', margin: 'auto' }}>
                  <FileIcon bundle={this.props.bundles[0]} />
                </div>
              </Grid>
              <Grid item xs={10}>
                <DocumentForm
                  areaType={this.props.areaType || null}
                  onFieldChange={this.props.updateField}
                  fileName={this.props.fileName}
                  onSubmit={values => this.handleSubmit(values)}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12} md={12} lg={12}>
              <MediaObject
                multiple={Boolean(this.props.multiple)}
                mode="drawer"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              color="default"
              variant="contained"
              size="medium"
              onClick={onClickCancel}
              disableElevation
              disabled={this.props.uploadingProcess}
            >
              Cancel
            </Button>
          </Grid>

          {this.state.hasBundles && (
            <Grid item xs={6} align="right">
              <RemoteSubmitButton isUploadingFiles={this.props.isSubmitting} />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { isUploadingFiles, bundles } = state.mediaObject;
  const { personId } = state.active.user;
  const { allUploaded, uploadingProcess } = state.mediaObjectDrawer;
  const fileName = Boolean(bundles[0]) ? bundles[0].file.name : null;
  const { isSubmitting } = state.documents.isSubmitting;
  return {
    bundles,
    personId,
    isUploadingFiles,
    allUploaded,
    uploadingProcess,
    fileName,
    isSubmitting,
  };
};

const mapDispatchToProps = dispatch => ({
  uploadFiles: (files, meta) => dispatch(uploadFiles(files, meta)),
  clearFiles: () => dispatch(clearFiles()),
  getMedia: (pid, aid, area) => dispatch(getMedia(pid, aid, area)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaAddMode);
