import Slider from './Slider';
import React, { useEffect, useState, useRef } from 'react';
import { Fade, Grid } from '@material-ui/core';
import SlideButton from './SlideButton';
import { useSliding } from './useSliding';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  slider: {
    display: 'grid',
    position: 'relative',
    marginBottom: '16px',
    border: 'none',
  },
  slider__container: {
    zIndex: '3',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    listStyleType: 'none',
    paddingLeft: '0px',
    flexWrap: 'nowrap',
    height: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0,0,0,.1)',
      // outline: '2px solid slategrey',
      backgroundClip: 'content-box',
      border: '3px solid transparent',
    },
  },
  child: {
    padding: '0px 24px 16px 0px',
    maxWidth: '400px',
  },
});

const SliderWrapper = ({
  list,
  childElement,
  listIds,
  hasUpdates,
  filter,
  sliderStyles = {},
}) => {
  const {
    wrapperRef,
    childWidth,
    index,
    scrollIndex,
    isScrolling,
    handleWrapperScroll,
    handleClick,
    getChildWidth,
    hasPrev,
    hasNext,
    setIndex,
  } = useSliding(listIds, hasUpdates);

  useEffect(() => {
    if (hasUpdates) {
      console.log('hasUpdates');
      handleClick('prev', 0);
    }
  }, [filter, hasUpdates]);

  const classes = useStyles();
  const wrapperStyle =
    scrollIndex === 0
      ? {
          marginLeft: '-2px',
          paddingLeft: '2px',
        }
      : {};
  return (
    <div className={classes.slider} style={{ ...sliderStyles }}>
      <Fade in={hasPrev}>
        <div>
          <SlideButton onClick={() => handleClick('prev')} type="prev" />
        </div>
      </Fade>
      <Fade in={hasNext}>
        <div>
          <SlideButton onClick={() => handleClick('next')} type="next" />
        </div>
      </Fade>
      <div
        onScroll={e => handleWrapperScroll(e)}
        ref={wrapperRef}
        className={classes.slider__container}
        style={wrapperStyle}
      >
        <Slider
          listIds={listIds}
          list={list}
          index={index}
          childElement={childElement}
          getChildWidth={getChildWidth}
          isScrolling={isScrolling}
          classes={{
            child: classes.child,
          }}
        />
      </div>
    </div>
  );
};

export default SliderWrapper;
