import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import DialogTitle from './DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import cx from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ProgressButtonLinear from '../../Buttons/ProgressButtonLinear';

const useStyles = makeStyles({
  root: {
    padding: '24px',
    borderRadius: 8,
    border: '2px solid #0068A8',
  },
  warningRoot: {
    backgroundColor: '#EBDC58',
  },
  keepButton: {
    minWidth: '110px',
  },
  warningButton: {
    minWidth: '110px',
  },
  dialogTitle: {
    paddingBottom: '0px',
    textAlign: 'center',
  },
  dialogContent: {
    padding: '0px',
  },
  subHeader: {
    fontWeight: '500',
    paddingBottom: '20px',
    fontSize: '18px',
  },
  subtitle: {
    fontSize: '16px',
    marginBottom: '12px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button1: {
    backgroundColor: '#ED5158',
    color: '#fff',
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  wrapper: {
    position: 'relative',
  },
});

const SubscriptionModal = ({
  header,
  subHeader,
  subtitle,
  onClose,
  button1,
  button2,
  open,
  type,
  onClick,
  handleClose,
  warning,
  isSubmitting,
  clearMessage,
}) => {
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.button1]: !isSubmitting,
  });

  const renderContent = () => {
    const hasContent = !!subHeader || !!subtitle;
    return hasContent ? (
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.subHeader} align="center" gutterBottom>
          {subHeader}
        </Typography>
        <Typography gutterBottom align="center" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </DialogContent>
    ) : null;
  };

  const renderActions = () => {
    const hasActions = !!button1 || !!button2;
    return hasActions ? (
      <DialogActions className={classes.dialogActions}>
        {button1 && (
          <div className={classes.wrapper}>
            <ProgressButtonLinear
              variant="contained"
              className={buttonClassname}
              disabled={isSubmitting}
              onClick={() => onClick(type)}
              type="submit"
              isProgressing={isSubmitting}
            >
              {button1}
            </ProgressButtonLinear>
          </div>
        )}
        {button2 && ( //closes modal
          <Button
            onClick={onClose}
            className={classes.keepButton}
            variant="contained"
            style={
              warning
                ? {
                    backgroundColor: '#0068A8',
                    color: 'white',
                  }
                : {}
            }
          >
            {button2}
          </Button>
        )}
      </DialogActions>
    ) : null;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      onBackdropClick={onClose}
      onEscapeKeyDown={onClose}
      PaperProps={{
        className: cx(classes.root, {
          [classes.warningRoot]: warning,
        }),
      }}
      onExited={clearMessage}
    >
      <DialogTitle onClose={onClose}>{header}</DialogTitle>
      {renderContent()}
      {renderActions()}
    </Dialog>
  );
};

SubscriptionModal.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  button1: PropTypes.string,
  button2: PropTypes.string,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  warning: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

SubscriptionModal.defaultProps = {
  header: '',
  subHeader: '',
  subtitle: '',
  button1: '',
  button2: '',
  type: '',
  warning: false,
  isSubmitting: false,
};

export default SubscriptionModal;
