import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DashboardLinkButton from './DashboardLinkButton';

import './DashboardLinkButtons.css';

const styles = theme => ({
  container: {
    display: 'flex',
  },
});

function DashboardLinkButtons(props) {
  const { isDependent } = props;
  const { healthRecord, account } = props.navigation.dashboard;

  const buttons = [
    {
      to: healthRecord.self,
      fa: 'assignment',
      label: 'Health Record',
    },
    {
      to: account.self,
      fa: 'person',
      label: 'Profile / Account',
    },
    // {
    //   to: healthRecord.portals.read,
    //   fa: 'public',
    //   label: 'Portals',
    // },
    {
      to: healthRecord.documents,
      label: 'My Documents',
      glyph: 'file',
    },
    {
      to: props.navigation.dashboard.journal,
      fa: 'import_contacts',
      label: 'Journal',
    },
    {
      to: props.navigation.dashboard.resourceLibrary,
      fa: 'bookmarks',
      label: 'Resource Center',
    },
  ];
  if (!isDependent) {
    buttons.push({
      to: props.navigation.dashboard.account.syncPermissions,
      fa: 'sync',
      label: 'Sync Permissions',
      disabled: isDependent,
    });
  }
  return (
    <Grid container spacing={2}>
      {buttons.map((button, index) => (
        <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
          <DashboardLinkButton
            to={button.to}
            icon={button.fa}
            label={button.label}
            glyph={button.glyph}
            disabled={Boolean(button.disabled)}
          />
        </Grid>
      ))}
      {/*<DashboardLinkButton*/}
      {/*to="goals"*/}
      {/*fa="bullseye"*/}
      {/*label="Goals" />*/}
      {/*<DashboardLinkButton*/}
      {/*to="careplan"*/}
      {/*fa="calendar-check-o"*/}
      {/*label="Care Plan" />*/}
      {/*<DashboardLinkButton*/}
      {/*to="metrics"*/}
      {/*fa="bar-chart"*/}
      {/*label="Tracking / Metrics" />*/}

      {/*<DashboardLinkButton*/}
      {/*to="careteam"*/}
      {/*fa="child"*/}
      {/*label="Care Team" />*/}
    </Grid>
  );
}

export default withStyles(styles)(DashboardLinkButtons);
