import React from 'react';
import { withStyles } from '@material-ui/core';
import SearchResult from './SearchResult';
import SearchLoadingResult from './SearchLoadingResult';

const styles = {
  root: {
    marginTop: 25,
    height: '100%',
    overflow: 'auto',
    paddingRight: 16,
    width: 'calc(100% + 16px)',
  },
};

const MAX_RESULTS = 100;

const SearchResults = ({ results, classes, onResultClick, selected }) => {
  const setSearchState = () => {
    const { initiated, loading, data } = results;

    if (selected) {
      return <SearchResult data={selected} />;
    }

    if (initiated) {
      if (loading) {
        return <SearchLoadingResult />;
      }

      if (data.length === 0) {
        return <h4>No results found.</h4>;
      }

      if (data.length <= MAX_RESULTS) {
        return data.map(result => (
          <SearchResult
            data={result}
            key={result.providerId}
            onClick={onResultClick}
          />
        ));
      } else {
        return <h4>Too many results, try refining your search</h4>;
      }
    }
  };
  return <div className={classes.root}>{setSearchState()}</div>;
};

export default withStyles(styles)(SearchResults);
