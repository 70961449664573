import React from 'react';
import { Grid, Button, Collapse } from '@material-ui/core';
import { useStyles } from './styles';

const ActionComponent = ({ handleSuccess, accepted }) => {
  const classes = useStyles();
  return (
    <Collapse in={accepted}>
      <Grid item xs={12} className={classes.actionContainer}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          disableElevation
          disabled={!accepted}
          onClick={() => handleSuccess()}
        >
          Finished
        </Button>
      </Grid>
    </Collapse>
  );
};
export default ActionComponent;
