const chunk = (array, chunkSize) => {
  const arrayLength = array.length
  const tempArray = []

  for (let index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = array.slice(index, index + chunkSize)
    // Do something if you want with the group
    tempArray.push(myChunk)
  }

  return tempArray
}

export default chunk
