import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';

import SmartTextArea from '../SmartTextArea';
import DateTimeInput from '../../../library/components/DateTimeInput';
import './NoteInput.css';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    borderRadius: 3,
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: 16,
  },
  title: {
    // background: '#eee',
    width: '100%',
    flex: 1,
    border: 'none',
    outline: 'none',
    fontSize: 16,
    fontWeight: 600,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  body: {
    // background: '#ddd',
    position: 'absolute',
    width: '100%',
    border: 'none',
    outline: 'none',
    fontSize: 16,
    fontWeight: 300,
    margin: 0,
    resize: 'none',
    zIndex: 300,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  focused: {
    color: theme.palette.primary.main,
    borderWidth: 2,
    padding: theme.spacing(2) - 1,
  },
  datetime: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  who: {
    width: '100%',
  },
});

class NoteInput extends React.Component {
  state = {
    title: '',
    body: '',
    isFocused: false,
    isHovered: false,
  };

  componentDidMount() {
    this.setState({
      title: this.props.title,
      body: this.props.body,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.title !== prevProps.title ||
      this.props.body !== prevProps.body
    ) {
      this.setState({
        title: this.props.title,
        body: this.props.body,
      });
    }
  }

  onChange = type => (event, caret) => {
    const value =
      type == 'title'
        ? event.target.value.slice(0, 100)
        : event.target.value.slice(0, 3000);

    const nextState = {
      ...this.state,
      [type]: value,
    };

    //this.props.onBodyChange(nextState.body);

    this.props.onChange({
      body: nextState.body,
      caret,
    });

    this.setState(nextState);
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = event => {
    if (!this.state.isHovered) {
      this.setState({ isFocused: false });
      if (this.props.onBlur) {
        this.props.onBlur(this.state, event);
      }
    }
  };

  onHover = event => {
    this.setState({ isHovered: true });
  };

  onHoverOut = () => {
    this.setState({ isHovered: false });
  };

  onKeyDownBody = event => {
    const props = {
      key: event.key,
      keyCode: event.target.keyCode,
      offset: event.target.selectionStart,
    };

    if (this.props.onKeyUpBody) {
      this.props.onKeyUpBody(props);
    }
    if (['@', '#'].includes(event.key) && this.props.onSpecialCharacter) {
      this.props.onSpecialCharacter(props);
    }

    // console.log(
    //   "[Key Down]",
    //   event.key,
    //   event.target.selectionStart,
    //   event.target.value
    // );
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        ref={ref => (this.note = ref)}
        onMouseOver={this.onHover}
        onMouseOut={this.onHoverOut}
        onFocusCapture={this.onFocus}
        onBlurCapture={this.onBlur}
        className={classNames(classes.root, {
          [classes.focused]: this.state.isFocused,
        })}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <SmartTextArea
            rows={this.props.rows}
            onChange={this.onChange('body')}
            onKeyDown={this.onKeyDownBody}
            className={classes.body}
            placeholder="Type your note here"
            value={this.state.body}
          />
        </div>
      </div>
    );
  }
}

NoteInput.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  rows: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyUpBody: PropTypes.func,
  onKeyDownBody: PropTypes.func,
  onSpecialCharacter: PropTypes.func,
  topInputSlot: PropTypes.element,
  bottomInputSlot: PropTypes.element,
};

NoteInput.defaultProps = {
  title: '',
  body: '',
  rows: 10,
};

export default withStyles(styles)(NoteInput);
