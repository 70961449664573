import React from 'react'
import { Chip, withStyles } from '@material-ui/core'

import { 
  ROLE_NAME_NO_ACCESS, 
  ROLE_NAME_TEAMMATE,
  ROLE_NAME_CAREGIVER, 
  ROLE_NAME_PROVIDER, 
  ROLE_NAME_SPOUSE,
  ROLE_NAME_GUARDIAN_PRIMARY,
  ROLE_NAME_GUARDIAN_ADDITIONAL
} from '../RoleSummary/data';


const styles = {
  chip: {
    borderRadius: 4,
    height: 25,
    fontWeight: 700,
    color: '#fff',
    textTransform: 'capitalize',
    marginRight: 12,
    marginBottom: 12
  }
}

const MemberDetailLabel = ({ label, classes, forRole }) => {

  const roleData = {
    [ROLE_NAME_NO_ACCESS]: { 
      label: "No Access", 
      color: "#d32f2f"
    },
    [ROLE_NAME_SPOUSE]: { 
      label: "Spouse",
      color: "#5A82F6"
    },
    [ROLE_NAME_CAREGIVER]: {
      label: "Caregiver",
      color: "#A4D859"
    },
    [ROLE_NAME_TEAMMATE]: {
      label: "Teammate",
      color: "#3E47DD"
    },
    [ROLE_NAME_PROVIDER]: {
      label: "Provider",
      color: "#2AA5A2"
    },
    [ROLE_NAME_GUARDIAN_PRIMARY]: {
      label: "Primary Guardian",
      color: "#AEAEAE",
      fontColor: "#FFF"
    },
    [ROLE_NAME_GUARDIAN_ADDITIONAL]: {
      label: "Additional Guardian",
      color: "#FBC67A",
      fontColor: "#C17306"
    }
  };

  const data = forRole && label in roleData 
    ? roleData[label] 
    : { label, color: '#AEAEAE' };

  return (
    <Chip
      className={classes.chip}
      label={data.label}
      style={{ backgroundColor: data.color, color: data.fontColor || "#FFF" }}
    />
  )
}

export default withStyles(styles)(MemberDetailLabel)
