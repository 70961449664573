import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const USER_PORTALS_REQUEST = '@@redux/portals/USER_PORTALS_REQUEST';
export const USER_PORTALS_SUCCESS = '@@redux/portals/USER_PORTALS_SUCCESS';
export const USER_PORTALS_FAILURE = '@@redux/portals/USER_PORTALS_FAILURE';

export const USER_PORTALS_CREATE = '@@redux/portals/USER_PORTALS_CREATE';
export const USER_PORTALS_CREATE_SUCCESS =
  '@@redux/portals/USER_PORTALS_CREATE_SUCCESS';
export const USER_PORTALS_CREATE_FAILURE =
  '@@redux/portals/USER_PORTALS_CREATE_FAILURE';

export const USER_PORTALS_UPDATE = '@@redux/portals/USER_PORTALS_UPDATE';
export const USER_PORTALS_UPDATE_SUCCESS =
  '@@redux/portals/USER_PORTALS_UPDATE_SUCCESS';
export const USER_PORTALS_UPDATE_FAILURE =
  '@@redux/portals/USER_PORTALS_UPDATE_FAILURE';

export const USER_PORTALS_DELETE = '@@redux/portals/USER_PORTALS_DELETE';
export const USER_PORTALS_DELETE_SUCCESS =
  '@@redux/portals/USER_PORTALS_DELETE_SUCCESS';
export const USER_PORTALS_DELETE_FAILURE =
  '@@redux/portals/USER_PORTALS_DELETE_FAILURE';

export const PORTALS_SELECT_ID = 'portals/id/select';
export const PORTALS_CLEAR_ID = 'portals/id/clear';

export const selectPortalId = id => ({
  type: PORTALS_SELECT_ID,
  payload: id,
});

export const clearSelectedPortalId = () => ({
  type: PORTALS_CLEAR_ID,
});

export const fetchUserPortals = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [USER_PORTALS_REQUEST, USER_PORTALS_SUCCESS, USER_PORTALS_FAILURE],
    endpoint: `/api/member/portals`,
  },
});

export const createUserPortal = (pid, portal) => {
  const payload = {
    personId: pid,
    portalName: portal.portalName,
    providerName: portal.providerName,
    portalUrl: portal.portalUrl,
    portalDescription: portal.portalDescription,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        USER_PORTALS_CREATE,
        USER_PORTALS_CREATE_SUCCESS,
        USER_PORTALS_CREATE_FAILURE,
      ],
      endpoint: `/api/member/portal`,
      payload,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(payload),
        },
      },
    },
  };
};

export const updateUserPortal = portal => {
  const payload = {
    portalName: portal.portalName,
    providerName: portal.providerName,
    portalUrl: portal.portalUrl,
    portalDescription: portal.portalDescription,
    portalId: portal.portalId,
    personId: portal.personId,
  };

  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [
        USER_PORTALS_UPDATE,
        USER_PORTALS_UPDATE_SUCCESS,
        USER_PORTALS_UPDATE_FAILURE,
      ],
      endpoint: `/api/member/portal`,
      payload,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
          mode: 'cors',
          body: JSON.stringify(payload),
        },
      },
    },
  };
};

export const deleteUserPortal = portal => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      USER_PORTALS_DELETE,
      USER_PORTALS_DELETE_SUCCESS,
      USER_PORTALS_DELETE_FAILURE,
    ],
    endpoint: `/api/member/portal/${portal.portalId}`,
    payload: portal,
    meta: {
      config: {
        method: 'DELETE',
        mode: 'cors',
      },
    },
  },
});
