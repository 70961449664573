import React from 'react';

export default props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#90B850" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8401 7.6175C19.4501 9.5875 20.3201 11.9575 20.4801 14.3775C19.2001 15.0675 18.0201 15.9475 16.9901 17.0075C15.9601 15.9375 14.7801 15.0575 13.5001 14.3775C13.7001 11.9675 14.6201 9.5975 16.2701 7.6175C16.6801 7.1175 17.4301 7.1175 17.8401 7.6175ZM13.8994 16.9722C13.9269 16.9904 13.9538 17.0088 13.9801 17.0275C13.9577 17.0115 13.9348 16.9961 13.9116 16.9809C13.9075 16.978 13.9035 16.9751 13.8994 16.9722ZM13.8994 16.9722C12.2467 15.7869 10.2864 15.0149 8.16005 14.8075C7.52005 14.7475 6.99005 15.2775 7.05005 15.9175C7.50005 20.7175 10.7001 24.6975 15.0301 26.2475C15.6601 26.4775 16.3201 26.6475 17.0001 26.7575C17.6801 26.6375 18.3301 26.4675 18.9701 26.2475C23.3001 24.6975 26.5001 20.7275 26.9501 15.9175C27.0101 15.2775 26.4701 14.7475 25.8401 14.8075C22.1301 15.1675 18.9401 17.2275 16.9901 20.1975C16.1665 18.9431 15.1217 17.8511 13.9116 16.9809C13.8662 16.9514 13.8194 16.923 13.772 16.8943L13.772 16.8943L13.772 16.8943C13.6915 16.8455 13.6094 16.7957 13.5301 16.7375C13.593 16.7795 13.6577 16.8197 13.7219 16.8596C13.7821 16.897 13.8419 16.9341 13.8994 16.9722Z"
      fill="white"
    />
  </svg>
);
