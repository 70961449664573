import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Switch, Typography } from '@material-ui/core';

const RadioSwitchButton = ({ value, options, onChange }) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      style={{
        maxWidth: '175px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-24px',
      }}
    >
      <Grid item xs={5}>
        <Typography style={{ fontSize: '12px' }}>{options[0]}</Typography>
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Switch
          checked={value}
          onChange={() => onChange(!value)}
          name="checkedC"
        />
      </Grid>
      <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography style={{ fontSize: '12px' }}>{options[1]}</Typography>
      </Grid>
    </Grid>
  );
};

RadioSwitchButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
};

export default RadioSwitchButton;
