import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  '@global': {
    html: {
      boxSizing: 'borderBox',
      '&::-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      margin: '0',
      backgroundColor: '#fafafa',
    },
    '*': {
      '&::before': {
        boxSizing: 'inherit',
      },
      '&::after': {
        boxSizing: 'inherit',
      },
    },
    ['@media print']: {
      // eslint-disable-line no-useless-computed-key
      body: {
        backgroundColor: '#fff',
      },
    },
  },
});

function CssBaseline() {
  return null;
}

CssBaseline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CssBaseline);
