import React from 'react';

export default props => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#ED5158" />
    <path
      d="M18.3536 25.1307C17.5936 25.8207 16.4236 25.8207 15.6636 25.1207L15.5536 25.0207C10.3036 20.2707 6.87357 17.1607 7.00357 13.2807C7.06357 11.5807 7.93357 9.95065 9.34357 8.99065C11.9836 7.19065 15.2436 8.03065 17.0036 10.0907C18.7636 8.03065 22.0236 7.18065 24.6636 8.99065C26.0736 9.95065 26.9436 11.5807 27.0036 13.2807C27.1436 17.1607 23.7036 20.2707 18.4536 25.0407L18.3536 25.1307Z"
      fill="white"
    />
  </svg>
);
