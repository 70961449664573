import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import companyLogo from '../../../client/images/agrinhealth.logo.png';
import './Header.css';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 900,
  },
});

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary">
          <Link to="/">
            <img
              style={{ height: '40px' }}
              src={companyLogo}
              alt="Agrin Health"
            />
          </Link>
        </AppBar>
      </div>
    );

    return (
      <header className="site-header dashboard-header">
        <div className="pull-left dashboardHeaderLeft">
          <Link to="/">
            <img width="240" src={companyLogo} alt="Agrin Health" />
          </Link>
        </div>
      </header>
    );
  }
}

export default withStyles(styles)(Header);
