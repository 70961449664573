import {
  localStorageSetObject,
  localStorageGetObject,
} from '../../../library/helpers/fetch';

import {
  APP_INITIAL_SESSION_REQUEST,
  APP_INITIAL_SESSION_SUCCESS,
  APP_INITIAL_SESSION_FAILURE,
  APP_AVATAR_CHANGE_ATTEMPT,
  APP_AVATAR_CHANGE_SUCCESS,
  APP_AVATAR_CHANGE_FAILURE,
  APP_TIMEZONE_CHANGE_REQUEST,
} from './actions';

import { SUBSCRIPTION_CHANGE_SUCCESS } from '../subscription/actions';

import { PERSON_INFO_UPDATE_SUCCESS } from '../../../store/reducers/person/actions';

const initialState = {
  user: null,
  isFetching: true,
  isAuthenticated: false,
  isChangingAvatar: false,
  timeZoneIANA: null,
};

const reducers = {};

reducers[APP_INITIAL_SESSION_REQUEST] = state => ({
  ...state,
  isFetching: true,
});

reducers[APP_INITIAL_SESSION_SUCCESS] = (state, action) => {
  const { dependents, ...user } = action.payload;

  return {
    ...state,
    user,
    isFetching: false,
  };
};

reducers[APP_INITIAL_SESSION_FAILURE] = state => ({
  ...state,
  user: null,
  isFetching: false,
});

reducers[PERSON_INFO_UPDATE_SUCCESS] = (state, action) => {
  if (action.meta.isDependent) {
    return state;
  }

  const { firstName, lastName } = action.payload;
  return {
    ...state,
    user: {
      ...state.user,
      firstName,
      lastName,
    },
  };
};

reducers[APP_AVATAR_CHANGE_ATTEMPT] = (state, _) => {
  return {
    ...state,
    isChangingAvatar: true,
  };
};

reducers[APP_AVATAR_CHANGE_SUCCESS] = (state, action) => {
  const profile = localStorageGetObject('profile');
  localStorageSetObject('profile', {
    ...profile,
    avatarUrl: action.payload.links.self,
  });

  return {
    ...state,
    user: {
      ...state.user,
      avatarUrl: action.payload.links.self,
      avatarPlaceholder: action.payload.base64DataUriString,
    },
    isChangingAvatar: false,
  };
};

reducers[APP_AVATAR_CHANGE_FAILURE] = (state, _) => {
  return {
    ...state,
    isChangingAvatar: false,
  };
};

reducers[APP_TIMEZONE_CHANGE_REQUEST] = (state, action) => {
  return {
    ...state,
    timeZoneIANA: action.payload.timeZoneIANA,
  };
};

reducers[SUBSCRIPTION_CHANGE_SUCCESS] = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      accountRole: action.payload.subscription.accountRole,
      startOn: action.payload.subscription.startOn,
    },
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
