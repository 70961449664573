import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutlinedContainer from '../../../../../containers/FormFields/OutlinedContainer';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

function SleepQuantityField(props) {
  const [fieldHourValue, setHourValue] = useState(0);
  const [fieldMinuteValue, setFieldMinuteValue] = useState(0);
  const [isLoadingProps, setIsLoadingProps] = useState(true);

  useEffect(() => {
    if (isLoadingProps) {
      const hourValue = Math.floor(props.initialValue);
      const minuteValue = Math.round((props.initialValue % 1) * 60);
      //prevent initialValues to affect state when component receives props
      setHourValue(hourValue);
      setFieldMinuteValue(minuteValue);
      setIsLoadingProps(false);
    }
  }, [props.initialValue]);

  const getTotalTimeSleptValue = (hrs, min) => hrs + min / 60;

  const handleChangeField = field => event => {
    const _value = event.target.value ? parseInt(event.target.value) : 0;
    const maxHrs = fieldMinuteValue < 60 ? 24 : 23;
    const maxMins = fieldHourValue < 24 ? 60 : 0;
    switch (field) {
      case 'hours':
        if (_value > maxHrs || _value < 0) return;
        setHourValue(_value);
        props.onChange(getTotalTimeSleptValue(_value, fieldMinuteValue));
        break;

      case 'minutes':
        if (_value > maxMins || _value < 0) return;
        setFieldMinuteValue(_value);
        props.onChange(getTotalTimeSleptValue(fieldHourValue, _value));
        break;
    }
  };

  useEffect(() => {
    const _value = getTotalTimeSleptValue(fieldHourValue, fieldMinuteValue);
    if (props.value !== _value && !isLoadingProps) {
      setHourValue(Math.floor(props.value));
      setFieldMinuteValue(Math.round((props.value % 1) * 60));
    }
  }, [props.value]);

  return (
    <OutlinedContainer
      error={props.error}
      disabled={props.disabled}
      label={props.label.length ? props.label : ''}
    >
      <Grid
        spacing={0}
        style={{ display: 'flex', flexDirection: 'row' }}
        key="3"
      >
        <Grid item xs={6}>
          <Input
            type="number"
            key="2"
            style={{ margin: '0', padding: '0px 10px', height: '18px' }}
            value={fieldHourValue ? fieldHourValue : ''}
            placeholder="0"
            maxLength="2"
            InputProps={{
              inputProps: {
                min: 0,
                max: fieldMinuteValue < 60 ? 24 : 23,
              },
            }}
            onFocus={props.input.onFocus}
            onChange={handleChangeField('hours')}
            endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
            disabled={props.disabled ? props.disabled : false}
            disableUnderline
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="number"
            key="1"
            style={{ margin: '0', padding: '0 10px', height: '18px' }}
            value={fieldMinuteValue ? fieldMinuteValue : ''}
            placeholder="0"
            maxLength="2"
            InputProps={{
              inputProps: {
                min: 0,
                max: fieldHourValue < 24 ? 60 : 0,
              },
            }}
            onFocus={props.input.onFocus}
            endAdornment={<InputAdornment position="end">min</InputAdornment>}
            onChange={handleChangeField('minutes')}
            disabled={props.disabled ? props.disabled : false}
            disableUnderline
            fullWidth
          />
        </Grid>
      </Grid>
    </OutlinedContainer>
  );
}

SleepQuantityField.defaultProps = {
  input: {
    onFocus: () => {},
  },
  label: '',
};

SleepQuantityField.propTypes = {
  initialHourValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  initialMinValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default SleepQuantityField;
