import React from 'react';
import { connect } from 'react-redux';
import FileIcon from '../../../containers/MediaObject/FileIcon';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import '../../../containers/MediaObject/File.css';

const MediaSubmitProgress = ({ bundle }) => {
  const {
    loaded,
    total,
    file: { name },
  } = bundle;
  const calculation = Math.round((loaded / total) * 100);
  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        <FileIcon bundle={bundle} />
      </Grid>
      <Grid
        item
        xs={10}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontWeight: '500', fontSize: '18px' }}>
          {name || ''}
        </Typography>
        <Typography
          style={{ color: '#AEAEAE', fontSize: '14px' }}
          color={calculation === 100 ? 'secondary' : 'initial'}
        >
          {calculation === 100 ? 'Upload Complete!' : `Uploading...`}
        </Typography>
        <div style={{ width: '100%' }}>
          <LinearProgress
            valueBuffer={100}
            variant="determinate"
            value={calculation || 1}
            color="secondary"
          />
        </div>
      </Grid>
    </Grid>
  );
};

// const mapStateToProps = (state, props) => ({
//   loaded: state.mediaObject.bundles[0].loaded,
//   total: state.mediaObject.bundles[0].total,
//   fileName: state.mediaObject.bundles[0].file.name,
// });

export default MediaSubmitProgress;
