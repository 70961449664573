import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { makeAspectCrop, getPixelCrop } from 'react-image-crop';
import { Divider } from '@material-ui/core';
import { SubmissionError } from 'redux-form';
import { orderBy, find } from 'lodash';

import PageHeader from '../../components/PageHeader';

import {
  INSURANCE_CREATE_SUCCESS,
  INSURANCE_CREATE_FAILURE,
  INSURANCE_UPDATE_SUCCESS,
  INSURANCE_UPDATE_FAILURE,
  insuranceCreate,
  insuranceUpdate,
  insuranceClearSelectedId,
  deleteExistingMedia,
} from './actions';

import InsuranceForm from '../../containers/InsurancePolicyForm/InsurancePolicyForm';
import ImageResizeModal from '../../components/ImageResizeModal';

import {
  setFiles,
  clearFiles,
  // } from './../../../store/reducers/mediaObject/actions';
} from '~/src/store/reducers/mediaObject/actions';
// } from '@store/reducers/mediaObject/action';
// } from '~/src/store/reducers/mediaObject/action.js';
import {
  blockNavigationWithStandardMsg,
  unblockNavigation,
} from '../../../store/reducers/ui/actions';

import 'react-image-crop/dist/ReactCrop.css';
// eslint-disable-next-line
import BasePage from '../BasePage';
import { Grid } from '@material-ui/core';
import { CardSelector } from '../../components/CardSelector';
import InsuranceCardPreview from './InsuranceCardPreview';

const ErrorAlert = ({ ...props }) => {
  let modelstate =
    props.error && props.error.modelstate ? props.error.modelstate : [];
  return (
    <span
      className={'alert alert-warning'}
      style={{ display: 'table', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <strong>{props.error.message}</strong>
      <br />
      {Object.keys(modelstate).map((key, i) => {
        return <div key={'error_' + i}>{modelstate[key][0]}</div>;
      })}
    </span>
  );
};

const coverageTypes = [
  { longName: 'Medical', shortName: 'Medical' },
  { longName: 'Dental', shortName: 'Dental' },
  { longName: 'Vision', shortName: 'Vision' },
  { longName: 'Pharmacy', shortName: 'Pharmacy' },
  { longName: 'Behavioral', shortName: 'Behavioral' },
  { longName: 'Supplemental', shortName: 'Supplemental' },
  { longName: 'Auto Liability', shortName: 'Auto Liability' },
  {
    longName: 'Accidental Death Dismemberment ',
    shortName: 'Accidental Death Dismemberment',
  },
  { longName: 'Travel Insurance', shortName: 'Travel Insurance' },
  {
    longName: 'Life Insurance Workers Comprehension',
    shortName: 'Life Insurance Workers Comprehension',
  },
  { longName: 'Other', shortName: 'Other' },
];

const defaultCoordinates = {
  x: 0,
  y: 0,
  width: 350,
  height: 180,
};

class InsurancePolicyPageAddUpdate extends Component {
  state = {
    displayModal: false,
    face: '',

    imgFrontFileName: '',
    imgFront: null,
    imgFrontSrc: null,
    imgFrontCrop: { x: 0, y: 0, width: 0, height: 0 },
    imgFrontPxCrop: defaultCoordinates,
    hasFrontCard: false,

    imgBackFileName: '',
    imgBack: null,
    imgBackSrc: null,
    imgBackCrop: { x: 0, y: 0, width: 0, height: 0 },
    imgBackPxCrop: defaultCoordinates,
    hasBackCard: false,
  };

  componentWillMount() {
    const { selectedId, history, action } = this.props;

    if (!selectedId && action !== 'CREATE') {
      history.goBack();
    }
  }

  componentWillUnmount() {
    const { insuranceClearSelectedId, unblockNavigation } = this.props;

    insuranceClearSelectedId();
    unblockNavigation();
  }

  handleSubmit = async (values, dispatch, parentProps) => {
    const {
      personId,
      selectedId,
      bundles,
      insuranceCreate,
      insuranceUpdate,
      clearFiles,
      unblockNavigation,
    } = this.props;
    const { dashboard } = this.props.navigation;

    let response;
    if (!selectedId) {
      response = await insuranceCreate(personId, values, bundles);
    } else {
      response = await insuranceUpdate(personId, values, bundles);
    }

    if (
      response.type === INSURANCE_CREATE_SUCCESS ||
      response.type === INSURANCE_UPDATE_SUCCESS
    ) {
      clearFiles();
      unblockNavigation();
      insuranceClearSelectedId();
      this.props.history.push(dashboard.account.insurance.read);
    } else if (
      response.type === INSURANCE_CREATE_FAILURE ||
      response.type === INSURANCE_UPDATE_FAILURE
    ) {
      throw new SubmissionError(response.payload);
    }
  };

  onFormChange = (_v, dispatch, props) => {
    const { pristine } = props;
    const {
      selectedId,
      ui: { isNavigationLocked },
    } = this.props;

    if (selectedId && !pristine && !isNavigationLocked) {
      dispatch(blockNavigationWithStandardMsg());
    }
  };

  onFormCancel = () => {
    const { dashboard } = this.props.navigation;
    const { history } = this.props;

    history.push(dashboard.account.insurance.read);
  };

  onCardFaceSelect = face => {
    const key = face === 'FRONT' ? 'Front' : 'Back';

    this.setState({
      displayModal: true,
      // Only display the card once the user has make a selection.
      // disable display while editing.
      [`has${key}Card`]: false,
      face,
    });
  };

  handleModalClose = () => {
    const { face } = this.state;

    const key = face === 'FRONT' ? 'Front' : 'Back';

    this.setState({
      [`has${key}Card`]: false,
      displayModal: false,
    });
  };

  onFileSelect = (face, { target }, fileName) => {
    const { files } = target;

    const key = face === 'FRONT' ? 'Front' : 'Back';

    if (files && files.length > 0) {
      if (face === 'FRONT') {
        const fileReader1 = new FileReader();
        fileReader1.addEventListener('load', event => {
          this.setState({
            imgFrontSrc: event.target.result,
          });
        });

        fileReader1.readAsDataURL(files[0]);
      } else {
        const fileReader2 = new FileReader();
        fileReader2.addEventListener(
          'load',
          event => {
            this.setState({
              imgBackSrc: event.target.result,
            });
          },
          false
        );

        fileReader2.readAsDataURL(files[0]);
      }

      this.setState({
        [`img${key}FileName`]: files[0].name,
        [`img${key}File`]: files[0],
        displayModal: true,
        face,
        //[`has${key}Card`]: true
      });
    }
  };

  onImageSelect = () => {
    const {
      face,
      imgFrontFile,
      imgFrontPxCrop,
      hasFrontCard,
      imgBackFile,
      imgBackPxCrop,
      hasBackCard,
    } = this.state;

    const { bundles, setFiles } = this.props;

    let temp = [null];

    const isFrontCard = face === 'FRONT';
    const key = isFrontCard ? 'Front' : 'Back';

    if (isFrontCard) {
      temp[0] = {
        file: imgFrontFile,
        description: 'insurance card front',
        loaded: 0,
        total: imgFrontFile.size,
        isUploading: false,
        isUploaded: false,
        cardFace: face,
        x: imgFrontPxCrop.x,
        y: imgFrontPxCrop.y,
        width: imgFrontPxCrop.width,
        height: imgFrontPxCrop.height,
      };
    } else {
      temp[1] = {
        file: imgBackFile,
        description: 'insurance card back',
        loaded: 0,
        total: imgBackFile.size,
        isUploading: false,
        isUploaded: false,
        cardFace: face,
        x: imgBackPxCrop.x,
        y: imgBackPxCrop.y,
        width: imgBackPxCrop.width,
        height: imgBackPxCrop.height,
      };
    }

    if (hasFrontCard && !isFrontCard) {
      temp[0] = bundles[0];
    } else if (hasBackCard && isFrontCard) {
      temp[1] = bundles[1];
    }

    this.setState({
      displayModal: false,
      [`has${key}Card`]: true,
      localBundle: temp,
    });

    setFiles(temp);

    // this.handleModalClose();
  };

  onCropChange = (face, crop, pxCrop) => {
    const key = face === 'FRONT' ? 'Front' : 'Back';

    this.setState({
      [`img${key}Crop`]: crop,
      [`img${key}PxCrop`]: pxCrop,
    });
  };

  placeholderForChangeEvents = event => {
    console.log('Event', event);
  };

  onImageLoaded = (face, image) => {
    const key = face === 'FRONT' ? 'Front' : 'Back';
    const units = 50;

    const nextCrop = makeAspectCrop(
      {
        x: units / 2,
        y: units / 2,
        aspect: 16 / 9,
        width: units,
      },
      image.width / image.height
    );

    this.setState({
      [`img${key}`]: image,
      [`img${key}Crop`]: nextCrop,
      [`img${key}PxCrop`]: getPixelCrop(image, nextCrop),
    });
  };

  onCropComplete = (face, crop, pxCrop) => {
    this.onCropChange(face, crop, pxCrop);
  };

  renderModal = () => {
    const {
      face,
      imgFrontFileName,
      imgFrontSrc,
      imgFrontCrop,
      //imgFrontPxCrop,
      imgBackFileName,
      imgBackSrc,
      imgBackCrop,
      //imgBackPxCrop,
      maxHeight,
    } = this.state;

    const isFrontFace = face === 'FRONT';
    const faceText =
      face && face.charAt(0).toUpperCase() + face.substr(1).toLowerCase();
    const hasImage =
      (isFrontFace && imgFrontSrc !== null) ||
      (!isFrontFace && imgBackSrc !== null);

    return (
      <ImageResizeModal
        isDisplayed={this.state.displayModal}
        hasImage={hasImage}
        imageSrc={isFrontFace ? imgFrontSrc : imgBackSrc}
        imageCrop={isFrontFace ? imgFrontCrop : imgBackCrop}
        imageName={isFrontFace ? imgFrontFileName : imgBackFileName}
        dialogTitleText={`Insurance Card ${faceText}`}
        dialogDescriptionText={`Select an image for the ${faceText} of the insurance card.`}
        selectFileText={`Select ${faceText} Card Image`}
        changeFileText={`Change ${faceText} Card Image`}
        onChange={this.onImageSelect}
        onClose={this.handleModalClose}
        onImageSelect={(event, fileName) =>
          this.onFileSelect(face, event, fileName)
        }
        onImageLoaded={image => this.onImageLoaded(face, image)}
        onCropChange={(crop, pxCrop) => this.onCropChange(face, crop, pxCrop)}
        onCropComplete={(crop, pxCrop) =>
          this.onCropComplete(face, crop, pxCrop)
        }
      />
    );
  };

  onCardSelection = (face, imageState) => {};

  render() {
    const {
      imgFrontFileName,
      imgFront,
      imgFrontPxCrop,
      hasFrontCard,
      imgBackFileName,
      imgBack,
      imgBackPxCrop,
      hasBackCard,
    } = this.state;

    const { dashboard } = this.props.navigation;
    const { errorMessage, action, bundles } = this.props;
    const entityAction = action === 'CREATE' ? 'New' : 'Update';

    const frontCardBundle = bundles ? bundles[0] : false;
    const backCardBundle = bundles ? bundles[1] : false;

    return (
      <BasePage>
        <Grid item xs={12} lg={8}>
          {this.renderModal()}

          <PageHeader
            headerText="Insurance Policies"
            subHeaderText={`${entityAction} Entry`}
            backButtonText="Go Back"
            backLink={dashboard.account.insurance.read}
          />
          <Divider style={{ marginTop: 24, marginBottom: 24 }} />
          {/* {errorMessage && <ErrorAlert error={errorMessage} />} */}
          <InsuranceForm
            onCancel={this.onFormCancel}
            onSubmit={this.handleSubmit}
            onChange={this.onFormChange}
            coverageTypes={coverageTypes}
            insuranceCardSlot={
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <CardSelector
                    onSelection={imageState => {
                      console.log(
                        'Front Insurance Card Image Selected',
                        imageState
                      );
                      setFiles();
                    }}
                    render={({ imageSrc }) => (
                      <InsuranceCardPreview face="Front" src={imageSrc} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardSelector
                    onSelection={imageState =>
                      console.log(
                        'Back Insurance Card Image Selected',
                        imageState
                      )
                    }
                    render={({ imageSrc }) => (
                      <InsuranceCardPreview face="Back" src={imageSrc} />
                    )}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </BasePage>
    );
  }
}

const useSelectedCards = () => {
  const [cards, setCards] = useState(null);

  const setCard = (face, data) => {
    setCards(prevState => ({
      ...prevState,
      [face]: data,
    }));
  };

  const clearCard = face => {
    const _cards = { ...cards };
    delete _cards[face];
    setCards(_cards);
  };

  return { cards, setCard, clearCard };
};

const mapCardStateToMediaObjectBundle = (face, card) => {
  return {
    file: card.dest,
    description: `insurance card ${face}`,
    loaded: 0,
    total: card.dest.size,
    isUploading: false,
    isUploaded: false,
    cardFace: face,
    x: card.imageState.crop.x,
    y: card.imageState.crop.y,
    width: card.imageState.crop.width,
    height: card.imageState.crop.height,
  };
};

const NewPage = rest => {
  const {
    selectedId,
    history,
    action,
    bundles,
    selectedInsurance,
    insuranceClearSelectedId,
    deleteMedia,
    ...props
  } = rest;
  const [defualtCardFront, setDefaultCardFront] = useState(null);
  const [defualtCardBack, setDefaultCardBack] = useState(null);
  const [mediaObjectsToRemoveIds, setMediaObjectsToRemoveIds] = useState(null);

  const { cards, setCard, clearCard } = useSelectedCards();

  useEffect(() => {
    if (!selectedId && action !== 'CREATE') history.goBack();

    return () => {
      insuranceClearSelectedId();
      unblockNavigation();
    };
  }, [selectedId, action]);

  useEffect(() => {
    if (!selectedInsurance?.mediaObject) return;

    const mediaObjects = orderBy(
      selectedInsurance.mediaObject,
      ['createdOn', 'meta.imageType'],
      ['desc', 'desc']
    );
    const firstInsuranceCardFront = find(mediaObjects, {
      meta: { imageType: 'FRONT' },
    });
    const firstInsuranceCardBack = find(mediaObjects, {
      meta: { imageType: 'BACK' },
    });

    setDefaultCardFront(firstInsuranceCardFront);
    setDefaultCardBack(firstInsuranceCardBack);
  }, [selectedInsurance]);

  const handleFormSubmit = async values => {
    const {
      personId,
      insuranceCreate,
      insuranceUpdate,
      clearFiles,
      unblockNavigation,
      setFiles,
    } = props;

    const { dashboard } = props.navigation;

    var newBundles = [];

    if (cards) {
      if (cards['FRONT'])
        newBundles[0] = mapCardStateToMediaObjectBundle(
          'FRONT',
          cards['FRONT']
        );
      if (cards['BACK'])
        newBundles[1] = mapCardStateToMediaObjectBundle('BACK', cards['BACK']);
      setFiles(newBundles);
    }

    const response = !selectedId
      ? await insuranceCreate(personId, values, newBundles)
      : await insuranceUpdate(
          personId,
          { ...(values ?? {}), mediaObjectsToRemoveIds },
          newBundles
        );

    if (
      response.type === INSURANCE_CREATE_SUCCESS ||
      response.type === INSURANCE_UPDATE_SUCCESS
    ) {
      clearFiles();
      unblockNavigation();
      history.push(dashboard.account.insurance.read);
      insuranceClearSelectedId();
    } else if (
      response.type === INSURANCE_CREATE_FAILURE ||
      response.type === INSURANCE_UPDATE_FAILURE
    ) {
      throw new SubmissionError(response.payload);
    }
  };

  const handleFormChange = (_v, dispatch, formProps) => {
    const { pristine } = formProps;
    const {
      selectedId,
      ui: { isNavigationLocked },
    } = props;

    if (selectedId && !pristine && !isNavigationLocked) {
      dispatch(blockNavigationWithStandardMsg());
    }
  };

  const handleFormCancel = () => {
    const { dashboard } = props.navigation;
    history.push(dashboard.account.insurance.read);
  };

  const handleCardSelection = face => imageState => {
    console.log(`${face} insurance card image selected`, imageState);
    const normalizeKey = face.toUpperCase();
    setCard(normalizeKey, imageState);
  };

  const handleCardDelete = async (value, face) => {
    console.log('handleCardDelete: ', value, face);

    // Handles the case when the card is virtual: meaing found on the client machine in
    // memory, not saved on the API.
    const normalizedFace = face.toUpperCase();
    if (typeof value === 'string' && value.includes('blob')) {
      clearCard(normalizedFace);
      return;
    }

    // Handles the case when the card has already been saved on the API
    // NOTE: doesn't actually remove the card. When the form update is triggered, the card is removed.
    switch (normalizedFace) {
      case 'FRONT':
        setDefaultCardFront(null);
        break;
      case 'BACK':
        setDefaultCardBack(null);
        break;
      default:
        throw new Error(`Face ${face} not supported`);
    }

    // Record media object ID to remove. Note: media object ID associated with insurance record is not validated on
    // the client side. The API will only remove the assocaited insurance media object if it's truely associated
    // with the insurance record.
    setMediaObjectsToRemoveIds(prevState =>
      [].concat(prevState ?? [], value.mediaObjectId)
    );
  };

  const entityAction = action === 'CREATE' ? 'New' : 'Update';
  const frontCardBundle = bundles ? bundles[0] : false;
  const backCardBundle = bundles ? bundles[1] : false;

  return (
    <BasePage>
      <Grid item xs={12} lg={8}>
        <PageHeader
          headerText="Insurance Policies"
          subHeaderText={`${entityAction} Entry`}
          backButtonText="Go Back"
          backLink={props.navigation.dashboard.account.insurance.read}
        />
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        {/* {errorMessage && <ErrorAlert error={errorMessage} />} */}
        <InsuranceForm
          onCancel={handleFormCancel}
          onChange={handleFormChange}
          onSubmit={handleFormSubmit}
          hasNewInsuranceImages={
            !!(cards && Object.keys(cards).length) ||
            mediaObjectsToRemoveIds?.length > 0
          }
          coverageTypes={coverageTypes}
          insuranceCardSlot={
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <>
                  <CardSelector
                    card={cards ? cards['FRONT'] : null}
                    onSelection={handleCardSelection('Front')}
                    render={({ imageSrc }) => (
                      <InsuranceCardPreview
                        currentSelection={defualtCardFront}
                        onDelete={handleCardDelete}
                        face="Front"
                        src={imageSrc}
                      />
                    )}
                  />
                  {frontCardBundle ? frontCardBundle.file.name : null}
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                  <CardSelector
                    card={cards ? cards['BACK'] : null}
                    onSelection={handleCardSelection('Back')}
                    render={({ imageSrc }) => (
                      <InsuranceCardPreview
                        currentSelection={defualtCardBack}
                        onDelete={handleCardDelete}
                        face="Back"
                        src={imageSrc}
                      />
                    )}
                  />
                  {backCardBundle ? backCardBundle.file.name : null}
                </>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = state => ({
  action: state.ui.entity.action,
  personId: state.active.user.personId,
  errorMessage: state.member.insurance.errorMessage,
  bundles: state.mediaObject.bundles,
  ui: state.ui,
  action: state.ui.entity.action,
  selectedId: state.member.insurance.selectedId,
  selectedInsurance: state.member.insurance?.selectedId
    ? state.member.insurance.list[state.member.insurance.selectedId]
    : null,
});

const mapDispatchToProps = dispatch => ({
  setFiles: files => dispatch(setFiles(files)),
  deleteMedia: (pid, aid) => dispatch(deleteExistingMedia(pid, aid)),
  clearFiles: () => dispatch(clearFiles()),
  insuranceCreate: (id, data, bundles) =>
    dispatch(insuranceCreate(id, data, bundles)),
  insuranceUpdate: (id, data, bundles) =>
    dispatch(insuranceUpdate(id, data, bundles)),
  insuranceClearSelectedId: () => dispatch(insuranceClearSelectedId()),
  unblockNavigation: () => dispatch(unblockNavigation()),
});

export default {
  component: connect(mapStateToProps, mapDispatchToProps)(withRouter(NewPage)),
};
