import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormContainer from './FormContainer';

const MVSDrawer = props => {
  const { open, setOpen, clearFiles } = props;
  const matches550 = useMediaQuery('(min-width: 550px)'); //mobile

  const handleOnClose = () => {
    clearFiles();
    setOpen(false);
  };
  return (
    <Drawer anchor="right" onClose={handleOnClose} open={open}>
      <FormContainer onCloseDrawer={handleOnClose} matches550={matches550} />
    </Drawer>
  );
};

export default MVSDrawer;
