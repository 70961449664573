
import {
  UI_CANCELED_FORM,
  UI_CANCELED_FORM_RESET,
  UI_STATE_ENTITY_CREATE,
  UI_STATE_ENTITY_UPDATE,
  UI_NAVIGATION_LOCKED,
  UI_NAVIGATION_UNLOCK
} from "./actions";

const initialState = {
  isFormCanceled: false,
  isNavigationLocked: false,
  lockedMessage: null,
  entity: {
    subject: '',
    action: ''
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case UI_CANCELED_FORM:
      return {
        ...state, 
        isFormCanceled: true
      }

    case UI_CANCELED_FORM_RESET:
      return {
        ...state,
        isFormCanceled: false,
        entity: initialState.entity
      }

    case UI_STATE_ENTITY_CREATE:
      return {
        ...state,
        entity: {
          subject: action.payload,
          action: 'CREATE'
        }
      }

    case UI_STATE_ENTITY_UPDATE:
      return {
        ...state,
        entity: {
          subject: action.payload,
          action: 'UPDATE'
        }
      }
    
    case UI_NAVIGATION_LOCKED:
      return {
        ...state,
        isNavigationLocked: true,
        lockedMessage: action.payload
      }

    case UI_NAVIGATION_UNLOCK:
      return {
        ...state,
        isNavigationLocked: false,
        lockedMessage: null
      }
    
    default:
      return state;
  }
  
}
