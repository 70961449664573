import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, CircularProgress, Grid } from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';

function sortDesc(a, b, orderBy) {
  const typeOfField = typeof a.columns[orderBy];

  const result =
    typeOfField === 'number'
      ? byNumber(a.columns, b.columns, orderBy)
      : a.columns[orderBy] instanceof Date && b.columns[orderBy] instanceof Date
      ? byDate(a.columns, b.columns, orderBy)
      : byString(
          getString(a.columns, orderBy).toLowerCase(),
          getString(b.columns, orderBy).toLowerCase(),
          orderBy
        );

  return result;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => +sortDesc(a, b, orderBy)
    : (a, b) => -sortDesc(a, b, orderBy);
}

function getString(o, field) {
  return !o[field]
    ? ''
    : typeof o[field] === 'string'
    ? o[field]
    : ('' + o[field]).toString();
}

function byDate(a, b, field) {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  // TODO -- Fix broken sort which doesn't put null records
  // at the bottom regardless of sort order direction. Temporarily
  // push nulls to bottom on "DESC" sort for better user experience.
  if (!l) return -1;
  else if (!r) return 1;

  const lx = l.getTime();
  const rx = r.getTime();

  return lx < rx ? -1 : lx > rx ? 1 : 0;
}

function byNumber(a, b, field) {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  // TODO -- Fix broken sort which doesn't put null records
  // at the bottom regardless of sort order direction. Temporarily
  // push nulls to bottom on "DESC" sort for better user experience.
  if (!l) return -1;
  else if (!r) return 1;

  return l - r;
}

function byString(a, b, field) {
  const l = field ? a[field] : a;
  const r = field ? b[field] : b;

  // TODO -- Fix broken sort which doesn't put null records
  // at the bottom regardless of sort order direction. Temporarily
  // push nulls to bottom on "DESC" sort for better user experience.
  if (!l) return -1;
  else if (!r) return 1;

  return l < r ? -1 : l > r ? 1 : 0;
}

function ReportModalTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    selected,
    rows,
    onRequestSort,
    headers,
    template,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const numSelected = selected.reduce((acc, curr) => {
    if (curr.recordType === template) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const isIndetermined =
    (numSelected > 0 && numSelected < rows.length) || numSelected > rows.length;

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.head} padding="checkbox">
          <Checkbox
            indeterminate={isIndetermined}
            checked={numSelected === rows.length}
            onChange={onSelectAllClick(isIndetermined)}
            inputProps={{ 'aria-label': 'select all record types' }}
          />
        </TableCell>
        {headers.map((headCell, index) => (
          <TableCell
            key={uuidv1()}
            className={classes.head}
            align={headCell.numeric ? 'right' : 'left'}
            padding={index === 0 ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    height: '40vh',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  col: {
    wordWrap: 'break-word',
    maxWidth: '250px',
  },
  head: {
    backgroundColor: '#fff',
    position: 'sticky',
    zIndex: 10,
    top: 0,
  },
});

export default withStyles(styles)(function ReportModalTable(props) {
  const {
    classes,
    handleClick,
    handleSelectAllClick,
    selected,
    rows,
    headers,
    template,
    loading,
    resourceType,
  } = props;
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState(headers[0].id);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const startDateIdx = headers.map(h => h.id).indexOf('startDate');
    const dateIdx = headers.map(h => h.id).indexOf('date');

    const idx =
      startDateIdx !== -1 ? startDateIdx : dateIdx !== -1 ? dateIdx : 0;

    setSortField(headers[idx].id);
  }, [template, rows]);

  useEffect(() => {
    //
    if (resourceType === 'MEDICAL_HX') setSortOrder('desc');
  }, [resourceType]);

  function indexOfHeader(id) {
    const idx = headers.map(h => h.id).indexOf(id);
    return idx !== -1 ? idx : 0;
  }

  function handleRequestSort(_, property) {
    const isDesc = sortField === property && sortOrder === 'desc';
    setSortOrder(isDesc ? 'asc' : 'desc');
    setSortField(property);
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const isSelected = id => selected.findIndex(x => x.recordId === id) >= 0;

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        {loading ? (
          <Grid
            style={{ height: '100%' }}
            container
            justify="center"
            alignItems="center"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <ReportModalTableHead
              classes={classes}
              headers={headers}
              selected={selected}
              order={sortOrder}
              orderBy={sortField}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rows={rows}
              template={template}
            />
            <TableBody>
              {stableSort(rows, getSorting(sortOrder, indexOfHeader(sortField)))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={uuidv1()}
                      hover
                      onClick={event =>
                        handleClick(event, {
                          id: row.id,
                          recordType: row.data.recordType,
                          text:
                            row.data.recordType === 'MEDICAL_HX'
                              ? row.data.condition
                              : undefined,
                          isTemp:
                            row.data.recordType === 'MEDICAL_HX' && row.isTemp
                              ? true
                              : false,
                        })
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={uuidv1()}
                      selected={isItemSelected}
                    >
                      <TableCell style={{ width: 60 }} padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {row.columns.map((col, index) =>
                        index === 0 ? (
                          <TableCell
                            key={uuidv1()}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className={classes.col}
                          >
                            {col}
                          </TableCell>
                        ) : headers[index].type === 'date' ||
                          headers[index].type === 'datetime' ? (
                          <TableCell
                            key={uuidv1()}
                            className={classes.col}
                            style={{ minWidth: 230 }}
                            align={
                              headers[index]
                                ? headers[index].numeric
                                  ? 'right'
                                  : 'left'
                                : 'left'
                            }
                          >
                            {col && col instanceof Date
                              ? format(
                                  col,
                                  headers[index].type === 'datetime'
                                    ? 'MM/dd/yyyy @ hh:mm a'
                                    : 'MM/dd/yyyy'
                                )
                              : '--'}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={uuidv1()}
                            className={classes.col}
                            style={{
                              minWidth: 230,
                              padding:
                                typeof col === 'number' ? '16px 50px' : '16px',
                            }}
                            align={
                              headers[index]
                                ? headers[index].numeric
                                  ? 'right'
                                  : 'left'
                                : 'left'
                            }
                          >
                            {col}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
});
