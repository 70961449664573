import React from 'react';
import { FormCard } from '../../Components/FormCard/FormCard';
import { Grid } from '@material-ui/core';
import { identifyingInformationFields, otherInformationFields } from './fields';
import { format } from 'date-fns';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ProgressButton from '../../../../components/Buttons/ProgressButton';

function AddPetDependent(props) {
  const { codeTypes, valid, handleSubmit, isSubmitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormCard
            title="Identifying Information"
            fields={identifyingInformationFields()}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCard
            title="Other Information"
            fields={otherInformationFields(codeTypes)}
            renderDefaultAddress={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <ProgressButton
            style={{ width: '150px' }}
            isPending={isSubmitting}
            disabled={!valid}
            type="submit"
          >
            Save
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  );
}

const mapStateToProps = state => ({
  codeTypes: state.codes,
  initialValues:
    state.member && state.member.basicInfo && state.member.basicInfo.userInfo
      ? {
          ...state.member.basicInfo.userInfo,
          dob: state.member.basicInfo.userInfo.dob
            ? format(
                new Date(state.member.basicInfo.userInfo.dob),
                'yyyy-MM-dd'
              )
            : '',
        }
      : {},
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'new-dependent-form',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddPetDependent)
);
