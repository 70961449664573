export const isEmailAddressUnique = email => {
  const options = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ email }),
  };

  return new Promise(resolve =>
    fetch(
      `${window.__env.API_URL}/api/registration/email/unique`,
      options
    ).then(response =>
      response.json().then(json => {
        if (!response.ok && json.code === 14) {
          resolve(false);
        }
        resolve(true);
      })
    )
  );
};

export default (values, dispatch, props, currentFieldName) => {
  const { emailDirty } = props;
  const previousErrors = props.asyncErrors;

  if (emailDirty && currentFieldName === 'email') {
    return isEmailAddressUnique(values.email).then(result => {
      if (!result) {
        throw {
          ...previousErrors,
          email: 'Email address is already taken. Please try another',
        };
      }
    });
  }

  return new Promise(resolve => resolve(true));
};
