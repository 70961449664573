import React, { useEffect, useState } from 'react';
import BasePage from '../../BasePage';
import { Grid, Button, Icon, Divider, Fade } from '@material-ui/core';
import SurgicalCard from '../SurgicalCard';
import SurgicalDrawer from '../SurgicalDrawer';
import PageHeader from '../../../components/PageHeader';
import { useStyles } from './styles';
import { UPDATE, CREATE, IDLE, VIEW } from '../Contents/useSurgicalHistory';
import SurgicalTable from '../SurgicalTable/SurgicalTable';

const SurgicalHistoryPage = props => {
  const [status, setStatus] = useState(IDLE);
  const {
    navigation: { dashboard },
    surgicalHistory,
    user,
    clearFiles,
  } = props;
  const xclasses = useStyles();

  useEffect(() => {
    props.fetchSurgicalHistories(user.personId);
  }, []);

  useEffect(() => {
    if (user.personId !== surgicalHistory.personId) {
      props.fetchSurgicalHistories(user.personId);
    }
  }, [user.personId, props.surgicalHistory]);

  useEffect(() => {
    if (
      !props.hasFetchedSurgicalHistoryType &&
      !props.isFetchingSurgicalHistoryType
    )
      props.fetchSurgicalHistoryType();
  }, [user.personId]);

  useEffect(() => {
    if (status === IDLE) {
      clearFiles();
    }
  }, [status]);

  const onClickUpdateCard = selectedId => () => {
    console.log(selectedId);
    props.setSelectedId(selectedId);
    setStatus(UPDATE);
  };

  const onClickDeleteCard = data => {
    console.log(data);
    props.deleteSurgicalHistory(
      user.personId,
      data.surgicalHistoryId,
      data.surgicalHistoryId
    );
    props.clearSelectedId();
  };

  const onClickViewCard = selectedId => {
    props.setSelectedId(selectedId);
    setStatus(UPDATE);
  };

  const onCloseDrawer = () => {
    props.clearSelectedId();
    props.reset();
    setStatus(IDLE);
  };

  return (
    <BasePage
      state={surgicalHistory.uiState}
      emptyStateComponentProps={{
        name: 'Surgery',
        type: 'Surgical History',
        onClick: () => setStatus(CREATE),
      }}
      DrawerComponent={
        <SurgicalDrawer
          status={status}
          onClose={onCloseDrawer}
          setStatus={setStatus}
        />
      }
    >
      <PageHeader
        headerText="Surgeries"
        subHeaderText="View Records"
        backButtonText="Go Back"
        backLink={dashboard.healthRecord.self}
        rightSlot={
          <Button
            color="primary"
            variant="contained"
            aria-label="Add"
            onClick={() => setStatus(CREATE)}
            style={{ float: 'right' }}
          >
            <Icon>add</Icon>
            New Record
          </Button>
        }
      />
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Grid item xs={12}>
        <SurgicalTable
          listIds={surgicalHistory.listIds}
          list={surgicalHistory.list}
          onEdit={onClickViewCard}
          onDelete={onClickDeleteCard}
        />
      </Grid>
    </BasePage>
  );
};

export default SurgicalHistoryPage;
