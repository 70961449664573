import React from 'react';
import {
  Grid,
  InputAdornment,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Field } from 'redux-form';

import InputField from '../../../library/forms/InputField';
import { mask } from '../../../library/helpers/format';

const postalCodeMask = value => mask(value, 'XXXXX-XXXX');

const postalCodeNormalizer = value => {
  // If the postal code is shorter than the long format
  // remove the dash, else preserve.
  if (value.length < 10) {
    return value.replace('-', '');
  }
  console.log('Normalizer', value);
  return value;
};

const GeneralSignupFormFields = ({ disabled = false }) => {
  const [toolTips, setTooltips] = React.useState({
    zip: false,
    dob: false,
  });

  const openTooltip = name => () => {
    setTooltips({ ...toolTips, [name]: true });
  };

  const closeTooltip = name => () => {
    setTooltips({ ...toolTips, [name]: false });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name="firstName"
            label="First Name"
            type="text"
            hint="Required"
            autoComplete="off"
            disabled={disabled}
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="lastName"
            label="Last Name"
            hint="Required"
            type="text"
            autoComplete="off"
            disabled={disabled}
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="dob"
            label="Date of Birth"
            type="date"
            hint="Required"
            max="2999-12-31"
            autoComplete="off"
            shrink
            disabled={disabled}
            component={InputField}
            props={{
              HoverTooltipMsg: (
                <React.Fragment>
                  <p>
                    Users must be 18 years of age to create an account.{'\n'}
                  </p>
                  <p>
                    WHY ACCURACY MATTERS:{'\n'}
                    <br />
                    Most Providers require a DOB to exchange information in
                    order to insure that it is connected to the right patient.
                    If you plan to exchange data with your doctor or other
                    Providers, it will be necessary to enter an accurate DOB.
                    Additionally, DOB is used in some health calculations and
                    screening recommendations. A missing or false DOB could
                    break these informative features.
                  </p>
                </React.Fragment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="postalCode"
            label="Zip Code"
            type="text"
            hint="Required (Only USA zip codes allowed at this time)"
            placeholder="XXXXX or XXXXX-XXXX"
            inputProps={{ maxLength: 10 }}
            component={InputField}
            format={postalCodeMask}
            normalize={postalCodeNormalizer}
            props={{
              HoverTooltipMsg: (
                <p>
                  Users must be residents of the United States of America to
                  create an account.
                </p>
              ),
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GeneralSignupFormFields;
