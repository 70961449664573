//import 'whatwg-fetch';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';
import { createQueryStringFromObject } from '../../../library/helpers/encode';

export const FAMILY_HISTORY_REQUEST = 'family-history/REQUEST';
export const FAMILY_HISTORY_SUCCESS = 'family-history/SUCCESS';
export const FAMILY_HISTORY_FAILURE = 'family-history/FAILURE';

export const FAMILY_HISTORY_UPDATE_REQUEST = 'family-history/update/REQUEST';
export const FAMILY_HISTORY_UPDATE_SUCCESS = 'family-history/update/SUCCESS';
export const FAMILY_HISTORY_UPDATE_FAILURE = 'family-history/update/FAILURE';

export const FAMILY_HISTORY_BULK_UPDATE_REQUEST =
  'family-history/update/bulk/REQUEST';
export const FAMILY_HISTORY_BULK_UPDATE_SUCCESS =
  'family-history/update/bulk/SUCCESS';
export const FAMILY_HISTORY_BULK_UPDATE_FAILURE =
  'family-history/update/bulk/FAILURE';

export const SET_SELECTED_CONDITIONS = 'FamilyHistory | set conditions';
export const CLEAR_SELECTED_CONDITIONS = 'FamilyHistory | clear conditions';

export const setSelectedConditions = payload => ({
  type: SET_SELECTED_CONDITIONS,
  payload,
});

export const clearSelectedConditions = () => ({
  type: CLEAR_SELECTED_CONDITIONS,
});

export const fetchFamilyHistory = pid => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      FAMILY_HISTORY_REQUEST,
      FAMILY_HISTORY_SUCCESS,
      FAMILY_HISTORY_FAILURE,
    ],
    endpoint: `/api/member/history/family`,
    meta: {
      config: {
        headers: { 'Content-Type': 'application/json', Pid: pid },
      },
    },
  },
});

export const updateFamilyHistoryCondition = (pid, payload) => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      FAMILY_HISTORY_UPDATE_REQUEST,
      FAMILY_HISTORY_UPDATE_SUCCESS,
      FAMILY_HISTORY_UPDATE_FAILURE,
    ],
    endpoint: `/api/member/history/family`,
    payload,
    meta: {
      tid: payload.tid,
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify({
          personId: pid,
          ...payload,
        }),
        mode: 'cors',
      },
    },
  },
});

export const updateFamilyHistoryConditionBulk = payload => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [
      FAMILY_HISTORY_BULK_UPDATE_REQUEST,
      FAMILY_HISTORY_BULK_UPDATE_SUCCESS,
      FAMILY_HISTORY_BULK_UPDATE_FAILURE,
    ],
    endpoint: `/api/member/history/family/bulk`,
    payload,
    meta: {
      tid: payload.tid,
      config: {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify({
          records: payload,
        }),
        mode: 'cors',
      },
    },
  },
});
