import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles, Tooltip, Icon } from '@material-ui/core';
import classnames from 'classnames';

import './MyHealthRecordButton.css';

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
    background: '#F5F5F5',
    textAlign: 'center',
    padding: theme.spacing(2),
    fontSize: '.9em',
    boxShadow: '2px 2px 0px 1px #e0e0e0',
    border: 'solid 1px #ddd',
    borderRadius: 3,
    transition: '.1s ease',
    '&:hover': {
      background: '#eee',
      boxShadow: 'none',
      textDecoration: 'none',
      transition: '.1s ease',
    },
  },
  disabledForSync: {
    display: 'block',
    width: '100%',
    height: '100%',
    background: '#F5F5F5',
    textAlign: 'center',
    color: '#666',
    opacity: 0.5,
    pointerEvents: 'none',
    padding: theme.spacing(2),
    fontSize: '.9em',
    boxShadow: '2px 2px 0px 1px #e0e0e0',
    border: 'solid 1px #ddd',
    borderRadius: 3,
    transition: '.1s ease',
    '&:hover': {},
  },
  glyphicon: {
    marginBottom: theme.spacing(1),
    fontSize: theme.spacing(6),
    height: '100%',
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    paddingTop: '2px',
  },
  disabled: {
    background: '#F5F5F5',
    opacity: 0.5,
    color: '#666',
    cursor: 'not-allowed',
    transition: 'all .2s',
    '&:hover': {
      background: '#F5F5F5',
      color: '#666',
      boxShadow: 'none',
      textDecoration: 'none',
      boxShadow: '2px 2px 0px 1px #e0e0e0',
      opacity: 0.25,
    },
  },
  popper: {
    top: `-80px !important`,
  },
  tooltip: {
    position: 'absolute',
    top: 'calc(50% - 5px)',
    left: 'calc(50% - 45px)',
    backgroundColor: 'rgba(0,0,0,.8)',
    textAlign: 'center',
    color: '#fff',
    opacity: 0,
    padding: '4px 8px',
    transition: 'all .14s',
    fontSize: '12px',
    transform: 'scale(0.9)',
    borderRadius: 4,
    zIndex: 10,
  },
  active: {
    opacity: 1,
    transform: 'scale(1)',
  },
  buttonLabel: {
    minHeight: '34px',
  },
  glyphContainer: {
    height: '56px',
    width: '56px',
  },
});

const MyHealthRecordButton = props => {
  const { classes } = props;

  const [isHovered, setIsHovered] = React.useState(false);

  const Glyph = () => {
    if (!props.icon) {
      return (
        <div className={classes.glyphicon}>
          <i className={`fa fa-${props.glyph}`}></i>
        </div>
      );
    }
    if (typeof props.icon === 'function') {
      const svgFill = props.disabled
        ? '#666'
        : isHovered
        ? '#006ab0'
        : '#337AB7';
      return (
        <div className={classes.glyphicon}>
          <div className={classes.glyphContainer}>
            {React.createElement(props.icon, { fill: svgFill })}
          </div>
        </div>
      );
    }
    if (typeof props.icon === 'string') {
      return (
        <div className={classes.glyphicon}>
          <Icon fontSize="inherit">{props.icon}</Icon>
        </div>
      );
    }
  };

  const Div = props => <div {...props}>{props.children}</div>;
  const Component = props.disabled ? Div : Link;

  if (props.route.includes('agrinhealth.com')) {
    return (
      <a
        className={classnames(classes.root, {
          [classes.disabled]: props.disabled,
        })}
        href={props.route}
        target="_blank"
      >
        <Glyph classes={classes} />
        <span>{props.label}</span>
      </a>
    );
  }

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.disabled && props.label !== 'Sync Permissions' && (
        <div
          className={classnames(classes.tooltip, {
            [classes.active]: isHovered,
          })}
        >
          Coming Soon
        </div>
      )}

      <Component
        className={classnames({
          [classes.root]: !Boolean(
            props.disabled && props.label === 'Sync Permissions'
          ),
          [classes.disabled]: Boolean(
            props.disabled && props.label !== 'Sync Permissions'
          ),
          [classes.disabledForSync]: Boolean(
            props.disabled && props.label === 'Sync Permissions'
          ),
        })}
        to={{
          pathname: props.route,
          state: { from: props.category ? props.category : '' },
        }}
      >
        <Glyph />
        <div className={classes.buttonLabel}>
          <span>{props.label}</span>
        </div>
      </Component>
    </div>
  );
};

PropTypes.propTypes = {
  glyph: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

PropTypes.defaultProps = {
  route: '',
};

export default withStyles(styles)(MyHealthRecordButton);
