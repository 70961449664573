import { isArray } from 'lodash';
import { formatISO } from 'date-fns';

import { getKeys, arrayToHashMap } from '../../../library/helpers/utils';
import {
  NOTIFICATIONS_RECEIVED,
  NOTIFICATIONS_GLOBAL_REQUEST,
  NOTIFICATIONS_GLOBAL_SUCCESS,
  NOTIFICATIONS_GLOBAL_FAILURE,
  NOTIFICATIONS_PERSON_REQUEST,
  NOTIFICATIONS_PERSON_SUCCESS,
  NOTIFICATIONS_PERSON_FAILURE,
  NOTIFICATION_PERSON_MARK_REQUEST,
  NOTIFICATION_PERSON_MARK_FAILURE,
  NOTIFICATION_GLOBAL_MARK_REQUEST,
  NOTIFICATION_GLOBAL_MARK_FAILURE,
  NOTIFICATIONS_PERSON_CLEAR_REQUEST,
  NOTIFICATIONS_PERSON_CLEAR_FAILURE,
} from './actions';

const initialState = {
  person: {
    unreadNotificationIds: [],
    list: {},
    listIds: [],
    hasNotification: false,
    isFetching: false,
  },
  global: {
    unreadNotifications: [],
    unreadNotificationIds: [],
    list: {},
    listIds: [],
    hasNotification: false,
    isFetching: false,
  },
};

const NOTIFICATION_GROUP_GLOBAL = 'global';
const NOTIFICATION_GROUP_PERSON = 'person';
const reducers = [];

const receiveNotification = (groupName, idName, state, action) => {
  const group = state[groupName];

  if (!action.payload) return state;

  const notifications = isArray(action.payload)
    ? action.payload
    : [action.payload];

  const listIds = [].concat(getKeys(idName, notifications), group.listIds);

  const unreadNotificationIds = [].concat(
    getKeys(
      idName,
      notifications.filter(x => !(x.clearedOn || x.readOn))
    )
  );

  return {
    ...state,
    [groupName]: {
      ...group,
      list: {
        ...arrayToHashMap(idName, notifications),
        ...group.list,
      },
      listIds,
      unreadNotificationIds,
      hasNotification: listIds.length > 0,
      isFetching: false,
    },
  };
};

const markNotification = (type, state, action) => {
  type = type.toLowerCase();
  if (
    type !== NOTIFICATION_GROUP_PERSON &&
    type !== NOTIFICATION_GROUP_GLOBAL
  ) {
    throw new Error(`Unsupported markNotification type "${type}"`);
  }

  const group = state[type];
  const idName =
    type === NOTIFICATION_GROUP_PERSON
      ? 'personNotificationId'
      : 'globalNotificationId';

  const notification = {
    ...group.list[action.payload.notification[idName]],
  };

  let isRemovedFromUnread = null;
  switch (action.payload.action) {
    case 'read':
      notification.readOn = formatISO(new Date());
      isRemovedFromUnread = true;
      break;
    case 'unread':
      notification.readOn = null;
      isRemovedFromUnread = false;
      break;
    case 'clear':
      notification.clearedOn = formatISO(new Date());
      isRemovedFromUnread = true;
      break;
    case 'restore':
      notification.clearedOn = null;
      isRemovedFromUnread = false;
      break;
  }

  const unreadNotificationIds = isRemovedFromUnread
    ? group.unreadNotificationIds.filter(x => x !== notification[idName])
    : [].concat(group.unreadNotificationIds, notification[idName]);

  return {
    ...state,
    [type]: {
      ...group,
      list: {
        ...group.list,
        [notification[idName]]: notification,
      },
      unreadNotificationIds,
      hasNotification: unreadNotificationIds.length > 0,
    },
  };
};

reducers[NOTIFICATIONS_RECEIVED] = (state, action) => {
  const unreadNotificationIds = [].concat(
    action.payload.personNotificationId,
    state.person.unreadNotificationIds
  );

  return {
    ...state,
    person: {
      ...state.person,
      list: {
        ...state.person.list,
        [action.payload.personNotificationId]: action.payload,
      },
      listIds: [].concat(
        action.payload.personNotificationId,
        state.person.listIds
      ),
      unreadNotificationIds,
      hasNotification: unreadNotificationIds.length > 0,
    },
  };
};

reducers[NOTIFICATIONS_GLOBAL_REQUEST] = state => ({
  ...state,
  global: {
    ...state.global,
    isFetching: true,
  },
});

reducers[NOTIFICATIONS_GLOBAL_SUCCESS] = (state, action) =>
  receiveNotification(
    NOTIFICATION_GROUP_GLOBAL,
    `${NOTIFICATION_GROUP_GLOBAL}NotificationId`,
    state,
    action
  );

reducers[NOTIFICATIONS_GLOBAL_FAILURE] = state => ({
  ...state,
  global: {
    ...state.global,
    isFetching: false,
  },
});

reducers[NOTIFICATIONS_PERSON_REQUEST] = state => ({
  ...state,
  person: {
    ...state.person,
    isFetching: true,
  },
});

reducers[NOTIFICATIONS_PERSON_SUCCESS] = (state, action) =>
  receiveNotification(
    NOTIFICATION_GROUP_PERSON,
    `personNotificationId`,
    state,
    action
  );

reducers[NOTIFICATIONS_PERSON_FAILURE] = state => ({
  ...state,
  person: {
    ...state.person,
    isFetching: false,
  },
});

reducers[NOTIFICATION_PERSON_MARK_REQUEST] = (state, action) =>
  markNotification(NOTIFICATION_GROUP_PERSON, state, action);

reducers[NOTIFICATION_PERSON_MARK_FAILURE] = (state, action) => {
  let markAdjustedAction = null;
  switch (action.payload.action) {
    case 'read':
      markAdjustedAction = 'unread';
      break;
    case 'unread':
      markAdjustedAction = 'read';
      break;
    case 'clear':
      markAdjustedAction = 'unclear';
      break;
  }

  const adjustedAction = {
    ...action,
    payload: {
      ...action.payload,
      action: markAdjustedAction,
    },
  };

  return markNotification(NOTIFICATION_GROUP_PERSON, state, adjustedAction);
};

reducers[NOTIFICATION_GLOBAL_MARK_REQUEST] = (state, action) =>
  markNotification(NOTIFICATION_GROUP_GLOBAL, state, action);

reducers[NOTIFICATION_GLOBAL_MARK_FAILURE] = (state, action) => {
  const adjustedAction = {
    ...action,
    payload: {
      ...action.payload,
      action: 'unread',
    },
  };

  markNotification(NOTIFICATION_GROUP_GLOBAL, state, adjustedAction);
};

reducers[NOTIFICATIONS_PERSON_CLEAR_REQUEST] = (state, action) => {
  const list = { ...state.person.list };
  const listIds = action.payload;

  for (let i = 0; i < listIds.length; i++) {
    list[listIds[i]] = {
      ...list[listIds[i]],
      clearedOn: formatISO(new Date()),
    };
  }

  const unreadNotificationIds = state.person.unreadNotificationIds.filter(
    id => !listIds.includes(id)
  );

  return {
    ...state,
    person: {
      ...state.person,
      list,
      unreadNotificationIds,
      hasNotification: unreadNotificationIds.length > 0,
    },
  };
};

reducers[NOTIFICATIONS_PERSON_CLEAR_FAILURE] = (state, action) => {
  const list = { ...state.person.list };
  const listIds = action.payload;

  for (let i = 0; i < listIds.length; i++) {
    list[listIds[i]] = {
      ...list[listIds[i]],
      clearedOn: null,
    };
  }

  const unreadNotificationIds = listIds;

  return {
    ...state,
    person: {
      ...state.person,
      list,
      unreadNotificationIds,
      hasNotification: unreadNotificationIds.length > 0,
    },
  };
};

export default (state = initialState, action) =>
  action.type in reducers ? reducers[action.type](state, action) : state;
