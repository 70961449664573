const LOADING = 'loading';
const SUCCESS = 'success';
const FINISHED = 'finished';
const ERROR = 'error';
const EMPTY = 'empty';
const FETCHING = 'fetching';

export const isFetching = data =>
  data && (data.isFetching || data.uiState === LOADING);

export const hasFetched = data =>
  data &&
  (data.hasFetched || data.uiState === SUCCESS || data.uiState === EMPTY);
export { LOADING, SUCCESS, ERROR, EMPTY, FINISHED, FETCHING };
