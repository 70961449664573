import React from 'react';
import PropTypes from 'prop-types';

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  buttonConfirm: {
    marginLeft: theme.spacing(2),
  },
});

const ConfirmActionDialog = ({
  onConfirm,
  onClose,
  isOpened,
  buttonCancelText,
  buttonConfirmText,
  titleText,
  classes,
  children,
}) => (
  <Dialog open={isOpened} onClose={onClose}>
    <DialogTitle>{titleText}</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Button onClick={onClose}>{buttonCancelText}</Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.buttonConfirm}
        onClick={onConfirm}
      >
        {buttonConfirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmActionDialog.propTypes = {
  titleText: PropTypes.string.isRequired,
  buttonConfirmText: PropTypes.string.isRequired,
  buttonCancelText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

ConfirmActionDialog.defaultProps = {
  buttonCancelText: 'Cancel',
  buttonConfirmText: 'Confirm',
  isOpened: false,
};

export default withStyles(styles)(ConfirmActionDialog);
