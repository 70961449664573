import React, { useEffect } from 'react';
import { format } from 'date-fns';

import {
  Grid,
  Button,
  Icon,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';

import { parseDate } from '../../../library/utils/date';

const styles = theme => {
  return {
    icons: {
      position: 'absolute',
      top: 15,
      right: 15,
    },
    cardBottom: {
      marginTop: 16,
    },
    icon: {
      marginRight: 8,
    },
    input: {
      marginBottom: 24,
    },
    value: {
      marginTop: 12,
      fontSize: 18,
      fontWeight: 500,
    },
    complication: {
      backgroundColor: '#006AB0',
      borderRadius: 24,
      color: '#fff',
      paddingTop: 4,
      paddingBottom: 4,
      paddingRight: 12,
      fontSize: 14,
      paddingLeft: 12,
      display: 'inline-block',
      marginRight: 18,
    },
  };
};

const ViewCondition = ({ data, classes, onCancel, onDelete, onUpdate }) => {
  const isNull = input =>
    input ? (
      <div className={classes.value}>{input}</div>
    ) : (
      <div className={classes.value}>
        <i>Not Set</i>
      </div>
    );

  const renderDate = date =>
    date ? format(parseDate(date), 'MMMM do, yyyy') : null;

  // const isModifying =
  //   data && data.isUpdating ? true : data && data.isDeleting ? true : false;
  return (
    <Grid item className={classes.root}>
      <Typography variant="h5" style={{ fontWeight: 500, fontSize: '1.5em' }}>
        {data.diagnosisDesc}
      </Typography>
      <Grid container spacing={3} className={classes.cardBottom}>
        <Grid item lg={4} xs={4} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>date_range</Icon>
            <div>Surgery Date</div>
          </Grid>
          {isNull(renderDate(data.diagnosedOn))}
        </Grid>
        <Grid item lg={4} xs={4} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>business</Icon>
            <div>Physician Name</div>
          </Grid>
          {isNull(data.providerName)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>healing</Icon>
            <div>Medications</div>
          </Grid>
          {isNull(data.relatedMvs)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>assignment_turned_in</Icon>
            <div>Related Treatments</div>
          </Grid>
          {isNull(data.relatedTreatments)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>assignment_late</Icon>
            <div>Related Symptoms</div>
          </Grid>
          {isNull(data.relatedSymptoms)}
        </Grid>
        <Grid item lg={12} xs={12} className={classes.input}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            style={{ opacity: 0.7 }}
          >
            <Icon className={classes.icon}>edit</Icon>
            <div>Notes</div>
          </Grid>
          <div className={classes.value}>{data.notes}</div>
        </Grid>
        <Grid item container lg={12} xs={12} alignItems="center">
          <Button onClick={onCancel} style={{ marginRight: 24 }}>
            Cancel
          </Button>
          <Button onClick={onDelete} style={{ marginRight: 24 }}>
            Delete
          </Button>
          <Button onClick={onUpdate} color="primary" variant="contained">
            Edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ViewCondition);
