import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

export const REACTIONS_REQUEST = '@@redux/api/REACTIONS_REQUEST';
export const REACTIONS_SUCCESS = '@@redux/api/REACTIONS_SUCCESS';
export const REACTIONS_FAILURE = '@@redux/api/REACTIONS_FAILURE';

export const fetchReactions = () => ({
  [DISPATCH_API_AUTHORIZED]: {
    types: [REACTIONS_REQUEST, REACTIONS_SUCCESS, REACTIONS_FAILURE],
    endpoint: '/api/code-table/reaction/1',
  }
});