import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ModalBase from './ModalBase';
import RoleSummary from '../RoleSummary/RoleSummary';

import NonProvider from '../NonProvider';
import Provider from '../Provider';

import {
  ROLE_NAME_NO_ACCESS,
  ROLE_NAME_TEAMMATE,
  ROLE_NAME_CAREGIVER,
  ROLE_NAME_PROVIDER,
  ROLE_NAME_SPOUSE,
  ROLE_NAME_GUARDIAN_ADDITIONAL,
} from '../RoleSummary/data.js';

const COMPONENT_ROLE_SELECTION = 'roleSelection';
const COMPONENT_NON_PROVIDER = 'nonProvider';
const COMPONENT_PROVIDER = 'provider';

const getComponentBasedOnRole = role => {
  switch (role) {
    case ROLE_NAME_TEAMMATE:
    case ROLE_NAME_SPOUSE:
    case ROLE_NAME_GUARDIAN_ADDITIONAL:
    case ROLE_NAME_CAREGIVER:
      return COMPONENT_NON_PROVIDER;
    case ROLE_NAME_PROVIDER:
      return COMPONENT_PROVIDER;
    default:
      return COMPONENT_ROLE_SELECTION;
  }
};

const ModifyModal = props => {
  const {
    onClose,
    onSubmit,
    open,
    baseRoutePath,
    history,
    member,
    isUpdating,
  } = props;

  useEffect(() => {
    if (isUpdating && member && member.relationshipType) {
      setRelationshipType(member.relationshipType);
    }
  }, []);

  const [relationshipType, setRelationshipType] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleGoBack = () => {
    history.push(`${baseRoutePath}/modify`);
  };

  const header = isUpdating
    ? `Update care team member record`
    : 'Add a care team member';

  const modalProps =
    history.location.pathname === `${baseRoutePath}/modify/provider/search`
      ? {
          minHeight: 670,
        }
      : {
          height: 670,
        };
  return (
    <ModalBase {...modalProps} open={open} onClose={handleClose} showCloseIcon>
      <Switch>
        <Route
          path={`${baseRoutePath}/modify`}
          exact
          render={props => (
            <RoleSummary
              {...props}
              header={header}
              isUpdating={isUpdating}
              member={member}
              role={relationshipType}
              onClickSelectRole={role => {
                setRelationshipType(role);
              }}
              onClickAddRole={role => {
                if (COMPONENT_NON_PROVIDER === getComponentBasedOnRole(role)) {
                  history.push(`${baseRoutePath}/modify/person/step-one`);
                } else {
                  history.push(`${baseRoutePath}/modify/provider/search`);
                }
                setRelationshipType(role);
              }}
            />
          )}
        />
        <Route
          path={`${baseRoutePath}/modify/person/:step`}
          render={props => (
            <NonProvider
              {...props}
              header={header}
              isUpdating={isUpdating}
              updateMember={member}
              relationshipType={relationshipType}
              baseRoutePath={baseRoutePath}
              onClickGoBack={handleGoBack}
              onSubmit={onSubmit}
            />
          )}
        />
        <Route
          path={`${baseRoutePath}/modify/provider/:step`}
          render={localProps => (
            <Provider
              {...props}
              {...localProps}
              header={header}
              isUpdating={isUpdating}
              updateMember={member}
              relationshipType={relationshipType}
              baseRoutePath={baseRoutePath}
              onClickGoBack={() => history.goBack()}
              onSubmit={onSubmit}
              onClose={handleClose}
            />
          )}
        />
      </Switch>
    </ModalBase>
  );
};

export default withRouter(ModifyModal);
