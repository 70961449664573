import { isValidZipCode } from '../../../library/validations/ZipCode';
import { simpleEmailValidation } from '../../../library/validations/EmailAddress';

export default (values) => {

  const {
    relationship,
    firstName,
    lastName,
    postalCode,
    phone1Number,
    phone1Type,
    phone2Number,
    phone2Type,
    email,
  } = values;

  const errors = {};

  if(!relationship) {
    errors.relationship = "Required";
  }

  if (!firstName) {
    errors.firstName = 'Required';
  } else if (firstName.length < 2) {
    errors.firstName = 'Must be at least 2 characters long';
  }

  if (!lastName) {
    errors.lastName = 'Required';
  } else if (lastName.length < 2) {
    errors.lastName = 'Must be at least 2 characters long';
  }

  if (postalCode && !isValidZipCode(postalCode)) {
    errors.postalCode = 'Invalid zip-code. Standard US format is XXXXX or XXXXX-XXXX';
  }

  if (phone1Number && phone1Number.length < 10) {
    errors.phone1Number = 'Invalid phone number. Correct format is XXX-XXX-XXXX';
  } else if (!phone1Number && phone1Type) {
    errors.phone1Number = "Phone type selected. Please entry a phone number";
  }

  if (phone1Number && (!phone1Type || phone1Type.toLowerCase() === 'type')) {
    errors.phone1Type = 'Select a phone type';
  }

  if (phone2Number && phone2Number.length < 10) {
    errors.phone2Number = 'Invalid phone number. Correct format is XXX-XXX-XXXX';
  } else if (!phone2Number && phone2Type) {
    errors.phone2Number = "Phone type selected. Please entry a phone number";
  }

  if (phone2Number && (!phone2Type || phone2Type.toLowerCase() === 'type')) {
    errors.phone2Type = 'Select a phone type';
  }

  if (email && !simpleEmailValidation(email)) {
    errors.email = 'Invalid email address';
  }


  return errors;
}