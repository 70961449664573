import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';

import InputField from '../../../library/forms/InputField';
import { mask } from '../../../library/helpers/format';
import { Icon } from '@material-ui/core';
import { ajax } from '../../../library/helpers/fetch';
import { connect } from 'react-redux';

const formatSsn = value => mask(value, 'XXX-XX-XXXX');

const SsnHint = () => (
  <React.Fragment>
    <Icon
      style={{
        marginRight: 3,
        marginBottom: -3,
        fontSize: 16,
        display: 'inline-block',
      }}
    >
      info
    </Icon>
    Most Providers require a SSN to exchange information in order to insure that
    it is connected to the right patient. If you plan to exchange data with your
    doctor or other Providers, it will be necessary to enter an accurate SSN.
  </React.Fragment>
);

const SsnFormField = ({
  hint,
  required,
  placeholder,
  shrink,
  ssnPart,
  ...props
}) => {
  const [initialValue, setInitialValue] = useState('');
  useEffect(async () => {
    if (!ssnPart) return;
    const url = `/api/member/private/field`;
    try {
      const { data } = await ajax(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          name: 'ssn',
        }),
      });
      setInitialValue(data);
    } catch (e) {
      throw e;
    }
  }, []);
  return (
    <Field
      name="ssn"
      shrink={shrink}
      label={`SSN  ${required ? '*' : ''}`}
      type="text"
      autoComplete="off"
      hint={hint ? hint : ''}
      inputProps={{ maxLength: 11 }}
      placeholder={initialValue || 'SSN'}
      component={InputField}
      format={formatSsn}
      props={{
        ...props,
        HoverTooltipMsg:
          'Most Providers require a SSN to exchange information in order to insure that it is connected to the right patient. If you plan to exchange data with your doctor or other Providers, it will be necessary to enter an accurate SSN.',
      }}
    />
  );
};

const mapStateToProps = state => ({
  ssnPart:
    state.form['new-dependent-form'] &&
    state.form['new-dependent-form'].values.ssnPart
      ? state.form['new-dependent-form'].values.ssnPart
      : '',
});

export default connect(mapStateToProps)(SsnFormField);
