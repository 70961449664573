const SURGERY_LIST = [
  {
    surgeryName: 'Arthroscopic anterior cruciate ligament surgery',
    surgeryEndedOn: new Date(),
    hospitalFacility: null,
    surgeon: 'Jack Williams',
    complications: null,
  },
  {
    surgeryName: 'Clubfoot repair',
    surgeryEndedOn: new Date(),
    hospitalFacility: 'John Hancock Hospital',
    surgeon: 'Jack Williams the second',
    complications: [{ name: 'Toe' }, { name: 'Bleeding' }],
  },
  {
    surgeryName: 'Arthroscopic anterior cruciate ligament surgery',
    date: new Date(),
    facility: null,
    surgeon: 'Jack Williams',
    complications: null,
  },
  {
    surgeryName: 'Clubfoot repair',
    surgeryEndedOn: new Date(),
    hospitalFacility: 'John Hancock Hospital',
    surgeon: 'Jack Williams the second',
    complications: [{ name: 'Toe' }, { name: 'Bleeding' }],
  },
];

export default SURGERY_LIST;
