import { useState, useEffect } from 'react';
import _ from 'lodash';
import { isWithinInterval } from 'date-fns';
import {
  parseDate,
  whichDataSet,
  DISCREET_DATA_POINTS,
  WEEKLY_AVERAGE,
} from '../utils';
import { DATERANGE } from '../../ChartWrapper';

export const pieDataDefault = [
  {
    type: '<10 oz',
    value: 0,
    range: [0, 10],
    percentage: 0,
    index: 0,
  },
  {
    type: '10 - 24 oz',
    value: 0,
    range: [10, 24],
    percentage: 0,
    index: 1,
  },
  {
    type: '25 - 39 oz',
    value: 0,
    range: [25, 39],
    percentage: 0,
    index: 2,
  },
  {
    type: '40 - 54 oz',
    value: 0,
    range: [40, 54],
    percentage: 0,
    index: 3,
  },
  {
    type: '55+ oz',
    value: 0,
    percentage: 0,
    index: 4,
  },
];

const useNetWaterIntake = (coordinates, dateExtent, dateRange) => {
  const [netData, setNetData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const dataSet = whichDataSet(dateRange);

  const getPieDate = () => {
    if (coordinates.length === 0) return;
    const _pieData = _.cloneDeep(pieDataDefault);
    coordinates.forEach(item => {
      const quantity = item.quantity;
      // get pie data
      switch (
        isWithinInterval(item.date, {
          start: dateExtent[0],
          end: dateExtent[1],
        })
      ) {
        case quantity <= 10:
          _pieData[0].value += 1;
          break;
        case quantity > 10 && quantity <= 24:
          _pieData[1].value += 1;
          break;
        case quantity > 24 && quantity <= 39:
          _pieData[2].value += 1;
          break;
        case quantity > 40 && quantity <= 54:
          _pieData[3].value += 1;
          break;
        default:
          _pieData[4].value += 1;
          break;
      }
    });
    const totalQuantity = coordinates.length;
    _pieData.forEach((val, i) => {
      if (val.value > 0) {
        _pieData[i].percentage =
          (Math.round((val.value * 100) / totalQuantity) * 100) / 100;
      }
      return;
    });
    setPieData(
      _pieData
        .filter(item => item.value > 0)
        .sort((a, b) => b.percentage - a.percentage)
    );
  };

  useEffect(() => {
    if (netData[dataSet]) return;
    calculateData();
  }, [dateRange]);

  useEffect(() => {
    getPieDate();
  }, [coordinates, dateExtent]);

  useEffect(() => {
    setNetData([]);
    calculateData();
  }, [coordinates]);

  const calculateData = () => {
    const result = [];
    if (dataSet === WEEKLY_AVERAGE) {
      coordinates.forEach(item => {
        const quantity = item.quantity;
        let date = new Date(item.date);
        date.setHours(0, 0, 0, 0);
        date = parseDate(date, DATERANGE.SIX_MONTHS);

        if (result[date]) {
          result[date].quantity += quantity;
          result[date].freq += 1;
          result[date].averageWaterIntake = Math.round(
            result[date].quantity / result[date].freq
          );
        } else {
          result[date] = {
            quantity: quantity,
            unit: item.unit,
            date,
            freq: 1,
            averageWaterIntake: quantity,
          };
        }
      });
    } else {
      coordinates.forEach(item => {
        const quantity = item.quantity;
        const date = new Date(item.date);

        if (result[date]) {
          result[date].quantity += quantity;
        } else {
          result[date] = {
            quantity: quantity,
            unit: item.unit,
            date,
          };
        }
      });
    }

    setNetData({
      ...netData,
      [dataSet]: Object.values(result),
    });
  };

  return [netData, pieData];
};

export default useNetWaterIntake;
