export const ROLE_NAME_NO_ACCESS = 'NoAccess';
export const ROLE_NAME_TEAMMATE = 'Teammate';
export const ROLE_NAME_CAREGIVER = 'CareGiver';
export const ROLE_NAME_PROVIDER = 'Provider';
export const ROLE_NAME_SPOUSE = 'Spouse';
export const ROLE_NAME_GUARDIAN_ADDITIONAL = 'AdditionalGuardian';
export const ROLE_NAME_GUARDIAN_PRIMARY = 'PrimaryGuardian';

export const RELATIONSHIP_STATUS_APPROVED = 'Approved';
export const RELATIONSHIP_STATUS_PENDING = 'Pending';
export const RELATIONSHIP_STATUS_ARCHIVED = 'Archived';
export const RELATIONSHIP_STATUS_DENIED_ACCESS = 'DeniedAccess';
export const RELATIONSHIP_STATUS_ACCESS_GRANTED = 'Access Granted';

const ROLES = [
  {
    label: 'Teammate',
    value: ROLE_NAME_TEAMMATE,
    icon: 'fitness_center',
    illustration: require('../../../../client/svgs/roles/Teammate.svg'),
    color: '#3E47DD',
    access: [
      // {
      //   label: 'Care Plan / Goals',
      //   value: 'careplan',
      //   type: 'r'
      // },
      {
        label: 'Journal',
        value: 'journal',
        type: 'r',
      },
      // {
      //   label: 'Alerts / Insights',
      //   value: 'alerts',
      //   type: null
      // },
      {
        label: 'Health Record',
        value: 'healthrecord',
        type: null,
      },
      {
        label: 'Care Team',
        value: 'careteam',
        type: null,
      },
      {
        label: 'Basic Information',
        value: 'basicinformation',
        type: null,
      },
      {
        label: 'Insurance',
        value: 'insurance',
        type: null,
      },
      {
        label: 'Emergency Info',
        value: 'emergencyinfo',
        type: null,
      },
      // {
      //   label: 'Account Info',
      //   value: 'accountinfo',
      //   type: null
      // }
    ],
  },
  {
    label: 'Care Giver',
    value: ROLE_NAME_CAREGIVER,
    icon: 'accessible',
    illustration: require('../../../../client/svgs/roles/CareGiver.svg'),
    color: '#A4D859',
    access: [
      // {
      //   label: 'Care Plan / Goals',
      //   value: 'careplan',
      //   type: 'rw'
      // },
      {
        label: 'Journal',
        value: 'journal',
        type: 'rw',
      },
      // {
      //   label: 'Alerts / Insights',
      //   value: 'alerts',
      //   type: 'rw'
      // },
      {
        label: 'Health Record',
        value: 'healthrecord',
        type: 'rw',
      },
      {
        label: 'Care Team',
        value: 'careteam',
        type: 'rw',
      },
      {
        label: 'Basic Information',
        value: 'basicinformation',
        type: null,
      },
      {
        label: 'Insurance',
        value: 'insurance',
        type: null,
      },
      {
        label: 'Emergency Info',
        value: 'emergencyinfo',
        type: null,
      },
      // {
      //   label: 'Account Info',
      //   value: 'accountinfo',
      //   type: null
      // }
    ],
  },
  {
    label: 'Provider',
    value: ROLE_NAME_PROVIDER,
    icon: 'local_hospital',
    illustration: require('../../../../client/svgs/roles/Provider.svg'),
    color: '#2AA5A2',
    disabled: false,
    access: [
      // {
      //   label: 'Care Plan / Goals',
      //   value: 'careplan',
      //   type: 'rw'
      // },
      {
        label: 'Journal',
        value: 'journal',
        type: 'r',
      },
      // {
      //   label: 'Alerts / Insights',
      //   value: 'alerts',
      //   type: 'rw'
      // },
      {
        label: 'Health Record',
        value: 'healthrecord',
        type: 'rw',
      },
      {
        label: 'Care Team',
        value: 'careteam',
        type: 'r',
      },
      {
        label: 'Basic Information',
        value: 'basicinformation',
        type: 'r',
      },
      {
        label: 'Insurance',
        value: 'insurance',
        type: 'r',
      },
      {
        label: 'Emergency Info',
        value: 'emergencyinfo',
        type: 'r',
      },
      // {
      //   label: 'Account Info',
      //   value: 'accountinfo',
      //   type: null
      // }
    ],
  },
  {
    label: 'Spouse',
    value: ROLE_NAME_SPOUSE,
    icon: 'favorite',
    illustration: require('../../../../client/svgs/roles/Spouse.svg'),
    color: '#5A82F6',
    access: [
      // {
      //   label: 'Care Plan / Goals',
      //   value: 'careplan',
      //   type: 'rw'
      // },
      {
        label: 'Journal',
        value: 'journal',
        type: 'rw',
      },
      // {
      //   label: 'Alerts / Insights',
      //   value: 'alerts',
      //   type: 'rw'
      // },
      {
        label: 'Health Record',
        value: 'healthrecord',
        type: 'rw',
      },
      {
        label: 'Care Team',
        value: 'careteam',
        type: 'rw',
      },
      {
        label: 'Basic Information',
        value: 'basicinformation',
        type: 'rw',
      },
      {
        label: 'Insurance',
        value: 'insurance',
        type: 'rw',
      },
      {
        label: 'Emergency Info',
        value: 'emergencyinfo',
        type: 'rw',
      },
      // {
      //   label: 'Account Info',
      //   value: 'accountinfo',
      //   type: 'rw'
      // }
    ],
  },
  {
    label: 'Guardian',
    subLabel: 'Additional',
    value: ROLE_NAME_GUARDIAN_ADDITIONAL,
    illustration: require('../../../../client/svgs/roles/CareGiver.svg'),
    color: '#FBC67A',
    icon: 'accessibility',
    access: [
      // {
      //   label: 'Care Plan / Goals',
      //   value: 'careplan',
      //   type: 'rw'
      // },
      {
        label: 'Journal',
        value: 'journal',
        type: 'rw',
      },
      // {
      //   label: 'Alerts / Insights',
      //   value: 'alerts',
      //   type: 'rw'
      // },
      {
        label: 'Health Record',
        value: 'healthrecord',
        type: 'rw',
      },
      {
        label: 'Care Team',
        value: 'careteam',
        type: 'rw',
      },
      {
        label: 'Basic Information',
        value: 'basicinformation',
        type: 'rw',
      },
      {
        label: 'Insurance',
        value: 'insurance',
        type: 'rw',
      },
      {
        label: 'Emergency Info',
        value: 'emergencyinfo',
        type: 'rw',
      },
      // {
      //   label: 'Account Info',
      //   value: 'accountinfo',
      //   type: 'rw'
      // }
    ],
  },
];

export default ROLES;
