import { format } from 'date-fns';
import { DISPATCH_API_AUTHORIZED } from '../../../library/middleware/dispatchApi';

import { uploadFiles } from '../mediaObject';
import { apiFetchAuthorized, apiDelete } from '../../../library/helpers/fetch';
import {
  RESOURCE_MEDIA_MODIFIED,
  RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
  RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
} from '../mediaObject/actions';

export const MEDIA_SET_MEDIA = 'mediaObject/drawer/MEDIA_SET_MEDIA';

export const MEDIA_SET_ITEM = 'mediaObject/drawer/SET_ITEM';
export const MEDIA_SET_CURRENT_ITEM = 'mediaObject/drawer/SET_CURRENT_ITEM';
export const MEDIA_DRAWER_SET_STATE = 'mediaObject/drawer/SET_STATE';
export const MEDIA_HEADER_SET_STATE = 'mediaObject/drawer/header/SET_STATE';

export const MEDIA_FETCH_REQUEST = 'mediaObject/drawer/MEDIA_FETCH_REQUEST';
export const MEDIA_FETCH_FAILURE = 'mediaObject/drawer/MEDIA_FETCH_FAILURE';
export const MEDIA_FETCH_SUCCESS = 'mediaObject/drawer/MEDIA_FETCH_SUCCESS';

export const MEDIA_REMOVE_REQUEST = 'mediaObject/drawer/MEDIA_REMOVE_REQUEST';
export const MEDIA_REMOVE_FAILURE = 'mediaObject/drawer/MEDIA_REMOVE_FAILURE';
export const MEDIA_REMOVE_SUCCESS = 'mediaObject/drawer/MEDIA_REMOVE_SUCCESS';
export const MEDIA_UPDATE_REQUEST = 'mediaObject/drawer/MEDIA_UPDATE_REQUEST';
export const MEDIA_UPDATE_FAILURE = 'mediaObject/drawer/MEDIA_UPDATE_FAILURE';
export const MEDIA_UPDATE_SUCCESS = 'mediaObject/drawer/MEDIA_UPDATE_SUCCESS';

export const MEDIA_UPLOAD_REQUEST = 'mediaObject/drawer/MEDIA_UPLOAD_REQUEST';
export const MEDIA_UPLOAD_FAILURE = 'mediaObject/drawer/MEDIA_UPLOAD_FAILURE';
export const MEDIA_UPLOAD_SUCCESS = 'mediaObject/drawer/MEDIA_UPLOAD_SUCCESS';

export const MEDIA_SET_AREATYPE = 'mediaObject/drawer/SET_AREA_TYPE';

export const DRAWER_STATUS_CLOSED = 'mediaObject/drawer/DRAWER_STATUS_CLOSED';
export const SET_AREA_TYPE = 'mediaObject/drawer/SET_AREA_TYPE';

export const cancelAddMedia = () => ({
  type: DRAWER_STATUS_CLOSED,
});

export const setAreaType = payload => ({
  type: SET_AREA_TYPE,
  payload,
});

export const setMedia = bundle => ({
  type: MEDIA_SET_MEDIA,
  payload: bundle,
});

export const setDrawerState = status => ({
  type: MEDIA_DRAWER_SET_STATE,
  payload: status,
});

export const setHeaderState = status => ({
  type: MEDIA_HEADER_SET_STATE,
  payload: status,
});

export const setItemByIndex = index => ({
  type: MEDIA_SET_ITEM,
  payload: index,
});

export const setCurrentItem = item => {
  return {
    type: MEDIA_SET_CURRENT_ITEM,
    payload: item,
  };
};

export const getMedia = (pid, aid, area) => {
  return {
    [DISPATCH_API_AUTHORIZED]: {
      types: [MEDIA_FETCH_REQUEST, MEDIA_FETCH_SUCCESS, MEDIA_FETCH_FAILURE],
      endpoint: `/api/member/${area}/${aid}`,
      meta: {
        config: {
          headers: { 'Content-Type': 'application/json', Pid: pid },
        },
      },
    },
  };
};

export const deleteMedia = (
  mediaObjectId,
  objectId,
  appArea,
  referenceId = null
) => async dispatch => {
  dispatch({ type: MEDIA_REMOVE_REQUEST, payload: mediaObjectId });

  try {
    const api = referenceId
      ? `/api/media/journal/${mediaObjectId}/reference/${referenceId}`
      : `/api/media/${mediaObjectId}`;
    console.log(api);
    const response = await apiDelete(api, {
      mode: 'cors',
    });
    const json = await response.json();
    console.log(response, api);

    if (response.status === 200) {
      dispatch({ type: MEDIA_REMOVE_SUCCESS, payload: mediaObjectId });

      // Notify resource collections (e.g., Allergies, MVS) that a media
      // object is removed so the original resource store syncs up.
      dispatch({
        type: RESOURCE_MEDIA_MODIFIED,
        payload: mediaObjectId,
        meta: {
          resource: appArea,
          operation: RESOURCE_MEDIA_MODIFIED_OPERATION_DELETE,
          objectId,
        },
      });
    } else if (response.status >= 400) {
      dispatch({ type: MEDIA_REMOVE_FAILURE, payload: mediaObjectId });
    }
  } catch (e) {
    dispatch({ type: MEDIA_REMOVE_FAILURE, payload: mediaObjectId });
  }
};

export const updateMedia = (appArea, data) => async dispatch => {
  const currDate = data.date ? data.date : format(new Date(), 'yyyy-MM-dd');
  const date = data.time
    ? new Date(`${currDate} ${data.time}`)
    : new Date(`${currDate}`);
  const newDateTime = format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");

  const request = {
    personId: data.personId,
    mediaObjectId: data.mediaObjectId,
    objectId: data.objectId,
    description: data.description,
    enteredOn: newDateTime,
  };

  dispatch({ type: MEDIA_UPDATE_REQUEST, payload: request, appArea });

  appArea = appArea.toUpperCase();

  try {
    const response = await apiFetchAuthorized('/api/media/details', {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(request),
    });

    const json = await response.json();

    if (response.status === 200) {
      const payload = {
        ...request,
        ...json.data,
      };

      dispatch({ type: MEDIA_UPDATE_SUCCESS, payload, response: json });

      // Notify resource collections (e.g., Allergies, MVS) that a media
      // object is updated so the original resource store syncs up.
      dispatch({
        type: RESOURCE_MEDIA_MODIFIED,
        payload,
        meta: {
          resource: appArea,
          operation: RESOURCE_MEDIA_MODIFIED_OPERATION_UPDATE,
          objectId: data.objectId,
        },
      });
    } else if (response.status >= 400) {
      dispatch({ type: MEDIA_UPDATE_FAILURE, payload: json.data });
    }
  } catch (e) {
    dispatch({ type: MEDIA_UPDATE_FAILURE, payload: request });
  }
};

export const addMediaCollection = (id, objectId, bundles, area) => {
  return async dispatch => {
    dispatch({ type: MEDIA_UPLOAD_REQUEST });

    if (bundles.length > 0) {
      if (area.toUpperCase() === 'DOCUMENTS') {
        await dispatch(uploadDocument(id, objectId, bundles, area));
      }
      await dispatch(uploadFiles(id, objectId, bundles, area));
    }

    return await dispatch({ type: MEDIA_UPLOAD_SUCCESS });
  };
};
