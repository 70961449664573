import React from "react";
import { Grid, withStyles, Typography } from "@material-ui/core";
import MemberDetailRow from "../MemberDetailRow";

// import './MemberList.css'
const styles = {
  root: {
    margin: "1em 0 4em 0",
  },
  noRecords: {
    color: "#ccc",
    textAlign: "center",
    padding: 24,
  },
};
const MemberList = ({
  data,
  filteredIds,
  filter,
  classes,
  onEdit,
  onArchive,
  onDelete,
  submitInlineEdit,
}) => {
  return (
    <Grid className={classes.root}>
      {filteredIds.length > 0 ? (
        filteredIds.map(id => (
          <MemberDetailRow
            key={id}
            member={data.list[id]}
            onEdit={onEdit}
            onArchive={onArchive}
            onDelete={onDelete}
            submitInlineEdit={submitInlineEdit}
          />
        ))
      ) : (
        <Grid container justify="center" alignItems="center">
          <Grid item sm={8} md={6} style={{ marginTop: 48 }}>
            <Typography variant="h5" className={classes.noRecords}>
              There are no members associated with the{" "}
              <strong style={{ color: "#999" }}>"{filter}"</strong> category
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MemberList.defaultProps = {
  data: {},
};

export default withStyles(styles)(MemberList);
